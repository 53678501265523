import React from 'react';

import useSelectedReset       from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import usePageCellSelectedSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/cell/use-cell-selected-set';
import { CellProps } from '../../types';

import { MainWrapper } from './styles';


interface Props extends CellProps {
  children: React.ReactNode;
}


export const CellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr,
    children,
  } = props;

  const setPageCellSelected = usePageCellSelectedSet();
  const resetAllSelected = useSelectedReset();

  const handleSelectCell = (event: React.MouseEvent) => {
    event.stopPropagation(); 
    
    resetAllSelected();
    setPageCellSelected({ pageCellAddr });
  }

  return (
    <MainWrapper onPointerDown={handleSelectCell}>
      { children }
    </MainWrapper>
  );
}
