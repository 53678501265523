import { ContentSavePlugin } from "../../editor-text/ContentSavePlugin";
import WidgetArrowTextBaseComponent from "../__widget-base/widget-arrows/widget-arrow-text-base";
import { WidgetPropsBase } from "../types";


interface Props extends WidgetPropsBase {
}


export const WidgetArrowTextComponent: React.FC<Props> = (props: Props) => {

  return (
    <WidgetArrowTextBaseComponent 
      {...props} 
      EditorTextPlugins={[
        <ContentSavePlugin  widgetAddr={props.widgetAddr} />
      ]}
    />
  );
}