import produce from 'immer';
import * as State from './state';


export class MetaData {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */
  setTitle(title: string) {
    this._state = produce(this._state, draft => {
      draft.title = title;
    });
  }

  setDescription(description: string) {
    this._state = produce(this._state, draft => {
      draft.description = description;
    });
  }

  setFormatVersion(version: number) {
    this._state = produce(this._state, draft => {
      draft.formatVersion = version;
    });
  }


  /**
   * Getters
   */
  getTitle() {
    return State.getTitle(this._state);
  }

  getDescription() {
    return State.getDescription(this._state);
  }

  getFormatVersion() {
    return State.getFormatVersion(this._state);
  }
}
