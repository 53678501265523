import React from 'react';
import { SliceRequestTypes } from 'app/arch/editor-instruction/document/states/slicing/content/slice-request';
import WatchersComponent from './watchers';
import SlicedContentGeneratorComponent from './sliced-content-generator';


interface Props {
  onPagesDefined: (
    pages: React.ReactElement[],
    sliceRequest: SliceRequestTypes.SliceRequest,
  ) => void;
}


export const SliceSystemComponent: React.FC<Props> = (props: Props) => {
  const {
    onPagesDefined
  } = props; 

  return (
    <>
      <SlicedContentGeneratorComponent
        onPagesDefined={onPagesDefined} 
      />
      <WatchersComponent />
    </>
  );
}
  
