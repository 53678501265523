import * as docx from "docx";

import DocState from 'app/arch/editor-instruction/document/states/doc-state';

import * as Tools from '../../tools';
import ExporterBase from "../../parts/exporter-base";
import AssetsRepo from "../../../parts/assets-repo";


class HeaderMarkersExporter extends ExporterBase {
  static PADDING = 6;

  constructor(docState: DocState, assetsRepo: AssetsRepo) {
    super(docState, assetsRepo);
  }

  get content() { return this._docState.content; }

  createSections() {
    const tableHeader = this._addMarkers();
    return [
      tableHeader,
    ];
  }

  private _addMarkers() {
    const cell = this._copyMarkers();

    const row = new docx.TableRow({
      children: [ cell ],
    });

    const table = new docx.Table({
      layout: docx.TableLayoutType.FIXED,
      borders: this.getBordersNone(),
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: [ row ]
    });
    
    return table;
  }

  private _copyMarkers() {
    const sizePerc = 100;
    const markersAddrs = this._docState.headerMarkers.getMarkersAddrs();

    const markers = markersAddrs.map(markerAddr => {
      const repoItem = this._assetsRepo.headerMarkers.getItem(markerAddr);
      const {
        element,
        png: pngBase64,
      } = repoItem;

      if ( ! element ) {
        const msg = `Element for marker is missing, marker: ${markerAddr}`
        throw new Error(msg);
      }
      
      const bbox = element.getBoundingClientRect();
      const width  = bbox.width;
      const height = bbox.height;

      const marker = new docx.ImageRun({
        type: "png",
        data: pngBase64,
        transformation: { width, height },
      });

      return marker;
    });

    const padding = Tools.getPadding(HeaderMarkersExporter.PADDING);

    const docxCell = new docx.TableCell({
      margins: padding,
      borders: this.getBordersFull(),
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.CENTER,
        children: markers
      })]
    });

    return docxCell;
  }
}


export default HeaderMarkersExporter;