import React from 'react';
import { Frame } from './styles';
import { MainWrapper } from './styles';


interface Props {
  videoId: string;
}


export const YoutubeEmbeddedComponent: React.FC<Props> = (props: Props) => {
  const {
    videoId,
  } = props;

  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <MainWrapper>
      <Frame
        src={embedUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
        // title="YouTube Video"
      ></Frame>
    </MainWrapper>
  );
}