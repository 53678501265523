import useInfoState from "../../../hooks/use-info-state";


const useActionsState = () => {
  const info = useInfoState();
  const actions = info.actions;

  return actions;
}


export default useActionsState;