import React from 'react';
import { useRecoilValue } from 'recoil';

import { useDocState }        from 'app/ui/contexts/document';
import ContentSaveBasePlugin  from 'app/ui/components/editor-txt/plugins/content-save-plugin-base';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_HeaderRows } from 'app/ui/states/editor-instruction';

import { HeaderRowsTypes } from "app/arch/editor-instruction/document/states/persistent/header-rows";


interface Props {
  pageCellAddr: HeaderRowsTypes.PageCellAddr;
}


export const ContentSavePlugin: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr, 
  } = props;

  const document = useDocState();

  const { 
    setHeaderRows 
  } = useEditorStatesSetters();

  const editorState = useRecoilValue(
    UIState_HeaderRows.cellText_text(pageCellAddr)
  );

  const handleEditorStateInit = (editorStateUpdate: string) => {
    // Initial editor state should be set during cell
    // construction. If so, this method should not be
    // called.
    console.error("[ViewContent, CustomRows] Editor text state init called");
  }

  const handleEditorStateSave = (editorStateUpdate: string) => {
    document.headerRows.cellText_writeText(pageCellAddr, editorStateUpdate);
    document.saveUndo();
    setHeaderRows();
  }

  return (
    <ContentSaveBasePlugin
      editorTextState={editorState}
      onInitEditorTextState={handleEditorStateInit}
      onSaveEditorTextState={handleEditorStateSave}
    />
  );
}
