import styled from 'styled-components';
import Scrollbar from 'lego/styles/scrollbar';


const ContentColumn = styled(Scrollbar)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;


export default ContentColumn;