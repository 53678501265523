import React from 'react';
import { CellMenuProps } from '../types';
import TopToolbarCommonComponent from 'app/ui-v2/editor-instruction/hls/top-toolbar-common';


interface Props extends CellMenuProps {
}


export const CellIndexMenuComponent: React.FC<Props> = (props: Props) => {

  return (
    <TopToolbarCommonComponent />
  );
}
