import styled from "styled-components";
import TopBarBase from "app/ui-v2/home-page/styles/top-bar";
import FlexLogoWrapperBase from "app/ui-v2/home-page/styles/flex-logo-wrapper";


export const TopBar = styled(TopBarBase)`
`;

export const FlexLogoWrapper = styled(FlexLogoWrapperBase)`
/* background-color: red; */
`;

export const TopMenuWrapper = styled.div`
`;

export const Filler = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
`;
