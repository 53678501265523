import produce from 'immer';
import * as State from './state';


export class Instruction {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  //-----------------
  //
  // Setters

  setInstructionId(id: number) {
    this._state = produce(this._state, draft => {
      draft.id = id;
    });
  }

  setRepoId(repoId: number) {
    this._state = produce(this._state, draft => {
      draft.repoId = repoId;
    });
  }

  setRevision(revision: number) {
    this._state = produce(this._state, draft => {
      draft.revision = revision;
    });
  }


  //-----------------
  //
  // Getters
  
  getId() {
    return State.getId(this._state);
  }

  getRepoId() {
    return State.getRepoId(this._state);
  }

  getRevision() {
    return State.getRevision(this._state);
  }
}
