import { useRecoilValue } from 'recoil';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import useBaseWatcher from './use-base-watcher';


const useSectionsAddrsdWatcher = () => {
  const sectionsAddrs = useRecoilValue(UIState_Content.sectionsAddrs);
  useBaseWatcher([sectionsAddrs]);
}
  
export default useSectionsAddrsdWatcher;
