import React from 'react';
import { useState } from 'react';

import useFeatureFlag from 'app/ui-v2/app/__modules/feature-flag/hooks/use-feature-flag';
import useFeatureFlagFetch from 'app/ui-v2/app/__modules/feature-flag/hooks/use-feature-flag-fetch';

import { LoadersTools }        from '..';
import LoaderAuthBaseComponent from '../base/loader-auth-base';
import { LoaderCoreProps }     from '../base/loader-auth-base/types';


interface Props {
  children: React.ReactNode;
}


export const LoaderFeatureFlagComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  return (
    <LoaderAuthBaseComponent
      debugTitle={"feature flag"}
      LoaderCoreAuthComponent={LoaderCore}
      LoaderCoreUnauthComponent={LoaderCore}
    >
      { children }
    </LoaderAuthBaseComponent>
  );
}


/**
 * FeatureFlag
 */
const LoaderCore: React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const {
    children 
  } = props;

  const logger = LoadersTools.getLogger();
  const [ready, setReady] = useState(false);
  const featureFlag_ = useFeatureFlag();

  logger.debug(`Loader feature flag - ready: ${ready}`);
  
  useFeatureFlagFetch({
    onDone: (props: {
      featureFlag: any,
    }) => {
      const {
        featureFlag
      } = props;
      featureFlag_.loadFlags(featureFlag.flags);
      setReady(true);
    },
    onError: (error: string) => {
      const msg = `Can't download feature flag, error: ${error}`;
      console.log(msg);
      setReady(true);
    },
  });

  if ( ! ready ) {
    return null;
  }

  return children;
}
  