import * as Types from './types';
import domtoimage from 'dom-to-image';


class RepoBase<T> {
  private _repo: Types.Repo;

  constructor() {
    this._repo = {};
  }

  getKey(addr: T): string {
    throw new Error("[RepoBase::getKey] Implement me");
  };

  tag(): string {
    throw new Error("[RepoBase::tag] Implement me");
  };

  async generatePngs() {
    const keys = Object.keys(this._repo);

    const promises = keys.map(async key => {
      const item = this._repo[key];
      const png = await domtoimage.toPng(item.element, { cacheBust: true });
      return { key, png };
    });

    const results = await Promise.all(promises);
    results.forEach(result => {
      const {
        key,
        png,
      } = result;
      const item = this._repo[key];
      item.png = png;
    });
  }

  addElement(
    addr: T,
    element: HTMLDivElement,
  ) {
    const key = this.getKey(addr);

    if ( key in this._repo ) {
      const msg = `item already in repo`;
      throw new Error(msg);
    }

    const repoItem: Types.RepoItem = {
      element: element,
      png: null
    }

    this._repo[key] = repoItem;
  }

  removeElement(addr: T) {
    const key = this.getKey(addr);

    if ( key in this._repo === false ) {
      const msg = `Marker not in repo`;
      throw new Error(msg);
    }

    delete this._repo[key];
  }

  getItem(addr: T): Types.RepoItem {
    const key = this.getKey(addr);

    if ( ! (key in this._repo) ) {
      const msg = `Marker not in repo`;
      throw new Error(msg);
    }

    const repoItem = this._repo[key];
    return repoItem;
  }

  getElement(addr: T) {
    const item =  this.getItem(addr);
    return item.element;
  }

  getPng(addr: T) {
    const item =  this.getItem(addr);
    return item.png;
  }
}


export default RepoBase;