import { UUID } from "app/arch/types";
import { DNDContext } from "app/ui-v2/editor-instruction/dnd-context";

namespace Tools {

  export const getDNDContext = (releaseId: UUID) => {
    const context = (
      `${DNDContext.viewChangelog.table.column}`
      + `__rel:${releaseId}`
    );

    return context
  }
}

export default Tools;