import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { PanelHeader, SortDate, SortTitle } from './styles';
import * as Types from '../types';
import SortMarkComponent from './sort-mark';


interface Props {
  onSort: (sortType: Types.SortBy) => void;
  sortBy: Types.SortBy;
  sortOrder: Types.SortOrder;
}


export const InstructionListHeaderComponent: React.FC<Props> = (props: Props) => {
  const {
    onSort,
    sortBy,
    sortOrder,
  } = props;

  const t = useTranslations();

  const handleSortTitle = (event: React.MouseEvent) => {
    onSort(Types.SortBy.TITLE);
  }

  const handleSortDate = (event: React.MouseEvent) => {
    onSort(Types.SortBy.DATE_UPDATED);
  }

  return (
    <PanelHeader>
      <SortTitle 
        onClick={handleSortTitle}
      >
        { t("docs header, name") }
        {
          sortBy === Types.SortBy.TITLE &&
          <SortMarkComponent sortOrder={sortOrder} />
        }

      </SortTitle>
      <SortDate
        onClick={handleSortDate}
      >
        { t("docs header, date update") }
        {
          sortBy === Types.SortBy.DATE_UPDATED &&
          <SortMarkComponent sortOrder={sortOrder} />
        }
      </SortDate>
    </PanelHeader>
  );
}
