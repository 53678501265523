import React from 'react';
import { IconContext } from 'react-icons';
import { settings } from 'app/configs';


interface Props {
  children: React.ReactNode;
}


export const ThemeReactIconsComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  return (
    <IconContext.Provider 
      value={{ 
        size: `${settings.icons.menu.size}px`, 
        style: { 
          // margin: '0.5em' 
        }
      }}
    >
      { children }
    </IconContext.Provider>    
  );
}
  
