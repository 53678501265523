import { useEffect } from 'react';

import LineHorizontalComponent from '../line-horizontal';
import LineVerticalComponent from '../line-vertical';

import { linesStateReducer } from './state-routines';
import { useReducer } from 'react';
import { LineUpdate } from '../types';
import { LinesUpdate } from './types';


interface Props {
  scale: number;

  onUpdate: (update: LinesUpdate) => void;
  onUpdateDone: (update: LinesUpdate) => void;

  x1: number;
  x2: number;
  y1: number;
  y2: number;

  locked: boolean;
}


export const LinesComponent: React.FC<Props> = (props: Props) => {
  const { x1, x2, y1, y2, scale, locked } = props;
  const [linesState, linesStateDispatch] = useReducer(
    linesStateReducer,
    {x1, x2, y1, y2}
  );

  useEffect(() => {
    linesStateDispatch({
      type: 'reset',
      payload: {x1, x2, y1, y2}
    });

  }, [x1, x2, y1, y2]);


  const handleLineUpdate = (update: LineUpdate) => {
    linesStateDispatch({
      type: 'update',
      payload: update
    });

    props.onUpdate(linesState);
  }

  const handleLineUpdateDone = (update: LineUpdate) => {
    handleLineUpdate(update);
    props.onUpdateDone(linesState);
  }

  const handleOnPointerDown = (event: React.PointerEvent) => {
    event.stopPropagation();
  }
  
  return (
    <div
      onPointerDown={handleOnPointerDown}
    >
      <LineVerticalComponent
        scale={scale}
        locked={locked}

        x={linesState.x1}
        lineType={'x1'}

        onUpdate={handleLineUpdate}
        onUpdateDone={handleLineUpdateDone}
      />

      <LineVerticalComponent
        scale={scale}
        locked={locked}

        x={linesState.x2}
        lineType={'x2'}

        onUpdate={handleLineUpdate}
        onUpdateDone={handleLineUpdateDone}
      />
      
      <LineHorizontalComponent
        scale={scale}
        locked={locked}

        y={linesState.y1}
        lineType={'y1'}

        onUpdate={handleLineUpdate}
        onUpdateDone={handleLineUpdateDone}
      />

      <LineHorizontalComponent
        scale={scale}
        locked={locked}

        y={linesState.y2}
        lineType={'y2'}

        onUpdate={handleLineUpdate}
        onUpdateDone={handleLineUpdateDone}
      />
    </div>
  );
};
