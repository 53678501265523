import { useRecoilValue } from 'recoil';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';
import { UIState_ContentColumnsSelected } from 'app/ui/states/editor-instruction';
import { useDocState } from 'app/ui/contexts/document';


const useColumnSelected = () => {
  const selectedCellAddr = useRecoilValue(UIState_ContentSession.cellSelected);
  const columnsSelected = useRecoilValue(UIState_ContentColumnsSelected.areColumnsSelected);
  const docState = useDocState();

  if ( columnsSelected ) {
    const columns = docState.contentColumnsSelected.getSelectedOrdered();
    const columnKey = columns[0];
    const columnAddr = ContentTools.columnKeyToAddr(columnKey);
    return columnAddr;
  }

  if ( selectedCellAddr !== null) {
    return selectedCellAddr;
  }

  return null;
}


export default useColumnSelected;