import { ThemeDefProps } from "../types";


export const themeDefs_buttonText = (props: ThemeDefProps) => {
  const { colors, accentColor, padding } = props;

  const buttonText = {
    padding: padding.large,
  };
    
  return buttonText;
}

