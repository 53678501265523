import React from 'react';
import { useRouteError } from "react-router-dom";
import { MainWrapper } from './styles';


interface Props {
}


export const ViewErrorComponent: React.FC<Props> = (props: Props) => {
  const error = useRouteError() as any;

  if (error === undefined) {
    return (
      <MainWrapper>
        Error is undefined
      </MainWrapper>
    );
  }
  
  return (
    <MainWrapper>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
      <i>{error.statusText || error.message}</i>
      </p>
    </MainWrapper>
  );
}
