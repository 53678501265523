import { useEffect, useState } from 'react';

import { setup as setupModal         } from './use-stripe-subscription';
import { StripeSubscriptionComponent } from '../stripe-subscription/StripeSubscription';

import { MsgDuration } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { MsgLevel    } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useMsgBox   } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { StripeTypes } from '..';

import { Scroller }   from './styles';
import { Content }  from './styles';
import { Center } from './styles';


interface Props {
}

/*
  It has to be splited like that into two components
  as when it is in one component use hook useTranslation causes
  for some reason different hook order msg
*/

export const StripeSubscriptionModalComponent: React.FC<Props> = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const [product, setProduct] = useState<StripeTypes.Product | null>(null);
  
  useEffect(() => {
    setupModal(pay);
  }, []);

  const pay = (props: StripeTypes.Product) => {
    setVisible(true);
    setProduct(props);
  }

  const handleReset = () => {
    setVisible(false);
    setProduct(null);
  }

  if ( ! visible || ! product) {
    return null;
  }

  return (
    <VisibleComponent
      product={product}
      onReset={handleReset}
    />
  );
};



interface VisibleProps {
  product: StripeTypes.Product;
  onReset: () => void;
}

export const VisibleComponent: React.FC<VisibleProps> = (props: VisibleProps) => {
  const {
    product,
    onReset,

  } = props;

  const t = useTranslations();
  const { showMsgBox } = useMsgBox();


  const handlePaymentCancel = () => {
    onReset();
  }

  const handlePaymentError = () => {
    onReset();
    showMsgBox(t('payment error'), MsgLevel.ERROR, MsgDuration.MIDDLE);
  }

  const stopPropagation = (event: any) => {
    event.stopPropagation();
  }
  
  return (
    <Scroller onClick={handlePaymentCancel}>
      <Center>
        <Content onClick={stopPropagation}>
          <StripeSubscriptionComponent 
            onCancel={ handlePaymentCancel }
            onError={ handlePaymentError }
            product={product}
          />
        </Content>  
      </Center>
    </Scroller>
  );
};
