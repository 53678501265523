import React from 'react';
import { ExporterAssetsRepo } from 'app/arch/editor-instruction/exporters/excel-exporter';
import Context from './context';


interface Props {
  assetsRepo: ExporterAssetsRepo;
  children: React.ReactNode;
}


export const ExcelExportContextComponent: React.FC<Props> = (props: Props) => {
  const {
    assetsRepo,
    children,
  } = props;

  return (
    <Context.Provider value={{assetsRepo}} >
      { children }
    </Context.Provider>
  );
}

