import React from 'react';
import PriceBaseComponent from '../price-base';

import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';
import usePriceGet from 'app/ui-v2/app/__modules/products/hooks/use-price-get';
import usePricePlan    from '../../../hooks/use-price-plan';


interface Props {
}


export const PriceDailyComponent: React.FC<Props> = (props: Props) => {
  const pricePlan = usePricePlan();
  const getPrice = usePriceGet();

  const { plan, planType } = pricePlan;

  const priceDaily = getPrice(planType, PricePlansTypes.BillingPeriod.DAY);
  const priceYearly = priceDaily * 356;

  return (
    <PriceBaseComponent 
      free={planType === PricePlansTypes.PlanType.FREE}
      priceMonthly={priceDaily}
      priceYearly={priceYearly}
      billingPeriod={ 'Daily' }
    />
  );
}

