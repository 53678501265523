import React from 'react';
import Context from './context';
import * as Types from './types';


interface Props {
  setScale: Types.SetScale;
  children: React.ReactNode;
}


export const DocumentContextScaleSetComponent: React.FC<Props> = (props: Props) => {
  const {
    setScale,
    children,
  } = props;

  return (
    <Context.Provider value={setScale}>
      { children }
    </Context.Provider>
  );
}

