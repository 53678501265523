import styled from 'styled-components';
import { Size     } from 'app/arch/types';
import { WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";
import { zIndex }   from 'app/ui-v2/editor-image/zIndex';


export interface StyledFrameBaseProps {
  frameOutSize: number;
  ctrlCornerSize: Size;
  ctrlSideSize: Size;
  scale: number;

  elementStyle: WidgetsStylesTypes.StyleAttrs;
};



const getCtrlDeltaBase = (props: StyledFrameBaseProps, cornerSize: number) => {
  const delta = (
    cornerSize / props.scale + 
    4 / props.scale + // 4 is because of 
                      // 2 * ( #ctrl-border-width + #ctrl-outline-width )
    props.frameOutSize 
  );

  return delta;
}

const getCtrlDeltaWidth = (props: StyledFrameBaseProps) => {
  return getCtrlDeltaBase(props, props.ctrlCornerSize[0]);
}

const getCtrlDeltaHeight = (props: StyledFrameBaseProps) => {
  return getCtrlDeltaBase(props, props.ctrlCornerSize[1]);
}

export const StyledFrameBase = styled.div<StyledFrameBaseProps>`
/* background-color: rgba(0, 255, 0, 0.5); */
  position: absolute;
  pointer-events: none;
  box-sizing: border-box;
  
  ${
    props => `
      width:  calc(100% + ${props.frameOutSize}px);
      height: calc(100% + ${props.frameOutSize}px);
    
      left: ${-1 * props.frameOutSize / 2}px;
      top:  ${-1 * props.frameOutSize / 2}px;
    `
  }

  > div.ctrl {
    cursor: pointer;

    width:  ${props => props.ctrlCornerSize[0] * 2}px;
    height: ${props => props.ctrlCornerSize[1] * 2}px;

    transform: scale( ${props => 1 / props.scale} );
    transform-origin: 
      ${props => props.ctrlCornerSize[0]}px 
      ${props => props.ctrlCornerSize[0]}px
    ;

    user-select: none;
    cursor: pointer;
    box-sizing: border-box;

    outline-style: none;
    
    border-style: solid;
    border-width:  ${props => props.theme.imageEditor.widget.ctrl.border.width};
    border-color:  ${props => props.theme.imageEditor.widget.ctrl.border.color.primary};
    border-radius: ${props => props.theme.imageEditor.widget.ctrl.border.radius};
    background:    ${props => props.theme.imageEditor.widget.ctrl.background.primary};

    transition: all ${props => props.theme.defs.transition.time.normal} ease-in-out;

    &:disabled {
      cursor: not-allowed;
      opacity: ${props => props.theme.defs.opacity.disabled};
    }

    &:active:not(:disabled) {
      background:    ${props => props.theme.imageEditor.widget.ctrl.background.active};
      /* outline-color: ${props => props.theme.defs.colors.border.primary}; */
    }

    &:hover {
      background:    ${props => props.theme.imageEditor.widget.ctrl.background.hover};
      border-color:  ${props => props.theme.imageEditor.widget.ctrl.border.color.hover};
    }
  }

  /**
  Corners controls 
  **/

  > div.ctrlCorner {
    position: absolute;
    z-index: ${zIndex.widgetControls};
  }

  > div.ctrlTopLeftWrapper {
    ${
      props => `
        left: -${getCtrlDeltaWidth(props)}px;
        top: -${getCtrlDeltaHeight(props)}px;
    `
    }
  }

  > div.ctrlTopRightWrapper {
    ${
      props => `
        right: -${getCtrlDeltaWidth(props)}px;
        top: -${getCtrlDeltaHeight(props)}px;
      `
    }
  }

  > div.ctrlBottomLeftWrapper {
    ${
      props => `
        left: -${getCtrlDeltaWidth(props)}px;
        bottom: -${getCtrlDeltaHeight(props)}px;
      `
    }    
  }

  > div.ctrlBottomRightWrapper {
    ${
      props => `
        right: -${getCtrlDeltaWidth(props)}px;
        bottom: -${getCtrlDeltaHeight(props)}px;
      `
    }    
  }

  /**
  Horizontal controls 
  **/

  > div.ctrlHorizontal {
    position: absolute;
    z-index: ${zIndex.widgetControls};

    ${
      props => `
      `
    }    
  }

  > div.ctrlTopHorizontalWrapper {
    ${
      props => `
        top: -${getCtrlDeltaHeight(props)}px;
        left: calc(50% - ${props.ctrlCornerSize[0]}px);
      `
    }      
  }

  > div.ctrlBottomHorizontalWrapper {
    ${
      props => `
        bottom: -${getCtrlDeltaHeight(props)}px;
        left: calc(50% - ${props.ctrlCornerSize[0]}px);
      `
    }  
  }


 /**
  Vertical controls 
  **/

  > div.ctrlVertical {
    position: absolute;
    z-index: ${zIndex.widgetControls};

    ${
      props => `
        top: calc(50% - ${props.ctrlCornerSize[1]}px);
      `
    }    
  }

  > div.ctrlLeftVerticalWrapper {
    ${
      props => `
        left: -${getCtrlDeltaWidth(props)}px;
      `
    }      
  }

  > div.ctrlRightVerticalWrapper {
    ${
      props => `
      right: -${getCtrlDeltaWidth(props)}px;
      `
    }       
  }
`;

export const FRAME_OUT_SIZE = 8;

StyledFrameBase.defaultProps = {
  frameOutSize: FRAME_OUT_SIZE,
  // frameOutSize: 0,
  ctrlCornerSize: [10, 10],
  ctrlSideSize: [10, 10],
}