import styled from 'styled-components';

export const DRAGGER_SIZE = 6;

export const DotWrapper = styled.div`
  position: absolute;
  cursor: resize;
`;

const BORDER_WIDTH  = 1;
const OUTLINE_WIDTH = 6;

export const StyledDot = styled.div`
  width:  ${DRAGGER_SIZE}px;
  height: ${DRAGGER_SIZE}px;

  box-sizing: border-box;
  background-color: transparent;
  border-radius: 50%;
  
  border-style: solid;
  border-width: ${BORDER_WIDTH}px;
  border-color: ${props => props.theme.defs.colors.border.primary};

  outline-style: solid;
  outline-width: ${OUTLINE_WIDTH}px;
  /* outline-color: ${props => props.theme.defs.accentColor.primary}; */
  outline-color: ${props => props.theme.defs.colors.background.third};

  transition: all ${props => props.theme.defs.transition.time.normal} ease-in-out;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 1;
    outline-color: ${props => props.theme.defs.accentColor.primary};
    /* outline-color: ${props => props.theme.defs.accentColor.dark}; */
  }

  &:before {
    content: '';
    position: absolute;
    top:    -${OUTLINE_WIDTH}px;
    left:   -${OUTLINE_WIDTH}px;
    right:  -${OUTLINE_WIDTH}px;
    bottom: -${OUTLINE_WIDTH}px;

    border-radius: 50%;
    border-style: solid;
    border-width: ${BORDER_WIDTH}px;
    border-color: ${props => props.theme.defs.colors.border.primary};
  }
`;
