import * as Types from './types';
import * as Tools from './tools';
import * as Defaults from './defaults';


/**
 * State
 */
export type State = {
  table: Types.Table,
  fields: Types.Fields,
};


/**
 * Table
 */
export const getTable = (state: State) => state.table;


/**
 * Fields
 */
export const getFields = (state: State) => state.fields;


/**
 * Columns
 */
export const __getColumns = (state: State): Types.Columns => { 
  return state.fields.columns;
}

export const getColumnsAddrs = (state: State): Types.ColumnsAddrs => { 
  const columns = __getColumns(state);
  return columns.addrs;
}

export const getColumnsProps = (state: State): Types.ColumnsProps => { 
  const columns = __getColumns(state);
  return columns.props;
}


/**
 * Column
 */
export const getColumnProps = (
  state: State, columnAddr: 
  Types.ColumnAddr
): Types.ColumnProps => { 
  const columnsProps = getColumnsProps(state);
  const columnKey    = Tools.getColumnKey(columnAddr);
  const columnProps  = columnsProps[columnKey];

  if (columnsProps === undefined) {
    const msg = `Column props not found`;
    throw new Error(msg);
  }

  return columnProps;
}

export const getColumnWidth = (
  state: State, 
  columnAddr: Types.ColumnAddr
): number => { 
  const columnProps = getColumnProps(state, columnAddr);
  return columnProps.width;
}

export const getColumnType = (
  state: State, 
  columnAddr: Types.ColumnAddr
): Types.ColumnType => { 
  const columnProps = getColumnProps(state, columnAddr);
  return columnProps.type;
}

export const getColumnIdx = (state: State, columnAddr: Types.ColumnAddr): number => {
  const columnsAddrs = getColumnsAddrs(state);
  
  const idx = columnsAddrs.findIndex((columnAddr_) => Tools.compareColumnAddr(columnAddr_, columnAddr));
  if ( idx === -1 ) {
    const msg = 'Column not found';
    throw new Error(msg);
  }

  return idx;
}


/**
 * Cells
 */
export const getCells = (
  state: State, 
): Types.Cells => {
  const fields = getFields(state);
  return fields.cells;
}

export const getCell = (
  state: State, 
  cellAddr: Types.CellAddr
): Types.CellTypes => {
  const cells   = getCells(state);
  const cellKey = Tools.getCellKey(cellAddr);
  
  const cell = cells[cellKey];
  if (cell === undefined) {
    const msg = (
      `Headercell not found, cell addr: \n`+
      `  columnId: ${cellAddr.columnId}`
    );
    throw new Error(msg);
  }

  return cell;
}


/**
 * Initial State
 */
export const createInitialState = (): State => {
  const state: State = {
    table: Defaults.getTable(),
    fields: Defaults.getFields(),
  }

  return state;
}
