import useViewSelected from "app/ui-v2/home-page/home-page-user/hooks/use-view-selected";
import useNavbarItemSelected from "app/ui-v2/home-page/home-page-user/hooks/use-navbar-item-selected";


const useStates = () => {
  const viewSelected = useViewSelected();
  const navbarItemSelected = useNavbarItemSelected();

  return {
    viewSelected,
    navbarItemSelected,
  }
}

export default useStates;
