import React from 'react';
import useSmartLinesWidgetsStickyLines from '../../../../hooks/smart-lines/use-smart-lines-widgets-sticky-lines';
import { SmartLineRaw  } from 'tools/smart-lines/types';
import { StickyLineRaw } from 'tools/smart-lines/types';
import { SmartLine     } from 'tools/smart-lines/smart-line';
import { StyleStickyLine } from './styles';


interface Props {
  srcLine: SmartLineRaw;
  dstLine: SmartLineRaw;
  
}

export const StickyLinesComponent: React.FC<Props> = (props: Props) => {
  const stickyLines = useSmartLinesWidgetsStickyLines();

  if (!stickyLines) {
    return null;
  }

  const getOriented = (stickyLine: StickyLineRaw) => {
    const srcLine = stickyLine.srcLine;
    const dstLine = stickyLine.dstLine;
  
    let x0 = 0;
    let x1 = 0;
    let y0 = 0;
    let y1 = 0;

    if (srcLine.y0 === srcLine.y1) {
      // horizontal
      x0 = Math.min(srcLine.x0, srcLine.x1, dstLine.x0, dstLine.x1); 
      x1 = Math.max(srcLine.x0, srcLine.x1, dstLine.x0, dstLine.x1); 
      y0 = dstLine.y0; 
      y1 = dstLine.y0; 
    }
    else {
      // vertical
      x0 = dstLine.x0; 
      x1 = dstLine.x0; 
      y0 = Math.min(srcLine.y0, srcLine.y1, dstLine.y0, dstLine.y1); 
      y1 = Math.max(srcLine.y0, srcLine.y1, dstLine.y0, dstLine.y1); 
    }

    return {x0, y0, x1, y1};
  }

  
  const renderOriented = (stickyLine: StickyLineRaw ) => {
    const line = getOriented(stickyLine);

    let style: any = {
      left:   `${line.x0}px`,
      top:    `${line.y0}px`,
    };

    if (SmartLine.IsHorizontal(line)) {
      const width = Math.abs(line.x0 - line.x1);
      style = {
        ...style,
        width: `${width}px`
      }
    }
    else {
      const height = Math.abs(line.y0 - line.y1);
      style = {
        ...style,
        height: `${height}px`
      }
    }

    return (
      <StyleStickyLine 
        style={style}
      />
    );
  }


  return (
    <>
    { stickyLines.horizontal &&  renderOriented(stickyLines.horizontal) }
    { stickyLines.vertical   &&  renderOriented(stickyLines.vertical)   }
    </>
  )
}

