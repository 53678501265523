import { settings } from 'app/configs/settings';
import { useAuth } from "./use-auth";


export const useIsGuestUser = () => {
  const auth = useAuth();

  const isGuestUser = () => {
    return auth.getUsername() === settings.demo.user.email;
  }

  return isGuestUser;
}
