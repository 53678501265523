import React from 'react';
import { useRecoilValue } from 'recoil';
import i18next from 'i18next';
import jtl from 'tools/jtl';

import SelectComponent      from 'lego/components/select';
import { SelectConfigType } from 'lego/components/select/config';
import PanelItem            from 'lego/styles/panel_item';
import PanelItemHeader      from 'lego/styles/panel_item_header';

import { Language      } from 'app/arch/types';
import { LanguageCodes } from 'app/arch/types';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { UIUser_Settings } from 'app/ui-v2/app/__modules/user/states';
import useUserSettings     from 'app/ui-v2/app/__modules/user/hooks/use-user-settings';
import useUserSettingsSave from 'app/ui-v2/app/__modules/user/hooks/use-user-settings-save';
import useUserStateSetters from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';



interface Props {
  closeRef: any;
}


export const UserPreferredLanguageComponent: React.FC<Props> = (props: Props) => {
  const { closeRef } = props;

  const t = useTranslations();
  const userSettings = useUserSettings();
  const saveUserSettings = useUserSettingsSave();

  const userLanguage = useRecoilValue(UIUser_Settings.userLanguage);

  const {
    setUserSettings
  } = useUserStateSetters();

  const langs = Object.keys(Language);
  const langsSorted = langs.sort();

  const options = langsSorted.map(element => {
    return {
      selected: userLanguage === element.toLowerCase(),
      value: jtl.string.capitalizeFirstLetter(element),
      valueText: jtl.string.capitalizeFirstLetter(element),
    };
  });


  const selectConfig: SelectConfigType = {
    onSelected: (value: string) => {
      const lang = value.toLowerCase() as Language;
      userSettings.updateUser({language: lang});
      setUserSettings();
      saveUserSettings();

      const langCode = LanguageCodes[lang];
      i18next.changeLanguage(langCode);
    },
    options: options
  }

  return (
    <PanelItem>
      <PanelItemHeader>
        { t('user language') }
      </PanelItemHeader>

      <SelectComponent 
        config={selectConfig}
        closeRef={closeRef}
      />

    </PanelItem>
  );
}
  


