import { useState }   from 'react';
import { useEffect }  from 'react';
import { useRecoilValue }    from 'recoil';

import jtl from 'tools/jtl';
import MaskedAreaComponent from 'lego-v2/masked-area';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { EditorImageSessionTypes } from 'app/arch/editor-instruction/document/states/sessions/editor-image-session';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { UIState_Content } from 'app/ui/states/editor-instruction';
import { useDocState }         from 'app/ui/contexts/document';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters  from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import CornersDotsComponent  from './corners-dots';
import { CornersDotsUpdate } from './corners-dots/types';
import LinesComponent        from './lines/lines';
import { LinesUpdate }       from './lines/lines/types';

import { MainWrapper } from './styles';


export const MIN_IMG_WIDTH  = 20;
export const MIN_IMG_HEIGHT = 20;


interface Props {
  imageAddr: Types.ImageAddr;
  scale: number;
}


export const ImageViewDefinerComponent: React.FC<Props> = (props: Props) => {
  const autoFit = useRecoilValue(UIState_EditorImageSession.viewDefinerAutoFit);
  const isAutoFit = ( autoFit.autoMode === EditorImageSessionTypes.ViewDefinerAutomationMode.AUTOMATIC);

  if (isAutoFit) {
    return null;
  }

  return <ImageViewDefinerManualComponent {...props} />
}


export const ImageViewDefinerManualComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    scale,
  } = props;

  const document = useDocState();
  
  const {
    setContent
  } = useEditorStatesSetters();

  const viewType = useRecoilValue(UIState_EditorImageSession.viewDefinerOverlayerShowType);
  const viewResizerLocked = useRecoilValue(UIState_EditorImageSession.viewDefinerOverlayerResizerLocked);

  const viewAreaGlobal = useRecoilValue(UIState_Content.cellImages_image_viewArea(imageAddr));

  const [viewAreaLocal, setViewAreaLocal] = useState<ContentTypes.ImageViewArea>(viewAreaGlobal);

  useEffect(() => {
    if (viewAreaGlobal !== viewAreaLocal) {
      setViewAreaLocal(viewAreaGlobal);
    }
  }, [viewAreaGlobal]);

  const applyGrid = (update: CornersDotsUpdate) => {
    const grid = document.editorImageSettings.getGrid();

    // If grid is disabled apply it anyway - just 
    // with value 1, just round up floats
    const gridSize = (
      grid.enabled ? 
      grid.size :
      1
    );

    const [x1, y1] = jtl.grid.applyToSize(
      update.x1,
      update.y1, 
      gridSize,
      gridSize);

    const [x2, y2] = jtl.grid.applyToSize(
      update.x2,
      update.y2, 
      gridSize, 
      gridSize);

    const gridedUpdate = { x1, y1, x2, y2 };
    return gridedUpdate;
  }

  const updateGlobalState = (viewAreaUpdate: LinesUpdate | CornersDotsUpdate ) => {
    const imageUpdate = { viewArea: viewAreaUpdate };
    document.content.cellImages_updateImage(
      imageAddr,
      imageUpdate
    );

    document.saveUndo();
    setContent();
  }


  const handleLinesUpdate = (viewAreaUpdate: LinesUpdate) => {
    setViewAreaLocal(viewAreaUpdate);
  }

  const handleLinesUpdateDone = (viewAreaUpdate: LinesUpdate) => {
    handleLinesUpdate(viewAreaUpdate);
    updateGlobalState(viewAreaUpdate);
  }

  const handleCornerDotsUpdate = (viewAreaUpdate: CornersDotsUpdate) => {
    setViewAreaLocal(viewAreaUpdate);
  }
  
  const handleCornerDotsUpdateDone = (viewAreaUpdate: CornersDotsUpdate) => {
    handleCornerDotsUpdate(viewAreaUpdate);
    updateGlobalState(viewAreaUpdate);
  }

  
  //--------------------------
  // Render
  //

  return (
    <MainWrapper>
      {
        viewType === EditorImageSessionTypes.ViewDefinerShowType.LINES_AND_OVERLAYERS &&
        <MaskedAreaComponent
          x1={viewAreaLocal.x1}
          x2={viewAreaLocal.x2}
          y1={viewAreaLocal.y1}
          y2={viewAreaLocal.y2}
        />
      }

      {
        viewType !== EditorImageSessionTypes.ViewDefinerShowType.HIDDEN && 
        <LinesComponent
          scale={scale}
          locked={viewResizerLocked}

          onUpdate={handleLinesUpdate}
          onUpdateDone={handleLinesUpdateDone}

          x1={viewAreaLocal.x1}
          x2={viewAreaLocal.x2}
          y1={viewAreaLocal.y1}
          y2={viewAreaLocal.y2}
        />
      }

      {
        viewType !== EditorImageSessionTypes.ViewDefinerShowType.HIDDEN && 
        <CornersDotsComponent
          scale={scale}
          locked={viewResizerLocked}

          onUpdate={handleCornerDotsUpdate}
          onUpdateDone={handleCornerDotsUpdateDone}

          x1={viewAreaLocal.x1}
          x2={viewAreaLocal.x2}
          y1={viewAreaLocal.y1}
          y2={viewAreaLocal.y2}
        />
      }
    </MainWrapper>
  );

}
