import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';

import DeskMechanicsComponent from './desk-mechanics';
import { MainWrapper } from './styles';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
  children: React.ReactNode;
};


export const EditorImageDeskComponent: React.FC<Props> = (props: Props) => {
  const { 
    imageAddr,
    children,
  } = props;

  const docState = useDocState();
  const scale = docState.editorImageSession.getScale();

  return (
    <MainWrapper>
      <DeskMechanicsComponent
        scale={scale}
        imageAddr={imageAddr}
      >
        { children }
      </DeskMechanicsComponent>
    </MainWrapper>
  );
};
