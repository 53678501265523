import React from 'react';

import IntroMovieComponent from './intro-movie/index';
import RunDemoComponent from './run-demo/index';

import PromoTextComponent from './promo-text';
import TitleAndLogoComponent from './title-and-logo';

import { MainWrapper } from './styles';


interface Props {
}


export const ViewRunDemoComponent: React.FC<Props> = (props: Props) => {

  return (
    <MainWrapper>  
      <TitleAndLogoComponent />
      <PromoTextComponent />
      <RunDemoComponent />
      <IntroMovieComponent />
    </MainWrapper>
  );
}
