import React from 'react';
import { IconContext } from 'react-icons';
import { useRef } from 'react';

import Icon from 'app/ui/icons/icons';
import NavbarPanelComponent from '../navbar-panel';
import NavbarItemContextComponent from '../navbar-item-context';
import useNavbarContext from '../../hooks/use-navbar-context';

import * as Types from '../../types';

import { GridRow1 } from './styles';
import { GridRow2 } from './styles';
import { PanelDetacher } from './styles';
import { Item } from './styles';
import { Text } from './styles';
import { IconWrapper } from './styles';


export interface Props extends Types.NavbarItemProps {
}


export const NavbarItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    col1,
    col2,
    row1,
    row2,
    config, 
    open, 
  } = props

  const cancelNextBlurRef = useRef<boolean>(false);
  const IconExpand = Icon.Expand.More;

  const {
    closeItem,
    toggleOpenItem,
  } = useNavbarContext();
  
  const handleItemClick = (event: React.MouseEvent) => {
    config.onClick?.(event);
    toggleOpenItem();
  };

  const handleBlur = () => {
    if (cancelNextBlurRef.current) {
      cancelNextBlurRef.current = false;
      return;
    }

    closeItem();
  }

  const cancelBlur = () => {
    cancelNextBlurRef.current = true;
  }

  return (
    <>
      <GridRow1 
        column={col1}
        row={row1}
      >
        <Item
          data-test={config.dataTest}

          onClick={handleItemClick}

          // To force div being focusable
          tabIndex={0}
          onBlur={handleBlur}

          open={open}
          selected={config.selected}
        >
          <Text>
          { config.text } 
          </Text>
          {
            config.panel &&
            <IconWrapper>
              <IconContext.Provider value={{ size: '10px' }} >
                <IconExpand />
              </IconContext.Provider>    
            </IconWrapper>
          }
        </Item>
      </GridRow1>

      { 
        open &&
        config.panel &&
        <GridRow2 
          column={col2} 
          row={row2}
        >
          <PanelDetacher>
            <NavbarItemContextComponent cancelBlur={cancelBlur}>
              <NavbarPanelComponent config={config.panel}/>
            </NavbarItemContextComponent>
          </PanelDetacher>
        </GridRow2>
      }
    </>
  );
}