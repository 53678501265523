import { MenuItemType } from 'lego-v2/menu';

import { Icon }            from "app/ui/icons/icons";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import useFunctions from './__use-functions';



const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();
  const {
    hideMenu
  } = useContextMenu();


  const rowAddAbove = (rowAddr: HeaderRowsTypes.PageRowAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('row, add above'),
      iconComponent: Icon.Create,
      onClick: (event: React.PointerEvent) => {
        fns.rowAddAbove(rowAddr);
        hideMenu();
      }
    }
  });

  const rowAddBelow = (rowAddr: HeaderRowsTypes.PageRowAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('row, add below'),
      iconComponent: Icon.Create,
      onClick: (event: React.PointerEvent) => {
        fns.rowAddBelow(rowAddr);
        hideMenu();
      }
    }
  });

  const rowDelete = (rowAddr: HeaderRowsTypes.RowAddr) => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('delete'),
      variant: 'delete',
      iconComponent: Icon.Delete,
      onClick: (event: React.PointerEvent) => {
        fns.rowDelete(rowAddr);
        hideMenu();
      }
    }
  });

  return {
    rowAddAbove,
    rowAddBelow,
    rowDelete,
  }
}

export default useItems;