import styled from "styled-components";
import TextWrap from "lego/styles/text-wrap";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.small};

  /* height: 40px; */
  /* background: rgba(0, 255, 0, 0.2); */
  width: 100%;
  overflow: clip;

`;


//-----------------------
// Name 

export const NameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  width: 48px;
  /* background: rgba(255, 0, 0, 0.2); */
`;

export const Name = styled(TextWrap)`
  font-size:   ${props => props.theme.defs.font.size.normal};
  font-weight: ${props => props.theme.defs.font.weight.xsmall};
  /* background: rgba(0, 0, 255, 0.2); */
`;



//-----------------------
// Value

export const ValueWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  flex-grow: 1;
  width: 0px;
  /* background: rgba(255, 0, 0, 0.2);   */
`;


export const Value = styled(TextWrap)`
  font-size:   ${props => props.theme.defs.font.size.large};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;
