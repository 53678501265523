import React from 'react';

import { PanelControlsItemConfig as Config} from '../../../config';
import PanelItemComponentBase from '../../base/panel-item';
import PanelSectionComponent from '../panel-section';


interface Props {
  config: Config;
}


export const PanelItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    config 
  } = props;

  return (
    <PanelItemComponentBase
      config={config}
      Section={PanelSectionComponent}
    />
  );
}
  
