import styled from 'styled-components';


export const MainWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background: red; */
`;
