import React from 'react';
import { useEffect } from 'react';
import { useIconsRepo } from 'app/ui-v2/editor-instruction/hooks/use-icons-repo';


interface Props {
  onLoaded: () => void;
}


export const IconsRepoLoaderComponent: React.FC<Props> = (props: Props) => {
  const {
    onLoaded
  } = props;

  const IconsRepo = useIconsRepo();

  useEffect(() => {
    IconsRepo.setupIcons()
      .then(() => {
        onLoaded();
      })
      .catch((error) => {
        console.error('Error during setup:', error);
      })
      .finally(() => {
        // console.log('Cleanup can be done here, regardless of success or failure.');
      });
  }, [])

  return null;
}

