import styled from "styled-components";
import LogoWrapperBase from "../styles/logo-wrapper";
import TextWrap from "lego/styles/text-wrap";


export const LogoWrapper = styled(LogoWrapperBase)`
  color: black;
  user-select: none;
`;

export const MainText = styled(TextWrap)`
  width: inherit;
  text-align: center;
  opacity:     ${props => props.theme.defs.opacity.fadeMore};
  font-size:   ${props => props.theme.defs.font.size.large};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;
