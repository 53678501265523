import styled from "styled-components";

import DraggerBase from "lego-v2/dragger/ui/styles";
import { StyledFrameBase } from "lego-v2/frame-resize/ui/style-frame-ctrls-whole";

import Window       from "lego/styles/window";
import WindowBorder from "lego/styles/window-border";
import WindowTitle  from "lego/styles/window-title";
import WindowTopbar from "lego/styles/window-topbar";


const TOP_BAR_HEIGHT = 21;


export const MainWrapper = styled.div`
  position: fixed;
  box-shadow: ${props => props.theme.window.boxShadow};
`;


export const ZIndexBarrier = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
`;

export const StyleWindowBorder = styled(WindowBorder)`
`;

export const StyledWindow = styled(Window)`
`;

export const StyledDragger = styled(DraggerBase)`
  position: relative;
  width: 100%;
`;

export const Content = styled.div`
  height: calc(100% - ${TOP_BAR_HEIGHT}px);
`;

export const Topbar = styled(WindowTopbar)`
  position: relative;
  height: ${TOP_BAR_HEIGHT}px;
`;

export const Title = styled(WindowTitle)`
`;

export const StyledWindowFrame = styled(StyledFrameBase)`
`;

