import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { BtnText }     from './styles';
import { BtnCancel }   from './styles';
import { BtnsWrapper } from './styles';


interface Props {
  disabled: boolean;
  onPay: () => void;
  onCancel: () => void;
}


export const CheckoutControlsComponent: React.FC<Props> = (props: Props) => {
  const {
    disabled,
    onPay,
    onCancel,
  } = props;

  const t = useTranslations();

  const handleCancel = (event: React.MouseEvent) => {
    event.stopPropagation();
    onCancel();
  }

  const handlePay = (event: React.MouseEvent) => {
    event.stopPropagation();
    onPay();
  }

  
  return (
    <BtnsWrapper>
      <BtnCancel 
        type={'button'}
        disabled={disabled}
        onClick={handleCancel}
      >
        { t('cancel') }
      </BtnCancel>

      <BtnText 
        type={'button'}
        disabled={disabled}
        onClick={handlePay}
      >
        { t('pay') }
      </BtnText>
    </BtnsWrapper>
  );
}

