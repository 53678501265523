import { MarkerAddr } from "app/arch/editor-instruction/document/states/persistent/repo-markers/types";
import { RepoMarkersTools } from "app/arch/editor-instruction/document/states/persistent/repo-markers";
import { RepoMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/repo-markers";
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import useStates from "./use-states";


const useFunctions = (markerAddr: MarkerAddr) => {
  const document = useDocState();
  const states = useStates(markerAddr);

  const {
    setRepoMarkers
  } = useEditorStatesSetters();

  const updateMarkerGroup = (markerGroupUpdate: RepoMarkersTypes.MarkerGroup) => {
    const markerGroup = RepoMarkersTools.getMarkerGroup(states.markerProps.type);
    if (markerGroup === markerGroupUpdate) {
      return;
    }

    const groupDefaultTypeMap: {[key in RepoMarkersTypes.MarkerGroup]: RepoMarkersTypes.MarkerType} = {
      [RepoMarkersTypes.MarkerGroup.ICON ]: RepoMarkersTypes.MarkerType.ICON,
      [RepoMarkersTypes.MarkerGroup.SIGN ]: RepoMarkersTypes.MarkerType.SIGN_INFO,
      [RepoMarkersTypes.MarkerGroup.LABEL]: RepoMarkersTypes.MarkerType.LABEL,
    }

    const markerType = groupDefaultTypeMap[markerGroupUpdate];
    const update = { type: markerType };

    document.repoMarkers.updateMarker(markerAddr, update)
    document.saveUndo();
    setRepoMarkers();
  }


  const updateMarkerType = (markerType: RepoMarkersTypes.MarkerType) => {
    if (states.markerProps.type === markerType) {
      return;
    }

    const update = { type: markerType };
    document.repoMarkers.updateMarker(markerAddr, update)
    document.saveUndo();
    setRepoMarkers();
  }


  const updateLabel = (props: {
    update: RepoMarkersTypes.LabelPropsUpdate,
    skipUndoSave?: boolean,
  }) => {
    const {
      update,
      skipUndoSave
    } = props;
    
    document.repoMarkers.updateMarkerLabel(markerAddr, update);
    if ( ! skipUndoSave ) {
      document.saveUndo();
    }
    setRepoMarkers();
  }

  const updateLabelCSS = (props: {
    cssUpdate: React.CSSProperties,
    skipUndoSave?: boolean,
  }) => {
    const {
      cssUpdate,
      skipUndoSave
    } = props;
    
    document.repoMarkers.updateMarkerLabelCSS(markerAddr, cssUpdate);
    if ( ! skipUndoSave ) {
      document.saveUndo();
    }
    setRepoMarkers();
  }


  return {
    updateMarkerGroup,
    updateMarkerType,
    
    updateLabel,
    updateLabelCSS
  }
}


export default useFunctions;
