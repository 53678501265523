import produce from 'immer';

import * as Tools from './tools';
import * as Types from './types';
import * as State from './state';


export class Prices {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  reset() {
    this._state = State.createInitialState();
  }

  get raw() { return this._state; }


  /**
   * Setters
   */
  loadPrices(pricesRaw: Types.PricesRaw) {
    this.reset();

    this._state = produce(this._state, draft => {
      pricesRaw.forEach((pricesRaw) => this.__addPrice(draft, pricesRaw));
      draft.loaded = true;

      const priceFree: Types.PriceRaw = {
        active: true,
        lookupKey: 'free',
        currency: 'usd',
        amount: 0
      }
      this.__addPrice(draft, priceFree);
    });
  }

  private __addPrice(
    draft: State.State,
    priceRaw: Types.PriceRaw
  ) {
    const pricesKeys  = State.getPricesKeys(draft);
    const pricesProps = State.getPricesProps(draft);

    const {
      lookupKey,
      ...priceProps
    } = priceRaw;
    
    pricesKeys.push(lookupKey);
    pricesProps[lookupKey] = priceProps;
  }


  /**
   * Getters
   */

  getLoaded(): boolean {
    return State.getLoaded(this._state);
  }

  getPricesKeys(): Types.PricesKeys {
    return State.getPricesKeys(this._state);
  }

  getPricesProps(): Types.PricesProps {
    return State.getPricesProps(this._state);
  }

  getPriceProps(priceKey: Types.PriceKey): Types.PriceProps {
    return State.getPriceProps(this._state, priceKey);
  }

  getPriceAmount(priceKey: Types.PriceKey): number {
    return State.getPriceAmount(this._state, priceKey);
  }
  
}
