import { useRecoilValue } from 'recoil';

import ButtonComponent from 'lego/components/buttons/button';

import environment from 'app/environment';
import { EditorSessionTypes } from 'app/arch/editor-instruction/document/states/sessions/editor-session';
import { useDocState }     from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { Icon }            from 'app/ui/icons/icons';
import { UIState_EditorSession } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDemoActive from 'app/ui-v2/app/__modules/demo/hooks/use-demo-active';

import { MainWrapper } from './styles';
import { Button } from './styles';


interface BtnDef {
  view: EditorSessionTypes.ViewType;
  tooltip: string;
  Icon: any;
  dataTest: string;
}

interface Props {
}


export const MenuViewComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const document = useDocState();
  const isDemoActive = useDemoActive();

  const {
    setEditorSession
  } = useEditorStatesSetters();

  const viewType = useRecoilValue(UIState_EditorSession.viewType);

  const handleSetView = (view: EditorSessionTypes.ViewType) => {
    document.editorSession.setViewType(view);
    setEditorSession();
  }

  const viewSandboxDef: BtnDef = {
    view: EditorSessionTypes.ViewType.SANDBOX,
    tooltip: 'Sandbox',
    Icon: Icon.Dev,
    dataTest: 'editor-doc__view-menu__sandbox',
  }

  const viewContentDef: BtnDef = {
    view: EditorSessionTypes.ViewType.CONTENT,
    tooltip: t('editor view - content'),
    Icon: Icon.Grid,
    dataTest: 'editor-doc__view-menu__content',
  }

  const viewChangeLogDef: BtnDef = {
    view: EditorSessionTypes.ViewType.CHANGELOG,
    tooltip: t('editor view - changelog'),
    Icon: Icon.Changelog,
    dataTest: 'editor-doc__view-menu__releaselog',
  }

  const renderBtn = (btnDef: BtnDef) => {
    const Icon = btnDef.Icon;

    return (
      <ButtonComponent
        Button={
          <Button
            data-test={btnDef.dataTest}
            $selected={viewType === btnDef.view}
            onClick={ () => { handleSetView(btnDef.view); }}
          >
            <Icon />
          </Button>
        }
        tooltip={btnDef.tooltip}
      />
    );
  }

  return (
    <MainWrapper>
      { 
        environment.dev &&
        ! isDemoActive &&
        renderBtn(viewSandboxDef) 
      }
      { renderBtn(viewContentDef) }
      { renderBtn(viewChangeLogDef) }
    </MainWrapper>
  );
}
