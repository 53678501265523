import { useRef } from 'react';
import useKeyBindingsBase     from 'lego/components/key-bindings/hooks/use-key-bindings';
import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';
import { KeyBinding   } from 'lego/components/key-bindings/arch/types';

import { UUID } from 'app/arch/types';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import useFunctions from './__use-functions';


const useKeyBindings = (cellAddr: Types.CellAddr) => {
  const fns = useFunctions(cellAddr);
  const bindingIdsRef = useRef<UUID[]>([]);

  const {
    addKeyDownBinding,
    removeKeyDownBinding,
  } = useKeyBindingsBase();


  const addBindings = () => {

    const bindingsDefs: KeyBinding[] = [
      {
        check: (event: any) => {
          return [
            'Delete',
            'Backspace'
          ].includes(event.key);
        },
        callback: fns.resetCell,
        priority: KeyBindingPriority.EDITOR_INSTRUCTION
      },
    ];

    bindingsDefs.forEach(binding => {
      const bindingIds = bindingIdsRef.current;
      const tmpId = addKeyDownBinding(binding);
      bindingIds.push(tmpId);
    });
  }

  const removeBindings = () => {
    const bindingIds = bindingIdsRef.current;
      bindingIds.forEach(id => removeKeyDownBinding(id));
      __resetBindingIds();
  }

  const __resetBindingIds = () => {
    bindingIdsRef.current = [];
  }


  return {
    addBindings,
    removeBindings
  }
}

export default useKeyBindings;