import React from 'react';

import { WidgetPropsBase }    from 'app/ui-v2/editor-image/hls/widgets/widgets/types';
import { RegistrationPlugin } from 'app/ui-v2/editor-image/hls/widgets/editor-text/RegistrationPlugin';
import { ContentSavePlugin }  from 'app/ui-v2/editor-image/hls/widgets/editor-text/ContentSavePlugin';
import WidgetArrowTextComponentBase from 'app/ui-v2/editor-image/hls/widgets/widgets/__widget-base/widget-arrows/widget-arrow-text-base';


interface Props extends WidgetPropsBase {
}


export const WidgetArrowTextComponent: React.FC<Props> = (props: Props) => {

  return (
    <WidgetArrowTextComponentBase
      {...props}
      EditorTextPlugins={[
        <RegistrationPlugin widgetAddr={props.widgetAddr} />,
        <ContentSavePlugin  widgetAddr={props.widgetAddr} />
      ]}
    />
  );
}
