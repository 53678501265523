import React from 'react';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';
import useMarkerEdit    from 'app/ui-v2/editor-markers/hooks/use-marker-edit';
import { MainWrapper } from './styles';


interface Props {
  markerAddr: ContentTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerEditComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children
  } = props;

  const document = useDocState();
  const editMarker = useMarkerEdit();

  const handleEditMarker = () => {
    const markerProps = document.content.cellMarkers_getMarkerProps(markerAddr);
    editMarker(markerProps.repoMarkerAddr);
  }

  return (
    <MainWrapper onDoubleClick={handleEditMarker} >
      { children }
    </MainWrapper>
  );
}
