import * as Defaults from './defaults';
import * as Tools from './tools';
import * as Types from './types';


/**
 * State
 */
export type State = Types.Clicks;


/**
 * Clicks
 */
export const getClicksAddrs = (
  state: State
): Types.ClicksAddrs => { 
  const clicks = __getClicks(state);
  return clicks.addrs;
}

export const getClicksProps = (
  state: State
): Types.ClicksProps => { 
  const clicks = __getClicks(state);
  return clicks.props;
}

const __getClicks = (
  state: State
): Types.Clicks => { 
  return state;
}


/**
 * Click
 */
export const getClickProps = (
  state: State,
  clickAddr: Types.ClickAddr,
): Types.ClickProps => { 
  const clicksProps = getClicksProps(state);
  const clickKey    = Tools.getClickKey(clickAddr);
  
  const clickProps = clicksProps[clickKey];
  if (clickProps === undefined) {
    const msg = `Click not found`;
    throw new Error(msg);
  }

  return clickProps;
}

export const getClickIdx = (
  state: State, 
  clickAddr: Types.ClickAddr,
): number => {
  const clicksAddrs = getClicksAddrs(state);

  const idx = clicksAddrs.findIndex((clickAddr_) => Tools.compareClickAddr(clickAddr_, clickAddr));
  if (idx === -1) {
    const msg = "Click not found";
    throw new Error(msg);
  }

  return idx;
}


/**
 * Initial state
 */
export const createInitialState = (): State => {
  const initState: State = Defaults.getClicks();
  return initState;
}
