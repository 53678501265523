import styled from "styled-components";
import InputWrapperBase from 'app-views/styles/input-wrapper';
import InputTitleBase   from 'app-views/styles/input-title';


export const InputWrapper = styled(InputWrapperBase)`
`;

export const InputTitle = styled(InputTitleBase)`
`;

