import { Position } from "app/arch/types";
import { Size     } from "app/arch/types";

import { SmartLines } from "./smart-lines";
import { FrameCtrlType } from 'lego-v2/frame-resize/arch';


export class SmartLinesFrameResizer extends SmartLines {
  loadFromFrameUpdate(
    ctrlType: FrameCtrlType, 
    size: Size, 
    position: Position, 
  ) {

    const CalculateFrameLinesHandlers: any = {
      [FrameCtrlType.BOTTOM_MIDDLE] : () => this.addLine_ctrlBottomMiddle(size, position),
      [FrameCtrlType.MIDDLE_RIGHT]  : () => this.addLine_ctrlMiddleRight(size, position),
      [FrameCtrlType.BOTTOM_RIGHT]  : () => this.addLine_ctrlBottomRight(size, position),

      [FrameCtrlType.TOP_LEFT]      : () => this.addLine_ctrlTopLeft(size, position),
      [FrameCtrlType.TOP_MIDDLE]    : () => this.addLine_ctrlTopMiddle(size, position),
      [FrameCtrlType.TOP_RIGHT]     : () => this.addLine_ctrlTopRight(size, position),

      [FrameCtrlType.MIDDLE_LEFT]  : () => this.addLine_ctrlMiddleLeft(size, position),
      [FrameCtrlType.BOTTOM_LEFT]  : () => this.addLine_ctrlBottomLeft(size, position),
    };

    const calculateFrameLineHandler: any = CalculateFrameLinesHandlers[ctrlType];
    calculateFrameLineHandler(size, position);
  }

  private addLine_ctrlBottomRight(size: Size, position: Position) {
    this.addLine_ctrlBottomMiddle(size, position);
    this.addLine_ctrlMiddleRight(size, position);
  }

  private addLine_ctrlTopLeft(size: Size, position: Position) {
    this.addLine_ctrlTopMiddle(size, position);
    this.addLine_ctrlMiddleLeft(size, position);
  }
  
  private addLine_ctrlTopRight(size: Size, position: Position) {
    this.addLine_ctrlTopMiddle(size, position);
    this.addLine_ctrlMiddleRight(size, position);
  }
  
  private addLine_ctrlBottomLeft(size: Size, position: Position) {
    this.addLine_ctrlBottomMiddle(size, position);
    this.addLine_ctrlMiddleLeft(size, position);
  }

  private addLine_ctrlBottomMiddle(size: Size, position: Position) {
    const lineContent = {
      x0: position[0], 
      y0: position[1] + size[1], 

      x1: position[0] + size[0], 
      y1: position[1] + size[1], 
    };

    this.addLine(lineContent);
  }

  private addLine_ctrlMiddleRight(size: Size, position: Position) {
    const lineContent = {
      x0: position[0] + size[0], 
      y0: position[1] , 

      x1: position[0] + size[0], 
      y1: position[1] + size[1], 
    };

    this.addLine(lineContent);
  }

  private addLine_ctrlTopMiddle(size: Size, position: Position) {
    const lineContent = {
      x0: position[0], 
      y0: position[1], 

      x1: position[0] + size[0], 
      y1: position[1], 
    };

    this.addLine(lineContent);
  }

  private addLine_ctrlMiddleLeft(size: Size, position: Position) {
    const lineContent = {
      x0: position[0], 
      y0: position[1], 

      x1: position[0], 
      y1: position[1] + size[1], 
    };

    this.addLine(lineContent);
  }
}
