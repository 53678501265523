import { useAuth } from 'services/auth';


const useUsername = () => {
  const auth = useAuth();
  const username = auth.getUsername();

  if (username === null) {
    const msg = 'Username is null';
    throw new Error(msg);
  }

  return username;
}


export default useUsername;
