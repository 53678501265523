import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import useActionsState from "./use-actions-state";
import { UIInfoActions } from "../states";
import { ActionsState } from "app/arch/app/info/actions";


const useActionsListenerRegistration = () => {
  const actions = useActionsState();
  const setActionState = useSetRecoilState(UIInfoActions.state);

  useEffect(() => {
    actions.addListener((state: ActionsState.State) => {
      setActionState(state);
    });

    return () => {
      actions.removeListener();
    }
  }, []);
}


export default useActionsListenerRegistration;
