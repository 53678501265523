import { useEffect } from 'react';
import { useRef }    from 'react';

import { useProgressModal } from 'lego-v2/progress-modal';

import { useMutation }       from 'app/arch/backend/use-mutation';
import { mutation }          from 'app/arch/backend';
import { useDocState }       from 'app/ui/contexts/document';
import { useTranslations }   from 'app/ui/hooks/app/use-translation';
import { MsgDuration }       from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useDialogYesNo }    from 'app/ui/components/editor-instruction/dialogs/dialog-yes-no/use-dialog-yes-no';
import useEditorStatesSetters from './use-editor-states-setters';
import useDocument from 'app/ui/contexts/document/use-document';


type OnDoneFn = () => void;

export interface FnProps {
  imageId: number;
  onDone?: OnDoneFn;
}


export const useDocumentImageDelete = () => {
  const t = useTranslations();
  const propsRef = useRef<null | FnProps>(null);
  const docState = useDocState();
  const document = useDocument();

  const {
    setContent,
    setRepoImages
  } = useEditorStatesSetters();

  const { 
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();


  //---------------
  // Yes/No dialog
  //
  const {
    show: showDialogYesNo,
  } = useDialogYesNo();


  //-------------------------
  // Delete instruction
  //
  const { 
    mutation: deleteInstructionImageMutation,
    data: deleteInstructionImageData,
  } = useMutation(
    mutation.deleteInstructionImage,
    {
      onStart: {
        msg:  t("document image, deleting"),
      },
      onEnd: 
      {
        msg:  t("document image, deleted"),
        duration: MsgDuration.MIDDLE
      }
    }
  );


  useEffect(() => {
    if ( ! deleteInstructionImageData ) {
      return;
    }

    const data = deleteInstructionImageData.instructionImageDelete;
    if (data.ok) {
      const props = propsRef.current !;

      document.removeImage(props.imageId);
      docState.saveUndo();

      setContent();
      setRepoImages();

      props.onDone?.();
    }
    else {
      console.warn(`Image has not been delete, unknown error`);
    }

    hideProgressModal();
    propsRef.current = null;
  }, [deleteInstructionImageData]);

  
  const deleteDocument = (props: FnProps) => {
    const title   = t("document image delete msgbox, title");
    const message = t("document image delete msgbox, message");
    const noAutoFocus = true;

    const {
      imageId,
      onDone
    } = props;

    propsRef.current = props;

    const onNoHandler  = () => {};
    const onYesHandler = () => {
      showProgressModal();
      deleteInstructionImageMutation({ 
        variables: { id: imageId } 
      });
    }

    showDialogYesNo({
      title, 
      message, 
      noAutoFocus, 
      onYesHandler, 
      onNoHandler 
    });
  }


  return deleteDocument;
}


export default useDocumentImageDelete;
