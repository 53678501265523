import { uuid } from "tools/uuid";
import * as Types from './types';


/**
 * Actions
 */

export const getActionKey = (
  actionAddr: Types.ActionAddr
): string => (
  `${actionAddr.actionId}`
);

export const createActionAddr = (
): Types.ActionAddr => {
  const actionId = uuid();
  const actionAddr = { actionId };
  return actionAddr;
}   

export const compareActionAddr = (
  srcActionAddr: Types.ActionAddr,
  dstActionAddr: Types.ActionAddr,
): boolean => (
  srcActionAddr.actionId === dstActionAddr.actionId
);
