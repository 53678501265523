import React from 'react';
import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';

import useBillingPeriod from '../../../../hooks/use-billing-period';
import PriceDailyComponent   from './price-daily';
import PriceMonthlyComponent from './price-monthly';
import PriceYearlyComponent  from './price-yearly';


interface Props {
}


export const PricePlanPriceComponent: React.FC<Props> = (props: Props) => {
  const billingPeriod   = useBillingPeriod();

  const PricesComponents: {[priceType in PricePlansTypes.BillingPeriod]: React.ElementType} = {
    [PricePlansTypes.BillingPeriod.DAY  ]: PriceDailyComponent,
    [PricePlansTypes.BillingPeriod.MONTH]: PriceMonthlyComponent,
    [PricePlansTypes.BillingPeriod.YEAR ]: PriceYearlyComponent,
  }

  const PriceComponent = PricesComponents[billingPeriod];

  return (
    <PriceComponent/>
  );
}

