import { createContext } from 'react';


export interface ContextProps {
  rowAdder: boolean;
  pageLastItem: boolean;
};


const Context = createContext<ContextProps>({
  rowAdder: false,
  pageLastItem: false,
});


export default Context;