import styled from "styled-components";


export const ImgUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  height: 100%;
  padding: ${props => props.theme.defs.padding.normal};

  box-sizing: border-box;

  border-style: solid;
  border-color: #666;
  border-radius: ${props => props.theme.defs.border.radius.small};
  border-width: 1px;
`;
