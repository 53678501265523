/**
 * 
 * Panel Assets
 * 
 */

export enum PanelAssetsItem {
  IMAGE   = 'image',
  MARKER  = 'marker',
};

export type PanelAssets = {
  visible: boolean,
  selected: PanelAssetsItem,
}

export type PanelAssetsUpdate = Partial<PanelAssets>;


/**
 * 
 * Panel Properties
 * 
 */

export enum PanelPropertiesItem {
  PAGE     = 'page',
  HEADER   = 'header',
  TABLE    = 'table',
  SECTIONS = 'sections',
  COLUMN   = 'column',
  COLUMNS  = 'columns',
};

export type PanelProperties = {
  visible: boolean,
  selected: PanelPropertiesItem,
}


/**
 * CellImages
 */

export type CellImagesUploadingImages = {
  [cellKey: string]: number
}



export type PanelPropertiesUpdate = Partial<PanelProperties>;

