import React from 'react';

import useIsKeyDown from 'lego/components/key-bindings/hooks/use-is-key-down';
import useSystemKeys from 'lego/components/key-bindings/hooks/use-system-keys';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { useDocState } from 'app/ui/contexts/document';
import useSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useColumnToggleSelect from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-column-toggle-select';

import { MainWrapper } from './styles';


interface Props {
  columnAddr: ContentTypes.ColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;

  const document = useDocState();
  const resetAllSelected = useSelectedReset();
  const toggleSelectColumn = useColumnToggleSelect();

  const systemKeys = useSystemKeys();
  const actionKeys = systemKeys.actionKeys;
  const isKeyDown = useIsKeyDown();

  const handleSelectColumn = (event: React.MouseEvent) => {
    event.stopPropagation();

    const isSelected = document.contentColumnsSelected.isSelected(columnAddr);
    if ( isSelected ) {
      return;
    }

    const enabledMultiSelected = isKeyDown(actionKeys.multiItemSelect);

    resetAllSelected();
    toggleSelectColumn({ 
      columnAddr,
      resetSelected: ! enabledMultiSelected
    });
  }

  return (
    <MainWrapper onPointerDown={handleSelectColumn}>
      { children }
    </MainWrapper>
  );
}
  
