import styled from "styled-components";


export const Grid = styled.div<{items: number, inColumn?: boolean}>`
  display: grid;

  width: 100%;
  height: 100%;

  /* background: red;; */

  grid-template-columns: ${ props => props.inColumn ?
    `1fr 0` :
    `repeat(${props.items}, minmax(auto, min-content))`
  };

  grid-template-rows: ${ props => props.inColumn ?
    `repeat(${props.items}, minmax(auto, min-content))` :
    `1fr 0`
  };

  column-gap: ${
    props => props.inColumn ?
    0 :
    props.theme.defs.gap.xxsmall
  };

  row-gap: ${
    props => props.inColumn ?
    props.theme.defs.gap.xxsmall :
    0
  };
`;

export default Grid;
