import React from 'react';
import HLS_MenubarItemPanelItemComponent from 'lego-v2/menubar/hls/menubar-item-panel-item';
import * as Types from '../../types';


interface Props extends Types.MenubarItemPanelItemProps {
}


export const MenubarItemPanelItemComponent: React.FC<Props> = (props: Props) => {

  return (
    <HLS_MenubarItemPanelItemComponent
      {...props}
    />
  );
}

