import React from 'react';
import { MarkerWrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const MarkerSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  return (
    <MarkerWrapper>
      { children }
    </MarkerWrapper>
  );
}
  
