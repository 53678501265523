import useExcelExportContext from "./use-excel-export-context";


const useAssetsRepo = () => {
  const context = useExcelExportContext();
  const assetsRepo = context.assetsRepo;

  return assetsRepo;
}


export default useAssetsRepo;