import React from 'react';
import ViewTitleProbeComponent   from './view-title-probe';
import ViewTitleContentComponent from './view-title-content';
import ViewTitleEditComponent    from './view-title-edit';
import ViewTitleCSSComponent     from './view-title-css';

import { Border } from './styles';

interface Props {
}


export const ViewTitleComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <ViewTitleProbeComponent>
      <Border>
        <ViewTitleEditComponent>
          <ViewTitleCSSComponent>
            <ViewTitleContentComponent />
          </ViewTitleCSSComponent>
        </ViewTitleEditComponent>
      </Border>
    </ViewTitleProbeComponent>
  );
}
  
