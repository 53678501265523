import styled from "styled-components";

import GridRow1Base from "../../styles/navbar-item/grid-row1";
import GridRow2Base from "../../styles/navbar-item/grid-row2";
import ItemBase from "../../styles/navbar-item/item";
import PanelDetacherBase from "../../styles/navbar-item/panel-detacher"
import TextBase from "../../styles/navbar-item/text";
import IconWrapperBase from "../../styles/navbar-item/icon-wrapper";


export const GridRow1 = styled(GridRow1Base)`
`;

export const GridRow2 = styled(GridRow2Base)`
`;

export const Item = styled(ItemBase)`
  /*
    Setting cursor to pointer - cause additional
    unneeded background flicker color change on touch down
    on touch enabled devices.
    Unknownly why. Therefore we need to reset to default.
   */
  cursor: default;
  
  /* 
    This is needed because
    if we click on button, and then click again
    it toggles selected property (in component), 
    however  it sill acts like it was hovered -
    hover selector is active on css. Touch devices 
    handle hover event diffrently.
  */
  &:hover:not(:active) {
    color: ${props => 
      props => props.selected ? 
      props.theme.button.color.selected :
      props.open ? 
        props.theme.button.color.selected :
        props.theme.button.color.primary
    };

    background: ${props => 
      props => props.selected ? 
      props.theme.button.background.selected : 
      props.open ? 
        props.theme.button.background.hover :
        'none'
    };

    border-color: ${props => 
      props => props.selected ? 
      props.theme.button.border.color.selected :
      props.open ? 
        props.theme.button.border.color.hover :
        props.theme.button.border.color.primary
    };
  }

  &:active:not(:disabled) {
    background: ${props => 
      props => props.selected ? 
      props.theme.button.background.selected : 
      props.theme.button.background.hover
    };
  }
  
`;

export const PanelDetacher = styled(PanelDetacherBase)`
`;

export const Text = styled(TextBase)`
`;

export const IconWrapper = styled(IconWrapperBase)`
`;