import React from 'react';

import { ContentWrapper } from './styles';
import { Content } from './styles';
import { Scaler } from './styles';


interface Props {
  children: React.ReactNode;
}


export const ImageViewComponent = React.forwardRef((
  props: Props, 
  ref: React.Ref<HTMLDivElement>
) => {

  const {
    children,
  } = props;

  return (
    <ContentWrapper>
      <Content ref={ref}>
        <Scaler>
          { children }
        </Scaler>
      </Content>
    </ContentWrapper>
  );
});
