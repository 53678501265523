import React from 'react';
import { MarkerViewProps } from '../types';
import { MarkerText } from './styles';


interface Props extends MarkerViewProps {
}


export const MarkerViewLabelComponent: React.FC<Props> = (props: Props) => {
  const {
    markerProps,
  } = props;

  return (
    <MarkerText 
      rotate={markerProps.label.rotate}
      style={markerProps.label.css}>
      { markerProps.label.text }
    </MarkerText>
  );
}
