import React from 'react';
import { FlexEditApp } from './styles';


interface Props {
  textSize?: number;
}


export const FlexEditNameComponent: React.FC<Props> = (props: Props) => {
  const textSize = (
    props.textSize !== undefined ? 
    props.textSize :
    40
  );

  return (
    <FlexEditApp fontSize={textSize}>
      FlexEdit.app
    </FlexEditApp>
  );
}
