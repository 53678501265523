import { RepoMarkersTypes } from '../repo-markers';
import * as Types from './types';


export const getMarkers = (): Types.Markers  => ({
  addrs: [],
  props: {}
});


export const getMarkerProps = (
  repoMarkerAddr: RepoMarkersTypes.MarkerAddr
): Types.MarkerProps => ({
  repoMarkerAddr
});
