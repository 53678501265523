import * as Defaults from './defaults';
import * as Tools from './tools';
import * as Types from './types';


/**
 * State
 */
export type State = Types.Infos;



/**
 * Infos
 */
export const getInfosAddrs = (
  state: State
): Types.InfosAddrs => { 
  const infos = __getInfos(state);
  return infos.addrs;
}

export const getInfosProps = (
  state: State
): Types.InfosProps => { 
  const infos = __getInfos(state);
  return infos.props;
}

const __getInfos = (
  state: State
): Types.Infos => { 
  return state;
}




/**
 * Info
 */
export const getInfoProps = (
  state: State,
  infoAddr: Types.InfoAddr,
): Types.InfoProps => { 
  const infosProps = getInfosProps(state);
  const infoKey    = Tools.getInfoKey(infoAddr);
  
  const infoProps = infosProps[infoKey];
  if (infoProps === undefined) {
    const msg = `Info props not found`;
    throw new Error(msg);
  }

  return infoProps;
}

export const getInfoIdx = (
  state: State, 
  infoAddr: Types.InfoAddr,
): number => {
  const infosAddrs = getInfosAddrs(state);

  const idx = infosAddrs.findIndex((infoAddr_) => Tools.compareInfoAddr(infoAddr_, infoAddr));
  if (idx === -1) {
    const msg = "Info not found";
    throw new Error(msg);
  }

  return idx;
}



/**
 * Initial state
 */

export const createInitialState = (): State => {
  const initState: State = Defaults.getInfos();
  return initState;
}
