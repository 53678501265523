import React from 'react';

import { useDocState } from 'app/ui/contexts/document';
import usePrintoutViewLoaded  from '../../hooks/use-printout-view-loaded';
import usePrintoutViewIdReady from '../../hooks/use-printout-view-id-ready';

import DebugInfoComponent from './debug-info';

import { LabelTitle } from './styles';
import { MainWrapper } from './styles';
import { PrintoutReadyMark } from './styles';
import { DebugLabel } from './styles';


interface Props {
}


export const PrintoutReadyMarkComponent: React.FC<Props> = (props: Props) => {
  const document = useDocState();
  const debug = document.printoutReleaseInfo.getDebug();
  const prinoutReadyId = usePrintoutViewIdReady();

  const {
    viewsLoaded,
    imagesLoaded,
    allLoaded,
  } = usePrintoutViewLoaded();


  const {divId, divText} = (
    allLoaded ?
    {divId: prinoutReadyId, divText: 'Ready'} :
    {divId: 'printout-state__not-ready', divText: 'Not Ready'}
  )

  const showDebugInfo = (
    debug && true
  );


  return (
    <>
      {
        showDebugInfo &&
        <DebugInfoComponent 
          viewsLoaded={viewsLoaded}
          imagesLoaded={imagesLoaded}
        />
      }
      <MainWrapper>
        <DebugLabel>
          <LabelTitle>ID:</LabelTitle>{ prinoutReadyId } 
        </DebugLabel>
        
        <PrintoutReadyMark id={divId}>
          <LabelTitle>State:</LabelTitle>{ divText } 
        </PrintoutReadyMark>
      </MainWrapper>
    </>
  );
}

