import { useRef } from 'react';

import { WidgetBoxedPropsBase }  from '../widget-boxed-base';
import WidgetBoxedBaseComponent  from '../widget-boxed-base';
import WidgetEditorTextComponent             from '../parts/widget-editor-text';
import WidgetEditorTextEditComponent         from '../parts/widget-editor-text-edit';
import WidgetEditorTextMarginsComponent      from '../parts/widget-editor-text-margins';
import WidgetEditorTextEditFinishedComponent from '../parts/widget-editor-text-edit-finished';


interface Props extends WidgetBoxedPropsBase {
  EditorTextPlugins: React.ReactNode[];
}


export const WidgetTextComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
    scale,
    EditorTextPlugins,
  } = props;

  const editDisabled = (
    props.editDisabled !== undefined ?
    props.editDisabled :
    false
  );

  const editorTextRef = useRef<HTMLDivElement | null>(null);
  

  return (
    <WidgetBoxedBaseComponent {...props}>
      <WidgetEditorTextEditComponent
        widgetAddr={widgetAddr}
        editDisabled={editDisabled}
        editorTextRef={editorTextRef}
      >
        <WidgetEditorTextEditFinishedComponent
          widgetAddr={widgetAddr}
        >
          <WidgetEditorTextMarginsComponent
            widgetAddr={widgetAddr}
            editDisabled={editDisabled}
          >
            <WidgetEditorTextComponent
              widgetAddr={widgetAddr}
              editDisabled={editDisabled}
              editorTextRef={editorTextRef}
              EditorTextPlugins={EditorTextPlugins}
            />
          </WidgetEditorTextMarginsComponent>
        </WidgetEditorTextEditFinishedComponent>
      </WidgetEditorTextEditComponent>
    </WidgetBoxedBaseComponent>
  );
};
