import { PanelControlsConfig } from "lego/components/panel-controls/config";
import { useTranslations } from "app/ui/hooks/app/use-translation";

import useDocHeaderInfoConfigSection from "./doc-header-info";
import useDocReleaselogTitleConfigSection from "./doc-releaselog-title";


const usePanelDocHeaderConfig = () => {
  const t = useTranslations();

  const docHeaderInfoSection = useDocHeaderInfoConfigSection();
  const docReleaselogTitleSection = useDocReleaselogTitleConfigSection();

  const getConfig = (): PanelControlsConfig => ({
    items: [{
      title: t('page header'),
      sections: [
        docHeaderInfoSection,
        docReleaselogTitleSection,
      ]
    }]
  });

  return getConfig;
}

export default usePanelDocHeaderConfig;