import { useRef } from 'react';
import { RefObject } from 'react';

import { Position } from "app/arch/types";
import { Size }     from "app/arch/types";
import useResizeObserver from 'app/ui/hooks/use-resize-observer';

import * as Types from './types';


interface Props {
  deskAreaRef: RefObject<HTMLDivElement>; 
  onPositionChange: Types.SetPositionFn;
}


export const useResizeFitter = (props: Props) => {
  const { 
    deskAreaRef,
    onPositionChange,
  } = props;
  
  const prevSize = useRef([0, 0] as Size);
  const isInitialized = useRef(false);

  useResizeObserver({
    elementRef: deskAreaRef,
    onResize: (entries) => {
      handleResize(entries);
    }
  }, [deskAreaRef.current]);


  const handleResize = (entries: ResizeObserverEntry[]) => {
    if ( ! isInitialized.current ) {
      init();
      return;
    }

    for (const entry of entries) {
      const deltaX = entry.contentRect.width - prevSize.current[0];
      const deltaY = entry.contentRect.height - prevSize.current[1];

      prevSize.current = [
        entry.contentRect.width,
        entry.contentRect.height
      ];

      onPositionChange((prev: Position) => {
        const x = prev[0] + deltaX / 2;
        const y = prev[1] + deltaY / 2;
        
        return ([x, y] as Position);
      });
    }
  }

  const init = () => {
    if ( isInitialized.current ) {
      return;
    }

    if ( ! deskAreaRef.current ) {
      return;
    }
    
    const bbox = deskAreaRef.current.getBoundingClientRect();
    if (bbox.height === 0 || bbox.width === 0) {
      return;
    }
    prevSize.current = [bbox.width, bbox.height];
    isInitialized.current = true;
  }
}
