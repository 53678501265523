import * as Tools from './tools';
import * as Types from './types';


export type State = {
  reposPacks: Types.ReposPacks
};


export const getReposPacks = (state: State) => {
  if (state.reposPacks.length === 0) {
    const msg = `Repos packs are not set`;
    throw new Error(msg);
  }
  return state.reposPacks;
}

export const getRepoPack = (
  state: State, 
  repoId: number
) => {
  const reposPacks = getReposPacks(state);
  const repoPack = reposPacks.find((pack) => pack.repo.id === repoId);

  if (repoPack === undefined) {
    const msg = `Repo id: ${repoId} not found`;
    throw new Error(msg);
  }

  return repoPack;
}

export const getRepoPackIdx = (
  state: State, 
  repoId: number
): number => {
  const reposPacks = getReposPacks(state);
  const repoIdx = reposPacks.findIndex((pack) => pack.repo.id === repoId);
  
  if (repoIdx === -1) {
    const msg = `Repo id: ${repoId} not found`;
    throw new Error(msg);
  }

  return repoIdx;
}

export const getRepo = (
  state: State, 
  repoId: number
): Types.Repo => {
  const repoPack = getRepoPack(state, repoId);
  return repoPack.repo;
}

export const getMember = (
  state: State, 
  repoId: number, 
  memberUsername: string
) => {
  const repo = getRepoPack(state, repoId);
  const member = repo.members.find((member) => member.username === memberUsername);

  if (member === undefined) {
    const msg = `Repo id: ${repoId}, memberUsername: ${memberUsername}, member not found`;
    throw new Error(msg);
  }

  return member;
}

export const getMemberIdx = (
  state: State, 
  repoId: number, 
  memberUsername: string
): number => {
  const repo = getRepoPack(state, repoId);
  const memberIdx = repo.members.findIndex((member) => member.username === memberUsername);

  if (memberIdx === -1) {
    const msg = `Repo id: ${repoId}, memberUsername: ${memberUsername}, member not found`;
    throw new Error(msg);
  }

  return memberIdx;
}

export const getRepoMemberAccess = (
  state: State, 
  repoId: number, 
  memberUsername: string
) => {
  const member = getMember(state, repoId, memberUsername);
  return member.access;
}

export const getRepoMemberStatus = (
  state: State, 
  repoId: number, 
  memberUsername: string
) => {
  const member = getMember(state, repoId, memberUsername);
  return member.status;
}

export const getRepoMembers = (
  state: State, 
  repoId: number, 
  memberUsername: string
) => {
  const repoPack = getRepoPack(state, repoId);
  // const members = repoPack.members.filter((member) => member.username !== memberUsername);
  return repoPack.members;
}


/**
 * Create initial state
 */

export const createInitialState = (): State => {
  const logger = Tools.getLogger();
  logger.debug("Create initial repos state");


  const initState: State = {
    reposPacks: [],
  }

  return initState;
}
