import { atom }     from 'recoil';
import { selector } from 'recoil';
import { PrintoutViewState as State } from 'app/arch/editor-instruction/document/states/printout/printout-view';


export const state = atom<State.State>({
  key: "editor_printoutView",
  default: State.createInitialState()
});


export const viewsLoaded = selector({
  key: `editor_printoutView_viewsLoaded`,
  get: ({ get }) => {
    const stateObj = get(state);
    const viewsLoaded = State.getViewsLoaded(stateObj);
    return viewsLoaded;
  }
});


export const pageCurrent = selector({
  key: `editor_printoutView_pageCurrent`,
  get: ({ get }) => {
    const stateObj = get(state);
    const pages = State.getPages(stateObj);
    return pages.current;
  }
});
