import React from 'react';
import { Logo } from './styles';


interface Props {
  logoSize?: number;
}


export const FlexEditLogoRawComponent: React.FC<Props> = (props: Props) => {
  const logoSize = (
    props.logoSize !== undefined ? 
    props.logoSize :
    85
  );
  
  return (
    <Logo 
      style={{
        width: `${logoSize}px`,
        height: `${logoSize}px`,
        minWidth: `${logoSize}px`,
        minHeight: `${logoSize}px`,
      }}
    />
  );
}

