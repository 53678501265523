import React from 'react';
import useColumnMenu  from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-column-menu/use-column-menu';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useColumnSelectedSet from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/column/use-column-selected-set';
import { HeaderCellProps } from '../types';
import { MainWrapper } from './styles';


interface Props extends HeaderCellProps {
}


export const HeaderCellContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;

  const getMenuConfig = useColumnMenu(columnAddr);
  const { showMenu  } = useContextMenu();
  const setColumnSelected = useColumnSelectedSet();

  const handleContextMenu = (event: React.MouseEvent) => {
    setColumnSelected(columnAddr);
    const config = getMenuConfig();

    showMenu({
      event,
      config
    });
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
