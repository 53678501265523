import styled from "styled-components";


export const WidgetInfoAnchor = styled.div`
  position: relative;
  z-index: 1;
`;


export const WidgetInfoDetacher = styled.div`
  position: absolute;
  left: 40px;
  top:  40px;
`;
