import { UIState_EditorImageSettings } from 'app/ui/states/editor-instruction';
import React, { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import jtl from 'tools/jtl';

import { SmartLinesAnalyzer    } from 'tools/smart-lines/smart-lines-analyzer';
import { OrientedSmartLinesRaw } from 'tools/smart-lines/types';
import { SmartLinesRaw  } from 'tools/smart-lines/types';
import { StickyLinesRaw } from 'tools/smart-lines/types';


const UPDATE_DEBOUNCE_DELAY = 1;


export interface SmartLinesControlProps<UpdateType> {
  smartLines:     OrientedSmartLinesRaw | null;  // compare to
  srcSmartLines?: SmartLinesRaw | null;        // compare from

  onSrcSmartLinesRequest: (update: UpdateType) => OrientedSmartLinesRaw;

  onSetSmartLines?:   () => void;
  onUnsetSmartLines?: () => void;

  onSetStickyLines?: (stickyLines: StickyLinesRaw) => void;
  onUnsetStickyLines?: () => void;
}


interface Props<UpdateType, PropsType> extends SmartLinesControlProps<UpdateType> {
  
  onApplyStickyLines: (update: UpdateType, stickyLines: StickyLinesRaw) => UpdateType;
  ControlProps: PropsType;
  ControlComponent: any;
}


export const SmartLinesControlComponent = <UpdateType, PropsType>(props: Props<UpdateType, PropsType>) => {
  const {
    smartLines: dstSmartLines,
    onSrcSmartLinesRequest,

    onSetSmartLines,
    onUnsetSmartLines,

    onSetStickyLines,
    onUnsetStickyLines,

    onApplyStickyLines,
    ControlProps,
    ControlComponent
  } = props;

  const {
    onUpdateStart,
    onUpdate,
    onUpdateDone,
  } = ControlProps as any;

  const [sticked, setSticked] = useState(false)
  const isDragging = useRef(false);
  const smartLines = useRecoilValue(UIState_EditorImageSettings.smartLines);

  const calculateStickyLines = <UpdateType,>(update2: UpdateType) => {
    const update = update2 as any;
    const srcSmartLines = onSrcSmartLinesRequest(update);

    const analyzer = new SmartLinesAnalyzer(
      srcSmartLines,
      dstSmartLines
    );
    
    const stickyLines = analyzer.getStickyLines(smartLines.stickyLines.stickyness);
    return stickyLines;
  }

  const applyStickyLines = <UpdateType,>(update: UpdateType, stickyLines: StickyLinesRaw) => {
    // TODO
    return onApplyStickyLines?.(update as any, stickyLines);
  }

  const updateSmartLinesDebounced = jtl.core.debounce((update: UpdateType) => {
    if ( ! isDragging.current ) {
      return;
    }

    const stickyLines = calculateStickyLines(update);
    if ( ! stickyLines.horizontal && ! stickyLines.vertical ) {
      onUnsetStickyLines?.();
      setSticked(false);

      return;
    }

    update = applyStickyLines(update, stickyLines);
    onSetStickyLines?.(stickyLines);
    onUpdate?.(update);
    setSticked(true);
  }
  , UPDATE_DEBOUNCE_DELAY);


  const handleUpdateStart = (event: any) => {
    isDragging.current = true;
    onUpdateStart?.(event);
    onSetSmartLines?.();
  }
  
  const handleUpdate = <UpdateType,>(update: UpdateType) => {
    ! sticked && onUpdate?.(update);
    updateSmartLinesDebounced(update);
  }

  const handleUpdateDone = <UpdateType,>(update: UpdateType) => {
    isDragging.current = false;

    const stickyLines = calculateStickyLines(update);
    const updateProcessed = applyStickyLines(update, stickyLines);

    onUpdateDone?.(updateProcessed);
    onUnsetStickyLines?.();
    onUnsetSmartLines?.();
  }

  return (
    <ControlComponent
      {...ControlProps}
      onUpdateStart={handleUpdateStart}
      onUpdate={handleUpdate}
      onUpdateDone={handleUpdateDone}
    />
  );
}

