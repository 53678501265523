import { useDocState }     from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDialogCustom } from 'app/ui/components/editor-instruction/dialogs/dialog-custom/use-dialog-custom';
import useDocumentSave     from 'app/ui-v2/editor-instruction/hooks/use-document-save';
import useUserGoHome from 'app/ui-v2/home-page/home-page-user/hooks/user-user-go-home';


const useEditorExit = () => {
  const t        = useTranslations();
  const document = useDocState();

  const { show: showDialog } = useDialogCustom();

  const userGoHome = useUserGoHome();

  const saveAndExit = useDocumentSave({
    onSaved: () => {
      userGoHome();
    }
  })

  const exitEditor = () => {
    if ( ! document.dirty ) {
      userGoHome();
      return;
    }

    const dataTest = 'editor__dialog__exit';

    // Dirty
    //
    showDialog({
      title: t("document not saved title"),
      message: t("document not saved msg"),
      // icon: DialogCustomTypes.IconType.QUESTION,
      buttons: [
        {
          autoFocus: true,
          text: t("cancel"),
          onClick: () => {},
          dataTest: `${dataTest}__btn-cancel`
        },
        {
          text: t("exit"),
          onClick: () => {
            userGoHome();
          },
          dataTest: `${dataTest}__btn-exit`
        },
        {
          text: t("save & exit"),
          onClick: () => {
            saveAndExit();
          },
          dataTest: `${dataTest}__btn-save-and-exit`
        },
      ]
    });  
  }

  
  return exitEditor;
}

export default useEditorExit;
