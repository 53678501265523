import { useRef } from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import deepEqual from 'deep-equal';
import { useDebouncedCallback } from 'use-debounce';
import { useThrottledCallback } from 'use-debounce';
import Logger from 'libs/debug';

import { Size } from 'app/arch/types';
import { Page } from 'app/arch/print/page';

import { DocReleaselogsSlicer as Slicer } from 'app/arch/editor-instruction/document/slicers/releaselogs/slicer';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/releaselogs/types';

import { useDocState }   from 'app/ui/contexts/document';
import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';
import { UIState_ReleaselogsSliceRequest } from 'app/ui/states/editor-instruction';

import Settings from '../../configs/settings';
import useSlicerState from './use-slicer-state';


const SLICE_DEBOUNCE_DELAY = Settings.slicer.slice.debounce;
const SLICE_THROTTLE_DELAY = Settings.slicer.slice.throttle;


interface Props {
  onSliced: (pages: SlicerTypes.Pages) => void;
}

interface SlicingProps {
  onStart?: ()=> void;
  onDone?:  ()=> void;
}


export const useSlicer = (props: Props) => {
  const {
    onSliced,
  } = props;
  
  const log = Logger.getChangelogSlicer();

  const reverseOrder = true;

  const document   = useDocState();
  const isPrintout = useIsPrintout();
  const prevPagesRef = useRef<SlicerTypes.Pages | []>([]);

  // Slicing in progress is for 
  // ViewPrintout - so it knows
  // when slicing has finished 
  // so it can generate pdf.
  const slicingInProgress = useSlicerState();

  const sliceRequest = useRecoilValue(UIState_ReleaselogsSliceRequest.sliceRequest);
  

  const slice = (props: SlicingProps) => {
    const {
      onStart,
      onDone
    } = props;

    slicingInProgress();

    log.log("[Releaselog] slicing");

    // On start needs to be called before
    // reading states, as it can alert 
    // changes below.
    onStart?.();


    // These states could be used from recoil states above.
    // However if we get custom slice request which would change
    // any of the states before slicing is done, then using
    // states from recoil would use previous (invalid) value.
    // For now I can't think of situation which would cause this 
    // bug. 
    // Just to understand better - if for example one of 
    // releaselogs was delayed in `onStart` function from 
    // custom slice request - then if we use releaslogs state 
    // from recoil - it would be invalid. Saying that - we
    // don't delete releaelogs every - it is just an example.

    const pageLayout  = document.viewsCommon.getPageLayout();
    const pageMargins = document.viewsCommon.getPageMargins();
    
    const docInfoVisible  = document.releaselogsView.getHeaderMeta().visible;
    const docTitleVisible = document.releaselogsView.getHeaderTitle().visible;
  
    
    // These states are accessed directly as they are
    // not populated through recoil.
    const docReleaselogs = document.releaselogs;
    const elementsSizes = document.releaselogsElementsSizes;

    const pageSize = Page.getBodySizePx(
      pageLayout.format, 
      pageLayout.orientation, 
      pageMargins
    ) as Size;

    const pages: SlicerTypes.Pages = Slicer.slice({
      isPrintout,
      
      pageSize,
      reverseOrder,

      docInfoVisible,
      docTitleVisible,

      docReleaselogs,
      elementsSizes,
    });

    const areEqual = deepEqual(pages, prevPagesRef.current, {strict: false});
    if (areEqual) {
      log.warn("[Releaselog] Content changed - but same layout after reslice");

      // If nothing change we can call `onDone` now.
      onDone?.();
      return;
    } 
    
    // console.log("Layout changed after reslice");
    prevPagesRef.current = pages;
    onSliced(pages);

    // This is a bit of hack. But if there
    // are updates which changes recoil state
    // in `onDone`, they need to be performed
    // after re-rending of sliced content has
    // finished. Otherwise they can cause 
    // change of for example row - which 
    // has been deleted in `onStart`, but has 
    // not been removed yet from page as it is
    // still in `Changlog` items. We need to 
    // rerender `Changelog` items first without
    // this row, and then update other recoil 
    // states. The very good example is when, 
    // row has been selected, and then removed.
    // Then unselecting this row - would cause
    // it to rerender, but by that time row
    // info is lost from the state, but the 
    // row still is in DOM as `Changelog` item
    // change (deletiong of this row) has not 
    // yet been rerender.
    setTimeout(() => {
      onDone?.();
    });
  }



  //----------------

  
  const __slice_throttled = useThrottledCallback<(props: SlicingProps) => void>(slice, SLICE_THROTTLE_DELAY);
  const __slice_debounced = useDebouncedCallback<(props: SlicingProps) => void>(__slice_throttled, SLICE_DEBOUNCE_DELAY);

  const slice_delayed = (props: SlicingProps) => {
    slicingInProgress();
    __slice_debounced(props);
  }
  

  //----------------

  useEffect(() => {
    log.debug(`[Releaselog] Slice request, delayed=${sliceRequest.delayed}`);
    const sliceFn = (
      sliceRequest.delayed ?
      slice_delayed :
      slice
    );

    sliceFn(sliceRequest);
  }, [sliceRequest]);
}
