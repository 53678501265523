import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Outlet } from 'react-router-dom';

import useGuestGoHome from 'app/ui-v2/home-page/home-page-guest/hooks/use-guest-go-home';
import { UIAppStates } from 'app/ui-v2/app/__modules/app-states/states';


interface Props {
}


export const ViewAuthGuardComponent: React.FC<Props> = (props: Props) => {
  const isLogged = useRecoilValue(UIAppStates.isLogged);
  const goHome = useGuestGoHome();
  
  useEffect(() => {
    if ( ! isLogged ) {
      goHome();
    }
  }, [isLogged]);

  return (
    <>
      { isLogged && <Outlet /> }
    </>
  );
}
