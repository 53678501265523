import styled from 'styled-components';
import PanelBase from "app-views/styles/panel";
import ButtonText from "lego/styles/button_text";


export const Panel = styled(PanelBase)`
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* background: red; */
`;

export const Button = styled(ButtonText)`

`;