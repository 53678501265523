import styled from "styled-components";


export const Virtual = styled.div<{$debug?: boolean}>`
  position: fixed; 

  background: ${props => props.$debug ? 'rgba(255, 0, 0, 0.3)' : 'unset'};
  visibility: ${props => props.$debug ? 'visible' : 'hidden'};
  left:       ${props => props.$debug ? '0px'     : '-999999px'};
  top:        ${props => props.$debug ? '0px'     : '-999999px'};
  z-index:    ${props => props.$debug ? '10000'   : '-10000'};
`;
