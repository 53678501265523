export class PageHeader {
  private _docInfoHeight: number;
  private _docCustomRowsHeight: number;
  private _docCustomFieldsHeight: number;
  private _docMarkersHeight: number;


  constructor() {
    this._docInfoHeight = 0;
    this._docCustomRowsHeight = 0;
    this._docCustomFieldsHeight = 0;
    this._docMarkersHeight = 0;
  }

  getDocInfoHeight() { return this._docInfoHeight; }
  setDocInfoHeight(height: number) { this._docInfoHeight = height; }

  getDocCustomRowsHeight() { return this._docCustomRowsHeight; }
  setDocCustomRowsHeight(height: number) { this._docCustomRowsHeight = height; }

  getDocCustomFieldsHeight() { return this._docCustomFieldsHeight; }
  setDocCustomFieldsHeight(height: number) { this._docCustomFieldsHeight = height; }

  getDocMarkersHeight() { return this._docMarkersHeight; }
  setDocMarkersHeight(height: number) { this._docMarkersHeight = height; }
}
