import produce from 'immer';
import * as State from './state';
import * as Tools from "./tools"
import * as Types from "./types"


export class Template {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  reset() {
    this._state = State.createInitialState();
  }

  get raw() { return this._state; }

  /**
   * Setters
   */

  setTemplates(
    templates: Types.BackendTemplates
  ) {
    const logger = Tools.getLogger();
    logger.debug("Load templates from backend");

    templates.forEach(templateRaw => {
      const repoId = templateRaw.repo.id;
      const templateSerial = templateRaw.content;

      if (templateSerial.length === 0) {
        logger.debug(`No template for repoId: ${repoId}`);
        return;
      }

      const template = JSON.parse(templateSerial);
      this.setTemplate(repoId, template);
    });
  }

  setTemplate(
    repoId: Types.RepoId, 
    template: Types.TemplateContent
  ) {
    const logger = Tools.getLogger();
    logger.debug(`Seting doc template for repo: ${repoId}`);
    logger.debug(template);

    this._state = produce(this._state, draft => {
      const templates = State.getTemplates(draft);
      templates[repoId] = template;
    });
  }


  /**
   * Getters
   */
  getTemplates(): Types.ReposTemplates {
    return State.getTemplates(this._state);
  }

  getTemplate(repoId: Types.RepoId): Types.TemplateContent {
    return State.getTemplate(this._state, repoId);
  }

  hasTemplate(repoId: Types.RepoId): boolean {
    return State.hasTemplate(this._state, repoId);
  }
}
