import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLayoutEffect } from 'react';
import { useThrottledCallback } from 'use-debounce';

import useRescaleUniversal from 'lego-hooks/rescale/use-rescale-universal';
import useRescaleScrollbarUpdate from 'lego-hooks/rescale/use-rescale-scrollbar-update';
import { ScrollProps } from 'lego-hooks/rescale/use-rescale-scrollbar-update';

import { settings } from 'app/configs';
import { Position } from 'app/arch/types';
import { Size } from 'app/arch/types';
import useDocumentScaleWatch from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-watch';
import useDocumentScaleSet from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-set';
import useDeskScrollerState from 'app/ui-v2/editor-instruction/__document/hooks/use-desk-scroller-state';

import ScalerComponent from './scaler';
import { Scroller } from './styles';


interface Props {
  pageSize: Size;
  children: React.ReactNode;
}


export const DeskScalerComponent: React.FC<Props> = (props: Props) => {
  const {
    pageSize,
    children,
  } = props;

  const SCALE_MIN = settings.document.scale.min;
  const SCALE_MAX = settings.document.scale.max;
    
  const scrollerRef = useRef<HTMLDivElement>(null);
  const scrollerBBoxRef = useRef<DOMRect | null>(null);

  const scale = useDocumentScaleWatch();
  const setScale = useDocumentScaleSet();

  const [scrollProps, setScrollProps] = useState({scrollLeft: 0, scrollTop: 0});

  const scrollPropsInit = useRef<ScrollProps>({scrollLeft: 0, scrollTop: 0});

  const scrollUpdate = useRescaleScrollbarUpdate();

  useLayoutEffect(() => {
    const scroller = scrollerRef.current;
    if (scroller === null) {
      const msg = `Scroller element not ready`;
      throw new Error(msg);
    }

    scroller.scrollTop  = scrollProps.scrollTop;
    scroller.scrollLeft = scrollProps.scrollLeft;
  }, [scrollProps]);


  /**
   * Rescale Universal
   * works on mouse wheel and on touch
   */

  const {
    wheelRescaleEnabled,
    handleWheel,

    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  } = useRescaleUniversal({
    getScale: () => {
      return scale;
    },


    /**
     * Rescale by Wheel
     */

    onWheel: (
      scaleInit, 
      scaleNew, 
      scalePoint,
    ) => {
      const scalePointAdjusted = adjustPoint(scalePoint);
      const scaleNewValidated = validateScale(scaleNew);

      const {
        scrollLeft,
        scrollTop,
      } = scrollUpdate({
        scrollbarInit: scrollerRef.current !,
        scaleInit,
        scaleNew: scaleNewValidated,
        scalePointInit: scalePointAdjusted,
        scalePointNew: scalePointAdjusted,
      });
  
      setScrollProps({ scrollLeft, scrollTop });
      updateScale(scaleNewValidated);
    },


    /**
     * Rescale by Touch
     */

    onTouchStart: () => {
      const element = scrollerRef.current !;

      scrollPropsInit.current = {
        scrollLeft: element.scrollLeft,
        scrollTop: element.scrollTop,
      }

      updateScrollerBBox();
    },
    onTouchMove: (
      scaleInit: number, 
      scaleNew: number, 
      scalePointInit: Position,
      scalePointNew: Position,
    ) => {
      const scalePointInitAdjusted = adjustPoint(scalePointInit);
      const scalePointNewAdjusted  = adjustPoint(scalePointNew);
      const scaleNewValidated = validateScale(scaleNew);

      const {
        scrollLeft,
        scrollTop,
      } = scrollUpdate({
        scrollbarInit: scrollPropsInit.current,
        scaleInit,
        scaleNew: scaleNewValidated,
        scalePointInit: scalePointInitAdjusted,
        scalePointNew: scalePointNewAdjusted,
      });

      const THROTTLED = true; 

      if ( THROTTLED ) {
        updateMove({
          scrollLeft,
          scrollTop,
          scaleNewValidated
        });
      }
      else {
        setScrollProps({ scrollLeft, scrollTop });
        updateScale(scaleNewValidated);
      }
    },
    onTouchEnd: () => {

    }
  });

  const updateMove = useThrottledCallback((props:{
    scrollLeft: number,
    scrollTop: number,
    scaleNewValidated: number,
  })=> {
    const {
      scrollLeft,
      scrollTop,
      scaleNewValidated
    } = props;
    setScrollProps({ scrollLeft, scrollTop });
    updateScale(scaleNewValidated);
  }, 5);

  useEffect(() => {
    if ( ! wheelRescaleEnabled ) {
      return;
    }
    updateScrollerBBox();
  }, [wheelRescaleEnabled]);


  const validateScale = (scale: number) => {
    let scaleValidated = scale;
    scaleValidated = Math.max(scaleValidated, SCALE_MIN); 
    scaleValidated = Math.min(scaleValidated, SCALE_MAX);

    return scaleValidated;
  }

  const updateScale = (scaleNew: number) => {
    setScale(scaleNew);
  }

  const updateScrollerBBox = () => {
    const bbox = scrollerRef.current?.getBoundingClientRect();
    if (bbox === undefined ) {
      const msg = `Element scroller not ready`;
      throw new Error(msg);
    }

    scrollerBBoxRef.current = bbox;
  }

  const adjustPoint = (point: Position) => {
    const bbox = scrollerBBoxRef.current;
    if (! bbox) {
      const msg = `BBox scroller not ready, implementation error`;
      throw new Error(msg);
    }

    const scalePoint = [
      point[0] - bbox.left, 
      point[1] - bbox.top
    ] as Position;

    return scalePoint;
  }

  return (
    <Scroller
      ref={scrollerRef}
      $isZooming={wheelRescaleEnabled}
      onWheel={handleWheel}

      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <ScalerComponent
        scale={scale}
        contentSize={pageSize}
      >
        { children }
      </ScalerComponent>  
    </Scroller>
  );
}
