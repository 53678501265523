import { ThemeProps } from "../types";

import { themeConsts_accentColors } from './__const-accent-colors';
import { themeConsts_border       } from "./__const-border";
import { themeConsts_boxShadow    } from "./__const-box-shadow";
import { themeConsts_colors       } from "./__const-colors";
import { themeConsts_font         } from "./__const-font";
import { themeConsts_gap          } from "./__const-gap";
import { themeConsts_opacity      } from "./__const-opacity";
import { themeConsts_outline      } from "./__const-outline";
import { themeConsts_padding      } from "./__const-padding";
import { themeConsts_scale        } from "./__const-scale";
import { themeConsts_transition   } from "./__const-transition";

import { themeDefs_button           } from './__def-button';
import { themeDefs_buttonColor      } from "./__def-button-color";
import { themeDefs_buttonText       } from "./__def-button-text";
import { themeDefs_dialog           } from "./__def-dialog";
import { themeDefs_headerNormal     } from "./__def-header-normal";
import { themeDefs_imageEditor      } from "./__def-image-editor";
import { themeDefs_input            } from "./__def-input";
import { themeDefs_itemSelected     } from "./__def-item-selected";
import { themeDefs_menuBar          } from "./__def-menu-bar";
import { themeDefs_menuBarItem      } from "./__def-menu-bar-item";
import { themeDefs_menuBarItemPanel } from "./__def-menu-bar-item-panel";
import { themeDefs_menuItem         } from "./__def-menu-item";
import { themeDefs_panel            } from "./__def-panel";
import { themeDefs_panelFloating    } from "./__def-panel-floating";
import { themeDefs_scrollbar        } from "./__def-scrollbar";
import { themeDefs_toolbar          } from "./__def-toolbar";
import { themeDefs_tooltip          } from "./__def-tooltip";
import { themeDefs_window           } from "./__def-window";
import { themeDefs_margins          } from "./__def-margins";

import { themeAttrs } from "./__attrs";


const getTheme = (props: ThemeProps) => {
  const { 
    accentColor: accentColorType, 
    darkMode
  } = props;

  const constProps = {darkMode};
  const accentColors = themeConsts_accentColors(constProps);
  const border       = themeConsts_border(constProps);
  const boxShadow    = themeConsts_boxShadow(constProps);
  const colors       = themeConsts_colors(constProps);
  const font         = themeConsts_font(constProps);
  const gap          = themeConsts_gap(constProps);
  const opacity      = themeConsts_opacity(constProps);
  const outline      = themeConsts_outline(constProps);
  const padding      = themeConsts_padding(constProps);
  const scale        = themeConsts_scale(constProps);
  const transition   = themeConsts_transition(constProps);
  
  const accentColor  = accentColors[accentColorType];
  
  const defProps = {
    darkMode,
    accentColor,
    border,
    boxShadow,
    colors, 
    font,
    gap,
    opacity,
    outline,
    padding,
    transition,
    scale
  };

  const theme = {
    button:           themeDefs_button(defProps),
    buttonColor:      themeDefs_buttonColor(defProps),
    buttonText:       themeDefs_buttonText(defProps),
    dialog:           themeDefs_dialog(defProps),
    headerNormal:     themeDefs_headerNormal(defProps),
    imageEditor:      themeDefs_imageEditor(defProps),
    input:            themeDefs_input(defProps),
    itemSelected:     themeDefs_itemSelected(defProps), 
    menuBar:          themeDefs_menuBar(defProps),
    menuBarItem:      themeDefs_menuBarItem(defProps),
    menuBarItemPanel: themeDefs_menuBarItemPanel(defProps),
    menuItem:         themeDefs_menuItem(defProps),
    panel:            themeDefs_panel(defProps),
    panelFloating:    themeDefs_panelFloating(defProps),
    toolbar:          themeDefs_toolbar(defProps),
    scrollbar:        themeDefs_scrollbar(defProps),
    tooltip:          themeDefs_tooltip(defProps),
    window:           themeDefs_window(defProps),
    margins:          themeDefs_margins(defProps),

    attrs: themeAttrs(defProps),
    defs: defProps,
  };

  return theme;
}

export default getTheme;
