import i18next from 'i18next';
import jtl from 'tools/jtl';
import Logger from 'libs/debug';

import Errors from 'app/arch/tools/errors';

import { HistoryTypes } from '../history-state';
import { HistoryTools } from '../history-state';

import { ContentTools } from '../persistent/content';
import { ContentTypes } from '../persistent/content';
import { HeaderFieldsTools } from '../persistent/header-fields';
import { HeaderFieldsTypes } from '../persistent/header-fields';
import { HeaderRowsTools } from '../persistent/header-rows';
import { HeaderRowsTypes } from '../persistent/header-rows';
import { ReleaselogsTools } from '../persistent/releaselogs';
import { ReleaselogsTypes } from '../persistent/releaselogs';
import { RepoMarkersTypes } from '../persistent/repo-markers';
import { WidgetsStylesDefaults } from '../persistent/editor-image-widgets-styles';

/** Loadable */
import Instruction  from '../loadable/instruction';
import RepoImages   from '../loadable/repo-images';

/** Persistent */
import Content             from '../persistent/content';
import ContentView         from '../persistent/content-view';
import EditorImageSettings from '../persistent/editor-image-settings';
import HeaderFields        from '../persistent/header-fields';
import HeaderRows          from '../persistent/header-rows';
import HeaderMarkers       from '../persistent/header-markers';
import HeaderMeta          from '../persistent/header-meta';
import MetaData            from '../persistent/meta-data';
import Releaselogs         from '../persistent/releaselogs';
import ReleaselogsView     from '../persistent/releaselogs-view';
import RepoMarkers         from '../persistent/repo-markers';
import WidgetsStyles       from '../persistent/editor-image-widgets-styles';
import ViewsCommon         from '../persistent/views-common';

/** Selected */
import ContentRowsSelected    from '../sessions/content-rows-selected';
import ContentColumnsSelected from '../sessions/content-columns-selected';
import CustomRowsRowsSelected from '../sessions/custom-rows-rows-selected';
import CustomFieldsColumnsSelected from '../sessions/custom-fields-columns-selected';


/** Sessions */
import ContentSession       from '../sessions/content-session';
import EditorImageSession   from '../sessions/editor-image-session';
import EditorLogoSession    from '../sessions/editor-logo-session';
import EditorMarkerSession  from '../sessions/editor-marker-session';
import EditorSession        from '../sessions/editor-session';
import HeaderRowsSession    from '../sessions/header-rows-session';
import HeaderFieldsSession  from '../sessions/header-fields-session';
import HeaderMarkersSession from '../sessions/header-markers-session';
import HeaderMetaSession    from '../sessions/header-meta-session';
import ReleaselogsSession   from '../sessions/releaselogs-session';
import RepoImagesSession    from '../sessions/repo-images-session';

/** Slicing */
import { ContentElementsSizes } from '../slicing/content';
import { ContentSliceRequest }  from '../slicing/content';
import { ReleaselogsElementsSizes } from '../slicing/releaselogs';
import { ReleaselogsSliceRequest }  from '../slicing/releaselogs';

/** Printout */
import PrintoutView       from '../printout/printout-view';
import PrintoutReleaseInfo from '../printout/printout-release-info';

import HistoryState from '../history-state';

const IDX_COLUMN_WIDTH     = 36;
const TEXT_COLUMN_WIDTH    = 100;
const ROWS_COLUMN_WIDTH    = 180;
const IMAGES_COLUMN_WIDTH  = 80;
const MARKERS_COLUMN_WIDTH = 40;


export class DocState {
  static CreateInitialState(title: string) {
    const initState = new DocState();
    initState.metaData.setTitle(title);

    DocState.CreateInitialState_Content(initState);
    DocState.CreateInitialState_CustomFields(initState);
    DocState.CreateInitialState_CustomRows(initState);
    DocState.CreateInitialState_WidgetsStylesSystem(initState);
    DocState.CreateInitialState_Releaselog(initState);
    
    return initState;
  }

  static CreateInitialState_Content(state: DocState) {
    const t = i18next.t;

    const columnIdxId = state.content.addColumn(
      ContentTypes.ColumnType.INDEX, { 
        name: t("content, default column name, idx"),
        width: IDX_COLUMN_WIDTH,
      }
    );

    const columnTextId = state.content.addColumn(
      ContentTypes.ColumnType.TEXT, { 
        name: t("content, default column name, text"),
        width: TEXT_COLUMN_WIDTH,
      }
    );

    const columnImagesId = state.content.addColumn(
      ContentTypes.ColumnType.IMAGES, { 
        name: t("content, default column name, images"),
        width: IMAGES_COLUMN_WIDTH,
      }
    );

    const columnMarkersId = state.content.addColumn(
      ContentTypes.ColumnType.MARKERS, { 
        name: t("content, default column name, markers"),
        width: MARKERS_COLUMN_WIDTH,
      }
    );

    const sectionAddr = state.content.addSection();
    ContentTools.columnsAutoAdjust(state);
  }
  
  static CreateInitialState_CustomFields(state: DocState) {
    const t = i18next.t;

    state.headerFields.addColumn(
      HeaderFieldsTypes.ColumnType.TEXT, { 
        name: t("custom fields, default column name, first"),
        width: TEXT_COLUMN_WIDTH,
      }
    );

    state.headerFields.addColumn(
      HeaderFieldsTypes.ColumnType.TEXT, { 
        name: t("custom fields, default column name, second"),
        width: TEXT_COLUMN_WIDTH,
      }
    );

    state.headerFields.addColumn(
      HeaderFieldsTypes.ColumnType.TEXT, { 
        name: t("custom fields, default column name, third"),
        width: TEXT_COLUMN_WIDTH,
      }
    );

    HeaderFieldsTools.columnsAutoAdjust(state);
  }

  static CreateInitialState_CustomRows(state: DocState) {
    const t = i18next.t;

    state.headerRows.addRow(
      HeaderRowsTypes.RowType.TEXT, { 
        name: t("custom rows, default name"),
        width: ROWS_COLUMN_WIDTH,
      }
    );
    
    HeaderRowsTools.columnsAutoAdjust(state);
  }

  static CreateInitialState_WidgetsStylesSystem(state: DocState) {
    state.widgetsStyles.populateSystemStyle();
  }

  static CreateInitialState_Releaselog(state: DocState) {
    const t = i18next.t;

    const releaselogAddr = state.releaselogs.addReleaselog();

    state.releaselogs.addColumn(
      releaselogAddr, 
      ReleaselogsTypes.ColumnType.INDEX, { 
        name: t("releaselog, default column name, idx"),
        width: IDX_COLUMN_WIDTH,
      }
    );

    state.releaselogs.addColumn(
      releaselogAddr,
      ReleaselogsTypes.ColumnType.TEXT, { 
        name: t("releaselog, default column name, summary"),
        width: 100,
      }
    );

    state.releaselogs.addColumn(
      releaselogAddr,
      ReleaselogsTypes.ColumnType.TEXT, { 
        name: t("releaselog, default column name, description"),
        width: 360,
      }
    );

    state.releaselogs.addRow(releaselogAddr);
    state.releaselogs.addRow(releaselogAddr);

    ReleaselogsTools.columnsSmartAdjust(state, releaselogAddr);
  }

  /** Loadable */
  private _instruction: Instruction;
  private _repoImages: RepoImages;

  /** Persistent */
  private _content: Content;
  private _contentView: ContentView;
  private _editorImageSettings: EditorImageSettings;
  private _headerFields: HeaderFields;
  private _headerRows: HeaderRows;
  private _headerMarkers: HeaderMarkers;
  private _headerMeta: HeaderMeta;
  private _metaData: MetaData;
  private _releaselogs: Releaselogs;
  private _releaselogsView: ReleaselogsView;
  private _repoMarkers: RepoMarkers;
  private _widgetsStyles: WidgetsStyles;
  private _viewsCommon: ViewsCommon;


  /** Selected */
  private _contentRowsSelected: ContentRowsSelected;
  private _contentColumnsSelected: ContentColumnsSelected;
  private _customRowsRowsSelected: CustomRowsRowsSelected;
  private _customFieldsColumnsSelected: CustomFieldsColumnsSelected;
  
  /** Sessions */
  private _contentSession: ContentSession;
  private _editorImageSession: EditorImageSession;
  private _editorLogoSession: EditorLogoSession;
  private _editorMarkerSession: EditorMarkerSession;
  private _editorSession: EditorSession;
  private _headerRowsSession: HeaderRowsSession;
  private _headerFieldsSession: HeaderFieldsSession;
  private _headerMarkersSession: HeaderMarkersSession;
  private _headerMetaSession: HeaderMetaSession;
  private _releaselogsSession: ReleaselogsSession;
  private _repoImagesSession: RepoImagesSession;

  /** Slicing */
  private _contentElementsSizes: ContentElementsSizes;
  private _contentSliceRequest: ContentSliceRequest;
  private _releaselogsElementsSizes: ReleaselogsElementsSizes;
  private _releaselogsSliceRequest: ReleaselogsSliceRequest;

  /** Printout */
  private _printoutView: PrintoutView;
  private _printoutReleaseInfo: PrintoutReleaseInfo;

  private _history: HistoryState | null;


  private _dirty: boolean;

  constructor() {
    /** Loadable */
    this._instruction   = new Instruction();
    this._repoImages    = new RepoImages();

    /** Persistent */
    this._content             = new Content();
    this._contentView         = new ContentView();
    this._editorImageSettings = new EditorImageSettings();
    this._headerFields        = new HeaderFields();
    this._headerRows          = new HeaderRows();
    this._headerMarkers       = new HeaderMarkers();
    this._headerMeta          = new HeaderMeta();
    this._metaData            = new MetaData();
    this._releaselogs         = new Releaselogs();
    this._releaselogsView     = new ReleaselogsView();
    this._repoMarkers         = new RepoMarkers();
    this._widgetsStyles       = new WidgetsStyles();
    this._viewsCommon         = new ViewsCommon();

    /** Selected */
    this._contentRowsSelected         = new ContentRowsSelected();
    this._contentColumnsSelected      = new ContentColumnsSelected();
    this._customRowsRowsSelected      = new CustomRowsRowsSelected();
    this._customFieldsColumnsSelected = new CustomFieldsColumnsSelected();
    
    /** Sessions */
    this._contentSession       = new ContentSession();
    this._editorImageSession   = new EditorImageSession();
    this._editorLogoSession    = new EditorLogoSession();
    this._editorMarkerSession  = new EditorMarkerSession();
    this._editorSession        = new EditorSession();
    this._headerFieldsSession  = new HeaderFieldsSession();
    this._headerRowsSession    = new HeaderRowsSession();
    this._headerMarkersSession = new HeaderMarkersSession();
    this._headerMetaSession    = new HeaderMetaSession();
    this._releaselogsSession   = new ReleaselogsSession();
    this._repoImagesSession    = new RepoImagesSession();

    /** Slicing */
    this._contentElementsSizes = new ContentElementsSizes();
    this._contentSliceRequest  = new ContentSliceRequest();
    this._releaselogsElementsSizes = new ReleaselogsElementsSizes();
    this._releaselogsSliceRequest  = new ReleaselogsSliceRequest();

    /** Printout */
    this._printoutView        = new PrintoutView();
    this._printoutReleaseInfo = new PrintoutReleaseInfo();

    this._history = null;

    this._dirty = false;
  }
  
  set dirty(dirty: boolean) { this._dirty = dirty; }
  get dirty() { return this._dirty; }

  /** Loadable */
  get instruction()   { return this._instruction; }
  get repoImages()    { return this._repoImages; }

  /** Persistent */
  get content()             { return this._content; }
  get contentView()         { return this._contentView; }
  get editorImageSettings() { return this._editorImageSettings; }
  get headerFields()        { return this._headerFields; }
  get headerRows()          { return this._headerRows; }
  get headerMarkers()       { return this._headerMarkers; }
  get headerMeta()          { return this._headerMeta; }
  get metaData()            { return this._metaData; }
  get releaselogs()         { return this._releaselogs; }
  get releaselogsView()     { return this._releaselogsView; }
  get repoMarkers()         { return this._repoMarkers; }
  get widgetsStyles()       { return this._widgetsStyles; }
  get viewsCommon()         { return this._viewsCommon; }

  
  /** Selected */
  get contentRowsSelected()    { return this._contentRowsSelected; }
  get contentColumnsSelected() { return this._contentColumnsSelected; }
  get customRowsRowsSelected() { return this._customRowsRowsSelected; }
  get customFieldsColumnsSelected() { return this._customFieldsColumnsSelected; }


  /** Sessions */
  get contentSession()       { return this._contentSession; }
  get editorImageSession()   { return this._editorImageSession; }
  get editorLogoSession()    { return this._editorLogoSession; }
  get editorMarkerSession()  { return this._editorMarkerSession; }
  get editorSession()        { return this._editorSession; }
  get headerFieldsSession()  { return this._headerFieldsSession; }
  get headerRowsSession()    { return this._headerRowsSession; }
  get headerMarkersSession() { return this._headerMarkersSession; }
  get headerMetaSession()    { return this._headerMetaSession; }
  get releaselogsSession()   { return this._releaselogsSession; }
  get repoImagesSession()    { return this._repoImagesSession; }
  
  /** Slicing */
  get contentSliceRequest()  { return this._contentSliceRequest; }
  get contentElementsSizes() { return this._contentElementsSizes; }
  get releaselogsSliceRequest()  { return this._releaselogsSliceRequest; }
  get releaselogsElementsSizes() { return this._releaselogsElementsSizes; }

  /** Printout */
  get printoutView()        { return this._printoutView; }
  get printoutReleaseInfo() { return this._printoutReleaseInfo; }
  

  private __initUndo() {
    const log = Logger.getDocUndo();
    log.info("Init undo");
    this._history = new HistoryState(this.getContentStates());

    log.debug(this._history);
  }

  testCrash() {
    this.content.testCrash();
    this.saveUndo();
  }

  testCrashHard() {
    this.content.testCrash();
    this.__initUndo();
    this.saveUndo();
  }

  saveUndo() {
    const log = Logger.getDocUndo();
    log.info("Save undo");
    log.debug(this._history);

    if (this._history === null) {
      // Undo has not been initialized yet
      // and we are not important in this state
      // changes as it just setup phase
      return;
    }

    const historyState = this._history.state.current;
    const currentState = this.getContentStates();

    const statesIdentical = HistoryTools.compareRecord(currentState, historyState);
    if (statesIdentical) {
      log.log("Same state - skipping undo save");
      return;
    }

    this._dirty = true;
    this._history.saveUndo(this.getContentStates());
  }

  resetUndo() {
    this.__initUndo();
  }

  undo() {
    const log = Logger.getDocUndo();
    log.debug("Undo");

    if (this._history === null) {
      const msg = `History is null while trying to undo`;
      Errors.hard(msg);
      return;
    }

    const record: HistoryTypes.Record = this._history.undo();
    this.loadStates(record);
  }

  redo() {
    const log = Logger.getDocUndo();
    log.debug("Redo");

    if (this._history === null) {
      const msg = `History is null while trying to redo`;
      throw new Error(msg);
    }

    const record: HistoryTypes.Record = this._history.redo();
    this.loadStates(record);
  }

  serialize() {
    const states = this.getContentStates();
    const stateSerial = jtl.serialize.serialize(states);
    return stateSerial;
  }

  private loadStates(record: HistoryTypes.Record) {
    this.content.state             = record.content;
    this.contentView.state         = record.contentView;
    this.editorImageSettings.state = record.editorImageSettings;
    this.headerFields.state        = record.headerFields;
    this.headerRows.state          = record.headerRows;
    this.headerMarkers.state       = record.headerMarkers;
    this.headerMeta.state          = record.headerMeta;
    this.metaData.state            = record.metaData;
    this.releaselogs.state         = record.releaselogs;
    this.releaselogsView.state     = record.releaselogsView;
    this.repoMarkers.state         = record.repoMarkers;
    this.viewsCommon.state         = record.viewsCommon;
  }

  getContentStates(): HistoryTypes.Record {
    const content             = this._content.state;
    const contentView         = this._contentView.state;
    const editorImageSettings = this._editorImageSettings.state;
    const headerFields        = this._headerFields.state;
    const headerRows          = this._headerRows.state;
    const headerMarkers       = this._headerMarkers.state;
    const headerMeta          = this._headerMeta.state;
    const metaData            = this._metaData.state;
    const releaselogs         = this._releaselogs.state;
    const releaselogsView     = this._releaselogsView.state;
    const repoMarkers         = this._repoMarkers.state;
    const widgetsStyles       = this._widgetsStyles.state;
    const viewsCommon         = this._viewsCommon.state;

    const states: HistoryTypes.Record = {
      content,
      contentView,
      editorImageSettings,
      headerFields,
      headerRows,
      headerMarkers,
      headerMeta,
      metaData,
      releaselogs,
      releaselogsView,
      repoMarkers,
      widgetsStyles,
      viewsCommon,
    };

    return states;
  }

  
  docMarker_delete(markerAddr: RepoMarkersTypes.MarkerAddr) {
    this._content.cellsMarkers_removeMarker(markerAddr);
    this._headerMarkers.removeMarkers(markerAddr);
    this._repoMarkers.deleteMarker(markerAddr);
  }



  //
  // Content2 releated
  //

  /**
   * Cell Images
   */


  cell_selectNextCell(srcCell: ContentTypes.CellAddr): 
  [ContentTypes.CellAddr, boolean]| null {
    const content = this._content;
    const contentSession = this._contentSession;

    const rows = content.getRowsAddrs(srcCell);
    const rowIdx = content.getRowIdxSection(srcCell);
    
    const nextRowIdx = rowIdx + 1;

    //
    // Last row in section
    //
    if (nextRowIdx >= rows.length) {
      const isLastSection = content.isSectionLast(srcCell);
      if ( isLastSection ) {
        return null;
      }

      let sectionIdx = content.getSectionIdx(srcCell);
      const sectionsAddrs = content.getSectionsAddrs();

      sectionIdx++;
      let nextSectionAddr = sectionsAddrs[sectionIdx];
      let rows = content.getSectionRows(nextSectionAddr);

      // Skip sections with no rows
      while (rows.addrs.length === 0 && sectionIdx < sectionsAddrs.length) {
        sectionIdx++;
        nextSectionAddr = content.getSectionsAddrs()[sectionIdx];
        rows = content.getSectionRows(nextSectionAddr);
      } 

      if (rows.addrs.length === 0) {
        return null;
      }

      const rowAddr = rows.addrs[0];

      const newCellAddr: ContentTypes.CellAddr = {
        columnId: srcCell.columnId,
        ...rowAddr,
      }

      let rowAdded = false;

      // If it is last section and it has only
      // one row - it has to be row adder
      if (rows.addrs.length === 1) {
        const isLastSection = content.isSectionLast(newCellAddr);
        if (isLastSection) {
          content.addRowBelow(newCellAddr);
          rowAdded = true;
        }
      }

      contentSession.setCellSelected(newCellAddr);
      return [newCellAddr, rowAdded];
    }


    //
    // `nextRowIdx` is not last row in section
    //
    const nextRowAddr = rows[nextRowIdx];
    const newCellAddr = {
      ...srcCell,
      rowId: nextRowAddr.rowId
    };

    let rowAdded = false;

    if (nextRowIdx === rows.length - 1) {
      const isLastSection = content.isSectionLast(newCellAddr);
      if (isLastSection) {
        content.addRowBelow(newCellAddr);
        rowAdded = true;
      }
    }

    contentSession.setCellSelected(newCellAddr);
    return [newCellAddr, rowAdded];
  }

  cellImages_image_addWidget(
    imageAddr: ContentTypes.ImageAddr,
    widgetType: ContentTypes.WidgetType,
  ): ContentTypes.WidgetAddr {
    const css = this._editorImageSettings.getWidgetStyle();

    const widgetAddr = this._content.cellImages_image_addWidget(
      imageAddr,
      widgetType,
      css,
    );
      
    return widgetAddr;
  }

  cellImages_image_setWidgetSelected(
    widgetAddr: ContentTypes.WidgetAddr
  ) {
    this._editorImageSession.setWidgetSelected(widgetAddr);

    const widgetProps = this._content.cellImages_image_getWidgetProps(widgetAddr);

    if (ContentTools.isWidgetArrowText(widgetProps.type)) {
      this._editorImageSession.setWidgetPartSelected(ContentTypes.WidgetPart.ARROW_BODY);
    }
    else if (ContentTools.isWidgetArrowTextLess(widgetProps.type)) {
      this._editorImageSession.setWidgetPartSelected(ContentTypes.WidgetPart.ARROW_BODY);
    }
    else {
      this._editorImageSession.setWidgetPartSelected(ContentTypes.WidgetPart.NONE);
    }
  }

  cellImages_image_copyWidgets(
    widgetAddrs: ContentTypes.WidgetAddr[]
  ) {
    const widgetsProps = widgetAddrs.map((widgetAddr) => {
      return this._content.cellImages_image_getWidgetProps(widgetAddr);
    });

    this._editorImageSession.clipboardWidgetsCopy(widgetsProps);
  }

  cellImages_image_pasteWidgets(
    dstImageAddr: ContentTypes.ImageAddr
  ) {
    const widgetsProps = this._editorImageSession.getClipboardWidgets() as ContentTypes.WidgetProps[];
    if (widgetsProps.length === 0) {
      console.warn("Can't paste widget(s)");
      return;
    }

    const widgetsAddrs = widgetsProps.map(widgetProp => {
      return this._content.cellImages_image_cloneWidget(
        dstImageAddr,
        widgetProp,
      );
    });

    if (widgetsAddrs.length === 1) {
      this._editorImageSession.setWidgetSelected(widgetsAddrs[0]);
    }
    else {
      // const widgetsIds = widgetsAddrs.map((widgetAddr) => widgetAddr.widgetId);
      // this._editorImageSession.setWidgetSelected(null);
      // this._editorImageSession.setWidgetPartSelected(null);
      // this._editorImageSession.setMultiSelectionSelectedWidgets(widgetsIds);
      // this._editorImageSession.setMultiSelectionImageAddr(imageId);
    }
  }

  cellImages_image_copyWidgetStyle(
    widgetAddr: ContentTypes.WidgetAddr
  ) {
    const widgetProps = this._content.cellImages_image_getWidgetProps(widgetAddr);
    this._editorImageSession.clipboardWidgetStyleCopy(widgetProps.style);
  }

  cellImages_image_pasteWidgetStyle(
    widgetAddr: ContentTypes.WidgetAddr
  ) {
    const widgetStyle = this._editorImageSession.getClipboardWidgetStyle();
    this._content.cellImages_image_widget_setStyle(
      widgetAddr,
      widgetStyle
    );
  }
}
