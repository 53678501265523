import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";


const useImageEdit = () => {
  const document = useDocState();
  const {
    setEditorImageSession
  } = useEditorStatesSetters();

  const hook = (imageAddr: ContentTypes.ImageAddr) => {
    document.editorImageSession.updateWindow({visible: true});
    document.editorImageSession.setImageEdited(imageAddr);
    setEditorImageSession();
  }

  return hook;
}


export default useImageEdit;