import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { IconContext } from 'react-icons';

import Icon from 'app/ui/icons/icons';
import NavbarPanelComponent from '../navbar-panel';
import useNavbarContext from '../../hooks/use-navbar-context';

import * as Types from '../../types';

import { GridRow1 } from './styles';
import { GridRow2 } from './styles';
import { PanelDetacher } from './styles';
import { Item } from './styles';
import { Text } from './styles';
import { IconWrapper } from './styles';


const CLOSE_DEBOUNCE_DELAY = 200;


export interface Props extends Types.NavbarItemProps {
}


export const NavbarItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    col1,
    col2,
    row1,
    row2,
    config, 
    open, 
  } = props;

  const timeoutHandler = useRef<any>(null);
  const IconExpand = Icon.Expand.More;
  
  const {
    openItem,
    closeItem,
  } = useNavbarContext();

  useEffect(() => {
    return () => {
      cancelLeave();
    }
  });
  
  const debouncedLeave = () => {
    cancelLeave();
    timeoutHandler.current = setTimeout(() => {
      closeItem();
    }, CLOSE_DEBOUNCE_DELAY);
  }

  const cancelLeave = () => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = null;
    }
  }

  const handleItemClick = (event: React.MouseEvent) => {
    config.onClick?.(event);
  };

  const handleItemEnter = (event: React.MouseEvent) => {
    openItem();
    cancelLeave();
  }

  const handlItemLeave = (event: React.MouseEvent) => {
    debouncedLeave();
  }

  const handlePanelEnter = (event: React.MouseEvent) => {
    cancelLeave();
  }

  const handlePanelLeave = (event: React.MouseEvent) => {
    debouncedLeave();
  }


  return (
    <>
      <GridRow1 
        column={col1}
        row={row1}
      >
        <Item
          data-test={config.dataTest}

          onClick={handleItemClick}
          onPointerEnter={handleItemEnter}
          onPointerLeave={handlItemLeave}

          open={open}
          selected={config.selected}
        >
          <Text>
          { config.text } 
          </Text>
          {
            config.panel &&
            <IconWrapper>
              <IconContext.Provider value={{ size: '10px' }} >
                <IconExpand />
              </IconContext.Provider>    
            </IconWrapper>
          }
        </Item>
      </GridRow1>

      { 
        open &&
        config.panel &&
        <GridRow2 
          column={col2} 
          row={row2}
        >
          <PanelDetacher
            onPointerEnter={handlePanelEnter}
            onPointerLeave={handlePanelLeave}
          >
            <NavbarPanelComponent config={config.panel}/>
          </PanelDetacher>
        </GridRow2>
      }
    </>
  );
}
