import * as Defaults from './defaults';
import * as Types from './types';


/**
 * State
 */

export type State = {
  oauth: Types.OAuth
}

export const getOAuth = (
  state: State
): Types.OAuth => { 
  const oauth = state.oauth
  return oauth;
}


/**
 * Initial state
 */

export const createInitialState = (): State => {
  const initState: State = {
    oauth: Defaults.getOAuth()
  }
  
  return initState;
}
