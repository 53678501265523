import useSelectUserFiles from "./use-select-user-files";
import { HookBaseProps } from "./types";


export interface HookProps extends HookBaseProps {
}


const useSelectUserImage = (props: HookProps) => {
  return useSelectUserFiles({
    ...props,
    multipleFiles: false,
    acceptFiles: "image/png, image/jpeg",
  });

};

export default useSelectUserImage;