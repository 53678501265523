import React from 'react';

import { useDocState } from 'app/ui/contexts/document';
import { Input } from './styles';


export const InputStyledComponent = React.forwardRef((
  props: React.InputHTMLAttributes<HTMLInputElement>, 
  ref: React.Ref<HTMLInputElement>
) => {
  const document = useDocState();
  const tableHeader = document.viewsCommon.getTableHeaderCSS();
  
  return (
    <Input
      {...props} 
      ref={ref} 

      $fontSize={tableHeader.fontSize!.toString()}
      $fontWeight={tableHeader.fontWeight!.toString()}
      $fontVariant={tableHeader.fontVariant!.toString()}
    />
  );
});
