import React from 'react';
import { useEffect } from 'react';

import useAppRemoveLoader  from 'app/ui-v2/app/hooks/use-app-remove-loader';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import FlexEditLogoComponent from 'app/ui-v2/hls/branding/flex-edit-logo';

import { BrowserSupport } from '../types';
import MessageComponent from './message';

import { MainWrapper } from './styles';
import { Padding } from './styles';
import { Content } from './styles';


interface Props {
  browserUnsupported: BrowserSupport
}


export const BrowserUnsupportedComponent: React.FC<Props> = (props: Props) => {
  const {
    browserUnsupported
  } = props;

  const t = useTranslations();
  const removeAppLoader = useAppRemoveLoader();

  useEffect(() => {
    removeAppLoader();
  }, []);

  const Msgs: {[support in BrowserSupport]: string} = {
    [BrowserSupport.INIT]: '', 
    [BrowserSupport.SUPPORTED]: '', 
    [BrowserSupport.UNSUPPORTED_SAFARI]: t('browser unsupported, safari'), 
  }

  const msg = Msgs[browserUnsupported];

  return (
    <MainWrapper>
      <Padding>
        <Content>

          <FlexEditLogoComponent />
          <MessageComponent msg={msg} />

        </Content>
      </Padding>
    </MainWrapper>
  );
}
