import useUndo from "app/ui-v2/editor-instruction/hooks/use-undo";
import useWidgetDuplicate from "app/ui-v2/editor-image/hooks/widget/use-widget-duplicate";
import useWidgetCopy from "app/ui-v2/editor-image/hooks/widget/use-widget-copy";
import useWidgetPaste from "app/ui-v2/editor-image/hooks/widget/use-widget-paste";


const useFunctions = () => {
  const {
    undo,
    redo
  } = useUndo();

  const duplicateWidget = useWidgetDuplicate();
  const copyWidget = useWidgetCopy();
  const pasteWidget = useWidgetPaste();


  return {
    undo,
    redo,

    duplicateWidget,
    copyWidget,
    pasteWidget,
  }
}

export default useFunctions;