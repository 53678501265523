import React from 'react';

import ConfettiComponent from 'lego-v2/confetti';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ViewPanelComponent  from 'app/ui-v2/home-page/hls/view-panel';

import { MsgWrapper } from './styles';
import { MsgHeader }   from './styles';
import { MsgText }     from './styles';
import { MsgEnd }      from './styles';


interface Props {
}


export const ViewPaymentFinalizeComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  return (
    <>
      <ConfettiComponent />
      <ViewPanelComponent title={t('payment completed')} >
        <MsgWrapper>
          <MsgHeader>
            { t('payment done, msg title') }
          </MsgHeader>

          <MsgText>
            { t('payment done, msg text') }
          </MsgText>

          <MsgEnd>
            { t('payment done, msg end') }
          </MsgEnd>
        </MsgWrapper>
      </ViewPanelComponent>
    </>
  );
}
  