import * as docx from "docx";
import jtl from "tools/jtl";

import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";

import AssetsRepo from "../../../parts/assets-repo";

import ExporterBase from "../../parts/exporter-base";
import TextConverter from "../../parts/text-converter";
import * as Defaults from '../../defaults';
import * as Tools from '../../tools';


class DocContentExporter extends ExporterBase {
  private _columnsWidthTotal: number;
  
  constructor(docState: DocState, assetsRepo: AssetsRepo) {
    super(docState, assetsRepo);
    this._columnsWidthTotal = 0;
  }

  get content() { return this._docState.content; }

  createSections() {
    this.calculateColumnsWidth();
    
    const tableHeader = this._addColumsHeader();
    const docxSections = this._addSections();
    
    return [
      tableHeader,
      ...docxSections,
    ];
  }

  private _addColumsHeader() {
    const columnsAddrs = this._docState.content.getColumnsAddrs();

    const cells = columnsAddrs.map(columnAddr => {
      return this._addColumnHeaderCell(columnAddr)
    });

    const row = new docx.TableRow({
      children: cells,
    });

    const table = new docx.Table({
      // layout: docx.TableLayoutType.FIXED,
      borders: this.getBordersNone(),
      rows: [ row ]
    });
    
    return table;
  }

  private _addColumnHeaderCell(
    columnAddr: ContentTypes.ColumnAddr,
  ): docx.TableCell {
    const sizePerc = this.getColumnSize(columnAddr);
    const columnProps = this.content.getColumnProps(columnAddr);
    const lastColumn = this.content.isColumnLast(columnAddr);
    const css = this._docState.viewsCommon.getTableHeaderCSS();

    const textRunOpt = Defaults.getTableHeaderOptions(css, {
      text: `${columnProps.name}`,
    });

    const padding = Tools.getCellPadding(css);

    const cell = new docx.TableCell({
      margins: padding,
      borders: this.getBordersCell(lastColumn),
      shading: this.getHeaderShading(),
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.LEFT,
        children:[new docx.TextRun(textRunOpt)]
      })]
    });
    
    return cell;
  }

  private _addSections() {
    const sectionsAddrs = this.content.getSectionsAddrs();
    const docxSections = sectionsAddrs.map(sectionAddr => this._addSection(sectionAddr));
    
    return docxSections;
  }

  private _addSection(sectionAddr: ContentTypes.SectionAddr) {
    const rows = this.content.getSectionRows(sectionAddr);
    const docxRows = rows.addrs.map(rowAddr => this._addRow(rowAddr));

    const config = this.content.getSectionsConfig();

    const rowsDefs = docxRows;
    if (config.enabled) {
      const tableSection = this._addSectionView(sectionAddr);
      rowsDefs.splice(0,0, tableSection);
    } 

    const table = new docx.Table({
      // layout: docx.TableLayoutType.FIXED,
      borders: this.getBordersNone(),
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: rowsDefs
    });

    return table;
  }

  private _addSectionView(sectionAddr: ContentTypes.SectionAddr) {
    const sectionProps = this.content.getSectionProps(sectionAddr);
    const columnsAddrs = this._docState.content.getColumnsAddrs();
    const borders = this.getBordersDefault();
    const columnSpan = columnsAddrs.length;

    const textHeader = Defaults.getTextHeaderOptions({
      text: `${sectionProps.name}`,
    });

    const cell = new docx.TableCell({
      borders,
      columnSpan,
      shading: this.getHeaderShading(),
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.LEFT,
        children:[new docx.TextRun(textHeader)]
      })],
    });

    const row = new docx.TableRow({
      children: [ cell ],
    });

    return row;
  }

  private _addRow(rowAddr: ContentTypes.RowAddr) {
    const cells = this._createRowCells(rowAddr);
    const row = new docx.TableRow({
      children: cells,
    });

    return row;
  }

  private _createRowCells(
    rowAddr: ContentTypes.RowAddr,
  ) {
    const columnsAddrs = this.content.getColumnsAddrs();

    const cells = columnsAddrs.map((columnAddr) => {
      const cellAddr: ContentTypes.CellAddr = {
        ...rowAddr,
        columnId: columnAddr.columnId
      };

      return this._copyCell(cellAddr)
    });

    return cells;
  }

  private _copyCell(
    cellAddr: ContentTypes.CellAddr,
  ) {
    const columnType = this.content.getColumnType(cellAddr);

    type GetCellContent = (
      cellAddr: ContentTypes.CellAddr,
    ) => docx.TableCell; 

    const copyFnsMap: {[key in ContentTypes.ColumnType]: GetCellContent } = {
      [ContentTypes.ColumnType.INDEX  ]: (cellAddr: ContentTypes.CellAddr) => this._copyCellIndex(cellAddr),
      [ContentTypes.ColumnType.TEXT   ]: (cellAddr: ContentTypes.CellAddr) => this._copyCellText(cellAddr),
      [ContentTypes.ColumnType.IMAGES ]: (cellAddr: ContentTypes.CellAddr) => this._copyCellImages(cellAddr),
      [ContentTypes.ColumnType.MARKERS]: (cellAddr: ContentTypes.CellAddr) => this._copyCellMarkers(cellAddr),
    }

    const copyCellFn = copyFnsMap[columnType];
    return copyCellFn(cellAddr);
  }

  private _getCellMargins(
    cellAddr: ContentTypes.CellAddr,
  ) {
    const columnProps = this.content.getColumnProps(cellAddr);
    const css = columnProps.css;
    const padding = Tools.getCellPadding(css);

    return padding;
  }

  private _copyCellIndex(
    cellAddr: ContentTypes.CellAddr,
  ): docx.TableCell {
    const idx = this.content.getRowIdxGlobal(cellAddr) + 1;
    const sizePerc = this.getColumnSize(cellAddr);
    const borders = this.getBordersCellSmart(cellAddr);
    const margins = this._getCellMargins(cellAddr);
    
    const css = this._docState.viewsCommon.getTableHeaderCSS();
    const color = jtl.color.expandHex(css.color);

    const text = `${idx}`;
    const textRunOpt = Defaults.getTextHeaderOptions({text, color});
    
    const cell = new docx.TableCell({
      borders,
      margins,
      shading: this.getHeaderShading(),
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.CENTER,
        children:[new docx.TextRun(textRunOpt)]
      })]
    });
    
    return cell;
  }

  private _copyCellText(
    cellAddr: ContentTypes.CellAddr,
  ): docx.TableCell {
    const cell = this.content.getCell(cellAddr) as ContentTypes.TextCell;
    if ( ! cell.editorState ) {
      throw new Error("Content exproter editor state is null");
    }

    const textConverter = new TextConverter();
    const paragraphs = textConverter.convert(cell.editorState);
    const sizePerc = this.getColumnSize(cellAddr);
    const borders = this.getBordersCellSmart(cellAddr);
    const margins = this._getCellMargins(cellAddr);

    const docxCell = new docx.TableCell({
      borders,
      margins,
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: paragraphs,
    });

    return docxCell;
  }

  private _copyCellImages(cellAddr: ContentTypes.CellAddr) {
    const sizePerc = this.getColumnSize(cellAddr);
    const images: docx.ImageRun[] = [];
    const cell = this.content.getCell(cellAddr) as ContentTypes.ImagesCell;
    const imagesAddrs = cell.images.addrs;

    for (let i = 0; i < imagesAddrs.length; i++) {
      const imageAddr = imagesAddrs[i];

      const repoItem = this._assetsRepo.cellImages.getItem(imageAddr);
      const {
        element,
        png: pngBase64,
      } = repoItem;

      if ( ! element ) {
        continue;
      }
      
      const bbox = element.getBoundingClientRect();
      const width  = bbox.width;
      const height = bbox.height;

      const imageWidth = width;
      const imageHeight = height;
      const imageCell = new docx.ImageRun({
        type: "png",
        data: pngBase64,
        transformation: {
          width: imageWidth, 
          height: imageHeight,
        },
      });

      images.push(imageCell)
    }

    const borders = this.getBordersCellSmart(cellAddr);
    const margins = this._getCellMargins(cellAddr);

    const docxCell = new docx.TableCell({
      borders,
      margins,
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.CENTER,
        children: images
      })]
    });

    return docxCell;
  }

  private _copyCellMarkers(cellAddr: ContentTypes.CellAddr): docx.TableCell {
    const sizePerc = this.getColumnSize(cellAddr);
    const markers: docx.ImageRun[] = [];
    const cell = this.content.getCell(cellAddr) as ContentTypes.MarkersCell;
    const markersAddrs = cell.markers.addrs;

    for (let i = 0; i < markersAddrs.length; i++) {
      const markerAddr = markersAddrs[i];

      const {
        element,
        png: pngBase64,
      } = this._assetsRepo.cellMarkers.getItem(markerAddr);

      if ( ! element ) {
        continue;
      }

      const bbox = element.getBoundingClientRect();
      const width  = bbox.width;
      const height = bbox.height;

      const marker = new docx.ImageRun({
        type: "png",
        data: pngBase64,
        transformation: { width, height },
      });

      markers.push(marker)
    }

    const borders = this.getBordersCellSmart(cellAddr);
    const margins = this._getCellMargins(cellAddr);

    const docxCell = new docx.TableCell({
      borders,
      margins,
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.CENTER,
        children: markers
      })]
    });

    return docxCell;
  }

  private calculateColumnsWidth() {
    const columnsAddrs = this.content.getColumnsAddrs();
    const totalWidth = columnsAddrs.reduce((acc, columnAddrs) => {
      const columnWidth = this.content.getColumnWidth(columnAddrs);
      return acc + columnWidth;
    }, 0);

    this._columnsWidthTotal = totalWidth;
  }

  private getColumnSize(
    columnAddr: ContentTypes.ColumnAddr,
  ) {
    const columnWidth = this.content.getColumnWidth(columnAddr);
    const sizePerc = (columnWidth / this._columnsWidthTotal) * 100;

    return sizePerc;
  }

  private getBordersCellSmart(
    cellAddr: ContentTypes.CellAddr,
  ) {
    return this.getBordersFull();

    // const lastRow = this.content.isRowLast(cellAddr);
    // const lastColumn = this.content.isColumnLast(cellAddr);
    // const borders = (lastRow ? this.getBordersFull() : this.getBordersCell(lastColumn));

    // return borders;
  }
}


export default DocContentExporter;