import produce from 'immer';
import * as Types from './types';
import * as State from './state';


export class HeaderMetaSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */

  updateLogo(
    update: Types.LogoUpdate
  ) {
    this._state = produce(this._state, draft => {
      const logo = State.getLogo(draft);
      Object.assign(logo, update);
    });
  }


  /**
   * Getters
   */

  getLogo() {
    return State.getLogo(this._state);
  }
}
