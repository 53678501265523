import { useRecoilValue } from 'recoil';
import { UIState_HeaderRows } from 'app/ui/states/editor-instruction';


const useStates = () => {
  const rowsAddrs = useRecoilValue(UIState_HeaderRows.rowsAddrs);

  return {
    rowsAddrs,
  }
}

export default useStates;