import PageHeader from './page-header';
import Table      from './table';
import Sections   from './sections';


export class ElementsSizes {
  private _pageHeader: PageHeader;
  private _table: Table;
  private _sections: Sections;

  constructor() {
    this._pageHeader = new PageHeader();
    this._table      = new Table();
    this._sections   = new Sections();
  }

  get pageHeader() { 
    return this._pageHeader; 
  }

  get table() { 
    return this._table; 
  }

  get sections() { 
    return this._sections;
  }
}
