import { useRecoilValue } from 'recoil';
import { UIState_Releaselogs } from 'app/ui/states/editor-instruction';
import useBaseWatcher from './use-base-watcher';


const useReleaselogActiveRowsAddrsWatcher = () => {
  const releaselogAddr = useRecoilValue(UIState_Releaselogs.releaselogActive);
  const rowsAddrs = useRecoilValue(UIState_Releaselogs.rowsAddrs(releaselogAddr));

  useBaseWatcher([rowsAddrs]);
}
  
export default useReleaselogActiveRowsAddrsWatcher;