import styled from "styled-components";


interface Props {
  columnsTemplate: string;
  rows?: number;
}

const ContentGrid = styled.div.attrs<Props>(props => ({
  style: {
    gridTemplateColumns: props.columnsTemplate,
    gridTemplateRows:(
      function() { 
        const rowsTemplate = (
          props.rows === undefined ?
          "auto" :
          `repeat(${props.rows}, auto)`
        );
        return rowsTemplate;
      }()
    )
  }
}))<Props>`
  display: grid;
`;

export default ContentGrid;
