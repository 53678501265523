import styled from "styled-components";
import SignBase from "./sign-base";
import { Config } from "../config";


const SignRectangle = styled(SignBase)`
  width:  ${Config.shape.rectangle.size}px;
  height: ${Config.shape.rectangle.size}px;

  border-radius: 10px;
`;


export default SignRectangle;