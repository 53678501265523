import produce from 'immer';
import { CornerType } from './corner-dot/types';
import { 
  MIN_IMG_HEIGHT, 
  MIN_IMG_WIDTH 
} from '../ImageViewDefiner';


export interface CornersState {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};


export const cornersStateReducer = (cornersState: CornersState, action: any) => {
  switch (action.type) {
    case 'update': return cmdUpdate(cornersState, action);
    case 'reset':  return cmdReset(cornersState, action);

    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}


const cmdReset = (cornersState: CornersState, action: any) => 
  produce(cornersState, draft => {
    draft.x1 = action.payload.x1;
    draft.x2 = action.payload.x2;
    draft.y1 = action.payload.y1;
    draft.y2 = action.payload.y2;
  });


const cmdUpdate = (cornersState: CornersState, action: any) => 
  produce(cornersState, draft => {

    //
    // Left top
    //
    if (action.payload.corner === CornerType.LEFT_TOP) {
      if (draft.x2 - action.payload.x < MIN_IMG_WIDTH) {
        draft.x1 = draft.x2 - MIN_IMG_WIDTH;
      }
      else {
        draft.x1 = action.payload.x;
      }

      if (draft.y2 - action.payload.y < MIN_IMG_HEIGHT) {
        draft.y1 = draft.y2 - MIN_IMG_HEIGHT;
      }
      else {
        draft.y1 = action.payload.y;
      }
    }

    //
    // Right top
    //
    if (action.payload.corner === CornerType.RIGHT_TOP) {
      if (action.payload.x - draft.x1 < MIN_IMG_WIDTH) {
        draft.x2 = draft.x1 + MIN_IMG_WIDTH;
      }
      else {
        draft.x2 = action.payload.x;
      }

      if (draft.y2 - action.payload.y < MIN_IMG_HEIGHT) {
        draft.y1 = draft.y2 - MIN_IMG_HEIGHT;
      }
      else {
        draft.y1 = action.payload.y;
      }
    }

    //
    // Left bottom
    //
    if (action.payload.corner === CornerType.LEFT_BOTTOM) {
      if (draft.x2 - action.payload.x < MIN_IMG_WIDTH) {
        draft.x1 = draft.x2 - MIN_IMG_WIDTH;
      }
      else {
        draft.x1 = action.payload.x;
      }

      if (action.payload.y - draft.y1 < MIN_IMG_HEIGHT) {
        draft.y2 = draft.y1 + MIN_IMG_HEIGHT;
      }
      else {
        draft.y2 = action.payload.y;
      }
    }

    //
    // Right bottom
    //
    if (action.payload.corner === CornerType.RIGHT_BOTTOM) {
      if (action.payload.x - draft.x1 < MIN_IMG_WIDTH) {
        draft.x2 = draft.x1 + MIN_IMG_WIDTH;
      }
      else {
        draft.x2 = action.payload.x;
      }

      if (action.payload.y - draft.y1 < MIN_IMG_HEIGHT) {
        draft.y2 = draft.y1 + MIN_IMG_HEIGHT;
      }
      else {
        draft.y2 = action.payload.y;
      }
    }
  });
