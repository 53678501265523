import styled from "styled-components";

export const WindowTopbar = styled.div`
  display: flex;
  justify-content: space-between;

  box-sizing: border-box;

  background: ${props => props.theme.defs.colors.background.primary};

  border-bottom-style: solid;
  border-bottom-width: ${props => props.theme.defs.border.width.normal};
  border-bottom-color: ${props => props.theme.defs.colors.border.primary};

  z-index: 1;
`;

export default WindowTopbar;