import React from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';
import useKeyBindings from './hooks/use-key-binding';


interface Props {
  markerAddr: Types.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerKeyBindingsComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;


  const {
    addBindings,
    removeBindings
  } = useKeyBindings(markerAddr);

  const isSelected = useRecoilValue(UIState_ContentSession.cellMarkers_isMarkerSelected(markerAddr));

  useEffect(() => {
    if (isSelected === false) {
      return;
    }

    addBindings();

    return () => {
      removeBindings();
    }
  }, [isSelected]);

  return children;
}

