import React, { useEffect } from 'react';

import PanelTitledComponent from 'app-views/components/panel-titled';
import { ViewTypes }   from 'app/arch/home-page-user/states/view';

import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import useDocFetch          from 'app/ui-v2/home-page/home-page-user/hooks/use-doc-fetch';
import useViewSelect        from 'app/ui-v2/home-page/home-page-user/hooks/use-view-select';
import useDocumentEdit      from 'app/ui-v2/editor-instruction/hooks/use-document-edit';
import useDocumentDelete    from 'app/ui-v2/editor-instruction/hooks/use-document-delete';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';

import DocumentInfoItemComponent from '../document-info-item';

import { ProgressWrapper } from './styles';


interface Props {
  documentId: number;
  onLoaded: () => void;
}


export const DocumentInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    documentId,
    onLoaded,
  } = props;

  const t = useTranslations();
  const editDocument = useDocumentEdit();
  const deleteDocument = useDocumentDelete();
  const selectView = useViewSelect();
  const {
    document,
    loaded: docLoaded
  } = useDocFetch({
    documentId,
    skipMsgs: true,
  });

  useEffect(() => {
    if ( ! docLoaded ) {
      return;
    }

    onLoaded();
  }, [docLoaded]);

  const handleDocumentEdit = () => {
    editDocument(documentId);
  }

  const handleDocumentDelete = () => {
    const onDone = () => {
      selectView(ViewTypes.ViewItem.DOCUMENTS);
    }

    deleteDocument({
      documentId,
      onDone
    });
  }

  const handleDocumentSelect = () => {
    editDocument(documentId);
  }

  const renderInstruction = () => {
    return (
      <PanelTitledComponent title={ t('document workspace') }>
        <DocumentInfoItemComponent 
          instruction={document ! }
          onDocumentEdit={handleDocumentEdit}
          onDocumentDelete={handleDocumentDelete}
          onDocumentSelect={handleDocumentSelect}
        />
      </PanelTitledComponent>
    );
  }

  
  return (
    <>
    { 
      ! docLoaded &&
      <ProgressWrapper>
        <ProgressBoxComponent title={t("document loading")} />
      </ProgressWrapper>
    }
    { docLoaded && renderInstruction() }
    </>
  );
}
  
