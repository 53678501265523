import { atom }     from 'recoil';
import { selector } from 'recoil';
import { EditorMarkerSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/editor-marker-session';


export const state = atom<State.State>({
  key: "editorMarkerSession",
  default: State.createInitialState()
});



//--------------------
//
// Selectors
//

export const windowVisible = selector({
  key: 'editorMarkerSession_windowVisible',
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getWindow(stateObj).visible;
  }
});

export const selectedMarkerAddr = selector({
  key: 'editorMarkerSession_selectedMarkerAddr',
  get: ({ get }) => {
    return get(state).editor.selectedMarkerAddr;
  }
});

export const iconSearchName = selector({
  key: 'editorMarkerSession_iconSearchName',
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getEditor(stateObj).iconSearchName;
  }
});
  