import { atom }           from 'recoil';
import { selector }       from 'recoil';
import { selectorFamily } from 'recoil';

import { HeaderRowsState as State} from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { HeaderRowsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-rows';

/**
 * State
 */

export const state = atom<State.State>({
  key: "ei_docContent_docCustomRows",
  default: State.createInitialState()
});


/**
 * Row(s)
 */

export const rowsAddrs = selector({
  key: `ei_docContent_docCustomRows_rowsAddrs`,
  get: ({ get }) => {
    const stateObj = get(state);
    const rowsAddrs = State.getRowsAddrs(stateObj);
    return rowsAddrs;
  }
});

export const rowName = selectorFamily({
  key: `ei_docContent_docCustomRows_rowName`,
  get: (rowAddr: Types.RowAddr) => ({ get }) => {
    const stateObj = get(state);
    const rowProps = State.getRowProps(stateObj, rowAddr);
    return rowProps.name;
  }
});

export const rowHeaderCSS = selectorFamily({
  key: "ei_docContent_docCustomRows_rowHeaderCSS",
  get: (rowAddr: Types.RowAddr | null) => ({ get }) => {
    if (rowAddr === null) {
      return null;
    }
    
    const stateObj = get(state);
    const rowProps = State.getRowProps(stateObj, rowAddr);
    return rowProps.header.css;
  }
});

export const rowCSS = selectorFamily({
  key: `ei_docContent_docCustomRows_rowCSS`,
  get: (rowAddr: Types.RowAddr | null)  => ({ get }) => {
    if (rowAddr === null) {
      return null;
    };

    const stateObj = get(state);
    const rowProps = State.getRowProps(stateObj, rowAddr);
    return rowProps.css;
  }
});


/**
 * Cell Text
 */

export const cellText_text = selectorFamily({
  key: `ei_docContent_docCustomRows_cellText_text`,
  get: (cellAddr: Types.CellAddr) => ({ get }) => {
    const stateObj = get(state);
    const cell = State.getCell(stateObj, cellAddr);
    const cellText = cell as Types.TextCell;
    const text = cellText.text;

    return text;
  }
});

export const columnWidth = selectorFamily({
  key: `ei_docContent_docCustomRows_columnWidth`,
  get: (columndIdx: Types.ColumnIdx) => ({ get }) => {
    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columndIdx);
    return columnProps.width;
  }
});