import React from 'react';
import { CellMenuProps } from '../types';
import { useEditor } from 'app/ui-v2/editor-instruction/hooks/use-editor';
import LexicalToolbarComponent from 'app/ui-v2/editor-instruction/hls/lexical/lexical-toolbar';
import { TopToolbar } from './styles';


interface Props extends CellMenuProps {
}


export const CellTextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr
  } = props;

  const editor = useEditor();
  const lexicalRegistry = editor.lexicalRegistrChangelog;
  const lexical = lexicalRegistry.getLexical(cellAddr);

  return (
    <TopToolbar>
      <LexicalToolbarComponent lexical={lexical} />
    </TopToolbar>
  );
}
  
