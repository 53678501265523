import React from 'react';
import { useRecoilValue } from 'recoil';
import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content, UIState_ContentSession } from 'app/ui/states/editor-instruction';
import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';

import { CSSWrapper } from './styles';


interface Props {
  sectionAddr: TypesContent.SectionAddr;
  children: React.ReactNode;
}


export const SecitionNameCSSComponent: React.FC<Props> = (props: Props) => {
  const {
    sectionAddr,
    children
  } = props;

  const tableHeaderCSS   = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);
  const sectionsNamesCSS = useRecoilValue(UIState_Content.sectionsNamesCSS);
  const sectionSelected  = useRecoilValue(UIState_ContentSession.isSectionSelected(sectionAddr));

  const {
    color,
    background
  } = tableHeaderCSS;

  return (
    <CSSWrapper
      style={sectionsNamesCSS}
      $selected={sectionSelected}
      $color={color as string}
      $background={background as string}
    >
      { children }
    </CSSWrapper>
  );
}
  
