import { atom } from 'recoil';
import { selector } from 'recoil';
import { NavbarState as State } from 'app/arch/home-page-user/states/navbar';


export const state = atom<State.State>({
  key: "homePageUser_navbar",
  default: State.createInitialState()
});

export const itemSelected = selector({
  key: `homePageUser_navbar_itemSelected`,
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.itemSelected;
  }
});
