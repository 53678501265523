import * as Types from './types';


export const getState = (): Types.State => {
  const initState: Types.State = {
    user: getStyles(),
    system: getStyles(),
  }

  return initState;
}


export const getStyles = (): Types.Styles => ({
  addrs: [],
  props: {},
});


export const getStyleProps = (): Types.StyleProps => ({
  name: "no name",
  style: {
  }
})


export const system_getStylesProps = (): Types.StyleProps[] => ([
  {
    name: "Red default",
    style: {
      arrowHeadWidth:  "18px",
      arrowHeadHeight: "28px",
      arrowShaftWidth: "0px",
    
      // If backgroundColor is unset,
      // for ellipse, rectange it will have backgroundColor: transparent
      // and for text, arrowText it will have backgroundColor: white
      // backgroundColor: "transparent",
      
      borderColor:  "#FF3B30",
      borderRadius: "12px",
      borderWidth:  "9px",
      outlineColor: "#FFFFFF",
      outlineWidth: "3px",
      textMarginBottom: "8px",
      textMarginLeft:   "8px",
      textMarginRight:  "8px",
      textMarginTop:    "8px",
    },
  },
  {
    name: "Red and black",
    style: {
      arrowHeadHeight: "32px",
      arrowHeadWidth: "17px",
      arrowShaftWidth: "4.5px",
      backgroundColor: "#FFFFFF",
      borderColor: "#FF3B30",
      borderRadius: "12px",
      borderWidth: "6px",
      outlineColor: "#2A2A2A",
      outlineWidth: "2px",
      textMarginBottom: "8px",
      textMarginLeft: "8px",
      textMarginRight: "8px",
      textMarginTop: "8px",
    },
  },
  {
    name: "Red simple",
    style: {
      arrowHeadHeight: "40px",
      arrowHeadWidth: "20px",
      arrowShaftWidth: "3px",
      backgroundColor: "#FFFFFF",
      borderColor: "#FF3B30",
      borderRadius: "12px",
      borderWidth: "2px",
      outlineColor: "#545454",
      outlineWidth: "1px",
      textMarginTop: "8px",
      textMarginLeft: "8px",
      textMarginRight: "8px",
      textMarginBottom: "8px"
    },
  },
  {
    name: "Blue simple",
    style: {
      arrowHeadHeight: "45px",
      arrowHeadWidth: "26px",
      arrowShaftWidth: "5px",
      backgroundColor: "#FFFFFF",
      borderColor: "#5AC8FA",
      borderRadius: "12px",
      borderWidth: "2px",
      outlineColor: "#007AFF",
      outlineWidth: "2px",
      textMarginTop: "6px",
      textMarginLeft: "6px",
      textMarginRight: "6px",
      textMarginBottom: "6px"
    },
  }
]);


export const getStyleAttrs = (): Types.StyleAttrs => ({
  arrowHeadHeight: "46px",
  arrowHeadWidth: "23px",
  arrowShaftWidth: "10px",

  backgroundColor: "#FFFFFF",
  borderColor: "#FF3B30",
  borderRadius: "12px",
  borderWidth: "7px",

  outlineColor: "#FFFFFF",
  outlineWidth: "3px",
  
  textMarginBottom: "8px",
  textMarginLeft:   "8px",
  textMarginRight:  "8px",
  textMarginTop:    "8px",
});
