import { useState }  from 'react';
import { useEffect } from 'react';

import { query }       from 'app/arch/backend';
import { useQueryV2 }  from 'app/arch/backend/use-query-v2';
import { Instruction } from 'app/arch/backend/types';
import { DocumentRelease } from 'app/arch/backend/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';


const useDocReleasesFetch = (props: {
  documentId: number,
  skipMsgs?: boolean,
}) => {

  const {
    documentId,
    skipMsgs,
  } = props;

  const t = useTranslations();

  const [
    document, 
    setDocument
  ] = useState<Instruction | null>(null);

  const [
    docReleases, 
    setDocReleases
  ] = useState<DocumentRelease[]>([]);

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    setLoaded(false);
    setDocument(null);
    setDocReleases([]);
    setError(null);
  }, [documentId]);

  const msgs = (
    skipMsgs ? 
    {} : 
    {
      onStart: { msg: t("document loading") },
      onEnd: { msg: t("document loaded") }
    }
  );

  const { 
    error: loadDocReleasesError, 
    data: loadDocReleasesData
  } = useQueryV2({
    query: query.getInstructionReleases(),
    variables: { instructionId: documentId },
    msgs
  });


  /**
   * Query data
   */
  useEffect(() => {
    if ( ! loadDocReleasesData ) {
      return;
    }

    const data = loadDocReleasesData.instructionReleases;
    setDocument(data.instruction);
    setDocReleases(data.releases);
    setError(null);
    setLoaded(true);
  }, [loadDocReleasesData]);


  /**
   * Query error
   */
  useEffect(() => {
    if ( ! loadDocReleasesError) {
      return;
    }

    setError(loadDocReleasesError.message);
    setDocument(null);
    setDocReleases([]);
    setLoaded(true);
  }, [loadDocReleasesError]);


  return {
    document,
    docReleases,
    loaded,
    error
  }
}

export default useDocReleasesFetch;