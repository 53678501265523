import produce from 'immer';
import jtl from 'tools/jtl';

import { Size } from 'app/arch/types';
import { Position }    from 'app/arch/types';
import { WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";
import { SideToolbar } from 'app/arch/editor-image/types';
import { WidgetStylesLibraryType } from 'app/arch/types/types-v2';

import { OrientedSmartLinesRaw } from 'tools/smart-lines/types';
import { StickyLinesRaw }        from 'tools/smart-lines/types';

import { ContentTypes } from '../../persistent/content';
import { ContentTools } from '../../persistent/content';

import * as Defaults from './defaults';
import * as Types    from './types';


//----------------------------
//
// State's
//



export interface Panel {
  visible: boolean,
  position: Position | null,
};

export type State = {
  window: Types.Window,

  // Name it like editor markers
  // editor: {
  //   selectedMarkerId: UUID | null,
  // }
  image: {
    edited: ContentTypes.ImageAddr | null,
  } 

  widget: {
    edited: ContentTypes.WidgetAddr | null,
    selected: ContentTypes.WidgetAddr | null,
    selectedPart: ContentTypes.WidgetPart | null,

    resizingSize: Size | null, 
  },



  sideToolbar: {
    panel: {
      type: SideToolbar.PanelType,
      visible: boolean,
      items: {
        styles: {
          libary: WidgetStylesLibraryType
        }
      }
    }
  },

  scale: number,

  viewDefiner: Types.ViewDefiner,
  
  clipboard: {
    widgetsProps: ContentTypes.WidgetProps[],
    widgetStyle: WidgetsStylesTypes.StyleAttrs
  },

  smartLinesWidgets: {
    lines: OrientedSmartLinesRaw | null,
    stickyLines : StickyLinesRaw | null,
    srcLines: OrientedSmartLinesRaw | null,
  },

  mutliSelection: {
    selectedImageAddr: ContentTypes.ImageAddr | null,
    selectedWidgets: ContentTypes.WidgetAddr[] | null,
  }

};


//----------------------------
//
// Producers
//

/**
 * Image
 */
export const setImageEdited = produce((draft: State, imageAddr: ContentTypes.ImageAddr | null) => {
  draft.image.edited = imageAddr;
});



/**
 * 
 * Smart lines
 * 
 */
export const setSmartLinesWidgetsLines = produce((
  draft: State, 
  lines: OrientedSmartLinesRaw | null
) => {
  draft.smartLinesWidgets.lines = lines;
});

export const setSmartLinesWidgetsSrcLines = produce((
  draft: State, 
  lines: OrientedSmartLinesRaw | null
) => {
  draft.smartLinesWidgets.srcLines = lines;
});

export const setSmartLinesWidgetsStickyLines = produce((
  draft: State, 
  stickyLines: StickyLinesRaw
) => {
  draft.smartLinesWidgets.stickyLines = stickyLines
});

export const unsetSmartLinesWidgetsStickyLines = produce((
  draft: State
) => {
  draft.smartLinesWidgets.stickyLines = null;
});





export const setScale = produce((draft: State, scale: number) => {
  draft.scale = scale;
});


export const setSideToolbarPanelType = produce((draft: State, panelType: SideToolbar.PanelType) => {
  draft.sideToolbar.panel.type = panelType;
});

export const setSideToolbarPanelVisible = produce((draft: State, visible: boolean) => {
  draft.sideToolbar.panel.visible = visible;
});

export const setSideToolbarPanel_stylesLibraryType = produce((draft: State, widgetStylesLibrary: WidgetStylesLibraryType) => {
  draft.sideToolbar.panel.items.styles.libary = widgetStylesLibrary;
});

export const clipboardWidgetStyleCopy = produce((draft: State, style: WidgetsStylesTypes.StyleAttrs) => {
  const styleClone = jtl.object.copy(style);
  draft.clipboard.widgetStyle = styleClone;
});

export const setMultiSelectionImageAddr = produce((draft: State, imageAddr: ContentTypes.ImageAddr | null) => {
  draft.mutliSelection.selectedImageAddr = imageAddr;
});

export const setMultiSelectionSelectedWidgets = produce((draft: State, selectedWidgets: ContentTypes.WidgetsAddrs | null) => {
  draft.mutliSelection.selectedWidgets = selectedWidgets;
});


//----------------------------
//
// Getters
//
export const getImageEdited = (state: State) => state.image.edited;

/**
 * Widget
 */
export const getWidgetSelected = (state: State) => state.widget.selected;

export const isWidgetSelected = (
  state: State, 
  widgetAddr: ContentTypes.WidgetAddr
): boolean => {
  const selectedWidget = getWidgetSelected(state);
  const isSelected = (
    selectedWidget !== null &&
    ContentTools.compareWidgetAddr(selectedWidget, widgetAddr)
  );

  return isSelected;
}

export const getWidgetPartSelected = (
  state: State,
  widgetAddr: ContentTypes.WidgetAddr
): ContentTypes.WidgetPart => {
  const isSelected = isWidgetSelected(state, widgetAddr);
  if ( ! isSelected ) {
    return ContentTypes.WidgetPart.NONE;
  }

  const widgetPartSelected = state.widget.selectedPart;
  if (widgetPartSelected === null) {
    return ContentTypes.WidgetPart.NONE;
  }

  return widgetPartSelected;
}

export const getWidgetEdited = (state: State) => state.widget.edited;
export const isWidgetEdited = (
  state: State, 
  widgetAddr: ContentTypes.WidgetAddr
): boolean => {
  const editedWidget = getWidgetEdited(state);
  const isEdited = (
    editedWidget !== null &&
    ContentTools.compareWidgetAddr(editedWidget, widgetAddr)
  );

  return isEdited;
}


export const getWidgetResizingSize = (state: State) => state.widget.resizingSize;


export const getSideToolbarPanelType = (state: State) => {
  return state.sideToolbar.panel.type;
}

export const getSideToolbarPanel_stylesLibraryType = (state: State) => {
  return state.sideToolbar.panel.items.styles.libary;
}

export const getSmartLinesWidgetsLines       = (state: State) => state.smartLinesWidgets.lines;
export const getSmartLinesWidgetsStickyLines = (state: State) => state.smartLinesWidgets.stickyLines;
export const getSmartLinesWidgetsSrcLines    = (state: State) => state.smartLinesWidgets.srcLines;


export const getWindow         = (state: State) => state.window;
export const getWindowPosition = (state: State) => state.window.position;
export const getWindowSize     = (state: State) => state.window.size;

export const getMultiSelectionSelectedWidgets = (state: State) => state.mutliSelection.selectedWidgets;
export const getMultiSelectionImageAddr = (state: State) => state.mutliSelection.selectedImageAddr;

export const getScale = (state: State) => state.scale;
export const getViewDefiner = (state: State) => state.viewDefiner;


//----------------------------
//
// Create initial state
//

export const createInitialState = () => {
  const state: State = {
    window: Defaults.getWindow(),

    image: {
      edited: null 
    },
    
    widget: {
      edited: null,
      selected: null,
      selectedPart:  null,
      
      resizingSize: null
    },


    scale: 1,
    viewDefiner: Defaults.getViewDefiner(),

    sideToolbar: {
      panel: {
        type: SideToolbar.PanelType.COLORS,
        visible: false,
        items: {
          styles: {
            libary: WidgetStylesLibraryType.USER
          }
        }
      }
    },

    clipboard: {
      widgetsProps: [],
      widgetStyle: {}
    },

    smartLinesWidgets: {
      lines: null,
      stickyLines:  null,
      srcLines: null,
    },

    mutliSelection: {
      selectedImageAddr: null,
      selectedWidgets: null,
    }

  }

  return state;
}
