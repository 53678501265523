import * as Tools from './tools';
import * as Types from './types';
import * as Defaults from './defaults';


/**
 * State
 */

export type State = {
  markers: Types.Markers,
};


/**
 * Marker 
 */

export const getMarkerProps = (
  state: State, 
  markerAddr: Types.MarkerAddr
): Types.MarkerProps => {
  const markerKey = Tools.getMarkerKey(markerAddr);
  const markersProps = getMarkersProps(state);
  const markerProps = markersProps[markerKey];

  if (markerProps === undefined) {
    const msg = `Marker not found`;
    throw new Error(msg);
  }

  return markerProps;
}
    

export const getMarkerIdx = (
  state: State, 
  markerAddr: Types.MarkerAddr
): number => {
  const markersAddrs = getMarkersAddrs(state);
  const idx = markersAddrs.findIndex((markerAddr_) => Tools.compareMarkerAddr(markerAddr_, markerAddr));
  if ( idx === -1 ) {
    const msg = 'Marker not found';
    throw new Error(msg);
  }

  return idx;
}


/**
 * Markers
 */

export const getMarkers      = (state: State) => state.markers;
export const getMarkersAddrs = (state: State) => state.markers.addrs;
export const getMarkersProps = (state: State) => state.markers.props;


/**
 * Initial State
 */

export const createInitialState = (): State => {
  const initState: State = {
    markers: Defaults.getMarkers(),
  }

  return initState;
}
