import React from 'react';
import { useIsMobile } from 'lego-hooks/use-is-mobile';
import DndDropMouseComponent from './dnd-drop-mouse';
import DndDropTouchComponent from './dnd-drop-touch';
import * as Types from './types';


interface Props extends Types.DNDDropProps {
}


export const DNDDropComponent: React.FC<Props> = (props: Props) => {
  const isMobile = useIsMobile();

  return (
    <>
      { ! isMobile && <DndDropMouseComponent {...props}/> }
      { isMobile && <DndDropTouchComponent {...props}/> }
    </>
  );
}
