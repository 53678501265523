import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import { Icon }            from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDocState }     from 'app/ui/contexts/document';
import useMarkerEdit       from 'app/ui-v2/editor-markers/hooks/use-marker-edit';

import useContextMenu         from '../../../../../hooks/use-context-menu';
import useEditorStatesSetters from '../../../../../hooks/use-editor-states-setters';
import useCellMarkerCreate    from './use-cell-marker-create';



const useCellMarkerMenu = () => {
  const t = useTranslations();
  const document = useDocState();

  const {
    hideMenu
  } = useContextMenu();

  const {
    setContent,
  } = useEditorStatesSetters();

  const createCellMarker = useCellMarkerCreate();

  const createMarker = (cellAddr: Types.CellAddr) => {
    createCellMarker(cellAddr);
    hideMenu();
  }

  const editMarker__ = useMarkerEdit();

  const editMarker = (markerAddr: Types.MarkerAddr) => {
    const markerProps = document.content.cellMarkers_getMarkerProps(markerAddr);
    editMarker__(markerProps.repoMarkerAddr);
    hideMenu();
  }

  const removeMarker = (markerAddr: Types.MarkerAddr) => {
    document.content.cellMarkers_removeMarker(markerAddr);
    document.saveUndo();
    setContent();

    hideMenu();
  }

  const getConfig = (markerAddr: Types.MarkerAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, new'),
                iconComponent: Icon.Create,
                onClick: (event: React.MouseEvent) => createMarker(markerAddr),
              }
            },                     
          ],
        },
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, edit'),
                iconComponent: Icon.Edit.Plain,
                onClick: (event: React.MouseEvent) => editMarker(markerAddr),
              }
            },  
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, remove'),
                iconComponent: Icon.Remove,
                variant: 'delete',
                onClick: (event: React.MouseEvent) => removeMarker(markerAddr),
              }
            }, 
          ],
        },
      ]
    }

    return config;
  }


  const hook = (markerAddr: Types.MarkerAddr) => {
    const config = getConfig(markerAddr);
    return config;
  }

  return hook;
}


export default useCellMarkerMenu;