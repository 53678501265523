import React from 'react';

import ProgressModalComponent from 'lego-v2/progress-modal';
import { StripeSubscriptionModalComponent } from 'lego-v2/stripe/stripe-subscription-modal';

import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';
import DialogCustomComponent from 'app/ui/components/editor-instruction/dialogs/dialog-custom';
import DialogEnterSingleLineComponent from 'app/ui/components/editor-instruction/dialogs/dialog-enter-single-line';
import DemoComponent from 'app/ui-v2/app/__modules/demo';
import useDemoActive from 'app/ui-v2/app/__modules/demo/hooks/use-demo-active';
import InfoComponent from 'app/ui-v2/app/__modules/info';
import ReloginComponent from 'app/ui-v2/app/__modules/modals/relogin';

import { ModalsWrapper }   from './styles';
import { ProgressWrapper } from './styles';
import { StripeWrapper }   from './styles';
import { InfoWrapper }    from './styles';
import { DemoWrapper }    from './styles';
import { ReloginWrapper } from './styles';


interface Props {
}

export const ModalsComponent: React.FC<Props> = (props: Props) => {
  const isPrintout = useIsPrintout();
  const isDemoActive = useDemoActive();
  
  if ( isPrintout ) {
    return null;
  }

  return (
    <ModalsWrapper>

      <ProgressWrapper>
        <ProgressModalComponent />
      </ProgressWrapper>

      <InfoWrapper>
        <InfoComponent />
      </InfoWrapper>

      <StripeWrapper>
        <StripeSubscriptionModalComponent />
      </StripeWrapper>

      {/* {
        isDemoActive &&
        <DemoWrapper>
          <DemoComponent />
        </DemoWrapper>
      } */}

      <DialogCustomComponent />
      <DialogEnterSingleLineComponent /> 

      <ReloginWrapper>
        <ReloginComponent />
      </ReloginWrapper>
    </ModalsWrapper>
  );
}

