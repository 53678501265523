import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";


const useWidgetPaste = () => {
  const docState = useDocState();

  const {
    setContent,
    setEditorImageSession,
  } = useEditorStatesSetters();

  
  const pasteWidget = (imageAddr: ContentTypes.ImageAddr) => {
    docState.cellImages_image_pasteWidgets(imageAddr);
    docState.saveUndo();
    setContent();
    setEditorImageSession();
  }

  return pasteWidget;
}


export default useWidgetPaste;