import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';

import { Icon }            from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";

import useDocHeaderMarkerCreate from './use-doc-header-marker-create';


const useDocHeaderMarkersMenu = () => {
  const t = useTranslations();

  const {
    hideMenu
  } = useContextMenu();

  const headerMarkerCreate = useDocHeaderMarkerCreate();

  const createMarker = () => {
    headerMarkerCreate();
    hideMenu();
  }


  const getConfig = () => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, new'),
                iconComponent: Icon.Create,
                onClick: (event: React.MouseEvent) => createMarker()
              }
            },      
          ],
        },
      ]
    }

    return config;
  }


  const hook = () => {
    const config = getConfig();
    return config;
  }

  return hook;
}


export default useDocHeaderMarkersMenu;