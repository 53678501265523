import styled from "styled-components";
import ButtonBase from "lego/styles/button";


export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  
  border-width:     ${props => props.theme.panel.border.width};
  border-color:     ${props => props.theme.panel.border.color};
  border-top-style: ${props => props.theme.panel.border.style};
 
  /* padding: ${props => props.theme.defs.padding.normal}; */
  /* background-color: rgba(105, 0, 0,0.1);; */
  /* background: ${props => props.theme.defs.colors.background.primary}; */
`;


export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${props => props.theme.defs.gap.small};
`;


const BTN_SIZE = 32;

export const Button = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${BTN_SIZE}px;
  height: ${BTN_SIZE}px;

  border-radius: 50%;
`;
