import { MutableRefObject } from 'react';
import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import EditorTextBaseComponent  from 'app/ui/components/editor-txt';

import ContentSavePlugin  from './content-save-plugin';
import RegistrationPlugin from './registration-plugin';
import EditorTextStyledComponent from './editor-text-styled';


interface Props {
  pageCellAddr: HeaderRowsTypes.PageCellAddr;
  editorTextRef: MutableRefObject<HTMLDivElement | null>;
}


export const CellTextEditorComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr, 
    editorTextRef,
  } = props;

  return (
    <EditorTextBaseComponent
      editorTextRef={editorTextRef}
      EditorStyled={EditorTextStyledComponent}
      Plugins={[
        <RegistrationPlugin pageCellAddr={pageCellAddr} />,
        <ContentSavePlugin  pageCellAddr={pageCellAddr} />
      ]}
    />
  );
}
