import styled from "styled-components";
import ButtonBase from "lego/styles/button";


export const Button = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: none;
  width: 18px;
  height: 18px;

  border-radius: 50%;
`;
