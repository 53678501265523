import * as Types from "./types";


/**
 * 
 * Changelog
 * 
 */

export const getChangelogItem_HeaderRow = (): Types.ChangelogItem_HeaderRow => ({
  type: Types.ChangelogItemType.HEADER_ROW
});

export const getChangelogItem_Row = (
  props: Omit<Types.ChangelogItem_Row, 'type'>
): Types.ChangelogItem_Row => ({
  ...props,
  type: Types.ChangelogItemType.ROW
});

export const getChangelogItems = (): Types.ChangelogItems => [];

export const getChangelog = (): Types.Changelog => ({
  items: getChangelogItems()
});


/**
 * 
 * Page 
 * 
 */
  
export const getPageItem_DocHeaderInfo = (
  props: Omit<Types.PageItem_DocHeaderInfo, 'type'>
): Types.PageItem_DocHeaderInfo => ({
  ...props,
  type: Types.PageItemType.DOC_HEADER_INFO,
});

export const getPageItem_ViewTitle = (): Types.PageItem_ViewTitle => ({
  type: Types.PageItemType.VIEW_TITLE,
});

export const getPageItem_Spacer = (
  props: Omit<Types.PageItem_Spacer, 'type'>
): Types.PageItem_Spacer => ({
  ...props,
  type: Types.PageItemType.SPACER,
});

export const getPageItem_ReleaseInfo = (
  props: Omit<Types.PageItem_ReleaseInfo, 'type'>
): Types.PageItem_ReleaseInfo => ({
  ...props,
  type: Types.PageItemType.RELEASE_INFO,
});

export const getPageItem_ReleaseChangelog = (
  props: Omit<Types.PageItem_ReleaseChangelog, ('type' | 'items')>
): Types.PageItem_ReleaseChangelog => ({
  ...props,
  type: Types.PageItemType.RELEASE_CHANGELOG,
  items: getChangelogItems(),
});

export const getPageItems = (): Types.PageItems => [];

export const getPage = (): Types.Page => ({
  items: getPageItems()
});

