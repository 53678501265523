import styled from "styled-components";


const CompanyInfo = styled.div`
  margin-top: 8px;
  margin-left: 26px;

  display: flex;
  flex-direction: column;
  gap: 3px;
`;


export default CompanyInfo;