import { useEffect } from 'react';
import useSliceRequestSend from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-slicer/use-slice-request-send';


const useBaseWatcher = (
  states: any[]
) => {
  const sendSliceRequest = useSliceRequestSend();

  useEffect(() => {
    sendSliceRequest({delayed: true});
  }, states);
}
  
export default useBaseWatcher;