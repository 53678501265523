import { useRecoilValue } from 'recoil';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTools as Tools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { UIState_ReleaselogsSignals } from 'app/ui/states/editor-instruction';


const useReleaselogColumnsWidths = (releaselogAddr: Types.ReleaselogAddr) => {
  useRecoilValue(UIState_ReleaselogsSignals.releaselogsColumnsWidths);

  const releaselogKey = Tools.getReleaselogKey(releaselogAddr);
  useRecoilValue(UIState_ReleaselogsSignals.releaselogColumnsWidths(releaselogKey));
}

export default useReleaselogColumnsWidths;