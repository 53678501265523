import * as Types from "./types";
import * as Tools from "./tools";
import * as Defaults from "./defaults";

//----------------------------
//
// State's
//
export type State = Types.State;



export const getStyles = (
  state: State,
  lib: Types.StyleLib,
): Types.Styles => { 
  const styles = state[lib]
  return styles;
}

export const getStylesAddrs = (
  state: State,
  lib: Types.StyleLib,
): Types.StylesAddrs => { 
  const styles = getStyles(state, lib);
  
  return styles.addrs;
}

export const getStylesProps = (
  state: State,
  lib: Types.StyleLib,
): Types.StylesProps => { 
  const styles = getStyles(state, lib);
  
  return styles.props;
}

export const getStyleIdx = (
  state: State, 
  lib: Types.StyleLib,
  styleAddr: Types.StyleAddr
): number => {
  const stylesAddrs = getStylesAddrs(state, lib);
  
  const idx = stylesAddrs.findIndex((styleAddr_) => Tools.compareStyleAddr(styleAddr_, styleAddr));
  if ( idx === -1 ) {
    const msg = 'Style not found';
    throw new Error(msg);
  }

  return idx;
}

export const getStyleProps = (
  state: State,
  lib: Types.StyleLib,
  styleAddr: Types.StyleAddr,
): Types.StyleProps => { 
  const styles = getStyles(state, lib);
  
  const stylesProps = styles.props;
  const styleKey = Tools.getStyleKey(styleAddr);
  const styleProps = stylesProps[styleKey];

  if ( ! styleProps ) {
    const msg = `Missing props for widget style ${styleKey}`;
    console.warn(msg);
    throw new Error(msg);
  }

  return styleProps;
}


/**
 * Initial State
 */

export const createInitialState = (): State => {
  const initState: State = {
    user: Defaults.getStyles(),
    system: Defaults.getStyles(),
  }

  return initState;
}
