import { useEffect }   from 'react';

import jtl from 'tools/jtl';

import { mutation }    from "app/arch/backend";
import { useMutation } from "app/arch/backend/use-mutation";
import { MsgDuration }     from "app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box";
import { useDocState }     from "app/ui/contexts/document";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import { useDialogEnterSingleLine } from 'app/ui/components/editor-instruction/dialogs/dialog-enter-single-line/use-dialog-enter-single-line';
import useRepo from './use-repo';
import useDocumentEdit from './use-document-edit';


const useDocumentSaveAs = () => {
  const t = useTranslations();
  
  const document = useDocState();
  const dialog   = useDialogEnterSingleLine();
  const repo     = useRepo();
  const editDocument = useDocumentEdit();

  // 
  // Create document
  //
  const { 
    data: dataCreateInstruction,
    mutation: createInstruction,
  } = useMutation(
    mutation.createInstruction,
    {
      onStart: {
        msg:  t("saving document"),
      },
      onEnd: 
      {
        msg:  t("document saved"),
        duration: MsgDuration.MIDDLE
      }
    }
  );


  useEffect(() => {
    if (dataCreateInstruction === undefined) {
      return;
    }
    const data = dataCreateInstruction.instructionCreate;
    if ( ! data.ok ) {
      console.warn("Can't create new document");
      return;
    }

    const instruction = data.instruction;
    editDocument(instruction.id);
  }, [dataCreateInstruction]);


  //
  // Whole routine - generate & download
  //
  const hookRoutine = () => {
    dialog.show({
      title: t("save as"),
      msg:   t("enter new instruction name"),
      onOKHandler: (enteredText: any) => {
        const contentStates = { ...document.getContentStates()};
        contentStates.metaData.title = enteredText;

        const content = jtl.serialize.serialize(contentStates);

        createInstruction({ 
          variables: {
            content, 
            repoId: repo.id
          }
        });

      },
      onCancelHandler:  () => {},
    });


  }

  return hookRoutine;
}


export default useDocumentSaveAs;
