import { atom }           from 'recoil';
import { selector }       from 'recoil';
import { selectorFamily } from 'recoil';

import { ContentState as State } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/content';


//----------------
// State
//
export const state = atom<State.State>({
  key: "ei_docContent_content",
  default: State.createInitialState()
});

/**
 * 
 * Sections
 * 
 */
export const sectionsAddrs = selector({
  key: "ei_docContent_content_sectionsAddrs",
  get: ({ get }) => {
    const stateObj = get(state);
    const sectionsAddrs = State.getSectionsAddrs(stateObj);
    return sectionsAddrs;
  }
});

export const sectionsEnabled = selector({
  key: "ei_docContent_content_sectionsEnabled",
  get: ({ get }) => {
    const stateObj = get(state);
    const config = State.getSectionsConfig(stateObj);
    return config.enabled;
  }
});

export const sectionsIndexing = selector({
  key: "ei_docContent_content_sectionsIndexing",
  get: ({ get }) => {
    const stateObj = get(state);
    const config = State.getSectionsConfig(stateObj);
    return config.indexing;
  }
});

export const sectionsNamesCSS = selector({
  key: "ei_docContent_content_sectionsNamesCSS",
  get: ({ get }) => {
    const stateObj = get(state);
    const config = State.getSectionsConfig(stateObj);
    return config.sectionsNames.css;
  }
});


/**
* 
* Section
* 
*/
export const sectionName = selectorFamily({
  key: "ei_docContent_content_sectionName",
  get: (sectionAddr: Types.SectionAddr) => ({ get }) => {
    const stateObj = get(state);
    const section = State.getSectionProps(stateObj, sectionAddr);
    return section.name;
  }
});

export const sectionPresent = selectorFamily({
  key: "ei_docContent_content_sectionPresent",
  get: (sectionAddr: Types.SectionAddr) => ({ get }) => {
    const stateObj = get(state);
    const present = State.isSectionPresent(
      stateObj, 
      sectionAddr
    );
    return present;
  }
});


/**
 * 
 * Column(s)
 * 
 */

export const columnsAddrs = selector({
  key: "ei_docContent_content_columnsAddrs",
  get: ({ get }) => {
    const stateObj = get(state);
    const columnsAddrs = State.getColumnsAddrs(stateObj);
    return columnsAddrs;
  }
});

export const columnsProps = selector({
  key: "ei_docContent_content_columnsProps",
  get: ({ get }) => {
    const stateObj = get(state);
    const columnsProps = State.getColumnsProps(stateObj);
    return columnsProps;
  }
});

export const columnName = selectorFamily({
  key: `ei_docContent_content_columnName`,
  get: (columnAddr: Types.ColumnAddr) => ({ get }) => {
    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr);
    return columnProps.name;
  }
});

export const columnHeaderCSS = selectorFamily({
  key: "ei_docContent_content_columnHeaderCSS",
  get: (columnAddr: Types.ColumnAddr | null) => ({ get }) => {
    if (columnAddr === null) {
      return null;
    }
    
    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr);
    return columnProps.header.css;
  }
});

export const columnCSS = selectorFamily({
  key: `ei_docContent_content_columnCSS`,
  get: (columnAddr: Types.ColumnAddr | null)  => ({ get }) => {
    if (columnAddr === null) {
      return null;
    };

    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr);
    return columnProps.css;
  }
});

export const columnImages_imagesIdxProps = selectorFamily({
  key: `ei_docContent_content_columnImages_imagesIdxProps`,
  get: (columnAddr: Types.ColumnAddr | null)  => ({ get }) => {
    if (columnAddr === null) {
      return null;
    }

    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr) as Types.ColumnImagesProps;
    
    if (columnProps.type !== Types.ColumnType.IMAGES) {
      return null;
    }

    return columnProps.imageIdx
  }
});

export const columnImages_imageBorderCSS = selectorFamily({
  key: `ei_docContent_content_columnImages_imageBorderCSS`,
  get: (columnAddr: Types.ColumnAddr | null)  => ({ get }) => {
    if (columnAddr === null) {
      return null;
    };

    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr) as Types.ColumnImagesProps;

    if (columnProps.type !== Types.ColumnType.IMAGES) {
      return null;
    }

    return columnProps.imageBorder.css;
  }
});



/**
 * 
 * Rows
 * 
 */

export const rowsAddrs = selectorFamily({
  key: `ei_docContent_content_rowsAddrs`,
  get: (sectionAddr: Types.SectionAddr) => ({ get }) => {
    const stateObj = get(state);
    const rowsAddrs = State.getRowsAddrs(
      stateObj, 
      sectionAddr
    );
    
    return rowsAddrs;
  }
});

export const rowIdxSection = selectorFamily({
  key: `ei_docContent_content_rowIdxSection`,
  get: (rowAddr: Types.RowAddr) => ({ get }) => {
    const stateObj = get(state);
    const rowIdx = State.getRowIdxSection(
      stateObj, 
      rowAddr
    );
    
    return rowIdx;
  }
});

export const rowIdxGlobal = selectorFamily({
  key: `ei_docContent_content_rowIdxGlobal`,
  get: (rowAddr: Types.RowAddr) => ({ get }) => {
    const stateObj = get(state);
    const rowIdx = State.getRowIdxGlobal(
      stateObj, 
      rowAddr
    );
    
    return rowIdx;
  }
});

export const rowPresent = selectorFamily({
  key: `ei_docContent_content_rowPresent`,
  get: (rowAddr: Types.RowAddr) => ({ get }) => {
    const stateObj = get(state);
    const present = State.isRowPresent(
      stateObj, 
      rowAddr
    );
    
    return present;
  }
});

/**
 * 
 * Cell Text
 * 
 */

export const cellText_editorState = selectorFamily({
  key: `ei_docContent_content_cellText_editorState`,
  get: (cellAddr: Types.CellAddr) => ({ get }) => {
    const stateObj = get(state);
    const cell = State.getCell(stateObj, cellAddr);
    const cellText = cell as Types.TextCell;
    const editorState = cellText.editorState;

    return editorState;
  }
});


/**
 * 
 * Cell images
 * 
 */
export const cellImages_imagesAddrs = selectorFamily({
  key: `ei_docContent_content_cellImages_imagesAddrs`,
  get: (cellAddr: Types.CellAddr) => ({ get }) => {
    const stateObj = get(state);
    const imagesAddrs = State.cellImages_getImagesAddrs(stateObj, cellAddr);
    return imagesAddrs;
  }
});

export const cellImages_image_viewArea = selectorFamily({
  key: `ei_docContent_content_cellImages_image_viewArea`,
  get: (imageAddr: Types.ImageAddr) => ({ get }) => {
    const stateObj = get(state);
    const imageProps = State.cellImages_getImageProps(stateObj, imageAddr);
    return imageProps.viewArea;
  }
});

export const cellImages_image_viewScale = selectorFamily({
  key: `ei_docContent_content_cellImages_image_viewScale`,
  get: (imageAddr: Types.ImageAddr) => ({ get }) => {
    const stateObj = get(state);
    const imageProps = State.cellImages_getImageProps(stateObj, imageAddr);
    return imageProps.viewScale;
  }
});

export const cellImages_image_imageIdx = selectorFamily({
  key: `ei_docContent_content_cellImages_image_imageIdx`,
  get: (imageAddr: Types.ImageAddr) => ({ get }) => {
    const stateObj = get(state);
    const imageIdx = State.cellImages_getImageIdx(stateObj, imageAddr);
    return imageIdx;
  }
});

export const cellImages_image_widgetsAddrs = selectorFamily({
  key: `ei_docContent_content_cellImages_image_widgetsAddrs`,
  get: (imageAddr: Types.ImageAddr | null) => ({ get }) => {
    if (imageAddr === null) {
      return null;
    }
    
    const stateObj = get(state);
    const widgetsAddrs = State.cellImages_image_getWidgetsAddrs(stateObj, imageAddr);
    return widgetsAddrs;
  }
});

export const cellImages_image_widgetProps = selectorFamily({
  key: `ei_docContent_content_cellImages_image_widgetProps`,
  get: (widgetAddr: Types.WidgetAddr) => ({ get }) => {
    const stateObj = get(state);
    const widgetProps = State.cellImages_image_getWidgetProps(stateObj, widgetAddr);
    return widgetProps;
  }
});

// TODO null here on widgetAddr is just a hack
// from old code. Fix when possible
export const cellImages_image_widgetProps_hack = selectorFamily({
  key: `ei_docContent_content_cellImages_image_widgetProps_hack`,
  get: (widgetAddr: Types.WidgetAddr | null) => ({ get }) => {
    if (widgetAddr === null) {
      return null;
    }

    const stateObj = get(state);
    const widgetProps = State.cellImages_image_getWidgetProps(stateObj, widgetAddr);
    return widgetProps;
  }
});

export const cellImages_image_widgetStyle = selectorFamily({
  key: `ei_docContent_content_cellImages_image_widgetStyle`,
  get: (widgetAddr: Types.WidgetAddr | null) => ({ get }) => {
    if (widgetAddr === null) {
      return null;
    }
    const stateObj = get(state);
    const widgetProps = State.cellImages_image_getWidgetProps(stateObj, widgetAddr);
    return widgetProps.style;
  }
});

//
// Shared between WidgetText and WidgetArrowText
export const cellImages_image_widgetEditorText_editorTextState = selectorFamily({
  key: `ei_docContent_content_cellImages_image_widgetEditorText_editorTextState`,
  get: (widgetAddr: Types.WidgetAddr) => ({ get }) => {
    const stateObj = get(state);
    const widgetProps = State.cellImages_image_getWidgetProps(stateObj, widgetAddr);
    const widgetEditorTextProps = widgetProps as (
      Types.WidgetArrowTextProps |
      Types.WidgetTextProps
    );

    return widgetEditorTextProps.editorTextState;
  }
});


/**
 * Cell Markers
 */
export const cellMarkers_markersAddrs = selectorFamily({
  key: `ei_docContent_content_cellMarkers_markersAddrs`,
  get: (cellAddr: Types.CellAddr) => ({ get }) => {
    const stateObj = get(state);
    const markersAddrs = State.cellMarkers_getMarkersAddrs_(stateObj, cellAddr);
    return markersAddrs;
  }
});
