import { useRef } from 'react';
import { useEffect } from 'react';
import { RefObject } from 'react';

import { Position } from 'app/arch/types';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';
import useResizeObserver from 'app/ui/hooks/use-resize-observer';


interface Props {
  deskAreaRef: RefObject<HTMLDivElement>; 

  imageAddr: ContentTypes.ImageAddr;
  scale: number;
  position: Position;
  onPositionChange: (position: Position) => void;
}


export const useDeskPositionInit = (props: Props) => {
  const {
    deskAreaRef,
    imageAddr,
    scale,
    position,
    onPositionChange,
  } = props;

  const docState = useDocState();
  const isInitialized = useRef(false);

  useEffect(() => {
    isInitialized.current = false;
  }, [imageAddr]);

  useResizeObserver({
    elementRef: deskAreaRef,
    onResize: (entries) => {
      handleResize(entries);
    }
  }, [imageAddr]);

  const handleResize = (entries: ResizeObserverEntry[]) => {
    if ( isInitialized.current ) {
      return;
    }
    
    init();
  }

  const init = () => {
    if ( isInitialized.current ) {
      return;
    }

    if ( ! deskAreaRef.current ) {
      return;
    }
    
    const bbox = deskAreaRef.current.getBoundingClientRect();
    if (bbox.height === 0 || bbox.width === 0) {
      return;
    }
    isInitialized.current = true;
    centerDeskImage();
  }

  const centerDeskImage = () => {
    const imageProps = docState.content.cellImages_getImageProps(imageAddr);
    const imageView = imageProps.viewArea;

    const bbox = deskAreaRef.current!.getBoundingClientRect();

    const xDelta = (bbox.width  / scale - (imageView.x2 - imageView.x1)) / 2 * scale;
    const yDelta = (bbox.height / scale - (imageView.y2 - imageView.y1)) / 2 * scale;
    const x = -imageView.x1 * scale +  xDelta;
    const y = -imageView.y1 * scale +  yDelta;

    if (position[0] !== x || position[1] !== y) {
      onPositionChange([x, y]);
    }
  }
}

export default useDeskPositionInit;