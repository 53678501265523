import { useEffect } from 'react';
import { useRef }    from 'react';

import { ReposTypes }  from 'app/arch/app/states/repos';
import { useMutation } from 'app/arch/backend/use-mutation';
import { mutation as mutationDefs } from 'app/arch/backend';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useFeatureLimitedInfo from './use-feature-limited-info';
import useToastAdd from '../__modules/info/components/toasts/hooks/use-toast-add';


type OnDoneFn  = () => void;
type OnErrorFn = (error: string) => void;
type OnLimitFn = (limit: string) => void;


export interface HookProps {
  repoId: number;
  email: string;
  access: ReposTypes.MemberAccess;

  onDone?: OnDoneFn;
  onLimit?: OnLimitFn;
  onError?: OnErrorFn;
}


const useRepoMemberAdd = () => {
  const t = useTranslations();
  const addToast = useToastAdd();
  const showFeatureLimited = useFeatureLimitedInfo();

  const onDoneRef  = useRef<OnDoneFn | null>(null);
  const onErrorRef = useRef<OnErrorFn | null>(null);
  const onLimitRef = useRef<OnLimitFn | null>(null);


  /**
   * Mutation
   */

  const { 
    mutation,
    data: response,
    error,
  } = useMutation(mutationDefs.addRepoMember);


  /**
   * Mutation data
   */

  useEffect(() => {
    if ( ! response ) {
      return;
    }

    const data = response.repoMemberAdd;

    if ( data.featureLimited ) {
      showFeatureLimited(data.limitType);
      onLimitRef.current?.(data.limitType);
      resetRefs();
      
      return;
    }

    if ( ! data.ok ) {
      console.warn("Can't send repo invitation");
      onErrorRef.current?.('Invalid data');
      resetRefs();
      return;
    }


    addToast({ text: t("repository invitation sent") });
    onDoneRef.current?.();
    resetRefs();
  }, [response]);


  /**
   * Mutation error
   */

  useEffect(() => {
    if ( ! error ) {
      return;
    }

    onErrorRef.current?.(error.message);
    resetRefs();
  }, [error]);


  const resetRefs = () => {
    onDoneRef.current = null;
    onErrorRef.current = null;
    onLimitRef.current = null;
  }

  const hook = (props: HookProps) => {
    const {
      repoId,
      email,
      access,

      onDone,
      onError,
      onLimit,
    } = props;
  
    onDoneRef.current  = onDone  || null;
    onErrorRef.current = onError || null;
    onLimitRef.current = onLimit || null;

    mutation({
      variables: { 
        repoId,
        email,
        access,
      }
    });
  }

  return hook;
}

export default useRepoMemberAdd;
