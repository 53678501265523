import React from 'react';

import { ReleaselogsTools as Tools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState }      from 'app/ui/contexts/document';
import useSelectedReset     from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-selected-reset';
import useColumnSelectedSet from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/column/use-column-selected-set';

import { HeaderCellProps } from '../types';
import { MainWrapper } from './styles';


interface Props extends HeaderCellProps {
}


export const HeaderCellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;

  const document = useDocState();
  const resetSelected = useSelectedReset();
  const setColumnSelected = useColumnSelectedSet();
  

  const handleSelectColumn = (event: React.MouseEvent) => {
    event.stopPropagation();

    const selectedColumn = document.releaselogsSession.getColumnSelected();
    const isSelected = (
      selectedColumn && 
      Tools.compareColumnAddr(selectedColumn, columnAddr)
    )

    if (isSelected) {
      return;
    }

    resetSelected({skipStateUpdate: true});
    setColumnSelected(columnAddr);
  }

  return (
    <MainWrapper onPointerDown={handleSelectColumn}>
      { children }
    </MainWrapper>
  );
}
  
