import styled from "styled-components";

const Scrollbar = styled.div`
  ${props => getScrollbarCSS(props)};
`;

export const getScrollbarCSS = (props: any) => `
  &::-webkit-scrollbar {
    width:  ${props.theme.scrollbar.width.normal};
    height: ${props.theme.scrollbar.width.normal};
  }

  &::-webkit-scrollbar-thumb {
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-radius: ${props.theme.defs.border.radius.normal};
    border-color:  ${props.theme.defs.colors.scrollbar.border.primary};
    background:    ${props.theme.defs.colors.scrollbar.thumb.primary};
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:vertical {
  }

  &::-webkit-scrollbar-track:horizontal {
  }

  &::-webkit-scrollbar-track:vertical {
  }
`;

export default Scrollbar;