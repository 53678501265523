import { useIsMobile } from "lego-hooks/use-is-mobile";

import { Position } from "app/arch/types";
import useRescaleByWheel from "./use-rescale-by-wheel";
import useRescaleByTouch from "./use-rescale-by-touch";


interface Props {
  getScale: () => number;

  onWheel?: (
    scaleInit: number,
    scaleNew: number, 
    scalePoint: Position,
  ) => void;


  onTouchStart?: () => void;
  onTouchMove?: (
    scaleInit: number,
    scaleNew: number, 
    scalePointInit: Position,
    scalePointNew: Position,
  ) => void;
  onTouchEnd?: () => void;
}


const useRescaleUniversal = (props: Props) => {
  const {
    getScale,
    
    onWheel,
    
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  } = props;


  const isMobile = useIsMobile();


  /**
   * Rescale by Wheel
   */

  const {
    enabled: wheelRescaleEnabled,
    handleWheel,
  } = useRescaleByWheel({
    disable: isMobile,
    getScale,
    onWheel,
  });


  /**
   * Rescale by Touch
   */

  const {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  } = useRescaleByTouch({
    disable: ! isMobile,
    getScale,
    onStart: onTouchStart,
    onMove: onTouchMove,
    onEnd: onTouchEnd
  });

  return {
    wheelRescaleEnabled,
    handleWheel,

    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  }
}


export default useRescaleUniversal;