import { useQuery as useQueryApollo } from '@apollo/client';
import { Msgs, useCommon } from './use-common';

export const useQuery = (
  query: any,
  msgs?: Msgs,
  skip?: boolean,
) => {
  const { 
    loading, 
    error, 
    data,
    refetch
  } = useQueryApollo(query, {
    skip: (skip !== undefined ? skip : false),
    fetchPolicy: 'no-cache'
  });

  useCommon({msgs, error, loading});
 
  return {
    loading,
    data,
    error,
    refetch
  }
}
