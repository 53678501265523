import NavbarPanelSectionComponent from '../navbar-panel-section';
import * as Types from '../../types';
import HLS_NavbarPanelComponent from '../../hls/navbar-panel';


interface Props {
  config: Types.Panel;
}


export const NavbarPanelComponent: React.FC<Props> = (props: Props) => {
  const { 
    config
  } = props;
  
  return (
    <HLS_NavbarPanelComponent 
      config={config}
      NavbarPanelSection={NavbarPanelSectionComponent}
    />
  );
}
