import { atom }     from 'recoil';
import { selector } from 'recoil';
import { EditorLogoSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/editor-logo-session';



export const state = atom<State.State>({
  key: "editorLogoSession",
  default: State.createInitialState()
});



//--------------------
//
// Selectors
//

export const visible = selector({
  key: `editorLogoSession_visible`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getWindow(stateObj).visible;
  }
});

