import styled from "styled-components";
import SignBase from "./sign-base";
import { Config } from "../config";


const SignCircle = styled(SignBase)`
  width:  ${Config.shape.circle.size}px;
  height: ${Config.shape.circle.size}px;

  border-radius: 50%;
`;


export default SignCircle;