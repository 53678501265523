import Logger from 'libs/debug';
import { useState } from 'react';
import { useEffect } from 'react';


const useDivsImgsLoaded = (startCheck: boolean) => {
  const log = Logger.getDocImgsLoaded();
  const [loaded, setLoaded] = useState(false);

  const __checkIfDone = (
    totalDivsCount: number,
    loadedImgsCount: number,
  ) => {
    if (totalDivsCount === loadedImgsCount) {
      log.debug(`Widget-image, imgs loaded ${loadedImgsCount}`);
      setLoaded(true);
    }
  }

  const checkDivs = () => {
    const divs = document.querySelectorAll('[data-imgs="widget-image"]');
    log.debug(`Widget-image, imgs found ${divs.length}`);

    const totalDivs = divs.length;
    let loadedCount = 0;

    if (totalDivs === 0) {
      setLoaded(true); // No divs to check
      return;
    }

    divs.forEach(div => {
      const bgImage = window.getComputedStyle(div).backgroundImage;
      const match = bgImage.match(/url\((['"]?)(.*?)\1\)/);

      if (match && match[2]) {
        const imageUrl = match[2];
        const img = new Image();

        img.onload = () => {
          loadedCount++;
          __checkIfDone(divs.length, loadedCount);
        };

        img.onerror = () => {
          console.error(`Image failed to load: ${imageUrl}`);
        };

        img.src = imageUrl;
      } else {
        console.warn(`Widget-image, no background image found`);
        loadedCount++;
        __checkIfDone(divs.length, loadedCount);
      }
    });
  };


  useEffect(() => {
    if ( ! startCheck ) {
      return;
    }
    
    checkDivs();
  }, [startCheck]);

  return loaded;
};

export default useDivsImgsLoaded;
