import jtl from "tools/jtl";
import ShapeBaseComponent from "../shape-base";
import { ShapeProps } from "../../types";
import { ShapeCalcPointer } from "../../shapes-calculators/shape-calc-pointer";


interface Props extends ShapeProps{
}


export const ShapePointerComponent: React.FC<Props> = (props: Props) => {
  const {
    startPoint, 
    endPoint,
    style,
  } = props;

  const shapeCalc = new ShapeCalcPointer({
    style,
    startPoint,
    endPoint
  });
  const c = shapeCalc;

  const offset0 = 0;
  const offset1 = c.outlineWidthAngledVertical;
  const offset2 = c.outlineWidthAngledVertical + c.borderWidthAngledVertical;

  const points = [
    [
      `50% ${offset0}px`,

      `calc(50% + ${c.arrowHeadWidth / 2 + c.borderWidthAngledHorizontal + c.outlineWidthAngledHorizontal}px) ${c.arrowHeadHeightTotal}px`, 
      `calc(50% - ${c.arrowHeadWidth / 2 + c.borderWidthAngledHorizontal + c.outlineWidthAngledHorizontal}px) ${c.arrowHeadHeightTotal}px`, 
    ],
    [
      `50% ${offset1}px`,

      `calc(50% + ${c.arrowHeadWidth / 2 + c.borderWidthAngledHorizontal}px) ${c.arrowHeadHeightTotal - c.outlineWidth}px`, 
      `calc(50% - ${c.arrowHeadWidth / 2 + c.borderWidthAngledHorizontal}px) ${c.arrowHeadHeightTotal - c.outlineWidth}px`, 
    ],
    [
      `50% ${offset2}px`,

      `calc(50% + ${c.arrowHeadWidth / 2}px)  ${c.arrowHeadHeightTotal - (c.borderWidth + c.outlineWidth)}px`, 
      `calc(50% - ${c.arrowHeadWidth / 2}px)  ${c.arrowHeadHeightTotal - (c.borderWidth + c.outlineWidth)}px`, 
    ]
  ]
  
  return (
    <ShapeBaseComponent
      width={c.widthTotal}
      height={c.heightTotal}
      
      startPoint={props.startPoint}
      endPoint={props.endPoint}
      layer={props.layer}
      style={props.style}
      points={points}
    />
  );
  
}
