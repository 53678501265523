
import { PanelControlsItemSubsectionConfig } from '../../../config';
import { unpackBoolean }   from '../../../tools';
import DynamicWidthWrapper from '../../../styles/dynamic-width-wrapper';
import DynamicWidth        from '../../../styles/dynamic-width';

import { MainWrapper } from './styles';
import { Title       } from './styles';
import { ItemWrapper } from './styles';
import { Separator   } from './styles';


interface Props {
  config: PanelControlsItemSubsectionConfig;
  SectionItem: React.ElementType;
}


export const PanelSectionSubsectionComponent: React.FC<Props> = (props: Props) => {
  const { 
    config,
    SectionItem
  } = props;
  
  const sectionDisabled = unpackBoolean(config.disabled);

  const renderItems = () => {
    const items = config.items.filter((item) => ! unpackBoolean(item.skip));
    const itemsComps = items.map((item, idx) => {

      return (
        <ItemWrapper key={`${idx}`}>
          <SectionItem 
            config={item}
            sectionDisabled={sectionDisabled}
          />
          {/* <Separator /> */}
        </ItemWrapper>
      )
    });

    return itemsComps;
  }

  
  return (
    <MainWrapper $disabled={sectionDisabled}>
      { 
        config.title &&
        <DynamicWidthWrapper>
          <DynamicWidth>
            <Title>
              { config.title }
            </Title>
          </DynamicWidth>
        </DynamicWidthWrapper>
      }

      { renderItems() }

    </MainWrapper>
  );
}
  
