import { useEffect } from 'react';
import { useRef }    from 'react';
import { useProgressModal } from 'lego-v2/progress-modal';
import { useMutation }     from 'app/arch/backend/use-mutation';
import { mutation }        from 'app/arch/backend';
import { MsgDuration }     from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useDialogYesNo }  from 'app/ui/components/editor-instruction/dialogs/dialog-yes-no/use-dialog-yes-no';
import { useTranslations } from 'app/ui/hooks/app/use-translation';


type OnDoneFn = () => void;

export interface FnProps {
  documentId: number;
  onDone?: OnDoneFn;
}


export const useDocumentDelete = () => {
  const t = useTranslations();
  const onDoneRef = useRef<null | OnDoneFn>(null);

  const { 
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();


  //---------------
  // Yes/No dialog
  //
  const {
    show: showDialogYesNo,
  } = useDialogYesNo();


  //-------------------------
  // Delete instruction
  //
  const { 
    mutation: deleteInstructionMutation,
    data: deleteInstructionData,
    loading: deleteInstructionLoading
  } = useMutation(
    mutation.deleteInstruction,
    {
      onStart: {
        msg:  t("document deleting notification"),
      },
      onEnd: 
      {
        msg:  t("document deleted notification"),
        duration: MsgDuration.MIDDLE
      }
    }
  );


  useEffect(() => {
    if ( deleteInstructionData === undefined || deleteInstructionData === null) {
      return;
    }

    const data = deleteInstructionData.instructionDelete;
    if (data.ok) {
      const removedId = data.id;
      onDoneRef.current?.();
      hideProgressModal();
    }
    else {
      console.warn(`Instruction has not been delete, unknown error`);
    }
  }, [deleteInstructionData]);

  
  const deleteDocument = (props: FnProps) => {
    const title   = t("document delete msgbox title");
    const message = t("document delete msgbox message");
    const noAutoFocus = true;

    const onNoHandler  = () => {};
    const onYesHandler = () => {
      onDoneRef.current = props.onDone || null;
      showProgressModal();
      deleteInstructionMutation({ 
        variables: { id: props.documentId } 
      });
    }

    showDialogYesNo({title, message, noAutoFocus, onYesHandler, onNoHandler });
  }


  return deleteDocument;
}


export default useDocumentDelete;
