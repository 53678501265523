import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";


const useCellSelectedSet = () => {
  const document = useDocState();

  const {
    setContentSession
  } = useEditorStatesSetters();


  const selectCell = ({cellAddr, skipStateUpdate}: {
    cellAddr: Types.CellAddr | null,
    skipStateUpdate?: boolean,
  }) => {
    document.contentSession.setCellSelected(cellAddr);
    
    if (skipStateUpdate) {
      return;
    }
    
    setContentSession();
  }

  return selectCell;
}

export default useCellSelectedSet;