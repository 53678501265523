import Errors from 'app/arch/tools/errors';
import * as Defaults from '../defaults';
import * as Types from '../types';


type CheckerFn = (cell: any, cellType: Types.ColumnType) => boolean;
type CheckerMap = {[cellType_ in Types.ColumnType]: CheckerFn};


class CheckerCellEmtpy {
  private _checkerMap: CheckerMap;

  constructor() {
    this._checkerMap = {
      [Types.ColumnType.INDEX]: this.__isEmptyCellIndex,
      [Types.ColumnType.TEXT]: this.__isEmptyCellText,
    }
  }

  isEmpty(
    cell: Types.CellTypes, 
    cellType: Types.ColumnType
  ): boolean {
    const checkFn = this._checkerMap[cellType];
    return checkFn(cell, cellType);
  }

  private __isEmptyCellIndex(
    cell: Types.CellTypes, 
    cellType: Types.ColumnType
  ): boolean {
    return true;
  }

  private __isEmptyCellText(
    cell_: Types.CellTypes, 
    cellType: Types.ColumnType
  ): boolean {
    const cell = cell_ as Types.TextCell;
    const cellDefault = Defaults.getCell(cellType);
    const keys = Object.keys(cellDefault);

    for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
      const key = keys[keyIdx];

      if ( ! (key in cell)) {
        const msg = `Key: ${key} is missing from cell`;
        Errors.hard(msg);
        return false;
      }

      const valueActual   = (cell as any)[key];
      const valueExpected = (cellDefault as any)[key];

      const sameSame = (valueActual === valueExpected);
      if ( ! sameSame ) {
        return false;
      }
    }

    return true;
  }
}


export default CheckerCellEmtpy;