import { useRecoilValue } from "recoil";
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';


const useSmartLinesWidgetsSrcLines = () => {
  const srcLines = useRecoilValue(UIState_EditorImageSession.smartLinesWidgetsSrcLines);
  return srcLines;
}

export default useSmartLinesWidgetsSrcLines;
