import React from 'react';
import { useRecoilValue } from 'recoil';

import { ReposTools } from 'app/arch/app/states/repos';
import { ReposTypes } from 'app/arch/app/states/repos';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { UIState_Repos }   from 'app/ui/states/app/repos-state';
import useUsername         from 'app/ui-v2/app/hooks/use-user-name';
import useRepo             from 'app/ui-v2/editor-instruction/hooks/use-repo';
import useRepos            from 'app/ui-v2/app/hooks/use-repos';
import ViewTitledComponent from 'app/ui-v2/home-page/hls/view-titled';

import RepoSetterComponent          from 'app-views/app-components/repo-setter';
import RepoInvitationPanelComponent from 'app-views/app-components/repo-invitation-panel';

import RepoMembersListComponent from './repo-members-list/repo-members-list';
import RepoInfoComponent        from './repo-info';
import RepoSendInviteComponent  from './repo-send-invite';

import { MembersWrapper }    from './styles';
import { AutoHeightWrapper } from './styles';


interface Props {
}


export const ViewReposComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const username = useUsername();
  const repos = useRepos();

  const reposPacks = useRecoilValue(UIState_Repos.reposPacks);
  const { id: repoId } = useRepo();

  const memberAccess = repos.getRepoMemberAccess(repoId, username);
  const memberStatus = repos.getRepoMemberStatus(repoId, username);
  
  const memberStatusAccepted    = (memberStatus === ReposTypes.MemberStatus.INVITE_ACCEPTED);
  const memberStatusInvited     = (memberStatus === ReposTypes.MemberStatus.INVITE_SENT);
  
  const memberAccessListMembers = ReposTools.hasAccessListMembers(memberAccess);
  const memberAccessAddMembers  = ReposTools.hasAccessAddMembers(memberAccess);

  const repoMembers = repos.getRepoMembers(repoId, username);

  const displayReposSetter    = (reposPacks.length > 1);
  const displayRepoInfo       = (memberStatusAccepted);
  const displayRepoInvitation = (memberStatusInvited);
  
  const displayRepoMemberList = (
    memberStatusAccepted && 
    memberAccessListMembers && 
    repoMembers.length > 0
  );
  
  const displayRepoSendInvite = (
    memberStatusAccepted && 
    memberAccessAddMembers
  );


  return (
    <ViewTitledComponent title={t('repositories')}>
      {
        displayReposSetter &&
        <AutoHeightWrapper>
          <RepoSetterComponent selectedRepoId={repoId} />
        </AutoHeightWrapper>
      }
    
      {     
        displayRepoInfo &&
        <AutoHeightWrapper>
          <RepoInfoComponent repoId={repoId} />
        </AutoHeightWrapper>
      }

      {
        displayRepoInvitation &&
        <AutoHeightWrapper>
          <RepoInvitationPanelComponent repoId={repoId} />
        </AutoHeightWrapper>
      }

      {
        displayRepoMemberList &&
        <MembersWrapper>
          <RepoMembersListComponent repoId={repoId}/>
        </MembersWrapper>
      }

      {
        displayRepoSendInvite &&
        <AutoHeightWrapper>
          <RepoSendInviteComponent repoId={repoId} />
        </AutoHeightWrapper>
      }

    </ViewTitledComponent>
  );
}

