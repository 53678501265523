//
// Loader opacity transition time is set to 600 in `index.html`
//
const LOADER_OPACITY_TRANSITION_TIME = 600;
const LOADER_REMOVE_DELAY = LOADER_OPACITY_TRANSITION_TIME + 200;


const useAppRemoveLoader = () => {
  const removeLoader = () => {
    // const clipper = document.getElementById('clipper');
    // if (clipper) {
    //   clipper.style.visibility = 'visible';
    // }

    document.documentElement.style.setProperty('--loader-opacity', `0`);

    setTimeout(() => {
      // 
      // Removing loader overlayer
      //
      const loader = document.getElementById('loader');
      loader?.parentNode?.removeChild(loader);

      //
      // Removing loader css as is not needed
      //
      const loaderStyle = document.getElementById('style-loader');
      loaderStyle?.parentNode?.removeChild(loaderStyle);
    }, LOADER_REMOVE_DELAY);

  }

  return removeLoader;
}


export default useAppRemoveLoader;