import { createContext } from 'react';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';


export interface ContextProps {
  widgetAddr: ContentTypes.WidgetAddr | null;
};

const Context = createContext<ContextProps>({
  widgetAddr: null,
});


export default Context;