import React from 'react';

import FieldsProbeComponent from './fields-probe';
import FieldsGridComponent  from './fields-grid';


interface Props {
}


export const FieldsComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <FieldsProbeComponent>
      <FieldsGridComponent />
    </FieldsProbeComponent>
  );
}
  
