
export enum Language {
  ENGLISH     = 'english',
  GERMAN      = 'german',
  POLISH      = 'polish',
  // SPANISH     = 'spanish',
  // FRENCH      = 'french',
  // SWEDISH     = 'swedish',
  // JAPANESE    = 'japanese',
  // CHINESE     = 'chinese',
  // PORTUGUESE  = 'portuguese',
  // HINDI       = 'hindi'
};


export const LanguageCodes: {[lang in Language]: string} = {
  [Language.ENGLISH]:    'en',
  [Language.GERMAN]:     'de',
  [Language.POLISH]:     'pl',
  // [Language.SPANISH]:    'es',
  // [Language.FRENCH]:     'fr',
  // [Language.SWEDISH]:    'se',
  // [Language.JAPANESE]:   'jp',
  // [Language.CHINESE]:    'cn',
  // [Language.HINDI]:      'in',
  // [Language.PORTUGUESE]: 'pt',
};


export enum WidgetStylesLibraryType {
  SYSTEM = 'system',
  USER   = 'user'
};


export enum UploadSource {
  DISK        = 'disk',
  CAMERA_BACK = 'camera-back'
};


