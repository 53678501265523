import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import jtl from "tools/jtl";

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useEditor }    from "app/ui-v2/editor-instruction/hooks/use-editor";
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr
  editable: boolean;
}


export const WidgetEditorTextEditableUpdaterComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
    editable
  } = props;

  const editor = useEditor();
  const widgetEdited = useRecoilValue(UIState_EditorImageSession.isWidgetEdited(widgetAddr));

  useEffect(() => {
    if ( ! editable ) {
      return;
    }

    setEditable(widgetEdited);
  }, [widgetEdited]);

  const setEditable = (editable: boolean) => {
    const lexicalPresent = editor.lexicalRegistrWidgets.hasLexical(widgetAddr);
    if ( ! lexicalPresent ) {
      console.error(`Lexcial not ready for widget ${jtl.object.hash(widgetAddr)}`);
      return;
    }    

    const lexical = editor.lexicalRegistrWidgets.getLexical(widgetAddr);
    lexical.setEditable(editable);
  }

  return null;
}
  
