import useMarkerSelectedCheck from "../use-marker-selected-check";


const useUndoEditorMarkerSession = () => {
  const checkSelectedMarker = useMarkerSelectedCheck();
  
  const onUndo = () => {
    checkSelectedMarker();
  }

  const onRedo = () => {

  }

  
  return { 
    onUndo,
    onRedo,
  }
}


export default useUndoEditorMarkerSession;
