import React from 'react';

import { useDocState }  from 'app/ui/contexts/document';
import { DNDContext }   from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';
import { HeaderCellProps } from '../types';

import { DropWrapper } from './styles';


interface Props extends HeaderCellProps {
}


export const HeaderCellDropComponent: React.FC<Props> = (props: Props) => {
  const {
    pageRowAddr,
    children
  } = props;

  const docState  = useDocState();
  const getDNDData = useDNDDataGet();
  
  const {
    setHeaderRows,
    setCustomRowsRowsSelected,
  } = useEditorStatesSetters();
  
  const handleDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const droppedRowAddr = data.pageRowAddr;
    
    const moved = docState.headerRows.moveRow(droppedRowAddr, pageRowAddr);
    if ( ! moved ) {
      return;
    }

    docState.saveUndo();
    docState.customRowsRowsSelected.reset();

    setHeaderRows();
    setCustomRowsRowsSelected();
  }

  const handleDragOver = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const isSelected = docState.customRowsRowsSelected.isSelected(pageRowAddr);
    if (isSelected) {
      return;
    }
    
    docState.customRowsRowsSelected.setDragOver(pageRowAddr);
    setCustomRowsRowsSelected();
  }

  const handleDragLeave = () => {
    docState.customRowsRowsSelected.setDragOver(null);
    setCustomRowsRowsSelected();
  }

  
  const contextsDefs: ContextDef[] = [{
    dndContext: DNDContext.viewContent.customRows.row,
    onDrop: handleDrop,
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
  }];

  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs} 
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
  
