import React from 'react';

import TooltipComponent from 'lego/components/tooltip';

import { useTranslations }       from 'app/ui/hooks/app/use-translation';
import { useDocState }           from 'app/ui/contexts/document';
import { Icon }                  from 'app/ui/icons/icons';
import useEditorStatesSetters    from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useMarkerEdit             from 'app/ui-v2/editor-markers/hooks/use-marker-edit';

import { Button } from './styles';


interface Props {
}


export const MarkersCreateNewComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const document = useDocState();

  const editMarker = useMarkerEdit();

  const {
    setRepoMarkers,
  } = useEditorStatesSetters();


  const handleAddMarker = () => {
    const markerAddr = document.repoMarkers.addMarker();
    document.saveUndo();
    setRepoMarkers();

    editMarker(markerAddr);
  }

  return (
    <TooltipComponent title={t("marker, new")}>
      <Button 
        data-test={'toolbar-markers-button-create-new'}
        onClick={handleAddMarker}
      >
        <Icon.Add />
      </Button>
    </TooltipComponent>
  );
};
