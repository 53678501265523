import { MsgDuration, MsgLevel } from './types';
import useToastsState from 'app/ui-v2/app/__modules/info/components/toasts/hooks/use-toasts-state';
import { ToastDuration } from 'app/arch/app/info/toasts/types';

// TODO
// Remove it, has all been migrated to 
// Toasts

export const useMsgBox = () => {
  const toasts = useToastsState();


  const showMsgBox = (
    msg: string, 
    level_?: MsgLevel,
    duration_?: number
  ) => {
    const level = level_ === undefined ? MsgLevel.INFO : level_;
    let duration = duration_;
    if (duration === undefined && level === MsgLevel.ERROR) {
      duration = MsgDuration.ERROR;
    }
    
    toasts.addToast({
      text: msg,
      // duration,
      duration: ToastDuration.XLONG,
      level: level as any
    });
  }

  return {showMsgBox};
}