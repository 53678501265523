import { useRecoilValue } from "recoil";
import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { UIState_RepoImages } from "app/ui/states/editor-instruction";
import { UIState_RepoImagesSession } from 'app/ui/states/editor-instruction';

import ControlImagesComponent      from "../controls/control-images";
import ControlUploadImageComponent from "../controls/control-upload-image";


const usePanelConfigImages = () => {
  const t = useTranslations();

  const images = useRecoilValue(UIState_RepoImages.images);
  const imagesCount = images.length;

  const imagesUploadingCount = useRecoilValue(UIState_RepoImagesSession.uploadingImagesCount);

  const getConfig = () => {
    const __image_upload = () => (
      {
        type: PanelControlsItemSectionItemType.USER_COMPONENT,
        component: ControlUploadImageComponent
      }
    );

    const __image_gallery = () => (
      {
        type: PanelControlsItemSectionItemType.USER_COMPONENT,
        component: ControlImagesComponent
      }
    );

    const skipImageGallery = (
      imagesCount === 0 &&
      imagesUploadingCount === 0
    );

    const config: PanelControlsConfig = {
      items: [
        {
          title: t('images'),
          sections: [
            {
              subsections: [
                {
                  title: t("upload"),
                  items: [
                    __image_upload(),
                  ]
                },
                {
                  skip: skipImageGallery,
                  items: [
                    __image_gallery(),
                  ]
                }
              ],
            }
          ]
        },
      ]
    } // config

    return config;
  }

  return getConfig;
}


export default usePanelConfigImages;