import { atom }     from 'recoil';
import { selector } from 'recoil';
import { RepoImagesState as State } from 'app/arch/editor-instruction/document/states/loadable/repo-images';


//----------------
//
// State
//
export const state = atom<State.State>({
  key: "ei_docImages",
  default: State.createInitialState()
});


//--------------------
//
// Selectors
//

export const images = selector({
  key: `ei_docImages_images`,
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.images;
  }
});


