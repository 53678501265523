import React from 'react';
import { StyledWrapper } from './styles';


interface Props {
  $editable: boolean;
  children: React.ReactNode;
}


export const EditorTextStyledComponent = React.forwardRef((
  props: Props, 
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    children
  } = props;

  return (
    <StyledWrapper 
      ref={ref}
      $editable={props.$editable}
    >
      { children }
    </StyledWrapper>
  );
});
