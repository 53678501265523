import styled from "styled-components";
import DraggedItemBorder from "./dragged-item-border";


interface Props {
  width: number;
  scale: number;
}


const TableHeaderDragged = styled(DraggedItemBorder).attrs<Props>(props => ({
  style: {
    width: `${props.width}px`
  }
}))<Props>`
  min-width: 100px;
  max-width: min(${props => 80 / props.scale}vw, 220px);
`;


export default TableHeaderDragged;