import { atom }     from 'recoil';
import { selector } from 'recoil';
import { RepoImagesSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/repo-images-session';


//----------------
//
// State
//
export const state = atom<State.State>({
  key: "ei_repoImagesSession",
  default: State.createInitialState()
});


//--------------------
//
// Selectors
//

export const uploadingImagesCount = selector({
  key: `ei_repoImagesSession_uploadingImagesCount`,
  get: ({ get }) => {
    const stateObj = get(state);
    const uploadingImgs = State.getImagesUploading(stateObj);
    return uploadingImgs.count;
  }
});
