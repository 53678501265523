import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Logger from 'libs/debug';
import urls from 'app/configs/urls';

import { NavbarTypes } from 'app/arch/home-page-guest/states/navbar';
import { ViewTypes }    from 'app/arch/home-page-guest/states/view';
import useViewSelect    from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-select';
import useViewParamsSet from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-params-set';
import useNavbarItemSelect from 'app/ui-v2/home-page/home-page-guest/hooks/use-navbar-item-select';
import useAppReset from 'app/ui/hooks/app/use-app-reset';


interface Props {
}


export const RedirectUserSignupComponent: React.FC<Props> = (props: Props) => {
  const { 
    email 
  } = useParams();

  const resetApp = useAppReset();
  const navigate = useNavigate();

  const selectView    = useViewSelect();
  const setViewParams = useViewParamsSet();
  const selectNavbarItem = useNavbarItemSelect();

  useEffect(() => {
    const logger = Logger.getHomePage();
    const url = (urls.home);
    
    resetApp();

    if ( ! email ) {
      logger.warn(`Invalid email: ${email}`);
      navigate(url);
      return;
    }

    selectView(ViewTypes.ViewItem.USER_SIGNUP);
    setViewParams({email: email !});
    selectNavbarItem(NavbarTypes.NavbarItem.USER_SIGNUP);

    logger.debug(`Redirecting to view ${ViewTypes.ViewItem.PASSWORD_RESET_FORM}`);
    navigate(url);
  }, []);

  return null;
}
