import React        from 'react';
import { useState } from 'react';

import PanelControlsComponent  from 'lego/components/panel-controls';
import { PanelControlsConfig } from 'lego/components/panel-controls/config';

import useScreenSizeVariantWatch from 'app/ui-v2/app/__modules/screen/hooks/use-screen-size-variant-watch';

import * as Types    from '../types';
import { PanelType } from '../types';
import * as Defaults from '../defaults';

import SidePanelContext from './context';
import { FixedWrapper }   from './styles';
import { DynamicWrapper } from './styles';


interface BaseProps {
  visible: boolean;
  config: PanelControlsConfig;
  panelType?: PanelType;
  screenToPanelMap?: Types.ScreenToPanelMap;
}


type Props = Types.PropLeftOrRight & BaseProps;


export const SidePanelComponent: React.FC<Props> = (props: Props) => {
  const { 
    visible, 
    config,
    left,
    right, 
  } = props;

  const [scrollTop, setScrollTop] = useState(0);
  const context = { scrollTop };

  const screenSizeVariant = useScreenSizeVariantWatch();

  const screenToPanelMap = (
    props.screenToPanelMap !== undefined ?
    props.screenToPanelMap :
    Defaults.getScreenToPanelMap()
  );

  const panelType = (
    props.panelType !== undefined ? 
    props.panelType :
    screenToPanelMap[screenSizeVariant]
  );

  const panelWidthsMap = Defaults.getPanelWidthsMap();
  const panelWidth = panelWidthsMap[panelType];

  const handlePanelScroll = (event: any) => {
    setScrollTop(event.target.scrollTop);
  }


  return (
    <SidePanelContext.Provider value={context} >        
      <DynamicWrapper
        $left={left}
        $right={right}
        $width={panelWidth}
        $visible={visible}
        onScroll={handlePanelScroll}
      >
        <FixedWrapper
          $left={left}
          $right={right}
          $width={panelWidth}
        >
          <PanelControlsComponent
            config={config} 
            panelType={panelType}
          />
        </FixedWrapper>
      </DynamicWrapper>
    </SidePanelContext.Provider>
  );
}
