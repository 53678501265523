import React from 'react';
import ButtonComponent from 'lego/components/buttons/button';

import { ThemeVariantType } from 'app/arch/app/themes/types';
import { useTranslations  }   from 'app/ui/hooks/app/use-translation';
import { useThemeDescriptor } from 'app/ui/components/app/theme/use-theme-descriptor';
import useUserSettings        from 'app/ui-v2/app/__modules/user/hooks/use-user-settings';
import useUserSettingsSave    from 'app/ui-v2/app/__modules/user/hooks/use-user-settings-save';
import useUserStateSetters    from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';

import { DarkModeIcon  }    from './styles';
import { LightModeIcon }    from './styles';
import { ButtonGroup }      from './styles';
import { ButtonGroupLeft }  from './styles';
import { ButtonGroupRight } from './styles';


interface Props {
}


export const ControlThemeModeComponent: React.FC<Props> = (props: Props) => {
  const t   = useTranslations();
  const userSettings = useUserSettings();
  const theme = useThemeDescriptor();
  const saveUserSettings = useUserSettingsSave();
  const {
    setUserSettings
  } = useUserStateSetters();

  const handleThemeLight = (e: any) => {
    e.stopPropagation();
    updateUserSettings(ThemeVariantType.LIGHT);
  }

  const handleThemeDark = (e: any) => {
    e.stopPropagation();
    updateUserSettings(ThemeVariantType.DARK);
  }

  const updateUserSettings = (variant: ThemeVariantType) => {
    userSettings.updateTheme({ variant });
    setUserSettings();
    saveUserSettings();
  }

  return (
    <ButtonGroup>
      <ButtonComponent
        Button={
          <ButtonGroupLeft 
            $selected={theme.variant === ThemeVariantType.LIGHT}
            onClick={handleThemeLight}
          >
            <LightModeIcon />
          </ButtonGroupLeft>
        }
        tooltip={t('light mode')}
      />

      <ButtonComponent
        Button={
          <ButtonGroupRight 
            $selected={theme.variant === ThemeVariantType.DARK}
            onClick={handleThemeDark}
          >
            <DarkModeIcon />
          </ButtonGroupRight>
        }
        tooltip={t('dark mode')}
      />

    </ButtonGroup>
  );
}
  
