import { useContext } from "react";
import ViewPagesContext from "../components/view-page-context/context";


const useViewPagesContext = () => {
  const context = useContext(ViewPagesContext);
  return context;
}


export default useViewPagesContext;
