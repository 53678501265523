import React from 'react';
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';
import CellIndexValueComponent     from '../../cell-index-value';
import CellIndexColumnCssComponent from '../../cell-index-column-css';

import { MainWrapper } from './styles';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
}


export const CellIndexDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const { 
    cellAddr, 
  } = props;

  const document = useDocState();
  const columnProps = document.releaselogs.getColumnProps(cellAddr);
  const width = columnProps.width;

  return (
    <MainWrapper 
      style={{
        width: `${width}px`
      }}
    >
      <CellIndexColumnCssComponent cellAddr={cellAddr} >
        <CellIndexValueComponent cellAddr={cellAddr} />
      </CellIndexColumnCssComponent>
    </MainWrapper>
  );
}
