import { RateControl } from "lego/types";
import { BooleanType } from "./types";
import { NumberType }  from "./types";


export enum PanelControlsItemSectionItemType {
  BUTTON         = 'button',
  BUTTON_GROUP   = 'button-group',
  TOOLBAR        = 'toolbar',
  COLOR_PICKER   = 'color-picker',
  DROPDOWN_LIST  = 'dropdown-list',
  LIST           = 'list',
  INPUT          = 'input',
  SLIDER         = 'slider',
  TEXT           = 'text',
  TEXT_MULTILINE = 'text-multiline',
  USER_COMPONENT = 'user-component',
  PROGRESS       = 'progress',
  SPACER         = 'spacer',

  ITEMS_MIX      = 'items-mix',
}

export type ItemType = PanelControlsItemSectionItemType;

export enum PanelControlTextVariant {
  INFO    = 'info',
  WARNING = 'warning'
}

export interface PanelControlsItemSectionControlConfig {
  title?: string;
  dataTest?: string;
  disabled?: BooleanType;
  skip?: BooleanType;
  type: ItemType;
}

export interface PanelControlsItemSectionControl_ColorPickerConfig extends PanelControlsItemSectionControlConfig {
  color?: string;
  onColorSelected?: (color: string) => void;
}

export interface PanelControlsItemSectionControl_InputConfig extends PanelControlsItemSectionControlConfig {
  value: string;
  onChange?: (value: string) => void;
  onChangeDone?: (value: string) => void;
}

export interface PanelControlsItemSectionControl_TextConfig extends PanelControlsItemSectionControlConfig {
  value: string;
  variant?: PanelControlTextVariant;
}

export interface PanelControlsItemSectionControl_TextMultilineConfig extends PanelControlsItemSectionControlConfig {
  value: string;
}

export interface PanelControlsItemSectionControl_ButtonConfig extends PanelControlsItemSectionControlConfig {
  text?: string;
  tooltip?: string;
  Icon?: React.ElementType;
  width?: string;
  onClick?: (event: any) => void;
}

interface GroupButton {
  skip?: BooleanType;
  text?: string;
  tooltip?: string;
  Icon?: React.ElementType;
  rotate?: number;
  value: any;
  selected?: BooleanType;
};

export interface PanelControlsItemSectionControl_ButtonGroupConfig extends PanelControlsItemSectionControlConfig {
  buttons: GroupButton[];
  onClick: (value: any) => void;
}

export interface ToolbarSection {
  buttons: ToolbarButton[];
};

export interface ToolbarButton {
  text?: string;
  tooltip?: string;
  Icon?: React.ElementType;
  selected?: BooleanType;
  onClick?: (value: any) => void;
};

export interface PanelControlsItemSectionControl_ToolbarConfig extends PanelControlsItemSectionControlConfig {
  sections: ToolbarSection[];
}


export interface PanelControlsItemSectionControl_SliderConfig extends PanelControlsItemSectionControlConfig {
  min?:   number,
  max?:   number,
  step?:  number,
  value:  NumberType,
  controlLess?: boolean,
  rateControl?: RateControl,
  onChangeStart?: () => void,
  onChange?:      (value: number) => void,
  onChangeDone?:  (value: number) => void,
}

export interface PanelControlsItemSectionControl_ListConfig extends PanelControlsItemSectionControlConfig {
  values: string[]
  valueIdx?: number;
  onValueSelected?: (idx: number) => void,
  onValueEnter?:    (idx: number) => void,
  onValueLeave?:    (idx: number, prevIdx?: number, ) => void,
}


export interface PanelControlsItemSectionControl_DropdownListConfig extends PanelControlsItemSectionControlConfig {
  values: string[]
  value?: string;
  onValueSelected?: (value: string) => void,
}


export interface PanelControlsItemSectionControl_ProgressConfig extends PanelControlsItemSectionControlConfig {
}


export interface PanelControlsItemSectionControl_SpacerConfig extends PanelControlsItemSectionControlConfig {
  height?: number;
}

export interface PanelControlsItemSectionControl_ItemsMix extends PanelControlsItemSectionControlConfig {
  type: PanelControlsItemSectionItemType.ITEMS_MIX;
  items: SectionItem[];
}


export interface PanelControlsItemSectionControl_UserComponent extends PanelControlsItemSectionControlConfig {
  component:    React.FunctionComponent<any>,
  componentProps? : any, 
}


export type SectionItem = (
  PanelControlsItemSectionControl_ButtonConfig        |
  PanelControlsItemSectionControl_ButtonGroupConfig   |
  PanelControlsItemSectionControl_ColorPickerConfig   |
  PanelControlsItemSectionControl_DropdownListConfig  |
  PanelControlsItemSectionControl_InputConfig         |
  PanelControlsItemSectionControl_ListConfig          |
  PanelControlsItemSectionControl_ProgressConfig      |
  PanelControlsItemSectionControl_SliderConfig        |
  PanelControlsItemSectionControl_SpacerConfig        |
  PanelControlsItemSectionControl_TextConfig          |
  PanelControlsItemSectionControl_TextMultilineConfig |
  PanelControlsItemSectionControl_ToolbarConfig       |
  PanelControlsItemSectionControl_ItemsMix            |
  PanelControlsItemSectionControl_UserComponent 
);


export interface PanelControlsItemSubsectionConfig {
  title?: string;
  disabled?: BooleanType;
  skip?: BooleanType;
  items: SectionItem[];
}


export interface PanelControlsItemSectionConfig {
  title?: string;
  disabled?: BooleanType;
  skip?: BooleanType;
  items?: SectionItem[];
  subsections?: PanelControlsItemSubsectionConfig[];
}


export interface PanelControlsItemConfig {
  title?: string;
  sections: PanelControlsItemSectionConfig[];
  disabled?: BooleanType;
}


export interface PanelControlsConfig {
  items: PanelControlsItemConfig[];
  disabled?: BooleanType;
}
