import { settings } from "app/configs";
import { Position } from "../types/types";
import { Size }     from "../types/types";
import { RepoImagesTypes } from "../editor-instruction/document/states/loadable/repo-images";

type Image = RepoImagesTypes.Image;


class ImageTool {

  static getDefaultBoxedSize(image: Image) {
    // Calculate image size to fit into 
    // default EditorImage box  
    const defaultImageSettings = settings.editorImage.newImage;

    const scaleWidth  = defaultImageSettings.width / image.size[0];
    const scaleHeight = defaultImageSettings.height / image.size[1];
    const scale = Math.min(scaleWidth, scaleHeight);

    const size = [
      Math.floor(scale * image.size[0]),
      Math.floor(scale * image.size[1]) 
    ] as Size;

    return size;
  }

  static getDefaultViewScale(image: Image) {
    // Calculate default image scale for CellMedia
    //
    // Try to scale to default height. 
    // If using scale from default height calculation, 
    // cause scale width to be to large, use width scale.

    const size = ImageTool.getDefaultBoxedSize(image);
    const imageScaleTo = settings.printView.imageScaleTo;
    const scaleX = imageScaleTo.default.width / size[0];
    const scaleY = imageScaleTo.default.height / size[1];
    const widthTooLarge = (scaleY * size[0] > imageScaleTo.default.width);

    const viewScale = (
      widthTooLarge ?
      scaleX :
      scaleY
    );
    return viewScale;
  }

  static getDefaultPosition(image: Image) {
    const size = ImageTool.getDefaultBoxedSize(image);
    const deskSettings = settings.editorImage.desk;

    const position = [
      Math.floor((deskSettings.width - size[0]) / 2),
      Math.floor((deskSettings.height - size[1]) / 2),
    ] as Position;
    
    return position;
  }

  static getDefaultViewArea(image: Image) {
    const size     = ImageTool.getDefaultBoxedSize(image);
    const position = ImageTool.getDefaultPosition(image);

    const viewArea = {
      x1: position[0], 
      y1: position[1], 

      x2: size[0] + position[0] - 1,
      y2: size[1] + position[1] - 1
    };

    return viewArea;
  }
}


export default ImageTool;