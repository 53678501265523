import styled from 'styled-components';
import TextWrap from 'lego/styles/text-wrap';


export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.large};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xxsmall};
`;

export const ValueName = styled(TextWrap)`
  user-select: none;
  font-size: ${props => props.theme.defs.font.size.small};
  color: ${props => props.theme.defs.colors.color.primary};
  opacity: ${props => props.theme.defs.opacity.description};
`;

export const Value = styled(TextWrap)`
  color: ${props => props.theme.defs.colors.color.primary};
`;
