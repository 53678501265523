import { useEffect } from "react";
import useState from "./use-state"


const useViewParamsRead = () => {
  const homePageUser = useState();
  const viewParams = homePageUser.view.getView().params;

  useEffect(() => {
    homePageUser.view.update({params: null});
  }, []);

  return viewParams;
}


export default useViewParamsRead;