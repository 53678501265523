import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "./use-editor-states-setters";


/*
Hook will check if selected marker is still available.
As selected marker could not be available any more, due
to being deleted, or due to user pressing undo. Then
selected marker has to be unselected.
*/

const useMarkerSelectedCheck = () => {
  const document = useDocState();

  const {
    setEditorMarkerSession,
  } = useEditorStatesSetters();

  const check = () => {
    const marker = document.editorMarkerSession.getSelectedMarkerAddr();
    if ( ! marker) {
      return;
    }

    const markerPresent = document.repoMarkers.hasMarker(marker);
    if ( markerPresent ) {
      return;
    }

    // If selected/edited marker has been removed while undo took place
    // set marker select to null
    document.editorMarkerSession.updateWindow({visible: false});
    document.editorMarkerSession.setSelectedMarkerAddr(null);
    setEditorMarkerSession();
  }

  return check;
}


export default useMarkerSelectedCheck;
