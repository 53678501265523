import { useDocState } from "app/ui/contexts/document";
import { ReleaselogsTools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';


const usePrintoutReleaseInfoGet = () => {
  const document = useDocState();

  const hook = () => {
    const releaseDate = ReleaselogsTools.getReleaseDateIso();
    const revision    = document.instruction.getRevision();
    
    document.printoutReleaseInfo.updateRelease({
      date: releaseDate,
      revision,
    });

    const stateSerial = document.printoutReleaseInfo.serialize();
    return stateSerial;
  }

  return hook;
}

export default usePrintoutReleaseInfoGet;