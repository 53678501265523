import produce from 'immer';
import { SubscriptionsTools } from '../subscriptions';
import { SubscriptionsTypes } from '../subscriptions';

import * as Defaults from "./defaults";
import * as State from './state';
import * as Tools from "./tools";
import * as Types from "./types";



export class Invoices {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  reset() {
    this._state = State.createInitialState();
  }
  
  get raw() { return this._state; }


  /**
   * Setters
   */

  loadInvoices(
    invoicesRaw: Types.InvoicesRaw
  ) {
    this.reset();
    
    this._state = produce(this._state, draft => {
      invoicesRaw.forEach((invoiceRaw) => {
        this.__addInvoice(draft, invoiceRaw);
      });
      draft.loaded = true;
    });
  }

  private __addInvoice(
    draft: State.State,
    invoiceRaw: Types.InvoiceRaw
  ) {
    const subscriptionAddr = SubscriptionsTools.createSubscriptionAddr(invoiceRaw.subscription.subscriptionId);

    const hasSubscription = State.hasInvoices(draft, subscriptionAddr);
    if ( ! hasSubscription) {
      const subscriptionsInvoices = State.getSubscriptionsInvoices(draft);
      const subscriptionKey = SubscriptionsTools.getSubscriptionKey(subscriptionAddr);
      subscriptionsInvoices[subscriptionKey] = Defaults.getInvoices();
    }

    const invoicesAddrs = State.getInvoicesAddrs(draft, subscriptionAddr);
    const invoicesProps = State.getInvoicesProps(draft, subscriptionAddr);

    const invoiceAddr = Tools.createInvoiceAddr(
      invoiceRaw.subscription.subscriptionId,
      invoiceRaw.invoiceId
    );
    const invoiceKey = Tools.getInvoiceKey(invoiceAddr);

    const {
      invoiceId,
      subscription,
      ...invoiceProps
    } = invoiceRaw;

    invoicesAddrs.push(invoiceAddr);
    invoicesProps[invoiceKey] = invoiceProps;
  }


  /**
   * Getters
   */

  getLoaded(): boolean {
    return State.getLoaded(this._state);
  }

  getSubscriptionsInvoices(): Types.SubscriptionInvoices {
    return State.getSubscriptionsInvoices(this._state);
  }

  hasInvoices(
    subscriptionAddr: SubscriptionsTypes.SubscriptionAddr
  ): boolean {
    return State.hasInvoices(this._state, subscriptionAddr);
  }

  getInvoices(
    subscriptionAddr: SubscriptionsTypes.SubscriptionAddr
  ): Types.Invoices {
    return State.getInvoices(this._state, subscriptionAddr);
  }

  getInvoicesAddrs(
    subscriptionAddr: SubscriptionsTypes.SubscriptionAddr
  ): Types.InvoicesAddrs {
    return State.getInvoicesAddrs(this._state, subscriptionAddr);
  }

  getInvoicesProps(
    subscriptionAddr: SubscriptionsTypes.SubscriptionAddr
  ): Types.InvoicesProps {
    return State.getInvoicesProps(this._state, subscriptionAddr);
  }

  getInvoiceProps(
    invoiceAddr: Types.InvoiceAddr
  ): Types.InvoiceProps {
    return State.getInvoiceProps(this._state, invoiceAddr);
  }
}
