import React from 'react';

import GridComponent from './grid';
import HeaderComponent from './header';
import CellsComponent from './cells';


interface Props {
}


export const RowsGridComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;


  return (
    <GridComponent>
      <HeaderComponent />
      <CellsComponent />
    </GridComponent>
  );
}

