import React from 'react';
import { PanelDraggableItemButtonConfig } from '../../config';
import { MainWrapper } from './styles';


interface Props {
  config: PanelDraggableItemButtonConfig;
  selected?: boolean;
}


export const ItemButtonComponent: React.FC<Props> = (props: Props) => {
  const { config, selected } = props;

  const Icon = config.Icon || <React.Fragment />;

  return (
    <MainWrapper
      $selected={selected}
      onClick={config.onClick}
    >
      <Icon />
    </MainWrapper>
  );
}
  
