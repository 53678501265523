import { ShapeCalcBase } from "./shape-calc-base";


export class ShapeCalcArrow extends ShapeCalcBase {
  get borderWidthAngledVertical() {
    const sinValue = Math.sin(this.arrowHeadAngle);
    if ( ! sinValue ) {
      return 0;
    }

    return this.borderWidth / sinValue;
  }

  get outlineWidthAngledVertical() {
    const sinValue = Math.sin(this.arrowHeadAngle);
    if ( ! sinValue ) {
      return 0;
    }
    return this.outlineWidth  / sinValue;
  }

  get borderWidthAngledHorizontal() {
    const ret = (this.borderWidthAngledVertical + this.borderWidth) / Math.tan(Math.PI / 2 - this.arrowHeadAngle);
    return ret;
  }

  get outlineWidthAngledHorizontal() {
    const ret = (this.outlineWidthAngledVertical + this.outlineWidth) / Math.tan(Math.PI / 2 - this.arrowHeadAngle);
    return ret;
  }

  get arrowHeadAngle() {
    return Math.atan2(
      (this.arrowHeadWidth + this.arrowShaftWidth) / 2, 
      this.arrowHeadHeight);
  }

  get arrowHeadHeightTotal() {
    const height = (
      this.outlineWidthAngledVertical +
      this.borderWidthAngledVertical + 
      this.arrowHeadHeight + 
      this.borderWidth + 
      this.outlineWidth
    );
    return height;
  }

  get bodyWidthTotal() {
    const width = 
      this.arrowShaftWidth +
      2 * (this.borderWidth + this.outlineWidth)
    ;

    return width;
  }

  get widthTotal() {
    const width =
      2 * this.outlineWidthAngledHorizontal + 
      2 * this.borderWidthAngledHorizontal + 
      this.arrowShaftWidth + 
      this.arrowHeadWidth
    ;

    return width;
  }

  get heightTotal() {
    const dx = this._endPoint[0] - this._startPoint[0];
    const dy = this._endPoint[1] - this._startPoint[1];
    const height = Math.sqrt(dx ** 2 + dy ** 2);
  
    return height;
  }
};