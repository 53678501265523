import styled from "styled-components";


export const StyledBtnBase = styled.button`
  display: inline-flex;
  flex-direction: row;
  border: 0;
  cursor: pointer;

  box-sizing: border-box;
  align-items: center;

  user-select: none;
  background-color: #fff;
  transition: background-color 0.2s ease-in-out;

  border-radius: 6px;


  &:not(:disabled):hover {
    background-color: #CEEDFA;
    /* background-color: #eee; */
  }

  // This should be called selected
  // to avoid confusion with :active
  // TODO
  &.active {
    background-color: #CEEDFA;
  }

  &:not(:disabled):active {
    background-color: #CEEDFA;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #ddd;
    color: #aaa;
  }

  &:disabled:hover {
    background-color: #ddd;
    color: #aaa;
  }
`;
