import * as Types from "./types"


export const getPanelAssets = (): Types.PanelAssets => ({
  visible: false,
  selected: Types.PanelAssetsItem.IMAGE,
});

export const getPanelProperties = (): Types.PanelProperties => ({
  visible: false,
  selected: Types.PanelPropertiesItem.PAGE,
});
  
