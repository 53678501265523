import { OrientedSmartLinesRaw } from "tools/smart-lines/types";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


export const useSmartLinesWidgetsSrcLinesSetter = () => {
  const document = useDocState();

  const {
    setEditorImageSession,
  } = useEditorStatesSetters();

  const setSrcLines = (srcLines: OrientedSmartLinesRaw) => {
    // document.editorImage2Session.setSmartLinesWidgetsSrcLines(srcLines);
    // setEditorImage2Session();
  }

  const unsetSrcLines = () => {
    document.editorImageSession.setSmartLinesWidgetsSrcLines(null);
    setEditorImageSession();
  }

  return {
    setSrcLines,
    unsetSrcLines,
  };
}
