import React from 'react';

import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';
import useGoogleOAuthReadyWatch from 'app/ui-v2/app/__modules/google-oauth/hooks/use-google-oauth-check';

import { TextError } from './styles';


interface Props {
  children: React.ReactNode;
}

export const GoogleOAuthReadyCheckComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;

  const t = useTranslations();
  const googlOAuth = useGoogleOAuthReadyWatch();
  
  const displayChildren = (  googlOAuth.ready && ! googlOAuth.error);
  const displayProgress = (! googlOAuth.ready && ! googlOAuth.error);
  const displayError    = googlOAuth.error;


  return (
    <>
      { displayChildren && children }
      { 
        displayProgress && 
        <ProgressBoxComponent hideTitle={true}/> 
      }
      { 
        displayError &&
        <TextError>
          { t('google oauth, provider error') }
        </TextError>
      }
    </>
  );
}

