import styled from "styled-components";


export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ProgressWrapper = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
