import ExcelJS from 'exceljs';
import { ContentTools } from '../../../document/states/persistent/content';
import { ContentTypes } from '../../../document/states/persistent/content';
import * as Types from './types';


class XLSRows {
  private _rows: Types.Rows;

  constructor() {
    this._rows = {}
  }

  addRow(
    rowAddr: ContentTypes.RowAddr, 
    xlsRow: ExcelJS.Row
  ) {
    const rowKey = ContentTools.getRowKey(rowAddr);

    if ( rowKey in this._rows ) {
      const msg = `Row already in repo`;
      throw new Error(msg);
    }

    this._rows[rowKey] = xlsRow;
  }
 
  getRow(
    rowAddr: ContentTypes.RowAddr, 
  ): ExcelJS.Row {
    const rowKey = ContentTools.getRowKey(rowAddr);

    if ( ! ( rowKey in this._rows )) {
      const msg = `Row not in repo`;
      throw new Error(msg);
    }

    const xlsRow = this._rows[rowKey];
    return xlsRow;
  }
}


export default XLSRows;
