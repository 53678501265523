import React from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { useDocState }         from 'app/ui/contexts/document';
import ContentSaveBasePlugin   from 'app/ui/components/editor-txt/plugins/content-save-plugin-base';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters  from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


interface Props {
  cellAddr: Types.CellAddr;
}


export const ContentSavePlugin: React.FC<Props> = (props: Props) => {
  const {
    cellAddr, 
  } = props;

  const document = useDocState();

  const { 
    setContent 
  } = useEditorStatesSetters();

  const editorState = useRecoilValue(
    UIState_Content.cellText_editorState(cellAddr)
  );

  const handleEditorStateInit = (editorStateUpdate: string) => {
    // Initial editor state should be set during cell
    // construction. If so, this method should not be
    // called.
    console.error("[ViewContent] Editor text state init called");

    // document.docContent2.cellText_writeContent(cellAddr, editorStateUpdate);
    // document.saveUndo();
    // setDocContent2();
  }

  const handleEditorStateSave = (editorStateUpdate: string) => {
    document.content.cellText_writeContent(cellAddr, editorStateUpdate);
    document.saveUndo();
    setContent();
  }

  return (
    <ContentSaveBasePlugin
      editorTextState={editorState}
      onInitEditorTextState={handleEditorStateInit}
      onSaveEditorTextState={handleEditorStateSave}
    />
  );
}
