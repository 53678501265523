import styled from "styled-components";
import TextWrap from "lego/styles/text-wrap";


export const Title = styled(TextWrap)`
  min-width: 60px;
  flex: 1 1 0;
`;


export const DateTimeWrapper = styled(TextWrap)`
  flex: 0 0 54px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* background-color: red; */
`;

export const Text = styled.div`
  /* color: white; */
  font-size: ${props => props.theme.defs.font.size.small};
`;

