import React from 'react';

import { ViewTypes } from "app/arch/home-page-guest/states/view"
import { NavbarTypes } from "app/arch/home-page-guest/states/navbar";
import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useViewSelect       from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-select';
import useNavbarItemSelect from 'app/ui-v2/home-page/home-page-guest/hooks/use-navbar-item-select';
import usePricePlan from '../../../hooks/use-price-plan';
import BuyButtonComponent  from '../parts/buy-button';


interface Props {
}


export const PricePlanSingupComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const selectView = useViewSelect();
  const selectNavbarItem = useNavbarItemSelect();

  const pricePlan = usePricePlan();

  const btnDisabled = (pricePlan.planType !== PricePlansTypes.PlanType.FREE);
  const btnLabel    = (
    pricePlan.planType === PricePlansTypes.PlanType.FREE ?
    t("sign up") :
    t("buy") 
  );

  const handleClick = () => {
    selectView(ViewTypes.ViewItem.USER_SIGNUP);
    selectNavbarItem(NavbarTypes.NavbarItem.USER_SIGNUP);
  }

  return (
    <BuyButtonComponent 
      label={btnLabel}
      onClick={handleClick}
      disabled={btnDisabled}
    />
  );
}

