import React from 'react';
import TopToolbarComponent from 'app/ui-v2/editor-instruction/blocks/top-toolbar';
import useConfig from './config/use-config';


interface Props {
}


export const TopToolbarCommonComponent: React.FC<Props> = (props: Props) => {
  const {
  } = props;

  const getConfigSections = useConfig();
  const sections = getConfigSections();

  return (
    <TopToolbarComponent
      sections={sections}
    />
  );
}

