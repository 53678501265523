import produce from 'immer';
import * as Types from './types';
import * as State from './state';


export class RepoImagesSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }
  
  reset() {
    this._state = State.createInitialState();
  }


  /**
   * Setters
   */

  addImagesUploading(imgsCount: number) {
    this._state = produce(this._state, draft => {
      const uploadingImages = draft.uploadingImages;
      uploadingImages.count = uploadingImages.count + imgsCount;
    });
  }

  removeImagesUploading(imgsCount: number) {
    this._state = produce(this._state, draft => {
      const uploadingImages = draft.uploadingImages;
      const updatedCount = uploadingImages.count - imgsCount;

      if ( updatedCount < 0 ) {
        const msg = `Removing more images than it has`;
        throw new Error(msg);
      }

      uploadingImages.count = updatedCount;
    });
  }


  /**
   * Getters
   */

  getImagesUploading() {
    return State.getImagesUploading(this._state);
  }
}
