import * as Tools from './tools';
import * as Defaults from './defaults';
import * as Types from './types';


export type State = Types.Prices;


export const getLoaded = (
  state: State,
): boolean => {
  return state.loaded;
}

export const getPricesKeys = (
  state: State,
): Types.PricesKeys => {
  return state.pricesKeys;
}

export const getPricesProps = (
  state: State,
): Types.PricesProps => {
  return state.pricesProps;
}

export const getPriceProps = (
  state: State,
  priceKey: Types.PriceKey
): Types.PriceProps => {
  const pricesProps = getPricesProps(state);

  const priceProps = pricesProps[priceKey];
  if (priceProps === undefined) {
    const msg = `Price not found, price key: ${priceKey}`;
    throw new Error(msg);
  }

  return priceProps;
}

export const getPriceAmount = (
  state: State,
  priceKey: Types.PriceKey
): number => {
  const priceProps = getPriceProps(state, priceKey);
  const amount = priceProps.amount / 100;
  return amount;
}


/**
 * Create initial state
 */

export const createInitialState = (): State => {
  const initState: State = {
    pricesKeys: [],
    pricesProps: {},
    loaded: false,
  }

  return initState;
}
