import produce from 'immer';
import { ContentTools } from '../../persistent/content';
import { ContentTypes } from '../../persistent/content';
import * as Types from './types';
import * as State from './state';


export class ContentSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }
  
  reset() {
    this._state = State.createInitialState();
  }

  //----------------------
  // Setters
  //

  /**
   * Content
   */
  
  setSectionSelected(sectionAddr: ContentTypes.SectionAddr | null) {
    this._state = produce(this._state, draft => {
      draft.section.selected = sectionAddr;
    });
  }

  setCellSelected(cellAddr: ContentTypes.CellAddr | null) {
    this._state = produce(this._state, draft => {
      draft.cell.selected = cellAddr;
    });
  }

  cellImages_setImageSelected(imageAddr: ContentTypes.ImageAddr | null) {
    this._state = produce(this._state, draft => {
      draft.cellImages.selectedImage = imageAddr;
    });
  }

  cellImages_addImagesUploading(
    cellAddr: ContentTypes.CellAddr,
    imgsCount: number,
  ) {
    this._state = produce(this._state, draft => {
      const cellKey = ContentTools.getCellKey(cellAddr);
      const cellUploadingImages = draft.cellImages.uploadingImages;

      if (cellKey in cellUploadingImages) {
        const currentCount = cellUploadingImages[cellKey];
        cellUploadingImages[cellKey] = currentCount + imgsCount;
      }
      else {
        cellUploadingImages[cellKey] = imgsCount;
      }
    });
  }

  cellImages_removeImagesUploading(
    cellAddr: ContentTypes.CellAddr,
    imgsCount: number,
  ) {
    this._state = produce(this._state, draft => {
      const cellKey = ContentTools.getCellKey(cellAddr);
      const cellUploadingImages = draft.cellImages.uploadingImages;

      if (cellKey in cellUploadingImages) {
        const currentCount = cellUploadingImages[cellKey];
        const updatedCount = currentCount - imgsCount;
        if ( updatedCount < 0 ) {
          const msg = `Removing more images than it has`;
          throw new Error(msg);
        }

        cellUploadingImages[cellKey] = updatedCount;
      }
      else {
        const msg = `Can't find uploading images`;
        throw new Error(msg);
      }
    });
  }

  cellMarkers_setMarkerSelected(markerAddr: ContentTypes.MarkerAddr | null) {
    this._state = produce(this._state, draft => {
      draft.cellMarkers.selectedMarker = markerAddr;
    });
  }


  /**
   * Custom Fields
   */

  customFields_setCellSelected(cellAddr: ContentTypes.CellAddr | null) {
    this._state = produce(this._state, draft => {
      draft.cell.selected = cellAddr;
    });
  }


  /**
   * Side Panels
   */
  updatePanelAssets(update: Types.PanelAssetsUpdate) {
    this._state = produce(this._state, draft => {
      const panels = draft.panels;
      const assets = panels.assets;
  
      panels.assets = {
        ...assets,
        ...update,
      }
    });
  }

  updatePanelProperties(update: Types.PanelPropertiesUpdate) {
    this._state = produce(this._state, draft => {
      const panels = draft.panels;
      const properties = panels.properties;
  
      panels.properties = {
        ...properties,
        ...update,
      }
    });
  }


  //----------------------
  // Getters
  //

  /**
   * 
   * Section
   * 
   */

  getSectionSelected() {
    return State.getSectionSelected(this._state);
  }

  isSectionSelected(sectionAddr: ContentTypes.SectionAddr) {
    return State.isSectionSelected(this._state, sectionAddr);
  }



  /**
   * 
   * Cell
   * 
   */

  getCellSelected() {
    return State.getCellSelected(this._state);
  }

  isCellSelected(cellAddr: ContentTypes.CellAddr) {
    return State.isCellSelected(this._state, cellAddr);
  }


  /**
   * 
   * Cell
   * 
   */

  getCellImages() {
    return State.getCellImages(this._state);
  }

  cellImages_countImagesUploading(cellAddr: ContentTypes.CellAddr) {
    return State.cellImages_countImagesUploading(
      this._state,
      cellAddr
    );
  }

  cellImages_getImageSelected() {
    return State.cellImages_getImageSelected(this._state);
  }
  
  getCellMarkers() {
    return State.getCellMarkers(this._state);
  }

  /**
   * 
   * Panel
   * 
   */
  getPanelAssets() {
    return State.getPanelAssets(this._state);
  }

  getPanelProperties() {
    return State.getPanelProperties(this._state);
  }
}
