import styled from "styled-components";
import PanelHeaderBase from "lego/styles/panel-header";


export const PanelHeader = styled(PanelHeaderBase)`
  justify-content: space-between;
`;

const WrapperBase = styled.div`
  cursor: pointer;
  flex: 0 1 auto;

  display: flex;
  align-items: center;
  gap: ${props => props.theme.defs.gap.xxsmall};
`;

export const SortTitle = styled(WrapperBase)`
  min-width: 60px;
  /* background-color: red; */
`;

export const SortDate = styled(WrapperBase)`
  /* text-align: end; */
  /* background-color: red; */
`;