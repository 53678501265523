import React from 'react';

import { PanelControlsItemSubsectionConfig as Config} from '../../../config';
import PanelSectionSubsectionComponentBase from '../../base/panel-section-subsection';
import PanelSectionItemComponent from '../panel-section-item';


interface Props {
  config: Config;
}


export const PanelSectionSubsectionComponent: React.FC<Props> = (props: Props) => {
  const { 
    config 
  } = props;

  return (
    <PanelSectionSubsectionComponentBase
      config={config}
      SectionItem={PanelSectionItemComponent}
    />
  );
}
  
