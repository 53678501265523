import { useEffect, useState } from 'react';


interface Props {
  duration: number;
  tick: number;
  onDone: () => void;
}


export const useTimer = ({duration, tick, onDone}: Props) => {
  const [seconds, setSeconds] = useState(duration);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        let next = prevSeconds - (tick / 1000);
        next = next < 0 ? 0 : next;
        return next;
      });
    }, tick);

    if (seconds <= 0) {
      clearInterval(intervalId);
      onDone();
    }

    return () => clearInterval(intervalId);
  }, [seconds]);

  return {
    time: seconds,
  }
}
