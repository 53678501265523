import React, { useState } from 'react';

import { useTimer } from 'lego-hooks/use-timer';

import useAppLogin from 'app/ui/hooks/app/use-app-login';
import ViewPanelComponent from 'app/ui-v2/home-page/hls/view-panel';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { Column }       from './styles';
import { Row }          from './styles';
import { TimerWrapper } from './styles';


export const TIMER_DURATION = 3;

interface Props {
  email: string;
  password: string;
}


export const PasswordUpdatedComponent: React.FC<Props> = (props: Props) => {
  const { 
    email, 
    password 
  } = props;

  const t = useTranslations();
  const appLogin = useAppLogin();

  const onTimerDone = () => {
    appLogin(email, password);
  }

  const timer = useTimer({
    duration: TIMER_DURATION,
    tick: 100,
    onDone: onTimerDone
  });


  return (
    <ViewPanelComponent title={t("password reset ok")} >
      <Column>
        <Row>
          <div>
          {t("auto login in")}: 
          </div>
          <TimerWrapper>
            {` ${timer.time.toFixed(1)} `}
          </TimerWrapper>
          <div>
          {t("seconds")}
          </div>
        </Row>
      </Column>
    </ViewPanelComponent>
  );
}

