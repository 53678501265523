import { PanelControlsItemSubsectionConfig } from "lego/components/panel-controls/config";
import { RepoMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/repo-markers";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useItems from "./__use-items";


const useConfigSubsections = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
  const t = useTranslations();
  const items = useItems(markerAddr);

  const getConfigSubsections = () => {
    const subsections: PanelControlsItemSubsectionConfig[] = [
      {
        title: t("marker sign"),
        items: [
          items.getSignType()
        ]
      },
    ];
  
    return subsections;
  }

  return getConfigSubsections;
}

export default useConfigSubsections;