import styled from 'styled-components';
import PanelBase from "app-views/styles/panel";
import { getScrollbarCSS } from 'lego/styles/scrollbar';


export const PanelDefault = styled(PanelBase)`
  color: ${props => props.theme.defs.colors.color.primary};

  height: 100%;
  overflow: auto;
  
  ${props => getScrollbarCSS(props)};
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.defs.colors.scrollbar.thumb.secondary};
  }
`;
