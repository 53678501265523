import { RefObject } from 'react';
import { Position } from 'app/arch/types';


interface Props {
  deskAreaRef: RefObject<HTMLDivElement>; 
  scale: number;
  position: Position;
}

export interface HookProps {
  scaleNew: number,
  scalePoint: Position,
}


const useRescaleReposition = (props: Props) => {
  const {
    deskAreaRef,
    scale,
    position,
  } = props;

  const reposition = ({
    scaleNew,
    scalePoint,
  }: HookProps) => {

    // Scaling has been already done. Now adjust 
    // so scale center point is belowe cursor/pointer.   

    const scaleNow = scale;
    const deskArea = deskAreaRef.current;

    if ( ! deskArea) {
      const msg = 'Desk area is not ready';
      throw new Error(msg);
    }
    
    const deskBBox = deskArea.getBoundingClientRect();
    const deskPosition = [deskBBox.x, deskBBox.y];
  
    const scalePointAdjusted = [
      scalePoint[0] - deskPosition[0],
      scalePoint[1] - deskPosition[1]
    ];

    const deltaScale  = scaleNew - scaleNow;
    
    const deltaX = deltaScale * (scalePointAdjusted[0] - position[0]) / scaleNow;
    const deltaY = deltaScale * (scalePointAdjusted[1] - position[1]) / scaleNow;

    const x = position[0] - deltaX;
    const y = position[1] - deltaY;

    const positionNew = [x, y] as Position;

    return positionNew;
  }

  return reposition;
}


export default useRescaleReposition;

