import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";


interface Props {
  signal: () => void;
}


const useColumnWidthSignal = (props: Props) => {
  const {
    signal
  } = props;

  const debouncedSignal= useDebouncedCallback(() => {
    signal();
  });
  
  useEffect(() => {
    return () => {
      debouncedSignal.flush();
    }
  },[debouncedSignal]);


  const hook = (props: { 
    debounced?: boolean 
  } = { debounced: true }) => {
    const { debounced } = props;

    if (debounced) { 
      debouncedSignal(); 
    }
    else { 
      signal(); 
    }
  }

  return hook;
}

export default useColumnWidthSignal;