import React from 'react';

import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import useSectionMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/section/use-section-menu';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useSectionSelectedSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/section/use-section-selected-set';

import { MainWrapper } from './styles';


interface Props {
  sectionAddr: TypesContent.SectionAddr;
  children: React.ReactNode;
}


export const SectionNameContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    sectionAddr,
    children
  } = props;

  const getMenuConfig = useSectionMenu();
  const { showMenu  } = useContextMenu();
  const setSectionSelected = useSectionSelectedSet ();

  const handleContextMenu = (event: React.MouseEvent) => {
    setSectionSelected({sectionAddr});
    const config = getMenuConfig(sectionAddr);

    showMenu({
      event,
      config
    });
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
