import styled from "styled-components";
import Scrollbar from "lego/styles/scrollbar";


export const Scroller = styled(Scrollbar)`
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;


export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  gap:     ${props => props.theme.defs.gap.normal};
  padding: ${props => props.theme.defs.padding.normal};
`;
