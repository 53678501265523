import React from 'react';
import { useEffect } from 'react';
import { RefObject } from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { UIState_HeaderRows } from 'app/ui/states/editor-instruction';
import { ColumnCSS } from './styles';


interface Props {
  pageCellAddr: HeaderRowsTypes.PageCellAddr;
  editorTextRef: RefObject<HTMLDivElement | null>;
  children: React.ReactNode;
}


export const CellCSSCustomComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr,
    editorTextRef,
    children,
  } = props;

  const styleWhole = useRecoilValue(UIState_HeaderRows.rowCSS(pageCellAddr));

  const {
    padding,
    ...style
  } = (styleWhole || {});


  useEffect(() => {
    if (editorTextRef.current === null) {
      return;
    }

    editorTextRef.current.style.padding = padding as string;
    // editorTextRef.current.style.backgroundColor = "red";
  }, [padding, editorTextRef.current]);

  return (
    <ColumnCSS style={{
      // background: 'red'
    }}>
      { children }
    </ColumnCSS>
  );
}
  
