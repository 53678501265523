import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const usePageRowSelectedSet = () => {
  const docState = useDocState();

  const {
    setCustomRowsRowsSelected
  } = useEditorStatesSetters();

  const selectPageColumn = (props : {
    pageRowAddr: HeaderRowsTypes.PageRowAddr,
    skipStateUpdate?: boolean,
  }) => {
    const {
      pageRowAddr, 
      skipStateUpdate
    } = props;

    docState.customRowsRowsSelected.select(pageRowAddr);

    if (skipStateUpdate) {
      return;
    }
    
    setCustomRowsRowsSelected();
  }

  return selectPageColumn;
}

export default usePageRowSelectedSet;