import React from 'react';
import { RunningSettings } from 'app/arch/editor-instruction/running-settings/running-settings';
import ProbeScrollbarWidthComponent from 'app/ui-v2/app/components/probes/probe-scrollbar-width';


interface Props {
}


export const LoaderScrollbarProbeComponent: React.FC<Props> = (props: Props) => {

  const handleScrollbarWidthChange = (width: number) => {
    RunningSettings.scrollbarWidth = width;
  }

  return (
    <ProbeScrollbarWidthComponent 
      onWidthChange={handleScrollbarWidthChange}
    />
  );
}
  
