import React from 'react';
import { RefObject } from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { Position } from 'app/arch/types';
import useDeskPositionInit from './use-desk-position-init';


interface Props {
  deskAreaRef: RefObject<HTMLDivElement>; 
  imageAddr: ContentTypes.ImageAddr;
  scale: number;
  position: Position;
  onPositionChange: (position: Position) => void;

  children: React.ReactNode;
}


export const DeskPositionInitComponent: React.FC<Props> = (props: Props) => {
  const {
    deskAreaRef,
    imageAddr,
    scale,
    position,
    onPositionChange,
    children,
  } = props;

  useDeskPositionInit({
    deskAreaRef,
    imageAddr,
    scale,
    position,
    onPositionChange, 
  });
  

  return children;
}

