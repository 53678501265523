import React, { ComponentType } from 'react';
import { StyledListBase     } from './styles';


interface Props {
  component?: ComponentType<any>;
  children: any;
}


export const ListComponent = (props: Props) => {
  const StyledList = (
    props.component ?
    props.component : StyledListBase
  );

  return (
    <StyledList>
      { props.children }
    </StyledList>
  );
};
