import styled from "styled-components";
import { StyledBtnBase      } from "lego-v2/styled";


export interface StyledBtnIconBaseProps {
  $icon?: string;
}


export const StyledBtnTextBase = styled(StyledBtnBase)`
  color: #333;
  background-color: #eee;
  font-size: 16px;
  min-width: 50px;
  white-space: nowrap;
  justify-content: center;
  font-weight: 500;
  font-variant-caps: all-small-caps;
  height: 30px;

  padding: 0px 1px 2px 1px;

  transition: background-color 0.2s ease-in-out;

  &:not(:disabled):hover {
    border-color: #1976d2;
    border-width: 2px;
    box-sizing: border-box;
  }
`;
