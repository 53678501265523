import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useColumnToggleSelect = () => {
  const docState = useDocState();

  const {
    setContentColumnsSelected,
  } = useEditorStatesSetters();

  const selectColumn = (props: {
    columnAddr: ContentTypes.ColumnAddr,
    resetSelected?: boolean,
    skipStateUpdate?: boolean,
  }) => {
    const {
      columnAddr,
      resetSelected,
      skipStateUpdate
    } = props;

    if (resetSelected) {
      docState.contentColumnsSelected.reset();
    }

    docState.contentColumnsSelected.toggleSelect(columnAddr);

    if (skipStateUpdate) {
      return;
    }

    setContentColumnsSelected();
  }

  return selectColumn;
}


export default useColumnToggleSelect;