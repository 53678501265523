import styled from "styled-components";
import ButtonGroupLeftBase   from "lego/styles/button_group_left";
import ButtonGroupMiddleBase from "lego/styles/button_group_middle";
import ButtonGroupRightBase  from "lego/styles/button_group_right";

import { MdDarkMode } from 'react-icons/md';
import { BsBrightnessLowFill } from 'react-icons/bs';
import { CiBrightnessUp   } from 'react-icons/ci';


import getReactIconProps from "lego/css-props/react-icon";


export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ButtonGroupLeft = styled(ButtonGroupLeftBase)`
`;

export const ButtonGroupRight = styled(ButtonGroupRightBase)`
`;


export const LightModeIcon = styled(BsBrightnessLowFill)`
  ${ props => getReactIconProps(props) };
`;

export const DarkModeIcon = styled(MdDarkMode)`
  ${ props => getReactIconProps(props) };
`;

