import styled from 'styled-components';
import DraggerBase from "lego-v2/dragger/ui/styles";


interface MainWrapperProps {
  $horizontal: boolean;
  $pageSize: [number, number];
}

export const MainWrapper = styled.div<MainWrapperProps>`
  position: absolute;
  width:  ${props => props.$horizontal ? '0px' : `${props.$pageSize[0]}px`};
  height: ${props => props.$horizontal ? `${props.$pageSize[1]}px` : '0px'};
`;


//---------------------
// Margin wrapper

interface MarginWrapperProps {
  $horizontal: boolean;
  $pageSize: [number, number];
  $draggerWidth: number;
}

export const MarginWrapper = styled.div.attrs<MarginWrapperProps>(props => ({
  style: {
    width: (
      props.$horizontal ? 
      `${props.$pageSize[0]}px` :
      `${props.$draggerWidth}px`
    ),
    height: (
      props.$horizontal ? 
      `${props.$draggerWidth}px` :
      `${props.$pageSize[1]}px` 
    ),
  }
}))<MarginWrapperProps>`
  position: relative;
  cursor: ${props => props.$horizontal ? 'ns-resize' : 'ew-resize'};
  /* background: rgba(0, 255, 0, 0.2); */
`;



const MARGIN_GRADIENT_LINE_SIZE = 4;
const MARGIN_GRADIENT_GAP_SIZE  = 6;
const MARGIN_COLOR     = '#555';
const MARGIN_GAP_COLOR = 'transparent';


const getGradientDef = (direction: string) =>`
  repeating-linear-gradient(${direction},
    ${MARGIN_COLOR}, 
    ${MARGIN_COLOR} ${MARGIN_GRADIENT_LINE_SIZE}px, 
    ${MARGIN_GAP_COLOR} ${MARGIN_GRADIENT_LINE_SIZE}px, 
    ${MARGIN_GAP_COLOR} ${(MARGIN_GRADIENT_LINE_SIZE + MARGIN_GRADIENT_GAP_SIZE)}px)
`;


//-----------------------------
//

interface MarginProps {
  $horizontal: boolean;
  $marginWidth: number;
  $draggerWidth: number;
  $mobile: boolean;
}

export const StyledMargin = styled.div<MarginProps>`
  position: relative;


  // Horizontal
  //---------------
  height: ${props => props.$horizontal ? 
    `${props.$marginWidth}px` : 
    `inherit`
  };

  top: ${props => props.$horizontal ?  
    `${(props.$draggerWidth - props.$marginWidth) / 2}px` :
    'auto'
  };

  // Vertical
  //---------------
  width: ${props => ! props.$horizontal ? 
    `${props.$marginWidth}px` :
    `inherit`
  };

  left: ${props => ! props.$horizontal ?  
    `${(props.$draggerWidth - props.$marginWidth) / 2}px` :
    'auto'
  }; 

  /* background: ${props => props.$mobile ? 
    MARGIN_COLOR :
    (props.$horizontal ? 
      getGradientDef('to right') : 
      getGradientDef('to bottom'))
  }; */

  background: ${props => props.theme.margins.background};
  /* background: #555; */
`;


export const Dragger = styled(DraggerBase)`
  height: inherit;
  width: inherit;
`;
