import React from 'react';
import FloatingPanelComponent from 'lego-v2/floating-panel';

import { Position } from 'app/arch/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDocState }     from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


interface Props {
  children: React.ReactNode;
}


export const EditorLogoWindowComponent: React.FC<Props> = (props: Props) => {
  const {
    children 
  } = props;

  const t = useTranslations();
  const docState = useDocState();
  const window = docState.editorLogoSession.getWindow();
  
  const {
    setEditorLogoSession
  } = useEditorStatesSetters();


  const handleWindowPosition = (position: Position) => {
    docState.editorLogoSession.updateWindow({position});
    setEditorLogoSession();
  }

  const handleWindowClose = () => {
    docState.editorLogoSession.updateWindow({visible: false});
    setEditorLogoSession();
  }


  return (
    <FloatingPanelComponent
      title={t('logo editor')}
      position={window.position}
      onPosition={handleWindowPosition}
      onClose={handleWindowClose}
    >
      { children }
    </FloatingPanelComponent>
  );
}
