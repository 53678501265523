import React from 'react';
import { Item } from './styles';
import { MainWrapper } from './styles';
import { Title } from './styles';


interface Props {
  title: string;
  Element: React.ReactElement;
}


export const PanelItemComponent: React.FC<Props> = (props: Props) => {
  const {
    title,
    Element
  } = props;

  return (
    <MainWrapper>
      <Title>
        { title }
      </Title>

      <Item>
        { Element }
      </Item>
    </MainWrapper>
  );
}

