import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const usePageCellSelectedSet = () => {
  const document = useDocState();

  const {
    setHeaderFieldsSession
  } = useEditorStatesSetters();


  const selectPageCell = (props: {
    pageCellAddr: Types.PageCellAddr | null,
    skipStateUpdate?: boolean,
  }) => {
    const {
      pageCellAddr,
      skipStateUpdate
    } = props;

    document.headerFieldsSession.setPageCellSelected(pageCellAddr);
    
    if (skipStateUpdate) {
      return;
    }
    
    setHeaderFieldsSession();
  }

  return selectPageCell;
}

export default usePageCellSelectedSet;