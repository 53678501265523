import i18next from 'i18next';
import { PanelControlsConfig } from "lego/components/panel-controls/config"
import {  PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import { Arrow }        from "app/arch/editor-image/types/arrows";
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { GetConfigProps } from "./types";


let lastUsedArrow: any = ContentTypes.WidgetType.ARROW_PLAIN;


export const getConfigArrow = (props: GetConfigProps) => {
  const { cmds, states } = props;
  const t = i18next.t;

  const ARROWS = [
    ContentTypes.WidgetType.ARROW_PLAIN,
    ContentTypes.WidgetType.ARROW_TEXT,
  ];

  const ARROWS_WITH_SHAFT = [
    ContentTypes.WidgetType.ARROW_PLAIN,
    ContentTypes.WidgetType.ARROW_TEXT,
  ];

  const disabled = (
    states.widgetProps === null ||
    ! ARROWS.includes(states.widgetProps?.type)
  );

  const disabled_headHeight = (
    states.widgetProps === null ||
    (ContentTools.isWidgetArrowText(states.widgetProps?.type) &&  Arrow.isPointerHeightless(states.widgetProps?.pointer))
  );

  const disabled_shaftWidth = (
    states.widgetProps === null ||
    (ContentTools.isWidgetArrowText(states.widgetProps?.type) &&  Arrow.isPointerShaftless(states.widgetProps?.pointer)) 
  );

  const disabled_sectionAppearance = (
    states.widgetProps === null ||
    ContentTools.isWidgetArrowTextLess(states.widgetProps?.type)
  );

  const getPointerIdx = (widgetProps: ContentTypes.WidgetProps) => {
    if ( ! widgetProps ) {
      // No widget selected
      return -1;
    }

    if (widgetProps.type !== ContentTypes.WidgetType.ARROW_TEXT) {
      return -1;
    }

    const widgetArrowTextProps = widgetProps as ContentTypes.WidgetArrowTextProps;

    const pointer = widgetArrowTextProps.pointer;
    if (pointer === undefined) return -1;
    
    return Arrow.getPointerIdx(pointer);
  }

  const getConfig = (hasShaft: boolean) => {
    const __arrow_headWidth = () => (
      {
        title: t('arrow head width'),
        type: PanelControlsItemSectionItemType.SLIDER,
        onChange: cmds.onArrowHeadWidthChanged,
        onChangeDone: cmds.onArrowHeadWidthChangedDone,
        min: 0,
        max: 350,
        value: states.arrowHeadWidth,
      }
    );

    const __arrow_headHeight = () => (
      {
        title: t('arrow head height'),
        disabled: disabled_headHeight,
        type: PanelControlsItemSectionItemType.SLIDER,
        onChange: cmds.onArrowHeadHeightChanged,
        onChangeDone: cmds.onArrowHeadHeightChangedDone,
        min: 1,
        max: 350,
        value: states.arrowHeadHeight,
      }
    );

    const __arrow_shaft = () => (
      {
        title: t('arrow shaft width'),
        disabled: disabled_shaftWidth,
        type: PanelControlsItemSectionItemType.SLIDER,
        onChange: cmds.onArrowShaftWidthChanged,
        onChangeDone: cmds.onArrowShaftWidthChangedDone,
        min: 0,
        max: 150,
        value: states.arrowShaftWidth,
      }      
    );

    const __arrow_type = () => (
      {
        title: t('arrow pointer'),
        type: PanelControlsItemSectionItemType.LIST,
        values: [
          t('arrow'),
          t('arrow pointer'),
          t('triangle'),
          // t('dots'),
        ],
        valueIdx: getPointerIdx(states.widgetProps), 
        onValueEnter: (idx: number) => {
          const pointer = Arrow.getPointer(idx);
          cmds.onArrowPointerEnter(pointer);
        },
        onValueLeave: (idx: number, prevIdx: number) => {
          const pointer     = Arrow.getPointer(idx);
          const prevPointer = Arrow.getPointer(prevIdx);
          cmds.onArrowPointerLeave(pointer, prevPointer);
        },
        onValueSelected: (idx: number) => {
          const pointer = Arrow.getPointer(idx);
          cmds.onArrowPointerChanged(pointer);
        }
      }
    );

    const __arrow_jointed = () => (
      {
        title: t('arrow joint type'),
        type: PanelControlsItemSectionItemType.BUTTON_GROUP,
        buttons: [
          {
            text: t('yes'),
            value: true,
            selected: states.widgetProps?.jointed ?? false,
          },
          {
            text: t('no'),
            value: false,
            selected: states.widgetProps?.jointed !== undefined ? ! states.widgetProps.jointed : false,
          },   
        ],
        onClick: cmds.onArrowJointChanged
      }      
    );

    const __arrow_identX = () => (
      {
        title: t('arrow head ident'),
        type: PanelControlsItemSectionItemType.SLIDER,
        onChange: cmds.onArrowHeadIdentXChanged,
        onChangeDone: cmds.onArrowHeadIdentXChangedDone,
        min: 0,
        max: 80,
        value: states.arrowHeadIdentX,
      }
    );

    const __arrow_identY = () => (
      {
        title: t('arrow head ident'),
        type: PanelControlsItemSectionItemType.SLIDER,
        onChange: cmds.onArrowHeadIdentYChanged,
        onChangeDone: cmds.onArrowHeadIdentYChangedDone,
        min: 0,
        max: 80,
        value: states.arrowHeadIdentY,
      }
    );

      
    const config: PanelControlsConfig = {
      disabled,
      items: [
        {
          title: t('arrow'),
          sections: [
            {
              subsections: [
                {
                  title: t('arrow head'),
                  items: [
                    __arrow_headWidth(),
                    __arrow_headHeight(), 
                    // __arrow_identX(),
                    // __arrow_identY(),
                  ]
                }
              ]
            },
            {
              disabled:disabled_shaftWidth,
              subsections: [
                {
                  title: t('arrow shaft'),
                  items: [
                    __arrow_shaft(),
                  ]
                }
              ]
            },
            {
              // title: t('arrow appearance'),
              skip: disabled_sectionAppearance,
              subsections: [
                {
                  title: t('arrow appearance'),
                  items: [
                    __arrow_type(),
                    __arrow_jointed(), 
                  ]
                }
              ]
            }
          ]
        },
      ]
    } // config

    return config;
  }
  
  const selectConfig = (widgetType: ContentTypes.WidgetType) => {
    const config = getConfig(ARROWS_WITH_SHAFT.includes(widgetType));
    return config;
  }

  let config: any = {};
  if (states.widgetProps === null || ! ARROWS.includes(states.widgetProps.type)) {
    config = selectConfig(lastUsedArrow);
  }
  else {
    config = selectConfig(states.widgetProps.type);
    lastUsedArrow = (states.widgetProps.type);
  }

  return config;
}

export default getConfigArrow;