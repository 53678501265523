import React from 'react';
import { RefObject } from 'react';

import * as Types from './types';
import { useResizeFitter } from './use-resize-fitter';


interface Props {
  deskAreaRef: RefObject<HTMLDivElement>; 
  onPositionChange: Types.SetPositionFn;
  children: React.ReactNode;
}


export const DeskResizeFitterComponent: React.FC<Props> = (props: Props) => {
  const {
    deskAreaRef,
    onPositionChange,
    children,
  } = props;

  useResizeFitter({
    deskAreaRef,
    onPositionChange
  });

  return children;
}
