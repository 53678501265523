import styled from "styled-components";
import ToolbarVertical from 'lego/styles/toolbar-vertical';


interface ToolbarProps {
  $left?: boolean;
  $right?: boolean;
}


export const Toolbar = styled(ToolbarVertical)<ToolbarProps>`
  ${props => props.$left  ? 'border-right-style: solid' : ''};
  ${props => props.$right ? 'border-left-style: solid' : ''};
`;
