import styled from "styled-components";
import ButtonGroupBase from "./button_group_base";


export const ButtonGroupLeft = styled(ButtonGroupBase)`
  border-right: none;
  border-radius: 
    ${props => props.theme.button.border.radius}
    0
    0
    ${props => props.theme.button.border.radius}
  ;
`;


export default ButtonGroupLeft;