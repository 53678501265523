import React from 'react';
import { useEffect } from 'react';
import { useState  } from 'react';
import { useRef    } from 'react';

import { WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import ContextMenuStyleComponent from '../context-menu-style';

import { PanelWidgetStyle } from './styles';
import { StyleItem }        from './styles';
import { StyleItemWrapper } from './styles';
import { StylesWrapper }    from './styles';
import { StyleContextMenuStyle  } from './styles';
import { StyleContextMenuStyleRel } from './styles';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr | null;
  stylesLib: WidgetsStylesTypes.StyleLib;
  stylesAddrs: WidgetsStylesTypes.StylesAddrs;
  deleteDisabled?: boolean;
}


export const StylesListComponent: React.FC<Props> = (props: Props) => {
  const { 
    widgetAddr,
    stylesLib, 
    stylesAddrs, 
    deleteDisabled
  } = props;

  const document = useDocState();

  const {
    setContent
  } = useEditorStatesSetters();

  const [
    styleSelected, 
    setStyleSelected
  ] = useState<WidgetsStylesTypes.StyleAddr | null>(null);

  const styleBackupRef = useRef<WidgetsStylesTypes.StyleAttrs | null>(null);

  useEffect(() => {
    if (widgetAddr === null) {
      return;
    }

    const style = document.content.cellImages_image_widget_getStyle(widgetAddr);
    styleBackupRef.current = style;
  }, [widgetAddr]);


  const getSortedStyleAddrs = (styles: WidgetsStylesTypes.Styles): WidgetsStylesTypes.StylesAddrs => (
    [...styles.addrs].sort((a, b) => 
      (styles.props[a]?.name || '').localeCompare(styles.props[b]?.name || '')
    )
  );

  const sortedAddr = getSortedStyleAddrs(document.widgetsStyles.state[stylesLib]);

  const __getStyleProps = (
    styleAddr: WidgetsStylesTypes.StyleAddr,
  ) => {
    const styleProps = document.widgetsStyles.getStyleProps(stylesLib, styleAddr);
    return styleProps;
  }

  const handlePointerEnterStyle = (styleAddr: WidgetsStylesTypes.StyleAddr) => {
    setStyleSelected(null);
    const styleProps = __getStyleProps(styleAddr);

    applyStyle(widgetAddr !, styleProps.style);
  }
  
  const handlePointerLeaveStyle = (styleAddr: WidgetsStylesTypes.StyleAddr) => {
    if (styleSelected !== null) {
      return;
    }
    applyStyle(widgetAddr !, styleBackupRef!.current!);
  }
  
  const handleStyleSelected = (styleAddr: WidgetsStylesTypes.StyleAddr) => {
    setStyleSelected(styleAddr);
  }

  const applyStyle = (
    widgetAddr: ContentTypes.WidgetAddr, 
    style: WidgetsStylesTypes.StyleAttrs
  ) => {
    document.content.cellImages_image_widget_setStyle(widgetAddr, style);
    setContent();
  }

  const handleContextMenuClose = () => {
    setStyleSelected(null);
    applyStyle(widgetAddr!, styleBackupRef.current!);
  }

  const handleStyleApplied = (styleAttrs: WidgetsStylesTypes.StyleAttrs) => {
    styleBackupRef.current = styleAttrs;
  }

  const renderStyle = (
    styleAddr: WidgetsStylesTypes.StyleAddr, 
  ) => {
    const styleProps = document.widgetsStyles.getStyleProps(stylesLib, styleAddr);
    return (
      <StyleItemWrapper
        key={styleAddr}
      >

      {
        styleSelected === styleAddr &&
        <StyleContextMenuStyleRel>
          <StyleContextMenuStyle>
            <ContextMenuStyleComponent 
              styleLib={stylesLib}
              styleAddr={styleAddr}
              widgetAddr={widgetAddr}
              deleteDisabled={deleteDisabled}
              onClose={handleContextMenuClose}
              onStyleApplied={handleStyleApplied}
            />
          </StyleContextMenuStyle>
        </StyleContextMenuStyleRel>
      }

        <StyleItem
          selected={styleSelected === styleAddr}
          onClick={() => handleStyleSelected(styleAddr)}
          onContextMenu={() => handleStyleSelected(styleAddr)}
          onPointerEnter={() => handlePointerEnterStyle(styleAddr)}
          onPointerLeave={() => handlePointerLeaveStyle(styleAddr)}
        >
          { styleProps.name }
        </StyleItem>

      </StyleItemWrapper>
    );
  }

  const renderStyles = () => {
    const StylesComponent = sortedAddr.map((styleAddr) => {
      return renderStyle(styleAddr);
    })

    return (
      <PanelWidgetStyle>
        <StylesWrapper>
          { StylesComponent }
        </StylesWrapper>
      </PanelWidgetStyle>
    );
  }
  
  return (
    <>
      { 
        stylesAddrs.length !== 0 &&
        renderStyles()
      }
    </>
  );
}
  
