import styled from "styled-components";
import TextInfoBase from "lego/styles/text-info";


export const MainWrapper = styled.div`
  height: 100%;

  display: flex;
  gap: 0;
  justify-content: center;
  align-items: center; 

  overflow: clip;

  padding: ${props => props.theme.defs.padding.normal};
  box-sizing: border-box;
  /* background-color: red; */
`;


export const TextInfo = styled(TextInfoBase)`
  user-select: none;
  /* background-color: blue; */
`;
