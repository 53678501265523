import { 
  useEffect, 
  useReducer 
} from 'react';
import { CornerDotComponent } from './corner-dot/CornerDot';
import { cornersStateReducer } from './state-routines';
import { CornerDotUpdate, CornerType } from './corner-dot/types';
import { CornersDotsUpdate } from './types';


interface Props {
  scale: number;

  onUpdate: (update: CornersDotsUpdate) => void;
  onUpdateDone: (update: CornersDotsUpdate) => void;

  x1: number;
  x2: number;
  y1: number;
  y2: number;

  locked: boolean;
}


export const CornersDotsComponent: React.FC<Props> = (props: Props) => {
  const { x1, x2, y1, y2, scale, locked } = props;
  
  const [cornersState, cornersStateDispatch] = useReducer(
    cornersStateReducer,
    {x1, x2, y1, y2}
  );

  useEffect(() => {
    cornersStateDispatch({
      type: 'reset',
      payload: {x1, x2, y1, y2}
    });

  }, [x1, x2, y1, y2]);

  
  const handleCornerDotUpdate = (update: CornerDotUpdate) => {
    cornersStateDispatch({
      type: 'update',
      payload: update
    });

    props.onUpdate(cornersState);
  }
  
  const handleCornerDotUpdateDone = (update: CornerDotUpdate) => {
    handleCornerDotUpdate(update);
    props.onUpdateDone(cornersState);
  }
  
  const handleOnPointerDown = (event: React.PointerEvent) => {
    event.stopPropagation();
  }

  return (
    <div
      onPointerDown={handleOnPointerDown}
    >
      <CornerDotComponent
        x={x1}
        y={y1}
        scale={scale}
        locked={locked}
        corner={CornerType.LEFT_TOP}
        onUpdate={handleCornerDotUpdate}
        onUpdateDone={handleCornerDotUpdateDone}
      />
      <CornerDotComponent
        x={x2}
        y={y1}
        scale={scale}
        locked={locked}
        corner={CornerType.RIGHT_TOP}
        onUpdate={handleCornerDotUpdate}
        onUpdateDone={handleCornerDotUpdateDone}
      />
      <CornerDotComponent
        x={x1}
        y={y2}
        scale={scale}
        locked={locked}
        corner={CornerType.LEFT_BOTTOM}
        onUpdate={handleCornerDotUpdate}
        onUpdateDone={handleCornerDotUpdateDone}
      />
      <CornerDotComponent
        x={x2}
        y={y2}
        scale={scale}
        locked={locked}
        corner={CornerType.RIGHT_BOTTOM}
        onUpdate={handleCornerDotUpdate}
        onUpdateDone={handleCornerDotUpdateDone}
      />
    </div>    
  );
};
