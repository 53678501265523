import styled from 'styled-components';


export const MainWrapper = styled.div`
  /* 
    This margin is very important, as
    it acts as centring 'force'
  */
  margin: auto;
  height: 0;
  /* background-color: rgba(255, 0, 0, 0.3);  */
`;

export const Scaler = styled.div`
`;
