import styled from "styled-components";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100px;

  min-width: 100px;
  min-height: 100px;
  padding: ${props => props.theme.defs.padding.normal};
`;

export default LogoWrapper;