import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { StripeContext } from 'lego-v2/stripe';
import { StripeTypes }   from 'lego-v2/stripe';

import { mutation } from 'app/arch/backend';
import { useMutation } from 'app/arch/backend/use-mutation';
import ProgressComponent from 'app/ui-v2/hls/progress';
import useProducts from 'app/ui-v2/app/__modules/products/hooks/use-products';

import StripeSubscriptionCheckoutComponent from '../stripe-subscription-checkout';
import { ProgressWrapper } from './styles';


interface Props {
  product: StripeTypes.Product;
  onCancel: () => void;
  onError?: (error: string) => void;
}

export const StripeSubscriptionComponent: React.FC<Props> = (props: Props) => {
  const { 
    product,
    onCancel,
    onError, 
  } = props;

  const products = useProducts();
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const { 
    mutation: createSubscriptionIntentMutation,
    data: createSubscriptionIntentData,
    error: createSubscriptionIntentError,
  } = useMutation(mutation.createSubscriptionIntent);

  useEffect(() => {
    const pricePlan = products.pricePlans.getPlan(product.pricePlanType);
    const priceLookupKey = pricePlan.price.lookup[product.billingPeriod];

    const variables = { priceLookupKey };
    createSubscriptionIntentMutation({ variables });
  }, []);

  useEffect(() => {
    if (createSubscriptionIntentData === undefined) return;
    const data = createSubscriptionIntentData.subscriptionIntentCreate;

    if ( ! data.ok ) {
      console.warn("problem");
      return;
    }

    setClientSecret(data.clientSecret);
  }, [createSubscriptionIntentData]);


  useEffect(() => {
    if (createSubscriptionIntentError === undefined) return;
    const error = createSubscriptionIntentError;

    console.warn(error);
    onError && onError(error.message);
  }, [createSubscriptionIntentError]);

  const handleCancel = () => {
    props.onCancel();
  }

  if (clientSecret === null) {
    return (
      <ProgressWrapper>
        <ProgressComponent />
      </ProgressWrapper>
    );
  }

  return (
    <StripeContext clientSecret={clientSecret} >
      <StripeSubscriptionCheckoutComponent 
        product={product}
        onCancel={handleCancel}
      />
    </StripeContext>
  );
}