import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }         from 'app/ui/contexts/document';
import useEditorStatesSetters  from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import HLS_NameEditComponent   from 'app/ui-v2/editor-instruction/views/shared/components/name-edit';

import InputStyledComponent from './input-styled';


interface Props {
  sectionAddr: TypesContent.SectionAddr;
  children: React.ReactNode;
}


export const SectionNameEditComponent: React.FC<Props> = (props: Props) => {
  const {
    sectionAddr,
    children,
  } = props;

  const document = useDocState();
  const { 
    setContent 
  } = useEditorStatesSetters();

  const sectionName = useRecoilValue(UIState_Content.sectionName(sectionAddr));

  const handleEditDone = (name: string) => {
    const update = { name };
    
    document.content.updateSection(
      sectionAddr, 
      update
    );
    document.saveUndo();

    setContent();
  }


  return (
    <HLS_NameEditComponent
      name={sectionName}
      onEditDone={handleEditDone}
      Input={InputStyledComponent}
    >
      { children }
    </HLS_NameEditComponent>
  );
}
  
