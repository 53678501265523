import React from 'react';
import { useRecoilValue } from 'recoil';

import useCellMarkersMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-markers/use-cell-markers-menu';
import useCellMarkerMenu  from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-markers/use-cell-marker-menu';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';

import { CellMenuProps } from '../types';
import TopToolbarComponent from 'app/ui-v2/editor-instruction/blocks/top-toolbar';


interface Props extends CellMenuProps {
}


export const CellMarkersMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr
  } = props;

  const getConfigMarkers = useCellMarkersMenu();
  const getConfigMarker  = useCellMarkerMenu();
  
  const selectedMarkerAddr = useRecoilValue(UIState_ContentSession.cellMarkers_markerSelected);

    
  const menuSections = (
    selectedMarkerAddr !== null ?
    [
      // ...getConfigMarkers(cellAddr).sections, 
      ...getConfigMarker(selectedMarkerAddr).sections
    ] 
    :
    getConfigMarkers(cellAddr).sections
  );
    
  return (
    <TopToolbarComponent
      sections={menuSections}
    />
  );
}
  
