import ShapeArrowBaseComponent from "../shape-arrow-base";
import { ShapeProps } from "../../types";
import { ShapeCalcArrow } from "../../shapes-calculators/shape-calc-arrow";


interface Props extends ShapeProps {
}


export const ShapeArrowComponent: React.FC<Props> = (props: Props) => {
  const {
    style,
    startPoint,
    endPoint
  } = props;

  const shapeCalc = new ShapeCalcArrow({
    style,
    startPoint,
    endPoint
  });
  const c = shapeCalc;

  const offset0 = 0;
  const offset1 = c.outlineWidthAngledVertical;
  const offset2 = c.outlineWidthAngledVertical + c.borderWidthAngledVertical;

  const points = [
    [
      `50% ${offset0}px`,

      `calc(50% + ${(c.arrowHeadWidth + c.arrowShaftWidth) / 2 + c.borderWidthAngledHorizontal + c.outlineWidthAngledHorizontal}px) ${offset0 + c.arrowHeadHeight + c.borderWidthAngledVertical + c.borderWidth + c.outlineWidthAngledVertical + c.outlineWidth}px`, 
      `calc(50% + ${(c.arrowShaftWidth) / 2 + c.borderWidth + c.outlineWidth}px) ${offset0 + c.arrowHeadHeight + c.borderWidthAngledVertical + c.borderWidth + c.outlineWidthAngledVertical + c.outlineWidth - c.arrowHeadIdentY}px`, 
      `calc(50% + ${(c.arrowShaftWidth) / 2 + c.borderWidth + c.outlineWidth}px) 100%`, 

      `calc(50% - ${(c.arrowShaftWidth) / 2 + c.borderWidth + c.outlineWidth}px) 100%`, 
      `calc(50% - ${(c.arrowShaftWidth) / 2 + c.borderWidth + c.outlineWidth}px) ${offset0 + c.arrowHeadHeight + c.borderWidthAngledVertical + c.borderWidth + c.outlineWidthAngledVertical + c.outlineWidth - c.arrowHeadIdentY}px`, 
      `calc(50% - ${(c.arrowHeadWidth + c.arrowShaftWidth) / 2 + c.borderWidthAngledHorizontal + c.outlineWidthAngledHorizontal}px) ${offset0 + c.arrowHeadHeight + c.borderWidthAngledVertical + c.borderWidth + c.outlineWidthAngledVertical + c.outlineWidth}px`,
    ],

    [
      `50% ${offset1}px`,

      `calc(50% + ${(c.arrowHeadWidth + c.arrowShaftWidth) / 2 + c.borderWidthAngledHorizontal}px) ${offset1 + c.arrowHeadHeight + c.borderWidthAngledVertical + c.borderWidth}px`, 
      `calc(50% + ${(c.arrowShaftWidth) / 2 + c.borderWidth}px) ${offset1 + c.arrowHeadHeight + c.borderWidthAngledVertical + c.borderWidth - c.arrowHeadIdentY}px`, 
      `calc(50% + ${(c.arrowShaftWidth) / 2 + c.borderWidth}px) calc(100% - ${c.outlineWidth}px)`, 

      `calc(50% - ${(c.arrowShaftWidth) / 2 + c.borderWidth}px) calc(100% - ${c.outlineWidth}px)`, 
      `calc(50% - ${(c.arrowShaftWidth) / 2 + c.borderWidth}px) ${offset1 + c.arrowHeadHeight + c.borderWidthAngledVertical + c.borderWidth - c.arrowHeadIdentY}px`,
      `calc(50% - ${(c.arrowHeadWidth + c.arrowShaftWidth) / 2 + c.borderWidthAngledHorizontal}px) ${offset1 + c.arrowHeadHeight + c.borderWidthAngledVertical + c.borderWidth}px`, 
    ],

    [
      `50% ${offset2}px`,

      `calc(50% + ${(c.arrowHeadWidth + c.arrowShaftWidth) / 2 }px) ${offset2 + c.arrowHeadHeight}px`, 
      `calc(50% + ${(c.arrowShaftWidth) / 2 - c.arrowHeadIdentX}px) ${offset2 + c.arrowHeadHeight - c.arrowHeadIdentY}px`, 
      `calc(50% + ${(c.arrowShaftWidth) / 2 }px) calc(100% - ${c.borderWidth + c.outlineWidth}px)`, 

      `calc(50% - ${(c.arrowShaftWidth) / 2 }px) calc(100% - ${c.borderWidth + c.outlineWidth}px)`,
      `calc(50% - ${(c.arrowShaftWidth) / 2 - c.arrowHeadIdentX}px) ${offset2 + c.arrowHeadHeight - c.arrowHeadIdentY}px`, 
      `calc(50% - ${(c.arrowHeadWidth + c.arrowShaftWidth) / 2 }px) ${offset2 + c.arrowHeadHeight}px`, 
    ]
  ]
  
  return (
    <ShapeArrowBaseComponent
      {...props}
      points={points}
    />
  );
}
