import { memo } from 'react';
import { WidgetPropsBase } from '../types';
import WidgetBoxedBaseComponent from '../__widget-base/widget-boxed-base';


interface Props extends WidgetPropsBase {
}


export const WidgetRectangelComponent: React.FC<Props> = memo((props: Props) => {

  return (
    <WidgetBoxedBaseComponent
      {...props}
    />
  );
});
