import styled from 'styled-components';
import ButtonText from "lego/styles/button_text";


export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.large};
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(ButtonText)`
`;

