import styled from "styled-components";


export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.defs.padding.large};
  gap: ${props => props.theme.defs.gap.xxsmall};
`;

export const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 3px;
`;
