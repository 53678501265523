import React from 'react';
import SliderRawComponent from 'lego/components/slider/slider-raw';

import { settings } from 'app/configs';
import useDocumentScaleWatch from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-watch';
import useDocumentScaleSet from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-set';
import { MainWrapper } from './styles';


interface Props {
}


export const DeskScaleSliderComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const SCALE_MIN = settings.document.scale.min;
  const SCALE_MAX = settings.document.scale.max;
  const WIDTH = 100;

  const step = (SCALE_MAX - SCALE_MIN) / (WIDTH - 1);

  const scale = useDocumentScaleWatch();
  const setScale = useDocumentScaleSet();

  const handleScaleChange = (scaleNew: number) => {
    setScale(scaleNew);
  }

  return (
    <MainWrapper $width={WIDTH}>
      <SliderRawComponent
        value={scale}
        min={SCALE_MIN}
        max={SCALE_MAX}
        step={step}

        onChange={handleScaleChange}
        onChangeDone={handleScaleChange}
      />
    </MainWrapper>
  );
}

