import { useSetRecoilState } from "recoil";

import { UIInfoActions } from "../components/actions/states";
import useActionsState   from "../components/actions/hooks/use-actions-state";

import { UIInfoToasts } from "../components/toasts/states";
import useToastsState   from "../components/toasts/hooks/use-toasts-state";


const useInfoStatesSetters = () => {
  const actions = useActionsState();
  const toasts = useToastsState();

  const __setActions = useSetRecoilState(UIInfoActions.state);
  const __setToasts  = useSetRecoilState(UIInfoToasts.state);

  const setActions = () => __setActions(actions.state);
  const setToasts  = () => __setToasts(toasts.state);

  return {
    setActions,
    setToasts,
  }
}


export default useInfoStatesSetters;