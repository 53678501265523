import { useSetRecoilState } from "recoil";
import useControlsDraggers from "./use-controls-draggers";
import { UIControlsDraggers } from "../states";


const useControlsStateSetters = () => {
  const controlsDraggers = useControlsDraggers();

  const __setControlsDraggers = useSetRecoilState(UIControlsDraggers.state);
  const setControlsDraggers = () => __setControlsDraggers(controlsDraggers.state);

  const setAll = () => {
    setControlsDraggers();
  }

  return {
    setAll,
    setControlsDraggers,
  }
}


export default useControlsStateSetters;