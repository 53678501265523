import { useRecoilValue } from 'recoil';
import { PrintoutViewTypes } from 'app/arch/editor-instruction/document/states/printout/printout-view';
import { UIPrintoutView } from '../states';
import useImagesLoaded from './use-images-loaded';


const usePrintoutViewLoaded = () => {
  const viewsLoaded = useRecoilValue(UIPrintoutView.viewsLoaded);
  
  const allViewsLoaded = (
       viewsLoaded[PrintoutViewTypes.ViewType.CONTENT] 
    && viewsLoaded[PrintoutViewTypes.ViewType.CHANGELOG] 
  );

  const imagesLoaded = useImagesLoaded(allViewsLoaded);
  
  const allLoaded = (
    viewsLoaded[PrintoutViewTypes.ViewType.CONTENT]
 && viewsLoaded[PrintoutViewTypes.ViewType.CHANGELOG]
 && imagesLoaded
);

  return {
    viewsLoaded,
    imagesLoaded,
    allLoaded,
  }
}


export default usePrintoutViewLoaded;