import { uuid } from 'tools/uuid';
import * as Types from './types';


export const getMarkerKey = (
  markerAddr: Types.MarkerAddr
): string => (
  `${markerAddr.markerId}`
);

export const createMarkerAddr = (): Types.MarkerAddr => {
  const markerId = uuid();
  const markerAddr = { markerId };
  return markerAddr;
}

export const compareMarkerAddr = (
  srcMarkerAddr: Types.MarkerAddr,
  dstMarkerAddr: Types.MarkerAddr,
): boolean => (
  srcMarkerAddr.markerId === dstMarkerAddr.markerId
);

export const getMarkerGroup = (
  markerType: Types.MarkerType
): Types.MarkerGroup => {

  const resolver: {[key in Types.MarkerType]: Types.MarkerGroup} = {
    [Types.MarkerType.ICON            ]: Types.MarkerGroup.ICON,
    [Types.MarkerType.LABEL           ]: Types.MarkerGroup.LABEL,
    [Types.MarkerType.SIGN_INFO       ]: Types.MarkerGroup.SIGN,
    [Types.MarkerType.SIGN_MANDATORY  ]: Types.MarkerGroup.SIGN,
    [Types.MarkerType.SIGN_PROHIBITION]: Types.MarkerGroup.SIGN,
    [Types.MarkerType.SIGN_WARNING    ]: Types.MarkerGroup.SIGN,
  };

  const markerGroup = resolver[markerType];
  return markerGroup
}

export const hasIcon = (markerType: Types.MarkerType): boolean => {
  const markerGroup = getMarkerGroup(markerType);
  const hasIcon = [
    Types.MarkerGroup.ICON,
    Types.MarkerGroup.SIGN,
  ].includes(markerGroup);

  return hasIcon;
}
