import React, { ErrorInfo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { mutation } from 'app/arch/backend';
import { useMutation } from 'app/arch/backend/use-mutation';
import EditorInstruction from 'app/arch/editor-instruction';

import FallbackComponent from './fallback';


interface Props {
  editorInstruction: EditorInstruction;
  children: any;
}

export const ErrorFallbackComponent: React.FC<Props> = (props: Props) => {
  const { 
    mutation: createCrash,
  } = useMutation(mutation.createCrash);

  const logError = (error: Error, info: ErrorInfo) => {
    createCrash({
      variables: { 
        error: `${error}`,
        stackTrace: `${info.componentStack}`,
      }
    })
  };
  
  return (
    <ErrorBoundary
      onError={logError}
      fallback={
        <FallbackComponent
          editorInstruction={props.editorInstruction}
        >
          { props.children }
        </FallbackComponent>
      }
    >
      { props.children }
    </ErrorBoundary>
  );
}
