import React from 'react';

import FormPanelComponent from 'app-views/components/form-panel';
import { Panel } from "./styles";
import ViewTitledComponent from '../view-titled';


interface Props {
  title: string;
  formButtonText?: string;
  submitDisabled?: boolean;
  onSubmit?: () => void;
  children: React.ReactNode;
}

export const ViewFormComponent: React.FC<Props> = (props: Props) => {
  const { 
    title, 
    formButtonText,
    submitDisabled,
    onSubmit,
    children, 
  } = props;

  return (
    <ViewTitledComponent title={title} >
      <Panel>
        <FormPanelComponent
          buttonText={formButtonText}
          onSubmit={onSubmit}
          submitDisabled={submitDisabled}
        >
        { children }
        </FormPanelComponent>
      </Panel>
      
    </ViewTitledComponent> 
  );
}
