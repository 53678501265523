import React from 'react';
import { useRecoilValue } from 'recoil';

import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';
import { useDocState }  from 'app/ui/contexts/document';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { UIState_ContentSignals } from 'app/ui/states/editor-instruction';
import useColumnsVisible from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-columns-visible';

import HeaderRowComponent    from './header-row';
import SectionComponent      from './section';
import SectionAdderComponent from './section-adder';

import { Grid } from './styles';


interface Props {
  content: SlicerTypes.PageItem_Content;
}


export const ContentGridComponent: React.FC<Props> = (props: Props) => {
  const { 
    content 
  } = props;

  const document = useDocState();

  // Track ColumnsVisible
  const columnsVisible = useColumnsVisible();

  // Track ColumnsWidth
  useRecoilValue(UIState_ContentSignals.columnsWidths);

  // Track ColumnsAddrs (create column, delete column, reorder)
  useRecoilValue(UIState_Content.columnsAddrs);

  const colsWidths = columnsVisible.map((columnAddr) => {
    const columnWidth = document.content.getColumnWidth(columnAddr);
    return `${columnWidth}px`;
  });

  const columnsTemplate = colsWidths.join(' ');
  

  const ItemsComponents: {[key in SlicerTypes.ContentItemType]: React.ElementType} = {
    [SlicerTypes.ContentItemType.HEADER_ROW   ]: HeaderRowComponent,
    [SlicerTypes.ContentItemType.SECTION      ]: SectionComponent,
    [SlicerTypes.ContentItemType.SECTION_ADDER]: SectionAdderComponent,
  };

  const renderItems = () => {
    const Items = content.items.map((item, idx) => {
      const Comp = ItemsComponents[item.type];

      return (
        <Comp 
          key={idx}
          item={item} 
        />
      );
    });
    return Items;
  }

  return (
    <Grid columnsTemplate={columnsTemplate} >
      { renderItems() }
    </Grid>
  );
}
  
