import React, { useState } from 'react';
import { PanelDraggableConfig } from './config';
import PanelItemComponent from './panel-item';
import { ItemWrapper    } from './styles';
import { MainWrapper    } from './styles';
import { Separator      } from './styles';
import { DummySeparator } from './styles';


interface Props {
  config: PanelDraggableConfig;
}


export const PanelDraggableComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;
  const [draggingIdx, setDraggingIdx] = useState<null | number>(null);
  const [dragOverIdx, setDragOverIdx] = useState<null | number>(null);

  const getIdx = (idx: number) => {
    return (
      config.reversOrder ? 
      config.items.length - idx - 1 :
      idx
    );
  }

  const handleSeparatorDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  }

  const handleDrop = (event: React.DragEvent, idx: number) => {
    const srcIdxTmp = +event.dataTransfer.getData("idx");
    const srcIdx = getIdx(srcIdxTmp);
    const dstIdx = getIdx(idx);
    config.onItemMove(srcIdx, dstIdx);

    setDraggingIdx(null);
    setDragOverIdx(null);     
  }


  const renderItems = () => {
    const items = (
      config.reversOrder ? 
      [...config.items].reverse() :
      config.items
    );

    const ItemsComps = items.map((item, idx) => {
      let SeparatorUp   = Separator;
      let SeparatorDown = DummySeparator;

      if ( 
        draggingIdx !== null &&
        dragOverIdx === idx && 
        dragOverIdx !== draggingIdx
      ) {

        if (draggingIdx < dragOverIdx) {
          SeparatorUp   = DummySeparator;
          SeparatorDown = Separator;
        }
        else {
          SeparatorUp   = Separator;
          SeparatorDown = DummySeparator;
        }
      }
      else {
        SeparatorUp   = DummySeparator;
        SeparatorDown = DummySeparator;
      }

      return (
        <ItemWrapper 
          key={`${item.key}`}
        >
          <SeparatorUp
            draggable={true}
            onDragOver={handleSeparatorDragOver}
            onDrop={(event: React.DragEvent) => { 
              handleDrop(event, idx); 
            }}
          />

          <PanelItemComponent 
            idx={idx}
            config={item}
            onDragStart={() => {
              setDraggingIdx(idx); 
              config.onDragStart?.(getIdx(idx));
            }}
            onDragOver={ () => {
              setDragOverIdx(idx); 
            }}
            onDragEnter={()=> { 
            }}
            onDragEnd={ () => {
              setDraggingIdx(null);
              setDragOverIdx(null);     
            }}
            onDrop={(event: React.DragEvent) => { 
              handleDrop(event, idx); 
            }}
          />
          
          <SeparatorDown
            draggable={true}
            onDragOver={handleSeparatorDragOver}
            onDrop={(event: React.DragEvent) => { 
              handleDrop(event, idx); 
            }}
          />
        </ItemWrapper>
      )
    });

    return ItemsComps;
  }

  return (
    <MainWrapper>
      { renderItems() }
    </MainWrapper>
  );
}
  
