import styled from "styled-components";
import ContentItemBorder from "app/ui-v2/editor-instruction/views/shared/styles/content-item-border";


export const MainWrapper = styled(ContentItemBorder)`
  user-select: none;
  position: relative;
`;


export const RowsResizersDetacher = styled.div`
  position: absolute;
  height: 100%;
`;