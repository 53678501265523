import React from 'react';

import { WidgetPropsBase }     from 'app/ui-v2/editor-image/hls/widgets/widgets/types';
import WidgetTextComponentBase from 'app/ui-v2/editor-image/hls/widgets/widgets/__widget-base/widget-text';
import { RegistrationPlugin }  from 'app/ui-v2/editor-image/hls/widgets/editor-text/RegistrationPlugin';
import { ContentSavePlugin }   from 'app/ui-v2/editor-image/hls/widgets/editor-text/ContentSavePlugin';


interface Props extends WidgetPropsBase {
}


export const WidgetTextComponent: React.FC<Props> = (props: Props) => {

  return (
    <WidgetTextComponentBase 
      {...props}
      EditorTextPlugins={[
        <RegistrationPlugin widgetAddr={props.widgetAddr} />,
        <ContentSavePlugin  widgetAddr={props.widgetAddr} />
      ]}
    />
  );
}
