import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import { EditorImageSessionTypes } from 'app/arch/editor-instruction/document/states/sessions/editor-image-session';
import { useTranslations } from "app/ui/hooks/app/use-translation";
import Icon from 'app/ui/icons/icons';

import * as Tools from '../tools';
import useState from "./__use-state";
import useFunctions from "./__use-functions";


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();
  const states = useState();

  const __getDataTest = (itemName: string) => {
    return Tools.getDataTest('image-view', itemName);
  }


  const getAutoFitMode = () => ({
    title: t('view image, auto fit, mode'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        tooltip: t('view image, auto fit, mode, manual'),
        Icon: Icon.Automation.Manual,
        value: EditorImageSessionTypes.ViewDefinerAutomationMode.MANUAL,
        selected: states.autoFit.autoMode === EditorImageSessionTypes.ViewDefinerAutomationMode.MANUAL,
      },
      {
        Icon: Icon.Automation.Automatic,
        tooltip: t('view image, auto fit, mode, automatic'),
        value: EditorImageSessionTypes.ViewDefinerAutomationMode.AUTOMATIC,
        selected: states.autoFit.autoMode === EditorImageSessionTypes.ViewDefinerAutomationMode.AUTOMATIC,
      },
    ],
    onClick: fns.setAutoFitMode,
    dataTest: __getDataTest('view-fit-auto-mode'),
  });

  const getAutoFitType = () => ({
    skip: ! states.isAutoFitAutomatic,
    title: t('view image, auto fit, type'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        text: t('view image, auto fit, type, to image'),
        value: EditorImageSessionTypes.ViewDefinerFitType.FIT_TO_IMAGE,
        selected: states.autoFit.fitType === EditorImageSessionTypes.ViewDefinerFitType.FIT_TO_IMAGE,
      },
      {
        text: t('view image, auto fit, type, to all'),
        value: EditorImageSessionTypes.ViewDefinerFitType.FIT_TO_ALL,
        selected: states.autoFit.fitType === EditorImageSessionTypes.ViewDefinerFitType.FIT_TO_ALL,
      },
    ],
    onClick: fns.setAutoFitType,
    dataTest: __getDataTest('view-fit-auto-mode'),
  });

  const getOverlayerViewType = () => ({
    title: t('view image, view type'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.ImageView.Overlayer.None,
        value: EditorImageSessionTypes.ViewDefinerShowType.HIDDEN,
        tooltip: t("view image, overlayer, type, hidden"),
        selected: states.overlayerView === EditorImageSessionTypes.ViewDefinerShowType.HIDDEN,
      },      {
        Icon: Icon.ImageView.Overlayer.Lines,
        value: EditorImageSessionTypes.ViewDefinerShowType.LINES_ONLY,
        tooltip: t("view image, overlayer, type, lines only"),
        selected: states.overlayerView === EditorImageSessionTypes.ViewDefinerShowType.LINES_ONLY,
      },
      {
        Icon: Icon.ImageView.Overlayer.Shadow,
        value: EditorImageSessionTypes.ViewDefinerShowType.LINES_AND_OVERLAYERS,
        tooltip: t("view image, overlayer, type, lines and overlayer"),
        selected: states.overlayerView === EditorImageSessionTypes.ViewDefinerShowType.LINES_AND_OVERLAYERS,
      },
    ],
    onClick: fns.setOverlayerViewType,
    dataTest: __getDataTest('view-type'),
  });

  const getOverlayerLocked = () => ({
    title: t('view image, lock'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Lock.Unlock,
        tooltip: t("unlocked"),
        value: false,
        selected: ! states.overlayerSizeLocked,
      },
      {
        Icon: Icon.Lock.Close,
        tooltip: t("locked"),
        value: true,
        selected: states.overlayerSizeLocked,
      },
    ],
    onClick: fns.setOverlayerSizeLocked,
    dataTest: __getDataTest('view-locked'),
  });

  const getOverlayerSizeAutoFit = () => ({
    title: t('view image, auto fit, type'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        text: t('view image, auto fit, type, to image'),
        value: EditorImageSessionTypes.ViewDefinerFitType.FIT_TO_IMAGE,
      },
      {
        text: t('view image, auto fit, type, to all'),
        value: EditorImageSessionTypes.ViewDefinerFitType.FIT_TO_ALL,
      },
    ],
    onClick: fns.fitImageView,
    dataTest: __getDataTest('view-locked'),
  });


  return {
    getAutoFitMode,
    getAutoFitType,
    getOverlayerViewType,
    getOverlayerLocked,
    getOverlayerSizeAutoFit,
  }
}


export default useItems;