import KeyboardAdjuster from "./keyboard-adjuster";
import Size from "./size";


class Screen {
  private _size: Size;
  private _keyboardAdjuster: KeyboardAdjuster;

  constructor() {
    this._size = new Size();
    this._keyboardAdjuster = new KeyboardAdjuster();
  }

  get keyboardAdjuster() { return this._keyboardAdjuster; }
  get size() { return this._size; }
}


export default Screen;