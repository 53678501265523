import * as TypesContent from '../../../../persistent/content/types';
import * as Types from './types';
import * as Defaults from './defaults';

import Logger from 'libs/debug';


const log = Logger.getContentElementsSizes();


export class Sections {
  private _sections: Types.Sections;

  constructor() {
    this._sections = Defaults.getSections();
  }

  getSectionNameHeight(sectionAddr: TypesContent.SectionAddr) {
    const sectionName = this._getSectionName(sectionAddr);
    return sectionName.height;
  } 

  setSectionNameHeight(
    sectionAddr: TypesContent.SectionAddr, 
    height: number
  ) {
    const sectionName = this._getSectionName(sectionAddr);
    return sectionName.height = height;
  } 

  getSectionRowHeight(rowAddr: TypesContent.RowAddr) {
    const row = this._getSectionRow(rowAddr);
    return row.height;
  } 

  setSectionRowHeight(
    rowAddr: TypesContent.RowAddr, 
    height: number
  ) {
    const row = this._getSectionRow(rowAddr);
    row.height = height;
  } 

  private _getSection(sectionAddr: TypesContent.SectionAddr) {
    const sectionId = sectionAddr.sectionId;
    
    let section = this._sections[sectionId];
    if (section === undefined) {
      log.debug(`Section not found, id: ${sectionId}`);
      log.debug('Create new section');

      section = Defaults.getSection();
      this._sections[sectionId] = section;
    }

    return section;
  }

  private _getSectionName(sectionAddr: TypesContent.SectionAddr) {
    const section = this._getSection(sectionAddr);
    return section.sectionName;
  }

  private _getSectionRows(sectionAddr: TypesContent.SectionAddr) {
    const section = this._getSection(sectionAddr);
    return section.sectionRows;
  }

  private _getSectionRow(rowAddr: TypesContent.RowAddr) {
    const rowId = rowAddr.rowId;
    const rows = this._getSectionRows(rowAddr);
    
    let row = rows[rowId];
    if (row === undefined) {
      log.debug(`Row not found, id: ${rowId}`);
      log.debug('Create new row');

      row = Defaults.getSectionRow();
      rows[rowId] = row;
    }

    return row;
  }

}
