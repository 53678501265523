import React from 'react';
import ReactDOM from 'react-dom';
import environment from 'app/environment';
import DebugModalComponent from 'app/ui-v2/app/__modules/debug/components/debug-modal';


interface Props {
}


export const AppDebugComponent: React.FC<Props> = (props: Props) => {
  if ( ! environment.dev ) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <DebugModalComponent />,
      document.body
    )
  );
}
  
