import styled from "styled-components";
import Panel  from "./panel";


export const PanelSecondary = styled(Panel)`
  padding:    ${props => props.theme.defs.padding.normal};
  background: ${props => props.theme.defs.colors.background.secondary};
`;


export default PanelSecondary;