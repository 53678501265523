import styled from "styled-components";


export const RevisionLabel = styled.div`
  white-space: nowrap;

  font-size:   ${props => props.theme.defs.font.size.xxxlarge};
  font-weight: ${props => props.theme.defs.font.weight.medium};
`;

