import { useRecoilValue } from 'recoil';
import jtl from 'tools/jtl';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';

import { MainWrapper }    from "./styles";
import { LineVertical }   from "./styles";
import { LineHorizontal } from "./styles";


interface Props {
  widgetAddr: ContentTypes.WidgetAddr;
}


export const MarginsComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr
  } = props;

  const edited   = useRecoilValue(UIState_EditorImageSession.isWidgetEdited(widgetAddr));
  const selected = useRecoilValue(UIState_EditorImageSession.isWidgetSelected(widgetAddr));
  const widgetStyle_ = useRecoilValue(UIState_Content.cellImages_image_widgetStyle(widgetAddr)) || {};
  const widgetStyle = widgetStyle_ as any; // FIXME
  
  const marginTop    = jtl.css.valueToNumber(widgetStyle.textMarginTop) - 1;
  const marginLeft   = jtl.css.valueToNumber(widgetStyle.textMarginLeft) - 1;
  const marginBottom = jtl.css.valueToNumber(widgetStyle.textMarginBottom) - 1;
  const marginRight  = jtl.css.valueToNumber(widgetStyle.textMarginRight) - 1;

  if (! (selected || edited)) {
    return null;
  }

  return (
    <MainWrapper>
      <LineVertical
        style={{
          left: `${marginLeft}px`
        }}
      />
      <LineVertical
        style={{
          right: `${marginRight}px`
        }}
      />
      <LineHorizontal
        style={{
          top: `${marginTop}px`
        }}
      />
      <LineHorizontal
        style={{
          bottom: `${marginBottom}px`
        }}
      />
    </MainWrapper>
  );
}
  
