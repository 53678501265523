import { Position      } from "app/arch/types";
import { Size          } from "app/arch/types";
import { DeltaMove } from "app/ui/hooks/use-dragging-delta";
import { FrameTargetUpdate } from "./types";
import { FrameCtrlType     } from "./types";


export class FrameHandlerBase {
  private _scale: number;
  private _startPosition: Position;
  private _startSize: Size;
  private _minSize: Size;
  private _startCustomData: any;

  constructor(
    scale: number,
    startPosition: Position,
    startSize: Size,
    minSize: Size,
    startCustomData?: any
  ) 
  {
    this._scale = scale;
    this._startPosition   = startPosition;
    this._startSize       = startSize;
    this._minSize         = minSize;
    this._startCustomData = startCustomData;
  }

  protected get scale(): number { return this._scale; }
  protected get startPosition(): Position { return this._startPosition; }
  protected get startSize(): Size { return this._startSize; }
  protected get minSize(): Size {  return this._minSize; }
  protected get startCustomData(): any { return this._startCustomData; }
  
  process(frameCtrlType: FrameCtrlType, delta: DeltaMove): FrameTargetUpdate {
    const deltaScaled = {
      x: delta.x / this.scale,
      y: delta.y / this.scale,
    }
    switch (frameCtrlType) {
      case FrameCtrlType.TOP_LEFT   : { return this.processTopLeft(deltaScaled);   }
      case FrameCtrlType.TOP_MIDDLE : { return this.processTopMiddle(deltaScaled); }
      case FrameCtrlType.TOP_RIGHT  : { return this.processTopRight(deltaScaled);  }

      case FrameCtrlType.MIDDLE_LEFT   : { return this.processLeftMiddle(deltaScaled);  }
      case FrameCtrlType.MIDDLE_RIGHT  : { return this.processRightMiddle(deltaScaled); }

      case FrameCtrlType.BOTTOM_LEFT   : { return this.processBottomLeft(deltaScaled);   }
      case FrameCtrlType.BOTTOM_MIDDLE : { return this.processBottomMiddle(deltaScaled); }
      case FrameCtrlType.BOTTOM_RIGHT  : { return this.processBottomRight(deltaScaled);  }

      default : {
        const error = `Invalid control type: ${frameCtrlType}`;
        throw new Error(error);
      }
    }
  }

  //--------------------------------------------------
  // Top
  protected processTopLeft(delta: DeltaMove): FrameTargetUpdate {
    return {
      control: FrameCtrlType.DUMMY
    }
  };  

  protected processTopMiddle(delta: DeltaMove): FrameTargetUpdate {
    return {
      control: FrameCtrlType.DUMMY
    }
  };  

  protected processTopRight(delta: DeltaMove): FrameTargetUpdate {
    return {
      control: FrameCtrlType.DUMMY
    }
  };  


  //--------------------------------------------------
  // Middle
  protected processLeftMiddle(delta: DeltaMove): FrameTargetUpdate {
    return {
      control: FrameCtrlType.DUMMY
    }
  };  

  protected processRightMiddle(delta: DeltaMove): FrameTargetUpdate {
    return {
      control: FrameCtrlType.DUMMY
    }
  };  


  //--------------------------------------------------
  // Bottom
  protected processBottomLeft(delta: DeltaMove): FrameTargetUpdate {
    return {
      control: FrameCtrlType.DUMMY
    }
  };  

  protected processBottomMiddle(delta: DeltaMove): FrameTargetUpdate {
    return {
      control: FrameCtrlType.DUMMY
    }
  };  

  protected processBottomRight(delta: DeltaMove): FrameTargetUpdate {
    return {
      control: FrameCtrlType.DUMMY
    }
  };

};