import styled from 'styled-components';


export const WidgetView = styled.div`
  height: 100%;
  overflow: clip;
  box-sizing: border-box;
  
  border-style: solid;
  border-width: 0px;
  border-color: transparent;

  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
`;

