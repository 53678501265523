import produce from 'immer';
import * as State from './state';
import * as Types from './types';


export class ViewsCommon {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * 
   * Setters
   * 
   */


  /**
   * Table
   */
  updateTableHeaderCSS(update: React.CSSProperties) {
    this._state = produce(this._state, draft => {
      const tableHeader = draft.table.header;
      tableHeader.css = {
        ...tableHeader.css,
        ...update
      }
    });
  }

  /**
   * Page
   */
  updatePageLayout(update: Types.PageLayoutUpdate) {
    this._state = produce(this._state, draft => {
      const page = draft.page;
      page.layout = {
        ...page.layout,
        ...update
      }
    });
  }

  updatePageMargins(update: Types.PageMarginsUpdate) {
    this._state = produce(this._state, draft => {
      const page = draft.page;
      page.margins = {
        ...page.margins,
        ...update
      }
    });
  }


  /**
   * 
   * Getters
   * 
   */


  /**
   * Table
   */
  getTableHeader() {
    return State.getTableHeader(this._state);
  }

  getTableHeaderCSS(): React.CSSProperties {
    return State.getTableHeaderCSS(this._state);
  }

  /**
   * Page
   */
  getPageLayout() {
    return State.getPageLayout(this._state);
  }

  getPageMargins() {
    return State.getPageMargins(this._state);
  }
}
