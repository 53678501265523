import { ThemeDefProps } from "../types";


export const themeDefs_buttonColor = (props: ThemeDefProps) => {
  const { colors, accentColor, border } = props;

  const buttonColor = {
    background: colors.background.primary,

    border: {
      color: colors.border.primary,
      radius: border.radius.normal
    }
  };
    
  return buttonColor;
}

