export type State = {
  id: number | null,
  repoId: number | null,
  revision: number,
};


export const getId = (state: State) => state.id;
export const getRepoId = (state: State) => state.repoId;
export const getRevision = (state: State) => state.revision;


export const createInitialState = () => {
  return {
    id: null,
    repoId: null,
    revision: 0,
  }
}
