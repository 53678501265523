import styled from 'styled-components';
import ButtonIcon from 'lego/styles/button_icon';

import { IoMdEye }    from "react-icons/io";
import { LuGrid }     from "react-icons/lu";

import getReactIconProps from 'lego/css-props/react-icon';


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.xxxsmall};
`;

export const Button = styled(ButtonIcon)`
`;

export const IconColumnsPrint = styled(IoMdEye)`
  ${ props => getReactIconProps(props) };
`;
