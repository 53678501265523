import React from 'react';
import RowsProbeComponent from './rows-probe';
import RowsGridComponent from './rows-grid';


interface Props {
}


export const RowsComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;


  return (
    <RowsProbeComponent>
      <RowsGridComponent />
    </RowsProbeComponent>
  );
}

