import React from 'react';
import { MainWrapper } from './styles';
import { Title }       from './styles';


interface Props {
  title: string;
  children: React.ReactNode;
}


export const TitledElementComponent: React.FC<Props> = (props: Props) => {
  const {
    title,
    children
  } = props;
  
  return (
    <MainWrapper>
      <Title>
      { title }
      </Title>
      { children }
    </MainWrapper>
  );
}
  
