import styled from "styled-components";
import Button from "./button";


const ButtonToolbar = styled(Button)`
  display: flex;
  align-items: center;  
  justify-content: center;
  
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;

  padding: 0px;
  
  border-radius: 0;
  border-style: none;
  border-color: ${props => props.theme.button.border.color.primary};

  outline-width: 0px;
  outline-style: none;

  background: ${props => 
    props.$selected ? 
    props.theme.button.background.selected : 
    props.theme.defs.colors.background.primary
  };
`;


export default ButtonToolbar;