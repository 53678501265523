import React from 'react';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';

import HeaderCellNameEditComponent from './components/header-cell-name-edit';
import HeaderCellViewComponent from './components/header-cell-view';
import HeaderCellCSSComponent from './components/header-cell-css';
import HeaderCellContextMenuComponent from './components/header-cell-context-menu';
import HeaderCellSelectComponent from './components/header-cell-select';
import HeaderCellBorderComponent from './components/header-cell-border';
import HeaderCellDraggerComponent from './components/header-cell-dragger';
import HeaderCellDropComponent from './components/header-cell-drop';


interface Props {
  pageRowAddr: HeaderRowsTypes.PageRowAddr;
}


export const HeaderCellComponent: React.FC<Props> = (props: Props) => {
  const {
    pageRowAddr
  } = props;


  return (
    <HeaderCellBorderComponent>
      <HeaderCellNameEditComponent pageRowAddr={pageRowAddr}>
        <HeaderCellSelectComponent pageRowAddr={pageRowAddr} >
          <HeaderCellContextMenuComponent pageRowAddr={pageRowAddr} >
            <HeaderCellDraggerComponent pageRowAddr={pageRowAddr} >
              <HeaderCellDropComponent pageRowAddr={pageRowAddr} >
                <HeaderCellCSSComponent pageRowAddr={pageRowAddr}>
                  <HeaderCellViewComponent pageRowAddr={pageRowAddr} />
                </HeaderCellCSSComponent>
              </HeaderCellDropComponent>
            </HeaderCellDraggerComponent>
          </HeaderCellContextMenuComponent>
        </HeaderCellSelectComponent>
      </HeaderCellNameEditComponent> 
    </HeaderCellBorderComponent>
  );
}

