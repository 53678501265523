import React from 'react';

import IconLib from 'app/ui/icons/icons';
import { IconContext } from 'react-icons';

import { Panel } from './styles';
import { Title } from './styles';
import { Content } from './styles';
import { Header } from './styles';
import { IconWrapper } from './styles';


interface Props {
  title?: string;
  children: React.ReactNode;
  Icon: React.ElementType;
}


export const InfoPanelComponent: React.FC<Props> = (props: Props) => {
  const {
    Icon,
    children,
  } = props;

  const iconContext = { 
    size: `40px`, 
    style: {
      color: 'white'
    }
  }

  const title = (
    props.title === undefined || props.title?.length === 0 ?
    <>&nbsp;</> :
    props.title
  );

  return (
    <Panel>
      <Header>
        <Title>
          { title }
        </Title>

        <IconWrapper>
          <IconContext.Provider 
            value={iconContext} 
          >
            <Icon />
          </IconContext.Provider>
        </IconWrapper>
      </Header>
      
      <Content>
      { children }
      </Content>
    </Panel>
  );
}

