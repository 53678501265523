import React from 'react';
import Context from './context';
import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';


interface Props {
  planType: PricePlansTypes.PlanType;
  children: React.ReactNode;
}


export const PricePlanContextComponent: React.FC<Props> = (props: Props) => {
  const {
    planType,
    children,
  } = props;

  return (
    <Context.Provider value={{planType}}>
      { children }
    </Context.Provider>
  );
}
