import styled from 'styled-components';
import Panel from 'lego/styles/panel';


export const MainWrapper = styled.div<{$visible: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  cursor: wait;

  opacity: ${props => props.$visible ? 1 : 0};
  pointer-events: ${props => props.$visible ? 'all' : 'none'};  ;

  transition: 
    opacity 
    ${props => props.theme.defs.transition.time.normal} 
    ease-in-out
  ;

  background-color: rgba(0, 0, 0, 0.8);
`;


export const TextWrapper = styled(Panel)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xlarge};
  padding: ${props => props.theme.defs.padding.xxlarge};
`;


export const Text = styled.div`
  color: ${props => props.theme.defs.colors.color.primary};
  font-size: ${props => props.theme.defs.font.size.xlarge};
  font-weight: ${props => props.theme.defs.font.weight.small};
  opacity: ${props => props.theme.defs.opacity.text};
`;