import jtl from 'tools/jtl';
import * as Types    from './types';
import * as Tools    from './tools';
import * as Defaults from './defaults';


//----------------------------
// State
//

export type State = {
  content: Types.Content;
};


//----------------------------
// Getters
//

export const getContent = (state: State) => state.content;

/**
 * 
 * Columns
 * 
 */

export const __getColumns = (state: State): Types.Columns => { 
  return state.content.columns;
}

export const getColumnsAddrs = (state: State): Types.ColumnsAddrs => { 
  const columns = __getColumns(state);
  return columns.addrs;
}

export const getColumnsProps = (state: State): Types.ColumnsProps => { 
  const columns = __getColumns(state);
  return columns.props;
}


/**
 * 
 * Column
 * 
 */

export const getColumnProps = (
  state: State, columnAddr: 
  Types.ColumnAddr
): Types.ColumnProps => { 
  const columnsProps = getColumnsProps(state);
  const columnKey = Tools.getColumnKey(columnAddr);
  const columnProps = columnsProps[columnKey];

  if (columnsProps === undefined) {
    const msg = `Column props not found`;
    throw new Error(msg);
  }

  return columnProps;
}

export const getColumnWidth = (
  state: State, 
  columnAddr: Types.ColumnAddr
): number => { 
  const columnProps = getColumnProps(state, columnAddr);
  return columnProps.width;
}

export const getColumnName = (
  state: State, 
  columnAddr: Types.ColumnAddr
): string => { 
  const columnProps = getColumnProps(state, columnAddr);
  return columnProps.name;
}


export const isColumnVisible = (
  state: State, 
  columnAddr: Types.ColumnAddr
): boolean => { 
  const columnProps = getColumnProps(state, columnAddr);
  return columnProps.visible;
}

export const getColumnType = (
  state: State, 
  columnAddr: Types.ColumnAddr
): Types.ColumnType => { 
  const columnProps = getColumnProps(state, columnAddr);
  return columnProps.type;
}

export const getColumnIdx = (state: State, columnAddr: Types.ColumnAddr): number => {
  const columnsAddrs = getColumnsAddrs(state);
  
  const idx = columnsAddrs.findIndex((columnAddr_) => Tools.compareColumnAddr(columnAddr_, columnAddr));
  if ( idx === -1 ) {
    const msg = 'Column not found';
    throw new Error(msg);
  }

  return idx;
}

export const isColumnLast = (
  state: State, 
  columnAddr: Types.ColumnAddr
): boolean => {
  const colIdx = getColumnIdx(state, columnAddr);
  const columnsAddrs = getColumnsAddrs(state);
  const isLast = (colIdx === (columnsAddrs.length - 1));
  return isLast;
}


/**
 * 
 * Sections
 * 
 */

const __getSections = (state: State): Types.Sections => { 
  const content = getContent(state);
  return content.sections;
}

export const getSectionsAddrs = (state: State): Types.SectionsAddrs => { 
  const sections = __getSections(state);
  return sections.addrs;
}

export const getSectionsProps = (state: State): Types.SectionsProps => { 
  const sections = __getSections(state);
  return sections.props;
}

export const getSectionsRows = (state: State): Types.SectionsRows => { 
  const sections = __getSections(state);
  return sections.rows;
}

export const getSectionsCells = (state: State): Types.SectionsCells => { 
  const sections = __getSections(state);
  return sections.cells;
}

export const getSectionsConfig = (state: State): Types.SectionsConfig => { 
  const sections = __getSections(state);
  return sections.config;
}



/**
 * 
 * Section
 * 
 */

export const getSectionProps = (
  state: State, 
  sectionAddr: Types.SectionAddr
): Types.SectionProps => { 
  const sectionsProps = getSectionsProps(state);
  const sectionKey = Tools.getSectionKey(sectionAddr);
  const sectionProps = sectionsProps[sectionKey];

  if ( sectionProps === undefined ) {
    const msg = (
      `Section props not found. \n` +
      Tools.debugSectionAddr(sectionAddr)
    );
    throw new Error(msg);
  }

  return sectionProps;
}

export const getSectionRows = (
  state: State, 
  sectionAddr: Types.SectionAddr
): Types.Rows => { 
  const sectionsRows = getSectionsRows(state);
  const sectionKey   = Tools.getSectionKey(sectionAddr);
  const sectionRows  = sectionsRows[sectionKey];

  if ( sectionRows === undefined ) {
    const msg = 'SectionRows not found';
    throw new Error(msg);
  }

  return sectionRows;
}

export const getSectionCells = (
  state: State, 
  sectionAddr: Types.SectionAddr
): Types.Cells => { 
  const sectionsCells = getSectionsCells(state);
  const sectionKey    = Tools.getSectionKey(sectionAddr);
  const sectionCells  = sectionsCells[sectionKey];

  if ( sectionCells === undefined ) {
    const msg = 'Section cells not found';
    throw new Error(msg);
  }

  return sectionCells;
}

export const getSectionIdx = (
  state: State, 
  sectionAddr: Types.SectionAddr
): number => {
  const sectionsAddrs = getSectionsAddrs(state);
  
  const idx = sectionsAddrs.findIndex((sectionAddr_) => Tools.compareSectionAddr(sectionAddr_, sectionAddr));
  if ( idx === -1 ) {
    const msg = 'Section not found';
    throw new Error(msg);
  }

  return idx;
}

export const isSectionLast = (
  state: State, 
  sectionAddr: Types.SectionAddr
): boolean => {
  const sectionsAddrs = getSectionsAddrs(state);
  const sectionIdx = getSectionIdx(state, sectionAddr);
  const isLast = (sectionIdx === sectionsAddrs.length - 1);
  return isLast;
}

export const isSectionPresent = (
  state: State, 
  sectionAddr: Types.SectionAddr
): boolean => {
  const sectionsAddrs = getSectionsAddrs(state);
  const present = sectionsAddrs.some((sectionAddr_) => Tools.compareSectionAddr(sectionAddr_, sectionAddr));
  return present;
}



/**
 * 
 * Rows
 * 
 */

const __getRows_ = (
  state: State, 
  sectionAddr: Types.SectionAddr
): Types.Rows => {
  const sectionRows = getSectionRows(state, sectionAddr);
  return sectionRows;
}

export const getRowsAddrs = (
  state: State, 
  sectionAddr: Types.SectionAddr
): Types.RowsAddrs => {
  const rows = __getRows_(state, sectionAddr);
  return rows.addrs;
}

export const getRowsProps = (
  state: State, 
  sectionAddr: Types.SectionAddr
): Types.RowsProps => {
  const rows = __getRows_(state, sectionAddr);
  return rows.props;
}



/**
 * 
 * Row
 * 
 */

export const getRowIdxSection = (
  state: State, 
  rowAddr: Types.RowAddr
): number => {
  const rowsAddrs = getRowsAddrs(state, rowAddr);

  const idx  = rowsAddrs.findIndex((rowAddr_) => Tools.compareRowAddr(rowAddr, rowAddr_));
  if (idx === - 1) {
    const msg = (
      `Row not found \n` +
      `  ${JSON.stringify(rowAddr)}`
    );
    throw new Error(msg);
  }

  return idx;
}

export const getRowIdxGlobal = (
  state: State, 
  rowAddr: Types.RowAddr
): number => {
  const sectionsAddrs = getSectionsAddrs(state);
  const sectionIdx = getSectionIdx(state, rowAddr); 
  const sectionsBefore = sectionsAddrs.slice(0, sectionIdx);

  const sectionsRowsCount: number = sectionsBefore.reduce((accumulator, sectionAddr) => {
    const sectionRows = getRowsAddrs(state, sectionAddr);
    return accumulator + sectionRows.length;
  }, 0);

  const sectionRowIdx = getRowIdxSection(state, rowAddr);
  const globalRowIdx = sectionRowIdx + sectionsRowsCount;

  return globalRowIdx;
}

export const isRowLast = (
  state: State, 
  rowAddr: Types.RowAddr
): boolean => {
  const rowIdx = getRowIdxSection(state, rowAddr);
  const rowsAddrs = getRowsAddrs(state, rowAddr);
  const isLast = (rowIdx === rowsAddrs.length - 1);
  return isLast;
}

export const isRowPresent = (
  state: State, 
  rowAddr: Types.RowAddr
): boolean => {
  const rowsAddrs = getRowsAddrs(state, rowAddr);
  const present = rowsAddrs.some((rowAddr_) => Tools.compareRowAddr(rowAddr_, rowAddr));
  return present;
}

export const isRowAdder = (
  state: State, 
  rowAddr: Types.RowAddr
): boolean => {
  const rowLast = isRowLast(state, rowAddr);
  const sectionLast = isSectionLast(state, rowAddr);
  const rowAdder = (rowLast && sectionLast);
  return rowAdder;
}



/**
 * Cells
 */
export const getCells = (
  state: State, 
  sectionAddr: Types.SectionAddr
): Types.Cells => {
  const sectionCells = getSectionCells(state, sectionAddr);
  return sectionCells;
}

export const getCellsOfType = (
  state: State, 
  columnType: Types.ColumnType,
): Types.CellAddr[] => {
  const cellsAddrs: Types.CellAddr[] = [];

  const columnsAddrs = getColumnsAddrs(state);
  const columnsTypedAddrs = columnsAddrs.filter((columnAddr) => {
    return getColumnType(state, columnAddr) === columnType;
  });

  const sectionAddrs = getSectionsAddrs(state);
  sectionAddrs.forEach((sectionAddr) => {
    const rowsAddrs = getRowsAddrs(state, sectionAddr);
    rowsAddrs.forEach((rowAddr) => {
      columnsTypedAddrs.forEach((columnAddr) => {

        const cellAddr: Types.CellAddr = {
          columnId: columnAddr.columnId,
          sectionId: sectionAddr.sectionId,
          rowId: rowAddr.rowId
        }

        cellsAddrs.push(cellAddr);
      });
    });
  });

  return cellsAddrs;
}


/**
 * Cell
 */
export const getCell = (
  state: State, 
  cellAddr: Types.CellAddr
): Types.CellTypes => {
  const cells   = getCells(state, cellAddr);
  const cellKey = Tools.getCellKey(cellAddr);
  
  const cell = cells[cellKey];
  if (cell === undefined) {
    const msg = (
      `Cell not found, cell addr: \n`+
      `  columnId: ${cellAddr.columnId}\n` +
      `  sectionId: ${cellAddr.sectionId}\n` +
      `  rowId: ${cellAddr.rowId}\n`
    );

    throw new Error(msg);
  }

  return cell;
}


/**
 * Cells Images
 */

export const cellsImages_getImagesAddrs = (
  state: State, 
): Types.ImageAddr[] => {
  const imagesAddrs: Types.ImageAddr[] = [];
  const cellsAddrs = getCellsOfType(state, Types.ColumnType.IMAGES);

  cellsAddrs.forEach((cellAddr) => {
    const cellImgsAddrs = cellImages_getImagesAddrs(state, cellAddr);
    imagesAddrs.push(...cellImgsAddrs);
  });

  return imagesAddrs;
}


/**
 * Cell Images
 */
export const cellImages_getImages = (
  state: State, 
  cellAddr: Types.CellAddr
): Types.Images => {
  const cell = getCell(state, cellAddr);
  const cellImages = cell as Types.ImagesCell;
  const images = cellImages.images;
  return images;
}

export const cellImages_getImagesAddrs = (
  state: State, 
  cellAddr: Types.CellAddr
): Types.ImagesAddrs => {
  const images_ = cellImages_getImages(
    state,
    cellAddr,
  );
  return images_.addrs;
}

export const cellImages_getImagesProps = (
  state: State, 
  cellAddr: Types.CellAddr
): Types.ImagesProps => {
  const images_ = cellImages_getImages(
    state,
    cellAddr,
  );
  return images_.props;
}

export const cellImages_getImageProps = (
  state: State, 
  imageAddr: Types.ImageAddr
): Types.ImageProps => {
  const imagesProps = cellImages_getImagesProps(
    state,
    imageAddr,
  );

  const imageKey = Tools.getImageKey(imageAddr);
  const imageProps = imagesProps[imageKey];

  if (imageProps === undefined) {
    const msg = `Image props not found`;
    throw new Error(msg);
  }

  return imageProps;
}

export const cellImages_getImageIdx = (
  state: State, 
  imageAddr: Types.ImageAddr
): number => {
  const imagesAddrs = cellImages_getImagesAddrs(state, imageAddr);
  const idx = imagesAddrs.findIndex((imageAddr_) => Tools.compareImageAddr(imageAddr_, imageAddr));

  if (idx === -1) {
    const msg = "Image not found";
    throw new Error(msg);
  }
  
  return idx;
}

export const cellImages_image_getWidgets = (
  state: State,
  imageAddr: Types.ImageAddr
): Types.Widgets => {
  const imageProps = cellImages_getImageProps(state, imageAddr);
  return imageProps.widgets;
}

export const cellImages_image_getWidgetsAddrs = (
  state: State, 
  imageAddr: Types.ImageAddr
): Types.WidgetsAddrs => {
  const widgets = cellImages_image_getWidgets(
    state,
    imageAddr,
  );
  return widgets.addrs;
}

export const cellImages_image_getWidgetsProps = (
  state: State, 
  imageAddr: Types.ImageAddr
): Types.WidgetsProps => {
  const widgets = cellImages_image_getWidgets(
    state,
    imageAddr,
  );
  return widgets.props;
}

export const cellImages_image_getWidgetProps = (
  state: State, 
  widgetAddr: Types.WidgetAddr
): Types.WidgetProps => {
  const widgetsProps = cellImages_image_getWidgetsProps(
    state,
    widgetAddr,
  );

  const widgetKey = Tools.getWidgetKey(widgetAddr);
  const widgetProps = widgetsProps[widgetKey];
  
  if (widgetProps === undefined) {
    const msg = (
      `Widget props not found. \n` 
      + `Widget key: ${widgetKey}`
    );
    throw new Error(msg);
  }

  return widgetProps;
}

export const cellImages_image_getWidgetIdx = (
  state: State, 
  widgetAddr: Types.WidgetAddr
): number => {
  const widgetsAddrs = cellImages_image_getWidgetsAddrs(state, widgetAddr);
  const widgetIdx    = widgetsAddrs.findIndex((widgetAddr_) => Tools.compareWidgetAddr(widgetAddr, widgetAddr_));

  if (widgetIdx === -1) {
    const msg = `Widget not found`;
    throw new Error(msg);
  }

  return widgetIdx;
}

export const cellImages_image_widget_getStyle = (
  state: State, 
  widgetAddr: Types.WidgetAddr
) => {
  const widgetProps = cellImages_image_getWidgetProps(state, widgetAddr);
  return widgetProps.style;
}


/**
 * Cells Markers
 */

export const cellsMarkers_getMarkersAddrs = (
  state: State, 
): Types.MarkerAddr[] => {
  const markersAddrs: Types.MarkerAddr[] = [];
  const cellsAddrs = getCellsOfType(state, Types.ColumnType.MARKERS);

  cellsAddrs.forEach((cellAddr) => {
    const cellMarkersAddrs = cellMarkers_getMarkersAddrs_(state, cellAddr);
    markersAddrs.push(...cellMarkersAddrs);
  });

  return markersAddrs;
}


/**
 * Cell Markers
 */
export const cellMarkers_getMarkers_ = (
  state: State, 
  cellAddr: Types.CellAddr
): Types.Markers => {
  const cell = getCell(state, cellAddr);
  const cellMarkers = cell as Types.MarkersCell;
  const markers = cellMarkers.markers;
  return markers;
}

export const cellMarkers_getMarkersAddrs_ = (
  state: State, 
  cellAddr: Types.CellAddr
): Types.MarkersAddrs => {
  const markers = cellMarkers_getMarkers_(state, cellAddr);
  return markers.addrs;
}

export const cellMarkers_getMarkersProps_ = (
  state: State, 
  cellAddr: Types.CellAddr
): Types.MarkersProps => {
  const markers = cellMarkers_getMarkers_(state, cellAddr);
  return markers.props;
}

export const cellMarkers_getMarkerProps_ = (
  state: State, 
  markerAddr: Types.MarkerAddr
): Types.MarkerProps => {
  const markersProps = cellMarkers_getMarkersProps_(state, markerAddr);
  const markerKey = Tools.getMarkerKey(markerAddr);

  const markerProps = markersProps[markerKey];
  if (markerProps === undefined) {
    const markerHash = jtl.object.hash(markerAddr);
    const msg = `Marker props not found, marker: ${markerHash}`;
    throw new Error(msg);
  }

  return markerProps;
}

export const cellMarkers_getMarkerIdx_ = (
  state: State, 
  markerAddr: Types.MarkerAddr
): number => {
  const markersAddrs = cellMarkers_getMarkersAddrs_(state, markerAddr);
  const idx = markersAddrs.findIndex((markerAddrs_) => Tools.compareMarkerAddr(markerAddr, markerAddrs_));

  if (idx === -1) {
    const msg = `Marker not found`;
    throw new Error(msg);
  }
  
  return idx;
}



//----------------------------
// Create initial state
//

export const createInitialState = (): State => {
  const state: State = {
    content: Defaults.getContent(),
  }

  return state;
}
