import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { useDocState } from 'app/ui/contexts/document';
import { UIState_HeaderRowsSession } from 'app/ui/states/editor-instruction';

import CellTextMenuComponent from './cell-text-menu';


interface Props {
}


export const CustomRowsCellMenuComponent: React.FC<Props> = (props: Props) => {
  const pageCellSelected = useRecoilValue(UIState_HeaderRowsSession.pageCellSelected);
  
  return (
    <>
      { 
        pageCellSelected &&
        <__Component pageCellAddr={pageCellSelected} />
      }
    </>
  );
}


interface CellMenuRenderProps {
  pageCellAddr: HeaderRowsTypes.PageCellAddr;
}

const __Component: React.FC<CellMenuRenderProps> = (props: CellMenuRenderProps) => {
  const {
    pageCellAddr,
  } = props;

  const document = useDocState();
  const rowProps = document.headerRows.getRowProps(pageCellAddr);

  const CellsMenus: {[key in HeaderRowsTypes.RowType]: React.FC<any>} = {
    [HeaderRowsTypes.RowType.TEXT]: CellTextMenuComponent,
  };

  const CellMenu = CellsMenus[rowProps.type];

  return (
    <CellMenu pageCellAddr={pageCellAddr} />
  );
}
