import React from 'react';
import { useRecoilValue } from 'recoil';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import ToolbarWidgetEditedComponent   from './toolbar-widget-edited';
import ToolbarWidgetSelectedComponent from './toolbar-widget-selected';
import { MainWrapper } from './styles';


interface Props {
}


export const ToolbarTopComponent: React.FC<Props> = (props: Props) => {
  const widgetEdited = useRecoilValue(UIState_EditorImageSession.widgetEdited);
  const widgetSelected = useRecoilValue(UIState_EditorImageSession.widgetSelected);

  const showToolbarWidgetEdited = (
    widgetEdited !== null
  );

  return (
    <MainWrapper>
      { 
        ! showToolbarWidgetEdited && 
        <ToolbarWidgetSelectedComponent widgetSelected={widgetSelected}/> 
      }

      { 
        showToolbarWidgetEdited && 
        <ToolbarWidgetEditedComponent widgetEdited={widgetEdited} /> 
      }
    </MainWrapper>
  );
}
