import { ThemeConstsProps } from "../types";


export const themeConsts_gap = (props: ThemeConstsProps) => {
  const gap = {
    none:     "0px",
    xxxsmall: "1px",
    xxsmall:  "2px",
    xsmall:   "4px",
    small:    "6px",
    normal:   "8px",
    large:    "14px",
    xlarge:   "20px",
    xxlarge:   "40px",
  };

  return gap;
}
