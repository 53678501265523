import { ThemeConstsProps } from "../types";


export const themeConsts_font = (props: ThemeConstsProps) => {
  const { darkMode } = props;

  const font = {
    size: {
      // xsmall:  "8px", 
      small:     "10px", 
      normal:    "12px",
      large:     "14px",
      xlarge:    "18px",
      xxlarge:   "20px",
      xxxlarge:  "26px",
      xxxxlarge: "46px",
    },

    weight: {
      // xsmall: 200, 
      xsmall: 300, 
      small:  400, 
      normal: 500,
      medium: 600,
      large:  700,
      // xlarge: 800,
    },

    family: 
      [
        // ** Code
        // "source-code-pro", 
        // "Menlo", 
        // "Monaco", 
        // "Consolas", 
        // 'Courier New',
        // "monospace",

        // ** Gnome
        // 'Ubuntu', 
        // 'Cantarell', 
        // 'Noto Sans', 
        // 'sans-serif',

        // ** Windows
        // 'Segoe UI', 
        // 'Tahoma', 
        // 'Arial', 
        // 'sans-serif'

        // ** Mac OSX
        'Helvetica Neue', 
        'Lucida Grande', 
        'Arial', 
        'sans-serif'

      ]
  };

  return font;
}
