import React from 'react';
import { Position } from 'app/arch/types';


export interface HookConfigProps {
  Element: any,
  ElementProps?: any,
}

interface SetupProps {
  setConfig:  (config: HookConfigProps | null) => void;
  setPosition: (position: Position) => void;
}

let setupProps: SetupProps | null = null;

export const useSetupContextElementHook = () => {
  const hook = (props: SetupProps) => {
    setupProps = props;
  }

  return hook;
}


interface HookProps extends HookConfigProps {
  event: React.PointerEvent | React.MouseEvent
}


export const useContextElement = () => {
  const showElement =(props: HookProps) => {
    if ( ! setupProps ) {
      console.warn('Context element hook not ready. Did you forget to include ContextElementComponent in DOM?');
      return;
    }

    const event = props.event;
    event.preventDefault();
    event.stopPropagation();

    const mouseX = event.pageX;
    const mouseY = event.pageY;

    const position = [mouseX, mouseY] as Position;

    setupProps.setPosition(position);
    setupProps.setConfig(props);
  }

  const hideElement =() => {
    setupProps?.setConfig(null);
  }

  return {
    showElement,
    hideElement,
  };
}


export default useContextElement;
