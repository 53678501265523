import React from 'react';
import { useRef } from "react";
import useResizeObserver from 'app/ui/hooks/use-resize-observer';
import { Virtual } from './styles';


interface Props {
  debug?: boolean;
  onBBoxChange: (bbox: DOMRect) => void;
  Virtual?: React.ElementType;
  children: React.ReactElement; 
}


export const VirtualProbeComponent: React.FC<Props> = (props: Props) => {
  const { 
    onBBoxChange,
    debug,
    children, 
  } = props;

  const divRef = useRef<HTMLDivElement>(null);
  const Wrapper = props.Virtual || Virtual;

  useResizeObserver({
    elementRef: divRef,
    onResize: (entries => {
      onBBoxChange(entries[0].contentRect);
    })
  }, [divRef.current]);


  return (
    <Wrapper 
      ref={divRef}
      $debug={debug}
    >
      { children }
    </Wrapper>
  );
}
