import React         from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';

import { settings } from 'app/configs';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";


const SAVE_UNDO_DEBOUNCE_DELAY = settings.editorText.updateDebounceDelay;


interface Props {
  editorTextState: string | null;
  onSaveEditorTextState: (editorState: string) => void;
  onInitEditorTextState: (editorState: string) => void;
};


export const ContentSaveBasePlugin: React.FC<Props> = (props: Props) => {
  const {
    editorTextState,
    onSaveEditorTextState,
    onInitEditorTextState,
  } = props;


  const [lexical] = useLexicalComposerContext();

  // I'm not sure why I need to store editorTextState in ref
  // but if I don't do that, then `editorTextState` has
  // value `null` in `isUpdateImportant`. That is probably 
  // due to way clouser works. 
  const editorTextStateRef = useRef(editorTextState);
  const saveStateOnUpdate = useRef(true);

  const debounceSaveFnRef = useRef<any | null>(null);
  const editorUpdateState = useRef<any | null>(null);

  // There is a bug/race in this approach. You need to be faster then 
  // `SAVE_UNDO_DEBOUNCE_DELAY` ms to exploit it. 
  // Anyway, if something will update content while, this
  // debounce is waiting to fire, history state will be saved in different
  // order to its occurance. 
  // 
  // If you start writing to once cell fast, and then you 
  // switch to another cell and start writing fast, 
  // selected cell will be switiching back and forth 
  // TODO FIXME ^^^^^^^^^^^^^
  // Another approach is acctually implement debounce and autosave on 
  // docState changes - but it has its own limitations/problems

  const isUpdateImportant = (currentState: any) => {
    // For some changes made to state, it is 
    // not needed to saved them in undo stack.
    // For example range selection is a 
    // change to editor state, but it is not 
    // important to take this change into account. 
    // import { editorInitConfig } from '../../../editor-text/config';
    // toJSON() method - creates json representation
    // of nodes only (selections etc. are skipped);
    if ( editorTextStateRef.current === null ||
         editorTextStateRef.current === undefined
    ) {
      // If state is undefined or null, it means that
      // state has not been set yet. Therefore
      // treat any update as important. 
      return true;
    }

    const prevStateTxt = editorTextStateRef.current;
    const currentStateTxt = JSON.stringify(currentState);
    return (prevStateTxt !== currentStateTxt);
  }

  const debouncedSaveEditorStateCore = () => {
    if ( editorUpdateState.current === null ) {
      return;
    }

    if ( ! isUpdateImportant(editorUpdateState.current)) {
      return;
    }

    const editorStateTxt = JSON.stringify(editorUpdateState.current);
    onSaveEditorTextState(editorStateTxt);
    editorUpdateState.current = null;
  }

  const debouncedSaveEditorState = (editorState: any) => {
    if (debounceSaveFnRef.current !== null) {
      clearTimeout(debounceSaveFnRef.current);
    }

    editorUpdateState.current = editorState;
    debounceSaveFnRef.current = setTimeout(() => {
      debouncedSaveEditorStateCore();
    }, SAVE_UNDO_DEBOUNCE_DELAY);
  }

  const onEditorUpdate = ({editorState}: any) => {
    if (saveStateOnUpdate.current) {
      debouncedSaveEditorState(editorState);
    }
  }

  useEffect(() => {
    const unregisterUpdateListener = lexical.registerUpdateListener(onEditorUpdate);
    return () => {
      unregisterUpdateListener();
    };
  }, []);

  useEffect(() => {
    editorTextStateRef.current = editorTextState;

    if (editorTextState !== null && editorTextState !== undefined) {

      const editorState = lexical.getEditorState();
      const current = JSON.stringify(editorState.toJSON());
      const incoming = editorTextState;
      
      if (current !== incoming) {
        saveStateOnUpdate.current = false;
        const stateDeserial = lexical.parseEditorState(editorTextState);
        lexical.setEditorState(stateDeserial);
        saveStateOnUpdate.current = true;
      }
    }
  }, [lexical, editorTextState]);

  useEffect(() => {
    // This is for new text editor which
    // has been created, to initialize its
    // recoil state with empty editor state
    if (editorTextStateRef.current === null) {
      // console.log("Initialized recoil state with empty editor on creation");
      
      onInitEditorTextState(JSON.stringify(lexical.getEditorState()));
    };
  }, []);

  return null;
}
