import { ThemeDefProps } from "../types";


export const themeDefs_panel = (props: ThemeDefProps) => {
  const { colors, accentColor, border, padding, boxShadow } = props;

  const panel = {
    background: colors.background.primary,
    padding: padding.large,

    border: {
      style: 'solid',
      color:  colors.border.primary,
      radius: border.radius.normal,
      width:  border.width.normal,
    },

    color:  {
      primary:  colors.border.primary,
      selected: accentColor.light,
      active:   accentColor.light,
    },

    boxShadow: boxShadow.primary,

  };
    
  return panel;
}

