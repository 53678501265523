import { useRef } from 'react';

import AnimatedPanelComponent from 'lego/components/animated-panel';
import ButtonComponent from 'lego/components/buttons/button';

import IconsLib from 'app/ui/icons/icons';
import { useTranslations    } from 'app/ui/hooks/app/use-translation';
import { useThemeDescriptor } from 'app/ui/components/app/theme/use-theme-descriptor';

import UserPreferredLanguageComponent  from './user-preferred-language';
import ThemeTypeSetterComponent        from './theme-type-setter';
import ThemeVariationSetterComponent   from './theme-variation-setter';
import ThemeAccentColorSetterComponent from './theme-accent-color-setter';

import { AnimPanel    } from './styles';
import { Button       } from './styles';
import { Panel        } from './styles';
import { MenuWrapper  } from './styles';
import { PanelSection } from './styles';


interface Props {
}


export const UserSettingsMenuComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const theme = useThemeDescriptor();
  const IconSettings = IconsLib.Settings;

  const setPanelEnabledRef    = useRef<any>(null);
  const togglePanelVisibleRef = useRef<any>(null);
  const closeLanguageRef      = useRef<any>(null);
  
  const toggleMenuVisiblity = (e: any) => {
    e.stopPropagation();
    togglePanelVisibleRef.current();
  }

  const enableMenu = (e: React.PointerEvent) => {
    e.stopPropagation();
    setPanelEnabledRef.current(true);
  }

  const stopPropagation = (e: any) => { 
    e.stopPropagation(); 
  }
  
  const handlePanelClose = () => {
    closeLanguageRef.current();
  }


  return (
    <MenuWrapper>
      <ButtonComponent
        tooltip={t('user settings')}
        Button={
          <Button
            onPointerDown={enableMenu}
            onClick={toggleMenuVisiblity}
          >
            <IconSettings />
          </Button>
        }
      />

      <AnimatedPanelComponent
        AnimPanel={AnimPanel}

        onClose={handlePanelClose}
        setEnabledRef={setPanelEnabledRef}
        toggleVisibleRef={togglePanelVisibleRef}
      >
        <Panel
          onPointerDown={stopPropagation}
          onClick={stopPropagation}
        >
          <PanelSection>
            <UserPreferredLanguageComponent  closeRef={closeLanguageRef} />
            {/* <ThemeTypeSetterComponent        theme={theme} /> */}
            <ThemeVariationSetterComponent   theme={theme} />
            <ThemeAccentColorSetterComponent theme={theme} />
          </PanelSection>
        </Panel>

      </AnimatedPanelComponent>
    </MenuWrapper>
  );
}
