import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { ContentTools }from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes }from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentColumnsSelectedState as State } from 'app/arch/editor-instruction/document/states/sessions/content-columns-selected';


/**
 * Atom
 */

export const state = atom<State.State>({
  key: "ei_docContent_contentColumnsSelected",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const isSelected = selectorFamily({
  key: "ei_docContent_contentColumnsSelected__isSelected",
  get: (columnAddr: ContentTypes.ColumnAddr) => ({ get }) => {
    const stateObj = get(state);
    const columnKey = ContentTools.getColumnKey(columnAddr);
    const selected = State.isSelected(stateObj, columnKey);
    return selected;
  }
});

export const isDragOver = selectorFamily({
  key: "ei_docContent_contentColumnsSelected__isDragOver",
  get: (columnAddr: ContentTypes.ColumnAddr) => ({ get }) => {
    const stateObj = get(state);
    const columnKey = ContentTools.getColumnKey(columnAddr);
    const selected = State.isDragOver(stateObj, columnKey);
    return selected;
  }
});

export const areColumnsSelected = selector({
  key: "ei_docContent_contentColumnsSelected__areColumnsSelected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selected = State.getSelectedOrdered(stateObj);

    return (selected.length > 0);
  }
});
  
