import styled from "styled-components";


export const ItemSelectedBorder = styled.div`
  overflow: clip;
  box-sizing: border-box;

  border-style:  ${props => props.theme.itemSelected.border.style};
  border-width:  ${props => props.theme.itemSelected.border.width};
  border-radius: ${props => props.theme.itemSelected.border.radius};
  border-color:  ${props => props.theme.itemSelected.border.color};
`;


export default ItemSelectedBorder;