
import React from 'react';

import { useDocState }        from 'app/ui/contexts/document';
import useColumnSelectedSet   from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/column/use-column-selected-set';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import HLS_HeaderCellNameEditComponent from 'app/ui-v2/editor-instruction/views/shared/components/header-cell/header-cell-name-edit';

import { HeaderCellProps } from '../types';


interface Props extends HeaderCellProps {
}


export const HeaderCellNameEditComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;

  const document = useDocState();
  const columnProps = document.releaselogs.getColumnProps(columnAddr);

  const setColumnSelected = useColumnSelectedSet();
  const { setReleaselogs } = useEditorStatesSetters();

  const handleColumnSelected = () => {
    setColumnSelected(columnAddr);
  }

  const handleColumnNameEditDone = (columnName: string) => {
    const name = columnName;
    document.releaselogs.updateColumnProps(columnAddr, {name});
    setReleaselogs();
    document.saveUndo();
  }

  return (
    <HLS_HeaderCellNameEditComponent
      columnName={columnProps.name}
      onColumnSelected={handleColumnSelected}
      onColumnNameEditDone={handleColumnNameEditDone}
    >
      { children }
    </HLS_HeaderCellNameEditComponent>
  );
}
