import { ThemeDefProps } from "../types";


export const themeAttrs = (props: ThemeDefProps) => {
  const { accentColor, outline } = props;

  const attrs = {
    focusVisible: {
      outline: {
        style: 'solid',
        width: outline.width.normal,
        color: accentColor.primary,
      }
    }
  };
    
  return attrs;
}
