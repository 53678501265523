import produce from 'immer';

import * as State from './state';
import * as Types from './types';


class Mouse {
  private _state: State.State;
  private _listener: Types.Listener;

  constructor() {
    this._state = State.createInitialState();
    this._listener = null;
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */

  setAnimDrag(anim: Types.AnimDrag) {
    this._state = produce(this._state, draft => {
      draft.animDrag = anim;
    });

    this.runListener();
  }

  clearAnimDrag() {
    this._state = produce(this._state, draft => {
      draft.animDrag = null;
    });

    this.runListener();
  }

  addListener(listener: Types.Listener) {
    this._listener = listener;
  }

  removeListener() {
    this._listener = null;
  }

  private runListener() {
    if (this._listener === null) {
      return;
    }

    this._listener(this._state);
  }


  /**
   * Getters
   */

  getAnimDrag(): Types.AnimDragNullable { 
    return State.getAnimDrag(this._state);
  }


  /**
   * Manual tests
   */

  _reset() {
    this.clearAnimDrag();
  }

  _test1() {
    this.setAnimDrag({
      position: {x: 100, y: 50}, 
      moveBy: {x: 0, y:0}, 
      duration: 2 
    });
  }

  _test2() {
    this.setAnimDrag({
      position: {x: 100, y: 50}, 
      moveBy: {x: 30, y: 175}, 
      duration: 2 
    });
  }

  _test3() {
    this.setAnimDrag({
      position: {x: 100, y: 50}, 
      moveBy: {x: 530, y: 575}, 
      duration: 2 
    });
  }
}

export default Mouse;