import { useRecoilValue } from 'recoil';
import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';
import useBaseWatcher from './use-base-watcher';


const usePageFormatWatcher = () => {
  const pageFormatItems = [
    useRecoilValue(UIState_ViewsCommon.pageLayout),
    useRecoilValue(UIState_ViewsCommon.pageMargins),
  ];

  useBaseWatcher(pageFormatItems);
}
  
export default usePageFormatWatcher;