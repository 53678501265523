import React from 'react';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import useCellImageMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-images/use-cell-image-menu';
import useContextMenu   from 'app/ui-v2/editor-instruction/hooks/use-context-menu';

import { MainWrapper } from './styles';


interface Props {
  imageAddr: Types.ImageAddr;
  children: React.ReactNode;
}


export const ImageContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children
  } = props;

  const { showMenu }  = useContextMenu();
  const getConfigMenu = useCellImageMenu();

  const handleContextMenu = (event: React.MouseEvent) => {
    const config = getConfigMenu(imageAddr);
    showMenu({event, config});
  }

  return (
    <MainWrapper
      onContextMenu={handleContextMenu}
    >
      { children }
    </MainWrapper>
  );
}
  
