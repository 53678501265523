import styled from "styled-components";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.defs.gap.small};
`;


export const Includes = styled.div`
  font-size:   ${props => props.theme.defs.font.size.xlarge};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;


export const Features = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${props => props.theme.defs.gap.xxxsmall};
`;
