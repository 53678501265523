import React from 'react';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import StylesBaseComponent from '../parts/styles-base';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr | null
}


export const StylesSystemComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr
  } = props;

  return (
    <StylesBaseComponent
      stylesLib={"system"}
      widgetAddr={widgetAddr}
    />
  );
}
  
