import styled from 'styled-components';


const PageBody = styled.div`
  /* TODO is this clip needed at all */
  overflow: clip;
  position: relative;
  background-color: white;

  width: 100%;
  height: 100%;
`;


export default PageBody;