/**
 * Panel Settings
 */

export type Navbar = {
  itemSelected: NavbarItem
}


export enum NavbarItem {
  WELCOME = 'welcome',
  DEMO    = 'demo',
  PRICING = 'pricing',

  USER_LOGIN  = 'login',
  USER_SIGNUP = 'sign-up'
};

export type NavbarUpdate = Partial<Navbar>;

