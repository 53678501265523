import styled from "styled-components";
import ScrollbarInv from 'lego/styles/scrollbar-inv';
import PanelBase  from "app-views/styles/panel";
import TitlePanel from "app-views/styles/title-panel";


export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;

  /* width: 100%; */
  max-height: 100%;
  /* background: rgba(255, 0, 0, 0.2); */
`;


/**
 * Grid rows
 */
export const RowTitle = styled.div`
  flex: 0 0 auto;
  /* background: rgba(0, 255, 0, 0.2); */
`;

export const RowContent = styled.div`
  display: flex;
  overflow: hidden;
`;

export const Title = styled(TitlePanel)`
`;

export const Panel = styled(PanelBase)`
  padding: 0px;
`;

export const Scroller = styled(ScrollbarInv)`
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  overflow-y: auto;
  padding: ${props => props.theme.panel.padding};
`;
