import styled from 'styled-components';
import { LineWrapperBase } from '../styles';
import { StyledLineBase  } from '../styles';
import { LINE_GRADIENT   } from '../styles';
import { DRAGGER_SIZE    } from '../styles';


export const LineWrapper = styled(LineWrapperBase)`
  height: 999999px;
`;

export const StyledLine = styled(StyledLineBase)`
  width: 1px;
  background: ${props => props.theme.margins.background};
  /* background-image: repeating-linear-gradient(
    0deg, 
    ${LINE_GRADIENT}
  ); */

  left: ${props => (DRAGGER_SIZE / 2 - props.$deltaX)}px;
`;
