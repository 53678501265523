import React from 'react';
import WidgetArrowPlainComponentBase from '../__widget-base/widget-arrows/widget-arrow-plain';
import { WidgetPropsBase } from '../types';


interface Props extends WidgetPropsBase {
}


export const WidgetArrowPlainComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <WidgetArrowPlainComponentBase {...props} />
  );
}
  
