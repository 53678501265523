import DraggerComponent  from "lego-v2/dragger/ui";
import { DraggerUpdate } from "lego-v2/dragger/arch";

import { Position } from "app/arch/types";
import { Size     } from "app/arch/types";
import { WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";
import { Arrow    } from "app/arch/editor-image/types/arrows";

import { DraggerHeadUpdate      } from "../types";
import { DraggerHeadUpdateStart } from "../types";
import { DraggerSizeMin         } from "../types";
import { ShapeCalcArrow    } from "../../shapes-calculators/shape-calc-arrow";
import { ShapeCalcPointer  } from "../../shapes-calculators/shape-calc-pointer";
import { ShapeCalcTriangle } from "../../shapes-calculators/shape-calc-triangle";

import { Control  } from "./styles";
import { Detacher } from "./styles";


const BTN_LEFT = 0;


export interface DraggerHeadProps {
  scale: number,
  disabled?: boolean;

  startPoint: Position;
  endPoint: Position;
  style: WidgetsStylesTypes.StyleAttrs;

  onContextMenu?: (event: any) => void;
  setWidgetSelected: (event: React.MouseEvent) => void;

  onUpdateStart?: (update: DraggerHeadUpdateStart) => void;
  onUpdate:       (update: DraggerHeadUpdate) => void;
  onUpdateDone:   (update: DraggerHeadUpdate) => void;

  dataTest: string | null;
}

interface Props extends DraggerHeadProps {
  pointer: Arrow.PointerType;
}


export const DraggerHeadBaseComponent: React.FC<Props> = (props: Props) => {
  const {
    scale,
    disabled,

    startPoint,
    endPoint,
    style,

    setWidgetSelected,
    onContextMenu,

    onUpdateStart,
    onUpdate,
    onUpdateDone,

    pointer,

    dataTest
  } = props;

  const headDataTest = (
    dataTest !== null ?
    `${dataTest}__head` :
    null
  );

  const ShapeCalcClasses: {[key in Arrow.PointerType]: any} = {
    [Arrow.PointerType.ARROW   ] : ShapeCalcArrow,
    [Arrow.PointerType.POINTER ] : ShapeCalcPointer,
    [Arrow.PointerType.TRIANGLE] : ShapeCalcTriangle,
    [Arrow.PointerType.DOTS    ] : ShapeCalcArrow, // not used
  }

  const ShapeCalcClass = ShapeCalcClasses[pointer];
  const shapeCalc = new ShapeCalcClass({
    style,
    startPoint,
    endPoint
  });
  const c = shapeCalc;

  const width = Math.max(DraggerSizeMin, c.widthTotal);
  
  let height  = c.arrowHeadHeightTotal;
  height = Math.max(DraggerSizeMin, height);
  height = Math.min(c.heightTotal, height);
  
  const left = startPoint[0] - width / 2;
  const top  = startPoint[1];


  const handlePointerDown = (event: React.MouseEvent) => {
    if (disabled) {
      return;
    }
   
    if (event.button !== BTN_LEFT) {
      return;
    }
    setWidgetSelected(event);
    
    event.stopPropagation();
  }

  const handleDraggerUpdateStart = (event: React.PointerEvent) => {
    const clickOffset = [
      event.nativeEvent.offsetX - c.widthTotal / 2,
      event.nativeEvent.offsetY
    ] as Size;

    const update = { clickOffset };
    onUpdateStart?.(update);
  }

  const createUpdate = (update: DraggerUpdate) => {
    const draggerHeadUpdate: DraggerHeadUpdate = {
      delta: update.delta,
    };

    return draggerHeadUpdate;
  }

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    const headUpdate = createUpdate(update);
    onUpdate(headUpdate);
  }
  
  const handleDraggerUpdateDone = (update: DraggerUpdate) => {
    handleDraggerUpdate(update);

    const headUpdate = createUpdate(update);
    onUpdateDone(headUpdate);
  }
  

  //----------------------------------------
  // Render
  //
  return (
    <div 
      onPointerDown={handlePointerDown}
      onContextMenu={onContextMenu}
    >
      <DraggerComponent
        position={startPoint}
        onUpdateStart={handleDraggerUpdateStart}
        onUpdate={handleDraggerUpdate}
        onUpdateDone={handleDraggerUpdateDone}
        scale={scale}
        disabled={disabled}
        stopPropagation={false}
      >
        <Detacher
          style={{
            left: `${left}px`,
            top:  `${top}px`,

            transform: `rotate(${c.arrowAngle}rad)`,
            transformOrigin: `${width / 2}px 0px`,
          }}
        >
          <Control
            style={{
              width:  `${width}px`,
              height: `${height}px`,
            }}
            data-test={headDataTest}
          />
        </Detacher>
      </DraggerComponent>
    </div>
  );
}

