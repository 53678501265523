import React from 'react';

import { PageFormatType }      from 'app/arch/types/types';
import { PageMarginType }      from 'app/arch/types/types';
import { PageOrientationType } from 'app/arch/types/types';

import PageMarginComponent from './page-margin';
import { MarginType }      from './page-margin/PageMargin';


interface Props {
  pageFormat: PageFormatType;
  pageMargins: PageMarginType;
  pageOrientation: PageOrientationType;
}


export const PageMarginsComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageMargins, 
    pageFormat, 
    pageOrientation 
  } = props;

  
  return (
    <>
      <PageMarginComponent
        marginType={MarginType.TOP}
        pageFormat={pageFormat}
        pageMargins={pageMargins}
        pageOrientation={pageOrientation}
      />
      <PageMarginComponent
        marginType={MarginType.BOTTOM}
        pageFormat={pageFormat}
        pageMargins={pageMargins}
        pageOrientation={pageOrientation}
      />
      <PageMarginComponent
        marginType={MarginType.LEFT}
        pageFormat={pageFormat}
        pageMargins={pageMargins}
        pageOrientation={pageOrientation}
      /> 
      <PageMarginComponent
        marginType={MarginType.RIGHT}
        pageFormat={pageFormat}
        pageMargins={pageMargins}
        pageOrientation={pageOrientation}
      />      
    </>
  );
}
