import { useNavigate } from "react-router-dom";

import urls from "app/configs/urls";
import { ViewTypes } from "app/arch/home-page-user/states/view"
import useViewSelect from "./use-view-select";


const useUserGoHome = () => {
  const navigate = useNavigate();
  const selectView = useViewSelect();
  
  const goHome = () => {
    selectView(ViewTypes.ViewItem.DOCUMENTS);
    navigate(urls.home);
  }
  
  return goHome;
}

export default useUserGoHome;