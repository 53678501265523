import styled from 'styled-components';
import DraggedBase from '../styles/dragged';
import ScalerBase from '../styles/scaler';
import VirtualBase from '../styles/virtual';
import DraggedWrapper from '../styles/dragged-wrapper';



export const Wrapper = styled(DraggedWrapper)`
`;

export const Dragged = styled(DraggedBase)`
`;

export const Scaler = styled(ScalerBase)`
`;

export const Virtual = styled(VirtualBase)`
  /* visibility: visible; */
  /* top: 199px; */
  /* left: 199px; */
  /* background: yellow; */
`;
