import styled from "styled-components";


interface Props {
  selected: boolean;
  color: string;
  borderColor: string;
}


export const ButtonColor = styled.div<Props>`
  cursor: pointer;

  width: 18px;
  height: 18px;
  box-sizing: border-box;
  outline: solid 1px ${props => props.theme.buttonColor.background};
  border:  solid 1px ${props => props.theme.buttonColor.border.color};

  background:    ${props => props.color};
  border-radius: ${props => props.theme.buttonColor.border.radius};

  ${props => props.selected ? 
    `
      box-shadow: 0 0 0 3px ${props.borderColor}; 
    `
  : 
    `
    `
  }


  &:active {
    box-shadow: 0 0 0 3px ${props => props.borderColor}; 
  }
`;


export const ButtonColorTransparent = styled(ButtonColor)`
  position: relative;
  overflow: clip;

  &::before,
  &::after {
    content: "";

    position: absolute;
    left: 7px;
    top: -1px;

    height: inherit;
    width: 2px;

    opacity:    ${props => props.theme.defs.opacity.medium};
    background: ${props => props.theme.defs.colors.color.primary};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }            
`;


export default ButtonColor;