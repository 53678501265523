import React from 'react';

import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import useCellSelected from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/cell/use-cell-selected';
import { useDocState } from 'app/ui/contexts/document';

import CellTextMenuComponent from './cell-text-menu';
import CellIndexMenuComponent from './cell-index-menu';


interface Props {
}


export const MenuRenderComponent: React.FC<Props> = (props: Props) => {
  const document = useDocState();
  const selectedCell = useCellSelected();

  if (selectedCell === null) {
    return null
  }

  type CellMenuType ={[key in Types.ColumnType]: React.ComponentType<any>};

  const CellMenuComponents: CellMenuType = {
    [Types.ColumnType.INDEX ]: CellIndexMenuComponent,
    [Types.ColumnType.TEXT  ]: CellTextMenuComponent,
  };

  const getMenu = () => {
    const columnProps = document.releaselogs.getColumnProps(selectedCell);

    if ( ! (columnProps.type in CellMenuComponents) ) {
      const msg = `Don't know how to render cell menu type: '${columnProps.type}'`
      throw new Error(msg);
    }

    const Menu = CellMenuComponents[columnProps.type];
    return Menu;
  }

  const Menu = getMenu();

  return (
    <Menu 
      cellAddr={selectedCell}
    />
  );
}
