import React from 'react';
import { PanelDraggableItemConfig } from '../config';
import { ColumnItem, ColumnButtons, MainWrapper } from './styles';
import ItemTextComponent from './item-text';
import ItemButtonComponent from './item-button';
import ItemDraggerComponent from './item-dragger';

interface Props {
  idx: number;
  config: PanelDraggableItemConfig;
  onDragStart: () => void;
  onDragOver: () => void;
  onDragEnter: () => void;
  onDragEnd: () => void;
  onDrop: (event: React.DragEvent) => void;
}

export const DND_CONTEXT = "view-print-columns-side-panel-columns";


export const PanelItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    idx, 
    config, 
    onDragStart,
    onDragOver,
    onDragEnter,
    onDragEnd,
    onDrop,
  } = props;
  
  const handleDragStart = (event: React.DragEvent) => {
    event.dataTransfer.setData("idx", `${idx}`);
    onDragStart();
  }

  const handleDragOver = (event: React.DragEvent) => {
    onDragOver();
  }

  const handleDragEnter = (event: React.DragEvent) => {
    onDragEnter()
  }

  const handleDragEnd = (event: React.DragEvent) => {
    onDragEnd();
  }

  const handleDrop = (event: React.DragEvent) => {
    onDrop(event);
  }

  const renderButtons = () => {
    const buttonsCompos = config.buttons?.map((button, idx) => {
      return (
        <ItemButtonComponent
          key={idx}
          config={button}
          selected={config.selected}
        />
      )
    });

    return buttonsCompos;
  }

  const handleClick = () => {
    config.onClick && config.onClick();
  }
  
  return (
    <MainWrapper onClick={handleClick}>
      <ColumnItem>
        <ItemDraggerComponent
          text={config.value}
          dndContext={DND_CONTEXT}
          disabled={false}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragEnd}
          onDrop={handleDrop}
        >
          <ItemTextComponent
            config={config}
          />
        </ItemDraggerComponent>
      </ColumnItem>

      <ColumnButtons>
        { renderButtons() }
      </ColumnButtons>

    </MainWrapper>
  );
}
  
