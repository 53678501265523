import React from 'react';
import MenuViewComponent from '../menu-view';
import UserSettingsMenuComponent from '../../panels/user-settings-menu';
import { MainWrapper } from './styles';

interface Props {
}


export const MenuTopRightComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <MainWrapper>
      <MenuViewComponent />
      <UserSettingsMenuComponent />
    </MainWrapper>
  );
}
  
