import styled from "styled-components";
import SignRectangle from "../styles/sing-rectangle";
import { Config } from "../config";


export const Sign = styled(SignRectangle)`
  color: #000;
  background-color: #FFF;
  outline-color: #00008b;
  
  border-color: #0561DC;
  border-width: ${Config.style.border.width}px;
  border-style: solid;
`;
