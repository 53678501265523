class RunningSettings__ {
  private static instance: RunningSettings__;
  public  static getInstance(): RunningSettings__ {
    if (!RunningSettings__.instance) {
      RunningSettings__.instance = new RunningSettings__();
    }
    return RunningSettings__.instance;
  }

  private _scrollbarWidth: number;

  private constructor() {
    this._scrollbarWidth = 0;
  }

  get scrollbarWidth() { return this._scrollbarWidth; }
  set scrollbarWidth(width: number) { this._scrollbarWidth = width; }
}

export const RunningSettings = RunningSettings__.getInstance();
