import { MutableRefObject } from 'react';
import EditorTextBaseComponent  from 'app/ui/components/editor-txt';

import { CellProps }      from '../../types';
import ContentSavePlugin  from './content-save-plugin';
import RegistrationPlugin from './registration-plugin';


interface Props extends CellProps {
  editorTextRef: MutableRefObject<HTMLDivElement | null>;
}


export const CellTextEditorComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr, 
    editorTextRef,
  } = props;

  return (
    <EditorTextBaseComponent
      editorTextRef={editorTextRef}
      Plugins={[
        <RegistrationPlugin pageCellAddr={pageCellAddr} />,
        <ContentSavePlugin  pageCellAddr={pageCellAddr} />
      ]}
    />
  );
}
