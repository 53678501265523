import React from 'react';

import FrameResizeComponent  from 'lego-v2/frame-resize/ui';
import { FrameProps        } from 'lego-v2/frame-resize/ui/FrameResize';
import { FrameCtrlType, FrameTargetUpdate } from 'lego-v2/frame-resize/arch';

import SmartLinesControlComponent from '../smart-lines-control';
import { SmartLinesControlProps } from '../smart-lines-control/SmartLinesControl';
import { StickyLinesRaw } from 'tools/smart-lines/types';


interface Props extends SmartLinesControlProps<FrameTargetUpdate> {
  controlProps: FrameProps;
}


export const FrameResizerSmartLinesComponent: React.FC<Props> = (props: Props) => {
  const SmartLinesControl = SmartLinesControlComponent<FrameTargetUpdate, FrameProps>;

  const handleApplyStickyLines = (update: FrameTargetUpdate, stickyLines: StickyLinesRaw) => {
    let size     = update.size ? [...update.size]: [0, 0];
    let position = update.position ? [...update.position]: [0, 0];

    if (stickyLines.horizontal) {
      const dstLine = stickyLines.horizontal.dstLine;
      const srcLine = stickyLines.horizontal.srcLine;
      
      const missAligment = dstLine.y0 - srcLine.y0;

      if ([
            FrameCtrlType.TOP_MIDDLE,
            FrameCtrlType.TOP_LEFT,
            FrameCtrlType.TOP_RIGHT,
          ].includes(update.control)) 
      {
        position[1] = position[1] + missAligment;
        size[1]     = size[1] - missAligment;
      }
      else {
        size[1] = size[1] + missAligment;
      }
    }

    if (stickyLines.vertical) {
      const dstLine = stickyLines.vertical.dstLine;
      const srcLine = stickyLines.vertical.srcLine;
      
      const missAligment = dstLine.x0 - srcLine.x0;

      if ([
        FrameCtrlType.MIDDLE_LEFT,
        FrameCtrlType.TOP_LEFT,
        FrameCtrlType.BOTTOM_LEFT,
      ].includes(update.control)) 
      {
        position[0] = position[0] + missAligment;
        size[0]     = size[0] - missAligment;
      }
      else {
        size[0] = size[0] + missAligment;
      }
    }

    return {
      size, 
      position,
      custom: update.custom
    } as FrameTargetUpdate;
  }

  return (
    <SmartLinesControl
      {...props}
      onApplyStickyLines={handleApplyStickyLines}
      ControlProps={props.controlProps}
      ControlComponent={FrameResizeComponent}
    />
  );
}
  