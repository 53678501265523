import styled from "styled-components";
import ButtonColorBase from "lego/styles/button_color";


export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  height: 32px;
`;

export const ButtonColor = styled(ButtonColorBase)`
`;
