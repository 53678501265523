import { uuid } from 'tools/uuid';
import * as Types from './types';


export const getMarkerKey = (
  markerAddr: Types.MarkerAddr
): string => (
  `${markerAddr.markerId}`
);

export const createMarkerAddr = (): Types.MarkerAddr => {
  const markerId = uuid();
  const markerAddr = { markerId };
  return markerAddr;
}

export const compareMarkerAddr = (
  srcMarkerAddr: Types.MarkerAddr,
  dstMarkerAddr: Types.MarkerAddr,
): boolean => (
  srcMarkerAddr.markerId === dstMarkerAddr.markerId
);
