import { Msgs } from './use-common';
import { useCommon } from './use-common';
import { useLazyQuery as useLazyQueryApollo } from '@apollo/client';


export const useQueryManual = (props: {
  query: any,
  msgs?: Msgs,
  variables?: any,
}) => {
  const {
    query,
    msgs,
    variables,
  } = props;

  const [fetch, { 
    loading, 
    error, 
    data,
  }] = useLazyQueryApollo(query, {
    variables,
    fetchPolicy: 'no-cache'
  });

  useCommon({ msgs, error, loading });
 
  return {
    fetch,
    loading,
    data,
    error,
  }
}
