import React from 'react';
import ImageBorderComponentBase from 'app/ui-v2/editor-instruction/views/view-content/components/content/page/page-items/page-item-content/content-grid/section/cells-row/cell/cell-render/cells/cell-images/images/image/image-border';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
  children: React.ReactNode;
}


export const ImageBorderComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
    imageAddr,
  } = props;


  return (
    <ImageBorderComponentBase imageAddr={imageAddr}>
      { children }
    </ImageBorderComponentBase>
  );
}

