import * as Tools from '../tools';
import * as Types from '../types';


type ActionMap = {[actionKey: string]:  boolean};


class DeadActions {
  private _deadActions: ActionMap;

  constructor() {
    this._deadActions = {}
  }

  addDead(actionAddr: Types.ActionAddr) {
    if ( this.hasAction(actionAddr) ) {
      const msg = `Dead action already saved`;
      console.warn(msg);
      return;
    }

    const actionKey = Tools.getActionKey(actionAddr);
    this._deadActions[actionKey] = true;
  }

  removeDead(actionAddr: Types.ActionAddr) {
    const actionKey = Tools.getActionKey(actionAddr);

    if ( ! this.hasAction(actionAddr) ) {
      const msg = `No action to be removed, action key: ${actionKey}`;
      console.warn(msg);
      return;
    }
    
    delete this._deadActions[actionKey];
  }

  hasAction(actionAddr: Types.ActionAddr) {
    const actionKey = Tools.getActionKey(actionAddr);
    return (actionKey in this._deadActions);
  }
}

export default DeadActions;