import styled from 'styled-components';



interface ClipperProps {
  $width: number;
  $height: number;
}

export const Clipper = styled.div.attrs<ClipperProps>(props =>({
  style: {
    width: `${props.$width}px`,
    height: `${props.$height}px`,
  }
}))<ClipperProps>`
  overflow: clip;
  background: white;
`;



interface ScalerProps {
  $scale: number;
}

export const Scaler = styled.div.attrs<ScalerProps>(props => ({
  style: {
    transform: `scale(${props.$scale})`,
  }
}))<ScalerProps>`
  transform-origin: 0 0;
`;


export const Positioner = styled.div`
  position: relative;
`;


interface DetacherProps {
  $left: number;
  $top: number;
}

export const Detacher =  styled.div.attrs<DetacherProps>(props => ({
  style: {
    left: `${props.$left}px`,
    top:  `${props.$top}px`,
  }
}))<DetacherProps>`
  position: absolute;
`;

