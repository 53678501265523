import styled from 'styled-components';

import PositionerBase from "../styles/positioner";
import CenterBase from "../styles/center";
import ClipBase from "../styles/clip";



export const Positioner = styled(PositionerBase)`
`;


export const Center = styled(CenterBase)`
`;


export const Clip = styled(ClipBase)`
`;


export const Shape = styled.div`
  border-style: none;
  outline-style: none;
`;


