import styled from "styled-components";
import { ExcelExportConfig } from "../../../../config";


const QUALITY = ExcelExportConfig.quality;


export const ContentWrapper = styled.div`
  position: absolute;
  top: -9999999px;
  left: -9999999px;
`;

export const Content = styled.div`
`;


export const Scaler = styled.div`
  transform-origin: 0 0;
  scale: ${QUALITY};
`;
