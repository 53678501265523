import React from 'react';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ViewLegalBaseComponent from '../view-legal-base';
import { content } from './content';


interface Props {
}


export const ViewLegalPrivacyPolicyComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  
  return (
    <ViewLegalBaseComponent
      title={ t('privacy policy') }
      content={content}
    />
  );
}
