import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { MainWrapper } from './styles';


interface Props {
}


export const SectionAdderViewComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  return (
    <MainWrapper>
      { t("section, add") }
    </MainWrapper>
  );
}
