import styled from 'styled-components';


const Scaler = styled.div<{scale: number}>`
  transform: scale(${props => props.scale});
  transform-origin: 0 0;

  width:  ${props => (100 / props.scale)}%;
  height: ${props => (100 / props.scale)}%;
`;


export default Scaler;