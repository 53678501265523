import React from 'react';
import DocHeaderComponent from './doc-header';
import { PageItemsProps } from '../types';


interface Props extends PageItemsProps {
}


export const PageItemDocHeaderInfoComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <DocHeaderComponent />
  );
}
  
