import produce from 'immer';
import { UUID }  from 'app/arch/types';
import * as State from './state';


export class HeaderMarkersSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  //-----------------
  //
  // Setters

  setMarkerSelected(markerId: UUID | null) {
    this._state = produce(this._state, draft => {
      draft.selectedMarker = markerId;
    });
  }

  //-----------------
  //
  // Getters
  
  getMarkerSelected() {
    return State.getMarkerSelected(this._state);
  }
}
