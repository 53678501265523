import React from 'react';
import ImageTool from 'app/arch/tools/image-tool';
import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import useImageUrlGet  from 'app/ui-v2/editor-instruction/hooks/use-image-url-get';

import { StyledImage } from './styles';
import { ItemSelectedBorder } from './styles';


interface Props {
  image: RepoImagesTypes.Image;
}


export const ImageDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const { 
    image, 
  } = props;

  const getImageUrl = useImageUrlGet();
  const imageUrl    = getImageUrl(image);

  const size      = ImageTool.getDefaultBoxedSize(image);
  const viewScale = ImageTool.getDefaultViewScale(image);
  
  const width  = size[0] * viewScale;
  const height = size[1] * viewScale;
  

  return (
    <ItemSelectedBorder
      $width={width}
      $height={height}
    >
      <StyledImage src={imageUrl} />
    </ItemSelectedBorder>
  );
}
