import * as Types from './types';



export const getInfos = (): Types.Infos => ({
  addrs: [],
  props: {}
});


export const getInfoProps = (
  props: Types.InfoPartialProps
): Types.InfoProps => ({
  text: (
    props.text !== undefined ?
    props.text : 
    "missing text"
  )
});