import * as Tools from '../tools';
import * as Types from '../types';


type Timeout = NodeJS.Timeout;
type TimeoutMap = {[actionKey: string]:  Timeout};


class Timeouts {
  private _timeouts: TimeoutMap;

  constructor() {
    this._timeouts = {}
  }

  addHandler(actionAddr: Types.ActionAddr, timeout: Timeout) {
    if ( this.hasHandler(actionAddr) ) {
      const msg = `Action already has timeout handler`;
      console.warn(msg);
      return;
    }

    const actionKey = Tools.getActionKey(actionAddr);
    this._timeouts[actionKey] = timeout;
  }

  removeHandler(actionAddr: Types.ActionAddr) {
    const actionKey = Tools.getActionKey(actionAddr);
    if ( ! this.hasHandler(actionAddr) ) {
      const msg = `Missing timeout handler for action: ${actionKey}`;
      console.warn(msg);
      return;
    }
    
    delete this._timeouts[actionKey];
  }

  clearHandler(actionAddr: Types.ActionAddr) {
    const actionKey = Tools.getActionKey(actionAddr);
    if ( ! this.hasHandler(actionAddr) ) {
      const msg = `Missing timeout handler for action: ${actionKey}`;
      console.warn(msg);
      return;
    }

    const timeoutHn = this._timeouts[actionKey];
    clearTimeout(timeoutHn);

    this.removeHandler(actionAddr);
  }

  hasHandler(actionAddr: Types.ActionAddr) {
    const actionKey = Tools.getActionKey(actionAddr);
    return (actionKey in this._timeouts);
  }
}

export default Timeouts;