import React from 'react';

import { useDocState } from 'app/ui/contexts/document';

import { Input } from './styles';
import { InputWrapper } from './styles';


export const InputStyledComponent = React.forwardRef((
  props: React.InputHTMLAttributes<HTMLInputElement>, 
  ref: React.Ref<HTMLInputElement>
) => {
  const pageRowAddr = (props as any).pageRowAddr;
  const docState = useDocState();
  const rowProps = docState.headerRows.getRowProps(pageRowAddr);
  const commonTableHeaderCSS =  docState.viewsCommon.getTableHeaderCSS();
  const background = commonTableHeaderCSS.background as string;

  const style = {
    ...rowProps.header.css
  }

  return (
    <InputWrapper $background={background}>
      <Input
        {...props} 
        ref={ref} 
        style={style}
      />
    </InputWrapper>
  );
});
