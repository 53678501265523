import useInfoState from "../../../hooks/use-info-state";


const useToastsState = () => {
  const info = useInfoState();
  const toasts = info.toasts;

  return toasts;
}


export default useToastsState;