import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import * as Types from './types';
import * as Defaults from './defaults';

import Logger from 'libs/debug';


const log = Logger.getContentElementsSizes();


export class Releaselogs {
  private _releaselogs: Types.Releaselogs;
  private _spacer: number;

  constructor() {
    this._releaselogs = Defaults.getReleaselogs();
    this._spacer = 0;
  }

  getSpacerHeight() {
    return this._spacer;
  }

  setSpacerHeight(height: number) {
    this._spacer = height;
  }

  getReleaseInfoHeight(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr
  ): number {
    const releaseInfo = this._getReleaselogInfo(releaselogAddr);
    return releaseInfo.height;
  } 

  setReleaseInfoHeight(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
    height: number
  ): void {
    const releaseInfo = this._getReleaselogInfo(releaselogAddr);
    releaseInfo.height = height;
  } 

  getChangelogHeaderRowHeight(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ): number {
    const changelogHeaderRow = this._getChangelogHeaderRow(releaselogAddr);
    return changelogHeaderRow.height;
  } 

  setChangelogHeaderRowHeight(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
    height: number
  ): void {
    const changelogHeaderRow = this._getChangelogHeaderRow(releaselogAddr);
    changelogHeaderRow.height = height;
  } 

  getChangelogRowHeight(
    rowAddr: ReleaselogsTypes.RowAddr,
  ): number {
    const row = this._getChangelogRow(rowAddr);
    return row.height;
  } 

  setChangelogRowHeight(
    rowAddr: ReleaselogsTypes.RowAddr,
    height: number
  ): void {
    const row = this._getChangelogRow(rowAddr);
    row.height = height;
  } 

  private _getReleaselog(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ): Types.Releaselog {
    const releaselogId = releaselogAddr.releaselogId;
    
    let releaselog = this._releaselogs[releaselogId];
    if (releaselog === undefined) {
      log.debug(`Releaselog not found, id: ${releaselogId}`);
      log.debug('Create new releaselog');

      releaselog = Defaults.getReleaselog();
      this._releaselogs[releaselogId] = releaselog;
    }

    return releaselog;
  }

  private _getReleaselogInfo(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ): Types.ReleaseInfo {
    const releaselog = this._getReleaselog(releaselogAddr);
    return releaselog.releaselogInfo;
  }

  private _getChangelog(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ): Types.Changelog {
    const releaselog = this._getReleaselog(releaselogAddr);
    return releaselog.changelog;
  }

  private _getChangelogHeaderRow(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ): Types.ChangelogHeaderRow {
    const changelog = this._getChangelog(releaselogAddr);
    return changelog.headerRow;
  }

  private _getChangelogRows(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ): Types.ChangelogRows {
    const changelog = this._getChangelog(releaselogAddr);
    return changelog.rows;
  }

  private _getChangelogRow(
    rowAddr: ReleaselogsTypes.RowAddr,
  ): Types.ChangelogRow {
    const rows = this._getChangelogRows(rowAddr);
    const rowId = rowAddr.rowId;

    let row = rows[rowId];
    if (row === undefined) {
      log.debug(`Row not found, id: ${rowId}`);
      log.debug('Create new row');

      row = Defaults.getChangelogRow();
      rows[rowId] = row;
    }

    return row;
  }
}
