import produce from 'immer';
import * as Types from './types';
import * as State from './state';


export class ReleaselogsView {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Header Meta
   */
  updateHeaderMeta(update: Types.HeaderMetaUpdate) {
    this._state = produce(this._state, draft => {
      const header = State.getHeader(draft);
      Object.assign(header.meta, update);
    });
  }

  /**
   * Header Fields
   */
  updateHeaderFields(update: Types.HeaderFieldsUpdate) {
    this._state = produce(this._state, draft => {
      const header = State.getHeader(draft);
      Object.assign(header.fields, update);
    });
  }


  /**
   * Header Title
   */
  updateHeaderTitle(update: Types.HeaderTitleUpdate) {
    this._state = produce(this._state, draft => {
      const header = State.getHeader(draft);
      Object.assign(header.title, update);
    });
  }

  updateHeaderTitleCSS(updateCSS: React.CSSProperties) {
    this._state = produce(this._state, draft => {
      const header = State.getHeader(draft);
      const headerTitle = header.title;
      headerTitle.css = {
        ...headerTitle.css,
        ...updateCSS
      }
    });
  }


  /**
   * Getters
   */
  getHeader() {
    return State.getHeader(this._state);
  }

  getHeaderMeta() {
    return State.getHeaderMeta(this._state);
  }

  getHeaderFields() {
    return State.getHeaderFields(this._state);
  }

  getHeaderTitle() {
    return State.getHeaderTitle(this._state);
  }
}
