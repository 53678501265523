import React from 'react';

import { DocumentRelease } from 'app/arch/backend/types';
import PanelItemComponent from 'app-views/components/panel-item';
import { PanelItemConfig } from 'app-views/components/panel-item/config';
import ItemContentComponent from './item-content';



interface Props {
  selected: boolean;
  release: DocumentRelease;
  onReleseSelect: (release: DocumentRelease) => void;
}


export const ReleaseItemComponent: React.FC<Props> = (props: Props) => {
  const {
    selected,
    release,
    onReleseSelect,
  } = props;

  const handleReleaseSelect = (event: React.MouseEvent) => {
    event.stopPropagation();
    onReleseSelect(release);
  }


  const config: PanelItemConfig = {
    selected,

    content: {
      component: ItemContentComponent,
      props: {
        release,
      },
    },
    buttonsAutoHide: true,
    onClick: handleReleaseSelect,
    buttons: []
  }


  return (
    <PanelItemComponent
      config={config}
    />
  );
}
