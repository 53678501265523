import React from 'react';
import { useRef } from 'react';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import usePageContext from 'app/ui-v2/editor-instruction/views/view-content/hooks/page/use-page-context';

import CellSelectedBorderComponent from './components/cell-selected-border';
import CellBorderComponent from './components/cell-border';
import CellLineSelectedComponent from './components/cell-line-selected';
import CellSelectComponent from './components/cell-select';
import CellTextComponent from './components/cell-text';
import CellCSSCustomComponent from './components/cell-css-custom';
import CellsLineDragOverComponent from './components/cells-line-drag-over';
import RowDropComponent from './components/row-drop';


interface Props {
  rowAddr: HeaderRowsTypes.RowAddr;
}



export const CellComponent: React.FC<Props> = (props: Props) => {
  const {
    rowAddr
  } = props;

  const editorTextRef = useRef<HTMLDivElement | null>(null);

  const pageContext = usePageContext();
  const pageIdx = pageContext.pageIdx;
  if (pageIdx === null) {
    const msg = `PageIdx is null`;
    throw new Error(msg);
  }

  const cellAddr: HeaderRowsTypes.CellAddr = {
    ...rowAddr
  };

  const pageCellAddr: HeaderRowsTypes.PageRowAddr = {
    ...cellAddr,
    pageIdx,
  }

  
  return (
    <CellBorderComponent>
      <CellLineSelectedComponent pageCellAddr={pageCellAddr} >
        <CellsLineDragOverComponent pageCellAddr={pageCellAddr} >
          <CellSelectedBorderComponent pageCellAddr={pageCellAddr}>
            <CellSelectComponent pageCellAddr={pageCellAddr} >
              <RowDropComponent pageCellAddr={pageCellAddr} >
                <CellCSSCustomComponent
                  pageCellAddr={pageCellAddr}
                  editorTextRef={editorTextRef}
                >
                  <CellTextComponent 
                    pageCellAddr={pageCellAddr} 
                    editorTextRef={editorTextRef}
                  />
                </CellCSSCustomComponent>
              </RowDropComponent>
            </CellSelectComponent>
          </CellSelectedBorderComponent>
        </CellsLineDragOverComponent>
      </CellLineSelectedComponent>
    </CellBorderComponent>
  );
}

