import { ComponentType } from 'react';
import { StyledBtnTextBase } from './styles';


interface Props {
  text: string;
  selected?: boolean;
  onClick?: (data?: any) => void;
  component?: ComponentType<any>;
  children?: any;
  disabled?: boolean;
  dataTest?: string;
};


export const BtnTextComponent = (props: Props) => {
  const {text, onClick} = props;
  const StyledBtnText = props.component || StyledBtnTextBase;

  const selected = (
    props.selected !== undefined ?
    props.selected :
    false
  );

  const disabled = (
    props.disabled !== undefined ?
    props.disabled :
    false
  );

  const dataTestProps = (
    props.dataTest !== undefined ?
    {'data-test': props.dataTest} :
    {}
  );

  return (
    <StyledBtnText 
      className={`item ` + (selected ? 'selected' : '')}
      onClick={onClick}
      disabled={disabled}
      {...dataTestProps}
    >
      {text}
      {props.children !== undefined && props.children}
    </StyledBtnText>    
  )
};
