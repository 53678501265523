import styled from "styled-components";


export const ColumnCSS = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  height: 100%;
  box-sizing: border-box;
`;
