import React from 'react';
import { useRecoilValue } from 'recoil';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import useItemsTools from '../../../hooks/use-items-tools';

import { Row } from './styles';


interface Props {
  children: React.ReactNode;
}


export const SectionAdderCSSComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const sectionsNamesCSS = useRecoilValue(UIState_Content.sectionsNamesCSS);
  
  const {
    sectionAdder_getBorderTopFaded
  } = useItemsTools();

  const borderTopFaded = sectionAdder_getBorderTopFaded();

  return (
    <Row
      style={sectionsNamesCSS}
      faded={true}

      borderTopFaded={borderTopFaded}
      borderLeft={true}
      borderBottom={true}
    >
      { children }
    </Row>
  );
}
  
