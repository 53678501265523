import React from 'react';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import IconLib from 'app/ui/icons/icons';

import BtnGuestRunComponent from './btn-guest-run';
import InfoPanelComponent from '../parts/info-panel';
import PanelTextComponent from './panel-text';


interface Props {
}


export const RunDemoComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const t = useTranslations();
  const Icon = IconLib.Demo;

  return (
    <InfoPanelComponent 
      title={ t("demo view, panel run demo, header") }
      Icon={Icon}
    >
      <PanelTextComponent />
      <BtnGuestRunComponent />
    </InfoPanelComponent>
  );
}

