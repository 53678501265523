import styled from "styled-components";


interface Props {
  $disabled?: boolean;
}


export const MainWrapper = styled.div.attrs<Props>(props =>({
  style: {
    pointerEvents: `${props.$disabled ? 'none' : 'all'}`
  }
}))<Props>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  gap: ${props => props.theme.defs.gap.normal};
`;
