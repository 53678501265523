import React from 'react';
import { MenuConfig }  from 'lego-v2/menu/config';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useContextMenu  from 'app/ui-v2/editor-instruction/hooks/use-context-menu';

import { MainWrapper } from './styles';


interface Props {
  cellAddr: ContentTypes.CellAddr;
  getConfig: (cellAddr: ContentTypes.CellAddr) => MenuConfig;
  children: React.ReactNode;
}


export const CellContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    getConfig,
    children,
  } = props;

  const { showMenu } = useContextMenu();

  const handleContextMenu = (event: React.MouseEvent) => {
    const config = getConfig(cellAddr);
    showMenu({ event, config });
  }

  return (
    <MainWrapper
      onContextMenu={handleContextMenu}
    >
      { children }
    </MainWrapper>
  );
}
