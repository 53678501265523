import { atom } from 'recoil';
import { selector } from 'recoil';
import { ViewState as State } from 'app/arch/home-page-guest/states/view';


export const state = atom<State.State>({
  key: "homePageGuest_view",
  default: State.createInitialState()
});

export const selected = selector({
  key: `homePageGuest_view_selected`,
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.selected;
  }
});
