import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig }   from 'lego-v2/menu/config';

import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsSessionTypes as SessionTypes } from 'app/arch/editor-instruction/document/states/sessions/releaselogs-session';

import { Icon }            from "app/ui/icons/icons";
import { useDocState }     from "app/ui/contexts/document";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";

import useContextElement      from "../../../../hooks/use-context-element";
import useEditorStatesSetters from "../../../../hooks/use-editor-states-setters";

import useSelectedReset        from '../use-selected-reset';
import useColumnSelectedSet    from '../column/use-column-selected-set';
import useReleaselogColumnsAutoAdjust from '../columns/use-releaselog-columns-auto-adjust';

import NewColumnPanelComponent from './new-column-panel';


const useColumnMenu = (columnAddr: Types.ColumnAddr) => {
  const t = useTranslations();
  const document = useDocState();

  const releaselogAddr = columnAddr;
  const columnsAutoAdjust = useReleaselogColumnsAutoAdjust(releaselogAddr);

  const {
    setReleaselogsSession,
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();

  const {
    showElement,
    hideElement,
  }  = useContextElement();

  const resetSelected = useSelectedReset();
  const setColumnSelected = useColumnSelectedSet();


  const addColumn = (columnAddr: Types.ColumnAddr, event: React.PointerEvent) => {
    event.stopPropagation();

    const onCancel = () => {
      hideElement();
    }

    const onDone = (newColumnAddr: Types.ColumnAddr) => {
      // Auto adjust was already called after adding column
      setColumnSelected(newColumnAddr);
    }

    showElement({
      Element: NewColumnPanelComponent, 
      ElementProps: {
        columnAddr,
        onCancel,
        onDone
      },
      event
    });
    hideMenu();
  }

  const showColumnProperties = (columnAddr: Types.ColumnAddr, event: React.PointerEvent) => {
    event.stopPropagation();
    
    document.releaselogsSession.setColumnSelected(columnAddr);
    document.releaselogsSession.setPanelDocument({
      visible: true, 
      selected: SessionTypes.PanelDocumentItem.COLUMN
    }); 
    setReleaselogsSession();
    hideMenu();
  }

  const deleteColumn = (columnAddr: Types.ColumnAddr, event: React.PointerEvent) => {
    event.stopPropagation();

    document.releaselogs.deleteColumn(columnAddr);
    
    columnsAutoAdjust({ debounced: false });
    document.saveUndo();

    resetSelected();
    hideMenu();
  }
  

  //---------------
  // Config

  const getConfig = () => {
    const isIdxColumn = document.releaselogs.isColumnIndexType(columnAddr);

    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('column, add'),
                iconComponent: Icon.Create,
                onClick: (event: React.PointerEvent) => addColumn(columnAddr, event),
              }
            },
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('column, properties'),
                iconComponent: Icon.Settings,
                onClick: (event: React.PointerEvent) => showColumnProperties(columnAddr, event),
              }
            },                
          ],
        },    
        {
          skip: isIdxColumn,
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('delete'),
                variant: 'delete',
                iconComponent: Icon.Delete,
                onClick: (event: React.PointerEvent) => deleteColumn(columnAddr, event),
              }
            },          
          ],
        },
      ]
    }

    return config;
  }


  const hook = () => {
    const config = getConfig();
    return config;
  }

  return hook;
}


export default useColumnMenu;