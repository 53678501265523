import { ThemeType        } from "./types";

import getMacOSXTheme        from './mac-osx/theme';
import getMacOSXAccentColors from './mac-osx/theme-accent-colors';


// import getGnomeDark           from './gnome/theme-dark';
// import getGnomeLight          from './gnome/theme-light';
// import getGnomeAccentColors   from './gnome/theme-accent-colors';


export namespace themes {

const getThemeAddr = (type: ThemeType) => {
  return `theme-${type}`;
}

const MACOSX_THEME_ADDR  = getThemeAddr(ThemeType.MACOSX);
const WINDOWS_THEME_ADDR = getThemeAddr(ThemeType.WINDOWS);

// const GNOME_LIGHT_ADDR = getThemeAddr(ThemeType.GNOME, ThemeVariantType.LIGHT);
// const GNOME_DARK_ADDR  = getThemeAddr(ThemeType.GNOME, ThemeVariantType.DARK);

const FALLBACK_THEME  = MACOSX_THEME_ADDR;


const THEMES = {
  [MACOSX_THEME_ADDR]: {
    getTheme: getMacOSXTheme,
    getAccentColors: getMacOSXAccentColors
  },

  // [WINDOWS_THEME_ADDR]: {
  //   getTheme: getWindowsTheme,
  //   // getAccentColors: getWindowsAccentColors
  // }

  // [GNOME_DARK_ADDR ]: {
  //   getTheme: getGnomeDark,
  //   getAccentColors: getGnomeAccentColors
  // }
};

const getThemeAddrWithFallback = (type: ThemeType) => {
  const addr = getThemeAddr(type);
  if ( ! (addr in THEMES) ) {
    console.warn(`Theme not found. Type: ${type}`);
    return FALLBACK_THEME;
  }

  return addr;
}

export const getThemeGenerator = (type: ThemeType) => {
  const addr = getThemeAddrWithFallback(type);
  return THEMES[addr].getTheme;
}

export const getThemeAccentColors = (type: ThemeType) => {
  const addr = getThemeAddrWithFallback(type);
  return THEMES[addr].getAccentColors;
}

} // ns themes