import React        from 'react';

import PanelItemComponent  from 'app-views/components/panel-item';
import { PanelItemConfig } from 'app-views/components/panel-item/config';
import { Instruction }     from 'app/arch/backend/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { IconDelete }   from './styles';
import { IconEdit }     from './styles';
import { IconSettings } from './styles';


interface Props {
  instruction: Instruction;
  onDocumentEdit?: () => void;
  onDocumentDelete?: () => void;
  onDocumentSelect?: () => void;
}


export const DocumentInfoItemComponent: React.FC<Props> = (props: Props) => {
  const {
    instruction,
    onDocumentEdit,
    onDocumentDelete,
    onDocumentSelect,
  } = props;

  const t = useTranslations();

  const handleInstructionEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDocumentEdit?.();
  }

  const handleInstructionSelect = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDocumentSelect?.();
  }

  const handleInstructionDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDocumentDelete?.();
  }


  const config: PanelItemConfig = {
    title: instruction.title,
    buttonsAutoHide: false,
    onClick: handleInstructionSelect,
    buttons: [
      {
        tooltip: t('document edit tooltip'),
        onClick: handleInstructionEdit,
        Icon: IconEdit
      },
      {
        tooltip: t('document delete tooltip'),
        onClick: handleInstructionDelete,
        Icon: IconDelete
      },
    ]
  }

  return (
    <PanelItemComponent
      config={config}
    />
  )
}
