class DPI {
  private static instance: DPI;

  private _ratio_in2px: number;
  private _ratio_mm2px: number;
  private _dpr: number;

  private constructor() {
    this._ratio_in2px = 0;
    this._ratio_mm2px = 0;
    this._dpr = 0;
  }

  set ratio_mm2px(ratio: number) { 
    // console.log(`DPI set mm2px ratio: ${ratio}`);
    this._ratio_mm2px = ratio; 
  }

  set ratio_in2px(ratio: number) { 
    // console.log(`DPI set in2px ratio: ${ratio}`);
    this._ratio_in2px = ratio; 
  }

  set dpr(dpr: number) { 
    // console.log(`DPR set: ${dpr}`);
    this._dpr = dpr; 
  }

  get ratio_mm2px() { 
    if (this._ratio_mm2px === 0) {
      const msg = `Ratio px2mm not set`;
      throw new Error(msg);
    }
    return this._ratio_mm2px; 
  }

  get ratio_in2px() { 
    if (this._ratio_in2px === 0) {
      const msg = `Ratio px2in not set`;
      throw new Error(msg);
    }
    return this._ratio_in2px; 
  }
  
  get dpr() { 
    if (this._dpr === 0) {
      const msg = `DPR not set`;
      throw new Error(msg);
    }
    return this._dpr; 
  }


  //--------------------------------------

  px2mm(px: number) { return px / this._ratio_mm2px; }
  mm2px(mm: number) { return mm * this._ratio_mm2px; }

  px2in(px: number)  { return px  / this.ratio_in2px; }
  in2px(in_: number) { return in_ * this.ratio_in2px; }

  any2px(value: number, units: string) {
    const fnMap: {[unit: string]: (value: number) => number} = {
      'in':     (value: number) => { return this.in2px(value); },
      'metric': (value: number) => { return this.mm2px(value); },
    }

    if (! (units in fnMap)) {
      const msg = `Wrong units (any2px): ${units}`;
      throw new Error(msg);
    }
    
    const fn = fnMap[units];
    return fn(value);
  }

  px2any = (value: number, units: string) => {
    const fnMap: {[unit: string]: (value: number) => number} = {
      'in':     (value: number) => { return this.px2in(value); },
      'metric': (value: number) => { return this.px2mm(value); },
    }

    if (! (units in fnMap)) {
      const msg = `Wrong units (px2any): ${units}`;
      throw new Error(msg);
    }
    
    const fn = fnMap[units];
    return fn(value);
  }


  public static getInstance(): DPI {
    if ( ! DPI.instance) {
      DPI.instance = new DPI();
    }
    return DPI.instance;
  }
}

export const _dpi = DPI.getInstance();