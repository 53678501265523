import { atom }     from 'recoil';
import { selector } from 'recoil';
import { ReposState as State } from 'app/arch/app/states/repos';


export namespace UIState_Repos {


export const state = atom<State.State>({
  key: "repos",
  default: State.createInitialState()
});


export const reposPacks = selector({
  key: `repos_reposPacks`,
  get: ({ get }) => {
    const stateObj = get(state) ;
    return State.getReposPacks(stateObj);
  }
});


} // namespace 

