import { UserSettingsTypes } from 'app/arch/app/user';
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"

import useStates    from './use-states';
import useFunctions from './use-functions';


const useItems = () => {
  const states = useStates();
  const fns = useFunctions();

  const getUserProp = (props: {
    title?: string, 
    key: keyof UserSettingsTypes.User
  }) => ({
    title: props.title,
    type: PanelControlsItemSectionItemType.INPUT,
    value: states.user[props.key] ?? "",
    onChangeDone: (value: string) => {
      fns.updateUser( { [props.key]: value } );
    }
  });

  const getCompanyProp = (props: {
    title?: string, 
    key: keyof UserSettingsTypes.Company
  }) => ({
    title: props.title,
    type: PanelControlsItemSectionItemType.INPUT,
    value: states.company[props.key] ?? "",
    onChangeDone: (value: string) => {
      fns.updateCompany( { [props.key]: value } );
    }
  });

  return {
    getUserProp,
    getCompanyProp,
  }
}

export default useItems;
