import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useDocState }     from "app/ui/contexts/document";
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";

import useWidgetLogger  from "./use-widget-logger";
import useWidgetContextMenuConfig from "../../hls/widgets/widgets/hooks/use-widget-context-menu-config";


const useWidgetContextMenu = (widgetAddr: ContentTypes.WidgetAddr) => {
  const document = useDocState();
  const logger   = useWidgetLogger();
  const getMenuConfig = useWidgetContextMenuConfig(widgetAddr);

  const {
    showMenu,
    hideMenu,
  } = useContextMenu();

  const showContextMenu = (props: {
    event: React.MouseEvent,
    widgetAddr: ContentTypes.WidgetAddr,
    editDisabled: boolean,
  }) => {
    const {
      event,
      widgetAddr,
      editDisabled,
    } = props;

    logger.debug(`Widget, show context menu`);

    if ( editDisabled ) {
      logger.debug(`Editing disable, skip show context menu`);
      return;
    }
    
    event.stopPropagation();

    const edited = document.editorImageSession.isWidgetEdited(widgetAddr);
    if (edited) {
      logger.debug(`Widget is edited, skip show context menu`);
      return;
    }

    const config = getMenuConfig();
    showMenu({
      config,
      event
    });
  }

  const hideContextMenu = () => {
    hideMenu();
  }

  return {
    showContextMenu,
    hideContextMenu,
  };
}

export default useWidgetContextMenu;