import styled from "styled-components";


const Bold = styled.div`
  display: inline;
  font-size: 12px;
  font-weight: 800;
`;


export default Bold;