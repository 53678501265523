import { ContentTools } from "../../../../document/states/persistent/content";
import { ContentTypes } from "../../../../document/states/persistent/content";
import RepoBase from "../repo-base";


class CellMarkersRepo extends RepoBase<ContentTypes.MarkerAddr> {
  getKey(markerAddr: ContentTypes.MarkerAddr): string {
    return ContentTools.getMarkerKey(markerAddr);
  };

  tag(): string {
    return "Markers";
  };
}


export default CellMarkersRepo;