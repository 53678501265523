import React from 'react';
import TooltipComponent from 'lego/components/tooltip';

import Icon from 'app/ui/icons/icons';
import useAppLogout from 'app/ui/hooks/app/use-app-logout';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { Button } from './styles';


interface Props {
}


export const SignOutBtnComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const appLogout = useAppLogout();

  const handleSignOut = () => {
    appLogout();
  }

  return (
    <TooltipComponent
      title={t("logout")}
    >
      <Button onClick={handleSignOut}>
        <Icon.Logout />
      </Button>
    </TooltipComponent>
  );
}
  
