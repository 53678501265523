import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Logger from 'libs/debug';
import urls from 'app/configs/urls';

import { ViewTypes } from 'app/arch/home-page-guest/states/view';
import useViewSelect from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-select';
import useViewParamsSet from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-params-set';
import useNavbarItemSelect from 'app/ui-v2/home-page/home-page-guest/hooks/use-navbar-item-select';
import { NavbarTypes } from 'app/arch/home-page-guest/states/navbar';
import useAppLogout from 'app/ui/hooks/app/use-app-logout';
import { useAuth } from 'services/auth';


interface Props {
}


export const RedirectUserPasswordResetComponent: React.FC<Props> = (props: Props) => {
  const params = useParams();
  const { 
    token, 
    email 
  } = params;

  const appLogout = useAppLogout();
  const auth = useAuth();

  const navigate = useNavigate();

  const selectView = useViewSelect();
  const selectNavbarItem = useNavbarItemSelect();
  const setViewParams = useViewParamsSet();


  useEffect(() => {
    const logger = Logger.getHomePage();
    const url = (urls.home);

    if ( ! token || ! email ) {
      logger.warn(`Invalid token: ${token} or email: ${email}`);
      navigate(url);
      return;
    }

    const __redirect = () => {
      setViewParams({
        email: email ! ,
        token: token !
      });
  
      selectView(ViewTypes.ViewItem.PASSWORD_RESET_FORM);
      selectNavbarItem(NavbarTypes.NavbarItem.USER_SIGNUP);
  
      logger.debug(`Redirecting to view ${ViewTypes.ViewItem.PASSWORD_RESET_FORM}`);
      navigate(url);
    }

    if ( auth.isLogged() ) {
      appLogout({
        onDone: () => {
          __redirect();
        }
      });
    }
    else {
      __redirect();
    }

  }, []);






  return null;
}
