import styled from "styled-components";
import TopToolbarSection from "app/ui-v2/editor-instruction/styles/top-toolbar-section";
import TopToolbarSeparator from "app/ui-v2/editor-instruction/styles/top-toolbar-separator";
import ButtonToolbarHorizontal from "lego/styles/button_toolbar_horizontal";


export const Section = styled(TopToolbarSection)<{$first?: boolean}>`
  border-right-style: solid;
  border-left-style: ${props => props.$first ? 'solid' : 'none'};
`;

export const Separator = styled(TopToolbarSeparator)`

`;

export const Button = styled(ButtonToolbarHorizontal)`
  border-style: none;
`;
