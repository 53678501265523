import { useState } from 'react';

import * as Types from '../../types';
import { Props as NavbarProps } from '../../navbar/Navbar';
import NavbarContextComponent from '../navbar-context';

import { Grid } from './styles';


interface Props extends NavbarProps {
  NavbarItem: React.ElementType;
}


export const NavbarComponent: React.FC<Props> = (props: Props) => {
  const { 
    config,
    NavbarItem,
  } = props;

  const items = config.items;

  const [
    openItemIdx, 
    setOpenItemIdx
  ] = useState<number | null>(null);
  
  const handleItemOpenToggle = (idx: number) => {
    if (openItemIdx === idx) {
      setOpenItemIdx(null);
      return;
    }

    setOpenItemIdx(idx);
  }

  const handleItemOpen = (idx: number) => {
    if (openItemIdx !== idx) {
      setOpenItemIdx(idx);
    }
  }

  const handleItemClose = (idx: number) => {
    if (openItemIdx === idx) {
      setOpenItemIdx(null);
    }
  }

  const renderItems = () => {
    const Items = items.map((item: Types.Item, idx: number) => {
      
      const flick = ! config.inColumn;
      const Remap  = (
        flick ? {
          col1: idx, 
          col2: idx, 
          row1 : 0, 
          row2 : 1, 
        }: {
          col1: 0, 
          col2: 1, 
          row1 : idx, 
          row2 : idx, 
        }
      );

      return (
        <NavbarContextComponent
          key={idx}
          openItem={ () => handleItemOpen(idx) }
          closeItem={ () => handleItemClose(idx) }
          toggleOpenItem={ () => handleItemOpenToggle(idx) }
        >
          <NavbarItem
            col1={Remap.col1}
            col2={Remap.col2}
            row1={Remap.row1}
            row2={Remap.row2}
            config={item}
            open={openItemIdx === idx}
          />
        </NavbarContextComponent>
      );
    });

    return Items;
  }


  return (
    <Grid 
      items={items.length} 
      inColumn={config.inColumn}
    >
      { renderItems() }
    </Grid>
  );
}
