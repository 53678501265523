import React from 'react';
import SideToolbarBaseComponent from 'lego/components/side-controls/side-toolbar';
import { SideControlsTypes } from 'lego/components/side-controls';

import useConfig from './config';


type Props = SideControlsTypes.PropLeftOrRight;


export const SideToolbarComponent: React.FC<Props> = (props: Props) => {
  const {
    left,
    right,
  } = props;

  const getConfig = useConfig();
  const config = getConfig();
  
  return (
    <SideToolbarBaseComponent
      config={config}
      left={left}
      right={right}
    />
  );
}

