import { useEffect, useRef } from 'react';

const BASE_SEGMENT = 100;

interface Props {
  units: string;
  onRatio: (ratio: number) => void;
};


const useProbeUnit = (props: Props, deps: any[]) => {
  const { 
    units, 
    onRatio, 
  } = props;
  
  const ratioRef = useRef<number | null>(null);

  useEffect(() => {
    const div = document.createElement('div');
    div.style.width    = `${BASE_SEGMENT}${units}`;
    div.style.height   = `${BASE_SEGMENT}${units}`;
    div.style.position = 'fixed';
    div.style.zIndex   = '-1';
    // div.style.top      = '-9999px';
    div.style.visibility = 'hidden';
    
    document.body.appendChild(div);
    const ratio = calculateRatio(div);
    div.remove();

    if (ratio !== ratioRef.current) {
      ratioRef.current = ratio;
      onRatio(ratio);
    }
  }, deps);

  const calculateRatio = (element: any) => {
    const bbox = element.getBoundingClientRect();
    const ratioWidth  = bbox.width  / BASE_SEGMENT;
    const ratioHeight = bbox.height / BASE_SEGMENT;

    if (ratioWidth !== ratioHeight) {
      console.warn(`Ratio width different than ratioHeight`);
    }

    return ratioHeight;
  }
};

export default useProbeUnit;