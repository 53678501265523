import styled from "styled-components";


const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${props => props.theme.defs.gap.normal};
`;


export default Buttons;