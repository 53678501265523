import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig }   from 'lego-v2/menu/config';

import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';

import { Icon }               from "app/ui/icons/icons";
import { useDocState }        from "app/ui/contexts/document";
import { useTranslations }    from "app/ui/hooks/app/use-translation";
import useContextMenu         from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import useRowDelete from './use-row-delete';


const useRowMenu = () => {
  const t = useTranslations();
  const document = useDocState();

  const {
    setReleaselogs,
  } = useEditorStatesSetters();

  const deleteRowHook = useRowDelete();

  const {
    hideMenu
  } = useContextMenu();

  const addRowAbove = (rowAddr: Types.RowAddr, event: React.PointerEvent) => {
    event.stopPropagation();

    document.releaselogs.addRowAbove(rowAddr);
    document.saveUndo();
    setReleaselogs();
    hideMenu();
  }

  const addRowBelow = (rowAddr: Types.RowAddr, event: React.PointerEvent) => {
    event.stopPropagation();

    document.releaselogs.addRowBelow(rowAddr);
    document.saveUndo();
    setReleaselogs();

    hideMenu();
  }

  const deleteRow = (rowAddr: Types.RowAddr, event: React.PointerEvent) => {
    event.stopPropagation();
    deleteRowHook(rowAddr);
    hideMenu();
  }
  

  //---------------
  // Config

  const getConfig = (rowAddr: Types.CellAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('row, add above'),
                iconComponent: Icon.Create,
                onClick: (event: React.PointerEvent) => addRowAbove(rowAddr, event),
              }
            },
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('row, add below'),
                iconComponent: Icon.Create,
                onClick: (event: React.PointerEvent) => addRowBelow(rowAddr, event),
              }
            },
          ],
        },    
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('delete'),
                variant: 'delete',
                iconComponent: Icon.Delete,
                onClick: (event: React.PointerEvent) => deleteRow(rowAddr, event),
              }
            },          
          ],
        },
      ]
    }

    return config;
  }


  const hook = (cellAddr: Types.CellAddr) => {
    const config = getConfig(cellAddr);
    return config;
  }

  return hook;
}


export default useRowMenu;