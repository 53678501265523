import styled from 'styled-components';


const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.normal};

  height: 100%;
  box-sizing: border-box;

  background: ${props => props.theme.menuBar.background};
  border-style: ${props => props.theme.panel.border.style};
  border-width: ${props => props.theme.panel.border.width};
  border-color: ${props => props.theme.panel.border.color};

  /* border-top-style: none; */
  border-left-style: none;
  border-right-style: none;
`;


export default TopBar;