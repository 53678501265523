import React from 'react';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ViewTitledComponent from 'app/ui-v2/home-page/hls/view-titled';

import LoginFormComponent   from './login-form';
import GoogleLoginComponent from './google-login';

import { Content } from './styles';


interface Props {
}


export const ViewUserLoginComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  return (
    <ViewTitledComponent 
      title={t("login to flexedit")} 
      Content={Content}
    >
      <LoginFormComponent />
      <GoogleLoginComponent />
    </ViewTitledComponent>
  );
}
