import React      from 'react';
import { useRef } from 'react';

import jtl from 'tools/jtl';

import { Position } from 'app/arch/types';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { ShapeCalcArrow  } from '../../shapes-calculators/shape-calc-arrow';
import ControlBoxComponent from '../controls/control-box';
import { FRAME_CONTROL_BOX_OFFSET } from '../styles/frame-control-box';
import { FRAME_CONTROL_BOX_SIZE   } from '../styles/frame-control-box';
import { FrameBaseProps } from '../types';

import { FrameWrapper } from './styles';


interface Props extends FrameBaseProps {
}


export const FrameArrowBodyComponent: React.FC<Props> = (props: Props) => {
  const { 
    widgetAddr,

    scale,

    widgetStyle,
    startPoint, 
    endPoint,
    tailSize,
  } = props;

  const document = useDocState();

  const {
    setContent,
  } = useEditorStatesSetters();

  const startDragValue  = useRef<any>(null);

  const frameCtrlBoxOffset = FRAME_CONTROL_BOX_OFFSET / scale;
  const frameCtrlBoxSize   = FRAME_CONTROL_BOX_SIZE   / scale;
  
  const shapeCalc = new ShapeCalcArrow({
    style: widgetStyle,
    startPoint,
    endPoint
  });

  const c = shapeCalc;

  const dx = endPoint[0] - startPoint[0];
  const dy = endPoint[1] - startPoint[1];
  const totalHeight = Math.sqrt(dx**2 + dy**2);

  const handleStyleChanged = (style: any) => {
    document.content.cellImages_image_widget_updateStyle(
      widgetAddr,
      style
    );
  }

  //-----------------
  // Ctrl positions
  //
  
  const getCtrlBodyCommonPosition = (
    sideLeft: boolean,
    _arrowHeadHeight: number,
    _arrowShaftWidth: number,
  ) => {
    const totalBorder = c.borderWidth + c.outlineWidth;
    const verticalBordersSize = c.borderWidthAngledVertical + c.outlineWidthAngledVertical + c.borderWidth + c.outlineWidth;
    const headDelta = _arrowHeadHeight + verticalBordersSize;
    const tailDelta = tailSize[1] / 2 + c.borderWidth + c.outlineWidth;
    const multiplier = sideLeft ? -1 : 1;


    const x = multiplier * (_arrowShaftWidth / 2 + totalBorder + frameCtrlBoxSize / 2 + frameCtrlBoxOffset);
    const y = (_arrowHeadHeight + verticalBordersSize) + (totalHeight - (headDelta + tailDelta)) / 2;
    
    const position = [x, y] as Position;
    return position;
  }

  const getCtrlBodyCommonPositionRev = (sideLeft: boolean, position: Position) => {
    const totalBorder = c.borderWidth + c.outlineWidth;
    const multiplier  = sideLeft ? -1 : 1;
    
    return {
      shaftWidth: (multiplier * position[0] - (frameCtrlBoxOffset + frameCtrlBoxSize / 2 + totalBorder)) * 2,
    };
  }

  const getCtrlBodyLeftPosition = (
    _arrowHeadHeight: number,
    _arrowShaftWidth: number,
  ) => {
    return getCtrlBodyCommonPosition(true, _arrowHeadHeight, _arrowShaftWidth);
  }

  const getCtrlBodyLeftPositionRev = (position: Position) => {
    return getCtrlBodyCommonPositionRev(true, position);
  }

  const getCtrlBodyRightPosition = (
    _arrowHeadHeight: number,
    _arrowShaftWidth: number,
  ) => {
    return getCtrlBodyCommonPosition(false, _arrowHeadHeight, _arrowShaftWidth);
  }

  const getCtrlBodyRightPositionRev = (position: Position) => {
    return getCtrlBodyCommonPositionRev(false, position);
  }

  //-----------
  // Dragging
  //

  const ctrl_handleDragStart = () => {
    const arrowHeadHeight = jtl.css.valueToNumber(widgetStyle.arrowHeadHeight);
    const arrowShaftWidth = jtl.css.valueToNumber(widgetStyle.arrowShaftWidth);

    const ctrlBodyLeft  = getCtrlBodyLeftPosition(arrowHeadHeight, arrowShaftWidth);
    const ctrlBodyRight = getCtrlBodyRightPosition(arrowHeadHeight, arrowShaftWidth);

    startDragValue.current = { 
      ctrlBodyLeft,
      ctrlBodyRight,
    };
  }
  
  const ctrl_handleDragStop = () => {
    document.saveUndo();
  }

  //---------------

  const ctrlBodyCommon_handleDrag = (    
    deltaMove: Position,
    ctrlStartPosition: Position,
    revCtrlPositionFn: any
) => {
    // As this move is taking place when arrow is already rotated,
    // we need to un-rotate it, to calculate delta move in (0, 0) plane.
    const radians = -1 * c.arrowAngle;
    const deltaMoveUnrotated = jtl.geometry.rotateVector(radians, deltaMove);

    const currentPosition = [
      ctrlStartPosition[0] + deltaMoveUnrotated[0],
      ctrlStartPosition[1] + deltaMoveUnrotated[1]
    ] as Position;

    const newProps = revCtrlPositionFn([
      currentPosition[0],
      currentPosition[1],
    ]);

    const width  = Math.max(0, newProps.shaftWidth);
    
    handleStyleChanged({ arrowShaftWidth:  `${width}px`});
    setContent();
  }

  const ctrlBodyLeft_handleDrag = (deltaMove: Position) => {
    ctrlBodyCommon_handleDrag(
      deltaMove,
      startDragValue.current.ctrlBodyLeft,
      getCtrlBodyLeftPositionRev
    );
  }

  const ctrlBodyRight_handleDrag = (deltaMove: Position) => {
    ctrlBodyCommon_handleDrag(
      deltaMove,
      startDragValue.current.ctrlBodyRight,
      getCtrlBodyRightPositionRev
    );
  }

  const ctrlBodyLeftPosition  = getCtrlBodyLeftPosition(c.arrowHeadHeight, c.arrowShaftWidth);
  const ctrlBodyRightPosition = getCtrlBodyRightPosition(c.arrowHeadHeight, c.arrowShaftWidth);

  return (
    <FrameWrapper
      style={{
        left: `${startPoint[0]}px`,
        top:  `${startPoint[1]}px`,
        transform: `rotate(${c.arrowAngle}rad)`,
        transformOrigin: `0px 0px`,
      }}
    >
      <ControlBoxComponent
        scale={scale}
        position={ctrlBodyLeftPosition}
        onDragStart={ctrl_handleDragStart}
        onDrag={ctrlBodyLeft_handleDrag}
        onDragStop={ctrl_handleDragStop}
      />

      <ControlBoxComponent
        scale={scale}
        position={ctrlBodyRightPosition}
        onDragStart={ctrl_handleDragStart}
        onDrag={ctrlBodyRight_handleDrag}
        onDragStop={ctrl_handleDragStop}
      />
    </FrameWrapper>
  );
}
