import produce from 'immer';
import jtl from 'tools/jtl';
import * as State from './state';
import * as Types from "./types"


export class Settings {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get raw() { return this._state; }

  reset() {
    this._state = State.createInitialState();
  }

  serialize() {
    const serial = jtl.serialize.serialize(this._state);  
    return serial;
  }


  /**
   * Setters
   */

  merge(updateSerial: string) {
    const deserial = jtl.serialize.deserialize(updateSerial);
    const newState = {
      ...this._state,
      ...deserial
    }
    this._state = newState;
  }

  updateUser(update: Types.UserUpdate) {
    this._state = produce(this._state, draft => {
      draft.user = {
        ...draft.user,
        ...update
      }
    });
  }

  updateCompany(update: Types.CompanyUpdate) {
    this._state = produce(this._state, draft => {
      draft.company = {
        ...draft.company,
        ...update
      }
    });
  }

  updateTheme(update: Types.ThemeUpdate) {
    this._state = produce(this._state, draft => {
      draft.theme = {
        ...draft.theme,
        ...update
      }
    });
  }

  updateRepo(update: Types.RepoUpdate) {
    this._state = produce(this._state, draft => {
      draft.repo = {
        ...draft.repo,
        ...update
      }
    });
  }


  /**
   * Getters
   */

  getUser() {
    return State.getUser(this._state);
  }

  getCompany() {
    return State.getCompany(this._state);
  }

  getTheme() {
    return State.getTheme(this._state);
  }

  getRepo() {
    return State.getRepo(this._state);
  }
}
