import React from 'react';
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useCustomRowMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-rows/row/use-custom-row-menu';
import { HeaderCellProps } from '../types';

import { MainWrapper } from './styles';


interface Props extends HeaderCellProps {
}


export const HeaderCellContextMenuComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageRowAddr,
    children
  } = props;

  const getMenuConfig = useCustomRowMenu();
  const { showMenu  } = useContextMenu();

  const handleContextMenu = (event: React.MouseEvent) => {
    const config = getMenuConfig(pageRowAddr);

    showMenu({
      event,
      config
    });
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
