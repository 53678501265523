import { createContext } from 'react';


export interface ContextProps {
  isRowLast: boolean;
};


const Context = createContext<ContextProps>({
  isRowLast: false,
});


export default Context;