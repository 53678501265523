import React from 'react';
import { useRecoilValue } from 'recoil';

import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';
import { UIState_Content } from 'app/ui/states/editor-instruction';

import { ContentItemProps } from '../types';
import SectionNameComponent from './section-name';
import CellsRowComponent    from './cells-row';


interface Props extends ContentItemProps {
}


export const SectionComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
  } = props;

  const section = item as SlicerTypes.ContentItem_Section;
  const sectionAddr = {
    sectionId: section.sectionId
  }

  // If section has been deleted, but content has not been 
  // resliced yet - leftover section will be still in DOM.
  // Therefore check if it does exists and skip displaying
  // it, if it does not.
  const sectionPresent = useRecoilValue(UIState_Content.sectionPresent(sectionAddr));

  if ( ! sectionPresent ) {
    return null;
  }

  return <SectionPresentComponent {...props} />;
}
  


export const SectionPresentComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
  } = props;

  const section = item as SlicerTypes.ContentItem_Section;

  const ItemsComponents: {[key in SlicerTypes.SectionItemType]: React.ElementType} = {
    [SlicerTypes.SectionItemType.SECTION_NAME]: SectionNameComponent,
    [SlicerTypes.SectionItemType.CELLS_ROW]:    CellsRowComponent,
  };

  const renderItems = () => {
    const Items = section.items.map((item, idx) => {
      const Comp = ItemsComponents[item.type];
      
      return (
        <Comp 
          key={idx}
          sectionId={section.sectionId}
          item={item} 
        />
      );
    });
    return Items;
  }

  return renderItems();
}
