import React from 'react';

import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/releaselogs/types';

import { PageItemsProps } from '../types';
import ReleaseInfoComponent from './release-info';


interface Props extends PageItemsProps {
}


export const PageItemReleaseInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
  } = props;
  
  const releaseInfo = item as SlicerTypes.PageItem_ReleaseInfo;
  const releaselogId = releaseInfo.releaselogId;

  return (
    <ReleaseInfoComponent
      releaselogId={releaselogId}
    />
  );
}
