import { UUID } from "app/arch/types";


/**
 * Content Section Items
 */

export enum SectionItemType {
  SECTION_NAME = 'section-name',
  CELLS_ROW    = 'cells-row',
}

export interface SectionItem {
  type: SectionItemType
}

export interface SectionItem_SectionName extends SectionItem {
  type: SectionItemType.SECTION_NAME
}


export interface SectionItem_CellsRow extends SectionItem {
  rowId: UUID,
  type: SectionItemType.CELLS_ROW,
}

export type SectionItems = (
    SectionItem_SectionName 
  | SectionItem_CellsRow
)[];




/**
 * Content Items
 */

export enum ContentItemType {
  SECTION       = 'section',
  SECTION_ADDER = 'section-adder',
  HEADER_ROW    = 'header-row',
}

export interface ContentItem {
  type: ContentItemType
}

export interface ContentItem_Section extends ContentItem {
  type: ContentItemType.SECTION,
  sectionId: UUID,
  items: SectionItems,
}

export interface ContentItem_SectionAdder extends ContentItem {
  type: ContentItemType.SECTION_ADDER,
}

export interface ContentItem_HeaderRow extends ContentItem {
  type: ContentItemType.HEADER_ROW
}

export type ContentItems = (
      ContentItem_HeaderRow
    | ContentItem_Section 
    | ContentItem_SectionAdder 
  )[];



/**
 * Page Items
 */

export enum PageItemType {
  DOC_INFO          = 'doc-info',
  DOC_CUSTOM_ROWS   = 'doc-custom-rows',
  DOC_CUSTOM_FIELDS = 'doc-custom-fields',
  DOC_MARKERS       = 'doc-markers',
  CONTENT           = 'content',
}

export interface PageItem {
  type: PageItemType,
}

export interface PageItem_DocInfo extends PageItem {
  type: PageItemType.DOC_INFO,
}

export interface PageItem_DocCustomRows extends PageItem {
  type: PageItemType.DOC_CUSTOM_ROWS,
}

export interface PageItem_DocCustomFields extends PageItem {
  type: PageItemType.DOC_CUSTOM_FIELDS,
}

export interface PageItem_DocMarkers extends PageItem {
  type: PageItemType.DOC_MARKERS,
}

export interface PageItem_Content extends PageItem {
  type: PageItemType.CONTENT,
  items: ContentItems
}

export type PageItems = (
    PageItem_DocInfo
  | PageItem_DocCustomRows
  | PageItem_DocCustomFields
  | PageItem_DocMarkers
  | PageItem_Content
)[];

export type Page = {
  items: PageItems,
}

export type Pages = Page[];

