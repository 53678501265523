import React from 'react';
import { IconContext } from 'react-icons';

import { SectionItem } from '../../types';
import useNavbarContext from '../../hooks/use-navbar-context';

import { Item }        from './styles';
import { IconWrapper } from './styles';
import { Text }        from './styles';
import { TextWrapper } from './styles';


interface Props {
  config: SectionItem;
}


export const NavbarPanelSectionItemComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
  } = props;

  const Icon = config.icon || React.Fragment;

  const {
    openItem,
    closeItem,
  } = useNavbarContext();

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    config.onClick?.(event);
    closeItem();
  }

  return (
    <Item 
      data-test={config.dataTest}
      onClick={handleClick}
    >

      <IconWrapper>
        <IconContext.Provider value={{ size: '15px' }} >
          <Icon />
        </IconContext.Provider>    
      </IconWrapper>

      <TextWrapper>
        <Text>
          { config.text }
        </Text> 
      </TextWrapper>

    </Item>
  );
}
