import styled from "styled-components";
import Scrollbar from "lego/styles/scrollbar";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;

  width: 100%;
  height: 100%;
  background: ${props => props.theme.defs.colors.background.primary};
`;

export const MarkerEditWrapper = styled.div`
  flex: 0 1 auto;
  min-height: 0px;

  /* 
  width: 100%;
  and align-self seems to do the same job
  */
  align-self: stretch;

  background: ${props => props.theme.defs.colors.background.forth};
  background: #FFF;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Scroller = styled(Scrollbar)`
  /* 
  max-height: 100%; 
  and align-self seems to do the same job
  */
  align-self: stretch;

  overflow: auto;

  box-sizing: border-box;
  padding: ${props => props.theme.defs.padding.normal};
  /* background: yellow; */
`;

export const Separator = styled.div`
  flex: 0 0 1px;
  width: 100%;
  background-color: ${props => props.theme.defs.colors.border.primary};
`;

export const SearchWrapper = styled.div`
  flex: 1 0 132px;
  width: 100%;

  padding: ${props => props.theme.defs.padding.normal};
  box-sizing: border-box;
`;
