import styled from "styled-components";
import DivButton from "./div_button";


const WindowButtonControl = styled(DivButton)`
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0;
  border-style:  none;

  border-left-color: ${props => props.theme.button.border.color.primary};
  border-left-width: ${props => props.theme.defs.border.width.normal};
  border-left-style: solid;
`;


export default WindowButtonControl;