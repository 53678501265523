import React from 'react';
import { useRecoilValue } from 'recoil';

import { RepoMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { UIState_RepoMarkers } from 'app/ui/states/editor-instruction';
import MarkerComponent from './marker';

import { Content }  from './styles';
import { Scroller } from './styles';


interface Props {
}


export const MarkersComponent: React.FC<Props> = (props: Props) => {
  const markersAddrs = useRecoilValue(UIState_RepoMarkers.markersAddrs);

  const renderMarkers = () => {
    const markersComps = markersAddrs.map((markerAddr) => {
      const markerKey = RepoMarkersTools.getMarkerKey(markerAddr);
      
      return (
        <MarkerComponent 
          key={markerKey}
          markerAddr={markerAddr} 
        />
      );
    });

    return markersComps;
  }

  return (
    <Scroller>
      <Content>
      { renderMarkers() }
      </Content>
    </Scroller>
  );
};


export default MarkersComponent;