import React from 'react';
import useDocCreateDemo1 from 'app/ui-v2/editor-instruction/hooks/use-doc-create-demo1';
import RedirectRunDemoBaseComponent from '../parts/redirect-run-demo-base';


interface Props {
}


export const RedirectRunDemoComponent: React.FC<Props> = (props: Props) => {
  const createDemoDoc = useDocCreateDemo1();

  const handleDone = () => {
    createDemoDoc();
  }

  return (
    <RedirectRunDemoBaseComponent 
      onDone={handleDone}
    />
  );
}
