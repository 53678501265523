import { HeaderRowsTypes } from "app/arch/editor-instruction/document/states/persistent/header-rows";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";
import { useTranslations } from "app/ui/hooks/app/use-translation";


const useFormatVer1_1 = () => {
  const docState = useDocState();
  const t = useTranslations();

  const {
    setHeaderRows,
    setMetaData,
  } = useEditorStatesSetters();

  // Create and populate default HeaderRows
  //
  const __fixRowHeaders = () => {
    const headerRows = docState.headerRows;
    headerRows.reset();

    headerRows.addRow(
      HeaderRowsTypes.RowType.TEXT, { 
        name: t("custom rows, default name"),
        width: 180,
      }
    );

    setHeaderRows();
  }

  const __fixContentVersion = () => {
    const metaData = docState.metaData;
    metaData.setFormatVersion(1.1);

    setMetaData();
  }

  const hook = () => {
    console.log("Formatting content to ver 1.1");
    __fixRowHeaders();
    __fixContentVersion();
  }

  return hook;
}


export default useFormatVer1_1;