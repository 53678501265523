import styled from "styled-components";
import ButtonColorBase from "lego/styles/button_color";


export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  justify-content: space-evenly;
  align-items: space-evenly;
`;

export const ButtonColor = styled(ButtonColorBase)`
`;
