import React from 'react';
import SpacerProbeComponent from './spacer-probe';
import { Spacer } from './styles';


interface Props {
}


export const SpacerComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <SpacerProbeComponent>
      <Spacer />
    </SpacerProbeComponent>
  );
}
  
