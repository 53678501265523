import styled from "styled-components";


export const Input = styled.input`
  position: fixed;
  top:  -9999999px;
  left: -9999999px;

  visibility: hidden;
`;
