import { useRef }  from 'react';
import { useEffect }  from 'react';

import { query } from 'app/arch/backend';
import { useQueryManual } from 'app/arch/backend/use-query-manual';
import { ReposTypes } from 'app/arch/app/states/repos';


export type OnDone = (repo: ReposTypes.RepoPackRaw) => void;
export type OnError = (error: string) => void;


export interface HookProps {
  repoId: number,
  onDone?: OnDone,
  onError?: OnError,
}

const useRepoFetch = () => {
  const onDoneRef  = useRef<OnDone | null>( null );
  const onErrorRef = useRef<OnError | null>( null );

  const { 
    fetch,
    data: response,
    error,
  } = useQueryManual({
    query: query.getRepoMembers(),
  });

  useEffect(() => {
    if (! response) {
      return;
    }

    const repo = response.repo;
    onDoneRef.current?.(repo);
    
    reset();
  }, [response]);

  useEffect(() => {
    if ( ! error) {
      return;
    }

    onErrorRef.current?.(error.message);
    reset();
  }, [error]);

  const reset = () => {
    onDoneRef.current = null;
    onErrorRef.current = null;
  }

  const hook = (props: HookProps) => {
    const repoId = props.repoId;
    onDoneRef.current  = props.onDone || null;
    onErrorRef.current = props.onError || null;

    fetch({
      variables: { repoId }
    });
  }

  return hook;
}

export default useRepoFetch;
