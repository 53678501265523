import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { UploadFileType }  from 'app/ui/hooks/editor-instruction/use-upload-files';
import { useUploadFiles }  from 'app/ui/hooks/editor-instruction/use-upload-files';
import { useDocState }     from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


type OnDone = (images: RepoImagesTypes.ImagesRaw) => void;
type OnError = (error: string) => void;


export interface HookProps {
  files: File[],
  onDone?: OnDone,
  onError?: OnError,
}


const useRepoImagesUpload = () => {
  const docState = useDocState();
  const uploadImages = useUploadFiles(UploadFileType.IMAGES);

  const {
    setRepoImagesSession
  } = useEditorStatesSetters();


  const uploadRepoImages = ({
    files,
    onDone,
    onError
  }: HookProps) => {
    docState.repoImagesSession.addImagesUploading(files.length);
    setRepoImagesSession();
    
    const resetLoadingProgress = () => {
      docState.repoImagesSession.removeImagesUploading(files.length);
      setRepoImagesSession();
    }

    uploadImages({
      files, 
      onDone: (
        ok: boolean, 
        data: any,
      ) => {
        if ( ! ok ) {
          resetLoadingProgress();

          console.warn(`Images could not be uploaded`);
          onError?.("Images upload failed");
          return;
        }
  
        resetLoadingProgress();
        onDone?.(data.images);
      },
      onError: (error: string) => {
        console.error(error);

        resetLoadingProgress();
        onError?.(error);
      }
    });
  }

  return uploadRepoImages;
}


export default useRepoImagesUpload;