import * as Types from './types';

export const getSectionName = (): Types.SectionName => ({
  height: 0
});

export const getSectionRow = (): Types.SectionRow => ({
  height: 0
});

export const getSectionsRows = (): Types.SectionRows => ({
});

export const getSection = (): Types.Section => ({
  sectionName: getSectionName(),
  sectionRows: getSectionsRows()
});


export const getSections = (): Types.Sections => ({
});
