import styled from "styled-components";
import ButtonGroupBase from "./button_group_base";


export const ButtonGroupRight = styled(ButtonGroupBase)`
  border-radius: 
    0
    ${props => props.theme.button.border.radius}
    ${props => props.theme.button.border.radius}
    0
  ;
`;

export default ButtonGroupRight;