import React from 'react';
import { CellBaseProps } from '../types';

import useReleaselogIsEditable       from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-releaselog-is-editable';
import CellOverflowClipperComponent  from '../parts/cell-overflow-clipper';
import CellsLineSelectedComponent    from '../parts/cells-line-selected';
import CellIndexColumnCssComponent   from './cell-index-column-css';
import CellIndexContextMenuComponent from './cell-index-context-menu';
import CellIndexDraggerComponent     from './cell-index-dragger';
import CellIndexDropComponent        from './cell-index-drop';
import CellIndexSelectComponent      from './cell-index-select';
import CellIndexValueComponent       from './cell-index-value';


interface Props extends CellBaseProps {
}


export const CellIndexComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;

  return (
    <CellOverflowClipperComponent>
      <CellIndexActivePartsComponent cellAddr={cellAddr} >
        <CellIndexColumnCssComponent cellAddr={cellAddr} >
          <CellIndexValueComponent cellAddr={cellAddr} />
        </CellIndexColumnCssComponent>
      </CellIndexActivePartsComponent>
    </CellOverflowClipperComponent>
  );
}



/**
 * 
 * Active parts of cell.
 * This parts should be disabled, when
 * given releaselog is not editable.
 * 
 */

interface CellIndexActivePartsProps extends Props {
  children: React.ReactNode;
}


const CellIndexActivePartsComponent: React.FC<CellIndexActivePartsProps> = (props: CellIndexActivePartsProps) => {
  const { 
    cellAddr,
    children,
  } = props; 
  
  const isEditable = useReleaselogIsEditable(cellAddr);

  if ( ! isEditable ) {
    return children;
  }

  return (
    <CellIndexSelectComponent cellAddr={cellAddr} >
      <CellsLineSelectedComponent cellAddr={cellAddr} >
        <CellIndexContextMenuComponent cellAddr={cellAddr} >
          <CellIndexDropComponent cellAddr={cellAddr} >
            <CellIndexDraggerComponent cellAddr={cellAddr} >
              { children }
            </CellIndexDraggerComponent>
          </CellIndexDropComponent>   
        </CellIndexContextMenuComponent>
      </CellsLineSelectedComponent>
    </CellIndexSelectComponent>
  );
}
