import jtl from "tools/jtl";
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config";

import { MarkerAddr } from "app/arch/editor-instruction/document/states/persistent/repo-markers/types";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import PanelItemGenerator from "app/ui-v2/editor-instruction/panel-items/generator";

import Config from "../config";
import useItemsCommon from '../common/use-items';
import useStates from "./__use-states";
import useFunctions from "./__use-functions";


const useItems = (markerAddr: MarkerAddr) => {
  const t = useTranslations();

  const itemsCommon = useItemsCommon(markerAddr);
  const states = useStates(markerAddr);
  const fns = useFunctions(markerAddr);


  /**
   * Icon
   */
  const getIconSize = () => ({
    title: t('size'),
    dataTest: "marker-icon-size",
    type: PanelControlsItemSectionItemType.SLIDER,
    min: 12,
    max: 80,
    value: states.markerProps.icon.size,
    onChange: (iconSize: number) => { 
      const update = {size: iconSize};
      fns.updateIcon({update, skipUndoSave: true});
    },
    onChangeDone:  (iconSize: number) => { 
      const update = {size: iconSize};
      fns.updateIcon({update});
    },
  });

  const getIconColor = () => ({
    title: t('color'),
    dataTest: "marker-icon-color",
    color: jtl.css.getValue(states.markerProps.icon.css, 'color'),
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: (color: string) => { 
      const cssUpdate = { color };
      fns.updateIconCSS({cssUpdate});
    },
  });

  const getIconBackgroundColor = () => ({
    title: t('background color'),
    dataTest: "marker-icon-background-color",
    color: jtl.css.getValue(states.markerProps.icon.css, 'backgroundColor'),
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: (backgroundColor: string) => { 
      const cssUpdate = { backgroundColor };
      fns.updateIconCSS({cssUpdate});
    },
  });

  const getIconPadding = () => PanelItemGenerator.getPadding({
    css: states.markerProps.icon.css,
    minValue: 0,
    maxValue: 220,
    onChange: (cssUpdate: React.CSSProperties) => {
      fns.updateIconCSS({
        cssUpdate, 
        skipUndoSave: true
      });
    },
    onChangeDone: (cssUpdate: React.CSSProperties) => {
      fns.updateIconCSS({ cssUpdate });
    },
  });


  /**
   * Border
   */
  const getBorderEnabled = () => PanelItemGenerator.getToggleOnOff({
    selected: states.borderEnabled,
    onClick: (enabled: boolean) => {
      const borderStyle = (
        enabled ?
        'solid' : 
        'none'
      );

      const cssUpdate =  { borderStyle };
      fns.updateIconCSS({cssUpdate});
    },
  });

  const getBorderColor = () => ({
    title: t('color'),
    skip: ! states.borderEnabled,
    dataTest: "marker-icon-border-color",
    color: jtl.css.getValue(states.markerProps.icon.css, 'borderColor'),
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: (borderColor: string) => { 
      const cssUpdate = { borderColor };
      fns.updateIconCSS({cssUpdate});
    },
  });

  const getBorderWidth = () => PanelItemGenerator.getBorderWidth({
    css: states.markerProps.icon.css,
    skip: ! states.borderEnabled,
    minValue: Config.border.width.min,
    maxValue: Config.border.width.max,
    onChange: (cssUpdate: React.CSSProperties) => {
      fns.updateIconCSS({
        cssUpdate, 
        skipUndoSave: true
      });
    },
    onChangeDone: (cssUpdate: React.CSSProperties) => {
      fns.updateIconCSS({ cssUpdate });
    },
  });

  const getBorderRadius = () => PanelItemGenerator.getBorderRadius({
    css: states.markerProps.icon.css,
    minValue: 0, 
    maxValue: 50,
    onChange: (cssUpdate: React.CSSProperties) => {
      fns.updateIconCSS({
        cssUpdate, 
        skipUndoSave: true
      });
    },
    onChangeDone: (cssUpdate: React.CSSProperties) => {
      fns.updateIconCSS({ cssUpdate });
    },
  });


  return {
    ...itemsCommon,
    getIconSize,
    getIconColor,
    getIconBackgroundColor,
    getIconPadding,

    getBorderEnabled,
    getBorderColor,
    getBorderWidth,
    getBorderRadius,
  }
}

export default useItems;
