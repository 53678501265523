import { UUID } from "app/arch/types";



/**
 * PageColumn
 */
export type PageRowAddr = RowAddr & {
  pageIdx: number;
}

export type PageCellAddr = CellAddr & {
  pageIdx: number;
}



/**
 * Columns
 */
export type ColumnIdx = 0 | 1;


export interface ColumnProps {
  width: number;
}

export type ColumnsProps = [ColumnProps, ColumnProps];

export type Columns = {
  props: ColumnsProps,
}

export type ColumnPropsUpdate = (
  Partial<ColumnProps>);


/**
 * Row
 */
export enum RowType {
  TEXT  = "text",
}

export type RowAddr = {
  rowId: UUID;
}

export type RowProps = {
  type: RowType;
  name: string;
  width: number;

  css: React.CSSProperties;
  header: {
    css: React.CSSProperties;
  }
}

export type RowPropsUpdate = (
  Partial< 
    Omit< 
      RowProps, "type" | "header"
    > 
  >);


/**
 * Columns
 */
export type RowsAddrs = RowAddr[];

export type RowsProps = {
  [rowKey: string]: RowProps
}

export type Rows = {
  addrs: RowsAddrs,
  props: RowsProps,
}


// /**
//  * PageColumn
//  */
// export type PageColumnAddr = RowAddr & {
//   pageIdx: number;
// }


/**
 * Cell
 */
export interface Cell {
}

//
// Text Cell
//
export interface TextCell extends Cell {
  text: string | null;
}

//
// Text Cell (end)
//

export type CellTypes = (
  TextCell 
)

export type CellAddr = {
  rowId: UUID;
}

/**
 * Cells
 */
export type Cells = {
  [cellKey: string]: CellTypes
}



/**
 * Content
 */
export type Content = {
  rows: Rows;
  cells: Cells;
  columns: Columns;
}
