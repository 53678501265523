import { useApp } from "app/ui/hooks/app/use-app";


const useInfoState = () => {
  const app = useApp();
  const info = app.info;

  return info;
}


export default useInfoState;