import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";
import useState from './__use-state';


const useFunctions = () => {
  const document = useDocState();
  const state = useState();

  const {
    setContent,
  } = useEditorStatesSetters();


  const __handleStyleChanged = (style: any) => {
    if (state.widgetAddr === null) return;

    document.content.cellImages_image_widget_updateStyle(
      state.widgetAddr,
      style
    );
    setContent();
  }


  /**
   * Fill
   */

  const setFillColor = (color: string) => {
    __handleStyleChanged({ backgroundColor: color })
    document.saveUndo();
  }


  /**
   * Border
   */

  const setBorderColor = (color: string) => {
    __handleStyleChanged({ borderColor: color })
    document.saveUndo();
  }

  const setBorderWidthChange = (width: number) => {
    __handleStyleChanged({ borderWidth: `${width}px` });
  }

  const setBorderWidthChangeDone = (width: number) => {
    setBorderWidthChange(width);
    document.saveUndo();
  }

  const setBorderRadiusChange = (radius: string) => {
    __handleStyleChanged({ borderRadius: `${radius}px` });
  }

  const setBorderRadiusChangeDone = (radius: string) => {
    setBorderRadiusChange(radius);
    document.saveUndo();
  }


  /**
   * Outline
   */

  const setOutlineColor = (color: string) => {
    __handleStyleChanged({ outlineColor: color })
    document.saveUndo();
  }

  const setOutlineWidthChange = (width: number) => {
    __handleStyleChanged({ outlineWidth: `${width}px` });
  }
  
  const setOutlineWidthChangeDone = (width: number) => {
    setOutlineWidthChange(width);
    document.saveUndo();
  }

  
  return {
    setFillColor,
    
    setBorderColor,
    setBorderWidthChange,
    setBorderWidthChangeDone,
    setBorderRadiusChange,
    setBorderRadiusChangeDone,

    setOutlineColor,
    setOutlineWidthChange,
    setOutlineWidthChangeDone,
  }
}


export default useFunctions;