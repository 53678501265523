import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { UserSubscriptionsState as State } from 'app/arch/app/user';
import { UserSubscriptionsTypes as Types } from 'app/arch/app/user';


export const state = atom<State.State>({
  key: "userSubscriptions",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const subscriptionsAddrs = selector({
  key: `userSubscriptions_subscriptionsAddrs`,
  get: ({ get }) => {
    const stateObj = get(state) ;
    return State.getSubscriptionsAddrs(stateObj);
  }
});

export const subscriptionProps = selectorFamily({
  key: "userSubscriptions_subscriptionProps",
  get: (subscriptionAddr: Types.SubscriptionAddr) => ({ get }) => {
    const stateObj = get(state);
    const subProps = State.getSubscriptionProps(stateObj, subscriptionAddr);
    return subProps;
  }
});
