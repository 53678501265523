import React from 'react';
import { MainWrapper } from './styles';


interface Props {
  editDisabled: boolean;
  children: React.ReactNode;
}

// Need to cancel pointerDown
// as it would be servered by underling
// widget reset selected.
export const WidgetClickCancelerComponent: React.FC<Props> = (props: Props) => {
  const {
    editDisabled,
    children
  } = props;

  const handlePointerDown = (event: React.PointerEvent) => {
    event.stopPropagation();
  }

  if (editDisabled) {
    return children;
  }

  return (
    <MainWrapper onPointerDown={handlePointerDown}>
      { children }
    </MainWrapper>
  );
}
  
