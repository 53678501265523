import React from 'react';
import HLS_ViewPricingV2Component from 'app/ui-v2/home-page/hls/view-pricing-v2';


interface Props {
}


export const ViewPricingComponent: React.FC<Props> = (props: Props) => {
  return <HLS_ViewPricingV2Component />;
}

