import environment from 'app/environment';
import { useInstruction } from 'app/ui/hooks/editor-instruction/use-instruction';
import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';


const useImageUrlGet = () => {
  const instruction = useInstruction();

  const hook = (image: RepoImagesTypes.Image) => {
    const imageUrl = environment.backend.getInstructionImageUrl(
      instruction.repoId !,
      instruction.id !,
      image.filename
    );

    return imageUrl;
  }
  
  return hook;
}


export default useImageUrlGet;
