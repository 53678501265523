import React from 'react';
import { Content } from './styles';


interface Props {
  children: React.ReactNode;
}


export const PricePlanContentComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;


  return (
    <Content>
      { children }
    </Content>
  );
}

