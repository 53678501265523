import React from 'react';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ViewPanelComponent  from 'app/ui-v2/home-page/hls/view-panel';

import LegalTextContentComponent from './legal-text-content';
import * as Types from './types';

import { Content } from './styles';


interface Props {
  title: string;
  content: Types.Content;
}


export const ViewLegalBaseComponent: React.FC<Props> = (props: Props) => {
  const { 
    title,
    content,
  } = props;

  const t = useTranslations();
  
  return (
    <ViewPanelComponent 
      title={title}
      Content={Content}
    >
      <LegalTextContentComponent 
        content={content}
      />
    </ViewPanelComponent>
  );
}
