import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import MarginsComponent from './margins';
import { MainWrapper } from "./styles";
import { MarginsWrapper } from "./styles";


interface Props {
  widgetAddr: ContentTypes.WidgetAddr;
  editDisabled: boolean;
  children: React.ReactNode;
}


export const WidgetEditorTextMarginsComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
    editDisabled,
    children,
  } = props;

  if (editDisabled) {
    return children;
  }

  return (
    <MainWrapper>
      <MarginsWrapper>
        <MarginsComponent widgetAddr={widgetAddr}/>
      </MarginsWrapper>
      
      { children }
    </MainWrapper>
  );
}
  
