import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { ReposTypes } from 'app/arch/app/states/repos';
import useRepos      from 'app/ui-v2/app/hooks/use-repos';
import useReposFetch from 'app/ui-v2/app/hooks/use-repos-fetch';
import useAppStateSetters from 'app/ui-v2/app/hooks/use-app-state-setters';

import { LoadersTools } from '..';
import LoaderAuthBaseComponent from '../base/loader-auth-base';
import { LoaderCoreProps }     from '../base/loader-auth-base/types';


interface Props {
  children: React.ReactNode;
}


export const LoaderReposComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  return (
    <LoaderAuthBaseComponent
      debugTitle={"repos"}
      LoaderCoreAuthComponent={LoaderCoreAuth}
      LoaderCoreUnauthComponent={LoaderCoreUnauth}
    >
      { children }
    </LoaderAuthBaseComponent>
  );
}


/**
 * Repos Authenticated
 */
const LoaderCoreAuth: React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const {
    children 
  } = props;

  const logger = LoadersTools.getLogger();

  const [ready, setReady] = useState(false);
  const repos = useRepos();
  const {
    setRepos
  } = useAppStateSetters();

  logger.debug(`Loader repos render, ready: ${ready}`);
  
  const onDone = (reposPack: ReposTypes.ReposPacksRaw) => {
    logger.debug(`Loader repos - repos loaded`);

    if (reposPack.length === 0) {
      const msg = `No single repos available`;
      throw new Error(msg);
    }

    repos.loadReposPacks(reposPack);
    setRepos();

    setReady(true);
  }

  const onError = (error: string) => {
    console.error(`Can't donwload repos, error: ${error}`);
  }

  useReposFetch({
    onDone,
    onError
  });


  if ( ! ready ) {
    return null;
  }

  return children;
}


/**
 * Repos Unauthenticated
 */
const LoaderCoreUnauth:  React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const {
    children 
  } = props;

  const logger = LoadersTools.getLogger();
  logger.debug(`Loader repos - render, unauth`);

  const repos = useRepos();
  const {
    setRepos
  } = useAppStateSetters();

  useEffect(() => {
    logger.debug(`Loader repos - reset repos`);
    repos.reset();
    setRepos();
  }, []);

  return children;
}
