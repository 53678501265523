import React from 'react';
import { useRecoilValue } from 'recoil';

import { useDocState } from 'app/ui/contexts/document';
import HLS_NameEditComponent from 'app/ui-v2/editor-instruction/views/shared/components/name-edit';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_HeaderRows } from 'app/ui/states/editor-instruction';

import InputStyledComponent from './input-styled';
import { HeaderCellProps } from '../types';


interface Props extends HeaderCellProps {
}


export const HeaderCellNameEditComponent: React.FC<Props> = (props: Props) => {
  const {
    pageRowAddr,
    children,
  } = props;

  const docState = useDocState();
  const rowName = useRecoilValue(UIState_HeaderRows.rowName(pageRowAddr));

  const { 
    setHeaderRows,
  } = useEditorStatesSetters();

  const handleRowSelected = () => {
  }

  const handleRowNameEditDone = (rowName: string) => {
    const update = {
      name: rowName
    };
    
    docState.headerRows.updateRow(
      pageRowAddr, 
      update
    );

    setHeaderRows();
    docState.saveUndo();
  }

  return (
    <HLS_NameEditComponent
      name={rowName}
      onEditStart={handleRowSelected}
      onEditDone={handleRowNameEditDone}
      Input={InputStyledComponent}
      inputProps={{pageRowAddr}}
    >
      { children }
    </HLS_NameEditComponent>
  );
}

