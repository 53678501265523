import React from 'react';
import Context from './context';
import { ContextProps } from './context';


interface Props extends ContextProps {
  children: React.ReactNode;
}


export const NavbarItemContextComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
    ...contextProps
  } = props;

  return (
    <Context.Provider value={contextProps}>
      { children }
    </Context.Provider>
  );
}

