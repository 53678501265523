import styled from "styled-components";


const ContentItemBorder = styled.div`
  box-sizing: border-box;

  border-width: 1px;
  border-style: solid;
  border-color: black;
  border-bottom-style: none;
`;


export default ContentItemBorder;