import { ShapeProps } from "../../types";
import ShapeBaseComponent from "../shape-base";
import { ShapeCalcTriangle } from "../../shapes-calculators/shape-calc-triangle";


interface Props extends ShapeProps {
}


export const ShapeTriangleComponent: React.FC<Props> = (props: Props) => {
  const {
    startPoint, 
    endPoint,
    style,
  } = props;

  const shapeCalc = new ShapeCalcTriangle({
    style,
    startPoint,
    endPoint
  });
  const c = shapeCalc;


  const offset1 = c.outlineWidthAngledVertical;
  const offset2 = c.outlineWidthAngledVertical + c.borderWidthAngledVertical;

  const points = [
    [
      `50% 0px`,

      `calc(50% + ${c.arrowHeadWidth / 2 + c.borderWidthAngledHorizontal + c.outlineWidthAngledHorizontal}px) 100%`,
      `calc(50% - ${c.arrowHeadWidth / 2 + c.borderWidthAngledHorizontal + c.outlineWidthAngledHorizontal}px) 100%`,
    ],
    [
      `50% ${offset1}px`,

      `calc(50% + ${c.arrowHeadWidth / 2 + c.borderWidthAngledHorizontal}px) calc(100% - ${c.outlineWidth}px)`, 
      `calc(50% - ${c.arrowHeadWidth / 2 + c.borderWidthAngledHorizontal}px) calc(100% - ${c.outlineWidth}px)`, 
    ],
    [
      `50% ${offset2}px`,

      `calc(50% + ${c.arrowHeadWidth / 2}px) calc(100% - ${c.borderWidth + c.outlineWidth}px)`, 
      `calc(50% - ${c.arrowHeadWidth / 2}px) calc(100% - ${c.borderWidth + c.outlineWidth}px)`, 
    ]
  ];
  
  return (
    <ShapeBaseComponent
      {...props}
      width={c.widthTotal}
      height={c.heightTotal}
      points={points}
    />
  );
}
