import React from 'react';
import { useEffect } from 'react';
import { useIsMobile } from 'lego-hooks/use-is-mobile';
import useScreenKeyboardAdjuster from 'app/ui-v2/app/__modules/screen/hooks/use-screen-keyboard-adjuster';


interface Props {
}


export const MobileKeyboardWatcherComponent: React.FC<Props> = (props: Props) => {
  const isMobile = useIsMobile();

  if ( ! isMobile ) {
    return null;
  }

  return <Component />;
}



const Component: React.FC<Props> = (props: Props) => {
  const keyboardAdjuster = useScreenKeyboardAdjuster();

  useEffect(() => {
    __adjust();
  }, []);

  useEffect(() => {
    __regHandler();

    return () => {
      __unregHandler();
    }
  }, []);

  const __regHandler = () => {
    window.addEventListener('resize', adjustViewport);
    // const win = window as any;
    // win.visualViewport.addEventListener('resize', adjustViewport);
  }

  const __unregHandler = () => {
    window.removeEventListener('resize', adjustViewport);
    // const win = window as any;
    // win.visualViewport.removeEventListener('resize', adjustViewport);
  }

  const  __adjust = () => {
    const ok = (window.visualViewport && 'height' in window.visualViewport);
    if ( ! ok ) {
      return;
    }
  
    // const vh = window.innerHeight * 0.01;
    const win = window as any;
    const vh = win.visualViewport.height * 0.01;
    // console.debug("Adjust view height to: ", win.visualViewport.height);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // document.documentElement.style.setProperty('--vh', `${0.5}vh`);
  }
  
  const  adjustViewport = () => {
    // if ( ! keyboardAdjuster.isEnabled() ) {
    //   return;
    // }
    __adjust();
  }


  return null;
}
