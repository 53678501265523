import produce from 'immer';

import * as Defaults from './defaults';
import * as State from './state';
import * as Tools from './tools';
import * as Types from './types';


class Relogin {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */

  setVisible(visible: boolean) {
    this._state = produce(this._state, draft => {
      draft.visible = visible;
    });
  }


  /**
   * 
   * Getters
   * 
   */

  getVisible(): boolean { 
    return State.getVisible(this._state);
  }
}

export default Relogin;