import * as log from 'loglevel';


export namespace Logger {

  export const init = () => {
    log.disableAll();

    // This one has to be disable 
    // here as it is statically instatiated
    // before `init` is called. Therefore
    // it inheriate properties from root logger
    // before root logger got disabled.

    // getDocUndo().setLevel(log.levels.INFO);
    getChangelogSlicer().disableAll();

    getContentCustomFieldsState().disableAll();
    getContentElementsSizes().disableAll();
    getContentSlicer().disableAll();
    getContentState().disableAll();
    getContentTrashbin().disableAll();

    getDocState().disableAll();
    getDocRepos().disableAll();
    getDocTemplates().disableAll();
    getDocument().disableAll();
    getDocUndo().disableAll();

    getEditorImage().disableAll();
    getEditorImageSessionState().disableAll();
    getEditorImageWidget().disableAll();
    getEditorImageWidgetArrow().disableAll();
    getEditorImageWidgetText().disableAll();

    getEditorKeyBinding().disableAll();
    
    getI18next().disableAll();
    getLexicalTools().disableAll();
    getProbes().disableAll();

    getHomePage().disableAll();
    getHomePageView().disableAll();

    getPayment().disableAll();

    getAppLimits().disableAll();
    getAppLoaders().disableAll();
    getAppProducts().disableAll();
    getEditorDraggerMouse().disableAll();
    getEditorDraggerTouch().disableAll();

    getDocImgsLoaded().enableAll();
  }


  export const getChangelogSlicer = () => log.getLogger("changelog:slicer");

  export const getContentCustomFieldsState = () => log.getLogger("content:customfields:state");
  export const getContentElementsSizes     = () => log.getLogger("content:elementsSizes");
  export const getContentSlicer            = () => log.getLogger("content:slicer");
  export const getContentState             = () => log.getLogger("content:state");
  export const getContentTrashbin          = () => log.getLogger("content:trashbin");

  export const getDocState      = () => log.getLogger("doc:state");
  export const getDocRepos      = () => log.getLogger("doc:repos");
  export const getDocTemplates  = () => log.getLogger("doc:template");
  export const getDocument      = () => log.getLogger("doc");
  export const getDocUndo       = () => log.getLogger("doc:undo");
  export const getDocImgsLoaded = () => log.getLogger("doc:imgs:loaded");

  export const getEditorImage             = () => log.getLogger("editorImage");
  export const getEditorImageSessionState = () => log.getLogger("editorImageSession:state");
  export const getEditorImageWidget       = () => log.getLogger("editorImage:widget");
  export const getEditorImageWidgetArrow  = () => log.getLogger("editorImage:widgetArrow");
  export const getEditorImageWidgetText   = () => log.getLogger("editorImage:widgetText");
  
  export const getEditorKeyBinding = () => log.getLogger("editor:keybinding");

  export const getI18next      = () => log.getLogger("i18next");
  export const getLexicalTools = () => log.getLogger("lexical:tools");
  export const getProbes       = () => log.getLogger("app:probes");

  export const getHomePage     = () => log.getLogger("homepage");

  export const getHomePageView = () => log.getLogger("homepage:view");

  export const getPayment = () => log.getLogger("app:payment");
  
  export const getAppLimits   = () => log.getLogger("app:limits");
  export const getAppLoaders  = () => log.getLogger("app:loaders");
  export const getAppProducts = () => log.getLogger("app:products");
  export const getEditorDraggerMouse = () => log.getLogger("editor:dragger:mouse");
  export const getEditorDraggerTouch = () => log.getLogger("editor:dragger:touch");
}
