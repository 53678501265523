import environment from "app/environment"


export const soft = (errorMsg: string) => {
  console.error(errorMsg);
}


export const hard = (errorMsg: string) => {
  if ( environment.dev ) {
    throw new Error(errorMsg);
  }

  console.error(errorMsg);
}
