import styled from 'styled-components';


const FlexLogoWrapper = styled.div`
  max-width: 40px;
  min-width: 40px;

  height: 100%;
`;


export default FlexLogoWrapper;