import React from 'react';

import SideControlComponent from 'lego/components/side-controls/side-control';
import { SideControlsTypes } from 'lego/components/side-controls';

import SideToolbarComponent from './side-toolbar';
import SidePanelComponent   from './side-panel';


interface Props {
}


export const MenuPropertiesComponent: React.FC<Props> = (props: Props) => {

  return (
    <SideControlComponent
      flow={SideControlsTypes.PanelFlow.IN_FLOW}
      right={true}
      Toolbar={SideToolbarComponent}
      Panel={SidePanelComponent}
    />
  );
}
  
