import { Position, Size } from "app/arch/types";
import { ContentTypes } from "../../persistent/content";

export type Window = {
  size: Size,
  position: Position,
  visible: boolean,
  maximized: boolean,
}

export type WindowUpdate = Partial<Window>;


export enum ViewDefinerAutomationMode {
  MANUAL    = 'manual',
  AUTOMATIC = 'automatic',
}

export enum ViewDefinerFitType {
  FIT_TO_IMAGE = 'fit-to-image',
  FIT_TO_ALL   = 'fit-to-all',
}

export enum ViewDefinerShowType {
  LINES_ONLY           = 'lines_only',
  LINES_AND_OVERLAYERS = 'lines_and_overlayers',
  HIDDEN               = 'hidden',
}

export type ViewDefinerAutoFit = {
  autoMode: ViewDefinerAutomationMode,
  fitType: ViewDefinerFitType,
}

export type ViewDefinerAutoFitUpdate = Partial<ViewDefinerAutoFit>;


export type ViewOverlayer = {
  showType: ViewDefinerShowType,
  resizerLocked: boolean,
}

export type ViewOverlayerUpdate = Partial<ViewOverlayer>;


export interface ViewDefiner {
  autoFit: ViewDefinerAutoFit,
  overlayer: ViewOverlayer,
}

