import { useRecoilValue } from 'recoil';
import React from 'react';
import jtl from 'tools/jtl';

import { ReleaselogsTools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';
import { useDocState } from 'app/ui/contexts/document';
import { UIState_Instruction } from 'app/ui/states/editor-instruction';
import useDemoActive from 'app/ui-v2/app/__modules/demo/hooks/use-demo-active';

import ValueInfoComponent from './value-info';
import { Content }        from './styles';
import { ContentWrapper } from './styles';


interface Props {
}


export const DocumentReleaseInfoComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const document = useDocState();
  const isPrintout = useIsPrintout();
  const isDemoActive = useDemoActive();
  
  const docRevision = useRecoilValue(UIState_Instruction.docRevision);

  const revisionText = t("document release info, revision");
  const dateText     = t("document release info, date");
  
  const docDate = (
    isDemoActive ?
    "2024-09-01" :
    ReleaselogsTools.getReleaseDateIso()
  );

  const printState = document.printoutReleaseInfo.getRelease();

  const revision = (
    isPrintout ?
    printState.revision :
    docRevision
  );

  const dateIso = (
    isPrintout ?
    printState.date :
    docDate
  );

  const [date, _] = jtl.date.toYYYYMMDD(new Date(dateIso));
  
  return (
    <ContentWrapper>
      <Content>

        <ValueInfoComponent
          name={revisionText}
          value={revision}
        />

        <ValueInfoComponent
          name={dateText}
          value={date}
        />

      </Content>
    </ContentWrapper>
  );
}
