import { useApp } from "app/ui/hooks/app/use-app";


const useDocTemplates = () => {
  const app = useApp();
  const docTemplates = app.docTemplates;
  
  return docTemplates;
}


export default useDocTemplates;