import React from "react";
import { FrameCtrlType   } from "../../arch";
import { useDraggingDelta, DeltaMove} from 'app/ui/hooks';
import { StyledFrameCtrl } from './styles';


interface Props {
  ctrlType: FrameCtrlType,
  onDragStart: (type: FrameCtrlType) => void,
  onDragMove:  (delta: DeltaMove) => void,
  onDragEnd:   (delta: DeltaMove) => void,
};


export const FrameCtrlComponent: React.FC<Props> = (props: Props) => {
  const {
    ctrlType,
    onDragStart,
    onDragMove,
    onDragEnd,
  } = props;

  const handleOnDragMove = (delta: DeltaMove) => {
    onDragMove(delta);
  }

  const handleOnDragEnd = (delta: DeltaMove) => {
    onDragEnd(delta);
  }

  const { 
    startDragging 
  } = useDraggingDelta({
    onDragMove: handleOnDragMove,
    onDragEnd: handleOnDragEnd,
  });

  const handleStartDragging = (event: React.PointerEvent) => {
    const started = startDragging(event);
    if ( ! started ) {
      return;
    }
    
    onDragStart(ctrlType);
  }

  const getCursorType = () => {
    switch (ctrlType) {
      case FrameCtrlType.TOP_LEFT   : return 'nwse-resize';
      case FrameCtrlType.TOP_MIDDLE : return 'ns-resize';
      case FrameCtrlType.TOP_RIGHT  : return 'nesw-resize';

      case FrameCtrlType.MIDDLE_LEFT  : return 'ew-resize';
      case FrameCtrlType.MIDDLE_RIGHT : return 'ew-resize';

      case FrameCtrlType.BOTTOM_LEFT   : return 'nesw-resize';
      case FrameCtrlType.BOTTOM_MIDDLE : return 'ns-resize';
      case FrameCtrlType.BOTTOM_RIGHT  : return 'nwse-resize';

      default: {
        const msg = `Unknown frame ctrl type: ${ctrlType}`;
        throw new Error(msg);
      }
    }
  }

  return (
    <StyledFrameCtrl
      onPointerDown={handleStartDragging}
      $cursor={getCursorType()}
    />
  );
}