import { useEffect }  from 'react';
import { query }      from 'app/arch/backend';
import { useQueryV2 } from 'app/arch/backend/use-query-v2';


type OnDoneFn = (userData: {
  user: any,
  userSettings: any,
}) => void;


export interface Props {
  onDone: OnDoneFn;
  onError: (error: string) => void;
}


const useUserSettingsFetch = (props: Props) => {
  const {
    onDone,
    onError,
  } = props;

  const { 
    data: userSettingsData,
    refetch: userSettingsRefetch,
    error: userSettingsError,
  } = useQueryV2({
    query: query.getUserSettings(),
  });

  useEffect(() => {
    if ( ! userSettingsData ) {
      return;
    }

    const user = userSettingsData.user;
    const userSettings = userSettingsData.userSettings;

    onDone({
      user,
      userSettings
    });

  }, [userSettingsData]);

  useEffect(() => {
    if ( ! userSettingsError) {
      return;
    }

    console.error(userSettingsError);
    onError(userSettingsError.message)
  }, [userSettingsError]);

  return userSettingsRefetch;
}
  

export default useUserSettingsFetch;