import styled from "styled-components";


export const AutoHeightWrapper = styled.div`
  flex: 0 0 auto;
  /* background: rgba(0, 255, 0, 0.2); */
`;

export const ReleasesWrapper = styled.div<{empty: boolean}>`
  flex: 0 1 auto;
  min-height: ${props => props.empty ? '60px' : '110px'};
`;


export const ButtonBackWrapper = styled.div<{visible: boolean}>`
  padding-top: ${props => props.theme.defs.padding.normal};
  /* background-color: red; */

  opacity: ${props => props.visible ? 1 : 0};
  visibility: ${props => props.visible ? 'visible' : 'hidden'};

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  transition: 
    opacity 
    ${props => props.theme.defs.transition.time.normal} 
    ease-in-out
    ;
`;