import styled from 'styled-components';
import { zIndex } from './views/z-index';


// This is not really needed
// just for clarity
export const TopMenuWrapper = styled.div`
  width: 100%;
  height: 36px;
`;


export const MenuTopRightWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 10px;
  z-index: ${zIndex.TOP_RIGHT_MENU};
`;


export const LayerControlsWrapper = styled.div`
  position: absolute;
  z-index: ${zIndex.LAYER_CONTROLS};
`;

