import { useRef }  from 'react';

import useRepoFetch, { 
  OnDone as RepoFetchOnDone, 
  HookProps as RepoFetchProps 
} 
from './use-repo-fetch';

import useRepos from './use-repos';
import useAppStateSetters from './use-app-state-setters';
import { ReposTypes } from 'app/arch/app/states/repos';


type OnDone = RepoFetchOnDone;


interface HookProps extends RepoFetchProps {
}

const useRepoFetchReload = () => {
  const repos = useRepos();
  const fetchRepo = useRepoFetch();
  
  const {
    setRepos
  } = useAppStateSetters();

  const hookPropsRef = useRef<HookProps | null>( null );


  const onDone: OnDone = (repoPack: ReposTypes.RepoPackRaw) => {
    repos.loadRepoPack(repoPack);
    setRepos();
    
    const hookProps = hookPropsRef.current;
    hookProps?.onDone?.(repoPack);
    resetRefs();
  }

  const resetRefs = () => {
    hookPropsRef.current = null;
  }

  const hook = (props: HookProps) => {
    const updatedProps = {
      ...props,
      onDone,
    };

    hookPropsRef.current = props;
    fetchRepo(updatedProps)
  }

  return hook;
}

export default useRepoFetchReload;
