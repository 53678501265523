import React from 'react';
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';

import { useDocState }        from 'app/ui/contexts/document';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';

import Tools from '../tools';

import { DropWrapper } from './styles';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
  children: React.ReactNode;
}


export const CellIndexDropComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;
  
  const document = useDocState();
  
  const {
    setReleaselogs
  } = useEditorStatesSetters();

  const getDNDData = useDNDDataGet();

  const handleDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }
    const data = JSON.parse(dataSerial);
    const droppedCellAddr = data.cellAddr;

    document.releaselogs.moveRow(droppedCellAddr, cellAddr);
    document.saveUndo();
    setReleaselogs();
  }

  const dndContext = Tools.getDNDContext(cellAddr.releaselogId);

  const contextsDefs: ContextDef[] = [
    {
      dndContext: dndContext,
      onDrop: handleDrop
    },
  ];


  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs}
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}

