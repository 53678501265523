import useUserSystem, { OS } from 'app/ui/hooks/user-system';

const ActionSymbolMap: any = {
  [OS.Mac]:     '⌘',
  [OS.Windows]: '⌃',
  [OS.Linux]:   '⌃',
  [OS.Unknown]: '⌃',
}

const ShiftSymbolMap: any = {
  [OS.Mac]:     '⇧',
  [OS.Windows]: '⇧',
  [OS.Linux]:   '⇧',
  [OS.Unknown]: '⇧',
}

export type SpecialKeysType = 'Meta' | 'Control' | 'Alt' | 'Shift';

export interface SpecialKeys {
  multiItemSelect: SpecialKeysType,
  scalerEnabled: SpecialKeysType,
  widgetsMultiSelectEnabled: SpecialKeysType,
  smartLinesDisabled: SpecialKeysType,
  imageResizeModeToggle: SpecialKeysType,
}

const ActionKeyMap: {[os in OS]: SpecialKeys} = {
  [OS.Mac]: {
    multiItemSelect: 'Meta',
    scalerEnabled: 'Control',
    widgetsMultiSelectEnabled: 'Control',
    smartLinesDisabled:  'Alt',
    imageResizeModeToggle: 'Shift',
  },
  [OS.Windows]: {
    multiItemSelect: 'Control',
    scalerEnabled: 'Control',
    widgetsMultiSelectEnabled: 'Control',
    smartLinesDisabled:  'Alt',
    imageResizeModeToggle: 'Shift',
  },
  [OS.Linux]: {
    multiItemSelect: 'Meta',
    scalerEnabled: 'Control',
    widgetsMultiSelectEnabled: 'Control',
    smartLinesDisabled:  'Alt',
    imageResizeModeToggle: 'Shift',
  },
  [OS.Unknown]: {
    multiItemSelect: 'Meta',
    scalerEnabled: 'Control',
    widgetsMultiSelectEnabled: 'Control',
    smartLinesDisabled:  'Alt',
    imageResizeModeToggle: 'Shift',
  },
}



const useSystemKeys = () => {
  const userSystem = useUserSystem();
  // const userSystem = OS.Windows;
  
  return {
    actionSymbol: ActionSymbolMap[userSystem],
    shiftSymbol: ShiftSymbolMap[userSystem],
    actionKeys: ActionKeyMap[userSystem],
  };
}

export default useSystemKeys;
