import styled from "styled-components";
import TextWrapBase from 'lego/styles/text-wrap';
import ButtonTextBase from 'lego/styles/button_text';


export const TextWrap = styled(TextWrapBase)`
  /* background-color: blue; */
`;

export const ButtonSendPDF = styled(ButtonTextBase)`
  width: 100%;
  max-width: unset;
`;
