import * as Defaults from './defaults';
import * as Types from './types';


export type State = {
  window: Types.Window;
};


export const getWindow = (state: State) => state.window;


export const createInitialState = (): State => {
  const state: State = {
    window: Defaults.getWindow(),
  };

  return state;
}
