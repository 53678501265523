import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_RepoMarkers } from 'app/ui/states/editor-instruction';

import ControlMarkersDropComponent        from './control-markers-drop';
import ControlMarkersContextMenuComponent from './control-markers-context-menu';
import MarkersComponent                   from './markers';

import { MarkersPanel } from './styles';


interface Props {
}


export const ControlMarkersComponent: React.FC<Props> = (props: Props) => {
  const markersCount = useRecoilValue(UIState_RepoMarkers.markersCount);

  return (
    <>
      {
        markersCount > 0 &&
        <MarkersPanel>
          <ControlMarkersContextMenuComponent>
            <ControlMarkersDropComponent>
              <MarkersComponent />
            </ControlMarkersDropComponent>
          </ControlMarkersContextMenuComponent>
        </MarkersPanel>
      }
    </>
  );
};
