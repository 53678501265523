import { RefObject } from "react";


export interface Props {
  wrapperRef: RefObject<HTMLElement | null>;
}


const useDropInCheck = (props: Props) => {
  const {
    wrapperRef
  } = props;

  const dropIsIn = (
    cursorX: number, cursorY: number
  ): boolean => {
    const wrapper = wrapperRef.current;
    if ( ! wrapper ) {
      console.warn(`Wrapper not ready`);
      return false;
    }

    // if (wrapper.contains(event.target as HTMLElement)) {
    //   console.warn('Touch start over Div');
    // }
    // else {
    // }

    const rect = wrapper.getBoundingClientRect();
    const isInside = (
      cursorX >= rect.left &&
      cursorX <= rect.right &&
      cursorY >= rect.top &&
      cursorY <= rect.bottom);
    
    return isInside;
  }

  return dropIsIn;
}


export default useDropInCheck;