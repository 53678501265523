import styled from "styled-components";
import { Config } from "./config";


const WRAPPER_SIZE = Config.shape.circle.size + 2 * Config.style.outline.width;


export const ViewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${WRAPPER_SIZE}px;
  height: ${WRAPPER_SIZE}px;
  /* background-color: rgba(0, 0, 255, 0.3); */
`;
