import styled from 'styled-components';

const PAGE_SPACING = 45;
export const PAGES_PADDING = 45;


export const PagesEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${PAGE_SPACING}px;

  padding-top: ${PAGES_PADDING}px;
  padding-bottom: ${PAGES_PADDING}px;
`;

export const PagesPrintoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  box-sizing: border-box;
  overflow: clip;
  padding: 0;
  margin: 0;
`;

export const PageWrapper = styled.div`
`;

export const MarginPostioner = styled.div`
  position: relative;
  z-index: 1;
  width: 0;
  height: 0;
`;

export const MarginDetacher = styled.div`
  position: absolute;
`;