import styled from "styled-components";
import TextWrap         from "lego/styles/text-wrap";
import TextareaAutosize from "lego/styles/textarea-auto-size";


export const Content = styled.div`
  box-sizing: border-box;
  padding: ${props => props.theme.defs.padding.medium};
  box-sizing: border-box;

  width: 100%;
  height: 100%;
`;

export const TitleText = styled(TextareaAutosize)`
  font-size:   ${props => props.theme.defs.font.size.xxlarge};
  font-weight: ${props => props.theme.defs.font.weight.large};
  /* background: rgba(255, 0, 0, 0.2); */
`;

export const TitleErrorPositioner = styled.div`
  position: relative;
  /* background: rgba(0, 0, 255, 0.2); */
`;

export const TitleError = styled(TextWrap)<{$visible: boolean}>`
  position: absolute;
  pointer-events: none;
  right: 1px;
  top: 1px;

  padding-top:    ${props => props.theme.defs.padding.medium};
  padding-bottom: ${props => props.theme.defs.padding.medium};
  padding-left:   ${props => props.theme.defs.padding.large};
  padding-right:  ${props => props.theme.defs.padding.large};
     
  font-size:  ${props => props.theme.defs.font.size.middle};
  color:      ${props => props.theme.defs.colors.special.white};
  background: ${props => props.theme.defs.colors.special.red};
  border-radius: ${props => props.theme.defs.border.radius.normal};

  opacity: ${props => props.$visible ? 1 : 0};
  transition: 
    opacity 
    ${props => props.theme.defs.transition.time.quick} 
    ease-in-out;
`;

export const DescriptionText = styled(TextareaAutosize)`
  font-size: ${props => props.theme.defs.font.size.large};
/* background: rgba(0, 255, 0, 0.3); */
`;
