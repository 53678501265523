import styled from "styled-components";
import { Input as InputBase } from "app/ui-v2/editor-instruction/views/shared/components/name-edit/name-input/styles";


export interface Props {
  $background: string;
}

export const InputWrapper = styled.div<Props>`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background: ${props => props.$background};
`;


export const Input = styled(InputBase)`
  height:  auto;
  padding: 10px 0;
  font-size: 16px;
`;
