import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }        from 'app/ui/contexts/document';
import { DNDContext }         from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';

import { DropWrapper } from './styles';


interface Props {
  cellAddr: ContentTypes.CellAddr;
  children: React.ReactNode;
}


export const CellMarkersDropComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;


  const document = useDocState();
  const { 
    setContent,
  } = useEditorStatesSetters();
  
  const getDNDData = useDNDDataGet();

  const handleDrop_fromGallery = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }
    
    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;
    
    document.content.cellMarkers_addMarker(
      markerAddrDropped, 
      cellAddr
    );
    document.content.checkForLastRow(cellAddr);

    document.saveUndo();
    setContent();
  }

  const handleDrop_fromCell = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }
    
    const data = JSON.parse(dataSerial);
    const markerDroppedAddr = data.markerAddr;

    document.content.cellMarkers_moveMarkerIntoCell(
      markerDroppedAddr,
      cellAddr
    );
    document.content.checkForLastRow(cellAddr);
    
    document.saveUndo();
    setContent();
  }

  const handleDrop_fromDocHeader = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }
    
    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;
    const headerMarkerProps = document.headerMarkers.getMarkerProps(markerAddrDropped);

    document.content.cellMarkers_addMarker(
      headerMarkerProps.repoMarkerAddr,
      cellAddr
    );
    document.content.checkForLastRow(cellAddr);

    document.saveUndo();
    setContent();    
  }

  const contextsDefs: ContextDef[] = [
    {
      dndContext: DNDContext.marker.cell,
      onDrop: handleDrop_fromCell,
    },
    {
      dndContext: DNDContext.marker.gallery,
      onDrop: handleDrop_fromGallery,
    },
    {
      dndContext: DNDContext.marker.docHeader,
      onDrop: handleDrop_fromDocHeader,
    },
    {
      dndContext: DNDContext.marker.editor,
      onDrop: handleDrop_fromGallery,
    },
  ];

  return (
    <DNDDropComponent
      contextsDefs={contextsDefs}
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
