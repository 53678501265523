import React from 'react';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import ImageIdxViewComponent from './idx-view';
import { MainWrapper } from './styles';


interface Props {
  imageAddr: Types.ImageAddr;
  children: React.ReactNode;
}


export const ImageIdxComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children,
  } = props;

  return (
    <MainWrapper>
      <ImageIdxViewComponent imageAddr={imageAddr} />
      { children }
    </MainWrapper>
  );
}
  