import * as Types from "./types";


/**
 * 
 * Content
 * 
 */

export const getContentItem_HeaderRow = (): Types.ContentItem_HeaderRow => ({
  type: Types.ContentItemType.HEADER_ROW
});

export const getContentItem_SectionAdder = (): Types.ContentItem_SectionAdder => ({
  type: Types.ContentItemType.SECTION_ADDER
});

export const getContentItem_Section = (
  props: Omit<Types.ContentItem_Section, 'type' | 'items'>
): Types.ContentItem_Section => ({
  type: Types.ContentItemType.SECTION,
  items: [],
  ...props,
});

export const getSectionItem_SectionName = (): Types.SectionItem_SectionName => ({
  type: Types.SectionItemType.SECTION_NAME,
});

export const getSectionItem_CellsRow = (
  props: Omit<Types.SectionItem_CellsRow, 'type'>
): Types.SectionItem_CellsRow => ({
  type: Types.SectionItemType.CELLS_ROW,
  ...props,
});

/**
 * 
 * Page 
 * 
 */
  
export const getPageItem_DocInfo = (): Types.PageItem_DocInfo => ({
  type: Types.PageItemType.DOC_INFO,
});

export const getPageItem_DocCustomRows = (): Types.PageItem_DocCustomRows => ({
  type: Types.PageItemType.DOC_CUSTOM_ROWS,
});

export const getPageItem_DocCustomFields = (): Types.PageItem_DocCustomFields => ({
  type: Types.PageItemType.DOC_CUSTOM_FIELDS,
});

export const getPageItem_DocMarkers = (): Types.PageItem_DocMarkers => ({
  type: Types.PageItemType.DOC_MARKERS,
});

export const getPageItem_Content = (): Types.PageItem_Content => ({
  type: Types.PageItemType.CONTENT,
  items: [],
});

export const getPageItems = (): Types.PageItems => [];

export const getPage = (): Types.Page => ({
  items: getPageItems()
});
