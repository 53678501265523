import React from 'react';
import HLS_GoogleAuthenticationComponent from 'app/ui-v2/home-page/hls/google-authentication';


interface Props {
}


export const GoogleSignupComponent: React.FC<Props> = (props: Props) => {
  return (
    <HLS_GoogleAuthenticationComponent signup={true} />
  );
}

