import { FrameCtrlType } from "lego-v2/frame-resize/arch";
import { FrameHandlerBase } from "lego-v2/frame-resize/arch";
import { FrameTargetUpdate } from "lego-v2/frame-resize/arch";

import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { DeltaMove } from "app/ui/hooks";
import { Position }  from 'app/arch/types';
import { Size }      from 'app/arch/types';



const FrameSizeMultiplier: {[key: string]: any} = {
  [FrameCtrlType.TOP_MIDDLE]:    [0, -1],
  [FrameCtrlType.MIDDLE_RIGHT]:  [1,  0],
  [FrameCtrlType.BOTTOM_MIDDLE]: [0,  1],
  [FrameCtrlType.MIDDLE_LEFT]:   [-1, 0],
};

const FramePositionMultiplier: {[key: string]: any} = {
  [FrameCtrlType.TOP_MIDDLE]:    [0, 1],
  [FrameCtrlType.MIDDLE_RIGHT]:  [0, 0],
  [FrameCtrlType.BOTTOM_MIDDLE]: [0, 0],
  [FrameCtrlType.MIDDLE_LEFT]:   [1, 0],
};

const ClipMultiplier: {[key: string]: any} = {
  [FrameCtrlType.TOP_MIDDLE]:    [1,  0,  0, 0],
  [FrameCtrlType.MIDDLE_RIGHT]:  [0, -1,  0, 0],
  [FrameCtrlType.BOTTOM_MIDDLE]: [0,  0, -1, 0],
  [FrameCtrlType.MIDDLE_LEFT]:   [0,  0,  0, 1],
}


export class FrameHandlerImage extends FrameHandlerBase {
  get startImagePosition() { return this.startCustomData.imagePosition; }
  get startImageSize()     { return this.startCustomData.imageSize; }
  get startClipInset()     { return this.startCustomData.imageClipInset; }


  //--------------------------------------------------
  //
  // Middle
  
  protected processTopMiddle(delta: DeltaMove): FrameTargetUpdate {
    return this.processMiddleRoutine(
      FrameCtrlType.TOP_MIDDLE,
      delta.x, delta.y);    
  };  

  protected processRightMiddle(delta: DeltaMove): FrameTargetUpdate {
    return this.processMiddleRoutine(
      FrameCtrlType.MIDDLE_RIGHT,
      delta.x, delta.y);
  }
  
  protected processBottomMiddle(delta: DeltaMove): FrameTargetUpdate {
    return this.processMiddleRoutine(
      FrameCtrlType.BOTTOM_MIDDLE,
      delta.x, delta.y);
  }
  
  protected processLeftMiddle(delta: DeltaMove): FrameTargetUpdate {
    return this.processMiddleRoutine(
      FrameCtrlType.MIDDLE_LEFT,
      delta.x, delta.y);    
  };  
  
  protected processMiddleRoutine(
    ctrlType: FrameCtrlType,
    deltaX: number, deltaY: number,
  ): FrameTargetUpdate {

    const [
      mFrameSizeX, 
      mFrameSizeY
    ] = FrameSizeMultiplier[ctrlType];

    const [
      mFramePositionX, 
      mFramePositionY
    ] = FramePositionMultiplier[ctrlType];
    

    const [deltaXChecked_step1, deltaYChecked_step1] = this.correctDelta_imageTooSmall(
      deltaX, deltaY,
      mFrameSizeX, mFrameSizeY
    );
    
    const [deltaXChecked_step2, deltaYChecked_step2] = this.correctDelta_imageFrameOverflow(
      ctrlType,
      deltaXChecked_step1, deltaYChecked_step1,
    );

    // Check if image is at its max
    // size - if so resize image instead 
    // of cliping
    let resizeUpdate = this.checkIfImageResizeNeeded(
      ctrlType,
      deltaX, deltaY,
    );

    if ( resizeUpdate !== null ) {
      return resizeUpdate;
    }

    const deltaChecked = {
      x: deltaXChecked_step2,
      y: deltaYChecked_step2
    };

    let frameNewSize = this.getFrameSize(
      mFrameSizeX * deltaChecked.x, 
      mFrameSizeY * deltaChecked.y
    );

    let frameNewPosition = this.getFramePosition(
      mFramePositionX * deltaChecked.x, 
      mFramePositionY * deltaChecked.y
    );

    const [
      mClipTop, 
      mClipRight,
      mClipBottom,
      mClipLeft
    ] = ClipMultiplier[ctrlType];
    
    const deltaPercentX = deltaChecked.x / this.startImageSize[0] * 100;
    const deltaPercentY = deltaChecked.y / this.startImageSize[1] * 100;

    let newClipTop    = this.startClipInset.top    + (mClipTop    * deltaPercentY);
    let newClipRight  = this.startClipInset.right  + (mClipRight  * deltaPercentX);
    let newClipBottom = this.startClipInset.bottom + (mClipBottom * deltaPercentY);
    let newClipLeft   = this.startClipInset.left   + (mClipLeft   * deltaPercentX);

    const newClipInset = {
      top: newClipTop,
      right: newClipRight,
      bottom: newClipBottom,
      left: newClipLeft, 
    }

    const imageNewPosition = this.getNewImagePosition(
      this.startImageSize,
      newClipInset
    );

    const update: FrameTargetUpdate = {
      control: ctrlType,
      size: frameNewSize,
      position: frameNewPosition,

      custom: {
        imagePosition: imageNewPosition,
        imageClipInset: newClipInset,
      }
    };

    return update;
  }

  private getFrameSize(dx: number, dy: number) {
    let newSize: Size = [
      this.startSize[0] + dx, 
      this.startSize[1] + dy
    ];
    return newSize;
  }

  private getFramePosition(dx: number, dy: number) {
    let newPosition: Position = [
      this.startPosition[0] + dx, 
      this.startPosition[1] + dy
    ];

    return newPosition;
  }

  
  // Check if image resize delta,
  // will not cause clipped image
  // to be to small.
  private correctDelta_imageTooSmall(
    dx: number, dy: number,
    mFrameSizeX: number, mFrameSizeY: number
  ) {
    // return [dx, dy];

    const dxChecked = (
        (this.startSize[0] + (mFrameSizeX * dx) >= this.minSize[0]) ?
        dx : (this.startSize[0] - this.minSize[0]) * (dx / Math.abs(dx))
    );

    const dyChecked = (
      (this.startSize[1] + (mFrameSizeY * dy) >= this.minSize[1]) ?
      dy : (this.startSize[1] - this.minSize[1]) * (dy / Math.abs(dy))
    );

    return [dxChecked, dyChecked];
  }

  // Check if image resize delta,
  // will not cause clipped image
  // to be too big - image frame
  // overflowing image border.
  private correctDelta_imageFrameOverflow(
    ctrlType: FrameCtrlType,
    deltaX: number, deltaY: number,
  ) {
    const checkDelta = (
      delta: number,
      startImgSize: number,
      startClip: number, 
      mClip: number,
    ) => {
      const deltaPercent = delta / startImgSize  * 100;
      const newClip = startClip + (mClip  * deltaPercent);
      if (newClip < 0 && mClip !== 0) {
        const correctedDelta = (-startClip / (100 * mClip)) * startImgSize;
        return correctedDelta;
      }
      return delta;
    }

    const [
      mClipTop, 
      mClipRight,
      mClipBottom,
      mClipLeft
    ] = ClipMultiplier[ctrlType];

    let deltaXChecked = deltaX;
    let deltaYChecked = deltaY;

    deltaXChecked = checkDelta(
      deltaXChecked,
      this.startImageSize[0],
      this.startClipInset.right, 
      mClipRight
    );

    deltaXChecked = checkDelta(
      deltaXChecked,
      this.startImageSize[0],
      this.startClipInset.left, 
      mClipLeft, 
    );

    deltaYChecked = checkDelta(
      deltaYChecked,
      this.startImageSize[1],
      this.startClipInset.top, 
      mClipTop
    );

    deltaYChecked = checkDelta(
      deltaYChecked,
      this.startImageSize[1],
      this.startClipInset.bottom, 
      mClipBottom
    );

    return [deltaXChecked, deltaYChecked];
  }

  private checkIfImageResizeNeeded(
    ctrlType: FrameCtrlType,
    deltaX: number, deltaY: number,
  ) {
    const [
      mClipTop,
      mClipRight,
      mClipBottom,
      mClipLeft,
    ] = ClipMultiplier[ctrlType];

    const checkDelta = (
      delta: number,
      startImgSize: number,
      startClip: number, 
      mClip: number,
    ): (null | any) => {
      const deltaPercent = delta / startImgSize  * 100;
      const newClip = startClip + (mClip  * deltaPercent);
      if (newClip < 0 && mClip !== 0) {
        const correctedDelta = (-startClip / (100 * mClip)) * startImgSize;
        return [true, Math.abs(correctedDelta)];
      }
      return [false, 0];
    }

    let deltaXCorrected: number = 0;
    let deltaYCorrected: number = 0;
    let shouldResize = false;

    switch(ctrlType) {
      case FrameCtrlType.MIDDLE_LEFT : {
        let resizeNeeded = false;
        [resizeNeeded, deltaXCorrected] = checkDelta(
          deltaX,
          this.startImageSize[0],
          this.startClipInset.left,
          mClipLeft
        );

        shouldResize = shouldResize || resizeNeeded;
        break;
      }

      case FrameCtrlType.MIDDLE_RIGHT : {
        let resizeNeeded = false;
        [resizeNeeded, deltaXCorrected] = checkDelta(
          deltaX,
          this.startImageSize[0],
          this.startClipInset.right,
          mClipRight
        );
        shouldResize = shouldResize || resizeNeeded;
        break;
      }

      case FrameCtrlType.TOP_MIDDLE : {
        let resizeNeeded = false;
        [resizeNeeded, deltaYCorrected] = checkDelta(
          deltaY,
          this.startImageSize[1],
          this.startClipInset.top,
          mClipTop
        );
        shouldResize = shouldResize || resizeNeeded;
        break;
      }

      case FrameCtrlType.BOTTOM_MIDDLE : {
        let resizeNeeded = false;
        [resizeNeeded, deltaYCorrected] = checkDelta(
          deltaY,
          this.startImageSize[1],
          this.startClipInset.bottom,
          mClipBottom
        );
        shouldResize = shouldResize || resizeNeeded;
        break;
      }

      default : {
        const msg = `Invalid control type: ${ctrlType}`
        throw(new Error(msg));
      }
    }

    if ( ! shouldResize ) {
      return null;
    }


    // Get scale
    // 
    const frameSizeMulti_ = FrameSizeMultiplier[ctrlType];
    
    let newTmpSize: Size = [
      this.startSize[0] + frameSizeMulti_[0] * deltaX,
      this.startSize[1] + frameSizeMulti_[1] * deltaY
    ];

    // Scale Frame and scale Image can be different
    // when let's say we have right clip set to 100,
    // then we start resinging on right side towards right
    // at this scenario frame gets rescaled immediatlly,
    // but image will start rescale a bit later,
    // once the movment to right will reach clip limit/image border
    const scaleFrameX = newTmpSize[0] / this.startSize[0];
    const scaleFrameY = newTmpSize[1] / this.startSize[1];
    let scaleFrame = Math.max(scaleFrameX, scaleFrameY);
    
    const scaleImageX = newTmpSize[0] / (this.startSize[0] + deltaXCorrected);
    const scaleImageY = newTmpSize[1] / (this.startSize[1] + deltaYCorrected);
    let scaleImage    = Math.max(scaleImageX, scaleImageY);

    // 
    // Get frame size
    //
    // TODO math.abs is temporary
    // - sign should be removed from table
    const frameSizeMulti = FrameSizeMultiplier[ctrlType];
    const scaleXMulti = Math.abs(frameSizeMulti[0]);
    const scaleYMulti = Math.abs(frameSizeMulti[1]);
    
    const frameNewSize: Size = [
      this.startSize[0] * (scaleXMulti ? scaleFrame : 1), 
      this.startSize[1] * (scaleYMulti ? scaleFrame : 1),
    ];

    //
    // Get frame position
    //
    const deltaWidth  = this.startSize[0] - frameNewSize[0];
    const deltaHeight = this.startSize[1] - frameNewSize[1];

    const framePosMulti = FramePositionMultiplier[ctrlType];
    const frameNewPosition: Position = [
      this.startPosition[0] + deltaWidth  * framePosMulti[0], 
      this.startPosition[1] + deltaHeight * framePosMulti[1]
    ];
    
    //
    // Set image size
    //
    const imageNewSize: Size = [
      this.startImageSize[0] * scaleImage,
      this.startImageSize[1] * scaleImage 
    ];


    const ClipMultiplier2 = {
      [FrameCtrlType.TOP_MIDDLE]:    [0, 1, 0, 1],
      [FrameCtrlType.MIDDLE_RIGHT]:  [1, 0, 1, 0],
      [FrameCtrlType.BOTTOM_MIDDLE]: [0, 1, 0, 1],
      [FrameCtrlType.MIDDLE_LEFT]:   [1, 0, 1, 0],
    }
    const ClipMultiplier3 = {
      [FrameCtrlType.TOP_MIDDLE]:    [0, 1, 1, 1],
      [FrameCtrlType.MIDDLE_RIGHT]:  [1, 0, 1, 1],
      [FrameCtrlType.BOTTOM_MIDDLE]: [1, 1, 0, 1],
      [FrameCtrlType.MIDDLE_LEFT]:   [1, 1, 1, 0],
    }

    const startImgWidth  = this.startImageSize[0] * (100 - (this.startClipInset.left + this.startClipInset.right)) / 100;
    const startImgHeight = this.startImageSize[1] * (100 - (this.startClipInset.top + this.startClipInset.bottom)) / 100;

    const imageNewWidth  = imageNewSize[0] * (100 - (this.startClipInset.left + this.startClipInset.right))  / 100;
    const imageNewHeight = imageNewSize[1] * (100 - (this.startClipInset.top  + this.startClipInset.bottom)) / 100;

    const deltaPercentX = ((imageNewWidth - startImgWidth)  / imageNewSize[0]) * 100;
    const deltaPercentY = ((imageNewHeight - startImgHeight) / imageNewSize[1]) * 100;

    const clipMulti = ClipMultiplier2[ctrlType];
    // This one exists as I need to be able
    // to zero this.startClipInset.x as
    // when we start with let's say inset.right
    // set to 100, then we resize to right,
    // once we get to the point we start rescaling
    // this clip inset should be zero. 
    const clipMulti3 = ClipMultiplier3[ctrlType];

    let newClipTop    = clipMulti3[0] * this.startClipInset.top    + (clipMulti[0] * deltaPercentY / 2);
    let newClipRight  = clipMulti3[1] * this.startClipInset.right  + (clipMulti[1] * deltaPercentX / 2);
    let newClipBottom = clipMulti3[2] * this.startClipInset.bottom + (clipMulti[2] * deltaPercentY / 2);
    let newClipLeft   = clipMulti3[3] * this.startClipInset.left   + (clipMulti[3] * deltaPercentX / 2);

    // newClipTop    = (clipMulti[0] * deltaPercentY / 2);
    // newClipRight  = (clipMulti[1] * deltaPercentX / 2);
    // newClipBottom = (clipMulti[2] * deltaPercentY / 2);
    // newClipLeft   = (clipMulti[3] * deltaPercentX / 2);

    const newPosLeft = -(newClipLeft / 100 ) * imageNewSize[0];
    const newPosTop  = -(newClipTop  / 100 ) * imageNewSize[1];

    const imageNewPosition = [
      newPosLeft,
      newPosTop,
    ] as Position;

    const newClipInset = {
      top: newClipTop,
      right: newClipRight,
      bottom: newClipBottom,
      left: newClipLeft, 
    }

    const update: FrameTargetUpdate = {
      control: ctrlType,
      size: frameNewSize,
      position: frameNewPosition,
      custom: {
        imageSize: imageNewSize,
        imagePosition: imageNewPosition,
        imageClipInset: newClipInset,
      }
    };

    return update;
  }
  //--------------------------------------------------
  // Corner controls

  protected override processTopLeft(delta: DeltaMove): FrameTargetUpdate {
    return this.processCornerRoutine(
      -delta.x, -delta.y, 
      1, 1,
      FrameCtrlType.TOP_LEFT
    );
  };  

  protected override processTopRight(delta: DeltaMove): FrameTargetUpdate {
    return this.processCornerRoutine(
      delta.x, -delta.y, 
      0, 1,
      FrameCtrlType.TOP_RIGHT
    );
  };  

  protected override processBottomLeft(delta: DeltaMove): FrameTargetUpdate {
    return this.processCornerRoutine(
      -delta.x, delta.y, 
      1, 0,
      FrameCtrlType.BOTTOM_LEFT
    );
  };  

  protected processBottomRight(delta: DeltaMove): FrameTargetUpdate {
    return this.processCornerRoutine(
      delta.x, delta.y, 
      0, 0,
      FrameCtrlType.BOTTOM_RIGHT
    );
  };


  //--------------------------------------------------
  // Corner controls - routine
  //

  private processCornerRoutine(
    dx: number, dy: number, 
    mMoveX: number, mMoveY: number,
    frameCtrlType: FrameCtrlType
  ): FrameTargetUpdate {

    //
    // Get scale
    // 
    let newTmpSize: Size = [
      this.startSize[0] + dx, 
      this.startSize[1] + dy
    ];

    const scaleX = newTmpSize[0] / this.startSize[0];
    const scaleY = newTmpSize[1] / this.startSize[1];
    let scale = Math.max(scaleX, scaleY);
    scale = this.checkScaleLimit(scale);
    
    // 
    // Get frame size
    //
    const frameNewSize: Size = [
      this.startSize[0] * scale, 
      this.startSize[1] * scale
    ];

    //
    // Get frame position
    //
    const deltaWidth  = this.startSize[0] - frameNewSize[0];
    const deltaHeight = this.startSize[1] - frameNewSize[1];

    let frameNewPosition: Position = [
      this.startPosition[0] + deltaWidth  * mMoveX, 
      this.startPosition[1] + deltaHeight * mMoveY
    ];
    
    //
    // Set image size
    //
    const imageNewSize: Size = [
      this.startImageSize[0] * scale,
      this.startImageSize[1] * scale
    ];

    const imageNewPosition = this.getNewImagePosition(
      imageNewSize,
      this.startClipInset
    );

    const update: FrameTargetUpdate = {
      control: frameCtrlType,
      size: frameNewSize,
      position: frameNewPosition,
      custom: {
        imageSize: imageNewSize,
        imagePosition: imageNewPosition
      }
    };

    return update;
  }

  private checkScaleLimit(scale: number) {
    const minScaleX = this.minSize[0] / this.startImageSize[0];
    const minScaleY = this.minSize[1] / this.startImageSize[1];
    const minScale = Math.max(minScaleX, minScaleY);
    
    return Math.max(scale, minScale);
  }

  //--------------------------------------------------
  // Common
  //

  private getNewImagePosition(
    imageNewSize: Size, 
    imageNewClipInset: ContentTypes.ImageClipInset
  ) {
    const newPosLeft = -(imageNewClipInset.left / 100 ) * imageNewSize[0];
    const newPosTop  = -(imageNewClipInset.top / 100 ) * imageNewSize[1];

    const imagePosition = [
      newPosLeft,
      newPosTop,
    ] as Position;

    return imagePosition;
  }
}