import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ViewPanelComponent from 'app/ui-v2/home-page/hls/view-panel';


interface Props {
}


export const ViewPasswordResetLinkSentComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  return (
    <ViewPanelComponent title={t("password reset link sent")} >
      { t('reset password sent info') }
    </ViewPanelComponent>
  )
}

