import React from 'react';

import { RepoMarkersTypes }    from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import HLC_MarkerViewComponent from 'app/ui-v2/editor-instruction/hls/markers/marker-view';

import MarkerDraggerComponent     from './marker-dragger';
import MarkerContextMenuComponent from './marker-context-menu';
import MarkerEditComponent        from './marker-edit';
import MarkerSelectComponent      from './marker-select';
import MarkerDropComponent        from './marker-drop';
import MarkerDataTestComponent    from './marker-data-test';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
}


export const MarkerComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr
  } = props;

  return (
    <MarkerDataTestComponent markerAddr={markerAddr} >
      <MarkerDraggerComponent markerAddr={markerAddr} >
        <MarkerDropComponent markerAddr={markerAddr} >
          <MarkerContextMenuComponent markerAddr={markerAddr} >
            <MarkerEditComponent markerAddr={markerAddr} >
              <MarkerSelectComponent>
                <HLC_MarkerViewComponent markerAddr={markerAddr} />
              </MarkerSelectComponent>
            </MarkerEditComponent>
          </MarkerContextMenuComponent>
        </MarkerDropComponent>
      </MarkerDraggerComponent>
    </MarkerDataTestComponent>
  );
};


export default MarkerComponent;