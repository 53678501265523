import { ThemeDefProps } from "../types";


export const themeDefs_menuBarItemPanel = (props: ThemeDefProps) => {
  const { colors, accentColor, font, border, padding, boxShadow } = props;

  const menuBarItemPanel = {
    background: colors.background.third,
    padding: padding.normal,
  };
    
  return menuBarItemPanel;
}

