import styled from "styled-components";
import ButtonBase from "lego/styles/button_text";
import TextWrap from "lego/styles/text-wrap";


export const Button = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.normal};
`;

export const Text = styled(TextWrap)`
`;


