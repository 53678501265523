import styled from "styled-components";
import ContentItemBorder from "app/ui-v2/editor-instruction/views/shared/styles/content-item-border";


interface Props {
  isRowLast: boolean;
}

export const MainWrapper = styled(ContentItemBorder)<Props>`
  height: 100%;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: ${props => props.isRowLast ? 'none' : 'solid'};
`;
