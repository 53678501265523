import { UUID } from 'app/arch/types';


//----------------------------
// State's
//
export type State = {
  selectedMarker: UUID | null,
};


//-----------------
// Getters
//
export const getMarkerSelected = (state: State) => state.selectedMarker;


//----------------------------
// Create initial state
//
export const createInitialState = (): State => {
  const initState: State = {
    selectedMarker: null,
  }

  return initState;
}
  
