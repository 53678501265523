import styled from "styled-components";
import TitleBase from "../../../../styles/title";


export const MainWrapper = styled.div<{$disabled?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xxsmall};

  opacity: ${props => props.$disabled ? props.theme.defs.opacity.disabled : 1};
`;

export const Title = styled(TitleBase)`
  font-size:   ${props => props.theme.defs.font.size.large};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;
