import styled from 'styled-components';
import ButtonBase from 'lego/styles/button';
import TextWrap from 'lego/styles/text-wrap';


export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;


export const Button = styled(ButtonBase)`
  width: 200px;
  max-width: unset;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.defs.gap.normal};

  padding: 
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.large}
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.large}
  ;

  ${props => props.$selected ? 
  `
    color:        ${props.theme.button.color.selected};
    background:   ${props.theme.button.background.selected};
    border-color: ${props.theme.button.border.color.selected};
  `
  : 
  `
    color:        ${props.theme.button.color.primary};
    background:   ${props.theme.defs.accentColor.gradient.primary};
    border-width: 1px;
    border-color: ${props.theme.defs.accentColor.darkest};


    &:focus-visible:not(:active) {
      outline-style: ${props.theme.attrs.focusVisible.outline.style};
      outline-width: ${props.theme.attrs.focusVisible.outline.width};
      outline-color: ${props.theme.attrs.focusVisible.outline.color};
    }
  `
  }
`;


export const IconWrap = styled.div`
  position: relative;
  bottom: 2px;

  flex: 0 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;


export const Text = styled(TextWrap)`
  flex: 0 1 auto;
  min-width: 0;


  font-size: ${props => props.theme.defs.font.size.xxlarge};
  font-weight: ${props => props.theme.defs.font.weight.medium};
  font-variant: small-caps;
`;
