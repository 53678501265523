import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { EditorImageSessionTypes } from "app/arch/editor-instruction/document/states/sessions/editor-image-session";
import { useDocState } from "app/ui/contexts/document";
import useImageViewFit from "./use-image-view-fit";


const useImageViewAutoFit = () => {
  const document = useDocState();
  const {
    fitViewToImage,
    fitViewToAll,
  } = useImageViewFit();

  const imageViewFitAuto = (imageAddr: ContentTypes.ImageAddr) => {
    const imageViewDefiner = document.editorImageSession.getViewDefiner();
    const isAutoFit = ( imageViewDefiner.autoFit.autoMode === EditorImageSessionTypes.ViewDefinerAutomationMode.AUTOMATIC);

    if ( ! isAutoFit) {
      return;
    }

    const fitFnMap: {[key in EditorImageSessionTypes.ViewDefinerFitType]: any} = {
      [EditorImageSessionTypes.ViewDefinerFitType.FIT_TO_IMAGE]: fitViewToImage,
      [EditorImageSessionTypes.ViewDefinerFitType.FIT_TO_ALL]: fitViewToAll,
    }

    const fitFn = fitFnMap[imageViewDefiner.autoFit.fitType];
    fitFn(imageAddr);
  }

  return imageViewFitAuto;
}


export default useImageViewAutoFit;