import styled from "styled-components";
import TopToolbarWrapper from "app/ui-v2/editor-instruction/styles/top-toolbar-wrapper";


export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  height: 100%;
`;

export const MenuTopWrapper = styled.div`
  flex: 0 0 auto;
`;

export const WidgetToolbarWrapper = styled(TopToolbarWrapper)`
  flex: 0 0 auto;
  /*
  TODO - this should be fixed in toolbar/dropdown itself 
    z-index is needed here otherwise
    page will cover dropdown color panel
    when expanded
  */
  position: relative;
  z-index: 1;
`;


export const ContentWrapper = styled.div`
  flex: 1 1 0;
  min-height: 0;
  /* overflow: hidden; */
`;


export const MenuBottomWrapper = styled.div`
  flex: 0 0 auto;
`;
