import React from 'react';
import { useState } from 'react';

import HLS_MenubarItemPanelItemSubsectionComponent from 'lego-v2/menubar/hls/menubar-item-panel-item-subsection';
import * as Types from '../../types';

import { MainWrapper } from './styles';


interface Props extends Types.MenubarItemPanelItemSubsectionProps {
}


export const MenubarItemPanelItemSubsectionComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
  } = props;

  const [sectionOpen, setSectionOpen] = useState(false);

  const handlePointerEnter = (event: React.PointerEvent) => {
    setSectionOpen(true);
  }

  const handlePointerLeave = (event: React.PointerEvent) => {
    setSectionOpen(false);
  }

  return (
    <MainWrapper 
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      <HLS_MenubarItemPanelItemSubsectionComponent
        {...props}
        open={sectionOpen}
      />
    </MainWrapper>
  );
}

