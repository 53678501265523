import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";


const useSectionSelectedSet = () => {
  const document = useDocState();

  const {
    setContentSession
  } = useEditorStatesSetters();

  const selectSection = (props: {
    sectionAddr: Types.SectionAddr | null
    skipStateUpdate?: boolean,
  }) => {
    const {
      sectionAddr,
      skipStateUpdate
    } = props;

    document.contentSession.setSectionSelected(sectionAddr);

    if (skipStateUpdate) {
      return;
    }

    setContentSession();
  }

  return selectSection;
}

export default useSectionSelectedSet;