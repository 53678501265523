import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { HeaderRowsTypes } from "app/arch/editor-instruction/document/states/persistent/header-rows";


const usePageCellSelectedSet = () => {
  const document = useDocState();

  const {
    setHeaderRowsSession
  } = useEditorStatesSetters();


  const selectPageCell = (props: {
    pageCellAddr: HeaderRowsTypes.PageCellAddr | null,
    skipStateUpdate?: boolean,
  }) => {
    const {
      pageCellAddr,
      skipStateUpdate
    } = props;

    document.headerRowsSession.setPageCellSelected(pageCellAddr);
    
    if (skipStateUpdate) {
      return;
    }
    
    setHeaderRowsSession();
  }

  return selectPageCell;
}

export default usePageCellSelectedSet;