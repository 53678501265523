import { PanelControlsItemSectionItemType } from 'lego/components/panel-controls/config';
import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import PanelItemGenerator  from 'app/ui-v2/editor-instruction/panel-items/generator';
import useFunctions from "./use-functions";


const useItems = (props: {
  sectionsEnabled: boolean,
  sectionsIndexing: TypesContent.SectionsIndexing,
  sectionsNamesCSS: React.CSSProperties,
}) => {
  const t = useTranslations();
  const fn = useFunctions();

  const {
    sectionsEnabled,
    sectionsIndexing,
    sectionsNamesCSS
  } = props;

  

  const __sections_enable = () => 
    PanelItemGenerator.getToggleOnOff({
      title: t('enabled'),
      selected: sectionsEnabled,
      onClick: fn.setSectionsEnabled
    });

  const __sections_indexing = () => ({
    title: t('section, indexing'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    skip: ! sectionsEnabled,
    buttons: [
      {
        text: t('indexing, local'),
        value: TypesContent.SectionsIndexing.LOCAL,
        selected: sectionsIndexing === TypesContent.SectionsIndexing.LOCAL,
      },
      {
        text: t('indexing, global'),
        value: TypesContent.SectionsIndexing.GLOBAL,
        selected: sectionsIndexing === TypesContent.SectionsIndexing.GLOBAL,
      },
    ],
    onClick: fn.setSectionsIndexing
  });
    
  const __sectionsNames_padding = () =>
    PanelItemGenerator.getPadding({
      css: sectionsNamesCSS,
      onChange: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: true
        });
      },
      onChangeDone: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      },
    });

  const __sectionsNames_alignHorizontal = () => 
    PanelItemGenerator.getAlignHorizontal({
      css: sectionsNamesCSS,
      onClick: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({ 
          cssUpdate,
          skipUndoSave: false 
        });
      },
      singleItem: true,
    });

  const __sectionsNames_fontSize = () => 
    PanelItemGenerator.getFontSize({
      css: sectionsNamesCSS,
      onChange: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: true
        });
      },
      onChangeDone: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      },
    });

  const __sectionsNames_fontWeight = () =>
    PanelItemGenerator.getFontWeight({
      css: sectionsNamesCSS,
      onChange: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: true
        });
      },
      onChangeDone: (cssUpdate: React.CSSProperties) => { 
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      },
    });
    

  const __sectionsNames_fontVariant = () => 
    PanelItemGenerator.getFontCapslock({
      css: sectionsNamesCSS,
      onClick: (cssUpdate: React.CSSProperties) => {
        fn.updateSectionsCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      }
    });


  //-------------  


  return {
    __sections_enable,
    __sections_indexing,
    __sectionsNames_padding,
    __sectionsNames_alignHorizontal,
    __sectionsNames_fontSize,
    __sectionsNames_fontWeight,
    __sectionsNames_fontVariant,
  }
}

export default useItems;