import React from 'react';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset       from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-selected-reset';
import useCellSelectedSet     from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/cell/use-cell-selected-set';

import { MainWrapper } from './styles';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
  children: React.ReactNode;
}


export const CellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props;

  const setCellSelected = useCellSelectedSet();
  const resetSelected = useSelectedReset();

  const {
    setReleaselogsSession
  } = useEditorStatesSetters();

  const handleSelectCell = (event: React.MouseEvent) => {
    event.stopPropagation(); 
    
    const skipStateUpdate = true;
    resetSelected({ skipStateUpdate });
    setCellSelected({ cellAddr, skipStateUpdate });

    setReleaselogsSession();
  }

  return (
    <MainWrapper onPointerDown={handleSelectCell}>
      { children }
    </MainWrapper>
  );
}
