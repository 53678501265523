import React from 'react';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState } from 'app/ui/contexts/document';
import ProbeHeightComponent from 'app/ui-v2/editor-instruction/views/view-content/components/content/page/probe-height';


interface Props {
  enabled: boolean;
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellProbeComponent: React.FC<Props> = (props: Props) => {
  const {
    enabled,
    cellAddr,
    children 
  } = props;

  const document = useDocState();

  const handleHeightUpdate = (height: number) => {
    const rowAddr = cellAddr;

    const elemSizes = document.contentElementsSizes;
    elemSizes.sections.setSectionRowHeight(
      rowAddr,
      height,
    );
  }

  return (
    <ProbeHeightComponent
      enabled={enabled}
      onHeightUpdate={handleHeightUpdate}
    >
      { children }
    </ProbeHeightComponent>
  );
};
  
