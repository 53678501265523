import produce from 'immer';
import * as State from './state';
import * as Types from './types';
import * as Tools from './tools';


class Size {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }


  /**
   * Setters
   */

  setSize(size: Types.Size) {
    this._state = produce(this._state, draft => {
      draft.size = size;

      const sizeVariant = Tools.getSizeVariant(size.width);
      draft.sizeVariant = sizeVariant;
    });
  }


  /**
   * Getters
   */

  getSize(): Types.Size { 
    return State.getSize(this._state);
  }

  getSizeVariant(): Types.SizeVariant { 
    return State.getSizeVariant(this._state);
  }
}

export default Size;