import React from 'react';
import { useRecoilValue } from 'recoil';

import { SideControlsTypes } from 'lego/components/side-controls';
import SidePanelBaseComponent from 'lego/components/side-controls/side-panel';
import { PanelControlsConfig } from 'lego/components/panel-controls/config';

import { UserPanelTypes } from 'app/arch/home-page-user/states/user-panel';
import { UIHomePageUser_UserPanel } from 'app/ui-v2/home-page/home-page-user/states';
import { useConfigTheme }   from '../configs';
import { useConfigUser }    from '../configs';
import { useConfigBilling } from '../configs';


type Props = SideControlsTypes.PropLeftOrRight;


export const SidePanelComponent: React.FC<Props> = (props: Props) => {
  const {
    left,
    right,
  } = props;

  const panel = useRecoilValue(UIHomePageUser_UserPanel.panel);

  const getConfigUser    = useConfigUser();
  const getConfigBilling = useConfigBilling();
  const getConfigTheme   = useConfigTheme();

  const Configs: {[config in UserPanelTypes.PanelItem]: () => PanelControlsConfig} = {
    [UserPanelTypes.PanelItem.USER_PROFILE]: getConfigUser,
    [UserPanelTypes.PanelItem.BILLING]:      getConfigBilling,
    [UserPanelTypes.PanelItem.THEME]:        getConfigTheme,
  }

  const getConfig = Configs[panel.selected];
  const config = getConfig();

  return (
    <SidePanelBaseComponent
      config={config}
      visible={panel.visible}
      
      left={left}
      right={right}      
    />
  );
}
  
