import { useContext } from "react";
import Context from "../components/document-states/document-context-scale/context";


const useDocumentScaleWatch = () => {
  const scale = useContext(Context);
  return scale;
}


export default useDocumentScaleWatch;