import * as Types    from './types';
import * as Defaults from './defaults';


/**
 * State
 */
export type State = {
  table: Types.Table,
  page:  Types.Page,
};


/**
 * Getters
 */


/**
 * Table
 */
export const getTableHeader    = (state: State) => state.table.header;
export const getTableHeaderCSS = (state: State) => state.table.header.css;


/**
 * Page
 */
export const getPageLayout  = (state: State) => state.page.layout;
export const getPageMargins = (state: State) => state.page.margins;


/**
 * Create initial state
 */

export const createInitialState = () => {
  const state: State = {
    table: Defaults.getTable(),
    page: Defaults.getPage(),
  }

  return state;
}
