import styled from "styled-components";
import TextBase from 'lego/styles/text';


export const TextWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: ${props => props.theme.button.size.primary.height}px;
  overflow: hidden;
  box-sizing: border-box;

  border-radius: ${props => props.theme.defs.border.radius.normal};
  background: ${props => props.theme.defs.colors.background.eight};
`;


export const Text = styled(TextBase)`
`;
