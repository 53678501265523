type Callback = () => void;

export class TokenRefresher {
  private _timer: any | null;
  private _callback: Callback;

  constructor(callback: Callback) {
    this._timer = null;
    this._callback = callback;
  }

  public schedule(seconds: number) {
    if (this._timer !== null) {
      clearInterval(this._timer);
    }

    this._timer = setTimeout(() => {
      this._timer = null;
      this.refreshToken();
    }, seconds)
  }

  public stop() {
    if (this._timer !== null) {
      clearInterval(this._timer);
      this._timer = null;
    }
  }

  private refreshToken() {
    this._callback();
  }
}

