import React from 'react';
import { useRecoilValue } from 'recoil';
import jtl from 'tools/jtl';

import { PanelControlsConfig }              from 'lego/components/panel-controls/config';
import { PanelControlsItemSectionItemType } from 'lego/components/panel-controls/config';

import { settings } from 'app/configs';
import { useDocState }     from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';

import useEditorStatesSetters from '../hooks/use-editor-states-setters';
import PanelItemGenerator     from '../panel-items/generator';


const usePanelConfigTable = () => {
  const document = useDocState();
  const t = useTranslations();

  const tableHeaderCSS = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);

  const { 
    setViewsCommon 
  } = useEditorStatesSetters();

  const handleTableHeaderCSSUpdate = (cssUpdate: React.CSSProperties) => {
    document.viewsCommon.updateTableHeaderCSS (cssUpdate);
    setViewsCommon();
    document.saveUndo();
  }

  const handleTableHeaderBackground = (background: string) => {
    document.viewsCommon.updateTableHeaderCSS ({background});
    setViewsCommon();
    document.saveUndo();
  };

  const handleTableHeaderColor = (color: string) => {
    document.viewsCommon.updateTableHeaderCSS ({color});
    setViewsCommon();
    document.saveUndo();
  };
  
  const handleTableHeaderFontSizeChange = (fontSize: number) => {
    document.viewsCommon.updateTableHeaderCSS ({
      fontSize: `${fontSize}px`
    });
    setViewsCommon();
    document.saveUndo();
  }

  const handleTableHeaderFontSizeChangeDone = (fontSize: number) => {
    handleTableHeaderFontSizeChange(fontSize);
    document.saveUndo();
  }
  
  const handleTableHeaderFontWeightChange = (fontWeight: number) => {
    document.viewsCommon.updateTableHeaderCSS ({
      fontWeight: `${fontWeight * 100}`
    });
    setViewsCommon();
    document.saveUndo();
  }

  const handleTableHeaderFontWeightChangeDone = (fontWeight: number) => {
    handleTableHeaderFontWeightChange(fontWeight);
    document.saveUndo();
  }

  const handleTableHeaderFontPaddingChange = (padding: number) => {
    document.viewsCommon.updateTableHeaderCSS ({
      padding: `${padding}px`
    });
    setViewsCommon();
    document.saveUndo();
  }

  const handleTableHeaderFontPaddingChangeDone = (padding: number) => {
    handleTableHeaderFontPaddingChange(padding);
    document.saveUndo();
  }


  //--------------------
  //
  // Config definition
  //
  
  const getConfig = () => {

    const __tableHeader_padding = () => (
      {
        title: t('padding'),
        type: PanelControlsItemSectionItemType.SLIDER,
        onChange: handleTableHeaderFontPaddingChange,
        onChangeDone: handleTableHeaderFontPaddingChangeDone,
        min: settings.printView.table.header.padding.min, 
        max: settings.printView.table.header.padding.max, 
        value: jtl.css.valueToNumber(tableHeaderCSS.padding?.toString()),
      }
    );
    
    const __tableHeader_fontSize = () => (
      {
        title: t('size'),
        type: PanelControlsItemSectionItemType.SLIDER,
        onChange: handleTableHeaderFontSizeChange,
        onChangeDone: handleTableHeaderFontSizeChangeDone,
        min: settings.printView.table.header.font.size.min, 
        max: settings.printView.table.header.font.size.max,
        value: jtl.css.valueToNumber(tableHeaderCSS.fontSize?.toString()),
      }
    );

    const __tableHeader_fontWeight = () => (
      {
        title: t('font weight'),
        type: PanelControlsItemSectionItemType.SLIDER,
        onChange: handleTableHeaderFontWeightChange,
        onChangeDone: handleTableHeaderFontWeightChangeDone,
        min: 1, 
        max: 9,
        value: jtl.css.valueToNumber(tableHeaderCSS.fontWeight?.toString()) / 100,
      }
    );
    const __tableHeader_fontCapsLock = () => 
    PanelItemGenerator.getFontCapslock({
      css: tableHeaderCSS,
      onClick: handleTableHeaderCSSUpdate
    });

    
    const __tableHeader_fontColor = () => (
      {
        title: t('font color'),
        type: PanelControlsItemSectionItemType.COLOR_PICKER,
        onColorSelected: handleTableHeaderColor,
        color: tableHeaderCSS.color,
      }
    );

    const __tableHeader_background = () => (
      {
        title: t('background'),
        type: PanelControlsItemSectionItemType.COLOR_PICKER,
        onColorSelected: handleTableHeaderBackground,
        color: tableHeaderCSS.background,
      }
    );

    

    const config: PanelControlsConfig = {
      items: [
        {
          title: t('table header'),
          sections: [
            {
              // title: t('table header'),
              subsections: [
                {
                  title: t("layout"),
                  items: [
                    __tableHeader_padding(),
                  ]
                },
                {
                  title: t("text"),
                  items: [
                    __tableHeader_fontSize(),
                    __tableHeader_fontWeight(),
                    __tableHeader_fontCapsLock(),
                    __tableHeader_fontColor(),
                    __tableHeader_background(),
                  ]
                },
              ],              
            },
          ]
        },
      ]
    } // config

    return config;
  }

  return getConfig;
}
  
export default usePanelConfigTable;
