import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { ContentTools }from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes }from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentRowsSelectedState as State } from 'app/arch/editor-instruction/document/states/sessions/content-rows-selected';


/**
 * Atom
 */

export const state = atom<State.State>({
  key: "ei_docContent_contentRowsSelected",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const isSelected = selectorFamily({
  key: "ei_docContent_contentRowsSelected__isSelected",
  get: (rowAddr: ContentTypes.RowAddr) => ({ get }) => {
    const stateObj = get(state);
    const rowKey = ContentTools.getRowKey(rowAddr);
    const selected = State.isSelected(stateObj, rowKey);
    return selected;
  }
});

export const isDragOver = selectorFamily({
  key: "ei_docContent_contentRowsSelected__isDragOver",
  get: (rowAddr: ContentTypes.RowAddr) => ({ get }) => {
    const stateObj = get(state);
    const rowKey = ContentTools.getRowKey(rowAddr);
    const selected = State.isDragOver(stateObj, rowKey);
    return selected;
  }
});

