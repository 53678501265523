import React from 'react';
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import useContextMenu    from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useRowMenu        from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/row/use-row-menu';
import useRowSelectedSet from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/row/use-row-selected-set';

import { MainWrapper } from './styles';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
  children: React.ReactNode;
}


export const CellIndexContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;
  
  const getMenuConfig = useRowMenu();
  const { showMenu  } = useContextMenu();
  const setRowSelected = useRowSelectedSet();


  const handleContextMenu = (event: React.MouseEvent) => {
    setRowSelected({ rowAddr: cellAddr });
    
    const config = getMenuConfig(cellAddr);
    showMenu({ event, config });
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}

