import React from 'react';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { useIconsRepo } from 'app/ui-v2/editor-instruction/hooks/use-icons-repo';
import MarkerViewSignBaseComponent from 'app/ui-v2/editor-markers/hls/marker-views/marker-view-sign-base';
import { Scaler } from './styles';


interface Props {
}


export const SignIconInfoComponent: React.FC<Props> = (props: Props) => {
  const IconsRepo = useIconsRepo();

  const iconDescriptor = IconsRepo.getIcon({
    column: 705,
    row: 8,
  });
  const Icon = iconDescriptor.Icon;

  return (
    <Scaler>
      <MarkerViewSignBaseComponent
        signType={RepoMarkersTypes.MarkerType.SIGN_INFO}
        Icon={Icon}
      />
    </Scaler>
  );
}
