import { useRef } from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import deepEqual from 'deep-equal';
import { useDebouncedCallback } from 'use-debounce';
import { useThrottledCallback } from 'use-debounce';

import Logger from 'libs/debug';

import { Size } from 'app/arch/types';
import { Page } from 'app/arch/print/page';

import * as Slicer from 'app/arch/editor-instruction/document/slicers/content/slicer';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';
import { SliceRequestTypes } from 'app/arch/editor-instruction/document/states/slicing/content/slice-request';

import { useDocState }   from 'app/ui/contexts/document';
import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';
import { UIState_ContentSliceRequest } from 'app/ui/states/editor-instruction';

import Settings from '../../configs/settings';
import useSlicerState from './use-slicer-state';


const SLICE_DEBOUNCE_DELAY = Settings.slicer.slice.debounce;
const SLICE_THROTTLE_DELAY = Settings.slicer.slice.throttle;


type SliceRequest = SliceRequestTypes.SliceRequest;

interface Props {
  onSliced: (
    pages: SlicerTypes.Pages, 
    sliceRequest: SliceRequest
  ) => void;
}


export const useSlicer = (props: Props) => {
  const {
    onSliced,
  } = props;
  const log = Logger.getContentSlicer();

  const document   = useDocState();
  const isPrintout = useIsPrintout();
  const prevPagesRef = useRef<SlicerTypes.Pages | []>([]);

  // Slicing in progress is for 
  // ViewPrintout - so it knows
  // when slicing has finished 
  // so it can generate pdf.
  const slicingInProgress = useSlicerState();

  const sliceRequest = useRecoilValue(UIState_ContentSliceRequest.sliceRequest);
  

  const slice = (sliceRequest: SliceRequest) => {

    slicingInProgress();

    log.log("[ContentSlicer] Slicing");
    // On start needs to be called before
    // reading states, as it can alert 
    // changes below.
    sliceRequest.onBeforeSlice?.();


    // These states could be used from recoil states above.
    // However if we get custom slice request which would change
    // any of the states before slicing is done, then using
    // states from recoil would use previous (invalid) value.
    // For now I can't think of situation which would cause this 
    // bug. 
    // Just to understand better - if for example one of 
    // releaselogs was delayed in `onStart` function from 
    // custom slice request - then if we use releaslogs state 
    // from recoil - it would be invalid. Saying that - we
    // don't delete releaelogs every - it is just an example.
    const pageLayout  = document.viewsCommon.getPageLayout();
    const pageMargins = document.viewsCommon.getPageMargins();
    
    const docInfoVisible         = document.contentView.getHeader().meta.visible;
    const docInternalVisible     = document.contentView.getHeader().internal.visible;
    const docCustomFieldsVisible = document.contentView.getHeader().fields.visible;
    const docMarkersVisible      = document.contentView.getHeader().markers.visible;

    // These states are accessed directly as they are
    // not populated through recoil.
    const docContent    = document.content;
    const elementsSizes = document.contentElementsSizes;

    const pageSize = Page.getBodySizePx(
      pageLayout.format, 
      pageLayout.orientation, 
      pageMargins
    ) as Size;


    const pages: SlicerTypes.Pages = Slicer.slice({
      isPrintout,
      
      pageSize,

      docInfoVisible,
      docInternalVisible,
      docCustomFieldsVisible,
      docMarkersVisible,

      docContent,
      elementsSizes,

    });

    sliceRequest.onAfterSlice?.();

    const areEqual = deepEqual(pages, prevPagesRef.current, {strict: false});
    if (areEqual) {
      log.warn("Content changed - but same layout after reslice");
      return;
    } 
    
    // console.log("Layout changed after reslice");
    prevPagesRef.current = pages;
    onSliced(pages, sliceRequest);
  }



  //----------------

  
  const __slice_throttled = useThrottledCallback<(sliceRequest: SliceRequest) => void>(slice, SLICE_THROTTLE_DELAY);
  const __slice_debounced = useDebouncedCallback<(sliceRequest: SliceRequest) => void>(__slice_throttled, SLICE_DEBOUNCE_DELAY);

  const slice_delayed = (sliceRequest: SliceRequest) => {
    slicingInProgress();
    __slice_debounced(sliceRequest);
  }
  

  //----------------

  useEffect(() => {
    log.log(`[ContentSlicer] Slice request, delayed=${sliceRequest.delayed}`);

    const sliceFn = (
      sliceRequest.delayed ?
      slice_delayed :
      slice
    );

    sliceFn(sliceRequest);
  }, [sliceRequest]);
}
