import styled from 'styled-components';

import { StyledFrameBase as StyledFrameOutBase } from 'lego-v2/frame-resize/ui/style-frame-ctrls-rectangular';
import DraggerBase from "lego-v2/dragger/ui/styles";


interface FrameProps {
  $left: number;
  $top: number;
  $width: number;
  $height: number;
}

export const FrameBox = styled.div.attrs<FrameProps>(props => ({
  style: {
    left:   `${props.$left}px`,
    top:    `${props.$top}px`,
    width:  `${props.$width}px`,
    height: `${props.$height}px`,
  }
}))<FrameProps>`
  position: absolute;
  pointer-events: none;
  /* background-color: rgba(255, 0, 0,0.2); */
`;

export const Dragger = styled(DraggerBase)`
  height: 100%;
  /* height: 0px; */
  /* width: 0px; */
  /* background: rgba(0, 255, 0, 0.7); */
`;


export const Frame = styled(StyledFrameOutBase)`
`;
