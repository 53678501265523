import { useEffect } from 'react';

import { query } from 'app/arch/backend';
import { useQueryManual } from "app/arch/backend/use-query-manual";
import useProductsStateSetters from './use-products-state-setter';
import usePrices from "./use-prices";


const usePricesLoad = () => {
  const prices = usePrices();
  const { setPrices } = useProductsStateSetters();

  const { 
    fetch: loadPrices,
    data: response,
    error,
  } = useQueryManual({
    query: query.getPrices(),
  });

  useEffect(() => {
    if ( ! response ) {
      return;
    }

    const pricesRaw = response.prices;
    prices.loadPrices(pricesRaw);
    setPrices();
  }, [response]);


  return loadPrices;
}


export default usePricesLoad;