import * as Types from './types';
import * as Defaults from './defaults';


export type State = {
  viewsLoaded: Types.ViewsLoaded,
  pages: Types.Pages,
};


//
// Getters
//
export const getViewsLoaded = (state: State) => state.viewsLoaded;
export const getPages = (state: State) => state.pages;


//----------------------------
// Create initial state
//
export const createInitialState = (): State => {
  const initState: State = {
    viewsLoaded: Defaults.getViewsLoaded(),
    pages: Defaults.getPages(),
  }

  return initState;
}
