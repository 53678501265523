import React from 'react';
import { useEffect } from 'react';
import { useRef    } from 'react';
import { useState  } from 'react';

import DraggerComponent      from 'lego-v2/dragger/ui';
import FrameResizeComponent  from 'lego-v2/frame-resize/ui';
import { FrameTargetUpdate } from 'lego-v2/frame-resize/arch';
import { DraggerUpdate }     from 'lego-v2/dragger/arch';

import { Size     } from 'app/arch/types';
import { Position } from 'app/arch/types';

import WindowButtonsComponent from './components/window-buttons';
import WindowContextComponent from './components/window-context';

import * as Types from './types';

import { Content           } from './styles';
import { StyleWindowBorder } from './styles';
import { Title             } from './styles';
import { StyledWindow      } from './styles';
import { StyledWindowFrame } from './styles';
import { ZIndexBarrier     } from './styles';
import { MainWrapper       } from './styles';
import { Topbar            } from './styles';


const MIN_WIDTH = 100;
const MIN_HEIGHT = 200;
const VISIBLE_LIMIT = 120;


interface Props {
  top: number;
  left: number;
  width: number;
  height: number;
  
  widthMin?: number;
  heightMin?: number;

  maximized?: boolean;
  title?: string;

  onClose?: () => void;
  onUpdate?: (update: Types.Update) => void;
  onUpdateDone?: (update: Types.Update) => void;

  dataTest?: string;

  children: React.ReactNode;
};


export const WindowComponent: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    onUpdate,
    onUpdateDone,
    maximized: maximized_,
    children,
  } = props;

  const title = props.title || '';
  const maximized = maximized_ !== undefined ? maximized_ : false;
  const dataTest = props.dataTest || null;

  const [size, setSize]         = useState<Size>([0, 0]);
  const [position, setPosition] = useState<Position>([0, 0]);
  const [isMaximized, setIsMaximized] = useState(maximized);

  const widthMin  = props.widthMin  !== undefined ? props.widthMin  : MIN_WIDTH;
  const heightMin = props.heightMin !== undefined ? props.heightMin : MIN_HEIGHT;

  const widthInit  = props.width  >= widthMin  ? props.width  : widthMin;
  const heightInit = props.height >= heightMin ? props.height : heightMin;

  const sizeUserDef     = useRef([widthInit, heightInit]);
  const positionUserDef = useRef([props.left, props.top]);


  useEffect(() => {
    if (maximized) {
      sizeUserDef.current     = [widthInit, heightInit];
      positionUserDef.current = [props.left, props.top];
      fitToBrowserSize();
    }
    else {
      setSize([widthInit, heightInit]);
      setPosition([props.left, props.top]);
    }

    if (isMaximized !== maximized) {
      setIsMaximized(maximized);
    }

  }, []);
  
  const fitToBrowserSize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (
      width !== size[0]  ||
      height !== size[1] ||
      position[0] !== 0  ||
      position[1] !== 0
    ) {
      setSize([width, height]);
      setPosition([0, 0]);          
    }
  }

  const setUserDefSize = () => {
    // let width = 0;
    // if (sizeUserDef.current[0] > window.innerWidth) {
    //   width = window.innerWidth - 100;
    // }
    // else {
    //   width = sizeUserDef.current[0];
    // }
    setSize([sizeUserDef.current[0], sizeUserDef.current[1]]);
    setPosition([positionUserDef.current[0], positionUserDef.current[1]]);          
  }

  const saveUserDefSize = () => {
    sizeUserDef.current = [...size];
    positionUserDef.current = [...position];
  }

  useEffect(() => {
    function handleResize() {
      if (isMaximized) {
        fitToBrowserSize();
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [isMaximized]);


   
  //-------------------
  // Window size toggle 
  //
  const handleToggleMaxSize = () => {
    const prevValue = isMaximized;

    setIsMaximized(! prevValue);
    if ( ! prevValue) {
      saveUserDefSize();
      fitToBrowserSize();
    }
    else {
      setUserDefSize();
    }

    if (onUpdateDone) {
      onUpdateDone({
        maximized: ! prevValue,
      });
    }
  }

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    setValidPosition(update.position);

    if (onUpdate) {
      onUpdate({ position: update.position});
    }
  }

  const setValidPosition = (position_: Position) => {
    const reduction = [0,0];
    // Left
    const leftLimit = -1 * size[0] + VISIBLE_LIMIT;
    if (position_[0] < leftLimit) { 
      // reduction[0] = -1 * position_[0];
      position_[0] = leftLimit; 
    }

    // Top
    if (position_[1] < 0) { 
      reduction[1] = -1 * position_[1];
      position_[1] = 0; 
    }

    // Right
    const rightLimit = window.innerWidth - VISIBLE_LIMIT;
    if ( position_[0] > rightLimit) {
      position_[0] = rightLimit;
    }
    
    // Bottom
    const bottomLimit = window.innerHeight - VISIBLE_LIMIT;
    if ( position_[1] > bottomLimit) {
      position_[1] = bottomLimit;
    }

    setPosition(position_);
    return reduction;
  }

  const handleDraggerUpdateDone = (update: DraggerUpdate) => {
    handleDraggerUpdate(update);
    if (onUpdateDone) {
      onUpdateDone({
        position: update.position,
      });
    }
  }

  const setValidSize = (size: Size) => {
    if (size[0] < widthMin) { size[0] = widthMin; }
    if (size[1] < heightMin) { size[1] = heightMin; }
    setSize(size);
  }

  const handleFrameUpdate = (update: FrameTargetUpdate) => {
    // TODO 
    // Use reducer instead of two states
    let reduction: number[] = [0, 0];
    if (update.position && update.size) {

      if (update.size[1] >= heightMin && update.size[0] >= widthMin) {
        reduction = setValidPosition(update.position); 
        const size: Size = [
          update.size[0] - reduction[0],
          update.size[1] - reduction[1],
        ];
        setValidSize(size); 
      }
      else if (update.size[1] >= heightMin) {
        reduction = setValidPosition([
          position[0],
          update.position[1]
        ]); 

        const size_: Size = [
          size[0],
          update.size[1] - reduction[1],
        ];
        setValidSize(size_); 
      }

      else if (update.size[0] >= widthMin) {
        reduction = setValidPosition([
          update.position[0], 
          position[1]
        ]); 
        const size_: Size = [
          update.size[0] - reduction[0],
          size[1],
        ];
        setValidSize(size_); 
      }
      else {
        return;
      }
    }

    if (onUpdate) {
      onUpdate({
        size: update.size,
        position: update.position,
      });
    }
  }

  const handleFrameUpdateDone = (update: FrameTargetUpdate) => {
    handleFrameUpdate(update);
    if (onUpdateDone) {
      onUpdateDone({
        size: update.size,
        position: update.position,
      });
    }
  }

  const handleClose = () => {
    onClose?.();
  }
  
  return (
    <MainWrapper
      style={{
        width: `${size[0]}px`,
        height: `${size[1]}px`,
        left: `${position[0]}px`,
        top: `${position[1]}px`,
      }}
    >
      <ZIndexBarrier>
        <StyleWindowBorder maximized={isMaximized}>
          <StyledWindow maximized={isMaximized}>
          
            <DraggerComponent
              position={position}

              onUpdate={handleDraggerUpdate}
              onUpdateDone={handleDraggerUpdateDone}

              disabled={isMaximized}
            >
              <Topbar 
                data-test={`${dataTest}__topbar`}
                onDoubleClick={handleToggleMaxSize}
              >
                <Title>
                  { title }
                </Title>

                <WindowButtonsComponent 
                  dataTest={dataTest}
                  isMaximized={isMaximized}
                  onToggleMaximize={handleToggleMaxSize}
                  onClose={handleClose}
                />
              </Topbar>
            </DraggerComponent> 

            <Content>
              <WindowContextComponent
                size={size}
              >
              { children }
              </WindowContextComponent>
            </Content>
          
          </StyledWindow>
        </StyleWindowBorder>

        {
          ! isMaximized &&
          <FrameResizeComponent
            minSize={[80, 80]}
            size={size}
            position={position}

            onUpdate={handleFrameUpdate}
            onUpdateDone={handleFrameUpdateDone}
            frameComponent={StyledWindowFrame}
          />
        }
      </ZIndexBarrier>
    </MainWrapper>
  );
}
