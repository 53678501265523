import styled from "styled-components";


const TopToolbarSeparator = styled.div`
  min-width: 1px;
  max-width: 1px;
  height: 100%;
  background: ${props => props.theme.button.border.color.primary};
`;


export default TopToolbarSeparator;