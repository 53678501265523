import { ThemeDefProps } from "../types";


export const themeDefs_headerNormal = (props: ThemeDefProps) => {
  const { colors, accentColor, border, padding, font } = props;

  const header = {
    color: {
      primary: colors.color.primary
    },

    font: {
      size: font.size.normal,
      weight: font.weight.large
    }
  };
    
  return header;
}

