import { TooltipComponent } from '../tooltip/Tooltip';


export const TooltipFollowComponent = (props: any) => {

  return (
    <TooltipComponent 
      followCursor={true}
      PopperProps={{
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
        },
      }}
      {...props}
      noarrow
    >
      { props.children }
    </TooltipComponent>
  );
}

