import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';

import { Icon }         from "app/ui/icons/icons";
import { useDocState }  from "app/ui/contexts/document";
import useContextMenu   from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useEditorStatesSetters from '../../../../../hooks/use-editor-states-setters';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';


const useCellIdxMenu = () => {
  const t = useTranslations();
  const document = useDocState();
  
  const {
    setContent
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();

  const addRowAbove = (srcRowAddr: Types.RowAddr) => {
    document.content.addRowAbove(srcRowAddr);
    document.saveUndo();
    setContent();

    hideMenu();
  }

  const addRowBelow = (srcRowAddr: Types.RowAddr) => {
    document.content.addRowBelow(srcRowAddr);
    document.saveUndo();
    setContent();

    hideMenu();
  }

  const deleteRow = (rowAddr: Types.RowAddr) => {
    document.content.deleteRow(rowAddr);
    document.saveUndo();
    setContent();

    hideMenu();
  }

  const getConfig = (cellAddr: Types.CellAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('row, add above'),
                iconComponent: Icon.CreateAbove,
                onClick: (event: React.MouseEvent) => addRowAbove(cellAddr),
              }
            },
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('row, add below'),
                iconComponent: Icon.CreateBelowe,
                onClick: (event: React.MouseEvent) => addRowBelow(cellAddr),
              }
            },
          ],
        },
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('row, delete'),
                iconComponent: Icon.Delete,
                onClick: (event: React.MouseEvent) => deleteRow(cellAddr),
                variant: "delete"
              }
            },
          ],
        },
      ]
    }

    return config;
  }


  const hook = (cellAddr: Types.CellAddr) => {
    const config = getConfig(cellAddr);
    return config;
  }

  return hook;
}


export default useCellIdxMenu;