import { LogoImageTool } from "app/arch/editor-logo/logo-image";

import { LogoImage } from "app/ui-v2/editor-logo/types";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";



interface Props {
  logoImage: LogoImage;
}

const useLogoSmartFit = (props: Props) => {
  const {
    logoImage,
  } = props;

  const docState = useDocState();

  const { 
    setHeaderMeta,
  } = useEditorStatesSetters();

  const smartFitLogo = () => {
    const logoImageTool = new LogoImageTool(logoImage);

    const { 
      position, 
      scale 
    } = logoImageTool.fitToView();

    docState.headerMeta.updateLogoView({scale});
    docState.headerMeta.updateLogoView({position});
    docState.saveUndo();

    setHeaderMeta();
  }

  return smartFitLogo;
}

export default useLogoSmartFit;