import styled from "styled-components";


export const ScrollTester = styled.div`
  position: fixed;
  visibility: hidden;
  top: -999999px;
  left: -999999px;

  overflow: scroll;
  width: 100px;
  height: 100px;
`;
