import styled from "styled-components";
import Scrollbar from "./scrollbar";


const ScrollbarSmall = styled(Scrollbar)`
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.defs.colors.scrollbar.thumb.secondary};
  }

  &::-webkit-scrollbar {
    width:  ${props => props.theme.scrollbar.width.small};
    height: ${props => props.theme.scrollbar.width.small};
  }
`;

export default ScrollbarSmall;