import { BooleanType } from "./types";
import { NumberType } from "./types";


export const unpackBoolean = (value: BooleanType): boolean => {
  switch (typeof value) {
    case 'undefined' : return false;
    case 'boolean'   : return value;
    case 'function'  : return value();
    default: {
      throw new Error(`Invalid type ${typeof value}`);
    }
  }
}

export const unpackNumber = (value: NumberType): number => {
  switch (typeof value) {
    case 'number'   : return value;
    case 'function'  : return value();
    default: {
      throw new Error(`Invalid type ${typeof value}`);
    }
  }
}
