import { loadStripe } from '@stripe/stripe-js';
import environment from 'app/environment';


class StripService {
  private static instance: StripService;
  private _promise: any;

  private constructor() {
    this._promise = loadStripe(environment.stripe.publicKey);
  }

  get promise() { return this._promise; }
  
  public static getInstance(): StripService {
    if ( ! StripService.instance) {
      StripService.instance = new StripService();
    }
    return StripService.instance;
  }
}

const Strip = StripService.getInstance();

export default Strip;
