import { useRecoilValue } from 'recoil';

import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content } from 'app/ui/states/editor-instruction';


export const useImageIdx = (imageAddr: TypesContent.ImageAddr) => {
  const rowIdxSection  = useRecoilValue(UIState_Content.rowIdxSection(imageAddr));
  const rowIdxGlobal   = useRecoilValue(UIState_Content.rowIdxGlobal(imageAddr));
  const imageIdx       = useRecoilValue(UIState_Content.cellImages_image_imageIdx(imageAddr));

  return { 
    rowIdxSection, 
    rowIdxGlobal, 
    imageIdx
  };
}
