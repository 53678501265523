import { useRef }    from "react";
import { useState }  from "react";
import { useEffect } from "react";

import { Icon } from "app/ui/icons/icons";
import { EditorTextDefaults } from "app/ui/components/editor-txt";
import FontSizeItemComponent from "./font-size-item";

import { MainWrapper } from "./styles";
import { StyledPanel } from "./styles";
import { InputCtrlsWrapper } from "./styles";
import { PanelWrapper } from "./styles";
import { Clipper } from "./styles";
import { StyledFontSizeInput } from "./styles";
import { ButtonGroupLeft } from "./styles";
import { ButtonGroupRight } from "./styles";


interface Props {
  togglePanelVisible: () => void,
  isPanelVisible: boolean,
  fontSize: number,
  setFontSize: (size: number) => void,
  skipSelectionUpdate: () => void,
}


export const DropdownFontSizeComponent: React.FC<Props> = (props: Props) => {
  const FontDefaults = EditorTextDefaults.getFont();

  const [fontSizeValue, setFontSizeValue] = useState<number | null>(props.fontSize);
  const inputRef = useRef<HTMLInputElement>(null);
  const mainWrapperRef = useRef<HTMLDivElement>(null);
  

  useEffect(() => {
    if (props.fontSize === null) {
      // TODO
      // return;
    }
    setFontSizeValue(props.fontSize);
  }, [props.fontSize]);

  const renderItems = () => {
    // const sizes = jtl.core.range(8, 61);
    const sizes = [6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 24, 36, 48, 60];
    const items = sizes.map((size, idx) => {
      const sizeTxt = `${size}`;

      const shouldScroll = () => {
        // One of predefined sizes
        //
        if (size === props.fontSize) {
          return true;
        }
        if (sizes.includes(props.fontSize) ) {
          return false;
        }

        // User input size
        // Scroll to closest bigger size in list
        let i = 0;
        for (i=0; i<sizes.length; i++) {
          if (sizes[i] > props.fontSize) {
            break;
          }
        }

        // Bigger then last one, 
        if (i === sizes.length) i--;
        if (sizes[i] === size) {
          return true;
        }

        return false;
      }

      return (
        <FontSizeItemComponent
          key={sizeTxt}
          sizeTxt={sizeTxt}
          selected={size === props.fontSize}
          onClick={(event: React.MouseEvent) => { 
            props.skipSelectionUpdate();
            props.setFontSize(size); 
          }}
          scrollTo={shouldScroll()}
        />
      )
    })

    return items;
  }

  const increaseFontSize = () => {
    if (props.fontSize === null) {
      props.setFontSize(FontDefaults.size.init);
      return;
    }

    props.setFontSize(props.fontSize + 1)
  }

  const decreaseFontSize = () => {
    if (props.fontSize === null) {
      props.setFontSize(FontDefaults.size.init);
      return;
    }

    if (props.fontSize <= FontDefaults.size.min) return;
    props.setFontSize(props.fontSize - 1)
  }

  const handleInputChange = (e: any) => {
    // console.log(e.target.value);

    if (e.target.value === "") {
      setFontSizeValue(null);
      return;
    }

    const value = parseInt(e.target.value, 10)
    if (isNaN(value)) {
      // console.log('nan ', value)
    }
    else {
      setFontSizeValue(value);
    }
  }

  const cancel = () => {
    setFontSizeValue(props.fontSize);
    props.setFontSize(props.fontSize);
  }

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      // console.log("setting ", fontSizeValue);
      if ( fontSizeValue !== null ) {
        props.setFontSize(fontSizeValue!);
      }
      else {
        cancel();
      }
      e.preventDefault();
      e.stopPropagation();
    }
    else if (e.key === "Escape") {
      cancel();

      e.preventDefault();
      e.stopPropagation();
    }
  }

  // const handleLostFocus = (e: any) => {
  //   const lostFocusToChild = mainWrapperRef.current!.contains(e.relatedTarget);
  //   if ( ! lostFocusToChild ) {
  //     // hidePanel();
  //   }
  // }

  const showPanel = () => {
    if (props.isPanelVisible) return;
    props.togglePanelVisible();
  }

  // const hidePanel = () => {
  //   if (!props.isPanelVisible) return;
  //   props.togglePanelVisible();
  // }


  return (
    <MainWrapper
      // onBlur={handleLostFocus}
      ref={mainWrapperRef}
    >
      <InputCtrlsWrapper>
         <ButtonGroupLeft 
          onClick={decreaseFontSize}
          disabled={props.fontSize !== null && props.fontSize <= FontDefaults.size.min}
        >
          <Icon.Minus />
        </ButtonGroupLeft>
        
        <StyledFontSizeInput
          onClick={showPanel}

          value={fontSizeValue !== null ? fontSizeValue : ""}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={inputRef}
        >
          
        </StyledFontSizeInput>

        <ButtonGroupRight onClick={increaseFontSize}>
          <Icon.Plus />
        </ButtonGroupRight>

      </InputCtrlsWrapper>

      {
      props.isPanelVisible &&
      <PanelWrapper>
        <StyledPanel
          onClick={(event: React.MouseEvent) => { 
            event.stopPropagation();
          }}
        >
          <Clipper>
            { renderItems() }
          </Clipper>
        </StyledPanel>

      </PanelWrapper>
      }
    </MainWrapper>
  );
}