import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { Icon }            from "app/ui/icons/icons";
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useCellMarkerCreate from './use-cell-marker-create';


const useCellMarkersMenu = () => {
  const t = useTranslations();
  const {
    hideMenu
  } = useContextMenu();

  const createCellMarker = useCellMarkerCreate();

  const createMarker = (cellAddr: Types.CellAddr) => {
    createCellMarker(cellAddr);
    hideMenu();
  }

  const getConfig = (cellAddr: Types.CellAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, new'),
                iconComponent: Icon.Create,
                onClick: (event: React.MouseEvent) => createMarker(cellAddr),
              }
            },      
          ],
        },
      ]
    }

    return config;
  }


  const hook = (cellAddr: Types.CellAddr) => {
    const config = getConfig(cellAddr);
    return config;
  }

  return hook;
}


export default useCellMarkersMenu;