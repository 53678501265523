import { useRecoilValue } from 'recoil';
import { UIState_ContentView } from 'app/ui/states/editor-instruction';


const useStates = () => {
  const markersVisible = useRecoilValue(UIState_ContentView.headerMarkersVisible);

  return {
    markersVisible,
  }
}


export default useStates;