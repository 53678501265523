import * as Types from './types';


/**
 * 
 * Subscription
 * 
 */

export const getSubscriptionKey = (
  subscriptionAddr: Types.SubscriptionAddr
): string => (
  `${subscriptionAddr.subscriptionId}`
);

export const createSubscriptionAddr = (
  subscriptionStripeId: string
): Types.SubscriptionAddr => {
  const subscriptionAddr = { subscriptionId: subscriptionStripeId };
  return subscriptionAddr;
}   

export const compareSubscriptionAddr = (
  srcSubscriptionAddr: Types.SubscriptionAddr,
  dstSubscriptionAddr: Types.SubscriptionAddr,
): boolean => (
  srcSubscriptionAddr.subscriptionId === dstSubscriptionAddr.subscriptionId
);

