import React from 'react';

import { useDocState } from 'app/ui/contexts/document';

import ToolbarDummyComponent      from './toolbar-dummy';
import ToolbarWidgetTextComponent from './toolbar-widget-text';

import { ToolbarProps } from './types';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';


interface Props {
  widgetEdited: ContentTypes.WidgetAddr;
}


export const ToolbarWidgetEditedComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetEdited,
  } = props;

  const document = useDocState();
  const widgetProps = document.content.cellImages_image_getWidgetProps(widgetEdited);

  const Toolbars: {[key in ContentTypes.WidgetType]: React.FC<ToolbarProps>} = {
    [ContentTypes.WidgetType.TEXT       ]: ToolbarWidgetTextComponent,
    [ContentTypes.WidgetType.ARROW_TEXT ]: ToolbarWidgetTextComponent,
    [ContentTypes.WidgetType.ARROW_PLAIN]: ToolbarDummyComponent,
    [ContentTypes.WidgetType.ELLIPSE    ]: ToolbarDummyComponent,
    [ContentTypes.WidgetType.RECTANGLE  ]: ToolbarDummyComponent,
    [ContentTypes.WidgetType.IMAGE      ]: ToolbarDummyComponent,
  };

  const Toolbar = Toolbars[widgetProps.type];

  return (
    <Toolbar widgetAddr={widgetEdited} /> 
  );
}
