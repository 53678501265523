
import { SmartLinesWidgets } from "tools/smart-lines/smart-lines-widgets";
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


export const useSmartLinesWidgetsLinesSetter = () => {
  const document = useDocState();

  const {
    setEditorImageSession,
  } = useEditorStatesSetters();

  const setSmartLines = (props: {
    srcWidgetsAddrs: ContentTypes.WidgetsAddrs,
    dstWidgetsAddrs: ContentTypes.WidgetsAddrs,
  }) => {

    const {
      srcWidgetsAddrs,
      dstWidgetsAddrs
    } = props;
  
    const dstWidgetAddrsFiltered = dstWidgetsAddrs.filter((widgetAddr) => {
      return ! srcWidgetsAddrs.includes(widgetAddr);
    })

    const dstWidgetsProps = dstWidgetAddrsFiltered.map((widgetAddr) => {
      return document.content.cellImages_image_getWidgetProps(widgetAddr);
    })

    const smartLines = new SmartLinesWidgets();
    smartLines.loadWidgets(dstWidgetsProps);

    document.editorImageSession.setSmartLinesWidgetsLines(smartLines.raw);
    setEditorImageSession();
  }

  const unsetSmartLines = () => {
    document.editorImageSession.setSmartLinesWidgetsLines(null);
    setEditorImageSession();
  }

  return {
    setSmartLines,
    unsetSmartLines
  }
}
