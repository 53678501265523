import styled from 'styled-components';


export const StyledBtnText = styled.button`
  cursor: pointer;

  color: #333;
  background-color: #eee;

  border: none;
  font-size: 16px;
  min-width: 80px;
  width: 80px;
  
  justify-content: center;
  font-weight: 500;
  font-variant-caps: all-small-caps;

  transition: background-color 0.2s ease-in-out;

  &:not(:disabled) {
    background-color: #007bff;
    background-color: #0056b3;
    font-weight: 500;
    border-width: 2px;
    box-sizing: border-box;
  }

  &:not(:disabled):hover {
    background-color: #0056b3;
    background-color: #007bff;
    font-weight: 700;
  }

  &:disabled {
    cursor: not-allowed;
    color: #999;
    background-color: #ddd;
    font-weight: 500;
  }

  &:disabled:hover {
    cursor: not-allowed;
    color: #999;
    background-color: #ddd;
    font-weight: 500;
  }
`;