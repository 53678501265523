import Settings from "./states/settings";
import Invoices from "./states/invoices";
import Subscriptions from "./states/subscriptions";


export class User {
  private _settings: Settings;
  private _invoices: Invoices;
  private _subscriptions: Subscriptions;

  constructor() {
    this._settings = new Settings();
    this._invoices = new Invoices();
    this._subscriptions = new Subscriptions();
  }

  reset() {
    this._settings.reset();
    this._invoices.reset();
    this._subscriptions.reset();
  }
  
  get settings() { return this._settings; }
  get invoices() { return this._invoices; }
  get subscriptions() { return this._subscriptions; }
}
