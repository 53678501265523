import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import urls from 'app/configs/urls';

import useUserViewSelect from 'app/ui-v2/home-page/home-page-user/hooks/use-view-select';
import useUserNavbarItemSelect  from 'app/ui-v2/home-page/home-page-user/hooks/use-navbar-item-select';
import { ViewTypes as UserViewTypes } from 'app/arch/home-page-user/states/view';
import { NavbarTypes as UserNavbarTypes } from 'app/arch/home-page-user/states/navbar';

import useGuestViewSelect from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-select';
import useGuestNavbarItemSelect from 'app/ui-v2/home-page/home-page-guest/hooks/use-navbar-item-select';
import { ViewTypes as GuestViewTypes } from 'app/arch/home-page-guest/states/view';
import { NavbarTypes as GuestNavbarTypes } from 'app/arch/home-page-guest/states/navbar';

import { useAuth } from 'services/auth';


interface Props {
}


export const RedirectHomePagePricingComponent: React.FC<Props> = (props: Props) => {
  const {
  } = props;

  const auth = useAuth();
  const navigate = useNavigate();

  const selectGuestView = useGuestViewSelect();
  const selectGuestNavbarItem = useGuestNavbarItemSelect();

  const selectUserView  = useUserViewSelect();
  const selectUserNavbarItem = useUserNavbarItemSelect();


  useEffect(() => {
    if (auth.isLogged()) {
      selectUserNavbarItem(UserNavbarTypes.NavbarItem.PRICING);
      selectUserView(UserViewTypes.ViewItem.PRICING);
    }
    else {
      selectGuestNavbarItem(GuestNavbarTypes.NavbarItem.PRICING);
      selectGuestView(GuestViewTypes.ViewItem.PRICING);
    }

    navigate(urls.home);
  }, []);

  return null;
}
