import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_RepoMarkers } from 'app/ui/states/editor-instruction';

import { RepoMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';

import MarkerViewLabelComponent from './marker-view-label';
import MarkerViewSignComponent  from './marker-view-sign';
import MarkerViewIconComponent  from './marker-view-icon';
import { MarkerViewProps } from './types';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
}


export const MarkerEditableViewComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr
  } = props;

  const markerProps = useRecoilValue(UIState_RepoMarkers.markerProps(markerAddr));
  const markerGroup = RepoMarkersTools.getMarkerGroup(markerProps.type);

  const MarkerViewMap: {[markerGroup in RepoMarkersTypes.MarkerGroup]: React.FC<MarkerViewProps>} = {
    [RepoMarkersTypes.MarkerGroup.SIGN ]: MarkerViewSignComponent,
    [RepoMarkersTypes.MarkerGroup.ICON ]: MarkerViewIconComponent,
    [RepoMarkersTypes.MarkerGroup.LABEL]: MarkerViewLabelComponent,
  }

  const MarkerView = MarkerViewMap[markerGroup]


  return (
    <MarkerView 
      markerAddr={markerAddr}
      markerProps={markerProps}
    />
  );
}
