import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './use-items';


const useColumnIndexConfig = () => {
  const t = useTranslations();
  const items = useItems();

  const getConfig = (): PanelControlsConfig => ({
    items: [{
      title: t("column index"),
      sections: [{
        subsections: [
          {
            title: t("layout, column header"),
            items: [
              items.getHeader_layout(),     
            ]
          },
          {
            title: t("layout, column"),
            items: [
              items.getColumn_padding(),
              items.getColumn_flexRowConfigHorizontal(true),
              items.getColumn_flexRowConfigVertical(),
            ]
          },
          {
            title: t("text"),
            items: [
              items.getColumn_fontSize(),
              items.getColumn_fontWeight(),
            ]
          },
        ],     
      }]
    }]
  });


  return getConfig;
}

export default useColumnIndexConfig;
