import { useSetRecoilState } from 'recoil';
import { UIState_ReleaselogsSignals } from 'app/ui/states/editor-instruction';


const useReleaselogsColumnsWidthsSignal = () => {
  const signalColumnsWidths = useSetRecoilState(UIState_ReleaselogsSignals.releaselogsColumnsWidths);

  const hook = () => {
    signalColumnsWidths({});
  }

  return hook;
}

export default useReleaselogsColumnsWidthsSignal;