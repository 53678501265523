import { useEffect } from 'react';
import { useRef }    from 'react';

import { mutation  }   from 'app/arch/backend';
import { useMutation } from 'app/arch/backend/use-mutation';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDialogYesNo }  from 'app/ui/components/editor-instruction/dialogs/dialog-yes-no/use-dialog-yes-no';
import useRepos from './use-repos';
import useAppStateSetters from './use-app-state-setters';


type OnStart = () => void;
type OnDone = () => void;
type OnError = (error: string) => void;

export interface HookProps {
  repoId: number;
  memberUsername: string;

  onStart?: OnStart;
  onDone?: OnDone;
  onError?: OnError;
}

export const useRepoMemberRemove = () => {
  const t = useTranslations();
  const repos = useRepos();

  const hookPropsRef  = useRef<HookProps | null>(null);

  const { show: showDialog } = useDialogYesNo();

  const {
    setRepos
  } = useAppStateSetters();

  // Mutation
  //
  const { 
    mutation: mutate,
    data: response,
    error,
  } = useMutation(mutation.removeRepoMember);

  useEffect(() => {
    if ( ! response ) {
      return;
    }

    const {
      repoId,
      memberUsername,
      onDone,
      onError
    } = hookPropsRef.current !;


    const data = response.repoMemberRemove;
    if ( ! data.ok ) {
      onError?.('Invalid data');
      resetRef();
      return;
    }

    repos.removeRepoMember(repoId, memberUsername);
    setRepos();
    onDone?.();
    
    resetRef();
  }, [response]);

  useEffect(() => {
    if ( ! error ) {
      return;
    }

    const {
      onError
    } = hookPropsRef.current !;

    onError?.(error.message);
    resetRef();
  }, [error]);


  const resetRef = () => {
    hookPropsRef.current = null;
  }

  const hook = (props: HookProps) => {
    const {
      repoId,
      memberUsername,
    } = props;
  
    hookPropsRef.current = props;

    const repoPack = repos.getRepoPack(repoId) !;
    const member   = repos.getMember(repoId, memberUsername) !;

    const title = t("repository member, remove msgbox title");
    const message = t("repository member, remove msgbox txt")
      .replace('<<REPO>>', repoPack.repo.name)
      .replace('<<MEMBER>>', member.username);

    const noAutoFocus = true;
    
    const onNoHandler  = () => {
    }

    const onYesHandler = () => {
      const props = hookPropsRef.current;
      props?.onStart?.();
      mutate({
        variables: { 
          repoId,
          memberUsername,
        }
      });
    }

    showDialog({
      title,
      message,
      onNoHandler,
      onYesHandler,
      noAutoFocus,
    });
  }

  return hook;
}
