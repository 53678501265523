import React from 'react';

import { Page as PageTool } from 'app/arch/print/page';
import { PageFormatType } from 'app/arch/types/types';
import { PageMarginType } from 'app/arch/types/types';
import { UnitsSymbol } from 'app/arch/types/types';
import { PageOrientationType } from 'app/arch/types/types';

import { PageShadow } from './styles';
import { Page } from './styles';
import { PageBody } from './styles';


interface Props {
  pageIdx:     number;
  margins:     PageMarginType;
  format:      PageFormatType;
  orientation: PageOrientationType;

  children: React.ReactNode;
}


export const PageEditorComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageIdx,
    format, 
    orientation,
    margins,
    children,
  } = props;
  
  const pageSize = PageTool.getSizeWithUnits(format, orientation);
  const marginUnit = UnitsSymbol[margins.units];

  return (
    <PageShadow>
      <Page
        $width={pageSize[0]}
        $height={pageSize[1]}

        $paddingLeft={`${margins.left}${marginUnit}`}
        $paddingRight={`${margins.right}${marginUnit}`}
        $paddingTop={`${margins.top}${marginUnit}`}
        $paddingBottom={`${margins.bottom}${marginUnit}`}
      >
        <PageBody>
          { children }
        </PageBody>
      </Page>
    </PageShadow>
  );
}
