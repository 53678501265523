import React from 'react';
import DraggerComponent  from 'lego-v2/dragger/ui';
import { DraggerUpdate } from 'lego-v2/dragger/arch';

import { Position } from 'app/arch/types';

import { Dragger } from './styles';


interface Props {
  position: Position;
  onPositionChange: (position: Position) => void;
  children: React.ReactNode;
}


export const DeskAreaDraggerComponent: React.FC<Props> = (props: Props) => {
  const {
    position,
    onPositionChange,
    children,
  } = props;

  const handleDraggerUpdateStart = (event: any) => {
  }

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    onPositionChange(update.position);
  }

  const handleDraggerUpdateDone = (update: DraggerUpdate) => {
  }

  return (
    <DraggerComponent
      cursorChangeWhileDragging={true}

      position={position}
      component={Dragger}

      onUpdateStart={handleDraggerUpdateStart}
      onUpdate={handleDraggerUpdate}
      onUpdateDone={handleDraggerUpdateDone}
    >
      { children }
    </DraggerComponent>
  );
}

