import { useRef } from "react";

import { Position } from "app/arch/types";
import useDebugPointSet from 'app/ui-v2/app/__modules/debug/hooks/use-debug-point-set';
import useControlsDraggersEnabledSet from "app/ui-v2/app/__modules/controls/hooks/use-controls-draggers-enabled-set";


export interface Props {
  disable: boolean;
  getScale: () => number;

  onStart?: () => void;
  onMove?: (
    scaleInit: number,
    scaleNew: number, 
    scalePointInit: Position,
    scalePointNew: Position,
  ) => void;
  onEnd?: () => void;
}


const useRescaleByTouch = (props: Props) => {
  const {
    disable,

    getScale,

    onStart,
    onMove,
    onEnd,
  } = props;

  const scaleInit = useRef<number>(0);
  const distanceInit = useRef<number>(0);
  const scalePointInit = useRef<Position>([0, 0]);

  const setDebugPoint = useDebugPointSet();
  const setDraggersEnabled = useControlsDraggersEnabledSet();

  const __getMinDistanceThreshold = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const diagonal = Math.hypot(width, height);

    let minDistance = diagonal * 0.10;
    minDistance = Math.min(100, minDistance);
    minDistance = Math.max(50, minDistance);

    return minDistance;
  };

  const __getDistance = (
    position1: Position, 
    position2: Position,
  ) => {
    const dx = position2[0] - position1[0];
    const dy = position2[1] - position1[1];

    const dist = Math.hypot(dx, dy);
    return dist;
  }

  const __getMiddlePoint = (
    position1: Position, 
    position2: Position, 
  ) => {
    const x = (position1[0] + position2[0]) / 2;
    const y = (position1[1] + position2[1]) / 2;

    const middlePoint = [x, y] as Position;
    return middlePoint;
  }

  const __calculateScale = (
    position1: Position, 
    position2: Position, 
  ) => {
    const distance = __getDistance(position1, position2);

    const scaleFactor = distance  / distanceInit.current;
    const newScale = scaleFactor * scaleInit.current;

    return newScale;
  }

  const handleTouchStart = (
    event: React.TouchEvent
  ) => {
    if ( disable ) {
      return;
    }

    const touches = event.touches;
    if (touches.length !== 2) {
      return;
    }

    setDraggersEnabled(false);

    onStart?.();
    event.stopPropagation();

    const position1 = [touches[0].clientX, touches[0].clientY] as Position;
    const position2 = [touches[1].clientX, touches[1].clientY] as Position;

    scaleInit.current = getScale();
    scalePointInit.current = __getMiddlePoint(position1, position2);
    distanceInit.current = Math.max(
      __getDistance(position1, position2), 
      __getMinDistanceThreshold()
    );
  };


  const handleTouchMove = (
    event: React.TouchEvent
  ) => {
    if ( disable ) {
      return;
    }

    const touches = event.touches;
    if (touches.length !== 2) {
      return;
    }

    event.stopPropagation();

    const position1 = [touches[0].clientX, touches[0].clientY]as Position;
    const position2 = [touches[1].clientX, touches[1].clientY]as Position;

    const scaleNew = __calculateScale(position1, position2);
    const scalePoint = __getMiddlePoint(position1, position2);
    
    setDebugPoint({x: scalePoint[0], y: scalePoint[1]});

    onMove?.(
      scaleInit.current,
      scaleNew, 
      scalePointInit.current,
      scalePoint,
    );
  };
  
  const handleTouchEnd = (
    event: React.TouchEvent
  ) => {
    if ( disable ) {
      return;
    }

    // const changedTouches = Array.from(event.changedTouches);
    // const primaryTouchEnded = changedTouches.some(touch => touch.identifier === 0);

    if (event.touches.length !== 1) {
      return;
    }

    event.stopPropagation();
    onEnd?.();
    setDraggersEnabled(true);
  }

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  }
}

export default useRescaleByTouch;