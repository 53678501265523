import React, { useRef } from 'react';
import * as Types from '../../types';
import usePanelScreenOverflowCheck from '../../hooks/use-panel-screen-overflow-check';
import { Panel } from './styles';


interface Props {
  config: Types.Panel;
  NavbarPanelSection: React.ElementType;
}


export const NavbarPanelComponent: React.FC<Props> = (props: Props) => {
  const { 
    config,
    NavbarPanelSection
  } = props;

  const panelRef = useRef<HTMLDivElement>(null);
  const {
    widthOverflow,
    done: overflowDone,
  } = usePanelScreenOverflowCheck(panelRef);

  const renderSections = () => {
    const SectionsComps = config.sections.map((section: Types.Section, idx: number) => {
      const isLast = (idx === config.sections.length - 1);

      return (
        <NavbarPanelSection
          key={idx}
          config={section}
          last={isLast}
        />
      );
    });

    return SectionsComps;
  }

  
  return (
    <Panel
      ref={panelRef}
      left={widthOverflow}
      visible={overflowDone}
    >
      { renderSections() }
    </Panel>
  );
}
