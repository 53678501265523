import { useDocState } from "app/ui/contexts/document";
import useFormatVer1 from "./use-format-ver1";
import useFormatVer1_1 from "./use-format-ver1_1";
import useFormatVer1_2 from "./use-format-ver1_2";


const useDocStatesCheck = () => {
  const docState = useDocState();
  const formatToVer1 = useFormatVer1();
  const formatToVer1_1 = useFormatVer1_1();
  const formatToVer1_2 = useFormatVer1_2();

  const hook = () => {
    const formatVersion = docState.metaData.getFormatVersion();
    console.log(`Content format version: ${formatVersion}`);
    
    if ( formatVersion === undefined ) {
      formatToVer1();
      formatToVer1_1();
      formatToVer1_2();
      return;
    }
    else if ( formatVersion === 1 ) {
      formatToVer1_1();
      formatToVer1_2();
      return;
    }
    else if ( formatVersion === 1.1 ) {
      formatToVer1_2();
    }
    else if ( formatVersion === 1.2 ) {
      console.log("Content foramt on latest ver");
      return;
    }
    else {
      console.warn("Invalid content format version: ", formatVersion);
    }
  }

  return hook;
}


export default useDocStatesCheck;