import { useRecoilValue } from 'recoil';
import useToastsListenerRegistration from './hooks/use-toasts-listener-registration';

import { ToastsTools } from 'app/arch/app/info/toasts';
import ToastComponent from './components/toast';
import ToastsTestComponent from './components/toasts-test';
import { UIInfoToasts } from './states';

import { ToastsWrapper } from './styles';


interface Props {
}


export const ToastsComponent: React.FC<Props> = (props: Props) => {
  useToastsListenerRegistration();
  const toastsAddrs = useRecoilValue(UIInfoToasts.addrs);
  
  const Toasts = toastsAddrs.map(toastAddr => {
    const key = ToastsTools.getToastKey(toastAddr);

    return (
      <ToastComponent
        key={key}
        toastAddr={toastAddr} 
      />
    );
  });

  return (
    <ToastsWrapper>
      { Toasts }
      {/* <ToastsTestComponent /> */}
    </ToastsWrapper>
  );
}
