import { useRef }    from 'react';

import { useAuth } from 'services/auth';

import { mutation  } from 'app/arch/backend';
import { useApp } from 'app/ui/hooks/app/use-app';
import { apolloClientNoAuth } from 'app/ui-v2/app/__modules/apollo/graphql';
import useAppStateSetters from './use-app-state-setters';


type OnDone = () => void;
type OnError = (error: string) => void;


export interface HookProps {
  googleToken: String;
  onDone?: OnDone;
  onError?: OnError;
}


export const useUserGoogleLogin = () => {
  const app = useApp();
  const auth = useAuth();

  const {
    setApp
  } = useAppStateSetters();

  const __login = (props: HookProps) => {
    const {
      googleToken,
      onDone,
      onError,
    } = props;

    apolloClientNoAuth
      .mutate({
        mutation: mutation.loginUserGoogle,
        variables: { googleToken }
      })
      .then((result: any) => {
        const data = result.data.userGoogleLogin;
        const {
          ok, 
          token,
        } = data;

        if ( ! ok ) {
          onError?.('Google authentication failed.');
          return;
        }

        const authenticated = auth.setToken(token);
        if ( ! authenticated ) {
          onError?.('Google authentication failed. Invalid tokend');
          return;
        }

        app.appState.updateState({isLogged: true});
        setApp();

        onDone?.();
      })
      .catch((error: any) => {
        onError?.(error);
        console.error(`Auth problem: ${error}`);
      });
  }

  const hook = (props: HookProps) => {
    __login(props);   
  }


  

  return hook;
}
