import { useNavigate } from "react-router-dom";

import urls from "app/configs/urls";
import { ViewTypes }   from "app/arch/home-page-guest/states/view"
import { NavbarTypes } from "app/arch/home-page-guest/states/navbar";

import useStateSetters from "./use-state-setters";
import useViewSelect   from "./use-view-select";
import useNavbarItemSelect from "./use-navbar-item-select";


const useGuestGoHome = () => {
  const navigate = useNavigate();

  const selectView = useViewSelect();
  const selectNavbarItem = useNavbarItemSelect();

  const {
    setView,
    setNavbar
  } = useStateSetters();
  
  const goHome = () => {
    selectView(ViewTypes.ViewItem.DEMO);
    selectNavbarItem(NavbarTypes.NavbarItem.DEMO);

    setView();
    setNavbar();

    navigate(urls.home);
  }
  
  return goHome;
}

export default useGuestGoHome;