import produce from 'immer';

import * as State from './state';
import * as Types from './types';



export class AppState {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get raw() { return this._state; }


  /**
   * Setters
   */

  updateState(update: Types.AppStateUpdate) {
    this._state = produce(this._state, draft => {
      Object.assign(draft, update);
    });
  }


  /**
   * Getters
   */

  getIsLogged() {
    return State.getIsLogged(this._state);
  }

  getIsPrintout() {
    return State.getIsPrintout(this._state);
  }
}
