import * as Types from './types';


export const getSizeVariant = (width: number): Types.SizeVariant => {
  if (width < 360) {
    return Types.SizeVariant.WIDTH_320;
  }
  else if (width >= 360 && width < 390) {
    return Types.SizeVariant.WIDTH_360;
  }
  else if (width >= 390 && width < 500) {
    return Types.SizeVariant.WIDTH_390;
  }
  else if (width >= 500 && width < 600) {
    return Types.SizeVariant.WIDTH_500;
  }
  else if (width >= 600 ) {
    return Types.SizeVariant.WIDTH_600;
  }

  const msg = `This can't happen, just to satisfy linter`;
  throw new Error(msg);
}
