import React from 'react';
import MobileKeyboardWatcherComponent from './mobile-keyboard-watcher';
import PrintoutClippingComponent from './printout-clipping';


interface Props {
}


export const CSSVarsSettersComponent: React.FC<Props> = (props: Props) => {
  
  const {

  } = props;


  return (
    <>
      <MobileKeyboardWatcherComponent />
      <PrintoutClippingComponent />
    </>
  );
}

