import * as Types from './types';


export const getActions = (): Types.Actions => ({
  addrs: [],
  props: {}
});

export const getActionProps = (
  props: Types.ActionCreateProps
): Types.ActionProps => ({
  state: Types.ActionState.STARTED,
  textStart: props.textStart,
  textStop: props.textStop,
  timeout: props.timeout || 60,
});