import { MarkerAddr } from "app/arch/editor-instruction/document/states/persistent/repo-markers/types";
import useStatesCommon from "../common/use-states";


const __useStates = (markerAddr: MarkerAddr) => {
  const states = useStatesCommon(markerAddr);
  const borderEnabled = (states.markerProps.icon.css.borderStyle === 'solid');
  
  return {
    ...states,
    borderEnabled,
  }
}


export default __useStates;
