import React from 'react';
import { useRecoilValue } from 'recoil';

import { useLogoUrl }         from 'app/ui/hooks/editor-instruction/use-logo-url';
import { UIState_HeaderMeta } from 'app/ui/states/editor-instruction';
import { UIState_HeaderMetaSession } from 'app/ui/states/editor-instruction';

import LogoImageUserComponent  from '../logo-image-user';
import LogoImageBlankComponent from '../logo-image-blank';
import LogoUploadingComponent  from '../logo-uploading';

import { MainWrapper } from './styles';


interface Props {
}


export const LogoViewComponent: React.FC<Props> = (props: Props) => {
  const logoUrl = useLogoUrl() !;

  const logoImage     = useRecoilValue(UIState_HeaderMeta.logoImage);
  const logoImageView = useRecoilValue(UIState_HeaderMeta.logoView);
  const logoUploading = useRecoilValue(UIState_HeaderMetaSession.logoUploading);

  const displayLogoBlank = (! logoUrl && ! logoUploading );
  const displayLogo      = (logoUrl && logoImage && ! logoUploading );
  const displayLogoUploading = logoUploading;

  return (
    <MainWrapper
      data-test={'doc-header-logo'}
    >
    { 
      displayLogoBlank &&
      <LogoImageBlankComponent /> 
    }
    
    { 
      displayLogo &&
      <LogoImageUserComponent 
        logoUrl={logoUrl}
        logoImage={logoImage} 
        logoImageView={logoImageView}           
      /> 
    } 

    {
      displayLogoUploading &&
      <LogoUploadingComponent />
    }
    
    </MainWrapper>
  );
}
