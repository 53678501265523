import React from 'react';
import { settings } from 'app/configs';
import { Position } from 'app/arch/types';
import { Content } from './styles';


interface Props {
  position: Position;
  scale: number;
  children: React.ReactNode;
}


export const DeskContentComponent: React.FC<Props> = (props: Props) => {
  const {
    position,
    scale,
    children,
  } = props;


  return (
    <Content
      $width={settings.editorImage.desk.width}
      $height={settings.editorImage.desk.height}
    
      $left={position[0]}
      $top={position[1]}
      $scale={scale}
    >
      { children }
    </Content>
  );
}

