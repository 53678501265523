export enum SortBy {
  NONE         = 'none',
  TITLE        = 'title',
  DATE_UPDATED = 'date-updated',
}


export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}