import styled from "styled-components";
import IconWrapperBase from "../../styles/navbar-panel-section-item/icon-wrapper";
import ItemBase from "../../styles/navbar-panel-section-item/item";
import TextWrapperBase from "../../styles/navbar-panel-section-item/text-wrapper";
import TextBase from "../../styles/navbar-panel-section-item/text";


export const IconWrapper = styled(IconWrapperBase)`
`;

export const Item = styled(ItemBase)`
`;

export const TextWrapper = styled(TextWrapperBase)`
`;

export const Text = styled(TextBase)`
`;
