import { useSetRecoilState } from "recoil";
import { UIHomePageGuest_Navbar } from "../states";
import { UIHomePageGuest_View }   from "../states";
import useState from "./use-state";



const useStateSetters = () => {
  const homePageGuest = useState();

  const __setNavbar = useSetRecoilState(UIHomePageGuest_Navbar.state);
  const __setView = useSetRecoilState(UIHomePageGuest_View.state);
 
  const setNavbar = () => __setNavbar(homePageGuest.navbar.state);
  const setView = () => __setView(homePageGuest.view.state);

  const setAll = () => {
    setNavbar();
    setView();
  }

  return {
    setAll,
    
    setNavbar,
    setView,
  }
}

export default useStateSetters;