import React from 'react';
import * as Types from './types';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';

import { CellValue } from './styles';
import { Grid } from './styles';
import { ProgressWrapper } from './styles';
import { ProgressBox } from './styles';
import { CellFullRow } from './styles';
import { CellTitle } from './styles';
import { Separator } from './styles';
import { Title } from './styles';


interface Props {
  title?: string;
  showProgres: boolean;
  progressTitle?: string;
  rowsCount: number;
  cells: Types.Cells
}


export const ItemPanelGridComponent: React.FC<Props> = (props: Props) => {
  const {
    title,
    showProgres,
    progressTitle,
    rowsCount,
    cells
  } = props;

  const hideTitle = progressTitle === undefined || progressTitle.length === 0;

  const renderCells = () => {
    const Cells = cells.map(cell => {
      const Component = cell.Component;
      const isTitleColumn = cell.columnIdx === 0;

      const Cell = (
        isTitleColumn ? 
        CellTitle :
        CellValue
      );

      const titleDef = (title !== undefined);
      const titleIdxDelta = (
        titleDef ?
        1 :
        0
      );

      return (
        <Cell
          $disabled={cell.disabled}
          $columnIdx={cell.columnIdx + 1}
          $rowIdx={cell.rowIdx + 1 + titleIdxDelta}
        >
          { Component }
        </Cell>
      );
    });

    return Cells;
  }

  const renderProgress = () => (
    <ProgressWrapper>
      <ProgressBoxComponent
        hideTitle={hideTitle}
        title={progressTitle}
        Box={ProgressBox}
      />
    </ProgressWrapper>
  );

  const renderTitle = () => {
    return (
      <CellFullRow >
        <Title>
          { title }
        </Title>
        <Separator />
      </CellFullRow>
    );
  }

  return (
    <Grid $rowsCount={rowsCount}>
      { showProgres && renderProgress() }
      { title !== undefined && renderTitle() }
      { renderCells() }
    </Grid>
  );
}

