import { HeaderMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/header-markers";
import { HeaderMarkersTools } from "app/arch/editor-instruction/document/states/persistent/header-markers";
import RepoBase from "../repo-base";


class HeaderMarkers extends RepoBase<HeaderMarkersTypes.MarkerAddr> {
  getKey(markerAddr: HeaderMarkersTypes.MarkerAddr): string {
    return HeaderMarkersTools.getMarkerKey(markerAddr);
  };

  tag(): string {
    return "HeaderMarkers";
  };
}


export default HeaderMarkers;