import { useState   } from "react";
import { useEffect  } from "react";
import { useReducer } from "react";
import { useRecoilValue    } from "recoil";

import jtl from "tools/jtl";

import { Arrow } from "app/arch/editor-image/types/arrows";
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useDocState } from "app/ui/contexts/document";
import { UIState_Content } from "app/ui/states/editor-instruction";
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import useWidgetContextMenu   from "app/ui-v2/editor-image/hooks/widget/use-widget-context-menu";
import useWidgetPartSelect    from "app/ui-v2/editor-image/hooks/widget/use-widget-part-select";

import { WidgetPropsBase }  from "../../../types";

import { DraggerBoxEditorTextUpdate }      from "../frames/box-frame/types";
import { DraggerBoxEditorTextUpdateStart } from "../frames/box-frame/types";
import { FrameBoxEditorTextUpdate }        from "../frames/box-frame/types";
import { FrameBoxEditorTextUpdateStart }   from "../frames/box-frame/types";

import FrameArrowHeadComponent from "../frames/frame-arrow-head";
import FrameArrowBodyComponent from "../frames/frame-arrow-body";
import FramePointerComponent   from "../frames/frame-pointer";
import FrameTriangleComponent  from "../frames/frame-triangle";
import FrameDummyComponent     from "../frames/frame-dummy";
import BoxFrameComponent       from "../frames/box-frame";

import { DraggerBodyUpdate }       from "../draggers/types";
import { DraggerHeadUpdate }       from "../draggers/types";
import { DraggerHeadUpdateStart }  from "../draggers/types";
import DraggerBodyComponent        from "../draggers/dragger-body";
import DraggerTriangleComponent    from "../draggers/dragger-triangle";
import DraggerHeadArrowComponent   from "../draggers/dragger-head-arrow";
import DraggerHeadPointerComponent from "../draggers/dragger-head-pointer";

import { arrowStateReducer    } from "./state-routines";
import { calcTailPosition     } from "./state-routines";
import { getArrowStateInitial } from "./state-routines";

import WidgetArrowTextViewComponent from "./widget-arrow-text-view";
import WidgetArrowTextEditorTextWrapperComponent from "./widget-arrow-text-editor-text-wrapper";

import { Canceler } from "./styles";
import { FrameArrowHeadWrapper } from "./styles";
import { FrameArrowBodyWrapper } from "./styles";


export interface Props extends WidgetPropsBase {
  EditorTextPlugins: React.ReactNode[];
}


export const WidgetArrowTextBaseComponent: React.FC<Props> = (props: Props) => {
  const { 
    dataTest,
    widgetAddr,
    scale, 
    EditorTextPlugins, 
   } = props;

   const editDisabled = (
    props.editDisabled !== undefined ?
    props.editDisabled :
    false
  );

   const document = useDocState();

   const {
    setContent,
  } = useEditorStatesSetters();

  const {
    showContextMenu,
    hideContextMenu,
  } = useWidgetContextMenu(widgetAddr);

  const selectWidgetPart = useWidgetPartSelect();

  const widgetProps_ = useRecoilValue(UIState_Content.cellImages_image_widgetProps(widgetAddr));
  const widgetProps = widgetProps_ as ContentTypes.WidgetArrowTextProps;

  const widgetStyle = useRecoilValue(UIState_Content.cellImages_image_widgetStyle(widgetAddr)) || {};
  const widgetPartSelected = useRecoilValue(UIState_EditorImageSession.widgetPartSelected(widgetAddr));
  
  const [dragging, setDragging] = useState(false);

  const [arrowState, arrowStateDispatch] = useReducer(
    arrowStateReducer,
    getArrowStateInitial(widgetProps)
  );

  useEffect(() => {
    arrowStateDispatch({
      type: 'reloadArrow',
      payload: {
        startPoint: widgetProps.startPoint,
        endPoint:   widgetProps.endPoint,
        tailSize:   widgetProps.tailSize,
      },
    });
  }, [widgetProps]);


  const FramesHead: {[key in Arrow.PointerType]: any} = {
    [Arrow.PointerType.ARROW   ] : FrameArrowHeadComponent,
    [Arrow.PointerType.POINTER ] : FramePointerComponent,
    [Arrow.PointerType.TRIANGLE] : FrameTriangleComponent,
    [Arrow.PointerType.DOTS    ] : FrameArrowHeadComponent,
  }

  const FramesBody: {[key in Arrow.PointerType]: any} = {
    [Arrow.PointerType.ARROW   ] : FrameArrowBodyComponent,
    [Arrow.PointerType.POINTER ] : FrameDummyComponent,
    [Arrow.PointerType.TRIANGLE] : FrameDummyComponent,
    [Arrow.PointerType.DOTS    ] : FrameDummyComponent,
  }

  const DraggerHeads: {[key in Arrow.PointerType]: any} = {
    [Arrow.PointerType.ARROW   ] : DraggerHeadArrowComponent,
    [Arrow.PointerType.POINTER ] : DraggerHeadPointerComponent,
    [Arrow.PointerType.TRIANGLE] : DraggerTriangleComponent,
    [Arrow.PointerType.DOTS    ] : DraggerHeadArrowComponent,
  }

  const widgetPointer = widgetProps.pointer as Arrow.PointerType;

  const BodyDraggerEnabled = [
    Arrow.PointerType.ARROW,
    // Arrow.PointerType.TRIANGLE
  ];

  const FrameHead = FramesHead[widgetPointer];
  const FrameBody = FramesBody[widgetPointer];

  const DraggerHead = DraggerHeads[widgetPointer];
  const renderBodyDragger = BodyDraggerEnabled.includes(widgetPointer);

  const saveDocContentState = () => {
    const update = {
      startPoint: arrowState.body.startPoint,
      endPoint:   arrowState.body.endPoint,
      tailSize:   arrowState.tail.size,
    }
    
    document.content.cellImages_image_widgetArrowText_update(
      widgetAddr,
      update
    );

    document.saveUndo();
    setContent();
  }


  /**
   * ArrowHead
   */
  const handleArrowHeadUpdateStart = (update: DraggerHeadUpdateStart) => {
    setDragging(true);

    arrowStateDispatch({
      type: 'arrowHeadPositionUpdate_start',
      payload: update,
    });
  }

  const handleArrowHeadUpdate = (update: DraggerHeadUpdate) => {
    arrowStateDispatch({
      type: 'arrowHeadPositionUpdate_update',
      payload: update,
    });
  }

  const handleArrowHeadUpdateDone = (update: DraggerHeadUpdate) => {
    handleArrowHeadUpdate(update);
    arrowStateDispatch({
      type: 'arrowHeadPositionUpdate_done',
      payload: update,
    });

    saveDocContentState();
    setDragging(false);
  }


  /**
   * ArrowBody
   */
  const handleArrowBodyUpdateStart = () => {
    setDragging(true);
  }

  const handleArrowBodyUpdate = (update: DraggerBodyUpdate) => {
    arrowStateDispatch({
      type: 'arrowBodyPositionChanged',
      payload: update,
    });
  }

  const handleArrowBodyUpdateDone = (update: DraggerBodyUpdate) => {
    handleArrowBodyUpdate(update);
    saveDocContentState();
    setDragging(false);
  }


  /**
   * ArrowTail Dragger
   */
  const handleArrowTailDraggerUpdateStart = (update: DraggerBoxEditorTextUpdateStart) => {
    setDragging(true);

    arrowStateDispatch({
      type: 'arrowTailPositionUpdate_start',
      payload: update,
    });    
  }

  const handleArrowTailDraggerUpdate = (update: DraggerBoxEditorTextUpdate) => {
    arrowStateDispatch({
      type: 'arrowTailPositionUpdate_update',
      payload: update,
    });  
  }

  const handleArrowTailDraggerUpdateDone = (update: DraggerBoxEditorTextUpdate) => {
    handleArrowTailDraggerUpdate(update);
    arrowStateDispatch({
      type: 'arrowTailPositionUpdate_done',
      payload: update,
    });

    saveDocContentState();
    setDragging(false);
  }


  /**
   * ArrowTail Frame
   */
  const handleArrowTailFrameUpdateStart = (update: FrameBoxEditorTextUpdateStart) => {
    arrowStateDispatch({
      type: 'arrowTailSizeUpdate_start',
      payload: update,
    }); 
  }

  const handleArrowTailFrameUpdate = (update: FrameBoxEditorTextUpdate) => {
    arrowStateDispatch({
      type: 'arrowTailSizeUpdate_update',
      payload: update,
    }); 
  }

  const handleArrowTailFrameUpdateDone = (update: FrameBoxEditorTextUpdate) => {
    handleArrowTailFrameUpdate(update);
    arrowStateDispatch({
      type: 'arrowTailSizeUpdate_done',
      payload: update,
    }); 

    saveDocContentState();
  }

  /**
   * 
   */
  const handleSelectWidgetPart = (
    event: React.MouseEvent, 
    widgetPart: ContentTypes.WidgetPart
  ) => {
    selectWidgetPart({
      widgetAddr,
      widgetPart,
      editDisabled
    });
  }

  const handleContextMenu = (
    event:  React.MouseEvent,
    widgetPart: ContentTypes.WidgetPart
  ) => {
    selectWidgetPart({
      widgetAddr,
      widgetPart,
      editDisabled
    });

    showContextMenu({
      event,
      widgetAddr,
      editDisabled
    });
  }


  //--------------------------------------
  // Render
  //

  const tailPosition = calcTailPosition(
    arrowState.body.endPoint, 
    arrowState.tail.size
  );


  const renderDraggers = () => {
    const style = widgetProps.style;

    const borderRadius = jtl.css.valueToNumber(style.borderRadius);
    // const clipperStyle = {
    //   left:   `${tailPosition[0]}px`,
    //   top:    `${tailPosition[1]}px`,
    //   width:  `${arrowState.tail.size[0]}px`,
    //   height: `${arrowState.tail.size[1]}px`,
    //   borderRadius: `${borderRadius}px`,
    // }

    return (
      <>
      { 
        renderBodyDragger &&
        <DraggerBodyComponent
          scale={scale}
          disabled={editDisabled}
          
          startPoint={arrowState.body.startPoint}
          endPoint={arrowState.body.endPoint}
          style={widgetProps.style}

          onUpdateStart={handleArrowBodyUpdateStart}
          onUpdate={handleArrowBodyUpdate}
          onUpdateDone={handleArrowBodyUpdateDone}

          onContextMenu={(event: React.MouseEvent) =>
            handleContextMenu(event, ContentTypes.WidgetPart.ARROW_BODY)
          }
          setWidgetSelected={(event: React.MouseEvent) => 
            handleSelectWidgetPart(event, ContentTypes.WidgetPart.ARROW_BODY)
          }

          dataTest={dataTest}
        />
      }

        <DraggerHead
          scale={scale}
          disabled={editDisabled}

          startPoint={arrowState.body.startPoint}
          endPoint={arrowState.body.endPoint}
          style={widgetProps.style}

          onUpdateStart={handleArrowHeadUpdateStart}
          onUpdate={handleArrowHeadUpdate}
          onUpdateDone={handleArrowHeadUpdateDone}

          onContextMenu={(event: React.MouseEvent) =>
            handleContextMenu(event, ContentTypes.WidgetPart.ARROW_HEAD)
          }
          setWidgetSelected={(event: React.MouseEvent) => 
            handleSelectWidgetPart(event, ContentTypes.WidgetPart.ARROW_HEAD)
          }

          dataTest={dataTest}
        />

        {/* 
          Prevent sending pointer down to underlying 
          reset selected in EditorImage.
        */}
        <Canceler
          onPointerDown={(event: React.PointerEvent) => {
            if (editDisabled) {
              return;
            }
            event.stopPropagation();
          }}
        >

          <BoxFrameComponent
            {...props}
          
            selected={widgetPartSelected === ContentTypes.WidgetPart.ARROW_TAIL && ! dragging}

            position={tailPosition}
            size={arrowState.tail.size}
            widgetStyle={widgetStyle}

            onDraggerUpdateStart={handleArrowTailDraggerUpdateStart}
            onDraggerUpdate={handleArrowTailDraggerUpdate}
            onDraggerUpdateDone={handleArrowTailDraggerUpdateDone}

            onFrameUpdateStart={handleArrowTailFrameUpdateStart}
            onFrameUpdate={handleArrowTailFrameUpdate}
            onFrameUpdateDone={handleArrowTailFrameUpdateDone}
          > 
            <WidgetArrowTextEditorTextWrapperComponent
              widgetStyle={widgetStyle as any}
              tailSize={arrowState.tail.size}

              widgetAddr={widgetAddr}
              editDisabled={editDisabled}
              EditorTextPlugins={EditorTextPlugins}

              dataTest={dataTest}
            />
          </BoxFrameComponent>

          {
            widgetPartSelected === ContentTypes.WidgetPart.ARROW_HEAD &&
            ! dragging &&

            <FrameArrowHeadWrapper>
              <FrameHead 
                scale={scale}
                
                widgetAddr={widgetAddr}

                widgetStyle={widgetStyle}
                startPoint={arrowState.body.startPoint}
                endPoint={arrowState.body.endPoint}
                tailSize={arrowState.tail.size}
              />
            </FrameArrowHeadWrapper>
          }

          {
            widgetPartSelected === ContentTypes.WidgetPart.ARROW_BODY &&
            ! dragging &&
            <FrameArrowBodyWrapper>
              <FrameBody 
                scale={scale}

                widgetAddr={widgetAddr}

                widgetStyle={widgetStyle}
                startPoint={arrowState.body.startPoint}
                endPoint={arrowState.body.endPoint}
                tailSize={arrowState.tail.size}
              />
            </FrameArrowBodyWrapper>
          }

        </Canceler>
      </>
    );
  }

  return (
    <>
      <WidgetArrowTextViewComponent 
        widgetAddr={widgetAddr}
        arrowState={arrowState}
      />

      { renderDraggers() }
    </>
  );
}