import styled from "styled-components";
import { StyledListBase } from 'lego-v2/list/styles';
import { PanelSecondary } from "lego/styles/panel_secondary";


export const MainWrapper = styled.div`
`;


export const MenuPanelBase = styled(PanelSecondary)`
  position: relative;
  min-width: 140px;
`;

export const StyledList = styled(StyledListBase)`
`;


export const SubmenuPositioner = styled.div`
  height: 0;
  width: 0;
  position: relative;
  top: -30px;
`;


export const SubmenuDetacher = styled.div`
  position: absolute;
  left: calc(100% - 20px);
  z-index: 1;
  /* left: calc(80px); */
`;
