import React from 'react';

import ButtonToolbarComponent from 'lego/components/buttons/button-toolbar';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDocState     } from 'app/ui/contexts/document';
import Icon from 'app/ui/icons/icons';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset from 'app/ui-v2/editor-image/hooks/use-selected-reset';

import { MenuWrapper } from './styles';


interface Props {
  imageAddr: Types.ImageAddr;
};


export const MenuWidgetsComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr
  } = props;

  const t = useTranslations();
  const document = useDocState();
  const resetSelected = useSelectedReset();

  const {
    setContent,
    setEditorImageSession,
  } = useEditorStatesSetters();

  const getDataTest = (widgetName: string) => {
    return (
      `editor-image`
      + `__menu-widget`
      + `__${widgetName}`
    );
  }

  const handleCreateWidget = (widgetType: Types.WidgetType) => {
    const widgetAddr = document.cellImages_image_addWidget(
      imageAddr,
      widgetType
    );
    
    document.saveUndo();
    setContent();

    resetSelected({skipStateUpdate: true});
    document.cellImages_image_setWidgetSelected(widgetAddr);
    setEditorImageSession();
  }

  const handleCreateWidgetBoxed = (widgetType: Types.WidgetType) => {
    handleCreateWidget(widgetType);
  }
  
  const handleCreateWidgetArrowPlain = () => {
    handleCreateWidget(Types.WidgetType.ARROW_PLAIN);
  }

  const handleCreateWidgetArrowText = () => {
    handleCreateWidget(Types.WidgetType.ARROW_TEXT);
  }

  const handlePointerDown = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  }

  interface ButtonProps {
    tooltip: string;
    dataTest: string;
    onClick: (event: React.MouseEvent) => void;
    Icon: React.ElementType;
  }

  const renderButton = (props: ButtonProps) => {
    const { 
      tooltip, 
      dataTest,
      onClick,
      Icon
    } = props;
    
    return (
      <ButtonToolbarComponent
        Icon={Icon}
        
        tooltip={tooltip}
        tooltipPlacement={'right'}
        onClick={onClick}

        dataTest={dataTest}
      />
    );
  }

  return (
    <MenuWrapper
      onPointerDown={handlePointerDown}
    >
      { 
        renderButton({
          tooltip: t('draw plain arrow'),
          dataTest: getDataTest('arrow'),
          onClick: handleCreateWidgetArrowPlain,
          Icon:    Icon.Widget.Shape.Arrow,
        })
      }

      { 
        renderButton({
          tooltip: t('draw text arrow'),
          dataTest: getDataTest('arrow-text'),
          onClick: handleCreateWidgetArrowText,
          Icon:    Icon.Widget.Shape.ArrowText,
        })
      }
      
      { 
        renderButton({
          tooltip: t('draw text box'),
          dataTest: getDataTest('text-box'),
          onClick: (event: any) => { 
            handleCreateWidgetBoxed(Types.WidgetType.TEXT);
          },
          Icon: Icon.Widget.Shape.TextBox,
        })
      }
      
      { 
        renderButton({
          tooltip: t('draw square'),
          dataTest: getDataTest('rectangle'),
          onClick: (event: any) => {
            handleCreateWidgetBoxed(Types.WidgetType.RECTANGLE);
          },
          Icon: Icon.Widget.Shape.Rectangle,
        })
      }

      { 
        renderButton({
          tooltip: t('draw circle'),
          dataTest: getDataTest('circle'),
          onClick: (event: any) => {
            handleCreateWidgetBoxed(Types.WidgetType.ELLIPSE);
          },
          Icon: Icon.Widget.Shape.Circle,
        })
      }

    </MenuWrapper>
  );
};
