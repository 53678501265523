import Panel from "lego/styles/panel";
import styled from "styled-components";


export const MainWrapper = styled.div`
  position: relative;
`;

export const PanelWrapper = styled.div`
  position: relative;
`;

export const StyledPanel = styled(Panel)`
  position: absolute;
  top: 4px;
  min-width: 170px;
`;