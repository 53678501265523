import React from 'react';
import FlexEditLogoRawComponent from 'app/ui-v2/hls/branding/flex-edit-logo-raw';
import { LogoWrapper } from './styles';


interface Props {
}


export const FlexEditLogoComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <LogoWrapper>
      <FlexEditLogoRawComponent logoSize={28} />
    </LogoWrapper>
  );
}
  
