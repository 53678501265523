import styled from 'styled-components';


export const ShadowBase = styled.div`
  pointer-events: none;
  position: absolute;
  opacity:    ${props => props.theme.defs.opacity.xlarge};
  background: ${props => props.theme.defs.colors.special.overlayer};
`;


export const ShadowTop = styled(ShadowBase)`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 0px;
`;


export const ShadowBottom = styled(ShadowBase)`
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
`;


export const ShadowLeft = styled(ShadowBase)`
  top: 0px;
  left: 0px;
  width: 0px;
  height: 0px;
`;


export const ShadowRight = styled(ShadowBase)`
  top: 0px;
  width: 0px;
  height: 0px;
`;
