import styled from "styled-components";
import TopToolbarWrapper from "app/ui-v2/editor-instruction/styles/top-toolbar-wrapper";


export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  /* background: rgba(255, 255, 0, 0.3); */
`;


export const TopMenuWrapper = styled(TopToolbarWrapper)`
  flex: 0 0 auto;
  /* 
    z-index is needed here otherwise
    page will cover dropdown color panel
    when expanded
  */
  position: relative;
  z-index: 1;
`;

export const MainRow = styled.div`
  flex: 1 1 0;

  display: flex;
  flex-direction: row;

  overflow: hidden;
  /* 
  overflow is needed or desk scroller will not kick in
   */
`;

export const SideMenuWrapper = styled.div`
  flex: 0 0 auto;
`;

export const ContentWrapper = styled.div`
  flex: 1 1 0;
  min-width: 0;

  display: flex;
  flex-direction: column;
`;

export const DeskWrapper = styled.div`
  flex: 1 1 0;
  min-height: 0;
`;

export const BottomToolbarWrapper = styled.div`
  flex: 0 0 auto;
`;