import CellImagsRepo from "./cell-images-repo/cell-images-repo";
import CellMarkersRepo from "./cell-markers-repo";
import HeaderMarkers from "./header-markers";
import OthersRepo from "./others-repo";


class AssetsRepo {
  private _headerMarkers: HeaderMarkers;
  private _cellImages: CellImagsRepo;
  private _cellMarkers: CellMarkersRepo;
  private _others: OthersRepo;

  constructor() {
    this._headerMarkers = new HeaderMarkers();
    this._cellImages = new CellImagsRepo();
    this._cellMarkers = new CellMarkersRepo();
    this._others = new OthersRepo();
  }

  get headerMarkers() { return this._headerMarkers; }
  get cellImages() { return this._cellImages; }
  get cellMarkers() { return this._cellMarkers; }
  get others() { return this._others; }

  async generatePngs() {
    await this._others.generatePngs();
    await this._headerMarkers.generatePngs();
    await this._cellImages.generatePngs();
    await this._cellMarkers.generatePngs();
  }
}

export default AssetsRepo;