import { MutableRefObject } from 'react';
import { MenubarTypes } from 'lego-v2/menubar';
import useSystemKeys from 'lego/components/key-bindings/hooks/use-system-keys';
import { KeyBindingPriority } from "lego/components/key-bindings/arch/types";

import environment from 'app/environment';
import Icon from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useFunctions from './use-functions';


const useConfigMenu = (
  closeMenuRef: MutableRefObject<(() => void) | null>
) => {

  const t = useTranslations();
  const systemKeys  = useSystemKeys();

  const fns = useFunctions(closeMenuRef);

  const getConfig = () => {

    const config: MenubarTypes.Config = {
      items: [
        {
          title: t("file"),
          dataTest: 'editor-doc__top-menu__file',
          panel: {
            sections: [
              {
                items: [
                  {
                    type: "item",
                    title: t("new file"),
                    dataTest: 'editor-doc__top-menu__file__new',
                    icon: Icon.AddBox,
                    onClick: (data: any) => { 
                      fns.handleCreateNew();
                    },
                    // keysLabel: `${systemKeys.action}N` ,
                    // keysBinding: (event: any) => (event.ctrlKey || event.metaKey) && ! event.shiftKey && event.key === 'n',
                  },
                ]
              },
              {
                items: [
                  {
                    type: "item",
                    title: t("save"),
                    dataTest: 'editor-doc__top-menu__file__save',
                    icon: Icon.Save,
                    onClick: (data: any) => { 
                      fns.handleSave();
                    },
                    keysLabel: `${systemKeys.actionSymbol}S` ,
                    keysBindingCheck: (event: any) => (event.ctrlKey || event.metaKey) && ! event.shiftKey && event.key === 's',
                    keysBidningPriority: KeyBindingPriority.EDITOR_INSTRUCTION
                  },
                  {
                    type: "item",
                    title: t("repository template export, menu"),
                    dataTest: 'editor-doc__top-menu__file__export-template',
                    icon: Icon.Export,
                    onClick: (data: any) => { 
                      fns.handleExportDefaults();
                    },
                  },
                  // {
                  //   title: t("save as"),
                  //   icon: Icon.SaveAs,
                  //   onClick: (data: any) => { 
                  //     fns.handleSaveAs();
                  //   },
                  //   keysLabel: `${systemKeys.shift}${systemKeys.action}S` ,
                  //   keysBindingCheck: (event: any) => (event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 's',
                  //   keysBidningPriority: KeyBindingPriority.EDITOR_INSTRUCTION
                  // },  
                ]
              },
              {
                items: [
                  {
                    type: "item-sub-section",
                    title: t("top menu - file, export"),
                    dataTest: 'editor-doc__top-menu__file__exports',
                    icon: Icon.File.Export,
                    onClick: (data: any) => { 
                    },
                    items: [
                      {
                        type: "item-sub-section",
                        title: t("export dialog, btn doc"),
                        icon: Icon.File.DOC,
                        onClick: (data: any) => { 
                          fns.handleDocExport();
                        }
                      },
                      {
                        type: "item-sub-section",
                        title: t("export dialog, btn xls"),
                        icon: Icon.File.XLS,
                        onClick: (data: any) => { 
                          fns.handleXlsExport();
                        }
                      },                      
                    ]
                  },
                ],
              },
  
              {
                items: [
                  {
                    type: "item",
                    title: t("exit"),
                    dataTest: 'editor-doc__top-menu__file__exit',
                    icon: Icon.Close,
                    onClick: (data: any) => { 
                      fns.handleExit();
                    },
                    keysLabel: `${systemKeys.shiftSymbol}${systemKeys.actionSymbol}X` ,
                    keysBindingCheck: (event: any) => (event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'x',
                    keysBidningPriority: KeyBindingPriority.EDITOR_INSTRUCTION
                  },
                ]
              },
            ]
          }
        },
  
        {
          title: t("top menu - release"),
          dataTest: 'editor-doc__top-menu__release',
          panel: {
            sections: [
              {
                items: [
                  {
                    type: "item",
                    title: t("top menu - release, pdf preview"),
                    dataTest: 'editor-doc__top-menu__release__preview-pdf',
                    icon: Icon.PDF.Preview,
                    onClick: (data: any) => { 
                      fns.handlePreviewPDF();
                    },
                  },
                ]
              },
              {
                items: [
                  {
                    type: "item",
                    title: t("top menu - release, pdf release"),
                    dataTest: 'editor-doc__top-menu__release__release-pdf',
                    icon: Icon.PDF.Release,
                    onClick: (data: any) => { 
                      fns.handleReleasePDF();
                    },
                  },
                ]
              },
            ]
          }
        },
        
        {
          title: t("edit"),
          dataTest: 'editor-doc__top-menu__edit',
          panel: {
            sections: [
              {
                items: [
                  {
                    type: "item",
                    title: t("undo"),
                    dataTest: 'editor-doc__top-menu__edit__undo',
                    icon: Icon.Undo,
                    onClick: (data: any) => { 
                      fns.handleUndo();
                    },
                    keysLabel: `${systemKeys.actionSymbol}Z` ,
                    keysBindingCheck: (event: any) => (event.ctrlKey || event.metaKey) && ! event.shiftKey && event.key === 'z',
                    keysBidningPriority: KeyBindingPriority.EDITOR_INSTRUCTION
                  },
                  {
                    type: "item",
                    title: t("redo"),
                    dataTest: 'editor-doc__top-menu__edit__redo',
                    icon: Icon.Redo,
                    onClick: (data: any) => { 
                      fns.handleRedo();
                    },
                    keysLabel: `${systemKeys.shiftSymbol}${systemKeys.actionSymbol}Z` ,
                    keysBindingCheck: (event: any) => (event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'z',
                    keysBidningPriority: KeyBindingPriority.EDITOR_INSTRUCTION
                  },
                ]
              }
            ]
          }
        },
      ]
    }

    return config;
  }
  
  const hook = () => {
    return getConfig();
  }

  return hook;
};


export default useConfigMenu;