import { createContext } from 'react';


export interface ContextProps {
  cancelBlur: () => void;
};


const Context = createContext<ContextProps>({
  cancelBlur: () => {},
});


export default Context;