import { DraggerUpdate } from 'lego-v2/dragger/arch';
import DraggerComponent from 'lego-v2/dragger/ui';
import { CornerDotUpdate, CornerType } from './types';
import { DRAGGER_SIZE, DotWrapper, StyledDot } from './styles';


interface Props {
  x: number;
  y: number;
  scale: number;
  corner: CornerType;
  
  onUpdate: (update: CornerDotUpdate) => void;
  onUpdateDone: (update: CornerDotUpdate) => void;

  locked: boolean;
}


export const CornerDotComponent: React.FC<Props> = (props: Props) => {
  const {
    x, 
    y,
    scale,
    corner,
    onUpdate,
    onUpdateDone,
    locked,
  } = props;

  const handleDraggerStart = () => {
  }

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    const dotUpdate = {
      corner,
      x: update.position[0],
      y: update.position[1],
    }

    onUpdate(dotUpdate);
  }

  const handleUpdateDone = (update: DraggerUpdate) => {
    handleDraggerUpdate(update);
    const dotUpdate = {
      corner,
      x: update.position[0],
      y: update.position[1],
    }
    onUpdateDone(dotUpdate);
  }
  
  const renderUnlocked = () => {
    return (
      <DraggerComponent
        onUpdateStart={handleDraggerStart}
        onUpdate={handleDraggerUpdate}
        onUpdateDone={handleUpdateDone}
        position={[x, y]}
        scale={scale}
      >
        <DotWrapper
          draggable={false}
          style={{
            left: `${x - (DRAGGER_SIZE / 2 / scale)}px`,
            top:  `${y - (DRAGGER_SIZE / 2 / scale)}px`,
            transform: `scale(${1 / scale})`,
            transformOrigin: '0 0',
          }}
        >
          <StyledDot
            draggable={false}
          />
        </DotWrapper>
      </DraggerComponent>
    );
  }

  return (
    <>
      { ! locked && renderUnlocked() }
    </>
  )
}
