
import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"

import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useStates    from './use-states';
import useFunctions from './use-functions';
import useItems     from "./use-items";


const useConfig = () => {
  const t = useTranslations();
  const states = useStates();
  const fns = useFunctions();
  const items = useItems();


  const getConfig = (): PanelControlsConfig => {
    const config: PanelControlsConfig = {
      items: [
        {
          title: t('user profile'),
          sections: [
            {
              subsections:
              [
                {
                  title: t('user'),
                  items: 
                  [
                    {
                      title: t('email'),
                      type: PanelControlsItemSectionItemType.TEXT,
                      value: states.user.email ?? "",
                    },
                    items.getUserProp({
                      key: 'firstname',
                      title: t('firstname'),
                    }),
                    items.getUserProp({
                      key: 'lastname',
                      title: t('lastname'),
                    }),
                    {
                      title: t('user language'),
                      type: PanelControlsItemSectionItemType.DROPDOWN_LIST,
                      values: states.languages,
                      value: states.user.language ?? "",
                      onValueSelected: fns.updateUserLanguage
                    },
                  ]
                },
                {
                  title: t('company'),
                  items: [
                    items.getCompanyProp({
                      key: 'name',
                      title: t('company, name'),
                    }),
                    items.getCompanyProp({
                      key: 'address1',
                      title: t('company, address'),
                    }),
                    items.getCompanyProp({
                      key: 'address2',
                    }),                                        
                    items.getCompanyProp({
                      key: 'postcode',
                      title: t('company, postcode'),
                    }),
                    items.getCompanyProp({
                      key: 'state',
                      title: t('company, state'),
                    }),                    
                    items.getCompanyProp({
                      key: 'city',
                      title: t('company, city'),
                    }),
                    items.getCompanyProp({
                      key: 'country',
                      title: t('company, country'),
                    }),
                  ]
                },
                
                {
                  title: t('security'),
                  items: [
                    {
                      title: t('password'),
                      type: PanelControlsItemSectionItemType.BUTTON,
                      text: t('password reset'),
                      onClick: () => { fns.sendPasswordReset(states.user.email !); },
                    },
                  ]
                }
              ]
            },
          ]
        },
      ]
    } // config
  
    return config;
  }

  
  return getConfig;
};


export default useConfig;