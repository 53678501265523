import { ThemeDefProps } from "../types";


export const themeDefs_dialog = (props: ThemeDefProps) => {
  const { colors, accentColor, border, padding, font } = props;

  const dialog = {
    color: {
      title: colors.color.primary,
      message:   colors.color.third,
    },
    background: colors.background.primary,
  };
    
  return dialog;
}
