/**
 * Panel Settings
 */

export type Navbar = {
  itemSelected: NavbarItem
}


export enum NavbarItem {
  DOCUMENTS    = 'documents',
  REPOSITORIES = 'repositories',
  PRICING      = 'pricing',

  LEGAL_TERMS_OF_SERVICE = 'legal-terms-of-service',
  LEGAL_PRIVACY_POLICY   = 'legal-privacy-policy'
};

export type NavbarUpdate = Partial<Navbar>;

