import { useRef } from 'react';
import { useEffect } from 'react';
import useKeyBindingsBase     from 'lego/components/key-bindings/hooks/use-key-bindings';
import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';
import { KeyBinding   } from 'lego/components/key-bindings/arch/types';

import { UUID } from 'app/arch/types';
import useFunctions from './__use-functions';


const useKeyBindings = () => {
  const fns = useFunctions();
  const bindingIdsRef = useRef<UUID[]>([]);

  const {
    addKeyDownBinding,
    removeKeyDownBinding,
  } = useKeyBindingsBase();


  const addBindings = () => {
    const bindingsDefs: KeyBinding[] = [
      {
        check: (event: any) => {
          return [
            'Delete',
            'Backspace'
          ].includes(event.key);
        },
        callback: fns.deleteSelectedWidgets,
        priority: KeyBindingPriority.EDITOR_IMAGE
      },
      {
        check: (event: any) => event.key === 'Escape',
        callback: fns.resetSelectedWidgets,
        priority: KeyBindingPriority.EDITOR_IMAGE
      },
    ];

    bindingsDefs.forEach(binding => {
      const bindingIds = bindingIdsRef.current;
      const tmpId = addKeyDownBinding(binding);
      bindingIds.push(tmpId);
    });
  }

  const removeBindings = () => {
    const bindingIds = bindingIdsRef.current;
      bindingIds.forEach(id => removeKeyDownBinding(id));
      __resetBindingIds();
  }

  const __resetBindingIds = () => {
    bindingIdsRef.current = [];
  }



  useEffect(() => {
    addBindings();

    return () => {
      removeBindings();
    };
  }, []);
}

export default useKeyBindings;