import styled from 'styled-components';
import Button from 'lego/styles/button';
import { IoMdResize } from 'react-icons/io';


export const MainWrapper = styled.div`
`;

const MARKER_SIZE = 24;

export const MarkerStyled = styled(Button)<{$visible: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;

  /* width:  ${MARKER_SIZE}px; */
  /* height: ${MARKER_SIZE}px; */

  opacity: ${props => props.$visible ? 1 : 0};

  outline-style: none;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 1;
  }

  transition: all ${props => props.theme.defs.transition.time.normal} ease-in-out;
`;


export const IconResize = styled(IoMdResize)`
  transform: rotate(90deg);
`;
