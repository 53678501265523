import { ReposTypes } from "app/arch/app/states/repos";

const __getValues = () => {
  const valuesAll = Object.values(ReposTypes.MemberAccess);
  const values = valuesAll.slice(0, -1);
  // return valuesAll;
  return values;
}


const __getLevelAccessByIndex = (
  idx: number
): ReposTypes.MemberAccess => {
  const values = __getValues();
  const access = values[idx] as ReposTypes.MemberAccess;
  return access;
}


export const getPrevLevelAccess = (
  memberAccess: ReposTypes.MemberAccess
): ReposTypes.MemberAccess => {
  const values = __getValues();
  const idx = values.indexOf(memberAccess);
  
  if (idx === -1) {
    const msg = `Invalid member access: ${memberAccess}`;
    throw new Error(msg);
  }

  if (idx === 0) {
    const lowestAccess = __getLevelAccessByIndex(0);
    return lowestAccess;
  }
  
  const nextAccess = __getLevelAccessByIndex(idx - 1);
  return nextAccess;
}


export const getNextLevelAccess = (
  memberAccess: ReposTypes.MemberAccess
): ReposTypes.MemberAccess => {
  const values = __getValues();
  const idx = values.indexOf(memberAccess);
  
  if (idx === -1) {
    const msg = `Invalid member access: ${memberAccess}`;
    throw new Error(msg);
  }

  if (idx === values.length - 1) {
    const highestAccess = __getLevelAccessByIndex(values.length - 1);
    return highestAccess;
  }
  
  const nextAccess = __getLevelAccessByIndex(idx + 1);
  return nextAccess;
}


export const isLevelAccessFirst = (
  memberAccess: ReposTypes.MemberAccess
): boolean => {
  const values = __getValues();
  const idx = values.indexOf(memberAccess);

  return (idx === 0);
}


export const isLevelAccessLast = (
  memberAccess: ReposTypes.MemberAccess
): boolean => {
  const values = __getValues();
  const idx = values.indexOf(memberAccess);

  return (idx === (values.length - 1));
}
