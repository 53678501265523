
export namespace validate {

export namespace document {
  export const title = (title: string) => {

    const len = title.length;
    const valid = len >= 3;

    return valid;

  }

} // ns document


} // ns validator

