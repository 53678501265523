import { useEffect } from "react";
import useState from "./use-state"


const useViewParamsRead = () => {
  const homePageGuest = useState();
  const viewParams = homePageGuest.view.getView().params;

  useEffect(() => {
    homePageGuest.view.update({params: null});
  }, []);

  return viewParams;
}


export default useViewParamsRead;