import { ShapeLayerType } from "../../types";
import { Size }     from "app/arch/types";
import { Position } from "app/arch/types";
import { WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";

import { Box } from "./styles";


interface Props {
  layer: ShapeLayerType;
  widgetStyle: WidgetsStylesTypes.StyleAttrs;
  tailPosition: Position;
  tailSize: Size;
}


export const ShapeBoxComponent: React.FC<Props> = (props: Props) => {
  const {
    layer, 
    widgetStyle,
    tailPosition,
    tailSize,
  } = props;

  const left = tailPosition[0] ;
  const top  = tailPosition[1];
  const width  = tailSize[0] ;
  const height = tailSize[1] ;


  const boxStyles = {
    [ShapeLayerType.BOTTOM]: widgetStyle,
    [ShapeLayerType.MIDDLE]: {
      ...widgetStyle,
      outlineColor: "transparent",
    },
    [ShapeLayerType.TOP] : {} // not in use
  }

  const boxStyle = boxStyles[layer];

  return (
    <Box
      $left={left}
      $top={top}
      $width={width}
      $height={height}
      
      style={boxStyle}
    />
  );
}
