import styled from "styled-components";
import { Config } from "../config";


const SignBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: clip;
  box-sizing: border-box;

  outline-width: ${Config.style.outline.width}px;
  outline-style: solid;
  outline-color: black;
`;


export default SignBase;