import styled from "styled-components";
import ToolbarBase from "./toolbar-base";


export const ToolbarVertical = styled(ToolbarBase)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;


export default ToolbarVertical;