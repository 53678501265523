import styled from "styled-components";

import ABase from "../styles/a";
import BoldBase from "../styles/bold";
import AddressBase from "../styles/address";
import CompanyInfoBase from "../styles/company-info";
import CompanyNameBase from "../styles/company-name";
import LiBase from "../styles/li";
import UlBase from "../styles/ul";
import SubSectionBase from "../styles/sub-section";


export const A = styled(ABase)`
`;

export const Bold = styled(BoldBase)`
`;

export const Address = styled(AddressBase)`
`;

export const CompanyInfo = styled(CompanyInfoBase)`
`;

export const CompanyName = styled(CompanyNameBase)`
`;

export const Li = styled(LiBase)`
`;

export const Ul = styled(UlBase)`
`;

export const SubSection = styled(SubSectionBase)`
`;
