import React        from 'react';

import * as Types from './types';

import DraggerMouseComponent from './dragger-mouse';
import { useIsMobile } from 'lego-hooks/use-is-mobile';
import DraggerTouchComponent from './dragger-touch';


interface Props extends Types.DraggerProps {
}


export const DraggerComponent: React.FC<Props> = (props: Props) => {
  const isMobile = useIsMobile();

  return (
    <>
      { ! isMobile && <DraggerMouseComponent {...props}/> }
      { isMobile && <DraggerTouchComponent {...props}/> }
      </>
  );
}
