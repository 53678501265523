import styled from 'styled-components';
import { ToastsTypes } from 'app/arch/app/info/toasts';
import { ToastsTools } from 'app/arch/app/info/toasts';


export const ToastWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;


export interface ToastProps {
  $level: ToastsTypes.ToastLevel;
}

export const Toast = styled.div<ToastProps>`
  user-select: none;
  pointer-events: none;
  flex-shrink: 1;

  color: ${props => props.theme.defs.colors.color.secondary};
  background-color: ${props => ToastsTools.getToastColor(props.$level)};

  border-style: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: ${props => props.theme.defs.border.radius.normal};

  box-shadow: 
    0 12px 28px 0 rgba(0, 120, 255, 0.2), 
    0 2px 4px 0 rgba(0, 120, 255, 0.1),
    inset 0 0 0 1px rgba(220, 225, 255, 0.5)
  ;

  font-size:   ${props => props.theme.defs.font.size.normal};
  font-weight: ${props => props.theme.defs.font.weight.normal};

  padding: 
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.large}
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.large}
  ;
`;

export const DummyFiller = styled.div`
  flex-grow: 1;
`;