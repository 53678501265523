import * as docx from "docx";
import jtl from "tools/jtl";

import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { ReleaselogsTypes } from "app/arch/editor-instruction/document/states/persistent/releaselogs";

import AssetsRepo    from "../../../parts/assets-repo";
import ExporterBase  from "../../parts/exporter-base";
import TextConverter from "../../parts/text-converter";

import * as Defaults from '../../defaults';
import * as Tools from '../../tools';


class ReleaselogExporter extends ExporterBase {
  private _columnsWidthTotal: number;
  
  constructor(docState: DocState, assetsRepo: AssetsRepo) {
    super(docState, assetsRepo);
    this._columnsWidthTotal = 0;
  }

  get content() { return this._docState.content; }
  get releaselog() { return this._docState.releaselogs; }
  get releaselogView() { return this._docState.releaselogsView; }

  createSections() {   
    const releaselogs = [
      ...this._addReleaselogsTitle(),
      new docx.Paragraph(""),
      ...this._addReleaselogs()
    ];
    return releaselogs;
  }

  private _addReleaselogsTitle() {
    const borders = this.getBordersFull();
    const shading = this.getHeaderShading();
    const margins = {
      ...Tools.getPadding(16),
    };
    const title = this.releaselogView.getHeaderTitle();
    const textHeader = Defaults.getTextHeaderOptions({
      text: title.title,
      size: 48,
    });

    const cell = new docx.TableCell({
      borders,
      margins,
      shading,
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.CENTER,
        children:[new docx.TextRun(textHeader)]
      })],
    });

    const row = new docx.TableRow({
      children: [ cell ],
    });

    const table = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: [ 
        row,
      ]
    });
    
    return [ table ];
  }

  private _addReleaselogs() {
    const releaselogsAddrs = this.releaselog.getReleaselogsAddrs();
    const reversed = [...releaselogsAddrs].reverse();

    const releaselogs = reversed.map(releaselogAddr => {
      this.calculateColumnsWidth(releaselogAddr);
      const info = this._addReleaselogInfo(releaselogAddr);
      const contentHeader = this._addReleaselogContentHeader(releaselogAddr);
      const content = this._addReleaselogContent(releaselogAddr);

      const table = new docx.Table({
        // layout: docx.TableLayoutType.FIXED,
        borders: this.getBordersNone(),
        width: {
          size: 100,
          type: docx.WidthType.PERCENTAGE,
        },
        rows: [ 
          ...info,
          contentHeader,
          ...content,
        ]
      });
      
      return [
        table,
        new docx.Paragraph(""),
      ];
    });

    return releaselogs.flat();
  }

  private _addReleaselogContentHeader(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ) {
    const columnsAddrs = this.releaselog.getColumnsAddrs(releaselogAddr);
    const cells = columnsAddrs.map(columnAddr => {
      return this._addColumnHeaderCell(columnAddr)
    });

    const row = new docx.TableRow({
      children: cells,
    });
    
    return row;
  }

  private _addColumnHeaderCell(
    columnAddr: ReleaselogsTypes.ColumnAddr,
  ): docx.TableCell {
    const sizePerc = this.getColumnSize(columnAddr);
    const columnProps = this.releaselog.getColumnProps(columnAddr);
    const css = this._docState.viewsCommon.getTableHeaderCSS();

    const textRunOpt = Defaults.getTableHeaderOptions(css, {
      text: `${columnProps.name}`,
    });

    const padding = Tools.getCellPadding(css);

    const cell = new docx.TableCell({
      margins: padding,
      borders: this.getBordersFull(),
      shading: this.getHeaderShading(),
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.LEFT,
        children:[new docx.TextRun(textRunOpt)]
      })]
    });
    
    return cell;
  }

  private _addReleaselogInfo(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ) {
    const rows = [
      this._addReleaselogInfoTitle(releaselogAddr),
      this._addReleaselogInfoDate(releaselogAddr),
    ];

    const hasDescription = this._hasReleaselogDescription(releaselogAddr);
    if ( hasDescription ) {
      const description = this._addReleaselogInfoDescription(releaselogAddr);
      rows.push(description)
    }

    return rows;
  }

  private _addReleaselogInfoTitle(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ) {
    const columnsAddrs = this.releaselog.getColumnsAddrs(releaselogAddr);
    const borders = this.getBordersDefault();
    const columnSpan = columnsAddrs.length;
    const idx = this.releaselog.getReleaselogIdx(releaselogAddr) + 1;
    const shading = this.getHeaderShading();
    const margins = {
      ...Tools.getPadding(8),
      bottom: 0,
    };

    const textHeader = Defaults.getTextHeaderOptions({
      text: `Revision ${idx}`,
      size: 32,
    });

    const cell = new docx.TableCell({
      borders,
      columnSpan,
      margins,
      shading,
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.LEFT,
        children:[new docx.TextRun(textHeader)]
      })],
    });

    const row = new docx.TableRow({
      children: [ cell ],
    });

    return row;
  }

  private _addReleaselogInfoDate(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ) {
    const MARGIN = 8;

    const columnsAddrs = this.releaselog.getColumnsAddrs(releaselogAddr);
    const columnSpan = columnsAddrs.length;
    const borders = this.getBordersOnSide();
    const shading = this.getHeaderShading();

    const bottom = (
      this._hasReleaselogDescription(releaselogAddr) ?
      0 :
      Tools.pixelsToDxa(MARGIN)
    );

    const margins = {
      ...Tools.getPadding(MARGIN),
      top: 0,
      bottom,
    };

    const releaselogProps = this.releaselog.getReleaselogProps(releaselogAddr);
    const releaselogDate = releaselogProps.info.date;
    
    const date: Date = (
      releaselogDate !== null ?
      new Date(releaselogDate) :
      new Date()
    );

    const [dateyyyy, _] = jtl.date.toYYYYMMDD(date);
    
    const textOpt = {
      ...Defaults.getFont(),
      size: 12,
      text: `${dateyyyy}`,
    };

    const cell = new docx.TableCell({
      borders,
      columnSpan,
      margins,
      shading,
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.LEFT,
        children:[new docx.TextRun(textOpt)]
      })],
    });

    const row = new docx.TableRow({
      children: [ cell ],
    });

    return row;
  }

  private _hasReleaselogDescription(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ) {
    const releaselogProps = this.releaselog.getReleaselogProps(releaselogAddr);
    const description = releaselogProps.info.description;
    
    return ( description.text && description.text.length > 0 );
  }

  private _addReleaselogInfoDescription(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr,
  ) {
    const columnsAddrs = this.releaselog.getColumnsAddrs(releaselogAddr);
    const borders = this.getBordersOnSide();
    const columnSpan = columnsAddrs.length;
    const shading = this.getHeaderShading();
    const margins = {
      ...Tools.getPadding(8),
      top: 0,
    };
    
    const releaselogProps = this.releaselog.getReleaselogProps(releaselogAddr);
    const description = releaselogProps.info.description;

    const textOpt = {
      ...Defaults.getFont(),
      size: 18,
      text: `${description.text}`,
    };

    const cell = new docx.TableCell({
      borders,
      columnSpan,
      margins,
      shading,
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.LEFT,
        children:[new docx.TextRun(textOpt)]
      })],
    });

    const row = new docx.TableRow({
      children: [ cell ],
    });

    return row;
  }

  private _addReleaselogContent(releaselogAddr: ReleaselogsTypes.ReleaselogAddr) {
    const changelog = this.releaselog.getReleaselogChangelog(releaselogAddr);
    const rowsAddrs = changelog.rows.addrs;
    const docxRows = rowsAddrs.map(rowAddr => this._addRow(rowAddr));
    const rowsDefs = docxRows;

    return rowsDefs;
  }


  private _addRow(rowAddr: ReleaselogsTypes.RowAddr) {
    const cells = this._createRowCells(rowAddr);
    const row = new docx.TableRow({
      children: cells,
    });

    return row;
  }

  private _createRowCells(
    rowAddr: ReleaselogsTypes.RowAddr,
  ) {
    const changelog = this.releaselog.getReleaselogChangelog(rowAddr);
    const columnsAddrs = changelog.columns.addrs;

    const cells = columnsAddrs.map((columnAddr) => {
      const cellAddr: ReleaselogsTypes.CellAddr = {
        ...rowAddr,
        columnId: columnAddr.columnId
      };

      return this._copyCell(cellAddr)
    });

    return cells;
  }

  private _copyCell(
    cellAddr: ReleaselogsTypes.CellAddr,
  ) {
    const columnProps = this.releaselog.getColumnProps(cellAddr);
    const columnType = columnProps.type;

    type GetCellContent = (
      cellAddr: ReleaselogsTypes.CellAddr,
    ) => docx.TableCell; 

    const copyFnsMap: {[key in ReleaselogsTypes.ColumnType]: GetCellContent } = {
      [ReleaselogsTypes.ColumnType.INDEX]: (cellAddr: ReleaselogsTypes.CellAddr) => this._copyCellIndex(cellAddr),
      [ReleaselogsTypes.ColumnType.TEXT ]: (cellAddr: ReleaselogsTypes.CellAddr) => this._copyCellText(cellAddr),
    }

    const copyCellFn = copyFnsMap[columnType];
    return copyCellFn(cellAddr);
  }

  private _getCellMargins(
    cellAddr: ReleaselogsTypes.CellAddr,
  ) {
    const columnProps = this.releaselog.getColumnProps(cellAddr);
    const css = columnProps.css;
    const padding = Tools.getCellPadding(css);

    return padding;
  }

  private _copyCellIndex(
    cellAddr: ReleaselogsTypes.CellAddr,
  ): docx.TableCell {
    const idx = this.releaselog.getRowIdx(cellAddr) + 1;
    const sizePerc = this.getColumnSize(cellAddr);
    const borders = this.getBordersFull();
    const margins = this._getCellMargins(cellAddr);
    
    const css = this._docState.viewsCommon.getTableHeaderCSS();
    const color = jtl.color.expandHex(css.color);

    const text = `${idx}`;
    const textRunOpt = Defaults.getTextHeaderOptions({text, color});
    
    const cell = new docx.TableCell({
      borders,
      margins,
      shading: this.getHeaderShading(),
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.CENTER,
        children:[new docx.TextRun(textRunOpt)]
      })]
    });
    
    return cell;
  }

  private _copyCellText(
    cellAddr: ReleaselogsTypes.CellAddr,
  ): docx.TableCell {
    const cell = this.releaselog.getCell(cellAddr) as ContentTypes.TextCell;
    if ( ! cell.editorState ) {
      throw new Error("Content exproter editor state is null");
    }

    const textConverter = new TextConverter();
    const paragraphs = textConverter.convert(cell.editorState);
    const sizePerc = this.getColumnSize(cellAddr);
    const borders = this.getBordersFull();
    const margins = this._getCellMargins(cellAddr);

    const docxCell = new docx.TableCell({
      borders,
      margins,
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: paragraphs,
    });

    return docxCell;
  }

  private calculateColumnsWidth(
    releaselogAddr: ReleaselogsTypes.ReleaselogAddr
  ) {
    const changelog = this.releaselog.getReleaselogChangelog(releaselogAddr);
    const columnsAddrs = changelog.columns.addrs;

    const totalWidth = columnsAddrs.reduce((acc, columnAddrs) => {
      const columnProps = this.releaselog.getColumnProps(columnAddrs);
      return acc + columnProps.width;
    }, 0);

    this._columnsWidthTotal = totalWidth;
  }

  private getColumnSize(
    columnAddr: ReleaselogsTypes.ColumnAddr,
  ) {
    const columnProps = this.releaselog.getColumnProps(columnAddr);
    const columnWidth = columnProps.width;;
    const sizePerc = (columnWidth / this._columnsWidthTotal) * 100;

    return sizePerc;
  }
}


export default ReleaselogExporter;