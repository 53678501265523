export enum ViewType {
  CHANGELOG = "view-changelog",
  CONTENT   = "view-content",
};

/**
 * View sliced and loaded
 */
export type ViewsLoaded = { 
  [view in ViewType]: boolean
}



/**
 * Pages
 */

export type PagesCount = {
  [view in ViewType]: number
}

export type  PageCurrent = {
  view: ViewType,
  pageIdx: number,
}

export type PageCurrentUpdate = Partial<PageCurrent>;


export type Pages = {
  current: PageCurrent,
  count: PagesCount
}
