import useDocContentSelectedReset       from './doc-content/use-selected-reset';
import useDocCustomFieldsSelectedReset  from './doc-custom-fields/use-selected-reset';
import useDocHeaderRowsSelectedReset    from './doc-custom-rows';
import useDocHeaderMarkersSelectedReset from './doc-header-markers/use-selected-reset';


const useSelectedReset = () => {
  const docContentSelectedReset = useDocContentSelectedReset();
  const docCustomFieldsSelectedReset = useDocCustomFieldsSelectedReset();
  const docHeaderMarkersSelectedReset = useDocHeaderMarkersSelectedReset();
  const docHeaderRowsSelectedReset = useDocHeaderRowsSelectedReset();

  const hook = (props?: {
    skipStateUpdate?: boolean
  }) => {
    docContentSelectedReset(props);
    docCustomFieldsSelectedReset(props);
    docHeaderMarkersSelectedReset(props);
    docHeaderRowsSelectedReset(props);
  }

  return hook;
}


export default useSelectedReset;
