import React from 'react';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { Box } from './styles';


interface Props {
}


export const PricesLoadProgressComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  return (
    <ProgressBoxComponent 
      Box={Box}
      title={ t('products loading') }
    />
  );
}
