import styled from "styled-components";


export const Anchor = styled.div`
  position: relative;
`;


export const ColumnsResizersDetacher = styled.div`
  position: absolute;

  // TODO a bit of hack
  // if we do not deduct 2 * 1px - which is
  // for top and bottom border, then resizers
  // would extend 2 px over content
  height: calc(100% - 2px);
  width: 0px;
  /* background: green; */
`;