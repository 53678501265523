import React from 'react';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';
import ProbeHeightComponent from 'app/ui-v2/editor-instruction/views/view-changelog/components/content/page/probe-height';


interface Props {
  enabled: boolean;
  cellAddr: ReleaselogsTypes.CellAddr;
  children: React.ReactNode;
}


export const GridCellProbeComponent: React.FC<Props> = (props: Props) => {
  const {
    enabled,
    cellAddr,
    children 
  } = props;

  const document = useDocState();

  const handleHeightUpdate = (height: number) => {
    const elementsSizes = document.releaselogsElementsSizes;
    elementsSizes.releaselogs.setChangelogRowHeight(cellAddr, height);
  }

  return (
    <ProbeHeightComponent
      enabled={enabled}
      onHeightUpdate={handleHeightUpdate}
    >
      { children }
    </ProbeHeightComponent>
  )
};
  
