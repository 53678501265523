import styled from "styled-components";
import Panel  from "lego/styles/panel";


export const MenuWrapper = styled.div<{$visible: boolean}>`
  display: flex;
  position: relative;
  visibility: ${props => props.$visible ? 'visible' : 'hidden'};
`;

export const MenuPanel = styled(Panel)`
  flex-grow: 1;

`;
