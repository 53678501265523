import styled from "styled-components";


const TopToolbarSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;

  height: 100%;

  border-color: ${props => props.theme.button.border.color.primary};
  border-style: none;
  border-width: 1px;
`;


export default TopToolbarSection;