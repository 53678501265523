import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";
import * as Types from '../types';
import { useRef } from "react";
import { Position } from "app/arch/types";
import { LogoImageTool } from "app/arch/editor-logo/logo-image";


const useLogoRescale = (states: Types.States) => {
  const {
    logoImage,
    logoImageView,
  } = states;

  const docState = useDocState();
  const logoCenterInitRef = useRef<Position>([0, 0] as Position);

  const { 
    setHeaderMeta,
  } = useEditorStatesSetters();

  const logoImageTool = new LogoImageTool(logoImage);

  const getLogoScale = () => {
    const sizeDelta = logoImageTool.calculateSizeDelta(logoImageView.scale);
    return sizeDelta;
  }

  const getLogoScaleMax = () => {
    const scaleMax = logoImageTool.getSizeDeltaMax();
    return scaleMax;
  }
  
  const getLogoScaleMin = () => {
    const scaleMin = logoImageTool.getSizeDeltaMin();
    return scaleMin;
  }

  const handleLogoRescaleStart = () => {
    const position = logoImageView.position;
    const scale    = logoImageView.scale;

    logoCenterInitRef.current = logoImageTool.getCenterPoint(position, scale);
  }

  const handleLogoRescale = (sliderValue: number) => {
    const scaleNew    = logoImageTool.calculateScale(sliderValue);
    const positionNew = logoImageTool.calculatePosition(logoCenterInitRef.current, scaleNew);

    docState.headerMeta.updateLogoView({scale: scaleNew});
    docState.headerMeta.updateLogoView({position: positionNew});

    setHeaderMeta();
  }

  const handleLogoRescaleDone = (sliderValue: number) => {
    handleLogoRescale(sliderValue);
    docState.saveUndo();
  }
  

  return {
    getLogoScale,
    getLogoScaleMin,
    getLogoScaleMax,

    handleLogoRescaleStart,
    handleLogoRescale,
    handleLogoRescaleDone,
  }
}

export default useLogoRescale;