import React from 'react';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { MainWrapper } from './styles';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
  children: React.ReactNode;
}


export const ImageIdComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children,
  } = props;

  const htmlId = ContentTools.getImageHTMLId(imageAddr);

  return (
    <MainWrapper id={htmlId}>
      { children }
    </MainWrapper>
  );
}
