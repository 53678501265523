import * as Types from './types';
import * as Defaults from './defaults';


export type State = {
  debug: boolean,
  release: Types.Release,
};

//
// Getters
//
export const getDebug   = (state: State) => state.debug;
export const getRelease = (state: State) => state.release;


//----------------------------
// Create initial state
//
export const createInitialState = () => {
  const initState: State = {
    debug: true,
    release: Defaults.getRelease(),
  }

  return initState;
}
