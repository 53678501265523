import React from 'react';

import { CellPropsBase } from '../types';

import ColumnCssComponent             from '../parts/column-css';
import CellOverflowClipperComponent   from '../parts/cell-overflow-clipper';
import CellSelectedBorderComponent    from '../parts/cell-selected-border';
import CellSelectComponent            from '../parts/cell-select';
import CellsLineSelectedComponent     from '../parts/cells-line-selected';
import CellsLineDragOverComponent     from '../parts/cells-line-drag-over';
import CellKeybindingsComponent       from '../parts/cell-keybindings';
import RowDropComponent               from '../parts/row-drop';
import ColumnDropComponent            from '../parts/column-drop';
import CellImagesDropComponent        from './cell-images-drop';
import CellImagesContextMenuComponent from './cell-images-context-menu';
import ImagesComponent                from './images';
import ImagesUploadingComponent       from './images-uploading';


interface Props extends CellPropsBase {
}


export const CellImagesComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;
  
  return (
    <CellSelectedBorderComponent cellAddr={cellAddr} >
      <CellOverflowClipperComponent>
        <CellsLineSelectedComponent cellAddr={cellAddr} >
          <CellsLineDragOverComponent cellAddr={cellAddr} >
            <CellSelectComponent cellAddr={cellAddr} >
              <CellKeybindingsComponent cellAddr={cellAddr} >
                <CellImagesContextMenuComponent cellAddr={cellAddr} >
                  <RowDropComponent cellAddr={cellAddr} >
                    <ColumnDropComponent cellAddr={cellAddr} >
                      <CellImagesDropComponent cellAddr={cellAddr} >
                        <ColumnCssComponent cellAddr={cellAddr} >
                          <ImagesComponent cellAddr={cellAddr} /> 
                          <ImagesUploadingComponent cellAddr={cellAddr} />
                        </ColumnCssComponent>
                      </CellImagesDropComponent>
                    </ColumnDropComponent>
                  </RowDropComponent>
                </CellImagesContextMenuComponent>
              </CellKeybindingsComponent>
            </CellSelectComponent>
          </CellsLineDragOverComponent>
        </CellsLineSelectedComponent>
      </CellOverflowClipperComponent>
    </CellSelectedBorderComponent>
  );
}
