import React from 'react';
import { SmartLineRaw } from 'tools/smart-lines/types';
import { SmartLine    } from 'tools/smart-lines/smart-line';
import { StyledSmartLine } from './styles';


const LINE_OFFSET = 100;


interface Props {
  line: SmartLineRaw;
}


export const SmartLineComponent: React.FC<Props> = (props: Props) => {
  const { line } = props;

  let style: any = {
    left:   `${line.x0}px`,
    top:    `${line.y0}px`,
  };

  if ( SmartLine.IsHorizontal(line) ) {
    const width = SmartLine.GetWidth(line);

    style = {
      ...style,
      width: `${width + LINE_OFFSET}px`,
      left:  `${line.x0 - LINE_OFFSET / 2}px`,
      }
  }
  else {
    const height = SmartLine.GetHeight(line);
    style = {
      ...style,
      height: `${height + LINE_OFFSET}px`,
      top:    `${line.y0 - LINE_OFFSET / 2}px`,
    }
  }

  return (
    <StyledSmartLine 
      style={style}
    />
  );
}
  
