export interface Size {
  width: number;
  height: number;
}


export enum SizeVariant {
  WIDTH_320 = 'width-320',
  WIDTH_360 = 'width-360',
  WIDTH_390 = 'width-390',
  WIDTH_500 = 'width-500',
  WIDTH_600 = 'width-600',
}
