import produce from 'immer';

import * as Defaults from './defaults';
import * as State from './state';
import * as Tools from './tools';
import * as Types from './types';


const MAX_INFOS_COUNT = 4;


class Infos {
  private _state: State.State;
  private _listener: Types.Listener;

  constructor() {
    this._state = State.createInitialState();
    this._listener = null;
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */

  addInfo(props: Types.InfoPartialProps) {
    const infoAddr = Tools.createInfoAddr();
    const infoProps = Defaults.getInfoProps(props);

    this._state = produce(this._state, draft => {
      const infosAddrs = State.getInfosAddrs(draft);
      const infosProps = State.getInfosProps(draft);

      const infoKey = Tools.getInfoKey(infoAddr);

      infosAddrs.splice(0, 0, infoAddr);
      infosProps[infoKey] = infoProps;

      this.__removeOverflowingInfos(draft);
    });

    this.runListener();

    return infoAddr;
  }

  private __removeOverflowingInfos(draft: State.State) {
    const infosAddrs = State.getInfosAddrs(draft);
    if (infosAddrs.length <= MAX_INFOS_COUNT) {
      return;
    }

    for (let i = MAX_INFOS_COUNT; i < infosAddrs.length; i++ ) {
      this.__removeInfo(draft, infosAddrs[i]);
    }
  }

  private __removeInfo(
    draft: State.State,
    infoAddr: Types.InfoAddr
  ) {
    const infosAddrs = State.getInfosAddrs(draft);
    const infosProps = State.getInfosProps(draft);

    const infoIdx = State.getInfoIdx(draft, infoAddr);
    const infoKey = Tools.getInfoKey(infoAddr);

    infosAddrs.splice(infoIdx, 1);
    delete infosProps[infoKey];
  }

  private __removeAll() {
    this._state = produce(this._state, draft => {
      const infosAddrs = State.getInfosAddrs(draft);
      const infosAddrsCopy = [...infosAddrs];
      
      infosAddrsCopy.forEach((infoAddr) => {
        this.__removeInfo(draft, infoAddr);
      })
    });

    this.runListener();
  }


  addListener(listener: Types.Listener) {
    this._listener = listener;
  }

  removeListener() {
    this._listener = null;
  }

  private runListener() {
    if (this._listener === null) {
      return;
    }

    this._listener(this._state);
  }


  /**
   * Getters
   */

  /**
   * Infos
   */
  getInfosAddrs(): Types.InfosAddrs { 
    return State.getInfosAddrs(this._state);
  }

  getInfosProps(): Types.InfosProps { 
    return State.getInfosProps(this._state);
  }


  /**
   * Click
   */
  getInfoProps(
    infoAddr: Types.InfoAddr,
  ): Types.InfoProps { 
    return State.getInfoProps(this._state, infoAddr);
  }

  getInfoIdx(
    infoAddr: Types.InfoAddr,
  ): number { 
    return State.getInfoIdx(this._state, infoAddr);
  }



  /**
   * Manual tests
   */

  _reset() {
    this.__removeAll();
  }

  _test1() {

    const msgs = [
      'Create document',
      'Upload company logo',
      'Upload images',
      'Update document changelog',
      'Release document'
    ];

    const max = msgs.length;
    const min = 0;
    const randIdx = Math.floor(Math.random() * (max - min)) + min;


    const msg = msgs[randIdx];
    this.addInfo({text: msg});
  }

  _test2() {
    this.addInfo({text: "Edit this and that"});
  }
}

export default Infos;