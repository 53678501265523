import React from 'react';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useContent from 'app/ui/contexts/document/use-content';
import MarkerComponent from './marker';


interface Props {
  cellAddr: ContentTypes.CellAddr;
}



export const CellMarkersComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;

  const content = useContent();

  const renderMarkers = () => {
    const cell = content.getCell(cellAddr) as ContentTypes.MarkersCell;
    const markers = cell.markers;
    const Markers = markers.addrs.map((markerAddr) => renderMarker(markerAddr));

    return Markers;
  }

  const renderMarker = (markerAddr: ContentTypes.MarkerAddr) => {
    const markerKey = ContentTools.getMarkerKey(markerAddr);

    return (
      <React.Fragment key={markerKey}>
        <MarkerComponent markerAddr={markerAddr} />
      </React.Fragment>
    );
  }

  return (
    <>
      { renderMarkers() }
    </>
  );
}

