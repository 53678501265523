import * as Defaults from './defaults';
import * as Tools from './tools';
import * as Types from './types';


/**
 * State
 */
export type State = Types.ReloginState;


/**
 * Getters
 */
export const getVisible = (
  state: State
): boolean => { 
  return state.visible;
}


/**
 * Initial state
 */
export const createInitialState = (): State => {
  const initState: State = Defaults.getState();
  return initState;
}
