import React from 'react';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/releaselogs/types';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTools as Tools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';

import { RowProps } from '../types';
import HeaderCellComponent from './header-cell';


interface Props extends RowProps {
}


export const RowHeaderCellComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
    releaselogId
  } = props;

  const document = useDocState();
  const releaselogAddr = { releaselogId };

  const columnsAddrs = document.releaselogs.getColumnsAddrs(releaselogAddr);
  const headerRowConfig = item as SlicerTypes.ChangelogItem_HeaderRow;


  //-------------------
  // Header cells
  const renderHeaderCell = (columnAddr: Types.ColumnAddr) => {
    const key = Tools.getColumnKey(columnAddr);

    return (
      <HeaderCellComponent
        key={key}
        columnAddr={columnAddr}
      />
    );
  }

  const renderHeaderRow = () => {
    const CellsComps = columnsAddrs.map((columnAddr) => renderHeaderCell(columnAddr));
    return CellsComps;
  }

  return renderHeaderRow()
}
  
