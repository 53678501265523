import { Size } from "app/arch/types";
import { Position } from "app/arch/types";


export interface ScrollProps {
  scrollLeft: number,
  scrollTop: number,
}


const useRescaleScrollbarUpdate = () => {

  const updateScrollbar = (props: {
    scrollbarInit: ScrollProps,
    scaleInit: number,
    scaleNew: number,
    scalePointInit: Position, 
    scalePointNew: Position, 
  }) => {
    const {
      scrollbarInit,
      scaleInit,
      scaleNew,
      scalePointInit,
      scalePointNew,
    } = props;

    const scalePointMove = [
      scalePointInit[0] - scalePointNew[0],
      scalePointInit[1] - scalePointNew[1]
    ] as Size;

    const scrollLeft = (
      // Scrollbar delta
      scrollbarInit.scrollLeft / scaleInit * scaleNew

      // Content delta
      + (scalePointNew[0] / scaleInit - scalePointNew[0] / scaleNew) * scaleNew

      // Scale point move
      + scalePointMove[0] / scaleInit * scaleNew
    );


    const scrollTop = (
      // Scrollbar delta
      scrollbarInit.scrollTop / scaleInit * scaleNew

      // Content delta
      + (scalePointNew[1] / scaleInit - scalePointNew[1] / scaleNew) * scaleNew

      // Scale point move
      + scalePointMove[1]
    );

    return { scrollLeft, scrollTop };
  }

  return updateScrollbar;
}


export default useRescaleScrollbarUpdate;