import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useContent from 'app/ui/contexts/document/use-content';
import HLS_WidgetsViewScaledComponent from 'app/ui-v2/editor-image/hls/widgets-view-scaled';


interface Props {
  imageAddr: ContentTypes.ImageAddr
}


export const WidgetsComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
  } = props;

  const content = useContent();

  const imageProps = content.cellImages_getImageProps(imageAddr);

  return (
    <HLS_WidgetsViewScaledComponent 
      imageAddr={imageAddr}
      viewArea={imageProps.viewArea}
      viewScale={imageProps.viewScale}
    />
  );
}
