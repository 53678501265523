import React from 'react';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/releaselogs/types';

import ViewTitleComponent from './view-title';
import { PageItemsProps } from '../types';


interface Props extends PageItemsProps {
}


export const PageItemViewTitleComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
  } = props;

  const viewTitle = item as SlicerTypes.PageItem_ViewTitle;
  
  return (
    <ViewTitleComponent />
  );
}
  
