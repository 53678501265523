import i18next from 'i18next';

import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import ControlWidgetsStackComponent from '../side-panel/controls/control-widgets-stack';

import { GetConfigProps } from "./types";


const getConfigWidgetsStack = (props: GetConfigProps) => {
  const t = i18next.t;

  const config: PanelControlsConfig = {
    items: [
      {
        title: t('layers'),
        sections: [
          {
            items: [
              {
                title: t('widgets stack'),
                type: PanelControlsItemSectionItemType.USER_COMPONENT,
                component: ControlWidgetsStackComponent
              },
            ]
          },
        ]
      }
    ]
  } // config

  return config;
}

export default getConfigWidgetsStack;