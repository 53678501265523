import styled from "styled-components";
import Text from "lego/styles/text";


export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;


export const Features = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xxxsmall};
`;


export const OutroWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  padding-bottom: ${props => props.theme.defs.padding.normal};
`;


export const Title = styled(Text)`
  font-size: ${props => props.theme.defs.font.size.xxlarge};
  font-weight: ${props => props.theme.defs.font.weight.large};
`;
