import styled from "styled-components";


const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width:  20px;
  height: 100%;

  /* background-color: red; */
`;


export default IconWrapper;
