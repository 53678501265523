import React from 'react';

import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { useDocState } from 'app/ui/contexts/document';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import HLC_MarkerDraggerComponent from 'app/ui-v2/editor-instruction/hls/markers/marker-dragger';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerDraggerComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr, 
    children
  } = props;

  const document = useDocState();
  const setDNDData = useDNDDataSet();

  const markerProps = document.headerMarkers.getMarkerProps(markerAddr);

  const handleDragStart = () => {
    const data = JSON.stringify({markerAddr});
    setDNDData(data);
  }

  return (
    <HLC_MarkerDraggerComponent
      markerAddr={markerProps.repoMarkerAddr}
      dndContext={DNDContext.marker.docHeader}
      onDragStart={handleDragStart}
    >
      { children }
    </HLC_MarkerDraggerComponent>
  );
}
