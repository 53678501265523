import styled from "styled-components";
import zIndex from "../../../../../z-index";
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";


export const Detacher = styled.div`
  position: absolute;
  z-index: ${zIndex.inCell};

  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;

  pointer-events: none;
/* background: rgba(0, 255, 0, 0.5); */
`;


interface PositionerProps {
  $placement: ContentTypes.ImageIdxPlacement;
}

export const Positioner = styled.div.attrs<PositionerProps>(props => ({
  style: getDetacherStyle(props.$placement),
}))<PositionerProps>`
  position: absolute;
`;


export const IdxLabel = styled.div`
  overflow: clip;

  background: white;
  padding: 3px;

  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #333;
  border-radius: 3px;
`;

export const Text = styled.div`
  user-select: none;
  color: #333;
  position: relative;
  line-height: 12px;
  font-size:   12px;
  font-weight: 400;
`;


const getDetacherStyle = (
  corner: ContentTypes.ImageIdxPlacement
) => {
  const positionDelta = 0;

  switch (corner) {
    case ContentTypes.ImageIdxPlacement.TOP_LEFT: {
      return {
        top: `${positionDelta}px`,
        left: `${positionDelta}px`,
      }
    }
    case ContentTypes.ImageIdxPlacement.TOP_RIGHT: {
      return {
        top: `${positionDelta}px`,
        right: `${positionDelta}px`,
      }
    }
    case ContentTypes.ImageIdxPlacement.BOTTOM_LEFT: {
      return {
        bottom: `${positionDelta}px`,
        left: `${positionDelta}px`,
      }
    }
    case ContentTypes.ImageIdxPlacement.BOTTOM_RIGHT: {
      return {
        bottom: `${positionDelta}px`,
        right: `${positionDelta}px`,
      }
    }
    default: {
      console.warn(`Unsupported corner type: ${corner}`);
      return {};
    }
  }
}