import { useState } from 'react';
import { useEffect } from 'react';

import ButtonText from 'lego/styles/button_text';

import * as Types from './types';
import { configureHook } from './use-dialog-custom';
import IconRenderComponent from './icon-render';

import { Dialog }        from './styles';
import { MsgWrapper }    from './styles';
import { DialogTitle }   from './styles';
import { DialogContent } from './styles';
import { DialogActions } from './styles';
import { IconWrapper }   from './styles';
import { DialogContentText } from './styles';


export const DialogCustomComponent = () => {
  const [open, setOpen]   = useState(false);
  const [title, setTitle] = useState("");
  const [msg, setMsg]     = useState("");
  const [msgComponent, setMsgComponent] = useState<any | null>(null);

  const [buttons, setButtons] = useState([]);
  const [icon, setIcon] = useState<Types.IconType | null>(null);
  
  useEffect(() => {
    configureHook({
      showDialog: () => { setOpen(true); },
      hideDialog: () => { setOpen(false); },
      setTitle: (title: string) => {
        setTitle(title);
      },
      setMessage: (msg: string) => { 
        setMsg(msg);
      },
      setIcon: (icon: Types.IconType | null) => {
        setIcon(icon);
      },
      setMessageComponent: (msgComponent: any) => { 
        if (msgComponent) {
          setMsgComponent(msgComponent);
        }
        else {
          setMsgComponent(null);
        }
      },
      setButtons: (buttons: any) => { 
        setButtons(buttons);
      },
    });
  });

  const handleClose = () => {
    setOpen(false);
  };


  const renderButtons = () => {
    const buttonsComponents = buttons.map((button: Types.Button, idx: number) => {
      const onClick = () => {
        button.onClick && button.onClick();
        handleClose();
      }

      return (
        <ButtonText
          key={idx}
          onClick={onClick} 
          autoFocus={button.autoFocus}
          data-test={button.dataTest}
        >
          {button.text}
        </ButtonText>
      );
    })

    return buttonsComponents;
  }

  const renderMsg = () => {
    if ( msg.length === 0 && msgComponent === undefined) {
      const msg = `Dialog box - you need to specify either message or message component`;
      throw new Error(msg);
    }

    if ( msgComponent ) {
      return msgComponent;
    }

    return (
      <DialogContentText>
        { msg }
      </DialogContentText>
    );
  }


  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        { title }
      </DialogTitle>

      <DialogContent>
        {
          icon &&
          <IconWrapper>
            <IconRenderComponent icon={icon}/>
          </IconWrapper>      
        }

        <MsgWrapper>
          { renderMsg() }
        </MsgWrapper>
      </DialogContent>

      <DialogActions>
        { renderButtons() }
      </DialogActions>

    </Dialog>
  );
}
