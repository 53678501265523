import React from 'react';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ViewPanelComponent from 'app/ui-v2/home-page/hls/view-panel';
import TextError from 'app-views/styles/text-error';


interface Props {
}


export const PasswordUpdateFailedComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  
  return (
    <ViewPanelComponent title={t("password reset failed")} >
      <TextError>
      { t("password reset failed") }
      </TextError>
    </ViewPanelComponent>
  );
}
