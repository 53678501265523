import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';

import { InfoItem } from './styles';
import { Info }     from './styles';
import { InfoItemSeparator } from './styles';


interface Props {
}


export const SelectedWidgetInfoComponent: React.FC<Props> = (props: Props) => {
  const widgetAddr = useRecoilValue(UIState_EditorImageSession.widgetSelected);

  if (widgetAddr === null) {
    return null;
  }

  return (
    <WidgetSelectedComponent widgetAddr={widgetAddr} />
  );
}



/**
 * Widget Selected
 */

interface WidgetSelectedProps {
  widgetAddr: ContentTypes.WidgetAddr;
}


const WidgetSelectedComponent: React.FC<WidgetSelectedProps> = (props: WidgetSelectedProps) => {
  const {
    widgetAddr
  } = props;

  const widgetProps = useRecoilValue(UIState_Content.cellImages_image_widgetProps(widgetAddr));

  if (widgetProps === undefined) {
    console.error('Should not happend, widget is undefined');
    return null;
  }

  const renderDual = () => (
    <>
      {/* 
      <InfoItem>
      { widgetProps.name }
      </InfoItem>

      <InfoItemSeparator /> 
      */}

      <InfoItem>
        { widgetProps.type }
      </InfoItem>
    </>
  );

  const renderSingle = () => (
    <InfoItem>
    { widgetProps.name }
    </InfoItem>
  );

  const singleInfo = ( widgetProps.name === widgetProps.type );

  return (
    <Info>
      {   singleInfo && renderSingle() }
      { ! singleInfo && renderDual() }
    </Info>
  );
}
