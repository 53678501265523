import Errors from "app/arch/tools/errors";


const useTextSelectionClear = () => {

  const clearSelection = () => {
    if ( ! window.getSelection) {
      Errors.hard("Very old browser, which does not support `window.getSelection`");
      return;
    }

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  };


  return clearSelection;
}


export default useTextSelectionClear;