import { useTranslations } from 'app/ui/hooks/app/use-translation';
import PanelItemGenerator  from 'app/ui-v2/editor-instruction/panel-items/generator';
import useFunctions from './__use-functions';
import useStates from './__use-states';


const useItems = () => {
  const t = useTranslations();
  const fn = useFunctions();

  const {
    visible,
    logoVisible,
    revisionVisible,
  } = useStates();


  const getInfoVisible = () => 
    PanelItemGenerator.getPanelVisibility({
      visible: visible,
      onClick: fn.setInfoVisible,
    });

  const getLogoVisible = () => 
    PanelItemGenerator.getToggleOnOff({
      title: t('page header, show logo'),
      selected: logoVisible,
      onClick: fn.setLogoVisible,
    });

  const getRevisionVisible = () => 
    PanelItemGenerator.getToggleOnOff({
      title: t('page header, show release'),
      selected: revisionVisible,
      onClick: fn.setRevisionVisible,
    });


  return {
    getInfoVisible,
    getLogoVisible,
    getRevisionVisible,
  }
}


export default useItems;