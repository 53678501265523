import { ContentTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset from '../use-selected-reset';


const useRowToSectionMove = () => {
  const document = useDocState();

  const {
    setContent,
  } = useEditorStatesSetters();

  const resetSelected = useSelectedReset();

  const moveRowToSection = (props: {
    srcRow: Types.RowAddr,
    dstSection: Types.SectionAddr,
  }) => {
    const {
      srcRow,
      dstSection,
    } = props;

    document.content.moveRowToSection(srcRow, dstSection);
    document.saveUndo();
    setContent();
    resetSelected();
  }

  return moveRowToSection;
}


export default useRowToSectionMove;