import { StickyLinesRaw } from "tools/smart-lines/types";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


export const useSmartLinesWidgetsStickyLinesSetter = () => {
  const document = useDocState();

  const {
    setEditorImageSession,
  } = useEditorStatesSetters();
  
  const setStickyLines = (stickyLines: StickyLinesRaw) => {
    document.editorImageSession.setSmartLinesWidgetsStickyLines(stickyLines);
    setEditorImageSession();
  }

  const unsetStickyLines = () => {
    document.editorImageSession.unsetSmartLinesWidgetsStickyLines();
    setEditorImageSession();
  }

  return {
    setStickyLines,
    unsetStickyLines,
  };
}
