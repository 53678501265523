import MenuItem from "lego/styles/menu_item";
import TextWrap from "lego/styles/text-wrap";
import styled from "styled-components";


export const MainWrapper = styled.div`

`;


export const Item = styled(MenuItem)`
  height: 32px;
  display: flex;
  gap: ${props => props.theme.defs.gap.large};
`;

export const Text = styled(TextWrap)`
`;