import { ThemeDefProps } from "../types";


export const themeDefs_menuItem = (props: ThemeDefProps) => {
  const { colors, accentColor, font, border, padding, boxShadow, gap } = props;

  const menuItem = {
    color: {
      primary:  colors.color.third,
      selected: colors.color.secondary,
      hover:    colors.color.secondary,
      active:   colors.color.secondary
    },

    background: {
      hover:    accentColor.primary,
      selected: accentColor.opacity06.primary,
      active:   accentColor.dark,
    },

    border: {
      radius: border.radius.normal,
    },

    font: {
      size: font.size.large
    },

    padding: padding.normal,
    gap: gap.normal,


    // Menu item - responsible for deleting resources 
    // like: row, column, etc. 
    variant: {
      delete: {
        background: {
          active: colors.special.delete.dark,
          hover:  colors.special.delete.primary,
        },
      }
    }
  };
    
  return menuItem;
}

