import { LexicalEditor } from "lexical";


export class LexicalRegistry<T> {
  private _register: {[id: string]: LexicalEditor};
  private _debugName: string;
  
  constructor() {
    this._register = {};
    this._debugName = this.getDebugName();
  }

  addLexical(itemAddr: T, lexcial: LexicalEditor) {
    const id = this.getId(itemAddr);

    if (this._register[id]) {
      const msg = (
        `[${this._debugName}] Instance of text editor already exists. ` +
        `Item addr: ${itemAddr}`
      );
      throw new Error(msg);
    }

    this._register[id] = lexcial;
  }

  getLexical(itemAddr: T) {
    const id = this.getId(itemAddr);
    const lexical = this._register[id];

    if ( ! lexical) {
      const msg = (
        `[${this._debugName}] Can't find instance of text editor (get). ` +
        `Item addr: ${itemAddr}`
      );
      throw new Error(msg);
    }

    return lexical;
  }

  hasLexical(itemAddr: T) {
    const id = this.getId(itemAddr);
    const lexical = this._register[id];

    return lexical !== undefined;
  }

  removeLexical(itemAddr: T) {
    const id = this.getId(itemAddr);

    if ( ! (id in this._register)) {
      const msg = (
        `[${this._debugName}] Can't find instance of text editor (remove). ` +
        `Item addr: ${itemAddr}`
      );
      throw new Error(msg);
    }

    delete this._register[id];
  }

  protected getDebugName(): string {
    throw new Error(`Override me, debug name`);
  }

  protected getId(itemAddr: T): string {
    throw new Error(`Override me, get id`);
  }
}
