import styled from "styled-components";


export const SmartLinesWrapper = styled.div`
  position: absolute;
  pointer-events: none;
`;

export const SelectedWidgetsBoxWrapper = styled.div`
  position: absolute;
  /* pointer-events: none; */
`;
