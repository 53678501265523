import React from 'react';
import { useRef } from 'react';
import { CellBaseProps } from '../types';

import useReleaselogIsEditable     from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-releaselog-is-editable';
import CellSelectedBorderComponent from '../parts/cell-selected-border';
import CellsLineSelectedComponent  from '../parts/cells-line-selected';
import CellSelectComponent         from '../parts/cell-select';
import CellTextEditorComponent     from './cell-text-editor';
import ColumnCSSCustomComponent    from './column-css-custom';


interface Props extends CellBaseProps {
}


export const CellTextComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;

  const editorTextRef = useRef<HTMLDivElement | null>(null);

  return (
    <CellTextActivePartsComponent cellAddr={cellAddr} >
      <ColumnCSSCustomComponent
        columnAddr={cellAddr}
        editorTextRef={editorTextRef}
      >
        <CellTextEditorComponent
          cellAddr={cellAddr}
          editorTextRef={editorTextRef}
        />
      </ColumnCSSCustomComponent>
    </CellTextActivePartsComponent>
  );
}
  


/**
 * 
 * Active parts of cell.
 * This parts should be disabled, when
 * given releaselog is not editable.
 * 
 */

interface CellTextActivePartsProps extends Props {
  children: React.ReactNode;
}


const CellTextActivePartsComponent: React.FC<CellTextActivePartsProps> = (props: CellTextActivePartsProps) => {
  const { 
    cellAddr,
    children,
  } = props; 
  
  const isEditable = useReleaselogIsEditable(cellAddr);

  if ( ! isEditable ) {
    return children;
  }

  return (
    <CellSelectedBorderComponent cellAddr={cellAddr} >
      <CellsLineSelectedComponent cellAddr={cellAddr} >
        <CellSelectComponent cellAddr={cellAddr} >
          { children }
        </CellSelectComponent>
      </CellsLineSelectedComponent>
    </CellSelectedBorderComponent>
  );
}
