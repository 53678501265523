import { WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";
import * as State from './state';
import * as Types from './types';


export class EditorImageSettings {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  //----------------------
  // 
  // Setters

  setWidgetStyle(style: WidgetsStylesTypes.StyleAttrs) {
    const nextState = State.setWidgetStyle(this._state, style);
    this.processNewState(nextState);
  }

  setGridSize(size: number) {
    const nextState = State.setGridSize(this._state, size);
    this.processNewState(nextState);
  }

  setGridEnabled(enabled: boolean) {
    const nextState = State.setGridEnabled(this._state, enabled);
    this.processNewState(nextState);
  }

  setGridShowType(showType: Types.GridType) {
    const nextState = State.setGridShowType(this._state, showType);
    this.processNewState(nextState);
  }

  setSmartLinesAligmentLinesVisible(visible: boolean) {
    const nextState = State.setSmartLinesAligmentLinesVisible(this._state, visible);
    this.processNewState(nextState);
  }
  
  setSmartLinesSticky(sticky: boolean) {
    const nextState = State.setSmartLinesSticky(this._state, sticky);
    this.processNewState(nextState);
  }

  setSmartLinesStickyness(stickyness: number) {
    const nextState = State.setSmartLinesStickyness(this._state, stickyness);
    this.processNewState(nextState);
  }
  
  setSmartLinesSrcLinesVisible(visible: boolean) {
    const nextState = State.setSmartLinesSrcLinesVisible(this._state, visible);
    this.processNewState(nextState);
  }
  
  //----------------------
  // 
  // Getters

  getWidgetStyle() {
    return this._state.widget.style;
  }

  getGrid() {
    return State.getGrid(this._state);
  } 

  getSmartLines() {
    return State.getSmartLines(this._state);
  } 


  private processNewState(newState: State.State) {
    return this._state = newState;
  }
}
