import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';
import { useIsMobile }  from 'lego-hooks/use-is-mobile';

import * as Types          from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { UploadSource }    from "app/arch/types/types-v2";
import { Icon }            from "app/ui/icons/icons";
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useCellImageUpload from "./use-cell-image-upload";



const useCellImagesMenu = () => {
  const t = useTranslations();
  const isMobile = useIsMobile();

  const uploadCellImage = useCellImageUpload();
  
  const {
    hideMenu
  } = useContextMenu();


  const uploadImageFromDisk = (cellAddr: Types.CellAddr) => {
    const source   = UploadSource.DISK;
    uploadCellImage({ cellAddr, source });
    hideMenu();
  }
  
  const uploadImageFromCamera = (cellAddr: Types.CellAddr) => {
    const source   = UploadSource.CAMERA_BACK;
    uploadCellImage({cellAddr, source});
    hideMenu();
  }


  const getConfig = (cellAddr: Types.CellAddr) => {

    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('images, upload from disk'),
                iconComponent: Icon.Upload.Disk,
                onClick: (event: React.MouseEvent) => uploadImageFromDisk(cellAddr),
              }
            },
            {
              type: MenuItemType.BUTTON,
              skip: ! isMobile,
              data: {
                title: t('image, upload from camera'),
                iconComponent: Icon.Upload.Camera,
                onClick: (event:  React.MouseEvent) => uploadImageFromCamera(cellAddr),
              }
            },
          ],
        },
      ]
    }

    return config;
  }


  const hook = (cellAddr: Types.CellAddr) => {
    const config = getConfig(cellAddr);
    return config;
  }

  return hook;
}


export default useCellImagesMenu;