import React from 'react';
import { useRecoilValue } from "recoil";

import { Arrow } from "app/arch/editor-image/types/arrows";
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { EditorImageSettingsTools } from 'app/arch/editor-instruction/document/states/persistent/editor-image-settings';
import { UIState_Content } from "app/ui/states/editor-instruction";

import ShapeBoxComponent       from "../../shapes/shape-box";
import ShapeDotsComponent      from "../../shapes/shape-dots";
import ShapePointerComponent   from "../../shapes/shape-pointer";
import ShapeTriangleComponent  from "../../shapes/shape-triangle";
import ShapeArrowOpenComponent from "../../shapes/shape-arrow-open";
import { ShapeLayerType }      from "../../types";
import { ArrowState }       from "../state-routines";
import { calcTailPosition } from "../state-routines";


interface Props {
  widgetAddr: ContentTypes.WidgetAddr;
  arrowState: ArrowState;
}


export const WidgetArrowTextViewComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
    arrowState,
  } = props;

  const widgetProps_ = useRecoilValue(UIState_Content.cellImages_image_widgetProps(widgetAddr));
  const widgetProps  = widgetProps_ as ContentTypes.WidgetArrowTextProps;
  const widgetPointer = widgetProps.pointer as Arrow.PointerType;

  // TODO
  const widgetStyle = widgetProps.style;
  const widgetStyleUpdated = EditorImageSettingsTools.getWidgetStyle(widgetProps.type, widgetProps.style);

  const PointerShapes: {[key in Arrow.PointerType]: any} = {
    [Arrow.PointerType.ARROW   ] : ShapeArrowOpenComponent,
    [Arrow.PointerType.POINTER ] : ShapePointerComponent,
    [Arrow.PointerType.TRIANGLE] : ShapeTriangleComponent,
    [Arrow.PointerType.DOTS    ] : ShapeDotsComponent,
  }

  const PointerComponent = PointerShapes[widgetPointer];

  const tailPosition = calcTailPosition(
    arrowState.body.endPoint, 
    arrowState.tail.size
  );

  const renderArrow = (shapeLayer: ShapeLayerType) => (
    <PointerComponent
      layer={shapeLayer}
      style={widgetStyle}
      startPoint={arrowState.body.startPoint}
      endPoint={arrowState.body.endPoint}
    />
  );

  const renderBackground = (shapeLayer: ShapeLayerType) => (
    <ShapeBoxComponent
      layer={shapeLayer}
      widgetStyle={widgetStyleUpdated}
      tailPosition={tailPosition}
      tailSize={arrowState.tail.size}
    />
  );

  const renderArrows = () => {
    if ( widgetProps.jointed ) {
      return (
        <>
          { renderArrow(ShapeLayerType.BOTTOM) }
          { renderBackground(ShapeLayerType.BOTTOM) }

          { renderArrow(ShapeLayerType.MIDDLE) }
          { renderBackground(ShapeLayerType.MIDDLE) }

          { renderArrow(ShapeLayerType.TOP) }
          {/* Not needed - and can cause problems */}
          {/* { renderBackground(ShapeLayerType.TOP) } */}
        </>
      );
    }
    else
    {
      return (
        <>
          { renderArrow(ShapeLayerType.BOTTOM) }
          { renderArrow(ShapeLayerType.MIDDLE) }
          { renderArrow(ShapeLayerType.TOP) }

          { renderBackground(ShapeLayerType.BOTTOM) }

          {/* Not problematic, but not needed */}
          {/* { renderBackground(ShapeLayerType.MIDDLE) } */}
          {/* { renderBackground(ShapeLayerType.TOP) } */}
        </>
      );
    }
  }

  return renderArrows();
}
  
