import React from 'react';
import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import useColumnCSS from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-column-css';
import { ColumnCSS } from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const ColumnCssComponent: React.FC<Props> = (props: Props) => {
  const { 
    cellAddr,
    children 
  } = props;
  
  const style = useColumnCSS(cellAddr);

  return (
    <ColumnCSS style={style}>
      { children }
    </ColumnCSS>
  );
}
