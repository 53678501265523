import styled from 'styled-components';
import PanelFloatingBase   from 'lego/styles/panel-floating';
import WindowButtonControl from 'lego/styles/window-button-control';
import WindowTopbar        from 'lego/styles/window-topbar';
import WindowTitle         from 'lego/styles/window-title';


export interface PanelProps {
  $left: number;
  $top: number;
}

export const Panel = styled(PanelFloatingBase).attrs<PanelProps>(props =>({
  style: {
    left: `${props.$left}px`,
    top:  `${props.$top}px`,
  }
}))<PanelProps>`
  position: absolute;
  z-index: 1;
`;

export const Topbar = styled(WindowTopbar)`
`;

export const Title = styled(WindowTitle)`
`;

export const Button = styled(WindowButtonControl)`
`;
