import { useApp } from 'app/ui/hooks/app/use-app';


const useUser = () => {
  const app = useApp();
  const user = app.user;
  return user;
}


export default useUser;