import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes }    from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import ProgressComponent   from 'app/ui-v2/hls/progress';

import { Border } from './styles';


interface Props {
  cellAddr: ContentTypes.CellAddr;
}


export const ImageUploadingViewComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;

  const imageBorderCSS = useRecoilValue(UIState_Content.columnImages_imageBorderCSS(cellAddr));
  const css = imageBorderCSS || {};

  return (
    <Border style={css}>
      <ProgressComponent />
    </Border>
  );
}

