import PageHeader from './page-header';
import Releaselogs   from './releaselogs';


export class ElementsSizes {
  private _pageHeader: PageHeader;
  private _releaselogs: Releaselogs;

  constructor() {
    this._pageHeader  = new PageHeader();
    this._releaselogs = new Releaselogs();
  }

  get pageHeader() { 
    return this._pageHeader; 
  }

  get releaselogs() { 
    return this._releaselogs;
  }
}
