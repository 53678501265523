import { atom } from 'recoil';
import { selector } from 'recoil';
import { UserInvoicesState as State } from 'app/arch/app/user';


export const state = atom<State.State>({
  key: "user_invoices",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const loaded = selector({
  key: `user_invoices_loaded`,
  get: ({ get }) => {
    const stateObj = get(state) ;
    return State.getLoaded(stateObj);
  }
});
