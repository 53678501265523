import React from 'react';
import { useSetRecoilState } from 'recoil';

import { useIsMobile } from 'lego-hooks/use-is-mobile';

import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { useDocState }        from 'app/ui/contexts/document';
import { UIState_RepoImages } from 'app/ui/states/editor-instruction';

import UploadPanelMobileComponent  from './upload-panel-mobile';
import UploadPanelDesktopComponent from './upload-panel-desktop';


interface Props {
  onImagesUploaded: () => void;
}


export const UploadPanelComponent: React.FC<Props> = (props: Props) => {
  const {
    onImagesUploaded
  } = props;

  const isMobile = useIsMobile();
  const document = useDocState();
  const setDocImagesState = useSetRecoilState(UIState_RepoImages.state);

  const handleImagesUploaded = (images: RepoImagesTypes.ImagesRaw) => {
    document.repoImages.loadImages(images);
    setDocImagesState(document.repoImages.state);
    onImagesUploaded();
  }

  return (
    <>
      { ! isMobile && <UploadPanelDesktopComponent onImagesUploaded={handleImagesUploaded} /> }
      {   isMobile && <UploadPanelMobileComponent  onImagesUploaded={handleImagesUploaded} /> }
    </>
  );
}
  
