import React from 'react';

import { IconWrapper } from './styles';
import { Item        } from './styles';
import { ItemDelete  } from './styles';
import { TextWrapper } from './styles';


interface Props {
  title: string;
  IconComponent: any;

  variant?: string;
}


export const MenuItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    IconComponent, 
    title,
  } = props;

  const ItemVariant = (
    props.variant === 'delete' ?
    ItemDelete :
    Item
  );

  return (
    <ItemVariant>
      <IconWrapper >
        <IconComponent/>
      </IconWrapper>

      <TextWrapper>
      { title }
      </TextWrapper>

    </ItemVariant>
  );
}
  
