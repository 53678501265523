import styled from 'styled-components';
import TitleBase from 'app-views/styles/title';


interface GridProps {
  $minHeight: number;
}

export const Grid = styled.div<GridProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.theme.defs.gap.normal};

  max-height: 100%;
  min-height: ${props => `${props.$minHeight}px`};
  /* background: rgba(0, 255, 0, 0.2); */
  /* padding-bottom: 45px; */
`;


/**
 * Row Title
 */
export const RowTitle = styled(TitleBase)`
  flex: 0 0 auto;
  text-align: center;
  /* background: rgba(0, 255, 0, 0.2); */
`;


/**
 * Row Content
 */
export const RowContent = styled.div`
  display: flex;
  overflow: hidden;
  
  width: 100%;
  justify-content: center;

  /* background: rgba(0, 0, 255, 0.2); */
`;

export const ContentDefault = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};
  
  /* background: rgba(255, 0, 255, 0.2); */
  align-items: stretch;
  width: 100%;
  max-width: 300px;

  /* 
  overflow: hidden - protector (but not only)
  
  The overflow property is only applied 
  to the element it is set on and its 
  immediate children, not to nested 
  descendants beyond that.
  */
`;

export const DebugInfo = styled.div`
  position: fixed;
  top: 40px;
  left: calc(50% - 30px);

  color:          ${props => props.theme.defs.colors.color.primary};
  font-size:      ${props => props.theme.defs.font.size.normal};
  font-weight:    ${props => props.theme.defs.font.weight.small};
`;