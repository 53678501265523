import useScreenKeyboardAdjuster from './use-screen-keyboard-adjuster';


const useScreenKeyboardAdjusterEnable = () => {
  const adjuster = useScreenKeyboardAdjuster();
  
  const enable = (en: boolean) => {
    adjuster.setEnabled(en);
  }

  return enable;
}


export default useScreenKeyboardAdjusterEnable;