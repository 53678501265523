import produce from 'immer';

import * as State from './state';
import * as Types from './types';
import * as Tools from './tools';
import * as Defaults from './defaults';



export class HeaderRows {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  reset() {
    this._state = State.createInitialState();
  }

  /**
   * Row
   */
  addRow(
    rowType: Types.RowType,
    rowProps?: Types.RowPropsUpdate
  ): Types.RowAddr {
    const rowAddr = Tools.createRowAddr();

    this._state = produce(this._state, draft => {
      const rowsAddrs = State.getRowsAddrs(draft);
      const idx = rowsAddrs.length;
    
      this.__addRowAtIdx(
        draft,
        idx,
        rowAddr,
        rowType,
        rowProps
      );
    });

    return rowAddr;
  }

  addRowBefore(
    srcRowAddr: Types.RowAddr,
    rowType:    Types.RowType,
    rowProps?:  Types.RowPropsUpdate
  ): Types.RowAddr {
    const columnAddr = Tools.createRowAddr();

    this._state = produce(this._state, draft => {
      const idx = State.getRowIdx(draft, srcRowAddr);

      this.__addRowAtIdx(
        draft,
        idx,
        columnAddr,
        rowType,
        rowProps
      );
    });

    return columnAddr;
  }

  addRowAfter(
    srcRowAddr: Types.RowAddr,
    rowType:    Types.RowType,
    rowProps?:  Types.RowPropsUpdate
  ): Types.RowAddr {
    const rowAddr = Tools.createRowAddr();

    this._state = produce(this._state, draft => {
      const idx = State.getRowIdx(draft, srcRowAddr);

      this.__addRowAtIdx(
        draft,
        idx + 1,
        rowAddr,
        rowType,
        rowProps
      );
    });

    return rowAddr;
  }

  private __addRowAtIdx(
    draft: State.State, 
    idx: number,
    rowAddr: Types.RowAddr,
    rowType:  Types.RowType,
    rowPropsUpdate?: Types.RowPropsUpdate,
  ) {
    const rowsAddrs  = State.getRowsAddrs(draft);
    const rowsProps  = State.getRowsProps(draft);
    
    const rowProps = {
      ...Defaults.getRowProps(rowType),
      ...rowPropsUpdate
    };
  
    const rowKey = Tools.getRowKey(rowAddr);
  
    rowsAddrs.splice(idx, 0, rowAddr);
    rowsProps[rowKey]  = rowProps;
  
    //
    // Create cells
    //
    const cellAddr: Types.CellAddr = {
      rowId: rowAddr.rowId,
    }

    this.__createCell(draft, cellAddr, rowType);
  }

  deleteRow(rowAddr: Types.RowAddr) {
    this._state = produce(this._state, draft => {
      const rowsAddrs  = State.getRowsAddrs(draft);
      const rowsProps  = State.getRowsProps(draft);
      
      const idx = State.getRowIdx(draft, rowAddr);
      const rowKey = Tools.getRowKey(rowAddr);
    
      rowsAddrs.splice(idx, 1);
      delete rowsProps[rowKey];
    
      //
      // Delete cells
      //
      const cellAddr: Types.CellAddr = {
        rowId: rowAddr.rowId,
      }
      this.__deleteCell(draft, cellAddr);
    });
  }

  moveRow(
    srcRowAddr: Types.RowAddr,
    dstRowAddr: Types.RowAddr,
  ) {
    let moved = false;

    this._state = produce(this._state, draft => {
      if ( Tools.compareRowAddr(srcRowAddr, dstRowAddr )) {
        console.log(`Src and dst rows are the same. Skipping move.`)
        return;
      }
    
      const rowsAddrs = State.getRowsAddrs(draft);
    
      const srcRowIdx = State.getRowIdx(draft, srcRowAddr);
      const srcIdxLowerThanDstIdx = (srcRowIdx < State.getRowIdx(draft, dstRowAddr));
    
      const srcRow = rowsAddrs.splice(srcRowIdx, 1)[0];
      const dstRowIdx = State.getRowIdx(draft, dstRowAddr);
    
      if (srcIdxLowerThanDstIdx) {
        rowsAddrs.splice(dstRowIdx + 1, 0, srcRow);
      }
      else {
        rowsAddrs.splice(dstRowIdx, 0, srcRow);
      }
      moved = true;
    });

    return moved;
  }

  updateRow(
    rowAddr: Types.RowAddr,
    update: Types.RowPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const columnProps = State.getRowProps(draft, rowAddr);
      Object.assign(columnProps, update);
    });
  }

  private __createCell(
    draft: State.State,
    cellAddr: Types.CellAddr,
    cellType: Types.RowType,
  ) {
    const cells = State.getCells(draft);
    const cellKey = Tools.getCellKey(cellAddr);

    if (cellKey in cells) {
      const msg = `Can create cell, as cell already exists`;
      throw new Error(msg);
    }

    const cellDef = Defaults.getCell(cellType);
    cells[cellKey] = cellDef;
  }

  private __deleteCell(
    draft: State.State,
    cellAddr: Types.CellAddr
  ) {
    const cells = State.getCells(draft);
    const cellKey = Tools.getCellKey(cellAddr);

    if (! ( cellKey in cells )) {
      const msg = `Can delete cell, as it is missing`;
      throw new Error(msg);
    }

    delete cells[cellKey];
  }

  /**
   * Cell Text
   */
  cellText_writeText(
    cellAddr: Types.CellAddr, 
    text: string
  ) {
    this._state = produce(this._state, draft => {
      const cell = State.getCell(draft, cellAddr) as Types.TextCell;
      cell.text = text;
    });
  }

  updateColumn(
    columnIdx: Types.ColumnIdx,
    update: Types.ColumnPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const columnProps = State.getColumnProps(draft, columnIdx);
      Object.assign(columnProps, update);
    });
  }

  getRowsAddrs() {
    return State.getRowsAddrs(this._state);
  }

  getRowProps(rowAddr: Types.RowAddr) {
    return State.getRowProps(this._state, rowAddr);
  }

  getColumnsProps() {
    return State.getColumnsProps(this._state);
  }

  getCell(cellAddr: Types.CellAddr) {
    return State.getCell(this._state, cellAddr);
  }
}
