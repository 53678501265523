import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import jtl from "tools/jtl";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import environment from "app/environment";


const __init = () => {
  i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    debug: environment.dev,

    // If you're using a language detector, 
    // do not define the lng option
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
};

export const init = jtl.core.callOnce(__init);

