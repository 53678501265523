/**
 * Panel Settings
 */

export type View = {
  selected: ViewItem,
  params: ViewParams,
}


export enum ViewItem {
  WELCOME   = 'welcome',
  PRICING   = 'pricing',

  DOCUMENTS = 'documents',
  DOCUMENT  = 'document',
  
  REPOSITORIES = 'repositories',
  
  PAYMENT_DONE = 'payment-completed',

  LEGAL_TERMS_OF_SERVICE = 'legal-terms-of-service',
  LEGAL_PRIVACY_POLICY   = 'legal-privacy-policy'
};


export type ViewUpdate = Partial<View>;



/**
 * ViewParams
 */
export type ViewParams = (
    ViewParamsDocument
  | ViewParamsBlank
);

export type ViewParamsBlank = null;

export type ViewParamsDocument = {
  documentId: number,
}
