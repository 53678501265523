import React from 'react';
import DocInfoViewComponent  from './doc-info-view';
import DocInfoProbeComponent from './doc-info-probe';


interface Props {
}


export const DocInfoComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <DocInfoProbeComponent>
      <DocInfoViewComponent />
    </DocInfoProbeComponent>
  );
}
  
