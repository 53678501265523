import styled from "styled-components";
import zIndex from "../../z-index";
import CellsSelectedBackground from "app/ui-v2/editor-instruction/views/shared/styles/cells-selected-background";


export const MainWrapper = styled.div`
  position: relative;
  height: 100%;
`;


export const Background = styled(CellsSelectedBackground)`
  z-index: ${zIndex.selectedBackground};
`;
