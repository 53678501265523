
import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './use-items';


const useNoColumnConfig = () => {
  const t = useTranslations();
  const items = useItems();

  const getConfig = (): PanelControlsConfig => ({
    items: [
      {
        disabled: true,
        title: t("column"),
        sections: [
          {
            items: [
              items.getColumnNotSelected()
            ],
          },
        ]
      },
    ]
  });  


  return getConfig;
}

export default useNoColumnConfig;
