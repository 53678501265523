import { ThemeDefProps } from "../types";


export const themeDefs_menuBarItem = (props: ThemeDefProps) => {
  const { colors, accentColor, font, border, padding, boxShadow } = props;

  const menuBarItem = {
    color: {
      primary: colors.color.primary,
      selected: colors.color.secondary,
      active: colors.color.secondary,
    },
    
    background: {
      active: `linear-gradient(
        to bottom,
        ${accentColor.primary},
        ${accentColor.dark}
      )`,
      selected: `linear-gradient(
        to bottom,
        ${accentColor.primary},
        ${accentColor.dark}
      )`,

    },

    border: {
      radius: border.radius.normal
    },

    padding: padding.large,

    font: {
      size: font.size.large
    }
  };
    
  return menuBarItem;
}

