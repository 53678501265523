import React from 'react';
import TooltipFollowComponent from 'lego/components/tooltip-follow';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDocState }     from 'app/ui/contexts/document';
import useLogoUpload from 'app/ui-v2/editor-logo/hooks/use-logo-upload';

import { MainText } from './styles';
import { LogoWrapper } from './styles';


interface Props {
}


export const LogoImageBlankComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const uploadLogo = useLogoUpload();
  const document   = useDocState();

  const logoUploadDone = () => {
    // document.editorLogoSession.setVisible(true);
    // setEditorLogoSessionState(document.editorLogoSession.state);
  }

  const handleDblClick = (event: React.MouseEvent) => {
    uploadLogo({onDone: logoUploadDone});
  }

  return (
    <TooltipFollowComponent
      title={t("logo click to upload")}
    >
      <LogoWrapper
        onClick={handleDblClick}
      >
        <MainText>
        { t("logo not found") }
        </MainText>
      </LogoWrapper>
    </TooltipFollowComponent>
  );
}
