import React from 'react';
import { useRecoilValue } from 'recoil';

import jtl from 'tools/jtl';

import SidePanelBaseComponent from 'lego/components/side-controls/side-panel';
import { SideControlsTypes }  from 'lego/components/side-controls';
import { PanelType } from 'lego/components/side-controls/types';

import { ScreenSizeTools } from 'app/arch/app/screen';
import { ScreenSizeTypes } from 'app/arch/app/screen';
import { EditorImageSettingsTypes } from 'app/arch/editor-instruction/document/states/persistent/editor-image-settings';
import { Arrow } from 'app/arch/editor-image/types/arrows';
import { SideToolbar } from 'app/arch/editor-image/types';

import { useDocState } from 'app/ui/contexts/document';
import { UIState_Content, UIState_EditorImageSettings } from 'app/ui/states/editor-instruction';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useWindowContext from 'app/ui-v2/editor-image/hooks/use-window-context';

import getConfigArrow        from '../configs/config-arrow';
import getConfigGrid         from '../configs/config-grid';
import getConfigWidgetsStack from '../configs/config-widgets-stack';
import getConfigSmartLines   from '../configs/config-smart-lines';
import getConfigStyles       from '../configs/config-styles';
import getConfigTextBox      from '../configs/config-text-box';

import useConfigWidgetStyles from './configs/use-config-widget-styles';

import useConfigView from '../configs/config-view';
import useConfigColors from '../configs/config-colors';


type Props = SideControlsTypes.PropLeftOrRight;


export const SidePanelComponent: React.FC<Props> = (props: Props) => {
  const {
    left,
    right,
  } = props;

  const document = useDocState();
  const panelType    = useRecoilValue(UIState_EditorImageSession.sideToolbarPanelType);
  const panelVisible = useRecoilValue(UIState_EditorImageSession.sideToolbarPanelVisible);

  const {
    setContent,
    setEditorImageSettings,
  } = useEditorStatesSetters();

  const getConfigView = useConfigView();
  const getConfigColors = useConfigColors();

  // Panel Size type
  const windowContext = useWindowContext();
  const windowWidth   = windowContext.size[0];
  
  const widgetSelectedAddr = useRecoilValue(UIState_EditorImageSession.widgetSelected);
  const widgetEditedAddr   = useRecoilValue(UIState_EditorImageSession.widgetEdited);

  const widgetAddr = (widgetSelectedAddr || widgetEditedAddr);

  const getConfigWidgetStyles = useConfigWidgetStyles(widgetAddr);

  const grid        = useRecoilValue(UIState_EditorImageSettings.grid);
  const smartLines  = useRecoilValue(UIState_EditorImageSettings.smartLines);
  
  const widgetProps = useRecoilValue(UIState_Content.cellImages_image_widgetProps_hack(widgetAddr));

  // TODO 
  // FIXME
  const widgetStyleTmp = useRecoilValue(UIState_Content.cellImages_image_widgetStyle(widgetAddr)) || {};
  const widgetStyle = widgetStyleTmp as any; 


  const textMarginTop    = jtl.css.valueToNumber(widgetStyle.textMarginTop);
  const textMarginBottom = jtl.css.valueToNumber(widgetStyle.textMarginBottom);
  const textMarginLeft   = jtl.css.valueToNumber(widgetStyle.textMarginLeft);
  const textMarginRight  = jtl.css.valueToNumber(widgetStyle.textMarginRight);
  
  const arrowHeadHeight  = jtl.css.valueToNumber(widgetStyle.arrowHeadHeight);
  const arrowHeadWidth   = jtl.css.valueToNumber(widgetStyle.arrowHeadWidth);
  const arrowHeadIdentX  = jtl.css.valueToNumber(widgetStyle.arrowHeadIdentX);
  const arrowHeadIdentY  = jtl.css.valueToNumber(widgetStyle.arrowHeadIdentY);
  const arrowShaftWidth  = jtl.css.valueToNumber(widgetStyle.arrowShaftWidth);

  
  const handleStyleChanged = (style: any) => {
    if (widgetAddr === null) return;

    document.content.cellImages_image_widget_updateStyle(
      widgetAddr,
      style
    );
    setContent();
  }

  const onGridSizeChange = (size: string) => {
    document.editorImageSettings.setGridSize(+size);
    setEditorImageSettings();
  }

  const onGridSizeChangeDone = (size: string) => {
    onGridSizeChange(size);
    document.saveUndo();
  }
  
  const onGridShowType = (showType: EditorImageSettingsTypes.GridType) => {
    document.editorImageSettings.setGridShowType(showType);
    setEditorImageSettings();
    document.saveUndo();
  }

  const onGridEnable = (enable: boolean) => {
    document.editorImageSettings.setGridEnabled(enable);
    setEditorImageSettings();
    document.saveUndo();
  }

  const onTextMarginTopChanged = (newValue: number) => {
    handleStyleChanged({ textMarginTop: `${newValue}px` });
  }
  
  const onTextMarginTopChangedDone = (newValue: number) => {
    onTextMarginTopChanged(newValue);
    document.saveUndo();    
  }

  const onTextMarginBottomChanged = (newValue: number) => {
    handleStyleChanged({ textMarginBottom: `${newValue}px` });
  }

  const onTextMarginBottomChangedDone = (newValue: number) => {
    onTextMarginBottomChanged(newValue);
    document.saveUndo();    
  }

  const onTextMarginLeftChanged = (newValue: number) => {
    handleStyleChanged({ textMarginLeft: `${newValue}px` });
  }

  const onTextMarginLeftChangedDone = (newValue: number) => {
    onTextMarginLeftChanged(newValue);
    document.saveUndo();    
  }

  const onTextMarginRightChanged = (newValue: number) => {
    handleStyleChanged({ textMarginRight: `${newValue}px` });
  }

  const onTextMarginRightChangedDone = (newValue: number) => {
    onTextMarginRightChanged(newValue);
    document.saveUndo();    
  }


  const onArrowHeadHeightChanged = (newValue: number) => {
    handleStyleChanged({ arrowHeadHeight: `${newValue}px` });

    // const arrowShaftSize  = jtl.css.valueToNumber(widgetStyle.arrowShaftSize);
    // let shaftSizeAdjusted = Math.min(arrowShaftSize, (newValue - 1) / 1.1 );
    // shaftSizeAdjusted = Math.max(shaftSizeAdjusted, 0);
    // if (shaftSizeAdjusted !== arrowShaftSize) {
    //   handleStyleChanged({ arrowShaftSize: `${shaftSizeAdjusted}px` });
    // }
  };

  const onArrowHeadHeightChangedDone = (newValue: number) => {
    onArrowHeadHeightChanged(newValue);
    document.saveUndo();
  };

  const onArrowHeadWidthChanged = (newValue: number) => {
    handleStyleChanged({ arrowHeadWidth: `${newValue}px` });
  };

  const onArrowHeadWidthChangedDone = (newValue: number) => {
    onArrowHeadWidthChanged(newValue);
    document.saveUndo();
  };

  const onArrowHeadIdentXChanged = (newValue: number) => {
    handleStyleChanged({ arrowHeadIdentX: `${newValue}px` });
  };

  const onArrowHeadIdentXChangedDone = (newValue: number) => {
    onArrowHeadIdentXChanged(newValue);
    document.saveUndo();
  };

  const onArrowHeadIdentYChanged = (newValue: number) => {
    handleStyleChanged({ arrowHeadIdentY: `${newValue}px` });
  };

  const onArrowHeadIdentYChangedDone = (newValue: number) => {
    onArrowHeadIdentYChanged(newValue);
    document.saveUndo();
  };


  const onArrowShaftWidthChanged = (newValue: number) => {
    handleStyleChanged({ arrowShaftWidth: `${newValue}px` });
  };

  const onArrowShaftWidthChangedDone = (newValue: number) => {
    onArrowShaftWidthChanged(newValue);
    document.saveUndo();
  };

  const onArrowJointChanged = (jointed: boolean) => {
    if (widgetAddr === null) {
      const msg = 'Widget addr is null';
      throw new Error(msg);
    }

    document.content.cellImages_image_widgetArrowText_update(
      widgetAddr,
      { jointed }
    );

    setContent();
    document.saveUndo();
  }

  const onArrowPointerChanged = (pointer: Arrow.PointerType) => {
    if (widgetAddr === null) {
      const msg = 'Widget addr is null';
      throw new Error(msg);
    }

    document.content.cellImages_image_widgetArrowText_update(
      widgetAddr,
      { pointer }
    );

    setContent();
    document.saveUndo();
  }

  const onArrowPointerEnter = (pointer: Arrow.PointerType) => {
    if (widgetAddr === null) {
      const msg = 'Widget addr is null';
      throw new Error(msg);
    }

    document.content.cellImages_image_widgetArrowText_update(
      widgetAddr,
      { pointer }
    );
    
    setContent();
  }

  const onArrowPointerLeave = (pointer: Arrow.PointerType, prevPointer: Arrow.PointerType) => {
    if (widgetAddr === null) {
      const msg = 'Widget addr is null';
      throw new Error(msg);
    }

    document.content.cellImages_image_widgetArrowText_update(
      widgetAddr,
      { pointer: prevPointer }
    );

    setContent();
  }

  // const onArrowHeadSizeChanged = (newValue: number) => {
  //   handleStyleChanged({ arrowHeadSize: `${newValue}px` });

  //   const arrowShaftSize  = jtl.css.valueToNumber(widgetStyle.arrowShaftSize);
  //   let shaftSizeAdjusted = Math.min(arrowShaftSize, (newValue - 1) / 1.1 );
  //   shaftSizeAdjusted = Math.max(shaftSizeAdjusted, 0);
  //   if (shaftSizeAdjusted !== arrowShaftSize) {
  //     handleStyleChanged({ arrowShaftSize: `${shaftSizeAdjusted}px` });
  //   }
  // };

  // const onArrowHeadSizeChangedDone = (newValue: number) => {
  //   onArrowHeadSizeChanged(newValue);
  //   document.saveUndo();
  // };

  // const onArrowShaftSizeChanged = (newValue: number) => {
  //   handleStyleChanged({ arrowShaftSize: `${newValue}px` });

  //   const arrowHeadSize  = jtl.css.valueToNumber(widgetStyle.arrowHeadSize);
  //   let headSizeAdjusted = Math.max(arrowHeadSize, 2 + newValue * 1.1);
  //   if (headSizeAdjusted !== arrowHeadSize) {
  //     handleStyleChanged({ arrowHeadSize: `${headSizeAdjusted}px` });
  //   }
  // };

  // const onArrowShaftSizeChangedDone = (newValue: number) => {
  //   onArrowShaftSizeChanged(newValue);
  //   document.saveUndo();
  // };

  const onSmartLinesAligmentLinesVisible = (visible: boolean) => {
    document.editorImageSettings.setSmartLinesAligmentLinesVisible(visible);
    setEditorImageSettings();
    document.saveUndo();
  }

  const onSmartLinesSticky = (sticky: boolean) => {
    document.editorImageSettings.setSmartLinesSticky(sticky);
    setEditorImageSettings();
    document.saveUndo();
  }

  const onSmartLinesStickynessChange = (stickyness: number) => {
    document.editorImageSettings.setSmartLinesStickyness(stickyness);
    setEditorImageSettings();
  }

  const onSmartLinesStickynessChangeDone = (stickyness: number) => {
    onSmartLinesStickynessChange(stickyness);
    document.saveUndo();
  }

  const onSmartLinesSrcLinesVisible = (visible: boolean) => {
    document.editorImageSettings.setSmartLinesSrcLinesVisible(visible);
    setEditorImageSettings();

    document.saveUndo();
  }


  const cmds = {
    onArrowHeadHeightChanged,
    onArrowHeadHeightChangedDone,
    onArrowHeadWidthChanged,
    onArrowHeadWidthChangedDone,
    onArrowHeadIdentXChanged,
    onArrowHeadIdentXChangedDone,
    onArrowHeadIdentYChanged,
    onArrowHeadIdentYChangedDone,
    onArrowShaftWidthChanged,
    onArrowShaftWidthChangedDone,
    onArrowJointChanged,
    onArrowPointerChanged,
    onArrowPointerEnter,
    onArrowPointerLeave,
    
    onTextMarginTopChanged,
    onTextMarginTopChangedDone,
    onTextMarginBottomChanged,
    onTextMarginBottomChangedDone,
    onTextMarginLeftChanged,
    onTextMarginLeftChangedDone,
    onTextMarginRightChanged,
    onTextMarginRightChangedDone,

    onGridSizeChange,
    onGridSizeChangeDone,
    onGridShowType,
    onGridEnable,

    onSmartLinesAligmentLinesVisible,
    onSmartLinesSrcLinesVisible,
    onSmartLinesSticky,
    onSmartLinesStickynessChange,
    onSmartLinesStickynessChangeDone,
  }


  const states = {
    
    arrowHeadHeight,
    arrowHeadWidth,
    arrowHeadIdentX,
    arrowHeadIdentY,
    arrowShaftWidth,

    widgetProps,
    widgetAddr,
    widgetStyle,

    grid,
    smartLines,

    textMarginTop,
    textMarginBottom,
    textMarginLeft,
    textMarginRight,
  };

  const Configs = {
    [SideToolbar.PanelType.COLORS     ]: getConfigColors, // TODO
    [SideToolbar.PanelType.ARROW      ]: getConfigArrow,
    [SideToolbar.PanelType.TEXT_BOX   ]: getConfigTextBox,
    [SideToolbar.PanelType.STYLES     ]: getConfigStyles, // TODO
    [SideToolbar.PanelType.VIEW       ]: getConfigView,  // TODO
    [SideToolbar.PanelType.LAYERS     ]: getConfigWidgetsStack,

    [SideToolbar.PanelType.GRID       ]: getConfigGrid,
    [SideToolbar.PanelType.SMART_LINES]: getConfigSmartLines,
  }

  const getConfig = Configs[panelType];
  const config = (

    panelType !== SideToolbar.PanelType.STYLES ?
    getConfig({cmds, states}) :
    getConfigWidgetStyles()
  );

  const getPanelType = (width: number): SideControlsTypes.PanelType => {
    const sizeVariant = ScreenSizeTools.getSizeVariant(width);
    const screenToPanelMap: SideControlsTypes.ScreenToPanelMap = {
      [ScreenSizeTypes.SizeVariant.WIDTH_320]: PanelType.NARROW_190,
      [ScreenSizeTypes.SizeVariant.WIDTH_360]: PanelType.NARROW_190,
      [ScreenSizeTypes.SizeVariant.WIDTH_390]: PanelType.NARROW_190,
      [ScreenSizeTypes.SizeVariant.WIDTH_500]: PanelType.NARROW_230,
      [ScreenSizeTypes.SizeVariant.WIDTH_600]: PanelType.WIDE_320,
    }

    const panelType = screenToPanelMap[sizeVariant];
    return panelType;
  }

  const panelSizeType = getPanelType(windowWidth);


  return (
    <SidePanelBaseComponent 
      config={config}
      visible={panelVisible}
      panelType={panelSizeType}
      left={left}
      right={right}
    />
  );
}
