import { useRef } from 'react';
import { useEffect } from 'react';

import { ReposTypes }  from 'app/arch/app/states/repos';
import { useMutation } from 'app/arch/backend/use-mutation';
import { mutation as mutationDefs } from 'app/arch/backend';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useFeatureLimitedInfo from './use-feature-limited-info';
import useToastAdd from '../__modules/info/components/toasts/hooks/use-toast-add';


type OnDone = () => void;
type OnError   = (error: string) => void;
type OnLimit = (limit: string) => void;


export interface HookProps {
  repoId: number;
  memberUsername: string;
  access: ReposTypes.MemberAccess;

  onDone?: OnDone;
  onError?: OnError;
  onLimit?: OnLimit;
}


const useRepoMemberAccessUpdate = () => {
  const t = useTranslations();
  const addToast = useToastAdd();
  const showFeatureLimited = useFeatureLimitedInfo();

  const onDoneRef  = useRef<OnDone | null>(null);
  const onErrorRef = useRef<OnError |  null>(null);
  const onLimitRef = useRef<OnLimit | null>(null);


  /**
   * Mutation
   */

  const { 
    mutation,
    data: response,
    error,
  } = useMutation(mutationDefs.updateRepoMemberAccess);


  /**
   * Mutation data
   */

  useEffect(() => {
    if ( ! response ) {
      return;
    }

    const data = response.repoMemberAccessUpdate;

    if ( data.featureLimited ) {
      showFeatureLimited(data.limitType);
      onLimitRef.current?.(data.limitType);
      resetRefs();
      
      return;
    }

    if ( ! data.ok ) {
      console.warn("Can't save repos member access update");
      onErrorRef.current?.('Invalid data');
      resetRefs();
      return;
    }

    addToast({ text: t("repository member, updated") });
    onDoneRef.current?.();
    resetRefs();
  }, [response]);



  /**
   * Mutation error
   */

  useEffect(() => {
    if ( ! error ) {
      return;
    }

    onErrorRef.current?.(error.message);
    resetRefs();
  }, [error]);


  const resetRefs = () => {
    onDoneRef.current = null;
    onErrorRef.current = null;
    onLimitRef.current = null;
  }

  const hook = (props: HookProps) => {
    const {
      repoId,
      memberUsername,
      access,

      onDone,
      onError,
      onLimit,
    } = props;
  
    onDoneRef.current  = onDone  || null;
    onErrorRef.current = onError || null;
    onLimitRef.current = onLimit || null;

    addToast({ text: t("repository member, updating") });

    mutation({
      variables: { 
        repoId,
        memberUsername,
        access,
      }
    });
  }

  return hook;
}

export default useRepoMemberAccessUpdate;