import React from 'react';
import useMarkerEdit from 'app/ui-v2/editor-markers/hooks/use-marker-edit';
import { MainWrapper } from './styles';
import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { useDocState } from 'app/ui/contexts/document';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerEditComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;
 
  const document = useDocState();
  const markerProps = document.headerMarkers.getMarkerProps(markerAddr);

  const editMarker = useMarkerEdit();

  const handleEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    editMarker(markerProps.repoMarkerAddr);
  }
  
  return (
    <MainWrapper onDoubleClick={handleEdit}>
      { children }
    </MainWrapper>
  );
}
  
