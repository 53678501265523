import React from 'react';
import { ContentTypes }  from 'app/arch/editor-instruction/document/states/persistent/content';
import useWidgetContextMenu from 'app/ui-v2/editor-image/hooks/widget/use-widget-context-menu';
import { MainWrapper } from './styles';


interface Props {
  editDisabled: boolean;
  widgetAddr: ContentTypes.WidgetAddr;
  children: React.ReactNode;
}


export const WidgetContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
    editDisabled,
    children,
  } = props;

  const {
    showContextMenu,
    hideContextMenu,
  } = useWidgetContextMenu(widgetAddr);

  const handleContextMenu = (event: React.MouseEvent) => {
    showContextMenu({
      event,
      widgetAddr,
      editDisabled
    });
  }

  if (editDisabled) {
    return children;
  }
  
  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
  
