import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderFieldsTools as Tools } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { UIState_HeaderFields } from 'app/ui/states/editor-instruction';
import usePageContext from 'app/ui-v2/editor-instruction/views/view-content/hooks/page/use-page-context';

import CellComponent from './cell';


interface Props {
}


export const CellsRowComponent: React.FC<Props> = (props: Props) => {
  const columnsAddrs = useRecoilValue(UIState_HeaderFields.columnsAddrs);
  const pageContext = usePageContext();
  const pageIdx = pageContext.pageIdx;

  if (pageIdx === null) {
    const msg = `Page Idx is null`;
    throw new Error(msg);
  }

  const renderCells = () => {
    const Cells = columnsAddrs.map((columnAddr) => {
      const pageCellAddr: Types.PageCellAddr = {
        pageIdx,
        columnId: columnAddr.columnId
      }

      const key = Tools.getColumnKey(columnAddr);

      return (
        <CellComponent
          key={key}
          pageCellAddr={pageCellAddr}
        />
      );
    });

    return Cells;
  }


  return renderCells();
}
  
