import React from 'react';

import useSelectUserImages from 'lego/components/user-files-selector/use-select-user-images';

import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import Icon from 'app/ui/icons/icons';

import ButtonUploadComponent     from '../hls/button-upload';
import IconStyleContextComponent from '../hls/icon-style-context';
import useRepoImagesUpload       from '../hooks/use-repo-images-upload';

import Buttons from '../styles/buttons';


interface Props {
  onImagesUploaded: (images: RepoImagesTypes.ImagesRaw) => void;
}


export const UploadPanelDesktopComponent: React.FC<Props> = (props: Props) => {
  const {
    onImagesUploaded
  } = props;
  
  const t = useTranslations();
  const uploadRepoImages = useRepoImagesUpload();


  const handleFilesSelected = (files: File[]) => {
    uploadRepoImages({
      files,
      onDone: (images: RepoImagesTypes.ImagesRaw) => {
        onImagesUploaded(images);
      },
      onError: () => {

      }
    });
  }
  
  const selectImages = useSelectUserImages({
    onFilesSelected: handleFilesSelected
  });

  const handleGalleryFilesUpload = (event: React.MouseEvent) => {
    selectImages();
  }

  return (
    <IconStyleContextComponent>
      <Buttons>
        <ButtonUploadComponent
          Icon={Icon.Upload.Disk}
          tooltip={t('upload')}
          onClick={handleGalleryFilesUpload}
        />
      </Buttons>
    </IconStyleContextComponent>
  );
}
