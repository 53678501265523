// TODO
// Remove it, has all been migrated to 
// ToastsTypes

export enum MsgLevel {
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  SUCCESS = 'success',
};

export const MsgLevelColor: any = {
  [MsgLevel.SUCCESS]: 'rgba(0, 128, 0, 1)',
  [MsgLevel.ERROR]: 'rgba(252, 33, 33, 1)',
  [MsgLevel.WARN]: 'rgba(255, 140, 0, 1)',
  [MsgLevel.INFO]: '#007bff',
}

export enum MsgDuration {
  XSHORT = 1000,
  SHORT  = 2000,
  MIDDLE = 3000,
  NORMAL = 4000,
  LONG   = 6000,
  XLONG  = 8000,

  ERROR  = 6000,
};
