import styled from "styled-components";


export const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const MarkerEditWrapper = styled.div`
  flex: 1 1 0;
  min-width: 0px;
`;

export const SideToolbarWrapper = styled.div`
  flex: 0 0 auto;
`;
