import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";


const useWidgetStyleCopy = () => {
  const docState = useDocState();

  const {
    setEditorImageSession
  } = useEditorStatesSetters();

  
  const copyStyle = (widgetAddr: ContentTypes.WidgetAddr) => {
    docState.cellImages_image_copyWidgetStyle(widgetAddr);
    setEditorImageSession();
  }

  return copyStyle;
}


export default useWidgetStyleCopy;