import * as Tools from './tools';
import * as Types from './types';
import * as Defaults from './defaults';


/**
 * State
 */

export type State = {
  content: Types.Content,
};



/**
 * Content
 */

export const getContent = (state: State) => state.content;

/**
 * Rows
 */
export const __getRows = (state: State): Types.Rows => {
  const content = getContent(state);
  return content.rows;
}

export const getRowsAddrs = (state: State): Types.RowsAddrs => { 
  const rows = __getRows(state);
  return rows.addrs;
}

export const getRowsProps = (state: State): Types.RowsProps => { 
  const rows = __getRows(state);
  return rows.props;
}

export const getColumnsProps = (
  state: State,
): Types.ColumnsProps => {
  return state.content.columns.props;
}

export const getColumnProps = (
  state: State,
  idx: Types.ColumnIdx,
): Types.ColumnProps => {
  const columnsProps = getColumnsProps(state);
  return columnsProps[idx];
}


/**
 * Row
 */
export const getRowProps = (
  state: State, 
  rowAddr: Types.RowAddr
): Types.RowProps => { 
  const rowsProps = getRowsProps(state);
  const rowKey    = Tools.getRowKey(rowAddr);
  const rowProps  = rowsProps[rowKey];

  if (rowsProps === undefined) {
    const msg = `Row props not found`;
    throw new Error(msg);
  }

  return rowProps;
}

export const getRowWidth = (
  state: State, 
  rowAddr: Types.RowAddr
): number => { 
  const rowProps = getRowProps(state, rowAddr);
  return rowProps.width;
}

export const getRowType = (
  state: State, 
  rowAddr: Types.RowAddr,
): Types.RowType => { 
  const rowProps = getRowProps(state, rowAddr);
  return rowProps.type;
}

export const getRowIdx = (
  state: State, 
  rowAddr: Types.RowAddr
): number => {
  const rowsAddrs = getRowsAddrs(state);
  
  const idx = rowsAddrs.findIndex((rowAddr_) => Tools.compareRowAddr(rowAddr_, rowAddr));
  if ( idx === -1 ) {
    const msg = 'Row not found';
    throw new Error(msg);
  }

  return idx;
}


/**
 * Cells
 */
export const getCells = (
  state: State, 
): Types.Cells => {
  const content = getContent(state);
  return content.cells;
}

export const getCell = (
  state: State, 
  cellAddr: Types.CellAddr
): Types.CellTypes => {
  const cells   = getCells(state);
  const cellKey = Tools.getCellKey(cellAddr);
  
  const cell = cells[cellKey];
  if (cell === undefined) {
    const msg = (
      `Cell not found, cell addr: \n`+
      `  columnId: ${cellAddr.rowId}`
    );
    throw new Error(msg);
  }

  return cell;
}

/**
 * Initial State
 */

export const createInitialState = (): State => {
  const initState: State = {
    content: Defaults.getContent(),
  }

  
  return initState;
}
