import { useEffect } from 'react';
import { useRef }    from 'react';

import { mutation  }         from 'app/arch/backend';
import { useMutation } from 'app/arch/backend/use-mutation';
import { useApp } from 'app/ui/hooks/app/use-app';
import { useSetRecoilState } from 'recoil';
import { UIState_Repos } from 'app/ui/states/app/repos-state';


export interface HookProps {
  repoId: number;
  name: string;
  onDone?: () => void;
  onError?: () => void;
}

export const useRepoNameUpdate = () => {
  const app = useApp();

  const hookPropsRef  = useRef<HookProps | null>(null);
  const setReposState = useSetRecoilState(UIState_Repos.state);

  // Mutation
  //
  const { 
    mutation: updateRepoNameMutation,
    data: updateRepoNameData,
    error: updateRepoNameError
  } = useMutation(mutation.updateRepoName
  );

  useEffect(() => {
    if (! updateRepoNameData) {
      return;
    }

    const data = updateRepoNameData.repoNameUpdate;
    if (! data.ok) {
      console.warn("Can't update repo name");
      return;
    }

    const props = hookPropsRef.current!;

    app.repos.setRepoName(props.repoId, props.name);
    setReposState(app.repos.raw);

    props.onDone?.();
    
    hookPropsRef.current = null;
  }, [updateRepoNameData]);

  useEffect(() => {
    if ( ! updateRepoNameError ) {
      return;
    }
    const props = hookPropsRef.current!;
    props.onError?.();
    hookPropsRef.current = null;
  }, [updateRepoNameError])
  const hook = (props: HookProps) => {
    const {
      repoId,
      name,
    } = props;
  
    hookPropsRef.current = props;

    updateRepoNameMutation({
      variables: { 
        repoId,
        name,
      }
    });
}

  return hook;
}
