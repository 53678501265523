import React from 'react';

import MarkerViewSignSquareComponent from '../parts/marker-view-sign-square';
import { MarkerViewBaseProps } from '../types';
import { Sign } from './styles';


interface Props extends MarkerViewBaseProps {
}


export const MarkerViewSignInfoComponent: React.FC<Props> = (props: Props) => {
  const { 
    Icon
  } = props;

  return (
    <MarkerViewSignSquareComponent
      Sign={Sign}
      Icon={Icon}
    />
  );
}
