export namespace SideToolbar {

export enum PanelType {
  ARROW       = 'arrow',
  COLORS      = 'colors',
  TEXT_BOX    = 'text-box',
  STYLES      = 'styles',
  VIEW        = 'view',
  LAYERS      = 'layers',

  GRID        = 'gird',
  SMART_LINES = 'smart-lines',
};


}
