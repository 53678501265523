const getNegativeHSLA = (h: number, s: number, l: number, a: number) => {
  const negativeH = (h + 180) % 360;
  const negativeL = 100 - l;

  return `hsla(${negativeH}, ${s}%, ${negativeL}%, ${a})`;
}


export const getColorSet = (h: number, s: number, l: number) => {

  const opacity1  = getColorFamily(h, s, l, 1);
  const opacity01 = getColorFamily(h, s, l, 0.1);
  const opacity02 = getColorFamily(h, s, l, 0.2);
  const opacity03 = getColorFamily(h, s, l, 0.3);
  const opacity04 = getColorFamily(h, s, l, 0.4);
  const opacity05 = getColorFamily(h, s, l, 0.5);
  const opacity06 = getColorFamily(h, s, l, 0.6);
  const opacity07 = getColorFamily(h, s, l, 0.7);
  const opacity08 = getColorFamily(h, s, l, 0.8);
  const opacity09 = getColorFamily(h, s, l, 0.9);

  const data = {
    ...opacity1,
    opacity01,
    opacity02,
    opacity03,
    opacity04,
    opacity05,
    opacity06,
    opacity07,
    opacity08,
    opacity09,
  }

  return data;
}

const getColorFamily = (h: number, s: number, l: number, o?: number) => {
  o = (o === undefined ? 1 : o);
  const primary  = `hsla(${h}, ${s}%, ${l}%,      ${o})`;
  const light    = `hsla(${h}, ${s}%, ${l +  5}%, ${o})`;
  const lighter  = `hsla(${h}, ${s}%, ${l + 10}%, ${o})`;
  const lightest = `hsla(${h}, ${s}%, ${l + 20}%, ${o})`;
  const dark     = `hsla(${h}, ${s}%, ${l -  5}%, ${o})`;
  const darker   = `hsla(${h}, ${s}%, ${l - 10}%, ${o})`;
  const darkest  = `hsla(${h}, ${s}%, ${l - 20}%, ${o})`;
  const contrast = 'white';

  const gradient = {
    primary: `
      linear-gradient(to bottom, ${primary}, ${dark})
    `,
    secondary: `
      linear-gradient(
        40deg, 
        ${primary} 0%, 
        transparent 50%, 
        transparent 100%
      )
    `
  }

  const negative = {
    primary: getNegativeHSLA(h, s, l, o)
  }

  return {
    primary,
    light,
    lighter,
    lightest,
    dark,
    darker,
    darkest,
    gradient,
    negative,
    contrast,
  }
}
