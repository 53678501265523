import { atom } from 'recoil';
import { selector } from 'recoil';
import { ToastsState as State } from 'app/arch/app/info/toasts';


export const state = atom<State.State>({
  key: "toasts",
  default: State.createInitialState()
});

export const addrs = selector({
  key: `toasts_addrs`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getToastsAddrs(stateObj);
  }
});
