import React from 'react';
import { UUID } from 'app/arch/types';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';
import { useDocState } from 'app/ui/contexts/document';
import useItemsTools from '../../../../hooks/use-items-tools';
import Context from './context';


interface Props {
  sectionId: UUID;
  cellsRow: SlicerTypes.SectionItem_CellsRow;
  children: React.ReactNode;
}


export const CellsRowContextComponent: React.FC<Props> = (props: Props) => {
  const {
    sectionId,
    cellsRow,
    children,
  } = props;

  const document = useDocState();
  
  const { 
    cellsRow_isLastPageItem
  } = useItemsTools();

  const rowAddr = {
    sectionId,
    rowId: cellsRow.rowId
  }

  const rowAdder = document.content.isRowAdder(rowAddr);
  const pageLastItem = cellsRow_isLastPageItem(cellsRow);

  const context = { 
    rowAdder,
    pageLastItem
  };

  return (
    <Context.Provider value={context}>
      { children }
    </Context.Provider>
  );
}

