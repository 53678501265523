import styled from "styled-components";
import TextBase         from 'lego/styles/text';
import ButtonBase       from "lego/styles/button";
import ButtonTextDelete from "lego/styles/button_text_delete";
import { Box } from "app/ui-v2/hls/progress-box/styles";

import { FaCheck }  from "react-icons/fa";
import { GiCancel } from "react-icons/gi";



export const MainWrapper = styled.div`
`;

export const Text = styled(TextBase)`
  overflow: unset;
  white-space: unset;
  text-overflow: unset;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.defs.gap.normal};
`;

export const Button = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.defs.gap.xsmall};

  min-width: 100px;
`;

export const ButtonDelete = styled(ButtonTextDelete)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.defs.gap.xsmall};

  min-width: 100px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};
`;


export const IconAccept = styled(FaCheck)`
`;

export const IconReject = styled(GiCancel)`
`;

export const ProgressBox = styled(Box)`
  width: 100%;
  height: 68px;
`;