import styled from "styled-components";
import ButtonBase from "lego/styles/button";
import PanelForth from "lego/styles/panel_forth";
import PanelThird from "lego/styles/panel_third";
import SeparatorBase from 'lego/styles/separator';


export const Panel = styled(PanelForth)`
  display: flex;
  flex-direction: column;
  width: 180px;
  gap: ${props => props.theme.defs.gap.xlarge};
  /* gap: 24px; */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.large};
`;

export const TypesList = styled(PanelThird)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xsmall};
`;

export const Button = styled(ButtonBase)`
  max-width: unset;
`;

export const Separator = styled(SeparatorBase)`
  margin: 0px;
  width: 100%;
`;