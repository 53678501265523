import * as Defaults from './defaults';
import * as Types from './types';


/**
 * State
 */

export type State = {
  window: Types.Window,
  editor: Types.Editor,
};


/**
 * Getters
 */

export const getWindow = (state: State) => state.window;
export const getEditor = (state: State) => state.editor;
export const getSelectedMarkerAddr = (state: State) => state.editor.selectedMarkerAddr;


/**
 * Initial state
 */

export const createInitialState = () => {
  const initState: State = {
    window: Defaults.getWindow(),
    editor: Defaults.getEditor(),
  }

  return initState;
}
