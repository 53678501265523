import React from 'react';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import MenuWidgetsComponent    from './menu-widgets';
import MenuPropertiesComponent from './menu-properties';

import { EditorRow }          from './styles';
import { WidgetsMenuWrapper } from './styles';
import { ContentWrapper }     from './styles';
import { MenuPropertiesWrapper } from './styles';


interface Props {
  imageAddr: Types.ImageAddr;
  children: React.ReactNode;
};


export const EditorImageContentComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children
  } = props;

  return (
    <EditorRow>

      <WidgetsMenuWrapper>
        <MenuWidgetsComponent imageAddr={imageAddr} />
      </WidgetsMenuWrapper>
      
      <ContentWrapper>
        { children }
      </ContentWrapper>
      
      <MenuPropertiesWrapper>
        <MenuPropertiesComponent />
      </MenuPropertiesWrapper>

    </EditorRow>
  );
}
  
