import ExcelJS from 'exceljs';
import React from 'react';
import jtl from 'tools/jtl';


class TextConverterCss {

  convert(
    xlsCell: ExcelJS.Cell,
    css: React.CSSProperties,
    text: string
  ) 
  {
    let bgColor = "FF000000";
    let color = "FFFFFFFF";
    let fontSize = 20;
    let bold = true;

    if (css.color ) {
      color = jtl.color.hex2argb(css.color as string);
    }

    if (css.background ) {
      bgColor = jtl.color.hex2argb(css.background as string);
    }

    if (css.fontSize ) {
      fontSize = jtl.css.valueToNumber(css.fontSize as string);
    }

    if (css.fontWeight !== undefined) {
      const fontWeight = +css.fontWeight;
      bold = fontWeight > 400;
    }


    xlsCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: bgColor }
    };

    const excelJsFormat = [
      {
        font: {
          size: fontSize,
          bold,
          color: {
            argb: color
          }
        },
        text
      }
    ];


    xlsCell.alignment = { wrapText: false, vertical: 'top' };
    xlsCell.value     = { 'richText' : excelJsFormat };
  }
}


export default TextConverterCss;
