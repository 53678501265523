import React from 'react';
import SideControlInflowComponent from '../side-control-inflow';
import SideControlDetachtedComponent from '../side-control-detached';

import * as Types from '../types';


interface BaseProps {
  flow: Types.PanelFlow;

  Toolbar: React.FC<Types.PropLeftOrRight>;
  Panel: React.FC<Types.PropLeftOrRight>;
}


type Props = BaseProps & Types.PropLeftOrRight;


export const SideControlComponent: React.FC<Props> = (props: Props) => {
  const {
    left, 
    right,

    flow,
    Toolbar,
    Panel,
  } = props;


  const SideControlMap: {[panelPosition in Types.PanelFlow]: any} = {
    [Types.PanelFlow.IN_FLOW ]: SideControlInflowComponent,
    [Types.PanelFlow.DETACHED]: SideControlDetachtedComponent,
  }

  const SideControl = SideControlMap[flow];

  return (
    <SideControl
    
      left={left}
      right={right}

      Toolbar={ 
        <Toolbar 
          left={left}
          right={right}
        /> 
      }
  
      Panel={ 
        <Panel 
          left={left}
          right={right} 
        /> 
      }

    />
  );
}

