import { ThemeDefProps } from "../types";


export const themeDefs_window = (props: ThemeDefProps) => {
  const { colors, font, border, padding, boxShadow } = props;

  const window = {
    title: {
      color: colors.color.primary,
      background: colors.background.eight,
      font: {
        size: font.size.large,
        weight: font.weight.small,
      }
    },

    border: {
      inner: {
        color: colors.border.primary,
        width: border.width.normal,
        radius: border.radius.xsmall
      },

      middle: {
        color: colors.background.secondary,
        width: border.width.small
      },

      outer: {
        color: colors.background.primary,
        width: border.width.normal,
        radius: border.radius.xsmall
      }
    },
    
    boxShadow: boxShadow.primary,
  };
    
  return window;
}

