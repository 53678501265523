import { atom, selector } from 'recoil';
import { UserPanelState as State } from 'app/arch/home-page-user/states/user-panel';


export const state = atom<State.State>({
  key: "homePage_userPanel",
  default: State.createInitialState()
});

export const panel = selector({
  key: `homePage_userPanel_panel`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getPanel(stateObj);
  }
});
