import React from 'react';

import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { useDocState }        from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset       from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';

import { MainWrapper } from './styles';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;
 
  const document = useDocState();
  const resetAllSelected = useSelectedReset();
  
  const {
    setHeaderMarkersSession
  } = useEditorStatesSetters();

  const handleSelectMarker = (event: React.PointerEvent) => {
    event.stopPropagation();
    
    resetAllSelected();

    // TODO change to addr
    document.headerMarkersSession.setMarkerSelected(markerAddr.markerId);
    setHeaderMarkersSession();
  }

  return (
    <MainWrapper onPointerDown={handleSelectMarker} >
      { children }
    </MainWrapper>
  );
}
  
