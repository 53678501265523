import React from 'react';
import FlexEditNameComponent from '../flex-edit-name';
import FlexEditLogoRawComponent from '../flex-edit-logo-raw';
import { Content } from './styles';


interface Props {
  logoSize?: number;
  textSize?: number;
}


export const FlexEditLogoComponent: React.FC<Props> = (props: Props) => {
  const {
    logoSize,
    textSize,
  } = props;


  return (
    <Content>
      <FlexEditLogoRawComponent logoSize={logoSize} />
      <FlexEditNameComponent textSize={textSize} />
    </Content>
  );
}

