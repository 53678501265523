import React from 'react';
import { DeskArea } from './styles';


interface Props {
  children: React.ReactNode;
}


export const DeskAreaComponent = React.forwardRef((
  props: Props, 
  ref: React.Ref<HTMLDivElement>
) => {
  
  const {
    children
  } = props;


  return (
    <DeskArea ref={ref}>
      { children }
    </DeskArea>
  );
});
