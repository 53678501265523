import React from 'react';
import { IconContext } from 'react-icons';

import { settings } from 'app/configs';
import { SectionItem }  from '../../types';
import useNavbarContext     from '../../hooks/use-navbar-context';
import useNavbarItemContext from '../navbar-item-context/use-context';

import { Item }        from './styles';
import { IconWrapper } from './styles';
import { Text }        from './styles';
import { TextWrapper } from './styles';


interface Props {
  config: SectionItem;
}


export const NavbarPanelSectionItemComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
  } = props;

  const Icon = config.icon || React.Fragment;

  const {
    closeItem,
  } = useNavbarContext();

  const {
    cancelBlur
  } = useNavbarItemContext();

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    config.onClick?.(event);

    setTimeout(() => {
      // This timeout is here, just to 
      // give visual feedback to user
      // - by setting css item clicked active
      // selector => chaning it color background.
      // So user know what he clicked - before 
      // closing panel.
      closeItem();
    }, settings.touch.visualFeedbackDelay);
  }

  const handleTouchStart = (event: React.TouchEvent) => {
    cancelBlur();
  }

  return (
    <Item 
      data-test={config.dataTest}
      onClick={handleClick}
      onTouchStart={handleTouchStart}
    >

      <IconWrapper>
        <IconContext.Provider value={{ size: '15px' }} >
          <Icon />
        </IconContext.Provider>    
      </IconWrapper>

      <TextWrapper>
        <Text>
          { config.text }
        </Text> 
      </TextWrapper>

    </Item>
  );
}
