import styled from "styled-components";
import Button from "./button";


const ButtonPanel = styled(Button)`
  display: flex;
  align-items: center;  
  justify-content: center;
`;


export default ButtonPanel;