import React from 'react';
import { IconContext } from 'react-icons';
import TooltipComponent from 'lego/components/tooltip';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { Icon as IconLib } from 'app/ui/icons/icons';

import { Button } from './styles';


interface Props {
  onClick?: (event?: React.MouseEvent) => void;
  expanded?: boolean;
}


export const MoreButtonComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  const {
    onClick,
    expanded
  } = props;

  const tooltip = (
    expanded ? 
    t("less") :
    t("more")
  );

  const Icon = (
    expanded ? 
    IconLib.Expand.Circle.Less :
    IconLib.Expand.Circle.More
  );

  return (
    <TooltipComponent title={tooltip}>
      <Button>
        <IconContext.Provider 
          value={{ 
            style: { 
              transform: 'scale(3)',
              color: `inherit`,
              background: `inherit`
            }
          }}
        >
          <Icon onClick={onClick} />
        </IconContext.Provider>    
      </Button>
    </TooltipComponent>
  );
}
  
