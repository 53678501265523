import styled from "styled-components";
import ButtonGroupLeftBase   from 'lego/styles/button_group_left';
import ButtonGroupMiddleBase from 'lego/styles/button_group_middle';
import ButtonGroupRightBase  from 'lego/styles/button_group_right';
import TextWrap              from "lego/styles/text-wrap";


export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;


export const ButtonGroupLeft = styled(ButtonGroupLeftBase)`
  max-width: unset;
`;


export const ButtonGroupMiddle = styled(ButtonGroupMiddleBase)`
  max-width: unset;
`;


export const ButtonGroupRight = styled(ButtonGroupRightBase)`
  max-width: unset;
`;


export const ButtonContent = styled(TextWrap)`
  max-width: 100%;
`;
