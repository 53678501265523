import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import useUserIsLogged from 'app/ui-v2/app/hooks/use-user-is-logged';
import { LoadersTools } from '../..';
import { LoaderCore } from './types';


interface Props {
  LoaderCoreAuthComponent: LoaderCore;
  LoaderCoreUnauthComponent: LoaderCore;
  debugTitle: string;
  children?: React.ReactNode;
}

export const LoaderAuthBaseComponent: React.FC<Props> = (props: Props) => {
  const {
    LoaderCoreAuthComponent,
    LoaderCoreUnauthComponent,
    debugTitle,
    children
  } = props;
  
  const logger = LoadersTools.getLogger();

  const renderCountRef = useRef(0);
  const isUserLogged = useUserIsLogged();

  useEffect(() => {
    renderCountRef.current += 1;
    const count = renderCountRef.current;
    logger.log(`Loader [${debugTitle}], auth: ${isUserLogged}, render count: ${count}`);
  });

  if ( ! isUserLogged ) {
    return (
      <LoaderCoreUnauthComponent>
      { children }
      </LoaderCoreUnauthComponent>
    )
  }

  return (
    <LoaderCoreAuthComponent>
      { children }
    </LoaderCoreAuthComponent>
  );
}
