import React from 'react';
import { useRecoilValue } from 'recoil';

import { Size }   from 'app/arch/types';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import { UIState_Content } from 'app/ui/states/editor-instruction';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import { useTranslations }     from 'app/ui/hooks/app/use-translation';

import { Text  } from './styles';


interface Props {
};


export const WidgetInfoComponent: React.FC<Props> = (props: Props) => {
  const widgetAddr = useRecoilValue(UIState_EditorImageSession.widgetSelected);
  
  return (
    <>
    { 
      widgetAddr !== null &&
      <Component widgetAddr={widgetAddr} />
    }
    </>
  );

}

interface ComponentProps {
  widgetAddr: Types.WidgetAddr;
};

const Component: React.FC<ComponentProps> = (props: ComponentProps) => {
  const {
    widgetAddr 
  } = props;
  
  const t = useTranslations();

  const widgetProps = useRecoilValue(UIState_Content.cellImages_image_widgetProps(widgetAddr));
  const widgetResizingSize = useRecoilValue(UIState_EditorImageSession.widgetResizingSize);

  if ( widgetProps.type === ContentTypes.WidgetType.ARROW_PLAIN) {
    return null;
  }

  const formatSizeText = (size: Size) => {
    const sizeLabel = t("size");
    const width  = size[0].toFixed(1);
    const height = size[1].toFixed(1);
    const sizeTxt = `${sizeLabel}: ${width} x ${height}`;
    
    return sizeTxt;
  }
  const getText = () => {
    if (ContentTools.isWidgetArrowText(widgetProps.type)) {
      const widgetArrowTextProps = widgetProps as ContentTypes.WidgetArrowTextProps;
      const size = widgetArrowTextProps.tailSize;
      const text = formatSizeText(size);
      return text;
    } 

    const widgetBoxedProps = widgetProps as ContentTypes.WidgetBoxedProps;
    const size: Size = (
      widgetResizingSize !== null ?
      widgetResizingSize : 
      widgetBoxedProps.size
    );

    const text = formatSizeText(size);;
    return text;
  }

  return ( 
    <Text>
      { getText() }
    </Text>
  );
};
