import styled from "styled-components";
import CellSelectedBorder from "app/ui-v2/editor-instruction/views/shared/styles/cell-selected-border";


export const MainWrapper = styled.div`
  position: relative;
  height: 100%;
`;


export const Border = styled(CellSelectedBorder)`
  z-index: 1;
`;
