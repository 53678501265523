import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { mutation as mutationDef } from 'app/arch/backend';
import { useMutation } from 'app/arch/backend/use-mutation';
import { SubscriptionsTypes } from 'app/arch/app/user/states/subscriptions';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

type OnDoneFn  = (subscriptions: SubscriptionsTypes.SubscriptionsRaw) => void;
type OnErrorFn = (error: string) => void;


export interface Props {
  onDone?: OnDoneFn;
  onError?: OnErrorFn;
}

const INIT_DELAY = 3000;


const useUserSubscriptionsFetchAndCopy = (props: Props) => {
  const {
    onDone,
    onError,
  } = props;

  const t = useTranslations();

  const { 
    mutation,
    data,
    error
  } = useMutation(mutationDef.copySubscriptions,{
    onError: { msg: t("graphql error, user subscriptions fetch") }
  });


  useEffect(() => {
      mutationDeb();
    
    return () => {
      mutationDeb.cancel();
    }
  }, []);

  // It is here so when component
  // remounts twice in Strict mode
  // this would get executed twice.
  // Also serves as a dealy to take load of update of
  // server when app loads.
  const mutationDeb = useDebouncedCallback(() => {
    mutation();
  }, INIT_DELAY);

  useEffect(() => {
    if ( ! data ) {
      return;
    }

    const response = data.subscriptionsCopy;

    if ( ! response.ok) {
      onError?.("Mutation subscriptionsFetchAndCopy executed with errors");
      return;
    }

    onDone?.(response.subscriptions);
  }, [data]);


  useEffect(() => {
    if ( ! error) {
      return;
    }

    console.warn(error);
    onError?.(error.message)
  }, [error]);

  return mutation;
}
  

export default useUserSubscriptionsFetchAndCopy;
