import * as Company from 'app/configs/company';

import { A } from './styles';
import { Address } from './styles';
import { CompanyInfo } from './styles';
import { CompanyName } from './styles'; 
import { Li } from './styles'; 
import { Ul } from './styles'; 

import { LegalTextTypes as Types } from '../view-legal-base';



/**
 * Title
 */

const title: Types.Title = (
  <>
    Terms of Service
  </>
);



/**
 * Last Update
 */

const lastUpdate: Types.LastUpdate = (
  <>
   June 13, 2024
  </>
);



/**
 * Sections
 */

const sections: Types.Sections = [
  {
    paragraphs: [
      <>
        Welcome to {`${Company.name}`} ("Company", "we", "our", "us"). 
        These Terms of Service ("Terms") govern your use of our 
        software-as-a-service (SaaS) platform for creating work instructions 
        ("Service"). By accessing or using the Service, you agree to be bound 
        by these Terms. If you do not agree to these Terms, do not use the Service.
      </>,
    ]
  },
  
  {
    title: <>Acceptance of Terms</>,
    paragraphs: [
      <>
        By accessing and using the Service, you accept and agree to be bound by 
        these Terms and any modifications we may make from time to time. It is
        your responsibility to review these Terms periodically for changes. Your 
        continued use of the Service after any such changes constitutes 
        your acceptance of the new Terms.
      </>,
    ]
  },
  {
    title: <>Eligibility</>,
    paragraphs: [
      <>
        You must be at least 18 years old to use the Service. By using the Service, 
        you represent and warrant that you meet this age requirement and that you 
        have the legal capacity to enter into a binding contract.
      </>,
    ]
  },
  {
    title: <>Account Registration</>,
    paragraphs: [
      <>
        To use certain features of the Service, you must register for an account. 
        You agree to:
        <Ul>
          <Li>Provide accurate, current, and complete information during 
              the registration process. 
          </Li>

          <Li>Maintain and promptly update your account information.
          </Li>

          <Li>Keep your password secure and confidential.
          </Li>

          <Li>Notify us immediately of any unauthorized use of your account.
          </Li>
        </Ul>
      </>,
    ]
  },
  {
    title: <>Use of the Service</>,
    paragraphs: [
      <>
        You agree to use the Service in accordance with these Terms and all 
        applicable laws and regulations. You shall not:
        <Ul>
          <Li>Use the Service for any unlawful purpose.
          </Li>

          <Li>Interfere with or disrupt the integrity or performance of the Service.
          </Li>

          <Li>Attempt to gain unauthorized access to the Service or its related 
              systems or networks.
          </Li>

          <Li>Upload or transmit any content that is infringing, defamatory, obscene, 
              or otherwise objectionable.
          </Li>
        </Ul>
      </>,
    ]
  },

  {
    title: <>User Content</>,
    paragraphs: [
      <>
        You retain ownership of any content you submit, post, or display on 
        or through the Service ("User Content"). By submitting User Content, 
        you grant us a non-exclusive, worldwide, royalty-free, sublicensable, 
        and transferable license to use, reproduce, modify, adapt, publish, 
        translate, create derivative works from, distribute, perform, and 
        display such User Content in connection with the Service.
      </>,
    ]
  },

  {
    title: <>Intellectual Property</>,
    paragraphs: [
      <>
        The Service and its original content, features, and functionality are 
        and will remain the exclusive property of the Company and its licensors. 
        The Service is protected by copyright, trademark, and other laws of both 
        the United States and foreign countries. Our trademarks and trade dress 
        may not be used in connection with any product or service without the 
        prior written consent of the Company.
      </>,
    ]
  },

  {
    title: <>Subscription and Payment</>,
    paragraphs: [
      <>
        <Ul>
          <Li>The Service is provided on a subscription basis. Subscription fees 
             are billed in advance on a monthly or annual basis and are non-refundable.
          </Li>

          <Li>We reserve the right to change the subscription fees or introduce 
              new charges upon reasonable notice.
          </Li>

          <Li>Failure to pay subscription fees may result in the termination or 
              suspension of your account.
          </Li>
        </Ul>
      </>,
    ]
  },
  
  {
    title: <>Termination</>,
    paragraphs: [
      <>
        We may terminate or suspend your account and access to the Service at our 
        sole discretion, without prior notice, if you breach these Terms or for 
        any other reason. Upon termination, your right to use the Service will 
        immediately cease.
      </>,
    ]
  }, 

  {
    title: <>Limitation of Liability</>,
    paragraphs: [
      <>
        To the maximum extent permitted by law, the Company shall not be liable 
        for any indirect, incidental, special, consequential, or punitive damages, 
        or any loss of profits or revenues, whether incurred directly or indirectly, 
        or any loss of data, use, goodwill, or other intangible losses, resulting from:

        <Ul>
          <Li>Your use of or inability to use the Service.
          </Li>

          <Li>Any unauthorized access to or use of our servers and/or any personal
             information stored therein.
          </Li>

          <Li>Any interruption or cessation of transmission to or from the Service.
          </Li>
        </Ul>
      </>,
    ]
  }, 

  {
    title: <>Disclaimer of Warranties</>,
    paragraphs: [
      <>
        The Service is provided "as is" and "as available" without any warranties 
        of any kind, whether express or implied, including, but not limited to, 
        implied warranties of merchantability, fitness for a particular purpose,
        non-infringement, or course of performance.
      </>,
    ]
  },

  {
    title: <>Governing Law</>,
    paragraphs: [
      <>
        These Terms shall be governed and construed in accordance with the laws 
        of Poland, without regard to its conflict of law provisions.
      </>,
    ]
  }, 
  
  {
    title: <>Changes to Terms</>,
    paragraphs: [
      <>
        We reserve the right to modify or replace these Terms at any time. 
        If a revision is material, we will provide at least 30 days' notice 
        prior to any new terms taking effect. What constitutes a material 
        change will be determined at our sole discretion.
      </>,
    ]
  }, 

  {
    title: <>Contact Us</>,
    paragraphs: [
      <>
        If you have any questions about these Terms, please contact us at:
        <Ul>
          <Li>Phone: {Company.phonePretty}</Li>
          <Li>Email: <A href={`mailto:${Company.email.legal.to}`}>{Company.email.legal.to}</A></Li>

          <Li> Address
            <CompanyInfo>
              <CompanyName>{Company.name}</CompanyName>
              <Address>
                <div>{Company.address.line1}</div>
                <div>{Company.address.postCode}</div>
                <div>{Company.address.city}</div>
                <div>{Company.address.country}</div>
              </Address>
            </CompanyInfo>
          </Li>
        </Ul>
      </>,
    ]
  }, 
];



/**
 * Endlog
 */

const endlog = (
  <>
    By using the Service, you acknowledge that you 
    have read, understood, and agreed to be bound 
    by these Terms of Service.
  </>
);


export const content = {
  lastUpdate,
  title,
  sections,
  endlog,
}