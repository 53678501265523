import { Size } from "app/arch/types";
import { Page } from "app/arch/print/page";
import { uuid } from 'tools/uuid';

import DocState from '../../doc-state';
import * as Types from './types';


/**
 * Row
 */
export const columnsAutoAdjust = (document: DocState) => {
  const columnsProps = document.headerRows.getColumnsProps();
  
  const pageLayout  = document.viewsCommon.getPageLayout();
  const pageMargins = document.viewsCommon.getPageMargins();
  
  const contentSize = Page.getBodySizePx(
    pageLayout.format, 
    pageLayout.orientation, 
    pageMargins
  ) as Size;

  const totalWidth = contentSize[0];

  const column0Width = columnsProps[0].width;
  const column1Width = totalWidth - columnsProps[0].width;
  
  document.headerRows.updateColumn(0, {width: column0Width});
  document.headerRows.updateColumn(1, {width: column1Width});
}


/**
 * Row
 */
export const getRowKey = (
  rowAddr: Types.RowAddr
): string => (
  `${rowAddr.rowId}`
);

export const createRowAddr = (
): Types.RowAddr => {
  const rowId = uuid();
  const rowAddr = { rowId };
  return rowAddr;
} 

export const compareRowAddr = (
  srcRowAddr: Types.RowAddr,
  dstRowAddr: Types.RowAddr,
): boolean => (
  srcRowAddr.rowId === dstRowAddr.rowId
);


/**
 * Cell
 */
export const getCellKey = (
  cellAddr: Types.CellAddr
): string => (
  `${cellAddr.rowId}`
);

export const compareCellAddr = (
  srcCellAddr: Types.CellAddr,
  dstCellAddr: Types.CellAddr,
): boolean => (
  srcCellAddr.rowId === dstCellAddr.rowId
);


/**
 * Page Row
 */

export const getPageRowKey = (
  pageRowAddr: Types.PageRowAddr
): string => (
  `${pageRowAddr.pageIdx}` +
  `::${pageRowAddr.rowId}`
);


export const comparePageRowAddr = (
  srcRowAddr: Types.PageRowAddr,
  dstRowAddr: Types.PageRowAddr,
): boolean => (
  compareRowAddr(srcRowAddr, dstRowAddr) && 
  (srcRowAddr.pageIdx === dstRowAddr.pageIdx)
);


/**
 * Page Cell 
 */
export const getPageCellKey = (
  pageRowAddr: Types.PageRowAddr
): string => (
  `${pageRowAddr.pageIdx}` +
  `::${pageRowAddr.rowId}`
);

export const comparePageCellAddr = (
  srcCellAddr: Types.PageCellAddr,
  dstCellAddr: Types.PageCellAddr,
): boolean => (
  compareCellAddr(srcCellAddr, dstCellAddr) &&
  ( srcCellAddr.pageIdx === dstCellAddr.pageIdx )
);

