import produce from 'immer';
import * as State from './state';
import * as Tools from "./tools";
import * as Types from "./types";


export class Subscriptions {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  reset() {
    this._state = State.createInitialState();
  }
  
  get raw() { return this._state; }


  /**
   * Setters
   */

  loadSubscriptions(
    subscriptionsRaw: Types.SubscriptionsRaw
  ) {
    this.reset();

    this._state = produce(this._state, draft => {
      subscriptionsRaw.forEach((subscriptionRaw) => {
        this.__addSubscription(draft, subscriptionRaw);
      })
    });
  }

  private __addSubscription(
    draft: State.State,
    subscriptionRaw: Types.SubscriptionRaw
  ) {
    const subscriptionsAddrs = State.getSubscriptionsAddrs(draft);
    const subscriptionsProps = State.getSubscriptionsProps(draft);

    const subscriptionAddr = Tools.createSubscriptionAddr(subscriptionRaw.subscriptionId);
    const subscriptionKey  = Tools.getSubscriptionKey(subscriptionAddr);
    
    subscriptionsAddrs.push(subscriptionAddr);
    subscriptionsProps[subscriptionKey] = subscriptionRaw;
  }

  updateSubscription(
    subscriptionRaw: Types.SubscriptionRaw
  ) {
    this._state = produce(this._state, draft => {
      const subscriptionsProps = State.getSubscriptionsProps(draft);
      const subscriptionAddr = Tools.createSubscriptionAddr(subscriptionRaw.subscriptionId);
      const subscriptionKey  = Tools.getSubscriptionKey(subscriptionAddr);

      subscriptionsProps[subscriptionKey] = subscriptionRaw;
    });
  }

  /**
   * Getters
   */

  /**
   *  Subscriptions
   */ 
  getSubscriptionsAddrs() {
    return State.getSubscriptionsAddrs(this._state);
  }

  getSubscriptionsProps() {
    return State.getSubscriptionsProps(this._state);
  }


  /**
   *  Subscription
   */ 

  getSubscriptionProps(
    subscriptionAddr: Types.SubscriptionAddr
  ) {
    return State.getSubscriptionProps(this._state, subscriptionAddr);
  }
}
