import React from 'react';
import "react-color-palette/css";

import { ColorPaletteType } from 'app/arch/types/types';

import { ButtonColor, ColorsRowWrapper, SelectedColor } from './styles';
import { MainWrapper }      from './styles';
import { ColorsColumnWrapper } from './styles';



interface Props {
  color?: string;
  onColorSelected?: (color: string) => void;
  colorPalette: ColorPaletteType;
  dataTestPrefix?: string;
}


export const ColorPickerPaletteComponent: React.FC<Props> = (props: Props) => {
  const { 
    color, 
    colorPalette, 
    onColorSelected,
    dataTestPrefix,
  } = props;


  const handleColorChange = (newColor: string) => {
    onColorSelected?.(newColor);
  };


  const renderColors = () => {
    const colorsComponents = colorPalette.map((colorsRow, rowIdx) => {
      const colorsRowComponent = colorsRow.map((color_, idx) => {

        return (
          <ButtonColor
            key={`${idx}-${color_}`}
            data-test={`${dataTestPrefix}-btnColor-${color_}`}
            $selected={color === color_}
            $color={color_}
            $lastRow={rowIdx === colorPalette.length - 1}
            onClick={() => { handleColorChange(color_); }}
          >
            { 
              color === color_ &&
              <SelectedColor />
            }
          </ButtonColor>
        );
      });

      return (
        <ColorsRowWrapper
          key={rowIdx}
        >
          { colorsRowComponent }
        </ColorsRowWrapper>
      );
    });

    return (
      <ColorsColumnWrapper>
        { colorsComponents }
      </ColorsColumnWrapper>
    )
  }  

  return (
    <MainWrapper>
      { renderColors() }
    </MainWrapper>
  );
}
