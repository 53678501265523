import styled from "styled-components";
import ScrollbarInv from "lego/styles/scrollbar-inv";


export const Window = styled(ScrollbarInv)`
  @media print {
    display: none;  
  }

  position: fixed;
  top: 10px;
  right: 10px;

  background: #eee;
  border-style: solid;
  border-width: 2px;
  border-color: #666;
  border-radius: 6px;

  padding: 10px;
  z-index: 1000;

  max-height: 260px;
  overflow-y: auto;
`;


export const Label = styled.div`
  font-size: 18px;
  font-weight: 600;
`;


export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 20px;
  row-gap: 2px;
`;


export const GridItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const Separator = styled.div`
  margin: 6px 0 6px 0;
  width: 100%;
  height: 1px;
  background: #333;
`;


export const PropLabel = styled.div`
  font-size: 14px;
`;


export const PropValue = styled(PropLabel)`
  font-weight: 600;
`;
