import React from 'react';
import { useRef } from 'react';

import environment from 'app/environment';
import useMinHeightCalc from './us-min-height-calc';

import { Grid }       from './styles';
import { RowTitle }   from './styles';
import { RowContent } from './styles';
import { ContentDefault } from './styles';
import { DebugInfo }  from './styles';


interface Props {
  title: string;
  Content?: React.ElementType;
  children: React.ReactNode;
}


export const ViewTitledComponent: React.FC<Props> = (props: Props) => {
  const { 
    title,
    children, 
  } = props;

  const Content = props.Content || ContentDefault;

  const gridRef    = useRef<HTMLDivElement>(null);
  const titleRef   = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  
  const minHeight = useMinHeightCalc({
    gridRef,
    titleRef,
    contentRef
  });


  return (
    <>

      <Grid 
        ref={gridRef}
        $minHeight={minHeight}
      >
        <RowTitle ref={titleRef}>
          { title } 
        </RowTitle>

        <RowContent>
          <Content ref={contentRef}>
            { children }
          </Content>
        </RowContent>

      </Grid> 
      {
        environment.dev &&
        <DebugInfo>
          min-height: { minHeight }
        </DebugInfo>
      }
    </>
  );
}
  
