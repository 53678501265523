import { PrintoutViewTypes } from "app/arch/editor-instruction/document/states/printout/printout-view";
import usePrintoutViewState from "./use-printout-view-state";
import usePrintoutViewPageCurrentUpdate from "./use-printout-view-page-current-update";


const usePrintoutViewPageNext = () => {
  const printoutState = usePrintoutViewState();
  const updatePageCurrent = usePrintoutViewPageCurrentUpdate();

  const __checkIfLastViewPage = (view: PrintoutViewTypes.ViewType) => {
    const pages = printoutState.getPages();
    const isLastPage = (pages.count[view] === (pages.current.pageIdx + 1))

    return isLastPage;
  }

  const __nextContentPage = () => {
    const pages = printoutState.getPages();
    const isLastPage = __checkIfLastViewPage(PrintoutViewTypes.ViewType.CONTENT);
    
    if (isLastPage)  {
      updatePageCurrent({
        pageIdx: 0,
        view: PrintoutViewTypes.ViewType.CHANGELOG,
      });

      return;
    }

    updatePageCurrent({
      pageIdx: pages.current.pageIdx + 1
    });
  }

  const __nextReleaselogPage = () => {
    const pages = printoutState.getPages();
    const isLastPage = __checkIfLastViewPage(PrintoutViewTypes.ViewType.CHANGELOG);

    if (isLastPage)  {
      console.warn("I'm the last page")
      return;
    }

    updatePageCurrent({
      pageIdx: pages.current.pageIdx + 1
    });
  }


  const nextPage = () => {
    const pages = printoutState.getPages();

    const NextPageFn: {[viewType in PrintoutViewTypes.ViewType]: () => void } = {
      [PrintoutViewTypes.ViewType.CONTENT  ]: __nextContentPage,
      [PrintoutViewTypes.ViewType.CHANGELOG]: __nextReleaselogPage,
    }

    const nextPageFn = NextPageFn[pages.current.view];
    nextPageFn();
  }

  return nextPage;
}


export default usePrintoutViewPageNext;