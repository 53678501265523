import styled from 'styled-components';
import MenuItem from 'lego/styles/menu_item';
import PanelSecondary from 'lego/styles/panel_secondary';


export const Panel = styled(PanelSecondary)`
  flex-grow: 1;
`;


export const Items = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemWrapper = styled.div`
`;

export const Item = styled(MenuItem)`
  height: 24px;
`;
