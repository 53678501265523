import React from 'react';
import { PanelControlsItemSectionControl_ColorPickerConfig as Config} from '../../config';
import ColorPickerComponent from 'lego/components/color-picker';
import { ColorsPalette } from 'app/configs/colors-palette';
import { unpackBoolean } from '../../tools';

interface Props {
  config: Config;
}


export const ControlColorPickerComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;
  const disabled = unpackBoolean(config.disabled);


  return (
    <ColorPickerComponent
      dataTest={config.dataTest}
      color={config.color}
      disabled={disabled}
      colorPalette={ColorsPalette.base}
      onColorSelected={config.onColorSelected}
    />
  );
}
  
