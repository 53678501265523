import { atom }     from 'recoil';
import { selector } from 'recoil';
import { ReleaselogsViewState as State} from 'app/arch/editor-instruction/document/states/persistent/releaselogs-view';


/**
 * State
 */

export const state = atom<State.State>({
  key: "edi_doc_viewReleaselogs",
  default: State.createInitialState()
});


/**
 * Header Meta
 */
export const headerMetaVisible = selector({
  key: "edi_doc_viewReleaselogs_headerMetaVisible",
  get: ({ get }) => {
    const stateObj = get(state);
    const visible = State.getHeaderMeta(stateObj).visible;
    return visible;
  }
});


/**
 * Header Title
 */
export const headerTitleVisible = selector({
  key: "edi_doc_viewReleaselogs_headerTitleVisible",
  get: ({ get }) => {
    const stateObj = get(state);
    const visible = State.getHeaderTitle(stateObj).visible;
    return visible;
  }
});


export const headerTitleTitle = selector({
  key: `edi_doc_viewReleaselogs_headerTitleTitle`,
  get: ({ get }) => {
    const stateObj = get(state);
    const title = State.getHeaderTitle(stateObj).title;
    return title
  }
});

export const headerTitleCSS = selector({
  key: "edi_doc_viewReleaselogs_headerTitleCSS",
  get: ({ get }) => {
    const stateObj = get(state);
    const css = State.getHeaderTitle(stateObj).css;
    return css;
  }
});


