import { PanelControlsConfig } from "lego/components/panel-controls/config";

import { RepoMarkersTools } from "app/arch/editor-instruction/document/states/persistent/repo-markers";
import { RepoMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/repo-markers";
import { useTranslations } from "app/ui/hooks/app/use-translation";

import useConfigSubsectionsMarkerLabel from "./marker-label";
import useConfigSubsectionsMarkerSign  from "./marker-sign";
import useConfigSubsectionsMarkerIcon  from "./marker-icon";

import useItems from "./common/use-items";
import useStates from "./common/use-states";


const usePanelConfig = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
  const t = useTranslations();
  const items = useItems(markerAddr);
  const states = useStates(markerAddr);

  const getConfigSubsectionsMarkerLabel = useConfigSubsectionsMarkerLabel(markerAddr);
  const getConfigSubsectionsMarkerIcon  = useConfigSubsectionsMarkerIcon(markerAddr);
  const getConfigSubsectionsMarkerSign  = useConfigSubsectionsMarkerSign(markerAddr);

  const markerGroup = RepoMarkersTools.getMarkerGroup(states.markerProps.type);

  const subsectionsResolver: {[markerGroup in RepoMarkersTypes.MarkerGroup]: any} = {
    [RepoMarkersTypes.MarkerGroup.ICON]: getConfigSubsectionsMarkerIcon,
    [RepoMarkersTypes.MarkerGroup.SIGN]: getConfigSubsectionsMarkerSign,
    [RepoMarkersTypes.MarkerGroup.LABEL]: getConfigSubsectionsMarkerLabel,
  }

  const getConfigSubsections = subsectionsResolver[markerGroup];
  const subsections = getConfigSubsections();


  //---------------------
  // Get config

  const getConfig = () => {
    const config: PanelControlsConfig = {
      items: [{
        sections: [{
          subsections: [
            {
              title: t("marker"),
              items: [ items.getMarkerGroup() ]
            },
            ...subsections,
          ]
        }]
      }]
    } // config
  
    return config;
  }

  return getConfig;
}

export default usePanelConfig;