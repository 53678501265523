import { ContentSessionTypes as Types } from 'app/arch/editor-instruction/document/states/sessions/content-session';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useSidetoolbarPropertiesShow = () => {
  const document = useDocState();

  const {
    setContentSession,
  } = useEditorStatesSetters();


  const showToolbar = (panelSelected: Types.PanelPropertiesItem) => {
    const update: Types.PanelPropertiesUpdate = {
      visible: true,
      selected: panelSelected,
    }

    document.contentSession.updatePanelProperties(update);
    setContentSession();
  }

  return showToolbar;
}

export default useSidetoolbarPropertiesShow;