import { useRecoilValue } from 'recoil';
import jtl from 'tools/jtl';

import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';


const useState = () => {
  const widgetSelectedAddr = useRecoilValue(UIState_EditorImageSession.widgetSelected);
  const widgetEditedAddr   = useRecoilValue(UIState_EditorImageSession.widgetEdited);
  const widgetAddr = (widgetSelectedAddr || widgetEditedAddr);
  const configDisabled = (widgetAddr === null);

  const widgetProps = useRecoilValue(UIState_Content.cellImages_image_widgetProps_hack(widgetAddr));

  // TODO 
  // FIXME
  const widgetStyleTmp = useRecoilValue(UIState_Content.cellImages_image_widgetStyle(widgetAddr)) || {};
  const widgetStyle = widgetStyleTmp as any; 
  
  const fillDisabled = (widgetProps !== null && widgetProps.type === ContentTypes.WidgetType.IMAGE);
  
  const noBackgroundWidget = (
    ! widgetProps ? 
    false : 
    ContentTools.hasWidgetNoBackground(widgetProps.type)
  );

  /**
   * CSS attrs extraction
   */ 

  const fillColor = widgetStyle.backgroundColor ?? 'none';

  const borderColor  = widgetStyle.borderColor ?? 'none';
  const borderWidth  = jtl.css.valueToNumber(widgetStyle.borderWidth);
  const borderRadius = jtl.css.valueToNumber(widgetStyle.borderRadius);

  const outlineColor    = widgetStyle.outlineColor ?? 'none';
  const outlineWidth    = jtl.css.valueToNumber(widgetStyle.outlineWidth);


  return {
    configDisabled,

    widgetAddr,
    widgetProps,

    noBackgroundWidget,

    fillColor,
    fillDisabled,

    borderColor,
    borderWidth,
    borderRadius,

    outlineColor,
    outlineWidth,
  }
}


export default useState;