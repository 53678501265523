import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useTranslations } from "app/ui/hooks/app/use-translation";

import useState from "./__use-state";
import useFunctions from "./__use-functions";


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();
  const states = useState();


  const getFillColor = () => ({
    title: t('color'),
    disabled: states.configDisabled,
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: fns.setFillColor,
    color: states.fillColor,
  });
  
  const getBorderColor = () => ({
    title: t('color'),
    disabled: states.configDisabled,
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: fns.setBorderColor,
    color: states.borderColor,
  });

  const getBorderWidth = () => ({
    title: t('width'),
    disabled: states.configDisabled,
    type: PanelControlsItemSectionItemType.SLIDER,
    onChange: fns.setBorderWidthChange,
    onChangeDone: fns.setBorderWidthChangeDone,
    min: 0,
    max: 50,
    value: states.borderWidth,
  });

  const getBorderRoundCorners = () => ({
    title: t('round corners'),
    disabled: states.configDisabled,
    skip: states.widgetProps?.type === ContentTypes.WidgetType.ELLIPSE,
    type: PanelControlsItemSectionItemType.SLIDER,
    onChange: fns.setBorderRadiusChange,
    onChangeDone: fns.setBorderRadiusChangeDone,
    min: 0,
    max: 50,
    value: states.borderRadius,
  });

  const getOutlineColor = () => ({
    title: t('color'),
    disabled: states.configDisabled,
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    onColorSelected: fns.setOutlineColor,
    color: states.outlineColor,
  });

  const getOutlineWidth = () => ({
    title: t('width'),
    disabled: states.configDisabled,
    type: PanelControlsItemSectionItemType.SLIDER,
    onChange: fns.setOutlineWidthChange,
    onChangeDone: fns.setOutlineWidthChangeDone,
    min: 0,
    max: 50,
    value: states.outlineWidth,
  });

  return {
    getFillColor,
    getBorderColor,
    getBorderWidth,
    getBorderRoundCorners,
    getOutlineColor,
    getOutlineWidth,
  }
}


export default useItems;