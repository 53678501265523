import React from 'react';
import { useRecoilValue } from 'recoil';
import ProgressComponent from 'app/ui-v2/hls/progress';
import { UIState_RepoImagesSession } from 'app/ui/states/editor-instruction';

import { ImgUploadWrapper } from './styles';


interface Props {
}


export const ImagesUploadingComponent: React.FC<Props> = (props: Props) => {
  const imagesUploadingCount = useRecoilValue(UIState_RepoImagesSession.uploadingImagesCount);
  // const imagesUploadingCount = 3;

  const renderUploadingImg = (idx: number) => (
    <ImgUploadWrapper key={idx}>
      <ProgressComponent size={20} />
    </ImgUploadWrapper>
  );

  const renderUploadingImgs = () => {
    const indexes = Array(imagesUploadingCount).fill(0).map((_, i) => i + 1);
    const UploadingImgs = indexes.map((idx) => renderUploadingImg(idx));

    return UploadingImgs;
  }

  return renderUploadingImgs();
}

