import { Msgs } from './use-common';
import { useCommon } from './use-common';
import { useQuery as useQueryApollo } from '@apollo/client';


export const useQueryV2 = (props: {
  query: any,
  msgs?: Msgs,
  skip?: boolean,
  variables?: any,
}) => {
  const {
    query,
    msgs,
    skip,
    variables
  } = props;

  const { 
    loading, 
    error, 
    data,
    refetch
  } = useQueryApollo(query, {
    variables,
    skip: (skip !== undefined ? skip : false),
    fetchPolicy: 'no-cache'
  });

  useCommon({msgs, error, loading});
 
  return {
    loading,
    data,
    error,
    refetch
  }
}
