import React  from 'react';
import { ColorPicker } from "react-color-palette";
import { IColor }      from "react-color-palette";
import { useColor }    from "react-color-palette";
import { useDebouncedCallback } from 'use-debounce';
import jtl from 'tools/jtl';

import { ColorPickerContainer } from './styles';


interface Props {
  color?: string;
  onColorSelected?: (color: string) => void;
}


export const ColorPickerCustomComponent: React.FC<Props> = (props: Props) => {
  const { 
    color, 
    onColorSelected,
  } = props;

  const colorInit = (
    color !== undefined && jtl.color.isColorString(color) ?
    color :
    "#000000"
  );

  
  const [customColor, setCustomColor] = useColor(colorInit);

  const handleColorChange = (newColor: IColor) => {
    if (newColor.hex) {
      setCustomColor(newColor);
      handleColorChangeDebounced(newColor.hex);
    }
  }

  const handleColorChangeDebounced = useDebouncedCallback((hexColor: string) => {
    onColorSelected?.(hexColor);
  }, 1);

  return (
    <ColorPickerContainer>
      <ColorPicker
        height={150}
        hideInput={true}
        hideAlpha={true}
        color={customColor} 
        onChange={handleColorChange} 
      />
    </ColorPickerContainer>      
  );
}
