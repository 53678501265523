import { PrintoutViewTypes } from 'app/arch/editor-instruction/document/states/printout/printout-view';
import usePrintoutViewPageCurrent from './use-printout-view-page-current';
import usePrintoutViewState from './use-printout-view-state';


const usePrintoutViewIdReady = () => {
  const pageCurrent = usePrintoutViewPageCurrent();
  const printoutViewState = usePrintoutViewState();
  const pagesCount = printoutViewState.getPages().count;

  const pageIdx = (
    pageCurrent.view === PrintoutViewTypes.ViewType.CONTENT ?
    pageCurrent.pageIdx :
    pageCurrent.pageIdx + pagesCount[PrintoutViewTypes.ViewType.CONTENT]
  )

  const idReady = `printout-state__ready__${pageIdx}`;
  return idReady;
}


export default usePrintoutViewIdReady;