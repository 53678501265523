import { atom } from 'recoil';
import { atomFamily } from 'recoil';


/**
 * Signal all releaselogs columns widths change (needed for undo)
 */

export const releaselogsColumnsWidths = atom({
  key: "ei_docReleaselog_signals_releaselogsColumnsWidths",
  default: {}
});


/**
 * Signal releaselog columns widths change
 */

export const releaselogColumnsWidths = atomFamily({
  key: "ei_docReleaselog_signals_releaselogColumnsWidths",
  default: {}
});


