import styled from "styled-components";
import ButtonBase from "lego/styles/button";
import PanelForth from "lego/styles/panel_forth";
import PanelThird from "lego/styles/panel_third";
import SeparatorBase from 'lego/styles/separator';



export const TypesList = styled(PanelThird)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xsmall};
`;
