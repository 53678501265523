import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import { useProgressModal } from 'lego-v2/progress-modal';
import { useAuth } from 'services/auth';

import { useApp } from 'app/ui/hooks/app/use-app';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import LoginFormComponent from 'app/ui-v2/home-page/home-page-guest/components/home-page-guest-view-selected/views/view-user-login/login-form';
import HLS_GoogleAuthenticationComponent from 'app/ui-v2/home-page/hls/google-authentication';
import useReloginVisibleSet from '../../hooks/use-relogin-visble-set';
import GuestButtonComponent from './guest-button';

import { MainWrapper } from './styles';
import { Background } from './styles';
import { Content } from './styles';
import { ContentWrapper } from './styles';
import { Title } from './styles';


interface Props {
}


export const ReloginViewComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  interface User {
    id: number,
    username: string | null,
  }

  const t = useTranslations();
  const app = useApp();
  const auth = useAuth();

  const setReloginVisible = useReloginVisibleSet();
  const userRef = useRef<User | null>(null);

  const {
    showProgressModal: showProgress,
    hideProgressModal: hideProgress
  } = useProgressModal();

  useEffect(() => {
    hideProgress();

    const user: User = {
      id: auth.getUserId(),
      username: auth.getUsername(),
    }

    userRef.current = user;
  }, []);

  const handleLogin = () => {
    const user: User = {
      id: auth.getUserId(),
      username: auth.getUsername(),
    }

    const reset = () => {
      setReloginVisible(false);
      userRef.current = null;
      app.reset();
      window.location.href = '/';
    }
    
    const oldUser = userRef.current;

    if ( oldUser === null) {
      reset();
      return;
    }

    if ( user.id !== oldUser.id) {
      // User relogin as another user
      // we should reset app states, as otherwise
      // we can guarante access to loaded instruction
      // to person without permission
      reset();
      return;
    }

    setReloginVisible(false);
    hideProgress();
  }

  const handleLoginError = (error: string) => {
    hideProgress();
  }

  return (
    <Background>
      <MainWrapper>

        <ContentWrapper>
          
          <Content>
            <Title>{ t("relogin, title") }</Title>

            <LoginFormComponent 
              onLogin={handleLogin}
              onLoginError={handleLoginError}
            />
            
            <HLS_GoogleAuthenticationComponent 
              onLogin={handleLogin}
              onLoginError={handleLoginError}
            />

            <GuestButtonComponent />
            
          </Content>
        </ContentWrapper>

      </MainWrapper>
    </Background>
  );
}
