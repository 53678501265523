import { EditorSessionTypes } from "app/arch/editor-instruction/document/states/sessions/editor-session";


const __editorDocumentEdit = (
  documentId: number, 
  view?: EditorSessionTypes.ViewType
) => {
  const urlBase = `/auth/editor/document/${documentId}/edit`;
  const url = (
    view === undefined ?
    `${urlBase}` :
    `${urlBase}/${view}`
  );

  return url;
}


const urls = {
  home: '/',

  editor: {
    document: {
      edit: __editorDocumentEdit,
    },
  },
}





export default urls;
