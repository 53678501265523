import { useApp } from 'app/ui/hooks/app/use-app';


const useModals = () => {
  const app = useApp();
  const modals = app.modals;

  return modals;
}


export default useModals;