import React from 'react';
import { useState } from 'react';
import DocumentContextScaleComponent    from './document-context-scale';
import DocumentContextScaleSetComponent from './document-context-scale-set';
import DocumentContextScaleRefComponent from './document-context-scale-ref';
import DeskScrollerRefComponent         from './desk-scroller-ref';


interface Props {
  children: React.ReactNode;
}


export const DocumentStatesComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const [scale, setScale] = useState(1);


  return (
    <DocumentContextScaleComponent scale={scale}>
      <DocumentContextScaleSetComponent setScale={setScale} >
        <DocumentContextScaleRefComponent scale={scale} >
          <DeskScrollerRefComponent >
          { children }
          </DeskScrollerRefComponent>
        </DocumentContextScaleRefComponent>
      </DocumentContextScaleSetComponent>
    </DocumentContextScaleComponent>
  );
}

