
/**
 * State
 */
export type State = {
  title: string;
  description: string;
  formatVersion: number;
};


/**
 * Getters
 */
export const getFormatVersion = (state: State) => state.formatVersion;
export const getTitle       = (state: State) => state.title;
export const getDescription = (state: State) => state.description;


/**
 * Initial State
 */
export const createInitialState = (): State => {
  const initState: State = {
    title: '',
    description: '',
    formatVersion: 1.2
  }

  return initState;
}
