import produce from 'immer';
import * as Types from './types';



//----------------------------
//
// State
//

export type State = {
  dnd: {
    context: string | null,
    data: string | null,
  },

  view: {
    type: Types.ViewType
  },

  toolbar: {
    items: {
      visible: boolean,
      selected: Types.ToolbarItems
    },

    properties: {
      visible: boolean,
      selected: Types.ToolbarProperties
    },
  },
};


//----------------------------
//
// Producers
//

export const setToolbarItems = produce((
  draft: State,
  update: {
    visible?: boolean,
    selected?: Types.ToolbarItems
  }) => {
  const toolbar = draft.toolbar.items;
  toolbar.visible  = update.visible  ?? toolbar.visible;
  toolbar.selected = update.selected ?? toolbar.selected;
});

export const setToolbarProperties = produce((
  draft: State,
  update: {
    visible?: boolean,
    selected?: Types.ToolbarProperties
  }) => {
  const toolbar = draft.toolbar.properties;
  toolbar.visible  = update.visible  ?? toolbar.visible;
  toolbar.selected = update.selected ?? toolbar.selected;
});

export const setDNDContext = produce((
  draft: State, 
  context: string | null
) => {
  draft.dnd.context = context;
});

export const setDNDData = produce((
  draft: State, 
  data: string | null
) => {
  draft.dnd.data = data;
});

export const setViewType  = produce((
  draft: State, 
  viewType: Types.ViewType
) => {
  draft.view.type = viewType;
}); 

//----------------------------
//
// Getters
//

export const getToolbarItemsVisible  = (state: State) => state.toolbar.items.visible;
export const getToolbarItemsSelected = (state: State) => state.toolbar.items.selected;

export const getToolbarPropertiesVisible  = (state: State) => state.toolbar.properties.visible;
export const getToolbarPropertiesSelected = (state: State) => state.toolbar.properties.selected;

export const getDNDContext = (state: State) => state.dnd.context;
export const getDNDData    = (state: State) => state.dnd.data;
export const getViewType   = (state: State) => state.view.type;


//----------------------------
//
// Create initial state
//

export const createInitialState = () => {
  return {
    dnd: {
      context: null,
      data: null,
    },

    view: {
      type: Types.ViewType.CONTENT,
      // type: ViewType.CHANGELOG,
      // type: ViewType.CONTENT,
    },

    toolbar: {
      items: {
        visible: false,
        selected: Types.ToolbarItems.IMAGE
      },
      properties: {
        visible: false,
        selected: Types.ToolbarProperties.PAGE
      },
    }
  }
}
