import { PanelControlsItemSubsectionConfig } from "lego/components/panel-controls/config";
import { RepoMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/repo-markers";
import { useTranslations } from "app/ui/hooks/app/use-translation";

import useItems from "./__use-items";


const useConfigSubsections = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
  const t = useTranslations();
  const items = useItems(markerAddr);

  const getConfigSubsections = () => {
    const subsections: PanelControlsItemSubsectionConfig[] = [
      {
        title: t("marker icon"),
        items: [
          items.getIconSize(),
          items.getIconPadding(),
          items.getBorderRadius(),
          items.getIconColor(),
          items.getIconBackgroundColor(),
        ]
      },

      {
        title: t("border"),
        items: [
          items.getBorderEnabled(),
          items.getBorderWidth(),
          items.getBorderColor(),
        ]
      },

      // {
      //   title: t("marker label"),
      //   items: items.getLabelItems(),
      // },
    ];
  
    return subsections;
  }


  return getConfigSubsections;
}

export default useConfigSubsections;