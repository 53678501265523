import { useEffect  } from "react";
import { useState   } from "react";
import { useReducer } from "react";
import { useRecoilValue    } from "recoil";

import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import { UIState_Content } from "app/ui/states/editor-instruction";
import { useDocState }         from "app/ui/contexts/document";
import useEditorStatesSetters  from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import useWidgetContextMenu    from "app/ui-v2/editor-image/hooks/widget/use-widget-context-menu";
import useWidgetPartSelect     from "app/ui-v2/editor-image/hooks/widget/use-widget-part-select";


import { arrowStateReducer    } from "./state-routines";
import { getArrowStateInitial } from "./state-routines";

import { WidgetPropsBase } from "../../../types";
import { ShapeLayerType }  from "../types";

import ShapeArrowComponent from "../shapes/shape-arrow";

import { DraggerHeadUpdateStart } from "../draggers/types";
import { DraggerHeadUpdate      } from "../draggers/types";
import { DraggerTailUpdateStart } from "../draggers/types";
import { DraggerTailUpdate      } from "../draggers/types";
import { DraggerBodyUpdate }      from "../draggers/types";

import DraggerBodyComponent       from "../draggers/dragger-body";
import DraggerHeadArrowComponent  from "../draggers/dragger-head-arrow";
import DraggerTailBlankComponent  from "../draggers/dragger-tail-blank";

import FrameArrowHeadComponent from "../frames/frame-arrow-head";
import FrameArrowBodyComponent from "../frames/frame-arrow-body";

import { Canceler } from "./styles";
import { FrameArrowBodyWrapper } from "./styles";
import { FrameArrowHeadWrapper } from "./styles";

interface Props extends WidgetPropsBase {
}


export const WidgetArrowPlainComponent: React.FC<Props> = (props: Props) => {
  const { 
    widgetAddr,
    scale,
    dataTest,
  } = props;

  const editDisabled = (
    props.editDisabled !== undefined ?
    props.editDisabled :
    false
  );

  const document = useDocState();

  const {
    setContent,
  } = useEditorStatesSetters();

  const {
    showContextMenu,
    hideContextMenu,
  } = useWidgetContextMenu(widgetAddr);

  const selectWidgetPart = useWidgetPartSelect();

  const widgetProps_ = useRecoilValue(UIState_Content.cellImages_image_widgetProps(widgetAddr));
  const widgetProps = widgetProps_ as ContentTypes.WidgetArrowProps;
  // const widgetStyle = useRecoilValue(UIState_Content.cellImages_image_widgetStyle(widgetAddr)) || {};
  const widgetPartSelected = useRecoilValue(UIState_EditorImageSession.widgetPartSelected(widgetAddr));

  const [dragging, setDragging] = useState(false);

  const [arrowState, arrowStateDispatch] = useReducer(
    arrowStateReducer,
    getArrowStateInitial(widgetProps)
  );

  useEffect(() => {
    arrowStateDispatch({
      type: 'reloadArrow',
      payload: {
        startPoint: widgetProps.startPoint,
        endPoint:   widgetProps.endPoint,
      },
    });
  }, [widgetProps]);
  
  const saveDocContentState = () => {
    const update = {
      startPoint: arrowState.body.startPoint,
      endPoint:   arrowState.body.endPoint,
    }

    document.content.cellImages_image_widgetArrowPlain_update(
      widgetAddr,
      update
    );
    document.saveUndo();
    setContent();
  }

  //--------------------------------------
  // Arrow Head
  //
  const handleArrowHeadUpdateStart = (update: DraggerHeadUpdateStart) => {
    setDragging(true);

    arrowStateDispatch({
      type: 'arrowHeadPositionUpdate_start',
      payload: update,
    });
  }

  const handleArrowHeadUpdate = (update: DraggerHeadUpdate) => {
    arrowStateDispatch({
      type: 'arrowHeadPositionUpdate_update',
      payload: update,
    });
  }

  const handleArrowHeadUpdateDone = (update: DraggerHeadUpdate) => {
    handleArrowHeadUpdate(update);
    arrowStateDispatch({
      type: 'arrowHeadPositionUpdate_done',
      payload: update,
    });

    saveDocContentState();
    setDragging(false);
  }



  //--------------------------------------
  // Arrow Body
  //
  const handleArrowBodyUpdateStart = () => {
    setDragging(true);
  }

  const handleArrowBodyUpdate = (update: DraggerBodyUpdate) => {
    arrowStateDispatch({
      type: 'arrowBodyPositionChanged',
      payload: update,
    });
  }

  const handleArrowBodyUpdateDone = (update: DraggerBodyUpdate) => {
    handleArrowBodyUpdate(update);
    saveDocContentState();
    setDragging(false);
  }



  //--------------------------------------
  // Arrow Tail dragger
  //
  const handleArrowTailDraggerUpdateStart = (update: DraggerTailUpdateStart) => {
    setDragging(true);

    arrowStateDispatch({
      type: 'arrowTailPositionUpdate_start',
      payload: update,
    });    
  }

  const handleArrowTailDraggerUpdate = (update: DraggerTailUpdate) => {
    arrowStateDispatch({
      type: 'arrowTailPositionUpdate_update',
      payload: update,
    });  
  }

  const handleArrowTailDraggerUpdateDone = (update: DraggerTailUpdate) => {
    handleArrowTailDraggerUpdate(update);
    arrowStateDispatch({
      type: 'arrowTailPositionUpdate_done',
      payload: update,
    });

    saveDocContentState();
    setDragging(false);
  }


  //--------------------------------------
  // 

  const handleSelectWidgetPart = (
    event: React.MouseEvent, 
    widgetPart: ContentTypes.WidgetPart
  ) => {
    selectWidgetPart({
      widgetAddr,
      widgetPart,
      editDisabled
    });
  }

  const handleContextMenu = (
    event:  React.MouseEvent,
    widgetPart: ContentTypes.WidgetPart
  ) => {
    selectWidgetPart({
      widgetAddr,
      widgetPart,
      editDisabled
    });

    showContextMenu({
      event,
      widgetAddr,
      editDisabled
    });
  }


  //--------------------------------------
  // Render
  //

  const renderArrow = (shapeLayer: ShapeLayerType) => (
    <ShapeArrowComponent
      layer={shapeLayer}
      style={widgetProps.style}
      startPoint={arrowState.body.startPoint}
      endPoint={arrowState.body.endPoint}
    />
  );

  const renderArrows = () => {
    return (
      <>
        { renderArrow(ShapeLayerType.BOTTOM) }
        { renderArrow(ShapeLayerType.MIDDLE) }
        { renderArrow(ShapeLayerType.TOP) }
      </>
    );
  }

  const renderDraggers = () => {
    return (
      <>
        <DraggerBodyComponent
          scale={scale}
          disabled={editDisabled}

          startPoint={arrowState.body.startPoint}
          endPoint={arrowState.body.endPoint}
          style={widgetProps.style}

          onUpdateStart={handleArrowBodyUpdateStart}
          onUpdate={handleArrowBodyUpdate}
          onUpdateDone={handleArrowBodyUpdateDone}

          onContextMenu={(event: React.MouseEvent) =>
            handleContextMenu(event, ContentTypes.WidgetPart.ARROW_BODY)
          }
          setWidgetSelected={(event: React.MouseEvent) => 
            handleSelectWidgetPart(event, ContentTypes.WidgetPart.ARROW_BODY)
          }

          dataTest={dataTest}
        />
        
        <DraggerHeadArrowComponent
          scale={scale}
          disabled={editDisabled}

          startPoint={arrowState.body.startPoint}
          endPoint={arrowState.body.endPoint}
          style={widgetProps.style}
          
          onUpdateStart={handleArrowHeadUpdateStart}
          onUpdate={handleArrowHeadUpdate}
          onUpdateDone={handleArrowHeadUpdateDone}

          onContextMenu={(event: React.MouseEvent) =>
            handleContextMenu(event, ContentTypes.WidgetPart.ARROW_HEAD)
          }
          setWidgetSelected={(event: React.MouseEvent) => 
            handleSelectWidgetPart(event, ContentTypes.WidgetPart.ARROW_HEAD)
          }

          dataTest={dataTest}
        />

        <DraggerTailBlankComponent
          scale={scale}
          disabled={editDisabled}

          startPoint={arrowState.body.startPoint}
          endPoint={arrowState.body.endPoint}
          style={widgetProps.style}

          onUpdateStart={handleArrowTailDraggerUpdateStart}
          onUpdate={handleArrowTailDraggerUpdate}
          onUpdateDone={handleArrowTailDraggerUpdateDone}

          onContextMenu={(event: React.MouseEvent) =>
            handleContextMenu(event, ContentTypes.WidgetPart.ARROW_BODY)
          }
          setWidgetSelected={(event: React.MouseEvent) => 
            handleSelectWidgetPart(event, ContentTypes.WidgetPart.ARROW_BODY)
          }

          dataTest={dataTest}
        /> 
      </>
    );
  }

  const renderFrames = () => {
    if ( dragging ) {
      return null;
    } 

    // Canceler is needed - otherwise it will 
    // start multiselection
    return (
      <Canceler
        onPointerDown={(event: React.PointerEvent) => {
          event.stopPropagation();
        }}
      >

      {
        widgetPartSelected === ContentTypes.WidgetPart.ARROW_HEAD &&
        <FrameArrowHeadWrapper>
          <FrameArrowHeadComponent 
            scale={scale}
            
            widgetAddr={widgetAddr}

            widgetStyle={widgetProps.style}
            startPoint={arrowState.body.startPoint}
            endPoint={arrowState.body.endPoint}
            tailSize={[0, 0]}
          />
        </FrameArrowHeadWrapper>
      }

      {
        widgetPartSelected === ContentTypes.WidgetPart.ARROW_BODY &&
        <FrameArrowBodyWrapper>
          <FrameArrowBodyComponent 
            scale={scale}

            widgetAddr={widgetAddr}

            widgetStyle={widgetProps.style}
            startPoint={arrowState.body.startPoint}
            endPoint={arrowState.body.endPoint}
            tailSize={[0, 0]}

          />
        </FrameArrowBodyWrapper>
      }

      </Canceler>
    );
  }


  return (
    <>
      { renderArrows()   }
      { ! editDisabled && renderDraggers() }
      { ! editDisabled && renderFrames()   } 
    </>
  );
}
