import React from 'react';
import { useSetRecoilState } from 'recoil';

import { settings } from 'app/configs';
import { ThemeAccentColor } from 'app/arch/app/themes/types';
import { ThemeVariantType } from 'app/arch/app/themes/types';
import { themes           } from 'app/arch/app/themes/themes';
import { useTranslations  } from 'app/ui/hooks/app/use-translation';
import useUserSettings     from 'app/ui-v2/app/__modules/user/hooks/use-user-settings';
import useUserStateSetters from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';
import useUserSettingsSave from 'app/ui-v2/app/__modules/user/hooks/use-user-settings-save';

import PanelItem      from 'lego/styles/panel_item';
import PanelItemLabel from 'lego/styles/panel_item_header';

import { ButtonGroup } from './styles';
import { ButtonColor } from './styles';


interface Props {
  theme: any;
}


export const ThemeAccentColorSetterComponent: React.FC<Props> = (props: Props) => {
  const { theme: themeDescriptor  } = props;

  const t = useTranslations();
  const userSettings = useUserSettings();

  const saveUserSettings = useUserSettingsSave();
  const {
    setUserSettings
  } = useUserStateSetters();

  const darkMode  = themeDescriptor.variant === ThemeVariantType.DARK;

  const generateThemeAccentColors = themes.getThemeAccentColors(themeDescriptor.type);
  const accentColors = generateThemeAccentColors(darkMode);


  const setAccentColor = (accentColor: ThemeAccentColor) => {
    userSettings.updateTheme({ accentColor });
    setUserSettings();
    saveUserSettings();
  }

  const renderButtons = () => {
    const buttonColors = settings.accentColors.map((color) => {
      const accent = accentColors[color];
      const selected = themeDescriptor.accentColor === color;

      return (
        <ButtonColor
          color={accent.primary}
          borderColor={accent.dark}
          selected={selected}
          key={`btn-${color}`}
          onClick={(e: any) => {
            e.stopPropagation();
            setAccentColor(color);
          }}
        />
      )
    });

    return buttonColors;
  }

  return (
    <PanelItem>
      <PanelItemLabel>
        { t('theme accent color') }
      </PanelItemLabel>
      <ButtonGroup>
      { renderButtons() }
      </ButtonGroup>
    </PanelItem>
  );
}
  
