import styled from "styled-components";


const TopToolbarWrapper = styled.div`
  display: flex;
  justify-content: center;

  box-sizing: border-box;

  background:    ${props => props.theme.defs.colors.background.third};
  border-color:  ${props => props.theme.toolbar.border.color};
  border-width:  ${props => props.theme.toolbar.border.width};
  border-radius: ${props => props.theme.toolbar.border.radius};
  border-style:  none;
  border-bottom-style: solid;
`;


export default TopToolbarWrapper;