import React from 'react';

import { FrameProps        } from 'lego-v2/frame-resize/ui/FrameResize';
import { FrameTargetUpdate } from 'lego-v2/frame-resize/arch';
import FrameResizerSmartLinesComponentBase from 'lego/components/frame-resizer-smart-lines';

import { SmartLinesFrameResizer } from 'tools/smart-lines/smart-lines-frame-resizer';

import jtl from 'tools/jtl';
import { Position, Size } from 'app/arch/types';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState } from 'app/ui/contexts/document';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';

import useSmartLinesWidgetsLines                 from 'app/ui-v2/editor-image/hooks/smart-lines/use-smart-lines-widgets-lines';
import { useSmartLinesWidgetsLinesSetter }       from 'app/ui-v2/editor-image/hooks/smart-lines/use-smart-lines-widgets-lines-setter';
import { useSmartLinesWidgetsStickyLinesSetter } from 'app/ui-v2/editor-image/hooks/smart-lines/use-smart-lines-widgets-sticky-lines-setter';


interface Props extends FrameProps {
  widgetAddr: Types.WidgetAddr;
}


export const FrameResizerSmartLinesComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
  } = props;

  const document = useDocState();
  const smartLines = useSmartLinesWidgetsLines();

  const {
    setSmartLines,
    unsetSmartLines
  } = useSmartLinesWidgetsLinesSetter();

  const {
    setStickyLines,
    unsetStickyLines,
  } = useSmartLinesWidgetsStickyLinesSetter();

  // const {
  //   setSrcLines,
  //   unsetSrcLines,
  // } = useSmartLinesWidgetsSrcLinesSetter();

  const handleSetSmartLines = () => {
    const widgetsAddrs = document.content.cellImages_image_getWidgetsAddrs(widgetAddr);
    setSmartLines({
      srcWidgetsAddrs: [widgetAddr],
      dstWidgetsAddrs: widgetsAddrs
    });
  }

  const handleSrcSmartLinesRequest = (update: FrameTargetUpdate) => {
    const widgetProps = document.content.cellImages_image_getWidgetProps(widgetAddr);
    const {
      size, 
      position
    } = (function(){
      if (ContentTools.isWidgetBoxed(widgetProps.type)) {
        const boxProps  = widgetProps as Types.WidgetBoxedProps;
        const size_     = update.size     || boxProps.size;
        const position_ = update.position || boxProps.position;
        const frameWidth = jtl.css.getFramingWidth(boxProps.style);

        const position = [
          position_[0] - frameWidth,
          position_[1] - frameWidth,
        ] as Position;

        const size = [
          size_[0] + 2 * frameWidth,
          size_[1] + 2 * frameWidth,
        ] as Size;

        return { 
          size, 
          position
        };
      }
      else if (ContentTools.isWidgetArrowText(widgetProps.type)) {
        const arrowProps = widgetProps as Types.WidgetArrowTextProps;
        const size_     = update.size     || arrowProps.tailSize;
        const position_ = update.position || arrowProps.endPoint;
        const widgetStyle = arrowProps.style;
        const outlineWidth =  jtl.css.valueToNumber(widgetStyle.outlineWidth);

        const position = [
          position_[0] - outlineWidth,
          position_[1] - outlineWidth,
        ] as Position;

        const size = [
          size_[0] + 2 * outlineWidth,
          size_[1] + 2 * outlineWidth,
        ] as Size;

        return { 
          size, 
          position
        };
      }

      throw new Error(`not implemented for widget: ${widgetProps.type}`);
    }());

    const srcSmartLines = new SmartLinesFrameResizer();

    srcSmartLines.loadFromFrameUpdate(update.control, size, position);
    
    // setSrcLines(srcSmartLines.raw);

    return srcSmartLines.raw;
  }

  return (
    <FrameResizerSmartLinesComponentBase
      controlProps={props}
      
      smartLines={smartLines}
      onSrcSmartLinesRequest={handleSrcSmartLinesRequest}

      onSetSmartLines={handleSetSmartLines}
      onUnsetSmartLines={unsetSmartLines}

      onSetStickyLines={setStickyLines}
      onUnsetStickyLines={unsetStickyLines}
    />
  );
}
  