import { atom }           from 'recoil';
import { selector }       from 'recoil';
import { selectorFamily } from 'recoil';

import { HeaderFieldsState as State} from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';

/**
 * State
 */

export const state = atom<State.State>({
  key: "ei_docContent_docCustomFields",
  default: State.createInitialState()
});




/**
 * Table
 */
export const tableHeaderCSS = selector({
  key: `ei_docContent_docCustomFields_tableHeaderCSS`,
  get: ({ get }) => {
    const stateObj = get(state);
    const table = State.getTable(stateObj);
    return table.header.css;
  }
});


/**
 * Column(s)
 */

export const columnsAddrs = selector({
  key: `ei_docContent_docCustomFields_columnsAddrs`,
  get: ({ get }) => {
    const stateObj = get(state);
    const columnsAddrs = State.getColumnsAddrs(stateObj);
    return columnsAddrs;
  }
});

export const columnName = selectorFamily({
  key: `ei_docContent_docCustomFields_columnName`,
  get: (columnAddr: Types.ColumnAddr) => ({ get }) => {
    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr);
    return columnProps.name;
  }
});

export const columnHeaderCSS = selectorFamily({
  key: "ei_docContent_docCustomFields_columnHeaderCSS",
  get: (columnAddr: Types.ColumnAddr | null) => ({ get }) => {
    if (columnAddr === null) {
      return null;
    }
    
    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr);
    return columnProps.header.css;
  }
});

export const columnCSS = selectorFamily({
  key: `ei_docContent_docCustomFields_columnCSS`,
  get: (columnAddr: Types.ColumnAddr | null)  => ({ get }) => {
    if (columnAddr === null) {
      return null;
    };

    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr);
    return columnProps.css;
  }
});


/**
 * Cell Text
 */

export const cellText_editorState = selectorFamily({
  key: `ei_docContent_docCustomFields_cellText_editorState`,
  get: (cellAddr: Types.CellAddr) => ({ get }) => {
    const stateObj = get(state);
    const cell = State.getCell(stateObj, cellAddr);
    const cellText = cell as Types.TextCell;
    const editorState = cellText.editorState;

    return editorState;
  }
});