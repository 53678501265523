import styled from "styled-components";
import MenuBarItem from "lego/styles/menu_bar_item";


export const MainWrapper = styled.div`
`;

export const StyledBtnText = styled(MenuBarItem)`
`;

export const MenuBarItemPanelDetacher = styled.div`
  position: relative;
  top: 36px;
  left: 0px;
`;

export const MenuBarItemPanelPositioner = styled.div`
  position: absolute;
  /* // TODO */
  z-index: 2;
  /* background-color: red; */
`;

export const BtnWrapper = styled.div`
  
`