import { useRef } from 'react';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Position } from 'app/arch/types';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import DeskContentComponent      from './components/desk-content';
import DeskAreaComponent         from './components/desk-area';
import DeskRescalerComponent     from './components/desk-rescaler';
import DeskAreaDraggerComponent  from './components/desk-area-dragger';
import DeskResizeFitterComponent from './components/desk-resize-fitter';
import DeskPositionInitComponent from './components/desk-position-init';


const UPDATE_SCALE_GLOBAL_DEBOUNCE = 100;
const DESK_INIT_POSTITION = [0, 0] as Position;


interface Props {
  scale: number;
  imageAddr: ContentTypes.ImageAddr;
  children: React.ReactNode;
};


export const DeskMechanicsComponent: React.FC<Props> = (props: Props) => {
  const { 
    imageAddr,
    children,
  } = props;

  const docState = useDocState();
  const deskAreaRef = useRef<HTMLDivElement>(null);

  const [scale, setScale] = useState(props.scale);
  const [position, setPosition] = useState<Position>(DESK_INIT_POSTITION);

  const {
    setEditorImageSession
  } = useEditorStatesSetters();

  const updateScaleGlobal = useDebouncedCallback((scaleNew: number) => {
    docState.editorImageSession.setScale(scaleNew);
    setEditorImageSession();
  }, UPDATE_SCALE_GLOBAL_DEBOUNCE);

  const handleScale = (scaleNew: number) => {
    setScale(scaleNew);
    updateScaleGlobal(scaleNew);;
  }

  const handlePosition = (positionNew: Position) => {
    setPosition(positionNew);
  }

  const handlePositionFunctional = (
    setPositionFn: (prev: Position) => Position
  ) => {
    setPosition(setPositionFn);
  }

  return (
    <DeskAreaDraggerComponent
      position={position}
      onPositionChange={handlePosition}
    >
      <DeskAreaComponent ref={deskAreaRef} >
        <DeskPositionInitComponent
          deskAreaRef={deskAreaRef}
          imageAddr={imageAddr}
          scale={scale}
          position={position}
          onPositionChange={handlePosition}
        >
          <DeskResizeFitterComponent
            deskAreaRef={deskAreaRef}
            onPositionChange={handlePositionFunctional}
          >
            <DeskRescalerComponent
              deskAreaRef={deskAreaRef}

              scale={scale}
              onScaleChange={handleScale}
              position={position}
              onPositionChange={handlePosition}
            >
              <DeskContentComponent
                scale={scale}
                position={position}
              >
                { children }
              </DeskContentComponent>
            </DeskRescalerComponent>
          </DeskResizeFitterComponent>
        </DeskPositionInitComponent>
      </DeskAreaComponent>
    </DeskAreaDraggerComponent>

  );
};
