import styled from "styled-components";


const TextWrapper = styled.div`
  flex-grow: 1;
  max-width: 180px;
  /* background-color: red; */
`;


export default TextWrapper;
