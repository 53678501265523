import React from 'react';

import { useDocState }      from 'app/ui/contexts/document';
import useSelectedReset     from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import usePageRowSelectedSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-rows/row/use-page-row-selected-set';
import { HeaderCellProps } from '../types';

import { MainWrapper } from './styles';


interface Props extends HeaderCellProps {
}


export const HeaderCellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    pageRowAddr,
    children,
  } = props;

  const document = useDocState();
  const resetAllSelected = useSelectedReset();
  const setRowSelected = usePageRowSelectedSet();
  
  const handleSelectColumn = (event: React.MouseEvent) => {
    event.stopPropagation();

    const isSelected = document.customRowsRowsSelected.isSelected(pageRowAddr);
    if ( isSelected ) {
      return;
    }

    resetAllSelected();
    setRowSelected({pageRowAddr});
  }

  return (
    <MainWrapper onPointerDown={handleSelectColumn}>
      { children }
    </MainWrapper>
  );
}
  
