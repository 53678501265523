export const name = 'PureCode.it';

export const phoneRaw = '+48600388795';
export const phonePretty = '+48.600.388.795';


export const email = {
  legal: {
    to: 'legal@flexedit.app',
  },
  support: {
    to: 'contact@flexedit.app',
    subject: 'Customer Support Request',
  }
}


export const address = {
  line1: 'ul. Zaleskiego 2D/10',
  postCode: '70-495',
  city: 'Szczecin',
  country: 'Poland'
}


export const twitter = 'https://x.com/FlexEdit_app';


export const whatsapp = {
  urlWeb: 'https://web.whatsapp.com/send?phone=',
  urlApp: 'https://wa.me'
};

