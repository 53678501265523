import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import useBrowserSafariVersion from 'app/ui-v2/app/hooks/use-browser-safari-version';
import BrowserUnsupportedComponent from './browser-unsupported';
import { BrowserSupport } from './types';


const SAFARI_SUPPORTED_VERSION = 17;


interface Props {
  children: React.ReactNode;
}


export const LoaderBrowserSupportCheckComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const safariVerison = useBrowserSafariVersion();
  const [browserSupported, setBrowserSupported] = useState(BrowserSupport.INIT);

  const isBrowserSupported = (browserSupported === BrowserSupport.SUPPORTED);
  const isBrowserUnsupported = (
    ! [
      BrowserSupport.INIT, 
      BrowserSupport.SUPPORTED
    ].includes(browserSupported)
  );

  useEffect(() => {
    if (safariVerison === null) {
      setBrowserSupported(BrowserSupport.SUPPORTED);
      return;
    }

    const safariReleaseMain = safariVerison[0];
    if (safariReleaseMain < SAFARI_SUPPORTED_VERSION) {
      setBrowserSupported(BrowserSupport.UNSUPPORTED_SAFARI);
      return;
    }

    setBrowserSupported(BrowserSupport.SUPPORTED);
  }, []);


  return (
    <>
      { 
        isBrowserSupported && 
        children 
      }

      { 
        isBrowserUnsupported && 
        <BrowserUnsupportedComponent browserUnsupported={browserSupported} /> 
      }
    </>
  );
}

