import React, { useEffect, useRef } from 'react';
import { useAuth } from 'services/auth';
import useUserGuestLogin from 'app/ui-v2/app/hooks/use-user-guest-login';
import { useProgressModal } from 'lego-v2/progress-modal';
import LoadingViewComponent from './loading-view';


interface Props {
  onDone: () => void;
}


export const RedirectRunDemoBaseComponent: React.FC<Props> = (props: Props) => {
  const {
    onDone,
  } = props;

  const doneRef = useRef(false);
  const loginGuest = useUserGuestLogin();
  const auth = useAuth();

  const {
    showProgressModal,
    hideProgressModal
  } = useProgressModal();

  useEffect(() => {
    showProgressModal();
  }, []);

  useEffect(() => {
    if ( auth.isLogged() ) {
      return;
    }

    loginGuest();
  }, []);

  useEffect(() => {
    if (doneRef.current) {
      return;
    }

    if ( ! auth.isLogged() ) {
      return;
    }

    onDone();
    doneRef.current = true;
  }, [auth.isLogged()]);


  return <LoadingViewComponent />;
}
