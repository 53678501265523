import React, { useEffect, useState } from 'react';

import SelectOptionComponent from './select-option';
import SelectButtonComponent from './select-button';
import { SelectConfigType, SelectOptionType } from './config';

import { MainWrapper, PanelAnchor, SelectPanelTester } from './styles';
import { SelectPanel } from './styles';
import VirtualProbeComponent from '../virtual-probe';


interface Props {
  config: SelectConfigType
  closeRef?: any;
  SelectButton?: any;
}


export const SelectComponent: React.FC<Props> = (props: Props) => {
  const { config, closeRef, SelectButton } = props;
  const [panelVisible, setPanelVisible] = useState(false);
  const [panelHeight, setPanelHeight] = useState(0);
  
  useEffect( () => {
    if ( closeRef === undefined ) return;

    closeRef.current = () => {
      setPanelVisible(false);
    };

    return () => {
      if ( closeRef === undefined ) return;
      closeRef.current = null;
    }
  });

  const togglePanelVisible = () => {
    setPanelVisible(! panelVisible);
  }
  
  const handleOptionSelect = (option: SelectOptionType) => {
    setPanelVisible(false);
    config.onSelected(option.value);
  }

  const renderOptions = () => {
    return config.options.map( (option: SelectOptionType, idx: number) => {
      return (
        <SelectOptionComponent
          key={`${idx}-${option}`}
          option={option}
          onSelected={handleOptionSelect}
        />
      )
    });
  }

  const getSelectedValue = () => {
    const optionSelected = config.options.find(option => option.selected);
    return optionSelected?.value;
  }

  const onBBoxChange = (bbox: DOMRect) => {
    setPanelHeight(bbox.height);
  }


  return (
    <>
    {
      panelHeight !== 0 &&
      <MainWrapper>
        <SelectButtonComponent
          expanded={panelVisible}
          onClick={togglePanelVisible}
          SelectButton={SelectButton}
        >
          { getSelectedValue() }
        </SelectButtonComponent>

        <PanelAnchor $visible={panelVisible}>
          <SelectPanel 
            $visible={panelVisible}
            $height={panelHeight}
          >
          { renderOptions() }
          </SelectPanel>
        </PanelAnchor>
      </MainWrapper>
    }

    {
      panelHeight === 0 &&
      <VirtualProbeComponent
        onBBoxChange={onBBoxChange}
      >
        <SelectPanelTester>
          { renderOptions() }
        </SelectPanelTester>
      </VirtualProbeComponent>
    }
    </>
  );
}
  
