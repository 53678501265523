import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Size } from 'app/arch/types';
import useDocumentScaleSet from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-set';
import { PAGES_PADDING } from '../../pages/styles';
import { MainWrapper } from './styles';


const PADDING_WIDTH = 25;
const PADDING_HEIGHT = PAGES_PADDING;


interface Props {
  pageSize: Size;
  children: React.ReactNode;
}


export const DeskScaleInitComponent: React.FC<Props> = (props: Props) => {
  const {
    pageSize,
    children,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const setScale = useDocumentScaleSet();

  useEffect(() => {
    const element = wrapperRef.current;
    if ( ! element) {
      return;
    }

    // Adjust scale so both are shown:
    // - at least whole page width + padding
    // - at least half page height: padding
    const bbox = element.getBoundingClientRect();
    const { 
      width: contentWidth, 
      height: contentHeight 
    } = bbox;

    const scaleWidth  = contentWidth / (pageSize[0] + 2 * PADDING_WIDTH);
    const scaleHeight = contentHeight / (pageSize[1] / 2 + 1 * PADDING_HEIGHT);

    const scale = Math.min(scaleWidth, scaleHeight);
    setScale(scale);
  }, []);

  return (
    <MainWrapper ref={wrapperRef}>
      { children }
    </MainWrapper>
  );
}

