import styled from "styled-components";


export const MainWrapper = styled.div`
  @media print {
    display: none;
  }

  position: fixed;
  z-index: 500010;
  bottom: 10px;
  right: 10px;

  display: flex;
  flex-direction: row;
  gap: 3px;
`;


export const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #aaa;
  cursor: pointer;
`;
