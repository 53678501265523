import styled from "styled-components";
import ButtonToolbar from "lego/styles/button_toolbar";


export const Button = styled(ButtonToolbar)`
  max-width: unset;
  min-width: unset;
  max-height: unset;
  min-height: unset;

  padding: ${props => props.theme.defs.padding.large};;
  height: auto;
  width:  auto;
`;
