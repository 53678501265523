import styled from "styled-components";
import PanelBase from "lego/styles/panel";


export const Panel = styled(PanelBase)`
  padding: 0px;
  overflow: clip;
  
  @media (max-width: 319px) {
    width: 250px;
  }

  @media (min-width: 320px) and (max-width: 359px) {
    width: 270px;
  }

  @media (min-width: 360px) and (max-width: 389px) {
    width: 310px;
  }

  @media (min-width: 390px) and (max-width: 499px) {
    width: 340px;
  }

  @media (min-width: 500px) and (max-width: 599px) {
    width: 450px;
  }

  @media (min-width: 600px) {
    width: 550px;
  }
`;


export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  background-color: ${props => props.theme.defs.colors.background.secondary};
`;

export const Title = styled.div`
  color: ${props => props.theme.defs.colors.color.secondary};
  flex: 1 1 auto;
  
  font-size:   ${props => props.theme.defs.font.size.xxxlarge};
  font-weight: ${props => props.theme.defs.font.weight.medium};

  border-style: none;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.defs.colors.border.primary};


  padding:
    ${props => props.theme.defs.padding.large} 
    ${props => props.theme.defs.padding.large} 
    ${props => props.theme.defs.padding.large} 
    ${props => props.theme.defs.padding.large}
  ;
`;

export const IconWrapper = styled.div`
  flex: 0 0 auto;
  padding-right: ${props => props.theme.defs.padding.normal};
`;

export const Content = styled.div`
  padding: ${props => props.theme.defs.padding.large};
`;