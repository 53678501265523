import { Props as NavbarProps } from '../../navbar/Navbar';
import HLS_NavbarComponent from '../../hls/navbar';
import NavbarItemComponent from '../navbar-item';


interface Props extends NavbarProps {
}


export const NavbarComponent: React.FC<Props> = (props: Props) => {
  const { 
    config,
  } = props;


  return (
    <HLS_NavbarComponent
      config={config}
      NavbarItem={NavbarItemComponent}
    />
  );
}
