import produce from 'immer';

import jtl from 'tools/jtl';
import { Size }        from 'app/arch/types';
import { SideToolbar } from 'app/arch/editor-image/types';
import { WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";
import { WidgetStylesLibraryType } from 'app/arch/types/types-v2';

import { OrientedSmartLinesRaw } from 'tools/smart-lines/types';
import {  StickyLinesRaw }       from 'tools/smart-lines/types';

import { ContentTypes } from '../../persistent/content';

import * as Types from './types';
import * as Tools from './tools';
import * as State from './state';



export class EditorImageSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  reset() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }


  /**
   * Image
   */
  setImageEdited(imageAddr: ContentTypes.ImageAddr | null) {
    const nextState = State.setImageEdited(this._state, imageAddr);
    this.processNewState(nextState);
  }


  /**
   * Widget(s)
   */
  setWidgetSelected(widgetAddr: ContentTypes.WidgetAddr | null) {
    const logger = Tools.getLogger();
    logger.debug(`Set widget selected: ${jtl.object.hash(widgetAddr)}`);

    this._state = produce(this._state, draft => {
      draft.widget.selected = widgetAddr;
    }); 
  }

  setWidgetPartSelected(part: ContentTypes.WidgetPart | null) {
    this._state = produce(this._state, draft => {
      draft.widget.selectedPart = part;
    });
  }

  setWidgetEdited(widgetAddr: ContentTypes.WidgetAddr | null) {
    this._state = produce(this._state, draft => {
      const logger = Tools.getLogger();
      logger.debug(`Set widget edited: ${jtl.object.hash(widgetAddr)}`);
      draft.widget.edited = widgetAddr;
    });
  }

  // TODO
  setWidgetResizingSize(size: Size | null) {
    this._state = produce(this._state, draft => {
      draft.widget.resizingSize = size;
    });
  }

  /**
   * Window
   */
  updateWindow(update: Types.WindowUpdate) {
    this._state = produce(this._state, draft => {
      Object.assign(draft.window, update);
    });
  }


  /**
   * Smart Lines
   */
  setSmartLinesWidgetsLines(lines: OrientedSmartLinesRaw | null) {
    const nextState = State.setSmartLinesWidgetsLines(this._state, lines);
    this.processNewState(nextState);
  }

  setSmartLinesWidgetsSrcLines(lines: OrientedSmartLinesRaw | null) {
    const nextState = State.setSmartLinesWidgetsSrcLines(this._state, lines);
    this.processNewState(nextState);
  }

  setSmartLinesWidgetsStickyLines(stickyLines: StickyLinesRaw) {
    const nextState = State.setSmartLinesWidgetsStickyLines(this._state, stickyLines);
    this.processNewState(nextState);
  }
  
  unsetSmartLinesWidgetsStickyLines() {
    const nextState = State.unsetSmartLinesWidgetsStickyLines(this._state);
    this.processNewState(nextState);
  }

  updateViewDefinerFitAutomation(update: Types.ViewDefinerAutoFitUpdate) {
    this._state = produce(this._state, draft => {
      const fitAutomation = draft.viewDefiner.autoFit;
      draft.viewDefiner.autoFit = {
        ...fitAutomation,
        ...update
      }
    });
  }

  updateViewDefinerOverlayer(update: Types.ViewOverlayerUpdate) {
    this._state = produce(this._state, draft => {
      const overlayer = draft.viewDefiner.overlayer;
      Object.assign(overlayer, update);
    });
  }

  setSideToolbarPanelType(panelType: SideToolbar.PanelType) {
    const nextState = State.setSideToolbarPanelType(this._state, panelType);
    this.processNewState(nextState);
  }

  setSideToolbarPanelVisible(visible: boolean) {
    const nextState = State.setSideToolbarPanelVisible(this._state, visible);
    this.processNewState(nextState);
  }

  setSideToolbarPanel_stylesLibraryType(widgetStylesLibrary: WidgetStylesLibraryType) {
    const nextState = State.setSideToolbarPanel_stylesLibraryType(this._state, widgetStylesLibrary);
    this.processNewState(nextState);
  }

  setScale(scale: number) {
    const nextState = State.setScale(this._state, scale);
    this.processNewState(nextState);
  }
  
  clipboardWidgetsCopy(widgetsProps: ContentTypes.WidgetProps[]) {
    this._state = produce(this._state, draft => {
      const widgetsPropsCloned = jtl.object.copy(widgetsProps);
      draft.clipboard.widgetsProps = widgetsPropsCloned;
    });
  }

  clipboardWidgetStyleCopy(style: WidgetsStylesTypes.StyleAttrs) {
    const nextState = State.clipboardWidgetStyleCopy(this._state, style);
    this.processNewState(nextState);
  }

  setMultiSelectionSelectedWidgets(widgetsAddrs: ContentTypes.WidgetsAddrs | null) {
    const nextState = State.setMultiSelectionSelectedWidgets(this._state, widgetsAddrs);
    this.processNewState(nextState);
  }

  setMultiSelectionImageAddr(imageAddr: ContentTypes.ImageAddr | null) {
    const nextState = State.setMultiSelectionImageAddr(this._state, imageAddr);
    this.processNewState(nextState);
  }

  
  //-----------------
  // Getters
  //

  /**
   * Image
   */
  getImageEdited() {
    return State.getImageEdited(this._state);
  }

  /**
   * Widget
   */
  getWidgetSelected() {
    return State.getWidgetSelected(this._state);
  }

  isWidgetSelected(widgetAddr: ContentTypes.WidgetAddr) {
    return State.isWidgetSelected(this._state, widgetAddr);
  }

  getWidgetPartSelected(widgetAddr: ContentTypes.WidgetAddr) {
    return State.getWidgetPartSelected(this._state, widgetAddr);
  }

  getWidgetEdited() {
    return State.getWidgetEdited(this._state);
  }

  isWidgetEdited(widgetAddr: ContentTypes.WidgetAddr) {
    return State.isWidgetEdited(this._state, widgetAddr);
  }

  getWindowPosition() {
    return State.getWindowPosition(this._state);
  }

  getWindowSize() {
    return State.getWindowSize(this._state);
  }

  getWindowMaximized() {
    return this._state.window.maximized;
  }

  getClipboardWidgets() {
    return this._state.clipboard.widgetsProps;
  }

  getClipboardWidgetStyle() {
    return this._state.clipboard.widgetStyle;
  }

  getSideToolbarPanelType() {
    return State.getSideToolbarPanelType(this._state);
  }
  
  getSideToolbarPanel_stylesLibraryType() {
    return State.getSideToolbarPanel_stylesLibraryType(this._state);
  }

  getSmartLinesWidgetsLines() {
    return State.getSmartLinesWidgetsLines(this._state);
  }

  getSmartLinesWidgetsStickyLines() {
    return State.getSmartLinesWidgetsStickyLines(this._state);
  }

  getMultiSelectionSelectedWidgets() {
    return State.getMultiSelectionSelectedWidgets(this._state);
  }
  
  getMultiSelectionImageAddr() {
    return State.getMultiSelectionImageAddr(this._state);
  }
  
  getScale() {
    return State.getScale(this._state);
  }

  getViewDefiner() {
    return State.getViewDefiner(this._state);
  }

  private processNewState(newState: State.State) {
    return this._state = newState;
  }
}
