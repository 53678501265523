import React from 'react';
import DocumentComponent from '../../document';

interface Props {
}


export const ViewPrintoutComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <DocumentComponent />
  );
}
  
