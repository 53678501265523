import React from 'react';
import { useDocState }        from 'app/ui/contexts/document';
import { DNDContext }         from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';


interface Props {
  children: React.ReactNode;
}


export const ControlMarkersDropComponent: React.FC<Props> = (props: Props) => {
  const { 
    children 
  } = props;

  const document = useDocState();
  const getDNDData = useDNDDataGet();

  const { 
    setContent,
    setHeaderMarkers, 
  } = useEditorStatesSetters();


  const handleDrop_fromCell = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;

    document.content.cellMarkers_removeMarker(markerAddrDropped);

    document.saveUndo();
    setContent();
  }

  const handleDrop_fromDocHeader = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;

    document.headerMarkers.removeMarker(markerAddrDropped);

    document.saveUndo();
    setHeaderMarkers();
  }


  const contextsDefs: ContextDef[] = [
    {
      dndContext: DNDContext.marker.cell,
      onDrop: handleDrop_fromCell,
    },
    {
      dndContext: DNDContext.marker.docHeader,
      onDrop: handleDrop_fromDocHeader,
    },
  ];

  return (
    <DNDDropComponent contextsDefs={contextsDefs} >
      { children }
    </DNDDropComponent>
  );
}
