import styled from "styled-components";


export const Panel = styled.div`
  background:    ${props => props.theme.panel.background};
  padding:       ${props => props.theme.panel.padding};
  
  border-style:  ${props => props.theme.panel.border.style};
  border-width:  ${props => props.theme.panel.border.width};
  border-radius: ${props => props.theme.panel.border.radius};
  border-color:  ${props => props.theme.panel.border.color};

  box-shadow:    ${props => props.theme.panel.boxShadow};
`;


export default Panel;