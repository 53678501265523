import environment from 'app/environment';
import { DebugPointTypes } from 'app/arch/app/debug';
import useDebugPoint from './use-debug-point';
import useDebugStateSetters from './use-debug-state-setters';


const useDebugPointSet = () => {
  const debugPoint = useDebugPoint();
  
  const {
    setDebugPoint,
  } = useDebugStateSetters();


  const setPoint = (point: DebugPointTypes.Point) => {
    if ( ! environment.debug ) {
      return;
    }

    debugPoint.setPoint(point);
    setDebugPoint();
  }

  return setPoint;
}


export default useDebugPointSet;