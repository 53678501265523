import React from 'react';

import { Page } from 'app/arch/print/page';
import { PageFormatType } from 'app/arch/types/types';
import { PageMarginType } from 'app/arch/types/types';
import { PageOrientationType } from 'app/arch/types/types';
import usePrintoutViewState from 'app/ui-v2/editor-instruction/views/view-printout/hooks/use-printout-view-state';

import useViewPagesContext from '../../hooks/use-view-pages-context';
import usePageMarginsLocked from '../../../../hooks/page/use-page-margins-locked';

import PageComponent        from './page';
import PageMarginsComponent from './page-margins';

import { MarginPostioner      } from './styles';
import { MarginDetacher       } from './styles';
import { PagesPrintoutWrapper } from './styles';
import { PagesEditorWrapper   } from './styles';
import { PageWrapper          } from './styles';


interface Props {
  pagesDefs: React.ReactNode[],
  pageMargins: PageMarginType;
  pageFormat: PageFormatType;
  pageOrientation: PageOrientationType;
  isPrintout?: boolean;
}


export const PagesComponent: React.FC<Props> = (props: Props) => {
  const { 
    pagesDefs, 
    pageMargins, 
    pageFormat, 
    pageOrientation 
  } = props;
  
  const isPrintout   = props.isPrintout !== undefined ? props.isPrintout :  false;
  const maginsLocked = usePageMarginsLocked();
  const printoutViewState = usePrintoutViewState();
  const viewPagesContext = useViewPagesContext();

  
  const PagesWrapper = (
    isPrintout ?
    PagesPrintoutWrapper :
    PagesEditorWrapper
  );

  const renderPage = (pageDef: any, idx: number) => {
    return (
      <PageWrapper key={idx}>
        {
          ! isPrintout &&
          ! maginsLocked &&
          <MarginPostioner>
            <MarginDetacher>
              <PageMarginsComponent
                pageFormat={pageFormat}
                pageMargins={pageMargins}
                pageOrientation={pageOrientation}
              />
            </MarginDetacher>
          </MarginPostioner>
        }

        <PageComponent 
          pageIdx={idx}
          format={pageFormat}
          margins={pageMargins}
          orientation={pageOrientation}
        >
          { pageDef }
        </PageComponent>
      </PageWrapper>
    );
  }

  const renderPages = () => {
    const pagesComponents = pagesDefs.map((pageDef: any, idx: number) => {
      return renderPage(pageDef, idx);
    });

    printoutViewState.setPagesCount(viewPagesContext.printoutView, pagesComponents.length);
    return pagesComponents;
  }

  const pageSize = Page.getSizePx(pageFormat, pageOrientation);
  
  return (
    <PagesWrapper
      style={{
        width: `${pageSize[0]}px`
      }}
    >
      { renderPages() }
    </PagesWrapper>
  );
}
