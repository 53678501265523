import i18next from 'i18next';
import { LexicalTools } from 'app/arch/tools';
import * as Types from './types';


export const getContent = (): Types.Content  => ({
  columns: getColumns(),
  rows: getRows(),
  cells: getCells(),
});

export const getColumnProps = (): Types.ColumnProps => ({
  width: 100,
});


export const getColumns = (): Types.Columns => ({
  props: [
    getColumnProps(),
    getColumnProps()
  ],
});

export const getRows = (): Types.Rows => ({
  addrs: [],
  props: {},
});

export const getRowProps = (
  rowType: Types.RowType
): Types.RowProps => {

  const defProps = {
    type: rowType,
    name: i18next.t("custom rows, default name"),
    width: 100,

    css: {
      padding: "6px",
    },
    header: {
      css: {
        alignItems: "flex-start",

        fontVariant: "small-caps",
        fontSize: "16px",
        fontWeight: "600",
        justifyContent:  "flex-start",
        padding: "5px"
      }
    }

  }
  return defProps
};



export const getCells = (): Types.Cells => ({
});


/**
 * Cell
 */
export const getCell = (
  cellType: Types.RowType
): Types.CellTypes => {
  type CellCreateFn = () => Types.CellTypes

  const CellCreateFn: {[key in Types.RowType]: CellCreateFn} = {
    [Types.RowType.TEXT ] : __getCellText,
  }

  const cellCreateFn = CellCreateFn[cellType];
  const cell = cellCreateFn();
  return cell;
};

const __getCellText = (): Types.TextCell => ({
  text: LexicalTools.getEditorInitStateSerial(),
});
