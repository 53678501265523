import styled from "styled-components";
import ToolbarHorizontal from "lego/styles/toolbar-horizontal";


export const Toolbar = styled(ToolbarHorizontal)`
  justify-content: flex-end;
  border-top-style: solid;

  padding-right: 32px;
`;
