import DraggerComponent from 'lego-v2/dragger/ui';
import { DraggerUpdate } from 'lego-v2/dragger/arch';

import { LineUpdate } from '../types';

import { LineWrapper } from './styles';
import { StyledLine  } from './styles';

import { DRAGGER_SIZE } from '../styles';
interface Props {
  y: number;
  scale: number;
  lineType: string;

  onUpdate: (update: LineUpdate) => void;
  onUpdateDone: (update: LineUpdate) => void;

  locked: boolean;
}


export const LineHorizontalComponent: React.FC<Props> = (props: Props) => {
  const {
    y, 
    scale,
    lineType,
    onUpdate,
    onUpdateDone,
    locked,
  } = props;

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    onUpdate({[lineType]: update.position[1]});
  }

  const handleUpdateDone = (update: DraggerUpdate) => {
    handleDraggerUpdate(update);
    onUpdateDone({[lineType]: update.position[1]});
  }
  
  // This delta is needed so the limiting line 
  // will be alwyas consistent within the subject
  // of being included or being excluded from the 
  // border. 
  const deltaX = lineType === 'y1' ? 1 : 0;

  return (
    <DraggerComponent
      onUpdate={handleDraggerUpdate}
      onUpdateDone={handleUpdateDone}
      position={[0, y]}
      scale={scale}
      disabled={locked}
      cursor={'ns-resize'}
    >
      <LineWrapper
        style={{
          top: `${y - DRAGGER_SIZE / 2 / scale}px`,
          transform: `scale(${ 1 / scale})`,
          transformOrigin: '0 0',
          pointerEvents: locked ? 'none' : 'all',
          cursor: locked ? 'default' : 'ns-resize',
        }}
      >
        <StyledLine $deltaX={deltaX} />
      </LineWrapper>
    </DraggerComponent>
  );
}
