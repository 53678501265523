import styled from "styled-components";


export const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${props => props.theme.defs.gap.xxsmall};
  color: ${props => props.theme.defs.colors.color.primary};
  opacity: ${props => props.theme.defs.opacity.text};
`;


export const TickWrapper = styled.div`
  position: relative;
  top: 0px;
  /* background: red; */

  display: flex;
  justify-content: flex-end;
  width: 24px;
`;


export const Text = styled.div`
  padding: 0px;
  font-size:   ${props => props.theme.defs.font.size.medium};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;