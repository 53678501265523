import styled from "styled-components";


const PanelHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${props => props.theme.defs.gap.normal};

  padding-left:   calc(${props => props.theme.panel.padding} + ${props => props.theme.menuItem.padding});
  padding-right:  calc(${props => props.theme.panel.padding} + ${props => props.theme.menuItem.padding});

  height: 34px;
  min-height: 34px;

  background:  ${props => props.theme.defs.colors.background.fifth};
  color:       ${props => props.theme.defs.colors.color.forth};
  font-size:   ${props => props.theme.defs.font.size.large};
  font-weight: ${props => props.theme.defs.font.weight.large};
`;


export default PanelHeader;