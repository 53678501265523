import React from 'react';
import UploadPanelComponent from './upload-panel';


interface Props {
}


export const ControlUploadImageComponent: React.FC<Props> = (props: Props) => {
  
  const onImagesUploaded = () => {
    // console.log('Images uploaded');
  }

  return (
    <UploadPanelComponent
      onImagesUploaded={onImagesUploaded}
    />
  );
}
  
