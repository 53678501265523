import { AppState } from "./app/app-state";
import { Repos } from "./repos/repos-state";


export class States {
  private _appState: AppState;
  private _repos: Repos;

  constructor() {
    this._appState = new AppState();
    this._repos = new Repos();
  }

  get appState () { return this._appState; }
  get repos () { return this._repos; }
}