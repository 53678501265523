import Logger from 'libs/debug';

import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';
import PageComponent from "../components/content/page";
import useSlicer from "./use-slicer";
import { SliceRequestTypes } from 'app/arch/editor-instruction/document/states/slicing/content/slice-request';


interface Props {
  onPagesDefined: (
    pages: React.ReactElement[],
    sliceRequest: SliceRequestTypes.SliceRequest,
  ) => void;
}


const usePageContentGenerate = (props: Props) => {
  const {
    onPagesDefined
  } = props;

  const log = Logger.getContentSlicer();
  
  const handleSliced = (
    pages: SlicerTypes.Pages, 
    sliceRequest: SliceRequestTypes.SliceRequest
  ) => {
    log.debug("[ContentSlicer] Handle pages redefs");

    const pagesComps: React.ReactElement[] = pages.map((page, idx) => (
      <PageComponent
        key={idx}
        page={page}
        pageIdx={idx}
      />
    ));

    onPagesDefined(pagesComps, sliceRequest);
  }

  useSlicer({
    onSliced: handleSliced,
  });

}

export default usePageContentGenerate;