import { useApp } from 'app/ui/hooks/app/use-app';


export const useIsPrintout = () => {
  /**
   * IsPrintout - must be stored in app,
   * as from the very begining of application load
   * we need to know if it is a printout - even
   * before we load editor - therefore editor
   * states can't be used to determin if it is a 
   * printout view.
   */
  const app = useApp();
  const isPrintout = app.appState.getIsPrintout();
  
  return isPrintout;
}
