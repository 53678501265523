import styled from "styled-components";
import { TextareaAutosize as TextareaAutosizeBase } from '@mui/base/TextareaAutosize';


// 
// Instead of using transient props, prop could be configured to be
// forward or not within styled component
// 
// export const TextareaAutosize = styled(mui.TextareaAutosize).withConfig({
//   shouldForwardProp: (prop) =>
//     ! ['editDisabled'].includes(prop),
// })<Props>`
// 


export interface Props {
  $editDisabled?: boolean;
}


export const TextareaAutosize = styled(TextareaAutosizeBase)<Props>`
  width: 100%;
  resize: none;
  border: none;
  background: none;

  box-sizing: border-box;
  padding: ${props => props.theme.defs.padding.xsmall};
  font-family:${props => props.theme.defs.font.family.join(', ') };

  outline-width: ${props => props.theme.defs.border.width.large};
  outline-style: none;
  
  &:focus:not(:disabled) {
    border-radius: ${props => props.theme.defs.border.radius.xxsmall};
    outline-color: ${props => props.theme.defs.accentColor.primary};
    outline-style: ${props => props.$editDisabled ? 'none' : 'solid'};
  }

  &:hover {
    border-radius: ${props => props.theme.defs.border.radius.xxsmall};
    outline-color: ${props => props.theme.defs.accentColor.lighter};
    outline-style: ${props => props.$editDisabled ? 'none' : 'solid'};
  }
`;


export default TextareaAutosize;