import produce from 'immer';

import * as Types from './types';
import * as State from './state';

/**
 * This state is used to pass release
 * information from 
 * step 1) frontend to backend
 * step 2) from backend to playwright which is used for pdf generation
 * 
 * This state does not need dynamic changes tracking. 
 * As it is not using recoil (as it is not needed).
 */
export class PrintoutReleaseInfo {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  serialize() {
    const serialState = JSON.stringify(this._state);
    return serialState;
  }

  updateRelease(update: Types.Release) {
    this._state = produce(this._state, draft => {
      draft.release.date = update.date;
      draft.release.revision = update.revision;
    });
  }


  //
  // Getters
  //
  getRelease() {
    return State.getRelease(this._state);
  }

  getDebug() {
    return State.getDebug(this._state);
  }
}
