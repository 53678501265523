import styled from "styled-components";
import PageBase from "../styles/page";
import PageBodyBase from "../styles/page-body";


export const Page = styled(PageBase)`
  background: ${props => props.$background ? props.$background : "white"};

  box-shadow: none;
  border-style: none;
  outline-style: none;
`;


export const PageBody = styled(PageBodyBase)`
`;

