import styled from 'styled-components';


export interface PageProps {
  $width: string;
  $height: string;

  $paddingLeft: string;
  $paddingRight: string;
  $paddingTop: string;
  $paddingBottom: string;

  $background?: string;
}


const Page = styled.div.attrs<PageProps>(props =>({
  style: {
    width:  `${props.$width}`,
    height: `${props.$height}`,

    paddingLeft:   `${props.$paddingLeft}`,
    paddingRight:  `${props.$paddingRight}`,
    paddingTop:    `${props.$paddingTop}`,
    paddingBottom: `${props.$paddingBottom}`,
  }
}))<PageProps>`
  box-sizing: border-box;
`;


export default Page;