import React from 'react';
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import useTextSelectionClear from './use-text-selction-clear';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr;
  children: React.ReactNode;
}

// If there is any text selected in EditorText, and widget stop 
// being edited - selected text remains selected. But it should get
// unselected. That is purpose for this component. 

export const WidgetEditorTextEditFinishedComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
    children,
  } = props;

  const widgetEdited = useRecoilValue(UIState_EditorImageSession.isWidgetEdited(widgetAddr));
  const clearTextSelection = useTextSelectionClear();

  useEffect(() => {
    if (widgetEdited) {
      return;
    }

    clearTextSelection();
  }, [widgetEdited]);


  return children;
}

