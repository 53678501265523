import React from 'react';

import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';

import { CellBaseProps }  from './cells/types';
import CellTextComponent  from './cells/cell-text';
import CellIndexComponent from './cells/cell-index';


interface Props extends CellBaseProps {
}


export const CellRenderComponent: React.FC<Props> = (props: Props) => {
  const document = useDocState();

  const {
    cellAddr,
  } = props;

  const columnProps = document.releaselogs.getColumnProps(cellAddr);

  const CellsComponents: {[key in Types.ColumnType]: React.ElementType} = {
    [Types.ColumnType.INDEX ]: CellIndexComponent,
    [Types.ColumnType.TEXT  ]: CellTextComponent,
  };


  const getCell = () => {
    if ( ! (columnProps.type in CellsComponents) ) {
      const msg = `Don't know how to render cell type: '${columnProps.type}'`;
      throw new Error(msg);
    }

    const Cell = CellsComponents[columnProps.type];
    return Cell;
  }

  const Cell = getCell();

  return (
    <Cell cellAddr={cellAddr} />
  );
}
