import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { PrintoutViewTypes } from 'app/arch/editor-instruction/document/states/printout/printout-view';
import { SliceRequestTypes } from 'app/arch/editor-instruction/document/states/slicing/content/slice-request';
import ViewPagesComponent from '../../../shared/components/view-pages';
import useSelectedReset   from '../../hooks/use-selected-reset';

import TopMenuComponent         from './top-menu';
import SliceSystemComponent     from './slice-system';
import PanelAssetsComponent     from './side-panels/panel-assets';
import PanelPropertiesComponent from './side-panels/panel-properties';


interface Props {
}


export const ContentComponent: React.FC<Props> = (props: Props) => {
  const resetSelected   = useSelectedReset();

  const [pagesContent, setPagesContent] = useState<any[]>([]);

  const sliceRequestRef = useRef<null | SliceRequestTypes.SliceRequest>(null);

  useEffect(() => {
    if (sliceRequestRef.current === null) {
      return;
    }

    sliceRequestRef.current.onAfterRender?.();
    sliceRequestRef.current = null;

  }, [pagesContent]);

  const handlePagesRedefined = (
    pages: React.ReactElement[],
    sliceRequest: SliceRequestTypes.SliceRequest,
  ) => {
    sliceRequestRef.current = sliceRequest;
    sliceRequest.onBeforeRender?.();

    setPagesContent(pages);
  }

  // TODO take me out
  // so for example side menus will not be re-render
  // when we need to rerender release logs sliced
  const renderPagesContent = () => {
    return pagesContent;
  }


  return (
    <>
      <SliceSystemComponent 
        onPagesDefined={handlePagesRedefined}
      />

      <ViewPagesComponent
        printoutView={PrintoutViewTypes.ViewType.CONTENT}

        renderPagesContent={renderPagesContent}
        resetSelectedItems={resetSelected}
        
        TopMenu={TopMenuComponent}
        SideLeftPanel={PanelAssetsComponent}
        SideRightPanel={PanelPropertiesComponent}
      />
    </>
  );
}
