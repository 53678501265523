import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { MainWrapper } from './styles';
import { useDocState } from 'app/ui/contexts/document';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerDataTestComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children
  } = props;

  const docState = useDocState();
  const markerIdx = docState.repoMarkers.getMarkerIdx(markerAddr);
  
  const dataTest = (
    `doc-editor`
    + `__markers-gallery`
    + `__marker-${markerIdx}`
  );

  return (
    <MainWrapper data-test={dataTest}>
      { children }
    </MainWrapper>
  );
}

