import styled from "styled-components";
import Button    from "lego/styles/button";
import InputBase from "lego/styles/input";
import PanelWidgetStyleBase from "../styles/panel-widget-styles";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.xsmall};
`;

export const Input = styled(InputBase)`
  // TODO
  // Othewise Input gets to big and pushs 
  // button out of the screen.
  width: 0px;
  flex-grow: 1;
`;

export const ButtonSave = styled(Button)`
  max-width: 50px;
`;

export const PanelWidgetStyle = styled(PanelWidgetStyleBase)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 62px;
  width: 158px;
  overflow: clip;

  background: ${props => props.theme.defs.colors.background.forth};
`;
