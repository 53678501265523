import React from 'react';
import { PageItemsProps } from '../types';

import RowsComponent from './rows';
import RowsResizersComponent from './rows-resizers';

import { MainWrapper } from './styles';
import { RowsResizersDetacher } from './styles';


interface Props extends PageItemsProps {
}


export const PageItemDocCustomRowsComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <MainWrapper>
      <RowsResizersDetacher>
        <RowsResizersComponent debug={false}/>
      </RowsResizersDetacher>

      <RowsComponent />
    </MainWrapper>
    );
}
