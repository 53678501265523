import styled from "styled-components";
import TooltipBase from '@mui/material/Tooltip';
import { tooltipClasses } from '@mui/material/Tooltip'; 


export const Tooltip = styled(({ 
  className, 
  ...props 
}: any) => (
  <TooltipBase 
    {...props} 
    classes={{ popper: className }} 
  />
))`
  .${tooltipClasses.arrow} {
    color: ${props => props.theme.tooltip.background};
  }

  .${tooltipClasses.tooltip} {
    color:      ${props => props.theme.tooltip.color};
    background: ${props => props.theme.tooltip.background};
  }
`;
