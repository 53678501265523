import styled from "styled-components";


const Center = styled.div`
  position: relative;
  width: 0;
  height: 0;
  /* background: rgba(0, 255, 0, 0.3);   */
`;

export default Center;
