import { useRecoilValue } from 'recoil';
import jtl from 'tools/jtl';
import { PanelControlTextVariant }          from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"

import { settings }            from 'app/configs';
import { Icon }    from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useColumnSelected   from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-column-selected';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { ContentTypes }    from 'app/arch/editor-instruction/document/states/persistent/content';

import useFunctions from "./use-functions";


const useItems = () => {
  const fn = useFunctions();
  const t = useTranslations();

  const selectedColumnAddr = useColumnSelected();
  const columnImages_imageIdx       = useRecoilValue(UIState_Content.columnImages_imagesIdxProps(selectedColumnAddr));
  const columnImages_imageBorderCSS = useRecoilValue(UIState_Content.columnImages_imageBorderCSS(selectedColumnAddr));


  const getHeader_layout = () => (
    {
      title: t('view print, images align horizontal'),
      type: PanelControlsItemSectionItemType.BUTTON_GROUP,
      buttons: [
        {
          Icon: Icon.Align.To.Left.Horizontal,
          tooltip: t("left"),
          value: {justifyContent: 'flex-start'},
          selected: () => fn.columnHeader__checkCSS({
            justifyContent: 'flex-start'
          }),
        },
        {
          Icon: Icon.Align.To.Center.Horizontal,
          tooltip: t("center"),
          value: {justifyContent: 'center'},
          selected: () => fn.columnHeader__checkCSS({
            justifyContent: 'center'
          }),
        },
        {
          Icon: Icon.Align.To.Right.Horizontal,
          tooltip: t("right"),
          value: {justifyContent: 'flex-end'},
          selected: () => fn.columnHeader__checkCSS({
            justifyContent: 'flex-end'
          }),
        },
      ],
      onClick: fn.columnHeader__updateCSS
    }
  );

  const getColumn_padding = () => ({
    title: t('cell padding'),
    type:  PanelControlsItemSectionItemType.SLIDER,
    min:   settings.printView.cell.padding.min,
    max:   settings.printView.cell.padding.max,
    step:  1,
    value: fn.column__getCSSPadding,
    onChange: (value: number) => { 
      fn.column__handleCSSSliderUpdate({padding: `${value}px`});
    },
    onChangeDone: (value: number) => {
      fn.column__handleCSSSliderUpdateDone({padding: `${value}px`});
    }
  });

  const getColumn_flexGap = () => ({
    title: t('images gap'),
    type: PanelControlsItemSectionItemType.SLIDER,
    min:   settings.printView.images.gap.min,
    max:   settings.printView.images.gap.max,
    step:  1,
    value: fn.column__getCSSGap,
    onChange: (value: number) => { 
      fn.column__handleCSSSliderUpdate({gap: `${value}px`});
    },
    onChangeDone: (value: number) => {
      fn.column__handleCSSSliderUpdateDone({gap: `${value}px`});
    }
  });

  const getColumn_flexLayout = () => ({
    title: t('grid or column'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Layout.Rows,
        tooltip: t("grid"),
        value: { flexDirection: 'row' },
        selected: () => fn.column__isDirectionRow(),
      },
      {
        Icon: Icon.Layout.Column,
        tooltip: t("column"),
        value: { flexDirection: 'column' },
        selected: () => fn.column__isDirectionColumn(),
      },
    ],
    onClick: fn.column__handleCSSUpdate
  });

  const getColumn_flexRowConfigHorizontal = (singleItem: boolean = false) => ({
    skip: fn.column__isDirectionColumn,
    title: t('view print, images align horizontal'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Align.To.Left.Horizontal,
        tooltip: t("left"),
        value: {justifyContent: 'flex-start'},
        selected: () => fn.column__checkCSS({
          justifyContent: 'flex-start'
        }),
      },
      {
        Icon: Icon.Align.To.Center.Horizontal,
        tooltip: t("center"),
        value: {justifyContent: 'center'},
        selected: () => fn.column__checkCSS({
          justifyContent: 'center'
        }),
      },
      {
        Icon: Icon.Align.To.Right.Horizontal,
        tooltip: t("right"),
        value: {justifyContent: 'flex-end'},
        selected: () => fn.column__checkCSS({
          justifyContent: 'flex-end'
        })
      },
      {
        skip: singleItem,
        Icon: Icon.Align.SpaceBetween.Horizontal,
        tooltip: t("space evenly"),
        value: {justifyContent: 'space-evenly'},
        selected: () => fn.column__checkCSS({
          justifyContent: 'space-evenly'
        })
      },
    ],
    onClick: fn.column__handleCSSUpdate
  });

  const getColumn_flexRowConfigVertical = () => ({
    skip: fn.column__isDirectionColumn,
    title: t('view print, images align vertical'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Align.To.Top,
        tooltip: t("top"),
        value: {
          alignContent: 'flex-start',
          alignItems:   'flex-start',
        },
        selected: () => fn.column__checkCSS({
          alignContent: 'flex-start',
          alignItems:   'flex-start',
        }),           
      },
      {
        Icon: Icon.Align.To.Center.Vertical,
        tooltip: t("center"),
        value: {
          alignContent: 'center',
          alignItems:   'center',
        },
        selected: () => fn.column__checkCSS({
          alignContent: 'center',
          alignItems:   'center',
        }), 
      },
      {
        Icon: Icon.Align.To.Bottom,
        tooltip: t("bottom"),
        value: {
          alignContent: 'flex-end',
          alignItems:   'flex-end',
        },
        selected: () => fn.column__checkCSS({
          alignContent: 'flex-end',
          alignItems:   'flex-end',
        }),           
      },
    ],
    onClick: fn.column__handleCSSUpdate
  });

  const getColumn_flexColumnConfigHorizontal = () => ({
    skip: fn.column__isDirectionRow,
    title: t('view print, images align horizontal'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Align.To.Left.Horizontal,
        value: {
          alignContent: 'flex-start',
          alignItems:   'flex-start'
        },
        selected: () =>  fn.column__checkCSS({
          alignContent: 'flex-start',
          alignItems:   'flex-start'
        }),            
      },
      {
        Icon: Icon.Align.To.Center.Horizontal,
        value: {
          alignContent: 'center',
          alignItems:   'center',
        },
        selected: () => fn.column__checkCSS({
          alignContent: 'center',
          alignItems:   'center'
        }),            
      },
      {
        Icon: Icon.Align.To.Right.Horizontal,
        value: {
          alignContent: 'flex-end',
          alignItems:   'flex-end'
        }, 
        selected: () => fn.column__checkCSS({
          alignContent: 'flex-end',
          alignItems:   'flex-end'
        }),
      },
    ],
    onClick: fn.column__handleCSSUpdate
  });

  const getColumn_flexColumnConfigVertical = () => ({
    skip: fn.column__isDirectionRow,
    title: t('view print, images align vertical'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Align.To.Top,
        value: {justifyContent: 'flex-start'},
        selected: () => fn.column__checkCSS({
          justifyContent: 'flex-start'
        }),
      },
      {
        Icon: Icon.Align.To.Center.Vertical,
        value: {justifyContent: 'center'},
        selected: () => fn.column__checkCSS({
          justifyContent: 'center'
        }),
      },
      {
        Icon: Icon.Align.To.Bottom,
        value: {justifyContent: 'flex-end'},
        selected: () => fn.column__checkCSS({
          justifyContent: 'flex-end'
        }),
      },
      {
        Icon: Icon.Align.SpaceBetween.Vertical,
        value: {justifyContent: 'space-evenly'},
        selected: () => fn.column__checkCSS({
          justifyContent: 'space-evenly'
        }),
      },
    ],
    onClick: fn.column__handleCSSUpdate
  });

  const getColumn_fontSize = () => ({
    title: t('size'),
    type: PanelControlsItemSectionItemType.SLIDER,
    min:   settings.printView.cell_index.font.size.min,
    max:   settings.printView.cell_index.font.size.max,
    step:  1,
    value: fn.column__getCSSFontSize,
    onChange: (value: number) => { 
      fn.column__handleCSSSliderUpdate({fontSize: `${value}px`});
    },
    onChangeDone: (value: number) => {
      fn.column__handleCSSSliderUpdateDone({fontSize: `${value}px`});
    }
  });

  const getColumn_fontWeight = () => ({
    title: t('font weight'),
    type: PanelControlsItemSectionItemType.SLIDER,
    min:   settings.printView.cell_index.font.weight.min,
    max:   settings.printView.cell_index.font.weight.max,
    step:  1,
    value: fn.column__getCSSFontWeight,
    onChange: (value: number) => { 
      fn.column__handleCSSSliderUpdate({fontWeight: `${value * 100}`});
    },
    onChangeDone: (value: number) => {
      fn.column__handleCSSSliderUpdateDone({fontWeight: `${value * 100}`});
    }
  });


  /**
   * 
   * Column Images
   * 
   */
  const getColumnImages_imageIdxVisible = () => ({
    title: t('visible'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        text: t('off'),
        value: false,
        selected: ! columnImages_imageIdx?.visible,
      },
      {
        text: t('on'),
        value: true,
        selected: columnImages_imageIdx?.visible,
      },
    ],
    onClick: (visible: boolean) => { 
      const update = {
        imageIdx: {visible}
      };
      fn.columnImages_updateProps(update); 
    },
  });

  const getColumnImages_imageIdxPlacement = () => ({
    skip:  ! columnImages_imageIdx?.visible,
    title: t('position'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Corner.TopLeft,
        value: ContentTypes.ImageIdxPlacement.TOP_LEFT,
        selected: columnImages_imageIdx?.placement === ContentTypes.ImageIdxPlacement.TOP_LEFT,
      },
      {
        Icon: Icon.Corner.TopRight,
        value: ContentTypes.ImageIdxPlacement.TOP_RIGHT,
        selected: columnImages_imageIdx?.placement === ContentTypes.ImageIdxPlacement.TOP_RIGHT,
      },
      {
        Icon: Icon.Corner.BottomLeft,
        value: ContentTypes.ImageIdxPlacement.BOTTOM_LEFT,
        selected: columnImages_imageIdx?.placement === ContentTypes.ImageIdxPlacement.BOTTOM_LEFT,
      },
      {
        Icon: Icon.Corner.BottomRight,
        value: ContentTypes.ImageIdxPlacement.BOTTOM_RIGHT,
        selected: columnImages_imageIdx?.placement === ContentTypes.ImageIdxPlacement.BOTTOM_RIGHT,
      },
    ],
    onClick: (placement: ContentTypes.ImageIdxPlacement) => { 
      const update = {
        imageIdx: {placement}
      };
      fn.columnImages_updateProps(update); 
    },
  });


  /**
   * 
   *  Column images / image border
   * 
   */
  const getColumnImages_imageBorderVisible = () => ({
    title: t('border, style'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Border.Style.None,
        value: 'none',
        selected: columnImages_imageBorderCSS?.borderStyle === 'none' 
      },
      // {
      //   Icon: Icon.Border.Style.Dotted,
      //   value: 'dotted',
      //   selected: columnImages_imageBorderCSS?.borderStyle === 'dotted' 
      // },
      // {
      //   Icon: Icon.Border.Style.Dashed,
      //   value: 'dashed',
      //   selected: columnImages_imageBorderCSS?.borderStyle === 'dashed' 
      // },
      {
        Icon: Icon.Border.Style.Solid,
        value: 'solid',
        selected: columnImages_imageBorderCSS?.borderStyle === 'solid' 
      },
    ],
    onClick: (borderStyle: string) => { 
      const update = {
        imageBorder: {
          css: { borderStyle }
        }
      }
      fn.columnImages_updateProps(update);
    },
  });

  const getColumnImages_imageBorderColor = () => ({
    skip: columnImages_imageBorderCSS?.borderStyle === 'none',
    title: t('color'),
    type: PanelControlsItemSectionItemType.COLOR_PICKER,
    color: columnImages_imageBorderCSS?.borderColor,
    onColorSelected: (borderColor: string) => {
      const update = {
        imageBorder: {
          css: { borderColor }
        }
      }
      fn.columnImages_updateProps(update);
    },
  });

  const getColumnImages_imageBorderWidth = () => ({
    skip:  columnImages_imageBorderCSS?.borderStyle === 'none',
    title: t('width'),
    type:  PanelControlsItemSectionItemType.SLIDER,
    min:   settings.printView.cell.padding.min,
    max:   settings.printView.cell.padding.max,
    step:  1,
    value: jtl.css.valueToNumber(columnImages_imageBorderCSS?.borderWidth),
    onChange: (width: number) => {
      const borderWidth = `${width}px`;
      const update = {
        imageBorder: {
          css: { borderWidth }
        }
      }
      fn.columnImages_updateProps(update, true);
    },
    onChangeDone: (width: number) => {
      const borderWidth = `${width}px`;
      const update = {
        imageBorder: {
          css: { borderWidth }
        }
      }
      fn.columnImages_updateProps(update);
    },
  });

  const getColumnImages_imageBorderRadius = () => ({
    skip:  columnImages_imageBorderCSS?.borderStyle === 'none',
    title: t('round corners'),
    type:  PanelControlsItemSectionItemType.SLIDER,
    min:   settings.printView.cell.padding.min,
    max:   settings.printView.cell.padding.max,
    step:  1,
    value: jtl.css.valueToNumber(columnImages_imageBorderCSS?.borderRadius),
    onChange: (radius: number) => {
      const borderRadius = `${radius}px`;
      const update = {
        imageBorder: {
          css: { borderRadius }
        }
      }
      fn.columnImages_updateProps(update, true);
    },
    onChangeDone: (radius: number) => {
      const borderRadius = `${radius}px`;
      const update = {
        imageBorder: {
          css: { borderRadius }
        }
      }
      fn.columnImages_updateProps(update);
    },
  });

  const getColumnImages_alignHeight= () => ({
    title: t('height'),
    type: PanelControlsItemSectionItemType.SLIDER,
    min:   settings.printView.imageScaleTo.min.height,
    max:   settings.printView.imageScaleTo.max.height,
    step:  1,
    value:  fn.getColumnImagesViewScaleHeight,
    onChangeStart: fn.columnImages__handleImagesSetHeightStart,
    onChange: fn.columnImages__handleImagesSetHeightChange,
    onChangeDone: fn.columnImages__handleImagesSetHeightDone,
  });

  const getColumnImages_alignWidth = () => ({
    title: t('width'),
    type: PanelControlsItemSectionItemType.SLIDER,
    min:   settings.printView.imageScaleTo.min.height,
    max:   settings.printView.imageScaleTo.max.height,
    step:  1,
    value:  fn.getColumnImagesViewScaleWidth,
    onChangeStart: fn.columnImages__handleImagesSetWidthStart,
    onChange: fn.columnImages__handleImagesSetWidthChange,
    onChangeDone: fn.columnImages__handleImagesSetWidthDone,
  });


  /**
   * 
   * No Column
   * 
   */
  const getColumnNotSelected = () => ({
    variant: PanelControlTextVariant.WARNING,
    type: PanelControlsItemSectionItemType.TEXT_MULTILINE,
    value:t('view print, select column'),
  });


  //-------------  


  return {
    /**
     * Column Common
     */
    getHeader_layout,


    getColumn_padding,

    getColumn_flexGap,
    getColumn_flexLayout,
    getColumn_flexRowConfigHorizontal,
    getColumn_flexRowConfigVertical,
    getColumn_flexColumnConfigHorizontal,
    getColumn_flexColumnConfigVertical,

    getColumn_fontSize,
    getColumn_fontWeight,

    
    /**
     * Column Images
     */
    getColumnImages_imageIdxVisible,
    getColumnImages_imageIdxPlacement,

    getColumnImages_imageBorderVisible,
    getColumnImages_imageBorderColor,
    getColumnImages_imageBorderWidth,
    getColumnImages_imageBorderRadius,

    getColumnImages_alignHeight,
    getColumnImages_alignWidth,


    /**
     * No Column
     */
    getColumnNotSelected,
  }
}

export default useItems;