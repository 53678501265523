import { useRecoilValue } from "recoil";
import { UIState_ViewsCommon } from "app/ui/states/editor-instruction";


const usePageMargins = () => {
  const pageMargins = useRecoilValue(UIState_ViewsCommon.pageMargins);
  return pageMargins;
}


export default usePageMargins;