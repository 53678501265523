import { atom } from 'recoil';
import { selector } from 'recoil';
import { DebugPointState as State } from 'app/arch/app/debug';


export const state = atom<State.State>({
  key: "debug_point",
  default: State.createInitialState()
});


export const point = selector({
  key: `debug_point_point`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getPoint(stateObj);
  }
});
