import { MsgLevel } from '../__remove-me/msg-box/types';
import useToastsState from 'app/ui-v2/app/__modules/info/components/toasts/hooks/use-toasts-state';


export const useMsgBox = () => {
  const toasts = useToastsState();

  const showMsgBox = (
    msg: string, 
    level_?: MsgLevel,
    duration_?: number
  ) => {
    const level = level_ === undefined ? MsgLevel.INFO : level_;

    toasts.addToast({
      text: msg,
      // duration,
      // duration: ToastDuration.XLONG,
      level: level as any
    });
  }

  return { showMsgBox };
}