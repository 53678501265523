import React from 'react';
import { ViewTypes as GuestViewTypes } from 'app/arch/home-page-guest/states/view';
import { ViewTypes as UserViewTypes } from 'app/arch/home-page-user/states/view';
import useLegalRedirect from '../../hooks/use-legal-redirect';


interface Props {
}


export const RedirectLegalTermsOfServiceComponent: React.FC<Props> = (props: Props) => {
  useLegalRedirect({
    viewGuest: GuestViewTypes.ViewItem.LEGAL_TERMS_OF_SERVICE,
    viewUser: UserViewTypes.ViewItem.LEGAL_TERMS_OF_SERVICE
  });

  return null;
}
