import styled from "styled-components";


export const MainWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
`;


export const Frame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

