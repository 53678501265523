import { ThemeConstsProps } from "../types";


export const themeConsts_boxShadow = (props: ThemeConstsProps) => {
  const { darkMode } = props;

  const boxShadow = {
    primary:   'rgba(0, 0, 0, 0.3) 0px 4px 22px',
    secondary: 'rgba(0, 0, 0, 0.8) 0px 4px 22px',
  };

  return boxShadow;
}
