import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { useDocState } from 'app/ui/contexts/document';
import HLC_MarkerViewComponent from 'app/ui-v2/editor-instruction/hls/markers/marker-view';
import { UIState_HeaderMarkersSession } from 'app/ui/states/editor-instruction';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
}


export const MarkerViewComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr
  } = props;

  const document = useDocState();
  const markerProps = document.headerMarkers.getMarkerProps(markerAddr);
  const isSelected = useRecoilValue(UIState_HeaderMarkersSession.isMarkerSelected(markerAddr.markerId));

  return (
    <HLC_MarkerViewComponent 
      markerAddr={markerProps.repoMarkerAddr} 
      selected={isSelected}
    />
  );
}
  
