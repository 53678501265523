import React from 'react';
import usePageContentGenerate from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-page-content-generate';


interface Props {
  onPagesDefined: (pages: React.ReactElement[]) => void;
}

// This `Slicer` component is really needed
// as usePageContentGenerate - uses internally
// useSlicer. And useSlicer gets often rerender
// due to recoil changes. But even recoil states
// chages then it might happen that PagesDefs 
// will not change - (for example cell will grow,
// but not so much that it will go to next page)
// - in this case putting hook directly into
// content component would result in unnecessary
// rerender. 
// If we we put it here - this will go rerender
// but nothing else.

export const SlicedContentGeneratorComponent: React.FC<Props> = (props: Props) => {
  const {
    onPagesDefined
  } = props;

  usePageContentGenerate({onPagesDefined});
  return null;
}
  
