import React from 'react';

import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import useSelectedReset   from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useCellSelectedSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell/use-cell-selected-set';

import { MainWrapper } from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props;

  const setCellSelected = useCellSelectedSet();
  const resetAllSelected = useSelectedReset();

  const handleSelectCell = (event: React.MouseEvent) => {
    event.stopPropagation(); 

    resetAllSelected();
    setCellSelected({ cellAddr });
  }

  return (
    <MainWrapper onPointerDown={handleSelectCell}>
      { children }
    </MainWrapper>
  );
}
