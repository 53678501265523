import React from 'react';
import { IconContext } from 'react-icons';

import Icons from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useUserGuestLogin from 'app/ui-v2/app/hooks/use-user-guest-login';

import { Button }   from './styles';
import { IconWrap } from './styles';
import { Text }     from './styles';
import { MainWrapper } from './styles';


interface Props {
}


export const BtnGuestRunComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const loginGuest = useUserGuestLogin();

  const handleRunAsGuest = () => {
    loginGuest();
  }

  const IconDemo = Icons.Demo;

  return (
    <MainWrapper>
      <Button
        data-test='app-run-demo'
        onClick={handleRunAsGuest}
      >
        <IconWrap>
          <IconContext.Provider value={{ size: '26px' }} >
            <IconDemo />
          </IconContext.Provider>   
        </IconWrap>

        <Text>
          { t('run app as guest') }
        </Text>

      </Button>
    </MainWrapper>
  );
}
