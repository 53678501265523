import { useSetRecoilState } from "recoil";
import { ToastsTypes } from "app/arch/app/info/toasts";
import useToastsState from "./use-toasts-state";
import { UIInfoToasts } from "../states";


const useToastAdd = () => {
  const toasts = useToastsState();
  const setState = useSetRecoilState(UIInfoToasts.state);

  const addToasts = (props: ToastsTypes.ToastPartialProps) => {
    toasts.addToast(props);
    setState(toasts.state);
  }

  return addToasts;
}


export default useToastAdd;