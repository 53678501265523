import React, { useEffect, useRef } from 'react';
import BtnTextComponent from 'lego-v2/btn-text';
import { BtnWrapper, MenuBarItemPanelDetacher, MenuBarItemPanelPositioner, StyledBtnText } from './styles';
import * as Types from '../../types';
import useMenubarContext from 'lego-v2/menubar/hooks/use-menubar-context';


const CLOSE_DEBOUNCE_DELAY = 50;


interface Props extends Types.MenubarItemProps {
  MenubarItemPanel: React.ElementType;
}


export const MenubarItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    config, 
    open, 

    MenubarItemPanel,
  } = props;

  const {
    openItem,
    closeItem,
    hoverItemOn,
    hoverItemOff,
  } = useMenubarContext();

  const timeoutHandler = useRef<any>(null);

  useEffect(() => {
    return () => {
      cancelClose();
    }
  });
  
  const debouncedClose = () => {
    cancelClose();
    timeoutHandler.current = setTimeout(() => {
      closeItem();
    }, CLOSE_DEBOUNCE_DELAY);
  }

  const cancelClose = () => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = null;
    }
  }

  const handleOpen = (event: any) => {
    openItem();
  };

  const handlePointerEnter = (event: any) => {
    hoverItemOn();
    cancelClose();
  }

  const handlePointerLeave = (event: any) => {
    hoverItemOff();
    debouncedClose();
  }

  const handlePointerEnterPanel = (event: any) => {
    cancelClose();
  }

  const handlePointerLeavePanel = (event: any) => {
    debouncedClose();
  }

  return (
    <>
      { 
        open &&
        <MenuBarItemPanelDetacher>
          <MenuBarItemPanelPositioner
            onPointerEnter={handlePointerEnterPanel}
            onPointerLeave={handlePointerLeavePanel}
          >
          <MenubarItemPanel
            config={config.panel}
          />
          </MenuBarItemPanelPositioner>
        </MenuBarItemPanelDetacher>
      }
      <BtnWrapper
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
      >
        <BtnTextComponent
          dataTest={config.dataTest}
          text={config.title}
          selected={open}
          onClick={handleOpen}
          component={StyledBtnText}
        />
      </BtnWrapper>
    </>
  )
}