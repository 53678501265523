import { useDebouncedCallback } from 'use-debounce';

import { useIsPrintout }      from 'app/ui/components/editor-instruction/use-is-printout';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { PrintoutViewTypes } from 'app/arch/editor-instruction/document/states/printout/printout-view';
import usePrintoutViewState from '../../view-printout/hooks/use-printout-view-state';



export const useSlicerState = (props: {
  view: PrintoutViewTypes.ViewType,
  debounceDelay: number,
}) => {

  const {
    view,
    debounceDelay,
  } = props;

  const printoutViewState = usePrintoutViewState();
  const isPrintout = useIsPrintout();
  
  const { 
    setPrintoutView
  } = useEditorStatesSetters();


  const __setReady = (ready: boolean) => {
    const viewsLoaded = printoutViewState.getViewsLoaded();
    if (ready === viewsLoaded[view]) {
      return;
    }

    printoutViewState.setViewLoaded(view, ready);
    setPrintoutView();
  }

  const setViewReady = useDebouncedCallback(() => {    
    __setReady(true);
  }, debounceDelay);

  const hook = () => {
    setViewReady.cancel();

    __setReady(false);
    setViewReady();
  }

  const hookDummy = () => {
  }

  return (
    isPrintout ? 
    hook :
    hookDummy
  );
}

export default useSlicerState;