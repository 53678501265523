import React from 'react';
import { useRecoilValue } from 'recoil';

import PanelDraggableComponent from 'lego/components/panel-draggable';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import useConfig from './config/use-config';

import { MainWrapper } from './styles';
import { Content }     from './styles';


interface Props {
}

export const ControlWidgetsStackComponent: React.FC<Props> = (props: Props) => {
  const imageAddr  = useRecoilValue(UIState_EditorImageSession.imageEdited);
  
  if (imageAddr === null) {
    return null;
  }

  return (
    <Component imageAddr={imageAddr} />
  );
}



interface ComponentProps {
  imageAddr: ContentTypes.ImageAddr;
}

const Component: React.FC<ComponentProps> = (props: ComponentProps) => {
  const {
    imageAddr
  } = props;

  const getConfig = useConfig(imageAddr);
  
  return (
    <MainWrapper>
      <Content>
        <PanelDraggableComponent 
          config={getConfig()}
        />
      </Content>
    </MainWrapper>
  );
}
