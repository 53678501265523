import * as Defaults from './defaults';
import * as Tools from './tools';
import * as Types from './types';


/**
 * State
 */
export type State = Types.Actions;


/**
 * Actions
 */
export const getActionsAddrs = (
  state: State
): Types.ActionsAddrs => { 
  const actions = __getActions(state);
  return actions.addrs;
}

export const getActionsProps = (
  state: State
): Types.ActionsProps => { 
  const actions = __getActions(state);
  return actions.props;
}

const __getActions = (
  state: State
): Types.Actions => { 
  return state;
}


/**
 * Action
 */
export const hasAction = (
  state: State,
  actionAddr: Types.ActionAddr,
): boolean => { 
  const actionsAddrs = getActionsAddrs(state);
  const idx = actionsAddrs.findIndex((actionAddr_) => Tools.compareActionAddr(actionAddr_, actionAddr));
  return (idx !== -1);
}

export const getActionProps = (
  state: State,
  actionAddr: Types.ActionAddr,
): Types.ActionProps => { 
  const actionsProps = getActionsProps(state);
  const actionKey    = Tools.getActionKey(actionAddr);
  
  const actionProps = actionsProps[actionKey];
  if (actionProps === undefined) {
    const msg = `Action not found`;
    throw new Error(msg);
  }

  return actionProps;
}

export const getActionIdx = (
  state: State, 
  actionAddr: Types.ActionAddr,
): number => {
  const actionsAddrs = getActionsAddrs(state);

  const idx = actionsAddrs.findIndex((actionAddr_) => Tools.compareActionAddr(actionAddr_, actionAddr));
  if (idx === -1) {
    const msg = "Action not found";
    throw new Error(msg);
  }

  return idx;
}

export const getActionState = (
  state: State,
  actionAddr: Types.ActionAddr
): Types.ActionState => {
  const actionProps = getActionProps(state, actionAddr);
  const actionState = actionProps.state;
  return actionState;
}


/**
 * Initial state
 */
export const createInitialState = (): State => {
  const initState: State = Defaults.getActions();
  return initState;
}
