import { LexicalRegistry } from "./lexical-registry";
import { HeaderRowsTools, HeaderRowsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-rows';


export class LexicalRegistryCustomRows extends LexicalRegistry<Types.PageCellAddr> {
  protected getDebugName(): string {
    return "CustomRows";
  }

  protected getId(pageCellAddr: Types.PageCellAddr) {
    const id = HeaderRowsTools.getPageCellKey(pageCellAddr);
    return id;
  }
}
