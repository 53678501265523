import styled from 'styled-components';


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${props => props.theme.defs.gap.small};

  height: 32px;
  box-sizing: border-box;

  border-top-style: solid;
  border-top-width: ${props => props.theme.defs.border.width.normal};
  border-top-color: ${props => props.theme.defs.colors.border.primary};
  
  background: ${props => props.theme.defs.colors.background.primary};
  
  padding: 
    ${props => props.theme.defs.padding.small}
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.small}
    ${props => props.theme.defs.padding.normal}
  ;
  /* background: green; */
`;  
