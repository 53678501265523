import * as Types from './types';


export type State = {
  images: Types.Image[],
};


/**
 * Getters
 */

export const hasImage = (
  state: State, 
  bid: number
): boolean => {
  const imagePresent = state.images.some((image) => image.bid === bid);
  return imagePresent;
}


export const getImage = (
  state: State, 
  bid: number
) => {
  const image = state.images.find(image => image.bid === bid);
  
  if (image === undefined) {
    const msg = 'Image not found';
    throw new Error(msg);
  }

  return image;
}

export const getImageIdx = (
  state: State, 
  bid: number
): number => {
  const image = getImage(state, bid);
  const idx = state.images.indexOf(image);

  if (idx === -1) {
    const msg = 'Image not found';
    throw new Error(msg);
  }

  return idx;
}

export const getImages = (state: State): Types.Image[] => {
  return state.images;
}


//----------------------------
//
// Create initial state
//

export const createInitialState = () => {
  return {
    images: [],
  }
}

