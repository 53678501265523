import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import environment from 'app/environment';
import { SubscriptionsTypes } from 'app/arch/app/user/states/subscriptions';
import useUserStateSetters  from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';
import useUserSubscriptions from 'app/ui-v2/app/__modules/user/hooks/use-user-subscriptions';
import useUserSubscriptionsFetch from 'app/ui-v2/app/__modules/user/hooks/use-user-subscriptions-fetch';

import { LoadersTools }        from '..';
import LoaderAuthBaseComponent from '../base/loader-auth-base';
import { LoaderCoreProps }     from '../base/loader-auth-base/types';


interface Props {
  children: React.ReactNode;
}


export const LoaderUserSubscriptionsComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  if ( ! environment.stripe.enabled) {
    const logger = LoadersTools.getLogger();
    logger.debug("Loader user subscriptions disabled");
    return children;
  }

  return (
    <LoaderAuthBaseComponent
      debugTitle={"user subscriptions"}
      LoaderCoreAuthComponent={LoaderCoreAuth}
      LoaderCoreUnauthComponent={LoaderCoreUnauth}
    >
      { children }
    </LoaderAuthBaseComponent>
  );
}


/**
 * UserSettings Auth
 */
const LoaderCoreAuth: React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const {
    children 
  } = props;

  const logger = LoadersTools.getLogger();

  const [ready, setReady] = useState(false);
  const userSubscriptions = useUserSubscriptions();

  logger.debug(`Loader user subscriptions - ready: ${ready}`);
  
  const {
    setUserSubscriptions,
  } = useUserStateSetters();


  const onDone = (
    subscriptions: SubscriptionsTypes.SubscriptionRaw[]
  ) => {
    logger.debug(`Loader user subscriptions, subscriptions loaded`)

    userSubscriptions.loadSubscriptions(subscriptions);
    setUserSubscriptions();
    setReady(true);
  }

  const onError = (error: string) => {
    const msg = `Can't download user subscriptions, error: ${error}`;
    console.error(msg);
  }

  useUserSubscriptionsFetch({ 
    onDone, 
    onError 
  });

  if ( ! ready ) {
    return null;
  }

  return children;
}
  

/**
 * UserSubscriptions Unauth
 */

const LoaderCoreUnauth:  React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const {
    children 
  } = props;

  const logger = LoadersTools.getLogger();
  logger.debug(`Loader user subscriptions - render, unauth`);

  const userSubscriptions = useUserSubscriptions();

  const {
    setUserSubscriptions,
  } = useUserStateSetters();

  useEffect(() => {
    logger.debug('Reset user subscriptions');
    userSubscriptions.reset();
    setUserSubscriptions();
  }, []);

  return children;
}
