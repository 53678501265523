import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { LoadersTools } from '../..';
import { LoaderCore } from './types';


interface Props {
  LoaderCoreComponent: LoaderCore;
  debugTitle: string;
  children: React.ReactNode;
}


/**
 * This Loader renders LoaderCoreComponent, and
 * once LoaderCoreComponent calls onDone method
 * LoaderCoreComponent will be removed and children
 * will be rendered.
 */
export const LoaderBaseComponent: React.FC<Props> = (props: Props) => {
  const {
    LoaderCoreComponent,
    debugTitle,
    children,
  } = props;

  const logger = LoadersTools.getLogger();

  const renderCountRef = useRef(0);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    renderCountRef.current += 1;
    const count = renderCountRef.current;
    logger.log(`Loader [${debugTitle}], ready: ${ready}, render count: ${count}`);
  });

  const handleDone = () => {
    logger.log(`Loader [${debugTitle}] - done`);
    setReady(true);
  }

  if ( ! ready ) {
    return (
      <LoaderCoreComponent onDone={handleDone} />
    );
  }

  return children;
}
