import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_CustomFieldsColumnsSelected } from 'app/ui/states/editor-instruction';
import { CellProps } from '../../types';

import { MainWrapper } from './styles';
import { Background }  from './styles';


interface Props extends CellProps {
  children: React.ReactNode;
}


export const CellsLineDragOverComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr,
    children,
  } = props;

  const columnDragOver = useRecoilValue(UIState_CustomFieldsColumnsSelected.isDragOver(pageCellAddr));
  const selected = columnDragOver;
 
  return (
    <MainWrapper>
      <Background selected={selected} />
      { children }
    </MainWrapper>
  );
}
  
