import { useSetRecoilState } from "recoil";
import useGoogleOAuth from "./use-google-oauth";
import { UIGoogleOAuth } from "../states";


const useGoogleStateSetters = () => {
  const googleOAuth = useGoogleOAuth();

  const __setGoogleOAuth = useSetRecoilState(UIGoogleOAuth.state);
  const setGoogleOAuth = () => __setGoogleOAuth(googleOAuth.state);


  const setAll = () => {
    setGoogleOAuth();
  }

  return {
    setAll,
    setGoogleOAuth
  }
}


export default useGoogleStateSetters;