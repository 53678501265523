import styled from "styled-components";
import ItemSelecteable from "lego/styles/item-selecteable";


export const MainWrapper = styled(ItemSelecteable)`
  padding: 2px;
  
  &:active:not(:disabled) {
    background: none;
  }
`;
