import { ContentTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useSectionMove = () => {
  const document = useDocState();
  const {
    setContent,
  } = useEditorStatesSetters();

  const moveSection = (props: {
    srcSection: Types.SectionAddr,
    dstSection: Types.SectionAddr
  }) => {
    const {
      srcSection,
      dstSection
    } = props;

    document.content.moveSection(srcSection, dstSection);
    document.saveUndo();
    setContent();
  }

  return moveSection;
}


export default useSectionMove;