import React from 'react';
import ToastsComponent  from './components/toasts';
import ActionsComponent from './components/actions';
import { MainWrapper } from './styles';


interface Props {
}


export const InfoComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;


  return (
    <MainWrapper>
      <ToastsComponent />
      <ActionsComponent />
    </MainWrapper>
  );
}

