import React from 'react';
import ToolbarButtonComponent from 'app/ui-v2/editor-logo/components/editor-views/shared/toolbar-button';
import { Button } from './styles';


interface Props {
  Icon: React.ElementType;
  tooltip: string;
  selected: boolean;
  onClick: (event?: React.MouseEvent) => void;

  dataTest?: string;
}


export const SideToolbarButtonComponent: React.FC<Props> = (props: Props) => {
  const { 
    Icon,
    tooltip,
    selected, 
    onClick, 

    dataTest
  } = props;
  

  return (
    <ToolbarButtonComponent
      Icon={Icon}
      Button={Button}

      selected={selected}
      tooltipText={tooltip}
      tooltipPlacement={'left'}

      onClick={onClick}
      dataTest={dataTest}
    />
  );
}
