import React from 'react';
import useUserIsLogged from 'app/ui-v2/app/hooks/use-user-is-logged';
import PricePlanBuyComponent from './price-plan-buy';
import PricePlanSingupComponent from './price-plan-singup';


interface Props {
}


export const PricePlanSelectComponent: React.FC<Props> = (props: Props) => {
  const isUser = useUserIsLogged();


  return (
    <>
      { isUser && <PricePlanBuyComponent /> }
      { ! isUser && <PricePlanSingupComponent /> }
    </>
  );
}

