import styled from "styled-components";
import { FaFaceFrown } from "react-icons/fa6";


export const TextWrapper = styled.div`
  display: flex;
  gap: ${props => props.theme.defs.gap.large};
  align-items: center;
`;


export const Text = styled.div`
  /* text-align: center; */
  /* background-color: red; */
  align-self: center;

  color: ${props => props.theme.defs.colors.color.primary};
  opacity: ${props => props.theme.defs.opacity.text};
  font-size: ${props => props.theme.defs.font.size.large};

  max-width: 520px;
`;


const ICON_SIZE = 48;

export const Icon = styled(FaFaceFrown)`
  color: yellow;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;

  min-width: ${ICON_SIZE}px;
  min-height: ${ICON_SIZE}px;

  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 2px;
`;