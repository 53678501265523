import { useTranslations } from "app/ui/hooks/app/use-translation";
import { useDialogCustom } from "../dialog-custom/use-dialog-custom";


type OnOK = () => void;


interface HookProps {
  title: string;
  message: string;
  onOK?: OnOK;
  dataTest?: string;
}


export const useDialogOk = () => {
  const {
    show: showCustom,
    hide: hideCustom
  } = useDialogCustom();

  const t = useTranslations();

  const showDialog = ({
    title,
    message,
    onOK,
    ...props
  }: HookProps) => {

    const dataTest = props.dataTest || 'dialog-ok';

    showCustom({
      title,
      message,
      buttons: [
        {
          text: t("ok"),
          dataTest: `${dataTest}__btn-ok`,
          onClick: () => {
            onOK?.();
          }
        },
      ]        
    });
  }

  return showDialog;
}
