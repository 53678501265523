import * as docx from "docx";

import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import AssetsRepo from "../../parts/assets-repo";
import { DocStateTypes } from "app/arch/editor-instruction/document/states";
import HeaderInfoExporter from "../common/header-info-exporter";
import CustomRowsExporter from "./custom-rows-exporter";
import CustomFieldsExporter from "./custom-fields-exporter";
import HeaderMarkersExporter from "./header-markers-exporter";
import DocContentExporter from "./doc-content-exporter";


class ViewContentExporter {
  private _docState: DocState;
  private _assetsRepo: AssetsRepo;

  constructor(
    docState: DocState,
    assetsRepo: AssetsRepo,
  ) {
    this._docState = docState;
    this._assetsRepo = assetsRepo;
  }

  get content() { return this._docState.content; }
  get assetsRepo() { return this._assetsRepo; }

  getSections(): (docx.Table | docx.Paragraph)[] {
    const sections = [
      ...this._getHeaderInfo(),
      ...this._getHeaderRows(),
      ...this._getHeaderFields(),
      ...this._getHeaderMarkers(),
      ...this._getContent(),
    ];
    
    return sections;
  }

  private _getHeaderInfo() {
    const visibleType = this._docState.contentView.getHeader().meta.visible;
    const visible = (visibleType !==  DocStateTypes.PageItemVisibility.NONE);
    if ( ! visible ) {
      return [];
    }

    const docHeaderInfo = new HeaderInfoExporter(this._docState, this.assetsRepo);
    const headerInfo = docHeaderInfo.createSections();

    const sections = [
      ...headerInfo,
      new docx.Paragraph(""),
    ];

    return sections;
  }

  private _getHeaderRows() {
    const visibleType = this._docState.contentView.getHeader().internal.visible;
    const visible = (visibleType !==  DocStateTypes.PageItemVisibility.NONE);
    if ( ! visible ) {
      return [];
    }

    const docCustomRows = new CustomRowsExporter(this._docState, this.assetsRepo);
    const customRows = docCustomRows.createSections();

    const sections = [
      ...customRows,
      new docx.Paragraph(""),
    ];

    return sections;
  }

  private _getHeaderFields() {
    const visibleType = this._docState.contentView.getHeader().fields.visible;
    const visible = (visibleType !==  DocStateTypes.PageItemVisibility.NONE);
    if ( ! visible ) {
      return [];
    }

    const docCustomFields = new CustomFieldsExporter(this._docState, this.assetsRepo);
    const customFields = docCustomFields.createSections();

    const sections = [
      ...customFields,
      new docx.Paragraph(""),
    ];

    return sections;
  }

  private _getHeaderMarkers() {
    const visibleType = this._docState.contentView.getHeader().markers.visible;
    const visible = (visibleType !==  DocStateTypes.PageItemVisibility.NONE);
    if ( ! visible ) {
      return [];
    }

    const docHeaderMarkers = new HeaderMarkersExporter(this._docState, this.assetsRepo);
    const headerMarkers = docHeaderMarkers.createSections();

    const sections = [
      ...headerMarkers,
      new docx.Paragraph(""),
    ];

    return sections;
  }

  private _getContent() {
    const docContent = new DocContentExporter(this._docState, this.assetsRepo);
    const content = docContent.createSections();

    const sections = [
      ...content,
    ];

    return sections;
  }
}


export default ViewContentExporter;