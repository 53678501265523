import React         from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useThrottledCallback } from 'use-debounce';

import DraggerComponent      from 'lego-v2/dragger/ui';
import { DraggerUpdate }     from 'lego-v2/dragger/arch';
import VirtualProbeComponent from 'lego/components/virtual-probe';
import { RateControl }       from 'lego/types';
import { RateControlType }   from 'lego/types';

import { settings } from 'app/configs';

import { DOT_SIZE } from './styles';
import { DraggerWrapper } from './styles';
import { LineCtrl } from './styles';
import { Dot } from './styles';
import { Line } from './styles';
import { LineLeft } from './styles';
import { MainWrapper } from './styles';
import { Dragger } from './styles';
import { ProbeWrapper } from './styles';


interface Props {
  min?: number,
  max?: number,
  value: number | null,
  step?: number,

  onChangeStart?: () => void,
  onChange?: (value: number) => void,
  onChangeDone?: (value: number) => void,

  rateControl?: RateControl,
  disabled?: boolean,
}


export const SliderRawComponent: React.FC<Props> = (props: Props) => {
  const { 
    value, 
    onChangeStart, 
    onChange, 
    onChangeDone,
  } = props;

  const step = props.step !== undefined ? props.step : 1;
  const disabled = props.disabled ? true : false;

  const rateControlInterval = props.rateControl?.interval ?? settings.rateControl.slider.interval;
  const rateControlType     = props.rateControl?.controlType ?? RateControlType.THROTTLED;


  const [position, setPosition] = useState<number>(0);
  const [sliderWidth, setSliderWidth] = useState(0);

  const minValue = props.min !== undefined ? props.min : 0;
  const maxValue = props.max !== undefined ? props.max : 100;
  const draggingRef = useRef<boolean>(false);

  const update = () => {
    if ( ! sliderWidth ) return;
    if (value === null) return;

    let newLeft = convValueToPosition(value);
    newLeft = checkPositionBoundries(newLeft);
    if (newLeft !== position) {
      setPosition(newLeft);
    }
  }

  useEffect(() => {
    if (draggingRef.current) return;
    update();
  }, [sliderWidth, value]);


  const onChange_debounced = useDebouncedCallback ((value: number) => {
    onChange?.(value);
  }, rateControlInterval);

  const onChange_throttled = useThrottledCallback ((value: number) => {
    onChange?.(value);
  }, rateControlInterval);


  const onChange_controlled = (() => {
    switch (rateControlType) {
      case RateControlType.THROTTLED: return onChange_throttled;
      case RateControlType.DEBOUNCED: return onChange_debounced;
      default: return onChange;
    }
  }) ()!;

  const checkPositionBoundries = (x: number) => {
    const xMax = getMaxX();
    const xMin = 0;

    x = Math.min(x, xMax);
    x = Math.max(x, xMin);

    return x;
  }

  const applyStep = (x: number) => {
    x = x - (x % step);
    return x;
  }

  const getMaxX = () => {
    return sliderWidth - DOT_SIZE;
  }

  const convPositionToValue = (x: number) => {
    const value = x / getMaxX() * (maxValue - minValue) + minValue;
    const valueStepped = applyStep(value);
    return valueStepped;
  }

  const convValueToPosition = (value: number) => {
    const percentage = (value - minValue) / (maxValue - minValue);
    const x = percentage * getMaxX();
    return x;
  }

  const handleDraggerStart = (event: any) => {
    draggingRef.current = true;
    onChangeStart?.();
  }

  const handleBBoxChange = (bbox: DOMRect) => {
    setSliderWidth(bbox.width);
  }

  const updateLocalStates = (update: DraggerUpdate) => {
    const newX = update.position[0];
    const x = checkPositionBoundries(newX);
    setPosition(x);
    return x;
  }


  const handleDraggerUpdate = (update: DraggerUpdate) => {
    const x = updateLocalStates(update);
    const value = convPositionToValue(x);

    onChange_controlled(value);
  }

  const handleDraggerUpdateDone = (update: DraggerUpdate) => {
    draggingRef.current = false;

    if (rateControlType !== RateControlType.NONE) {
      (onChange_controlled as any).cancel();
    }
  
    const x = updateLocalStates(update);
    const value = convPositionToValue(x);
    onChangeDone?.(value);
  }

  const handleLinePointerDown = (event: React.MouseEvent) => {
    const div = event.currentTarget;
    const rect = div.getBoundingClientRect();

    const x = event.clientX - rect.left;
    const value = convPositionToValue(x - DOT_SIZE / 2);

    onChange?.(value);
  }

  return (
    <VirtualProbeComponent 
      Virtual={ProbeWrapper}
      onBBoxChange={handleBBoxChange}
    >
      <MainWrapper>
        <Line 
          $disabled={disabled} 
        />
        <LineLeft 
          $disabled={disabled}
          style={{
            width: `${position}px`
          }}
        />

        <LineCtrl
          $disabled={disabled} 
          onPointerDown={handleLinePointerDown}
        >
          <DraggerWrapper>
            <DraggerComponent
              disabled={disabled}
              component={Dragger}
              position={[position, 0]}

              onUpdateStart={handleDraggerStart}
              onUpdate={handleDraggerUpdate}
              onUpdateDone={handleDraggerUpdateDone}
            >
              <Dot 
                disabled={disabled}
                style={{left: `${position}px`}}
              />

            </DraggerComponent>
          </DraggerWrapper>
        </LineCtrl>

      </MainWrapper>
    </VirtualProbeComponent>
  );
}
