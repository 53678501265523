import { useApp } from 'app/ui/hooks/app/use-app';


const useScreen = () => {
  const app = useApp();
  const screen = app.screen;

  return screen;
}


export default useScreen;