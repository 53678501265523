import React from 'react';
import { IconContext } from 'react-icons';


interface Props {
  children: React.ReactElement;
}


export const IconContextComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;


  const iconContext = { 
    size: `48px`, 
  }

  return (
    <IconContext.Provider value={iconContext} >
      { children }
    </IconContext.Provider>
  );
}

