import * as Company from 'app/configs/company';

import { A } from './styles';
import { Bold } from './styles';
import { Address } from './styles';
import { CompanyInfo } from './styles';
import { CompanyName } from './styles'; 
import { Li } from './styles'; 
import { Ul } from './styles'; 
import { SubSection } from './styles'; 

import { LegalTextTypes as Types } from '../view-legal-base';



/**
 * Title
 */

const title: Types.Title = (
  <>
    Privacy Policy
  </>
);



/**
 * Last Update
 */

const lastUpdate: Types.LastUpdate = (
  <>
   June 13, 2024
  </>
);



/**
 * Sections
 */

const sections: Types.Sections = [
  {
    paragraphs: [
      <>
        {`${Company.name}`} ("Company", "we", "our", "us") is committed to 
        protecting your privacy. This Privacy Policy explains how we collect, 
        use, disclose, and safeguard your information when you use our 
        software-as-a-service (SaaS) platform for creating documents 
        ("Service"). Please read this Privacy Policy carefully. By accessing 
        or using the Service, you agree to be bound by this Privacy Policy.
      </>,
    ]
  },
  

  {
    title: <>Information We Collect</>,
    paragraphs: [
      <>
        <SubSection>Information You Provide</SubSection>
        <Ul>
          <Li>
            <Bold>Account Information:</Bold> When you register for an account, we collect 
            your name, email address, and other contact details you provide.
          </Li>

          <Li>
            <Bold>User Content:</Bold> Any content, such as documents and related 
            materials, that you create, upload, or store on our Service.
          </Li>

          <Li>
            <Bold>Payment Information:</Bold> If you subscribe to our Service, we 
            collect billing information such as credit card details and 
            billing address.
          </Li>
        </Ul>
      </>,
        
      <>
        <SubSection>Information We Collect Automatically</SubSection>
        <Ul>
          <Li>
            <Bold>Usage Data:</Bold> We collect information about your interactions with 
            the Service, including IP address, browser type, operating system, 
            referral URLs, pages viewed, and access times.
          </Li>
        </Ul>
      </>,
    ]
  },


  {
    title: <>Use of Information</>,
    paragraphs: [
      <>
        We use the information we collect for the following purposes:
        <Ul>
          <Li>To provide, operate, and maintain our Service.
          </Li>

          <Li>To process and complete transactions, and send related 
              information, including purchase confirmations and invoices.
          </Li>

          <Li>To improve our Service and develop new features, products, and services.
          </Li>

          <Li>To communicate with you, including responding to your comments, 
              questions, and requests.
          </Li>

          <Li>To send you technical notices, updates, security alerts, and 
              support and administrative messages.
          </Li>

          <Li>To protect, investigate, and deter against fraudulent, 
              unauthorized, or illegal activity.
          </Li>
        </Ul>
      </>,
    ]
  },


  {
    title: <>Disclosure of Information</>,
    paragraphs: [
      <>
        We may disclose your information to third parties in the following circumstances:
        <Ul>
          <Li><Bold>Service Providers:</Bold> We may engage third-party companies and individuals to 
            facilitate our Service, perform Service-related tasks, or assist us in 
            analyzing how our Service is used.
          </Li>

          <Li><Bold>Compliance with Laws:</Bold> We may disclose your information to comply with 
              applicable laws, regulations, legal processes, or governmental requests.
          </Li>

          <Li><Bold>Business Transfers:</Bold> If we are involved in a merger, acquisition, financing 
              due diligence, reorganization, bankruptcy, or sale of assets, your information 
              may be transferred as part of that transaction.
          </Li>
        </Ul>
      </>,
    ]
  },


  {
    title: <>Data Security</>,
    paragraphs: [
      <>
        We take reasonable measures to protect your information from unauthorized 
        access, alteration, disclosure, or destruction. However, no method of 
        transmission over the internet or electronic storage is completely secure, 
        so we cannot guarantee absolute security.
      </>,
    ]
  },


  {
    title: <>Your Choices and Rights</>,
    paragraphs: [
      <>
        <Ul>
          <Li><Bold>Account Information:</Bold> You may update, correct, or delete your account 
              information at any time by logging into your account settings.
          </Li>

          <Li><Bold>Promotional Communications:</Bold> You can opt out of receiving promotional 
              emails from us by following the unsubscribe instructions provided in the emails.
          </Li>
        </Ul>
      </>,
    ]
  },


  {
    title: <>Children's Privacy</>,
    paragraphs: [
      <>
        Our Service is not intended for children under the age of 18. We do not 
        knowingly collect or solicit personal information from anyone under the 
        age of 18. If we learn that we have collected personal information from 
        a child under age 18, we will delete that information as quickly as possible.
      </>,
    ]
  },


  {
    title: <>Changes to This Privacy Policy</>,
    paragraphs: [
      <>
        We may update this Privacy Policy from time to time in order to reflect 
        changes to our practices or for other operational, legal, or regulatory 
        reasons. We will notify you of any changes by posting the new Privacy 
        Policy on this page with a new "Last Updated" date.
      </>,
    ]
  },


  {
    title: <>Contact Us</>,
    paragraphs: [
      <>
        If you have any questions about this Privacy Policy, please contact us at:
        <Ul>
          <Li>Phone: {Company.phonePretty}</Li>
          <Li>Email: <A href={`mailto:${Company.email.legal.to}`}>{Company.email.legal.to}</A></Li>

          <Li> Address
            <CompanyInfo>
              <CompanyName>{Company.name}</CompanyName>
              <Address>
                <div>{Company.address.line1}</div>
                <div>{Company.address.postCode}</div>
                <div>{Company.address.city}</div>
                <div>{Company.address.country}</div>
              </Address>
            </CompanyInfo>
          </Li>
        </Ul>
      </>,
    ]
  }, 
];



/**
 * Endlog
 */

const endlog = (
  <>
    By using the Service, you acknowledge that you 
    have read, understood, and agree to be bound 
    by this Privacy Policy.
  </>
);


export const content = {
  lastUpdate,
  title,
  sections,
  endlog,
}