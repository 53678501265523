import { useRecoilValue } from "recoil";
import { PanelControlsConfig } from "lego/components/panel-controls/config";
import { useTranslations }     from "app/ui/hooks/app/use-translation";
import { UIState_Content } from "app/ui/states/editor-instruction";
import useItems from "./use-items";


const usePanelSectionsConfig = () => {
  const t = useTranslations();

  const sectionsEnabled  = useRecoilValue(UIState_Content.sectionsEnabled);
  const sectionsIndexing = useRecoilValue(UIState_Content.sectionsIndexing);
  const sectionsNamesCSS = useRecoilValue(UIState_Content.sectionsNamesCSS);

  const items = useItems({
    sectionsEnabled,
    sectionsIndexing,
    sectionsNamesCSS,
  });


  const getConfig = (): PanelControlsConfig => ({
    items: [
      {
        title: t("sections"),
        sections: [
          {
            subsections: [
              {
                items: [
                  items.__sections_enable(),
                  items.__sections_indexing(),
                ]
              },
            ],     
          },
          {
            skip: ! sectionsEnabled,
            title: t("section name"),
            subsections: [
              {
                title: t("layout"),
                items: [
                  items.__sectionsNames_padding(),
                  items.__sectionsNames_alignHorizontal(),
                ]
              },
              {
                title: t("text"),
                items: [
                  items.__sectionsNames_fontSize(),
                  items.__sectionsNames_fontWeight(),
                  items.__sectionsNames_fontVariant(),
                ]
              },
            ],     
          },
        ]
      },
    ]
  });


  return getConfig;
}


export default usePanelSectionsConfig;

