import { useDialogCustom } from "../dialog-custom/use-dialog-custom";
import { useTranslations } from "app/ui/hooks/app/use-translation";


export const useDialogYesNo = () => {
  const {
    show: showCustom,
    hide: hideCustom
  } = useDialogCustom();

  const t = useTranslations();

  const show = ({
    title,
    message,
    onNoHandler, 
    onYesHandler,
    noAutoFocus,
    yesAutoFocus,
    dataTest: dataTest_,
  }: any) => {

    const dataTest = dataTest_ || 'dialog-yes-no';

    showCustom({
      title,
      message,
      buttons: [
        {
          autoFocus: yesAutoFocus === true,
          text: t("yes"),
          dataTest: `${dataTest}__btn-yes`,
          onClick: () => {
            onYesHandler();
          }
        },
        {
          autoFocus: noAutoFocus === true,
          text: t("no"),
          dataTest: `${dataTest}__btn-no`,
          onClick: () => {
            onNoHandler();
          }
        },
      ]        
    });
  }

  return {
    show
  }
}
