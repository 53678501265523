import ViewContentComponent   from '../view-content';
import ViewChangelogComponent from '../view-changelog';
import usePrintoutViewLocalStorageSet from './hooks/use-printout-view-localstorage-set';
import PrintoutReadyMarkComponent     from './components/printout-ready-mark';


interface Props {
}


export const ViewPrintoutComponent: React.FC<Props> = (props: Props) => {
  usePrintoutViewLocalStorageSet();
  
  return (
    <>
      <ViewContentComponent />
      <ViewChangelogComponent />
      <PrintoutReadyMarkComponent />
    </>
  );
}
  
