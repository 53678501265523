import styled from "styled-components";
import TitleBase from "../../../../styles/title";


export const MainWrapper = styled.div<{$disabled?: boolean}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${props => props.theme.defs.gap.xlarge};
  opacity: ${props => props.$disabled ? props.theme.defs.opacity.disabled : 1};
  
  box-sizing: border-box;
`;

const TITLE_WIDTH = 80;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 4px;

  min-width: ${TITLE_WIDTH}px;
  max-width: ${TITLE_WIDTH}px;
  
  box-sizing: border-box;
`;

export const Title = styled(TitleBase)`
  font-size:   ${props => props.theme.defs.font.size.normal};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;


export const ItemWrapper = styled.div`
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - ${props => props.theme.defs.gap.xlarge} - ${TITLE_WIDTH}px);
`;
