import React from 'react';
import { IconContext } from 'react-icons';

import { useIsMobile }  from 'lego-hooks/use-is-mobile';
import TooltipComponent from 'lego/components/tooltip';

import Icon from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import * as Company from 'app/configs/company';

import { MainWrapper }    from './styles';
import { ButtonsWrapper } from './styles';
import { Button }         from './styles';


interface BtnDef {
  Icon: React.ElementType;
  tooltip: string;
  onClick: (event: React.MouseEvent) => void;
}


interface Props {
}


export const ContactBarComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;
  
  const t = useTranslations();
  const isMobile = useIsMobile();

  const phoneDef: BtnDef = {
    Icon: Icon.contact.Phone,
    tooltip: t('contact, phone'),
    onClick: (event: React.MouseEvent) => {
      event.stopPropagation();
      window.location.href = 'tel:' + Company.phoneRaw;
    }
  }

  const twitterDef: BtnDef = {
    Icon: Icon.contact.Twitter,
    tooltip: t('contact, twitter'),
    onClick: (event: React.MouseEvent) => {
      event.stopPropagation();
      window.open(Company.twitter, '_blank');
    }
  }

  const emailDef: BtnDef = {
    Icon: Icon.contact.Email,
    tooltip: t('contact, email'),
    onClick: (event: React.MouseEvent) => {
      event.stopPropagation();

      const email = Company.email.support.to;
      const subject = Company.email.support.subject;
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
      window.location.href = mailtoLink;
    }
  }

  const whatsappDef: BtnDef = {
    Icon: Icon.contact.WhatsApp,
    tooltip: t('contact, whatsapp'),
    onClick: (event: React.MouseEvent) => {
      event.stopPropagation();

      const phone = Company.phoneRaw;
      const urlWhatsapp = (
        isMobile ?
        `${Company.whatsapp.urlApp}/${phone}` :
        `${Company.whatsapp.urlWeb}${phone}`
      );

      window.open(urlWhatsapp, '_blank');
    }
  }

  const btnsDefs = [
    emailDef,
    whatsappDef,
    twitterDef,
    phoneDef,
  ];


  const renderBtns = () => {
    const Btns = btnsDefs.map((btnDef, idx) => {
      const {
        Icon,
        tooltip,
        onClick,
      } = btnDef;

      return (
        <TooltipComponent 
          key={idx}
          title={tooltip}
        >
          <Button onClick={onClick}>
            <Icon  style={{borderRadius: '50%'}}/>
          </Button>
        </TooltipComponent>

      );
    });

    return (
      <ButtonsWrapper>
        { Btns }
      </ButtonsWrapper>
    );
  }


  return (
    <MainWrapper>
      <IconContext.Provider value={{size: `38px`}}>
        { renderBtns() }
      </IconContext.Provider>    
    </MainWrapper>
  );
}
