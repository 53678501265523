import React      from 'react';
import { FrameBaseProps } from '../types';


interface Props extends FrameBaseProps {
}


export const FrameDummyComponent: React.FC<Props> = (props: Props) => {
  return null;
}

