import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import { MainWrapper } from './styles';
import { configureHook } from './use-dialog-enter-single-line';
import { useState } from 'react';
import { TextField } from '@mui/material';


export const DialogEnterSingleLineComponent = () => {
  const [open, setOpen] = useState(false);

  const textFieldRef = React.useRef<any | null>(null);

  const onCancelHandler  = React.useRef<any | null>(null);
  const onOKHandler = React.useRef<any | null>(null);

  const [enteredText, setEnteredText] = useState("");

  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  const [inputLabel, setInputLabel] = useState("");


  React.useEffect(() => {
    configureHook({
      showDialog:  ({
        title, msg, inputLabel
      }) => { 
        setOpen(true); 
        setTitle(title);
        setMsg(msg);
        setInputLabel(inputLabel);
      },
      setOnOKHandler: (handler: any) => { 
        onOKHandler.current = handler;
      },
      setOnCancelHandler:  (handler: any) => { 
        onCancelHandler.current = handler;
      }
    });
  });

  const handleClose = () => {
    setOpen(false);
    setEnteredText("");
  };

  const handleOK = () => {
    if (onOKHandler.current !== null && onOKHandler.current !== undefined) {
      onOKHandler.current(enteredText);
    }
    setEnteredText("");
    setOpen(false);
  }

  const handleCancel = () => {
    if (onCancelHandler.current !== null && onCancelHandler.current !== undefined) {
      onCancelHandler.current();
    }
    setEnteredText("");
    setOpen(false);
  }

  const handleTextChange = (e: any) => {
    setEnteredText(e.target.value);
  }

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !isOKDisabled) {
      handleOK();
    }
    e.stopPropagation();
  }

  const isOKDisabled = enteredText.length <= 2

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {msg}
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          ref={textFieldRef}
          onKeyDown={handleKeyDown}
          onChange={handleTextChange}
          margin="dense"
          label={inputLabel}
          variant="standard"
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button 
          onClick={handleOK} 
          autoFocus
          disabled={isOKDisabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
