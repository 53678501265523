import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';
import * as SlicerTools from 'app/arch/editor-instruction/document/slicers/content/tools';
import useContentContext from "../content-context/use-content-context";


const useItemsTools = () => {
  const contentContext = useContentContext();
  const content = contentContext.content !;
  

  const cellsRow_isLastPageItem = (item: SlicerTypes.SectionItem_CellsRow): boolean => {
    const contentItemsCount = content.items.length;
    if (contentItemsCount === 0) {
      const msg = `Invalid content items count: ${contentItemsCount}`;
      console.error(msg);
      return false;
    }

    const lastContentItem = content.items[content.items.length - 1];
    if (lastContentItem.type !== SlicerTypes.ContentItemType.SECTION) {
      return false;
    }

    const section = lastContentItem as SlicerTypes.ContentItem_Section;
    const sectionItemsCount = section.items.length;
    if (sectionItemsCount === 0) {
      return false;
    }

    const lastSectionItem = section.items[sectionItemsCount - 1];
    if (lastSectionItem.type !== SlicerTypes.SectionItemType.CELLS_ROW) {
      return false;
    }

    const sectionLastCellsRow = lastSectionItem as SlicerTypes.SectionItem_CellsRow;
    const isLast = SlicerTools.compareCellsRow(
      sectionLastCellsRow,
      item
    )

    return isLast;
  }

  const sectionAdder_getBorderTopFaded = (): boolean => {
    const sectionAdderIdx = content.items.findIndex((item) => item.type === SlicerTypes.ContentItemType.SECTION_ADDER);
    if (sectionAdderIdx === -1) {
      const msg = `Section adder not found`;
      console.error(msg);
      return false;
    }

    const itemBefore = content.items[sectionAdderIdx - 1];
    const notFaded = [
      SlicerTypes.ContentItemType.HEADER_ROW
    ].includes(itemBefore.type);

    const faded = ! notFaded;
    return faded;
  }

  
  return {
    cellsRow_isLastPageItem,
    sectionAdder_getBorderTopFaded
  }
}

export default useItemsTools;