import { useRecoilValue } from "recoil";

import environment from "app/environment";
import { useInstruction }    from "app/ui/hooks/editor-instruction/use-instruction";
import { UIState_HeaderMeta } from "app/ui/states/editor-instruction";


//---------------------------
// 
// Hook Use Logo URL
// 

export const useLogoUrl = () => {
  const instruction = useInstruction();
  const logoImage   = useRecoilValue(UIState_HeaderMeta.logoImage);

  let logoUrl: string | null = null;

  if ( logoImage !== null ) {
    logoUrl = environment.backend.getInstructionLogoUrl(
      instruction.repoId !,
      instruction.id !,
      logoImage.filename
    );
  }

  return logoUrl;
}
