import React from 'react';
import DeskScaleSliderComponent from './components/desk-scale-slider';
import { Toolbar } from './styles';


interface Props {
}


export const BottomToolbarComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;


  return (
    <Toolbar>
      <DeskScaleSliderComponent />
    </Toolbar>
  );
}

