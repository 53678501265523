import { useRecoilValue } from "recoil";
import { UIModalsRelogin } from "../states";


const useReloginVisibleWatch = () => {
  const visible = useRecoilValue(UIModalsRelogin.visible);
  return visible;
}


export default useReloginVisibleWatch;