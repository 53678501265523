namespace ViewChangelogSettings {
  // TODO redundatnt with ViewContentSettings

  export const slicer = {
    slice: {
      debounce: 50,
      throttle: 100,
    },

    setReady: {
      debounce: 600
    }
  }
}

export default ViewChangelogSettings;