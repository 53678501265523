import React from 'react';
import jtl from 'tools/jtl';
import { Instruction } from 'app/arch/backend/types';

import { DateTimeWrapper } from './styles';
import { Text } from './styles';
import { Title } from './styles';


interface Props {
  instruction: Instruction;
  hover: boolean;
}


export const ItemContentComponent: React.FC<Props> = (props: Props) => {
  const {
    instruction,
    hover
  } = props;

  const dateUpdated = instruction.dateUpdated;
  const dateUpdatedFormatted = jtl.date.toYYYYMMDD(new Date(dateUpdated));
  const [date, time] = dateUpdatedFormatted;
  
  return (
    <>
      <Title>
        { instruction.title }
      </Title>
      {
        ! hover &&
        <DateTimeWrapper>
          <Text>
            { date }
          </Text>
          <Text>
            { time }
          </Text>
        </DateTimeWrapper>
      }
    </>
  );
}

