import React from 'react';
import { MenuSection } from 'lego-v2/menu/config';
import { MenuItem }    from 'lego-v2/menu/config';
import ToolbarButtonComponent from 'app/ui-v2/editor-logo/components/editor-views/shared/toolbar-button';

import { Button } from './styles';
import { Section } from './styles';


interface Props {
  sections: MenuSection[];
}


export const ToolbarSectionsComponent: React.FC<Props> = (props: Props) => {
  const {
    sections,
  } = props;


  const renderItem = (item: MenuItem, firstItem: boolean) => {
    const onClick  = item.data.onClick;
    const Icon     = item.data.iconComponent;
    const tooltip  = item.data.title;
    const disabled = item.disabled;
    
    return (
      <ToolbarButtonComponent
        Icon={Icon}
        Button={Button}
  
        tooltipText={tooltip}
        tooltipPlacement={'bottom'}
  
        disabled={disabled}
        onClick={onClick}
        // dataTest={dataTest}
      />
    );
  }

  const renderSection = (section: MenuSection, sectionIdx: number) => {
    const items = section.items.filter((item) => ! item.skip);
    const compsItems = items.map((item, itemIdx) => {
      const firstItem = (
        itemIdx === 0 && sectionIdx === 0
      );
      
      return (
        <React.Fragment key={`item-${sectionIdx}-${itemIdx}`} >
        { renderItem(item, firstItem) }
        </React.Fragment>
      );
    });

    return compsItems;
  }

  const renderSections = () => {
    const sections_ = sections.filter((section) => ! section.skip);
    const compsSections = sections_.map((section, idx) => (
      <React.Fragment key={`section-${idx}`}>
        <Section $first={idx===0}>
          { renderSection(section, idx) }
        </Section>
      </React.Fragment>
    ));

    return compsSections;
  }

  return renderSections();
}
