import styled from "styled-components";


const MARGIN_WIDTH = 4;

export const Separator = styled.div`
  height: 1px;
  max-height: 1px;
  width: calc(100% - ${MARGIN_WIDTH * 2}px);
  margin: 6px ${MARGIN_WIDTH}px 6px ${MARGIN_WIDTH}px;

  background: ${props => props.theme.defs.colors.separator.primary};
  opacity:    ${props => props.theme.defs.opacity.small};
`;

export default Separator;