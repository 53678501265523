import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import AssetsRepo from "../../../parts/assets-repo";
import ViewProps from '../view-props';
import * as Defaults from '../../defaults';


class ExporterBase {
  protected _docState: DocState;
  protected _assetsRepo: AssetsRepo;
  protected _viewProps: ViewProps;
  
  protected constructor(docState: DocState, assetsRepo: AssetsRepo) {
    this._docState = docState;
    this._assetsRepo = assetsRepo;
    this._viewProps = new ViewProps(docState);
  }

  get content() { return this._docState.content; }

  protected getBordersFull() {
    const borders = {
      top: Defaults.getBorderOn(),
      left: Defaults.getBorderOn(),
      right: Defaults.getBorderOn(),
      bottom: Defaults.getBorderOn(),
    };

    return borders;
  }

  protected getBordersDefault() {
    const borders = {
      top: Defaults.getBorderOn(),
      left: Defaults.getBorderOn(),
      right: Defaults.getBorderOn(),
      bottom: Defaults.getBorderOff(),
    };

    return borders;
  }

  protected getBordersOnSide() {
    const borders = {
      top: Defaults.getBorderOff(),
      left: Defaults.getBorderOn(),
      right: Defaults.getBorderOn(),
      bottom: Defaults.getBorderOff(),
    };

    return borders;
  }

  protected getBordersCell(lastCell?: boolean) {
    const borders = {
      top: Defaults.getBorderOn(),
      left: Defaults.getBorderOn(),
      right: (lastCell ? Defaults.getBorderOn() : Defaults.getBorderOff()),
      bottom: Defaults.getBorderOff(),
    };

    return borders;
  }

  protected getBordersNone() {
    const borders = {
      top: Defaults.getBorderOff(),
      left: Defaults.getBorderOff(),
      right: Defaults.getBorderOff(),
      bottom: Defaults.getBorderOff(),
    };

    return borders;
  }

  protected getHeaderShading() {
    const shading = Defaults.getTextHeaderShading({
      fill: this._viewProps.tableHeader.background,
    });

    return shading;
  }
}


export default ExporterBase;