import { useEffect }   from 'react';
import { useRecoilValue } from 'recoil';
import { UIUser_Settings } from 'app/ui-v2/app/__modules/user/states';

import { useProgressModal } from 'lego-v2/progress-modal';

import DocState            from 'app/arch/editor-instruction/document/states/doc-state';
import { mutation }        from "app/arch/backend";
import { useMutation }     from "app/arch/backend/use-mutation";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import { useDialogEnterSingleLine } from 'app/ui/components/editor-instruction/dialogs/dialog-enter-single-line/use-dialog-enter-single-line';
import useDocTemplates     from 'app/ui-v2/doc-templates/hooks/use-doc-templates';
import useFeatureLimitedInfo from 'app/ui-v2/app/hooks/use-feature-limited-info';

import useDocumentEdit from './use-document-edit';


interface FnProps {
  title?: string;
}


const useDocumentCreate = () => {
  const t = useTranslations();
  const dialog = useDialogEnterSingleLine();
  const repoId = useRecoilValue(UIUser_Settings.repoId);

  const docTemplates = useDocTemplates()
  const editDocument = useDocumentEdit();
  const showFeatureLimited = useFeatureLimitedInfo();
  const {
    showProgressModal,
    hideProgressModal
  } = useProgressModal();

  // 
  // Create document
  //

  const msgs = { 
    onStart: { msg: t("document creating") },
  }

  const { 
    data: response,
    mutation: createInstruction,
  } = useMutation(mutation.createInstruction, msgs);

  useEffect(() => {
    if ( ! response ) {
      return;
    }
    
    const data = response.instructionCreate;
    
    if ( data.featureLimited ) {
      hideProgressModal();
      showFeatureLimited(data.limitType);
      return;
    }

    const instruction = data.instruction;
    editDocument(instruction.id);
  }, [response]);


  const createDocument = (title: string) => {
    if (repoId === null) {
      const msg = `Repo id is null`;
      throw new Error(msg);
    }

    showProgressModal();

    const docState = DocState.CreateInitialState(title);
    const contentDefault = docState.getContentStates();
    const content = docTemplates.applyTemplate(repoId, contentDefault);
    const contentSerial = JSON.stringify(content);

    const variables = {
      content: contentSerial, 
      repoId
    }

    createInstruction({ variables });
  }


  //
  // Whole routine - generate & download
  //
  const hookRoutine = (props: FnProps) => {
    if (props.title !== undefined) {
      createDocument(props.title);
      return;
    }

    dialog.show({
      title: t("new instruction"),
      msg:   t("enter new instruction name"),
      onOKHandler: (enteredText: any) => {
        const title = enteredText;
        createDocument(title);
      },
      onCancelHandler: () => {},
    });
  }

  return hookRoutine;
}


export default useDocumentCreate;
