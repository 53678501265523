import { atom } from 'recoil';
import { selectorFamily } from 'recoil';

import { HeaderRowsTools } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { CustomRowsRowsSelectedState as State } from 'app/arch/editor-instruction/document/states/sessions/custom-rows-rows-selected';


/**
 * Atom
 */

export const state = atom<State.State>({
  key: "ei_docContent_customRowsSelected",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const isSelected = selectorFamily({
  key: "ei_docContent_customRowsSelected__isSelected",
  get: (rowAddr: HeaderRowsTypes.PageRowAddr) => ({ get }) => {
    const stateObj = get(state);
    const rowKey = HeaderRowsTools.getPageRowKey(rowAddr);
    const selected = State.isSelected(stateObj, rowKey);
    return selected;
  }
});

export const isDragOver = selectorFamily({
  key: "ei_docContent_customRowsSelected__isDragOver",
  get: (rowAddr: HeaderRowsTypes.PageRowAddr) => ({ get }) => {
    const stateObj = get(state);
    const rowKey = HeaderRowsTools.getPageRowKey(rowAddr);
    const selected = State.isDragOver(stateObj, rowKey);
    return selected;
  }
});
