import styled from 'styled-components';
import MenubarBase from 'lego-v2/menubar/styles/menubar';


export const MenuWrapper = styled.div`
  position: relative;
`;



export const MenubarWrapper = styled(MenubarBase)`
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
`;