import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useWidgetSelect from 'app/ui-v2/editor-image/hooks/widget/use-widget-select';
import { MainWrapper } from './styles';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr;
  editDisabled: boolean;
  children: React.ReactNode;
}


export const WidgetSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
    editDisabled,
    children,
  } = props;

  const selectWidget = useWidgetSelect();

  const handleSelect = (event: React.PointerEvent) => {
    // Event - do no stop to propagate - as
    // WidgetDragger gets enabled on onPointerDown
    selectWidget({
      widgetAddr,
      editDisabled
    });
  }

  if (editDisabled) {
    return children;
  }

  return (
    <MainWrapper onPointerDown={handleSelect}>
      { children }
    </MainWrapper>
  );
}
  
