import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTools as Tools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState }          from 'app/ui/contexts/document';
import useEditorStatesSetters   from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useColumnsAutoAdjustBase from 'app/ui-v2/editor-instruction/views/shared/hooks/use-columns-auto-adjust';
import useReleaselogColumnsWidthsSignal from './use-releaselog-columns-widths-signal';


const useReleaselogColumnsAutoAdjust = (releaselogAddr:  Types.ReleaselogAddr) => {
  const document = useDocState();

  const { 
    setReleaselogs
  } = useEditorStatesSetters();

  const signalColumnsWidths = useReleaselogColumnsWidthsSignal(releaselogAddr);  
  const columnAutoAdjust = () => {
    Tools.columnsSmartAdjust(document, releaselogAddr);
  }
  
  const hook = useColumnsAutoAdjustBase({
    columnAutoAdjust,
    signalColumnsWidths,
    setRecoilState: setReleaselogs,
  });

  return hook;
}


export default useReleaselogColumnsAutoAdjust;
