import styled from "styled-components";
import ContentItemBorder from "app/ui-v2/editor-instruction/views/shared/styles/content-item-border";


export const GridColumnSpan = styled.div`
  grid-column: 1 / -1;
`;


export const SectionNameBorder = styled(ContentItemBorder)`

`;