import styled from "styled-components";
import ButtonToolbar from "lego/styles/button_toolbar";


export const MainWrapper = styled.div`
  display: flex;
`;


export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.normal};
`;


export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.xxsmall};
`;


export const Button = styled(ButtonToolbar)`
`;
