import { useEffect } from "react";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import DocState from "app/arch/editor-instruction/document/states/doc-state";


const useEditorLeave = (document: DocState) => {
  const t = useTranslations();

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  const handleBeforeUnload = (event: any) => {
    if ( ! document.dirty) {
      return;
    }
    
    const message = t("leaving page msg");
    event.returnValue = message;
    return message;
  }
}

export default useEditorLeave;
