import React, { useState } from 'react';

import jtl from 'tools/jtl';

import ItemPanelGridComponent from 'lego-v2/item-panel-grid';
import { ItemPanelGridTypes } from 'lego-v2/item-panel-grid';

import { DocumentRelease } from 'app/arch/backend/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { MsgLevel } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useMsgBox } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import useDocumentReleaseSend from 'app/ui-v2/app/hooks/use-document-release-send';

import EmailInputComponent from './email-input';

import { ButtonSendPDF } from './styles';
import { TextWrap } from './styles';


interface Props {
  documentId: number;
  release: DocumentRelease;
}


export const ItemPdfSendComponent: React.FC<Props> = (props: Props) => {
  const {
    documentId,
    release,
  } = props;
  
  const t = useTranslations();

  const [email, setEmail] = useState<string>("");
  const [sendingPDF, setSendingPDF] = useState(false);
  const sendDocumentRelease = useDocumentReleaseSend();

  const {
    showMsgBox
  } = useMsgBox();

  const isEmailValid = jtl.email.isValid(email);

  const handleSendPDF = () => {
    if ( ! jtl.email.isValid(email)) {
      showMsgBox(t("invalid email address"), MsgLevel.WARN);
      return;
    }
    setSendingPDF(true);

    const revision = release.revision;
    const recipient = email;
    
    sendDocumentRelease({
      recipient,
      documentId, 
      revision,

      onDone: () => {
        setSendingPDF(false);
        setEmail("");
      },
      onError: (error: string) => {
        console.warn(error);
        setSendingPDF(false);
      },
    });
  }

  const cellsAddrs: ItemPanelGridTypes.Cells = [
    {
      rowIdx: 0,
      columnIdx: 0,
      disabled: sendingPDF,
      Component: (
        <TextWrap>
        { t("doc release send, email") }
        </TextWrap>
      )
    },
    {
      rowIdx: 0,
      columnIdx: 1,
      disabled: sendingPDF,
      Component: (
        <EmailInputComponent
          disabled={sendingPDF}
          email={email}
          onEmailChange={setEmail}
          onSendPDF={handleSendPDF}
        />
      )
    },
    {
      rowIdx: 1,
      columnIdx: 0,
      disabled: sendingPDF,
      Component: (
        <>
        </>
      )
    },
    {
      rowIdx: 1,
      columnIdx: 1,
      disabled: sendingPDF,
      Component: (
        <ButtonSendPDF
          disabled={! isEmailValid || sendingPDF}
          onClick={handleSendPDF}
        >
          <TextWrap>
          { t("doc release send, btn") }
          </TextWrap>
        </ButtonSendPDF>
      )
    }
  ];

  return (
    <ItemPanelGridComponent
      title={t("doc release send, title")}
      rowsCount={2}
      cells={cellsAddrs}
      showProgres={sendingPDF}
      progressTitle={t("doc release send, msgbox, sending")}
    />
  );
}


