import { SmartLineRaw  } from "./types";



export class SmartLine {
  
  static IsHorizontal(line: SmartLineRaw) {
    return line.y0 === line.y1;
  }

  static GetWidth(line: SmartLineRaw) {
    if ( ! SmartLine.IsHorizontal(line)) {
      return 0;
    }
    return line.x1 - line.x0;
  }

  static GetHeight(line: SmartLineRaw) {
    if ( SmartLine.IsHorizontal(line)) {
      return 0;
    }
    return line.y1 - line.y0;
  }
}
