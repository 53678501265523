import styled from 'styled-components';
import PanelForth from 'lego/styles/panel_forth';
import Input from 'lego/styles/input';
import { getScrollbarCSS } from 'lego/styles/scrollbar';
import { StyledBtnIconBase } from "lego-v2/btn-icon";
import TopToolbarButton from 'app/ui-v2/editor-instruction/styles/top-toolbar-button';


export const StyledDropdownBtnBase = styled(StyledBtnIconBase)`
  position: relative;
  top: -2px;

  .cnt-icon-text {
    display: flex;
    gap: 0px;
    align-items: center;
    /* background: rgba(255, 255,0, 0.3); */
  }

  .text {
    font-size: 10px;
    height: 11px;
    margin-left: 4px;
    overflow: clip;
    text-overflow: ellipsis;
    text-align: left; 
    width: 50px;
    max-width: 60px;
  }

  .icon {
    width: 11px;
    height: 11px;
    margin-left: 2px;
    margin-right: 4px;
    /* position: relative; */
    /* top: 0px; */
  }

  .chevron-down {
    width: 8px;
    height: 8px;
    user-select: none;
    margin-left: 5px;
    margin-right: 2px;
    margin-top: 2px;
    background-color: transparent;
    background-size: contain;
    background-image: url(/images/editor-text/toolbar/icons/chevron-down.svg);
  }
`;



export const MainWrapper  = styled.div`
`;

export const PanelWrapper = styled.div`
  position: relative;
`;

export const StyledDropdownBtn = styled(StyledDropdownBtnBase)`
  .text {
    width: 30px;
  }
`;

export const StyledPanel = styled(PanelForth)`
  position: absolute;
  left: 17px;
  top: 3px;
  overflow: clip;
  padding: 0px;
`;


export const Clipper = styled.div`
  height: 180px;
  width: 60px;
  overflow-x: clip;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  
  gap:     ${props => props.theme.defs.gap.xxsmall};
  padding: ${props => props.theme.defs.padding.normal};

  ${props => getScrollbarCSS(props)};

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.defs.colors.scrollbar.thumb.secondary};
  }

  /* ::-webkit-scrollbar {
    width:  ${props => props.theme.scrollbar.width.small};
    height: ${props => props.theme.scrollbar.width.small};
  } */
`;

export const InputCtrlsWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

export const StyledFontSizeInput = styled(Input)`
  width: 36px;
  box-sizing: border-box;
  text-align: center;

  &:focus-visible {
    outline-style: none;
  }

  border-style: none;
  border-radius: 0;
  background: ${props =>  props.theme.defs.colors.background.primary};
`;


export const ButtonGroupLeft = styled(TopToolbarButton)`
`;

export const ButtonGroupRight = styled(TopToolbarButton)`
`;