import styled from "styled-components";


interface ControlProps {
  $left?: boolean;
  $right?: boolean;
}


export const MainWrapper = styled.div<ControlProps>`
  display: flex;

  ${props => props.$left  ? 'flex-direction: row;' : ''};
  ${props => props.$right ? 'flex-direction: row-reverse;' : ''};

  height: 100%;
`;

export const ToolbarWrapper = styled.div`
  /* background: rgba(255, 255, 0, 0.2); */
`;

export const PanelWrapper = styled.div`
  position: relative;
  /* background: rgba(0, 255, 0, 0.2); */
`;

export const PanelDetacher = styled.div<ControlProps>`
  position: absolute;
  z-index: 1;

  top: 0;

  ${props => props.$left  ? 'left: 0;' : ''};
  ${props => props.$right ? 'right: 0;' : ''};
  
  height: 100%;
`;
