import React from 'react';

import HLS_FlexEditLogoComponent from 'app/ui-v2/home-page/hls/flex-edit-logo';

import TopMenuComponent    from './top-menu';

import { Filler } from './styles';
import { TopBar } from './styles';
import { FlexLogoWrapper }  from './styles';
import { TopMenuWrapper }   from './styles';
import TopMenuUserComponent from './top-menu-user';


interface Props {
}


export const TopbarComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <TopBar>

      <FlexLogoWrapper>
        <HLS_FlexEditLogoComponent/>
      </FlexLogoWrapper>

      <TopMenuWrapper>
        <TopMenuComponent />
      </TopMenuWrapper>

      <Filler />

      <TopMenuWrapper>
        <TopMenuUserComponent />
      </TopMenuWrapper>

    </TopBar>
  );
}
  
