import * as Types from './types';

export type StateRaw = {
  past:    Types.Record[],
  current: Types.Record,
  future:  Types.Record[],
};

export const createInitialState = (record: Types.Record): StateRaw => {
  const initState: StateRaw = {
    past: [],
    current: record,
    future: [],
  }

  return initState;
}
