import { EditorImageSessionTypes } from 'app/arch/editor-instruction/document/states/sessions/editor-image-session';
import useImageViewFit from 'app/ui-v2/editor-image/hooks/image/use-image-view-fit';
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";


const useFunctions = () => {
  const document = useDocState();
  
  const {
    setEditorImageSession
  } = useEditorStatesSetters();

  const {
    fitViewToImage,
    fitViewToAll,
  } = useImageViewFit();
  

  const setAutoFitMode = (autoMode: EditorImageSessionTypes.ViewDefinerAutomationMode) => {
    const update = {autoMode};
    document.editorImageSession.updateViewDefinerFitAutomation(update);
    setEditorImageSession();
  }

  const setAutoFitType = (fitType: EditorImageSessionTypes.ViewDefinerFitType) => {
    console.log(fitType);
    
    const update = {fitType};
    document.editorImageSession.updateViewDefinerFitAutomation(update);
    setEditorImageSession();
  }

  const setOverlayerViewType = (showType: EditorImageSessionTypes.ViewDefinerShowType) => {
    document.editorImageSession.updateViewDefinerOverlayer({showType});
    setEditorImageSession();
  }

  const setOverlayerSizeLocked = (resizerLocked: boolean) => {
    document.editorImageSession.updateViewDefinerOverlayer({resizerLocked});
    setEditorImageSession();
  }

  const fitImageView = (fitMode: EditorImageSessionTypes.ViewDefinerFitType) => {
    const imageAddr = document.editorImageSession.getImageEdited();
    if (imageAddr === null) {
      const msg = `Edited image addr is null, can't fit view to image.`
      throw new Error(msg);
    }

    const fitFnMap: {[key in EditorImageSessionTypes.ViewDefinerFitType]: any} = {
      [EditorImageSessionTypes.ViewDefinerFitType.FIT_TO_IMAGE]: fitViewToImage,
      [EditorImageSessionTypes.ViewDefinerFitType.FIT_TO_ALL]: fitViewToAll,
    }

    const fitFn = fitFnMap[fitMode];
    fitFn(imageAddr);
  }

  return {
    setAutoFitMode,
    setAutoFitType,

    setOverlayerViewType,
    setOverlayerSizeLocked,

    fitImageView,
  }
}


export default useFunctions;