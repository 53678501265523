import React from 'react';
import Separator from 'lego/styles/separator';
import { MenuTitle } from './styles';


interface Props {
  title: string | undefined;
}


export const MenuTitleComponent: React.FC<Props> = (props: Props) => {
  
  const {
    title 
  } = props;

  return (
    <>
    { 
      title !== undefined &&
      <>
        <MenuTitle>
          { title }
        </MenuTitle>
        <Separator />
      </>
    }
    </>
  );
}
  
