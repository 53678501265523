import { atom     } from 'recoil';
import { selector } from 'recoil';
import { MetaDataState } from 'app/arch/editor-instruction/document/states/persistent/meta-data';


export const state = atom<MetaDataState.State>({
  key: "edi_doc_dataMeta",
  default: MetaDataState.createInitialState()
});


/**
 * Selectors
 */
export const title = selector({
  key: `edi_doc_dataMeta_title`,
  get: ({ get }) => {
    return get(state).title;
  }
});

export const description = selector({
  key: `edi_doc_dataMeta_description`,
  get: ({ get }) => {
    return get(state).description;
  }
});

