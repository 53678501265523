import styled from "styled-components";
import Button from "lego/styles/button";
import Panel from "lego/styles/panel";


export const MainWrapper = styled(Panel)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.large};
  height: 110px;
  padding: 0px;

  box-shadow: none;
  overflow: clip;
`;


export const ColorsColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: ${props => props.theme.defs.gap.xsmall}; */
  gap: 0px;
  flex-grow: 1;
  justify-content: space-between;
`;

export const ColorsRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: inherit;
  justify-content: space-between;
`;


interface Props {
  $color: string;
  $lastRow: boolean
}

export const ButtonColor = styled(Button)<Props>`
  overflow: visible;
  position: relative;
  display: flex;
  flex-grow: 1;
  
  min-width: 18px;
  min-height: 18px;
  width: unset;
  height: unset;

  border-radius: 0px;
  background:   ${props => props.$color};

  border-style:  none;
  outline-style: none;
`;


export const SelectedColor = styled.div`
  position: absolute;;
  z-index: 1;

  top: 0px;
  left: 0px;
  box-sizing: border-box;

  outline: 2px solid #666;
  border:  2px solid #ccc;

  width:  100%;
  height: 100%;
`