import { useRef } from "react";
import { useState } from "react";

import { Position } from "app/arch/types";
import { DraggerUpdate } from "../arch";
import { DeltaMove } from "app/ui/hooks";
import { useDraggingDelta } from "app/ui/hooks";

import { DraggerBase } from './styles';


export interface DraggerProps {
  position: Position;
  scale?: number;

  onUpdateStart?: (event: any) => void;
  onUpdate?: (update: DraggerUpdate) => void;
  onUpdateDone?: (update: DraggerUpdate) => void;

  cursorChangeWhileDragging?: boolean;
  cursor?: string;
  disabled?: boolean;

  stopPropagation?: boolean;

  component?: React.ElementType;
  children?: React.ReactNode;
};


export const DraggerComponent = (props: DraggerProps) => {
  const { 
    children, 
    position, 
    onUpdateStart,
    onUpdate, 
    onUpdateDone, 
    
    disabled,
    
    stopPropagation,
  } = props;
  

  const scale = props.scale || 1;
  const cursor = props.cursor || 'move';
  const cursorChangeWhileDragging = (
    props.cursorChangeWhileDragging !== undefined ?
    props.cursorChangeWhileDragging : 
    true
  );
  const Dragger = props.component || DraggerBase;

  const [isDragging, setIsDragging] = useState(false);
  const startingPosition = useRef<Position>([...position]);

  const getPosition = (delta: DeltaMove) => {
    const x = startingPosition.current[0] + delta.x / scale;
    const y = startingPosition.current[1] + delta.y / scale;
    return [x, y] as Position;
  }

  const calcDeltaScaled = (delta: DeltaMove) => {
    return {
      x: delta.x / scale,
      y: delta.y / scale
    }
  }

  const handleWidgetDragMove = (delta: DeltaMove) => {
    if (onUpdate) {
      onUpdate({
        position: getPosition(delta),
        delta: calcDeltaScaled(delta)
      });
    }
  }
  
  const handleWidgetDragEnd = (delta: DeltaMove) => {
    handleWidgetDragMove(delta);
    setIsDragging(false);

    if (onUpdateDone) {
      onUpdateDone({
        position: getPosition(delta),
        delta: calcDeltaScaled(delta)
      });        
    }
  }

  const {
    startDragging,
  } = useDraggingDelta({
    onDragMove: handleWidgetDragMove,
    onDragEnd: handleWidgetDragEnd,
    stopPropagation,
  });

  const handleStartDragging = (event: React.PointerEvent) => {
    if (disabled) {
      return;
    }

    const started = startDragging(event);
    if ( ! started ) {
      return;
    }

    startingPosition.current = [...position];
    setIsDragging(true);
    onUpdateStart?.(event);
  }
    
  
  const getCursor = () => {
    if ( ! cursorChangeWhileDragging ) {
      return (disabled && cursor || 'default');
    }

    if (isDragging) {
      return cursor;
    }
    return 'default'
  }
  
  return (
    <Dragger
      onPointerDown={handleStartDragging}
      style={{ cursor: getCursor() }}
    >
      { children }
    </Dragger>
  );
};
