import React from 'react';

import useUserIsLogged from 'app/ui-v2/app/hooks/use-user-is-logged';
import HomePageGuestComponent from 'app/ui-v2/home-page/home-page-guest';
import HomePageUserComponent  from 'app/ui-v2/home-page/home-page-user';

interface Props {
}


export const RedirectHomePageUserOrGuestComponent: React.FC<Props> = (props: Props) => {
  const isLogged = useUserIsLogged();

  return (
    <>
      { ! isLogged && <HomePageGuestComponent /> }
      {   isLogged && <HomePageUserComponent /> }
    </>
  );
}
  
