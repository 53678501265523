import React from 'react';
import { useEffect } from 'react';
import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';


interface Props {
}


export const PrintoutClippingComponent: React.FC<Props> = (props: Props) => {
  const isPrintout = useIsPrintout();

  if ( ! isPrintout ) {
    return null;
  }

  return <Component />;
}


const Component: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    disableClipping();
  }, []);

  const disableClipping = () => {
    document.documentElement.style.setProperty('--body-clip', 'visible');
  }
  
  return null;
}
