import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_EditorSession } from 'app/ui/states/editor-instruction';

import * as Types from '../types';
import useDropOn from '../hooks/use-drop-on';
import useDropInCheck from '../hooks/use-drop-in-check';
import { Wrapper as WrapperDefault } from './styles';


interface Props extends Types.DNDDropProps {
}


export const DndDropMouseComponent: React.FC<Props> = (props: Props) => {
  const {
    contextsDefs,
    children
  } = props;

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dndContext = useRecoilValue(UIState_EditorSession.DNDContext);
  const dragging   = contextsDefs.some((contextDef) => contextDef.dndContext === dndContext);
  
  const isDraggerIn = useDropInCheck({wrapperRef});
  const onDrop = useDropOn({contextsDefs, dndContext});

  const isOverRef = useRef(false);


  const Wrapper = (
    props.Wrapper !== undefined ?
    props.Wrapper :
    WrapperDefault
  );

  
  useEffect(() => {
    if ( ! dragging) {
      return;
    }

    __registerHandlers();
    return () => {
      __unregisterHandlers();
    };
  }, [dragging]);


  const __registerHandlers = () => {
    window.document.addEventListener("pointerup", handlePointerUp);
    window.document.addEventListener("pointermove", handlePointerMove);
  }

  const __unregisterHandlers = () => {
    window.document.removeEventListener("pointerup", handlePointerUp);
    window.document.removeEventListener("pointermove", handlePointerMove);
  }

  const handlePointerUp = (event: PointerEvent) => {
    if ( ! dragging ) {
      return;
    }

    const {
      clientX: x,
      clientY: y,
     } = event;

    if (isDraggerIn(x, y)) {
      onDrop();
    }
  }

  const handlePointerMove = (event: PointerEvent) => {
    if ( ! dragging ) {
      return;
    }

    if (dndContext === null) {
      console.warn("DND selected context is null");
      return;
    }

     const {
      clientX: x,
      clientY: y,
     } = event;


    const context = contextsDefs.find(ctx => ctx.dndContext === dndContext);
    if (context === undefined) {
      console.warn("Can't find selected dnd context in contexts defs ");
      return;
    }

    if (isDraggerIn(x, y)) {
      isOverRef.current = true;
      context.onDragOver?.();
    }
    else if ( isOverRef.current ) {
      isOverRef.current = false;
      context.onDragLeave?.();
    }
  }


  return (
    <Wrapper 
      ref={wrapperRef}
    >
      { children }
    </Wrapper>
  );
}
