import { HeaderFieldsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { useDocState } from "app/ui/contexts/document";


const usePageColumnSelectedSet = () => {
  const document = useDocState();

  const {
    setCustomFieldsColumnsSelected,
  } = useEditorStatesSetters();

  const selectPageColumn = (props : {
    pageColumnAddr: HeaderFieldsTypes.PageColumnAddr,
    skipStateUpdate?: boolean,
  }) => {
    const {
      pageColumnAddr, 
      skipStateUpdate
    } = props;

    document.customFieldsColumnsSelected.select(pageColumnAddr);

    if (skipStateUpdate) {
      return;
    }
    
    setCustomFieldsColumnsSelected();
  }

  return selectPageColumn;
}

export default usePageColumnSelectedSet;