import styled from "styled-components";
import MenuItem   from "lego/styles/menu_item";
import TextWrap from "lego/styles/text-wrap";


export const Item = styled(MenuItem)`
  /* background: rgba(129, 255, 0, 0.3); */
  height: 30px;
  gap: ${props => props.theme.defs.gap.small};
  box-sizing: border-box;

  min-width: 100px;
  max-width: 190px;
`;

export const IconWrapper = styled.div`
  /* background: rgba(255, 255, 0, 0.3); */
  pointer-events: none;

  flex: 0 0 26px;
  min-width: 26px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconScaler = styled.div`
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  
  /* scale: ${props => props.theme.defs.scale.small}; */
  scale: 0.8;
`;

export const TextWrapper = styled.div`
  /* background: rgba(110, 133, 123, 0.5); */
  pointer-events: none;

  flex: 1 1 0;
  min-width: 0px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Text = styled(TextWrap)`
  width: 100%;
`;

export const KeyBindingWrapper = styled.div`
  /* background: rgba(0, 255, 255, 0.3); */
  pointer-events: none;
  
  flex: 0 0 32px;
  min-width: 32px;
  height: 100%;
  
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
