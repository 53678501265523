import React from 'react';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import useImageEdit from 'app/ui-v2/editor-image/hooks/image/use-image-edit';
import { MainWrapper } from './styles';


interface Props {
  imageAddr: Types.ImageAddr;
  children: React.ReactNode;
}


export const ImageEditComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children,
  } = props;

  const editImage = useImageEdit();

  const handleImageEdit = (event: React.MouseEvent) => {
    editImage(imageAddr);
  }

  return (
    <MainWrapper
      onDoubleClick={handleImageEdit}
    >
      { children }
    </MainWrapper>
  );
}
  
