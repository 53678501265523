import React from 'react';
import { useEffect } from 'react';

import ContentComponent from './components/content';
import useSelectedReset from './hooks/use-selected-reset';


interface Props {
}


export const ViewChangelogComponent: React.FC<Props> = (props: Props) => {
  const resetSelected = useSelectedReset ();

  useEffect(() => {
    return () => resetSelected();
  }, []);

  return (
    <ContentComponent />
  );
}
