import styled from "styled-components";


export const MainWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 250px;
`;
