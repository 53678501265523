import styled from "styled-components";
import WindowButtonControl from "lego/styles/window-button-control";


export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;


export const Button = styled(WindowButtonControl)`
  `;

