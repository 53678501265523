import { ReleaselogsTypes } from "app/arch/editor-instruction/document/states/persistent/releaselogs";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useRowSelectedSet = () => {
  const document = useDocState();

  const {
    setReleaselogsSession
  } = useEditorStatesSetters();

  const selectRow = ({rowAddr, skipStateUpdate} : {
    rowAddr: ReleaselogsTypes.RowAddr | null,
    skipStateUpdate?: boolean,
  }) => {
    document.releaselogsSession.setRowSelected(rowAddr);

    if (skipStateUpdate) {
      return;
    }

    setReleaselogsSession();
  }

  return selectRow;
}


export default useRowSelectedSet;