import i18next from 'i18next';
import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import { GetConfigProps } from "./types";
import { EditorImageSettingsTypes } from 'app/arch/editor-instruction/document/states/persistent/editor-image-settings';


type GridType = EditorImageSettingsTypes.GridType;


export const getConfigGrid = (props: GetConfigProps) => {
  const { cmds, states } = props;
  const t = i18next.t;
  // const disabled = states.widgetAddr === null;
  const disabled = false;

  const config: PanelControlsConfig = {
    disabled: disabled,
    items: [
      {
        title: t('grid'),
        sections: [
          {
            items: [
              {
                title: t('grid enabled'),
                type: PanelControlsItemSectionItemType.BUTTON_GROUP,
                buttons: [
                  {
                    text: t('off'),
                    value: false,
                    selected: ! states.grid.enabled,
                  },
                  {
                    text: t('on'),
                    value: true,
                    selected: states.grid.enabled,
                  },
                ],
                onClick: cmds.onGridEnable
              },
              {
                title: t('grid visible'),
                type: PanelControlsItemSectionItemType.BUTTON_GROUP,
                disabled: ! states.grid.enabled,
                buttons: [
                  {
                    text: t('grid hide'),
                    value: EditorImageSettingsTypes.GridType.HIDE,
                    selected: states.grid.showType === EditorImageSettingsTypes.GridType.HIDE,
                  },
                  {
                    text: t('grid lines'),
                    value: EditorImageSettingsTypes.GridType.LINES,
                    selected: states.grid.showType === EditorImageSettingsTypes.GridType.LINES,
                  },
                  {
                    text: t('grid dots'),
                    value: EditorImageSettingsTypes.GridType.DOTS,
                    selected: states.grid.showType === EditorImageSettingsTypes.GridType.DOTS,
                  },
                ],
                onClick: cmds.onGridShowType
              },
              {
                title: t('grid size'),
                type: PanelControlsItemSectionItemType.SLIDER,
                disabled: ! states.grid.enabled,
                onChange: cmds.onGridSizeChange,
                onChangeDone: cmds.onGridSizeChangeDone,
                step: 1,
                min: 5,
                max: 50,
                value: states.grid.size,
              },
            ]
          }
        ]
      },
      
    ]
  } // config

  return config;
}

export default getConfigGrid;