import { useRef }  from 'react';
import { useEffect }  from 'react';
import { query } from 'app/arch/backend';
import { useQueryManual } from 'app/arch/backend/use-query-manual';
import { SubscriptionsTypes } from 'app/arch/app/user/states/subscriptions';


type OnDoneFn = (
  subscriptionRaw: SubscriptionsTypes.SubscriptionRaw
) => void;

type OnError = (error: string) => void;


export interface HookProps {
  subscriptionId: string;
  onDone?: OnDoneFn;
  onError?: OnError;
}


const useUserSubscriptionFetch = () => {
  const { fetch, data, error } = useQueryManual({
    query: query.getSubscription(),
  });

  const hookPropsRef = useRef<HookProps | null>(null);

  useEffect(() => {
    if ( ! data ) {
      return;
    }

    if ( ! hookPropsRef.current) {
      const msg = `User subscription fetch query - hook props not set`;
      throw new Error(msg);
    }

    const subscription = data.subscription;
    const onDone = hookPropsRef.current.onDone;
    onDone?.(subscription);
    
    resetHookProps();
  }, [data]);


  useEffect(() => {
    if ( ! error) {
      return;
    }

    if ( ! hookPropsRef.current) {
      const msg = `User subscription fetch query - hook props not set`;
      throw new Error(msg);
    }

    const onError = hookPropsRef.current.onError;
    onError?.(error.message);
    
    resetHookProps();
  }, [error]);


  const resetHookProps = () => {
    hookPropsRef.current = null;
  }

  
  const hook = (hookProps: HookProps) => {
    const {
      subscriptionId
    } = hookProps;

    hookPropsRef.current = hookProps;

    fetch({
      variables: {
        subscriptionId
      }
    });
  }

  return hook;
}
  

export default useUserSubscriptionFetch;