import React from 'react';

import { useDocState } from 'app/ui/contexts/document';
import { Input } from './styles';


export const InputStyledComponent = React.forwardRef((
  props: React.InputHTMLAttributes<HTMLInputElement>, 
  ref: React.Ref<HTMLInputElement>
) => {
  const document = useDocState();
  const style = document.releaselogsView.getHeaderTitle().css;
  
  return (
    <Input
      {...props} 
      ref={ref} 
      style={style}
    />
  );
});
