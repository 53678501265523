import * as Types from './types';


/**
 * Cells Rows
 */

export const compareCellsRow = (
  cellsRowSrc: Types.SectionItem_CellsRow,
  cellsRowDst: Types.SectionItem_CellsRow,
) => (
  cellsRowSrc.rowId === cellsRowDst.rowId
);