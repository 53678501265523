import { atom }     from 'recoil';
import { selector } from 'recoil';

import { SliceRequestState as State} from 'app/arch/editor-instruction/document/states/slicing/releaselogs/slice-request';

/**
 * State
 */

export const state = atom<State.State>({
  key: "ei_docReleaselog_sliceRequest",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const sliceRequest = selector({
  key: "ei_docReleaselog_sliceRequest_sliceRequest",
  get: ({ get }) => {
    const stateObj = get(state);
    const update = State.getSliceRequest(stateObj);
    return update;
  }
});

