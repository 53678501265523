import { MenuTypes } from 'lego-v2/menu';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useItems from './__use-items';


const useConfig = (widgetAddr: ContentTypes.WidgetAddr | null) => {
  const items = useItems(widgetAddr);

  
  const getConfigSections = () => {
    const config: MenuTypes.MenuSection[] = [
      {
        items: [
          items.getUndo(),
          items.getRedo(),
        ],
      },
      {
        items: [
          items.getWidgetDuplicate(),
          items.getWidgetCopy(),
          items.getWidgetPaste(),
        ],
      },
    ];

    return config;
  }


  return getConfigSections;
}


export default useConfig;