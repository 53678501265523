import React from 'react';

import { ReleaselogsTools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import CellRenderComponent    from './cell-render';
import GridCellProbeComponent from './grid-cell-probe';

import { GridCell } from './styles';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;

  firstCellInRow: boolean;
  
  lastPageRow: boolean;
  lastRow: boolean;
}


export const GridCellComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,

    firstCellInRow,
    lastPageRow,
    lastRow,
  } = props;

  const document = useDocState();

  const {
    setReleaselogs
  } = useEditorStatesSetters();
  
  const isReleaselogActive = document.releaselogs.isReleaselogActive(cellAddr);
  const rowAdder = isReleaselogActive && lastRow;

  const cellDataTest = ReleaselogsTools .getCellDataTest({
    cellAddr,
    docState: document,
    prefix: 'releaselog-cell'
  });

  const handleAddRow = (event: React.MouseEvent) => {
    document.releaselogs.addRow(cellAddr);
    document.saveUndo();
    setReleaselogs();
  }

  const rowAdderProps = (
    rowAdder ?
    {
      onPointerDownCapture: handleAddRow,
    } :
    {}
  );


  return (
    <GridCellProbeComponent
      enabled={firstCellInRow}
      cellAddr={cellAddr}
    >
      <GridCell
        data-test={cellDataTest}
        borderLeft={firstCellInRow}
        borderBottom={lastPageRow || lastRow}
        faded={rowAdder}
        {...rowAdderProps}
      >
        <CellRenderComponent
          cellAddr={cellAddr}
        />
      </GridCell>
    </GridCellProbeComponent>
  );
}
  
