import styled from "styled-components";


const zIndex = {
  stripe: 190,
  relogin: 195,
  progress: 200,
  toast: 210,
  demo: 220,
}

export const InfoWrapper = styled.div`
  position: absolute;
  right: 2px;
  bottom: 2px;

  z-index: ${zIndex.toast};
`;

const WrapperBase = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`;


export const ModalsWrapper = styled(WrapperBase)`
  z-index: 500000;
  pointer-events: none;
`;

export const ProgressWrapper = styled(WrapperBase)`
  z-index: ${zIndex.progress};
`;

export const StripeWrapper = styled(WrapperBase)`
  z-index: ${zIndex.stripe};
`;

export const DemoWrapper = styled(WrapperBase)`
  z-index: ${zIndex.demo};
`;

export const ReloginWrapper = styled(WrapperBase)`
  z-index: ${zIndex.relogin};
`;
