import styled from "styled-components";
import TitleElement from "lego/styles/title-element";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xsmall};
`;

export const Title = styled(TitleElement)`

`;