import { ContentTypes }    from "app/arch/editor-instruction/document/states/persistent/content";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState }        from "app/ui/contexts/document";
import useSelectedReset from "../use-selected-reset";
import useWidgetLogger  from "./use-widget-logger";


const useWidgetSelect = () => {
  const document = useDocState();
  const logger = useWidgetLogger();
  const resetSelected = useSelectedReset();

  const {
    setEditorImageSession
  } = useEditorStatesSetters();


  const selectWidget = (props: {
    widgetAddr: ContentTypes.WidgetAddr,
    editDisabled: boolean,
  }) => {
    const {
      widgetAddr,
      editDisabled
    } = props;

    logger.debug(`Widget, select`);

    if ( editDisabled ) {
      logger.debug(`Editing disable, skip select`);
      return;
    }

    const selected = document.editorImageSession.isWidgetSelected(widgetAddr);
    if ( selected ) {
      logger.debug(`Widget, already selected`);
      return;
    }

    const edited = document.editorImageSession.isWidgetEdited(widgetAddr);
    if ( edited ) {
      logger.debug(`Widget is edited, skip select`);
      return;
    }

    resetSelected({skipStateUpdate: true});
    document.cellImages_image_setWidgetSelected(widgetAddr);
    setEditorImageSession();
  }

  return selectWidget;
}

export default useWidgetSelect;