import TextError from 'app/ui-v2/home-page/styles/text-error';
import styled from 'styled-components';


export const MainWrapper = styled.div<{$loaded: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.defs.gap.large};

  @media (min-width: 610px) {
    flex-direction: row;
    gap: ${props => props.theme.defs.gap.xlarge};
  }
  /* background-color: blue; */

  opacity: ${props => props.$loaded ? 1 : 0};
  transition: opacity 250ms ease-in-out;
`;


export const ProductInfoWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.large};

  max-width: 250px;
  min-width: 100%;

  @media (min-width: 610px) {
    min-width: unset;
  }

  padding-bottom: ${props => props.theme.defs.padding.large};
`;


export const StyleForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xlarge};

  flex: 1 1 auto;
  /* background-color: red; */
`;

export const CheckoutControlsWrapper = styled.div`
`;


export const InfoWrapper = styled.div`
`;


export const StripePaymentWrapper = styled.div`
`; 


export const ErrorText = styled(TextError)`
  font-size: ${props => props.theme.defs.font.size.large};
  font-weight: ${props => props.theme.defs.font.weight.medium};
`