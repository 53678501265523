import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderMarkersTools }    from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { UIState_HeaderMarkers } from 'app/ui/states/editor-instruction';
import { useTranslations }       from 'app/ui/hooks/app/use-translation';
import { MarkerComponent } from './marker/Marker';

import { MainWrapper } from './styles';
import { TextInfo }    from './styles';


interface Props {
}


export const MarkersComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const markersAddrs = useRecoilValue(UIState_HeaderMarkers.markersAddrs);
  const markersAvailable = (markersAddrs.length > 0);

  const renderMarkers = () => {
    const markersComps = markersAddrs.map((markerAddr) => {
      const markerKey = HeaderMarkersTools.getMarkerKey(markerAddr);
      
      return (
        <MarkerComponent 
          key={markerKey}
          markerAddr={markerAddr} 
        />
      );
    });

    return markersComps;
  }

  const renderNoMarkers = () => (
    <TextInfo>
      {t("marker, no marker msg")}
    </TextInfo>
  );


  return (
    <MainWrapper>
      { markersAvailable && renderMarkers() }
      { ! markersAvailable && renderNoMarkers() }
    </MainWrapper>
  );
}
  
