import { useMutation }              from 'app/arch/backend/use-mutation';
import { mutation as mutationDefs } from 'app/arch/backend';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useUserSettings from './use-user-settings';


const useUserSettingsSave = () => {
  const t = useTranslations();
  const userSettings = useUserSettings();

  // const msgs = {
  //   onStart: {
  //     msg:  t("saving user settings"),
  //   },
  //   onEnd: 
  //   {
  //     msg:  t("user settings saved"),
  //     duration: MsgDuration.MIDDLE
  //   }
  // }

  // Mutation
  //
  const { 
    mutation,
    data,
    error,
  } = useMutation(mutationDefs.updateUserSettings);

  const hook = () => {
    const userSettingsSerial = userSettings.serialize();

    mutation({
      variables: { settings: userSettingsSerial }
    });
  }

  return hook;
}


export default useUserSettingsSave;
