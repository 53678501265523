import { useRecoilValue } from "recoil"
import { UIPrintoutView } from "../states";


const usePrintoutViewPageCurrent = () => {
  const pageCurrent = useRecoilValue(UIPrintoutView.pageCurrent);
  return pageCurrent;
}


export default usePrintoutViewPageCurrent;