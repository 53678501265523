import React from 'react';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState } from 'app/ui/contexts/document';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';

import { DropWrapper } from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellImagesDropComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;

  const document = useDocState();
  const getDNDData = useDNDDataGet();

  const {
    setContent
  } = useEditorStatesSetters();

  const handleDrop_fromCell = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const imageAddrDropped = data.imageAddr as Types.ImageAddr;

    document.content.cellImages_moveImageIntoCell(
      imageAddrDropped,
      cellAddr
    );
    document.content.checkForLastRow(cellAddr);
    
    document.saveUndo();
    setContent();
  }

  const handleDrop_fromGallery = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }
    const data = JSON.parse(dataSerial);
    const imageGid = data.imageGid;
    const image = document.repoImages.getImage(imageGid);

    document.content.cellImages_addImage(cellAddr, image);
    document.content.checkForLastRow(cellAddr);

    document.saveUndo();
    setContent();
  }


  const contextsDefs: ContextDef[] = [
    {
      dndContext: DNDContext.image.cell,
      onDrop: handleDrop_fromCell,
    },
    {
      dndContext: DNDContext.image.gallery,
      onDrop: handleDrop_fromGallery
    },
  ];

  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs}
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
