import styled from 'styled-components';


const Virtual = styled.div`
  visibility: hidden;
  position: fixed;
  top: -99999px;
  left: -99999px;
`;

export default Virtual;