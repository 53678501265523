import styled from "styled-components";

export const PanelFloating = styled.div`
  background:    ${props => props.theme.panelFloating.background};
  padding:       ${props => props.theme.panelFloating.padding};
  
  border-style:  ${props => props.theme.panelFloating.border.style};
  border-width:  ${props => props.theme.panelFloating.border.width};
  border-radius: ${props => props.theme.panelFloating.border.radius};
  border-color:  ${props => props.theme.panelFloating.border.color};

  box-shadow:    ${props => props.theme.panelFloating.boxShadow};

  overflow: clip;
  display: flex;
  flex-direction: column;
`;

export default PanelFloating;