import React from 'react';
import { useEffect } from 'react';
import { RefObject } from 'react';
import { useRecoilValue } from 'recoil';
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { UIState_Releaselogs } from 'app/ui/states/editor-instruction';

import { ColumnCSS } from './styles';


interface Props {
  columnAddr: ReleaselogsTypes.ColumnAddr;
  editorTextRef: RefObject<HTMLDivElement | null>;
  children: React.ReactNode;
}


export const ColumnCSSCustomComponent: React.FC<Props> = (props: Props) => {
  const { 
    columnAddr,
    editorTextRef,
    children,
  } = props;
  
  const styleWhole = useRecoilValue(UIState_Releaselogs.columnCSS(columnAddr)) || {};

  const {
    padding,
    ...style
  } = styleWhole;

  useEffect(() => {
    if (editorTextRef.current !== null) {
      if (typeof(padding) !== "string") {
        console.warn(`Padding expected type string, but got ${typeof(padding)}`);
        return;
      }
      editorTextRef.current.style.padding = padding as string;
    }
  }, [padding]);

  return (
    <ColumnCSS style={style}>
      { children }
    </ColumnCSS>
  );
}
