import { HookBaseProps } from "./types";
import { SelectFiles } from "./UserFilesSelector";


export interface HookProps extends HookBaseProps {
}


const useSelectUserFiles = (props: HookProps) => {
  const selectFiles = () => {
    if (SelectFiles === null) {
      console.warn(
        `Select user files hook not configured. ` +
        `Did you include UserFilesSelectorComponent on page?`
      );
      return;
    }

    SelectFiles(props);
  }

  return selectFiles;
};

export default useSelectUserFiles;