import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useRowToggleSelect = () => {
  const docState = useDocState();

  const {
    setContentRowsSelected,
  } = useEditorStatesSetters();

  const selectRow = (props: {
    rowAddr: ContentTypes.RowAddr,
    resetSelected?: boolean,
    skipStateUpdate?: boolean,
  }) => {
    const {
      rowAddr,
      resetSelected,
      skipStateUpdate
    } = props;

    if (resetSelected) {
      docState.contentRowsSelected.reset();
    }

    docState.contentRowsSelected.toggleSelect(rowAddr);

    if (skipStateUpdate) {
      return;
    }

    setContentRowsSelected();
  }

  return selectRow;
}


export default useRowToggleSelect;