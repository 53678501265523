import * as Defaults from './defaults';
import * as Tools from "./tools"
import * as Types from "./types"


/**
 * State
 */
export type State = Types.Subscriptions;


/**
 * Getters
 */
export const getSubscriptionsAddrs = (
  state: State, 
): Types.SubscriptionsAddrs => {
  return state.addrs;
}

export const getSubscriptionsProps = (
  state: State, 
): Types.SubscriptionsProps => {
  return state.props;
}

export const getSubscriptionProps = (
  state: State, 
  subscriptionAddr: Types.SubscriptionAddr
): Types.SubscriptionProps => {
  const subscriptionsProps  = getSubscriptionsProps(state);
  
  const subscriptionKey   = Tools.getSubscriptionKey(subscriptionAddr);
  const subscriptionProps = subscriptionsProps[subscriptionKey];
  if (subscriptionProps === undefined) {
    const msg = 'Subscription not found';
    throw new Error(msg);
  }

  return subscriptionProps;
}



/**
 * Create Init State
 */
export const createInitialState = (): State => {
  const initState: State = {
    addrs: [],
    props: {},
  }

  return initState;
}
