class Log {
  private static instance: Log;

  debug(msg: any) {
    console.log(`%c${msg}`, "color: rgba(50, 230, 255, 0.8);");
  }

  info(msg: any) {
    console.log(`%c${msg}`, "color: rgba(50, 250, 50, 0.8);");
  }

  warn(msg: any) {
    console.log(`%c${msg}`, "color: rgba(255, 165, 0, 0.8);");
  }

  public static getInstance(): Log {
    if ( ! Log.instance) {
      Log.instance = new Log();
    }
    return Log.instance;
  }
}

export const _log = Log.getInstance();