import { atom     } from 'recoil';
import { selector } from 'recoil';
import { HeaderMetaState } from 'app/arch/editor-instruction/document/states/persistent/header-meta';


export const state = atom<HeaderMetaState.State>({
  key: "edi_doc_headerMeta",
  default: HeaderMetaState.createInitialState()
});


/**
 * Logo
 */
export const logoVisible = selector({
  key: `edi_doc_headerMeta_logoVisible`,
  get: ({ get }) => {
    const stateObj = get(state);
    const visible = HeaderMetaState.getLogo(stateObj).visible;
    return visible;
  }
});

export const logoImage = selector({
  key: `edi_doc_headerMeta_logoImage`,
  get: ({ get }) => {
    const stateObj = get(state);
    const logoImage = HeaderMetaState.getLogo(stateObj).image;
    return logoImage;
  }
});

export const logoView = selector({
  key: `edi_doc_headerMeta_logoView`,
  get: ({ get }) => {
    const stateObj = get(state);
    const logoView = HeaderMetaState.getLogo(stateObj).view;
    return logoView;
  }
});


/**
 * Release Info
 */
export const releaseInfoVisible = selector({
  key: `edi_doc_headerMeta_releaseInfoVisible`,
  get: ({ get }) => {
    const stateObj = get(state);
    return HeaderMetaState.getReleaseInfo(stateObj).visible;
  }
});
