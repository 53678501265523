import { UUID } from "app/arch/types";


/**
 * Changelog Items
 */

export enum ChangelogItemType {
  ROW        = 'row',
  HEADER_ROW = 'header-row',
}

export interface ChangelogItem {
  type: ChangelogItemType
}

export interface ChangelogItem_HeaderRow extends Omit<ChangelogItem, 'type'> {
  type: ChangelogItemType.HEADER_ROW
}

export interface ChangelogItem_Row extends Omit<ChangelogItem, 'type'> {
  type: ChangelogItemType.ROW,
  rowId: UUID
}

export type ChangelogItems = (
    ChangelogItem_Row 
  | ChangelogItem_HeaderRow
)[];


export type Changelog = {
  items: ChangelogItems,
}


/**
 * Page Items
 */

export enum PageItemType {
  DOC_HEADER_INFO   = 'doc-header-info',
  VIEW_TITLE        = 'view-title',
  RELEASE_INFO      = 'release-info',
  RELEASE_CHANGELOG = 'release-changelog',
  SPACER            = 'spacer',
}

export interface PageItem {
  type: PageItemType,
}

export interface PageItem_DocHeaderInfo extends Omit<PageItem, 'type'> {
  type: PageItemType.DOC_HEADER_INFO,
}

export interface PageItem_ViewTitle extends Omit<PageItem, 'type'> {
  type: PageItemType.VIEW_TITLE,
}

export interface PageItem_Release extends PageItem {
  releaselogId: UUID,
}

export interface PageItem_ReleaseInfo extends Omit<PageItem_Release, 'type'> {
  type: PageItemType.RELEASE_INFO,
}

export interface PageItem_ReleaseChangelog extends Omit<PageItem_Release, 'type'> {
  type: PageItemType.RELEASE_CHANGELOG,
  items: ChangelogItems
}

export interface PageItem_Spacer extends Omit<PageItem, 'type'> {
  type: PageItemType.SPACER,
}

export type PageItems = (
    PageItem_DocHeaderInfo
  | PageItem_ViewTitle
  | PageItem_ReleaseInfo
  | PageItem_ReleaseChangelog
  | PageItem_Spacer
)[];

export type Page = {
  items: PageItems,
}

export type Pages = Page[];

