import React, { useEffect, useRef, useState } from 'react';

import { Position } from 'app/arch/types';
import VirtualProbeComponent from 'lego/components/virtual-probe';
import { MainWrapper } from './styles';


const DELTA_X  = 10;
const DELTA_Y  = -10;



interface Props {
  position: Position;
  children: JSX.Element;
}


export const ScreenFitterComponent: React.FC<Props> = (props: Props) => {
  const {
    position: positionRequested,
    children
  } = props;
  
  const [position, setPosition] = useState<Position>([0, 0]);
  const [bbox, setBbox] = useState<DOMRect | null>(null);
  
  useEffect(() => {
    setBbox(null);
  }, [positionRequested]);

  const adjustPosition = (bbox: DOMRect) => {
    const deltaX = window.innerWidth  - (positionRequested[0] + DELTA_X + bbox.width);
    const deltaY = window.innerHeight - (positionRequested[1] + DELTA_Y + bbox.height);

    let x = positionRequested[0];
    if ( deltaX < 0 ) {
      x += deltaX;
    }

    let y = positionRequested[1];
    if ( deltaY < 0 ) {
      y += deltaY;
    }

    setPosition([x, y] as Position);
  }

  const hanldeBBox = (bbox: DOMRect) => {
    setBbox(bbox);
    adjustPosition(bbox);
  }

  return (
    <>
      {
        bbox &&
        <MainWrapper
          style={{
            left: `${position[0] + DELTA_X}px`,
            top:  `${position[1] + DELTA_Y}px`,
          }}
        >
        { children }
        </MainWrapper>
      }

      {
        ! bbox && 
        <VirtualProbeComponent onBBoxChange={hanldeBBox}>
          { children }
        </VirtualProbeComponent>
      }
    </>
  );
}
  
