import { useEffect }   from 'react';
import { useRecoilValue } from 'recoil';
import { UIUser_Settings } from 'app/ui-v2/app/__modules/user/states';

import { useProgressModal } from 'lego-v2/progress-modal';

import { mutation }        from "app/arch/backend";
import { useMutation }     from "app/arch/backend/use-mutation";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useFeatureLimitedInfo from 'app/ui-v2/app/hooks/use-feature-limited-info';

import useDocumentEdit from './use-document-edit';


interface FnProps {
  title?: string;
}


const useDocCreateDemo1 = () => {
  const t = useTranslations();
  const editDocument = useDocumentEdit();
  const showFeatureLimited = useFeatureLimitedInfo();
  const repoId = useRecoilValue(UIUser_Settings.repoId);
    
  const {
    showProgressModal,
    hideProgressModal
  } = useProgressModal();

  // 
  // Create document
  //

  const msgs = { 
    onStart: { msg: t("document creating") },
  }

  const { 
    data: response,
    mutation: createInstructionDemo1,
  } = useMutation(mutation.createInstructionDemo1, msgs);

  useEffect(() => {
    if ( ! response ) {
      return;
    }

    const data = response.instructionCreateDemo1;
    
    if ( data.featureLimited ) {
      hideProgressModal();
      showFeatureLimited(data.limitType);
      return;
    }

    const instruction = data.instruction;
    editDocument(instruction.id);
  }, [response]);


  const createDocument = () => {
    if (repoId === null) {
      const msg = `Repo id is null`;
      throw new Error(msg);
    }

    showProgressModal();

    const variables = { repoId };
    createInstructionDemo1({ variables });
  }

  return createDocument;
}


export default useDocCreateDemo1;
