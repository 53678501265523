import { atom } from 'recoil';
import { selector } from 'recoil';
import { PricePlansSessionState as State } from 'app/arch/app/products/states/price-plans-session';


export const state = atom<State.State>({
  key: "products_pricePlansSession",
  default: State.createInitialState()
});


export const billingPeriod = selector({
  key: `products_pricePlansSession_billingPeriod`,
  get: ({ get }) => {
    const stateObj = get(state) ;
    return State.getBillingPeriod(stateObj);
  }
});
