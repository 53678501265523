import React from 'react';
import { ContentSavePlugin }   from "../../editor-text/ContentSavePlugin";
import WidgetTextComponentBase from '../__widget-base/widget-text';
import { WidgetPropsBase } from '../types';


interface Props extends WidgetPropsBase {
}


export const WidgetTextComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <WidgetTextComponentBase 
      {...props} 
      EditorTextPlugins={[
        <ContentSavePlugin  widgetAddr={props.widgetAddr} />
      ]}
    />
  );
}
