import styled from "styled-components";
import SelectButtonBase from "lego/styles/select-button";
import RepoNameBase from './styles/repo-name';


export const SelectButton = styled(SelectButtonBase)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: ${props => props.theme.defs.gap.normal};
  max-width: unset;
  width: 100%;
`;

export const RepoName = styled(RepoNameBase)`
  text-align: left;
  flex-grow: 1;
`;

export const RepoListWrapper = styled.div`
  padding-top: ${props => props.theme.defs.gap.xsmall};;
`;