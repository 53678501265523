import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';
import { UIState_ReleaselogsView } from 'app/ui/states/editor-instruction';

import { CSSWrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const ViewTitleCSSComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const style = useRecoilValue(UIState_ReleaselogsView.headerTitleCSS);
  const tableHeaderCSS = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);
  
  const {
    color,
    background
  } = tableHeaderCSS;
  
  return (
    <CSSWrapper
      $color={color as string}
      $background={background as string}
      style={style}
    >
      { children }
    </CSSWrapper>
  );
}
  
