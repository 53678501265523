import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { DNDContext }       from 'app/ui-v2/editor-instruction/dnd-context';
import HLS_DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import useDNDDataSet from "app/ui-v2/editor-instruction/hooks/use-dnd-data-set";

import CellIndexDraggedViewComponent from './cell-index-dragged-view';
import { DraggerWrapper } from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellIndexDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    cellAddr, 
    children, 
  } = props;

  const setDNDData = useDNDDataSet();

  const handleDragStart = () => {
    const rowAddr: Types.RowAddr = {
      sectionId: cellAddr.sectionId,
      rowId: cellAddr.rowId
    }

    const data = { rowAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  return (
    <HLS_DraggerComponent
      dndContext={DNDContext.viewContent.table.row}
      onDragStart={handleDragStart}
      Wrapper={DraggerWrapper}
      draggedView={
        <CellIndexDraggedViewComponent cellAddr={cellAddr} />
      }
    >
      { children }
    </HLS_DraggerComponent>
  );
}
