import produce from 'immer';

import * as Defaults from './defaults';
import * as State from './state';
import * as Tools from './tools';
import * as Types from './types';


class Clicks {
  private _state: State.State;
  private _listener: Types.Listener;

  constructor() {
    this._state = State.createInitialState();
    this._listener = null;
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */
  addClick(props: Types.ClickPartialProps) {
    const clickAddr = Tools.createClickAddr();
    const clickProps = Defaults.getClickProps(props);

    this._state = produce(this._state, draft => {
      const clicksAddrs = State.getClicksAddrs(draft);
      const clicksProps = State.getClicksProps(draft);

      const clickKey = Tools.getClickKey(clickAddr);

      clicksAddrs.push(clickAddr);
      clicksProps[clickKey] = clickProps;
    });

    this.runListener();

    return clickAddr;
  }

  removeClick(clickAddr: Types.ClickAddr) {
    this._state = produce(this._state, draft => {
      const clicksAddrs = State.getClicksAddrs(draft);
      const clicksProps = State.getClicksProps(draft);

      const clickIdx = State.getClickIdx(draft, clickAddr);
      const clickKey = Tools.getClickKey(clickAddr);

      clicksAddrs.splice(clickIdx, 1);
      delete clicksProps[clickKey];
    });

    this.runListener();
  }

  addListener(listener: Types.Listener) {
    this._listener = listener;
  }

  removeListener() {
    this._listener = null;
  }

  private runListener() {
    if (this._listener === null) {
      return;
    }

    this._listener(this._state);
  }

  /**
   * 
   * Getters
   * 
   */

  /**
   * Clicks
   */
  getClicksAddrs(): Types.ClicksAddrs { 
    return State.getClicksAddrs(this._state);
  }
  
  getClicksProps(): Types.ClicksProps { 
    return State.getClicksProps(this._state);
  }


  /**
   * Click
   */
  getClickProps(
    clickAddr: Types.ClickAddr,
  ): Types.ClickProps { 
    return State.getClickProps(this._state, clickAddr);
  }

  getClickIdx(
    clickAddr: Types.ClickAddr,
  ): number { 
    return State.getClickIdx(this._state, clickAddr);
  }

  /**
   * Manual tests
   */

    _test1() {
      this.addClick({
        position: [200, 340], 
        onDone: () => { console.log("done called"); }
      });
    }

}

export default Clicks;