import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import HLC_MarkerViewComponent from 'app/ui-v2/editor-instruction/hls/markers/marker-view';
import { useDocState } from 'app/ui/contexts/document';
import useAssetsRepo from '../../../../hook/use-assets-repo';
import ImageViewComponent from '../../../parts/image-view';


interface Props {
  markerAddr: ContentTypes.MarkerAddr;
}


export const MarkerComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
  } = props;

  const assetsRepo = useAssetsRepo();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const document = useDocState();
  const markerProps = document.content.cellMarkers_getMarkerProps(markerAddr);


  useEffect(() => {
    if ( ! wrapperRef.current ) {
      return;
    }

    if ( ! assetsRepo ) {
      console.warn(`Assets repo not ready`);
      return;
    }

    const element = wrapperRef.current;
    assetsRepo.cellMarkers.addElement(markerAddr, element);

    return () => {
      assetsRepo.cellMarkers.removeElement(markerAddr);
    }
  }, []);

  return (
    <ImageViewComponent ref={wrapperRef}>
      <HLC_MarkerViewComponent markerAddr={markerProps.repoMarkerAddr} />
    </ImageViewComponent>
  );
}

