import { atom }           from 'recoil';
import { selector }       from 'recoil';
import { selectorFamily } from 'recoil';

import { ContentViewState as State} from 'app/arch/editor-instruction/document/states/persistent/content-view';

/**
 * State
 */

export const state = atom<State.State>({
  key: "edi_doc_viewContent",
  default: State.createInitialState()
});


/**
 * Headers
 */
export const headerMetaVisible = selector({
  key: "edi_doc_viewContent_headerMetaVisible",
  get: ({ get }) => {
    const stateObj = get(state);
    const visible = State.getHeader(stateObj).meta.visible;
    return visible;
  }
});

export const headerInternalVisible = selector({
  key: "edi_doc_viewContent_headerInternalVisible",
  get: ({ get }) => {
    const stateObj = get(state);
    const visible = State.getHeader(stateObj).internal.visible;
    return visible;
  }
});
export const headerFieldsVisible = selector({
  key: "edi_doc_viewContent_headerFieldsVisible",
  get: ({ get }) => {
    const stateObj = get(state);
    const visible = State.getHeader(stateObj).fields.visible;
    return visible;
  }
});

export const headerMarkersVisible = selector({
  key: "edi_doc_viewContent_headerMarkersVisible",
  get: ({ get }) => {
    const stateObj = get(state);
    const visible = State.getHeader(stateObj).markers.visible;
    return visible;
  }
});
