import produce from 'immer';
import * as State from './state';
import * as Types from "./types"


export class View {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  reset() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }

  /**
   * Setters
   */
  update(update: Types.ViewUpdate) {
    this._state = produce(this._state, draft => {
      Object.assign(draft, update);
    });
  }


  /**
   * Getters
   */
  getView() {
    return State.getView(this._state);
  }
}
