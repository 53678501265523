import React from 'react';
import { useRef } from 'react';
import { RefObject } from 'react';

import { useIsMobile } from 'lego-hooks/use-is-mobile';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useWidgetEdit   from 'app/ui-v2/editor-image/hooks/widget/use-widget-edit';
import useDoubleTap from 'app/ui-v2/app/hooks/use-double-tap';
import useCaretOnPoint from './use-caret-on-point-set';

import { MainWrapper } from "./styles";
import { Input } from "./styles";


interface Props {
  widgetAddr: ContentTypes.WidgetAddr;
  editorTextRef: RefObject<HTMLDivElement | null>;
  editDisabled: boolean;

  children: React.ReactNode;
}


// This should be called WidgtetEditorTextEdit
export const WidgetEditorTextEditComponent: React.FC<Props> = (props: Props) => {
  const isMobile = useIsMobile();

  const {
    editorTextRef,
    widgetAddr,
    editDisabled,

    children,
  } = props;


  const docState = useDocState();
  const editWidget = useWidgetEdit();
  const setCaretOnPoint = useCaretOnPoint({
    editorTextRef,
  });
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const onDoubleTap = (event: React.TouchEvent) => {
    if ( ! isMobile ) {
      return;
    }

    if (event.touches.length === 0) {
      const msg = `No touch to process :(`;
      console.warn(msg);
      return;
    }

    hiddenInputRef.current?.focus();

    const touch = event.touches[0];
    __edit(touch.clientX, touch.clientY);

    editorTextRef.current?.focus();
  }

  const handleDoubleTap = useDoubleTap({
    onDoubleTap
  });


  const handleDblClick = (event: React.MouseEvent) => {
    if ( isMobile ) {
      return;
    }

    __edit(event.clientX, event.clientY);
  }

  const __edit = (x: number, y: number) => {
    const widgetEdit = docState.editorImageSession.getWidgetEdited();
    if ( widgetEdit !== null && ContentTools.compareWidgetAddr(widgetEdit, widgetAddr) ) {
      // Do nothing if is already being edited, 
      // and let double click be handle by underlying
      // EditorText - to select text underneath cursor
      console.debug("Skip widget text edit, already editing")
      return;
    }

    editWidget({
      widgetAddr,
      editDisabled,
    });

    setCaretOnPoint(x, y);
  }

  return (
    <MainWrapper 
      onDoubleClick={handleDblClick}
      onTouchStart={handleDoubleTap}
    >
      {
        isMobile &&
        <Input
          ref={hiddenInputRef}
          type="text"
        />
      }
    { children }
    </MainWrapper>
  );
}
