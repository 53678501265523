import jtl from "tools/jtl";
import DocState from "app/arch/editor-instruction/document/states/doc-state";


const getCssAttrib = (cssAttrib: string) => {
  const attrib = jtl.color.expandHex(cssAttrib) as string;
  return attrib.slice(1);
}


class TableHeader {
  private _docState: DocState;

  constructor(docState: DocState) {
    this._docState = docState;
  }

  get viewCommon() {
    return this._docState.viewsCommon;
  }

  get css() {
    return this.viewCommon.getTableHeaderCSS();
  }
  
  get background(): string {
    const css = this.css;
    const background = css.background;

    if (typeof(background) !== 'string') {
      const msg = `Invalid background color of table header: ${background}`
      throw new Error(msg);
    }

    const bgColor = getCssAttrib(background);
    return bgColor;
  }
}

export default TableHeader;