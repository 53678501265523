import * as Tools from 'app/arch/editor-instruction/document/states/persistent/content/tools';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters   from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useColumnsAutoAdjustBase from 'app/ui-v2/editor-instruction/views/shared/hooks/use-columns-auto-adjust';
import useColumnsWidthSignal    from './use-columns-width-signal';


const useColumnsAutoAdjust = () => {
  const document = useDocState();

  const {
    setContent
  } = useEditorStatesSetters();

  const signalColumnsWidths = useColumnsWidthSignal();
  const columnAutoAdjust = () => Tools.columnsAutoAdjust(document);

  const hook = useColumnsAutoAdjustBase({
    columnAutoAdjust,
    signalColumnsWidths,
    setRecoilState: setContent,
  });

  return hook;
}


export default useColumnsAutoAdjust;
