import styled from "styled-components";
import MenuItem   from "lego/styles/menu_item";


export const Item = styled(MenuItem)`
  height: 30px;
  gap: ${props => props.theme.defs.gap.small};
  /* background: blue; */
`;


export const TextWrapper = styled.div`
  overflow: clip;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* background-color: red; */
`;

