import React from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';

import SidePanelComponent      from 'lego/components/side-controls/side-panel';
import { PanelType }           from 'lego/components/side-controls/types';
import { PanelControlsConfig } from 'lego/components/panel-controls/config';
import FloatingPanelComponent  from 'lego-v2/floating-panel';

import { Position } from 'app/arch/types';

import { useSetupContextPanelHook } from '../../hooks/use-context-panel';
import ScreenFitterComponent        from '../screen-fitter';


interface Props {
}


export const ContextPanelComponent: React.FC<Props> = (props: Props) => {
  const [position, setPosition]     = useState<Position>([0, 0]);
  const [panelConfig, setPanelConfig] = useState<PanelControlsConfig | null>(null);

  const setupContextPanelHook = useSetupContextPanelHook()

  useEffect(() => {
    setupContextPanelHook({
      setConfig:   (config: PanelControlsConfig | null) => setPanelConfig(config),
      setPosition: (position_: Position) => setPosition(position_)
    });
  });

  const handlePanelClose = () => {
    setPanelConfig(null);
  }

  return (
    <>
    {
      panelConfig && 
      <ScreenFitterComponent
        position={position}
      >
        <FloatingPanelComponent
          title={"TODO-TODO"}
          onClose={handlePanelClose}
          // position={position}
        >
          <SidePanelComponent
            config={panelConfig}
            visible={true}
          />
        </FloatingPanelComponent>
      </ScreenFitterComponent>
    }
    </>
  );
}
  
