import { useContext } from "react";
import { CellContext } from "../cell-context";


const useCellContext = () => {
  const context = useContext(CellContext)
  return context;
}


export default useCellContext;