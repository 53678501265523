import styled from 'styled-components';

export const MainWrapper = styled.div`
  box-sizing: border-box;
  overflow: clip;
  width: 210mm;
  height: 297mm;
  background-color: rgba(0, 255, 0, 0.4);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;


export const Box = styled.div`
  border: 1px solid black;
  box-sizing: border-box;
  width: 50%;
  height: 50%;
`;
