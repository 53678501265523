import { useRecoilValue } from 'recoil';

import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { UIState_HeaderFields } from 'app/ui/states/editor-instruction';
import { UIState_ContentView } from 'app/ui/states/editor-instruction';


const useStates = () => {
  const css = useRecoilValue(UIState_HeaderFields.tableHeaderCSS);
  const visible = useRecoilValue(UIState_ContentView.headerFieldsVisible);
  const visibleNone = (visible === TypesShared.PageItemVisibility.NONE);
  
  
  return {
    css,
    visible,
    visibleNone,
  }
}


export default useStates;