import styled from "styled-components";

export interface GridCellProps {
  borderTopFaded?: boolean;
  borderLeft?: boolean,
  borderBottom?: boolean,
  faded?: boolean,
};


const BORDER_COLOR = 'black';
const BORDER_FADED = 'rgba(0, 0, 0, 0.3)';
const COLOR_FADED  = 'rgba(0, 0, 0, 0.3)';
const BACKGROUND_FADED = 'rgba(180, 180, 180, 0.1)';


export const GridCell = styled.div<GridCellProps>`
  box-sizing: border-box;

  border-width: 1px;

  border-top-style:   solid;
  border-right-style: solid;
  border-left-style:   ${props => props.borderLeft ? 'solid' : 'none'};
  border-bottom-style: ${props => props.borderBottom ? 'solid' : 'none'};

  border-top-color:    ${props => props.borderTopFaded ? BORDER_FADED : BORDER_COLOR}; 
  border-bottom-color: ${props => props.faded ? BORDER_FADED : BORDER_COLOR};
  border-left-color:   ${props => props.faded ? BORDER_FADED : BORDER_COLOR};
  border-right-color:  ${props => props.faded ? BORDER_FADED : BORDER_COLOR};

  cursor:     ${props => props.faded ? "pointer" : "unset" };
  color:      ${props => props.faded ? COLOR_FADED : "unset" };
  background: ${props => props.faded ? BACKGROUND_FADED : "unset" };
`;



export default GridCell;
