import React from 'react';
import * as Types from '../../types';

import { Section } from './styles';
import { Separator } from './styles';


interface Props {
  config: Types.Section;
  last: boolean;
  NavbarPanelSectionItem: React.ElementType;

}


export const NavbarPanelSectionComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
    last,
    NavbarPanelSectionItem
  } = props;

  const renderItems = (items: Types.SectionItem[]) => {
    const ItemsComps = items.map((item: Types.SectionItem, idx: number) => (
      <NavbarPanelSectionItem 
        key={idx}
        config={item}
      />
    ));

    return ItemsComps;
  }

  return (
    <Section>
      { renderItems(config.items) }
      { last || <Separator /> }
    </Section>
  );
}
