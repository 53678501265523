import React from 'react';
import { MenuSection } from 'lego-v2/menu/config';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import TopToolbarComponent from 'app/ui-v2/editor-instruction/blocks/top-toolbar';
import useConfig from './config';


interface Props {
  widgetSelected: ContentTypes.WidgetAddr | null
}


export const ToolbarWidgetSelectedComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetSelected
  } = props;

  const getConfigSections = useConfig(widgetSelected);
  const sections: MenuSection[] = getConfigSections();


  return (
    <TopToolbarComponent sections={sections} />
  );
}

