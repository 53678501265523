import { useRef } from 'react';
import { useDragging } from './use-dragging';
import { Props as PropsDraggingBase } from './use-dragging';


export type DeltaMove = {
  x: number, 
  y: number
};


interface Props extends Omit<PropsDraggingBase, "onDragMove" | "onDragEnd"> {
  onDragMove:(delta: DeltaMove) => void;
  onDragEnd:(delta: DeltaMove) => void;
};


export const useDraggingDelta = (props: Props) => {
  const {
    onDragMove,
    onDragEnd,
  } = props;

  const startPosition = useRef<DeltaMove | null>({x: 0, y:0});

  const getDeltaMove = (event: PointerEvent) => {
    if (startPosition.current === null) {
      startPosition.current = {
        x: event.clientX,
        y: event.clientY
      }
    }

    const x = event.clientX - startPosition.current.x;
    const y = event.clientY - startPosition.current.y;

    const delta = {x, y};
    return delta;  
  }

  const handlePointerMove = (event: PointerEvent) => {
    const delta = getDeltaMove(event);
    onDragMove(delta);
  }

  const handlePointerEnd = (event: PointerEvent) => {
    const delta = getDeltaMove(event);
    onDragEnd(delta);
  }

  const {
    onDragMove: __onDragMove,
    onDragEnd: __onDragEnd,
    ...baseProps 
  } = props;

  const {
    startDragging,
  } = useDragging({
    ...baseProps,
    onDragMove: handlePointerMove, 
    onDragEnd: handlePointerEnd
  });


  const handleStartDragging = (event: React.PointerEvent) => {

    const started = startDragging(event);
    if ( ! started ) {
      return false;
    }

    startPosition.current = {
      x: event.clientX,
      y: event.clientY
    };

    return true;
  }

  return {
    startDragging: handleStartDragging,
  };
};
