import { useRecoilValue } from "recoil";
import { UIState_ViewsCommon } from "app/ui/states/editor-instruction";


const usePageMarginsLocked = () => {
  const pageMarginsLocked = useRecoilValue(UIState_ViewsCommon.pageMarginsLocked);
  return pageMarginsLocked;
}


export default usePageMarginsLocked;