import styled from "styled-components";

export const MenuBar = styled.div`
  display: flex;

  width: 36px;
  height: 36px;
  box-sizing: border-box;

  background:    ${props => props.theme.menuBar.background};
  border-style:  ${props => props.theme.menuBar.border.style};
  border-width:  ${props => props.theme.menuBar.border.width};
  border-color:  ${props => props.theme.menuBar.border.color};
`;

export default MenuBar;