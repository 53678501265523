import { Position  } from "app/arch/types";
import { WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";


export enum ShapeLayerType  {
  BOTTOM = 'bottom',
  MIDDLE = 'middle',
  TOP    = 'top'
}


export interface ShapeProps {
  layer: ShapeLayerType;
  style: WidgetsStylesTypes.StyleAttrs;
  startPoint: Position;
  endPoint: Position;
}