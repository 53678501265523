import React, { useEffect, useRef } from 'react';
import { useState } from 'react';

import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe }      from '@stripe/react-stripe-js';
import { useElements }    from '@stripe/react-stripe-js';

import environment from 'app/environment';
import { StripeTypes } from '..';

import AppNameComponent from './app-name';
import ProductInfoComponent from './product-info';
import CheckoutControlsComponent from './checkout-controls';

import { CheckoutControlsWrapper, ErrorText, MainWrapper } from './styles';
import { ProductInfoWrapper } from './styles';
import { StripePaymentWrapper } from './styles';
import { StyleForm } from './styles';
import useToastAdd from 'app/ui-v2/app/__modules/info/components/toasts/hooks/use-toast-add';
import { InfoToastsTypes } from 'app/arch/app/info';


const PAYMENT_RETURN_URL = `${environment.frontend.url}/redirect/payment/done`;


interface Props {
  product: StripeTypes.Product;
  onCancel: () => void;
}


export const StripeSubscriptionCheckoutComponent: React.FC<Props> = (props: Props) => {
  const { 
    product,
    onCancel, 
  } = props;
  
  const [processing, setProcessing ] = useState(false);
  const [formLoaded, setFormLoaded ] = useState(false);
  const [error, setError] = useState<null | string>();

  const stripe   = useStripe();
  const elements = useElements();

  const formRef = useRef<HTMLFormElement>(null);

  const btnsDisabled = (! stripe || processing );
  const addToast = useToastAdd()


  const getUrl = () => {
    const url = `${PAYMENT_RETURN_URL}`;
    return encodeURI(url);
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if ( ! stripe || ! elements ) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    setProcessing(true);
    const return_url = getUrl();

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url
      },
    });

    const error = result.error;
    if (error) {
        if (error.message) {
          console.warn(error.message);
          showError(error.message);
        }
    } else {
      // console.warn(result)

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    setProcessing(false);
  };

  const handleFormLoaded = () => {
    setFormLoaded(true);
  }

  const handlePay = () => {
    if (formRef.current === null) {
      const msg = `Stripe form not ready`;
      throw new Error(msg);
    }

    formRef.current.requestSubmit();
  }

  const handleCancel = () => {
    onCancel();
  }

  const showError = (error: string) => {
    setError(error);
    addToast({
      level: InfoToastsTypes.ToastLevel.ERROR,
      text: error,
    });
  }

  return (
    <MainWrapper
      $loaded={formLoaded}
    >
      <ProductInfoWrapper>
        <AppNameComponent />
        <ProductInfoComponent product={product} />
        {
          error && 
          <ErrorText>
            { error }
          </ErrorText>
        }

      </ProductInfoWrapper>

      <StyleForm 
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <StripePaymentWrapper>
          <PaymentElement onReady={handleFormLoaded} />
        </StripePaymentWrapper>
        
        <CheckoutControlsWrapper>
          <CheckoutControlsComponent 
            disabled={btnsDisabled}
            onPay={handlePay}
            onCancel={handleCancel}
          />
          
        </CheckoutControlsWrapper>
      </StyleForm>
    </MainWrapper>
  );
}