import Logger from 'libs/debug';
import * as Types from './types';


export const getLogger = () => Logger.getDocRepos();


/* 
  From repos, get repos which user can edit.
 */
export const getReposEditable = (
  repos: Types.RepoPack[], 
  memberUsername: string
) => {
  const reposEditable = repos.filter((repo) => {
    const userMemberTmp = repo.members.filter((member) => {
      return member.username === memberUsername;
    });

    if (userMemberTmp.length !== 1) {
      const msg = `Invalid repo member count: ${userMemberTmp.length}`;
      throw new Error(msg);
    } 

    const userMember = userMemberTmp[0];

    const validAccess = hasAccessWrite(userMember.access);
    const validStatus = (userMember.status === Types.MemberStatus.INVITE_ACCEPTED);
    const validRepo   = (validAccess && validStatus);
    
    return validRepo;
  });

  return reposEditable;
}


export const hasAccessWrite = (
  memberAccess: Types.MemberAccess
): boolean => {
  const writeAccesses: Types.MemberAccess[] = [
    Types.MemberAccess.OWNER,
    Types.MemberAccess.ADMIN,
    Types.MemberAccess.EDITOR,
  ];

  return writeAccesses.includes(memberAccess);
}

export const hasAccessListMembers = (
  memberAccess: Types.MemberAccess
): boolean => {
  const listMembersAccesses: Types.MemberAccess[] = [
    Types.MemberAccess.OWNER,
    Types.MemberAccess.ADMIN,
  ];

  return listMembersAccesses.includes(memberAccess);
}

export const hasAccessAddMembers = (
  memberAccess: Types.MemberAccess
): boolean => {
  const addMembersAccesses: Types.MemberAccess[] = [
    Types.MemberAccess.OWNER,
    Types.MemberAccess.ADMIN,
  ];

  return addMembersAccesses.includes(memberAccess);
}
