import { MarkerAddr } from "app/arch/editor-instruction/document/states/persistent/repo-markers/types";
import { RepoMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/repo-markers";
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = (markerAddr: MarkerAddr) => {
  const document = useDocState();

  const {
    setRepoMarkers
  } = useEditorStatesSetters();


  const updateIcon = (props: {
    update: RepoMarkersTypes.IconPropsUpdate,
    skipUndoSave?: boolean,
  }) => {
    const {
      update,
      skipUndoSave
    } = props;

    document.repoMarkers.updateMarkerIcon(markerAddr, update);
    setRepoMarkers();

    if ( skipUndoSave ) {
      return;
    }

    document.saveUndo();
  }

  const updateIconCSS = (props: {
    cssUpdate: React.CSSProperties,
    skipUndoSave?: boolean,
  }) => {
    const {
      cssUpdate,
      skipUndoSave
    } = props;

    document.repoMarkers.updateMarkerIconCSS(markerAddr, cssUpdate);
    setRepoMarkers();

    if ( skipUndoSave ) {
      return;
    }

    document.saveUndo();
  }


  return {
    updateIcon,
    updateIconCSS,
  }
}


export default useFunctions;
