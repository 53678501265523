export enum BillingPeriod {
  DAY   = 'day',
  MONTH = 'month',
  YEAR  = 'year'  
}

export interface Feature {
  name: string;
}

export type Features = Feature[];

export type PriceLookup = {
  [key in BillingPeriod]: string
}

export interface Price {
  lookup: PriceLookup;
  description?: string;
}

export interface PricePlan {
  title: string;
  description: string;
  color: string;
  features: Features;
  price: Price;
}

export enum PlanType {
  FREE = 'free',
  ECO  = 'eco',
  TEAM = 'team'
}


export type PricePlans = {
  [key in PlanType]: PricePlan;
}