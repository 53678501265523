import styled from "styled-components";
import TextWrap from "lego/styles/text-wrap";


const Text = styled(TextWrap)`
  /* background-color: blue; */
`;


export default Text;
