import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";

const useFunctions = (imageAddr: ContentTypes.ImageAddr) => {
  const docState = useDocState();
  
  const {
    setContent,
    setEditorImageSession,
  } = useEditorStatesSetters();

  const handleItemMove = (
    srcWidgetIdx: number, 
    dstWidgetIdx: number
  ) => {
    docState.content.cellImages_image_widget_changeOrder(
      imageAddr,
      srcWidgetIdx,
      dstWidgetIdx
    );

    docState.saveUndo();
    setContent();
  }

  const handleDragStart = (idx: number) => {
    const widgetsAddrs = docState.content.cellImages_image_getWidgetsAddrs(imageAddr);
    const widgetAddr = widgetsAddrs[idx];

    docState.cellImages_image_setWidgetSelected(widgetAddr);
    docState.saveUndo();
    setEditorImageSession();
  }

  return {
    handleItemMove,
    handleDragStart
  }
}

export default useFunctions;