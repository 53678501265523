import styled from "styled-components";


interface Props {
  selected: boolean;
}

const CellSelectedBorder = styled.div<Props>`
  position: absolute;
  pointer-events: none;

  height: 100%;
  width: 100%;

  border-radius: 0px;
  outline-style: ${props => props.selected ? "solid": "none"};
  outline-width: 3px;
  outline-color: ${props => props.theme.defs.accentColor.primary};
`;

export default CellSelectedBorder;