import styled from "styled-components";


export const WindowBorder = styled.div<{maximized?: boolean}>`
  position: relative;
  width:  100%;
  height: 100%;

  box-sizing: border-box;

  border-style: solid;
  border-width: ${props => props.theme.window.border.middle.width}; 
  border-color: ${props => props.theme.window.border.middle.color}; 
  background:   ${props => props.theme.window.border.middle.color};

  border-radius: ${
    props => props.maximized ? 
    '0px' : 
    props.theme.window.border.outer.radius
  };

  box-shadow: 
    0 0 
    0 ${props => props.theme.window.border.outer.width}
    ${props => props.theme.window.border.outer.color};
`;

export default WindowBorder;
