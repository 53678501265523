import { ThemeDefProps } from "../types";


export const themeDefs_input = (props: ThemeDefProps) => {
  const { colors, accentColor, border, padding, font } = props;

  const input = {
    padding: padding.normal,
    color: colors.color.primary,
    background: colors.background.secondary,

    border: {
      radius: border.radius.normal,
      width:  border.width.normal,
      color:  colors.border.secondary,
      style: 'solid'
    }, 

    font: {
      size: font.size.large
    }
  };
    
  return input;
}
