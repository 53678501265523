import styled from "styled-components";
import SignBase from "./sign-base";
import { Config } from "../config";


const SignDiamond = styled(SignBase)`
  width:  ${Config.shape.diamond.size}px;
  height: ${Config.shape.diamond.size}px;

  border-radius: 10px;
  transform: rotate(45deg);
`;


export default SignDiamond;