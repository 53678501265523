import styled from "styled-components";


export const Anchor = styled.div`
  position: relative;
`;

export const Detacher = styled.div`
  position: absolute;
`;
