import styled from "styled-components";
import SectionBase from "../../styles/navbar-panel-section/section";
import SeparatorBase from "../../styles/navbar-panel-section/separator";


export const Section = styled(SectionBase)`
`;

export const Separator = styled(SeparatorBase)`
`;
