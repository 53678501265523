import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config";

import { MarkerAddr } from "app/arch/editor-instruction/document/states/persistent/repo-markers/types";
import { RepoMarkersTools } from "app/arch/editor-instruction/document/states/persistent/repo-markers";
import { RepoMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/repo-markers";

import { useTranslations } from "app/ui/hooks/app/use-translation";

import useFunctions from "./use-functions";
import useStates from "./use-states";


const useItems = (markerAddr: MarkerAddr) => {
  const t = useTranslations();
  const fns = useFunctions(markerAddr);
  const states = useStates(markerAddr)

  const markerGroup = RepoMarkersTools.getMarkerGroup(states.markerProps.type);

  const getMarkerGroup = () => ({
    // title: t('marker group'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        text: t('marker group, sign'),
        value: RepoMarkersTypes.MarkerGroup.SIGN,
        selected: markerGroup === RepoMarkersTypes.MarkerGroup.SIGN,
      }, 
      {
        text: t('marker group, icon'),
        value: RepoMarkersTypes.MarkerGroup.ICON,
        selected: markerGroup === RepoMarkersTypes.MarkerGroup.ICON,
      },
      {
        text: t('marker group, label'),
        value: RepoMarkersTypes.MarkerGroup.LABEL,
        selected: markerGroup === RepoMarkersTypes.MarkerGroup.LABEL,
      },        
    ],
    onClick: fns.updateMarkerGroup
  });

  return {
    getMarkerGroup,
  }
}

export default useItems;
