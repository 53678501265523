import produce from 'immer';
import * as CustomFieldsTypes from '../../persistent/header-fields/types';
import * as State from './state';


export class HeaderFieldsSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  //----------------------
  // Setters
  //

  /**
   * Page Cell
   */
  setPageCellSelected(
    pageCellAddr: CustomFieldsTypes.PageCellAddr | null
  ) {
    this._state = produce(this._state, draft => {
      draft.pageCell.selected = pageCellAddr;
    });
  }


  //----------------------
  // Getters
  //


  /**
   * Page Cell
   */
  getPageCellSelected(
  ): CustomFieldsTypes.PageCellAddr | null {
    return State.getPageCellSelected(this._state);
  }

  isPageCellSelected(
    pageCellAddr: CustomFieldsTypes.PageCellAddr
  ): boolean {
    return State.isPageCellSelected(this._state, pageCellAddr);
  }
}
