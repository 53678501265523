import produce from 'immer';

import { RepoMarkersTypes } from '../repo-markers';
import { RepoMarkersTools } from '../repo-markers';

import * as State from './state';
import * as Types from './types';
import * as Tools from './tools';
import * as Defaults from './defaults';



export class HeaderMarkers {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Add Marker
   */
  addMarker(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr
  ) {
    const markersAddrs = this.getMarkersAddrs();
    const idx = markersAddrs.length;
    const markerAddr = this.__addMarker(repoMarkerAddr, idx);
    
    return markerAddr;
  }

  addMarkerLeft(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr, 
    srcMarkerAddr: Types.MarkerAddr
  ) {
    const idx = this.getMarkerIdx(srcMarkerAddr);
    const markerAddr = this.__addMarker(repoMarkerAddr, idx);
    
    return markerAddr;
  }

  addMarkerRight(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr, 
    srcMarkerAddr: Types.MarkerAddr
  ) {
    const idx = this.getMarkerIdx(srcMarkerAddr);
    const markerAddr = this.__addMarker(repoMarkerAddr, idx + 1);
    
    return markerAddr;
  }

  addMarkerAtStart(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr
  ) {
    const markerAddr = this.__addMarker(repoMarkerAddr, 0);
    return markerAddr;
  }

  private __addMarker(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr,
    idx: number
  ): Types.MarkerAddr {
    const markerAddr = Tools.createMarkerAddr();

    this._state = produce(this._state, draft => {
      const markersAddrs = State.getMarkersAddrs(draft);
      const markersProps = State.getMarkersProps(draft);

      const markerKey = Tools.getMarkerKey(markerAddr);
      const markerProps = Defaults.getMarkerProps(repoMarkerAddr);

      markersAddrs.splice(idx, 0, markerAddr);
      markersProps[markerKey] = markerProps;
    });

    return markerAddr;
  }


  /**
   * Remove Marker(s)
   */
  removeMarkers(
    repoMarkerAddr: RepoMarkersTypes.MarkerAddr
  ) {
    this._state = produce(this._state, draft => {
      const markersAddrs = State.getMarkersAddrs(draft);
      const markersAddrsToDelete = markersAddrs.filter((markerAddr) => {
        const markerProps = State.getMarkerProps(draft, markerAddr);
        return RepoMarkersTools.compareMarkerAddr(markerProps.repoMarkerAddr, repoMarkerAddr);
      });

      markersAddrsToDelete.forEach((markerAddr) => {
        this.__removeMarker(draft, markerAddr);
      });
    });
  }

  removeMarker(markerAddr: Types.MarkerAddr) {
    this._state = produce(this._state, draft => {
      this.__removeMarker(draft, markerAddr);
    });
  }

  private __removeMarker(
    draft: State.State,
    markerAddr: Types.MarkerAddr
  ) {
    const markersAddrs = State.getMarkersAddrs(draft);
    const markersProps = State.getMarkersProps(draft);

    const markerKey = Tools.getMarkerKey(markerAddr);
    const markerIdx = State.getMarkerIdx(draft, markerAddr);

    markersAddrs.splice(markerIdx, 1);
    delete markersProps[markerKey];
  }



  moveMarker(
    srcMarkerAddr: Types.MarkerAddr, 
    dstMarkerAddr: Types.MarkerAddr
  ) {
    const markersEqual = Tools.compareMarkerAddr(srcMarkerAddr, dstMarkerAddr);
    if (markersEqual) {
      console.debug("The same marker - skip marker move");
      return;
    }

    this._state = produce(this._state, draft => {
      const markersAddrs = State.getMarkersAddrs(draft);
    
      const srcMarkerIdx = State.getMarkerIdx(draft, srcMarkerAddr)
      const dstMarkerIdxTmp = State.getMarkerIdx(draft, dstMarkerAddr)
    
      const srcIdxLowerThanDstIdx = (srcMarkerIdx < dstMarkerIdxTmp);
      markersAddrs.splice(srcMarkerIdx, 1)[0];
    
      const dstMarkerIdx = State.getMarkerIdx(draft, dstMarkerAddr);
    
      if (srcIdxLowerThanDstIdx) {
        markersAddrs.splice(dstMarkerIdx + 1, 0, srcMarkerAddr);
      }
      else {
        markersAddrs.splice(dstMarkerIdx, 0, srcMarkerAddr);
      }
    });
  }

  
  //----------------------
  // 
  // Getters



/**
 * Markers
 */
  getMarkers() {
    return State.getMarkers(this._state);
  }

  getMarkersAddrs() {
    return State.getMarkersAddrs(this._state);
  }

  getMarkersProps() {
    return State.getMarkersProps(this._state);
  }

  /**
   * Marker
   */
  getMarkerProps(markerAddr: Types.MarkerAddr) {
    return State.getMarkerProps(this._state, markerAddr);
  }

  getMarkerIdx(markerAddr: Types.MarkerAddr) {
    return State.getMarkerIdx(this._state, markerAddr);
  }
}
