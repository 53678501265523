import produce from 'immer';
import * as State from './state';
import * as Types from "./types"


export class UserPanel {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  reset() {
    this._state = State.createInitialState();
  }
  
  get state() { return this._state; }

  /**
   * Setters
   */
  update(update: Types.PanelUpdate) {
    this._state = produce(this._state, draft => {
      draft.panel = {
        ...draft.panel,
        ...update,
      }
    });
  }


  /**
   * Getters
   */
  getPanel() {
    return State.getPanel(this._state);
  }
}
