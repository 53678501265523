import { UUID } from 'app/arch/types';
import KeyboardBindings from 'lego/components/key-bindings/arch/keyboard-bindings';
import { KeyBinding   } from 'lego/components/key-bindings/arch/types';


const useKeyBindings = () => {
  const addKeyDownBinding = (binding: KeyBinding) => {
    return KeyboardBindings.addKeyDownBinding(binding);
  }

  const removeKeyDownBinding = (bindingId: UUID) => {
    KeyboardBindings.removeKeyDownBinding(bindingId);
  }

  const addKeyUpBinding = (binding: KeyBinding) => {
    return KeyboardBindings.addKeyUpBinding(binding);
  }

  const removeKeyUpBinding = (bindingId: UUID) => {
    KeyboardBindings.removeKeyUpBinding(bindingId);
  }

  const setBindingsEnabled = (enabled:boolean) => {
    KeyboardBindings.enabled = enabled;
  }

  const getBindingsEnabled = () => {
    return KeyboardBindings.enabled;
  }

  return {
    addKeyDownBinding,
    removeKeyDownBinding,
    
    addKeyUpBinding,
    removeKeyUpBinding,

    setBindingsEnabled,
    getBindingsEnabled
  };
}

export default useKeyBindings;