import environment from "app/environment";
import Mouse  from "./mouse";
import Clicks from "./clicks";
import Infos  from "./infos";


class Demo {
  private _active: boolean;

  private _mouse: Mouse;
  private _clicks: Clicks;
  private _infos: Infos;


  constructor() {
    this._active = environment.demo.active;

    this._mouse = new Mouse();
    this._clicks = new Clicks();
    this._infos = new Infos();
  }

  get active() { return this._active; }
  set active(active: boolean) { this._active = active; }

  get mouse() { return this._mouse; }
  get clicks() { return this._clicks; }
  get infos() { return this._infos; }
}


export default Demo;