import { LexicalTools } from "app/arch/tools";
import * as Types from './types';



/**
 * Releaselogs
 */
export const getReleaselogs = (
): Types.Releaselogs => ({
  addrs: [],
  props: {},
  changelogs: {},
});


/**
 * Releaselog
 */
export const getReleaselogInfoDescription_ = (
): Types.ReleaselogInfoDescription => ({
  text: null,
  visible: false,
});

export const getReleaselogInfo_ = (
): Types.ReleaselogInfo => ({
  date: null,
  revision: null,
  description: getReleaselogInfoDescription_(),
});

export const getReleaselogProps = (
): Types.ReleaselogProps => ({
  editable: true,
  info: getReleaselogInfo_()
});

export const getReleaselogChangelog = (
): Types.ReleaselogChangelog => ({
  columns: getColumns(),
  rows:    getRows(),
  cells:   getCells(),
});


/**
 * Columns
 */
export const getColumns = (
): Types.Columns => ({
  addrs: [],
  props: {},
});

  
/**
 * Column
 */
export const getColumnProps = (
  columnType: Types.ColumnType
): Types.ColumnPropsTypes => {

  type ColumnCreateFn = () => Types.ColumnPropsTypes

  const ColumnCreateFnMap: {[key in Types.ColumnType]: ColumnCreateFn} = {
    [Types.ColumnType.INDEX] : __getColumnIndexProps,
    [Types.ColumnType.TEXT ] : __getColumnTextProps,
  }

  const createColumnPropsFn = ColumnCreateFnMap[columnType];
  const columnProps = createColumnPropsFn();
  
  return columnProps;
}
  
const __getColumnIndexProps = (): Types.ColumnIndexProps => ({
  ...__getColumnBaseProps(Types.ColumnType.INDEX),
  css: {
    padding: '5px',
    fontSize: '14px',
    fontWeight: '600',

    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  },
});

const __getColumnTextProps = (): Types.ColumnTextProps => ({
  ...__getColumnBaseProps(Types.ColumnType.TEXT),
})


const __getColumnBaseProps = (
  columnType: Types.ColumnType
): Types.ColumnProps => ({
  type: columnType,
  name: "change me",
  width: 200,
  css: {
    padding: '5px',
  },
  header: {
    css: {
      /*
        Do not put here props like:
        background, color, fontSize etc, 
        as they are set as part table.header defaults.
        Putting them here will mess things up, for
        example putting background here - will stop
        selected header cell background from being
        accent color.

        Is this true? 
        This has been copied from releaselogs.
        I guess it is true but not sure.
      */
      justifyContent: "flex-start"
    }
  }
});


/**
 * Rows
 */
export const getRows = (
): Types.Rows => ({
  addrs: [],
  props: {},
});


/**
 * Row
 */
export const getRowProps = (
): Types.RowProps => ({
});


/**
 * Cells
 */
export const getCells = (): Types.Cells => ({
});


/**
 * Cell
 */
export const getCell = (
  cellType: Types.ColumnType
): Types.CellTypes => {
  type CellCreateFn = () => Types.CellTypes

  const CellCreateFn: {[key in Types.ColumnType]: CellCreateFn} = {
    [Types.ColumnType.INDEX]: __getCellIndex,
    [Types.ColumnType.TEXT ]: __getCellText,
  }

  const cellCreateFn = CellCreateFn[cellType];
  const cell = cellCreateFn();
  
  return cell;
}

const __getCellIndex = (): Types.IndexCell => ({
});

const __getCellText = (): Types.TextCell => ({
  editorState: LexicalTools.getEditorInitStateSerial()
});
  
