import { PanelControlsConfig }              from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import ControlPrintColumnsComponent         from "lego/components/panel-controls/controls/control-print-columns";
import { useTranslations } from 'app/ui/hooks/app/use-translation';


const usePanelConfigColumns = () => {
  const t = useTranslations();

  //--------------------
  // Config definition
  //
  const getConfig = () => {
    const config: PanelControlsConfig = {
      items: [
        {
          title: t('document columns'),
          sections: [
            {
              items: [ 
                {
                  // title: t('document columns'),
                  type: PanelControlsItemSectionItemType.USER_COMPONENT,
                  component: ControlPrintColumnsComponent
                }
              ]
            }
          ]
        },
      ]
    } 

    return config;
  }

  return getConfig;
}
  
export default usePanelConfigColumns;
