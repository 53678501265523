import { useApp } from 'app/ui/hooks/app/use-app';


const useDebug = () => {
  const app = useApp();
  const debug = app.debug;

  return debug;
}


export default useDebug;