import { MarkerAddr } from "app/arch/editor-instruction/document/states/persistent/repo-markers/types";
import { RepoMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/repo-markers";
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = (markerAddr: MarkerAddr) => {
  const document = useDocState();

  const {
    setRepoMarkers
  } = useEditorStatesSetters();

  const updateLabel = (props: {
    update: RepoMarkersTypes.LabelPropsUpdate,
    skipUndoSave?: boolean,
  }) => {
    const {
      update,
      skipUndoSave
    } = props;
    
    document.repoMarkers.updateMarkerLabel(markerAddr, update);
    if ( ! skipUndoSave ) {
      document.saveUndo();
    }
    setRepoMarkers();
  }

  const updateLabelCSS = (props: {
    cssUpdate: React.CSSProperties,
    skipUndoSave?: boolean,
  }) => {
    const {
      cssUpdate,
      skipUndoSave
    } = props;

    document.repoMarkers.updateMarkerLabelCSS(markerAddr, cssUpdate);
    if ( ! skipUndoSave ) {
      document.saveUndo();
    }
    setRepoMarkers();
  }


  return {
    updateLabel,
    updateLabelCSS
  }
}


export default useFunctions;
