import React from 'react';
import { useEffect } from 'react';

import { ViewTypes } from 'app/arch/home-page-guest/states/view';
import useViewParamsRead from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-params-read';
import useGuestGoHome from 'app/ui-v2/home-page/home-page-guest/hooks/use-guest-go-home';

import RequestPasswordResetComponent from './request-password-reset';


interface Props {
}


export const ViewPasswordResetRequestComponent: React.FC<Props> = (props: Props) => {
  const goHome = useGuestGoHome();
  const viewParams = useViewParamsRead() as ViewTypes.ViewParamsPasswordResetRequest;

  useEffect(() => {
    if (viewParams === null) {
      goHome();
    }
  }, [viewParams]);


  if ( viewParams === null ) {
    return null;
  } 

  const {
    email
  } = viewParams;


  return (
    <RequestPasswordResetComponent 
      email={email}
    />
  );
}

