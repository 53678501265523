import produce from 'immer';
import * as Types from './types';
import * as State from './state';


/**
 * This state should not be every saved.
 * It is used to notify document printing
 * process (which happens in backend) that
 * ViewContent and ViewReleaselog has been
 * rendered and PDF generation can take place.
 * It uses recoil.
 */

// All this naming here should be change to views ready.
// As it only describes is view content and view releaselog
// are ready. Once view content and view releaselog are ready
// we need to check if all images have loaded. And if they did
// then printout is read
export class PrintoutView {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */
  setViewLoaded(
    view: Types.ViewType,
    loaded: boolean,
  ) {
    this._state = produce(this._state, draft => {
      draft.viewsLoaded[view] = loaded;
    });
  }

  updatePageCurrent(update: Types.PageCurrentUpdate) {
    console.log(update);
    this._state = produce(this._state, draft => {
      const currentViewPage = draft.pages.current;
      draft.pages.current = {
        ...currentViewPage,
        ...update,
      }
    });
  }

  setPagesCount(view: Types.ViewType, pageCount: number) {
    this._state = produce(this._state, draft => {
      draft.pages.count[view] = pageCount;
    });
  }


  /**
   * Getters
   */
  getViewsLoaded() {
    return State.getViewsLoaded(this._state);
  }

  getPages() {
    return State.getPages(this._state);
  }
}
