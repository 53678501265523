import styled from 'styled-components';


export const FlexEditWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.normal};
  align-items: center;
  justify-content: center;
  /* background-color: blue;; */
`;
