import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_HeaderMeta } from 'app/ui/states/editor-instruction';
import { UIState_EditorLogoSession } from 'app/ui-v2/editor-logo/states';

import EditorLogoPanelComponent from '../editor-logo-panel';
import EditorLogoWindowComponent from '../editor-logo-window';
import { WrapperFixed } from './styles';


interface Props {
}


export const EditorLogoComponent: React.FC<Props> = (props: Props) => {
  const visible       = useRecoilValue(UIState_EditorLogoSession.visible);
  const logoImage     = useRecoilValue(UIState_HeaderMeta.logoImage);
  const logoImageView = useRecoilValue(UIState_HeaderMeta.logoView);
  

  if ( ! logoImage || ! visible ) {
    return null;
  }

  return (
    <WrapperFixed>
      <EditorLogoWindowComponent>
        <EditorLogoPanelComponent
          logoImage={logoImage} 
          logoImageView={logoImageView} 
        />
      </EditorLogoWindowComponent>
    </WrapperFixed>
  );
}

