import { useEffect }  from 'react';

import { query }      from 'app/arch/backend';
import { useQueryV2 } from 'app/arch/backend/use-query-v2';
import { ReposTypes } from 'app/arch/app/states/repos';


const useReposFetch = (props: {
  onDone: (repos: ReposTypes.RepoPackRaw[]) => void;
  onError: (error: string) => void;
}) => {
  const {
    onDone,
    onError
  } = props;

  const { 
    data: reposData,
    error: reposError,
    refetch: reposRefetch
  } = useQueryV2({
    query: query.getRepos(),
  });

  useEffect(() => {
    if (! reposData) {
      return;
    }

    const reposRaw = reposData.repos;
    onDone(reposRaw);
  }, [reposData]);

  useEffect(() => {
    if ( ! reposError) {
      return;
    }

    console.error(reposError);
    onError(reposError.message);
  }, [reposError]);

  return reposRefetch;
}

export default useReposFetch;
