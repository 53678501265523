import React from 'react';
import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import { SectionName } from './styles';


interface Props {
  sectionAddr: TypesContent.SectionAddr;
}


export const SectionNameViewComponent: React.FC<Props> = (props: Props) => {
  const {
    sectionAddr
  } = props;
  
  const document = useDocState();

  const sectionProps = document.content.getSectionProps(sectionAddr);
  const sectionName  = sectionProps.name;

  const sectionNameDisplayed  = (
    sectionName.length !== 0 ?
    sectionName :
    <>&nbsp;</>
  );

  return (
    <SectionName>
      { sectionNameDisplayed }
    </SectionName>
  );
}
  
