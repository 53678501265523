import { atom     }       from 'recoil';
import { selector }       from 'recoil';
import { selectorFamily } from 'recoil';

import { ViewsCommonState as State } from 'app/arch/editor-instruction/document/states/persistent/views-common';


//----------------
// State

export const state = atom<State.State>({
  key: "editor_docOutlookCommon",
  default: State.createInitialState()
});


/**
 * Table
 */
export const tableHeaderCSS = selector({
  key: `editor_docOutlookCommon_tableHeaderCSS`,
  get: ({ get }) => {
    const stateObj = get(state);
    const tableHeader = State.getTableHeader(stateObj);
    return tableHeader.css;
  }
});

/**
 * Page
 */
export const pageLayout = selector({
  key: `editor_docOutlookCommon_pageLayout`,
  get: ({ get }) => {
    const stateObj = get(state);
    const pageLayout = State.getPageLayout(stateObj);
    return pageLayout;
  }
});

export const pageMargins = selector({
  key: `editor_docOutlookCommon_pageMargins`,
  get: ({ get }) => {
    const stateObj = get(state);
    const pageMargins = State.getPageMargins(stateObj);
    return pageMargins;
  }
});

// TODO move to session
export const pageMarginsLocked = selector({
  key: `editor_docOutlookCommon_pageMarginsLocked`,
  get: ({ get }) => {
    const stateObj = get(state);
    const pageMargins = State.getPageMargins(stateObj);
    return pageMargins.locked;
  }
});
