import { useSetRecoilState } from 'recoil';
import { UIState_ContentSignals } from 'app/ui/states/editor-instruction';


const useColumnsVisibleSignal = () => {
  const signalColumnsVisible = useSetRecoilState(UIState_ContentSignals.columnsVisible);

  const hook = () => {
    signalColumnsVisible({});
  }

  return hook;
}

export default useColumnsVisibleSignal;