import { createContext } from 'react';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';


export interface ContextProps {
  content: SlicerTypes.PageItem_Content | null;
};


const Context = createContext<ContextProps>({
  content: null,
});


export default Context;