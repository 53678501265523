import { useState } from 'react';
import { useEffect } from 'react';
import Logger from 'libs/debug';


const useImgsTagsLoaded = (startCheck: boolean) => {
  const [loaded, setLoaded] = useState(false);
  const log = Logger.getDocImgsLoaded();

  const checkImages = () => {
    const images = document.querySelectorAll('img');
    log.debug(`Imgs tags found, count: ${images.length}`);

    const allLoaded = Array.from(images).every((img) => img.complete);
    if (allLoaded) {
      log.debug(`Imgs tags loaded, count: ${images.length}`);
      setLoaded(true);
    }
  };

  const handleImageLoad = () => checkImages();
  const handleImageError = () => checkImages();


  useEffect(() => {
    if ( ! startCheck ) {
      return;
    }
    
    checkImages();

    const images = document.querySelectorAll('img');
    images.forEach((img) => {
      img.addEventListener('load', handleImageLoad);
      img.addEventListener('error', handleImageError);
    });


    return () => {
      images.forEach((img) => {
        img.removeEventListener('load', handleImageLoad);
        img.removeEventListener('error', handleImageError);
      });
    };
  }, [startCheck]);

  return loaded;
};

export default useImgsTagsLoaded;
