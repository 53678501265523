import React from 'react';
import { RefObject } from 'react';

import useRescaleUniversal from 'lego-hooks/rescale/use-rescale-universal';
import { Position } from 'app/arch/types';

import useRescaleReposition from './use-rescaler-reposition';
import { Rescaler } from './styles';


const SCALE_MIN  = 0.1;
const SCALE_MAX  = 8.0;


interface Props {
  deskAreaRef: RefObject<HTMLDivElement>; 

  scale: number;
  onScaleChange: (scaleNew: number) => void;
  position: Position;
  onPositionChange: (position: Position) => void;

  children: React.ReactNode;
}


export const DeskRescalerComponent: React.FC<Props> = (props: Props) => {
  const {
    deskAreaRef,

    scale,
    onScaleChange,
    position,
    onPositionChange,
  
    children,
  } = props;

  const reposition = useRescaleReposition({
    deskAreaRef,
    scale,
    position
  });

  const {
    wheelRescaleEnabled,
    handleWheel,

    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  } = useRescaleUniversal({
    getScale: () => {
      return scale;
    },

    /**
     * Rescale by Wheel
     */

    onWheel: (
      scaleInit, 
      scaleNew, 
      scalePoint,
    ) => {
      rescale(scaleNew, scalePoint);
    },

    /**
     * Rescale by Touch
     */

    onTouchStart: () => {
      // positionInitRef.current = position;
    },

    onTouchMove: (
      scaleInit: number, 
      scaleNew: number, 
      scalePointInit: Position,
      scalePointNew: Position,
    ) => {
      rescale(scaleNew, scalePointNew);
      return;

      // TODO

//     const deskArea = deskAreaRef.current;
//     if ( ! deskArea) {
//       const msg = 'Desk area is not ready';
//       throw new Error(msg);
//     }
    
//     const positionInit = positionInitRef.current !;
//     const deskBBox = deskArea.getBoundingClientRect();
//     const deskPosition = [deskBBox.x, deskBBox.y];
  
//     const scalePointAdjusted = [
//       scalePointNew[0] - deskPosition[0],
//       scalePointNew[1] - deskPosition[1]
//     ];


//     const scalePointMove = [
//       scalePointNew[0] - scalePointInit[0],
//       scalePointNew[1] - scalePointInit[1]
//     ] as Size;
    


//     // const cp = [250, 300];
//     const cp = scalePointAdjusted;;

//     setDebugPoint({

//       x: cp[0] + deskBBox.left,
//       y: cp[1] + deskBBox.top,
//     });



//     const postUpdate = [
//       positionInit[0] / scaleInit * scaleNew 
//       + (cp[0] / scaleNew - cp[0] / scaleInit) * scaleNew 
//       // + scalePointMove[0]
// ,
//       positionInit[1] / scaleInit * scaleNew
//       + (cp[1] / scaleNew - cp[1] / scaleInit) * scaleNew
//       // + scalePointMove[1]
//       ] as Position;

//       onPositionChange(postUpdate);
//       onScaleChange(scaleNew);
    },

    onTouchEnd: () => {
    }
  });

  const rescale = (
    scaleNew: number,
    scalePoint: Position,
  ) => {
    scaleNew = validateScale(scaleNew);

    const positionNew = reposition({
      scaleNew,
      scalePoint,
    });
      
    onScaleChange(scaleNew);
    onPositionChange(positionNew);
  }

  const validateScale = (scale: number) => {
    scale = Math.max(scale, SCALE_MIN);
    scale = Math.min(scale, SCALE_MAX);

    return scale;
  }


  return (
    <Rescaler
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      { children }
    </Rescaler>
  );
}
