import RepoBase from "../repo-base";


type Addr = "logo" | "--some-other-addr--";


class OthersRepo extends RepoBase<Addr> {
  getKey(addr: Addr): string {
    return addr;
  };

  tag(): string {
    return "Others";
  };
}


export default OthersRepo;