import useSelectUserImage from "./use-select-user-image";
import { HookBaseProps } from "./types";


export interface HookProps extends HookBaseProps {
}


const useSelectUserImageCameraBack = (props: HookProps) => {
  return useSelectUserImage({
    ...props,
    captureSource: {capture: "environment"}
  });
};


export default useSelectUserImageCameraBack;
