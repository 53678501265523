import * as Defaults from './defaults';
import * as Types from './types';


/**
 * State
 */
export type State = {
  logo: Types.Logo,
  releaseInfo: Types.ReleaseInfo,
};


/**
 * Getters
 */
export const getLogo = (state: State) => state.logo;
export const getReleaseInfo = (state: State) => state.releaseInfo;


/**
 * Init State
 */
export const createInitialState = (): State => {
  const initState: State = {
    logo: Defaults.getLogo(),
    releaseInfo: Defaults.getReleaseInfo(),
  }

  return initState;
}
