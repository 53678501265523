export namespace Arrow {


export enum PointerType {
  ARROW    = 'arrow',
  POINTER  = 'pointer',
  TRIANGLE = 'triangle',
  DOTS     = 'dots'
};

export const getPointerIdx = (pointer: PointerType) => {
  return Object.values(PointerType).indexOf(pointer);
}

export const getPointer = (idx: number) => {
  const values = Object.values(PointerType);
  const value = values[idx];
  return value as PointerType;
}

export const isPointerHeightless = (pointer: PointerType) => {
  const pointers = [
    PointerType.TRIANGLE
  ];

  return pointers.indexOf(pointer) !== -1;
}

export const isPointerShaftless = (pointer: PointerType) => {
  const pointers = [
    PointerType.POINTER,
    PointerType.TRIANGLE
  ];

  return pointers.indexOf(pointer) !== -1;
}

} // ns

