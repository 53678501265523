import Navbar from "./states/navbar";
import View from "./states/view";


export class HomePageGuest {
  private _navbar: Navbar;
  private _view: View;

  constructor() {
    this._navbar = new Navbar();
    this._view = new View();
  }

  reset() {
    this._navbar.reset();
    this._view.reset();
  }

  get navbar() { return this._navbar; }
  get view()   { return this._view; }
}
