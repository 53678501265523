import { PanelControlTextVariant } from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"

import { settings } from 'app/configs';
import { Icon } from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useFunctions from './use-functions';


const useItems = () => {
  const t = useTranslations();
  const fn = useFunctions();

  const getColumnNotSelected = () => ({
    variant: PanelControlTextVariant.WARNING,
    type: PanelControlsItemSectionItemType.TEXT_MULTILINE,
    value:t('view print, select column'),
  });

  const getColumn_padding = () => ({
    title: t('cell padding'),
    type:  PanelControlsItemSectionItemType.SLIDER,
    min:   settings.printView.cell.padding.min,
    max:   settings.printView.cell.padding.max,
    step:  1,
    value: fn.column_getPadding,
    onChange: (value: number) => { 
      fn.column_sliderUpdateCSS({padding: `${value}px`});
    },
    onChangeDone: (value: number) => {
      fn.column_sliderUpdateCSSDone({padding: `${value}px`});
    }
  });

  const getColumn_fontSize = () => ({
    title: t('size'),
    type: PanelControlsItemSectionItemType.SLIDER,
    min:  settings.printView.cell_index.font.size.min,
    max:  settings.printView.cell_index.font.size.max,
    step:  1,
    value: fn.column_getFontSize,
    onChange: (value: number) => { 
      fn.column_sliderUpdateCSS({fontSize: `${value}px`});
    },
    onChangeDone: (value: number) => {
      fn.column_sliderUpdateCSSDone({fontSize: `${value}px`});
    }
  });

  const getColumn_fontWeight = () => ({
    title: t('font weight'),
    type: PanelControlsItemSectionItemType.SLIDER,
    min:  settings.printView.cell_index.font.weight.min,
    max:  settings.printView.cell_index.font.weight.max,
    step:  1,
    value: fn.column_getFontWeight,
    onChange: (value: number) => { 
      fn.column_sliderUpdateCSS({fontWeight: `${value * 100}`});
    },
    onChangeDone: (value: number) => {
      fn.column_sliderUpdateCSSDone({fontWeight: `${value * 100}`});
    }
  });

  const getColumn_flexRowConfigHorizontal = (singleItem: boolean = false) => ({
    skip: fn.column_isDirectionColumn,
    title: t('view print, images align horizontal'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Align.To.Left.Horizontal,
        tooltip: t("left"),
        value: {justifyContent: 'flex-start'},
        selected: () => fn.column_checkCSS({justifyContent: 'flex-start'}),
      },
      {
        Icon: Icon.Align.To.Center.Horizontal,
        tooltip: t("center"),
        value: {justifyContent: 'center'},
        selected: () => fn.column_checkCSS({justifyContent: 'center'}),
      },
      {
        Icon: Icon.Align.To.Right.Horizontal,
        tooltip: t("right"),
        value: {justifyContent: 'flex-end'},
        selected: () => fn.column_checkCSS({justifyContent: 'flex-end'}),
      },
      {
        skip: singleItem,
        Icon: Icon.Align.SpaceBetween.Horizontal,
        tooltip: t("space evenly"),
        value: {justifyContent: 'space-evenly'},
        selected: () => fn.column_checkCSS({justifyContent: 'space-evenly'}),
      },
    ],
    onClick: fn.column_updateCSS
  });

  const getColumn_flexRowConfigVertical = () => ({
    skip: fn.column_isDirectionColumn,
    title: t('view print, images align vertical'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Align.To.Top,
        tooltip: t("top"),
        value: {
          alignContent: 'flex-start',
          alignItems:   'flex-start',
        },
        selected: () => fn.column_checkCSS({
          alignContent: 'flex-start',
          alignItems:   'flex-start',
        }),           
      },
      {
        Icon: Icon.Align.To.Center.Vertical,
        tooltip: t("center"),
        value: {
          alignContent: 'center',
          alignItems:   'center',
        },
        selected: () => fn.column_checkCSS({
          alignContent: 'center',
          alignItems:   'center',
        }), 
      },
      {
        Icon: Icon.Align.To.Bottom,
        tooltip: t("bottom"),
        value: {
          alignContent: 'flex-end',
          alignItems:   'flex-end',
        },
        selected: () => fn.column_checkCSS({
          alignContent: 'flex-end',
          alignItems:   'flex-end',
        }),           
      },
    ],
    onClick: fn.column_updateCSS
  });

  const getHeader_layout = () => ({
    title: t('view print, images align horizontal'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: Icon.Align.To.Left.Horizontal,
        tooltip: t("left"),
        value: {justifyContent: 'flex-start'},
        selected: () => fn.header_checkCSS({justifyContent: 'flex-start'}),
      },
      {
        Icon: Icon.Align.To.Center.Horizontal,
        tooltip: t("center"),
        value: {justifyContent: 'center'},
        selected: () => fn.header_checkCSS({justifyContent: 'center'}),
      },
      {
        Icon: Icon.Align.To.Right.Horizontal,
        tooltip: t("right"),
        value: {justifyContent: 'flex-end'},
        selected: () => fn.header_checkCSS({justifyContent: 'flex-end'}),
      },
    ],
    onClick: fn.header_updateCSS
  });


  //-------------  


  return {
    getHeader_layout,

    getColumn_padding,
    getColumn_fontSize,
    getColumn_fontWeight,
    getColumn_flexRowConfigHorizontal,
    getColumn_flexRowConfigVertical,

    getColumnNotSelected,
  }
}

export default useItems;