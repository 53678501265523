import styled from "styled-components";
import PanelBase from 'lego/styles/panel';


const Panel = styled(PanelBase)<{left: number, visible: boolean}>`
  /* 
    Although Panel has been already was detached by Navbar item,
    it has to be detached again - as if the last top menu item 
    - item on the right end of top menu - will have a panel 
    which extends beyond screen - eventhough we are adjusting
    panel position by moving it to the left so it fits to the screen
    if it has position relative here instead of absolute, it would
    cause horizontal scrollbar to appear in the browser window
  */
  position: absolute;
  left: ${props => props.left}px;

  display: flex;
  flex-direction: column;

  padding:    ${props => props.theme.defs.padding.normal};
  background: ${props => props.theme.defs.colors.background.third};

  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  opacity: ${props => props.visible ? 1 : 0};

  transition: 
    opacity 
    ${props => props.theme.defs.transition.time.quick} 
    ease-in-out
  ;
`;


export default Panel;