import React from 'react';
import { MainWrapper } from './styles';

interface Props {
  onClick?: (event: React.MouseEvent) => void;
  color: string;
}


export const ColorPickerPreviewComponent: React.FC<Props> = (props: Props) => {
  const {
    color,
    onClick
  } = props;

  return (
    <MainWrapper 
      onClick={onClick}
      $color={color}
    />
  );
}
