import React from 'react';
import { useRecoilValue } from 'recoil';

import { SideControlsTypes }    from 'lego/components/side-controls';
import SideToolbarBaseComponent from 'lego/components/side-controls/side-toolbar';
import { SideToolbarConfig }    from 'lego/components/side-controls/side-toolbar/config';

import { ContentSessionTypes as Types } from 'app/arch/editor-instruction/document/states/sessions/content-session';

import { Icon }               from 'app/ui/icons/icons';
import { useTranslations }    from 'app/ui/hooks/app/use-translation';
import { useDocState }        from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';


type Props = SideControlsTypes.PropLeftOrRight;


export const SideToolbarComponent: React.FC<Props> = (props: Props) => {
  const {
    left,
    right,
  } = props;

  const t = useTranslations();
  const document = useDocState();
  const contentSession = document.contentSession;
  const panel = useRecoilValue(UIState_ContentSession.panelAssets);

  const {
    setContentSession,
  } = useEditorStatesSetters();

  const switchPanel = (selectedPanelItem: Types.PanelAssetsItem) => {
    contentSession.updatePanelProperties({visible: false});

    if (panel.visible) {
      if (panel.selected !== selectedPanelItem) {
        contentSession.updatePanelAssets({
          selected: selectedPanelItem
        });
      }
      else {
        contentSession.updatePanelAssets({
          visible: false
        });
      }
    }
    else {
      contentSession.updatePanelAssets({
        visible: true,
        selected: selectedPanelItem
      });
    }

    setContentSession();
  }

  const showPhotos  = () => switchPanel(Types.PanelAssetsItem.IMAGE);
  const showMarkers = () => switchPanel(Types.PanelAssetsItem.MARKER);

  const toolbarConfig: SideToolbarConfig = {
    items: [
      {
        tooltip: t('photos properties'),
        dataTest: 'toolbar-images-properties',
        selected: panel.visible && panel.selected === Types.PanelAssetsItem.IMAGE,
        onClick: showPhotos,
        Icon: Icon.Image
      },
      {
        tooltip: t('markers properties'),
        dataTest: 'toolbar-markers-properties',
        selected: panel.visible && panel.selected === Types.PanelAssetsItem.MARKER,
        onClick: showMarkers,
        Icon: Icon.Marker.Toolbar
      },    
    ]
  };

  return (
    <SideToolbarBaseComponent 
      config={toolbarConfig}
      left={left}
      right={right}
    />
  );
}
