import React from 'react';
import { useDocState } from 'app/ui/contexts/document';
import ProbeHeightComponent from 'app/ui-v2/editor-instruction/views/view-content/components/content/page/probe-height';


interface Props {
  enabled: boolean;
  children: React.ReactNode;
}


export const HeaderCellProbeComponent: React.FC<Props> = (props: Props) => {
  const {
    enabled,
    children,
  } = props;

  const document = useDocState();

  const handleHeightChange = (height: number) => {
    const elemSizes = document.contentElementsSizes;
    elemSizes.table.setHeaderRowHeight(height);
  }

  return (
    <ProbeHeightComponent
      enabled={enabled}
      onHeightUpdate={handleHeightChange}
    >
      { children }
    </ProbeHeightComponent>
  );
}
  
