import { ViewTypes } from "app/arch/home-page-user/states/view"
import useState from "./use-state"
import useStateSetters from "./use-state-setters";


const useViewSelect = () => {
  const homePageUser = useState();
  const {
    setView
  } = useStateSetters();
  
  const selectView = (view: ViewTypes.ViewItem) => {
    homePageUser.view.update({selected: view});
    setView();
  }

  return selectView;
}

export default useViewSelect;