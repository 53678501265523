import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import useResizeObserver from 'app/ui/hooks/use-resize-observer';


interface Props {
  enabled: boolean;
  onBBoxUpdate: (bbox: DOMRectReadOnly) => void;
  children: React.ReactNode;
}


export const ProbeVirtualComponent: React.FC<Props> = (props: Props) => {
  const { 
    enabled,
    onBBoxUpdate,
    children 
  } = props;

  const childRef = useRef<any>(null); 

  useEffect(() => {
    // For now let's support single child
    const childrenCount = React.Children.count(children);
    if (childrenCount !== 1) {
      const msg = `Invalid children count. Expected 1, got ${childrenCount}`;
      throw new Error(msg);
    }
  }, [children]);


  useResizeObserver({
    elementRef: childRef,
    onResize: (entries) => {
      entries.forEach((entry: ResizeObserverEntry) => {
        const bbox = entry.target.getBoundingClientRect();
        onBBoxUpdate(bbox);
      });
    }
  }, [children]);


  const renderEnabled = () => (
    <>
      {React.Children.map(children, (child, index) => {
        // Cloning the child with ref prop
        
        return (
          React.isValidElement(child) ? 
          React.cloneElement(child as React.ReactElement<any>, {
            ref: childRef, // Assigning the ref
          }) :
          child
        );

      })}
    </>
  );

  const renderDisabled = () => children;


  return (
    <>
      { enabled && renderEnabled() }
      { enabled || renderDisabled() }
    </>
  )
};