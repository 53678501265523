import { HeaderRowsTools } from '../../persistent/header-rows';
import { HeaderRowsTypes } from '../../persistent/header-rows';
import ItemsSelectedBase from '../_parts/items-selected-base';


export class CustomRowsRowsSelected extends ItemsSelectedBase<HeaderRowsTypes.PageRowAddr> {
  protected getKey(columnAddr: HeaderRowsTypes.PageRowAddr): string {
    return HeaderRowsTools.getPageRowKey(columnAddr);
  }
}
