import React from 'react';
import { IconContext } from 'react-icons';

import Icon from 'app/ui/icons/icons';
import usePrintoutViewPageNext from 'app/ui-v2/editor-instruction/views/view-printout/hooks/use-printout-view-page-next';
import usePrintoutViewPagePrev from 'app/ui-v2/editor-instruction/views/view-printout/hooks/use-printout-view-page-prev';

import { MainWrapper } from './styles';
import { Btn } from './styles';


interface Props {
}


export const PageIdxControlComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const prevViewPage = usePrintoutViewPagePrev();
  const nextViewPage = usePrintoutViewPageNext();

  const IconNext = Icon.Idx.Next;
  const IconPrev = Icon.Idx.Prev;

  return (
    <IconContext.Provider 
      value={{ 
        size: `24px`, 
        style: { 
          borderWidth: '3px',
          borderStyle: 'solid',
          borderColor: 'white',

          outlineWidth: '1px',
          outlineStyle: 'solid',
          outlineColor: 'black',

          background: 'white',
          borderRadius: '50%'
        }
      }}
    >
      <MainWrapper>
        <Btn 
          id='printout-ctrl__page-prev'
          onClick={prevViewPage}
        >
          <IconPrev/>
        </Btn>

        <Btn 
          id='printout-ctrl__page-next'
          onClick={nextViewPage}
        >
          <IconNext />
        </Btn>

      </MainWrapper>
    </IconContext.Provider>    
  );
}

