import React from 'react';

import InputComponentBase from 'lego/components/input';
import { InputTitle   } from './styles';
import { InputWrapper } from './styles';


interface Props {
  value?: string;
  type?: string;
  title?: string;
  
  blurOnEnterKey?: boolean;
  focusOnLoad?: boolean;

  onChange?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;

  dataTest?: string;
}


export const InputComponent: React.FC<Props> = (props: Props) => {
  const { 
    title, 
    type, 
    value, 
    onChange,
    blurOnEnterKey,
    onKeyDown,
    focusOnLoad,
    dataTest
  } = props;


  const handleValueChange = (value: string) => {
    onChange?.(value);
  }

  return (
    <InputWrapper>
      {
        title &&
        <InputTitle>
        { title }
        </InputTitle>
      }

      <InputComponentBase
        type={type}
        value={value}
        focusOnLoad={focusOnLoad}
        onValueChange={handleValueChange}
        onKeyDown={onKeyDown}
        blurOnEnterKey={blurOnEnterKey}
        dataTest={dataTest}
      />
    </InputWrapper>
  );
}
  
