import React, { useEffect } from 'react';
import useScreenSize from '../../hooks/use-screen-size';
import useScreenStateSetters from '../../hooks/use-screen-state-setters';


interface Props {
}


export const ScreenSizeWatcherComponent: React.FC<Props> = (props: Props) => {
  const screenSize = useScreenSize();

  const {
    setScreenSize
  } = useScreenStateSetters();

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    screenSize.setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    setScreenSize();
  };

  return null;
}
