import styled from "styled-components";


export const MainWrapper = styled.div`

`;

export const TextWrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: clip;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  /* flex-grow: 1; */
  /* background: red; */
`;
