import Errors from 'app/arch/tools/errors';
import { useDocState } from 'app/ui/contexts/document';
import useUndo from 'app/ui-v2/editor-instruction/hooks/use-undo';
import useMarkerEdit from 'app/ui-v2/editor-markers/hooks/use-marker-edit';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import * as Types from './types';


export interface Props {
  closeMenuRef: Types.CloseMenuRef
}


const useFunctions = ({
  closeMenuRef
}: Props) => {

  const document = useDocState();  
  const { 
    undo, 
    redo 
  } = useUndo();

  const editMarker = useMarkerEdit();
 
  const {
    setRepoMarkers,
    setEditorMarkerSession,
  } = useEditorStatesSetters();


  const __closeMenu = () => {
    if (closeMenuRef.current !== null) {
      closeMenuRef.current();
    }
  }

  const handleMarkerCreate = () => {
    const markerAddr = document.repoMarkers.addMarker();
    document.saveUndo();
    setRepoMarkers();
    
    editMarker(markerAddr);
    __closeMenu();
  }

  const handleMarkerDuplicate = () => {
    const srcMarkerAddr = document.editorMarkerSession.getSelectedMarkerAddr();
    if ( ! srcMarkerAddr ) {
      Errors.hard(`Src marker addr is invalid: ${srcMarkerAddr}`);
      return;
    }

    const markerAddr = document.repoMarkers.duplicateMarker(srcMarkerAddr);
    document.saveUndo();
    setRepoMarkers();
    
    editMarker(markerAddr);
    __closeMenu();
  }

  const handleUndo = () => {
    undo();
    __closeMenu();
  }

  const handleRedo = () => {
    redo();
    __closeMenu();
  }

  const handleExit = () => {
    document.editorMarkerSession.updateWindow({visible: false});
    setEditorMarkerSession();
    __closeMenu();
  }

  return  {
    handleMarkerCreate,
    handleMarkerDuplicate,
    handleUndo,
    handleRedo,
    handleExit,
  }
}


export default useFunctions;