import React from 'react';
import { useEffect } from 'react';

import useAppRemoveLoader from 'app/ui-v2/app/hooks/use-app-remove-loader';


interface Props {
}


export const LoaderRemoveLoaderComponent: React.FC<Props> = (props: Props) => {
  const removeAppLoader = useAppRemoveLoader();

  useEffect(() => {
    removeAppLoader();
  }, []);

  
  return null;
}
