import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { useDocState } from 'app/ui/contexts/document';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import HLC_MarkerDraggerComponent from 'app/ui-v2/editor-instruction/hls/markers/marker-dragger';
import useDNDDataSet from "app/ui-v2/editor-instruction/hooks/use-dnd-data-set";


interface Props {
  markerAddr: Types.MarkerAddr
  children: React.ReactNode;
}


export const MarkerDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr, 
    children, 
  } = props;
  
  const document  = useDocState();
  const setDNDData = useDNDDataSet();

  const markerProps = document.content.cellMarkers_getMarkerProps(markerAddr);

  const handleDragStart = () => {
    const data = { markerAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  return (
    <HLC_MarkerDraggerComponent
      markerAddr={markerProps.repoMarkerAddr}
      dndContext={DNDContext.marker.cell}
      onDragStart={handleDragStart}
    >
      { children }
    </HLC_MarkerDraggerComponent>
  );
}
