import styled from 'styled-components';

export interface MainWrapperProps {
  $horizontal?: any;
  $vertical?: any;
};

export const MainWrapper = styled.div<MainWrapperProps>`
  width: 100%;
  height: 100%;

  display: flex;
  ${props => {
    if (props.$horizontal) return 'flex-direction: column';
    if (props.$vertical)   return 'flex-direction: row';
  }};

`;

export interface LineVerticalProps {
  $color: string;
};

export const LineVertical = styled.div<LineVerticalProps>`
  height: 100%;
  width: 100%;
  background-color: ${props => props.$color};
`;

