import styled from "styled-components";
import MenuItem from "lego/styles/menu_item";
import TextWrap from "lego/styles/text-wrap";


export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  pointer-events: none;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Item = styled(MenuItem)`
  height: 30px;
`;

export const ItemDelete = styled(Item)`
  &:active {
    background: ${props => props.theme.menuItem.variant.delete.background.active};
  }

  &:hover:not(:active) {
    background: ${props => props.theme.menuItem.variant.delete.background.hover};
  }
`;

export const TextWrapper = styled(TextWrap)`
  max-width: 140px;
  flex-grow: 1;

  pointer-events: none;
  overflow: hidden; // not sure if needed as it gets clip from TextWrap
`;
