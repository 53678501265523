import { useContext } from "react";
import Context from "../components/price-plan-context/context";
import useProducts from "app/ui-v2/app/__modules/products/hooks/use-products";


const usePricePlan = () => {
  const products = useProducts();
  const planContext = useContext(Context);
  const planType = planContext.planType;

  if (planType === null) {
    const msg = `Price plan type is null`;
    throw new Error(msg);
  }

  const plan = products.pricePlans.getPlan(planType);
  return { plan, planType };
}

export default usePricePlan;