import { LineVertical } from "./styles";
import { MainWrapper  } from "./styles";


const Colors: string[][] = [
  [
    "#DFFF00", 
    "#FFBF00", 
    "#FF7F50", 
    "#DE3163", 
    "#9FE2BF",
    "#40E0D0",
    "#6495ED",
    "#CCCCFF",
  ],
  [
    "#390099", 
    "#9e0059", 
    "#ff0054", 
    "#ff5400", 
    "#ffbd00",
  ],
  [
    "#ffff00",
    "#e3ee00",
    "#c6dd00",
    "#aacc00",
    "#8ebb00",
    "#71aa00",
    "#559900",
    "#398800",
    "#1c7700",
    "#006600",
  ],
  [
    "#1800ff",
    "#3200e3",
    "#4b00c6",
    "#6500aa",
    "#7f008e",
    "#980071",
    "#b20055",
    "#cc0039",
    "#e5001c",
    "#ff0000",
  ]

]



interface Props {
  vertical?: any;
  horizontal?: any;
  paletteIdx?: number;
}


export const TestLinesComponent: React.FC<Props> = (props: Props) => {
  const paletteIdx = props.paletteIdx !== undefined ? props.paletteIdx : 0;

  const renderLines = () => {
    const lines = Colors[paletteIdx].map((color: string, idx: number) => {
      return (
        <LineVertical 
          key={`${idx}-{color}`}
          $color={color}
        />
      );
    });

    return lines;
  }

  return (
    <MainWrapper 
      $horizontal={props.horizontal}
      $vertical={props.vertical}
    >
      { renderLines() }
    </MainWrapper>
  );
}
