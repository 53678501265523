import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useSelectedReset = () => {
  const document = useDocState();
  const {
    setContentSession,

    setCustomRowsRowsSelected,
    setCustomFieldsColumnsSelected,
    setContentRowsSelected,
    setContentColumnsSelected,
  } = useEditorStatesSetters();

  const hook = (props?: {
    skipStateUpdate?: boolean,
  }) => {
    const skipStateUpdate = props?.skipStateUpdate ?? false;

    document.contentRowsSelected.reset();
    document.contentColumnsSelected.reset();

    document.customRowsRowsSelected.reset();
    document.customFieldsColumnsSelected.reset();

    document.contentSession.setSectionSelected(null);
    document.contentSession.setCellSelected(null);
    document.contentSession.cellImages_setImageSelected(null);
    document.contentSession.cellMarkers_setMarkerSelected(null);

    if (skipStateUpdate) {
      return;
    }

    setContentSession();

    setCustomRowsRowsSelected();
    setCustomFieldsColumnsSelected();
    setContentRowsSelected();
    setContentColumnsSelected();
  }

  return hook;
}


export default useSelectedReset;
