import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import Context from './context';


interface Props {
  scale: number;
  children: React.ReactNode;
}


export const DocumentContextScaleRefComponent: React.FC<Props> = (props: Props) => {
  const {
    scale,
    children,
  } = props;

  const scaleRef = useRef(scale);

  useEffect(() => {
    scaleRef.current = scale;
  }, [scale]);

  return (
    <Context.Provider value={scaleRef}>
      { children }
    </Context.Provider>
  );
}