import { atom } from 'recoil';
import { selector } from 'recoil';
import { ReloginState as State } from 'app/arch/app/modals/relogin';


export const state = atom<State.State>({
  key: "modals_relogin",
  default: State.createInitialState()
});


export const visible = selector({
  key: `modals_relogin__visible`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getVisible(stateObj);
  }
});
