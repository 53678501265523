import React from 'react';
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useDocState } from 'app/ui/contexts/document';
import CellTextComponent from './cells/cell-text';


interface Props {
  pageCellAddr: Types.PageCellAddr;
}


export const CellRenderComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr
  } = props;

  const document = useDocState();
  const columnAddr = {
    columnId: pageCellAddr.columnId
  }
  const columnType = document.headerFields.getColumnType(columnAddr);

  const CellsComponents: {[key in Types.ColumnType]: React.ElementType} = {
    [Types.ColumnType.TEXT]: CellTextComponent,
  };

  const CellComponent = CellsComponents[columnType];

  return (
    <CellComponent
      pageCellAddr={pageCellAddr}
    />
  );
}
  
