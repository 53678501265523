import { PanelDraggableConfig } from "lego/components/panel-draggable/config";
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useItems     from "./use-items";
import useFunctions from "./use-functions";



const useConfig = (imageAddr: ContentTypes.ImageAddr) => {
  const fns = useFunctions(imageAddr);
  const getItems = useItems(imageAddr);

  //--------------------
  // Configs
  //

  const getConfig = () => {
    const config: PanelDraggableConfig = {
      reversOrder: true,
      items: getItems(),
      onItemMove: fns.handleItemMove,
      onDragStart: fns.handleDragStart,
    }
    
    return config;
  }

  return getConfig;
}
  
export default useConfig;
