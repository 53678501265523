import styled from "styled-components";

import ButtonGroupLeft   from 'lego/styles/button_group_left';
import ButtonGroupMiddle from "lego/styles/button_group_middle";
import ButtonGroupRight  from 'lego/styles/button_group_right';
import TitleBase from "lego/styles/title";
import TextWrap  from "lego/styles/text-wrap";


export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  /* background: rgba(255, 0, 0, 0.3); */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.theme.defs.gap.xxsmall};

  max-width: 100%;
  /* background: rgba(255, 255, 0, 0.3); */
`;

export const Title = styled(TitleBase)`
  font-size:   ${props => props.theme.defs.font.size.small};
  font-weight: ${props => props.theme.defs.font.weight.small};
  opacity:     ${props => props.theme.defs.opacity.text};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  /* background-color: red;; */
`;

export const ButtonLeft = styled(ButtonGroupLeft)`
  min-width: 80px;
  padding: ${props => props.theme.defs.padding.normal};
`;

export const ButtonMiddle = styled(ButtonGroupMiddle)`
  min-width: 80px;
  padding: ${props => props.theme.defs.padding.normal};
`;

export const ButtonRight = styled(ButtonGroupRight)`
  min-width: 80px;
  padding: ${props => props.theme.defs.padding.normal};
`;

export const BtnText = styled(TextWrap)`
`;
