import styled from "styled-components";

// Remove me - i guess
export const StyledBtnTextBase = styled.button`
  font-size: 11px;
  font-weight: 600;
  background-color: #166EE1;
  border: none;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  color: #fff;
  cursor: pointer;
  &:disabled {
    background-color: rgba(40, 114, 225, 0.5);
    cursor: not-allowed;
  };
`;
