import React from 'react';

import { Demo, Text } from './styles';


interface Props {
  width: string;
  height: string;
}


export const DemoOverviewComponent: React.FC<Props> = (props: Props) => {
  const {
    width,
    height,
  } = props;


  return (
    <Demo
      $width={width}
      $height={height}
    >
      <Text>
        demo
      </Text>
      <Text>
        demo
      </Text>
      <Text>
        demo
      </Text>
    </Demo>
  );
}

