import { OS } from "./types";
import { useEffect, useState } from "react";

const OSResolver: {[agentSystem: string]: OS } = {
  'Win': OS.Windows,
  'Mac': OS.Mac,
  'X11': OS.Linux,  // used to be unix
  'Linux': OS.Linux,
  '': OS.Unknown,
};

const userAgentsSystems = [
  'Win', 'Mac', 'X11', 'Linux'
];

export const useUserSystem = () => {
  const [userOS, setUserOS] = useState(OS.Unknown);

  useEffect(() => {
    const { userAgent } = navigator;
    userAgentsSystems.forEach((system: string)=> {
      if (userAgent.indexOf(system) !== -1) {
        setUserOS(OSResolver[system]);
      }
    });

  }, []);

  return userOS;
}
