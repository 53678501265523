import i18next from 'i18next';

import { MenuConfig, MenuItem   } from "lego-v2/menu/config"
import { MenuItemType } from "lego-v2/menu/config"
import Icon from "app/ui/icons/icons";



export const getConfig = (cmds: any, states: any, deleteDisabled?: boolean) => {
  const t = i18next.t;

  const config: MenuConfig = {
    sections: [{
      items: [
        {
          type: MenuItemType.BUTTON,
          data: {
            title: t('widget style apply'),
            iconComponent: Icon.PaintBrush,
            onClick: () => { 
              cmds.applyStyle();
            }
          }
        },
        {
          type: MenuItemType.BUTTON,
          data: {
            title: t('widget style set as default'),
            iconComponent: Icon.Default,
            onClick: () => { 
              cmds.setDefaultStyle();
            }
          }
        },
      ]
    }]
  }

  const deleteItem: MenuItem = {
    type: MenuItemType.BUTTON,
    data: {
      title: t('widget style delete'),
      variant: 'delete',
      iconComponent: Icon.Delete,
      onClick: () => { 
        cmds.deleteStyle();
      }
    }
  }

  if ( ! deleteDisabled ) {
    config.sections[0].items.push(deleteItem);
  }

  return config;
}