import { DialogCustomComponent } from './DialogCustom';
import { useDialogCustom } from './use-dialog-custom';
import * as Types from './types';



export { useDialogCustom };
export { Types as DialogCustomTypes };

export default DialogCustomComponent;
