import React from 'react';
import WindowComponent, { WindowTypes }  from 'lego-v2/window';
import { Position } from 'app/arch/types';
import { Size }     from 'app/arch/types';
 

interface Props {
  dataTest?: string;
  title: string;
  visible: boolean;

  windowSize: Size;
  windowSizeMin: Size;
  windowPosition: Position;
  windowMaximized: boolean;

  onWindowSize: (size: Size) => void;
  onWindowPosition: (position: Position) => void;
  onWindowMaximized: (maximized: boolean) => void;
  onWindowUpdateDone: () => void;
  onWindowClose: () => void;

  children: React.ReactNode;
}


export const WindowManagedComponent: React.FC<Props> = (props: Props) => {
  const {
    dataTest,
    title,
    visible,

    windowSize,
    windowSizeMin,
    windowPosition,
    windowMaximized,
  
    onWindowSize,
    onWindowPosition,
    onWindowMaximized,
    onWindowUpdateDone,
    onWindowClose,

    children, 
  } = props;


  const handleWindowUpdate = (update: WindowTypes.Update) => {
    if ('size' in update)      { onWindowSize(update.size!); }
    if ('position' in update)  { onWindowPosition(update.position ! ); }
    if ('maximized' in update) { onWindowMaximized(update.maximized ! ); }
  }

  const handleWindowUpdateDone = (update: WindowTypes.Update) => {
    handleWindowUpdate(update);
    onWindowUpdateDone();
  }

  const handleWindowClose = () => {
    onWindowClose();
  }

  if ( ! visible ) {
    return null;
  }

  return (
    <WindowComponent
      dataTest={dataTest}
      title={title}

      maximized={windowMaximized}

      left={windowPosition[0]}
      top={windowPosition[1]}

      width={windowSize[0]}
      height={windowSize[1]}

      widthMin={windowSizeMin[0]}
      heightMin={windowSizeMin[1]}

      onClose={handleWindowClose}
      onUpdate={handleWindowUpdate}
      onUpdateDone={handleWindowUpdateDone}
    >
      { children }
    </WindowComponent>
  );
}
  
