import { atom } from 'recoil';
import { selector } from 'recoil';
import { GoogleOAuthState as State } from 'app/arch/app/google';


export const state = atom<State.State>({
  key: "google_oauth",
  default: State.createInitialState()
});

export const oauth = selector({
  key: `google_oauth_oauth`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getOAuth(stateObj);
  }
});

