import React from 'react';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';
import { PanelControlsItemSectionControl_ProgressConfig as Config} from '../../config';

import { MainWrapper } from './styles';
import { Box } from './styles';


interface Props {
  config: Config;
}


export const ControlProgressComponent: React.FC<Props> = (props: Props) => {
  const {
    config
  } = props;


  return (
    <MainWrapper>
      <ProgressBoxComponent
        Box={Box}
        hideTitle={true}
      />
    </MainWrapper>
  );
}

