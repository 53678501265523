import * as CustomFieldsTypes from '../../persistent/header-fields/types';
import * as CustomFieldsTools from '../../persistent/header-fields/tools';


//----------------------------
// State
//

export type State = {
  pageCell: {
    selected: CustomFieldsTypes.PageCellAddr | null,
  }
};


/**
 * Page Cell
 */
export const getPageCellSelected = (state: State) => state.pageCell.selected;

export const isPageCellSelected  = (
  state: State, 
  pageCellAddr: CustomFieldsTypes.PageCellAddr
) => {
  const pageCellSelected = state.pageCell.selected;
  const selected = (
    pageCellSelected !== null &&
    CustomFieldsTools.comparePageCellAddr(pageCellAddr, pageCellSelected)
  );

  return selected;
}


//----------------------------
// Create initial state
//

export const createInitialState = (): State => {
  const state: State = {

    pageCell: {
      selected: null,
    }
  }

  return state;
}
