import React from 'react';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
}


export const CellIndexValueComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr
  } = props;

  const document = useDocState();
  const rowIdx = document.releaselogs.getRowIdx(cellAddr);
  const stepNo = rowIdx + 1;

  return stepNo;
}
  
