import React from 'react';
import { memo } from 'react';
import { MutableRefObject } from 'react';
import { ListPlugin } from "@lexical/react/LexicalListPlugin";

import { EditorTextContext } from './EditorTextContext';
import { EditorTextBody    } from './EditorTextBody';

import { TabIndentationPlugin } from './plugins/tab-indent-plugin/TabIndetPlugin';
import { SmartIdentPlugin     } from './plugins/smart-ident-plugin/SmartIdentPlugin';


interface Props {
  editable?: boolean;
  editorTextRef?: MutableRefObject<HTMLDivElement | null>;
  EditorStyled?: React.ElementType;
  Plugins: React.ReactNode[];
}


export const EditorText: React.FC<Props> = memo((props: Props) => {
  const {
    editorTextRef,
    EditorStyled,
    Plugins,
  } = props;

  const editable = (
    props.editable !== undefined ?
    props.editable :
    true
  );

  const PluginsUnpack = Plugins.map((Plugin, idx) => (
    <React.Fragment key={idx}>
      { Plugin }
    </React.Fragment>
  ));

  return (
    <EditorTextContext editable={editable}>
      
      {/* <TabIndentationPlugin /> */}
      <ListPlugin /> 
      <SmartIdentPlugin />

      { PluginsUnpack }
      
      <EditorTextBody
        editable={editable}
        editorTextRef={editorTextRef}
        EditorStyled={EditorStyled}
      />

    </EditorTextContext>
  )
});

