import { useRecoilValue } from "recoil";

import { ContentTools } from "app/arch/editor-instruction/document/states/persistent/content";
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";

import Icon                from "app/ui/icons/icons";
import { useDocState }     from "app/ui/contexts/document";
import { UIState_Content } from "app/ui/states/editor-instruction";
import { UIState_EditorImageSession } from "app/ui/states/editor-instruction";
import useSelectedReset       from 'app/ui-v2/editor-image/hooks/use-selected-reset';
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useItems = (imageAddrs: ContentTypes.ImageAddr) => {
  const docState = useDocState();

  const {
    setContent,
    setEditorImageSession,
  } = useEditorStatesSetters();

  const resetSelected = useSelectedReset();

  const widgetsAddrs         = useRecoilValue(UIState_Content.cellImages_image_widgetsAddrs(imageAddrs)) || [];
  const selectedWidgetAddr   = useRecoilValue(UIState_EditorImageSession.widgetSelected);
  const selectedWidgetsAddrs = useRecoilValue(UIState_EditorImageSession.multiSelectionSelectedWidgets) || [];


  const getItems = () => {
    const items = widgetsAddrs.map((widgetAddr) => {
      const widgetProps = docState.content.cellImages_image_getWidgetProps(widgetAddr);
      const widgetSelected = (
        selectedWidgetAddr !== null && 
        ContentTools.compareWidgetAddr(widgetAddr, selectedWidgetAddr)
      );
      const widgetMultiSelected = selectedWidgetsAddrs.includes(widgetAddr);

      const key      = ContentTools.getWidgetKey(widgetAddr);
      const value    = widgetProps.name;
      const editable = true;
      const selected = widgetSelected || widgetMultiSelected;

      const handleWidgetClick = () => { 
        docState.cellImages_image_setWidgetSelected(widgetAddr);
        setEditorImageSession();
      }

      const handleWidgetNameChange = (name: string) => {
        docState.content.cellImages_image_updateWidget(widgetAddr, {name});
        setContent();
        docState.saveUndo();
      }
    
      const handleWidgetDelete = () => { 
        // Cause if it fires directly from click,
        // click => causes this item to be removed, 
        // so click can't finish as item has been removed
        // therefore, delay to next cycle, after click finished
        setTimeout(() => {
          docState.content.cellImages_image_deleteWidget(widgetAddr);
          setContent();
          docState.saveUndo();

          resetSelected();
        });
      }

      return {
        key,
        value,
        selected,
        editable,
        onClick: handleWidgetClick,
        onValueChangeDone: handleWidgetNameChange,

        buttons: [
          // {
          //   Icon: locked ? IconLib.Lock.Open : IconLib.Lock.Close,
          //   onClick: onWidgetLockToggle
          // },
          {
            Icon: Icon.Delete,
            onClick: handleWidgetDelete
          },                
        ]
      }
    }); // items

    return items;
  }

  return getItems;
}

export default useItems;