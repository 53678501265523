import { MenuConfig } from 'lego-v2/menu/config';
import { Position }   from 'app/arch/types';
import { PanelControlsConfig } from 'lego/components/panel-controls/config';


type PanelConfigNullable = PanelControlsConfig | null;


interface ElementProps {
  setConfig:   (config: PanelConfigNullable) => void;
  setPosition: (position: Position) => void;
}

let setupProps: ElementProps | null = null;

export const useSetupContextPanelHook = () => {
  const hook = (props: ElementProps) => {
    setupProps = props;
  }

  return hook;
}

//
//
//


export const useContextPanel = () => {
  const showPanel = (props: {
    config: any,
    event: React.MouseEvent
  }) => {
    if (! setupProps) {
      console.warn(`Setup for context panel hook has not been done`);
      console.warn(`Did you forget to include <ContextPanelComponent /> in dom?`);
      return;
    }
    
    const {
      event, 
      config
    } = props;

    event.preventDefault();
    event.stopPropagation();

    const mouseX = event.pageX;
    const mouseY = event.pageY;

    const position = [mouseX, mouseY] as Position;

    setupProps.setConfig(config);
    setupProps.setPosition(position);
  }

  const hidePanel = () => {
    setupProps?.setConfig(null);
  }

  return {
    showPanel,
    hidePanel,
  };
}


export default useContextPanel;
