import React from 'react';
import TooltipComponent from 'lego/components/tooltip';

import { ReposTypes } from 'app/arch/app/states/repos';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import Icon from 'app/ui/icons/icons';
import * as Tools from './tools';

import {  MainWrapper }   from './styles';
import { AccessText }    from './styles';
import { AccessWrapper } from './styles';
import { ButtonLeft }    from './styles';
import { ButtonRight }   from './styles';
import { ButtonWrapper }   from './styles';


interface Props {
  memberAccess: ReposTypes.MemberAccess;
  onSetMemberAccess: (access: ReposTypes.MemberAccess) => void;
  disabled?: boolean;
}


export const RepoMemberAccessSelectorComponent: React.FC<Props> = (props: Props) => {
  const {
    memberAccess,
    onSetMemberAccess,
  } = props;

  const t = useTranslations();
  const disabled = (props.disabled !== undefined ? props.disabled : false);
  const accessStr = t(`access, ${memberAccess}`).toUpperCase();

  const handleRaiseAccess = () => {
    const nextAccess = Tools.getNextLevelAccess(memberAccess);
    onSetMemberAccess(nextAccess);
  }

  const handleLowerAccess = () => {
    const prevAccess = Tools.getPrevLevelAccess(memberAccess);
    onSetMemberAccess(prevAccess);
  }

  const leftBtnDisabled = (
    Tools.isLevelAccessFirst(memberAccess) ||
    disabled
  );

  const rightBtnDisabled = (
    Tools.isLevelAccessLast(memberAccess) ||
    disabled
  );


  return (
    <MainWrapper>

      <ButtonWrapper>
        <TooltipComponent 
          disabled={leftBtnDisabled}
          title={ t("access, lower access") }
        >
          <ButtonLeft 
            disabled={leftBtnDisabled}
            onClick={handleLowerAccess}
          >
            <Icon.Minus />
          </ButtonLeft>
        </TooltipComponent>
      </ButtonWrapper>

      <AccessWrapper $disabled={disabled}>
        <AccessText>
        { accessStr }
        </AccessText>
      </AccessWrapper>

      <ButtonWrapper>
        <TooltipComponent 
          disabled={rightBtnDisabled}
          title={ t("access, raise access") }
        >
          <ButtonRight 
            disabled={rightBtnDisabled}
            onClick={handleRaiseAccess}
          >
            <Icon.Plus />
          </ButtonRight>
        </TooltipComponent>
      </ButtonWrapper>

    </MainWrapper>
  );
}
