import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useFunctions = () => {
  const document = useDocState();

  const {
    setHeaderMeta,
    setReleaselogsView
  } = useEditorStatesSetters();

  
  const setInfoVisible = (visible: TypesShared.PageItemVisibility) => {
    document.releaselogsView.updateHeaderMeta({visible});
    document.saveUndo();
    setReleaselogsView();
  }

  const setLogoVisible = (visible: boolean) => {
    document.headerMeta.updateLogo({visible});
    document.saveUndo();
    setHeaderMeta();
  }

  const setRevisionVisible = (visible: boolean) => {
    document.headerMeta.updateReleaseInfo({visible});
    document.saveUndo();
    setHeaderMeta();
  }

  return {
    setInfoVisible,
    setLogoVisible,
    setRevisionVisible,
  }
}


export default useFunctions;