import styled from "styled-components";
import ButtonGroupLeftBase   from "lego/styles/button_group_left";
import ButtonGroupRightBase  from "lego/styles/button_group_right";
import InputBase             from "lego/styles/input";


export const MainWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ButtonGroupLeft = styled(ButtonGroupLeftBase)`
`;

export const ButtonGroupRight = styled(ButtonGroupRightBase)`
`;

export const Input = styled(InputBase)`
`;
