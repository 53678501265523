import React from 'react';
import { MainWrapper } from './styles';
import useCellContext from '../../../../hooks/use-cell-context';


interface Props {
  children: React.ReactNode;
}


export const HeaderCellBorderComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;

  const cellContext = useCellContext();

  return (
    <MainWrapper isRowLast={cellContext.isRowLast}>
      { children }
    </MainWrapper>
  );
}
