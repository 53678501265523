import React from 'react';
import { useEffect } from 'react';
import { useRef    } from 'react';
import { useState  } from 'react';

import MenuComponent from 'lego-v2/menu';
import { useSidePanelContext } from 'lego/components/side-controls/side-panel/use-side-panel-context';

import { Position } from 'app/arch/types';
import { WidgetsStylesTypes } from 'app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { getConfig } from './config';

import { MenuPanel   } from './styles';
import { MenuWrapper } from './styles';


interface Props {
  styleAddr: WidgetsStylesTypes.StyleAddr;
  styleLib: WidgetsStylesTypes.StyleLib;
  widgetAddr: ContentTypes.WidgetAddr | null;
  onClose: () => void;
  onStyleApplied: (styleProps: WidgetsStylesTypes.StyleAttrs) => void;
  deleteDisabled?: boolean;
}

const BOTTOM_PADDING = 8;
const POSITION_X = 0;
const POSITION_Y = 0;

export const ContextMenuStyleComponent: React.FC<Props> = (props: Props) => {
  const { 
    styleAddr,
    styleLib, 
    widgetAddr, 
    onClose,
    onStyleApplied,
    deleteDisabled
  } = props;
  
  const t = useTranslations();
  const document = useDocState();
  const context = useSidePanelContext();
  const ignoreMenuCloseRef = useRef(false);
  
  const styleProps = document.widgetsStyles.getStyleProps(styleLib, styleAddr);

  const {
    setContent,
    setEditorImageWidgetsStyles,
  } = useEditorStatesSetters();

  const [position, setPosition] = useState<Position | null>(null);
  const menuWrapperRef  = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (menuWrapperRef.current === null) return;
    if (position !== null) return;

    const rect = menuWrapperRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const divBottom    = rect.bottom + BOTTOM_PADDING - context.scrollTop;

    if (divBottom > windowHeight) {
      // console.log("out of screen")
      const y = -1 * (divBottom - windowHeight);
      setPosition([POSITION_X, POSITION_Y - context.scrollTop + y]);
    }
    else {
      setPosition([POSITION_X, POSITION_Y - context.scrollTop]);
    }
  }, [menuWrapperRef.current]);

  const prevScrollTopRef = useRef<number | null>(null);
  useEffect(() => {
    if (prevScrollTopRef.current != null && prevScrollTopRef.current != context.scrollTop) {
      onClose();
    }
    prevScrollTopRef.current = context.scrollTop;

  }, [context.scrollTop]);

  if (widgetAddr === null) {
    return null;
  }

  const applyStyle = () => {
    document.content.cellImages_image_widget_setStyle(
      widgetAddr,
      styleProps.style
    );
    document.saveUndo();
    setContent();
    onStyleApplied(styleProps.style);
    onClose();
  }

  const deleteStyle = () => {
    ignoreMenuCloseRef.current = true;

    document.widgetsStyles.deleteStyle(styleLib, styleAddr);
    document.saveUndo();
    setEditorImageWidgetsStyles();
  }

  const setDefaultStyle = () => {
    document.editorImageSettings.setWidgetStyle(styleProps.style);
    onClose();
  }

  const handleClose = () => {
    if (ignoreMenuCloseRef.current) {
      ignoreMenuCloseRef.current = false;
      return;
    }
    onClose();
  }

  const cmds = {
    applyStyle,
    deleteStyle,
    setDefaultStyle
  }

  const states = {

  }
  
  const menuConfig = getConfig(cmds, states, deleteDisabled);
  const top = position ? `${position[1]}px` : '0px';

  return (
    <MenuWrapper
      ref={menuWrapperRef}
      $visible={position !== null}
      style={{top}}
    >
      <MenuComponent 
        config={menuConfig}
        onClose={handleClose}
        Panel={MenuPanel}
      />
    </MenuWrapper>
  );
}
  
