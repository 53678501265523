import { HeaderRowsTools } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { useDocState }          from 'app/ui/contexts/document';
import useEditorStatesSetters   from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useColumnsAutoAdjustBase from 'app/ui-v2/editor-instruction/views/shared/hooks/use-columns-auto-adjust';


const useColumnsAutoAdjust = () => {
  const document = useDocState();
  const {
    setHeaderFields
  } = useEditorStatesSetters();

  const columnAutoAdjust = () => HeaderRowsTools.columnsAutoAdjust(document);
  const signalColumnsWidths = () => {
    // There is no need to send signal as
    // recoil listen directly on both columns
  }

  const hook = useColumnsAutoAdjustBase({
    columnAutoAdjust,
    signalColumnsWidths,
    setRecoilState: setHeaderFields,
  });

  return hook;
}


export default useColumnsAutoAdjust;
