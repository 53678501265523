import styled from 'styled-components';
import { zIndex } from './z-index';


export const ViewWrapper = styled.div`
  position: relative;
  z-index: ${zIndex.VIEW};

  width: 100%;
  height: calc(100% - 36px);
`;
