import React from 'react';
import { useState } from 'react';
import Context from './context';


interface Props {
  children: React.ReactNode;
}


export const DeskScrollerRefComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;

  const [deskScroller, setDeskScroller] = useState<HTMLElement | null>(null);

  return (
    <Context.Provider value={[
      deskScroller,
      setDeskScroller
    ]}>
      { children }
    </Context.Provider>
  );
}