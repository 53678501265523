import { ThemeDefProps } from "../types";


export const themeDefs_button = (props: ThemeDefProps) => {
  const { colors, accentColor, border } = props;

  const button = {
    size: {
      primary: {
        height: 32,
      },
      max: {
        width: 170,
      }
    },
    
    color: {
      primary:  colors.color.third,
      selected: colors.color.secondary,
      active:   colors.color.secondary,
      hover:    colors.color.secondary,
    },
    
    background: {
      primary:  colors.background.secondary,
      selected: accentColor.gradient.primary,
      active:   accentColor.dark,
      hover:    accentColor.lighter,
    },

    border: {
      radius: border.radius.normal,
      width:  border.width.normal,
      color:  {
        primary:  colors.border.secondary,
        selected: accentColor.light,
        active:   accentColor.light,
        hover:    accentColor.lighter,
      },
      style: 'solid'
    }
  };
    
  return button;
}

