import styled from "styled-components";
import Scrollbar from "./scrollbar";


const ScrollbarInv = styled(Scrollbar)`
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.defs.colors.scrollbar.thumb.secondary};
  }
`;

export default ScrollbarInv;