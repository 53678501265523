import styled  from 'styled-components';
import { css } from 'styled-components';
import { keyframes } from 'styled-components';


const __growHeight = (height: number) => keyframes`
  from { height: 0px; }
  to   { height: ${height}px; }
`;


const __shrinkHeight = (height: number) => keyframes`
  from { height: ${height}px; }
  to   { height: 0px; }
`;


const __setOverflowVisible = keyframes`
  to { overflow: visible; }
`;


const __setOverflowClip = keyframes`
  to { overflow: clip; }
`;



export interface VisibleProps {
  $enabled: boolean;
  $visible: boolean;
  $panelHeight: number;
};


export const AnimPanelBase = styled.div<VisibleProps>`
  box-shadow:     ${props => props.theme.panel.boxShadow};
  visibility:     ${props => props.$enabled ? 'visible' : 'hidden' };
  pointer-events: ${props => props.$visible ? 'all': 'none'};
  
  overflow: clip;
  height: 0px;

  animation: ${props => props.$visible ? 
    css`
      ${__growHeight(props.$panelHeight)} 0.2s ease-in-out forwards,
      ${__setOverflowVisible} 0.0s ease-in-out 0.2s forwards
    ` : 
    css`
      ${__setOverflowClip} 0.0s ease-in-out forwards,
      ${__shrinkHeight(props.$panelHeight)} 0.2s ease-in-out 0.0s forwards
    `
  };
`;
