import React from 'react';
import { useRecoilValue } from 'recoil';

import useCellImagesMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-images/use-cell-images-menu';
import useCellImageMenu  from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-images/use-cell-image-menu';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';

import TopToolbarComponent from 'app/ui-v2/editor-instruction/blocks/top-toolbar';
import { CellMenuProps } from '../types';


interface Props extends CellMenuProps {
}


export const CellImagesMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr
  } = props;

  const selectedImageAddr = useRecoilValue(UIState_ContentSession.cellImages_imageSelected);

  const getConfigImages = useCellImagesMenu();
  const getConfigImage  = useCellImageMenu();
  
  const menuConfig = (
    selectedImageAddr !== null ?
    getConfigImage(selectedImageAddr) :
    getConfigImages(cellAddr)
  );

  return (
    <TopToolbarComponent
      sections={menuConfig.sections}
    />
  );
}
  
