export type UUID = string;
export type Position = [number, number];
export type Size     = [number, number];


export type Area = {
  x: number,
  y: number,
  width:  number,
  height: number,
}


export enum UnitsType {
  METRIC = 'metric',
  IMPERIAL = 'imperial'
}

export const UnitsSymbol: {[key in UnitsType]: string} = {
  [UnitsType.METRIC]:   'mm',
  [UnitsType.IMPERIAL]: 'in',
}


export enum PageFormatType {
  A3 = 'a3',
  A4 = 'a4',
  A5 = 'a5',
  A6 = 'a6',
  LETTER = 'letter',
  LEGAL  = 'legal'
}

export enum PageOrientationType {
  LANDSCAPE = 'landscape',
  PORTRAIT  = 'portrait'
}

export type PageMarginType = {
  top: number,
  right: number,
  bottom: number,
  left: number,
  units: UnitsType
} 


export type ColorPaletteType = string[][];
