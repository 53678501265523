import { ContentTypes } from "../content";


export const getWidgetStyle = (
  widgetType: ContentTypes.WidgetType, 
  style: any
) => {

  const GetWidgetStyleMap: {[widgetType in ContentTypes.WidgetType]: any} = {
    [ContentTypes.WidgetType.ARROW_PLAIN]: __getWidgetArrowPlainStyle,
    [ContentTypes.WidgetType.ARROW_TEXT ]: __getWidgetArrowTextStyle,
    [ContentTypes.WidgetType.ELLIPSE    ]: __getWidgetEllipseStyle,
    [ContentTypes.WidgetType.RECTANGLE  ]: __getWidgetRectangleStyle,
    [ContentTypes.WidgetType.IMAGE      ]: __getWidgetImageStyle,
    [ContentTypes.WidgetType.TEXT       ]: __getWidgetTextStyle,
  }
  const getWidgetStyleFn = GetWidgetStyleMap[widgetType];
  const updatedStyle = getWidgetStyleFn(style);
  return updatedStyle;
}


const __getWidgetArrowPlainStyle = (style: any) => {
  return __getWidgetStyleUnfilled(style);
}

const __getWidgetArrowTextStyle = (style: any) => {
  return __getWidgetStyleFilled(style);
}

const __getWidgetEllipseStyle = (style: any) => {
  return __getWidgetStyleUnfilled(style);
}

const __getWidgetRectangleStyle = (style: any) => {
  return __getWidgetStyleUnfilled(style);
}

const __getWidgetImageStyle = (style: any) => {
  return __getWidgetStyleUnfilled(style);
}

const __getWidgetTextStyle = (style: any) => {
  return __getWidgetStyleFilled(style);
}


const __getWidgetStyleFilled = (style: any) => {
  if ( 'backgroundColor' in style ) {
    return style;
  }

  const styleUpdated = {
    ...style,
    backgroundColor: '#FFFFFF'
  }

  return styleUpdated;
}

const __getWidgetStyleUnfilled = (style: any) => {
  if ( 'backgroundColor' in style ) {
    return style;
  }

  const styleUpdated = {
    ...style,
    backgroundColor: 'transparent'
  }

  return styleUpdated;
}