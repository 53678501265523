import React from 'react';
import ButtonToolbar from 'lego/styles/button_toolbar';
import TooltipComponentBase from 'lego/components/tooltip';
import { TooltipTypes } from 'lego/components/tooltip';


export interface Props {
  Icon: React.ElementType;
  selected?: boolean;
  onClick?: (event: React.MouseEvent) => void;

  tooltip?: string;
  tooltipPlacement?: TooltipTypes.Placement;
  dataTest?: string;
}


export const ButtonToolbarComponent: React.FC<Props> = (props: Props) => {
  const { 
    tooltip, 
    selected, 
    onClick, 
    Icon,
    dataTest
  } = props;
  
  const tooltipPlacement = props.tooltipPlacement || 'bottom';

  return (
    <TooltipComponentBase
      title={tooltip}
      placement={tooltipPlacement}
    >
      <ButtonToolbar
        data-test={dataTest}
        onClick={onClick}
        $selected={selected}
      >
        <Icon />
      </ButtonToolbar>
    </TooltipComponentBase>
  );
}

