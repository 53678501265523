import styled from "styled-components";
import Title from "lego/styles/title";


export const Box = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;


export const Info = styled(Title)`
  position: absolute;
  z-index: 1;
  top: 50%;
  translate: 0 calc(-50% + 35px);

  /*  */
  /* 
  max-width: 100%; 
  if max-width: 100%; 
  it acctually will limits Info on divs which are not already
  expanded. For example if you go to document releases lists,
  then only thing which is expanding View is Progress spinner. 
  Then if we have max-width: 100% -> effectivly it make of width
  of Progress spinner.
   */
  text-align: center;  

  color:       ${props => props.theme.defs.colors.color.primary};
  background:  ${props => props.theme.defs.colors.background.primary};
  font-size:   ${props => props.theme.defs.font.size.normal};
  font-weight: ${props => props.theme.defs.font.weight.small};
  opacity:     ${props => props.theme.defs.opacity.fade};

  padding: 
    ${props => props.theme.defs.padding.small}
    ${props => props.theme.defs.padding.large}
    ${props => props.theme.defs.padding.small}
    ${props => props.theme.defs.padding.large}
  ;

  border-style: solid;
  border-color:  ${props => props.theme.defs.colors.border.primary};
  border-radius: ${props => props.theme.defs.border.radius.small};
  border-width:  ${props => props.theme.defs.border.width.normal};
`;
