import { ThemeConstsProps } from "../types";


export const themeConsts_border = (props: ThemeConstsProps) => {
  const border = {
    radius: {
      none:    "0px",
      xxsmall: "1px", 
      xsmall:  "2px", 
      small:   "3px", 
      normal:  "5px",
      medium:  "8px",
      large:  "12px",
    },
    width: {
      normal: "1px",
      small:  "2px",
      large:  "3px",
    },
  };

  return border;
}
