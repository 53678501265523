import styled from 'styled-components';


export const DraggedWrapper = styled.div<{debug?: boolean}>`
  background: ${props => props.debug ? 'rgba(255, 0, 0, 0.4)' : 'transparent'};
  padding:    ${props => props.debug ? '10px' : '0px'};

  touch-action: none;
  pointer-events: all;

  /*
    user-select: none 
    is important, or otherwise text from other
    div's will get selected, while dragging item
  */
  user-select: none;
`;


export default DraggedWrapper;