import { Position } from "app/arch/types";
import useIsKeyDownStated from "lego/components/key-bindings/hooks/use-is-key-down-stated";
import useSystemKeys from "lego/components/key-bindings/hooks/use-system-keys";
import useDebugPointSet from "app/ui-v2/app/__modules/debug/hooks/use-debug-point-set";


const SCALE_WHEEL_ROTATE_DELTA = 0.00075;


interface Props {
  disable: boolean;

  getScale: () => number;

  onWheel?: (
    scaleInit: number,
    scaleNew: number, 
    scalePoint: Position,
  ) => void;
}


const useRescaleByWheel = (props: Props) => {
  const {
    disable,
    getScale,
    onWheel,
  } = props;

  const setDebugPoint = useDebugPointSet();

  const systemKeys = useSystemKeys();
  const actionKeys = systemKeys.actionKeys;

  const enabled = useIsKeyDownStated({
    key: actionKeys.scalerEnabled,
    fallThrough: true 
  });

  const __calculateScale = (
    scaleInit: number,
    wheelDelta: number,
  ) => {
    const scaleDelta = -1 * wheelDelta * SCALE_WHEEL_ROTATE_DELTA;
    const scaleNew = scaleInit + scaleDelta;
    
    return scaleNew;
  }

  
  const handleWheel = (event: React.WheelEvent) => {
    if ( disable ) {
      return;
    }

    if ( ! enabled ) {
      return;
    }
    
    const scaleInit = getScale();
    const scaleNew = __calculateScale(scaleInit, event.deltaY);
    const scalePoint = [event.clientX, event.clientY] as Position;

    setDebugPoint({x: scalePoint[0], y: scalePoint[1]});

    onWheel?.(
      scaleInit,
      scaleNew,
      scalePoint,
    );
  }

  return { 
    enabled,
    handleWheel 
  };
}

export default useRescaleByWheel;