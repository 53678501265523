import React from 'react';
import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';
import PricePlanComponent from '../../../price-plan';
import { FlexWrap } from './styles';


interface Props {
}


export const PricePlansPlansComponent: React.FC<Props> = (props: Props) => {
  const plans = [
    PricePlansTypes.PlanType.FREE,
    PricePlansTypes.PlanType.ECO,
    PricePlansTypes.PlanType.TEAM,
  ];

  const PricePlans = plans.map(plan => (
    <PricePlanComponent 
      key={plan} 
      planType={plan} 
    />
  ));

  return (
    <FlexWrap>
      { PricePlans }
    </FlexWrap> 
  );
}

