import styled from "styled-components";
import ListItemBase from "lego/styles/list-item";
import RepoNameBase   from '../../styles/repo-name';


export const ListItem = styled(ListItemBase)`
`;


export const RepoName = styled(RepoNameBase)`
  max-width: 86%;
  font-weight: ${props => props.theme.defs.font.weight.small};
`;


export const InvitationMarker = styled.div`
  color:      ${props => props.theme.defs.colors.color.secondary};
  background: ${props => props.theme.defs.accentColor.darker};

  padding: 
    ${props => props.theme.defs.padding.small}
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.small}
    ${props => props.theme.defs.padding.normal}
  ;

  border-style:  solid;
  border-color:  ${props => props.theme.defs.accentColor.darkest};
  border-width:  ${props => props.theme.defs.border.width.normal};
  border-radius: ${props => props.theme.defs.border.radius.small};

  font-size:   ${props => props.theme.defs.font.size.small};
  font-weight: ${props => props.theme.defs.font.weight.small};
`;