import { createContext } from 'react';
import { ExporterAssetsRepo } from 'app/arch/editor-instruction/exporters/excel-exporter';


export interface ContextProps {
  assetsRepo: ExporterAssetsRepo | null
};


const Context = createContext<ContextProps>({
  assetsRepo: null,
});


export default Context;