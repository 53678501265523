import styled from "styled-components";
import SeparatorBase from "lego/styles/separator";


const SEPARATOR_MARGIN = 6;

const Separator = styled(SeparatorBase)`
  opacity: 1;
  padding: 0;
  margin:  0;

  // TODO a bit hacky
  position: relative;
  left: -${props =>  props.theme.defs.padding.large};

  width: calc(100% + ${props =>  props.theme.defs.padding.large} + ${props =>  props.theme.defs.padding.large});
  
  background: ${props => props.theme.defs.colors.border.primary};

  margin-top: ${SEPARATOR_MARGIN}px;
  margin-bottom: ${SEPARATOR_MARGIN}px;
`;


export default Separator;
