import React from 'react';
import * as Types from '../../types';
import HLS_MenubarItemComponent from 'lego-v2/menubar/hls/menubar-item';
import MenubarItemPanelComponent from '../menubar-item-panel';


interface Props extends Types.MenubarItemProps {
}


export const MenubarItemComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;


  return (
    <HLS_MenubarItemComponent 
      {...props}
      MenubarItemPanel={MenubarItemPanelComponent}
    />
  );
}

