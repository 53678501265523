import DraggerComponent from 'lego-v2/dragger/ui';
import { DraggerUpdate } from 'lego-v2/dragger/arch';

import { LineUpdate } from '../types';

import { LineWrapper } from './styles';
import { StyledLine  } from './styles';
import { DRAGGER_SIZE } from '../styles';


interface Props {
  x: number;
  lineType: string;
  scale: number;

  onUpdate: (update: LineUpdate) => void;
  onUpdateDone: (update: LineUpdate) => void;

  locked: boolean;
}


export const LineVerticalComponent: React.FC<Props> = (props: Props) => {
  const {
    x, 
    lineType,
    scale,
    onUpdate,
    onUpdateDone,
    locked,
  } = props;

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    onUpdate({[lineType]: update.position[0]});
  }

  const handleUpdateDone = (update: DraggerUpdate) => {
    handleDraggerUpdate(update);
    onUpdateDone({[lineType]: update.position[0]});
  }

  // This delta is needed so the limiting line 
  // will be alwyas consistent within the subject
  // of being included or being excluded from the 
  // border. 
  const deltaX = lineType === 'x1' ? 1 : 0;

  return (
    <DraggerComponent
      onUpdate={handleDraggerUpdate}
      onUpdateDone={handleUpdateDone}
      position={[x, 0]}
      scale={scale}
      disabled={locked}
      cursor={'ew-resize'}
    >
      <LineWrapper
        style={{
          left: `${x - DRAGGER_SIZE / 2 / scale}px`,
          transform: `scale(${1 / scale})`,
          transformOrigin: '0 0',
          pointerEvents: locked ? 'none' : 'all',
          cursor: locked ? 'default' : 'ew-resize',
        }}
      >
        <StyledLine $deltaX={deltaX} />
      </LineWrapper>
    </DraggerComponent>
  );
}
