import { atom, selectorFamily } from 'recoil';
import { selector } from 'recoil';
import { ActionsTypes, ActionsState as State } from 'app/arch/app/info/actions';


export const state = atom<State.State>({
  key: "infoActions",
  default: State.createInitialState()
});

export const addrs = selector({
  key: `infoActions__addrs`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getActionsAddrs(stateObj);
  }
});

export const actionState = selectorFamily({
  key: `infoActions__actionState`,
  get: (actionAddr: ActionsTypes.ActionAddr) => ({ get }) => {
    const stateObj = get(state);
    const props = State.getActionProps(stateObj, actionAddr);
    return props.state;
  }
});
