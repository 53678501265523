import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import Strip from './stripe-service';


interface Props {
  clientSecret: string;
  children: React.ReactNode;
}

export const StripeContext: React.FC<Props> = (props: Props) => {
  const { 
    clientSecret,
    children,
  } = props;
  
  const options = {
    clientSecret: `${clientSecret}`,
  };


  return (
    <Elements 
      stripe={Strip.promise} 
      options={options}
    >
      { children }
    </Elements>
  );
};

