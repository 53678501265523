import styled from "styled-components";
import InputBase  from 'lego/styles/input';
import ButtonIcon from "lego/styles/button_icon";
import InputTitleBase   from 'app-views/styles/input-title';
import InputWrapperBase from 'app-views/styles/input-wrapper';

import { AiTwotoneEye          } from 'react-icons/ai';
import { AiTwotoneEyeInvisible } from 'react-icons/ai';
import getReactIconProps from "lego/css-props/react-icon";


export const MainWrapper = styled(InputWrapperBase)`
`;

export const Button = styled(ButtonIcon)`
  position: absolute;
  right: 0px;
  top: 0px;
`;

export const ShowIcon = styled(AiTwotoneEye)`
  ${props => getReactIconProps(props)};
`;

export const HideIcon = styled(AiTwotoneEyeInvisible)`
  ${props => getReactIconProps(props)};
`;

export const InputTitle = styled(InputTitleBase)`
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
`;