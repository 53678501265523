import React from 'react';

import environment from 'app/environment';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useCellsRowContext  from '../hooks/use-cells-row-context';
import CellProbeComponent  from './cell-probe';
import CellRenderComponent from './cell-render';

import { GridCell } from './styles';


interface Props {
  cellAddr: ContentTypes.CellAddr;
}


export const CellComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;
  
  const document = useDocState();

  const cellsRowContext = useCellsRowContext();

  const { 
    setContent 
  } = useEditorStatesSetters();

  const cellDivId = ContentTools.getCellHTMLId(cellAddr);
  const columnType = document.content.getColumnType(cellAddr);
  const isIdxColumn = (columnType === ContentTypes.ColumnType.INDEX);
  
  const rowAdder = cellsRowContext.rowAdder;
  const pageLastItem = cellsRowContext.pageLastItem;

  const cellDataTest = ContentTools.getCellDataTest({
    cellAddr,
    docState: document,
    prefix: 'content-cell'
  });

  const handleAddRow = (event: React.MouseEvent) => {
    document.content.addRow(cellAddr);
    document.saveUndo();
    setContent();
  }

  const rowAdderProps = (
    rowAdder ?
    {
      onPointerDownCapture: handleAddRow,
    } :
    {}
  );


  return (
    <CellProbeComponent
      enabled={isIdxColumn}
      cellAddr={cellAddr}
    >
      <GridCell
        id={cellDivId}
        data-test={cellDataTest}
        borderLeft={isIdxColumn}
        borderBottom={pageLastItem}
        faded={rowAdder}
        {...rowAdderProps}
      >
        <CellRenderComponent 
          cellAddr={cellAddr}
        />
      </GridCell>
    </CellProbeComponent>
  );
}

