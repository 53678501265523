import styled from 'styled-components';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';


export const Textarea = styled(TextareaAutosize)`
  box-sizing: border-box;
  border-radius: ${props => props.theme.defs.border.radius.small};

  text-align: center;
  text-transform: uppercase;
  resize: none;

  outline-style: none;

  max-width: 100%;
  font-family:${props => props.theme.defs.font.family.join(', ') };

  padding: 
    ${props => props.theme.defs.padding.medium} 
    ${props => props.theme.defs.padding.large}
  ;
`;
