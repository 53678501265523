import styled from "styled-components";
import Panel  from "./panel";


// For now used for internal panel in panel menus
// Like column type menu - when you create new column
// Or select dropdowns like language select
export const PanelThird = styled(Panel)`
  padding:    ${props => props.theme.defs.padding.normal};
  background: ${props => props.theme.defs.colors.background.secondary};
  box-shadow: none;

  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xxsmall};

  &:focus-visible {
    outline-style: ${props => props.theme.attrs.focusVisible.outline.style};
    outline-width: ${props => props.theme.attrs.focusVisible.outline.width};
    outline-color: ${props => props.theme.attrs.focusVisible.outline.color};
  }
`;


export default PanelThird;