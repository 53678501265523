import styled from "styled-components";
import Text from "lego/styles/text";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  box-sizing: border-box;
`;


export const PromoTitle = styled(Text)`
  font-size: ${props => props.theme.defs.font.size.xxlarge};
  font-weight: ${props => props.theme.defs.font.weight.large};

  overflow: unset;
  white-space: wrap;
`;


export const PromoText = styled(Text)`
  font-size: ${props => props.theme.defs.font.size.xlarge};

  overflow: unset;
  white-space: wrap;
`;

