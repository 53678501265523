import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import ControlThemeModeComponent from 'lego/components/panel-controls/controls-app-view-front/control-theme-mode';
import ControlThemeAccentColorComponent from 'lego/components/panel-controls/controls-app-view-front/control-theme-accent-color';

import { useTranslations } from 'app/ui/hooks/app/use-translation';


const useConfig = () => {
  const t = useTranslations();

  const getConfigTheme = (): PanelControlsConfig => {
  
    const config: PanelControlsConfig = {
      items: [
        {
          title: t('theme'),
          sections: [
            {
              items: [
                {
                  title: t('theme mode'),
                  type: PanelControlsItemSectionItemType.USER_COMPONENT,
                  component: ControlThemeModeComponent
                },
                {
                  title: t('theme accent color'),
                  type: PanelControlsItemSectionItemType.USER_COMPONENT,
                  component: ControlThemeAccentColorComponent
                },              
              ]
            }
          ]
        },
      ]
    } // config
  
    return config;
  }

  return getConfigTheme;
};


export default useConfig;