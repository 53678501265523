import { SideToolbar } from 'app/arch/editor-image/types';
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";
import useState from './__use-state';



const useFunctions = () => {
  const document = useDocState();
  const state = useState();
  
  const {
    setEditorImageSession,
  } = useEditorStatesSetters();
  

  const __switchPanel = (newPanelType: SideToolbar.PanelType) => {
    if (state.panelVisible) {
      if (state.panelType !== newPanelType) {
        document.editorImageSession.setSideToolbarPanelType(newPanelType);
      }
      else {
        document.editorImageSession.setSideToolbarPanelVisible(false);
      }
    }
    else {
      document.editorImageSession.setSideToolbarPanelVisible(true);
      document.editorImageSession.setSideToolbarPanelType(newPanelType);
    }

    setEditorImageSession();
  }

  const showColors = () => {
    __switchPanel(SideToolbar.PanelType.COLORS);
  }

  const showLayers = () => {
    __switchPanel(SideToolbar.PanelType.LAYERS);
  }

  const showGrid = () => {
    __switchPanel(SideToolbar.PanelType.GRID);
  }

  const showWidgetStyles = () => {
    __switchPanel(SideToolbar.PanelType.STYLES);
  }

  const showWidgetArrow = () => {
    __switchPanel(SideToolbar.PanelType.ARROW);
  }
  
  const showWidgetTextBox = () => {
    __switchPanel(SideToolbar.PanelType.TEXT_BOX);
  }

  const showSmartLines = () => {
    __switchPanel(SideToolbar.PanelType.SMART_LINES);
  }

  const showView = () => {
    __switchPanel(SideToolbar.PanelType.VIEW);
  }
  

  return {
    showColors,
    showWidgetArrow,
    showWidgetTextBox,
    showWidgetStyles,

    showView,
    showLayers,

    showGrid,
    showSmartLines,
  }

}


export default useFunctions;