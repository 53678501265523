import React from 'react';
import { PanelControlsItemSectionControl_TextMultilineConfig as Config} from '../../config';
import { Text, TextWrapper } from './styles';


interface Props {
  config: Config;
}


export const ControlTextMultilineComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;

  const text = (
    config.value !== undefined && config.value.length > 0 ?
    config.value :
    <>&nbsp;</>
  );

  return (
    <TextWrapper>
      <Text>
      { text }
      </Text>
    </TextWrapper>
  );
}
