import { ContentTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset       from '../use-selected-reset';


const useRowMove = () => {
  const document = useDocState();
  const {
    setContent,
  } = useEditorStatesSetters();

  const resetSelected = useSelectedReset();
  
  const moveRow = (props: {
    srcRow: Types.RowAddr,
    dstRow: Types.RowAddr,
  }) => {
    const {
      srcRow,
      dstRow,
    } = props;

    document.content.moveRowToRow(srcRow, dstRow);
    document.saveUndo();
    setContent();
    resetSelected();
  }

  return moveRow;
}


export default useRowMove;