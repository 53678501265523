import React from 'react';
import { useState } from 'react';

import TooltipComponent from 'lego/components/tooltip';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import HLS_MarkerViewLabelComponent from 'app/ui-v2/editor-markers/hls/marker-views/marker-view-label';

import { MarkerViewProps } from '../types';
import MarkerTextInputComponent from './marker-text-input';

import { MainWrapper } from './styles';


interface Props extends MarkerViewProps {
}


export const MarkerViewLabelComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    markerProps
  } = props;

  const t = useTranslations();
  const [textEditing, setTextEditing] = useState(false);

  const handleEditText = (event: React.MouseEvent) => {
    event.stopPropagation();
    setTextEditing(true);
  }

  const hanleMarkerChangeDone = () => {
    setTextEditing(false);
  }

  const hanleMarkerChangeCancel = () => {
    setTextEditing(false);
  }


  return (
    <>
      {
        ! textEditing &&
        <TooltipComponent title={t("dblclick to edit")}>
          <MainWrapper onDoubleClick={handleEditText}>
            <HLS_MarkerViewLabelComponent markerProps={markerProps} />
          </MainWrapper>
        </TooltipComponent>
      }

      {
        textEditing &&
        <MarkerTextInputComponent
          markerAddr={markerAddr}
          onDone={hanleMarkerChangeDone}
          onCancel={hanleMarkerChangeCancel}
        />
      }
    </>
  );
}
