import styled from "styled-components";
import ButtonBase from "lego/styles/button";


export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Button = styled(ButtonBase)`
  width: 80%;
  height: auto;

  padding: ${props => props.theme.defs.padding.normal};
  font-size: ${props => props.theme.defs.font.size.xxlarge};
  font-weight: ${props => props.theme.defs.font.weight.medium};
`;
