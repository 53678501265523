import React from 'react';

import SliderComponentBase from 'lego/components/slider/slider';
import SliderRawComponent  from 'lego/components/slider/slider-raw';

import { PanelControlsItemSectionControl_SliderConfig as Config} from '../../config';
import { unpackBoolean } from '../../tools';
import { unpackNumber }  from '../../tools';


interface Props {
  config: Config;
}


export const ControlSliderComponent: React.FC<Props> = (props: Props) => {
  const { 
    config 
  } = props;

  const sliderDisabled = unpackBoolean(config.disabled);
  const sliderValue    = unpackNumber(config.value);

  const getStepValue = (value: number) => {
    if (config.step === undefined) {
      return value;
    }

    const rem = value % config.step;
    const stepValue = value - rem;

    return stepValue;
  }

  const handleChangeStart = () => {
    config.onChangeStart?.();
  }

  const handleChange = (value: number | null) => {
    if (value === null) {
      return;
    }

    const stepValue = getStepValue(value);
    config.onChange?.(stepValue);
  }

  const handleChangeDone = (value: number | null) => {
    if (value === null) {
      return;
    }

    const stepValue = getStepValue(value);
    config.onChangeDone?.(stepValue);
  }

  const SliderComponent = (
    config.controlLess ? 
    SliderRawComponent : 
    SliderComponentBase
  );

  return (
    <SliderComponent 
      step={config.step}
      value={sliderValue}
      onChangeStart={handleChangeStart}
      onChange={handleChange}
      onChangeDone={handleChangeDone}
      max={config.max}
      min={config.min}
      disabled={sliderDisabled}
      rateControl={config.rateControl}
    />
  );
}
  
