import React from 'react';
import { useRecoilValue } from 'recoil';
import { HeaderRowsTools } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { UIState_HeaderRows } from 'app/ui/states/editor-instruction';
import { CellComponent } from './cell/Cell';
import CellContextComponent from '../cell-context';


interface Props {
}


export const CellsComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const rowsAddrs = useRecoilValue(UIState_HeaderRows.rowsAddrs);

  const renderCells = () => {
    const Cells = rowsAddrs.map((rowAddr, idx) => {
      const isRowLast = (idx === (rowsAddrs.length - 1));
      
      const key = HeaderRowsTools.getRowKey(rowAddr);
      return (
        <React.Fragment key={key}>
          <CellContextComponent isRowLast={isRowLast}>
            <CellComponent rowAddr={rowAddr} />
          </CellContextComponent >
        </React.Fragment>
      )
    });

    return Cells;
  }


  return renderCells();
}

