import React from 'react';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { MainWrapper } from './styles';


interface Props {
  markerAddr: ContentTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerIdComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;

  const htmlId = ContentTools.getMarkerHTMLId(markerAddr);

  return (
    <MainWrapper id={htmlId}>
      { children }
    </MainWrapper>
  );
}
