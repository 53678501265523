import React from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';

import useKeyBindings from './hooks/use-key-binding';


interface Props {
  imageAddr: Types.ImageAddr;
  children: React.ReactNode;
}


export const ImageKeyBindingsComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children,
  } = props;

  const {
    addBindings,
    removeBindings
  } = useKeyBindings(imageAddr);

  const isSelected = useRecoilValue(UIState_ContentSession.cellImages_isImageSelected(imageAddr));

  useEffect(() => {
    if (isSelected === false) {
      return;
    }

    addBindings();

    return () => {
      removeBindings();
    }
  }, [isSelected]);

  return children;
}

