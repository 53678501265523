import React from 'react';
import { useRecoilValue } from 'recoil';

import { UUID } from 'app/arch/types';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';

import { useDocState } from 'app/ui/contexts/document';
import { UIState_Releaselogs } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { Column }             from 'app/ui-v2/editor-instruction/views/shared/components/columns-resizers/types';

import useReleaselogColumnsWidths       from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/columns/use-releaselog-columns-widths';
import useReleaselogColumnsWidthsSignal from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/columns/use-releaselog-columns-widths-signal';
import HLS_ColumnsResizersComponent     from 'app/ui-v2/editor-instruction/views/shared/components/columns-resizers';


interface Props {
  releaselogId: UUID;
  debug?: boolean;
}


export const ColumnsResizersComponent: React.FC<Props> = (props: Props) => {
  const { 
    releaselogId,
    debug,
  } = props;
  const document = useDocState();
  const releaselogAddr = { releaselogId };
  
  const { 
    setReleaselogs,
  } = useEditorStatesSetters();

  // Signal that columns widths have changed.
  const signalColumnsWidths = useReleaselogColumnsWidthsSignal(releaselogAddr);

  // This rerender is needed for the same
  // changelog which exists on multiple pages and/or
  // for undo.
  useReleaselogColumnsWidths(releaselogAddr);

  // If we add new column, we need to update so resizer appear.
  // Also it will kick in when column width gets changed
  const columnsAddrs = useRecoilValue(UIState_Releaselogs.columnsAddrs(releaselogAddr));
  
  const columnsResizers: Column[] = columnsAddrs.map((columnAddr) => {
    const columnProps = document.releaselogs.getColumnProps(columnAddr);
    const columnResizer = {
      id: columnAddr.columnId,
      width: columnProps.width,
    }

    return columnResizer;
  });
  
  const handleSetColumnsWidth = (columns: Column[]) => {
    columns.forEach((column) => {
      const columnAddr: Types.ColumnAddr = { 
        releaselogId, 
        columnId: column.id
      };
      const width = column.width;
      document.releaselogs.updateColumnProps(columnAddr, { width });
    });

    setReleaselogs();
    signalColumnsWidths();
  }

  const handleResizeEnd = () => {
    document.saveUndo();
  }

  
  return (
    <HLS_ColumnsResizersComponent
      columns={columnsResizers}

      onSetColumnsWidth={handleSetColumnsWidth}
      onResizeEnd={handleResizeEnd}

      debug={debug}
      dataTest={'editor__view-releaselog__content'}
    />
  );
}
 