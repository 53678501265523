import { uuid } from "tools/uuid";
import * as Types from './types';


/**
 * Toast
 */

export const getToastKey = (
  toastAddr: Types.ToastAddr
): string => (
  `${toastAddr.toastId}`
);

export const createToastAddr = (
): Types.ToastAddr => {
  const toastId = uuid();
  const toastAddr = { toastId };
  return toastAddr;
}   

export const compareToastAddr = (
  srcToastAddr: Types.ToastAddr,
  dstToastAddr: Types.ToastAddr,
): boolean => (
  srcToastAddr.toastId === dstToastAddr.toastId
);

export const getToastColor = (
  level: Types.ToastLevel
): string => {
  const ToastsColors: {[color in Types.ToastLevel]: string} = {
    [Types.ToastLevel.SUCCESS]: 'rgba(0, 128, 0, 1)',
    [Types.ToastLevel.ERROR  ]: 'rgba(252, 33, 33, 1)',
    [Types.ToastLevel.WARN   ]: 'rgba(255, 140, 0, 1)',
    [Types.ToastLevel.INFO   ]: '#007bff',
  }

  const color = ToastsColors[level];
  return color;
}