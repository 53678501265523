import { Position } from "app/arch/types";
import { DeltaMove } from "app/ui/hooks";


//----------------------------
// Arrow head draggers updates
//
export interface DraggerHeadUpdateStart {
  clickOffset: Position;
}

export interface DraggerHeadUpdate {
  delta: DeltaMove;
}


//----------------------------
// Arrow body draggers updates
//
export interface DraggerBodyUpdate {
  startPoint: Position;
  endPoint:   Position;
}



//----------------------------
// Arrow tail draggers updates
//
export interface DraggerTailUpdateStart {
}

export interface DraggerTailUpdate {
  delta: DeltaMove;
}




export const DraggerSizeMin = 36;