import React from 'react';
import useImageUrlGet from 'app/ui-v2/editor-instruction/hooks/use-image-url-get';
import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';

import ImageDraggerComponent     from './image-dragger';
import ImageContextMenuComponent from './image-context-menu';
import ImageSelectedComponent    from './image-selected';
import ImageDataTestComponent    from './image-data-test';

import { StyledImage  } from './styles';


interface Props {
  image: RepoImagesTypes.Image;
  idx: number;
}


export const ImageComponent: React.FC<Props> = (props: Props) => {
  const { 
    image,
    idx,
  } = props;

  const getImageUrl = useImageUrlGet();
  const imageUrl = getImageUrl(image);

  return (
    <ImageDataTestComponent idx={idx} >
      <ImageContextMenuComponent image={image} >
        <ImageDraggerComponent image={image} >
          <ImageSelectedComponent >
            <StyledImage 
              src={imageUrl}
              draggable="false"
            />
          </ImageSelectedComponent>
        </ImageDraggerComponent>
      </ImageContextMenuComponent>
    </ImageDataTestComponent>
  );
}
