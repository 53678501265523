import React from 'react';
import SidePanelBaseComponent from 'lego/components/side-controls/side-panel';

import { LogoImage } from '../../types';
import { LogoView }  from '../../types';

import useConfig from './config';


interface Props {
  logoImage: LogoImage;
  logoImageView: LogoView;
}


export const EditorLogoPanelComponent: React.FC<Props> = (props: Props) => {
  const {
    logoImage,
    logoImageView
  } = props;

  const getConfig = useConfig({
    logoImage,
    logoImageView
  });

  const config = getConfig();

  
  return (
    <SidePanelBaseComponent
      visible={true}
      right={true}
      config={config}
    />
  );
}
