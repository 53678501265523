import React from 'react';

import { Form }   from './styles';
import { Button } from './styles';
import { ButtonWrapper } from './styles';
import { InputsWrapper } from './styles';


interface Props {
  submitDisabled?: boolean;
  buttonText?: string;
  onSubmit?: () => void;

  children: React.ReactNode;

  btnDataTest?: string;
}


export const FormPanelComponent: React.FC<Props> = (props: Props) => {
  const { 
    submitDisabled,
    buttonText, 
    onSubmit, 

    children, 
    btnDataTest,
  } = props;

  const handleSubmit = (event: any) => {
    event.preventDefault();
    onSubmit?.();
  }

  return (
    <Form onSubmit={handleSubmit}>
      
      <InputsWrapper>
        { children }
      </InputsWrapper>

      <ButtonWrapper>
        <Button 
          disabled={submitDisabled}
          data-test={btnDataTest}
        >
        { buttonText }
        </Button>
      </ButtonWrapper>

    </Form>
  );
}
  
