import { ThemeConstsProps } from "../types";


export const themeConsts_opacity = (props: ThemeConstsProps) => {
  const opacity = {
    small:        0.2, 
    medium:       0.3, 
    disabledMore: 0.4, 
    disabled:     0.5, 
    xlarge:       0.85,

    fade:       0.7,
    fadeMore:   0.4,

    description: 0.6,
    text:        0.8,
    
    fadeMenu:  0.2,
    overlayer: 0.95,
  };

  return opacity;
}
