import styled from "styled-components";
import TitleBase from "lego/styles/title";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.large};
  padding-left: ${props => props.theme.defs.padding.normal};
`;

const TITLE_WIDTH = '30%';

export const Title = styled(TitleBase)`
  width: ${TITLE_WIDTH};
  text-align: right;
  justify-self: flex-end;
  /* align-self: flex-end; */
  
  padding-top: 4px;
  font-size:   ${props => props.theme.defs.font.size.normal};
  font-weight: ${props => props.theme.defs.font.weight.small};

  /* background-color: red; */
`;

export const Item = styled.div`
  width: calc(100% - ${TITLE_WIDTH} - ${props => props.theme.defs.gap.large});
  /* background-color: red; */
`;