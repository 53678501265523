import React, { useEffect, useRef } from 'react';

import environment from 'app/environment';
import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { useInstruction } from 'app/ui/hooks/editor-instruction/use-instruction';
import { useDocState }    from 'app/ui/contexts/document';

import { MainWrapper } from './styles';
import { StyledImage } from './styles';


type Image = RepoImagesTypes.Image;

interface Props {
  onLoaded: () => void;
}


export const ImagesLoaderComponent: React.FC<Props> = (props: Props) => {
  const {
    onLoaded
  } = props;

  const instruction = useInstruction();
  const document = useDocState();
  
  const loadedImages = useRef<number[]>([]);
  
  useEffect(() => {
    const images = document.repoImages.getImages();
    if (images.length === 0) {
      onLoaded();
    }
  }, []);

  const getImageUrl = (image: Image) => {
    const imageUrl = environment.backend.getInstructionImageUrl(
      instruction.repoId !,
      instruction.id !,
      image.filename
    );

    return imageUrl;
  }

  const handleImageLoaded = (image: Image) => {
    loadedImages.current.push(image.bid);
    const images = document.repoImages.getImages();

    if (loadedImages.current.length === images.length) {
      onLoaded();
    }
  }

  const renderImages = () => {
    const images = document.repoImages.getImages();

    const imagesComponents = images.map((image: Image) => {
      const imageUrl = getImageUrl(image);
      return (
        <StyledImage
          key={image.bid}
          src={imageUrl}
          onLoad={() => { handleImageLoaded(image); }}
        />
      );
    });

    return imagesComponents;
  }

  return (
    <MainWrapper>
      { renderImages() }
    </MainWrapper>
  );
}
  
