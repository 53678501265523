import React from 'react';

import { PanelControlsItemSectionConfig } from '../../../config';
import { unpackBoolean }   from '../../../tools';
import DynamicWidthWrapper from '../../../styles/dynamic-width-wrapper';
import DynamicWidth        from '../../../styles/dynamic-width';

import { MainWrapper } from './styles';
import { Title       } from './styles';
import { ItemWrapper } from './styles';
import { Separator   } from './styles';


interface Props {
  config: PanelControlsItemSectionConfig;
  SectionSubsection: React.ElementType;
  SectionItem: React.ElementType;
}


export const PanelSectionComponent: React.FC<Props> = (props: Props) => {
  const { 
    config,
    SectionItem,
    SectionSubsection,
  } = props;
  
  const sectionDisabled = unpackBoolean(config.disabled);
  const subsectionsEnabled = config.subsections !== undefined;

  const renderItems = () => {
    if (config.items === undefined) {
      return null;
    }

    const items = config.items.filter((item) => ! unpackBoolean(item.skip));
    const itemsComps = items.map((item, idx) => {

      return (
        <ItemWrapper key={`${idx}`}>
          <SectionItem 
            config={item}
            sectionDisabled={sectionDisabled}
          />
        </ItemWrapper>
      )
    });

    return itemsComps;
  }
  
  const renderSubsections = () => {
    if ( config.subsections === undefined ) {
      throw new Error('Config.subsections is null');
    }

    const subsections = config.subsections.filter((subsection) => ! unpackBoolean(subsection.skip));
    const subsectionsComps = subsections.map((subsection, idx) => {
      return (
        <React.Fragment key={`${idx}`}>
          <SectionSubsection config={subsection} />
          <Separator />
        </React.Fragment>
      )
    });

    return subsectionsComps;
  }


  return (
    <MainWrapper $disabled={sectionDisabled}>
      { 
        config.title &&
        <>
          <DynamicWidthWrapper>
            <DynamicWidth>
              <Title>
                { config.title }
              </Title>
            </DynamicWidth>
          </DynamicWidthWrapper>

          <Separator />
        </>
      }

      {   subsectionsEnabled && renderSubsections() }
      { ! subsectionsEnabled && renderItems() }

    </MainWrapper>
  );
}
  
