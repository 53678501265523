import { PrintoutViewTypes } from 'app/arch/editor-instruction/document/states/printout/printout-view';
import Settings from '../../configs/settings';
import useSlicerStateBase from '../../../shared/hooks/use-slicer-state';

const DEBOUNCE_DELAY = Settings.slicer.setReady.debounce;


export const useSlicerState = () => {

  return useSlicerStateBase({
    view: PrintoutViewTypes.ViewType.CHANGELOG,
    debounceDelay: DEBOUNCE_DELAY,
  });
}

export default useSlicerState;