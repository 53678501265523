import styled from "styled-components";




export const MainWrapper = styled.div`
  @media print {
    display: none;
  }

  position: fixed;
  right: 10px;
  bottom: 50px;

  display: flex;
  flex-direction: column;
  gap: 3px;

  justify-content: flex-end;
  align-items: flex-end;
`;


const Label = styled.div`
  height: 10px;

  padding: 6px;

  font-size: 12px;
  background-color: #aaa;

  border-width: 2px;
  border-style: solid;
  border-color: black;
  border-radius: 4px;
`;

export const PrintoutReadyMark = styled(Label)`
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const DebugLabel = styled(Label)`
`;

export const LabelTitle = styled.div`
  display: inline;
  font-size: 12px;
  font-weight: 800;
  padding-right: 5px;
`;