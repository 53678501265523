import Logger from 'libs/debug';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDialogOk }     from 'app/ui/components/editor-instruction/dialogs/dialog-ok/use-dialog-ok';


const useFeatureLimitedInfo = () => {
  const t = useTranslations();
  const showDialog = useDialogOk();

  const showMsg = (limitType: string) => {
    const logger = Logger.getAppLimits();
    logger.debug(`Limit reached: ${limitType}`);

    const title   = t(`feature limit title, ${limitType}`);
    const message = t(`feature limit msg, ${limitType}`);
    showDialog({ title, message });
  }


  return showMsg;
}


export default useFeatureLimitedInfo;