import styled from "styled-components";
import TextWrap from "./text-wrap";


export const WindowTitle = styled(TextWrap)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;

  user-select: none;
  padding-left: ${props => props.theme.defs.padding.normal};
  
  color: ${props => props.theme.window.title.color};
  background: ${props => props.theme.window.title.background};
  
  font-size: ${props => props.theme.window.title.font.size};
  font-weight: ${props => props.theme.window.title.font.weight};
  font-variant: small-caps;
  text-transform: capitalize;
`;


export default WindowTitle;