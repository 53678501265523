import { ApolloError } from '@apollo/client';
import { useProgressModal } from 'lego-v2/progress-modal';
import useAppReset from './use-app-reset';
import { useAuth } from "services/auth";


type OnDoneFn = () => void;
type OnErrorFn = (error: string) => void;


const useAppLogout = () => {
  const auth = useAuth();
  const resetApp = useAppReset();

  const { 
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();

  
  const logout = (callbacks?: {
    onDone?: OnDoneFn,
    onError?: OnErrorFn,
  }) => {

    showProgressModal();

    auth.logout().subscribe({
      next(result: any) {
        resetApp();
        callbacks?.onDone?.();
        // no need hideProgress 
        // as logout will start app loaders to refresh
        // which will hide progress when
      },
      error(error: ApolloError) {
        hideProgressModal();
        callbacks?.onError?.(error.message);
      }
    });
  }

  return logout;
}

export default useAppLogout;