import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }   from 'app/ui/contexts/document';
import useColumnSelected from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-column-selected';

import useColumnIndexConfig   from './__use-column-index-config';
import useColumnTextConfig    from './__use-column-text-config';
import useColumnImagesConfig  from './__use-column-images-config';
import useColumnMarkersConfig from './__use-column-markers-config';
import useNoColumnConfig      from './__use-no-column-config';


const usePanelColumnConfig = () => {
  const document = useDocState();
  const selectedColumnAddr = useColumnSelected();

  const getNoColumnConfig      = useNoColumnConfig();
  const getColumnIndexConfig   = useColumnIndexConfig();
  const getColumnTextConfig    = useColumnTextConfig();
  const getColumnImagesConfig  = useColumnImagesConfig();
  const getColumnMarkersConfig = useColumnMarkersConfig();

  //--------------------
  //
  // Configs
  //

  const getConfig = () => {
    if (selectedColumnAddr === null) {
      return getNoColumnConfig();
    }

    const columnProps = document.content.getColumnProps(selectedColumnAddr);
  
    const Configs: {[key in TypesContent.ColumnType]: () => PanelControlsConfig } = {
      [TypesContent.ColumnType.INDEX  ]: getColumnIndexConfig,
      [TypesContent.ColumnType.TEXT   ]: getColumnTextConfig,
      [TypesContent.ColumnType.IMAGES ]: getColumnImagesConfig,
      [TypesContent.ColumnType.MARKERS]: getColumnMarkersConfig,
    }

    const getConfig = Configs[columnProps.type];
    const config = getConfig();
    
    return config;
  }

  return getConfig;
}
  
export default usePanelColumnConfig;
