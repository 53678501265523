import styled from "styled-components";


export const LastUpdate = styled.div`
  font-size: 12px;
  font-weight: 700;
  font-variant: small-caps;
`;


export const Content = styled.div`
`;

export const ContentTitle = styled.div`
  font-size: 28px;
  font-weight: 800;
  font-variant: small-caps;
`;


export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Section = styled.div`
`;

export const SectionTitle = styled.div`
  font-size: 23px;
  font-weight: 700;
  font-variant: small-caps;
`;


export const Paragraphs = styled.div`
`;

export const Paragraph = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 11px;
  font-weight: 400;
`;

export const Endlog = styled.p`
  font-size: 16px;
  font-weight: 500;
`;
