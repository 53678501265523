import { useRecoilValue } from 'recoil';

import { EditorSessionTypes } from 'app/arch/editor-instruction/document/states/sessions/editor-session';
import ViewSandboxComponent   from 'app/ui-v2/editor-instruction/views/view-sandbox';
import ViewChangelogComponent from 'app/ui-v2/editor-instruction/views/view-changelog';
import ViewPrintoutComponent  from 'app/ui-v2/editor-instruction/views/view-printout';
import ViewContentComponent   from 'app/ui-v2/editor-instruction/views/view-content';
import { UIState_EditorSession } from 'app/ui/states/editor-instruction';

import { ViewWrapper } from './styles';



interface Props {};

export const ViewsComponent: React.FC<Props> = (props: Props) => {
  const viewType  = useRecoilValue(UIState_EditorSession.viewType);
  
  const ViewsMap: {[viewType in EditorSessionTypes.ViewType]: React.FC} = {
    [EditorSessionTypes.ViewType.CHANGELOG ] : ViewChangelogComponent,
    [EditorSessionTypes.ViewType.CONTENT   ] : ViewContentComponent,
    [EditorSessionTypes.ViewType.PRINTOUT  ] : ViewPrintoutComponent,
    [EditorSessionTypes.ViewType.SANDBOX   ] : ViewSandboxComponent,
  };

  const View = ViewsMap[viewType];
  const isPrintout = viewType === EditorSessionTypes.ViewType.PRINTOUT;


  //-----------------------
  // Render
  //
  return (
    <>
      {   isPrintout && <View /> }
      {
        ! isPrintout &&
        <ViewWrapper>
          <View />
        </ViewWrapper>
      }
    </>
  );
}

