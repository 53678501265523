import { atom     } from 'recoil';
import { selector } from 'recoil';
import { EditorImageSettingsState as State } from 'app/arch/editor-instruction/document/states/persistent/editor-image-settings';



export const state = atom<State.State | null>({
  key: "editorInstructionSettings",
  default: null
});


//--------------------
//
// Selectors
//

export const grid = selector({
  key: `editorInstructionSettings_grid`,
  get: ({ get }) => {
    const stateObj = get(state) || State.createInitialState();
    return State.getGrid(stateObj);
  }
});

export const smartLines = selector({
  key: `editorInstructionSettings_smartLines`,
  get: ({ get }) => {
    const stateObj = get(state) || State.createInitialState();
    return State.getSmartLines(stateObj);
  }
});

