import styled from "styled-components";


export const Anchor = styled.div`
  position: relative;
`;

export const ColumnsResizersDetacher = styled.div`
  position: absolute;

  height: 100%;
  width: 0px;
`;