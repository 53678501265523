import styled from 'styled-components';
import PanelBase  from 'app-views/styles/panel';
import ButtonText from "lego/styles/button_text";


export const Panel = styled(PanelBase)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
`;


export const Button = styled(ButtonText)`
  min-width: 164px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.defs.gap.normal};
`;

export const IconWrap = styled.div`
  position: relative;
  bottom: 1px;

  flex: 0 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;