import { InfoActionsTypes } from "app/arch/app/info";

import useInfoStatesSetters from "../../../hooks/use-info-state-setters";
import useActionsState from "./use-actions-state";
import useActionAdd from "./use-action-add";
import useActionScheduleDelete from "./use-action-schedule-delete";
import { useRef } from "react";
import { ActionsTypes } from "app/arch/app/info/actions";


type UseActionScheduleDelete = (...args: any[]) => void; // Define the type for __delAction



const useActionManage = () => {
  const actionAddrRef = useRef<ActionsTypes.ActionAddr | null>(null);
  const __addAction = useActionAdd();
  const __delAction = useActionScheduleDelete();

  type AddAction = typeof __addAction;

  
  const __resetActionAddr = () => {
    actionAddrRef.current = null
  }

  const addAction = (...args: Parameters<AddAction>) => {
    if (actionAddrRef.current !== null) {
      const msg = `Action addr is already set`;
      console.error(msg);
      return;
    }

    const actionAddr = __addAction(...args);
    actionAddrRef.current = actionAddr;
  }

  const delAction = () => {
    const actionAddr = actionAddrRef.current;
    if (actionAddr === null) {
      const msg = `Action is missing`;
      console.error(msg);
      return;
    }

    __resetActionAddr();

    return __delAction(actionAddr);
  }
  

  return {
    addAction,
    delAction,
  };
}


export default useActionManage;