import { useSetRecoilState } from "recoil";

import { UIHomePageUser_Navbar }    from "../states";
import { UIHomePageUser_View }      from "../states";
import { UIHomePageUser_UserPanel } from "../states";
import useState from "./use-state";


const useStateSetters = () => {
  const homePageUser = useState();

  const __setNavbar    = useSetRecoilState(UIHomePageUser_Navbar.state);
  const __setView      = useSetRecoilState(UIHomePageUser_View.state);
  const __setUserPanel = useSetRecoilState(UIHomePageUser_UserPanel.state);
 
  const setNavbar    = () => __setNavbar(homePageUser.navbar.state);
  const setView      = () => __setView(homePageUser.view.state);
  const setUserPanel = () => __setUserPanel(homePageUser.userPanel.state);

  const setAll = () => {
    setNavbar();
    setView();
    setUserPanel();
  }

  return {
    setAll,
    
    setNavbar,
    setView,
    setUserPanel,
  }
}

export default useStateSetters;