import * as docx from "docx";

import jtl from 'tools/jtl';

import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import AssetsRepo from "../parts/assets-repo";
import ViewContentExporter from "./view-content-exporter";
import ViewReleaselogExporter from "./view-releaselog-exporter";


class WinwordExporter {
  private _docState: DocState;
  private _assetsRepo: AssetsRepo;

  constructor(docState: DocState) {
    this._docState = docState;
    this._assetsRepo = new AssetsRepo();
  }

  get content() { return this._docState.content; }
  get assetsRepo() { return this._assetsRepo; }

  async download() {
    const viewContentExporter = new ViewContentExporter(this._docState, this.assetsRepo);
    const viewReleaselogExporter = new ViewReleaselogExporter(this._docState, this.assetsRepo);
    
    await this._assetsRepo.generatePngs();

    const viewContentSections = viewContentExporter.getSections();
    const viewReleaselogSections = viewReleaselogExporter.getSections();

    const sections: (docx.Table | docx.Paragraph)[] = [
      ...viewContentSections,
      new docx.Paragraph(""),
      ...viewReleaselogSections,
    ];
    
    
    const metaData = this._docState.metaData;

    const doc = new docx.Document({
      title: metaData.getTitle(),
      description: metaData.getDescription(),
      // compatibility: {
      //   doNotBreakConstrainedForcedTable: true,
      //   doNotAutofitConstrainedTables: true,
      //   layoutRawTableWidth: true,
      //   alignTablesRowByRow: true,
      //   useWord2002TableStyleRules: true,
      // },
      // subject: "",
      sections: [{
        properties: {
          page: {
            size: {
              // width: 10000,
              // height: 10000,
              orientation: docx.PageOrientation.PORTRAIT,
            },
            margin: {
              // top: 10,
              // right: 10,
              // bottom: 10,
              // left: 10,
            },
          },
        },
        
        children: sections,
      }],
    });

    this._download(doc);
  }

  private _download(doc: docx.Document) {
    docx.Packer.toBlob(doc).then((blob) => {
      const meta = this._docState.metaData;
      const title = meta.getTitle();
      const filename = jtl.string.toFilename(title)
      const fullpath = `${filename}.docx`;

      jtl.file.download(fullpath, blob);
    });
  }
}


export default WinwordExporter;