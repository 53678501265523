import produce from 'immer';
import * as State from './state';
import * as Types from './types';
import * as Tools from './tools';
import * as Defaults from './defaults';


export class EditorImageWidgetsStyles {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  reset() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  populateSystemStyle() {
    const styles = Defaults.system_getStylesProps();
    styles.forEach(style => {
      this.addStyle(
        "system",
        style,
      );
    })
  }

  //----------------------
  // 
  // Setters
  addStyle(
    lib: Types.StyleLib,
    styleProps: Types.StylePropsUpdate
  ): Types.StyleAddr {
    const styleAddr = Tools.createStyleAddr();

    this._state = produce(this._state, draft => {
      const stylesAddrs = State.getStylesAddrs(draft, lib);
      const idx = stylesAddrs.length;
  
      this.__addStyleAtIdx(
        draft,
        lib,
        idx,
        styleAddr,
        styleProps
      );
    });

    return styleAddr;
  }

  private __addStyleAtIdx(
    draft: State.State, 
    lib: Types.StyleLib,
    idx: number,
    styleAddr: Types.StyleAddr,
    stylePropsUpdate?: Types.StylePropsUpdate,
  ) {
    const stylesAddrs = State.getStylesAddrs(draft, lib);
    const stylesProps = State.getStylesProps(draft, lib);
    
    const styleProps: Types.StyleProps = {
      ...Defaults.getStyleProps(),
      ...stylePropsUpdate
    };
  
    const styleKey = Tools.getStyleKey(styleAddr);
  
    stylesAddrs.splice(idx, 0, styleAddr);
    stylesProps[styleKey]  = styleProps;
  }

  deleteStyle(
    lib: Types.StyleLib,
    styleAddr: Types.StyleAddr,
  ) {
    this._state = produce(this._state, draft => {
      const stylesAddrs = State.getStylesAddrs(draft, lib);
      const stylesProps = State.getStylesProps(draft, lib);
      

      const styleKey = Tools.getStyleKey(styleAddr);
      const styleIdx = State.getStyleIdx(draft, lib, styleAddr);
    
      stylesAddrs.splice(styleIdx, 1);
      delete stylesProps[styleKey];
    });
  }


  //----------------------
  // 
  // Getters
  //

  getStyles(
    lib: Types.StyleLib
  ) {
    return State.getStyles(this._state, lib);
  }

  getStylesAddrs(
    lib: Types.StyleLib
  ) {
    return State.getStylesAddrs(this._state, lib);
  }

  getStylesProps(
    lib: Types.StyleLib
  ) {
    return State.getStylesProps(this._state, lib);
  }

  getStyleProps(
    lib: Types.StyleLib,
    styleAddr: Types.StyleAddr,
  ) {
    return State.getStyleProps(this._state, lib, styleAddr);
  }
}
