import styled from "styled-components";
import Button from "./button";

export const SelectButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  max-width: unset;
`;


export default SelectButton;