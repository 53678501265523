import React from 'react';
import WatchersComponent from './watchers';
import SlicedContentGeneratorComponent from './sliced-content-generator';


interface Props {
  onPagesDefined: (pages: React.ReactElement[]) => void;
}


export const SliceSystemComponent: React.FC<Props> = (props: Props) => {
  const {
    onPagesDefined
  } = props; 

  return (
    <>
      <SlicedContentGeneratorComponent 
        onPagesDefined={onPagesDefined} 
      />
      <WatchersComponent />
    </>
  );
}
  
