import { atom     } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { RepoMarkersState } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';


export const state = atom<RepoMarkersState.State>({
  key: "edi_doc_repoMarkers",
  default: RepoMarkersState.createInitialState()
});


/**
 * Selectors
 */
export const markersAddrs = selector({
  key: `edi_doc_repoMarkers_markersAddrs`,
  get: ({ get }) => {
    const stateObj = get(state);
    const markersAddrs = RepoMarkersState.getMarkersAddrs(stateObj);
    
    return markersAddrs;
  }
});

export const markersCount = selector({
  key: `edi_doc_repoMarkers_markersCount`,
  get: ({ get }) => {
    const stateObj = get(state);
    const markersAddrs = RepoMarkersState.getMarkersAddrs(stateObj);

    return markersAddrs.length;
  }
});

export const markerProps = selectorFamily({
  key: `edi_doc_repoMarkers_markerProps`,
  get: (markerAddr: RepoMarkersTypes.MarkerAddr) => ({ get }) => {
    const stateObj = get(state);
    return RepoMarkersState.getMarkerProps(stateObj, markerAddr);
  }
});

