import { useRecoilValue } from 'recoil';
import { UIState_ContentSignals } from 'app/ui/states/editor-instruction';
import { useDocState } from 'app/ui/contexts/document';


const useColumnsVisible = () => {
  const document = useDocState();
  
  useRecoilValue(UIState_ContentSignals.columnsVisible);

  const columnsAddrs = document.content.getColumnsAddrs();

  const columnsVisible = columnsAddrs.filter((columnAddr) => {
    const columnProps = document.content.getColumnProps(columnAddr);
    return columnProps.visible;
  })

  return columnsVisible;
}

export default useColumnsVisible;