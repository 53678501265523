import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import Icon from 'app/ui/icons/icons';

import { TickWrapper } from './styles';
import { Text }        from './styles';
import { Feature }     from './styles';


interface Props {
  feature: string;
}


export const FeatureComponent: React.FC<Props> = (props: Props) => {
  const {
    feature
  } = props;

  const t = useTranslations();
  const Tick = Icon.Tick.Small;

  return (
    <Feature>
      <TickWrapper>
        <Tick />
      </TickWrapper>

      <Text>
        { feature }
      </Text>
    </Feature>
  );
}

