import { PrintoutViewTypes } from "app/arch/editor-instruction/document/states/printout/printout-view";
import usePrintoutViewState from "./use-printout-view-state";
import usePrintoutViewPageCurrentUpdate from "./use-printout-view-page-current-update";


const usePrintoutViewPagePrev = () => {
  const printoutState = usePrintoutViewState();
  const updatePageCurrent = usePrintoutViewPageCurrentUpdate();


  const __checkIfFirstViewPage = (view: PrintoutViewTypes.ViewType) => {
    const pages = printoutState.getPages();
    const isFirstPage = (pages.current.pageIdx === 0)

    return isFirstPage;
  }

  const __prevContentPage = () => {
    const pages = printoutState.getPages();
    const isFirstPage = __checkIfFirstViewPage(PrintoutViewTypes.ViewType.CONTENT);
    
    if (isFirstPage)  {
      console.warn("I'm first page");
      return;
    }

    updatePageCurrent({
      pageIdx: pages.current.pageIdx - 1
    });
  }

  const __prevReleaselogPage = () => {
    const pages = printoutState.getPages();
    const isFirstPage = __checkIfFirstViewPage(PrintoutViewTypes.ViewType.CHANGELOG);

    if (isFirstPage)  {
      updatePageCurrent({
        view: PrintoutViewTypes.ViewType.CONTENT,
        pageIdx: pages.count[PrintoutViewTypes.ViewType.CONTENT] - 1
      });
      return;
    }

    updatePageCurrent({
      pageIdx: pages.current.pageIdx - 1
    });
  }


  const prevPage = () => {
    const pages = printoutState.getPages();

    const PrevFnMap: {[viewType in PrintoutViewTypes.ViewType]: () => void } = {
      [PrintoutViewTypes.ViewType.CONTENT  ]: __prevContentPage,
      [PrintoutViewTypes.ViewType.CHANGELOG]: __prevReleaselogPage,
    }

    const prevPageFn = PrevFnMap[pages.current.view];
    prevPageFn();
  }

  return prevPage;
}


export default usePrintoutViewPagePrev;