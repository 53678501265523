import React from 'react';
import { useRecoilValue } from 'recoil';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { EditorImageSettingsTools } from 'app/arch/editor-instruction/document/states/persistent/editor-image-settings';
import { useDocState } from 'app/ui/contexts/document';
import { UIState_Content } from 'app/ui/states/editor-instruction';

import { WidgetView } from './styles';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr;
  children: React.ReactNode;
}


export const WidgetBoxedViewComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
    children,
  } = props;

  const docState = useDocState();
  const widgetProps = docState.content.cellImages_image_getWidgetProps(widgetAddr);

  const widgetStyle = useRecoilValue(UIState_Content.cellImages_image_widgetStyle(widgetAddr)) || {};
  let style = EditorImageSettingsTools.getWidgetStyle(widgetProps.type, widgetStyle);

  const noBackgroundWidget = ContentTools.hasWidgetNoBackground(widgetProps.type);
  if (noBackgroundWidget) {
    const {
      backgroundColor,
      ...stylePart
    } = style;

    style = stylePart;
  }


  return (
    <WidgetView style={style} >
      { children }
    </WidgetView>  
  );
}
