import React from 'react';
import { IconContext } from 'react-icons';
import SelectButtonDefault from 'lego/styles/select-button';

import IconLib from 'app/ui/icons/icons';

import { TextWrapper } from './styles';
import { IconWrapper } from './styles';


interface Props {
  children?: any;
  onClick: (event?: any) => void;

  SelectButton?: React.ComponentType<any>;
  expanded?: boolean;
}


export const SelectButtonComponent: React.FC<Props> = (props: Props) => {
  const { 
    children, 
    onClick,
    SelectButton: SelectButton_,
    expanded
  } = props;

  const SelectButton = SelectButton_ || SelectButtonDefault;
  const Icon = expanded ? IconLib.Expand.Less : IconLib.Expand.More;

  return (
    <SelectButton onClick={onClick}>

      <TextWrapper>
      { children }
      </TextWrapper>

      <IconWrapper>
        <IconContext.Provider value={{ size: '15px' }} >
          <Icon />
        </IconContext.Provider>   
      </IconWrapper>

    </SelectButton>
  );
}
  
