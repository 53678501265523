import { ThemeConstsProps } from "../types";
import { getColorSet   } from "./__common";


export const themeConsts_accentColors = (props: ThemeConstsProps) => {
  const data = {
    blue:   { ...getColorSet(211, 100, 50) },
    violet: { ...getColorSet(299, 42,  41) },
    pink:   { ...getColorSet(332, 91,  65) },
    red:    { ...getColorSet(358, 73,  55) },
    orange: { ...getColorSet(28,  93,  54) },
    yellow: { ...getColorSet(45,  99,  57) },
    green:  { ...getColorSet(106, 46,  50) },
    grey:   { ...getColorSet(0,   0,   60) },
  }

  return data;
};
