import styled from "styled-components";


export const MainWrapper = styled.div`
  height: 100%;
`;

export const MarginsWrapper = styled.div`
  // It anchors in WidgetBoxedRelativeAnchor 
  // located in WidgetBoxedBase
  position: absolute;

  width: 100%;
  height: 100%;
`;

