import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './__use-items';
import useStates from './__use-states';


const useConfigSection = () => {
  const t = useTranslations();
  const items = useItems();
  
  const {
    visibleNone,
  } = useStates();


  const section = {
    subsections: [
      {
        title: t('page header, main info'),
        items: [
          items.getVisible(),
        ]
      },
      {
        skip: visibleNone,
        items: [
          items.getLogoVisibile(),
          items.getRevisionInfoVisible(),
        ]
      },
    ]
  }
  
  return section
}


export default useConfigSection;