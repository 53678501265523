import React from 'react';
import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { useDocState }        from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset       from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';


import { MarkerWrapper } from './styles';


interface Props {
  markerAddr: Types.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;

  const document = useDocState();
  const resetAllSelected = useSelectedReset();

  const {
    setContentSession,
  } = useEditorStatesSetters();

  const handleSelectMarker = (event: React.MouseEvent) => {
    event.stopPropagation();
    
    resetAllSelected();

    document.contentSession.cellMarkers_setMarkerSelected(markerAddr);
    document.contentSession.setCellSelected(markerAddr);
    setContentSession();
  }

  return (
    <MarkerWrapper onPointerDown={handleSelectMarker}>
      { children }
    </MarkerWrapper>
  );
}
  
