import Navbar from "./states/navbar";
import View from "./states/view";
import UserPanel from "./states/user-panel";


export class HomePageUser {
  private _navbar: Navbar;
  private _view: View;
  private _userPanel: UserPanel;

  constructor() {
    this._navbar = new Navbar();
    this._view = new View();
    this._userPanel = new UserPanel();
  }

  reset() {
    this._navbar.reset();
    this._view.reset();
    this._userPanel.reset();
  }
  get navbar()    { return this._navbar; }
  get view()      { return this._view; }
  get userPanel() { return this._userPanel; }
}
