import styled from "styled-components";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.normal};

  align-items: center;
`;

export const Price = styled.div`
  font-size:   ${props => props.theme.defs.font.size.xxxxlarge};
  font-weight: ${props => props.theme.defs.font.weight.medium};
`;

export const PriceInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PriceInfoMonthly = styled.div`
  font-size:   ${props => props.theme.defs.font.size.small};
  font-weight: ${props => props.theme.defs.font.weight.small};
`;

export const PriceBillingPeriodInfo = styled.div`
  font-size:   ${props => props.theme.defs.font.size.small};
  font-weight: ${props => props.theme.defs.font.weight.medium};
`;

export const PriceYearlyTotal = styled.div`
  font-size: ${props => props.theme.defs.font.size.large};
  font-weight: ${props => props.theme.defs.font.weight.small};
`;
