import styled from 'styled-components';
import PanelSectionBase from 'lego/styles/panel_section';
import ButtonIcon from 'lego/styles/button_icon';
import PanelBase from 'lego/styles/panel';
import { AnimPanelBase } from 'lego/components/animated-panel/styles';


export const MenuWrapper = styled.div`
  position: relative;
`;

export const AnimPanel = styled(AnimPanelBase)`
  position: absolute;
  top: 34px;
  right: -8px;
  z-index: 1;
`;


export const Panel = styled(PanelBase)`
  width: 220px;
  box-shadow: none;
`;


export const PanelSection = styled(PanelSectionBase)`
`;


export const Button = styled(ButtonIcon)`
`;