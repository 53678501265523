import { ThemeDefProps } from "../types";


export const themeDefs_imageEditor = (props: ThemeDefProps) => {
  const { colors, accentColor, border, padding, font } = props;

  const image_editor = {
    widget : {
      ctrl : {
        background: {
          primary:  accentColor.opacity08.primary,
          hover:    accentColor.primary,
          active:   accentColor.primary,
        },
        border: {
          width: border.width.normal,
          color: {
            primary: colors.border.primary,
            hover: accentColor.opacity08.primary,
          },
          radius: border.radius.xsmall,
        }
      }
    }
  };
    
  return image_editor;
}
