import { ViewTypes } from "app/arch/home-page-guest/states/view"
import useState from "./use-state"


const useViewParamsSet = () => {
  const homePageGuest = useState();
  
  const setViewParams = (params: ViewTypes.ViewParams) => {
    homePageGuest.view.update({params});
  }

  return setViewParams;
}


export default useViewParamsSet;