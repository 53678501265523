import i18next from 'i18next';
import { useEffect, useRef } from 'react';
import { ApolloError } from '@apollo/client';
import { useMsgBox } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { MsgDuration, MsgLevel } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box/types';


export interface Msgs {
  onStart?: {
    msg: string,
    duration?: number,
  }
  onEnd?: {
    msg?: string,
    duration?: number,
    callback?: (data?: any) => void,
  },
  onError?: {
    msg?: string,
    duration?: number,
  }
}


export interface Props {
  msgs?: Msgs,
  error: ApolloError | undefined,
  loading: boolean
}


export const useCommon = (props: Props) => {
  // If you use hook useTranslations
  // this can't be used from loaders then
  const t = i18next.t;

  const { msgs, error, loading} = props;
  const {showMsgBox} = useMsgBox();
  const prevLoading = useRef(false);


  useEffect(() => {
    if (error === null || error === undefined) return;

    if (
      error.graphQLErrors && 
      error.graphQLErrors.length &&
      error.graphQLErrors[0].extensions
    ) {
      const gerr = error.graphQLErrors[0];
      if (gerr.extensions?.code) {
        if (gerr.extensions.code === 'TOKEN_EXPIRED') {
          // Do not display token expired msg.
          // App when it starts, if there is
          // jwt token in localstorage, it will
          // try to refresh it. If it fails
          // TOKEN_EXPIRED will be populated up to 
          // here.
          // console.debug(`Token has expired, skip msg box`);
          return;
        }
      }
    }
     
    if (msgs?.onError !== undefined) {
      const duration = (
        msgs.onError.duration ||
        MsgDuration.XSHORT
      );

      if ( msgs.onError.msg ) {
        showMsgBox(
          msgs.onError.msg, 
          MsgLevel.ERROR,
          duration,
        );
      }
    }
    else {
      showMsgBox(
        t(error!.message),
        MsgLevel.ERROR,
      );
    }

  },[error]);


  useEffect(() => {
    if (prevLoading.current === loading) return;
    if (loading) {
      if (msgs?.onStart !== undefined) {
        let duration = msgs.onStart.duration;
        if (duration === undefined) duration = MsgDuration.NORMAL;
        showMsgBox(
          msgs.onStart.msg, 
          MsgLevel.INFO,
          duration,
        );
      }
    }
    else {
      if (msgs?.onEnd !== undefined && error === undefined) {
        let duration = msgs.onEnd.duration;
        if (duration === undefined) duration = MsgDuration.XSHORT;

        if ( msgs.onEnd.msg ) {
          showMsgBox(
            msgs.onEnd.msg, 
            MsgLevel.INFO,
            duration,
          );
        }
        
        msgs.onEnd.callback && msgs.onEnd.callback();
      }
    }

    prevLoading.current = loading;
  }, [loading]);

  return null;
}
