import React from 'react';
import * as Tools from 'app/arch/editor-instruction/document/states/persistent/content/tools';
import useColumnsVisible from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-columns-visible';
import { ContentItemProps } from '../types';
import HeaderCellComponent  from './header-cell';


interface Props extends ContentItemProps {
}


export const HeaderRowComponent: React.FC<Props> = (props: Props) => {

  // Track ColumnsVisible
  const columnsVisible = useColumnsVisible();

    
  const renderHeaderCells = () => {
    const HeaderCells = columnsVisible.map((columnAddr) => {
      const key = Tools.getColumnKey(columnAddr);

      return (
        <HeaderCellComponent
          key={key}
          columnAddr={columnAddr}
        />
      );
    });

    return HeaderCells;
  }


  return renderHeaderCells();
}
