import React from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useImageIdx } from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-images/use-image-idx';
import { UIState_Content } from 'app/ui/states/editor-instruction';

import { Positioner } from './styles';
import { Detacher }   from './styles';
import { Text }       from './styles';
import { IdxLabel }   from './styles';


interface Props {
  imageAddr: Types.ImageAddr;
}


export const ImageIdxViewComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
  } = props;

  const {
    imageIdx, 
    rowIdxSection,
    rowIdxGlobal
  } = useImageIdx (imageAddr);

  const imagesIdxProps = useRecoilValue(UIState_Content.columnImages_imagesIdxProps(imageAddr)) !;
  const indexingType = useRecoilValue(UIState_Content.sectionsIndexing);

  const rowIdx = (
    indexingType === Types.SectionsIndexing.GLOBAL ?
    rowIdxGlobal :
    rowIdxSection
  );

  const rowNo   = rowIdx + 1;
  const imageNo = imageIdx + 1;

  return (
    <>
    {
      imagesIdxProps.visible &&
      <Detacher>
        <Positioner $placement={imagesIdxProps.placement} >
          <IdxLabel>
            <Text>
              { rowNo }.{ imageNo }
            </Text>
          </IdxLabel>
        </Positioner>
      </Detacher>
    }
    </>
  );
}
