import React from 'react';

import useIsKeyDown from 'lego/components/key-bindings/hooks/use-is-key-down';
import useSystemKeys from 'lego/components/key-bindings/hooks/use-system-keys';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }    from 'app/ui/contexts/document';
import useSelectedReset   from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useCellSelectedSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell/use-cell-selected-set';
import useRowToggleSelect from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/row/use-row-toggle-select';

import { MainWrapper } from './styles';


interface Props {
  cellAddr: ContentTypes.CellAddr;
  children: React.ReactNode;
}


export const CellIndexSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props
  
  const document = useDocState();

  const systemKeys = useSystemKeys();
  const actionKeys = systemKeys.actionKeys;

  const resetAllSelected = useSelectedReset();
  const setCellSelected = useCellSelectedSet();
  const toggleSelectRow = useRowToggleSelect();

  const isKeyDown = useIsKeyDown();

  const handleSelectRow = (event: React.MouseEvent) => {
    event.stopPropagation();

    const isSelected = document.contentRowsSelected.isSelected(cellAddr);
    if ( isSelected ) {
      return;
    }

    const enabledMultiSelected = isKeyDown(actionKeys.multiItemSelect);
    
    // Select cell as well, in order
    // get get panel column menu active for
    // idx row.
    const skipStateUpdate = true;

    resetAllSelected();
    setCellSelected({ cellAddr });
    toggleSelectRow({ 
      rowAddr: cellAddr,
      resetSelected: ! enabledMultiSelected
    });
  }


  return (
    <MainWrapper onPointerDown={handleSelectRow}>
      { children }
    </MainWrapper>
  );
}
