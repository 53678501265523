import React from 'react';
import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import urls from 'app/configs/urls';

import { ViewTypes }   from 'app/arch/home-page-user/states/view';
import { NavbarTypes } from 'app/arch/home-page-user/states/navbar';
import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';
import useViewSelect        from 'app/ui-v2/home-page/home-page-user/hooks/use-view-select';
import useNavbarItemSelect  from 'app/ui-v2/home-page/home-page-user/hooks/use-navbar-item-select';

import { MainWrapper } from './styles';
import { Box } from './styles';


interface Props {
}


export const RedirectPaymentDoneComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectView    = useViewSelect();
  const selectNavbarItem = useNavbarItemSelect();

  useEffect(() => {
    const paymentId = searchParams.get('payment_intent');

    selectNavbarItem(NavbarTypes.NavbarItem.PRICING);
    selectView(ViewTypes.ViewItem.PAYMENT_DONE);

    navigate(urls.home);
  }, []);


  return (
    <MainWrapper>
      <ProgressBoxComponent 
        Box={Box}
        title={t('payment processing')} 
      />
    </MainWrapper>
  );
}
