import styled from "styled-components";
import PanelBase from "lego/styles/panel";


export const Panel = styled(PanelBase)`
  display: flex;
  flex-direction: column;

  width: 100%;
  /* background: red; */

  box-sizing: border-box;
`;


export default Panel;