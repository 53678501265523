import React from 'react';

import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useDocState }  from 'app/ui/contexts/document';
import useSelectedReset from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import usePageColumnSelectedSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/column/use-page-column-selected-set';

import { MainWrapper } from './styles';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    pageColumnAddr,
    children,
  } = props;

  const document = useDocState();
  const resetAllSelected = useSelectedReset();
  const setPageColumnSelected = usePageColumnSelectedSet();
  
  const handleSelectColumn = (event: React.MouseEvent) => {
    event.stopPropagation();

    const isSelected = document.customFieldsColumnsSelected.isSelected(pageColumnAddr);
    if ( isSelected ) {
      return;
    }

    resetAllSelected();
    setPageColumnSelected({ pageColumnAddr });
  }

  return (
    <MainWrapper onPointerDown={handleSelectColumn}>
      { children }
    </MainWrapper>
  );
}
