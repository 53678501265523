import * as Types from './types';
import { LexicalTools } from 'app/arch/tools';


/**
 * Columns
 */
export const getColumns = (
): Types.Columns => ({
  addrs: [],
  props: {},
});

export const getColumnProps = (
  columnType: Types.ColumnType
): Types.ColumnPropsTypes => {

  type ColumnCreateFn = () => Types.ColumnPropsTypes

  const ColumnCreateFnMap: {[key in Types.ColumnType]: ColumnCreateFn} = {
    [Types.ColumnType.TEXT] : __getColumnText,
  }

  const createColumnPropsFn = ColumnCreateFnMap[columnType];
  const columnProps = createColumnPropsFn();
  return columnProps;
};

const __getColumnText = (): Types.ColumnTextProps => ({
  ...__getColumnBaseProps(Types.ColumnType.TEXT),
  css: {
    padding: '5px',
  },
});

const __getColumnBaseProps = (
  columnType: Types.ColumnType
): Types.ColumnProps => ({
  type: columnType,
  name: "",
  width: 200,
  css: {
  },
  header: {
    css: {
    }
  }
});


/**
 * Cells
 */
export const getCells = (): Types.Cells => ({
});


/**
 * Cell
 */
export const getCell = (cellType: Types.ColumnType): Types.CellTypes => {
  type CellCreateFn = () => Types.CellTypes

  const CellCreateFn: {[key in Types.ColumnType]: CellCreateFn} = {
    [Types.ColumnType.TEXT ] : __getCellText,
  }

  const cellCreateFn = CellCreateFn[cellType];
  const cell = cellCreateFn();
  return cell;
};

const __getCellText = (): Types.TextCell => ({
  editorState: LexicalTools.getEditorInitStateSerial(),
});


/**
 * Fields
 */
export const getFields = (
): Types.Fields => ({
  columns: getColumns(),
  cells:   getCells(),
});

export const getTable = (
): Types.Table => ({
  header: {
    css: {
      justifyContent: 'flex-start',
      padding: '5px',
      fontSize: '16px',
      fontWeight: '600',
      fontVariant: 'small-caps'
    }
  }
});