
const COUNTER_RESET_DELAY  = 2000;


export class CrashWatcher {
  private _crashCount: number;
  private _crashCountTotal: number;
  private _resetHandler: any;
  
  constructor() {
    this._crashCount = 0;
    this._crashCountTotal = 0;  
    this._resetHandler = null;
  }

  get crashCount() { return this._crashCount; }
  get crashCountTotal() { return this._crashCountTotal; }

  resetCounter() {
    this._crashCount = 0;
  }

  recordCrash() {
    this._crashCount++;
    this._crashCountTotal++;
    this.setResetTimeout();
  }

  private setResetTimeout() {
    if (this._resetHandler) {
      clearTimeout(this._resetHandler);
    }

    this._resetHandler = setTimeout(() => {
      console.log(`Reset crash watcher count, current count: ${this._crashCount}`);
      this._crashCount = 0;
    }, COUNTER_RESET_DELAY);

  }
};
