import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_HeaderMeta }      from 'app/ui/states/editor-instruction';
import HLS_DocHeaderInfoComponent from 'app/ui-v2/editor-instruction/views/shared/components/doc-header-info';


interface Props {
}


export const DocInfoViewComponent: React.FC<Props> = (props: Props) => {
  const logoVisible         = useRecoilValue(UIState_HeaderMeta.logoVisible);
  const revisionInfoVisible = useRecoilValue(UIState_HeaderMeta.releaseInfoVisible);

  return (
    <HLS_DocHeaderInfoComponent
      logoVisible={logoVisible}
      revisionInfoVisible={revisionInfoVisible}
    />
  );
}
  
