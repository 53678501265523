import styled from "styled-components";
import ItemSelecteable from "./item-selecteable";


export const Marker = styled(ItemSelecteable)`
  padding: ${props => props.theme.defs.padding.small};

  background-color: transparent;
  border-color: transparent;

  &:active:not(:disabled) {
    background: white;
    border-color: ${props => props.theme.defs.accentColor.primary};
  };

  &:hover {
    background: white;
  }
`;

export default Marker;