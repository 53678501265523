import React from 'react';
import { MainWrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const CellOverflowClipperComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;
  
  return (
    <MainWrapper>
      { children }
    </MainWrapper>
  );
}
