import { useRecoilValue } from "recoil";
import jtl from 'tools/jtl';
import { Language } from 'app/arch/types';
import { UIUser_Settings } from "app/ui-v2/app/__modules/user/states";


const useStates = () => {
  const user = useRecoilValue(UIUser_Settings.user);
  const company = useRecoilValue(UIUser_Settings.company);
  
  const langsSorted = Object.keys(Language).sort();
  const languages =  langsSorted.map((lang: string) => jtl.string.capitalizeFirstLetter(lang));

  return {
    user,
    company,
    languages
  }
}

export default useStates;
