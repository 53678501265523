import styled from "styled-components";
import SignCircle from "../styles/sign-circle";
import { Config } from "../config";


export const Sign = styled(SignCircle)`
  color: #000;
  background-color: #FFF;
  outline-color: #8B0000;

  border-style: solid;
  border-color: #FF0000;
  border-width: ${Config.style.border.width}px;
`;
