import { useRecoilValue } from "recoil";
import { UIState_ReleaselogsSession } from "app/ui/states/editor-instruction";


const useCellSelected = () => {
  const cell = useRecoilValue(UIState_ReleaselogsSession.cellSelected);
  return cell;
}


export default useCellSelected;