import React from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';


interface Props {
  themeData: any;
  children: React.ReactNode;
}


export const ThemeMuiComponent: React.FC<Props> = (props: Props) => {
  const {
    themeData,
    children
  } = props;


  const theme = createTheme({
    typography: {
      fontFamily: themeData.defs.font.family.join(', '),
    },
  
    components: {
      // MuiTextField: {
      // MuiInputBase: {

      MuiButtonBase: {
        defaultProps: {
          // disableRipple: true,
        },
      },

      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: themeData.defs.colors.color.secondary, 
            // backgroundColor: themeData.defs.accentColor.opacity02.primary,
          },
          root: {
            backgroundColor: themeData.defs.colors.background.primary, 
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            color: themeData.defs.colors.color.primary, 
            backgroundColor: themeData.defs.colors.background.primary, 
            fontSize: themeData.defs.font.size.normal,
            '&.Mui-selected': {
              color: themeData.defs.colors.color.secondary, 
              backgroundColor:  themeData.defs.accentColor.primary,
            },
          },
        },
      },
      
    },
  });


  return (
    <MuiThemeProvider
      theme={theme}
    >
      { children }
    </MuiThemeProvider>
  );
}
  
