import React from 'react';
import { useRecoilValue } from 'recoil';

import usePageContext from 'app/ui-v2/editor-instruction/views/view-content/hooks/page/use-page-context';
import { HeaderFieldsTools as Tools } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { UIState_HeaderFields } from 'app/ui/states/editor-instruction';
import HeaderCellComponent from './header-cell';


interface Props {
}


export const HeaderRowComponent: React.FC<Props> = (props: Props) => {
  const columnsAddrs = useRecoilValue(UIState_HeaderFields.columnsAddrs);
  const pageContext = usePageContext();
  const pageIdx = pageContext.pageIdx;
  
  if (pageIdx === null) {
    const msg = `PageIdx is null`;
    throw new Error(msg);
  }

  const renderHeaderCells = () => {
    const HeaderCells = columnsAddrs.map((columnAddr) => {
      const key = Tools.getColumnKey(columnAddr);

      const pageColumnAddr = {
        pageIdx,
        ...columnAddr,
      }

      return (
        <HeaderCellComponent
          key={key}
          pageColumnAddr={pageColumnAddr}
        />
      );
    });

    return HeaderCells;
  }


  return renderHeaderCells();
}
  
