import React from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';

import { MainWrapper } from './styles';
import { Border }      from './styles';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellSelectedBorderComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props;

  const cellSelected = useRecoilValue(UIState_ContentSession.isCellSelected(cellAddr));

  return (
    <MainWrapper>
      <Border selected={cellSelected} />
      { children }
    </MainWrapper>
  );
}
  
