import React from 'react';
import IconLib from 'app/ui/icons/icons';
import YoutubeEmbeddedComponent from 'app/ui-v2/hls/youtube-embedded/index';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import InfoPanelComponent from '../parts/info-panel';


interface Props {
}


export const IntroMovieComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const t = useTranslations();
  const Icon = IconLib.Movie;

  return (
    <InfoPanelComponent 
      title={ t("demo view, panel intro, header") }
      Icon={Icon}
    >
      <YoutubeEmbeddedComponent
        videoId="4qwdGcXXDkk" 
      />
    </InfoPanelComponent>
  );
}

