import { atom } from 'recoil';


/**
 * These signals are not to store values.
 * They are here in order signal that given
 * props have changed. Once component recieve
 * signal that property has change, it can 
 * retrive new values/props from doc-state.
 */


/**
 * Signal columns widths change
 */

export const columnsWidths = atom({
  key: "ei_docContent_signals_columnsWidths",
  default: {}
});


/**
 * Signal columns visibility change
 */

export const columnsVisible = atom({
  key: "ei_docContent_signals_columnsVisible",
  default: {}
});
