import { Language } from 'app/arch/types';
import { ThemeType }        from 'app/arch/app/themes/types';
import { ThemeVariantType } from 'app/arch/app/themes/types';
import { ThemeAccentColor } from 'app/arch/app/themes/types';

import * as Types from "./types"


export const getUser = (): Types.User => ({
  firstname:  null,
  lastname: null,
  email: null,
  language: Language.ENGLISH,
});


export const getCompany = (): Types.Company => ({
  name: null,
  address1: null,
  address2: null,
  postcode: null,
  city: null,
  state: null,
  country:  null,
});


export const getTheme = (): Types.Theme => ({
  type: ThemeType.MACOSX,
  variant: ThemeVariantType.DARK,
  accentColor: ThemeAccentColor.BLUE
});


export const getRepo = (): Types.Repo => ({
  id: null,
});
