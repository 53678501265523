import React from 'react';
import { useRecoilValue } from 'recoil';
import { UIState_HeaderRows } from 'app/ui/states/editor-instruction';
import ContentGrid from './styles';


interface Props {
  children: React.ReactNode;
}


export const GridComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;


  const column0Width = useRecoilValue(UIState_HeaderRows.columnWidth(0));
  const column1Width = useRecoilValue(UIState_HeaderRows.columnWidth(1));
  const rowsAddrs = useRecoilValue(UIState_HeaderRows.rowsAddrs);
  
  const template = `${column0Width}px 1fr`;

  return (
    <ContentGrid
      rows={rowsAddrs.length}
      columnsTemplate={template}
    >
      { children }
    </ContentGrid>
  );
}

