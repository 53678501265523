import styled from "styled-components";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};
`;

export const ColorsColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xsmall};
`;

export const ColorPickerContainer = styled.div`
  border-style:  ${props => props.theme.panel.border.style};
  border-width:  ${props => props.theme.panel.border.width};
  border-radius: ${props => props.theme.defs.border.radius.medium};
  border-color:  ${props => props.theme.panel.border.color};
`;
