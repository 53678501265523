import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useParams } from 'react-router';

import EditorInstruction from 'app/arch/editor-instruction';
import { EditorSessionTypes } from 'app/arch/editor-instruction/document/states/sessions/editor-session';
import EditorInstructionComponent from 'app/ui/components/editor-instruction';
import ErrorFallbackComponent     from 'app/ui/components/editor-instruction/error-fallback';
import { UIState_EditorSession }  from 'app/ui/states/editor-instruction';


interface Props {
}


export const ViewEditorComponent: React.FC<Props> = (props: Props) => {
  const params = useParams();
  const paramsInstructionId = (
    params.instructionId !== undefined ?
    +params.instructionId :
    null
  );

  const [instructionId, setInstructionId] = useState<number | null>(paramsInstructionId);

  useEffect(()=> {
    if (paramsInstructionId === null) {
      return;
    }

    if (paramsInstructionId === instructionId) {
      return;
    }

    setInstructionId(paramsInstructionId);
  }, [paramsInstructionId]);


  if (instructionId === null) {
    console.warn("There is no instruction id set yet.");
    return null;
  }

  if (paramsInstructionId !== instructionId) {
    // If for example instruction was already edited
    // therefore instructionId is set, and new
    // instruction create was called and its new
    // instruction id arived via params, then
    // do not render editor yet, as new instruction id
    // will be set to component state from useEffect
    // therefore no need to render component now,
    // as it will be rerender in the seconds,
    // once state gets updated with new instruction id.
    return null;
  }

  return (
    <ViewEditorComponent__ 
      instructionId={instructionId}
      viewType={params.viewType as EditorSessionTypes.ViewType}
    />
  );
}



interface Props__ {
  instructionId: number,
  viewType?: EditorSessionTypes.ViewType;
}

export const ViewEditorComponent__: React.FC<Props__> = (props: Props__) => {
  const {
    instructionId,
    viewType
  } = props;

  // Do not use hook, as document is not yet setup
  const setEditorSession = useSetRecoilState(UIState_EditorSession.state);

  useEffect(() => {
    switchToView();
  }, []);

  // const editorRef = useRef(new EditorInstruction(instructionId));
  // const editor = editorRef.current;
  const editor = new EditorInstruction(instructionId);
  const docState = editor.document.docState;

  const switchToView = () => {
    if (viewType === undefined) {
      // View type not specified, will use default.
      return;
    }

    const currentView = docState.editorSession.getViewType()
    if ( currentView === viewType) {
      return;
    }

    const validViewRequest = Object.values(EditorSessionTypes.ViewType).includes(viewType);
    if (validViewRequest) {
      console.debug(`Loading view type: ${viewType}`);
      docState.editorSession.setViewType(viewType);
      setEditorSession(docState.editorSession.state);
      return;
    }

    // Requested view does not exists
    // console.warn(`Unknown view type: ${viewType}`);
  }

  // Key is here just to force rerender
  // when new instruction is created from
  // within editor.
  return (
    <React.Fragment key={instructionId}>
      <ErrorFallbackComponent editorInstruction={editor} >
        <EditorInstructionComponent editorInstruction={editor} />
      </ErrorFallbackComponent>
    </React.Fragment>
  );
}
