import { ShapeCalcArrow } from "./shape-calc-arrow";


export class ShapeCalcTriangle extends ShapeCalcArrow {
  override get arrowHeadAngle() {
    return Math.atan2(
      this.arrowHeadWidth / 2, 
      this.heightTotal
    );
  }

  override get arrowHeadHeightTotal() {
    return this.heightTotal;
  }

  override get bodyWidthTotal() {
    return 0;
  }

  override get arrowShaftWidth() {
    return 0;
  }

  override get widthTotal() {
    const width =
      2 * this.outlineWidthAngledHorizontal + 
      2 * this.borderWidthAngledHorizontal + 
      this.arrowHeadWidth
    ;

    return width;
  }
};