import React from 'react';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState } from 'app/ui/contexts/document';

import CellIndexComponent   from './cells/cell-index';
import CellTextComponent    from './cells/cell-text';
import CellImagesComponent  from './cells/cell-images';
import CellMarkersComponent from './cells/cell-markers';


interface Props {
  cellAddr: Types.CellAddr;
}


export const CellRenderComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr
  } = props;

  const document = useDocState();
  const columnType = document.content.getColumnType(cellAddr);

  const CellsComponents: {[key in Types.ColumnType]: React.ElementType} = {
    [Types.ColumnType.INDEX  ]: CellIndexComponent,
    [Types.ColumnType.TEXT   ]: CellTextComponent,
    [Types.ColumnType.IMAGES ]: CellImagesComponent,
    [Types.ColumnType.MARKERS]: CellMarkersComponent,
  };

  const CellComponent = CellsComponents[columnType];

  return (
    <CellComponent
      cellAddr={cellAddr}
    />
  );
}
