import React from 'react';

import SidePanelComponent from './side-panel';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';

import { MainWrapper } from './styles';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
}


export const SideMenuComponent: React.FC<Props> = (props: Props) => {
  const { markerAddr } = props;

  return (
    <MainWrapper>
      <SidePanelComponent markerAddr={markerAddr} />
    </MainWrapper>
  );
}
  