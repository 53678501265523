import React from 'react';

import { PanelControlsItemSectionControlConfig as Config } from '../../../config';
import PanelSectionItemComponentBase from '../../base/panel-section-item';
import ItemComponent from './item';


interface Props {
  config: Config;
  sectionDisabled: boolean;
}


export const PanelSectionItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    config,
    sectionDisabled,
  } = props;


  return (
    <PanelSectionItemComponentBase
      config={config as any}
      sectionDisabled={sectionDisabled}
      Item={ItemComponent}
    />
  );
}
