import React from 'react';
import { useEffect } from 'react';
import { RefObject } from 'react';
import { CellProps } from '../../types';


interface Props extends CellProps {
  editorTextRef: RefObject<HTMLDivElement | null>;
  children: React.ReactNode;
}


export const CellCSSCustomComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr,
    editorTextRef,
    children,
  } = props;

  // const css = useRecoilValue(UIState_DocContent2DocCustomFields.tableHeaderCSS);
  // const padding = css.padding;

  useEffect(() => {
    if (editorTextRef.current === null) {
      return;
    }

    editorTextRef.current.style.padding = "4px";
    // editorTextRef.current.style.padding = padding as string;
  }, [editorTextRef.current]);


  return children;
}
  
