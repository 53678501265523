import React from 'react';
import SelectComponent      from 'lego/components/select';
import { PanelControlsItemSectionControl_DropdownListConfig as Config} from '../../config';


interface Props {
  config: Config;
}


export const ControlDropdownListComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;

  const getSelectConfig = () => {
    const options = config.values.map((value) => {
      return {
        value,
        valueText: value,
        selected: config.value?.toLocaleLowerCase() === value.toLocaleLowerCase()
      }
    });

    const onSelected = (value: string) => {
      config.onValueSelected?.(value);
    }

    return   {
      options,
      onSelected
    };
  };


  return (
    <SelectComponent
      config={getSelectConfig()}
    />
  );
}
  
