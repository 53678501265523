import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { useDocState } from 'app/ui/contexts/document';
import { UIState_HeaderRows } from 'app/ui/states/editor-instruction';
import { Column }             from 'app/ui-v2/editor-instruction/views/shared/components/columns-resizers/types';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import HLS_ColumnsResizersComponent from 'app/ui-v2/editor-instruction/views/shared/components/columns-resizers';
import { useHeaderRowsColumnsAutoAdjust } from '../hooks';


interface Props {
  debug?: boolean;
}


export const RowsResizersComponent: React.FC<Props> = (props: Props) => {
  const { 
    debug
  } = props;

  const document = useDocState();
  const columnsAutoAdjust = useHeaderRowsColumnsAutoAdjust();
  
  const { 
    setHeaderRows,
  } = useEditorStatesSetters();
  
  // This rerender is needed for remaining pages.
  useRecoilValue(UIState_HeaderRows.columnWidth(0));
  useRecoilValue(UIState_HeaderRows.columnWidth(1));
  
  const columnsProps = document.headerRows.getColumnsProps();

  const columnsResizers: Column[] = columnsProps.map((columnProps, idx) => {
    const columnResizer: Column = {
      id: `${idx}`,
      width: columnProps.width,
    }

    return columnResizer;
  });
  

  const handleSetColumnsWidth = (columns: Column[]) => {
    columns.forEach((column) => {
      const columnIdx = +column.id as HeaderRowsTypes.ColumnIdx;
      const width = column.width;
      document.headerRows.updateColumn(columnIdx, { width });
    });

    columnsAutoAdjust();
    setHeaderRows();
  }

  const handleResizeEnd = () => {
    document.saveUndo();
  }

  
  return (
    <HLS_ColumnsResizersComponent
      columns={columnsResizers}

      onSetColumnsWidth={handleSetColumnsWidth}
      onResizeEnd={handleResizeEnd}

      debug={debug}
    />
  );
}
 