import styled from "styled-components";


export const Dragged = styled.div`
  position: fixed;
  top:  -99999px;
  left: -99999px;

  z-index: 1;
`;


export default Dragged;