import React from 'react';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/releaselogs/types';

import SpacerComponent from './spacer';
import { PageItemsProps } from '../types';


interface Props extends PageItemsProps {
}


export const PageItemSpacerComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
  } = props;

  const spacer = item as SlicerTypes.PageItem_Spacer;

  return (
    <SpacerComponent />
  );
}
  
