import { useApp } from 'app/ui/hooks/app/use-app';


const useControls = () => {
  const app = useApp();
  const controls = app.controls;

  return controls;
}


export default useControls;