import React from 'react';
import Context from './context';


interface Props {
  scale: number;
  children: React.ReactNode;
}


export const DocumentContextScaleComponent: React.FC<Props> = (props: Props) => {
  const {
    scale,
    children,
  } = props;


  return (
    <Context.Provider value={scale}>
      { children }
    </Context.Provider>
  );
}