import React from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { Spacer } from './styles';


interface Props {
}


export const CrashMessageComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  
  return (
    <>
      <DialogContentText>
        {t("crash - app restored")}
      </DialogContentText>

      <Spacer />
      <DialogContentText>
        {t("crash - we are sorry")}
      </DialogContentText>   
    </>
  );
}
  
