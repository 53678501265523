import React from 'react';
import ProgressComponent from 'app/ui-v2/hls/progress';
import { LogoWrapper } from './styles';


interface Props {
}


export const LogoUploadingComponent: React.FC<Props> = (props: Props) => {

  return (
    <LogoWrapper>
      <ProgressComponent />
    </LogoWrapper>
  );
}
