import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { UIState_HeaderFields } from 'app/ui/states/editor-instruction';

import { Text } from './styles';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
}


export const HeaderCellViewComponent: React.FC<Props> = (props: Props) => {
  const {
    pageColumnAddr,
  } = props;

  const columnName = useRecoilValue(UIState_HeaderFields.columnName(pageColumnAddr));

  const columnNameDisplayed = (
    columnName.length ?
    columnName :
    <>&nbsp;</>
  );

  return (
    <Text>
    { columnNameDisplayed }
    </Text>
  );
}
  
