import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { UIState_CustomRowsRowsSelected } from 'app/ui/states/editor-instruction';

import { MainWrapper } from './styles';
import { Background }  from './styles';


interface Props {
  pageCellAddr: HeaderRowsTypes.PageCellAddr;
  children: React.ReactNode;
}

export const CellsLineDragOverComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr,
    children,
  } = props;

  const rowDragOver = useRecoilValue(UIState_CustomRowsRowsSelected.isDragOver(pageCellAddr));
  const selected = rowDragOver;
 
  return (
    <MainWrapper>
      <Background selected={selected} />
      { children }
    </MainWrapper>
  );
}
  
