import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { HeaderFieldsTools } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { HeaderFieldsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { CustomFieldsColumnsSelectedState as State } from 'app/arch/editor-instruction/document/states/sessions/custom-fields-columns-selected';


/**
 * Atom
 */

export const state = atom<State.State>({
  key: "ei_docContent_customFieldsSelected",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const isSelected = selectorFamily({
  key: "ei_docContent_customFieldsSelected__isSelected",
  get: (columnAddr: HeaderFieldsTypes.PageColumnAddr) => ({ get }) => {
    const stateObj = get(state);
    const columnKey = HeaderFieldsTools.getPageColumnKey(columnAddr);
    const selected = State.isSelected(stateObj, columnKey);
    return selected;
  }
});

export const isDragOver = selectorFamily({
  key: "ei_docContent_customFieldsSelected__isDragOver",
  get: (columnAddr: HeaderFieldsTypes.PageColumnAddr) => ({ get }) => {
    const stateObj = get(state);
    const columnKey = HeaderFieldsTools.getPageColumnKey(columnAddr);
    const selected = State.isDragOver(stateObj, columnKey);
    return selected;
  }
});

export const areSelected = selector({
  key: "ei_docContent_customFieldsSelected__areSelected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selected = State.getSelectedOrdered(stateObj);

    return (selected.length > 0);
  }
});
  