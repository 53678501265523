import React      from 'react';
import { useRef } from 'react';
import { useRecoilValue } from 'recoil';

import Dragged from 'lego/styles/dragged';

import { useDocState }        from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_EditorSession } from 'app/ui/states/editor-instruction';

import { MainWrapper }    from './styles';
import { Text }           from './styles';
import { TextWrapper }    from './styles';
import { ContentWrapper } from './styles';


interface Props {
  dndContext: string;
  text?: string;
  disabled: boolean;
  children: any;
  onDragStart: (event: React.DragEvent) => void;
  onDragOver:  (event: React.DragEvent) => void;
  onDragEnter: (event: React.DragEvent) => void;
  onDragEnd:   (event: React.DragEvent) => void;
  onDrop:      (event: React.DragEvent) => void;
}

export const ItemDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    children, 
    dndContext,
    text,
    disabled, 
    onDragEnter, 
    onDragEnd, 
    onDragStart,
    onDragOver,
    onDrop
  } = props;

  const document = useDocState();
  const {
    setEditorSession,
  } = useEditorStatesSetters();

  const isDraggingEnabled = useRecoilValue(UIState_EditorSession.isDNDContextEnabled(dndContext));

  const contentRef = useRef<HTMLDivElement>(null);

  const enableDND = () => {
    document.editorSession.setDNDContext(dndContext);
    setEditorSession();
  }

  const disableDND = () => {
    document.editorSession.setDNDContext(null);
    setEditorSession();
  }

  const handlePointerDown = (event: React.PointerEvent) => {
    enableDND();
  }

  const handlePointerUp = () => {
    disableDND();
  }

  const handleDragStart = (event: React.DragEvent) => {
    if (! isDraggingEnabled) return;
    onDragStart(event);

    if (contentRef.current) {
      const x = 5;
      const y = 25;
      event.dataTransfer.setDragImage(contentRef.current, x, y);
    }
  }

  const handleDragEnd = (event: React.DragEvent) => {
    if (! isDraggingEnabled) return;
    disableDND();
    onDragEnd(event);
  }

  const handleDragOver = (event: React.DragEvent) => {
    if (! isDraggingEnabled) return;
    event.preventDefault();
    onDragOver(event);
  }

  const handleDrop = (event: React.DragEvent) => {
    if (! isDraggingEnabled) return;

    disableDND();
    onDrop(event);
  }

  const handleDragEnter = (event: React.DragEvent) => {
    onDragEnter(event);
  }

  return (
    <>
      { disabled && children }
      { ! disabled && 
        <MainWrapper
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          
          draggable={isDraggingEnabled}

          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
        >
          {
            isDraggingEnabled && 
            <Dragged ref={contentRef}>
              <ContentWrapper>
                <TextWrapper>
                  <Text> 
                  { text }
                  </Text>
                </TextWrapper>
              </ContentWrapper>
            </Dragged>
          }
          { children }
        </MainWrapper>
      }
    </>
  );
}
  
