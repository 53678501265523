import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset from '../use-selected-reset';


const useRowDelete = () => {
  const document = useDocState();

  const {
    setReleaselogs,
  } = useEditorStatesSetters();

  const resetSelected = useSelectedReset();

  const deleteRow = (rowAddr: Types.RowAddr) => {
    document.releaselogs.deleteRow(rowAddr);
    resetSelected();
    setReleaselogs();

    document.saveUndo();
  }

  return deleteRow;
}


export default useRowDelete;