import { MenuConfig }   from 'lego-v2/menu/config';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import useItems from './__use-items';
import useFunctions from './__use-functions';


const useCustomRowMenu = () => {
  const items = useItems();
  const fns = useFunctions();

  const getConfig = (rowAddr: HeaderRowsTypes.PageRowAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            items.rowAddAbove(rowAddr),
            items.rowAddBelow(rowAddr),
          ],
        },        
        {
          skip: fns.skipDelete(), 
          items: [
            items.rowDelete(rowAddr),
          ],
        },
      ]
    }

    return config;
  }


  const hook = (rowAddr: HeaderRowsTypes.PageRowAddr) => {
    const config = getConfig(rowAddr);
    return config;
  }

  return hook;
}


export default useCustomRowMenu;