import styled from "styled-components";


const PanelDetacher = styled.div`
  position: absolute;
  top: 100%;

  /* TODO */
  z-index: 2;
`;


export default PanelDetacher;
