import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes as ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";

import { useDocState }         from 'app/ui/contexts/document';
import ContentSaveBasePlugin   from 'app/ui/components/editor-txt/plugins/content-save-plugin-base';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters  from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr,
}


export const ContentSavePlugin: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr, 
  } = props;

  const document = useDocState();

  const { 
    setContent 
  } = useEditorStatesSetters();

  const editorState = useRecoilValue(
    UIState_Content.cellImages_image_widgetEditorText_editorTextState(widgetAddr)
  );

  const handleEditorStateInit = (editorStateUpdate: string) => {
    console.error("[Widget] Editor text state init called");
  }

  const handleEditorStateSave = (editorTextState: string) => {
    document.content.cellImages_image_widgetEditorText_setEditorState(widgetAddr, editorTextState);
    document.saveUndo();
    setContent();
  }

  return (
    <ContentSaveBasePlugin
      editorTextState={editorState}
      onInitEditorTextState={handleEditorStateInit}
      onSaveEditorTextState={handleEditorStateSave}
    />
  );
}
