import styled from "styled-components";


export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  /* padding: 0px 8px 0px 4px; */
  /* min-width: unset; */
  /* background: ${props => props.theme.button.background.primary}; */
`;
