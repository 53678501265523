import { atom }           from 'recoil';
import { selector }       from 'recoil';
import { selectorFamily } from 'recoil';
import { EditorSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/editor-session';



export const state = atom<State.State>({
  key: "editorSession",
  default: State.createInitialState()
});


//--------------------
//
// Selectors
//

export const toolbarItemsVisible = selector({
  key: `editorSession_toolbarItemsVisible`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getToolbarItemsVisible(stateObj);
  }
});

export const toolbarItemsSelected = selector({
  key: `editorSession_toolbarItemsSelected`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getToolbarItemsSelected(stateObj);
  }
});

export const toolbarPropertiesVisible = selector({
  key: `editorSession_toolbarPropertiesVisible`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getToolbarPropertiesVisible(stateObj);
  }
});

export const toolbarPropertiesSelected = selector({
  key: `editorSession_toolbarPropertiesSelected`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getToolbarPropertiesSelected(stateObj);
  }
});

export const DNDContext = selector({
  key: `editorSession_DNDContext`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getDNDContext(stateObj);
  }
});

export const isDNDContextEnabled = selectorFamily({
  key: `editorSession_isDNDContextEnable`,
  get: (context: string) => ({ get }) => {
    const stateObj = get(state);
    const currentContext = State.getDNDContext(stateObj);
    return context === currentContext;
  }
});

export const viewType = selector({
  key: `editorSession_viewTypw`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getViewType(stateObj);
  }
});

