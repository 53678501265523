import styled from "styled-components";
import Button from "./button";


export const ButtonGroupBase = styled(Button)`
  flex-grow: 1;

  display: flex;
  align-items: center;  
  justify-content: center;

  padding: ${props => props.theme.defs.padding.normal};
`;


export default ButtonGroupBase;