import React from 'react';

import FlexEditLogoRawComponent from 'app/ui-v2/hls/branding/flex-edit-logo-raw';
import FlexEditNameComponent from 'app/ui-v2/hls/branding/flex-edit-name';

import { FlexEditWrapper } from './styles';


interface Props {
}


export const AppNameComponent: React.FC<Props> = (props: Props) => {
  const {
  } = props;


  return (
    <FlexEditWrapper>
      <FlexEditLogoRawComponent logoSize={45} />
      <FlexEditNameComponent textSize={34} />
    </FlexEditWrapper>
  );
}

