import styled from "styled-components";
import TextWrap from "./text-wrap";


export const TextInfo = styled(TextWrap)`
  font-size:  ${props => props.theme.defs.font.size.large};
  padding:  ${props => props.theme.defs.padding.normal};
  opacity:  ${props => props.theme.defs.opacity.medium};
  color:    black;
`;


export default TextInfo;