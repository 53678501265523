import styled from 'styled-components';


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};
  width: 100%;
  /* background: red; */
`;

/**
 * License
 */
export const ItemsWrapper = styled.div<{$color: string}>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};

  background-color: ${props => props.$color};
  padding: ${props => props.theme.defs.padding.large};
  border-radius: ${props => props.theme.defs.border.radius.normal};

  color: #fff;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;

export const ItemTitle = styled.div`
  color: #ddd;
  font-size: ${props => props.theme.defs.font.size.small};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;


export const LicenseNameItem = styled.div<{$color: string}>`
  /* background-color: ${props => props.$color}; */
  font-size:  ${props => props.theme.defs.font.size.xxlarge};
  font-weight: ${props => props.theme.defs.font.weight.medium};
`;

export const PriceItem = styled.div`
  font-size: ${props => props.theme.defs.font.size.xxxlarge};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;

export const LicenseValidityItem = styled.div`
  font-size: ${props => props.theme.defs.font.size.large};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;
