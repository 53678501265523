import React      from 'react';
import { useRef } from 'react';

import jtl from 'tools/jtl';

import { Position } from 'app/arch/types';
import { useDocState } from 'app/ui/contexts/document';

import ControlBoxComponent from '../controls/control-box';
import { FRAME_CONTROL_BOX_OFFSET } from '../styles/frame-control-box';
import { FRAME_CONTROL_BOX_SIZE   } from '../styles/frame-control-box';

import { ShapeCalcPointer } from '../../shapes-calculators/shape-calc-pointer';
import { FrameWrapper } from './styles';
import { FrameBaseProps } from '../types';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


interface Props extends FrameBaseProps {
}


export const FramePointerComponent: React.FC<Props> = (props: Props) => {
  const { 
    widgetAddr,
    
    scale,

    startPoint, 
    endPoint,
    tailSize,

    widgetStyle,
  } = props;

  const document = useDocState();
  
  const {
    setContent
  } = useEditorStatesSetters();

  const startDragValue  = useRef<any>(null);
  
  const frameCtrlBoxOffset = FRAME_CONTROL_BOX_OFFSET / scale;
  const frameCtrlBoxSize   = FRAME_CONTROL_BOX_SIZE   / scale;

  const shapeCalc = new ShapeCalcPointer({
    style: widgetStyle,
    startPoint,
    endPoint
  });

  const c = shapeCalc;

  const handleStyleChanged = (style: any) => {
    document.content.cellImages_image_widget_updateStyle(
      widgetAddr,
      style
    );
  }

  //-----------------
  // Ctrl positions
  //
  const getCtrlHeadCommonPosition = (sideLeft: boolean, _arrowHeadWidth: number, _arrowHeadHeight: number) => {
    const verticalAngleBordersSize = c.borderWidthAngledVertical + c.outlineWidthAngledVertical;
    const totalBorderWidth = c.borderWidth + c.outlineWidth;

    const multiplier = sideLeft ? -1 : 1;

    const ret = [
      multiplier * (( _arrowHeadWidth + c.arrowShaftWidth ) / 2 + totalBorderWidth + frameCtrlBoxSize / 2 + frameCtrlBoxOffset),
      _arrowHeadHeight// + verticalAngleBordersSize,
    ] as Position;

    return ret;
  }

  const getCtrlHeadCommonPositionRev = (sideLeft: boolean, position: Position) => {
    const multiplier = sideLeft ? -1 : 1;
    const verticalBordersSize = c.borderWidthAngledVertical + c.outlineWidthAngledVertical;// + borderWidth + outlineWidth;
    const totalBorderWidth    = c.borderWidth + c.outlineWidth;

    const ret = [
      (multiplier * position[0] - (frameCtrlBoxOffset + frameCtrlBoxSize / 2 + totalBorderWidth)) * 2 - c.arrowShaftWidth,
      position[1] //- verticalBordersSize,
    ];

    return {
      headWidth:  ret[0],
      headHeight: ret[1]
    };
  }

  const getCtrlHeadRightPosition = (_arrowHeadWidth: number, _arrowHeadHeight: number) => {
    return getCtrlHeadCommonPosition(false, _arrowHeadWidth, _arrowHeadHeight);
  }

  const getCtrlHeadRightPositioRev = (position: Position) => {
    return getCtrlHeadCommonPositionRev(false, position);
  }

  const getCtrlHeadLeftPosition = (_arrowHeadWidth: number, _arrowHeadHeight: number) => {
    return getCtrlHeadCommonPosition(true, _arrowHeadWidth, _arrowHeadHeight);
  }

  const getCtrlHeadLeftPositionRev = (position: Position) => {
    return getCtrlHeadCommonPositionRev(true, position);
  }

  const getCtrlHeadTopPosition = () => {
    const ret = [
      0,
      0,
    ] as Position;

    return ret;;
  }
  //-----------
  // Dragging
  //

  const ctrl_handleDragStart = () => {
    const arrowHeadWidth  = jtl.css.valueToNumber(widgetStyle.arrowHeadWidth);
    const arrowHeadHeight = jtl.css.valueToNumber(widgetStyle.arrowHeadHeight);

    const ctrlHeadLeft  = getCtrlHeadLeftPosition(arrowHeadWidth, arrowHeadHeight);
    const ctrlHeadRight = getCtrlHeadRightPosition(arrowHeadWidth, arrowHeadHeight);

    startDragValue.current = { 
      ctrlHeadRight, 
      ctrlHeadLeft,

      startPoint,
      arrowHeadHeight
    };
  }

  const ctrl_handleDragStop = () => {
    document.saveUndo();
  }

  const ctrlHeadCommon_handleDrag = (
    deltaMove: Position,
    ctrlStartPosition: Position,
    revCtrlPositionFn: any
  ) => {
    // As this move is taking place when arrow is already rotated,
    // we need to un-rotate it, to calculate delta move in (0, 0) plane.
    const radians = -1 * c.arrowAngle;
    const deltaMoveUnrotated = jtl.geometry.rotateVector(radians, deltaMove);


    const currentPosition = [
      ctrlStartPosition[0] + deltaMoveUnrotated[0],
      ctrlStartPosition[1] + deltaMoveUnrotated[1]
    ] as Position;

    
    const newProps = revCtrlPositionFn([
      currentPosition[0],
      currentPosition[1],
    ]);

    const width  = Math.max(0, newProps.headWidth);
    const height = Math.max(0, newProps.headHeight);
    
    handleStyleChanged({ 
      arrowHeadWidth:  `${width}px`,
      arrowHeadHeight: `${height}px`
    });

    setContent();
  }

  const ctrlHeadRight_handleDrag = (deltaMove: Position) => {
    ctrlHeadCommon_handleDrag(
      deltaMove,
      startDragValue.current.ctrlHeadRight,
      getCtrlHeadRightPositioRev
    );
  }

  const ctrlHeadLeft_handleDrag = (deltaMove: Position) => {
    ctrlHeadCommon_handleDrag(
      deltaMove,
      startDragValue.current.ctrlHeadLeft,
      getCtrlHeadLeftPositionRev
    );
  }

  const ctrlHeadTop_handleDrag = (deltaMove: Position) => {
    const deltaRotated = jtl.geometry.rotateVector(c.arrowAngle, deltaMove);

    const arrowHeadHeight = startDragValue.current.arrowHeadHeight;
    const _startPoint = [...startDragValue.current.startPoint] as Position;
    _startPoint[1] += deltaRotated[1];

    const update = { startPoint: _startPoint };
    document.content.cellImages_image_widgetArrowText_update(widgetAddr, update);

    handleStyleChanged({ 
      arrowHeadHeight: `${arrowHeadHeight - deltaRotated[1]}px`
    });

    setContent();
  }

  const ctrlHeadRightPosition = getCtrlHeadRightPosition(c.arrowHeadWidth, c.arrowHeadHeight);
  const ctrlHeadLeftPosition  = getCtrlHeadLeftPosition(c.arrowHeadWidth, c.arrowHeadHeight);
  const ctrlHeadTopPosition   = getCtrlHeadTopPosition();


  return (
    <FrameWrapper
      style={{
        left: `${startPoint[0]}px`,
        top:  `${startPoint[1]}px`,
        transform: `rotate(${c.arrowAngle}rad)`,
        transformOrigin: `0px 0px`,
      }}
    >
      <ControlBoxComponent
        scale={scale}
        position={ctrlHeadRightPosition}
        onDragStart={ctrl_handleDragStart}
        onDrag={ctrlHeadRight_handleDrag}
        onDragStop={ctrl_handleDragStop}
      />

      <ControlBoxComponent
        scale={scale}
        position={ctrlHeadLeftPosition}
        onDragStart={ctrl_handleDragStart}
        onDrag={ctrlHeadLeft_handleDrag}
        onDragStop={ctrl_handleDragStop}
      />

      {/* <ControlBoxComponent
        scale={scale}
        position={ctrlHeadTopPosition}
        onDragStart={ctrl_handleDragStart}
        onDrag={ctrlHeadTop_handleDrag}
        onDragStop={ctrl_handleDragStop}
      /> */}
    </FrameWrapper>
  );
}
