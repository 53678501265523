import React from 'react';
import jtl from 'tools/jtl';

import { DocumentRelease } from 'app/arch/backend/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { Revision } from './styles';
import { ReleaseDate } from './styles';


interface Props {
  release: DocumentRelease;
  hover: boolean;
}


export const ItemContentComponent: React.FC<Props> = (props: Props) => {
  const {
    release,
    hover,
  } = props;

  const t = useTranslations();
  const date = release.dateCreated;
  const [dateTxt, timeTxt]  = jtl.date.toYYYYMMDD(new Date(date));

  const revText    = t("document revision, list item");
  const revisionNo = release.revision;
  const revDisplay = `${revText} ${revisionNo}`

  return (
    <>
      <Revision>
      { revDisplay }
      </Revision>

      <ReleaseDate>
      { <>{dateTxt}<>&nbsp;</> {timeTxt}</> }
      </ReleaseDate>
    </>
  );
}
