import React from 'react';
import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';

import { DNDContext }      from 'app/ui-v2/editor-instruction/dnd-context';
import HLS_DropComponent   from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useRowToSectionMove from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/row/use-row-to-section-move';
import useSectionMove      from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/section/use-section-move';
import useDNDDataGet       from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef }      from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';


interface Props {
  sectionAddr: TypesContent.SectionAddr;
  children: React.ReactNode;
}


export const SectionNameDropComponent: React.FC<Props> = (props: Props) => {
  const {
    sectionAddr,
    children
  } = props;

  const moveSection = useSectionMove();
  const moveRowToSection = useRowToSectionMove();
  const getDNDData = useDNDDataGet();

  const handleSectionDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const droppedSectionAddr = data.sectionAddr;

    moveSection({
      srcSection: droppedSectionAddr,
      dstSection: sectionAddr
    });
  }

  const handleRowDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const droppedRowAddr = data.rowAddr;

    moveRowToSection({
      srcRow: droppedRowAddr, 
      dstSection: sectionAddr
    });
  }

  const contextsDefs: ContextDef[] = [
    {
      dndContext: DNDContext.viewContent.table.section,
      onDrop: handleSectionDrop
    },
    {
      dndContext: DNDContext.viewContent.table.row,
      onDrop: handleRowDrop
    },
  ];

  return (
    <HLS_DropComponent 
      contextsDefs={contextsDefs}
    >
      { children }
    </HLS_DropComponent>
  );
}
