import React from 'react';
import { useRecoilValue } from 'recoil';
import { HeaderRowsTools } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { UIState_HeaderRows } from 'app/ui/states/editor-instruction';
import usePageContext from 'app/ui-v2/editor-instruction/views/view-content/hooks/page/use-page-context';
import HeaderCellComponent from './header-cell';
import CellContextComponent from '../cell-context';



interface Props {
}


export const HeaderComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const pageContext = usePageContext();
  const pageIdx = pageContext.pageIdx;
  
  if (pageIdx === null) {
    const msg = `PageIdx is null`;
    throw new Error(msg);
  }


  const rowsAddrs = useRecoilValue(UIState_HeaderRows.rowsAddrs);

  const renderCells = () => {
    const Cells = rowsAddrs.map((rowAddr, idx) => {
      const isRowLast = (idx === (rowsAddrs.length - 1));
      
      const pageRowAddr = {
        pageIdx,
        ...rowAddr,
      }

      const key = HeaderRowsTools.getRowKey(rowAddr);
      return (
        <React.Fragment key={key}>
          <CellContextComponent isRowLast={isRowLast}>
            <HeaderCellComponent pageRowAddr={pageRowAddr} />
          </CellContextComponent >
        </React.Fragment>
      )
    });

    return Cells;
  }


  return renderCells();
}

