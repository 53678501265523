import Errors from "app/arch/tools/errors";
import { HookConfigProps } from "./types";
import { ShowDialogProps } from "./types";

//------------------
// Config hook
// This call is coming from DialogYesNoComponent


let _hookConfig: HookConfigProps | null = null;

export const configureHook = (props: HookConfigProps) => {
  _hookConfig = {
    ...props
  };
}


//-------------------
// Hook itself
//
export interface Cfg  {
}

export const useDialogCustom = () => {
  const show = ({
    title,
    message,
    messageComponent,
    icon: icon_,
    buttons
  }: ShowDialogProps) => {
    
    if (_hookConfig === null) {
      const msg = `Dialog custom has not been configured`;
      Errors.hard(msg);
      return;
    }

    const msg = message || "";
    const icon = icon_ || null;

    _hookConfig.setTitle(title);
    _hookConfig.setMessage(msg);
    _hookConfig.setMessageComponent(messageComponent);
    _hookConfig.setIcon(icon);
    _hookConfig.setButtons(buttons);
    _hookConfig.showDialog();
  }

  const hide = () => {
    _hookConfig!.hideDialog();
  }

  return { show, hide }
}
