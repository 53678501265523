import useNavbarItemSelect from "app/ui-v2/home-page/home-page-user/hooks/use-navbar-item-select";
import useViewSelect       from "app/ui-v2/home-page/home-page-user/hooks/use-view-select"
import { NavbarTypes } from "app/arch/home-page-user/states/navbar";
import { ViewTypes }   from "app/arch/home-page-user/states/view";


const useFunctions = () => {
  const selectNavbarItem = useNavbarItemSelect();
  const selectView = useViewSelect();

  const handleSelect = (props: {
    navbarItem: NavbarTypes.NavbarItem,
    view: ViewTypes.ViewItem
  }) => {
    const {
      navbarItem,
      view
    } = props;
    selectNavbarItem(navbarItem);
    selectView(view);
  }

  return {
    handleSelect,
  }
}

export default useFunctions;