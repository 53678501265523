import useLogoSmartFit from "app/ui-v2/editor-logo/hooks/use-logo-smart-fit";
import useLogoUpload   from "app/ui-v2/editor-logo/hooks/use-logo-upload";
import useLogoDelete   from "app/ui-v2/editor-logo/hooks/use-logo-delete";
import useLogoRescale  from "./use-logo-resize";

import * as Types from '../types';


const useFunctions = (states: Types.States) => {
  const handleLogoUpload = useLogoUpload();
  const handleLogoDelete = useLogoDelete();
  const handleLogoSmartFit = useLogoSmartFit(states);
  
  const logoRescaleFns = useLogoRescale(states);


  return {
    handleLogoUpload,
    handleLogoDelete,
    handleLogoSmartFit,

    ...logoRescaleFns,
  }
}


export default useFunctions;