import { useRecoilValue } from "recoil";
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { UIState_EditorImageSession } from "app/ui/states/editor-instruction";


const useStates = (widgetAddr: ContentTypes.WidgetAddr | null) => {
  const imageEdited = useRecoilValue(UIState_EditorImageSession.imageEdited);
  const clipboardWidgetsProps = useRecoilValue(UIState_EditorImageSession.clipboardWidgetsProps);

  const isWidgetSelected = (widgetAddr !== null);
  const isClipboardWidgetsPropsEmpty = (clipboardWidgetsProps.length === 0);

  
  return {
    imageEdited,
    isWidgetSelected,
    isClipboardWidgetsPropsEmpty,
  }
}


export default useStates;