import { atom }           from 'recoil';
import { selector }       from 'recoil';
import { selectorFamily } from 'recoil';

import { UUID } from 'app/arch/types';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTools as Tools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/releaselogs-session';


//----------------
// State
//

export const state = atom<State.State>({
  key: "ei_docReleaselogSession",
  default: State.createInitialState()
});


//--------------------
// Selectors
//

export const cellSelected = selector({
  key: "ei_docReleaselogSession_cellSelected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selectedCellAddr = State.getCellSelected(stateObj);
    return selectedCellAddr;
  }
});

export const isCellSelected = selectorFamily({
  key: "ei_docReleaselogSession_isCellSelected",
  get: (cellAddr: Types.CellAddr) => ({ get }) => {
    const stateObj = get(state);
    const selectedCellAddr = State.getCellSelected(stateObj);

    if (selectedCellAddr === null) {
      return false;
    }

    const selected = Tools.compareCellAddr(selectedCellAddr, cellAddr);
    return selected;
  }
});


export const columnSelected = selector({
  key: "ei_docReleaselogSession_columnSelected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selectedColumnAddr = State.getColumnSelected(stateObj);
    return selectedColumnAddr;
  }
});

export const isColumnSelected = selectorFamily({
  key: "ei_docReleaselogSession_isColumnSelected",
  get: (columnAddr: Types.ColumnAddr) => ({ get }) => {
    const stateObj = get(state);
    const selectedColumnAddr = State.getColumnSelected(stateObj);

    if (selectedColumnAddr === null) {
      return false;
    }

    const selected = Tools.compareColumnAddr(selectedColumnAddr, columnAddr);
    return selected;
  }
});

export const isRowSelected = selectorFamily({
  key: "ei_docReleaselogSession_isRowSelected",
  get: (rowAddr: Types.RowAddr) => ({ get }) => {
    const stateObj = get(state);
    const selectedRowAddr = State.getRowSelected(stateObj);

    if (selectedRowAddr === null) {
      return false;
    }

    const selected = Tools.compareRowAddr(selectedRowAddr, rowAddr);
    return selected;
  }
});


/**
 * @deprecated Seems not needed
 */
export const signalColumnsWidth = selectorFamily({
  key: "ei_docReleaselogSession_signalColumnsWidth",
  get: (releaselogId: UUID) => ({ get }) => {
    const stateObj = get(state);
    const signal = State.getSignalColumnsWidth(stateObj, releaselogId);
    return signal;
  }
});


export const panelDocument = selector({
  key: `ei_docReleaselogSession_panelDocument`,
  get: ({ get }) => {
    const stateObj = get(state);
    const panel = State.getPanelDocument(stateObj);
    return panel;
  }
});
