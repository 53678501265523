import styled from "styled-components";

export const MenuBarItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;
  border: none;

  height: 34px;

  padding-left:  ${props => props.theme.menuBarItem.padding};
  padding-right: ${props => props.theme.menuBarItem.padding};
  color:         ${props => props.theme.menuBarItem.color.primary};
  font-size:     ${props => props.theme.menuBarItem.font.size};
  border-radius: ${props => props.theme.menuBarItem.border.radius};

  &.selected {
    color:      ${props => props.theme.menuBarItem.color.selected};
    background: ${props => props.theme.menuBarItem.background.selected};
  }

  &:not(:disabled):active {
    color:      ${props => props.theme.menuBarItem.color.active};
    background: ${props => props.theme.menuBarItem.background.active};
  }
`;

export default MenuBarItem;