import styled from "styled-components";
import CellsSelectedBackground from "./cells-selected-background";


const CellsDragOver = styled(CellsSelectedBackground)`
  background: ${
    props => props.selected ? 
    props.theme.defs.accentColor.opacity03.primary : 
    // "rgba(0, 0, 0, 0.1)" : 
    "transparent"
  };
`;


export default CellsDragOver;
