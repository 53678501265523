import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import HLSDraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import ImageDraggedViewComponent from './image-dragged-view';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';


interface Props {
  imageAddr: Types.ImageAddr;
  children: React.ReactNode;
}


export const ImageDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    imageAddr, 
    children
  } = props;

  const setDNDData = useDNDDataSet();

  const handleDragStart = () => {
    const data = { imageAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  return (
    <HLSDraggerComponent
      debug={false}
      dndContext={DNDContext.image.cell}
      onDragStart={handleDragStart}
      draggedView={
        <ImageDraggedViewComponent imageAddr={imageAddr} />
      }
    >
      { children }
    </HLSDraggerComponent>
  );
}
