import React from 'react';

import { ReleaselogsTools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState }      from 'app/ui/contexts/document';
import useReleaselogIsEditable from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-releaselog-is-editable';

import HeaderCellDraggerComponent     from './header-cell-dragger';
import HeaderCellDropComponent        from './header-cell-drop';
import HeaderCellViewComponent        from './header-cell-view';
import HeaderCellContextMenuComponent from './header-cell-context-menu';
import HeaderCellSelectComponent      from './header-cell-select';
import HeaderCellNameEditComponent    from './header-cell-name-edit';
import HeaderCellCssComponent         from './header-cell-css';
import HeaderCellProbeComponent       from './header-cell-probe';

import { HeaderCell } from './styles';


interface Props {
  columnAddr: ReleaselogsTypes.ColumnAddr;
}


export const HeaderCellComponent: React.FC<Props> = (props: Props) => {
  const { 
    columnAddr,
  } = props;

  const document = useDocState();
  const isIdxColumn = document.releaselogs.isColumnIndexType(columnAddr);

  const cellDataTest = ReleaselogsTools.getColumnDataTest({
    columnAddr,
    docState: document,
    prefix: 'releaselog-header-cell'
  });


  return (
    <HeaderCellProbeComponent 
      enabled={isIdxColumn}
      columnAddr={columnAddr}
    >
      <HeaderCell 
        data-test={cellDataTest}
        borderLeft={isIdxColumn} 
      >
        <HeaderCellActivePartsComponent 
          columnAddr={columnAddr} 
          isIdxColumn={isIdxColumn}
        >
          <HeaderCellCssComponent columnAddr={columnAddr} >
            <HeaderCellViewComponent columnAddr={columnAddr} />
          </HeaderCellCssComponent>
        </HeaderCellActivePartsComponent>
      </HeaderCell>
    </HeaderCellProbeComponent>
  );
}



/**
 * 
 * Active parts of header cell.
 * This parts should be disabled, when
 * given releaselog is not editable.
 * 
 */

interface HeaderCellActivePartsProps extends Props {
  isIdxColumn: boolean;
  children: React.ReactNode;
}


const HeaderCellActivePartsComponent: React.FC<HeaderCellActivePartsProps> = (props: HeaderCellActivePartsProps) => {
  const { 
    isIdxColumn,
    columnAddr,
    children,
  } = props; 
  
  const isEditable = useReleaselogIsEditable(columnAddr);

  if ( ! isEditable ) {
    return children;
  }

  return (
    <HeaderCellNameEditComponent columnAddr={columnAddr} >
      <HeaderCellSelectComponent columnAddr={columnAddr} >
        <HeaderCellContextMenuComponent columnAddr={columnAddr} >
          <HeaderCellDraggerComponent 
            columnAddr={columnAddr} 
            disabled={isIdxColumn}
          >
            <HeaderCellDropComponent 
              columnAddr={columnAddr} 
              disabled={isIdxColumn}
            > 
            { children }
            </HeaderCellDropComponent>
          </HeaderCellDraggerComponent>
        </HeaderCellContextMenuComponent>
      </HeaderCellSelectComponent>
    </HeaderCellNameEditComponent>
  );
}
