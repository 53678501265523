import { useRecoilValue } from "recoil";

import { PanelControlsConfig }              from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"

import { useTranslations }    from 'app/ui/hooks/app/use-translation';
import { UIState_RepoMarkers } from "app/ui/states/editor-instruction";

import ControlMarkersComponent       from "../controls/control-markers";
import ControlMarkersCreateComponent from "../controls/control-markers-create";


const usePanelConfigMarkers = () => {
  const t = useTranslations();
  const markersCount = useRecoilValue(UIState_RepoMarkers.markersCount);

  //---------------
  // Get config
  //
  const getConfig = () => {

    const __markers_createMarker = () => (
      {
        type: PanelControlsItemSectionItemType.USER_COMPONENT,
        component: ControlMarkersCreateComponent
      }
    );

    const __markers_gallery = () => (
      {
        type: PanelControlsItemSectionItemType.USER_COMPONENT,
        component: ControlMarkersComponent
      }
    );

    const config: PanelControlsConfig = {
      items: [
        {
          title: t('markers'),
          sections: [
            {
              subsections: [
                {
                  title: t("marker, create"),
                  items: [
                    __markers_createMarker()
                  ]
                },
                {
                  skip: markersCount === 0,
                  items: [
                    __markers_gallery()
                  ]
                },                
              ],
            }
          ]
        },
      ]
    } // config
  
    return config;
  }

  return getConfig;
}


export default usePanelConfigMarkers;