import React from "react";
import jtl from "tools/jtl";
import * as docx from "docx";
import * as Types from './types';


export const dxaToPixels = (dxa: number): number => {
  const inches = dxa / 1440;  // Convert DXA to inches
  const pixels = inches * 96; // Convert inches to pixels (assuming 96 DPI)

  return pixels;
}


export const pixelsToDxa = (pixels: number): number => {
  const inches = pixels / 96; // Convert pixels to inches (assuming 96 DPI)
  const dxa = inches * 1440;  // Convert inches to DXA

  return dxa;
}

export const getCellAlignment = (
  css: React.CSSProperties,
) => {
  const justify = css.justifyContent;
  if (justify  === 'center') {
    return docx.AlignmentType.CENTER;
  }

  if (justify  === 'flex-end') {
    return docx.AlignmentType.RIGHT;
  }

  // justify === undefined || others...
  return docx.AlignmentType.LEFT;
}

export const getPadding = (px: number) => {
  const dxa = pixelsToDxa(px);

  const margins = {
    top: dxa,
    bottom: dxa,
    left: dxa,
    right: dxa,
  }

  return margins;
}

export const getCellPadding = (
  css: React.CSSProperties,
) => {
  const padding = jtl.css.valueToNumber(css.padding);
  return getPadding(padding);
}

