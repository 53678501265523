import React from 'react';
import InputComponent from 'lego/components/input';
import { Input } from './styles';


interface Props {
  email: string;
  disabled: boolean;
  onEmailChange: (email: string) => void;
  onSendPDF: () => void;
}


export const EmailInputComponent: React.FC<Props> = (props: Props) => {
  const {
    email,
    disabled,
    onEmailChange,
    onSendPDF
  } = props;


  return (
    <InputComponent 
      disabled={disabled}
      value={email}
      onValueChange={onEmailChange}
      onCommit={onSendPDF}
      // blurOnEnterKey={true}
      focusOnLoad={false}
      Input={Input}
    />
  );
}

