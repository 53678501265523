import React from 'react';
import * as Types from '../../types';

import { IconWrapper } from './styles';
import { Item }        from './styles';
import { IconScaler }  from './styles';
import { TextWrapper } from './styles';
import { Text } from './styles';
import { KeyBindingWrapper } from './styles';


interface Props extends Types.MenubarItemPanelItemProps {
  skipClick?: boolean;
}


export const MenubarItemPanelItemComponent: React.FC<Props> = (props: Props) => {
  const {
    skipClick,
    config,
  } = props;

  const Icon = config.icon;

  const handleClick = (event: React.MouseEvent) => {
    if ( skipClick ) return;

    event.stopPropagation();
    config.onClick?.();
  }

  return (
    <Item 
      data-test={config.dataTest}
      onClick={handleClick}
    >
      <IconWrapper>
        {
          Icon &&
          <IconScaler>
            <Icon />
          </IconScaler>
        }
      </IconWrapper>

      <TextWrapper>
        <Text>
        { config.title }
        </Text>
      </TextWrapper>

      <KeyBindingWrapper>
        { config.keysLabel }
      </KeyBindingWrapper>
    </Item>
  );
}
