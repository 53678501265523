import { ContentTypes }    from "app/arch/editor-instruction/document/states/persistent/content";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState }        from "app/ui/contexts/document";
import useSelectedReset from "../use-selected-reset";
import useWidgetLogger  from "./use-widget-logger";


const useWidgetEdit = () => {
  const document = useDocState();
  const logger = useWidgetLogger();
  const resetSelected = useSelectedReset();

  const {
    setEditorImageSession
  } = useEditorStatesSetters();


  const editWidget = (props: {
    widgetAddr: ContentTypes.WidgetAddr,
    editDisabled?: boolean,
  }) => {
    const {
      widgetAddr,
      editDisabled,
    } = props;

    logger.debug(`Edit widget, edit disable: ${editDisabled}`);

    if ( editDisabled ) {
      return;
    }

    const edited = document.editorImageSession.isWidgetEdited(widgetAddr);
    if (edited) {
      logger.debug(`Widget, already being edited`);
      return;
    }

    resetSelected({skipStateUpdate: true});
    document.editorImageSession.setWidgetEdited(widgetAddr);
    setEditorImageSession();
  }

  return editWidget;
}

export default useWidgetEdit;