import React from 'react';
import { PanelControlsItemSectionControl_ItemsMix as Config} from '../../config';
import ItemRenderComponent from './item-render';
import { MainWrapper } from './styles';


interface Props {
  config: Config;
}


export const ControlItemsMixComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
  } = props;

  const renderItems = () => (
    config.items.map((item, idx) => 
      <ItemRenderComponent
        key={idx}
        config={item} 
      />
  ));


  return (
    <MainWrapper>
      { renderItems() }
    </MainWrapper>
  );
}
