import React from 'react';

import usePageFormatWatcher        from './hooks/use-page-format-watcher';
import useDocHeaderItemsWatcher    from './hooks/use-doc-header-items-watcher';
import useReleaselogsAddrsdWatcher from './hooks/use-releaselogs-addrs-watcher';
import useReleaselogActiveRowsAddrsWatcher from './hooks/use-releaselog-active-rows-addrs-watcher';


interface Props {
}


export const WatchersComponent: React.FC<Props> = (props: Props) => {
  usePageFormatWatcher();
  useDocHeaderItemsWatcher();
  useReleaselogsAddrsdWatcher();
  useReleaselogActiveRowsAddrsWatcher();

  return null;
}
  
