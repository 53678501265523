import styled from "styled-components";


export const LinesWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const StickyLinesWrapper = styled.div`
  position: relative;
  z-index: 2;
`;
