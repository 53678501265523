import React from 'react';
import { PageItemsProps } from '../types';
import DocMarkersComponent from './doc-markers';


interface Props extends PageItemsProps {
}


export const PageItemDocMarkersComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <DocMarkersComponent/>
  );
}
  
