import React from 'react';
import useKeyBindings from './hooks/use-key-bindings';


interface Props {
  children: React.ReactNode;
}


export const EditorImageKeybindingsComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  useKeyBindings();

  return children;
}

