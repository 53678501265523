import React from 'react';

import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';

import LoaderDPIProbeComponent        from './loader-dpi-probe';
import LoaderScrollbarProbeComponent  from './loader-scrollbar-probe';
import LoaderGoogleAnalyticsComponent from './loader-google-analytics';
import LoaderStripePricesComponent    from './loader-stripe-prices';
import LoaderUserInvoicesComponent    from './loader-user-invoices';


interface Props {
}


export const LoadersSimpleComponent: React.FC<Props> = (props: Props) => {
  const isPrintout = useIsPrintout();

  const renderEdit = () => (
    <>
      <LoaderGoogleAnalyticsComponent />
      <LoaderScrollbarProbeComponent />
      {/* <LoaderStripePricesComponent /> */}
      <LoaderUserInvoicesComponent />
      <LoaderDPIProbeComponent />
    </>
  );


  const renderPrintout = () => (
    <LoaderDPIProbeComponent />
  );

  const render = (
    isPrintout ?
    renderPrintout : 
    renderEdit
  );

  return render();
}
