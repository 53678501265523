import React from 'react';
import { useState } from 'react';

import HLS_MenubarItemPanelItemSubsectionComponent from 'lego-v2/menubar/hls/menubar-item-panel-item-subsection';
import useMenubarItemContext from '../menubar-item-context/use-context';

import * as Types from '../../types';
import { MainWrapper } from './styles';


interface Props extends Types.MenubarItemPanelItemSubsectionProps {
}


export const MenubarItemPanelItemSubsectionComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
  } = props;

  const [sectionOpen, setSectionOpen] = useState(false);
  
  const {
    cancelBlur
  } = useMenubarItemContext();

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSectionOpen(true);
  }

  const handlePointerDown = (event: React.PointerEvent) => {
    cancelBlur();
  }

  return (
    <MainWrapper 
      onPointerDown={handlePointerDown}
      onClick={handleClick}
    >
      <HLS_MenubarItemPanelItemSubsectionComponent
        {...props}
        open={sectionOpen}
      />
    </MainWrapper>
  );
}

