import { PricePlansTypes } from '../price-plans';
import * as Defaults from './defaults';
import * as Tools from './tools';
import * as Types from './types';


export type State = {
  billingPeriod: PricePlansTypes.BillingPeriod,
};

export const getBillingPeriod = (state: State) => {
  return state.billingPeriod;
}



/**
 * Create initial state
 */

export const createInitialState = (): State => {
  const initState: State = {
    billingPeriod: Defaults.getBillingPeriod()
  }

  return initState;
}
