import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";


const useWidgetStylePaste = () => {
  const docState = useDocState();

  const {
    setContent,
  } = useEditorStatesSetters();


  const pasteStyle = (widgetAddr: ContentTypes.WidgetAddr) => {
    docState.cellImages_image_pasteWidgetStyle(widgetAddr);
    docState.saveUndo();
    setContent();
  }

  return pasteStyle;
}


export default useWidgetStylePaste;