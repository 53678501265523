import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { MainWrapper } from './styles';
import { Loading } from './styles';


interface Props {
}


export const LoadingViewComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const t = useTranslations();

  return (
    <MainWrapper>
      {/* <Loading>
        { t('loading') }
      </Loading> */}
    </MainWrapper>
  );
}

