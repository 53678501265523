import { useRef } from "react";
import { useEffect } from "react";

import { useMutation  } from 'app/arch/backend/use-mutation';
import { mutation as mutationDef } from 'app/arch/backend';
import { useTranslations } from "app/ui/hooks/app/use-translation";


type OnDoneFn  = (subscriptionId: string) => void;
type OnErrorFn = (error: string) => void;


export interface HookProps {
  variables: any;
  onDone?: OnDoneFn;
  onError?: OnErrorFn;
}


const useUserSubscriptionMutateCancelAtEnd = () => {
  const t = useTranslations();
  const hookPropsRef = useRef<HookProps | null>(null);

  const msgs = {
    // onStart: { msg: t('subscription is being updated'), },
    onEnd: { msg: t("subscription has been updated"), }
  }

  const mutation = mutationDef.cancelSubscriptionAtPeriodEnd;

  const { 
    mutation: mutate,
    data,
    error,
  } = useMutation(mutation, msgs);

  useEffect(() => {
    if ( ! data ) {
      return;
    }

    if ( ! hookPropsRef.current ) {
      const msg = `Cancel sub mutation props not set`;
      throw new Error(msg);
    } 

    const result = data.subscriptionCancelAtPeriodEnd;
    const variables = hookPropsRef.current.variables;
    const subscriptionId = variables.subscriptionId;

    if (result.ok) {
      const onDone = hookPropsRef.current.onDone;
      onDone?.(subscriptionId);
    }
    else {
      const onError = hookPropsRef.current.onError;
      onError?.('Invalid data');
    }

    resetProps();
  }, [data]);


  useEffect(() => {
    if ( ! error ) {
      return;
    }

    if ( ! hookPropsRef.current ) {
      const msg = `Cancel sub mutation props not set`;
      throw new Error(msg);
    } 

    const onError = hookPropsRef.current.onError;
    onError?.(error.message);

    resetProps();
  }, [error]);

  const resetProps = () => {
    hookPropsRef.current = null;
  }

  const hook = ({
    variables,
    onDone
  }: HookProps) => {
    hookPropsRef.current = { variables, onDone };
    mutate({ variables });
  }

  return hook;
}

export default useUserSubscriptionMutateCancelAtEnd;