import * as Types from './types';


interface Props extends Types.ConfigProps {
}


const useStates = (props: Props) => {
  const {
    logoImage,
    logoImageView
  } = props;

  return {
    logoImage,
    logoImageView
  }
}


export default useStates;