import styled from "styled-components";


export const MenuItem = styled.div<{selected?: boolean}>`
  cursor: pointer;
  user-select: none;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap:           ${props => props.theme.menuItem.gap};
  font-size:     ${props => props.theme.menuItem.font.size};
  padding-left:  ${props => props.theme.menuItem.padding};
  padding-right: ${props => props.theme.menuItem.padding};
  border-radius: ${props => props.theme.menuItem.border.radius};

  ${props => props.selected ? 
  `
    color:        ${props.theme.menuItem.color.selected};
    background:   ${props.theme.menuItem.background.selected};
  `
  : 
  `
    color:         ${props.theme.menuItem.color.primary};
  `
  }

  &:hover:not(:active) {
    color:      ${props => props.theme.menuItem.color.hover};
    background: ${props => props.theme.menuItem.background.hover};
  }

  &:active {
    color:        ${props => props.theme.menuItem.color.active};
    background:   ${props => props.theme.menuItem.background.active};
  }

  transition: all ${props => props.theme.defs.transition} ease-in-out;
`;


export default MenuItem;