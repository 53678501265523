import * as docx from "docx";

import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import AssetsRepo from "../../parts/assets-repo";
import ReleaselogExporter from "./releaselog-exporter";


class ViewReleaselogExporter {
  private _docState: DocState;
  private _assetsRepo: AssetsRepo;

  constructor(
    docState: DocState,
    assetsRepo: AssetsRepo,
  ) {
    this._docState = docState;
    this._assetsRepo = assetsRepo;
  }

  get content() { return this._docState.content; }
  get assetsRepo() { return this._assetsRepo; }

  getSections(): (docx.Table | docx.Paragraph)[] {
    const sections: (docx.Table | docx.Paragraph)[] = [
      ...this._getReleaselogs(),
    ];
    
    return sections;
  }


  private _getReleaselogs() {
    const releaselogsExporter = new ReleaselogExporter(this._docState, this.assetsRepo);
    const releaselogs = releaselogsExporter.createSections();

    const sections = [
      ...releaselogs,
    ];

    return sections;
  }
}


export default ViewReleaselogExporter;