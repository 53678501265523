import { useSetRecoilState } from "recoil";

import useUserSettings from "./use-user-settings";
import useUserInvoices from "./use-user-invoices";
import useUserSubscriptions from "./use-user-subscriptions";

import { UIUser_Settings } from "../states";
import { UIUser_Invoices } from "../states";
import { UIUser_Subscriptions } from "../states";


const useUserStateSetters = () => {
  const userSettings = useUserSettings();
  const userInvoices = useUserInvoices();
  const userSubscriptions = useUserSubscriptions();

  const __setUserSettings = useSetRecoilState(UIUser_Settings.state);
  const __setUserInvoices = useSetRecoilState(UIUser_Invoices.state);
  const __setUserSubscriptions = useSetRecoilState(UIUser_Subscriptions.state);

  const setUserSettings = () => __setUserSettings(userSettings.raw);
  const setUserInvoices = () => __setUserInvoices(userInvoices.raw);
  const setUserSubscriptions = () => __setUserSubscriptions(userSubscriptions.raw);


  const setAll = () => {
    setUserSettings();
    setUserInvoices();
    setUserSubscriptions();
  }

  return {
    setAll,
    
    setUserSettings,
    setUserInvoices,
    setUserSubscriptions,
  }
}

export default useUserStateSetters;