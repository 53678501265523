import { createContext } from 'react';


export interface ContextProps {
  pageIdx: number | null;
};


const Context = createContext<ContextProps>({
  pageIdx: null,
});


export default Context;