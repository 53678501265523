import styled from "styled-components";


const TextWrap = styled.div`
  overflow: clip;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
`;


export default TextWrap;
