import { useRecoilValue } from 'recoil';
import { UIGoogleOAuth } from '../states';


const useGoogleOAuthReadyWatch = () => {
  const oauth = useRecoilValue(UIGoogleOAuth.oauth);
  return oauth;
}


export default useGoogleOAuthReadyWatch;