import React from 'react';
import EditorInstruction   from 'app/arch/editor-instruction';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useUserGoHome from 'app/ui-v2/home-page/home-page-user/hooks/user-user-go-home';
import ViewPanelComponent from 'app/ui-v2/home-page/hls/view-panel';

import { FillerTop } from './styles';
import { FillerBottom } from './styles';
import { MainWrapper } from './styles';
import { PanelWrapper } from './styles';
import { Panel } from './styles';
import { Text } from './styles';
import { Button } from './styles';
import { ButtonsWrapper } from './styles';


interface Props {
  editor: EditorInstruction;
}


export const GiveUpComponent: React.FC<Props> = (props: Props) => {
  const { 
    editor 
  } = props;
  
  const t = useTranslations();
  const userGoHome = useUserGoHome();

  const handleGoHome = () => {
    userGoHome();
  }

  return (
    <MainWrapper>
      <FillerTop />
      
      <PanelWrapper>
        <ViewPanelComponent 
          title="App crashed"
          Panel={Panel}
        >
          <Text>
            The app has crashed, and we can't recover. 
            We apologize for the inconvenience.
          </Text>
          <ButtonsWrapper>
            <Button
              onClick={handleGoHome}
            >
              {t('ok')}
            </Button>
          </ButtonsWrapper>

        </ViewPanelComponent>
      </PanelWrapper>

      <FillerBottom />

    </MainWrapper>
  );
}
