import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_HeaderFieldsSession } from 'app/ui/states/editor-instruction';
import { CellProps } from '../../types';

import { MainWrapper } from './styles';
import { Border }      from './styles';


interface Props extends CellProps {
  children: React.ReactNode;
}


export const CellSelectedBorderComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr,
    children,
  } = props;

  const cellSelected = useRecoilValue(
    UIState_HeaderFieldsSession.isPageCellSelected(pageCellAddr)
  );
  
  return (
    <MainWrapper>
      <Border selected={cellSelected} />
      { children }
    </MainWrapper>
  );
}
