import * as Defaults from './defaults';
import * as Types from "./types"


/**
 * State
 */
export type State = Types.View;


/**
 * Getters
 */
export const getView = (state: State) => state;


/**
 * Create Init State
 */
export const createInitialState = (): State => {
  const initState: State = Defaults.getView();
  return initState;
}
