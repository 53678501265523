import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useFunctions = () => {
  const document = useDocState();

  const {
    setContent
  } = useEditorStatesSetters();

  const setSectionsEnabled = (enabled: boolean) => {
    const update = { enabled };

    document.content.updateSectionsConfig(update);
    document.saveUndo();
    setContent();
  }

  const setSectionsIndexing = (indexing: TypesContent.SectionsIndexing) => {
    const update = { indexing };

    document.content.updateSectionsConfig(update);
    document.saveUndo();
    setContent();
  }

  const updateSectionsCSS = (props: {
    cssUpdate: React.CSSProperties,
    skipUndoSave?: boolean,
  }) => {
    const {
      cssUpdate,
      skipUndoSave
    } = props;
    
    document.content.updateSectionsNamesCSS(cssUpdate);
    if ( ! skipUndoSave ) {
      document.saveUndo();
    }
    setContent();
  }


  return {
    setSectionsEnabled,
    setSectionsIndexing,
    updateSectionsCSS
  }
}

export default useFunctions;