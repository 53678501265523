import React from 'react';
import MarkerViewSignBaseComponent from '../marker-view-sign-base';
import { MarkerViewSignBaseProps } from '../types';
    

interface Props extends MarkerViewSignBaseProps {
}


export const MarkerViewSignCircleComponent: React.FC<Props> = (props: Props) => {
  const { 
    Sign,
    Icon,
  } = props;


  return (
    <MarkerViewSignBaseComponent
      Sign={Sign}
      Icon={Icon}
    />
  );
}
