import produce from 'immer';
import * as State from './state';
import * as Types from './types';
import * as Defaults from './defaults';


export class HeaderMeta {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Logo
   */
  updateLogo(update: Types.LogoUpdate) {
    this._state = produce(this._state, draft => {
      const logo = State.getLogo(draft);
      Object.assign(logo, update);
    });
  }

  updateLogoImage(update: Types.LogoImageUpdate | null) {
    this._state = produce(this._state, draft => {
      const logo = State.getLogo(draft);

      if (update === null) {
        logo.image = null;
        return;
      }

      // Update is not null
      if (logo.image === null) {
        logo.image = Defaults.getLogoImage();
      }
      Object.assign(logo.image, update);
    });
  }

  updateLogoView(update: Types.LogoViewUpdate) {
    this._state = produce(this._state, draft => {
      const logo = State.getLogo(draft);
      Object.assign(logo.view, update);
    });
  }


  /**
   * ReleaseInfo
   */
  updateReleaseInfo(update: Types.ReleaseInfoUpdate) {
    this._state = produce(this._state, draft => {
      const releaseInfo = State.getReleaseInfo(draft);
      Object.assign(releaseInfo, update);
    });
  }

  
  /**
   * Getters
   */
  getLogo() {
    return State.getLogo(this._state);
  }
  
  getReleaseInfo() {
    return State.getReleaseInfo(this._state);
  }
}
