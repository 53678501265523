import { useEffect }  from 'react';

import { query }      from 'app/arch/backend';
import { useQueryV2 } from 'app/arch/backend/use-query-v2';
import { TemplateTypes } from 'app/arch/doc-templates/states/template';



export interface Props {
  reposIds: number[];
  onDone: (templates: TemplateTypes.BackendTemplates) => void;
  onError: (error: string) => void;
}

const useDocTemplatesFetch = (props: Props) => {
  const {
    reposIds,
    onDone,
    onError,
  } = props;

  const { 
    data: docTemplatesData,
    error: docTemplatesError,
    refetch: docTemplatesRefetch
  } = useQueryV2({
    query: query.getDocTemplates(),
    variables: { reposIds },
  });

  useEffect(() => {
    if ( ! docTemplatesData ) {
      return;
    }

    const templates: TemplateTypes.BackendTemplates = docTemplatesData.docTemplates;
    onDone(templates);

  }, [docTemplatesData]);


  useEffect(() => {
    if ( ! docTemplatesError ) {
      return;
    }

    console.error(docTemplatesError);
    onError(docTemplatesError.message);
  }, [docTemplatesError]);

  return docTemplatesRefetch;
}

export default useDocTemplatesFetch;
