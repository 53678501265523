import styled from "styled-components";
import PanelMsgWrapper from 'app/ui-v2/home-page/styles/panel-msg-wrapper';
import PanelMsgHeader  from "app/ui-v2/home-page/styles/panel-msg-header";
import PanelMsgText    from "app/ui-v2/home-page/styles/panel-msg-text";
import PanelMsgEnd     from "app/ui-v2/home-page/styles/panel-msg-end";


export const MsgWrapper = styled(PanelMsgWrapper)`
`;

export const MsgHeader = styled(PanelMsgHeader)`
`;

export const MsgText = styled(PanelMsgText)`
`;

export const MsgEnd = styled(PanelMsgEnd)`
`;

