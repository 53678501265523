import { useSetRecoilState } from "recoil";

import { UIProducts_Prices } from "../states";
import { UIProducts_PricePlansSession } from "../states";

import useProducts from "./use-products";


const useProductsStateSetters = () => {
  const products = useProducts();

  const __setPrices = useSetRecoilState(UIProducts_Prices.state);
  const setPrices = () => __setPrices(products.prices.raw);

  const __setPricePlansSession = useSetRecoilState(UIProducts_PricePlansSession.state);
  const setPricePlansSession = () => { __setPricePlansSession(products.pricePlansSession.raw); }


  const setAll = () => {
    setPrices();
    setPricePlansSession();
  }

  return {
    setAll,
    
    setPrices,
    setPricePlansSession,
  }
}

export default useProductsStateSetters;