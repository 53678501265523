import { ThemeConstsProps } from "../types";


export const themeConsts_outline = (props: ThemeConstsProps) => {
  const outline = {
    width: {
      // small:  "0px", 
      normal: "2px",
      middle: "3px",
      // large:  "2px",
    },
  };

  return outline;
}
