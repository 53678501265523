import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content } from 'app/ui/states/editor-instruction';

import MarkerComponent from './marker';


interface Props  {
  cellAddr: ContentTypes.CellAddr;
}


export const MarkersComponent: React.FC<Props> = (props: Props) => {
  const { 
    cellAddr, 
  } = props;

  const markersAddrs = useRecoilValue(UIState_Content.cellMarkers_markersAddrs(cellAddr));

  const renderMarkers = () => {
    const MarkersComponents = markersAddrs.map((markerAddr) => {
      const key = ContentTools.getMarkerKey(markerAddr);

      return (
        <MarkerComponent 
          key={key}
          markerAddr={markerAddr} 
        />
      );
    });

    return MarkersComponents;
  }

  return renderMarkers();
}
