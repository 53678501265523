import React      from 'react';
import { useRef } from 'react';

import jtl from 'tools/jtl';

import { Position } from 'app/arch/types';
import { useDocState } from 'app/ui/contexts/document';

import ControlBoxComponent from '../controls/control-box';
import { FRAME_CONTROL_BOX_OFFSET } from '../styles/frame-control-box';
import { FRAME_CONTROL_BOX_SIZE   } from '../styles/frame-control-box';

import { ShapeCalcTriangle } from '../../shapes-calculators/shape-calc-triangle';
import { FrameWrapper } from './styles';
import { FrameBaseProps } from '../types';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const MIN_DISTANCE_BETWEEN_CTRLS = 6;


interface Props extends FrameBaseProps {
}


export const FrameTriangleComponent: React.FC<Props> = (props: Props) => {
  const { 
    widgetAddr,

    scale,

    startPoint, 
    endPoint,
    tailSize,

    widgetStyle,
  } = props;

  const document = useDocState();
  
  const {
    setContent,
  } = useEditorStatesSetters();

  const startDragValue  = useRef<any>(null);
  
  const frameCtrlBoxOffset = FRAME_CONTROL_BOX_OFFSET / scale;
  const frameCtrlBoxSize   = FRAME_CONTROL_BOX_SIZE   / scale;

  const shapeCalc = new ShapeCalcTriangle({
    style: widgetStyle,
    startPoint,
    endPoint
  });
  const c = shapeCalc;

  const handleStyleChanged = (style: any) => {
    document.content.cellImages_image_widget_updateStyle(
      widgetAddr,
      style
    );
  }

  //-----------------
  // Ctrl positions
  //

  const getCtrlHeadCommonPosition = (sideLeft: boolean, _arrowHeadWidth: number) => {
    const arrowHeadAngle = Math.atan2(
      _arrowHeadWidth / 2, 
      c.heightTotal
    );

    const multiplier = sideLeft ? -1 : 1;
    const heightXDelta = Math.abs((tailSize[0] / 2 + c.borderWidth + c.outlineWidth + frameCtrlBoxSize / 2 + frameCtrlBoxOffset) * Math.sin(c.arrowAngle)); 
    const heightYDelta = Math.abs((tailSize[1] / 2 + c.borderWidth + c.outlineWidth + frameCtrlBoxSize / 2 + frameCtrlBoxOffset) * Math.cos(c.arrowAngle)); 
    
    const y = c.heightTotal - (heightXDelta + heightYDelta);
    const x = multiplier * (y * Math.tan(arrowHeadAngle) + frameCtrlBoxSize + frameCtrlBoxOffset);
  
    const position = [x, y] as Position;
    return position;
  }

  const getCtrlHeadCommonPositionRev = (sideLeft: boolean, position: Position) => {
    const width = position[0];
    const height = position[1];
    const multiplier = sideLeft ? -1 : 1;

    const arrowHeadWidth = ((multiplier * width - (frameCtrlBoxSize + frameCtrlBoxOffset)) / height) * c.heightTotal * 2;

    return {
      headWidth:  arrowHeadWidth,
    };
  };
  
  const isTriangleUnderBox = () => {
    const xLen = Math.abs(Math.sin(c.arrowAngle) * c.heightTotal);
    const yLen = Math.abs(Math.cos(c.arrowAngle) * c.heightTotal);
    
    const insideX = tailSize[0] / 2 >= xLen;
    const insideY = tailSize[1] / 2 >= yLen;

    return insideX && insideY;
  }

  const getCtrlHeadRightPosition = (_arrowHeadWidth: number) => {
    return getCtrlHeadCommonPosition(false, _arrowHeadWidth);
  }

  const getCtrlHeadRightPositioRev = (position: Position) => {
    return getCtrlHeadCommonPositionRev(false, position);
  }

  const getCtrlHeadLeftPosition = (_arrowHeadWidth: number) => {
    return getCtrlHeadCommonPosition(true, _arrowHeadWidth);
  }

  const getCtrlHeadLeftPositionRev = (position: Position) => {
    return getCtrlHeadCommonPositionRev(true, position);
  }


  //-----------
  // Dragging
  //

  const ctrl_handleDragStart = () => {
    const arrowHeadWidth  = jtl.css.valueToNumber(widgetStyle.arrowHeadWidth);

    const ctrlHeadLeft  = getCtrlHeadLeftPosition(arrowHeadWidth);
    const ctrlHeadRight = getCtrlHeadRightPosition(arrowHeadWidth);

    startDragValue.current = { 
      ctrlHeadRight, 
      ctrlHeadLeft,
    };
  }

  const ctrl_handleDragStop = () => {
    document.saveUndo();
  }

  const ctrlHeadCommon_handleDrag = (
    deltaMove: Position,
    ctrlStartPosition: Position,
    revCtrlPositionFn: any
  ) => {
    // As this move is taking place when arrow is already rotated,
    // we need to un-rotate it, to calculate delta move in (0, 0) plane.
    const radians = -1 * c.arrowAngle;
    const deltaMoveUnrotated = jtl.geometry.rotateVector(radians, deltaMove);

    const currentPosition = [
      ctrlStartPosition[0] + deltaMoveUnrotated[0],
      ctrlStartPosition[1] + deltaMoveUnrotated[1]
    ] as Position;

    const newProps = revCtrlPositionFn([
      currentPosition[0],
      currentPosition[1],
    ]);

    const headWidth = newProps.headWidth > 0 ? newProps.headWidth : 0;
    handleStyleChanged({arrowHeadWidth: `${headWidth}px`});
    setContent();
  }

  const ctrlHeadRight_handleDrag = (deltaMove: Position) => {
    ctrlHeadCommon_handleDrag(
      deltaMove,
      startDragValue.current.ctrlHeadRight,
      getCtrlHeadRightPositioRev
    );
  }

  const ctrlHeadLeft_handleDrag = (deltaMove: Position) => {
    ctrlHeadCommon_handleDrag(
      deltaMove,
      startDragValue.current.ctrlHeadLeft,
      getCtrlHeadLeftPositionRev
    );
  }

  const getCtrlPositions = () => {
    const ctrlHeadRightPosition = getCtrlHeadRightPosition(c.arrowHeadWidth);
    const ctrlHeadLeftPosition  = getCtrlHeadLeftPosition(c.arrowHeadWidth);
  
    if (ctrlHeadRightPosition[0] <= (frameCtrlBoxSize / 2 + MIN_DISTANCE_BETWEEN_CTRLS)) {
      ctrlHeadRightPosition[0] = frameCtrlBoxSize / 2 + MIN_DISTANCE_BETWEEN_CTRLS;
      ctrlHeadLeftPosition[0]  = -1 * ctrlHeadRightPosition[0];
    }

    return {
      ctrlHeadRightPosition,
      ctrlHeadLeftPosition
    }
  }

  const {
    ctrlHeadRightPosition,
    ctrlHeadLeftPosition
  } = getCtrlPositions();

  const visible = ! isTriangleUnderBox();

  return (
    <>
    {
      visible &&
      <FrameWrapper
        style={{
          left: `${startPoint[0]}px`,
          top:  `${startPoint[1]}px`,
          transform: `rotate(${c.arrowAngle}rad)`,
          transformOrigin: `0px 0px`,
        }}
      >
        <ControlBoxComponent
          scale={scale}
          position={ctrlHeadRightPosition}
          onDragStart={ctrl_handleDragStart}
          onDrag={ctrlHeadRight_handleDrag}
          onDragStop={ctrl_handleDragStop}
        />

        <ControlBoxComponent
          scale={scale}
          position={ctrlHeadLeftPosition}
          onDragStart={ctrl_handleDragStart}
          onDrag={ctrlHeadLeft_handleDrag}
          onDragStop={ctrl_handleDragStop}
        />

      </FrameWrapper>
    }
    </>

  );
}
