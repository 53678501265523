import i18next from 'i18next';
import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';

import Icon from 'app/ui/icons/icons';


export const getConfig = (cmds: any, states: any) => {
  const t = i18next.t;

  const config: MenuConfig = {
    sections: [
      // {
      //   items: [
      //     {
      //       type: MenuItemType.BUTTON,
      //       data: {
      //         dataTest: 'editor-instruction-image-gallery-menu-add-to-cell',
      //         title: t('add to cell'),
      //         iconComponent: IconAdd,
      //         onClick: () => { 
      //           cmds.addToCell();
      //         }
      //       }
      //     },          
      //   ],
      // },
      {
        items: [
          {
            type: MenuItemType.BUTTON,
            data: {
              title: t('delete image'),
              variant: 'delete',
              iconComponent: Icon.Delete,
              onClick: () => { 
                cmds.deleteImage() ;
              }
            }
          },          
        ],
      },
    ]
  }

  return config;
}

