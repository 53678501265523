import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";


const useColumnSelectedSet = () => {
  const document = useDocState();

  const {
    setContentSession
  } = useEditorStatesSetters();

  const selectColumn = (props : {
    columnAddr: Types.ColumnAddr,
    skipStateUpdate?: boolean,
  }) => {
    const {
      columnAddr, 
      skipStateUpdate
    } = props;

    document.contentColumnsSelected.select(columnAddr);

    if (skipStateUpdate) {
      return;
    }
    
    setContentSession();
  }

  return selectColumn;
}

export default useColumnSelectedSet;