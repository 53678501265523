import styled from "styled-components";


export const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: ${props => props.theme.defs.gap.xxsmall};
`;


export const TickWrapper = styled.div`
  position: relative;
  top: 0px;
  /* background: red; */

  display: flex;
  justify-content: flex-end;
  width: 24px;
`;

export const Text = styled.div`
  font-size:   ${props => props.theme.defs.font.size.normal};
  font-weight: ${props => props.theme.defs.font.weight.small};
`;