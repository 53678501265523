import i18next from 'i18next';
import * as docx from "docx";
import jtl from "tools/jtl";

import { Size } from "app/arch/types";
import { Page } from "app/arch/print/page";
import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import { ReleaselogsTools } from "app/arch/editor-instruction/document/states/persistent/releaselogs";
import { ExcelExportConfig } from "app/ui-v2/editor-instruction/__modules/exporters/config";

import AssetsRepo from "../../../parts/assets-repo";
import ExporterBase from "../../parts/exporter-base";

import * as Defaults from '../../defaults';
import * as Tools from '../../tools';


class HeaderInfoExporter extends ExporterBase {
  static PADDING = 4;

  constructor(docState: DocState, assetsRepo: AssetsRepo) {
    super(docState, assetsRepo);
  }

  get content() { return this._docState.content; }

  createSections() {
    const tableHeader = this._createTable();
    const sections = [ tableHeader ];

    return sections;
  }

  private _createTable() {
    const headerRow = this._addHeaderRow();

    const table = new docx.Table({
      layout: docx.TableLayoutType.FIXED,
      borders: this.getBordersNone(),
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows: [ headerRow ]
    });
    
    return table;
  }

  private _addHeaderRow() {
    const repoItem = this._assetsRepo.others.getItem("logo");
    const {
      element,
    } = repoItem;

    if ( ! element ) {
      const msg = `Element for logo is missing`;
      throw new Error(msg);
    }

    const bbox = element.getBoundingClientRect();
    const height = bbox.height;

    const cells = this._createCells();
    const row = new docx.TableRow({ 
      children: cells,
      height: {
        value: Tools.pixelsToDxa(height),
        rule: docx.HeightRule.EXACT,
      }
    });

    return row;
  }

  private _createCells() {
    const cellLogo = this._getLogoCell();
    const cellTitle = this._getTitleCell();
    const cellRevision = this._getRevisionCell();

    const cells = [cellLogo, cellTitle, cellRevision];

    return cells;
  }

  private _getLogoCell() {
    const repoItem = this._assetsRepo.others.getItem("logo");
    const cellWidth = this._getColumnWidth("logo");

    const {
      element,
      png: pngBase64,
    } = repoItem;

    if ( ! element ) {
      const msg = `Element for logo is missing`;
      throw new Error(msg);
    }

    const bbox = element.getBoundingClientRect();
    const width  = bbox.width;
    const height = bbox.height;

    const logo = new docx.ImageRun({
      type: "png",
      data: pngBase64,
      transformation: { width, height },
    });

    const docxCell = new docx.TableCell({
      borders: this.getBordersFull(),
      width: {
        size: cellWidth,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [new docx.Paragraph({
        alignment: docx.AlignmentType.LEFT,
        children: [logo]
      })]
    });

    return docxCell;
  }

  private _getTitleCell() {
    const cellWidth = this._getColumnWidth("title");
    const metaData = this._docState.metaData;

    const titleOpt = Defaults.getTitleTextOptions({
      text: metaData.getTitle()
    });

    const descriptionOpt = Defaults.getDescriptionTextOptions({
      text: metaData.getDescription()
    })

    const titleText = new docx.TextRun(titleOpt);
    const descriptionText = new docx.TextRun(descriptionOpt);

    const titlePara = new docx.Paragraph({
      alignment: docx.AlignmentType.LEFT,
      children: [titleText]
    });

    const descriptionPara = new docx.Paragraph({
      alignment: docx.AlignmentType.LEFT,
      children: [descriptionText]
    });

    const padding = Tools.getPadding(HeaderInfoExporter.PADDING);

    const docxCell = new docx.TableCell({
      margins: padding,
      borders: this.getBordersFull(),
      width: {
        size: cellWidth,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [titlePara, descriptionPara]
    });

    return docxCell;
  }
  
  private _getRevisionCell() {
    const cellWidth = this._getColumnWidth("revision");
    const instruction = this._docState.instruction;
    const revision = `${instruction.getRevision()}`;
    const releaseDateIso = ReleaselogsTools.getReleaseDateIso();
    const releaseDate = jtl.date.toYYYYMMDD(new Date(releaseDateIso))[0];
    const t = i18next.t;

    const revisionOpt = Defaults.getTextOptions({
      text: `${t("document release info, revision")} `,
    });

    const revisionValueOpt = Defaults.getTextOptions({
      text: revision,
      bold: true,
    });

    const dateOpt = Defaults.getTextOptions({
      text: `${t("document release info, date")} `,
    });

    const dateValueOpt = Defaults.getTextOptions({
      text: releaseDate,
      bold: true,
    });

    const revisionText = new docx.TextRun(revisionOpt);
    const revisionValueText = new docx.TextRun(revisionValueOpt);
    const dateText = new docx.TextRun(dateOpt);
    const dateValueText = new docx.TextRun(dateValueOpt);

    const revisionPara = new docx.Paragraph({
      alignment: docx.AlignmentType.LEFT,
      children: [revisionText]
    });

    const revisionValuePara = new docx.Paragraph({
      alignment: docx.AlignmentType.LEFT,
      children: [revisionValueText]
    });

    const datePara = new docx.Paragraph({
      alignment: docx.AlignmentType.LEFT,
      children: [dateText]
    });

    const dateValuePara = new docx.Paragraph({
      alignment: docx.AlignmentType.LEFT,
      children: [dateValueText]
    });

    const revCellWidth = 50;

    const cellRevision = new docx.TableCell({
      borders: this.getBordersNone(),
      width: {
        size: revCellWidth,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [revisionPara]
    });

    const cellRevisionValue = new docx.TableCell({
      borders: this.getBordersNone(),
      width: {
        size: revCellWidth,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [revisionValuePara]
    });

    const cellDate = new docx.TableCell({
      borders: this.getBordersNone(),
      width: {
        size: revCellWidth,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [datePara]
    });

    const cellDateValue = new docx.TableCell({
      borders: this.getBordersNone(),
      width: {
        size: revCellWidth,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [dateValuePara]
    });

    const cellsRevision = [cellRevision, cellRevisionValue];
    const cellsDate = [cellDate, cellDateValue];
    
    const rowRevision = new docx.TableRow({children: cellsRevision});
    const rowDate = new docx.TableRow({children: cellsDate});

    const revTable = new docx.Table({
      borders: this.getBordersNone(),
      rows: [rowRevision, rowDate]
    });

    const padding = Tools.getPadding(HeaderInfoExporter.PADDING);

    const docxCell = new docx.TableCell({
      margins: padding,
      borders: this.getBordersFull(),
      width: {
        size: cellWidth,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [revTable]
    });

    return docxCell;
  }

  private _getColumnWidth(column: "logo" | "title" | "revision") {
    const pageLayout  = this._docState.viewsCommon.getPageLayout();
    const pageMargins = this._docState.viewsCommon.getPageMargins();
    
    const contentSize = Page.getBodySizePx(
      pageLayout.format, 
      pageLayout.orientation, 
      pageMargins
    ) as Size;
  
    const totalWidth = contentSize[0];
    const repoItem = this._assetsRepo.others.getItem("logo");

    const {
      element,
    } = repoItem;

    if ( ! element ) {
      const msg = `Element for logo is missing`;
      throw new Error(msg);
    }

    const bbox = element.getBoundingClientRect();

    const imgWidth = bbox.width;
    const revWidth = 140;

    const Map = {
      "logo": () => {
        return imgWidth / totalWidth * 100;
      },
      "title": () => {
        const width = (totalWidth - (imgWidth + revWidth));
        return width / totalWidth * 100;
      },
      "revision": () => {
        return revWidth / totalWidth * 100;
      },
    }

    const getFn = Map[column];
    return getFn();
  }
}


export default HeaderInfoExporter;