import React from 'react';
import { useRecoilValue } from 'recoil';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { Input } from './styles';


export const InputStyledComponent = React.forwardRef((
  props: React.InputHTMLAttributes<HTMLInputElement>, 
  ref: React.Ref<HTMLInputElement>
) => {
  const sectionsCSS = useRecoilValue(UIState_Content.sectionsNamesCSS);

  return (
    <Input
      {...props} 
      ref={ref} 
      style={sectionsCSS}
    />
  );
});
