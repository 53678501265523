import React, { useRef } from 'react';
import { PanelControlsItemSectionControl_ListConfig as Config} from '../../config';

import { Panel       } from './styles';
import { Item        } from './styles';
import { ItemWrapper } from './styles';
import { Items       } from './styles';


interface Props {
  config: Config;
}


export const ControlListComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;
  const initialIdx = useRef<any>(config.valueIdx);

  const handleItemSelected = (idx: number) => {
    config.onValueSelected?.(idx);
    initialIdx.current = idx
  }

  const handleItemPointerEnter = (idx: number) => {
    config.onValueEnter?.(idx);
  }

  const handleItemPointerLeave = (idx: number) => {
    config.onValueLeave?.(idx, initialIdx.current);
  }

  const renderItem = (idx: number) => {
    return (
      <ItemWrapper
        key={idx}
      >
        <Item
          selected={config.valueIdx === idx}
          onClick={() => handleItemSelected(idx) }
          onPointerEnter={() => handleItemPointerEnter(idx) }
          onPointerLeave={() => handleItemPointerLeave(idx) }
        >
          { config.values[idx]}
        </Item>
      </ItemWrapper>
    );
  }

  const renderItems = () => {
    const stylesComponent = config.values.map((value, idx: number) => {
      return renderItem(idx);
    })

    return (
      <Items>
        { stylesComponent }
      </Items>
    );
  }
  
  return (
    <Panel>
      { renderItems() }
    </Panel>
  );
}
