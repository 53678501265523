import { ContentTools } from "../../../../document/states/persistent/content";
import { ContentTypes } from "../../../../document/states/persistent/content";
import RepoBase from "../repo-base";


class CellImagsRepo extends RepoBase<ContentTypes.ImageAddr> {
  getKey(imageAddr: ContentTypes.ImageAddr): string {
    return ContentTools.getImageKey(imageAddr);
  };

  tag(): string {
    return "Images";
  };
}


export default CellImagsRepo;