import React from 'react';
import { useRecoilValue } from 'recoil';

import { SideControlsTypes }   from 'lego/components/side-controls';
import SidePanelBaseComponent  from 'lego/components/side-controls/side-panel';
import { PanelControlsConfig } from 'lego/components/panel-controls/config';

import { ReleaselogsSessionTypes as Types } from 'app/arch/editor-instruction/document/states/sessions/releaselogs-session';

import { UIState_ReleaselogsSession } from 'app/ui/states/editor-instruction';
import usePagePanelConfig      from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-panel-config';
import usePanelConfigTable     from 'app/ui-v2/editor-instruction/panel-configs/use-panel-config-table';
import usePanelConfigColumn    from './configs/use-panel-config-columns';
import usePanelDocHeaderConfig from '../../hook/panel-doc-header/use-panel-doc-header-config';


type Props = SideControlsTypes.PropLeftOrRight;


export const PanelComponent: React.FC<Props> = (props: Props) => {
  const {
    left,
    right,
  } = props;

  const panel = useRecoilValue(UIState_ReleaselogsSession.panelDocument);

  const getConfigPage      = usePagePanelConfig();
  const getConfigDocHeader = usePanelDocHeaderConfig();
  const getConfigTable     = usePanelConfigTable ();
  const getConfigColumn    = usePanelConfigColumn();

  const Configs: {[key in Types.PanelDocumentItem]: () => PanelControlsConfig} = {
    [Types.PanelDocumentItem.DOC_HEADER] : getConfigDocHeader,
    [Types.PanelDocumentItem.PAGE      ] : getConfigPage,
    [Types.PanelDocumentItem.TABLE     ] : getConfigTable,
    [Types.PanelDocumentItem.COLUMN    ] : getConfigColumn,
  }

  const getConfig = Configs[panel.selected];
  const config = getConfig();

  return (
    <SidePanelBaseComponent
      config={config}
      visible={panel.visible}

      left={left}
      right={right}      
    />
  );
}
  
