import styled from 'styled-components';
import DraggedBase from 'lego/styles/dragged';


interface Props {
  width: number, 
  height: number,
  left: number, 
  top: number,
}


const Dragged = styled(DraggedBase).attrs<Props>(props => ({
  style: {
    left:   `${props.left}px`,
    top:    `${props.top}px`,
  }
}))<Props>`
  width:  ${props => `${props.width}px` };
  height: ${props => `${props.height}px`};
`;


export default Dragged;