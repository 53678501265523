import produce from 'immer';
import * as State from './state';
import * as Types from './types';


class Draggers {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }


  /**
   * Setters
   */

  setEnabled(enabled: boolean) {
    this._state = produce(this._state, draft => {
      draft.enabled = enabled;
    });
  }


  /**
   * Getters
   */

  getEnabled(): boolean { 
    return State.getEnabled(this._state);
  }
}

export default Draggers;