import React from 'react';
import { PageItemsProps } from '../types';

import FieldsComponent          from './fields';
import ColumnsResizersComponent from './columns-resizers';

import { Anchor } from './styles';
import { ColumnsResizersDetacher } from './styles';


interface Props extends PageItemsProps {
}


export const PageItemDocCustomFieldsComponent: React.FC<Props> = (props: Props) => {

  return (
    <Anchor>
      <ColumnsResizersDetacher>
        <ColumnsResizersComponent debug={false} />
      </ColumnsResizersDetacher>

      <FieldsComponent />
    </Anchor>
  );
}
