import { useEffect }  from 'react';
import { query }      from 'app/arch/backend';
import { useQueryV2 } from 'app/arch/backend/use-query-v2';
import { SubscriptionsTypes } from 'app/arch/app/user/states/subscriptions';


type OnDoneFn = (subscriptions: SubscriptionsTypes.SubscriptionsRaw) => void;


export interface Props {
  onDone?: OnDoneFn;
  onError?: (error: string) => void;
}

const useUserSubscriptionsFetch = (props: Props) => {
  const {
    onDone,
    onError,
  } = props;

  const { 
    data: userSubscriptionsData,
    error: userSubscriptionsError,
    refetch: userSubscriptionsRefetch,
  } = useQueryV2({
    query: query.getSubscriptions(),
  });

  useEffect(() => {
    if ( ! userSubscriptionsData ) {
      return;
    }

    const subscriptions = userSubscriptionsData.subscriptions;
    onDone?.(subscriptions);
  }, [userSubscriptionsData]);


  useEffect(() => {
    if ( ! userSubscriptionsError) {
      return;
    }

    console.error(userSubscriptionsError);
    onError?.(userSubscriptionsError.message)
  }, [userSubscriptionsError]);


  0
  return userSubscriptionsRefetch;
}
  

export default useUserSubscriptionsFetch;