import { useState }  from 'react';
import { useEffect } from 'react';

import { query }       from 'app/arch/backend';
import { useQueryV2 }  from 'app/arch/backend/use-query-v2';
import { Instruction } from 'app/arch/backend/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';


const useDocsFetch = (props: {
  repoId: number,
  skip: boolean,
  skipMsgs?: boolean,
}) => {

  const {
    repoId,
    skip,
    skipMsgs
  } = props;

  const t = useTranslations();
  const [loaded, setLoaded] = useState(false);
  const [documents, setDocumens] = useState<Instruction[]>([]);
  
  useEffect(() => {
    setLoaded(false);
    setDocumens([]);
  }, [repoId]);

  const msgs = (
    skipMsgs ? 
    {} : 
    {
      onStart: { msg: t("loading documents") },
      onEnd: { msg: t("documents loaded") }
    }
  );

  const { 
    error: loadDocsError, 
    data: loadDocsData
  } = useQueryV2({
    query: query.getInstructions(),
    variables: { repoId },
    msgs,
    skip,
  });


  /**
   * Query data
   */
  useEffect(() => {
    if ( ! loadDocsData ) {
      return;
    }

    const docs = loadDocsData.instructions;
    const docsSorted = sortByTitle(docs, 'asc');

    setDocumens(docsSorted);
    setLoaded(true);

  }, [loadDocsData]);


  /**
   * Query error
   */
  useEffect(() => {
    if ( ! loadDocsError) {
      return;
    }

    setDocumens([]);
    setLoaded(true);
  }, [loadDocsError]);


  const sortByTitle = (
    data: Instruction[], 
    order: "asc" | "desc"
  ): Instruction[] => {
    const sortedData = [...data];

    sortedData.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();

      if (order === "asc") {
        return titleA.localeCompare(titleB);
      } else if (order === "desc") {
        return titleB.localeCompare(titleA);
      } else {
        throw new Error("Invalid sorting order. Use 'asc' or 'desc'.");
      }
    });

    return sortedData;
  };

  return {
    documents,
    loaded
  }
}

export default useDocsFetch;