import useControlsDraggers from "./use-controls-draggers";
import useControlsStateSetters from "./use-controls-state-setters";


const useControlsDraggersEnabledSet = () => {
  const draggers = useControlsDraggers();
  const {
    setControlsDraggers
  } = useControlsStateSetters();

  const setEnabled = (enabled: boolean) => {
    draggers.setEnabled(enabled);
    setControlsDraggers();
  }

  return setEnabled;
}


export default useControlsDraggersEnabledSet;