import { useRecoilValue } from 'recoil';
import { EditorImageSessionTypes } from 'app/arch/editor-instruction/document/states/sessions/editor-image-session';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';


const useState = () => {
  const overlayerView = useRecoilValue(UIState_EditorImageSession.viewDefinerOverlayerShowType);
  const overlayerSizeLocked = useRecoilValue(UIState_EditorImageSession.viewDefinerOverlayerResizerLocked);
  const autoFit = useRecoilValue(UIState_EditorImageSession.viewDefinerAutoFit);

  const isAutoFitAutomatic = ( autoFit.autoMode === EditorImageSessionTypes.ViewDefinerAutomationMode.AUTOMATIC);

  return {
    autoFit,
    isAutoFitAutomatic,
    overlayerView,
    overlayerSizeLocked,
  }
}


export default useState;