import { getStyleObjectFromCSS } from "@lexical/selection";
import { 
  $isTextNode, 
  LexicalNode, 
  RangeSelection, 
  TextNode 
} from "lexical";


export function $processSelectedNodes(
  selection: RangeSelection,
  nodeChecker: (node: LexicalNode) => boolean,
  nodeProcessor: (node: LexicalNode) => string | number,
) {
  const nodes = selection.getNodes();
  const anchor = selection.anchor;
  const focus = selection.focus;
  const isBackward = selection.isBackward();
  const endOffset = isBackward ? focus.offset : anchor.offset;
  const endNode = isBackward ? focus.getNode() : anchor.getNode();

  const values: (string | number)[] = [];

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    // if no actual characters in the end node are selected, we don't
    // include it in the selection for purposes of determining style
    // value
    if (i !== 0 && endOffset === 0 && node.is(endNode)) {
      continue;
    }

    if (nodeChecker(node)) {
      const nodeValue = nodeProcessor(node);
      values.push(nodeValue);
    }
  }

  return values;
}


export function $getSelectedTextNodesCssProperty(
  selection: RangeSelection,
  styleProperty: string,
  defaultValue: string,
) {
  let selectionStyle = '';
  if (selection.style !== '') {
    const css = selection.style;
    const styleObject = getStyleObjectFromCSS(css);

    if (styleObject !== null && styleProperty in styleObject) {
      selectionStyle = styleObject[styleProperty];
    }
  }

  const values = $processSelectedNodes(
    selection,
    (node: LexicalNode) => {
      return $isTextNode(node);
    },
    (node: LexicalNode) => {
      const nodeStyleValue = $getNodeCssProperty(
        node as TextNode,
        styleProperty,
        defaultValue,
      );
      return nodeStyleValue;
    }
  )

  return {
    nodesStyles: values,
    selectionStyle: selectionStyle
  };
}


export function $getSelectedTextNodesCssPropertyNormalized(
  selection: RangeSelection,
  styleProperty: string,
  defaultValue: string,
  defaultWhenDiffrent: boolean,
): string | null {
  const { 
    nodesStyles,
    selectionStyle
  } = $getSelectedTextNodesCssProperty(selection, styleProperty, defaultValue);
  if (nodesStyles.length === 0) {
    return selectionStyle;
  }
  else {
    const areStylesSame = nodesStyles.every(val => val === nodesStyles[0]);
    if (areStylesSame) {
      return nodesStyles[0] as string;
    }
    else {
      if (defaultWhenDiffrent) {
        return defaultValue;
      }
      else {
        return null;
      }
    }
  }
}


function $getNodeCssProperty(
  node: TextNode,
  styleProperty: string,
  defaultValue: string,
): string {
  const css = node.getStyle();
  const styleObject = getStyleObjectFromCSS(css);

  if (styleObject !== null) {
    return styleObject[styleProperty] || defaultValue;
  }

  return defaultValue;
}
