
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import Icon from "app/ui/icons/icons";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useFunctions from "../use-functions/use-functions";

import * as Types from '../types';


const useItems = (states: Types.States) => {
  const t = useTranslations();
  const fns = useFunctions(states);

  const uploadLogo = () => ({
    type: PanelControlsItemSectionItemType.BUTTON,
    title: t('logo image, upload'),
    Icon: Icon.Upload.Disk,
    onClick: () => {
      fns.handleLogoUpload();
    },
  });

  const removeLogo = () => ({
    type: PanelControlsItemSectionItemType.BUTTON,
    title: t('logo image, delete'),
    Icon: Icon.Delete,
    onClick: () => {
      fns.handleLogoDelete(states.logoImage);
    },
  });

  const __scaleLogo = () => ({
    type:  PanelControlsItemSectionItemType.SLIDER,
    step:  1,
    controlLess: true,
    min:   fns.getLogoScaleMin(),
    max:   fns.getLogoScaleMax(),
    value: fns.getLogoScale(),
    onChangeStart: () => {
      fns.handleLogoRescaleStart();
    },
    onChange: (value: number) => { 
      fns.handleLogoRescale(value);
    },
    onChangeDone: (value: number) => {
      fns.handleLogoRescaleDone(value);
    }
  });

  const __smartFitLogo = () => ({
    type: PanelControlsItemSectionItemType.BUTTON,
    width: 'unset',
    tooltip: t('logo image, smart align'),
    Icon: Icon.Smart,
    onClick: () => {
      fns.handleLogoSmartFit();
    },
  });

  const rescale = () => {
    const item = {
      title: t('logo image, size'),
      type: PanelControlsItemSectionItemType.ITEMS_MIX,
      items: [
        __scaleLogo(), 
        __smartFitLogo()
      ]
    }

    return item;
  }


  return {
    uploadLogo,
    removeLogo,
    rescale,
  }
}

export default useItems;