import i18next from 'i18next';
import { SideToolbarConfig } from 'lego/components/side-controls/side-toolbar/config';
import { UserPanelTypes } from 'app/arch/home-page-user/states/user-panel';

import Icon from 'app/ui/icons/icons';


export const getConfig = (cmds: any, states: any) => {
  const t = i18next.t;

  const config: SideToolbarConfig = {
    items: [
      {
        tooltip: t('user settings'),
        selected: states.panelVisible && states.panelType === UserPanelTypes.PanelItem.USER_PROFILE,
        onClick: cmds.handleUserProfile,
        Icon: Icon.User,
      },
      {
        tooltip: t('payments'),
        selected: states.panelVisible && states.panelType === UserPanelTypes.PanelItem.BILLING,
        onClick: cmds.handleBilling,
        Icon: Icon.Billing,
      },    
      {
        tooltip: t('theme'),
        selected: states.panelVisible && states.panelType === UserPanelTypes.PanelItem.THEME,
        onClick: cmds.handleTheme,
        Icon: Icon.Theme,
      }
    ]
  };

  return config;
}