import i18next from 'i18next';
import Logger from 'libs/debug';
import { useRef }   from 'react';
import { useEffect } from 'react';
import { i18nTools } from '../tools';


const CHECK_TRANSLATION_READY_DELAY = 5;


const usei18nInit = (props: {
  onDone: () => void 
}) => {
  const {
    onDone
  } = props;

  const log = Logger.getI18next();
  const transCheckFn = useRef<any>(null);

  useEffect(() => {
    i18nTools.init();
    log.info(`i18next starting checker, init status: ${i18next.isInitialized}`);

    transCheckFn.current = setInterval(()=> {
      if ( ! i18next.isInitialized ) {
        log.info(`i18next, init status: ${i18next.isInitialized}`);
        return;
      }

      log.info(`i18next, has initialized`);
      clearInterval(transCheckFn.current);
      transCheckFn.current = null;
      onDone();
    }, CHECK_TRANSLATION_READY_DELAY);

    return () => {
      if ( transCheckFn.current !== null ) {
        clearInterval(transCheckFn.current);
        transCheckFn.current = null;
        log.info(`i18next stopping checker, init status: ${i18next.isInitialized}`);
      }
    }
  }, []);
}

export default usei18nInit;