import React from 'react';

import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useDocState }          from 'app/ui/contexts/document';
import useEditorStatesSetters   from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import HLS_NameEditComponent    from 'app/ui-v2/editor-instruction/views/shared/components/name-edit';
import InputStyledComponent     from './input-styled';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellNameEditComponent: React.FC<Props> = (props: Props) => {
  const {
    pageColumnAddr,
    children,
  } = props;
  
  const document = useDocState();
  const columnProps = document.headerFields.getColumnProps(pageColumnAddr);

  const { 
    setHeaderFields
  } = useEditorStatesSetters();

  const handleColumnSelected = () => {
  }

  const handleColumnNameEditDone = (columnName: string) => {
    const update = {
      name: columnName
    };
    
    document.headerFields.updateColumn(
      pageColumnAddr, 
      update
    );
    setHeaderFields();
    document.saveUndo();
  }

  return (
    <HLS_NameEditComponent
      name={columnProps.name}
      onEditStart={handleColumnSelected}
      onEditDone={handleColumnNameEditDone}
      Input={InputStyledComponent}
    >
      { children }
    </HLS_NameEditComponent>
  );
}
