import React, { useState } from "react";

import DraggerComponent from "lego-v2/dragger/ui";
import { DraggerUpdate } from "lego-v2/dragger/arch";

import { Position } from "app/arch/types";
import Icon from "app/ui/icons/icons";

import { Panel }  from "./styles";
import { Topbar } from "./styles";
import { Title }  from "./styles";
import { Button } from "./styles";


interface Props {
  title?: string;
  position?: Position;

  onPosition?: (position: Position) => void;
  onClose?: () => void;

  children?: React.ReactNode;
}


export const FloatingPanelComponent: React.FC<Props> = (props: Props) => {
  const { 
    title,
    onPosition,
    onClose,

    children,
  } = props;
  
  const IconClose = Icon.Close;
  const initPosition: Position = (props.position || [0, 0]);
  const [position, setPosition] = useState<Position>(initPosition);


  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose?.();
  }

  const handleDraggerUpdateStart = () => { 
  }

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    setPosition(update.position);
  }

  const handleDraggerUpdateDone = (update: DraggerUpdate) => {
    handleDraggerUpdate(update);
    onPosition?.(update.position);
   }


  return (
    <Panel
      $left={position[0]}
      $top={position[1]}
    >
      <DraggerComponent
        position={position}
        onUpdateStart={handleDraggerUpdateStart}
        onUpdate={handleDraggerUpdate}
        onUpdateDone={handleDraggerUpdateDone}
      >
        <Topbar>
          { title && <Title>{ title }</Title> }
          <Button onClick={handleClose}>
            <IconClose />
          </Button>
        </Topbar>
      </DraggerComponent>

      { children }

    </Panel>
  );
}
