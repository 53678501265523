import React from 'react';
import { useRecoilValue } from 'recoil';

import { PanelType } from 'lego/components/side-controls/types';
import { SideControlsTypes } from 'lego/components/side-controls';
import SidePanelBaseComponent from 'lego/components/side-controls/side-panel';

import { ContentSessionTypes as Types } from 'app/arch/editor-instruction/document/states/sessions/content-session';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';

import usePanelConfigMarkers from '../configs/use-panel-config-markers';
import usePanelConfigImages  from '../configs/use-panel-config-images';


type Props = SideControlsTypes.PropLeftOrRight;


export const SidePanelComponent: React.FC<Props> = (props: Props) => {
  const {
    left, 
    right
  } = props;

  const panel = useRecoilValue(UIState_ContentSession.panelAssets);

  const getConfigImages  = usePanelConfigImages();
  const getConfigMarkers = usePanelConfigMarkers();

  const Configs: {[key in Types.PanelAssetsItem]: any} = {
    [Types.PanelAssetsItem.IMAGE]:  getConfigImages,
    [Types.PanelAssetsItem.MARKER]: getConfigMarkers,
  }

  const getConfig = Configs[panel.selected];
  const config = getConfig();

  // TODO
  // Panel assets should have flexible width
  return (
    <SidePanelBaseComponent
      config={config}
      visible={panel.visible}
      panelType={PanelType.NARROW_190}

      left={left}
      right={right}
    />
  );
}

