import { useEffect } from "react";


interface Props {
  enabled: boolean;
  onClick: (event: MouseEvent) => void;
}


const useGClick = (props: Props) => {
  const {
    enabled,
    onClick,
  } = props;

  const handleClick = (event: MouseEvent) => {
    onClick(event);
  };

  useEffect(() => {
    if ( ! enabled ) { return; }
    document.addEventListener('click', handleClick);

    return () => {
      if ( ! enabled ) { return; }
      document.removeEventListener('click', handleClick);
    };
  }, [enabled]);

}

export default useGClick;