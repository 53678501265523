import React from 'react';
import NavbarComponent from 'app/ui-v2/home-page/hls/navbar2';
import useConfigMenu from './config/use-config-menu';


interface Props {
}


export const TopMenuComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const getMenuConfig = useConfigMenu();
  const config = getMenuConfig();

  return (
    <NavbarComponent config={config} />
  );
}

