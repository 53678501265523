import i18next from 'i18next';

import { mutation    }       from 'app/arch/backend';
import { useMutation }       from 'app/arch/backend/use-mutation';
import { Language }          from 'app/arch/types';
import { LanguageCodes }     from 'app/arch/types';
import { UserSettingsTypes } from 'app/arch/app/user';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDialogCustom } from 'app/ui/components/editor-instruction/dialogs/dialog-custom/use-dialog-custom';
import useUserSettings     from 'app/ui-v2/app/__modules/user/hooks/use-user-settings';
import useUserSettingsSave from 'app/ui-v2/app/__modules/user/hooks/use-user-settings-save';
import useUserStateSetters from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';



const useFunctions = () => {
  const t = useTranslations();
  const { show: showDialog } = useDialogCustom();

  const userSettings = useUserSettings();
  const saveUserSettings = useUserSettingsSave();
  const {
    setUserSettings
  } = useUserStateSetters()


  const mutationMsgs = {
    onStart: { msg:  t("sending reset password link"), },
    onEnd:   { msg: t("reset password link sent") }
  }

  const { 
    mutation: createUserPasswordToken,
    data:     createUserPasswordTokenData,
  } = useMutation( mutation.createUserPasswordToken, mutationMsgs );

  const updateUser = (
    update: UserSettingsTypes.UserUpdate
  ) => {
    userSettings.updateUser(update);
    setUserSettings();
    saveUserSettings();
  }

  const updateUserLanguage = (language: string) => {
    const lang = language.toLowerCase() as Language;
    userSettings.updateUser({language: lang});
    setUserSettings();
    saveUserSettings();

    const langCode = LanguageCodes[lang];
    i18next.changeLanguage(langCode);
  }

  const updateCompany = (
    update: UserSettingsTypes.CompanyUpdate
  ) => {
    userSettings.updateCompany(update);
    setUserSettings();
    saveUserSettings();
  }

  const sendPasswordReset = (email: string) => {
    createUserPasswordToken({ 
      variables: { email }
    });

    showDialog({
      title: t('password request sent - title'),
      message: t('password request sent - info'),
      buttons: [
        {
          text: t('ok'),
          onClick: () => {}
        }
      ] 
    })
  }

  return {
    updateUser,
    updateUserLanguage,
    updateCompany,
    
    sendPasswordReset
  }
}

export default useFunctions;
