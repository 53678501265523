import styled from "styled-components";


export const PaddingWrapper = styled.div<{$disabled: boolean}>`
  opacity:        ${props => ! props.$disabled ? 1 : props.theme.defs.opacity.disabledMore};
  pointer-events: ${props => ! props.$disabled ? 'all' : 'none'};
  padding:        ${props => props.theme.defs.padding.large};

  box-sizing: border-box;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;