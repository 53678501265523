import React from 'react';
import DocMarkersViewComponent  from './doc-markers-view';
import DocMarkersProbeComponent from './doc-markers-probe';


interface Props {
}


export const DocMarkersComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <DocMarkersProbeComponent>
      <DocMarkersViewComponent />
    </DocMarkersProbeComponent>
  );
}
  
