import { useDocState } from 'app/ui/contexts/document';


const usePrintoutViewState = () => {
  const docState = useDocState();
  const printoutState = docState.printoutView;
  
  return printoutState;
}


export default usePrintoutViewState;