import React from 'react';

import { CellPropsBase } from '../types';

import ColumnCssComponent              from '../parts/column-css';
import CellOverflowClipperComponent    from '../parts/cell-overflow-clipper';
import CellSelectedBorderComponent     from '../parts/cell-selected-border';
import CellSelectComponent             from '../parts/cell-select';
import CellsLineSelectedComponent      from '../parts/cells-line-selected';
import CellsLineDragOverComponent      from '../parts/cells-line-drag-over';
import CellKeybindingsComponent        from '../parts/cell-keybindings';
import RowDropComponent                from '../parts/row-drop';
import ColumnDropComponent             from '../parts/column-drop';
import CellMarkersDropComponent        from './cell-markers-drop';
import CellMarkersContextMenuComponent from './cell-markers-context-menu';
import MarkersComponent                from './markers';


interface Props extends CellPropsBase {
}


export const CellMarkersComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;

  return (
    <CellSelectedBorderComponent cellAddr={cellAddr} >
      <CellOverflowClipperComponent>
        <CellsLineSelectedComponent cellAddr={cellAddr} >
          <CellsLineDragOverComponent cellAddr={cellAddr} >
            <CellSelectComponent cellAddr={cellAddr} >
              <CellKeybindingsComponent cellAddr={cellAddr} >
                <CellMarkersContextMenuComponent cellAddr={cellAddr} >
                  <RowDropComponent cellAddr={cellAddr} >
                    <ColumnDropComponent cellAddr={cellAddr} >
                      <CellMarkersDropComponent cellAddr={cellAddr}>
                        <ColumnCssComponent cellAddr={cellAddr} >
                          <MarkersComponent cellAddr={cellAddr} />
                        </ColumnCssComponent>
                      </CellMarkersDropComponent>
                    </ColumnDropComponent>          
                  </RowDropComponent>
                </CellMarkersContextMenuComponent>
              </CellKeybindingsComponent>
            </CellSelectComponent>
          </CellsLineDragOverComponent>
        </CellsLineSelectedComponent>
      </CellOverflowClipperComponent>
    </CellSelectedBorderComponent>
  );
}
