import { Position  } from "app/arch/types";
import { DeltaMove } from "app/ui/hooks";

export enum SmartLineType {
  HORIZONTAL = 'horizontal',
  VERTICAL   = 'vertical'
};

export type SmartLineRaw = {
  x0: number,
  y0: number,
  x1: number,
  y1: number
};

export type SmartLinesRaw = SmartLineRaw[];


export type OrientedSmartLinesRaw = {
  horizontal: SmartLinesRaw,
  vertical:   SmartLinesRaw
}

export type StickyLineRaw = {
  srcLine: SmartLineRaw,
  dstLine: SmartLineRaw,
}

export type StickyLinesRaw = {
  horizontal: StickyLineRaw | null,
  vertical:   StickyLineRaw | null,
}


export const StickyLinesApply = (
  stickyLines: StickyLinesRaw,
  position_:   Position, 
  deltaMove_:  DeltaMove
) => {
  let position:  Position  = [...position_];
  let deltaMove: DeltaMove = {...deltaMove_};

  if (stickyLines.horizontal) {
    const dstLine = stickyLines.horizontal.dstLine;
    const srcLine = stickyLines.horizontal.srcLine;
    
    const missAligment = dstLine.y0 - srcLine.y0;
    position[1] += missAligment;
    deltaMove.y += missAligment;
  }

  if (stickyLines.vertical) {
    const dstLine = stickyLines.vertical.dstLine;
    const srcLine = stickyLines.vertical.srcLine;
    
    const missAligment = dstLine.x0 - srcLine.x0;
    position[0] += missAligment;
    deltaMove.x += missAligment;
  }

  return { position, deltaMove };
}