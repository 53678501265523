import styled from 'styled-components';

import PositionerBase from "../styles/positioner";
import CenterBase from "../styles/center";
import ClipBase from "../styles/clip";


export const Positioner = styled(PositionerBase)`
`;

export const Center = styled(CenterBase)`
`;


export const Clip = styled(ClipBase)`
`;


export const DotsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: inherit;
`;

export const Dot = styled.div`
  border-style: solid;
  outline-style: solid;
`;
