import { useRef } from 'react';
import useConfigMenu from './config/use-config-menu';
import MenubarComponent from 'lego-v2/menubar';
import { Menubar } from './styles';


interface Props {}


export const TopMenuComponent: React.FC<Props> = (props: Props) => {
  const closeMenuRef = useRef<(() => void) | null>(null);
  const getMenuConfig = useConfigMenu(closeMenuRef);

  const config = getMenuConfig();

  return (
    <MenubarComponent 
      config={config}
      closeMenu={closeMenuRef}
      MenuBar={Menubar}
    />
  );
}
