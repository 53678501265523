import jtl from 'tools/jtl';
import produce from 'immer';

import { Position } from 'app/arch/types';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';


export interface ArrowState {
  //-------------------------------
  // Acctual arrow state
  body: {
    startPoint: Position;
    endPoint:   Position;
  },

  //-------------------------------
  // This cache is needed
  // for calculations during update. It will
  // have initial value stored, when updates
  // have started.
  
  cache: {
    rotation: number | null,
    
    head: {
      // Click offset from arrow head / arrow start point.
      clickOffset: Position | null,
      clickPoint:  Position | null,
    },

    body: {
      startPoint: Position | null,
      endPoint:   Position | null,
    },

    tail: {
      // Click offset from arrow tail / arrow end point.
      clickOffset: Position | null,
      clickPoint:  Position | null,
    },
  },

  tmp: Position | null


}



export const arrowStateReducer = (arrowState: ArrowState, action: any) => {

  switch (action.type) {

    //--------------------------
    //
    // Head moving
    //
    //--------------------------

    case 'arrowHeadPositionUpdate_start' : {
      return produce(arrowState, draft => {

        const rotation = jtl.geometry.calculateAngle(
          ...draft.body.startPoint,
          ...draft.body.endPoint
        );

        const clickOffsetRotated = jtl.geometry.rotateVector(
          rotation, 
          action.payload.clickOffset
        );
        
        const clickPoint = [
          draft.body.startPoint[0] + clickOffsetRotated[0], 
          draft.body.startPoint[1] + clickOffsetRotated[1]
        ] as Position;

        draft.cache.head.clickOffset = action.payload.clickOffset;
        draft.cache.head.clickPoint  = clickPoint;

        draft.cache.body.startPoint  = draft.body.startPoint;
        draft.cache.body.endPoint    = draft.body.endPoint;
        draft.cache.rotation         = rotation;
      });
    }

    case 'arrowHeadPositionUpdate_update' : {
      return produce(arrowState, draft => {
        const deltaMove   = action.payload.delta;

        const clickOffset = draft.cache.head.clickOffset !;
        const clickPoint  = draft.cache.head.clickPoint as Position;

        const clickPointMoved = [
          clickPoint[0] + deltaMove.x,
          clickPoint[1] + deltaMove.y,
        ] as Position;
      
        //------------------
        // Angle between initial click and arrow endpoint
        const angelClickPointStart = jtl.geometry.calculateAngle(
          ...clickPoint,
          ...draft.body.endPoint,
        );

        //------------------
        // Angle between moved click and arrow endpoint
        const angelClickPointMoved = jtl.geometry.calculateAngle(
          ...clickPointMoved,
          ...draft.body.endPoint,
        );

        //------------------
        // Angle created between 
        // initial click point and moved click point
        let angleClickMovedDelta = angelClickPointMoved - angelClickPointStart;
        angleClickMovedDelta = jtl.geometry.normalizeAngle(angleClickMovedDelta);

        const arrowRotationInit = draft.cache.rotation !;
        
        //---------------------
        // Arrow rotation after
        // moving click point
        const arrowRotationNow  = arrowRotationInit + angleClickMovedDelta;
        
       
        //----------------------
        // Rotate click offset
        const clickOffsetRotated = jtl.geometry.rotateVector(arrowRotationNow, clickOffset);


        const newStartPoint = [
          clickPointMoved[0] - clickOffsetRotated[0],
          clickPointMoved[1] - clickOffsetRotated[1],
        ] as Position;

        draft.body.startPoint = newStartPoint;
      });
    }


    case 'arrowHeadPositionUpdate_done' : {
      return produce(arrowState, draft => {
        cleanCache(draft);
      });
    }



    //--------------------------
    //
    // Body moving
    //
    //--------------------------

    case 'arrowBodyPositionChanged' : {
      return produce(arrowState, draft => {
        draft.body.startPoint = action.payload.startPoint;
        draft.body.endPoint   = action.payload.endPoint;
      });
    }



    //--------------------------
    //
    // Tail moving
    //
    //--------------------------

    case 'arrowTailPositionUpdate_start' : {
      return produce(arrowState, draft => {
        const rotation = jtl.geometry.calculateAngle(
          ...draft.body.startPoint,
          ...draft.body.endPoint
        );

        const clickOffsetRotated = jtl.geometry.rotateVector(
          rotation, 
          action.payload.clickOffset
        );

        const clickPoint = [
          draft.body.endPoint[0] + clickOffsetRotated[0], 
          draft.body.endPoint[1] + clickOffsetRotated[1]
        ] as Position;

        draft.cache.tail.clickOffset = action.payload.clickOffset;
        draft.cache.tail.clickPoint  = clickPoint;

        draft.cache.body.startPoint  = draft.body.startPoint;
        draft.cache.body.endPoint    = draft.body.endPoint;
        draft.cache.rotation         = rotation;
      });
    }


    case 'arrowTailPositionUpdate_update' : {
      return produce(arrowState, draft => {
        const deltaMove   = action.payload.delta;

        const clickOffset = draft.cache.tail.clickOffset !;
        const clickPoint  = draft.cache.tail.clickPoint as Position;

        const clickPointMoved = [
          clickPoint[0] + deltaMove.x,
          clickPoint[1] + deltaMove.y,
        ] as Position;

        //------------------
        // Angle between initial click and arrow startPoint
        const angelClickPointInit = jtl.geometry.calculateAngle(
          ...clickPoint,
          ...draft.body.startPoint,
        );

        //------------------
        // Angle between moved click and arrow startPoint
        const angelClickPointMoved = jtl.geometry.calculateAngle(
          ...clickPointMoved,
          ...draft.body.startPoint,
        );

        //------------------
        // Angle created between 
        // initial click point and moved click point
        let angleClickMovedDelta = angelClickPointMoved - angelClickPointInit;
        angleClickMovedDelta = jtl.geometry.normalizeAngle(angleClickMovedDelta);

        const arrowRotationInit = draft.cache.rotation !;


        //---------------------
        // Arrow rotation after
        // moving click point
        const arrowRotationNow  = arrowRotationInit + angleClickMovedDelta;
        
       
        //----------------------
        // Rotate click offset
        const clickOffsetRotated = jtl.geometry.rotateVector(arrowRotationNow, clickOffset);


        const newEndPoint = [
          clickPointMoved[0] - clickOffsetRotated[0],
          clickPointMoved[1] - clickOffsetRotated[1],
        ] as Position;

        draft.body.endPoint = newEndPoint;
      });
    }


    case 'arrowTailPositionUpdate_done' : {
      return produce(arrowState, draft => {
        cleanCache(draft);
      });
    }

    //-------------------
    // Reload arrow
    //
    case 'reloadArrow': {
      return produce(arrowState, draft => {
        draft.body.startPoint = action.payload.startPoint;
        draft.body.endPoint   = action.payload.endPoint;
      });
    }

    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export const getArrowStateInitial = (
  widgetArrowProps: ContentTypes.WidgetArrowProps
): ArrowState => {
  return ({
    //---------------
    // Real states
    //
    body: {
      startPoint: widgetArrowProps.startPoint,
      endPoint:   widgetArrowProps.endPoint,
    },

    //------------------------------
    // Cache needed during updates
    cache: {
      rotation: null,

      head: {
        clickPoint:  null,
        clickOffset: null,
      },

      body: {
        startPoint: null,
        endPoint:   null,
      },

      tail: {
        clickPoint:  null,
        clickOffset: null,
      }
    },
    tmp: null
  });
}


export const cleanCache = (state: ArrowState) => {
  state.cache.rotation = null;

  state.cache.head.clickPoint  = null;
  state.cache.head.clickOffset = null;

  state.cache.body.startPoint  = null;
  state.cache.body.endPoint    = null;

  state.cache.tail.clickPoint  = null;
  state.cache.tail.clickOffset = null;
}
