import styled from "styled-components";
import SelectableCell from "./selectable-cell";


interface Props  {
  $editDisabled?: boolean;
}


const HeaderCell = styled(SelectableCell)<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  height: 100%;
  width:  100%;

  cursor: ${props => props.$editDisabled ? 'unset' : 'pointer'};
  user-select: none;
  box-sizing: border-box;
`;

export default HeaderCell;