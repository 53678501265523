import styled from "styled-components";
import MenuItem from "lego/styles/menu_item";


const Item = styled(MenuItem)`
  height: 30px;
  gap: ${props => props.theme.defs.gap.normal};
  /* background: blue; */
`;


export default Item;
