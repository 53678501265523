import styled from "styled-components";
import TextWrap from "./text-wrap";


export const Title = styled(TextWrap)`
  max-width: 100%;
  user-select: none;

  color:       ${props => props.theme.defs.colors.color.primary};
  font-size:   ${props => props.theme.defs.font.size.xxxlarge};
  font-weight: ${props => props.theme.defs.font.weight.large};
`;


export default Title;