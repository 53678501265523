import React from 'react';

import { TextWrapper } from './styles';
import { Text } from './styles';


interface Props {
  text: string;
}


export const ElementTextComponent: React.FC<Props> = (props: Props) => {
  const {
    text
  } = props;


  return (
    <TextWrapper>
      <Text>
      { text }
      </Text>
    </TextWrapper>
  );
}

