import styled from "styled-components";


export const Window = styled.div<{maximized?: boolean}>`
  position: relative;
  overflow: clip;
  box-sizing: border-box;

  width:  100%;
  height: 100%;

  border-style:  solid;
  border-color:  ${props => props.theme.window.border.inner.color}; 
  border-width:  ${props => props.theme.window.border.inner.width}; 
  border-radius: ${props => props.theme.window.border.inner.radius};
`;

export default Window;
