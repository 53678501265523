import React from 'react';

import PagePrintoutComponent from './page-printout';
import PageEditorComponent from './page-editor';
import { PageProps } from './types';
import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';


interface Props extends PageProps {
}


export const PageComponent: React.FC<Props> = (props: Props) => {
  const { 
  } = props;
  
  const isPrintout = useIsPrintout();

  const PageComp = (
    isPrintout ?
    PagePrintoutComponent :
    PageEditorComponent
  );

  
  return (
    <PageComp {...props} />
  );
}
