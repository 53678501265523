import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { ReposTypes } from 'app/arch/app/states/repos';
import useRepos           from 'app/ui-v2/app/hooks/use-repos';
import ProgressComponent  from 'app/ui-v2/hls/progress';
import useAppStateSetters from 'app/ui-v2/app/hooks/use-app-state-setters';
import useRepoMemberAccessUpdate from 'app/ui-v2/app/hooks/use-repos-members-save';
import RepoMemberAccessSelectorComponent from 'app/ui-v2/home-page/hls/repo-member-access-selector';

import { MainWrapper } from './styles';
import { ProgressWrapper } from './styles';


const DEBOUNCE_DELAY = 1000;


interface Props {
  repoId: number,
  member: ReposTypes.Member,
  disabled: boolean;
}


export const RepoMemberItemAccessComponent: React.FC<Props> = (props: Props) => {
  const {
    member,
    repoId,
    disabled,
  } = props;

  const [memberAccess, setMemberAccess] = useState(member.access);
  const [updating, setUpdating] = useState(false);

  const repos = useRepos();
  const updateMemberAccess = useRepoMemberAccessUpdate();

  const {
    setRepos
  } = useAppStateSetters();

  useEffect(() => {
    return () => {
      updateBackend.flush();
    }
  }, []);

  const handleSetMemberAccess = (access_: ReposTypes.MemberAccess) => {
    setMemberAccess(access_);
    updateBackend(access_);
  }

  const updateBackend = useDebouncedCallback((access: ReposTypes.MemberAccess) => {

    // FIXME
    // OnDone will not be called if
    // updateBackend will get started
    // and user changes to another view.
    // So backend will be updated,
    // but this component will get 
    // unmounted and local repos state
    // will not get updated.

    const onDone = () => {
      repos.setRepoMemberAccess(
        repoId, 
        member.username, 
        memberAccess
      );
    
      setRepos();
      setUpdating(false);
    }
    
    const onError = (error: string) => {
      setUpdating(false);
      setMemberAccess(member.access);
    }

    const onLimit = (limit: string) => {
      setUpdating(false);
      setMemberAccess(member.access);
    }

    setUpdating(true);
    updateMemberAccess({
      repoId,
      memberUsername: member.username,
      access,

      onDone,
      onError,
      onLimit,
    });
  }, DEBOUNCE_DELAY);


  return (
    <MainWrapper>
      <RepoMemberAccessSelectorComponent 
        disabled={updating || disabled}
        memberAccess={memberAccess}
        onSetMemberAccess={handleSetMemberAccess}
      />
      {
        updating &&
        <ProgressWrapper>
          <ProgressComponent size={32}/>
        </ProgressWrapper>
      }
    </MainWrapper>
  );
}

