
export const ColorsPalette = {
  base: [
    ['#000000', '#2A2A2A', '#545454', '#7E7E7E', '#A8A8A8', '#D2D2D2', '#FFFFFF', 'transparent' ],
  
    ['#FF665E', '#FFB27A', '#FFD18F', '#70E088', '#7BCFF2', '#67A0E3', '#8A88E5', '#FF669F' ],
    ['#FF3B30', '#FF9500', '#FFCC00', '#4CD964', '#5AC8FA', '#007AFF', '#5856D6', '#E93598' ],
    ['#D62D20', '#D57800', '#D4AF37', '#3B8B3A', '#3C9BAA', '#2969B0', '#3D3A8B', '#D62D7F' ],
    ['#920D08', '#935200', '#918B1F', '#1E4B1E', '#1F5A66', '#143264', '#221C64', '#920D54' ],
  ],
}