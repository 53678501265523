import produce from 'immer';

import * as Tools from './tools';
import * as Types from './types';
import * as State from './state';


export class Repos {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  reset() {
    const logger = Tools.getLogger();
    logger.debug("Reseting repos state");

    this._state = State.createInitialState();
  }

  get raw() { return this._state; }

 
  /**
   * Setters
   */

  loadReposPacks(
    reposPacks: Types.ReposPacksRaw
  ) {
    const logger = Tools.getLogger();
    logger.debug(`Loading repos pack, repos count: ${reposPacks.length}`);

    this._state = produce(this._state, draft => {
      draft.reposPacks = reposPacks;
    });
  }

  loadRepoPack(
    repoPack: Types.RepoPackRaw
  ) {
    const logger = Tools.getLogger();
    logger.debug(`Loading repo pack`);

    this._state = produce(this._state, draft => {
      const idx = State.getRepoPackIdx(draft, repoPack.repo.id);
      draft.reposPacks[idx] = repoPack;
    });
  }


  removeRepoPack(
    repoId: number
  ) {
    this._state = produce(this._state, draft => {
      const reposPacks = State.getReposPacks(draft);
      const repoIdx = State.getRepoPackIdx(draft, repoId);
      reposPacks.splice(repoIdx, 1);
    });
  }
  
  setRepoName(
    repoId: number, 
    name: string
  ) {
    this._state = produce(this._state, draft => {
      const repo = State.getRepo(draft, repoId);
      repo.name = name;
    });
  }

  removeRepoMember(
    repoId: number, 
    memberUsername: string,
  ) {
    this._state = produce(this._state, draft => {
      const repoPack = State.getRepoPack(draft, repoId);
      const memberIdx = State.getMemberIdx(draft, repoId, memberUsername);
      repoPack.members.splice(memberIdx, 1);
    });
  }
  
  setRepoMemberStatus(
    repoId: number, 
    memberUsername: string,
    status: Types.MemberStatus
  ) {
    this._state = produce(this._state, draft => {
      const member = State.getMember(draft, repoId, memberUsername);
      member.status = status;
    });
  }

  setRepoMemberAccess(
    repoId: number, 
    memberUsername: string, 
    access: Types.MemberAccess
  ) {
    this._state = produce(this._state, draft => {
      const member = State.getMember(draft, repoId, memberUsername);
      member.access = access;
    });
  }


  /**
   * Getters
   */

  getReposPacks() {
    return State.getReposPacks(this._state);
  }

  getRepoPack(
    repoId: number
  ) {
    return State.getRepoPack(this._state, repoId);
  }

  getMember(
    repoId: number, 
    memberUsername: string
  ) {
    return State.getMember(this._state, repoId, memberUsername);
  }

  getRepoMemberAccess(
    repoId: number, 
    memberUsername: string
  ) {
    return State.getRepoMemberAccess(this._state, repoId, memberUsername);
  }

  getRepoMemberStatus(
    repoId: number, 
    memberUsername: string
  ) {
    return State.getRepoMemberStatus(this._state, repoId, memberUsername);
  }

  getRepoMembers(
    repoId: number, 
    memberUsername: string
  ) {
    return State.getRepoMembers(this._state, repoId, memberUsername);
  }
}
