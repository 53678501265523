import React from 'react';
import { useRecoilValue } from 'recoil';

import { IconAddr } from 'tools/icon-repo/types';

import { RepoMarkersTools }  from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_RepoMarkers } from 'app/ui/states/editor-instruction';

import MarkerViewComponent       from './marker-view';
import MarkerIconSearchComponent from './marker-icon-search';

import { Scroller } from './styles';
import { Separator } from './styles';
import { MainWrapper } from './styles';
import { SearchWrapper } from './styles';
import { MarkerEditWrapper } from './styles';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
}


export const MarkerEditPanelComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr
  } = props;

  const document = useDocState();
  const { 
    setRepoMarkers 
  } = useEditorStatesSetters();

  const markerProps = useRecoilValue(UIState_RepoMarkers.markerProps(markerAddr));
  const hasIcon = RepoMarkersTools.hasIcon(markerProps.type);
  
  const handleSelectIcon = (iconAddr: IconAddr) => {
    document.repoMarkers.updateMarkerIcon(markerAddr, {
      addr: iconAddr
    });
    document.saveUndo();
    setRepoMarkers();
  }


  return (
    <MainWrapper>

      <MarkerEditWrapper>
        <Scroller>
          <MarkerViewComponent markerAddr={markerAddr} />
        </Scroller>
      </MarkerEditWrapper>

      {
        hasIcon &&
        <>
          <Separator /> 

          <SearchWrapper>
            <MarkerIconSearchComponent onSelectIcon={handleSelectIcon} />
          </SearchWrapper>
        </>
      }

    </MainWrapper>
  );
}
