import React from 'react';
import { useState } from 'react';

import PanelTitledComponent from 'app-views/components/panel-titled';
import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import useRepos from 'app/ui-v2/app/hooks/use-repos';
import IconLib  from 'app/ui/icons/icons';
import useUserSettingsSave from 'app/ui-v2/app/__modules/user/hooks/use-user-settings-save';
import useUserSettings     from 'app/ui-v2/app/__modules/user/hooks/use-user-settings';
import useUserStateSetters from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';

import ReposListComponent from './repos-list';

import { RepoName } from './styles';
import { RepoListWrapper } from './styles';
import { SelectButton } from './styles';


interface Props {
  selectedRepoId: number;
}


export const RepoSetterComponent: React.FC<Props> = (props: Props) => {
  const {
    selectedRepoId,
  } = props;

  const t = useTranslations();
  const userSettings = useUserSettings();
  const saveUserSettings = useUserSettingsSave();

  const {
    setUserSettings
  } = useUserStateSetters();

  const [
    panelExpanded, 
    setPanelExpanded
  ] = useState<boolean>(false);

  const repos = useRepos();
  const repoPack = repos.getRepoPack(selectedRepoId);


  const handleToggleExpand = (event: any) => {
    setPanelExpanded( ! panelExpanded );
  }

  const handleRepoSelected = (repoId: number) => {
    userSettings.updateRepo({id: repoId});
    setUserSettings();
    saveUserSettings();

    setPanelExpanded(false);
  }

  const Icon = (
    panelExpanded ?
    IconLib.Expand.Less :
    IconLib.Expand.More
  );

  return (
    <PanelTitledComponent title={t('repositories joined')} >
      <SelectButton onClick={handleToggleExpand} >
        <RepoName>
        { repoPack.repo.name }
        </RepoName>
        <Icon />
      </SelectButton>

    {
      panelExpanded &&
      <RepoListWrapper>
        <ReposListComponent 
          selectedRepoId={selectedRepoId} 
          onRepoSelected={handleRepoSelected}
        /> 
      </RepoListWrapper>
    }

    </PanelTitledComponent>
  );
}
  
