import React from 'react';

import { ReposTypes } from 'app/arch/app/states/repos';
import useUsername from 'app/ui-v2/app/hooks/use-user-name';
import MemberPanelComponent   from './member-panel';
import OwnerPanelComponent    from './owner-panel';
import YourselfPanelComponent from './yourself-panel';

import { ListItem }       from './styles';
import { MainWrapper }    from './styles';
import { MemberUsername } from './styles';


interface Props {
  repoId: number,
  member: ReposTypes.Member,
  selected: boolean,
  onSelected: (memberUsername: string) => void;
}


export const RepoMemberItemComponent: React.FC<Props> = (props: Props) => {
  const {
    repoId,
    member,
    selected,
    onSelected,
  } = props;

  const username = useUsername();
  const yourself = (username === member.username);

  const handleClick = (event: React.MouseEvent) => {
    onSelected(member.username);
  }

  const renderSelectedComps = () => (
    <>
      { 
        ! yourself &&
        member.access !== ReposTypes.MemberAccess.OWNER &&
        <MemberPanelComponent
          repoId={repoId}
          member={member}
        /> 
      }
      { 
        yourself &&
        member.access !== ReposTypes.MemberAccess.OWNER &&
        <YourselfPanelComponent /> 
      }
      { 
        member.access === ReposTypes.MemberAccess.OWNER &&
        <OwnerPanelComponent /> 
      }
    </>
  );


  return (
    <MainWrapper $selected={selected}>
      <ListItem 
        selected={selected}
        onClick={handleClick}
      >
        <MemberUsername>
        { member.username }
        </MemberUsername>
      </ListItem>

      { 
        selected && 
        renderSelectedComps() 
      }

    </MainWrapper>
  );
}
