import React from 'react';
import { useIsMobile } from 'lego-hooks/use-is-mobile';

import Icon from 'app/ui/icons/icons';
import { Instruction } from 'app/arch/backend/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import PanelItemComponent from 'app-views/components/panel-item';
import { PanelItemConfig } from 'app-views/components/panel-item/config';
import ItemContentComponent from './item-content';



interface Props {
  instruction: Instruction;
  selected: boolean;

  onInstructionEdit: (instructionId: number) => void;
  onInstructionSettings: (instructionId: number) => void;
  onInstructionSelect: (instructionId: number) => void;
}


export const InstructionItemComponent: React.FC<Props> = (props: Props) => {
  const {
    instruction,
    selected,

    onInstructionEdit,
    onInstructionSettings,
    onInstructionSelect,
  } = props;

  const t = useTranslations();
  const isMobile = useIsMobile();

  const handleInstructionSettings = (event: React.MouseEvent) => {
    event.stopPropagation();
    onInstructionSettings(+instruction.id);
  }

  const handleInstructionEdit = (event: React.MouseEvent) => {
    event.stopPropagation();
    onInstructionEdit(+instruction.id);
  }

  const handleInstructionSelect = (event: React.MouseEvent) => {
    event.stopPropagation();

    if ( isMobile  ) {
      if (selected) {
        onInstructionEdit(+instruction.id);
      }
      else {
        onInstructionSelect(+instruction.id);
      }
    }
    else {
      onInstructionEdit(+instruction.id);
    }
  }

  const config: PanelItemConfig = {
    // title: instruction.title,
    selected: selected,

    content: {
      component: ItemContentComponent,
      props: {
        instruction,
      },
    },
    buttonsAutoHide: true,
    onClick: handleInstructionSelect,
    buttons: [
      ...(isMobile ? [{
        tooltip: t('document edit tooltip'),
        onClick: handleInstructionEdit,
        Icon: Icon.Edit.Plain
      }] : []),
      {
        tooltip: t('document settings tooltip'),
        onClick: handleInstructionSettings,
        Icon: Icon.More
      },
    ]
  }

  return (
    <PanelItemComponent
      config={config}
    />
  );
}
  
