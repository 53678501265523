interface Props {
  signalColumnsWidths: (props: { debounced?: boolean }) => void;
  setRecoilState: () => void;
  columnAutoAdjust: () => void;
}


const useColumnsAutoAdjust = (props: Props) => {
  const {
    columnAutoAdjust,
    signalColumnsWidths,
    setRecoilState,
  } = props;

  const columnsAutoAdjust = (props: {
    debounced?: boolean,
    skipStateUpdate?: boolean
  } = {
    debounced: true,
    skipStateUpdate: false,
  }) => {

    const {
      debounced,
      skipStateUpdate
    } = props;

    columnAutoAdjust();

    if ( skipStateUpdate ) {
      return;
    }

    signalColumnsWidths({ debounced });
    setRecoilState();
  }

  return columnsAutoAdjust;
}


export default useColumnsAutoAdjust;