import DropdownColorPickerComponent from "lego-v2/dropdown-color-picker";

import { ColorsPalette } from "app/configs/colors-palette";
import { ScreenSizeTypes } from "app/arch/app/screen";
import { Icon } from "app/ui/icons/icons";
import useScreenSizeVariantWatch from "app/ui-v2/app/__modules/screen/hooks/use-screen-size-variant-watch";

import DropdownFontSizeComponent from "./dropdown-font-size";

import { Section } from "./styles";
import { Button } from "./styles";


type Callback = (data?: any) => void;


interface BtnDef {
  selected?: boolean,
  onClick: (event: React.MouseEvent) => void,
  Icon: React.ElementType
}


interface Props {
  callbacks: { [fnName: string]: Callback },
  states: { [stateName: string]: (boolean | string | number | null ) },
}


export const ControlsComponent: React.FC<Props> = (props: Props) => {
  const {callbacks, states} = {...props};
  const scnree = useScreenSizeVariantWatch();


  const btnFormatBold: BtnDef = {
    selected: states.selectionIsBold as boolean,
    onClick: callbacks.formatBold,
    Icon: Icon.FormatText.Bold
  }
  
  const btnFormatItalic: BtnDef = {
    selected: states.selectionIsItalic as boolean,
    onClick: callbacks.formatItalic,
    Icon: Icon.FormatText.Italic
  }

  const btnAlignLeft: BtnDef = {
    selected: states.selectionTextAlignLeft as boolean,
    onClick: callbacks.formatLeft,
    Icon: Icon.FormatText.Align.Left
  }

  const btnAlignCenter: BtnDef = {
    selected: states.selectionTextAlignCenter as boolean,
    onClick: callbacks.formatCenter,
    Icon: Icon.FormatText.Align.Center
  }

  const btnAlignRight: BtnDef = {
    selected: states.selectionTextAlignRight as boolean,
    onClick: callbacks.formatRight,
    Icon: Icon.FormatText.Align.Right
  }

  const btnFormatListNumbered: BtnDef = {
    selected: states.selectionIsNumberList as boolean,
    onClick: callbacks.formatNumberedList,
    Icon: Icon.FormatText.List.Numbered
  }

  const btnFormatListBullet: BtnDef = {
    selected: states.selectionIsBulletList as boolean,
    onClick: callbacks.formatBulletList,
    Icon: Icon.FormatText.List.Bullet
  }

  const btnFormatIndentDecrease: BtnDef = {
    onClick: callbacks.formatOutdent,
    Icon: Icon.FormatText.Indent.Decrease
  }

  const btnFormatIndentIncrease: BtnDef = {
    onClick: callbacks.formatIndent,
    Icon: Icon.FormatText.Indent.Increase
  }

  const sectionsAll = [
    [btnFormatBold, btnFormatItalic],
    [btnAlignLeft, btnAlignCenter, btnAlignRight],
    [btnFormatListNumbered, btnFormatListBullet],
    [btnFormatIndentDecrease, btnFormatIndentIncrease],
  ];

  const sectionsMedium = [
    [btnFormatBold, btnFormatItalic],
    [btnAlignLeft, btnAlignCenter, btnAlignRight],
  ];

  const sectionsMinimal = [
    [btnFormatBold, btnFormatItalic],
  ];

   const ScreenToSectionMap = {
    [ScreenSizeTypes.SizeVariant.WIDTH_320]: sectionsMinimal,
    [ScreenSizeTypes.SizeVariant.WIDTH_360]: sectionsMedium,
    [ScreenSizeTypes.SizeVariant.WIDTH_390]: sectionsMedium,
    [ScreenSizeTypes.SizeVariant.WIDTH_500]: sectionsAll,
    [ScreenSizeTypes.SizeVariant.WIDTH_600]: sectionsAll,
  };

  const sections = ScreenToSectionMap[scnree];

  
  const createButton = (btnDef: BtnDef, idx: number) => {
    const {
      selected,
      onClick,
      Icon
    } = btnDef;

    return (
      <Button
        key={idx}
        $selected={selected}
        onClick={onClick}
      >
        <Icon />
      </Button>
    );
  }


  const renderBtnsSections = () => {
    const Sections = sections.map((buttons, sectionIdx) => {
      const Btns = buttons.map((button, buttonIdx) => {
        return createButton(button, buttonIdx);
      });

      return (
        <Section key={sectionIdx}>
          { Btns }
        </Section>
      );
    });

    return Sections;
  }

  const handleFontColorPanelEnter = () => {
    (callbacks.hideFontColorPanelDeb as any).cancel();
  }

  const handleFontColorPanelLeave = () => {
    callbacks.hideFontColorPanelDeb();
  }

  const handleFontBgColorPanelEnter = () => {
    (callbacks.hideFontBgColorPanelDeb as any).cancel();
  }

  const handleFontBgColorPanelLeave = () => {
    callbacks.hideFontBgColorPanelDeb();
  }
  return (
    <>
      <Section $first={true}>
        <DropdownFontSizeComponent
          togglePanelVisible={callbacks.toggleFontSizePanelVisible}
          isPanelVisible={states.fontSizePanelVisible as boolean}
          setFontSize={callbacks.setTextSize}
          fontSize={states.fontSize as number}
          skipSelectionUpdate={callbacks.skipSelectionUpdate}
        />
      </Section>

      <Section>
        <DropdownColorPickerComponent
          id={'editor-text-toolbar-color-picker-font-color'}
          onPanelEnter={handleFontColorPanelEnter}
          onPanelLeave={handleFontColorPanelLeave}
          btnProps={{
            id: 'editor-text-toolbar-btn-font-color',
            iconMuiComponent: Icon.FormatText.Color.Text,
            selectedColor: states.fontColor as string,
            onClick: callbacks.toggleFontColorPanelVisible,
          }}
          colorPickerProps={{
            colorPalette: ColorsPalette.base,
            onColorSelected:callbacks.setTextColor,
            color: states.fontColor as string,
          }}
          isPanelVisible={states.fontColorPanelVisible as boolean}
          togglePanelVisible={callbacks.toggleFontColorPanelVisible}
        />

        <DropdownColorPickerComponent
          onPanelEnter={handleFontBgColorPanelEnter}
          onPanelLeave={handleFontBgColorPanelLeave}
          btnProps={{
            iconMuiComponent: Icon.FormatText.Color.Fill,
            selectedColor: states.fontBgColor as string,
            onClick: callbacks.toggleFontBgColorPanelVisible,
          }}
          colorPickerProps={{
            colorPalette: ColorsPalette.base,
            onColorSelected: callbacks.setTextBackgroundColor,
            color: states.fontBgColor as string,
          }}
          isPanelVisible={ states.fontBgColorPanelVisible as boolean}
          togglePanelVisible={callbacks.toggleFontBgColorPanelVisible}
        />
      </Section>

   
      { renderBtnsSections() }
    </>
  );
}

