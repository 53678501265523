import { useThemeDescriptor } from './use-theme-descriptor';
import { ThemeVariantType } from 'app/arch/app/themes/types';
import { themes           } from 'app/arch/app/themes/themes';


export const useThemeAccentColor = () => {
  const theme = useThemeDescriptor();

  const darkMode  = theme.variant === ThemeVariantType.DARK;
  const generateThemeAccentColors = themes.getThemeAccentColors(theme.type);
  const accentColors = generateThemeAccentColors(darkMode);

  return accentColors[theme.accentColor];
 }
