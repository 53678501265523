import React from 'react';

import MenuTopComponent from './menu-top';
import ToolbarTopComponent from './toolbar-top';
import MenuBottomComponent from './menu-bottom';

import { MainColumn } from './styles';
import { WidgetToolbarWrapper } from './styles';
import { ContentWrapper } from './styles';
import { MenuBottomWrapper } from './styles';
import { MenuTopWrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const EditorImageContentMenusComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;


  return (
    <MainColumn>
      {/* <MenuTopWrapper>
        <MenuTopComponent />
      </MenuTopWrapper>  */}

      <WidgetToolbarWrapper>
        <ToolbarTopComponent />
      </WidgetToolbarWrapper>

      <ContentWrapper>
        { children }
      </ContentWrapper>

      <MenuBottomWrapper>
        <MenuBottomComponent />
      </MenuBottomWrapper>
              
    </MainColumn>
  );
}

