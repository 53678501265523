import React from 'react';
import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import useCellMarkersMenu       from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-markers/use-cell-markers-menu';
import CellContextMenuComponent from '../../parts/cell-context-menu';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellMarkersContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props;

  const getConfig = useCellMarkersMenu();

  return (
    <CellContextMenuComponent
      cellAddr={cellAddr}
      getConfig={getConfig}
    >
      { children }
    </CellContextMenuComponent>
  );
}
