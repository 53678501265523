import React from 'react';
import { useRecoilValue } from 'recoil';

import { useDocState } from 'app/ui/contexts/document';
import { UIState_HeaderFieldsSession } from 'app/ui/states/editor-instruction';
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';

import CellTextMenuComponent from './cell-text-menu';
import { CellMenuProps } from './types';


interface Props {
}


export const CustomFieldsCellMenuComponent: React.FC<Props> = (props: Props) => {
  const pageCellSelected = useRecoilValue(UIState_HeaderFieldsSession.pageCellSelected);
  
  return (
    <>
      { 
        pageCellSelected &&
        <__Component pageCellAddr={pageCellSelected} />
      }
    </>
  );
}


interface CellMenuRenderProps {
  pageCellAddr: Types.PageCellAddr;
}

const __Component: React.FC<CellMenuRenderProps> = (props: CellMenuRenderProps) => {
  const {
    pageCellAddr,
  } = props;

  const document = useDocState();
  const columnProps = document.headerFields.getColumnProps(pageCellAddr);

  const CellsMenus: {[key in Types.ColumnType]: React.FC<CellMenuProps>} = {
    [Types.ColumnType.TEXT]: CellTextMenuComponent,
  };

  const CellMenu = CellsMenus[columnProps.type];

  return (
    <CellMenu pageCellAddr={pageCellAddr} />
  );
}
