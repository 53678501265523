import React, { useState } from 'react';
import { useEffect } from 'react';

import { DocumentRelease } from 'app/arch/backend/types';
import { ViewTypes } from 'app/arch/home-page-user/states/view';
import useViewParamsRead   from 'app/ui-v2/home-page/home-page-user/hooks/use-view-params-read';
import useGoHome           from 'app/ui-v2/home-page/hooks/use-go-home';
import ViewTitledComponent from 'app/ui-v2/home-page/hls/view-titled';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import ReleasesListComponent from './releases-list/releases-list';
import DocumentInfoComponent from './document-info/document-info';
import ButtonBackComponent from './button-back';

import { AutoHeightWrapper } from './styles';
import { ReleasesWrapper } from './styles';
import { ButtonBackWrapper } from './styles';

interface Props {
}


export const ViewDocumentComponent: React.FC<Props> = (props: Props) => {
  const goHome = useGoHome();
  const params = useViewParamsRead() as ViewTypes.ViewParamsDocument;


  useEffect(() => {
    if (params === null) {
      goHome();
    }
  }, [params])


  if (params === null) {
    return null;
  }

  return <ViewDocumentComponent_ documentId={params.documentId}  />;
}
  



interface Props_ {
  documentId: number,
}

export const ViewDocumentComponent_: React.FC<Props_> = (props: Props_) => {
  const {
    documentId
  } = props;

  const t = useTranslations();

  const [docInfoLoaded, setDocInfoloaded] = useState(false);
  const [releaseLoaded, setReleaseLoaded] = useState(false);
  const [docReleasesCount, setDocReleasesCount] = useState(0);

  const handleDocInfoLoaded = () => {
    setDocInfoloaded(true);
  }

  const handleReleaseLoaded = (releases: DocumentRelease[]) => {
    setReleaseLoaded(true);
    setDocReleasesCount(releases.length);
  }

  const showButton = ( 
    docInfoLoaded &&
    releaseLoaded
  )

  return (
    <ViewTitledComponent title={t('document')}>

      <AutoHeightWrapper>
        <DocumentInfoComponent 
          documentId={documentId} 
          onLoaded={handleDocInfoLoaded}
        />
      </AutoHeightWrapper>

      <ReleasesWrapper
        empty={docReleasesCount === 0}
      >
        <ReleasesListComponent 
          documentId={documentId} 
          onLoaded={handleReleaseLoaded}
        />
      </ReleasesWrapper>

      <ButtonBackWrapper visible={showButton}>
        <ButtonBackComponent />
      </ButtonBackWrapper>

    </ViewTitledComponent>
  );

}