import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { ReposTools }    from 'app/arch/app/states/repos';
import { TemplateTypes } from 'app/arch/doc-templates/states/template';
import useUsername     from 'app/ui-v2/app/hooks/use-user-name';
import useRepos        from 'app/ui-v2/app/hooks/use-repos';
import useDocTemplates from 'app/ui-v2/doc-templates/hooks/use-doc-templates';
import useDocTemplatesFetch from 'app/ui-v2/app/hooks/use-doc-templates-fetch';


import { LoadersTools }        from '..';
import LoaderAuthBaseComponent from '../base/loader-auth-base';
import { LoaderCoreProps }     from '../base/loader-auth-base/types';


interface Props {
  children: React.ReactNode;
}


export const LoaderDocTemplatesComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  return (
    <LoaderAuthBaseComponent
      debugTitle={"doc templates"}
      LoaderCoreAuthComponent={LoaderCoreAuth}
      LoaderCoreUnauthComponent={LoaderCoreUnauth}
    >
      { children }
    </LoaderAuthBaseComponent>
  );
}

  

/**
 * UserSettings Auth
 */
export const LoaderCoreAuth: React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const {
    children 
  } = props;
  
  const logger = LoadersTools.getLogger();
  const [ready, setReady] = useState(false);

  logger.debug(`Loader doc templates - render, auth, ready: ${ready}`);
  
  const docTemplates = useDocTemplates();
  const username = useUsername();

  const reposData = useRepos();

  const reposPacks = reposData.getReposPacks();
  const reposEditable = ReposTools.getReposEditable(reposPacks, username);
  const reposEditableIds = reposEditable.map(repoPack => repoPack.repo.id);

  const onDone = (templates: TemplateTypes.BackendTemplates) => {
    docTemplates.loadTemplates(templates);
    setReady(true);
  }

  const onError = () => {
    console.error(`Can't download doc templates`);
    setReady(true);
  }
  
  useDocTemplatesFetch({
    onDone,
    onError,
    reposIds: reposEditableIds
  });

  if ( ! ready ) {
    return null;
  }

  return children;
}
  

/**
 * UserSettings Unauth
 */
const LoaderCoreUnauth:  React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const {
    children 
  } = props;

  const logger = LoadersTools.getLogger();
  logger.debug(`Loader doc templates - render, unauth`);

  const docTemplates = useDocTemplates();
  
  useEffect(() => {
    logger.debug('Reset doc templates settings');
    docTemplates.reset();
  }, []);

  return children;
}
