import styled from "styled-components";
import CellsDragOver from "app/ui-v2/editor-instruction/views/shared/styles/cells-drag-over";
import zIndex from "../z-index";


export const MainWrapper = styled.div`
  position: relative;
  height: 100%;
`;


export const Background = styled(CellsDragOver)`
  z-index: ${zIndex.overBackground};
`;
