
export enum MemberAccess {
  // Order is important
  VIEWER = 'viewer',
  EDITOR = 'editor',
  ADMIN  = 'admin',
  OWNER  = 'owner',
};


export enum MemberStatus {
  UNINIT          = 'uninit',
  INVITE_SENT     = 'invite_sent',
  INVITE_ACCEPTED = 'invite_accepted',
  INVITE_REJECTED = 'invite_rejected',
};


export interface MemberRaw {
  username: string;
  access: MemberAccess;
  status: MemberStatus;
}


export interface RepoRaw {
  id: number,
  name: string,
}

export interface RepoPackRaw {
  repo: RepoRaw,
  members: MemberRaw[],
}

export type ReposPacksRaw = RepoPackRaw[];



export interface Member extends MemberRaw {
}

export type Members = Member[];

export interface Repo extends RepoRaw {
}

export interface RepoPack {
  repo: Repo,
  members: Members,
}

export type ReposPacks = RepoPack[];
