import React from 'react';
import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { MainWrapper } from './styles';


interface Props {
  idx: number;
  children: React.ReactNode;
}


export const ImageDataTestComponent: React.FC<Props> = (props: Props) => {
  const {
    idx,
    children,
  } = props;


  const dataTest = (
    `doc-editor`
    + `__images-gallery`
    + `__image-${idx}`
  );

  return (
    <MainWrapper data-test={dataTest}>
      { children }
    </MainWrapper>
  );
}

