import jtl from 'tools/jtl';
import DocState from './states/doc-state';
import { ContentTypes } from './states/persistent/content';
import Logger from 'libs/debug';


export class Document {
  private _docState: DocState;

  constructor(docId: number) {
    this._docState = new DocState();
    this._docState.instruction.setInstructionId(docId);
  }

  get docState() { return this._docState; }


  removeImage(bid: number) {
    const content = this._docState.content;
    content.cellsImages_removeImage(bid);

    const docImages = this._docState.repoImages;
    docImages.removeImage(bid);

    this.docState.resetUndo();
  }
  
  statesLoaded() {
    this.__loadedContentFix();
    this.docState.resetUndo();
    this.docState.dirty = false;
  }
 

  private __loadedContentFix() {
    // It can happen that someone will have an image in gallery and he uses it in multiple cells.
    // Then he save document and then delete this image from gallery (and effectively from remote server),
    // and also from current document state. However if he does not save it, when he gets content from the server,
    // ex. on reload, he will also get in data cell images, with image which was already removed from gallery/server.
    // In this case we remove ghosted image(s) from cells.

    const logger = Logger.getDocument();

    const content   = this._docState.content;
    const docImages = this._docState.repoImages;

    const imagesAddrs = content.cellsImages_getImagesAddrs();
    const imagesAddrsToRemove: ContentTypes.ImageAddr[] = [];
  
    imagesAddrs.forEach((imageAddr) => {
      const imageProps = content.cellImages_getImageProps(imageAddr);
      const imagePresent = docImages.hasImage(imageProps.bid);

      if ( ! imagePresent ) {
        imagesAddrsToRemove.push(imageAddr);
      }
    });

    imagesAddrsToRemove.forEach((imageAddr) => {
      const imageAddrHash = jtl.object.hash(imageAddr);
      logger.warn(`Removing ghost image from content, image addr hash: ${imageAddrHash}`);
      content.cellImages_removeImage(imageAddr);
    });
  }
};
