import styled from "styled-components";
import MenuItem from "./menu_item";


export const ListItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${props => props.theme.defs.gap.normal};

  height: 32px;
`;

export default ListItem;