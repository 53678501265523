import React from 'react';
import MenuRenderComponent from './menu-render';
import MenuDefaultComponent from './menu-default';

import { MainWrapper } from './styles';


interface Props {
}


export const TopMenuComponent: React.FC<Props> = (props: Props) => {

  return (
    <MainWrapper>
      <MenuRenderComponent />
      <MenuDefaultComponent />
    </MainWrapper>
  );
}
