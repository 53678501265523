import styled from "styled-components";
import Button from "lego/styles/button";
import DraggerBase from "lego-v2/dragger/ui/styles";



export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 30px;
`;

const CTRL_LINE_SIZE = 18;

export const LineCtrl = styled.div<{$disabled: boolean}>`
  position: absolute;
  z-index: 2;

  box-sizing: border-box;

  height: ${CTRL_LINE_SIZE}px;
  width: 100%;
  
  border-radius: ${props => props.theme.defs.border.radius.normal};
  border-style: none;

  /* background: rgba(255, 0, 0, 0.2); */
  background: transparent;
  cursor: pointer;
`;


export const DraggerWrapper = styled.div`
  position: absolute;
  top: ${CTRL_LINE_SIZE / 2}px;
  left: 0px;
`

export const Line = styled.div<{$disabled: boolean}>`
  position: absolute;
  box-sizing: border-box;

  height: 5px;
  width: 100%;
  border-radius: ${props => props.theme.defs.border.radius.normal};
  border-width:  ${props => props.theme.defs.border.width.normal};
  border-color:  ${props => props.theme.defs.colors.border.secondary};

  border-style: solid;

  background: ${props => 
    props.$disabled ?
    props.theme.defs.colors.special.disabled :
    props.theme.defs.colors.background.forth
  };
`;

export const LineLeft = styled(Line)`
  position: absolute;

  background: ${props => 
    props.$disabled ?
    props.theme.defs.colors.special.disabled :
    props.theme.defs.accentColor.primary
  };
  
`;

export const DOT_SIZE = 16;

export const Dot = styled(Button)`
  position: absolute;
  background: ${props => props.theme.defs.colors.color.secondary};

  height: ${DOT_SIZE}px;
  width:  ${DOT_SIZE}px;
  border-radius: 50%;
  top: ${-1 * DOT_SIZE / 2}px;

  &:disabled {
    opacity: 1;
    cursor: not-allowed;
    background: ${props => props.theme.defs.colors.special.disabled};
  }
`;

export const Dragger = styled(DraggerBase)`
  position: relative;
`;

export const ProbeWrapper = styled.div`
  flex-grow: 1;
`;