import { useRecoilValue } from 'recoil';
import { UIUser_Settings } from 'app/ui-v2/app/__modules/user/states';


const useRepo = () => {
  const repoId = useRecoilValue(UIUser_Settings.repoId);
  
  if (repoId === null) {
    const msg = `Repo id is null`;
    throw new Error(msg);
  }

  return {
    id: repoId !
  };
}


export default useRepo;
