import React from 'react';
import { CellMenuProps } from '../types';
import { TopToolbar } from './styles';


interface Props extends CellMenuProps {
}


export const CellIndexMenuComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <TopToolbar />
  );
}
  
