import React from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { UIState_Content } from 'app/ui/states/editor-instruction';

import { Text } from './styles';


interface Props {
  columnAddr: Types.ColumnAddr;
}


export const HeaderCellViewComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
  } = props;

  const columnName = useRecoilValue(UIState_Content.columnName(columnAddr));

  const columnNameDisplayed = (
    columnName.length ?
    columnName :
    <>&nbsp;</>
  );
  
  return (
    <Text>
    { columnNameDisplayed }
    </Text>
  );
}
