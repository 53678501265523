import styled from "styled-components";


export const FlexWrap = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  row-gap: ${props => props.theme.defs.gap.large};
  column-gap: ${props => props.theme.defs.gap.large};

  /* background: rgba(0, 255, 255, 0.3); */
`;
