import styled from "styled-components";


const Section = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
`;


export default Section;
