import React from 'react';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useContent from 'app/ui/contexts/document/use-content';
import ImageComponent from './image';


interface Props {
  cellAddr: ContentTypes.CellAddr;
}


export const CellImagesComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;

  const content = useContent();

  const renderImages = () => {
    const cell = content.getCell(cellAddr) as ContentTypes.ImagesCell;
    const images = cell.images;
    const Images = images.addrs.map((imageAddr) => renderImage(imageAddr));

    return Images;
  }

  const renderImage = (imageAddr: ContentTypes.ImageAddr) => {
    const imageKey = ContentTools.getImageKey(imageAddr);

    return (
      <React.Fragment key={imageKey}>
        <ImageComponent imageAddr={imageAddr} />
      </React.Fragment>
    );
  }

  return (
    <>
      { renderImages() }
    </>
  );
}

