import styled from "styled-components";
import PanelBase from "lego/styles/panel";


export const Panel = styled(PanelBase)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.large};
  
  width: 100%;
  max-width: 320px;
  
  box-sizing: border-box;;
  overflow: clip;
  padding: 0px;

  color: ${props => props.theme.defs.colors.color.primary};
  border-radius: ${props => props.theme.defs.border.radius.large};
`;
