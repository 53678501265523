import Prices from "./states/prices";
import PricePlans from "./states/price-plans";
import PricePlansSession from "./states/price-plans-session";


export class Products {
  private _prices: Prices;
  private _pricePlans: PricePlans;
  private _pricePlansSession: PricePlansSession;

  constructor() {
    this._prices = new Prices();
    this._pricePlans = new PricePlans();
    this._pricePlansSession = new PricePlansSession();
  }

  reset() {
    // There is no point to reset prices
    // or prices plans
    //
    // this._prices.reset();
    // this._pricePlans.reset();
    this._pricePlansSession.reset();
  }

  get prices() { return this._prices; }
  get pricePlans() { return this._pricePlans; }
  get pricePlansSession() { return this._pricePlansSession; }
}
