import React from 'react';
import Logger from 'libs/debug';

import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset from '../../hooks/use-selected-reset';

import { MainWrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const EditorImageDeskResetSelectedComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const document = useDocState();
  const resetSelected = useSelectedReset();

  const {
    setEditorImageSession
  } = useEditorStatesSetters();

  const handleResetSelected = (event: React.PointerEvent) => {
    const logger = Logger.getEditorImage();
    logger.debug("EditorImage desk reset pointer down");

    if (event.button !== 0 ) {
      return;
    }

    const widgetEdited = document.editorImageSession.getWidgetEdited();
    if (widgetEdited === null) {
      resetSelected();
      return;
    }

    // WidgetEdited is not null
    resetSelected({skipStateUpdate: true});
    document.cellImages_image_setWidgetSelected(widgetEdited);
    setEditorImageSession();
  }

  return (
    <MainWrapper onPointerDown={handleResetSelected}>
      { children }
    </MainWrapper>
  );
}
  
