import Actions from "./actions";
import Toasts from "./toasts";


class Info {
  private _actions: Actions;
  private _toast: Toasts;

  constructor() {
    this._actions = new Actions();
    this._toast = new Toasts();
  }

  get actions() { return this._actions; }
  get toasts() { return this._toast; }
}


export default Info;