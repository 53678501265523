import * as Defaults from './defaults';
import * as Types from "./types"


/**
 * State
 */
export type State = Types.Navbar;


/**
 * Getters
 */
export const getNavbar = (state: State) => state;


/**
 * Create Init State
 */
export const createInitialState = (): State => {
  const initState: State = Defaults.getNavbar();
  return initState;
}
