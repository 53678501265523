import React from 'react';
import Progress from 'lego/styles/progress';
import { useThemeAccentColor } from 'app/ui/components/app/theme/use-theme-accent-color';


interface Props {
  size?: number;
}


export const ProgressComponent: React.FC<Props> = (props: Props) => {
  const accentColor = useThemeAccentColor();
  const color = accentColor.primary;

  const styleSize = (
    props.size !== undefined ? {
      width: `${props.size}px`,
      height: `${props.size}px`,
    } : {}
  );

  return (
    <Progress
      style={{
        color,
        ...styleSize
      }}
    />
  );
}
  
