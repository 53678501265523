
import React from 'react';

import { ViewTypes } from 'app/arch/home-page-user/states/view';
import { NavbarTypes } from "app/arch/home-page-user/states/navbar";

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useNavbarItemSelect from "app/ui-v2/home-page/home-page-user/hooks/use-navbar-item-select";
import useViewSelect from "app/ui-v2/home-page/home-page-user/hooks/use-view-select"
import Icon from 'app/ui/icons/icons';

import { Button } from './styles';
import { Text } from './styles';

interface Props {
}


export const ButtonBackComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const t = useTranslations();
  const selectView = useViewSelect();
  const selectNavbarItem = useNavbarItemSelect();
  const IconBack = Icon.Back;

  const handleBack = () => {
    selectNavbarItem(NavbarTypes.NavbarItem.DOCUMENTS);
    selectView(ViewTypes.ViewItem.DOCUMENTS);
  }


  return (
    <Button onClick={handleBack}>
      <IconBack />
      <Text>
        { t('documents') }
      </Text>
    </Button>
  );
}

