import React from 'react';
import FeatureComponent from './feature';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { MainWrapper } from './styles';
import { Title } from './styles';
import { OutroWrapper } from './styles';
import { Features } from './styles';


interface Props {
}


export const PanelTextComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const t = useTranslations();

  return (
    <MainWrapper>
      {/* <Title>
        { t('demo view, panel run demo, title')}
      </Title> */}

      <Features>
      <FeatureComponent feature={ t("demo view, panel run demo, feature, no registraion") } />
      <FeatureComponent feature={ t("demo view, panel run demo, feature, no fees") } />
      <FeatureComponent feature={ t("demo view, panel run demo, feature, no hassle") } />
      </Features>

      <OutroWrapper>
        <Title>
          { t('demo view, panel run demo, click & play') }
        </Title>
      </OutroWrapper>
    </MainWrapper> 
  );
}

