import jtl from "tools/jtl";
import { Size } from "app/arch/types";
import { Page } from "app/arch/print/page";
import environment from "app/environment";
import { uuid } from "tools/uuid";
import DocState from '../../doc-state';
import { Releaselogs } from "./releaselogs";
import * as Types from './types';


/**
 * 
 * Releaselog
 * 
 */

export const getReleaselogKey = (
  releaselogAddr: Types.ReleaselogAddr
): string => (
  `${releaselogAddr.releaselogId}`
);

export const createReleaselogAddr = (
): Types.ReleaselogAddr => {
  const releaselogId = uuid();
  const releaselogAddr = { releaselogId };
  return releaselogAddr;
}   

export const compareReleaselogAddr = (
  srcReleaselogAddr: Types.ReleaselogAddr,
  dstReleaselogAddr: Types.ReleaselogAddr,
): boolean => (
  srcReleaselogAddr.releaselogId === dstReleaselogAddr.releaselogId
);


/**
 * 
 * Column
 * 
 */

export const getColumnKey = (
  columnAddr: Types.ColumnAddr
): string => (
  `${columnAddr.releaselogId}::` +
  `${columnAddr.columnId}`
);

export const createColumnAddr = (
  releaselogAddr: Types.ReleaselogAddr
): Types.ColumnAddr => {
  const columnId = uuid();
  const columnAddr = { 
    releaselogId: releaselogAddr.releaselogId,
    columnId 
  };
  
  return columnAddr;
} 

export const compareColumnAddr = (
  srcColumnAddr: Types.ColumnAddr,
  dstColumnAddr: Types.ColumnAddr,
): boolean => (
      srcColumnAddr.releaselogId === dstColumnAddr.releaselogId
  && srcColumnAddr.columnId     === dstColumnAddr.columnId
);

export const getColumnDataTest = (props: {
  columnAddr: Types.ColumnAddr, 
  docState: DocState,
  prefix: string,
}): string => {
  if ( ! environment.dev) {
    return '';
  }

  const {
    columnAddr,
    docState,
    prefix
  } = props;

  const releaselogIdx = docState.releaselogs.getReleaselogIdx(columnAddr);
  const columnIdx = docState.releaselogs.getColumnIdx(columnAddr);

  return `${prefix}-${releaselogIdx}-${columnIdx}`;
}

/**
 * 
 * Row
 * 
 */

export const getRowKey = (
  rowAddr: Types.RowAddr
): string => (
  `${rowAddr.releaselogId}::` +
  `${rowAddr.rowId}`
);

export const createRowAddr = (
  releaselogAddr: Types.ReleaselogAddr
): Types.RowAddr => {
  const rowId = uuid();
  const rowAddr = { 
    releaselogId: releaselogAddr.releaselogId,
    rowId 
  };
  return rowAddr;
} 

export const compareRowAddr = (
  srcRowAddr: Types.RowAddr,
  dstRowAddr: Types.RowAddr,
): boolean => (
      srcRowAddr.releaselogId === dstRowAddr.releaselogId
  && srcRowAddr.rowId        === dstRowAddr.rowId
);


/**
 * 
 * Cell
 * 
 */

export const getCellKey = (
  cellAddr: Types.CellAddr
): string => (
  `${cellAddr.releaselogId}::` +
  `${cellAddr.columnId}::`     +
  `${cellAddr.rowId}`
);

export const compareCellAddr = (
  srcCellAddr: Types.CellAddr,
  dstCellAddr: Types.CellAddr,
): boolean => (
      srcCellAddr.releaselogId === dstCellAddr.releaselogId
  && srcCellAddr.columnId     === dstCellAddr.columnId
  && srcCellAddr.rowId        === dstCellAddr.rowId
);

export const getCellDataTest = (props: {
  cellAddr: Types.CellAddr, 
  docState: DocState,
  prefix: string,
}): string => {
  if ( ! environment.dev) {
    return '';
  }

  const {
    cellAddr,
    docState,
    prefix
  } = props;

  const releaselogIdx = docState.releaselogs.getReleaselogIdx(cellAddr);
  const columnIdx     = docState.releaselogs.getColumnIdx(cellAddr);
  const rowIdx        = docState.releaselogs.getRowIdx(cellAddr);
    
  return `${prefix}-${releaselogIdx}-${columnIdx}-${rowIdx}`;
}

/**
 * 
 * Other
 * 
 */

export const columnsSmartAdjust = (
  document: DocState,
  releaselogAddr: Types.ReleaselogAddr
) => {
  const columnsAddrs = document.releaselogs.getColumnsAddrs(releaselogAddr)

  const indexColumnAddr = columnsAddrs.filter((columnAddr) => 
    document.releaselogs.isColumnIndexType(columnAddr)
  )[0];
  const indexColumnProps = document.releaselogs.getColumnProps(indexColumnAddr);


  const columnsNoIdxAddrs = columnsAddrs.filter((columnAddr) => 
    ! document.releaselogs.isColumnIndexType(columnAddr)
  );
  const columnsNoIdxProps = columnsNoIdxAddrs.map((columnAddr) => 
    document.releaselogs.getColumnProps(columnAddr)
  );

  const pageLayout  = document.viewsCommon.getPageLayout();
  const pageMargins = document.viewsCommon.getPageMargins();

  const contentSize = Page.getBodySizePx(
    pageLayout.format, 
    pageLayout.orientation, 
    pageMargins
  ) as Size;

  const totalWidth        = contentSize[0] - indexColumnProps.width;
  const columnsWidthTotal = columnsNoIdxProps.reduce(
    (accumulator, columnProps) => accumulator + columnProps.width, 
    0
  );

  const deltaWidth        = totalWidth - columnsWidthTotal;
  const columnsWidthRatio = columnsNoIdxProps.map((columnProps) => columnProps.width / columnsWidthTotal);
  const columnsWidtInit   = columnsNoIdxProps.map((columnProps) => columnProps.width);

  columnsNoIdxAddrs.forEach((columnAddr, idx) => {

    const widthInit     = columnsWidtInit[idx];
    const deltaRatioed  = columnsWidthRatio[idx] * deltaWidth;
    const widthAdjusted = widthInit + deltaRatioed;
    const update = { width: widthAdjusted };

    document.releaselogs.updateColumnProps(columnAddr, update);
  });
}

export const getReleaseDateIso = () => {
  const now = new Date();
  const isoDate = now.toISOString()

  return isoDate;
}

export const getChangelogRows_editableAdjusted =(
  docReleaselogs: Releaselogs,
  releaselogAddr: Types.ReleaselogAddr, 
  isPrintout: boolean
) => {
  const rowsAddrs = docReleaselogs.getRowsAddrs(releaselogAddr);
  const isEditabled = docReleaselogs.isReleaselogEditable(releaselogAddr);

  let ret: Types.RowsAddrs = [];

  if ( isPrintout && isEditabled ) {
    ret = rowsAddrs.slice(0, -1);
  }
  else {
    ret = rowsAddrs;
  }

  return ret;
}
