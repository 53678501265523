import React from 'react';

import ImagesComponent from './images';
import ImagesUploadingComponent from './images-uploading';

import { Grid } from './styles';


interface Props {
}


export const ImagesPanelComponent: React.FC<Props> = (props: Props) => {

  return (
    <Grid>
      <ImagesComponent />
      <ImagesUploadingComponent />
    </Grid>
  );
}
  
