import React from 'react';

import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';

import LoaderTranslationComponent  from './loader-translation';
import LoaderUserLoggedComponent   from './loader-user-logged';
import LoaderReposComponent        from './loader-repos';
import LoaderUserSettingsComponent from './loader-user-settings';
import LoaderDocTemplatesComponent from './loader-doc-templates';
import LoaderRemoveLoaderComponent from './loader-remove-loader';
import LoaderLoadingFinishedComponent from './loader-loading-finished';
import LoaderUserSubscriptionsComponent from './loader-user-subscriptions';
import LoaderBrowserSupportCheckComponent from './loader-browser-support-check';
import LoaderUserSubscriptionsUpdateComponent from '../loaders-simple/loader-user-subscriptions-update';
import LoaderFeatureFlagComponent from './loader-feature-flag';


interface Props {
  children: React.ReactNode;
}


export const LoadersOrderedComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const isPrintout =  useIsPrintout();

  const renderEdit = () => (
    <LoaderTranslationComponent>
      <LoaderBrowserSupportCheckComponent>
        <LoaderUserLoggedComponent>
          <LoaderReposComponent>
            <LoaderUserSettingsComponent>
              <LoaderFeatureFlagComponent>
                <LoaderUserSubscriptionsComponent>
                  <LoaderDocTemplatesComponent>
                    <LoaderLoadingFinishedComponent>
                      <LoaderUserSubscriptionsUpdateComponent />
                      <LoaderRemoveLoaderComponent />
                      { children }
                    </LoaderLoadingFinishedComponent>
                  </LoaderDocTemplatesComponent>
                </LoaderUserSubscriptionsComponent>
              </LoaderFeatureFlagComponent>
            </LoaderUserSettingsComponent>
          </LoaderReposComponent>
        </LoaderUserLoggedComponent>
      </LoaderBrowserSupportCheckComponent>
    </LoaderTranslationComponent>
  );

  const renderPrintout = () => (
    <LoaderTranslationComponent>
      <LoaderRemoveLoaderComponent />
      <LoaderUserLoggedComponent>
        <LoaderReposComponent>
          <LoaderUserSettingsComponent>
            <LoaderFeatureFlagComponent>
              <LoaderUserSubscriptionsComponent>
                {/* <LoaderDocTemplatesComponent> */}
                  <LoaderLoadingFinishedComponent>
                    { children }
                  </LoaderLoadingFinishedComponent>
                {/* </LoaderDocTemplatesComponent> */}
              </LoaderUserSubscriptionsComponent>
            </LoaderFeatureFlagComponent>
          </LoaderUserSettingsComponent>
        </LoaderReposComponent>
      </LoaderUserLoggedComponent>
    </LoaderTranslationComponent>
  );

  const render = (
    isPrintout ?
    renderPrintout :
    renderEdit
  );

  return render();
}
  
