import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import jtl from 'tools/jtl';

import InputComponent from 'app-views/components/input';

import { mutation } from 'app/arch/backend';
import { useMutation } from 'app/arch/backend/use-mutation';
import { ViewTypes } from 'app/arch/home-page-guest/states/view';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { MsgDuration } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import ViewFormComponent from 'app/ui-v2/home-page/hls/view-form';
import useViewSelect from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-select';


interface Props {
  email: string;
}


export const RequestPasswordResetComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const selectView = useViewSelect();

  const [email, setEmail] = useState(props.email);
  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    const emailValid = jtl.email.isValid(email);
    if (emailValid !== isEmailValid) {
      setIsEmailValid(emailValid);
    }
  }, []);

  const { 
    mutation: createUserPasswordToken,
    data: dataCreateUserPasswordToken
  } = useMutation(
    mutation.createUserPasswordToken,
    {
      onStart: {
        msg:  t("requesting password token"),
      },
      onEnd: 
      {
        msg: t("password token sent"),
        duration: MsgDuration.MIDDLE
      }
    }
  );

  useEffect(() => {
    if (dataCreateUserPasswordToken === undefined) {
      return;
    }
    if ('userPasswordTokenCreate' in dataCreateUserPasswordToken === false) {
      return;
    }

    const data = dataCreateUserPasswordToken.userPasswordTokenCreate;
    if ( ! data.ok ) {
      return;
    }

    selectView(ViewTypes.ViewItem.PASSWORD_RESET_LINK_SENT);
  }, [dataCreateUserPasswordToken]);


  const handleEmailChange = (email: string) => {
    setEmail(email);
    setIsEmailValid(jtl.email.isValid(email));
  };

  const handleSubmit = () => {
    createUserPasswordToken({ 
      variables: { email }
    });
  };


  return (
    <ViewFormComponent
      title={t('forgot user password')}
      formButtonText={t('ok')}
      submitDisabled={! isEmailValid}
      onSubmit={handleSubmit}
    >
      <InputComponent
        title={t('enter your email')}
        value={email}
        onChange={handleEmailChange}
      />
    </ViewFormComponent>
  );
}
