import * as Types from './types';


export const getToasts = (): Types.Toasts => ({
  addrs: [],
  props: {}
});

export const getToastProps = (
  props: Types.ToastPartialProps
): Types.ToastProps => ({
  level: (
    props.level !== undefined ?
    props.level :
    Types.ToastLevel.INFO
  ),
  duration: (
    props.duration !== undefined ?
    props.duration :
    Types.ToastDuration.LONG
  ),
  text: props.text
});