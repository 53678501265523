import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDialogCustom } from 'app/ui/components/editor-instruction/dialogs/dialog-custom';
import { DialogCustomTypes } from 'app/ui/components/editor-instruction/dialogs/dialog-custom';

import useExcelExport   from './use-excel-export';


const useExcelExportDialog = () => {
  const t = useTranslations();
  const exportXLS = useExcelExport();
  
  const {
    show: showCustom,
  } = useDialogCustom();

  const showDialog = () => {
    const title = t('export dialog, title');
    const message = t('export dialog, message');
    const icon = DialogCustomTypes.IconType.QUESTION;

    showCustom({
      title,
      message,
      icon,
      buttons: [
        {
          text: t("export dialog, btn xls"),
          onClick: exportXLS
        },
        {
          text: t("cancel"),
        },
      ]        
    });
  }


  const hook = () => {
    showDialog();
  }

  return hook;
};


export default useExcelExportDialog;