import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';

import CellIndexValueComponent     from '../../cell-index-value';
import CellIndexColumnCssComponent from '../../cell-index-column-css';

import { MainWrapper } from './styles';


interface Props {
  cellAddr: ContentTypes.CellAddr;
}


export const CellIndexDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const { 
    cellAddr, 
  } = props;

  const document = useDocState();
  const width = document.content.getColumnWidth(cellAddr);

  return (
    <MainWrapper $width={width}>
      <CellIndexColumnCssComponent cellAddr={cellAddr} >
        <CellIndexValueComponent cellAddr={cellAddr} />
      </CellIndexColumnCssComponent>
    </MainWrapper>
  );
}
  
