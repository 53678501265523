import Logger from 'libs/debug';

import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/releaselogs/types';

import PageComponent from "../components/content/page";
import useSlicer from "./use-slicer";


interface Props {
  onPagesDefined: (pages: React.ReactElement[]) => void;
}


const usePageContentGenerate = (props: Props) => {
  const {
    onPagesDefined
  } = props;

  const log = Logger.getChangelogSlicer();

  const handleSliced = (pages: SlicerTypes.Pages) => {
    log.debug("Handle pages redefs");

    const pagesComps: React.ReactElement[] = pages.map((page, idx) => (
      <PageComponent
        key={idx}
        page={page}
      />
    ));

    onPagesDefined(pagesComps);
  }

  useSlicer({
    onSliced: handleSliced,
  });

}

export default usePageContentGenerate;