import React from 'react';

import { useDocState } from 'app/ui/contexts/document';
import ProbeHeightComponent from 'app/ui-v2/editor-instruction/views/view-changelog/components/content/page/probe-height';


interface Props {
  children: React.ReactNode;
}


export const SpacerProbeComponent: React.FC<Props> = (props: Props) => {
  const {
    children 
  } = props;

  const document = useDocState();

  const handleHeightUpdate = (height: number) => {
    const elementsSizes = document.releaselogsElementsSizes;
    elementsSizes.releaselogs.setSpacerHeight(height);
  }

  return (
    <ProbeHeightComponent
      enabled={true}
      onHeightUpdate={handleHeightUpdate}
    >
      { children }
    </ProbeHeightComponent>
  );
}
  
