import * as Defaults from './defaults';
import * as Types from './types';


/**
 * State
 */

export type State = {
  user: Types.User,
  company: Types.Company,
  theme: Types.Theme,
  repo: Types.Repo,
};


/**
 * Getters
 */

export const getUser = (state: State) => state.user;
export const getCompany = (state: State) => state.company;
export const getTheme = (state: State) => state.theme;
export const getRepo = (state: State) => state.repo;


/**
 * Initial state
 */

export const createInitialState = (): State => {
  const stateInit: State = {
    user: Defaults.getUser(),
    company: Defaults.getCompany(),
    theme: Defaults.getTheme(),
    repo: Defaults.getRepo(),
  }

  return stateInit;
}
