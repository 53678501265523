import styled from "styled-components";


interface TitleProps {
  $color: string;
}

export const Title = styled.div<TitleProps>`
  background-color: ${props => props.$color};
  padding:     
    ${props => props.theme.defs.padding.large}
    ${props => props.theme.defs.padding.large}
    ${props => props.theme.defs.padding.large}
    ${props => props.theme.defs.padding.large}
  ;

  color: ${props => props.theme.defs.colors.color.secondary};

  font-size:   ${props => props.theme.defs.font.size.xxxlarge};
  font-weight: ${props => props.theme.defs.font.weight.medium};
`;
