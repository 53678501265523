import ViewsComponent   from "./views"
import TopMenuComponent from "./top-menu";

// TODO
// Move these comps out of views
import LayerControlsComponent from './views/layer-controls';
import MenuTopRightComponent  from './views/controls/menu-top-right';

import DocumentStatesComponent from "app/ui-v2/editor-instruction/__document/components/document-states";

import { useIsPrintout } from "../use-is-printout";

import { TopMenuWrapper }       from "./styles";
import { MenuTopRightWrapper }  from "./styles";
import { LayerControlsWrapper } from "./styles";


interface Props {
}


export const DocumentComponent: React.FC<Props> = (props: Props) => {
  const isPrintout = useIsPrintout();
  
  const renderPanels = () => (
    <>
      <TopMenuWrapper>
        <TopMenuComponent />
      </TopMenuWrapper>

      <MenuTopRightWrapper>
        <MenuTopRightComponent />
      </MenuTopRightWrapper>

      <LayerControlsWrapper>
        <LayerControlsComponent />
      </LayerControlsWrapper>
    </>
  );


  return (
    <>
      { ! isPrintout && renderPanels() }
      <DocumentStatesComponent>
        <ViewsComponent />
      </DocumentStatesComponent>
    </>
  );
}
