import React from 'react';
import ViewTitledComponent from 'app/ui-v2/home-page/hls/view-titled';
import { PanelDefault } from './styles';


interface Props {
  title: string;
  Panel?: React.ElementType;
  Content?: React.ElementType;
  children: React.ReactNode;
}


export const ViewPanelComponent: React.FC<Props> = (props: Props) => {
  const { 
    title, 
    children,
    Panel: PanelUser,
    Content: ContentUser,
  } = props;

  const Panel = PanelUser || PanelDefault;
  
  return (
    <ViewTitledComponent 
      title={title} 
      Content={ContentUser}
    >
      <Panel>
        { children }
      </Panel>
    </ViewTitledComponent>
  );
}
