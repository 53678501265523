import { useSetRecoilState } from 'recoil';
import { UIState_ContentSignals } from 'app/ui/states/editor-instruction';
import useColumnWidthSignalBase from 'app/ui-v2/editor-instruction/views/shared/hooks/use-columns-width-signal';


const useColumnsWidthSignal = () => {
  const signalColumnsWidths = useSetRecoilState(UIState_ContentSignals.columnsWidths);
  const signal = () => signalColumnsWidths({});
  const hook = useColumnWidthSignalBase({ signal });

  return hook;
}

export default useColumnsWidthSignal;
