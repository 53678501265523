import React from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';

import jtl from 'tools/jtl';

import { settings } from 'app/configs';
import environment  from 'app/environment';

import FormPanelComponent from 'app-views/components/form-panel';
import InputComponent     from 'app-views/components/input';

import { ViewTypes } from 'app/arch/home-page-guest/states/view';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useAppLogin from 'app/ui/hooks/app/use-app-login';
import useViewSelect    from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-select';
import useViewParamsSet from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-params-set';

import { ForgotPassword }       from "./styles";
import { ForgotPasswordAnchor } from "./styles";
import { Panel }                from "./styles";


interface Props {
  onLogin?: () => void;
  onLoginError?: (error: string) => void;
}


export const LoginFormComponent: React.FC<Props> = (props: Props) => {
  const {
    onLogin,
    onLoginError,
  } = props;

  const t = useTranslations();

  const debug        = environment.debug;
  const demoUser     = settings.demo.user;
  const emailInit    = debug ? demoUser.email    : '';
  const passwordInit = debug ? demoUser.password : '';
  
  const [email, setEmail] = useState<string>(emailInit);
  const [password, setPassword] = useState<string>(passwordInit);
  const [isDataValid, setIsDataValid] = useState(false);

  const appLogin = useAppLogin();
  const selectView = useViewSelect();
  const setViewParams = useViewParamsSet();
  
  useEffect(() => {
    checkData(email, password);
  }, []);

  
  const handleEmailChange = (email_: string) => {
    setEmail(email_);
    checkData(email_, password)
  };

  const handlePasswordChange = (password_: string) => {
    setPassword(password_);
    checkData(email, password_)
  };

  const handleSubmit = () => {
    const callbacks = {
      onDone: () => { onLogin?.(); },
      onError: (error: string) => { onLoginError?.(error); },
    }

    appLogin(email, password, callbacks);
  };

  const checkData = (email: string, password: string) => {
    if ( ! jtl.email.isValid(email) ) {
      setIsDataValid(false);
      return;
    }

    if ( ! jtl.password.isValid(password) ) {
      setIsDataValid(false);
      return;
    }

    setIsDataValid(true);
  }

  const handleForgotPassword = (event: any) => {
    selectView(ViewTypes.ViewItem.PASSWORD_RESET_REQUEST_FORM);
    setViewParams({email});
  }

  return (
    <Panel>
      <FormPanelComponent
        buttonText={t('login')}
        onSubmit={handleSubmit}
        submitDisabled={! isDataValid}
      >

        <InputComponent
          title={t("email")}
          type="email"
          value={email}
          onChange={handleEmailChange}
        />

        <InputComponent
          title={t("password")}
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />

        <ForgotPasswordAnchor>
          <ForgotPassword
            onClick={handleForgotPassword}
            type="button"
          >
            {t('forgot password')}
          </ForgotPassword>
        </ForgotPasswordAnchor>

      </FormPanelComponent>
    </Panel>
  );
}
