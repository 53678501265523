import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Logger from 'libs/debug';
import urls from 'app/configs/urls';

import { ViewTypes as GuestViewTypes } from 'app/arch/home-page-guest/states/view';
import useGuestViewSelect from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-select';

import { ViewTypes as UserViewTypes } from 'app/arch/home-page-user/states/view';
import useUserViewSelect from 'app/ui-v2/home-page/home-page-user/hooks/use-view-select';

import { useAuth } from 'services/auth';


const useLegalRedirect = (props: {
  viewGuest: GuestViewTypes.ViewItem,
  viewUser:  UserViewTypes.ViewItem,
}) => {
  const {
    viewGuest,
    viewUser,
  } = props;

  const auth = useAuth();
  const navigate = useNavigate();
  const selectGuestView = useGuestViewSelect();
  const selectUserView  = useUserViewSelect();

  useEffect(() => {
    if (auth.isLogged()) {
      selectUserView(viewUser);
    }
    else {
      selectGuestView(viewGuest);
    }

    navigate(urls.home);
  }, []);
}


export default useLegalRedirect;