import React from 'react';

import { DraggerProps }  from 'lego-v2/dragger/ui/Dragger';
import { DraggerUpdate } from 'lego-v2/dragger/arch';
import DraggerSmartLinesComponentBase from 'lego/components/dragger-smart-lines';

import { SmartLinesWidgets } from 'tools/smart-lines/smart-lines-widgets';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState } from 'app/ui/contexts/document';
import useSmartLinesWidgetsLines from 'app/ui-v2/editor-image/hooks/smart-lines/use-smart-lines-widgets-lines';

import { useSmartLinesWidgetsLinesSetter }       from '../../../../hooks/smart-lines/use-smart-lines-widgets-lines-setter';
import { useSmartLinesWidgetsStickyLinesSetter } from '../../../../hooks/smart-lines/use-smart-lines-widgets-sticky-lines-setter';
import { useSmartLinesWidgetsSrcLinesSetter }    from '../../../../hooks/smart-lines/use-smart-lines-widgets-src-lines-setter';
import { ContentTools, ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { Position } from 'app/arch/types';


interface Props extends DraggerProps {
  widgetAddr: Types.WidgetAddr;
}


export const DraggerSmartLinesComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
  } = props;


  const document = useDocState();
  const smartLines = useSmartLinesWidgetsLines();

  const {
    setSmartLines,
    unsetSmartLines
  } = useSmartLinesWidgetsLinesSetter();

  const {
    setStickyLines,
    unsetStickyLines,
  } = useSmartLinesWidgetsStickyLinesSetter();

  const {
    setSrcLines,
    unsetSrcLines,
  } = useSmartLinesWidgetsSrcLinesSetter();


  const handleSetSmartLines = () => {
    const widgetsAddrs = document.content.cellImages_image_getWidgetsAddrs(widgetAddr);
    setSmartLines({
      srcWidgetsAddrs: [widgetAddr], 
      dstWidgetsAddrs: widgetsAddrs
    });
  }

  const handleSrcSmartLinesRequest = (update: DraggerUpdate) => {
    const widgetProps = document.content.cellImages_image_getWidgetProps(widgetAddr);
    const widgetType = widgetProps.type;

    // TODO multiselection
    const position = update.position;
    let ok = false;

    const srcSmartLines = new SmartLinesWidgets();

    if (ContentTools.isWidgetArrowText(widgetType)) {
      const arrrowProps = widgetProps as ContentTypes.WidgetArrowTextProps;
      const tailSize = arrrowProps.tailSize;

      const endPoint = [
        position[0] + tailSize[0] / 2,
        position[1] + tailSize[1] / 2,
      ] as Position;

      ok = srcSmartLines.loadWidget(
        widgetType, {
        tailSize, 
        endPoint, 
        style: widgetProps.style
      });
    }
    else if (ContentTools.isWidgetBoxed(widgetType)) {
      const boxProps = widgetProps as ContentTypes.WidgetBoxedProps;
      const size = boxProps.size;

      ok = srcSmartLines.loadWidget(
        widgetType, {
        size, 
        position, 
        style: widgetProps.style
      });
    }
    else {
      console.warn(`Widget type not implemented ${widgetType}`);
    }

    if ( ! ok ) {
        console.warn(`Problem with smart lines for widget type ${widgetProps.type}`);
    }

    setSrcLines(srcSmartLines.raw);
    return srcSmartLines.raw;
  }


  return (
    <DraggerSmartLinesComponentBase
      controlProps={props}

      smartLines={smartLines}
      onSrcSmartLinesRequest={handleSrcSmartLinesRequest}

      onSetSmartLines={handleSetSmartLines}
      onUnsetSmartLines={unsetSmartLines}

      onSetStickyLines={setStickyLines}
      onUnsetStickyLines={unsetStickyLines}
    />
  );
}
  