import React from 'react';

import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import MarkerDraggerComponent      from './marker-dragger';
import MarkerEditableViewComponent from './marker-editable-view';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
}


export const MarkerViewComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr
  } = props;

  return (
    <MarkerDraggerComponent markerAddr={markerAddr} >
      <MarkerEditableViewComponent markerAddr={markerAddr} />
    </MarkerDraggerComponent>
  );
}
  
