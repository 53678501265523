import { ThemeDefProps } from "../types";


export const themeDefs_panelFloating = (props: ThemeDefProps) => {
  const { colors, accentColor, border, padding, boxShadow } = props;

  const panelFloating = {
    background: colors.background.primary,
    padding: 0,

    border: {
      style: 'solid',
      color:  colors.border.primary,
      radius: border.radius.xsmall,
      width:  border.width.normal,
    },

    boxShadow: boxShadow.primary,
  };
    
  return panelFloating;
}

