import React from 'react';

import { PanelControlsItemSectionControlConfig as Config } from '../../../config';
import { PanelControlsItemSectionItemType                } from '../../../config';
import { PanelControlsItemSectionControl_UserComponent   } from '../../../config';

import { unpackBoolean } from '../../../tools';

import ControlButtonComponent        from '../../../controls/control-button';
import ControlButtonGroupComponent   from '../../../controls/control-button-group';
import ControlColorPickerComponent   from '../../../controls/control-color-picker';
import ControlDropdownListComponent  from '../../../controls/control-dropdown-list';
import ControlInputComponent         from '../../../controls/control-input';
import ControlListComponent          from '../../../controls/control-list';
import ControlProgressComponent      from '../../../controls/control-progress';
import ControlSliderComponent        from '../../../controls/control-slider';
import ControlSpacerComponent        from '../../..//controls/control-spacer';
import ControlTextComponent          from '../../../controls/control-text';
import ControlTextMultilineComponent from '../../../controls/control-text-multiline';
import ControlToolbarComponent       from '../../../controls/control-toolbar';
import ControlItemsMixComponent      from '../../../controls/control-items-mix';


interface Props {
  config: Config;
  sectionDisabled: boolean;
  Item: React.ElementType;
}


export const PanelSectionItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    config,
    sectionDisabled,
    Item,
  } = props;

  const getUserComponent = () => {
    const userConfig = config as PanelControlsItemSectionControl_UserComponent;
    return userConfig.component;
  }

  const getUserComponentProps = () => {
    const userConfig = config as PanelControlsItemSectionControl_UserComponent;
    return userConfig.componentProps;
  }

  const Items = {
    [PanelControlsItemSectionItemType.BUTTON        ]: ControlButtonComponent,
    [PanelControlsItemSectionItemType.BUTTON_GROUP  ]: ControlButtonGroupComponent,
    [PanelControlsItemSectionItemType.COLOR_PICKER  ]: ControlColorPickerComponent,
    [PanelControlsItemSectionItemType.DROPDOWN_LIST ]: ControlDropdownListComponent,
    [PanelControlsItemSectionItemType.INPUT         ]: ControlInputComponent,
    [PanelControlsItemSectionItemType.LIST          ]: ControlListComponent,
    [PanelControlsItemSectionItemType.PROGRESS      ]: ControlProgressComponent,
    [PanelControlsItemSectionItemType.SLIDER        ]: ControlSliderComponent,
    [PanelControlsItemSectionItemType.SPACER        ]: ControlSpacerComponent,
    [PanelControlsItemSectionItemType.TEXT          ]: ControlTextComponent,
    [PanelControlsItemSectionItemType.TEXT_MULTILINE]: ControlTextMultilineComponent,
    [PanelControlsItemSectionItemType.TOOLBAR       ]: ControlToolbarComponent,
    [PanelControlsItemSectionItemType.ITEMS_MIX     ]: ControlItemsMixComponent,
    [PanelControlsItemSectionItemType.USER_COMPONENT]: getUserComponent(),
  }

  const ItemComponent = Items[config.type];
  const itemProps     = (
    config.type === PanelControlsItemSectionItemType.USER_COMPONENT ?
    getUserComponentProps() :
    {}
  );
  const itemDisabled  = unpackBoolean(config.disabled);

  return (
    <Item 
      config={config}
      sectionDisabled={sectionDisabled}
      itemDisabled={itemDisabled}
      ItemComponent={ItemComponent}
      itemProps={itemProps}
    />
  );
}
