import { settings } from "app/configs";
import styled from "styled-components";


export const MainWrapper = styled.div`
  position: absolute;
  height: 100%;

  pointer-events: all;
  touch-action: none;
`;


const WIDTH_MOBILE = settings.resizerLine.width.mobile;
const WIDTH_DESKTOP = settings.resizerLine.width.desktop;

export const ActiveArea = styled.div<{$debug?: boolean, $mobile: boolean}>`
  position: absolute;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  translate: -50% 0;
  width: ${props => 
    props.$mobile ? 
    `${WIDTH_MOBILE}px` : 
    `${WIDTH_DESKTOP}px`
  };
  height: 100%;

  cursor: ew-resize;

  background-color: ${props => props.$debug ? 'rgba(255, 0, 0, 0.3)' : 'none'};
`;

export const MarkerLine = styled.div<{$visible: boolean}>`
  width: 1px;
  height: 100%;

  pointer-events: none;
  
  background-color: ${props => props.theme.defs.accentColor.primary};
  visibility: ${props => props.$visible ? "visible" : "hidden"};
`;


const MARK_WIDTH  = 6;
const MARK_HEIGHT = 30;

export const Mark = styled.div`
  position: absolute;
  display: block;
  box-sizing: border-box;

  pointer-events: none;
  user-select: none;
  
  width:  ${MARK_WIDTH}px;
  height: ${MARK_HEIGHT}px;

  border-radius: 5px;
  border-width:  1px;
  border-style:  solid;
  border-color:  ${props => props.theme.defs.accentColor.darkest};
  
  background-color: ${props => props.theme.defs.accentColor.primary};
`;
