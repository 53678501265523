import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig }   from 'lego-v2/menu/config';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { ContentSessionTypes as TypesSession } from 'app/arch/editor-instruction/document/states/sessions/content-session';

import { Icon }            from "app/ui/icons/icons";
import { useDocState }     from "app/ui/contexts/document";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";

import useContextElement          from "../../../../../hooks/use-context-element";
import ColumnCreatePanelComponent from "../../../../../components/column-create-panel";

import useSelectedReset     from '../use-selected-reset';
import useColumnSelectedSet from './use-column-selected-set';
import useColumnsAutoAdjust from '../columns/use-columns-auto-adjust';
import useColumnVisibleSet  from './use-column-visible-set';
import useSidetoolbarPropertiesShow from '../../use-sidetoolbar-properties-show';


const useColumnMenu = () => {
  const t = useTranslations();
  const document = useDocState();
  const columnsAutoAdjust = useColumnsAutoAdjust();

  const {
    hideMenu
  } = useContextMenu();

  const {
    showElement,
  }  = useContextElement();

  const setColumnVisible = useColumnVisibleSet();

  const resetSelected = useSelectedReset();
  const setColumnSelected = useColumnSelectedSet();
  const showSideToolbarProps = useSidetoolbarPropertiesShow();

  const addColumn = (srcColumnAddr: Types.ColumnAddr, event: React.PointerEvent) => {
    const onDone = (newColumnAddr: Types.ColumnAddr) => {
      setColumnSelected({columnAddr: newColumnAddr});
    }

    showElement({
      Element: ColumnCreatePanelComponent, 
      ElementProps: {
        columnAddr: srcColumnAddr,
        onDone
      },
      event
    });

    hideMenu();
  }

  const deleteColumn = (columnAddr: Types.ColumnAddr) => {
    document.content.deleteColumn(columnAddr);
    columnsAutoAdjust({ debounced: false });
    document.saveUndo();

    resetSelected();
    hideMenu();
  }
  
  const hideColumn = (columnAddr: Types.ColumnAddr) => {
    setColumnVisible(columnAddr, false);
    hideMenu();
  }

  const showColumnProperties = (columnAddr: Types.ColumnAddr, event: React.PointerEvent) => {
    setColumnSelected({columnAddr});
    showSideToolbarProps(TypesSession.PanelPropertiesItem.COLUMN);
    hideMenu();
  }
  
  const getConfig = (columnAddr: Types.ColumnAddr) => {
    const columnType = document.content.getColumnType(columnAddr);
    const isIdxColumn = (columnType === Types.ColumnType.INDEX);

    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('column, add'),
                iconComponent: Icon.Create,
                onClick: (event: React.PointerEvent) =>  addColumn(columnAddr, event),
              }
            },
          ],
        },        
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('column, properties'),
                iconComponent: Icon.Settings,
                onClick: (event: React.PointerEvent) =>  showColumnProperties(columnAddr, event),
              }
            },                
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('hide'),
                iconComponent: Icon.Hide,
                onClick: () =>  hideColumn(columnAddr),
              }
            },    
          ],
        },    
        {
          skip: isIdxColumn,
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('delete'),
                variant: 'delete',
                iconComponent: Icon.Delete,
                onClick: () =>  deleteColumn(columnAddr),
              }
            },          
          ],
        },
      ]
    }

    return config;
  }


  const hook = (columnAddr: Types.ColumnAddr) => {
    const config = getConfig(columnAddr);
    return config;
  }

  return hook;
}


export default useColumnMenu;