import { useRef }    from 'react';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { ReposTypes } from 'app/arch/app/states/repos';
import { useMutation } from 'app/arch/backend/use-mutation';
import { mutation as mutationDefs } from 'app/arch/backend';

import { useApp } from 'app/ui/hooks/app/use-app';
import { UIState_Repos } from 'app/ui/states/app/repos-state';
import useDocTemplates from 'app/ui-v2/doc-templates/hooks/use-doc-templates';
import useUsername from './use-user-name';


export interface HookProps {
  repoId: number;
  onDone?: () => void;
}


export const useRepoMemberAcceptInvite = () => {
  const app = useApp();
  const username = useUsername();

  const onDoneRef = useRef<any>(null);
  const repoIdRef = useRef<number | null>(null);

  const setRepoState = useSetRecoilState(UIState_Repos.state);
  const docTemplates = useDocTemplates();
  
  
  // Mutation
  //
  const { 
    mutation,
    data: response,
    error,
  } = useMutation(mutationDefs.acceptRepoMemberInvite);

  useEffect(() => {
    if (! response) {
      return;
    }

    const data = response.repoMemberAcceptInvite;
    if (! data.ok) {
      console.warn("Can't accept repo member invitation");
      return;
    }

    const docTemplate = data.docTemplate;
    const repoId = repoIdRef.current;

    // Process doc template for the new repo
    if ( docTemplate ) {
      const templateSerial = docTemplate.content;

      if (templateSerial.length !== 0) {
        const template = JSON.parse(templateSerial);
        docTemplates.template.setTemplate(repoId !, template);
      }
      else {
        console.debug(`No template for repoId: ${repoId}`);
      }
    } 
    else {
      console.debug(`No template for repoId: ${repoId}`);
    }

    // Update status in recoil
    app.repos.setRepoMemberStatus(
      repoIdRef.current !,
      username,
      ReposTypes.MemberStatus.INVITE_ACCEPTED
    );
    setRepoState(app.repos.raw);

    onDoneRef.current?.();

    repoIdRef.current = null;
    onDoneRef.current = null;
  }, [response]);

  const hook = (props: HookProps) => {
    const {
      repoId,
      onDone,
    } = props;
  
    onDoneRef.current = onDone;
    repoIdRef.current = repoId;

    mutation({
      variables: { repoId }
    });
  }

  return hook;
}
