import { DeltaMove } from "app/ui/hooks/use-dragging-delta";
import { FrameHandlerBase } from "./frame-handler-base";
import { FrameCtrlType, FrameTargetUpdate } from "./types";


export class FrameHandlerTypical extends FrameHandlerBase {

  //--------------------------------------------------
  // Top

  protected override processTopLeft(delta: DeltaMove) {
    return this.processMatrix(
      delta.x, delta.y, 
      1, 1,
      1, 1,
      FrameCtrlType.TOP_LEFT
    );
  };  

  protected override processTopMiddle(delta: DeltaMove) {
    return this.processMatrix(
      0, delta.y, 
      0, 1,
      0, 1,
      FrameCtrlType.TOP_MIDDLE
    );
  };  

  protected override processTopRight(delta: DeltaMove) {
    return this.processMatrix(
      -delta.x, delta.y, 
      1, 1,
      0, 1,
      FrameCtrlType.TOP_RIGHT
    );
  };  


  //--------------------------------------------------
  // Middle
  protected override processLeftMiddle(delta: DeltaMove) {
    return this.processMatrix(
      delta.x, 0, 
      1, 0,
      1, 0,
      FrameCtrlType.MIDDLE_LEFT
    );
  };  

  protected override processRightMiddle(delta: DeltaMove) {
    return this.processMatrix(
      -delta.x, 0, 
      1, 0,
      0, 0,
      FrameCtrlType.MIDDLE_RIGHT
    );
  };  


  //--------------------------------------------------
  // Bottom
  protected override processBottomLeft(delta: DeltaMove) {
    return this.processMatrix(
      delta.x, -delta.y, 
      1, 1,
      1, 0,
      FrameCtrlType.BOTTOM_LEFT
    );
  };  

  protected override processBottomMiddle(delta: DeltaMove) {
    return this.processMatrix(
      0, -delta.y, 
      0, 1,
      0, 0,
      FrameCtrlType.BOTTOM_MIDDLE
    );
  };  

  protected override processBottomRight(delta: DeltaMove) {
    return this.processMatrix(
      -delta.x, -delta.y, 
      1, 1,
      0, 0,
      FrameCtrlType.BOTTOM_RIGHT
    );
  };
  

  private processMatrix(
    deltaX: number, deltaY: number, 
    multiplierSizeX: number, multiplierSizeY: number,
    multiplierPositionX: number, multiplierPositionY: number,
    ctrlType: FrameCtrlType
  ): FrameTargetUpdate {
    const deltaChecked = this.checkDeltaLimits(deltaX, deltaY);

    const width  = this.startSize[0] - deltaChecked.x * multiplierSizeX;
    const height = this.startSize[1] - deltaChecked.y * multiplierSizeY;

    const x = this.startPosition[0] + deltaChecked.x * multiplierPositionX;
    const y = this.startPosition[1] + deltaChecked.y * multiplierPositionY;
    
    // if ( multiplierPositionX === 0 && multiplierPositionY === 0 ) 
    // in this case we do not need to return position in 
    // update, as position did not change. But leaving it here
    // just for the sake of clarity.
    const update: FrameTargetUpdate = {
      control: ctrlType,
      size: [width, height],
      position: [x, y],
    };

    return update;
  }

  private checkDeltaLimits(dx: number, dy: number) {
    const dxChecked = (
      ((this.startSize[0] - dx) >= this.minSize[0]) ?
      dx : this.startSize[0] - this.minSize[0]
    );

    const dyChecked = (
      ((this.startSize[1] - dy) >= this.minSize[1]) ?
      dy : this.startSize[1] - this.minSize[1]
    );

    return {x: dxChecked, y: dyChecked};
  }
}