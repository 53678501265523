import jtl from "tools/jtl";
import { UUID }     from "app/arch/types";
import { Size }     from "app/arch/types";
import { Position } from "app/arch/types";
import { Arrow }    from 'app/arch/editor-image/types/arrows';
import { WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";
import { RepoMarkersTypes } from "../repo-markers";


/**
 * Cells
 */
export interface Cell {
}


/**
 * Index Cell
 */
export interface IndexCell extends Cell {
}


/**
 * Text Cell
 */
export interface TextCell extends Cell {
  editorState: string | null;
}


/**
 * Markers Cell
 */ 


export interface MarkersCell extends Cell {
  markers: Markers;
}
  
export type Markers = {
  addrs: MarkersAddrs,
  props: MarkersProps,
}


export type MarkerAddr = CellAddr & {
  markerId: UUID;
}

export type MarkersAddrs = MarkerAddr[];

export type MarkersProps = {
  [markerKey: string]: MarkerProps
}

export type MarkerProps = {
  repoMarkerAddr: RepoMarkersTypes.MarkerAddr;
}



/**
 * Image Cell
 */ 

export interface ImagesCell extends Cell {
  images: Images
}


/**
 * Image
 */ 

export type ImageAddr = CellAddr & {
  imageId: UUID;
}

export type ImageProps = {
  bid: number;
  viewArea: ImageViewArea;
  viewScale: number;
  widgets: Widgets,
}

export type ImagePropsUpdate = (
  Partial< Omit<ImageProps, "id" | "bid" | "widgets" > >
);

export type ImagesProps = {
  [imageKey: string]: ImageProps
}

export type ImagesAddrs = ImageAddr[];

export type Images = {
  addrs: ImagesAddrs,
  props: ImagesProps,
}


export type ImageViewArea = {
  x1: number,
  x2: number,
  y1: number,
  y2: number,
};


/**
 *  Widgets
 */ 

export enum WidgetType {
  ARROW_PLAIN = 'arrow-plain',
  ARROW_TEXT  = 'arrow-text',
  ELLIPSE     = 'ellipse',
  RECTANGLE   = 'rectangle',
  IMAGE       = 'image',
  TEXT        = 'text',
};

export interface WidgetBaseProps {
  type: WidgetType
  name: string;
  locked: boolean;
  style: WidgetsStylesTypes.StyleAttrs;
  css: React.CSSProperties,
}

export interface WidgetBasePropsUpdate extends Partial<WidgetBaseProps> {
}

export interface WidgetBoxedProps extends WidgetBaseProps {
  size: Size;
  position: Position;
}

export interface WidgetBoxedPropsUpdate extends Partial<WidgetBoxedProps> {
}

export interface WidgetImageProps extends WidgetBoxedProps {
  type: WidgetType.IMAGE,
  // This is redundatnt
  // This data is already in CellImage
  bid: number;
  // All of it is needed, because of 
  // image clipping
  imageSize: Size;
  imagePosition: Position;
  imageClipInset: ImageClipInset;
}

export interface WidgetImagePropsUpdate extends Partial<
  Omit<WidgetImageProps, "type" | "bid" >
> {
}

export interface WidgetRectangleProps extends WidgetBoxedProps {
  type: WidgetType.RECTANGLE,
}

export interface WidgetEllipseProps extends WidgetBoxedProps {
  type: WidgetType.ELLIPSE,
}

export interface WidgetArrowProps extends WidgetBaseProps {
  type: WidgetType.ARROW_PLAIN,
  startPoint: Position;
  endPoint: Position;
}

export interface WidgetArrowPropsUpdate extends Partial<
  Omit<WidgetArrowProps, "type" >
> {
}

export interface WidgetArrowTextProps extends Omit<WidgetArrowProps, "type"> {
  type: WidgetType.ARROW_TEXT,
  tailSize: Size;
  jointed: boolean;
  pointer: Arrow.PointerType;
  editorTextState: string;
}

export interface WidgetArrowTextPropsUpdate extends Partial<
  Omit<WidgetArrowTextProps, "type" | "editorTextState" >
> {
}

export interface WidgetTextProps extends WidgetBoxedProps {
  type: WidgetType.TEXT,
  editorTextState: string;
}

export interface WidgetTextPropsUpdate extends Partial<
  Omit<WidgetTextProps, "type" | "editorTextState" >
> {
}

export type WidgetProps = (
    WidgetImageProps
  | WidgetRectangleProps
  | WidgetEllipseProps
  | WidgetArrowProps
  | WidgetArrowTextProps
  | WidgetTextProps 
);

export type WidgetsProps = {
  [widgetKey: string]: WidgetProps
}

export type WidgetAddr = ImageAddr & {
  widgetId: UUID;
}

export type WidgetsAddrs = WidgetAddr[];

export type Widgets = {
  addrs: WidgetsAddrs,
  props: WidgetsProps,
}

export enum WidgetPart {
  NONE        = 'none',
  ARROW_HEAD  = 'arrow-head',
  ARROW_BODY  = 'arrow-body',
  ARROW_TAIL  = 'arrow-tail',
};

export type ImageClipInset = {
  top: number,
  right: number,
  bottom: number,
  left: number
};


// TODO 
// Review me

export type WidgetArrowPosition = {
  startPoint: Position, 
  endPoint: Position
}

export type WidgetBoxedPosition = Position;

export type WidgetPosition = (
  Position | 
  WidgetArrowPosition
);

// TODO finish



/**
 * 
 * Cell types
 * 
 */

export type CellTypes = (
    IndexCell 
  | TextCell 
  | MarkersCell
  | ImagesCell
);



//----------- Content types belowe -----------------//


/**
 * 
 * Column
 * 
 */

export type ColumnAddr = {
  columnId: UUID;
}

export enum ColumnType {
  INDEX   = "index",
  TEXT    = "text",
  MARKERS = "markers",
  IMAGES  = "images",
}

/**
 * Column Props
 */

export interface ColumnProps {
  type: ColumnType;
  name: string;
  width: number;
  visible: boolean;
  css: React.CSSProperties;
  header: {
    css: React.CSSProperties;
  }
}

export interface ColumnIndexProps extends ColumnProps {
}
  
export interface ColumnTextProps extends ColumnProps {
}

export interface ColumnImagesProps extends ColumnProps {
  imageIdx: {
    visible: boolean,
    placement: ImageIdxPlacement
  },
  imageBorder: {
    css: React.CSSProperties,
  }
}

export enum ImageIdxPlacement {
  TOP_LEFT     = 'top-left',
  TOP_RIGHT    = 'top-right',
  BOTTOM_LEFT  = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right'
}


export interface ColumnMarkersProps extends ColumnProps {
}

export type ColumnPropsTypes = (
  ColumnIndexProps   |
  ColumnTextProps    |
  ColumnImagesProps  |
  ColumnMarkersProps
);


/**
 * Column Props Update
 */
export type ColumnPropsUpdate = (
  Partial< 
    Omit< 
      ColumnProps, "type" | "css" | "header"
    > 
  >);



export type ColumnImagesPropsUpdate = (
  jtl.RecursivePartial<ColumnImagesProps>
);



/**
 * 
 * Columns
 * 
 */

export type ColumnsAddrs = ColumnAddr[];

export type ColumnsProps = {
  [columnKey: string]: ColumnProps
}

export type Columns = {
  addrs: ColumnsAddrs,
  props: ColumnsProps,
}


/**
 * 
 * Row
 * 
 */

export type RowAddr = {
  sectionId: UUID;
  rowId: UUID;
}

export type RowProps = {
}

export type RowPropsUpdate = (
  Partial<RowProps>
);


/**
 * 
 * Rows
 * 
 */

export type RowsAddrs = RowAddr[];

export type RowsProps = {
  [rowKey: string]: RowProps
}

export type Rows = {
  addrs: RowsAddrs,
  props: RowsProps,
}


/**
 * 
 * Cell
 * 
 */

export type CellAddr = ColumnAddr & RowAddr;


/**
 * 
 * Cells
 * 
 */

export type Cells = {
  [cellKey: string]: CellTypes
}


/**
 * 
 * Section
 * 
 */

export type SectionAddr = {
  sectionId: UUID;
}

export type SectionProps = {
  name: string;
  css: React.CSSProperties;
}

export type SectionPropsUpdate = (
  Partial< 
    Omit< 
      SectionProps, "css" 
    > 
  >);


/**
 * 
 * Sections
 * 
 */

export type SectionsAddrs = SectionAddr[];

export type SectionsProps = {
  [sectionKey: string]: SectionProps
}

export type SectionsRows = {
  [sectionKey: string]: Rows
}

export type SectionsCells = {
  [sectionKey: string]: Cells
}

export enum SectionsIndexing {
  LOCAL = "local",
  GLOBAL = "global"
}

export type SectionsConfig = {
  enabled: boolean,
  indexing: SectionsIndexing,
  sectionsNames: {
    css: React.CSSProperties,
  }
}

export type SectionsConfigUpdate = (
  Partial<Omit < SectionsConfig, "css"> >
);

export type Sections = {
  addrs:  SectionsAddrs,
  props:  SectionsProps,
  rows:   SectionsRows,
  cells:  SectionsCells,
  config: SectionsConfig,
}



/**
 * 
 * Content
 * 
 */

export type Content = {
  columns: Columns,
  sections: Sections,
}
