import * as Tools from './tools';
import * as Defaults from './defaults';
import * as Types from './types';


export type State = {
  plans: Types.PricePlans,
};


export const getPlans = (
  state: State,
): Types.PricePlans => {
  return state.plans;
}

export const getPlan = (
  state: State,
  planType: Types.PlanType
): Types.PricePlan => {
  const plans = getPlans(state);
  const plan = plans[planType]

  return plan;
}

export const getBillingPriceLookup =(
  state: State,
  planType: Types.PlanType,
  billingPeriod: Types.BillingPeriod
): string => {
  const plan = getPlan(state, planType);
  const priceLookup = plan.price.lookup[billingPeriod];
  
  return priceLookup;
}

/**
 * Create initial state
 */

export const createInitialState = (): State => {
  // const logger = Tools.getLogger();
  // logger.debug("Create initial repos state");

  const initState: State = {
    plans: Defaults.getPlans(),
  }

  return initState;
}
