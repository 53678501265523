import { createContext } from 'react';
import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';


export interface ContextProps {
  planType: PricePlansTypes.PlanType | null;
};


const Context = createContext<ContextProps>({
  planType: null,
});


export default Context;