import { getConfig } from "./menu-config";
import { RepoImagesTypes } from "app/arch/editor-instruction/document/states/loadable/repo-images";
import useContextMenu from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useDocumentImageDelete from "app/ui-v2/editor-instruction/hooks/use-document-image-delete";


const useImageMenu = (props: {
  image: RepoImagesTypes.Image
}) => {
  const {
    image
  } = props;

  const {
    showMenu: showContextMenu
  } = useContextMenu();

  const deleteDocumentImage = useDocumentImageDelete();

  const addToCell = () => {
  }

  const deleteImage = () => {
    deleteDocumentImage({imageId: image.bid});
  }


  const cmds = {
    addToCell,
    deleteImage
  }

  const states = {

  }
  
  const handleContextMenu = (event: React.MouseEvent) => {
    const config = getConfig(cmds, states);
    showContextMenu({config, event});
  }


  return handleContextMenu;

}

export default useImageMenu;