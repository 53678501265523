import styled from "styled-components";



interface BoxProps {
  $left: number;
  $top: number;
  $width: number;
  $height: number;
}


export const Box = styled.div.attrs<BoxProps>(props => ({
  style: {
    left:   `${props.$left}px`,
    top:    `${props.$top}px`,
    width:  `${props.$width}px`,
    height: `${props.$height}px`,
  }
}))<BoxProps>`
  position: absolute;

  overflow: clip;
  box-sizing: border-box;
  
  border-style: solid;
  border-width: 0px;
  border-color: transparent;

  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
`;

