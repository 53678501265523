import React from 'react';

import SideControlComponent from 'lego/components/side-controls/side-control';
import { SideControlsTypes } from 'lego/components/side-controls';

import PanelComponent   from './panel';
import ToolbarComponent from './toolbar';


interface Props {
}


export const PanelDocumentComponent: React.FC<Props> = (props: Props) => {

  return (
    <SideControlComponent
      flow={SideControlsTypes.PanelFlow.IN_FLOW}
      right={true}
      Toolbar={ToolbarComponent}
      Panel={PanelComponent}
    />
  );
}
  
