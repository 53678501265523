import React from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';

import { LexicalComposer }           from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import environment from 'app/environment';
import { LexicalTools } from 'app/arch/tools';
import { LexicalTypes } from 'app/arch/tools';

import { MainWrapper } from './styles';


export interface Props {

}

export const LexicalCheckComponent: React.FC<Props> = (props: Props) => {
  const [lexcialCheckDone, setLexicalCheckDone] = useState(false);
  const lexcialConfig = LexicalTools.getLexicalConfig({editable: true});

  const checkStructure = (valid: boolean) => {
    if (valid) {
      return;
    }

    if (environment.dev) {
      const msg = "Invalid lexical init editor state structure";
      // console.warn(msg);
    }
    else {
      // Production
    }
  }

  const checkVersion = (valid: boolean) => {
    if (valid) {
      return;
    }

    const msg = "Invalid lexical init editor state version";
    if (environment.dev) {
      // throw new Error(msg);
    }
    else {
      // Production
      console.warn(msg);
    }
  }

  const handleCheckDone = (stateValid: LexicalTypes.StateValid) => {
    checkVersion(stateValid.versionValid);
    checkStructure(stateValid.structureValid);

    setLexicalCheckDone(true);
  }

  return (
    <>
    {
      ! lexcialCheckDone &&
      <MainWrapper>
        <LexicalComposer initialConfig={lexcialConfig}>
          <Check onDone={handleCheckDone} />
        </LexicalComposer>
      </MainWrapper>
    }
    </>
  );
}
  

/**
 * Check component
 */

interface CheckProps {
  onDone: (valid: LexicalTypes.StateValid) => void;
}


const Check: React.FC<CheckProps> = (props: CheckProps) => {
  const {
    onDone
  } = props;

  const [lexical] = useLexicalComposerContext();
  
  useEffect(() => {
    const editorState = lexical.getEditorState();
    const valid = LexicalTools.validateInitState(editorState.toJSON());
    onDone(valid);
  }, [lexical]);

  return null;
}