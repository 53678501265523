import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from './use-editor-states-setters';


const useDNDDataSet = () => {
  const document = useDocState();
  const { setEditorSession } = useEditorStatesSetters();

  const setData = (data: string | null) => {
    document.editorSession.setDNDData(data);
    setEditorSession();
  }

  return setData;
}


export default useDNDDataSet;