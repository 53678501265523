import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useIsMobile } from 'lego-hooks/use-is-mobile';

import { appRelease } from 'app/configs/release';
import { useIsPrintout } from 'app/ui/components/editor-instruction/use-is-printout';
import useDemoActive from 'app/ui-v2/app/__modules/demo/hooks/use-demo-active';
 
import { MainWrapper } from './styles';
import { BuildText }   from './styles';
import { BuildNo }     from './styles';


interface Props {
}


export const AppVersionComponent: React.FC<Props> = (props: Props) => {
  const [visible, setVisible] = useState(false);

  const releaseNo = appRelease;
  const isPrintout = useIsPrintout();
  const isDemo = useDemoActive();
  const isMobile = useIsMobile();

  const handlePointerEnter = (event: React.PointerEvent) => {
    setVisible(true);
  }

  const handlePointerLeave = (event: React.PointerEvent) => {
    setVisible(false);
  }

  const render = () => (
    ReactDOM.createPortal(
      <MainWrapper 
        $visible={true}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
      >
        <BuildText>
          build
        </BuildText>
        <BuildNo>
          { releaseNo }
        </BuildNo>
      </MainWrapper>,
      document.getElementById('app-version') as HTMLElement
    )
  );

  if ( isPrintout || isDemo ) {
    return null;
  }
  
  return render();
}
  
