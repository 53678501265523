import React from 'react';
import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import useContextMenu    from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useCellMarkerMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/cell-markers/use-cell-marker-menu';

import { MainWrapper } from './styles';


interface Props {
  markerAddr: Types.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children
  } = props;

  const { showMenu }  = useContextMenu();
  const getConfigMenu = useCellMarkerMenu();

  const handleContextMenu = (event: React.MouseEvent) => {
    const config = getConfigMenu(markerAddr);
    showMenu({event, config});
  }

  return (
    <MainWrapper
      onContextMenu={handleContextMenu}
    >
      { children }
    </MainWrapper>
  );
}
  
