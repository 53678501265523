import { AuthJwtProvider } from "./jwt/auth-jwt";

class AuthService {
  private authProvider: AuthJwtProvider;

  constructor() {
    this.authProvider = new AuthJwtProvider();
  }

  init() {
    return this.authProvider.init();
  }

  login(email: string, password: string) {
    return this.authProvider.login(email, password);
  }

  logout() {
    return this.authProvider.logout();
  }
  
  isLogged() {
    return this.authProvider.isLogged();
  }

  reset() {
    this.authProvider.reset();
  }

  getUsername() {
    return this.authProvider.getUsername();
  }

  getUserId() {
    return this.authProvider.getUserId();
  }

  getToken() {
    return this.authProvider.getToken();
  }
  
  setToken(jwtToken: string | undefined | null): boolean {
    return this.authProvider.setToken(jwtToken);
  }

  getAuthHeader() { 
    const token = this.getToken();
    const authValue  = token !== null ? `JWT ${token}` : '';
    const authHeader = { 'Authorization':  authValue };
    
    return authHeader;
  }
}

export default AuthService;
