import * as docx from "docx";
import jtl from "tools/jtl";

import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import { HeaderFieldsTypes } from "app/arch/editor-instruction/document/states/persistent/header-fields";

import ExporterBase from "../../parts/exporter-base";
import AssetsRepo from "../../../parts/assets-repo";
import * as Defaults from '../../defaults';
import * as Tools from '../../tools';
import TextConverter from "../../parts/text-converter";


class CustomFieldsExporter extends ExporterBase {
  private _columnsWidthTotal: number;

  constructor(docState: DocState, assetsRepo: AssetsRepo) {
    super(docState, assetsRepo);
    this._columnsWidthTotal = 0;
  }

  get content() { return this._docState.content; }

  createSections() {
    this.calculateColumnsWidth();

    const tableHeader = this._addFields();
    const sections = [ tableHeader ];

    return sections;
  }

  private _addFields() {
    const headerRow = this._addRowHeader();
    const fieldsRow = this._addRowFields();
    
    const table = new docx.Table({
      borders: this.getBordersNone(),
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      // layout: docx.TableLayoutType.FIXED,
      rows: [ 
        headerRow, 
        fieldsRow 
      ]
    });
    
    return table;
  }

  private _addRowHeader() {
    const cells = this._copyCellsHeader();

    const row = new docx.TableRow({
      children: cells,
    });
    return row;
  }

  private _copyCellsHeader() {
    const columnsAddrs = this._docState.headerFields.getColumnsAddrs();

    const cells = columnsAddrs.map(columnAddr => {
      const columnProps = this._docState.headerFields.getColumnProps(columnAddr);
      const sizePerc = this._getColumnSize(columnAddr);
      const css = this._docState.headerFields.getTable().header.css;
      const cssTableHeader = this._docState.viewsCommon.getTableHeaderCSS();
      
      const text = `${columnProps.name}`;
      const color = jtl.color.expandHex(cssTableHeader.color);

      const textRunOpt = Defaults.getCustomFieldsHeaderOptions(css, {text, color});
      const textRun = new docx.TextRun(textRunOpt);

      const alignment = Tools.getCellAlignment(css);
      const padding = Tools.getCellPadding(css);

      const docxCell = new docx.TableCell({
        margins: padding,
        borders: this.getBordersFull(),
        shading: this.getHeaderShading(),
        width: {
          size: sizePerc,
          type: docx.WidthType.PERCENTAGE,
        },
        children: [new docx.Paragraph({
          alignment,
          children: [textRun]
        })]
      });

      return docxCell;
    });


    return cells;
  }

  private _addRowFields() {
    const cells = this._copyCellsFields();

    const row = new docx.TableRow({
      children: cells,
    });

    return row;
  }

  private _copyCellsFields() {
    const headerFields = this._docState.headerFields;
    const columnsAddrs = headerFields.getColumnsAddrs();

    const cells = columnsAddrs.map(columnAddr =>{
      const sizePerc = this._getColumnSize(columnAddr);
      const cell = headerFields.getCell(columnAddr);
      
      const columnProps = headerFields.getColumnProps(columnAddr);
      const textConverter = new TextConverter();
      const paragraphs = textConverter.convert(cell.editorState);
      const padding = Tools.getCellPadding(columnProps.css);

      const docxCell = new docx.TableCell({
        margins: padding,
        borders: this.getBordersFull(),
        width: {
          size: sizePerc,
          type: docx.WidthType.PERCENTAGE,
        },
        children: paragraphs,
      });

      return docxCell;
    });

    return cells;
  }


  private calculateColumnsWidth() {
    const columnsAddrs = this.content.getColumnsAddrs();
    const totalWidth = columnsAddrs.reduce((acc, columnAddrs) => {
      const columnWidth = this.content.getColumnWidth(columnAddrs);
      return acc + columnWidth;
    }, 0);

    this._columnsWidthTotal = totalWidth;
  }


  private _getColumnSize(
    columnAddr: HeaderFieldsTypes.ColumnAddr,
  ) {
    const headerFields = this._docState.headerFields;
    const columnWidth = headerFields.getColumnWidth(columnAddr);
    const sizePerc = (columnWidth / this._columnsWidthTotal) * 100;

    return sizePerc;
  }
}


export default CustomFieldsExporter;