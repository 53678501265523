import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { GraphQLTypes } from './graphql';
import { createApolloClientAuth } from './graphql';
import useReloginVisibleSet from '../modals/relogin/hooks/use-relogin-visble-set';


interface Props {
  children: React.ReactNode;
}


export const ApolloProviderComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const setReloginVisible = useReloginVisibleSet();

  const onError = (error: GraphQLTypes.Errors) => {
    setReloginVisible(true);
  }

  const graphqlClientAuth = createApolloClientAuth(onError);

  return (
    <ApolloProvider client={graphqlClientAuth}>
      { children }
    </ApolloProvider>
  );
}

