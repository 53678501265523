import styled from 'styled-components';


const Layout = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-width: 320px;

  height: 100%;
  min-height: 240px;
  
  background: ${props => props.theme.defs.colors.background.seventh};
`;


export default Layout;