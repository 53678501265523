import styled from "styled-components";


export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: clip;
`;


export default Content;
