import Logger from "libs/debug";

import { Size } from "app/arch/types";

import * as TypesShared from "../../states/types";

import { ReleaselogsTypes as StateTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTools as StateTools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';

import Releaselogs from "../../states/persistent/releaselogs";
import { ReleaselogsElementsSizes } from "../../states/slicing/releaselogs";


import * as Types from "./types";
import * as Defaults from "./defaults";



export namespace DocReleaselogsSlicer {
  export const slice = (props: {
    isPrintout: boolean,

    pageSize: Size,
    reverseOrder: boolean,

    docInfoVisible: TypesShared.PageItemVisibility,
    docTitleVisible: TypesShared.PageItemVisibility,

    docReleaselogs: Releaselogs,
    elementsSizes: ReleaselogsElementsSizes,
  }) => {

    const {
      isPrintout,
      
      pageSize,
      reverseOrder,

      docInfoVisible,
      docTitleVisible,

      docReleaselogs,
      elementsSizes,
    } = props;


    const log = Logger.getChangelogSlicer();

    /***************
     *   Vars      *
     ***************/
    let page: Types.Page = Defaults.getPage();
    let pages: Types.Pages = [];
    let pageHeightAvailable = pageSize[1];
    let pageItem_Changelog = Defaults.getPageItem_ReleaseChangelog({releaselogId: ""});


    /***************
     *   Helpers   *
     ***************
     *    Start    *
     ***************/

    const __addPageRaw = () => {
      page = Defaults.getPage();
      pages.push(page);
      pageHeightAvailable = pageSize[1];
    }

    const __addDocHeader_conditionally = () => {
      const visible = (
          docInfoVisible === TypesShared.PageItemVisibility.ALL ||
        ( docInfoVisible === TypesShared.PageItemVisibility.FIRST_PAGE &&  pages.length === 1 )
      );

      if ( ! visible ) {
        return;
      }
      const pageItem_DocHeaderInfo = Defaults.getPageItem_DocHeaderInfo({});
      page.items.push(pageItem_DocHeaderInfo);

      const docInfoHeight = elementsSizes.pageHeader.getDocInfoHeight();
      pageHeightAvailable -= docInfoHeight;      
    }

    const __addViewTitle_conditionally = () => {
      const visible = (
          docTitleVisible === TypesShared.PageItemVisibility.ALL ||
        ( docTitleVisible === TypesShared.PageItemVisibility.FIRST_PAGE && pages.length === 1 )
      );

      if ( ! visible ) {
        return;
      }
      
      const pageItem_ViewTitle = Defaults.getPageItem_ViewTitle();
      page.items.push(pageItem_ViewTitle);

      const docTitleHeight = elementsSizes.pageHeader.getDocTitleHeight();
      pageHeightAvailable -= docTitleHeight;      
    }

    const __addPage = () => {
      __addPageRaw();
      __addDocHeader_conditionally();
      __addViewTitle_conditionally();
    }

    const __addSpacer = () => {
      const pageItem_Spacer = Defaults.getPageItem_Spacer({});
      page.items.push(pageItem_Spacer);

      const spacerHeight = elementsSizes.releaselogs.getSpacerHeight();
      pageHeightAvailable -= spacerHeight;
    }

    const __addReleaseHeader = (
      releaselogAddr: StateTypes.ReleaselogAddr
    ) => {
      const releaselogId = releaselogAddr.releaselogId;

      const pageItem_ReleaseInfo = Defaults.getPageItem_ReleaseInfo({releaselogId});
      page.items.push(pageItem_ReleaseInfo);

      const releaseInfoHeight = elementsSizes.releaselogs.getReleaseInfoHeight(releaselogAddr);
      pageHeightAvailable -= releaseInfoHeight; 
    }

    const __isSpacerNeeded = () => {
      if (page.items.length === 0) {
        return false;
      }

      const lastItem = page.items[page.items.length - 1];
      const spacerNeeded = ( 
        lastItem.type === Types.PageItemType.RELEASE_CHANGELOG
      );

      return spacerNeeded;
    }

    /**
     * Changelog
     */
    const __addChangelog = (
      releaselogAddr: StateTypes.ReleaselogAddr
    ) => {
      const releaselogId = releaselogAddr.releaselogId;

      pageItem_Changelog = Defaults.getPageItem_ReleaseChangelog({releaselogId});
      page.items.push(pageItem_Changelog);
    }

    const __addChangelogHeaderRow = (
      releaselogAddr: StateTypes.ReleaselogAddr
    ) => {
      const changelogItem_HeaderRow = Defaults.getChangelogItem_HeaderRow();
      pageItem_Changelog.items.push(changelogItem_HeaderRow);
      
      const headerRowHeight = elementsSizes.releaselogs.getChangelogHeaderRowHeight(releaselogAddr);
      pageHeightAvailable -= headerRowHeight;
    }

    const __addChangelogRow = (
      rowAddr: StateTypes.RowAddr
    ) => {
      const rowId = rowAddr.rowId;

      const changelogItem_Row = Defaults.getChangelogItem_Row({rowId});
      pageItem_Changelog.items.push(changelogItem_Row);

      const rowHeight = elementsSizes.releaselogs.getChangelogRowHeight(rowAddr);
      pageHeightAvailable -= rowHeight;
    }
    
    
    /***************
     *   Helpers   *
     ***************
     *     End     *
     ***************/


    __addPage();

    /**********************
     **********************
     **                  **
     **   Releases Loop  **
     **                  **
     **********************
     **********************/

    const spacerHeight   = elementsSizes.releaselogs.getSpacerHeight();

    const releaselogsAddrs_ = docReleaselogs.getReleaselogsAddrs();
    const releaselogsAddrs = (
      reverseOrder ? 
      [...releaselogsAddrs_].reverse() :
      releaselogsAddrs_
    );
 
    /*******************
     * 
     * Releaselogs loop
     * 
     *******************/

    releaselogsAddrs.forEach((releaselogAddr) => {
      const rowsAddrs = StateTools.getChangelogRows_editableAdjusted(
        docReleaselogs,
        releaselogAddr,
        isPrintout
      );


      const headerRowHeight = elementsSizes.releaselogs.getChangelogHeaderRowHeight(releaselogAddr);
      const releaseInfoHeight = elementsSizes.releaselogs.getReleaseInfoHeight(releaselogAddr);

      {
        if (rowsAddrs.length === 0) {
          const msg = `Rows count can't be 0`;
          throw new Error(msg);
        }

        const firstRowHeight = elementsSizes.releaselogs.getChangelogRowHeight(rowsAddrs[0]);
        
        const heightNeeded = (
          (__isSpacerNeeded() ? spacerHeight : 0) +
          releaseInfoHeight + 
          headerRowHeight + 
          firstRowHeight
        );

        if (heightNeeded > pageHeightAvailable) {
          __addPage();         
        }
      }


      if ( __isSpacerNeeded() ) {
        __addSpacer();
      }

      __addReleaseHeader(releaselogAddr);
      __addChangelog(releaselogAddr);

    
      rowsAddrs.forEach((rowAddr, rowIdx) => {
        const rowHeight = elementsSizes.releaselogs.getChangelogRowHeight(rowAddr);
        
        const heightNeeded = (
          rowIdx !== 0 ?
          rowHeight :
          rowHeight + headerRowHeight
        );


        //
        // Enough space on page to accomodate 
        // row (or row with header row)
        //
        if (heightNeeded <= pageHeightAvailable) {
          if ( rowIdx === 0 ) {
            __addChangelogHeaderRow(releaselogAddr);
          }
          __addChangelogRow(rowAddr);
        }

        //
        // Not enought space for 
        // changelog row header + changelog row
        // 
        else {
          __addPage();

          // __addReleaseHeader({
          //   releaselogId,
          //   releaseInfoHeight
          // });

          __addChangelog(releaselogAddr);
          __addChangelogHeaderRow(releaselogAddr);
          __addChangelogRow(rowAddr);
        }
      });
    });

    log.debug("[ViewChangelog] Content slicer, pages defs:");
    log.log(pages);
    return pages;
  }
}
