import React from 'react';
import { useRecoilValue } from 'recoil';

import { SideControlsTypes }  from 'lego/components/side-controls';
import SidePanelBaseComponent from 'lego/components/side-controls/side-panel';

import { ContentSessionTypes as Types } from 'app/arch/editor-instruction/document/states/sessions/content-session';
import usePagePanelConfig from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-panel-config';
import usePanelDocHeaderConfig from 'app/ui-v2/editor-instruction/views/view-content/hooks/panel-properties/panel-doc-header/use-panel-doc-header-config';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';

// TODO move to here / dir wise
import usePanelConfigColumns   from 'app/ui-v2/editor-instruction/panel-configs/use-panel-config-columns';
import usePanelConfigTable     from 'app/ui-v2/editor-instruction/panel-configs/use-panel-config-table';
import usePanelColumnConfig    from './hooks/panel-column/use-panel-column-config';
import usePanelSectionsConfig  from './hooks/panel-sections/use-panel-sections-config';


type Props = SideControlsTypes.PropLeftOrRight;


export const SidePanelComponent: React.FC<Props> = (props: Props) => {
  const {
    left, 
    right
  } = props;

  const panel = useRecoilValue(UIState_ContentSession.panelProperties);
  
  const getConfigPage      = usePagePanelConfig();
  const getConfigDocHeader = usePanelDocHeaderConfig();
  const getConfigTable     = usePanelConfigTable();
  const getConfigSections  = usePanelSectionsConfig();
  const getConfigColumn    = usePanelColumnConfig();
  const getConfigColumns   = usePanelConfigColumns();


  const Configs: {[key in Types.PanelPropertiesItem]: any} = {
    [Types.PanelPropertiesItem.PAGE     ] : getConfigPage,
    [Types.PanelPropertiesItem.HEADER   ] : getConfigDocHeader,
    [Types.PanelPropertiesItem.TABLE    ] : getConfigTable,
    [Types.PanelPropertiesItem.SECTIONS ] : getConfigSections,
    [Types.PanelPropertiesItem.COLUMN   ] : getConfigColumn,
    [Types.PanelPropertiesItem.COLUMNS  ] : getConfigColumns,
  }

  const getConfig = Configs[panel.selected];
  const config = getConfig();

  return (
    <SidePanelBaseComponent
      config={config}
      visible={panel.visible}

      left={left}
      right={right}
    />
  );
}
