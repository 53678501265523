import styled from "styled-components";


export const Revision = styled.div`
  flex: 0 0 80px;
  /* background: red; */
  font-weight: ${props => props.theme.defs.font.weight.large};
`;


export const ReleaseDate = styled.div`
  flex: 1 1 0px;
  min-width: 0px;
  /* background: green; */
  font-weight: ${props => props.theme.defs.font.weight.small};
  opacity: ${props => props.theme.defs.opacity.text};
`;

