import React from 'react';
import { Wrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const ViewPricingLayoutComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;


  return (
    <Wrapper>
      { children }
    </Wrapper>
  );
}

