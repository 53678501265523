import React from 'react';

import { PanelControlsItemConfig as Config} from '../../../config';
import { unpackBoolean }   from '../../../tools';
import DynamicWidthWrapper from '../../../styles/dynamic-width-wrapper';
import DynamicWidth        from '../../../styles/dynamic-width';

import { MainWrapper } from './styles';
import { Title }       from './styles';
import { Separator }   from './styles';


interface Props {
  config: Config;
  Section: React.ElementType;
}


export const PanelItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    config,
    Section,
  } = props;

  const panelItemDisabled = unpackBoolean(config.disabled);

  const renderSections = () => {
    const sections = config.sections.filter((section) => ! unpackBoolean(section.skip));
    const sectionsComps = sections.map((section, idx) => (
      <Section
        key={`${idx}`}
        config={section}
      />
    ));
    
    return sectionsComps;
  }

  return (
    <MainWrapper $disabled={panelItemDisabled} >
      {
        config.title &&
        <>
          <DynamicWidthWrapper>
            <DynamicWidth>
              <Title>
              { config.title }
              </Title>
            </DynamicWidth>
          </DynamicWidthWrapper> 
          <Separator />
        </>
      }

      { renderSections() }
    </MainWrapper>
  );
}
  
