import React from 'react';

import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import MarkerViewComponent from 'app/ui-v2/editor-instruction/hls/markers/marker-view';
import { Marker } from './styles';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
}


export const MarkerDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr
  } = props;

  return (
    <MarkerViewComponent 
      markerAddr={markerAddr} 
      selected={true}
      Marker={Marker}
    />
  );
}
  
