export class PageHeader {
  private _docInfoHeight: number;
  private _docTitleHeight: number;

  constructor() {
    this._docInfoHeight = 0;
    this._docTitleHeight = 0;
  }

  getDocInfoHeight() { return this._docInfoHeight; }
  setDocInfoHeight(height: number) { this._docInfoHeight = height; }

  getDocTitleHeight() { return this._docTitleHeight; }
  setDocTitleHeight(height: number) { this._docTitleHeight = height; }
}
