import * as Types from './types';


export const getMarkers = (): Types.Markers  => ({
  addrs: [],
  props: {}
});

export const getMarkerTypeProp = (): Types.MarkerType => (
  Types.MarkerType.SIGN_INFO
);

export const getMarkerLabelProp = (): Types.LabelProps => ({
  text: "Marker",
  rotate: Types.LabelRotation.ROTATE_0,
  css: {
    backgroundColor:  "#3B8B3A",
    borderColor: "#1E4B1E",
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "2px",
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 600,
    fontVariant: 'none',
  }
});

export const getMarkerIconProp = (): Types.IconProps => ({
  addr: {
    row: 1,
    column: 2215
  },
  size: 28,
  css: {
    backgroundColor:  "#3B8B3A",
    borderColor: "#1E4B1E",
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "2px",
    color: "#FFFFFF",
    padding: "4px"
  }
});

export const getMarkerProps = (): Types.MarkerProps => ({
  type: getMarkerTypeProp(),
  label: getMarkerLabelProp(),
  icon: getMarkerIconProp(),
});

