import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import EditorInstruction      from 'app/arch/editor-instruction';
import { useTranslations }    from 'app/ui/hooks/app/use-translation';
import useUndo                from 'app/ui-v2/editor-instruction/hooks/use-undo';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { useDialogCustom }   from '../../dialogs/dialog-custom/use-dialog-custom';
import GiveUpComponent       from '../give-up';
import CrashMessageComponent from '../crash-message';
import EditorInstructionContext from 'app/ui/contexts/editor-instruction';


interface Props {
  children: React.ReactNode;
  editorInstruction: EditorInstruction;
}

export const FallbackComponent: React.FC<Props> = (props: Props) => {
  const {
    editorInstruction
  } = props;

  const contextProps = {
    editorInstruction,
  };

  editorInstruction.recoveryMode = true;
  
  return(
    <EditorInstructionContext.Provider
      value={contextProps}
    >
      <FallbackComponentCore {...props} />
    </EditorInstructionContext.Provider>
  );
}


const FallbackComponentCore: React.FC<Props> = (props: Props) => {
  const editor       = props.editorInstruction;
  const docState     = editor.document.docState;
  const crashWatcher = editor.crashWatcher;

  const {
    setContentSession,
    setEditorImageSession,
  } = useEditorStatesSetters();

  const { undo, redo } = useUndo();

  const [init, setInit] = useState(false);
  const [giveUp, setGiveUp] = useState(false);

  const t = useTranslations();
  const { 
    show: showDialog,
    hide: hideDialog,
  } = useDialogCustom();


  useEffect(() => {
    if (init && ! giveUp) return;

    if (! init ) {
      crashWatcher.recordCrash();
      crashRecover();
      setInit(true);
    }

    if (giveUp) {
      hideDialog();
      return;
    }

    showDialog({
      title: t('crash dialog title'),
      messageComponent: <CrashMessageComponent />,
      buttons: [
        {
          text: t("ok"),
          onClick: () => {}
        }
      ]
    });
  }, [init, giveUp]);


  const resetDummy = () => {
    console.log("Dummy reset");
    // This is first recovery case.
    // Do not reset  anything, as the 
    // crash could happend due to command
    // has nothing to do with states, 
    // therefore no states reset is needed.
    // 
    // For example if crash happen during
    // 'Save as' - just rerendering Editor
    // should be enough without any reset.
  }

  const resetDocContentSession = () => {
    console.log("Reset DocContentSession");
    docState.contentSession.reset();
    setContentSession();
  }

  const resetEditorImageSession = () => {
    console.log("Reset EditorImageSession");
    docState.editorImageSession.reset();
    setEditorImageSession();
  }

  const undoEditor = () => {
    console.log("Undo Editor");
    undo();
  }

  // It is in pairs as
  // second handlers gets executed anyway
  // - i guess due to 'test' re-render
  const recoveryHandlers = [
    resetDummy,
    resetEditorImageSession,
    resetDummy,
    resetDocContentSession,
    resetDummy,
    undoEditor,
    resetDummy,
    // resetDummy,
  ];

  const crashRecover = () => {
    const crashCount = crashWatcher.crashCount;
    if (crashCount > recoveryHandlers.length) {
      console.warn(`No more recovery handlers. Crash count: ${crashCount}`);  
      setGiveUp(true);
      return;
    }
    
    console.log(`Executing recovery, crash count: ${crashCount}/${recoveryHandlers.length}`);
    const recover = recoveryHandlers[crashCount-1];
    recover();
  }

  return (
    <>
    { init && ! giveUp && props.children }
    { 
      init && giveUp && 
      <GiveUpComponent editor={editor} />
    }
    </>
  );
}

