
export const getFont = () => ({
  color: '#000000',
  background: '#FFFFFF',
  size: {
    init: 12,
    min: 6
  },
});

