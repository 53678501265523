import Draggers from "./draggers";


class Controls {
  private _draggers: Draggers;

  constructor() {
    this._draggers = new Draggers();
  }

  get draggers() { return this._draggers; }
}


export default Controls;