import styled from "styled-components";
import TextWrap from "lego/styles/text-wrap";
import ButtonPanel from "lego/styles/button_panel";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonWrapper = styled.div`
  flex: 0 0 auto;
`;

const Button = styled(ButtonPanel)`
`;

export const ButtonLeft = styled(Button)`
  border-radius: 
    ${props => props.theme.button.border.radius}
    0
    0
    ${props => props.theme.button.border.radius}
  ;
`;

export const ButtonRight = styled(Button)`
  border-radius: 
    0
    ${props => props.theme.button.border.radius}
    ${props => props.theme.button.border.radius}
    0
  ;
`;

export const AccessWrapper = styled.div<{$disabled: boolean}>`
  flex: 1 1 0;
  min-width: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  background:   ${props => props.theme.button.background.primary};

  border-color: ${props => props.theme.button.border.color.primary};
  border-style: ${props => props.theme.button.border.style};
  border-width: ${props => props.theme.button.border.width};
  
  border-left-style: none;
  border-right-style: none;

  opacity: ${props => 
    props.$disabled ?
    props.theme.defs.opacity.disabled :
    1
  };
`;

export const AccessText = styled(TextWrap)`
  user-select: none;
  color: ${props => props.theme.button.color.primary};
  font-size: ${props => props.theme.defs.font.size.normal};
  font-variant: small-caps;
  padding: ${props => props.theme.defs.padding.normal};
  box-sizing: border-box;
;
`;