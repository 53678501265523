import React from 'react';
import Context from './context';
import { PrintoutViewTypes } from 'app/arch/editor-instruction/document/states/printout/printout-view';


interface Props {
  printoutView: PrintoutViewTypes.ViewType;
  children: React.ReactNode;
}


export const ViewPageContextComponent: React.FC<Props> = (props: Props) => {
  const {
    printoutView,
    children,
  } = props;

  const context = {
    printoutView
  }

  return (
    <Context.Provider value={context}>
      { children }
    </Context.Provider>
  );
}
