import styled from 'styled-components';


interface BoxProps {
  $left: number;
  $top: number;
  $width: number;
  $height: number;
}

export const WidgetWrapper = styled.div.attrs<BoxProps>(props => ({
  style: {
    left:   `${props.$left}px`,
    top:    `${props.$top}px`,
    width:  `${props.$width}px`,
    height: `${props.$height}px`,
  }
}))<BoxProps>`
  position: absolute;
  /* background: rgba(2,  255,0 ,0.5); */
`;


export const WidgetAnchor = styled.div`
  position: relative;
  height: 100%;
`;
