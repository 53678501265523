import React from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';

import useKeyBindings from './hooks/use-key-binding';


interface Props {
  cellAddr: Types.CellAddr;
  children: React.ReactNode;
}


export const CellKeybindingsComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;


  const {
    addBindings,
    removeBindings
  } = useKeyBindings(cellAddr);

  const isSelected = useRecoilValue(UIState_ContentSession.isCellSelected(cellAddr));

  useEffect(() => {
    if (isSelected === false) {
      return;
    }

    addBindings();

    return () => {
      removeBindings();
    }
  }, [isSelected]);

  return children;
}

