
import React from 'react';
import useDocumentCreate from 'app/ui-v2/editor-instruction/hooks/use-document-create';
import RedirectRunDemoBaseComponent from '../parts/redirect-run-demo-base';


interface Props {
}


export const RedirectRunDemoBlankComponent: React.FC<Props> = (props: Props) => {
  const createDoc = useDocumentCreate();

  const handleDone = () => {
    createDoc({title: "Demo"});
  }

  return (
    <RedirectRunDemoBaseComponent
      onDone={handleDone}
    />
  );
}

