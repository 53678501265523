// import styled, { css } from 'styled-components';
import styled from 'styled-components';

const FRAME_OUTSIZE = -2;
const CTRL_WIDTH = 8;

export interface StyledFramePropsBase {
  frameOutsize: number,
  ctrlWidth: number,
  scale: number
};


export const StyledFrameBase = styled.div<StyledFramePropsBase>`

  position: absolute;
  pointer-events: none;
  /* background-color: rgba(0, 255, 0, 0.5); */

  box-sizing: border-box;

  top: ${props => -1 * props.frameOutsize}px;
  left: ${props => -1 * props.frameOutsize}px;
  width:  calc(100% + ${props => props.frameOutsize * 2}px);
  height: calc(100% + ${props => props.frameOutsize * 2}px);


  > div.ctrl {
    /* border: 1px solid #007aff; */

    box-sizing: border-box;
    &:active, &:hover {
    }    
  }

  /**
  Corners controls 
  **/

  > div.ctrlCorner {
    /* background-color: rgba(0, 255, 0, 0.5); */

    position: absolute;
    width: calc(5% + ${props => props.ctrlWidth / 2 / props.scale}px);
    min-width: 10px;
    height: calc(5% + ${props => props.ctrlWidth / 2/ props.scale}px);
    min-height: 10px;
  }

  > div.ctrlTopLeftWrapper {
    left: -${props => props.ctrlWidth / 2}px;
    top:  -${props => props.ctrlWidth / 2}px;
    clip-path: polygon(
      0px 0px, 
      100% 0px, 
      100% ${props => props.ctrlWidth}px, 
      ${props => props.ctrlWidth}px ${props => props.ctrlWidth}px,
      ${props => props.ctrlWidth}px 100%,
      0px 100%
    );
  }

  > div.ctrlTopRightWrapper {
    right: -${props => props.ctrlWidth / 2}px;
    top:  -${props => props.ctrlWidth / 2}px;
    clip-path: polygon(
      0px 0px,
      100% 0px, 
      100% 100%, 
      calc(100% - ${props => props.ctrlWidth}px) 100%,
      calc(100% - ${props => props.ctrlWidth}px) ${props => props.ctrlWidth}px,
      0px ${props => props.ctrlWidth}px
    );
  }

  > div.ctrlBottomLeftWrapper {
    left: -${props => props.ctrlWidth / 2}px;
    bottom:  -${props => props.ctrlWidth / 2}px;
    clip-path: polygon(
      0px 0px,
      ${props => props.ctrlWidth}px 0px, 
      ${props => props.ctrlWidth}px calc(100% - ${props => props.ctrlWidth}px), 
      100% calc(100% - ${props => props.ctrlWidth}px), 
      100% 100%, 
      0px 100%
    );
  }

  > div.ctrlBottomRightWrapper {
    right: -${props => props.ctrlWidth / 2}px;
    bottom:  -${props => props.ctrlWidth / 2}px;
    clip-path: polygon(
      calc(100% - ${props => props.ctrlWidth}px) 0px,
      100% 0px,
      100% 100%,
      0 100%,
      0 calc(100% - ${props => props.ctrlWidth}px),
      calc(100% - ${props => props.ctrlWidth}px) calc(100% - ${props => props.ctrlWidth}px)
    );
  }

  /**
  Horizontal controls 
  **/

  > div.ctrlHorizontal {
    position: absolute;
    width: 80%;
    height: ${props => props.ctrlWidth / props.scale}px;
    left: 10%;

  }

  > div.ctrlTopHorizontalWrapper {
    top: -${props => props.ctrlWidth / 2 / props.scale}px;
  }

  > div.ctrlBottomHorizontalWrapper {
    bottom: -${props => props.ctrlWidth / 2 / props.scale}px;
  }


 /**
  Vertical controls 
  **/

  > div.ctrlVertical {
    position: absolute;
    width:  ${props => props.ctrlWidth / props.scale}px;
    height: 80%;
    top: 10%;
  }

  > div.ctrlLeftVerticalWrapper {
    left: -${props => props.ctrlWidth / 2 / props.scale}px;
  }

  > div.ctrlRightVerticalWrapper {
    right: -${props => props.ctrlWidth / 2 / props.scale}px;
  }
`;


StyledFrameBase.defaultProps = {
  frameOutsize: FRAME_OUTSIZE,
  ctrlWidth: CTRL_WIDTH,
  scale: 1
};