import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './__use-items';
import useStates from './__use-states';


const useConfigSection = () => {
  const t = useTranslations();
  const items = useItems();
  
  const {
    visibleNone,
  } = useStates();


  const section = {
    subsections: [
      {
        title: t('page header, main info'),
        items: [
          items.getInfoVisible(),
        ]
      },
      {
        skip: visibleNone,
        items: [
          items.getLogoVisible(),
          items.getRevisionVisible(),
        ]
      },
    ]
  }
  
  return section;
}


export default useConfigSection;