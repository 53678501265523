import React from 'react';
import { useState } from 'react';

import InputComponent from 'lego/components/input';

import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useTranslations }    from 'app/ui/hooks/app/use-translation';
import { useDocState }        from 'app/ui/contexts/document';
import useContextElement      from 'app/ui-v2/editor-instruction/hooks/use-context-element';
import TitledElementComponent from 'app/ui-v2/editor-instruction/components/titled-element';
import ElementCreatePanelComponent from 'app/ui-v2/editor-instruction/views/shared/components/element-create-panel';
import useReleaselogColumnsAutoAdjust from '../../columns/use-releaselog-columns-auto-adjust';



interface Props {
  columnAddr: Types.ColumnAddr;
  onCancel?: () => void;
  onDone: (newColumnAddr: Types.ColumnAddr) => void;
}


export const NewColumnPanelComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    onCancel,
    onDone,
  } = props;

  const t = useTranslations();
  const document = useDocState();

  const [
    columnName, 
    setColumnName
  ] = useState("");

  const {
    hideElement
  } = useContextElement();

  const releaselogAddr = columnAddr;
  const columnsAutoAdjust = useReleaselogColumnsAutoAdjust(releaselogAddr);
  
  const handleInput = (value: string) => {
    setColumnName(value);
  }

  const addColumn = () => {
    const columnProps = {
      name: columnName
    }

    const newColumnAddr = document.releaselogs.addColumnAfter(
      columnAddr, 
      Types.ColumnType.TEXT,
      columnProps
    );

    columnsAutoAdjust({ debounced: false });
    document.saveUndo();

    hideElement();
    onDone(newColumnAddr);
  }

  const handleCreate = () => {
    addColumn();
  }

  const handleCancel = () => {
    onCancel?.();
  }

  return (
    <ElementCreatePanelComponent
      onCreate={handleCreate}
      onCancel={handleCancel}
    >
      <TitledElementComponent
        title={t("column name")}
      >
        <InputComponent 
          value={columnName}
          focusOnLoad={true}
          onValueChange={handleInput}
        />
      </TitledElementComponent>
    </ElementCreatePanelComponent>
  );
}
  
