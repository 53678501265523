export class RegistryKeysDown__ {
  private static instance: RegistryKeysDown__;
  
  public static getInstance(): RegistryKeysDown__ {
    if ( ! RegistryKeysDown__.instance) {
      RegistryKeysDown__.instance = new RegistryKeysDown__();
    }
    return RegistryKeysDown__.instance;
  }

  private _registry: Set<string>;

  constructor() {
    this._registry = new Set();
  }

  keyDown(key: string) {
    this._registry.add(key);
  }
  
  keyUp(key: string) {
    this._registry.delete(key);
  }

  isKeyDown(key: string) {
    return this._registry.has(key)
  }
}

const RegistryKeysDown = RegistryKeysDown__.getInstance();

export default RegistryKeysDown;