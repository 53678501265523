import produce from 'immer';

import * as Defaults from './defaults';
import * as State from './state';
import * as Tools from './tools';
import * as Types from './types';


export class RepoMarkers {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  //-----------------
  //
  // Setters

  addMarker(props: {
    type: Types.MarkerType,
    label?: Types.LabelProps,
    icon?:  Types.IconProps,
  } = Defaults.getMarkerProps()) {

    const type  = props.type;
    const label = props.label || Defaults.getMarkerLabelProp();
    const icon  = props.icon  || Defaults.getMarkerIconProp();

    const markerAddr = Tools.createMarkerAddr();

    this._state = produce(this._state, draft => {
      const markersAddrs = State.getMarkersAddrs(draft);
      const markersProps = State.getMarkersProps(draft);

      const markerKey = Tools.getMarkerKey(markerAddr);
      const markerProps = {
        type,
        label,
        icon,
      };

      markersAddrs.push(markerAddr);
      markersProps[markerKey] = markerProps;
    });

    return markerAddr;
  }

  duplicateMarker(srcMarkerAddr: Types.MarkerAddr) {
    const markerProps = this.getMarkerProps(srcMarkerAddr);

    const markerAddr = this.addMarker({
      type: markerProps.type,
      label: markerProps.label,
      icon:  markerProps.icon,
    });

    return markerAddr;
  }

  /**
   * Marker updates
   */
  updateMarker(
    markerAddr: Types.MarkerAddr,
    update: Types.MarkerPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const markerProps  = State.getMarkerProps(draft, markerAddr);
      const markersProps = State.getMarkersProps(draft);
      const markerKey    = Tools.getMarkerKey(markerAddr);

      markersProps[markerKey] = {
        ...markerProps,
        ...update,
      }
    });
  }

  /**
   * Marker Icon updates
   */
  updateMarkerIcon(
    markerAddr: Types.MarkerAddr,
    update: Types.IconPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const markerProps = State.getMarkerProps(draft, markerAddr);
      const icon = markerProps.icon;
      markerProps.icon = {
        ...icon,
        ...update,
      }
    });
  }

  updateMarkerIconCSS(
    markerAddr: Types.MarkerAddr,
    updateCSS: React.CSSProperties
  ) {
    this._state = produce(this._state, draft => {
      const markerProps = State.getMarkerProps(draft, markerAddr);
      const css = markerProps.icon.css;
      markerProps.icon.css = {
        ...css,
        ...updateCSS,
      }
    });
  }


  /**
   * Marker Label updates
   */
  updateMarkerLabel(
    markerAddr: Types.MarkerAddr,
    update: Types.LabelPropsUpdate
  ) {
    this._state = produce(this._state, draft => {
      const markerProps = State.getMarkerProps(draft, markerAddr);
      const label = markerProps.label;
      markerProps.label = {
        ...label,
        ...update,
      }
    });
  }

  updateMarkerLabelCSS(
    markerAddr: Types.MarkerAddr,
    updateCSS: React.CSSProperties
  ) {
    this._state = produce(this._state, draft => {
      const markerProps = State.getMarkerProps(draft, markerAddr);
      const css = markerProps.label.css;
      markerProps.label.css = {
        ...css,
        ...updateCSS,
      }
    });
  }

  deleteMarker(markerAddr: Types.MarkerAddr) {
    this._state = produce(this._state, draft => {
      const markersAddrs = State.getMarkersAddrs(draft);
      const markersProps = State.getMarkersProps(draft);

      const markerKey = Tools.getMarkerKey(markerAddr);
      const markerIdx = State.getMarkerIdx(draft, markerAddr);
      
      markersAddrs.splice(markerIdx, 1);
      delete markersProps[markerKey];
    });
  }

  moveMarker(
    srcMarkerAddr: Types.MarkerAddr, 
    dstMarkerAddr: Types.MarkerAddr
  ) {
    const markersEqual = Tools.compareMarkerAddr(srcMarkerAddr, dstMarkerAddr);
    if (markersEqual) {
      console.debug("The same marker - skip marker move");
      return;
    }

    this._state = produce(this._state, draft => {
      const markersAddrs = State.getMarkersAddrs(draft);
    
      const srcMarkerIdx = State.getMarkerIdx(draft, srcMarkerAddr)
      const dstMarkerIdxTmp = State.getMarkerIdx(draft, dstMarkerAddr)
    
      const srcIdxLowerThanDstIdx = (srcMarkerIdx < dstMarkerIdxTmp);
      markersAddrs.splice(srcMarkerIdx, 1)[0];
    
      const dstMarkerIdx = State.getMarkerIdx(draft, dstMarkerAddr);
    
      if (srcIdxLowerThanDstIdx) {
        markersAddrs.splice(dstMarkerIdx + 1, 0, srcMarkerAddr);
      }
      else {
        markersAddrs.splice(dstMarkerIdx, 0, srcMarkerAddr);
      }
    });
  }


  //-----------------
  //
  // Getters

  /**
   * Markers
   */
  getMarkers() {
    return State.getMarkers(this._state);
  }

  getMarkersAddrs() {
    return State.getMarkersAddrs(this._state);
  }

  getMarkersProps() {
    return State.getMarkersProps(this._state);
  }


  /**
   * Marker 
   */
  
  hasMarker(
    markerAddr: Types.MarkerAddr
  ): boolean {
    return State.hasMarker(this._state, markerAddr);
  }

  getMarkerProps(
    markerAddr: Types.MarkerAddr
  ): Types.MarkerProps {
    return State.getMarkerProps(this._state, markerAddr);
  }

  getMarkerIdx(
    markerAddr: Types.MarkerAddr
  ): number {
    return State.getMarkerIdx(this._state, markerAddr);
  }
}
