import { ContentTools, ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useContextMenu from 'app/ui-v2/editor-instruction/hooks/use-context-menu';

import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset from 'app/ui-v2/editor-image/hooks/use-selected-reset';
import useWidgetDuplicate from 'app/ui-v2/editor-image/hooks/widget/use-widget-duplicate';
import useWidgetEdit from 'app/ui-v2/editor-image/hooks/widget/use-widget-edit';
import useWidgetStyleCopy from 'app/ui-v2/editor-image/hooks/widget/use-widget-style-copy';
import useWidgetStylePaste from 'app/ui-v2/editor-image/hooks/widget/use-widget-style-paste';
import useWidgetStyleSetDefault from 'app/ui-v2/editor-image/hooks/widget/use-widget-style-set-default';


const useFunctions = () => {
  const docState = useDocState();

  const {
    showMenu,
    hideMenu,
  } = useContextMenu();

  const {
    setContent,
  } = useEditorStatesSetters();

  const resetSelected = useSelectedReset();

  const duplicateWidget = useWidgetDuplicate();
  const editWidget__ = useWidgetEdit();

  const copyWidgetStyle = useWidgetStyleCopy();
  const pasteWidgetStyle = useWidgetStylePaste();
  const setWidgetStyleDefault = useWidgetStyleSetDefault();

  const editWidget = (widgetAddr: ContentTypes.WidgetAddr) => {
    editWidget__({widgetAddr});

    const widgetHTMLId = ContentTools.getWidgetHTMLId(widgetAddr);
    const widgetElement = document.getElementById(widgetHTMLId);
    if ( ! widgetElement ) {
      console.warn(`Widget not found`);
      return;
    }

    const editorInput = widgetElement.querySelector<HTMLDivElement>('.editor-input');
    if ( ! editorInput ) {
      console.warn("Editor input not found.");
      return;
    }

    let count = 0;
    const INTERVAL_DELAY = 20;
    const TIMEOUT = 1200;

    //
    // We need to wait for editor input to become
    // content editable, due to `editWidget__` call above
    //
    const startEditInterval = setInterval(() => {
      if ( ! editorInput.contentEditable ) {
        count++;
        if ( count * INTERVAL_DELAY >= TIMEOUT) {
          console.warn(`Widget edit can't be completed, timeout`)          
          clearInterval(startEditInterval);
        }
        return;
      }

      clearInterval(startEditInterval);

      editorInput.focus();

      // Move the cursor to the end of the content
      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(editorInput);
      range.collapse(false); // Set to true if you want the cursor at the start

      selection?.removeAllRanges();
      selection?.addRange(range);
    }, INTERVAL_DELAY);
  }

  const moveUpWidget = (widgetAddr: ContentTypes.WidgetAddr) => {
    docState.content.cellImages_image_widget_moveTowardsTop(widgetAddr);
    docState.saveUndo();
    setContent();
  }

  const moveDownWidget = (widgetAddr: ContentTypes.WidgetAddr) => {
    docState.content.cellImages_image_widget_moveTowardsBottom(widgetAddr);
    docState.saveUndo();
    setContent();
  }

  const deleteWidget = (widgetAddr: ContentTypes.WidgetAddr) => {
    docState.content.cellImages_image_deleteWidget(widgetAddr);
    docState.saveUndo();
    setContent();

    resetSelected();
    hideMenu();
  }

  const isWidgetEditable = (widgetAddr: ContentTypes.WidgetAddr) => {
    const widget = docState.content.cellImages_image_getWidgetProps(widgetAddr);
    const isTextWidget = ContentTools.isWidgetEditorText(widget.type);

    return isTextWidget;
  }

  return  {
    hideMenu,

    copyWidgetStyle,
    pasteWidgetStyle,
    setWidgetStyleDefault,
    
    duplicateWidget,
    editWidget,
    isWidgetEditable,

    moveUpWidget,
    moveDownWidget,
    deleteWidget,
  }
}

export default useFunctions;