import Logger from 'libs/debug';
import * as Types from 'app/arch/editor-instruction/document/states/slicing/content/slice-request/types';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useSliceRequestSend = () => {
  const document = useDocState();
  const log = Logger.getContentSlicer();

  const {
    setContentSliceRequest
  } = useEditorStatesSetters();

  const hook = (request_?: Types.SliceRequest) => {
    log.debug("[ContentSlicer] set slice requeste");

    const request = request_ || {
      delayed: true,
      // onStart: () => {},
      // onDone:  () => {},
    };

    document.contentSliceRequest.requestSlicing(request);
    setContentSliceRequest();
  }

  return hook;
}


export default useSliceRequestSend;