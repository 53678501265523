import React from 'react';
import { useRecoilValue } from 'recoil';
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';
import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';
import { UIState_Releaselogs } from 'app/ui/states/editor-instruction';
import { UIState_ReleaselogsSession } from 'app/ui/states/editor-instruction';
import useReleaselogIsEditable from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-releaselog-is-editable';

import { HeaderCell } from './styles';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
  children: React.ReactNode;
}

export const CellIndexColumnCssComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children
  } = props;

  const document = useDocState();
  const lastRow  = document.releaselogs.isRowLast(cellAddr);
  
  const tableHeaderCSS = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);
  const columnCSS      = useRecoilValue(UIState_Releaselogs.columnCSS(cellAddr));
  const rowSelected    = useRecoilValue(UIState_ReleaselogsSession.isRowSelected(cellAddr));
  const isEditable     = useReleaselogIsEditable(cellAddr);

  if (columnCSS === null) {
    const msg = `Column CSS is null`;
    throw new Error(msg);
  }
  
  const {
    color: columnColor,
    background: columnBackground,
    ...columnStyle
  } = columnCSS;

  const {
    color: headerColor,
    background: headerBackground,
  } = tableHeaderCSS;

  const color = (
    ! (lastRow && isEditable) ?
    headerColor as string :
    columnColor as string
  );

  const background = (
    ! (lastRow && isEditable) ?
    headerBackground as string :
    columnBackground as string
  );

  return (
    <HeaderCell
      style={columnStyle}
  
      $selected={rowSelected}
      $color={color}
      $background={background}
      $editDisabled={! isEditable}
    >
      { children }
    </HeaderCell>
  );
}
  
