import React from 'react';
import jtl from 'tools/jtl';

import { UUID } from 'app/arch/types';
import { ReleaselogsTools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';
import { DateTxt } from './styles';


interface Props {
  releaselogId: UUID;
}


export const ContentDateComponent: React.FC<Props> = (props: Props) => {
  const {
    releaselogId,
  } = props;
  
  const document = useDocState();
  
  const releaselogAddr = { releaselogId };
  const releaselogProps = document.releaselogs.getReleaselogProps(releaselogAddr);
  const dateValue  = releaselogProps.info.date || ReleaselogsTools.getReleaseDateIso();
  const date: Date = new Date(dateValue);
  const [dateyyyy, _] = jtl.date.toYYYYMMDD(date);

  return (
    <DateTxt>
      { dateyyyy }
    </DateTxt>
  );
}
