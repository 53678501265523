
export enum MenuItemType {
  BUTTON = 'button',
  SUB_MENU = 'sub-menu',
}

export interface MenuItemButtonData {
  title: string;
  onClick: (event?: any) => void;

  disabled?: boolean;
  iconComponent?: any;
  variant?: string;
  dataTest?: string;
}

export interface MenuItemSubmenuData extends MenuItemButtonData {
  sections: MenuSection[];
}

type MenuItemData = (
  MenuItemButtonData |
  MenuItemSubmenuData
)

export interface MenuItem {
  type: MenuItemType;
  data: MenuItemData;
  skip?: boolean;
  disabled?: boolean;
}

export interface MenuSection {
  items: MenuItem[];
  skip?: boolean;
}

export interface MenuConfig {
  title?: string;
  sections: MenuSection[];
}

