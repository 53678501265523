import React from 'react';
import { P, Lines, Page, PageB, PageG, PageR, Pages } from './styles';

interface Props {
}


export const ViewSandboxComponent: React.FC<Props> = (props: Props) => {
  

  const renderText = () => {
    const numbers: number[] = Array.from({ length: 128 }, (_, i) => i );

    const Background = [
      "rgba(255, 0, 0, 0.4)",
      "rgba(0, 255, 0, 0.4)",
      "rgba(0, 0, 255,  0.4)",
      "rgba(255, 255, 0, 0.4)",
      "rgba(255, 0, 255, 0.4)",
      "rgba(0, 255, 255,  0.4)",
    ];


    const lines = numbers.map((i) => {
      const bgidx = i % Background.length;
      const bg = Background[bgidx];

      return (
        <P
          style={{ 
            background: bg
          }}
        >
          {/* <span 
            style={{
              fontSize: "14px"
            }}
          > */}
            {i}
          {/* </span> */}
        </P>
      );
    });

    return (
      <Lines>
     { lines }
    </Lines>
      
    );
  }

  const renderManual = () => {
    const fontSize = 240;

    return (
      <div
        className="editor-input" 
        role="textbox" 
        data-lexical-editor="true" 
        style={{
          userSelect: "text",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          background: "rgba(255, 0, 0, 0.4)",
        }}
  >
    <p style={{
        background: "rgba(0, 255, 0, 0.4)",
        fontSize: `${fontSize}px`,
      }}
    >
    F
    </p>

    <p style={{
        background: "rgba(0, 0, 255, 0.4)",
        fontSize: `${fontSize}px`,
      }}
    >
    B
    </p>
  </div>

    );
  }



  return (
    <Pages>

      <PageR>
        {renderText()}
      </PageR>
{/* 
      <PageG>
        ViewSandbox
      </PageG>

      <PageB>
        ViewSandbox
      </PageB> */}

    </Pages>
  );
}
  
