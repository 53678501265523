import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';
import { WidgetsStylesState as State } from 'app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles';
import { WidgetsStylesTypes } from 'app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles';


export const state = atom<State.State>({
  key: "doc_widgetsStyles",
  default: State.createInitialState()
});


//--------------------
//
// Selectors
//
export const libStylesAddrs = selectorFamily({
  key: "doc_widgetsStyles_libStylesAddrs",
  get: (styleLib: WidgetsStylesTypes.StyleLib) => ({ get }) => {
    const stateObj = get(state);
    const styles = State.getStyles(stateObj, styleLib);
    return styles.addrs;
  }
});