import styled from "styled-components";


interface ContentProps {
  $width: number;
  $height: number;

  $left: number;
  $top: number;

  $scale: number;
}


export const Content = styled.div.attrs<ContentProps>(props =>({
  style: {
    left: `${props.$left}px`,
    top: `${props.$top}px`,
    transform: `scale(${props.$scale})`,
  }
}))<ContentProps>`
  position: relative;
  overflow: clip;

  width:  ${props => props.$width}px;
  height: ${props => props.$height}px;

  border-style: solid;

  border-width:  ${props => props.theme.defs.border.width.normal};
  border-color:  ${props => props.theme.defs.colors.border.forth};
  border-radius: ${props => props.theme.defs.border.radius.xsmall};

  box-shadow: ${props => props.theme.defs.boxShadow.secondary};
  background: ${props => props.theme.defs.colors.background.forth};
  /* background: blue; */

  transform-origin: 0 0;
`;