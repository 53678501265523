import * as docx from "docx";
import jtl from 'tools/jtl';
import * as Defaults from '../../defaults';


class TextConverter {
  convert(lexicalState_: string | null): docx.Paragraph[] {
    const paragraphs: docx.Paragraph[] = [];
    let textRuns: docx.TextRun[] = [];

    // Recursive function to process each node
    const processNode = (node: any) => {
      if (node.children) {
          node.children.forEach((child: any) => processNode(child));
      }
  
      // Handle text nodes
      if (node.type === 'text' && node.text) {
        let bold = false;
        let italics = false;
        let fontColor = Defaults.getFont().color;
        let fontSize  = Defaults.getFont().size;
        let backgroundColor: undefined | string = undefined;

        // 
        // Extract font color based on style
        // Background can't be set on excel spreadsheet
        // (it looks like background color is a property
        // for activated excels only). 
        // For that reason if background-color is present
        // we use it to set font color. If color is also set
        // then it take precedense before background-color
        if (node.style) {
          const bgcolorMatch = node.style.match(/background-color:\s*([^;]+)/);
          if (bgcolorMatch && bgcolorMatch[1] !== '#FFFFFF') {
            backgroundColor = bgcolorMatch[1];
          }

          const colorMatch = node.style.match(/[^-]*color:\s*([^;]+)/);
          if (colorMatch ) {
            const color = colorMatch[1];
            fontColor = color;
          }

          const fontSizeMatch = node.style.match(/font-size:\s*([^;]+)/);
          if (fontSizeMatch ) {
            fontSize = jtl.css.valueToNumber(fontSizeMatch[1]);
          }
        }
  
        if (node.format) {
          const BOLD = 1;
          const ITALIC = 2;

          bold = (node.format & BOLD) === BOLD;
          italics = (node.format & ITALIC) === ITALIC;
        } 
  
        //
        // Create text run
        //
        const textOpt = Defaults.getTextOptions({
          shading: {
            fill: backgroundColor,
          },
          text: node.text,
          size: fontSize,
          color: fontColor,
          bold,
          italics,
        });
        
        const textRun = new docx.TextRun(textOpt);
        textRuns.push(textRun);
      }
  
      // Handle new lines
      if (node.type === 'paragraph' || node.type === 'listitem') {
        const textRun = new docx.TextRun({text: '\n'});
        textRuns.push(textRun);

        const paragraph = new docx.Paragraph({ children: textRuns});
        paragraphs.push(paragraph);
        textRuns = [];
      }
    };
  
    // Start processing from the root node
    if ( lexicalState_ !== null ) {
      const lexicalState = JSON.parse(lexicalState_);
      processNode(lexicalState.root);
    }

    if (textRuns.length !== 0) {
      const paragraph = new docx.Paragraph({ children: textRuns});
      paragraphs.push(paragraph);
      textRuns = [];
    }

    return paragraphs;
  }
}

export default TextConverter;
