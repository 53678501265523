import DivButton from "lego/styles/div_button";
import styled from "styled-components";


export const MainWrapper = styled(DivButton)<{$color: string}>`
  background  : ${props => props.$color};
  height: 32px;
  /* width: 42px; */
  flex-grow: 1;
  display: flex;
`;
