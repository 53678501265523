import styled from "styled-components";


export interface DemoProps {
  $width: string;
  $height: string;

}

export const Demo = styled.div.attrs<DemoProps>(props =>({
  style: {
    width:  `${props.$width}`,
    height: `${props.$height}`,
  }
}))<DemoProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0px;

  display: flex;
  flex-direction: column;
  /* background-color: rgba(255, 0, 0, 0.3); */
`;


export const Text = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10vw; /* Font size will scale with viewport width */
  color: #333;
  transition: font-size 0.3s ease;
  opacity: 0.2;
  font-variant: small-caps;
`;