import jtl from "tools/jtl";
import { ShapeLayerType, ShapeProps } from "../../types";

import { Positioner } from "./styles";
import { Center } from "./styles";
import { Clip } from "./styles";

import { Dot } from "./styles";
import { DotsWrapper } from "./styles";


const FINAL_DOT_SIZE = 0;


interface Props extends ShapeProps{
}


export const ShapeDotsComponent: React.FC<Props> = (props: Props) => {
  const {
    startPoint, 
    endPoint,
    style,
    layer,
  } = props;



  const backgroundColors: {[key in ShapeLayerType]: any} = {
    [ShapeLayerType.BOTTOM]: style.backgroundColor,
    [ShapeLayerType.MIDDLE]: style.backgroundColor,
    [ShapeLayerType.TOP]:    'transparent',
  }

  const borderColors: {[key in ShapeLayerType]: any} = {
    [ShapeLayerType.BOTTOM]: style.borderColor,
    [ShapeLayerType.MIDDLE]: style.borderColor,
    [ShapeLayerType.TOP]:    'transparent',
  }

  const outlineColors: {[key in ShapeLayerType]: any} = {
    [ShapeLayerType.BOTTOM]: style.outlineColor,
    [ShapeLayerType.MIDDLE]: 'transparent',
    [ShapeLayerType.TOP]:    'transparent',
  }


  const arrowHeadHeight  = jtl.css.valueToNumber(style.arrowHeadHeight);
  const arrowHeadWidth   = jtl.css.valueToNumber(style.arrowHeadWidth);
  const borderWidth      = jtl.css.valueToNumber(style.borderWidth);
  const outlineWidth     = jtl.css.valueToNumber(style.outlineWidth);
  
  const dx = endPoint[0] - startPoint[0];
  const dy = endPoint[1] - startPoint[1];
  const arrowAngle = jtl.geometry.calculateAngle(...startPoint, ...endPoint);

  const left = startPoint[0];
  const top  = startPoint[1];

  const height = Math.sqrt(dx ** 2 + dy ** 2);
  // const width = arrowHeadWidth * 2 + 2 * (borderWidth + outlineWidth);
const width = 1000;

  const renderDots = () => {
    const DOT_START_RADIUS = arrowHeadWidth;
    const DOT_DELTA  = arrowHeadHeight;
    
    let distance = height;
    let dotSize = 2 * DOT_START_RADIUS + 2 * (borderWidth + outlineWidth);
    let dotsCount = 0;

    for (let i=0;; i++) {
      distance -= dotSize;
      if (distance < 0) {
        break;
      }

      dotSize += DOT_DELTA;
      dotsCount++;
    }
    const dotsIdxs = Array.from({ length: dotsCount }, (_, index) => index);
    
    dotSize = 2 * DOT_START_RADIUS + 2 * (borderWidth + outlineWidth);
    const dots = dotsIdxs.map((dot, idx) => {
      dotSize += (dot * DOT_DELTA);
      const width =  dotSize;
      const height = width;

      return (
        <Dot 
          key={idx}
          style={{
            ...style,

            width:  `${width}px`,
            height: `${height}px`,

            backgroundColor: backgroundColors[layer],
            borderColor:     borderColors[layer],
            outlineColor:    outlineColors[layer],
            borderRadius:    '50%'
          }}
        />
      )
    })

    return (
      <DotsWrapper>
      { dots }
      </DotsWrapper>
    );
  }

  return (
    <Positioner
      style={{
        left: `${left}px`,
        top:  `${top}px`,
        transform: `rotate(${arrowAngle}rad)`,
        transformOrigin: `0px 0px`,
      }}
    >
      <Center
        style={{
          left: `${-1 * width / 2}px`,
          top:  `${0}px`,
        }}
      >
        <Clip 
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          { renderDots() }
        </Clip>
      </Center>
    </Positioner>             
  );
}


