import React from 'react';

import TooltipComponent from 'lego/components/tooltip';

import { PanelControlsItemSectionControl_ToolbarConfig as Config} from '../../config';
import { ToolbarButton }  from '../../config';
import { ToolbarSection } from '../../config';
import { unpackBoolean }  from '../../tools';

import { Button }          from './styles';
import { ButtonsWrapper }  from './styles';
import { SectionsWrapper } from './styles';



interface Props {
  config: Config;
}


export const ControlToolbarComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;

  const renderButton = (button: ToolbarButton, idx: number) => {
    const Icon = button.Icon;
    const btnSelected = unpackBoolean(button.selected);

    return (
      <TooltipComponent
        key={idx}
        disabled={button.tooltip === undefined}
        title={button.tooltip}
      >
        <Button
          onClick={button.onClick}
          $selected={btnSelected}
        >
        { Icon && <Icon /> }    
        </Button>
      </TooltipComponent>
    );
  }

  const renderSection = (section: ToolbarSection, idx: number) => {
    const buttons = section.buttons.map((button, idx) => renderButton(button, idx));
    return (
      <ButtonsWrapper
        key={idx}
      >
      { buttons }
      </ButtonsWrapper>
    );
  }

  const renderButtons = () => {
    const sections = config.sections.map((section, idx) => renderSection(section, idx));

    return (
      <SectionsWrapper>
        { sections }
      </SectionsWrapper>
    )
  }

  return (
    <>
      { renderButtons() }
    </>
  )
}
  
