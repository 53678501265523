import styled from 'styled-components';
import Scrollbar from 'lego/styles/scrollbar';


const LayoutScroller = styled(Scrollbar)`
  height: 100%;
  width: 100%;

  overflow: auto;
`;


export default LayoutScroller;
