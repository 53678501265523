import * as Defaults from './defaults';
import * as Tools from "./tools"
import * as Types from "./types"
import { SubscriptionsTools } from '../subscriptions';
import { SubscriptionsTypes } from '../subscriptions';


/**
 * State
 */

export type State = {
  subscriptionsInvoices: Types.SubscriptionInvoices,
  loaded: boolean
}


/**
 * Getters
 */

export const getLoaded = (
  state: State, 
): boolean => {
  return state.loaded;
}

export const getSubscriptionsInvoices = (
  state: State, 
): Types.SubscriptionInvoices => {
  return state.subscriptionsInvoices;
}

export const hasInvoices = (
  state: State, 
  subscriptionAddr: SubscriptionsTypes.SubscriptionAddr
): boolean => {
  const subscriptionKey = SubscriptionsTools.getSubscriptionKey(subscriptionAddr);
  const subscriptionsInvoices = getSubscriptionsInvoices(state);
  
  const invoices = subscriptionsInvoices[subscriptionKey];
  return (invoices !== undefined);
}

export const getInvoices = (
  state: State, 
  subscriptionAddr: SubscriptionsTypes.SubscriptionAddr
): Types.Invoices => {
  const subscriptionKey = SubscriptionsTools.getSubscriptionKey(subscriptionAddr);
  const subscriptionsInvoices = getSubscriptionsInvoices(state);
  
  const invoices = subscriptionsInvoices[subscriptionKey];
  if (invoices === undefined) {
    const msg = `Invoices not found for, subscription key: ${subscriptionKey}`;
    throw new Error(msg);
  }

  return invoices;
}

export const getInvoicesAddrs = (
  state: State, 
  subscriptionAddr: SubscriptionsTypes.SubscriptionAddr
): Types.InvoicesAddrs => {
  const invoices = getInvoices(state, subscriptionAddr);
  return invoices.addrs;
}

export const getInvoicesProps = (
  state: State, 
  subscriptionAddr: SubscriptionsTypes.SubscriptionAddr
): Types.InvoicesProps => {
  const invoices = getInvoices(state, subscriptionAddr);
  return invoices.props;
}

export const getInvoiceProps = (
  state: State, 
  invoiceAddr: Types.InvoiceAddr
): Types.InvoiceProps => {
  const invoicesProps = getInvoicesProps(state, invoiceAddr);
  const invoiceKey = Tools.getInvoiceKey(invoiceAddr);
  
  const invoiceProps = invoicesProps[invoiceKey];
  if (invoiceProps === undefined) {
    const msg = `Invoice not found, for key: ${invoiceKey}`;
    throw new Error(msg);
  }

  return invoiceProps;
}


/**
 * Create Init State
 */
export const createInitialState = (): State => {
  const initState: State = {
    subscriptionsInvoices: Defaults.getSubscriptionsInvoices(),
    loaded: false,
  }
  return initState;
}
