import React from 'react';
import { useDocState }        from 'app/ui/contexts/document';
import { DNDContext }         from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';
import { DropWrapper }        from './styles';


interface Props {
  children: React.ReactNode;
}


export const MarkersDropComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const document = useDocState();
  const getDNDData = useDNDDataGet();

  const { 
    setHeaderMarkers 
  } = useEditorStatesSetters();

  const handleDrop_fromCell = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;

    const cellMarkerProps = document.content.cellMarkers_getMarkerProps(markerAddrDropped);

    document.headerMarkers.addMarker(cellMarkerProps.repoMarkerAddr);
    document.saveUndo();
    setHeaderMarkers();
  }

  const handleDrop_fromGallery = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;

    document.headerMarkers.addMarker(markerAddrDropped);
    document.saveUndo();
    setHeaderMarkers();
  }

  const handleDrop_fromEditor = () => {
    handleDrop_fromGallery();
  }

  const handleDrop_fromDocHeader = () => {
    // TODO
  }

  const contextsDefs: ContextDef[] = [
    {
      dndContext: DNDContext.marker.cell,
      onDrop: handleDrop_fromCell,
    },
    {
      dndContext: DNDContext.marker.gallery,
      onDrop: handleDrop_fromGallery
    },
    {
      dndContext: DNDContext.marker.docHeader,
      onDrop: handleDrop_fromDocHeader
    },
    {
      dndContext: DNDContext.marker.editor,
      onDrop: handleDrop_fromEditor
    },
  ];

  return (
    <DNDDropComponent
      contextsDefs={contextsDefs}
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
  

  
