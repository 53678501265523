import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "./use-editor-states-setters";


const useEditorCrashTest = () => {
  const docState = useDocState();
  const {
    setContent
  } =  useEditorStatesSetters();

  const crashSoft = () => {
    docState.testCrash();
    setContent();
  }

  const crasHard = () => {
    docState.testCrashHard();
    setContent();
  }

  return { 
    crashSoft,
    crasHard
  }
}

export default useEditorCrashTest;
