import * as Types from './types';


/**
 * Logo
 */
export const getLogoImage = (): Types.LogoImage => ({
  filename: "",
  width: 0,
  height: 0,
  bid: -1,
});

export const getLogoView = (): Types.LogoView => ({
  position: [0, 0],
  scale: 1
});

export const getLogo = (): Types.Logo => ({
  visible: true,
  image: null,
  view: getLogoView(),
});


/**
 * Release Info
 */
export const getReleaseInfo = (): Types.ReleaseInfo => ({
  visible: true,
});
