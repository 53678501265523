import styled from "styled-components";


export const ContentWrapper = styled.div`
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  padding: ${props => props.theme.defs.padding.medium};
`;


export default ContentWrapper;
