import produce from 'immer';
import * as State from './state';
import * as Types from './types';


class OAuth {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }


  /**
   * Setters
   */

  updateOAuth(update: Types.OAuthUpdate) {
    this._state = produce(this._state, draft => {
      draft.oauth = {
        ...draft.oauth,
        ...update,
      }
    });
  }


  /**
   * Getters
   */

  getOuath(): Types.OAuth { 
    return State.getOAuth(this._state);
  }
}

export default OAuth;