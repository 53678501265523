import styled from "styled-components";


const DraggedItemBorder = styled.div`
  box-sizing: border-box;

  border-width: 1px;
  border-style: solid;
  border-color: black;
`;


export default DraggedItemBorder;