import React from 'react';

import jtl from 'tools/jtl';

import { FrameHandlerBase }    from 'lego-v2/frame-resize/arch';
import { FrameHandlerTypical } from 'lego-v2/frame-resize/arch';
import { FrameTargetUpdate }   from 'lego-v2/frame-resize/arch';

import { Size }     from 'app/arch/types';
import { Position } from 'app/arch/types';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';
import useEditorStatesSetters  from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import FrameResizerSmartLinesComponent from '../../../../smart-lines/frame-resizer-smart-lines';

import * as Tools from '../tools';

import { FrameWrapper } from './styles';
import { Frame } from './styles';


interface Props {
  dataTest: string | null;
  widgetAddr: ContentTypes.WidgetAddr;
  scale: number;

  left: number;
  top: number;
  width: number;
  height: number;

  onFrameUpdate?: (update: FrameTargetUpdate) => void;
  onFrameUpdateDone?: (update: FrameTargetUpdate, updated: boolean) => void;
  frameHandlerFactory?: (
    scale: number,
    startPosition: Position, 
    startSize: Size, 
    minSize: Size,
  ) => FrameHandlerBase;

  position: Position,
  onPositionUpdate: (position: Position) => void;
  onPositionUpdateDone: (position: Position) => void;
  
  size: Size,
  onSizeUpdate: (size: Size) => void;
  onSizeUpdateDone: (size: Size) => void;
}


export const WidgetBoxedFrameComponent: React.FC<Props> = (props: Props) => {
  const { 
    dataTest,

    scale,
    widgetAddr,

    left,
    top,
    width,
    height,

    onFrameUpdate,
    onFrameUpdateDone,

    position,
    onPositionUpdate,
    onPositionUpdateDone,

    size,
    onSizeUpdate,
    onSizeUpdateDone,
  } = props;

  const document = useDocState();

  const {
    setEditorImageSession
  } = useEditorStatesSetters();

  const frameHandlerFactory = props.frameHandlerFactory || defaultFrameHandlerFactory;
  const widgetProps = document.content.cellImages_image_getWidgetProps(widgetAddr) as ContentTypes.WidgetBoxedProps;


  /**
   * Calculate frame position and size
   */
  const outlineWidth = jtl.css.valueToNumber(widgetProps.style.outlineWidth);

  const frameLeft   = left - outlineWidth;
  const frameTop    = top  - outlineWidth;
  const frameWidth  = width  + 2 * outlineWidth;
  const frameHeight = height + 2 * outlineWidth;



  /**
   * Frame handlers
   */
  const handleFrameUpdate = (update: FrameTargetUpdate) => {
    if ('position' in update) { 
      update.position = Tools.round(update.position !);
      onPositionUpdate(update.position);
    }
    
    if ('size' in update) {
      update.size = Tools.round(update.size !);
      onSizeUpdate(update.size);

      document.editorImageSession.setWidgetResizingSize(update.size);
      setEditorImageSession();
    }

    onFrameUpdate?.(update);
  }

  const handleFrameUpdateDone = (update: FrameTargetUpdate) => {
    handleFrameUpdate(update);

    document.editorImageSession.setWidgetResizingSize(null);
    setEditorImageSession();

    let updated = false;

    if ('position' in update) { 
      update.position = Tools.round(update.position !);
      onPositionUpdateDone(update.position);
      updated = true;
    }

    if ('size' in update) {
      update.size = Tools.round(update.size !);
      onSizeUpdateDone(update.size);
      updated = true;
    }

    if (updated) {
      // If document has been updated,
      // but it also populates update to
      // parent component, do not save undo.
      // As parent caller will change state
      // and save undo. If undo was saved here
      // it would result in two unnecessary 
      // undo saves (undo would save intermidate
      // step).
      if ( ! onFrameUpdateDone ) {
        document.saveUndo();
      }
    }

    onFrameUpdateDone?.(update, updated);
  }

  return (
    <FrameWrapper
      $left={frameLeft}
      $top={frameTop}
      $width={frameWidth}
      $height={frameHeight}
    >
      <FrameResizerSmartLinesComponent
        dataTest={dataTest}
        scale={scale}

        size={size}
        minSize={[24, 24]}
        position={position}

        widgetAddr={widgetAddr}
        elementStyle={widgetProps.style}
        frameComponent={Frame}

        onUpdate={handleFrameUpdate}
        onUpdateDone={handleFrameUpdateDone}
        handlerFactory={frameHandlerFactory}
      />
    </FrameWrapper>
  );
}


const defaultFrameHandlerFactory = (
  scale: number,
  startPosition: Position, 
  startSize: Size, 
  minSize: Size
) => {
  return new FrameHandlerTypical(scale, startPosition, startSize, minSize);
}
