import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import HLC_MarkerDraggerComponent from 'app/ui-v2/editor-instruction/hls/markers/marker-dragger';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    children, 
    markerAddr, 
  } = props;

  const setDNDData = useDNDDataSet();
  
  const handleDragStart = () => {
    const data = JSON.stringify({markerAddr});
    setDNDData(data);
  }

  return (
    <HLC_MarkerDraggerComponent
      markerAddr={markerAddr}
      dndContext={DNDContext.marker.gallery}
      onDragStart={handleDragStart}
    >
      { children }
    </HLC_MarkerDraggerComponent>
  );
}
