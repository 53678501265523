import { UUID } from "app/arch/types";
import { uuid } from "tools/uuid";
import * as Types from "./types";


export const createStyleAddr = (): UUID => {
  return uuid();
}

export const getStyleKey = (
  styleAddr: Types.StyleAddr
): string => (
  `${styleAddr}`
);

export const compareStyleAddr = (
  srcMarkerAddr: Types.StyleAddr,
  dstMarkerAddr: Types.StyleAddr,
): boolean => (
  srcMarkerAddr === dstMarkerAddr
);