import styled from "styled-components";


export const MainWrapper = styled.div`

`;

export const StyledBox = styled.div`
  contain: content;
  border-style: solid;
  border-width: 0px;
  border-color: transparent;
  outline-style: solid;
  outline-width: 0px;
  outline-color: transparent;
`;