import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_ContentSession } from 'app/ui/states/editor-instruction';
import ImageUploadingViewComponent from './image-uploading-view';



interface Props  {
  cellAddr: ContentTypes.CellAddr;
}


export const ImagesUploadingComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;
  
  const imagesUploadingCount = useRecoilValue(UIState_ContentSession.cellImages_imagesUploadingCount(cellAddr));

  const renderImages = () => {
    if (imagesUploadingCount === 0) {
      return null;
    }

    const indexes = Array(imagesUploadingCount).fill(0).map((_, i) => i + 1);
    const imagesComponents = indexes.map((idx) => (
      <ImageUploadingViewComponent 
        key={idx}
        cellAddr={cellAddr}
      />
    ));
    
    return imagesComponents;
  }

  return renderImages();
}
