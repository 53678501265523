import React from 'react';
import { useRecoilValue } from 'recoil';
import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import { useDocState } from 'app/ui/contexts/document';
import { UIState_Content } from 'app/ui/states/editor-instruction';


interface Props {
  cellAddr: Types.CellAddr;
}


export const CellIndexValueComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr
  } = props;

  const docState = useDocState();
  const indexingType = useRecoilValue(UIState_Content.sectionsIndexing);

  const rowIdxLocal  = docState.content.getRowIdxSection(cellAddr);
  const rowIdxGlobal = docState.content.getRowIdxGlobal(cellAddr);
  
  const idx = (
    indexingType === Types.SectionsIndexing.GLOBAL ?
    rowIdxGlobal :
    rowIdxLocal
  );

  const stepNo = idx + 1;

  return stepNo;
}
