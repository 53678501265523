import Relogin from "./relogin";


class Modals {
  private _relogin: Relogin;

  constructor() {
    this._relogin = new Relogin();
  }

  get relogin() { return this._relogin; }
}


export default Modals;