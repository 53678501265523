import React from 'react';
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';

import { DNDContext } from 'app/ui-v2/editor-instruction/dnd-context';
import HLS_DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';

import HeaderCellDraggedViewComponent from './header-cell-dragged-view';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageColumnAddr,
    children
  } = props;

  const setDNDData = useDNDDataSet();

  const handleDragStart = () => {
    const data = { columnAddr: pageColumnAddr };
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  return (
    <HLS_DraggerComponent
      dndContext={DNDContext.viewContent.customFields.column}
      onDragStart={handleDragStart}
      draggedView={
        <HeaderCellDraggedViewComponent
          pageColumnAddr={pageColumnAddr}
        />
      }
    >
      { children }
    </HLS_DraggerComponent>
  );
}
