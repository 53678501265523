import { UUID } from 'app/arch/types';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import * as TypesSession from './types';
import * as State from './state';


export class ReleaselogsSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  //----------------------
  // Setters
  //

  setCellSelected(cellAddr: Types.CellAddr | null) {
    const nextState = State.setCellSelected(this._state, cellAddr);
    this.processNewState(nextState);
  }
  
  setColumnSelected(columnAddr: Types.ColumnAddr | null) {
    const nextState = State.setColumnSelected(this._state, columnAddr);
    this.processNewState(nextState);
  }
  
  setRowSelected(rowAddr: Types.RowAddr | null) {
    const nextState = State.setRowSelected(this._state, rowAddr);
    this.processNewState(nextState);
  }

  /**
   * @deprecated Looks like it is not needed any more
   */
  setSignalColumnsWidth(releaselogId: UUID) {
    const nextState = State.setSignalColumnsWidth(this._state, releaselogId);
    this.processNewState(nextState);
  }

  setPanelDocument(update: Partial<TypesSession.PanelDocumentState>) {
    const nextState = State.setPanelDocument(this._state, update);
    this.processNewState(nextState);
  }

  //----------------------
  // Getters
  //

  getCellSelected() {
    return State.getCellSelected(this._state);
  }

  getColumnSelected() {
    return State.getColumnSelected(this._state);
  }

  getRowSelected() {
    return State.getRowSelected(this._state);
  }

  getSignalColumnsWidth(releaselogId: UUID) {
    return State.getSignalColumnsWidth(this._state, releaselogId);
  }

  private processNewState(newState: State.State) {
    return this._state = newState;
  }
}
