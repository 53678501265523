import { Size } from "app/arch/types";
import { Page } from "app/arch/print/page";
import environment from "app/environment";
import { uuid } from 'tools/uuid';

import DocState from '../../doc-state';
import * as Types from './types';


/**
 * Column
 */
export const getColumnKey = (
  columnAddr: Types.ColumnAddr
): string => (
  `${columnAddr.columnId}`
);

export const createColumnAddr = (
): Types.ColumnAddr => {
  const columnId = uuid();
  const columnAddr = { columnId };
  return columnAddr;
} 

export const compareColumnAddr = (
  srcColumnAddr: Types.ColumnAddr,
  dstColumnAddr: Types.ColumnAddr,
): boolean => (
  srcColumnAddr.columnId === dstColumnAddr.columnId
);

export const columnsAutoAdjust = (document: DocState) => {
  const columnsAddrs = document.headerFields.getColumnsAddrs();
  
  const pageLayout  = document.viewsCommon.getPageLayout();
  const pageMargins = document.viewsCommon.getPageMargins();
  
  const contentSize = Page.getBodySizePx(
    pageLayout.format, 
    pageLayout.orientation, 
    pageMargins
  ) as Size;


  const totalWidth = contentSize[0];
  const columnsWidthTotal = columnsAddrs.reduce((accumulator, columnAddr) => {
    const newValue = accumulator + document.headerFields.getColumnWidth(columnAddr);
    return newValue;
  }, 0);

  const deltaWidth = totalWidth - columnsWidthTotal;

  const columnsWidthRatio = columnsAddrs.map((columnAddr) => {
    const columnWidth = document.headerFields.getColumnWidth(columnAddr);
    return columnWidth / columnsWidthTotal;
  });

  const columnsWidthInit = columnsAddrs.map((columnAddr) => {
    const columnWidth = document.headerFields.getColumnWidth(columnAddr);
    return columnWidth;
  });

  columnsAddrs.forEach((columnAddr, idx) => {
    const widthInit     = columnsWidthInit[idx];
    const deltaRatioed  = columnsWidthRatio[idx] * deltaWidth;
    const widthAdjusted = widthInit + deltaRatioed;
    const update = { width: widthAdjusted };

    document.headerFields.updateColumn(columnAddr, update);
  });
}


/**
 * Page Column
 */

export const getPageColumnKey = (
  columnAddr: Types.PageColumnAddr
): string => (
  `${columnAddr.pageIdx}::`  +
  `${columnAddr.columnId}`
);

export const pageColumnKeyToAddr = (columnKey: string): Types.PageColumnAddr => {
  const [pageIdxStr, columnId] = columnKey.split("::");
  const pageIdx = +pageIdxStr;
  
  return {
    pageIdx,
    columnId,
  };
};


export const comparePageColumnAddr = (
  srcColumnAddr: Types.PageColumnAddr,
  dstColumnAddr: Types.PageColumnAddr,
): boolean => (
  compareColumnAddr(srcColumnAddr, dstColumnAddr) && 
  (srcColumnAddr.pageIdx === dstColumnAddr.pageIdx)
);

export const getPageColumnDataTest = (props: {
  pageColumnAddr: Types.PageColumnAddr, 
  docState: DocState,
  prefix: string,
}): string => {
  if ( ! environment.dev) {
    return '';
  }

  const {
    pageColumnAddr,
    docState,
    prefix
  } = props;

  const columnIdx = docState.headerFields.getColumnIdx(pageColumnAddr);
  const pageIdx  = pageColumnAddr.pageIdx
  
  return `${prefix}-${pageIdx}-${columnIdx}`;
}


/**
 * Cell
 */
export const getCellKey = (
  cellAddr: Types.CellAddr
): string => (
  `${cellAddr.columnId}`
);

export const compareCellAddr = (
  srcCellAddr: Types.CellAddr,
  dstCellAddr: Types.CellAddr,
): boolean => (
  srcCellAddr.columnId === dstCellAddr.columnId
);

export const debugCellAddr = (
  cellAddr: Types.CellAddr
): string => (
  `columnId: ${cellAddr.columnId}`
);


/**
 * Page Cell 
 */
export const comparePageCellAddr = (
  srcCellAddr: Types.PageCellAddr,
  dstCellAddr: Types.PageCellAddr,
): boolean => (
  compareCellAddr(srcCellAddr, dstCellAddr) &&
  ( srcCellAddr.pageIdx === dstCellAddr.pageIdx )
);

export const getPageCellDataTest = (props: {
  pageCellAddr: Types.PageCellAddr, 
  docState: DocState,
  prefix: string,
}): string => {
  if ( ! environment.dev) {
    return '';
  }

  const {
    pageCellAddr,
    docState,
    prefix
  } = props;

  const pageIdx = pageCellAddr.pageIdx
  const cellIdx = docState.headerFields.getColumnIdx(pageCellAddr);

  return `${prefix}-${pageIdx}-${cellIdx}`;
}
