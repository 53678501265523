import React from 'react';
import { MenuSection } from 'lego-v2/menu/config';
import ToolbarSectionsComponent from './toolbar-sections';
import TopToolbar from '../../styles/top-toolbar';


interface Props {
  sections: MenuSection[];
}


export const TopToolbarComponent: React.FC<Props> = (props: Props) => {
  const {
    sections,
  } = props;


  return (
    <TopToolbar>
      <ToolbarSectionsComponent sections={sections} />
    </TopToolbar>
  );
}
