import React from 'react';
import PricePlansLayoutComponent from './components/price-plans-layout';
import PricePlansPlansComponent  from './components/price-plans-plans';
import PricePlansBillingPeriodToggleComponent from './components/price-plans-billing-period-toggle';


interface Props {
}


export const PricePlansComponent: React.FC<Props> = (props: Props) => {

  return (
    <PricePlansLayoutComponent>
      <PricePlansBillingPeriodToggleComponent />
      <PricePlansPlansComponent />
    </PricePlansLayoutComponent> 
  );
}

