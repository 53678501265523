import { UUID } from "app/arch/types";


/**
 * Action addr
 */
export type ActionAddr = {
  actionId: UUID
};


/**
 * Actions addrs
 */
export type ActionsAddrs = ActionAddr[];


export enum ActionState {
  STARTED = 'started',
  STOPPING_FINISHED = 'stopping-finished',
  STOPPING_TIMEOUT  = 'stopping-timeout',
}


/**
 * Action Props
 */
export interface ActionProps {
  state: ActionState,

  textStart: string,
  textStop: string,
  timeout: number,
}

export interface ActionCreateProps {
  textStart: string,
  textStop: string,
  timeout?: number,
}

export type ActionPropsUpdate = Partial<
  Omit< ActionProps, "textStart" | "textStop" | "timeout">
> ;


/**
 * Actions Props
 */
export type ActionsProps = {
  [actionKey: string]: ActionProps
};


/**
 * Actions State
 */
export type Actions = {
  addrs: ActionsAddrs,
  props: ActionsProps,
};



export type Listener = ((actions: Actions) => void) | null;