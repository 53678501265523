import * as State from './state';
import * as Types from './types';


export class EditorSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  //----------------------
  // 
  // Setters
  //

  setToolbarItems(update: {
    visible?: boolean,
    selected?: Types.ToolbarItems
  }) {
    const nextState = State.setToolbarItems(this._state, update);
    this.processNewState(nextState);
  }

  setToolbarProperties(update: {
    visible?: boolean,
    selected?: Types.ToolbarProperties
  }) {
    const nextState = State.setToolbarProperties(this._state, update);
    this.processNewState(nextState);
  }

  setDNDContext(context: string | null) {
    const nextState = State.setDNDContext(this._state, context);
    this.processNewState(nextState);
  }

  setDNDData(data: string | null) {
    const nextState = State.setDNDData(this._state, data);
    this.processNewState(nextState);
  }

  setViewType(viewType: Types.ViewType) {
    const nextState = State.setViewType(this._state, viewType);
    this.processNewState(nextState);
  }

  //----------------------
  // 
  // Getters

  getToolbarItemsVisible() {
    return State.getToolbarItemsVisible(this._state);
  }

  getToolbarItemsSelected() {
    return State.getToolbarItemsSelected(this._state);
  }

  getDNDContext() {
    return State.getDNDContext(this._state);
  }

  getDNDData() {
    return State.getDNDData(this._state);
  }

  getViewType() {
    return State.getViewType(this._state);
  }

  //--------------------
  

  private processNewState(newState: State.State) {
    return this._state = newState;
  }
}
