import React from 'react';
import { SideToolbarConfig } from './config';
import SideToolbarButtonComponent from './side-toolbar-button';
import { Toolbar } from './styles';
import * as Types from '../types';


interface BaseProps {
  config: SideToolbarConfig;
}

type Props = Types.PropLeftOrRight & BaseProps;


export const SideToolbarComponent: React.FC<Props> = (props: Props) => {
  const {
    config,

    left,
    right,
  } = props;

  const renderItems = () => {
    const items = config.items.map((item, idx) => {
      return (
        <SideToolbarButtonComponent
          key={idx}
          tooltip={item.tooltip}
          selected={item.selected}
          onClick={item.onClick}
          Icon={item.Icon}
          dataTest={item.dataTest}
        />
      )
    });

    return items;
  }

  return (
    <Toolbar
      $left={left}
      $right={right}
    >
      { renderItems() }
    </Toolbar>
  );
}
