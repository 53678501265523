import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import HLS_WidgetsComponent     from '../../../hls/widgets';
import WidgetTextComponent      from './widget-text';
import WidgetArrowTextComponent from './widget-arrow-text';


export interface Props {
  scale: number;
  imageAddr: ContentTypes.ImageAddr;
}


export const WidgetsComponent: React.FC<Props> = (props: Props) => {
  const {
    scale,
    imageAddr
  } = props;

  return (
    <HLS_WidgetsComponent
      scale={scale}
      dataTest={'editor-image'}
      imageAddr={imageAddr}
      widgetTextComponent={WidgetTextComponent}
      widgetArrowTextComponent={WidgetArrowTextComponent}
      editDisabled={false}
    />
  );
}
