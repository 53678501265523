import styled from "styled-components";


interface Props {
  selected: boolean;
}

const CellsSelectedBackground = styled.div<Props>`
  position: absolute;
  pointer-events: none;

  height: 100%;
  width: 100%;

  background: ${
    props => props.selected ? 
    props.theme.defs.accentColor.opacity02.lightest : 
    "transparent"
  };
`;


export default CellsSelectedBackground;
