import styled from 'styled-components';
import PanelBase  from 'app-views/styles/panel';
import ButtonText from 'lego/styles/button_text';



export const Panel = styled(PanelBase)`
`;

export const ForgotPasswordAnchor = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

export const ForgotPassword = styled(ButtonText)`
  position: absolute;
  z-index: 1;
  height: auto;
  top: -5px;
  cursor: pointer;

  padding: ${props => props.theme.defs.padding.small};
  font-size: ${props => props.theme.defs.font.size.small};
  font-weight: ${props => props.theme.defs.font.weight.large};

  border: none;
  background: none;
`;
