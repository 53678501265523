import React from 'react';
import SectionAdderProbeComponent  from './section-adder-probe';
import SectionAdderViewComponent   from './section-adder-view';
import SectionAdderCSSComponent    from './section-adder-css';
import SectionAdderSelectComponent from './section-adder-select';

import { GridColumnSpan } from './styles';


interface Props {
}


export const SectionAdderComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <GridColumnSpan data-test={'content-section-adder'}>
      <SectionAdderProbeComponent>
        <SectionAdderSelectComponent>
          <SectionAdderCSSComponent>
            <SectionAdderViewComponent />
          </SectionAdderCSSComponent>
        </SectionAdderSelectComponent>
      </SectionAdderProbeComponent>
    </GridColumnSpan>
  );
}
  
