import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useFunctions = () => {
  const document = useDocState();

  const {
    setContentView,
  } = useEditorStatesSetters();

  const setVisible = (visible: TypesShared.PageItemVisibility) => {
    document.contentView.updateHeaderInternal({visible});
    document.saveUndo();
    setContentView();
  }

  return {
    setVisible,
  }
}


export default useFunctions;