import * as Defaults from './defaults';
import * as Types from './types';


export type State = {
  logo: Types.Logo
};


export const getLogo = (state: State) => state.logo;


/**
 * Create init state
 */

export const createInitialState = (): State => {
  const initState: State = {
    logo: Defaults.getLogo(),
  }

  return initState;
}
  
