import styled from "styled-components";
import TextBase from "lego/styles/text";
import ButtonBase from "lego/styles/button";


export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const Button = styled(ButtonBase)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${props => props.theme.defs.gap.normal};

  max-width: 100%;

  padding-left: 0px;
  overflow: clip;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: white;
  min-width: 30px;
  min-height: 30px;
`;

export const Text = styled(TextBase)`
  flex-grow: 1;
  padding: 0;
  padding-right: ${props => props.theme.defs.padding.normal};

`;
