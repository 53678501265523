import { HeaderFieldsTypes } from '../../persistent/header-fields';
import { HeaderFieldsTools } from '../../persistent/header-fields';
import ItemsSelectedBase from '../_parts/items-selected-base';


export class CustomFieldsColumnsSelected extends ItemsSelectedBase<HeaderFieldsTypes.PageColumnAddr> {
  protected getKey(columnAddr: HeaderFieldsTypes.PageColumnAddr): string {
    return HeaderFieldsTools.getPageColumnKey(columnAddr);
  }
}
