import React, { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import jtl from 'tools/jtl';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import Context from "../context";
import { StyledWrapper } from './styles';


interface Props {
  $editable: boolean;
  children: React.ReactNode;
}


export const EditorTextStyledComponent = React.forwardRef((
  props: Props, 
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    children
  } = props;

  const context = useContext(Context);
  const widgetAddr = context.widgetAddr;
  const editable = props.$editable;
  
  if (widgetAddr === null) {
    const msg = "Widget address is null";
    throw new Error(msg);
  }

  const widgetStyle_ = useRecoilValue(UIState_Content.cellImages_image_widgetStyle(widgetAddr)) || {};
  const edited       = useRecoilValue(UIState_EditorImageSession.isWidgetEdited(widgetAddr));

  // TODO FIXME
  const widgetStyle = widgetStyle_ as any;

  const marginTop    = jtl.css.valueToNumber(widgetStyle.textMarginTop);
  const marginLeft   = jtl.css.valueToNumber(widgetStyle.textMarginLeft);
  const marginBottom = jtl.css.valueToNumber(widgetStyle.textMarginBottom);
  const marginRight  = jtl.css.valueToNumber(widgetStyle.textMarginRight);

  return (
    <StyledWrapper 
      ref={ref}
      $editable={editable}
      $edited={edited}
      $marginTop={marginTop}
      $marginLeft={marginLeft}
      $marginRight={marginRight}
      $marginBottom={marginBottom}
    >
      { children }
    </StyledWrapper>
  );
});
