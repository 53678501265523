import styled from 'styled-components';
import ScrollbarInv from 'lego/styles/scrollbar-inv';


export const Scroller = styled(ScrollbarInv)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  pointer-events: all;
  background: rgba(0, 0, 0, 0.85);
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100%;
  box-sizing: border-box;

  padding: ${props => props.theme.defs.padding.xlarge};
  /* background-color: yellowgreen; */
`;

export const Content = styled.div`
  border-radius: ${props => props.theme.defs.border.radius.normal};
  background-color: #eee;

  padding: ${props => props.theme.defs.padding.xlarge};
  box-sizing: border-box;
`;
