import React from 'react';
import { useRecoilValue } from 'recoil';

import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import HLS_NameEditComponent  from 'app/ui-v2/editor-instruction/views/shared/components/name-edit';
import { UIState_ReleaselogsView } from 'app/ui/states/editor-instruction';

import InputStyledComponent from './input-styled';


interface Props {
  children: React.ReactNode;
}


export const ViewTitleEditComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;

  const document = useDocState();
  const title = useRecoilValue(UIState_ReleaselogsView.headerTitleTitle);

  const {
    setReleaselogsView
  } = useEditorStatesSetters();

  const handleEditDone = (title: string) => {
    document.releaselogsView.updateHeaderTitle({title});
    document.saveUndo();
    setReleaselogsView();
  }

  return (
    <HLS_NameEditComponent 
      name={title}
      Input={InputStyledComponent}
      onEditDone={handleEditDone}
    >
      { children }
    </HLS_NameEditComponent>
  );
}
  
