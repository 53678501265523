import styled from "styled-components";
import zIndex from "../../../../../../z-index";

export const Positioner = styled.div`
  position: relative;
  z-index: ${zIndex.inCell};
`;


export const Detacher = styled.div`
  position: absolute;
  right:  0px;
  bottom: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
