import React from 'react';
import jtl from 'tools/jtl';

import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { MainWrapper }  from './styles';
import { PriceWrapper } from './styles';
import { Price }        from './styles';
import { PriceInfoWrapper } from './styles';
import { PriceInfoMonthly } from './styles';
import { PriceBillingPeriodInfo }  from './styles';
import { PriceYearlyTotal } from './styles';


interface Props {
  free: boolean;
  priceMonthly: number;
  priceYearly: number;
  billingPeriod: string;
}


export const PriceBaseComponent: React.FC<Props> = (props: Props) => {
  const {
    free,
    priceMonthly,
    priceYearly,
    billingPeriod,
  } = props;
  
  const t = useTranslations();


  const priceMonthlyFormattedTmp = jtl.number.moneyFormat(priceMonthly);
  const priceYearlyFormatted     = jtl.number.moneyFormat(priceYearly);
  
  const priceMonthlyFormatted = (
    ! free ? 
    priceMonthlyFormattedTmp :
    t('price free')
  );

  const priceYearlyTotal = (
    ! free ? 
    `${t('payment yearly total')} ${priceYearlyFormatted}` :
    ''
  );


  return (
    <MainWrapper>

      <PriceWrapper>
        <Price>
          { priceMonthlyFormatted }
        </Price>

        {
          free || 
          <PriceInfoWrapper>
            <PriceInfoMonthly>
              { t('payment per month') }
            </PriceInfoMonthly>
            <PriceBillingPeriodInfo>
              { billingPeriod.toUpperCase() }
            </PriceBillingPeriodInfo>
          </PriceInfoWrapper>
        }
      </PriceWrapper>

      <PriceYearlyTotal>
       { priceYearlyTotal }&nbsp;
      </PriceYearlyTotal>
    </MainWrapper>
  );
}

