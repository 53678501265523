import React from 'react';
import PanelDraggableComponent from 'lego/components/panel-draggable';

import usePanelConfig from './use-panel-config';

import { MainWrapper } from './styles';
import { Content }     from './styles';


interface Props {
}


export const ControlPrintColumnsComponent: React.FC<Props> = (props: Props) => {
  const getConfig = usePanelConfig();
  const config = getConfig();

  return (
    <MainWrapper>
      <Content>
        <PanelDraggableComponent 
          config={config}
        />
      </Content>
    </MainWrapper>
  );
}
