import styled from "styled-components";
import { Box as BoxBase } from "app/ui-v2/hls/progress-box/styles";


export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${props => props.theme.panel.background};
`;

export const Box = styled(BoxBase)`
  width: 100%;
`;