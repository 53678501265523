import React from 'react';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import usePricePlan from '../../hooks/use-price-plan';
import { Title } from './styles';


interface Props {
}


export const PricePlanHeaderComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const pricePlan = usePricePlan();
  const plan = pricePlan.plan;
  
  return (
    <Title $color={plan.color}>
    { t(plan.title) }
    </Title>
  );
}
