
import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';

import { useDocState }     from "app/ui/contexts/document";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import { Icon }            from "app/ui/icons/icons";
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useMarkerEdit       from 'app/ui-v2/editor-markers/hooks/use-marker-edit';

import useEditorStatesSetters from "../../../hooks/use-editor-states-setters";


const useDocMarkersMenu = () => {
  const document = useDocState();
  const t = useTranslations();

  const {
    hideMenu
  } = useContextMenu();

  const {
    setRepoMarkers,
  } = useEditorStatesSetters();

  const editMarker = useMarkerEdit();

  const createMarker = () => {
    const markerAddr = document.repoMarkers.addMarker();
    document.saveUndo();
    setRepoMarkers();

    editMarker(markerAddr);
    hideMenu();
  }

  const getConfig = () => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, new'),
                iconComponent: Icon.Create,
                onClick: (event: React.MouseEvent) => createMarker(),
              }
            },          
          ],
        },        
      ]
    }
  
    return config;
  }

  
  const hook = () => {
    const config = getConfig();
    return config;
  }

  return hook;
}


export default useDocMarkersMenu;