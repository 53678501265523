import React from 'react';
import TooltipComponent from 'lego/components/tooltip';
import { TooltipTypes } from 'lego/components/tooltip';

import { ButtonToolbar } from './styles';


export interface Props {
  Button?: React.ElementType;
  Icon: React.ElementType;

  tooltipText?: string;
  tooltipPlacement?: TooltipTypes.Placement;

  disabled?: boolean;
  selected?: boolean;

  onClick?: (event?: React.MouseEvent) => void;
  onPointerUp?: (event?: React.PointerEvent) => void;
  onPointerDown?: (event?: React.PointerEvent) => void;

  dataTest?: string;
}


export const ToolbarButtonComponent: React.FC<Props> = (props: Props) => {
  const { 
    Icon, 

    tooltipText, 

    disabled,
    selected,
    
    onClick,
    onPointerUp,
    onPointerDown,

    dataTest,
  } = props;

  const Button = props.Button || ButtonToolbar;
  const tooltipPlacement = props.tooltipPlacement || 'top';

  return (
    <TooltipComponent
      title={tooltipText}
      disabled={disabled}
      placement={tooltipPlacement}
    >
      <Button 
        data-test={dataTest}
        $selected={selected}
        disabled={disabled}

        onClick={onClick}
        onPointerUp={onPointerUp}
        onPointerDown={onPointerDown}
      >
        <Icon />
      </Button>
    </TooltipComponent>
  );
}
