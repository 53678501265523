import React from 'react';
import useCellContext from '../../../../hooks/use-cell-context';
import { MainWrapper } from './styles';


interface Props {
  children: React.ReactNode;

}


export const CellBorderComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;

  const cellContext = useCellContext();

  return (
    <MainWrapper isRowLast={cellContext.isRowLast}>
      { children }
    </MainWrapper>
  );
}
