import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import WidgetsViewScaledComponent from 'app/ui-v2/editor-image/hls/widgets-view-scaled';
import { useDocState }            from 'app/ui/contexts/document';

import { MainWrapper } from './styles';


interface Props {
  imageAddr: Types.ImageAddr;
}


export const ImageDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr, 
  } = props;

  const document = useDocState();
  const imageProps = document.content.cellImages_getImageProps(imageAddr);

  return (
    <MainWrapper>
      <WidgetsViewScaledComponent
        imageAddr={imageAddr} 
        viewScale={imageProps.viewScale}
        viewArea={imageProps.viewArea}
      />
    </MainWrapper>
  );
}
