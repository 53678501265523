import { RefObject, useContext } from "react";
import Context from "../components/document-states/document-context-scale-ref/context";


const useDocumentScaleRef = (): RefObject<number> => {
  const scaleRef = useContext(Context);

  if (scaleRef.current === null) {
    const msg = 'Scale ref not ready';
    throw new Error(msg);
  }

  return scaleRef as RefObject<number>;
}


export default useDocumentScaleRef;