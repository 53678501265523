import React from 'react';

import MarkersComponent            from './markers';
import MarkersDropComponent        from './markers-drop';
import MarkersContextMenuComponent from './markers-context-menu';

import { MainWrapper } from './styles';


interface Props {
}


export const DocMarkersViewComponent: React.FC<Props> = (props: Props) => {
  const dataTest = 'editor__view-content__header-markers';
  
  return (
    <MainWrapper data-test={dataTest}>
      <MarkersContextMenuComponent>
        <MarkersDropComponent>
          <MarkersComponent />
        </MarkersDropComponent>
       </MarkersContextMenuComponent>
    </MainWrapper>
  );
}
  
