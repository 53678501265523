import * as Types from './types';
import { Instruction } from "app/arch/backend/types";


export const sortInstructions = (
  instructions: Instruction[], 
  sortBy: Types.SortBy, 
  sortOrder: Types.SortOrder = Types.SortOrder.ASC
): Instruction[] => {
  return instructions.toSorted((a, b) => {
    let compareValue: number;

    if (sortBy === Types.SortBy.TITLE) {
      compareValue = a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    } else if (sortBy === Types.SortBy.DATE_UPDATED) {
      compareValue = new Date(a.dateUpdated).getTime() - new Date(b.dateUpdated).getTime();
    } else {
      compareValue = 0;
    }

    // If descending order, invert the compare value
    if (sortOrder === Types.SortOrder.DESC) {
      compareValue *= -1;
    }

    return compareValue;
  });
}