import { Product } from "../types";


type PayFn = (props: Product) => void;

let __pay: PayFn | null = null;

export const setup = (payFn: PayFn) => {
  __pay = payFn;
}


export const useStripeSubscription = () => {
  const pay = (props: Product) => {
    if ( __pay === null ) {
      const msg = `Stripe payment not ready, is StripeSubscriptionModalComponent in DOM?`;
      throw new Error(msg);
    }

    __pay(props);
  }

  return pay;
}