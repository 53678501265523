import React from 'react';
import { IconContext } from 'react-icons';

import { useApp } from 'app/ui/hooks/app/use-app';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useUserGuestLogin from 'app/ui-v2/app/hooks/use-user-guest-login';
import Icon from 'app/ui/icons/icons';

import { Panel } from './styles';
import { IconWrap } from './styles';
import { Button } from './styles';


interface Props {
}


export const GuestButtonComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const app = useApp();
  const loginGuest = useUserGuestLogin();
  const IconDemo = Icon.Demo;

  const handleRunAsGuest = () => {
    app.reset();

    loginGuest(() => {
      window.location.href = '/';
    });
  }

  return (
    <Panel>
      <Button onClick={handleRunAsGuest}>
        <IconWrap>
          <IconContext.Provider value={{ size: '22px' }} >
            <IconDemo />
          </IconContext.Provider>   
        </IconWrap>
        { t('run app as guest') }
      </Button>
    </Panel>
  );
}

