import styled from "styled-components";


export const MainWrapper = styled.div`
  background-color: transparent;
  opacity: 1;
  
  position: absolute;
  top: -9999999px;
  left: -9999999px;
  
  display: flex;
  flex: row;
  flex-wrap: wrap;
  overflow: clip;
  max-height: 100%;
  width: 100%;
`;

export const StyledImage = styled.img`
  width: 10px;
  height: 10px;
`;
