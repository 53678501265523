import { ContentTools } from "app/arch/editor-instruction/document/states/persistent/content";
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";

import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useEditor } from "app/ui-v2/editor-instruction/hooks/use-editor";


const useCellTextSelectNext = () => {
  const docState = useDocState();

  const {
    setContent,
    setContentSession,
  } = useEditorStatesSetters();

  const editor = useEditor();
  const lexicalRegistry = editor.lexicalRegistrContent;

  const selectNext = (cellAddr: ContentTypes.CellAddr) => {
    const nextCellRet = docState.cell_selectNextCell(cellAddr);
    if ( ! nextCellRet ) {
      return;
    }

    const [newCellAddr, rowAdded] = nextCellRet;
    if (rowAdded) {
      setContent();
      docState.saveUndo();
    }

    // Check if lexical is already present in registery.
    // When user press and holds down tab,
    // render of CellMenuText crashes as it
    // can't find lexical instance in registry.
    const hasLexical = lexicalRegistry.hasLexical(newCellAddr);
    if ( ! hasLexical ) {
      console.log("Is tab down?");
      return;
    }

    setContentSession();

    const cellId = ContentTools.getCellHTMLId(cellAddr);
    const contentCell = document.getElementById(cellId);

    if (contentCell) {
        const editorInput = contentCell.querySelector<HTMLDivElement>('.editor-input');
        if (editorInput && editorInput.contentEditable === "true") {
          editorInput.focus();

          // Create a range to select all text inside the contentEditable div
          const range = document.createRange();
          range.selectNodeContents(editorInput);

          // Clear any existing selections and apply the new range
          const selection = window.getSelection();
          if (selection) {
            selection.removeAllRanges(); // Clear any previous selection
            selection.addRange(range);   // Select the new range
          }
        } else {
          console.warn("Editor input is not contentEditable or not found.");
        }
    } else {
      console.warn(`Content cell not found, cell id: ${cellId}`);
    }
  }

  return selectNext;
}

export default useCellTextSelectNext;