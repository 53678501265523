export enum CornerType {
  LEFT_TOP     = 'left-top',
  LEFT_BOTTOM  = 'left-bottom',
  RIGHT_TOP    = 'right-top',
  RIGHT_BOTTOM = 'right-bottom'
}


export interface CornerDotUpdate {
  x: number;
  y: number;
  corner: CornerType;
}
