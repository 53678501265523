import React from 'react';
import { useRef }    from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import { CaptureSourceType }          from './types';
import { HookBaseProps }              from './types';
import { OnFilesSelectedType }        from './types';
import { OnFilesSelectionCancelType } from './types';

import { Input } from './styles';


export interface SelectFilesFunctionProps extends HookBaseProps {
  captureSrc?: CaptureSourceType;
  acceptFiles?: string,
  multipleFiles?: boolean,
}


export let SelectFiles: (null | ((props: SelectFilesFunctionProps) => void)) = null;


interface Props {
}


export const UserFilesSelectorComponent: React.FC<Props> = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [key, setKey] = useState(0); 

  const [multipleFiles, setMultipleFiles] = useState(false);
  const [acceptFiles,   setAcceptFiles  ] = useState("");
  const [captureSource, setCaputreSource] = useState<CaptureSourceType>({});

  const onFilesSelectedRef        = useRef<OnFilesSelectedType | null | undefined>(null);
  const onFilesSelectionCancelRef = useRef<OnFilesSelectionCancelType | null | undefined>(null);

  const selectFile = (props: SelectFilesFunctionProps) => {
    onFilesSelectedRef.current = props.onFilesSelected;
    onFilesSelectionCancelRef.current = props.onFilesSelectionCancel;

    setMultipleFiles(props.multipleFiles ? true : false);
    setAcceptFiles(props.acceptFiles ? props.acceptFiles : "*/*");
    setCaputreSource(props.captureSource ? props.captureSource : {});
    // Needs to be performed on next cycle as otherwise
    // it will not update above states
    setTimeout(() => {
      inputRef.current?.click();
    });
  }

  // TODO
  // Don't I need run this hook on every rerender?
  useEffect(() => {
    SelectFiles = selectFile;
  }, []);

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target == null ) {
      onFilesSelectionCancelRef.current?.();
      return;
    }

    const files = event.target.files;
    if (files === null) {
      onFilesSelectionCancelRef.current?.();
      return;
    }

    if (files.length === 0) {
      onFilesSelectionCancelRef.current?.();
      return;
    }

    if (multipleFiles === false && files.length > 1) {
      console.warn(`Multiple files selected, that should not be possible`);
      return;
    }

    const filesArray: File[] = Array.from(files);
    onFilesSelectedRef.current?.(filesArray);

    // If we select the same files twice, one after another:
    // Select file, upload, then again select file, upload
    // without rerendering component second select will not 
    // call handleFileSelected handler
    setKey(key + 1);
  }

  return (
    <Input
      key={`user-files-selector-${key}`}
      ref={inputRef}
      type={"file"}
      accept={acceptFiles}
      multiple={multipleFiles}
      onChange={handleFileSelected}
      {...captureSource}
    />
  );
}
  
