import styled from "styled-components";


export const MainWrapper = styled.div`
  position: relative;
`;


export const Detacher = styled.div`
  left: 100%;
  top: -4px;

  position: absolute;
`;
