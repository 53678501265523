import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_HeaderRows } from 'app/ui/states/editor-instruction';
import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';
import { UIState_CustomRowsRowsSelected } from 'app/ui/states/editor-instruction';
import usePageContext from 'app/ui-v2/editor-instruction/views/view-content/hooks/page/use-page-context';
import { HeaderCellProps } from '../types';

import { HeaderCellView } from './styles';


interface Props extends HeaderCellProps {
}


export const HeaderCellCSSComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageRowAddr,
    children,
  } = props;
    
  const pageContext = usePageContext();
  
  const pageIdx = pageContext.pageIdx;
  
  if (pageIdx === null) {
    const msg = `PageIdx is null`;
    throw new Error(msg);
  }

  const tableHeaderCSS = useRecoilValue(UIState_HeaderRows.rowHeaderCSS(pageRowAddr));
  const commonTableHeaderCSS = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);
  const selected = useRecoilValue(UIState_CustomRowsRowsSelected.isSelected(pageRowAddr));
  const isDragOver = useRecoilValue(UIState_CustomRowsRowsSelected.isDragOver(pageRowAddr));

  const color = commonTableHeaderCSS.color as string;
  const background = commonTableHeaderCSS.background as string;
  
  return (
    <HeaderCellView
      $selected={selected}
      $color={color} 
      $background={background}
      $isDragOver={isDragOver}

      style={{
        ...tableHeaderCSS
      }}
    >
      { children }
    </HeaderCellView>
  );
}