import { useSetRecoilState } from 'recoil';
import { ReleaselogsTools as Tools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { UIState_ReleaselogsSignals } from 'app/ui/states/editor-instruction';
import useColumnWidthSignalBase         from 'app/ui-v2/editor-instruction/views/shared/hooks/use-columns-width-signal';

const useReleaselogColumnsWidthsSignal = (releaselogAddr: Types.ReleaselogAddr) => {
  const releaselogKey = Tools.getReleaselogKey(releaselogAddr);
  const signalColumnsWidths = useSetRecoilState(UIState_ReleaselogsSignals.releaselogColumnsWidths(releaselogKey));
  const signal = () => signalColumnsWidths({});
  const hook = useColumnWidthSignalBase({ signal });

  return hook;
}

export default useReleaselogColumnsWidthsSignal;