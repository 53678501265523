import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";
import { useTranslations } from "app/ui/hooks/app/use-translation";


const useFormatVer1_2 = () => {
  const docState = useDocState();
  const t = useTranslations();

  const {
    setMetaData,
    setEditorImageWidgetsStyles,
  } = useEditorStatesSetters();

  const __fixWidgetsStyles = () => {
    const widgetsStyles = docState.widgetsStyles;
    widgetsStyles.reset();
    widgetsStyles.populateSystemStyle();
    setEditorImageWidgetsStyles();
  }

  const __fixContentVersion = () => {
    const metaData = docState.metaData;
    metaData.setFormatVersion(1.2);
    setMetaData();
  }

  const hook = () => {
    console.log("Formatting content to ver 1.2");
    __fixWidgetsStyles();
    __fixContentVersion();
  }

  return hook;
}


export default useFormatVer1_2;