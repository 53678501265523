import styled from "styled-components";
import TextWrap from "./text-wrap";


export const Text = styled(TextWrap)`
  background: none;
  
  color:         ${props => props.theme.defs.colors.color.primary};
  border-color:  ${props => props.theme.defs.colors.border.secondary};
  border-radius: ${props => props.theme.defs.border.radius.normal};
  border-style:  ${props => props.theme.defs.border.style};
  border-width:  ${props => props.theme.defs.border.width.normal};
  
  font-size:     ${props => props.theme.defs.font.size.large};
  padding:       ${props => props.theme.defs.padding.normal};

  border-color:  ${props => props.theme.input.border.color};
  opacity:       ${props => props.theme.defs.opacity.text};
`;


export default Text;