import React from 'react';

import { MainWrapper }  from './styles';
import { NameWrapper }  from './styles';
import { Name }         from './styles';
import { ValueWrapper } from './styles';
import { Value }        from './styles';


interface Props {
  name: string;
  value: string | number;
}


export const ValueInfoComponent: React.FC<Props> = (props: Props) => {
  const { 
    value, 
    name 
  } = props;

  return (
    <MainWrapper>
      <NameWrapper>
        
        <Name>
          { name }  
        </Name>
      </NameWrapper>

      <ValueWrapper>
        <Value>
          { value }
        </Value>
      </ValueWrapper>
    </MainWrapper>
  );
}
  
