import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import { PrintoutViewTypes } from 'app/arch/editor-instruction/document/states/printout/printout-view';

import { GridItem }  from './styles';
import { Label }     from './styles';
import { PropLabel } from './styles';
import { PropValue } from './styles';
import { Separator } from './styles';
import { Grid }      from './styles';
import { Window }    from './styles';


interface Props {
  viewsLoaded: PrintoutViewTypes.ViewsLoaded;
  imagesLoaded: boolean;
}


export const DebugInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    viewsLoaded,
    imagesLoaded,
  } = props;

  const values = useRef<any[]>([]);

  useEffect(() => {
    values.current.push({
      viewContent:   viewsLoaded[PrintoutViewTypes.ViewType.CONTENT],
      viewChangelog: viewsLoaded[PrintoutViewTypes.ViewType.CHANGELOG],
      imagesLoaded,
    });
  }, [viewsLoaded, imagesLoaded]);

  const booleanToStr = (value: boolean) => (
    value ? "true" : "false"
  );

  const renderDebug = () => {
    const Comps = values.current.map((entry, idx) => (
      <React.Fragment key={idx}>
        <GridItem>
          <PropLabel>Content:</PropLabel>
          <PropValue>{ booleanToStr(entry.viewContent) }</PropValue>
        </GridItem>
  
        <GridItem>
          <PropLabel>Releaselog:</PropLabel>
          <PropValue>{ booleanToStr(entry.viewChangelog) }</PropValue>
        </GridItem>

        <GridItem>
          <PropLabel>Imgs loaded:</PropLabel>
          <PropValue>{ booleanToStr(entry.imagesLoaded) }</PropValue>
        </GridItem>
      </React.Fragment>
    ));

    return (
      <Grid>
        { Comps }
      </Grid>
    );
  }


  return (
    <Window>
      
      <Label>
        Prev values
      </Label>
      { renderDebug() }

      <Separator />

      <Label>
        Current values
      </Label>

      <Grid>
        <GridItem>
          <PropLabel>Content:</PropLabel>
          <PropValue>{ booleanToStr(viewsLoaded[PrintoutViewTypes.ViewType.CONTENT]) }</PropValue>
        </GridItem>
  
        <GridItem>
          <PropLabel>Releaselog:</PropLabel>
          <PropValue>{ booleanToStr(viewsLoaded[PrintoutViewTypes.ViewType.CHANGELOG]) }</PropValue>
        </GridItem>

        <GridItem>
          <PropLabel>Imgs loaded:</PropLabel>
          <PropValue>{ booleanToStr(imagesLoaded) }</PropValue>
        </GridItem>
      </Grid>

    </Window>
  );
}
  
