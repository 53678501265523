import { useEffect } from "react";
import { PrintoutViewTypes } from "app/arch/editor-instruction/document/states/printout/printout-view";
import usePrintoutViewLoaded from "./use-printout-view-loaded";
import usePrintoutViewState from "./use-printout-view-state";


const usePrintoutViewLocalStorageSet = () => {
  const {
    allLoaded,
  } = usePrintoutViewLoaded();

  const printoutViewState = usePrintoutViewState();
  const STORAGE_KEY = 'printout-pages-count';

  useEffect(() => {
    if ( ! allLoaded ) {
      return;
    }

    if ( localStorage.getItem(STORAGE_KEY) !== null ) {
      return;
    }

    const viewPagesCount = printoutViewState.getPages().count;
    const pagesCount = (
        viewPagesCount[PrintoutViewTypes.ViewType.CONTENT] 
      + viewPagesCount[PrintoutViewTypes.ViewType.CHANGELOG] 
    );

    localStorage.setItem(STORAGE_KEY, `${pagesCount}`);
  }, [allLoaded]);
}

export default usePrintoutViewLocalStorageSet;