import { useContext } from "react";
import EditorInstructionContext from "../editor-instruction";


const useDocument = () => {
  const editorContext = useContext(EditorInstructionContext) !;
  const document = editorContext.editorInstruction.document;

  return document;
}

export default useDocument;
