import { useDocState }        from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useSelectedReset = () => {
  const document = useDocState();

  const {
    setEditorImageSession
  } = useEditorStatesSetters();

  const hook = (props?: {skipStateUpdate?: boolean}) => {
    const skipStateUpdate = props?.skipStateUpdate ?? false;

    document.editorImageSession.setWidgetSelected(null);
    document.editorImageSession.setWidgetPartSelected(null);

    document.editorImageSession.setMultiSelectionSelectedWidgets(null);
    document.editorImageSession.setMultiSelectionImageAddr(null);

    document.editorImageSession.setWidgetEdited(null);

    if (skipStateUpdate) {
      return;
    }

    setEditorImageSession();
  }

  return hook;
}


export default useSelectedReset;
