export class Table {
  private _headerRowHeight: number;
  private _sectionAdderHeight: number;

  constructor() {
    this._headerRowHeight = 0;
    this._sectionAdderHeight = 0;
  }

  getHeaderRowHeight() { 
    return this._headerRowHeight; 
  }

  setHeaderRowHeight(height: number) { 
    this._headerRowHeight = height; 
  }

  getSectionAdderHeight() { 
    return this._sectionAdderHeight; 
  }

  setSectionAdderHeight(height: number) { 
    this._sectionAdderHeight = height; 
  }
}
