import React from 'react';

import FlexEditLogoComponent from 'app/ui-v2/hls/branding/flex-edit-logo';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { MainWrapper } from './styles';
import { Description } from './styles';
import { DescriptionWrapper } from './styles';


interface Props {
}


export const TitleAndLogoComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const t = useTranslations();
  const description = t("flexedit, description").toUpperCase();

  return (
    <MainWrapper>
      <FlexEditLogoComponent />

      <DescriptionWrapper>
        <Description>
          { description }
        </Description>
      </DescriptionWrapper>
    </MainWrapper>
  );
}

