import useRelogin from "./use-relogin";
import useReloginStateSetters from "./use-relogin-state-setters";


const useReloginVisibleSet = () => {
  const relogin = useRelogin();
  const {
    setRelogin
  } = useReloginStateSetters();

  const setVisible = (visible: boolean) => {
    relogin.setVisible(visible);
    setRelogin();
  }

  return setVisible;
}


export default useReloginVisibleSet;