import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"

import { RepoMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/repo-markers";
import { MarkerAddr }       from "app/arch/editor-instruction/document/states/persistent/repo-markers/types";
import { useTranslations }  from "app/ui/hooks/app/use-translation";

import useFunctions   from "../common/use-functions";
import useItemsCommon from "../common/use-items";
import useStates      from "../common/use-states";

import SignIconInfoComponent       from "./components/sign-icon-info";
import SignIconWarningComponent    from "./components/sign-icon-warning";
import SignIconProhibitedComponent from "./components/sign-icon-prohibited";
import SignIconMandatoryComponent  from "./components/sign-icon-mandatory";


const useItems = (markerAddr: MarkerAddr) => {
  const t = useTranslations();
  const fns = useFunctions(markerAddr);
  const itemsCommon = useItemsCommon(markerAddr);
  const states = useStates(markerAddr);

  const getSignType = () => ({
    // title: t('marker sign, type'),
    type: PanelControlsItemSectionItemType.BUTTON_GROUP,
    buttons: [
      {
        Icon: SignIconInfoComponent,
        tooltip: t('marker sign type, info'),
        value: RepoMarkersTypes.MarkerType.SIGN_INFO,
        selected: states.markerProps.type === RepoMarkersTypes.MarkerType.SIGN_INFO,
      },        
      {
        Icon: SignIconMandatoryComponent,
        tooltip: t('marker sign type, mandatory'),
        value: RepoMarkersTypes.MarkerType.SIGN_MANDATORY,
        selected: states.markerProps.type === RepoMarkersTypes.MarkerType.SIGN_MANDATORY,
      },
      {
        Icon: SignIconProhibitedComponent,
        tooltip: t('marker sign type, prohibition'),
        value: RepoMarkersTypes.MarkerType.SIGN_PROHIBITION,
        selected: states.markerProps.type === RepoMarkersTypes.MarkerType.SIGN_PROHIBITION,
      },
      {
        Icon: SignIconWarningComponent,
        tooltip: t('marker sign type, warning'),
        value: RepoMarkersTypes.MarkerType.SIGN_WARNING,
        selected: states.markerProps.type === RepoMarkersTypes.MarkerType.SIGN_WARNING,
      }, 
    ],
    onClick: fns.updateMarkerType
  });


  return {
    ...itemsCommon,
    getSignType
  }
}

export default useItems;
