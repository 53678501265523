import { useRecoilValue } from 'recoil';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { UIState_Content } from 'app/ui/states/editor-instruction';


const useColumnCSS = (columnAddr: Types.ColumnAddr) => {
  const css = useRecoilValue(UIState_Content.columnCSS(columnAddr));
  return css || {} ;
}


export default useColumnCSS;