import styled from "styled-components";


interface ControlProps {
  $left?: boolean;
  $right?: boolean;
}

export const MainWrapper = styled.div<ControlProps>`
  display: flex;

  ${props => props.$left  ? 'flex-direction: row;' : ''};
  ${props => props.$right ? 'flex-direction: row-reverse;' : ''};

  height: 100%;
`;

export const ToolbarWrapper = styled.div`
  flex: 0 0 auto;
  /* background: rgba(255, 255, 0, 0.2); */
`;

export const PanelWrapper = styled.div`
  flex: 1 1 0;
  min-width: 0;
  /* background: rgba(0, 255, 0, 0.2); */
`;

