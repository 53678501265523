import React from 'react';

import HomePageLayoutComponent from 'app/ui-v2/home-page/hls/home-page-layout';
import ContactBarComponent     from 'app/ui-v2/home-page/hls/contact-bar';

import SideMenuComponent from './side-menu';
import TopbarComponent   from './topbar';

import { ContactBarWrapper } from './styles';
import { ContentColumn }     from './styles';
import { ContentLayout }     from './styles';
import { ContentRow }        from './styles';


interface Props {
  children: React.ReactNode;
}


export const HomePageUserLayoutComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;


  return (
    <HomePageLayoutComponent Topbar={ TopbarComponent }>
      <ContentRow>

        <ContentColumn>
          <ContentLayout>
            { children }
          </ContentLayout>

          <ContactBarWrapper>
            <ContactBarComponent />
          </ContactBarWrapper>
        </ContentColumn>

        <SideMenuComponent />

      </ContentRow> 
    </HomePageLayoutComponent>
  );
}
