  
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useWidgetPartSelect from 'app/ui-v2/editor-image/hooks/widget/use-widget-part-select';
import { MainWrapper } from "./styles";


interface Props {
  widgetAddr: ContentTypes.WidgetAddr;
  widgetPart: ContentTypes.WidgetPart;
  editDisabled: boolean;
  children: React.ReactNode;
}


export const WidgetPartSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
    widgetPart,
    editDisabled,
    children,
  } = props;

  const selectWidgetPart = useWidgetPartSelect();

  const handleSelect = (event: React.PointerEvent) => {
    // Do not stop propagating 
    // as dragger activates on onPointerDown
    // const widgetPart = DocContentTypes.WidgetPart.ARROW_TAIL;
    selectWidgetPart({
      widgetAddr,
      widgetPart,
      editDisabled,
    });
  }

  if (editDisabled) {
    return children;
  }

  return (
    <MainWrapper onPointerDown={handleSelect}>
    { children }
    </MainWrapper>
  );
}
