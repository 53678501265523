import React from 'react';
import { useRecoilValue } from 'recoil';

import { useDocState }  from 'app/ui/contexts/document';
import { UIState_HeaderFields } from 'app/ui/states/editor-instruction';
import { UIState_HeaderFieldsSignals } from 'app/ui/states/editor-instruction';

import { Grid } from './styles';


interface Props {
  children: React.ReactNode;
}


export const GridComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const document = useDocState();

  const columnsAddrs = useRecoilValue(UIState_HeaderFields.columnsAddrs);

  // Track ColumnsWidth
  useRecoilValue(UIState_HeaderFieldsSignals.columnsWidths);

  const colsWidths = columnsAddrs.map((columnAddr) => {
    const columnWidth = document.headerFields.getColumnWidth(columnAddr);
    return `${columnWidth}px`;
  });

  const columnsTemplate = colsWidths.join(' ');

  return (
    <Grid columnsTemplate={columnsTemplate} >
      { children }
    </Grid>
  );
}
