import produce from 'immer';
import * as Types from './types';
import * as State from './state';
import * as Defaults from './defaults';


export class ContentView {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Headers
   */
  updateHeaderMeta(update: Types.HeaderMetaUpdate) {
    this._state = produce(this._state, draft => {
      const header = State.getHeader(draft);
      Object.assign(header.meta, update);
    });
  }

  updateHeaderFields(update: Types.HeaderFieldsUpdate) {
    this._state = produce(this._state, draft => {
      const header = State.getHeader(draft);
      Object.assign(header.fields, update);
    });
  }

  updateHeaderInternal(update: Types.HeaderInternalUpdate) {
    this._state = produce(this._state, draft => {
      const header = State.getHeader(draft);
      Object.assign(header.internal, update);
    });
  }

  updateHeaderMarkers(update: Types.HeaderMarkersUpdate) {
    this._state = produce(this._state, draft => {
      const header = State.getHeader(draft);
      Object.assign(header.markers, update);
    });
  }


  __fixToVer1() {
    this._state = produce(this._state, draft => {
      const header = State.getHeader(draft);
      header.internal = Defaults.getHeaderInternal();
    });
  }


  /**
   * Getters
   */
  getHeader() {
    return State.getHeader(this._state);
  }
}
