import { Position } from "app/arch/types";
import { Size     } from "app/arch/types";
import { OrientedSmartLinesRaw, SmartLineRaw } from "./types";
import { SmartLine } from "./smart-line";


export class SmartLines {
  protected _lines: OrientedSmartLinesRaw;

  constructor() {
    this._lines = {
      vertical: [],
      horizontal: [],
    };
  }

  get raw() {
    return this._lines;
  }
  
  reset() {
    this._lines = {
      vertical:   [],
      horizontal: [],
    };
  }

  addLines(size: Size, position: Position) {
    if (size === undefined || position === undefined) {
      console.warn(`Problem while creating smart lines, size: ${size}, position: ${position}`);
      return;
    } 

    this.addLine({
      x0: position[0], 
      y0: position[1], 
    
      x1: position[0] + size[0], 
      y1: position[1]
    });

    this.addLine({
      x0: position[0], 
      y0: position[1] + size[1], 

      x1: position[0] + size[0], 
      y1: position[1] + size[1],
    });

    this.addLine({
      x0: position[0], 
      y0: position[1], 

      x1: position[0], 
      y1: position[1] + size[1], 
    });

    this.addLine({
      x0: position[0] + size[0], 
      y0: position[1], 

      x1: position[0] + size[0], 
      y1: position[1] + size[1], 
    });

    // centers
    this.addLine({
      x0: position[0] + size[0] / 2, 
      y0: position[1], 

      x1: position[0] + size[0] / 2, 
      y1: position[1] + size[1], 
    });

    this.addLine({
      x0: position[0], 
      y0: position[1] + size[1] / 2, 

      x1: position[0] + size[0], 
      y1: position[1] + size[1] / 2, 
    });
  }

  sort() {
    this._lines.horizontal.sort((a, b) => (a.y0 - b.y0) );
    this._lines.vertical.sort((a, b)   => (a.x0 - b.x0) );
  }

  addLine(line: SmartLineRaw) {
    if (SmartLine.IsHorizontal(line)) {
      this._lines.horizontal.push(line);
    }
    else {
      this._lines.vertical.push(line);
    }
  }
}
