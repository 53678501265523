import * as Types from './types';


export const getReleaseInfo = (): Types.ReleaseInfo => ({
  height: 0
});

export const getChangelogHeaderRow = (): Types.ChangelogHeaderRow => ({
  height: 0
});

export const getChangelogRow = (): Types.ChangelogRow => ({
  height: 0
});


export const getChangelogRows = (): Types.ChangelogRows => ({
});

export const getChangelog = (): Types.Changelog => ({
  headerRow: getChangelogHeaderRow(),
  rows:   getChangelogRows(),
});

export const getReleaselog = (): Types.Releaselog => ({
  releaselogInfo: getReleaseInfo(),
  changelog: getChangelog()
});

export const getReleaselogs = (): Types.Releaselogs => ({
});

