import React from 'react';
import { MenuItem } from './styles';

interface Props {
  sizeTxt: string;
  selected: boolean;
  scrollTo: boolean;
  onClick: (e: any) => void;
}


export const FontSizeItemComponent: React.FC<Props> = (props: Props) => {
  const { sizeTxt, selected, scrollTo, onClick } = props;
  const itemRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (itemRef.current === null) return;

    if (scrollTo) {
      itemRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block:    'nearest',
        inline:   "nearest" 
      });
    }
  }, [scrollTo]);

  return (
    <MenuItem
      key={sizeTxt}
      selected={selected}
      onClick={onClick}
      ref={itemRef}
    >
    { sizeTxt }
    </MenuItem>
);
}
  
