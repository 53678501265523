import deepEqual from 'deep-equal';
import * as Types from './types';


export const compareRecord = (
  srcRecord: Types.Record,
  dstRecord: Types.Record,
): boolean => {

  const identical = deepEqual(
    srcRecord, 
    dstRecord, 
    {strict: true}
  );

  return identical;
}
