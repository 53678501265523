import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';

import { useDocState } from 'app/ui/contexts/document';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { UIState_ContentSignals } from 'app/ui/states/editor-instruction';
import { Column }             from 'app/ui-v2/editor-instruction/views/shared/components/columns-resizers/types';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useColumnsWidthSignal  from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-columns-width-signal';
import useColumnsVisible      from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-columns-visible';
import HLS_ColumnsResizersComponent from 'app/ui-v2/editor-instruction/views/shared/components/columns-resizers';


interface Props {
  debug?: boolean;
}


export const ColumnsResizersComponent: React.FC<Props> = (props: Props) => {
  const { 
    debug
  } = props;

  const document = useDocState();

  const { 
    setContent,
  } = useEditorStatesSetters();

  // Update on column order change
  useRecoilValue(UIState_Content.columnsAddrs);

  // This send signal that columns widths have changed.
  const signalColumnsWidths = useColumnsWidthSignal();

  // This rerender is needed for remaining pages.
  useRecoilValue(UIState_ContentSignals.columnsWidths);

  // Track ColumnsVisible
  const columnsVisible = useColumnsVisible();

  const columnsResizers: Column[] = columnsVisible.map((columnAddr) => {
    const columnProps = document.content.getColumnProps(columnAddr);
    const columnResizer = {
      id: columnAddr.columnId,
      width: columnProps.width,
    }

    return columnResizer;
  });
  
  const handleSetColumnsWidth = (columns: Column[]) => {
    columns.forEach((column) => {
      const columnId = column.id;
      const columnAddr: TypesContent.ColumnAddr = { columnId };
      const width = column.width;
      
      document.content.updateColumn(columnAddr, { width });
    });

    setContent();
    signalColumnsWidths();
  }

  const handleResizeEnd = () => {
    document.saveUndo();
  }


  return (
    <HLS_ColumnsResizersComponent
      columns={columnsResizers}

      onSetColumnsWidth={handleSetColumnsWidth}
      onResizeEnd={handleResizeEnd}

      debug={debug}
      dataTest={'editor__view-content__content'}
    />
  );
}
 