import React from 'react';
import TooltipComponent from '../../tooltip';


interface Props {
  Button: any;
  tooltip?: string;
}


export const ButtonComponent: React.FC<Props> = (props: Props) => {
  const { Button, tooltip } = props;

  return (
    <>
    {
      tooltip &&
      <TooltipComponent
        title={tooltip}
      >
        { Button }
      </TooltipComponent>
    }

    { ! tooltip && Button }
    </>
  );
}
