import { UUID } from "app/arch/types";


/**
 * Column
 */
export enum ColumnType {
  TEXT  = "text",
}

export type ColumnAddr = {
  columnId: UUID;
}

export type ColumnProps = {
  type: ColumnType;
  name: string;
  width: number;
  css: React.CSSProperties;
  header: {
    css: React.CSSProperties;
  }
}

export interface ColumnTextProps extends ColumnProps {
}

export type ColumnPropsTypes = (
  ColumnTextProps
);

export type ColumnPropsUpdate = (
  Partial< 
    Omit< 
      ColumnProps, "type" | "header"
    > 
  >);


/**
 * Columns
 */
export type ColumnsAddrs = ColumnAddr[];

export type ColumnsProps = {
  [columnKey: string]: ColumnProps
}
export type Columns = {
  addrs: ColumnsAddrs,
  props: ColumnsProps,
}


/**
 * PageColumn
 */
export type PageColumnAddr = ColumnAddr & {
  pageIdx: number;
}


/**
 * Cell
 */
export interface Cell {
}

//
// Text Cell
//
export interface TextCell extends Cell {
  editorState: string | null;
}
//
// Text Cell (end)
//

export type CellTypes = (
  TextCell 
)

export type CellAddr = {
  columnId: UUID;
}

/**
 * Cells
 */
export type Cells = {
  [cellKey: string]: CellTypes
}


/**
 * PageCell
 */
export type PageCellAddr = CellAddr & {
  pageIdx: number;
}


/**
 * Fields
 */
export type Fields = {
  columns: Columns;
  cells:   Cells;
}


/**
 * Table 
 */
export type Table = {
  header: {
    css: React.CSSProperties;
  }
}
