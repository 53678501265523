import { useEffect } from 'react';
import { useState }  from 'react';
import Logger from 'libs/debug';

import DPI from '..';
import useProbeUnit from './use-probe-unit';


const useDPIWatch = () => {
  const log = Logger.getProbes();
  const [rerender, setRerender] = useState(0);

  useProbeUnit({
    units: 'in',
    onRatio: (ratio: number) => {
      log.log(`Inches to pixels ratio set: ${ratio}`);
      DPI.ratio_in2px = ratio;
    }
  }, [rerender]);

  useProbeUnit({
    units: 'mm',
    onRatio: (ratio: number) => {
      log.log(`Mm to pixels ratio set: ${ratio}`);
      DPI.ratio_mm2px = ratio;
    }
  }, [rerender]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    DPI.dpr = window.devicePixelRatio;
    // Just to rerender.
    setRerender(DPI.dpr);
  };
};

export default useDPIWatch;