import styled from 'styled-components';


export const InputRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  /* background-color: blue; */
`;

export const InputWrapper = styled.div`
  flex: 1 1 auto;
`;

export const IconAnchor = styled.div`
  right: 0px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  right: 8px;

  position: absolute;
  flex: 0 0 32px;
  /* background-color: yellow; */

  display: flex;
  flex-direction: row;
  gap: 0;
  justify-content: center;
  align-items: center;

  color: ${props => props.theme.defs.colors.color.primary};
  opacity: ${props => props.theme.defs.opacity.fade};
`;