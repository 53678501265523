import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './__use-items';
import useStates from './__use-states';


const useConfigSection = () => {
  const t = useTranslations();
  const items = useItems();

  const {
    visibleNone,
  } = useStates();

  const section = {
    subsections: [
      {
        title: t('page header, custom fields'),
        items: [
          items.getVisible(),
        ]
      },
      {
        skip: visibleNone,
        items: [
          items.getPadding(),
          items.getAlignHorizontal(),
        ]
      },
      {
        skip: visibleNone,
        items: [
          items.getFontSize(),
          items.getFontWeight(),
          items.getFontVariant(),
        ]
      }              
    ],
  }
  
  return section
}


export default useConfigSection;