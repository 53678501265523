import styled from "styled-components";


export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;

  color: ${props => props.theme.defs.colors.color.third};
  background-color: ${props => props.theme.defs.colors.background.seventh};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loading = styled.div`
  /* background-color: blue; */

  text-align: center;
  font-size: ${props => props.theme.defs.font.size.xxxxlarge};
  padding: ${props => props.theme.defs.padding.normal};
`;