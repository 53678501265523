import { useContext } from 'react';
import SidePanelContext from './context';


export const useSidePanelContext = () => {
  const context = useContext(SidePanelContext);
  if (context === null) {
    throw new Error('useSidePanelContext must be used within a SidePanelContext');
  }

  return context;
}