import React         from 'react';
import { useEffect } from 'react';
import { useRef }    from 'react';
import { useState }  from 'react';
import { useDebouncedCallback } from 'use-debounce';

import DPI from 'libs/dpi';

import DraggerComponent  from 'lego-v2/dragger/ui';
import { DraggerUpdate } from 'lego-v2/dragger/arch';

import { settings }            from 'app/configs';
import { PageFormatType }      from 'app/arch/types/types';
import { PageMarginType }      from 'app/arch/types/types';
import { Position }            from 'app/arch/types/types';
import { PageOrientationType } from 'app/arch/types/types';
import { Page }                from 'app/arch/print/page';
import { useDocState }         from 'app/ui/contexts/document';
import useEditorStatesSetters  from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import { useIsMobile } from 'lego-hooks/use-is-mobile';
import useContentColumnsAutoAdjust      from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-columns-auto-adjust';
import useContentCustomFiledsAutoAdjust from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/columns/use-columns-auto-adjust';
import useReleaselogsColumnsAutoAdjust  from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/columns/use-releaselogs-columns-auto-adjust';
import useDocumentScaleRef from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-ref';

import { MainWrapper }   from './styles';
import { StyledMargin }  from './styles';
import { Dragger }       from './styles';
import { MarginWrapper } from './styles';


const MARGIN_UPDATE_DELAY = settings.rateControl.resizing.interval;


const MARGIN_MIN = 0;
const MARGINS_MIN_GAP = 20;
const MARGIN_VALUE_DECIMAL_PLACES = 1;


export const enum MarginType {
  TOP    = 'top',
  RIGHT  = 'right',
  BOTTOM = 'bottom',
  LEFT   = 'left',
};

const Multiplier = {
  [MarginType.TOP]:    [0, 1],
  [MarginType.RIGHT]:  [-1, 0],
  [MarginType.BOTTOM]: [0, -1],
  [MarginType.LEFT]:   [1, 0],
};

const MARGIN_WIDTH = 1;


interface Props {
  marginType: MarginType;
  pageFormat: PageFormatType;
  pageMargins: PageMarginType;
  pageOrientation: PageOrientationType;
}


export const PageMarginComponent: React.FC<Props> = (props: Props) => {
  const document = useDocState();
  const { 
    pageMargins, 
    marginType 
  } = props;

  const scaleRef = useDocumentScaleRef();
  const scale = scaleRef.current ! ;
  const isMobile = useIsMobile();
  
  const DRAGGER_WIDTH = (
    isMobile ?
    settings.resizerLine.width.mobile :
    settings.resizerLine.width.desktop
  );


  const docReleaselogsColumnsAutoAdjust  = useReleaselogsColumnsAutoAdjust()
  const docContent2ColumnsAutoAdjust     = useContentColumnsAutoAdjust();
  const docContentCustomFieldsAutoAdjust = useContentCustomFiledsAutoAdjust();
  
  const autoAdjustColumns = () => {
    docReleaselogsColumnsAutoAdjust();
    docContent2ColumnsAutoAdjust();
    docContentCustomFieldsAutoAdjust();
  }

  const multiplier = Multiplier[marginType];

  const getMargin = () => {
    switch(marginType) {
      case MarginType.TOP    : return pageMargins.top;
      case MarginType.RIGHT  : return pageMargins.right;
      case MarginType.BOTTOM : return pageMargins.bottom;
      case MarginType.LEFT   : return pageMargins.left;
      default: {
        throw new Error(`Unknown margin type: ${marginType}`);
      }
    }
  }

  const margin = getMargin();
  const draggerWidth = DRAGGER_WIDTH;
  const marginWidth  = MARGIN_WIDTH;

  const isHorizontal = ( marginType === MarginType.TOP || marginType === MarginType.BOTTOM);

  const position_px = DPI.any2px(margin, pageMargins.units);
  const positionInit = (
    isHorizontal ?
    [0, position_px] :
    [position_px, 0]
  ) as Position;

  const [position, setPosition] = useState<Position>(positionInit);
 
  const {
    setViewsCommon
  } = useEditorStatesSetters();

  const startPosition = useRef<Position | null>(null);

  useEffect(() => {
    const position_px = DPI.any2px(margin, pageMargins.units);
    const positionAdjusted = (
      isHorizontal ?
      [0, position_px] :
      [position_px, 0]
    ) as Position;

    setPosition(positionAdjusted);
  }, [pageMargins]);

  const pageSize = Page.getSizePx(
    props.pageFormat, 
    props.pageOrientation,
  );

  const getOppositeMargin = () => {
    switch(marginType) {
      case MarginType.TOP    : return pageMargins.bottom;
      case MarginType.RIGHT  : return pageMargins.left;
      case MarginType.BOTTOM : return pageMargins.top;
      case MarginType.LEFT   : return pageMargins.right;
      default: {
        throw new Error(`Unknown margin type: ${marginType}`);
      }
    }
  }

  const getValidPosition = (position: Position) => {
    const marginsGap = DPI.mm2px(MARGINS_MIN_GAP);

    const newPosition = [...position] as Position;
    newPosition[0] = Math.max(MARGIN_MIN, newPosition[0]);
    newPosition[1] = Math.max(MARGIN_MIN, newPosition[1]);

    const oppositeMarginSize = DPI.any2px(getOppositeMargin(), pageMargins.units);
    const remainer = (
      isHorizontal ?
      pageSize[1] - newPosition[1] - oppositeMarginSize :
      pageSize[0] - newPosition[0] - oppositeMarginSize
    );

    if (remainer < marginsGap) {
      const idx = isHorizontal ? 1 : 0;
      newPosition[idx] = pageSize[idx] - oppositeMarginSize - marginsGap;
    } 
  
    return newPosition;
  }

  const handleDraggerUpdateStart = (update: DraggerUpdate) => {
    const position_px = DPI.any2px(margin, pageMargins.units)
    const position_ = (
      isHorizontal ?
      [0, position_px] :
      [position_px, 0]
    ) as Position;

    startPosition.current = position_;
  }

  const handleDraggerUpdateCommon = (update: DraggerUpdate) => {
    const startPos = startPosition.current!;
    if (startPos === null) {
      console.warn("Problem while resizing page margins");
      return 0;
    }

    const newPosition = [
      startPos[0] + update.position[0] / scale * multiplier[0],
      startPos[1] + update.position[1] / scale * multiplier[1]
    ] as Position;

    const validPosition = getValidPosition(newPosition);
    setPosition(validPosition);

    const newPosPx = (
      isHorizontal ?
      validPosition[1] :
      validPosition[0]
    );

    return newPosPx;
  }

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    const newPosPx = handleDraggerUpdateCommon(update);
    updateState_debounced(newPosPx); 
  }

  const handleDraggerUpdateDone = (update: DraggerUpdate) => {
    updateState_debounced.cancel();

    const newPosPx = handleDraggerUpdateCommon(update);
    updateState(newPosPx);
    setViewsCommon();

    document.saveUndo();
    startPosition.current = null;
  }

  const updateState = (position: number) => {
    const newPosUnitsConverted_ = DPI.px2any(position, pageMargins.units);
    const newPosUnitsConverted = +newPosUnitsConverted_.toFixed(MARGIN_VALUE_DECIMAL_PLACES);
    const update = {[marginType]: newPosUnitsConverted};

    document.viewsCommon.updatePageMargins(update);
    setViewsCommon();
    autoAdjustColumns();
  }

  const updateState_debounced = useDebouncedCallback((position:number) => {
    updateState(position);
  }, MARGIN_UPDATE_DELAY);


  const getPositionStyle = () => {
    switch(marginType) {
      case MarginType.LEFT   : return {left: `${position[0] - draggerWidth / 2}px`};
      case MarginType.RIGHT  : return {left: `calc(100% - ${position[0] + draggerWidth / 2}px)`};
      case MarginType.TOP    : return {top: `${position[1] - draggerWidth / 2}px`};
      case MarginType.BOTTOM : return {top: `calc(100% - ${position[1] + draggerWidth / 2}px)`};
      default: {
        throw new Error(`Invalid margin type: ${marginType}`)
      }
    }
  }

  return (
    <MainWrapper
      onPointerDown={(e) => {e.stopPropagation();}}
      $horizontal={isHorizontal}
      $pageSize={pageSize}
    >
      <DraggerComponent
        position={[0, 0]}
        onUpdateStart={handleDraggerUpdateStart}
        onUpdate={handleDraggerUpdate}
        onUpdateDone={handleDraggerUpdateDone}
        component={Dragger}
      >
        <MarginWrapper
          $horizontal={isHorizontal}
          $pageSize={pageSize}
          $draggerWidth={draggerWidth}
          style={{
            ...getPositionStyle(),
          }}
        >
          <StyledMargin 
            $mobile={isMobile}
            $horizontal={isHorizontal}
            $marginWidth={marginWidth}
            $draggerWidth={draggerWidth}
          />
        </MarginWrapper>
      </DraggerComponent>
    </MainWrapper>
  );
}
