import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useSelectedReset = () => {
  const document = useDocState();
  const {
    setReleaselogsSession
  } = useEditorStatesSetters();

  const hook = (props?: {skipStateUpdate?: boolean}) => {
    const skipStateUpdate = props?.skipStateUpdate ?? false;

    document.releaselogsSession.setCellSelected(null);
    document.releaselogsSession.setColumnSelected(null);
    document.releaselogsSession.setRowSelected(null);

    if (skipStateUpdate) {
      return;
    }

    setReleaselogsSession();
  }

  return hook;
}


export default useSelectedReset;
