import { useRecoilValue } from "recoil";
import { UIUser_Invoices } from "app/ui-v2/app/__modules/user/states";
import { UIUser_Subscriptions } from "app/ui-v2/app/__modules/user/states";


const useStates = () => {
  const subscriptionsAddrs = useRecoilValue(UIUser_Subscriptions.subscriptionsAddrs);
  const invoicesLoaded = useRecoilValue(UIUser_Invoices.loaded);

  return {
    subscriptionsAddrs,
    invoicesLoaded,
  }
}

export default useStates;
