import { useRecoilValue } from 'recoil';

import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { UIState_ReleaselogsView } from 'app/ui/states/editor-instruction';


const useStates = () => {
  const css = useRecoilValue(UIState_ReleaselogsView.headerTitleCSS);
  const visible = useRecoilValue(UIState_ReleaselogsView.headerTitleVisible);
  const visibleNone = (visible === TypesShared.PageItemVisibility.NONE);


  return {
    css,
    visible,
    visibleNone,
  }
}


export default useStates;