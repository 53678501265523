import styled from "styled-components";
import TitleBase from "lego/styles/title";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};
  align-items: center;
`;

export const Title = styled(TitleBase)`
  font-size:   ${props => props.theme.defs.font.size.large};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;
