import styled from "styled-components";


export const MenuTitle = styled.div`
  cursor: pointer;
  user-select: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap:           ${props => props.theme.menuItem.gap};
  font-size:     ${props => props.theme.defs.font.size.xlarge};
  font-weight:   ${props => props.theme.defs.font.weight.medium};
  color:         ${props => props.theme.menuItem.color.primary};
`;

