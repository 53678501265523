import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { useDocState } from 'app/ui/contexts/document';
import { UIState_RepoMarkers } from 'app/ui/states/editor-instruction';

import { Textarea } from './styles';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  onDone?: () => void;
  onCancel?: () => void;
}


export const MarkerTextInputComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    onDone,
    onCancel,
  } = props;

  const document = useDocState();
  const markerProps = document.repoMarkers.getMarkerProps(markerAddr);

  const [markerText, setMarkerText] = useState(markerProps.label.text);

  const textareaRef   = useRef<any>(null);
  const setDocMarkers = useSetRecoilState(UIState_RepoMarkers.state);

  useEffect(() => {
    if ( ! textareaRef.current) {
      return;
    }

    textareaRef.current.focus();
    const textLength = textareaRef.current.value.length;
    textareaRef.current.setSelectionRange(textLength, textLength);

  }, [textareaRef.current])

  const handleInputChange = (event: any) => {
    setMarkerText(event.target.value);
  }

  const commit = () => {
    const textTrimmed = markerText.trim()
    document.repoMarkers.updateMarkerLabel(
      markerAddr,
      {text: textTrimmed}
    );

    document.saveUndo();
    setDocMarkers(document.repoMarkers.state);
    onDone?.();
  }

  const cancel = () => {
    onCancel?.();
  }

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      commit();
    }
    else if (event.key === "Escape") {
      event.preventDefault();
      cancel();
    } 
  };


  return (
    <Textarea
      ref={textareaRef}
      value={markerText}
      onBlur={commit}
      onInput={handleInputChange}      
      onKeyDown={handleKeyDown}

      style={{
        ...markerProps.label.css,
      }}
    />
  );
}
  
