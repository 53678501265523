import styled from "styled-components";
import ItemSelectedBorderBase from "lego/styles/item-selected-border";


interface Props {
  $width: number;
  $height: number;
}

export const ItemSelectedBorder = styled(ItemSelectedBorderBase)<Props>`
  width:  ${props => props.$width}px;
  height: ${props => props.$height}px;
`;


export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;
