import React from 'react';

import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';

import MarkerViewSignInfoComponent   from './marker-view-sign-info';
import MarkerViewSignProhibitedComponent from './marker-view-sign-prohibited';
import MarkerViewSignWarningComponent    from './marker-view-sign-warning';
import MarkerViewSignMandatoryComponent  from './marker-view-sign-mandatory';
import { MarkerViewBaseProps } from './types';

import { ViewWrapper } from './styles';


interface Props extends MarkerViewBaseProps {
  signType: RepoMarkersTypes.MarkerSignType;
}


export const MarkerViewSignBaseComponent: React.FC<Props> = (props: Props) => {
  const {
    Icon,
    signType
  } = props;


  type SignsMapType = {[signType in RepoMarkersTypes.MarkerSignType]: React.FC<MarkerViewBaseProps>};

  const SignsMap: SignsMapType = {
    [RepoMarkersTypes.MarkerType.SIGN_INFO       ]: MarkerViewSignInfoComponent,
    [RepoMarkersTypes.MarkerType.SIGN_WARNING    ]: MarkerViewSignWarningComponent,
    [RepoMarkersTypes.MarkerType.SIGN_PROHIBITION]: MarkerViewSignProhibitedComponent,
    [RepoMarkersTypes.MarkerType.SIGN_MANDATORY  ]: MarkerViewSignMandatoryComponent
  }

  const SignViewComponent = SignsMap[signType];

  
  return  (
    <ViewWrapper>
      <SignViewComponent Icon={Icon} />
    </ViewWrapper>
  );
}
