import { InfoActionsTypes } from "app/arch/app/info";
import useInfoStatesSetters from "../../../hooks/use-info-state-setters";
import useActionsState from "./use-actions-state";


const useActionScheduleDelete = () => {
  const actions = useActionsState();
  const {
    setActions
  } = useInfoStatesSetters();

  const scheduleDelete = (actionAddr: InfoActionsTypes.ActionAddr) => {
    actions.scheduleDelete(actionAddr);
    setActions();
  }

  return scheduleDelete;
}


export default useActionScheduleDelete;