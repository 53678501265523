export enum RateControlType {
  DEBOUNCED = 'debounced',
  THROTTLED = 'throttled',
  NONE = 'none'
}


export interface RateControl {
  controlType?: RateControlType
  interval?: number;
}
