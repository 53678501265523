import React from 'react';
import ProgressComponent from '../progress';

import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { Box as BoxDefault } from './styles';
import { Info } from './styles';


interface Props {
  title?: string;
  hideTitle?: boolean;
  Box?: React.ElementType;
}


export const ProgressBoxComponent: React.FC<Props> = (props: Props) => {
  const {
    hideTitle,
  } = props;

  const t = useTranslations();
  const title = props.title || t('loading');
  const Box = props.Box || BoxDefault;

  return (
    <Box>
      <ProgressComponent />
      {
        ! hideTitle &&
        <Info>{ title }</Info>
      }
    </Box>
  );
}
  
