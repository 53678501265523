import React, { useState } from 'react';
import PanelTitledComponent from 'app-views/components/panel-titled';

import { ReposTypes } from 'app/arch/app/states/repos';
import useRepos from 'app/ui-v2/app/hooks/use-repos';
import useUsername from 'app/ui-v2/app/hooks/use-user-name';
import { useTranslations }  from 'app/ui/hooks/app/use-translation';

import RepoMemberItemComponent from '../repo-member-item';


interface Props {
  repoId: number;
}

  
export const RepoMembersListComponent: React.FC<Props> = (props: Props) => {
  const { 
    repoId,
  } = props;

  const t = useTranslations();
  const username = useUsername();
  const repos = useRepos();
  const [selectedMember, setSelectedMember] = useState<string | null>(null);

  const members = repos.getRepoMembers(repoId, username);

  const handleSelectMember = (memberUsername: string) => {
    if (selectedMember === memberUsername) {
      setSelectedMember(null);
    }
    else {
      setSelectedMember(memberUsername);
    }
  }

  const renderMembers = () => members.map((
    member: ReposTypes.Member, 
    idx: number
  ) => {
    const selected = member.username === selectedMember;

    return (
      <RepoMemberItemComponent 
        key={idx}
        member={member}
        repoId={repoId}
        selected={selected}
        onSelected={handleSelectMember}
      />
    );
  });


  return (
    <PanelTitledComponent title={t('repository members')} >
      { renderMembers() }
    </PanelTitledComponent>
  );
}