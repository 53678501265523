import { useProgressModal } from 'lego-v2/progress-modal';

import Errors from "app/arch/tools/errors";
import ExcelExporter from 'app/arch/editor-instruction/exporters/excel-exporter';

import { useDocState } from "app/ui/contexts/document";
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import { HookConfigProps } from "../types";


let _hookConfig: HookConfigProps | null = null;


export const configureHook = (props: HookConfigProps) => {
  _hookConfig = {
    ...props
  };
}


const useExcelExport = () => {
  const t = useTranslations();
  const docState = useDocState();

  const {
    showProgressModal,
    hideProgressModal,
  } = useProgressModal();

  const exportToExcel = () => {
    const xlsExporter = new ExcelExporter(docState);
    const assetsRepo = xlsExporter.assetsRepo;
    
    const {
      showImagesRender,
      hideImagesRender,
      setAssetsRepo,
    } = _hookConfig !;

    setAssetsRepo(assetsRepo);
    
    showImagesRender(() => {
      // Once asset repo has been populated
      const exportPromise = xlsExporter.download();

      exportPromise.then(() => {
        console.debug('XLS file downloaded successfully!');
      })
      .catch((error) => {
        console.error('Error downloading XLS file:', error);
      })
      .finally(() => {
        hideImagesRender();
        hideProgressModal();
      });
    });
  }

  const hook = () => {
    if (_hookConfig === null) {
      const msg = `ExcelExport hook has not been configured`;
      Errors.hard(msg);
      return;
    }

    showProgressModal(t("export xls, progress, msg"));

    // As it blocks for a moment
    // let click up and other event
    // finish first
    setTimeout(() => {
      exportToExcel();
    });
  }

  return hook;
};


export default useExcelExport;