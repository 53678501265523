import * as Defaults from './defaults';
import * as Types from './types';


/**
 * State
 */
export type State = {
  header: Types.Header
};


/**
 * Getters
 */
export const getHeader = (state: State) => state.header;
export const getHeaderMeta = (state: State) => state.header.meta;
export const getHeaderFields = (state: State) => state.header.fields;
export const getHeaderTitle = (state: State) => state.header.title;


/**
 * Initial state
 */
export const createInitialState = () => {
  const state: State = {
    header: Defaults.getHeader(),
  }

  return state;
}

