import { PrintoutViewTypes } from "app/arch/editor-instruction/document/states/printout/printout-view";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import usePrintoutViewState from "./use-printout-view-state";


const usePrintoutViewPageCurrentUpdate = () => {
  const printoutViewState = usePrintoutViewState();
  
  const {
    setPrintoutView,
  } = useEditorStatesSetters();

  
  const updatePageCurrent = (update: PrintoutViewTypes.PageCurrentUpdate) => {
    printoutViewState.updatePageCurrent(update);
    setPrintoutView();
  }

  return updatePageCurrent;
}


export default usePrintoutViewPageCurrentUpdate;