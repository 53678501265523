import React from 'react';
import jtl from 'tools/jtl';
import { Size } from 'app/arch/types';
import { ContentTools, ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { WidgetArrowTextEditorTextComponent } from '../widget-arrow-text-editor-text/WidgetArrowTextEditorText';
import { Box } from './styles';


interface Props {
  dataTest: string | null;
  widgetStyle: any;
  tailSize: Size;

  widgetAddr: ContentTypes.WidgetAddr;
  editDisabled: boolean;
  EditorTextPlugins: React.ReactNode[];
}


export const WidgetArrowTextEditorTextWrapperComponent: React.FC<Props> = (props: Props) => {
  const {
    dataTest,
    widgetStyle,
    tailSize,
    widgetAddr,
    editDisabled,
    EditorTextPlugins
  } = props;

  const outlineWidth = jtl.css.valueToNumber(widgetStyle.outlineWidth);

  // TODO: this Id should be on arrow 'root'
  // However there is none, that is why it is here
  // Inside this div/tree we are looking for 
  // div with '.editor-input' classname.
  const widgetHTMLId = ContentTools.getWidgetHTMLId(widgetAddr);

  const left = outlineWidth;
  const top = outlineWidth;
  const width  = tailSize[0];
  const height = tailSize[1];

  const {
    borderColor,
    outlineColor,
    background,
    backgroundColor,
    ...styleStripped
  } = widgetStyle;

  return (
    <Box
      id={widgetHTMLId}
      data-test={dataTest}
      $left={left}
      $top={top}
      $width={width}
      $height={height}
      style={styleStripped}
    >
      <WidgetArrowTextEditorTextComponent
        dataTest={dataTest}
        widgetAddr={widgetAddr}
        editDisabled={editDisabled}
        EditorTextPlugins={EditorTextPlugins}
      />
    </Box>
  )
}
