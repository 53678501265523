import React from 'react';

import * as Types from '../types';

import { MainWrapper }    from './styles';
import { ToolbarWrapper } from './styles';
import { PanelWrapper }   from './styles';


interface BaseProps {
  Panel: React.ReactElement;
  Toolbar: React.ReactElement;
}

type Props = BaseProps & Types.PropLeftOrRight;


export const SideControlInflowComponent: React.FC<Props> = (props: Props) => {
  const {
    Panel,
    Toolbar,

    left,
    right,
  } = props;


  return (
    <MainWrapper
      $left={left}
      $right={right}
    >
      <ToolbarWrapper>
        { Toolbar }
      </ToolbarWrapper>

      <PanelWrapper>
        { Panel }
      </PanelWrapper>

    </MainWrapper>
  );
}
