import React from 'react';
import { useSetRecoilState } from 'recoil';

import TooltipFollowComponent from 'lego/components/tooltip-follow';

import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import { useDocState }      from 'app/ui/contexts/document';
import { UIState_EditorLogoSession } from 'app/ui-v2/editor-logo/states';

import { Image }        from './styles';
import { ImageWrapper } from './styles';
import { LogoImage, LogoView } from 'app/ui-v2/editor-logo/types';


interface Props {
  logoUrl: string;
  logoImage: LogoImage;
  logoImageView: LogoView;
}


export const LogoImageUserComponent: React.FC<Props> = (props: Props) => {
  const { 
    logoUrl,
    logoImage,
    logoImageView
   } = props;

  const t = useTranslations();
  const document = useDocState();
  const setEditorLogoSessionState = useSetRecoilState(UIState_EditorLogoSession.state);

  const imgWidth  = logoImage?.width  ?? 0;
  const imgHeight = logoImage?.height ?? 0;

  const imgScale = logoImageView.scale;
  const imgLeft  = logoImageView.position[0] * 1;
  const imgTop   = logoImageView.position[1] * 1;


  const handleEditLogo = (event: React.MouseEvent) => {
    // event.stopPropagation();
    document.editorLogoSession.updateWindow({visible: true});
    setEditorLogoSessionState(document.editorLogoSession.state);
  }

  return (
    <TooltipFollowComponent
      title={t("click to edit")}
    >
      <ImageWrapper 
        onClick={handleEditLogo}
        style={{
          width:    `${imgWidth * imgScale}px`,
          minWidth: `${imgWidth * imgScale}px`,
          height: ` ${imgHeight * imgScale}px`,
        }}
      >
        <Image
          draggable={false}
          alt={""}
          src={logoUrl} 
          width={imgWidth * imgScale} 
          height={imgHeight * imgScale} 
        />
      </ImageWrapper>

    </TooltipFollowComponent>
  );
}
