
import styled from "styled-components";
import Title from "lego/styles/title";


export const TitleElement = styled(Title)`
  font-size:   ${props => props.theme.defs.font.size.normal};
  font-weight: ${props => props.theme.defs.font.weight.normal};
  opacity:     ${props => props.theme.defs.opacity.disabled};
  padding: 0;
`;

export default TitleElement;
