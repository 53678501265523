import styled from "styled-components";
import { PanelDefault } from "app/ui-v2/home-page/hls/view-panel/styles";
import ButtonText from "lego/styles/button_text";
import LayoutBase from 'app/ui-v2/home-page/styles/layout';
import TextBase from "lego/styles/text";


export const MainWrapper = styled(LayoutBase)`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  /* align-items: center; */
`;

export const FillerTop = styled.div`
  flex: 1 1 0;
  min-height: 0px;
`;

export const PanelWrapper = styled.div`
  flex: 0 0 auto;
`;

export const Panel = styled(PanelDefault)`
`;

export const FillerBottom = styled.div`
  flex: 1.5 1 0;
  min-height: 0px;
`;

export const Text = styled(TextBase)`
  overflow: visible;
  white-space: wrap;
`;

export const Button = styled(ButtonText)`
`;

export const ButtonsWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  width: 100%;
  gap: 6px;
`;