import { LexicalRegistry } from "./lexical-registry";
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';


type CellAddr = Types.CellAddr;


export class LexicalRegistryChangelog extends LexicalRegistry<CellAddr> {
  protected getDebugName(): string {
    return "ChangelogView";
  }

  protected getId(cellAddr: CellAddr) {
    const {
      releaselogId,
      columnId,
      rowId,
    } = cellAddr;

    const id = `${releaselogId}::${columnId}::${rowId}`;
    return id;
  }
}
