import * as Types from 'app/arch/editor-instruction/document/states/slicing/content/slice-request/types';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import Logger from 'libs/debug';


const useSliceRequestSend = () => {
  const document = useDocState();
  const log = Logger.getChangelogSlicer();

  const {
    setReleaselogsSliceRequest
  } = useEditorStatesSetters();

  const hook = (request_?: Types.SliceRequest) => {
    log.debug("[ViewChangelog] set slice request");

    const request = request_ || {
      delayed: true,
      onStart: () => { },
      onDone:  () => { },
    };

    document.releaselogsSliceRequest.requestSlicing(request);
    setReleaselogsSliceRequest();
  }

  return hook;
}


export default useSliceRequestSend;