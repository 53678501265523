import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from 'app/ui/contexts/document';
import useColumnsVisibleSignal from '../columns/use-columns-visible-signal';
import useColumnsAutoAdjust    from '../columns/use-columns-auto-adjust';


const useColumnVisibleSet = () => {
  const document = useDocState();
  const signalColumnsVisible = useColumnsVisibleSignal();
  const columnsAutoAdjust = useColumnsAutoAdjust();

  const hook = (
    columnAddr: TypesContent.ColumnAddr, 
    visible: boolean
  ) => {
    const isVisible = document.content.isColumnVisible(columnAddr);
    if (isVisible === visible) {
      console.warn(`Column visibility is already set to: ${visible}`);
      return;
    }

    document.content.updateColumn(columnAddr, { visible });
    columnsAutoAdjust();
    document.saveUndo();

    signalColumnsVisible();
  }

  return hook;
}

export default useColumnVisibleSet;