import { atom     } from 'recoil';
import { selector } from 'recoil';
import { HeaderMetaSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/header-meta-session';


export const state = atom<State.State>({
  key: "edi_doc_headerMetaSession",
  default: State.createInitialState()
});


/**
 * Logo
 */

export const logoUploading = selector({
  key: `edi_doc_headerMetaSession_logoUploading`,
  get: ({ get }) => {
    const stateObj = get(state);
    const uploading = State.getLogo(stateObj).uploading;
    return uploading;
  }
});
