import DocState from "app/arch/editor-instruction/document/states/doc-state";
import TableHeader from "./table-header";


class ViewProps {
  private _tableHeader: TableHeader;


  constructor(docState: DocState) {
    this._tableHeader = new TableHeader(docState);
  }

  get tableHeader() {
    return this._tableHeader;
  }
}


export default ViewProps;