import { useRecoilValue } from "recoil";
import { MarkerAddr } from "app/arch/editor-instruction/document/states/persistent/repo-markers/types";
import { UIState_RepoMarkers } from "app/ui/states/editor-instruction";


const useStates = (markerAddr: MarkerAddr) => {
  const markerProps = useRecoilValue(UIState_RepoMarkers.markerProps(markerAddr));
  
  return {
    markerProps,
  }
}

export default useStates;
