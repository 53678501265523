import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';
import useMarkerEdit    from 'app/ui-v2/editor-markers/hooks/use-marker-edit';
import useEditorStatesSetters from '../../../../../hooks/use-editor-states-setters';


export const useCellMarkerCreate = () => {
  const document = useDocState();
  const editMarker = useMarkerEdit();

  const {
    setContent,
    setRepoMarkers,
  } = useEditorStatesSetters();


  const hook = (cellAddr: ContentTypes.CellAddr) => {
    const markerAddr = document.repoMarkers.addMarker();
    document.content.cellMarkers_addMarker(markerAddr, cellAddr);
    document.saveUndo();
    
    setContent();
    setRepoMarkers();

    editMarker(markerAddr);
  }

  return hook;
}


export default useCellMarkerCreate;