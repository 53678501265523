import Text from 'lego/styles/text';
import styled from 'styled-components';


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.defs.gap.xxsmall};

  /* background-color: red; */
`;


export const DescriptionWrapper = styled.div`
  background-color: ${props => props.theme.defs.colors.background.primary};;
  border-width: 1px;
  border-color: ${props => props.theme.defs.colors.border.primary};
  border-style: solid;

  padding: ${props => props.theme.defs.padding.small};
`;


export const Description = styled(Text)`
  font-size: ${props => props.theme.defs.font.size.large};
  font-weight: ${props => props.theme.defs.font.weight.large};
`;
