import React from 'react';
import { useRecoilValue } from 'recoil';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { UIState_ReleaselogsSession } from 'app/ui/states/editor-instruction';

import { MainWrapper } from './styles';
import { Background }  from './styles';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
  children: React.ReactNode;
}


export const CellsLineSelectedComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props;

  const columnSelected = useRecoilValue(UIState_ReleaselogsSession.isColumnSelected(cellAddr));
  const rowSelected    = useRecoilValue(UIState_ReleaselogsSession.isRowSelected(cellAddr));
  const selected = columnSelected || rowSelected;

  return (
    <MainWrapper>
      <Background selected={selected} />
      { children }
    </MainWrapper>
  );
}
  
