import React from 'react';
import { useState }  from 'react';
import { useEffect } from 'react';

import { Position } from 'app/arch/types';
import ScreenFitterComponent          from '../screen-fitter';
import { HookConfigProps }            from '../../hooks/use-context-element';
import { useSetupContextElementHook } from '../../hooks/use-context-element';


interface Props {
}


export const ContextElementComponent: React.FC<Props> = (props: Props) => {
  const [position, setPosition]     = useState<Position>([0, 0]);
  const [hookConfig, setHookConfig] = useState<HookConfigProps | null>(null);
  
  const setupHook = useSetupContextElementHook()
  
  useEffect(() => {
    setupHook({
      setConfig:   (config_: HookConfigProps | null) => setHookConfig(config_),
      setPosition: (position_: Position) => setPosition(position_)
    });
  });


  const Element      = hookConfig?.Element;
  const ElementProps = hookConfig?.ElementProps;

  return (
    <>
    {
      hookConfig && 
      <ScreenFitterComponent
        position={position}
      >
        <Element {...ElementProps} />
      </ScreenFitterComponent>
    }
    </>
  );
}
