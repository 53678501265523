import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useReleaselogsColumnsWidthsSignal from './use-releaselogs-columns-widths-signal';
import { ReleaselogsTools as Tools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';


const useReleaselogsColumnsAutoAdjust = () => {
  const document = useDocState();
  const { 
    setReleaselogs 
  } = useEditorStatesSetters();
  const signalReleaselogsColumnsWidths = useReleaselogsColumnsWidthsSignal();


  const columnsAutoAdjust = () => {
    const releaselogsAddrs = document.releaselogs.getReleaselogsAddrs();
    
    releaselogsAddrs.forEach((releaselogAddr) => {
      Tools.columnsSmartAdjust(
        document,
        releaselogAddr
      );
    });

    setReleaselogs();
    signalReleaselogsColumnsWidths();
  }

  return columnsAutoAdjust;
}


export default useReleaselogsColumnsAutoAdjust;
