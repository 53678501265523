import React from 'react';

import { Title } from './styles';
import { Scroller }   from './styles';
import { Grid }       from './styles';
import { RowTitle }   from './styles';
import { RowContent } from './styles';
import { Panel as PanelDefault } from './styles';


interface Props {
  title?: string;

  Panel?: React.ElementType;
  Header?: JSX.Element;

  children: React.ReactNode;
}


export const PanelTitledComponent: React.FC<Props> = (props: Props) => {
  const { 
    title, 
    Panel: Panel_,
    Header,

    children,
  } = props;
  
  const Panel  = Panel_  || PanelDefault;

  return (
    <Grid>
      <RowTitle>
      {
        title &&
        <Title>
          { title }
        </Title>
      }
      </RowTitle>

      <RowContent>
        <Panel>
          { Header }
          <Scroller>
          { children }
          </Scroller>
        </Panel>
      </RowContent>
    </Grid>
  );
}

