import jtl from "tools/jtl";

import { Position } from "app/arch/types";
import { Size }     from "app/arch/types";
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { ContentTools } from "app/arch/editor-instruction/document/states/persistent/content";
import { WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";

import { SmartLines } from "./smart-lines";

interface OptWidgetBoxed {
  size: Size, 
  position: Position, 
  style: WidgetsStylesTypes.StyleAttrs
};

interface OptWidgetArrowText {
  tailSize: Size, 
  endPoint: Position, 
  style: WidgetsStylesTypes.StyleAttrs
};

type OptLoadWidget = OptWidgetBoxed | OptWidgetArrowText;


export class SmartLinesWidgets extends SmartLines {
  loadWidgets(widgetsProps: ContentTypes.WidgetProps[]) {
    this.reset();

    widgetsProps.forEach((widgetProps) => {
      const ok = this.loadWidget(widgetProps.type, widgetProps as any);
    });

    // const horizontalLen = this._lines.horizontal.length;
    // const verticalLen   = this._lines.vertical.length;
    // console.log(`Loaded hor: ${horizontalLen}, ver: ${verticalLen}`);
    this.sort();
  }
  
  loadWidget(
    widgetType: ContentTypes.WidgetType,
    opt: OptLoadWidget,
  ) {
    let ok = true;
    
    if (ContentTools.isWidgetArrowText(widgetType)) {
      const {
        tailSize,
        endPoint,
        style,
      } = opt as OptWidgetArrowText;

      ok = this.loadWidgetArrowText(tailSize, endPoint, style);
    }
    else if (ContentTools.isWidgetArrowTextLess(widgetType)) {
      // skip
    }
    else if (ContentTools.isWidgetBoxed(widgetType)) {
      const {
        size,
        position,
        style,
      } = opt as OptWidgetBoxed;
      ok = this.loadWidgetBoxed(size, position, style);
    }
    else {
      console.warn(`Widget type not implemented ${widgetType}`);
    }

    if ( ! ok ) {
      console.warn(`Problem with widget ${widgetType}`);
    }

    return ok;
  }

  private loadWidgetBoxed(
    size: Size, 
    position: Position, 
    widgetStyle: WidgetsStylesTypes.StyleAttrs
  ): boolean {
    if (position === undefined ) {
      console.warn("Loading smart lines from widgets, problem with position");
      return false;
    }

    if (size === undefined ) {
      console.warn("Loading smart lines from widgets, problem with size");
      return false;
    }

    // Content lines - no background, no outline
    {
      // this.addLines(size, position);
    }

    // Content + background + ouline
    {
      const frameWidth = jtl.css.getFramingWidth(widgetStyle);
      const size_     = size.map((value: number) => value + 2 * frameWidth) as Size;
      const position_ = position.map((value: number) => value - frameWidth) as Position;

      this.addLines(size_, position_);
    }

    return true;
  }

  private loadWidgetArrowText(
    tailSize: Size, 
    endPoint: Position, 
    widgetStyle: WidgetsStylesTypes.StyleAttrs
  ) {
    const outlineWidth =  jtl.css.valueToNumber(widgetStyle.outlineWidth);

    const size = [
      tailSize[0] + 2 * outlineWidth,
      tailSize[1] + 2 * outlineWidth,
    ] as Size;

    const position = [
      endPoint[0] - tailSize[0] / 2 - outlineWidth,
      endPoint[1] - tailSize[1] / 2 - outlineWidth,
    ] as Position;

    this.addLines(size, position);

    return true;
  }
}
