import React from 'react';
import { MutableRefObject } from 'react';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import EditorTextBaseComponent from 'app/ui/components/editor-txt';
import useReleaselogIsEditable from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-releaselog-is-editable';
import ContentSavePlugin  from './content-save-plugin';
import RegistrationPlugin from './registration-plugin';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
  editorTextRef: MutableRefObject<HTMLDivElement | null>;
}


export const CellTextEditorComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr, 
    editorTextRef,
  } = props;

  const editable = useReleaselogIsEditable(cellAddr);

  return (
    <EditorTextBaseComponent
      editable={editable}
      editorTextRef={editorTextRef}
      Plugins={[
        <RegistrationPlugin cellAddr={cellAddr} />,
        <ContentSavePlugin  cellAddr={cellAddr} />
      ]}
    />
  );
}
