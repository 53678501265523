import React from 'react';
import useReloginVisibleWatch from './hooks/use-relogin-visible-watch';
import ReloginViewComponent from './components/relogin-view';


interface Props {
}


export const ReloginComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const isVisible = useReloginVisibleWatch();

  if ( ! isVisible ) {
    return null;
  }

  return (
    <ReloginViewComponent />
  );
}

