import React from 'react';

import { UUID } from 'app/arch/types';

import ReleaseInfoProbeComponent   from './release-info-probe';
import ReleaseInfoContentComponent from './release-info-content';

import { Border } from './styles';


interface Props {
  releaselogId: UUID;
}


export const ReleaseInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    releaselogId,
  } = props;
  

  return (
    <ReleaseInfoProbeComponent
      releaselogId={releaselogId}
    >
      <Border>
        <ReleaseInfoContentComponent
          releaselogId={releaselogId}
        />
      </Border>
    </ReleaseInfoProbeComponent>
  );
}
