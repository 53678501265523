import { settings } from 'app/configs';
import * as Types from './types'; 
import { Tooltip } from './styles';


interface Props {
  title?: string;
  placement?: Types.Placement;
  noarrow?: boolean;
  disabled?: boolean;
  children: React.ReactElement;
}


export const TooltipComponent: React.FC<Props> = (props: Props) => {
  const { 
    title,
    noarrow,
    disabled,
    children
  } = props;

  const tooltipOff = disabled || ! title;
  const placement = props.placement || 'bottom';

  if (tooltipOff) {
    return children;
  }

  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow={! noarrow}
      enterDelay={settings.tooltip.entryDelay}
    >
      { children }
    </Tooltip>
  );
}
