import { settings } from "app/configs";
import { Position }      from "../types";
import { LogoImage } from "app/ui-v2/editor-logo/types";

const LogoSettings = settings.document.header.logo;

const MIN_MULTIPLIER = 4;
const MIN_SCALED_LOGO_WIDTH  = LogoSettings.width  / MIN_MULTIPLIER;
const MIN_SCALED_LOGO_HEIGHT = LogoSettings.height / MIN_MULTIPLIER;

const MAX_MULTIPLIER = 2;
const MAX_SCALED_LOGO_WIDTH  = LogoSettings.width  * MAX_MULTIPLIER;
const MAX_SCALED_LOGO_HEIGHT = LogoSettings.height * MAX_MULTIPLIER;


export class LogoImageTool {
  private _logoImage: LogoImage;

  constructor(logoImage: LogoImage) {
    this._logoImage = logoImage;
  }
  
  fitToView() {
    const scale = this.fitToViewScale();
    const position = this.centerInView(scale);

    return { scale, position };
  }

  fitToViewScale() {

    const scaleX = LogoSettings.width  / this._logoImage.width;
    const scaleY = LogoSettings.height / this._logoImage.height;
    const scale  = Math.min(scaleX, scaleY);

    return scale;
  }

  alignToTop(position: Position) {
    const positionNew = [position[0], 0] as Position;
    return positionNew;
  }

  alignToCenterVertical(position: Position, viewScale: number) {
    const positionCentered = this.centerInView(viewScale);
    const positionNew = [position[0], positionCentered[1]] as Position;

    return positionNew;
  }

  alignToCenterHorizontal(position: Position, viewScale: number) {
    const positionCentered = this.centerInView(viewScale);
    const positionNew = [positionCentered[0], position[1]] as Position;

    return positionNew;
  }

  centerInView(viewScale: number) {
    const header = settings.document.header;
  
    const scaledWidth  = viewScale * this._logoImage.width;
    const scaledHeight = viewScale * this._logoImage.height;

    const deltaX = LogoSettings.width  - scaledWidth;
    const deltaY = LogoSettings.height - scaledHeight;
    const position = [deltaX / 2, deltaY / 2] as Position;

    return position;
  }

  calculateScale(sizeDelta: number) {
    const scale0 = this.fitToViewScale();

    const widthScaled  = this._logoImage.width  * scale0;
    const heightScaled = this._logoImage.height * scale0;

    const widthNew  = widthScaled  + sizeDelta;
    const heightNew = heightScaled + sizeDelta;
    
    const scaleWidth = widthNew   / this._logoImage.width;
    const scaleHeight = heightNew / this._logoImage.height;

    const scaleNew = Math.min(scaleWidth, scaleHeight);
    return scaleNew;
  }

  // Size delta is equivalent of slider value
  calculateSizeDelta(scale: number) {
    // This is really revering `calculateScale` method
    const scale0 = this.fitToViewScale();
    
    const width0  = this._logoImage.width  * scale0;
    const height0 = this._logoImage.height * scale0;

    const widthNow  = this._logoImage.width  * scale;
    const heightNow = this._logoImage.height * scale;

    const deltaX = widthNow - width0;
    const deltaY = heightNow - height0;
    const delta  = Math.max(deltaX, deltaY);

    // Just sometimes we get VALUE.000000003
    const deltaInt = Math.ceil(delta);

    return deltaInt;
  }

  getCenterPoint(position: Position, scale: number) {
    const centerPoint = [
      position[0] + scale * this._logoImage.width / 2,
      position[1] + scale * this._logoImage.height / 2,
    ] as Position;

    return centerPoint;
  }

  calculatePosition(centerPoint: Position, scale: number) {
    const widthScaled  = this._logoImage.width  * scale;
    const heightScaled = this._logoImage.height * scale;

    const position = [
      centerPoint[0] - widthScaled / 2,
      centerPoint[1] - heightScaled / 2,
    ] as Position;

    return position;
  }

  getSizeDeltaMax() { 
    const scaleMaxX = MAX_SCALED_LOGO_WIDTH  / this._logoImage.width;
    const scaleMaxY = MAX_SCALED_LOGO_HEIGHT / this._logoImage.height;
    const scaleMax = Math.max(scaleMaxX, scaleMaxY);

    return this.calculateSizeDelta(scaleMax);
  }
  
  getSizeDeltaMin() { 
    const scaleMinX = MIN_SCALED_LOGO_WIDTH  / this._logoImage.width;
    const scaleMinY = MIN_SCALED_LOGO_HEIGHT / this._logoImage.height;
    const scaleMin = Math.min(scaleMinX, scaleMinY);

    return this.calculateSizeDelta(scaleMin);
  }
}