import styled from 'styled-components';


export const FrameArrowHeadWrapper = styled.div`
  position: absolute;
`;

export const FrameArrowBodyWrapper = styled.div`
  position: absolute;
`;

export const Canceler = styled.div`
  pointer-events: all;
`;
