

import React, { useRef } from 'react';

import BtnTextComponent from 'lego-v2/btn-text';
import useMenubarContext from 'lego-v2/menubar/hooks/use-menubar-context';

import * as Types from '../../types';
import MenubarItemPanelComponent from '../menubar-item-panel';
import MenubarItemContextComponent from '../menubar-item-context';

import { 
  BtnWrapper,
  MenuBarItemPanelDetacher, 
  MenuBarItemPanelPositioner, 
  StyledBtnText 
} from './styles';



interface Props extends Types.MenubarItemProps {
}


export const MenubarItemComponent: React.FC<Props> = (props: Props) => {
  const { 
    config, 
    open, 
  } = props;

  const {
    closeItem,
    toggleOpenItem,
  } = useMenubarContext();

  const cancelNextBlurRef = useRef<boolean>(false);

  const handleClick = (event: any) => {
    toggleOpenItem();
  };

  const handleBlur = () => {
    if (cancelNextBlurRef.current) {
      cancelNextBlurRef.current = false;
      return;
    }

    closeItem();
  }

  const cancelBlur = () => {
    cancelNextBlurRef.current = true;
  }


  return (
    <>
      { 
        open &&
        <MenuBarItemPanelDetacher>
          <MenuBarItemPanelPositioner>
            <MenubarItemContextComponent cancelBlur={cancelBlur}>
              <MenubarItemPanelComponent config={config.panel}/>
            </MenubarItemContextComponent>
          </MenuBarItemPanelPositioner>
        </MenuBarItemPanelDetacher>
      }
      <BtnWrapper
        tabIndex={0}
        onBlur={handleBlur}
      >
        <BtnTextComponent
          dataTest={config.dataTest}
          text={config.title}
          selected={open}
          onClick={handleClick}
          component={StyledBtnText}
        />
      </BtnWrapper>
    </>
  )
}