import styled from "styled-components";
import zIndex from "../../z-index";
import CellSelectedBorder from "app/ui-v2/editor-instruction/views/shared/styles/cell-selected-border";


export const MainWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const Border = styled(CellSelectedBorder)`
  // It must be more than 1
  // because in cell childs use sometime 1
  // for example image idx
  z-index: ${zIndex.cellBorder};
`;
