import React from 'react';
import { ToolbarProps } from '../types';


interface Props extends ToolbarProps {
}


export const ToolbarDummyComponent: React.FC<Props> = (props: Props) => {
  return null;
}
