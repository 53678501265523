import * as Types    from './types';
import * as Defaults from './defaults';


/**
 * State
 */

export type State = {
  uploadingImages: Types.UploadingImages,
};


/**
 * Getters
 */

export const getImagesUploading = (
  state: State,
) => {
  return state.uploadingImages;
}


/**
 * Initial state
 */

export const createInitialState = (): State => {
  const state: State = {
    uploadingImages: Defaults.getUploadingImages()
  }

  return state;
}
