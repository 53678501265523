import React from 'react';

import AppVersionComponent from './components/app-version';
import AppDebugComponent   from './components/app-debug';
import LoaderComponent     from './components/loader';
import ModalsComponent     from './components/modals';
import LoaderIsPrintoutComponent from './components/loader/loader-is-printout';
import CSSVarsSettersComponent   from './components/css-vars-setters';
import ScreenSizeWatcherComponent from 'app/ui-v2/app/__modules/screen/components/screen-size-watcher';


interface Props {
  children: React.ReactNode;
}


export const AppComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  return (
    <LoaderIsPrintoutComponent>
      <LoaderComponent>
        { children }
      </LoaderComponent>

      <ModalsComponent />
      <AppVersionComponent />
      <AppDebugComponent />
      <ScreenSizeWatcherComponent />
      <CSSVarsSettersComponent />

    </LoaderIsPrintoutComponent>
  );
}

