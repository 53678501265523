import { useApp } from 'app/ui/hooks/app/use-app';


const useGoogle = () => {
  const app = useApp();
  const google = app.google;
  return google;
}


export default useGoogle;