import { NavbarTypes } from "app/arch/home-page-guest/states/navbar";
import useState from "./use-state"
import useStateSetters from "./use-state-setters";


const useNavbarItemSelect = () => {
  const homePageGuest = useState();
  const {
    setNavbar
  } = useStateSetters();
  
  const selectNavbarItem = (navbarItem: NavbarTypes.NavbarItem) => {
    homePageGuest.navbar.update({itemSelected: navbarItem});
    setNavbar();
  }

  return selectNavbarItem;
}

export default useNavbarItemSelect;