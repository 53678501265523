import styled from "styled-components";
import PanelBase from "lego/styles/panel";
import ItemSelecteable from "lego/styles/item-selecteable";
import Scrollbar from "lego/styles/scrollbar";
import TextWrap from "lego/styles/text-wrap";
import InputBase from 'lego/styles/input';


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: ${props => props.theme.defs.gap.normal};
  height: 100%;
  width: 100%;
  /* background: blue; */
`;



// Padding needs to be here to fix outline of input
// when it is selected, being cut off div due to 
// overflow clip (which is needed to force scrolling
// on icons panel)
export const InputWrapper = styled.div`
  flex: 0 0 auto;
  max-width: 100%;
  /* background: yellow; */
`;

export const Input = styled(InputBase)`
  max-width: 100%;
`;

export const Button = styled(ItemSelecteable)`
`;

export const Panel = styled(PanelBase)`
  box-sizing: border-box;
  flex: 1 1 0;
  min-height: 70px;

  /* background: ${props => props.theme.defs.colors.background.secondary}; */
  background: ${props => props.theme.defs.colors.background.forth};
  padding: 0px;
  overflow: hidden;
`;

export const IconsScroller = styled(Scrollbar)`
  height: 100%;
  overflow-y: auto;
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.normal};
  flex-wrap: wrap;
  justify-content: center;
  padding: ${props => props.theme.defs.padding.normal};
`;


export const TextWrapper = styled(TextWrap)`
`