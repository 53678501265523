import { PanelControlsConfig } from "lego/components/panel-controls/config";
import useStates from "./use-states";
import useItems from "./use-items";

import * as Types from './types';


interface Props extends Types.ConfigProps {

}

const useConfig = (props: Props) => {
  const states = useStates(props);
  const items = useItems(states);

  const getConfig = (): PanelControlsConfig => ({
    disabled: false,
    items: [
      {
        sections: [
          {
            items: [
              items.uploadLogo(),
              items.removeLogo(),
              items.rescale(),
            ]
          }
        ]
      },
    ]
  });


  return getConfig;
}


export default useConfig;
