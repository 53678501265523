import TestLinesComponent from "../test-lines";
import { Box         } from "./styles";
import { MainWrapper } from "./styles";


interface Props {
}


export const TestPageComponent: React.FC<Props> = (props: Props) => {

  return (
    <MainWrapper>
      <Box>
        <TestLinesComponent 
          horizontal 
          paletteIdx={0}
        />
      </Box>
      <Box>
        <TestLinesComponent 
          vertical 
          paletteIdx={1}
        />
      </Box>
      <Box>
        <TestLinesComponent 
          vertical 
          paletteIdx={2}
        />
      </Box>
      <Box>
        <TestLinesComponent 
          horizontal 
          paletteIdx={3}
        />
      </Box>
    </MainWrapper>
  );
}
