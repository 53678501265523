import { useRef }    from 'react';
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { query } from "app/arch/backend";

import { MsgDuration }     from "app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box";
import { MsgLevel }        from "app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box";
import { useMsgBox }       from "app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box";
import { useTranslations } from "app/ui/hooks/app/use-translation";


type OnNotLockedFn = () => void;


const usePDFGenerateLockCheck = () => {
  const t = useTranslations();
  const onNotLockedRef = useRef<OnNotLockedFn | null>(null);
  
  const {
    showMsgBox 
  } = useMsgBox();

  const [
    getPDFGenerateLock, { 
      loading, 
      error, 
      data 
    }
  ] = useLazyQuery(
    query.getPDFGenerateLock(), {
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (data === undefined) { return; }

    const lockStatus = data.pdfGenerateLock;
    const locked  = lockStatus.locked;
    const timeout = lockStatus.timeout;

    if ( locked ) {
      showLockedWarning(timeout);
      return
    }

    if (onNotLockedRef.current === null) { return; }
    onNotLockedRef.current()
    onNotLockedRef.current = null;
  }, [data]);


  const showLockedWarning = (timeout: number) => {
    const msgRaw = t("pdf, generation in progress <<TIMEOUT>>");
    const msg = msgRaw.replace('<<TIMEOUT>>', `${timeout}`);

    showMsgBox(
      msg,
      MsgLevel.ERROR,
      MsgDuration.ERROR
    );
  }

  const hook = (props: {
    onNotLocked: OnNotLockedFn
  }) => {
    onNotLockedRef.current = props.onNotLocked;
    getPDFGenerateLock();
  }

  return hook;
}


export default usePDFGenerateLockCheck;
