import React from 'react';

import { ToastsTypes } from 'app/arch/app/info/toasts';
import useToastsState from '../../hooks/use-toasts-state';

import { ToastWrapper } from './styles';
import { Toast }       from './styles';
import { DummyFiller } from './styles';


interface Props {
  toastAddr: ToastsTypes.ToastAddr;
}


export const ToastComponent: React.FC<Props> = (props: Props) => {
  const {
    toastAddr
  } = props;

  const toasts = useToastsState();
  const toastProps = toasts.getToastProps(toastAddr);

  return (
    <ToastWrapper>
      <DummyFiller />
      <Toast $level={toastProps.level}>
        { toastProps.text }
      </Toast>
    </ToastWrapper>
  );
}
