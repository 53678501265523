import { useApp } from 'app/ui/hooks/app/use-app';


const useRepos = () => {
  const app = useApp();
  const repos = app.repos;
  return repos;
}


export default useRepos;