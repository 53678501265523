import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_RepoImages } from 'app/ui/states/editor-instruction';

import ImageComponent from './image';


interface Props {
}


export const ImagesComponent: React.FC<Props> = (props: Props) => {
  const images = useRecoilValue(UIState_RepoImages.images);

  const renderImages = () => {
    const ImgsComps = images.map((image, idx) => (
      <ImageComponent 
        key={`${image.bid}-${idx}`}
        idx={idx}
        image={image} 
      />
    ));

    return ImgsComps;
  }

  return renderImages();
}