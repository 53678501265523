import React from 'react';

import ContentCellMenuComponent      from './content-cell-menu';
import CustomFieldsCellMenuComponent from './custom-fields-cell-menu';
import CustomRowsCellMenuComponent   from './custom-rows-cell-menu';
import BlankMenuComponent            from './blank-menu';

import { MainWrapper } from './styles';


interface Props {
}


export const TopMenuComponent: React.FC<Props> = (props: Props) => {

  return (
    <MainWrapper>
      <ContentCellMenuComponent />
      <CustomFieldsCellMenuComponent />
      <CustomRowsCellMenuComponent />
      <BlankMenuComponent />
    </MainWrapper>
  );
}
