import React from 'react';
import * as Types from '../../types';
import { MenubarItemPanelItemComponent } from '../menubar-item-panel-item/MenubarItemPanelItem';


import SubsectionPanelComponent from './subsection-panel';
import { MainWrapper } from './styles';
import { Detacher } from './styles';


interface Props extends Types.MenubarItemPanelItemSubsectionProps {
  open: boolean;
}


export const MenubarItemPanelItemSubsectionComponent: React.FC<Props> = (props: Props) => {
  const {
    open,
    config,
  } = props;


  return (
    <MainWrapper>
      <MenubarItemPanelItemComponent 
        {...props} 
        skipClick={true}
      />
      {
        open &&
        <Detacher>
          <SubsectionPanelComponent items={config.items}/>
        </Detacher>
      }
    </MainWrapper>
  );
}
