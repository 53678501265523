import { useState } from 'react';


type DouleTap = (event: React.TouchEvent) => void;

export interface Props {
  onDoubleTap: DouleTap;
  delay?: number;
}


const useDoubleTap = ({ onDoubleTap, delay: delayInit }: Props) => {
  const [lastTap, setLastTap] = useState(0);

  const delay = (
    delayInit !== undefined ? 
    delayInit :
    300
  );

  const handleTouchStart = (event: React.TouchEvent) => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap;

    if (tapLength < delay && tapLength > 0) {
      onDoubleTap(event);
    }
    setLastTap(currentTime);
  };

  return handleTouchStart;
};


export default useDoubleTap;
