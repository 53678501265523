import styled from "styled-components";
import TopBarBase from "app/ui-v2/home-page/styles/top-bar";
import FlexLogoWrapperBase from "app/ui-v2/home-page/styles/flex-logo-wrapper";
import Panel from "lego/styles/panel";


export const TopBar = styled(TopBarBase)`
`;

export const FlexLogoWrapper = styled(FlexLogoWrapperBase)`
`;

export const TopMenuWrapper = styled.div`
  flex-grow: 1;
`;

export const TopMenuWrapperTest = styled.div`
  flex-grow: 1;
  position: absolute;
  z-index: 1;
  top: 46px;
  left: 13px;
`;

export const LogoutBtnWrapper = styled.div`
  width: 38px;
  height: 100%;
`;
