import produce from 'immer';
import { MIN_IMG_HEIGHT, MIN_IMG_WIDTH } from '../../ImageViewDefiner';

export interface LinesState {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};


export const linesStateReducer = (lineState: LinesState, action: any) => {
  switch (action.type) {
    case 'update': return cmdUpdate(lineState, action);
    case 'reset':  return cmdReset(lineState, action);

    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}


const cmdReset = (lineState: LinesState, action: any) => 
  produce(lineState, draft => {
    draft.x1 = action.payload.x1;
    draft.x2 = action.payload.x2;
    draft.y1 = action.payload.y1;
    draft.y2 = action.payload.y2;
  });


const cmdUpdate = (lineState: LinesState, action: any) => 
  produce(lineState, draft => {
    if ('x1' in action.payload) {
      if (draft.x2 - action.payload.x1 < MIN_IMG_WIDTH) {
        draft.x1 = draft.x2 - MIN_IMG_WIDTH;
      }
      else {
        draft.x1 = action.payload.x1;
      }
    }

    if ('x2' in action.payload) {
      if (action.payload.x2 - draft.x1 < MIN_IMG_WIDTH) {
        draft.x2 = draft.x1 + MIN_IMG_WIDTH;
      }
      else {
        draft.x2 = action.payload.x2;
      }
    }

    if ('y1' in action.payload) {
      if (draft.y2 - action.payload.y1 < MIN_IMG_HEIGHT) {
        draft.y1 = draft.y2 - MIN_IMG_HEIGHT;
      }
      else {
        draft.y1 = action.payload.y1;
      }
    }

    if ('y2' in action.payload) {
      if (action.payload.y2 - draft.y1 < MIN_IMG_HEIGHT) {
        draft.y2 = draft.y1 + MIN_IMG_HEIGHT;
      }
      else {
        draft.y2 = action.payload.y2;
      }
    }
  });
