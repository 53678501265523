import styled from "styled-components";
import ButtonText from "lego/styles/button_text";
import TextWrap   from "lego/styles/text-wrap";


interface ButtonProps {
  $width?: string;
}


export const Button = styled(ButtonText)<ButtonProps>`
  width: ${props => props.$width !== undefined ? props.$width : '100%' };
  max-width: unset;
  min-width: unset;
`;

export const Text = styled(TextWrap)`
`;