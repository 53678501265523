import React from 'react';
import Context from './context';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';


interface Props {
  content: SlicerTypes.PageItem_Content;
  children: React.ReactNode;
}


export const ContentContextComponent: React.FC<Props> = (props: Props) => {
  const {
    content,
    children,
  } = props;

  return (
    <Context.Provider
      value={{content}}
    >
      { children }
    </Context.Provider>
  );
}
  
