import React from 'react';
import { MenubarItemPanelItemComponent } from '../../menubar-item-panel-item/MenubarItemPanelItem';
import * as Types from '../../../types';

import { Panel } from './styles';


interface Props {
  items: Types.SectionItem[],
}


export const SubsectionPanelComponent: React.FC<Props> = (props: Props) => {
  const {
    items
  } = props;

  const renderItems = () => {
    return items.map((item, idx) => {
      const key = `${idx}`;

      return (
        <MenubarItemPanelItemComponent
          key={key}
          config={item}
        />
      )
    });
  }

  return (
    <Panel>
      { renderItems() }
    </Panel>
  );
}
