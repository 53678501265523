import styled from "styled-components";


export const Pages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;


export const Page = styled.div`
  background: rgba(255, 0, 0, 0.2);
  width: 210mm;
  height: 297mm;
  overflow: clip;
`;

export const PageR = styled(Page)`
  background: rgba(255, 0, 0, 0.2);
`;

export const PageG = styled(Page)`
  background: rgba(0, 255, 0, 0.2);
`;

export const PageB = styled(Page)`
  background: rgba(0, 0, 255, 0.2);
`;

export const P = styled.p`
  /* margin: 0px; */
  
  overflow: clip;
  /* height: 30px; */
  /* line-height: 10px; */

`

export const Lines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;