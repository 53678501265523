import styled from 'styled-components';


interface StyledFrameCtrlProps {
  $cursor: string;
};

export const StyledFrameCtrl = styled.div<StyledFrameCtrlProps>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  pointer-events: all;

  ${props => `cursor: ${props.$cursor};`};
`;