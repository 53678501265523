import React from 'react';

import usePricePlan from '../../hooks/use-price-plan';
import { FeatureComponent } from './feature/Feature';
import { MainWrapper } from './styles';
import { Includes } from './styles';
import { Features } from './styles';


interface Props {
}


export const PricePlanFeaturesComponent: React.FC<Props> = (props: Props) => {
  const pricePlan = usePricePlan();
  const plan = pricePlan.plan;
  const features = plan.features;

  const FeaturesComps = features.map((feature, idx) => (
    <FeatureComponent 
      key={idx}
      feature={feature}
    />
  ));
  
  return (
    <MainWrapper>
      <Includes>
        Includes:
      </Includes>
      <Features>
        { FeaturesComps }
      </Features>
    </MainWrapper>

  );
}

