import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { ToastsState } from "app/arch/app/info/toasts";
import useToastsState from "./use-toasts-state";
import { UIInfoToasts } from "../states";


const useToastsListenerRegistration = () => {
  const toasts = useToastsState();
  const setToastsState = useSetRecoilState(UIInfoToasts.state);

  useEffect(() => {
    toasts.addListener((state: ToastsState.State) => {
      setToastsState(state);
    });

    return () => {
      toasts.removeListener();
    }
  }, []);
}


export default useToastsListenerRegistration;
