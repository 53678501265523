import styled from "styled-components";


export const MainWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const Line = styled.div`
  position: absolute;
  background: rgba(100, 100, 100, 0.4);
`;

export const LineVertical = styled(Line)`
  width: 1px;
  height: 100%;
`;

export const LineHorizontal = styled(Line)`
  width: 100%;
  height: 1px;
`;