import * as Types from './types';


export const getViewsLoaded = (): Types.ViewsLoaded => ({
  [Types.ViewType.CONTENT]: false,
  [Types.ViewType.CHANGELOG]: false,
});


export const getPages = (): Types.Pages => ({
  current: {
    view: Types.ViewType.CONTENT,
    pageIdx: 0,
  },

  count: {
    [Types.ViewType.CONTENT  ]: 0,
    [Types.ViewType.CHANGELOG]: 0,
  }
});
