import * as Types from './types';
import { PanelType } from './types';
import { ScreenSizeTypes } from 'app/arch/app/screen';


export const getPanelWidthsMap = (): Types.PanelWidthsMap => {
  const panelWidths: Types.PanelWidthsMap  = {
    [PanelType.NARROW_190]: 190,
    [PanelType.NARROW_230]: 230,

    [PanelType.WIDE_320]: 320,
    [PanelType.WIDE_360]: 360,
    [PanelType.WIDE_390]: 390,
  }

  return panelWidths;
}


export const getScreenToPanelMap = (): Types.ScreenToPanelMap => ({
  [ScreenSizeTypes.SizeVariant.WIDTH_320]: PanelType.NARROW_190,
  [ScreenSizeTypes.SizeVariant.WIDTH_360]: PanelType.NARROW_230,
  [ScreenSizeTypes.SizeVariant.WIDTH_390]: PanelType.NARROW_230,
  [ScreenSizeTypes.SizeVariant.WIDTH_500]: PanelType.WIDE_320,
  [ScreenSizeTypes.SizeVariant.WIDTH_600]: PanelType.WIDE_390,
});
