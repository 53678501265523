import Logger from "libs/debug";
import { MutableRefObject, useRef, useState } from "react";
import { useEffect } from "react";


interface Props {
  gridRef: MutableRefObject<HTMLDivElement | null>,
  titleRef: MutableRefObject<HTMLDivElement | null>,
  contentRef: MutableRefObject<HTMLDivElement | null>,
}

const useMinHeightCalc = (props: Props) => {
  const {
    gridRef,
    titleRef,
    contentRef
  } = props;
  

  const logger = Logger.getHomePageView();

  const [titleHeight, setTitleHeight] = useState(0);
  const [gridGap, setGridGap]         = useState(0);
  const [contentGap, setContentGap]   = useState(0);
  const [
    contentChildrenHeight, 
    setContentChildrenHeight
  ] = useState(0);

  const [
    minHeightCalculated, 
    setMinHeightCalculated
  ] = useState(0);


  /**
   * Grid gap
  */
  useEffect(() => {
    if (gridRef.current === null) {
      return;
    }

    const gridStyle = window.getComputedStyle(gridRef.current);
    const gapTmp = parseInt(gridStyle.gap);
    const gap = (isNaN(gapTmp) ? 0 : gapTmp);
    
    if (gap !== gridGap) {
      logger.debug(`Set grid gap: ${gap}`)
      setGridGap(gap);
    }
  }, [gridRef]);


  /**
   * Title height
   */
  useEffect(() => {
    if (titleRef.current === null) {
      return;
    }

    const titleNode = titleRef.current;
    const height = titleNode.offsetHeight;
    
    if (height !== titleHeight) {
      logger.debug(`Set title height: ${height}`)
      setTitleHeight(height);
    }
  }, [titleRef]);


  /**
   * Content gap
   */
  useEffect(() => {
    if (contentRef.current === null) {
      return;
    }

    const contentStyle = window.getComputedStyle(contentRef.current);
    const gapTmp = parseInt(contentStyle.gap);
    const gap = (isNaN(gapTmp) ? 0 : gapTmp);

    if (gap !== contentGap) {
      logger.debug(`Set content gap: ${gap} , ${contentStyle.gap}`)
      setContentGap(gap);
    }

    const childrenHeight = calcContentChildrenHeight();
    if (childrenHeight !== contentChildrenHeight) {
      logger.debug(`Set content children height: ${childrenHeight}`)
      setContentChildrenHeight(childrenHeight);
    }
  }, [contentRef]);



  /**
   * Observer changes in child list
   * and calculate children min-height property
   */
  useEffect(() => {
    const contentNode = contentRef.current;

    const handleMutations: MutationCallback = (mutationsList, observer) => {
      logger.debug("Mutations callback called");
      if (contentNode === null) {
        return;
      }

      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          const childrenHeight = calcContentChildrenHeight();
          if (childrenHeight !== contentChildrenHeight) {
            logger.debug(`Set content children height: ${contentChildrenHeight}`);
            setContentChildrenHeight(childrenHeight);
          }
        }
        // if (mutation.type === 'attributes') {
        //   console.log('Attributes changed.');
        // }
      });
    };

    const observer = new MutationObserver(handleMutations);
    
    const config = { 
      childList: true, 
      subtree: true 
      // attributes: true, 
    };

    if (contentNode) {
      observer.observe(contentNode, config);
    }

    return () => {
      observer.disconnect();
    };
  }, [contentRef]);


  /**
   * Calculation
   */
  useEffect(() => {
    const contentNode = contentRef.current;
    if (contentNode === null) {
      return;
    }

    const contentChildrenCount = contentNode.childNodes.length;
    const contentChildrenGap   = ((contentChildrenCount - 1) * contentGap);

    const contentHeight = (
      contentChildrenHeight +
      contentChildrenGap
    );

    /**
     * For every child its height can be for example
     * 68.4 - but it will get reported as 68 - therefore
     * lets add 1 pixel of height for every child and 
     * for title height. Flex gaps seems to be stable
     * (free for rounding) - so we do 
     */
    const roundingDelta = contentChildrenCount + 1 /* one for title */;

    const heightTotal = (
      contentHeight +
      titleHeight +
      gridGap +
      roundingDelta
    );

    logger.debug(
      `Grid gap: ${gridGap}, ` +
      `title height: ${titleHeight}, ` +
      `content children height: ${contentChildrenHeight}, ` +
      `content children gap: ${contentChildrenGap}, ` +
      `minHeightCalculated: ${minHeightCalculated}`
    );

    if (heightTotal !== minHeightCalculated) {
      logger.debug(`Setting min height ${heightTotal}`);
      setMinHeightCalculated(heightTotal);
    }
  }, [
    gridGap,
    titleHeight, 
    contentChildrenHeight,
    contentGap, 
  ]);


  const calcContentChildrenHeight = () => {
    const contentNode = contentRef.current;
    if (contentNode === null) {
      return 0;
    }

    let contentChildrenHeight = 0;
      contentNode.childNodes.forEach((node, idx) => {
        if (node instanceof HTMLElement) {

          const computedStyle = window.getComputedStyle(node);
          const computedMinHeight = computedStyle.getPropertyValue('min-height');
          let nodeMinHeight = 0;

          if (computedMinHeight === 'auto') {
            nodeMinHeight = node.offsetHeight;
          }
          else if (computedMinHeight.slice(-2) === "px") {
            nodeMinHeight = +computedMinHeight.slice(0, -2);
          }
          else {
            const msg = `Invalid compute style, min-height: ${computedMinHeight}`;
            throw new Error(msg);
          }

          logger.debug(`Content children (idx: ${idx}), min-height: ${nodeMinHeight}`)
          contentChildrenHeight += nodeMinHeight;
        }
      });

    return contentChildrenHeight;
  }


  return minHeightCalculated;
}

export default useMinHeightCalc;