import { MenuConfig } from 'lego-v2/menu/config';
import { Position }   from 'app/arch/types';


type MenuConfigNullable = MenuConfig | null;


interface ElementProps {
  setConfig:   (config: MenuConfigNullable) => void;
  setPosition: (position: Position) => void;
}

let setupProps: ElementProps | null = null;

export const useSetupContextMenuHook = () => {
  const hook = (props: ElementProps) => {
    setupProps = props;
  }

  return hook;
}

//
//
//

export const useContextMenu = () => {

  const showMenu = (props: {
    config: MenuConfig;
    event: React.MouseEvent
  }) => {
    if (! setupProps) {
      console.warn(`Setup for context menu hook has not been done`);
      console.warn(`Did you forget to include <ContextMenuComponent /> in dom?`);
      return;
    }
    
    const {
      event, 
      config
    } = props;

    event.preventDefault();
    event.stopPropagation();

    const mouseX = event.pageX;
    const mouseY = event.pageY;

    const position = [mouseX, mouseY] as Position;

    setupProps.setConfig(config);
    setupProps.setPosition(position);
  }

  const hideMenu = () => {
    setupProps?.setConfig(null);
  }

  return {
    showMenu,
    hideMenu,
  };
}


export default useContextMenu;
