import React from 'react';
import environment from 'app/environment';
import useGoogleAnalytics from 'app/ui-v2/app/hooks/use-google-analytics';


interface Props {
}


export const LoaderGoogleAnalyticsComponent: React.FC<Props> = (props: Props) => {
  const {
  } = props;

  useGoogleAnalytics({
    trackingId: environment.google.analytics.tag,
  });


  return null;
}
  
