import { LexicalComposer  } from "@lexical/react/LexicalComposer";
import { LexicalTools } from "app/arch/tools";


interface Props {
  editable: boolean;
  children: React.ReactNode;
};


export const EditorTextContext: React.FC<Props> = (props: Props) => {
  const {
    editable,
    children,
  } = props;

  const lecialConfig = LexicalTools.getLexicalConfig({
    editable
  });

  return (
    <LexicalComposer initialConfig={lecialConfig}>
      { children }
    </LexicalComposer>
  );
}
