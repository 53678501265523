import React      from 'react';
import { useRef } from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import WidgetEditorTextEditComponent from '../../../parts/widget-editor-text-edit';
import WidgetPartSelectComponent from '../../../parts/widget-part-select';
import WidgetContextMenuComponent from '../../../parts/widget-context-menu';
import WidgetEditorTextComponent from '../../../parts/widget-editor-text';
import WidgetEditorTextMarginsComponent from '../../../parts/widget-editor-text-margins';
import WidgetEditorTextEditFinishedComponent from '../../../parts/widget-editor-text-edit-finished';

import { DataTestWrapper } from './styles';


interface Props {
  dataTest: string | null;
  widgetAddr: ContentTypes.WidgetAddr;
  editDisabled: boolean;
  EditorTextPlugins: React.ReactNode[];
}


export const WidgetArrowTextEditorTextComponent: React.FC<Props> = (props: Props) => {
  const {
    dataTest,
    widgetAddr,
    editDisabled,
    EditorTextPlugins
  } = props;

  const editorTextRef = useRef<HTMLDivElement | null>(null);
  const dataTestTextEditor = (
    `${dataTest}`
    + `__textEditor`
  );

  return (
    <DataTestWrapper data-test={dataTestTextEditor}>
      <WidgetContextMenuComponent
        widgetAddr={widgetAddr}
        editDisabled={editDisabled}
      >
        <WidgetPartSelectComponent
          widgetAddr={widgetAddr}
          widgetPart={ContentTypes.WidgetPart.ARROW_TAIL}
          editDisabled={editDisabled}
        >
          <WidgetEditorTextEditComponent
            widgetAddr={widgetAddr}
            editDisabled={editDisabled}
            editorTextRef={editorTextRef}
          >
            <WidgetEditorTextEditFinishedComponent
              widgetAddr={widgetAddr}
            >
              <WidgetEditorTextMarginsComponent
                widgetAddr={widgetAddr}
                editDisabled={editDisabled}
              >
                <WidgetEditorTextComponent 
                  widgetAddr={widgetAddr}
                  editDisabled={editDisabled}
                  editorTextRef={editorTextRef}
                  EditorTextPlugins={EditorTextPlugins}
                />
              </WidgetEditorTextMarginsComponent>
            </WidgetEditorTextEditFinishedComponent>
          </WidgetEditorTextEditComponent>
        </WidgetPartSelectComponent>
      </WidgetContextMenuComponent>
    </DataTestWrapper>
  );
}
