import React from 'react';
import WidgetInfoComponent from './widget-info';

import { MainWrapper   } from './styles';


interface Props {
};


export const MenuBottomComponent: React.FC<Props> = (props: Props) => {

  return (
    <MainWrapper>
      <WidgetInfoComponent/>
    </MainWrapper>
  );
};
