import React from 'react';
import MarkersComponent from './markers';


interface Props {
}


export const HeaderMarkersComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;


  return (
    <MarkersComponent />
  );
}

