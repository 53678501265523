import jtl from "tools/jtl";


import { ShapeLayerType, ShapeProps } from "../../types";

import { Positioner } from "./styles";
import { Center } from "./styles";
import { Clip } from "./styles";
import { Shape } from "./styles";

interface Props extends ShapeProps {
  points: string[][];

  width: number;
  height: number;
}


export const ShapeBaseComponent: React.FC<Props> = (props: Props) => {
  const {
    startPoint, 
    endPoint,
    layer,
    style,
    points,
    width,
    height,
  } = props;

  const arrowAngle = jtl.geometry.calculateAngle(...startPoint, ...endPoint);
  const left = startPoint[0];
  const top  = startPoint[1];

  const colors = {
    [ShapeLayerType.BOTTOM]: style.outlineColor,
    [ShapeLayerType.MIDDLE]: style.borderColor,
    [ShapeLayerType.TOP]:    style.backgroundColor,
  }

  const getIdx = () => {
    switch(layer) {
      case ShapeLayerType.BOTTOM: return 0;
      case ShapeLayerType.MIDDLE: return 1;
      case ShapeLayerType.TOP:    return 2;
    }
  }

  return (
    <Positioner
      style={{
        left: `${left}px`,
        top:  `${top}px`,
        transform: `rotate(${arrowAngle}rad)`,
        transformOrigin: `0px 0px`,
      }}
    >
      <Center
        style={{
          left: `${-1 * width / 2}px`,
          top:  `${0}px`,
        }}
      >
        <Clip 
          style={{
            width: `${width}px`,
            height: `${height}px`,
            // background: `rgba(255, 255, 0, 0.2)`,
          }}
        >
          <Shape
            style={{
              width: `${width}px`,
              height: `${height}px`,
              backgroundColor: colors[layer],
              clipPath: `polygon(${points[getIdx()].map(point => point).join(', ')})`
            }}
          /> 
        </Clip>
      </Center>
    </Positioner>             
  );
}
