import styled from "styled-components";


export const ItemSelecteable = styled.div<{selected?: boolean}>`
  box-sizing: border-box;
  overflow: ${props => props.selected ? "clip" : "visible"};

  cursor: pointer;
  background-color: white;

  border-style:  ${props => props.theme.itemSelected.border.style};
  border-width:  ${props => props.theme.itemSelected.border.width};
  border-radius: ${props => props.theme.itemSelected.border.radius};
  border-color:  ${props => props.selected ? props.theme.itemSelected.border.color : 'white'};

  &:hover {
    border-color: ${props => props.theme.defs.accentColor.primary};
    overflow: clip;
  }

  &:active:not(:disabled) {
    background: ${props => props.theme.defs.accentColor.primary};
  }

  transition: 
    all
    ${props => props.theme.defs.transition.time.quick} 
    ease-in-out;
`;


export default ItemSelecteable;