import React from 'react';

import HomePageUserLayoutComponent       from './components/home-page-user-layout';
import HomePageUserViewSelectedComponent from './components/home-page-user-view-selected';


interface Props {
}


export const HomePageUserComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <HomePageUserLayoutComponent>
      <HomePageUserViewSelectedComponent />
    </HomePageUserLayoutComponent>
  );
}
