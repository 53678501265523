import * as Defaults from './defaults';
import * as Tools from './tools';
import * as Types from './types';


/**
 * State
 */
export type State = Types.Toasts;


/**
 * Toasts
 */
export const getToastsAddrs = (
  state: State
): Types.ToastsAddrs => { 
  const toasts = __getToasts(state);
  return toasts.addrs;
}

export const getToastsProps = (
  state: State
): Types.ToastsProps => { 
  const toasts = __getToasts(state);
  return toasts.props;
}

const __getToasts = (
  state: State
): Types.Toasts => { 
  return state;
}


/**
 * Toast
 */
export const getToastProps = (
  state: State,
  toastAddr: Types.ToastAddr,
): Types.ToastProps => { 
  const toastsProps = getToastsProps(state);
  const toastKey    = Tools.getToastKey(toastAddr);
  
  const toastProps = toastsProps[toastKey];
  if (toastProps === undefined) {
    const msg = `Toast not found`;
    throw new Error(msg);
  }

  return toastProps;
}

export const getToastIdx = (
  state: State, 
  toastAddr: Types.ToastAddr,
): number => {
  const toastsAddrs = getToastsAddrs(state);

  const idx = toastsAddrs.findIndex((toastAddr_) => Tools.compareToastAddr(toastAddr_, toastAddr));
  if (idx === -1) {
    const msg = "Toast not found";
    throw new Error(msg);
  }

  return idx;
}


/**
 * Initial state
 */
export const createInitialState = (): State => {
  const initState: State = Defaults.getToasts();
  return initState;
}
