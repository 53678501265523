import produce from 'immer';
import { RepoMarkersTypes } from '../../persistent/repo-markers';

import * as State from './state';
import * as Types from './types';


export class EditorMarkerSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */

  updateWindow(update: Types.WindowUpdate) {
    this._state = produce(this._state, draft => {
      const window = State.getWindow(draft);
      Object.assign(window, update);
    });
  }

  setSelectedMarkerAddr(
    markerAddr: RepoMarkersTypes.MarkerAddr | null
  ) {
    this._state = produce(this._state, draft =>{
      draft.editor.selectedMarkerAddr = markerAddr;
    });
  }

  updateEditor(update: Types.EditorUpdate) {
    this._state = produce(this._state, draft => {
      const editor = State.getEditor(draft);
      Object.assign(editor, update);
    });
  }


  /**
   * Getters
   */
  
  getWindow() {
    return State.getWindow(this._state);
  }
  
  getSelectedMarkerAddr() {
    return State.getSelectedMarkerAddr(this._state);
  }
}
