import React from 'react';
import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';
import usePageContentSize from 'app/ui-v2/editor-instruction/views/shared/hooks/page/use-page-content-size';
import useDocumentScaleRef from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-ref';
import SectionNameViewComponent from '../../section-name-view';
import SecitionNameCSSComponent from '../../secition-name-css';

import { MainWrapper } from './styles';


interface Props {
  sectionAddr: TypesContent.SectionAddr;
}

export const SectionNameDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const {
    sectionAddr,
  } = props;

  const contentSize = usePageContentSize();
  const scaleRef = useDocumentScaleRef();
  const scale = scaleRef.current || 1;

  return (
    <MainWrapper 
      scale={scale}
      width={contentSize[0]}
    >
      <SecitionNameCSSComponent sectionAddr={sectionAddr} >
        <SectionNameViewComponent sectionAddr={sectionAddr}/>
      </SecitionNameCSSComponent>
    </MainWrapper>
  );
}
  
