import styled from "styled-components";


interface Props {
  $selected: boolean;
}


const ItemPanel = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};

  box-sizing: border-box;

  border-radius: ${props => props.theme.defs.border.radius.normal};
  border-width:  ${props => props.theme.defs.border.width.small};

  border-color: ${props => 
    props.$selected ? 
    props.theme.defs.accentColor.primary : 
    "transparent"
  };

  border-style: ${props => 
    props.$selected ? 
    "solid" :
    "none"
  };

  padding: ${props => 
    props.$selected ? 
    props.theme.defs.padding.medium :
    "0px"
  };

  margin:  ${props => 
    props.$selected ? 
    props.theme.defs.padding.medium :
    "0px"
  };
  margin-left: 0px;
  margin-right: 0px;
`;


export default ItemPanel;