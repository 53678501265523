import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig }   from 'lego-v2/menu/config';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { ContentSessionTypes as TypesSession } from 'app/arch/editor-instruction/document/states/sessions/content-session';

import { Icon }            from "app/ui/icons/icons";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import { useDocState }     from 'app/ui/contexts/document';
import useContextMenu      from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useContextElement   from 'app/ui-v2/editor-instruction/hooks/use-context-element';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSidetoolbarPropertiesShow from '../../../use-sidetoolbar-properties-show';
import useSectionSelectedSet        from '../use-section-selected-set';
import SectionCreatePanelComponent  from './components/section-create-panel';
import { SectionCreatePanelProps }  from './components/section-create-panel';
import { SectionAddPlacement }      from './components/section-create-panel';
import useSectionDelete             from '../use-section-delete';


const useSectionMenu = () => {
  const t = useTranslations();
  const document = useDocState();

  const {
    setContent
  } = useEditorStatesSetters();

  const {
    hideMenu
  } = useContextMenu();

  const {
    showElement,
  }  = useContextElement();

  const deleteSectionHook = useSectionDelete();
  const setSectionSelected = useSectionSelectedSet();
  const showSideToolbarProps = useSidetoolbarPropertiesShow();

  const addSectionAbove = (sectionAddr: Types.SectionAddr, event: React.PointerEvent) => {
    const newSectionAddr = document.content.addSectionAbove(sectionAddr);
    document.saveUndo();
    setContent();

    setSectionSelected({sectionAddr: newSectionAddr});
    hideMenu();

    // __addSection({
    //   srcSectionAddr: sectionAddr,
    //   sectionAddPlacement: SectionAddPlacement.ABOVE_SRC_SECTION,
    // }, event);
  }

  const addSectionBelow = (sectionAddr: Types.SectionAddr, event: React.PointerEvent) => {
    const newSectionAddr = document.content.addSectionBelow(sectionAddr);
    document.saveUndo();
    setContent();

    setSectionSelected({sectionAddr: newSectionAddr});
    hideMenu();

    // __addSection({
    //   srcSectionAddr: sectionAddr,
    //   sectionAddPlacement: SectionAddPlacement.BELOW_SRC_SECTION,
    // }, event);
  }

  const __addSection = (
    panelProps: Omit<SectionCreatePanelProps, "onDone">,
    event: React.PointerEvent,
  ) => {
    const onDone = (newSectionAddr: Types.SectionAddr) => {
      setSectionSelected({sectionAddr: newSectionAddr});
    }

    showElement({
      Element: SectionCreatePanelComponent, 
      ElementProps: {
        ...panelProps,
        onDone,
      },
      event
    });

    hideMenu();
  }

  const deleteSection = (sectionAddr: Types.SectionAddr) => {
    deleteSectionHook(sectionAddr);
    hideMenu();
  }
  
  const showSectionProperties = (
    sectionAddr: Types.SectionAddr, 
    event: React.PointerEvent
  ) => {
    setSectionSelected({sectionAddr});
    showSideToolbarProps(TypesSession.PanelPropertiesItem.SECTIONS);
    hideMenu();
  }
  
  const getConfig = (sectionAddr: Types.SectionAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('section, add above'),
                iconComponent: Icon.Create,
                onClick: (event: React.PointerEvent) => addSectionAbove(sectionAddr, event),
              }
            },
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('section, add below'),
                iconComponent: Icon.Create,
                onClick: (event: React.PointerEvent) => addSectionBelow(sectionAddr, event),
              }
            },
          ],
        }, 
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('section, properties'),
                iconComponent: Icon.Settings,
                onClick: (event: React.PointerEvent) => showSectionProperties(sectionAddr, event),
              }
            },                
          ],
        },    
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('delete'),
                variant: 'delete',
                iconComponent: Icon.Delete,
                onClick: () =>  deleteSection(sectionAddr),
              }
            },          
          ],
        },
      ]
    }

    return config;
  }


  const hook = (sectionAddr: Types.SectionAddr) => {
    const config = getConfig(sectionAddr);
    return config;
  }

  return hook;
}


export default useSectionMenu;