import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { ExporterAssetsRepo } from 'app/arch/editor-instruction/exporters/excel-exporter';

import { configureHook as confExcelExporter } from '../hooks/use-excel-export';
import { configureHook as confWinwordExporter } from '../hooks/use-winword-export';

import * as Types from '../types';

import ExcelExportContextComponent from './components/excel-export-context';
import ContentComponent from './components/content';
import HeaderMarkersComponent from './components/header-markers';
import HeaderInfoComponent from './components/header-info';


interface Props {
}


export const ExportRenderComponent: React.FC<Props> = (props: Props) => {
  const {
  } = props;

  const [visible, setVisible] = useState(false);
  const assetsRepoRef = useRef<ExporterAssetsRepo | null>(null);
  const onDoneRef = useRef<Types.OnDoneFn | null>(null);


  useEffect(() => {
    if ( ! visible ) {
      return;
    }

    if ( ! onDoneRef.current ) {
      const msg = `OnDone funcition is null`;
      throw new Error(msg);
    }

    const onDone = onDoneRef.current;
    onDone();
    onDoneRef.current = null;
  }, [visible]);


  useEffect(() => {
    const hooksSetups = [
      confExcelExporter,
      confWinwordExporter,
    ];

    hooksSetups.forEach(hookSetup => {
      hookSetup({
        setAssetsRepo: (repo: ExporterAssetsRepo) => {
          assetsRepoRef.current = repo;
        },
        showImagesRender: (onDone: Types.OnDoneFn) => { 
          if (onDoneRef.current) {
            const msg = `OnDone funcition should not be null`;
            throw new Error(msg);
          }
          onDoneRef.current = onDone;
  
          setVisible(true); 
        },
        hideImagesRender: () => { 
          setVisible(false); 
        },
      });
    })
  });

  if ( ! visible ) {
    return null;
  }

  if ( assetsRepoRef.current === null) {
    return null;
  }

  return (
    <ExcelExportContextComponent assetsRepo={assetsRepoRef.current}>
      <HeaderInfoComponent />
      <HeaderMarkersComponent />
      <ContentComponent />
    </ExcelExportContextComponent>
  );
}
