import * as Defaults from './defaults';
import * as Types from "./types"


/**
 * State
 */
export type State = {
  reposTemplates: Types.ReposTemplates
};


/**
 * Getters
 */
export const getTemplates = (
  state: State
): Types.ReposTemplates => {
  return state.reposTemplates;
}

export const getTemplate = (
  state: State, 
  repoId: Types.RepoId
): Types.TemplateContent => {
  const templates = getTemplates(state);
  const repoTemplate = templates[repoId];
  
  if (repoTemplate === undefined) {
    const msg = `Can't find repo document template`;
    throw new Error(msg);
  }
  
  return repoTemplate;
}

export const hasTemplate = (
  state: State, 
  repoId: Types.RepoId
): boolean => {
  const templates = getTemplates(state);
  const repoTemplate = templates[repoId];
  return (repoTemplate !== undefined);
}

/**
 * Create Init State
 */
export const createInitialState = (): State => {
  const initState: State = {
    reposTemplates: {},
  }

  return initState;
}
