import React from 'react';
import TooltipComponent from 'lego/components/tooltip';

import { MainWrapper } from './styles';
import { Button }      from './styles';


interface Props {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}


export const BuyButtonComponent: React.FC<Props> = (props: Props) => {
  const {
    label,
    onClick,
    disabled,
  } = props;

  return (
    <MainWrapper>
      <TooltipComponent 
        title={label}
        disabled={disabled}
      >
        <Button 
          disabled={disabled}
          onClick={onClick}>
          { label }
        </Button>
      </TooltipComponent>
    </MainWrapper>
  );
}

