import styled from "styled-components";
import TextBase from 'lego/styles/text';
import { TextWrapper as TextWrapperBase } from "../control-text/styles";


export const TextWrapper = styled(TextWrapperBase)`
  height: auto;
`;


export const Text = styled(TextBase)`
  overflow: visible;
  white-space: wrap;
  text-overflow: clip;
`;
