import { Size } from "app/arch/types";
import * as Types from './types';


export const convertImage = (
  image: Types.ImageRaw
): Types.Image => ({
  bid: +image.id,
  filename: image.filename,
  size: [image.width, image.height] as Size
});

