import styled from 'styled-components';
import ButtonTextBase from 'lego/styles/button_text';
import TextWrapBase from 'lego/styles/text-wrap';
import { Panel as PanelBase } from 'app-views/components/panel-titled/styles';
import { Box as BoxBase } from 'app/ui-v2/hls/progress-box/styles';


export const Grid = styled.div`
  display: grid;
  row-gap:    ${props => props.theme.defs.gap.normal};
  column-gap: ${props => props.theme.defs.gap.large};

  grid-template-columns: 70px 1fr;
  grid-template-rows: auto auto auto; 
 
  grid-template-areas:
    "email_title  email_value"
    "access_title access_value"
    "blank        send_email"; 
`;


/**
 * Grid Cells
 */
const CellTitle = styled.div<{$disabled: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  color:     ${props => props.theme.defs.colors.color.primary};
  font-size: ${props => props.theme.defs.font.size.large};

  user-select: none;

  opacity: ${props =>
    props.$disabled ?
    props.theme.defs.opacity.disabled :
    1  
  };
`;

const CellValue = styled.div`
  min-width: 0px;
`;


export const CellEmailTitle = styled(CellTitle)`
  grid-area: email_title;
`;

export const CellEmailValue = styled(CellValue)`
  grid-area: email_value; 
`;

export const CellAccessTitle = styled(CellTitle)`
  grid-area: access_title;
`;

export const CellAccessValue = styled(CellValue)`
  grid-area: access_value;
`;

export const CellSendBtn = styled(CellValue)`
  grid-area: send_email;
`;

/**
 * Cells end
 */


export const ButtonShowWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonShowPanel = styled(ButtonTextBase)`
`;

export const TextWrap = styled(TextWrapBase)`
`;

export const ButtonSendInvite = styled(ButtonTextBase)`
  width: 100%;
  max-width: unset;
`;

export const Panel = styled(PanelBase)`
  position: relative;
`;

export const ProgressWrapper = styled.div`
  position: absolute;
  z-index: 1;

  top: 50%;
  right: 50%;
  translate: 50% -50%;
`;


export const ProgressBox = styled(BoxBase)`
  width: 200px;
`;
