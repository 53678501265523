import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import jtl from 'tools/jtl';

import { FrameHandlerBase }  from 'lego-v2/frame-resize/arch';
import { FrameTargetUpdate } from 'lego-v2/frame-resize/arch';

import { Size } from 'app/arch/types';
import { Position } from 'app/arch/types';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { UIState_Content } from 'app/ui/states/editor-instruction';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';

import { WidgetPropsBase } from '../../types';

import WidgetSelectComponent        from '../parts/widget-select';
import WidgetContextMenuComponent   from '../parts/widget-context-menu';
import WidgetClickCancelerComponent from '../parts/widget-click-canceler';
import WidgetBoxedViewComponent     from './widget-boxed-view';
import WidgetBoxedFrameComponent    from './widget-boxed-frame';
import WidgetBoxedDraggerComponent  from './widget-boxed-dragger';

import { WidgetWrapper } from './styles';
import { WidgetAnchor }  from './styles';



export interface WidgetBoxedPropsBase extends WidgetPropsBase {
  onFrameUpdate?: (update: FrameTargetUpdate) => void;
  onFrameUpdateDone?: (update: FrameTargetUpdate, updated: boolean) => void;
  frameHandlerFactory?: (
    scale: number,
    startPosition: Position, 
    startSize: Size, 
    minSize: Size,
  ) => FrameHandlerBase;

  children?: React.ReactNode;
}


export const WidgetBoxedBaseComponent: React.FC<WidgetBoxedPropsBase> = (props: WidgetBoxedPropsBase) => {
  const { 
    dataTest,

    scale,
    widgetAddr,

    frameHandlerFactory,
    onFrameUpdate,
    onFrameUpdateDone,

    children, 
  } = props;

  const document = useDocState();

  const editDisabled = (
    props.editDisabled !== undefined ?
    props.editDisabled :
    false
  );

  const {
    setContent,
  } = useEditorStatesSetters();


  // Does it have to be recoil?
  // TODO this seems to be absurd
  // I need to listen only on
  // Widget.style
  // widget.data.position
  // widget.data.size
  const widgetProps_ = useRecoilValue(UIState_Content.cellImages_image_widgetProps(widgetAddr));
  const edited       = useRecoilValue(UIState_EditorImageSession.isWidgetEdited(widgetAddr));
  const selected     = useRecoilValue(UIState_EditorImageSession.isWidgetSelected(widgetAddr));

  const widgetProps = widgetProps_ as ContentTypes.WidgetBoxedProps ;

  const [sizeLocal, setSizeLocal] = useState<Size>(widgetProps.size);
  const [positionLocal, setPositionLocal] = useState<Position>(widgetProps.position);
  const [isDragged, setIsDragged] = useState(false);
  const wrapperId  = ContentTools.getWidgetHTMLId(widgetAddr);

  useEffect(() => {
    setSizeLocal(widgetProps.size);
    setPositionLocal(widgetProps.position);
  }, [widgetProps.size, widgetProps.position]);


  /**
   * Calculate position and size
   */
  const borderWidth  = jtl.css.valueToNumber(widgetProps.style.borderWidth);

  const left   = positionLocal[0] - borderWidth;
  const top    = positionLocal[1] - borderWidth;
  const width  = sizeLocal[0] + 2 * borderWidth;
  const height = sizeLocal[1] + 2 * borderWidth;

  const actionDisabled = (editDisabled || edited);

  const showFrame = (
    selected &&
    ! actionDisabled &&
    ! isDragged
  );


  /**
  * 
  * Global (recoil) states setters
  */
  const setSizeGlobal = (size: Size) => {
    document.content.cellImages_image_widgetBoxed_setSize(
      widgetAddr,
      size
    );

    setContent();
  }

  const setPositionGlobal = (position: Position) => {
    document.content.cellImages_image_widget_setPosition(
      widgetAddr,
      position
    );
    setContent();
  }


  return (
    <>
      <WidgetWrapper
        id={wrapperId}
        data-test={dataTest}
        $left={left}
        $top={top}
        $width={width}
        $height={height}
      >
        <WidgetClickCancelerComponent
          editDisabled={editDisabled}
        > 
          <WidgetBoxedDraggerComponent
            scale={scale}
            disabled={actionDisabled}
            widgetAddr={widgetAddr}

            position={positionLocal}
            onPositionUpdate={setPositionLocal}
            onPositionUpdateDone={setPositionGlobal}

            onDraggingStart={() => setIsDragged(true)}
            onDraggingStop={() => setIsDragged(false)}
          >
            <WidgetContextMenuComponent
              widgetAddr={widgetAddr}
              editDisabled={editDisabled}
            >
              <WidgetSelectComponent
                widgetAddr={widgetAddr}
                editDisabled={editDisabled}
              >
                <WidgetBoxedViewComponent widgetAddr={widgetAddr}>
                  <WidgetAnchor>
                    { children }
                  </WidgetAnchor>
                </WidgetBoxedViewComponent> 
              </WidgetSelectComponent>
            </WidgetContextMenuComponent>
          </WidgetBoxedDraggerComponent>
        </WidgetClickCancelerComponent>
      </WidgetWrapper>

      { 
        showFrame && 
        <WidgetBoxedFrameComponent 
          dataTest={dataTest}

          scale={scale}
          widgetAddr={widgetAddr}

          left={left}
          top={top}
          width={width}
          height={height}

          onFrameUpdate={onFrameUpdate}
          onFrameUpdateDone={onFrameUpdateDone}
          frameHandlerFactory={frameHandlerFactory}

          position={positionLocal}
          onPositionUpdate={setPositionLocal}
          onPositionUpdateDone={setPositionGlobal}

          size={sizeLocal}
          onSizeUpdate={setSizeLocal}
          onSizeUpdateDone={setSizeGlobal}
        />
      } 
    </>
  );
}

