import produce from 'immer';
import * as State from './state';


export abstract class ItemsSelectedBase<T> {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }
  
  protected abstract getKey(itemAddr: T): string;

  /**
   * Rows
   */

  select(itemAddr: T) {
    this._state = produce(this._state, draft => {
      const selected = draft.selected;
      const itemKey = this.getKey(itemAddr);

      if (itemKey in selected) {
        if (selected[itemKey]) {
          const msg = `Item already selected`;
          throw new Error(msg);
        }
      }

      selected[itemKey] = true;
    });
  }

  unselect(itemAddr: T) {
    this._state = produce(this._state, draft => {
      const selected = draft.selected;
      const itemKey = this.getKey(itemAddr);

      if ( ! (itemKey in selected)) {
        const msg = `Item is not selected`;
        throw new Error(msg);
      }

      if (itemKey in selected) {
        if ( ! selected[itemKey]) {
          const msg = `Item is not selected`;
          throw new Error(msg);
        }
      }

      delete selected[itemKey];
    });
  }

  toggleSelect(itemAddr: T) {
    this._state = produce(this._state, draft => {
      const selected = draft.selected;
      const itemKey = this.getKey(itemAddr);

      if (itemKey in selected) {
        if ( selected[itemKey] ) {
          delete selected[itemKey];
        }
        else {
          selected[itemKey] = ! selected[itemKey];
        }
      }
      else {
        selected[itemKey] = true;
      }
    });
  }

  reset(): void {
    this._state = produce(this._state, draft => {
      const selected = draft.selected;
      const keys = Object.keys(selected);

      for (const key of keys) {
        delete selected[key];
      }

      draft.dragOver = null;
    });
  }

  setDragOver(itemAddr: T | null) {
    this._state = produce(this._state, draft => {
      if ( itemAddr === null ) {
        draft.dragOver = null;
        return;
      }
      const itemKey = this.getKey(itemAddr);
      draft.dragOver = itemKey;
    });
  }



  /**
   * Getters
   */



  /**
   * Row
   */

  getSelected() {
    return State.getSelected(this._state);
  }

  getSelectedOrdered() {
    return State.getSelectedOrdered(this._state);
  }

  isSelected(itemAddr: T): boolean {
    const itemKey = this.getKey(itemAddr);
    return State.isSelected(this._state, itemKey);
  }
  
  getDragOver() {
    return State.getDragOver(this._state);
  }

  isDragOver(itemAddr: T): boolean {
    const itemKey = this.getKey(itemAddr);
    return State.isDragOver(this._state, itemKey);
  }
}
