import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { 
  $getSelection, 
  $isRangeSelection, 
  COMMAND_PRIORITY_EDITOR, 
  ElementNode, 
  LexicalCommand, 
  LexicalNode, 
  createCommand 
} from "lexical";
import { $findMatchingParent, $getNearestBlockElementAncestorOrThrow, mergeRegister } from "@lexical/utils";
import { $isListNode, ListItemNode, ListNode } from '@lexical/list';
import { $isListItemNode } from '@lexical/list';


export const SMART_INDENT_COMMAND: LexicalCommand<string> = createCommand(
  "SMART_INDENT_COMMAND"
);

export const SMART_OUTDENT_COMMAND: LexicalCommand<string> = createCommand(
  "SMART_OUTDENT_COMMAND"
);

export function SmartIdentPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeListener =  mergeRegister( 
      editor.registerCommand<string>(
        SMART_INDENT_COMMAND,
        (payload) => {
          return handleIndentAndOutdent((block) => {
            const indent = block.getIndent();
            block.setIndent(indent + 1);
          });
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<string>(
        SMART_OUTDENT_COMMAND,
        (payload) => {
          return handleIndentAndOutdent((block) => {
            const indent = block.getIndent();
            block.setIndent(indent - 1);
          });
        },
        COMMAND_PRIORITY_EDITOR
      )
    );

    return removeListener;
  }, [editor]);

  return null;
}


// This has been copied from lexical
function handleIndentAndOutdent(
  indentOrOutdent: (block: ElementNode) => void,
): boolean {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return false;
  }
  const alreadyHandled = new Set();
  const nodes = selection.getNodes();
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const key = node.getKey();
    if (alreadyHandled.has(key)) {
      continue;
    }
    
    const parentBlock = $getNearestBlockElementAncestorOrThrow(node);
    const parentKey = parentBlock.getKey();

    if (parentBlock.canIndent() && !alreadyHandled.has(parentKey)) {
      alreadyHandled.add(parentKey);

      if ($isListItemNode(parentBlock)) {
        const listNode = getListNode(parentBlock);
        if (listNode !== null) {
          indentOrOutdent(listNode);
        }
      }
      else {
        indentOrOutdent(parentBlock);
      }
    }
  }
  return alreadyHandled.size > 0;
}

function getListNode(listItem: ListItemNode) {
  const listNode = $findMatchingParent(
    listItem, 
    (node: LexicalNode) => $isListNode(node)
  ) as ListNode | null;

  return listNode;
}