import React from 'react';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import { HeaderMarkersTools } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { useDocState } from 'app/ui/contexts/document';

import MarkerComponent from './marker';


interface Props {
}



export const MarkersComponent: React.FC<Props> = (props: Props) => {
  const {
  } = props;

  const docState = useDocState();
  const headerMarkers = docState.headerMarkers;

  const markersAddrs = headerMarkers.getMarkersAddrs();

  const renderMarkers = () => {
    const Markers = markersAddrs.map((markerAddr) => renderMarker(markerAddr));

    return Markers;
  }

  const renderMarker = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    const markerKey = HeaderMarkersTools.getMarkerKey(markerAddr);

    return (
      <React.Fragment key={markerKey}>
        <MarkerComponent markerAddr={markerAddr} />
      </React.Fragment>
    );
  }

  return renderMarkers();
}

