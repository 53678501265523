import styled from 'styled-components';


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: ${props => props.theme.defs.gap.xxlarge};
  padding: ${props => props.theme.defs.padding.xxlarge};
`;
