import styled from "styled-components";
import Panel  from "./panel";


export const PanelForth = styled(Panel)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};
`;


export default PanelForth;