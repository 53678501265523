import i18next from 'i18next';
import * as TypesShared from "../../types";
import * as Types from './types';


export const getHeaderMeta = (): Types.HeaderMeta => ({
  visible: TypesShared.PageItemVisibility.ALL
});


export const getHeaderFields = (): Types.HeaderFields => ({
  visible: TypesShared.PageItemVisibility.NONE
});


export const getHeaderTitleTitle = (): string => {
  const t = i18next.t;
  const title = t("changelog");
  return title;
}

export const getHeaderTitle = (): Types.HeaderTitle => ({
  title: getHeaderTitleTitle(),
  visible: TypesShared.PageItemVisibility.FIRST_PAGE,
  css: {
    padding: "12px",
    fontSize: "32px",
    fontWeight: "600",
    fontVariant: "none",
    justifyContent: "center",
  }
});


export const getHeader = (): Types.Header => ({
  meta: getHeaderMeta(),
  fields: getHeaderFields(),
  title: getHeaderTitle(),
});
