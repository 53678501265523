import { useEffect } from "react";
import { useRef }    from "react";
import { useState }  from "react";
import Logger from "libs/debug";
import { ScrollTester } from './styles';


interface Props {
  onWidthChange: (width: number) => void;
}

export const ProbeScrollbarWidthComponent = (props: Props) => {
  const {
    onWidthChange
  } = props;

  const [rerender, setRerender] = useState({});
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const scrollbarWidthRef = useRef<null | number>(null);

  const log = Logger.getProbes();

  useEffect(() => {
    if (scrollDivRef.current === null) {
      return;
    }
    
    const scrollDiv = scrollDivRef.current;
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    if (scrollbarWidth !== scrollbarWidthRef.current) {
      scrollbarWidthRef.current = scrollbarWidth;
      log.log(`Scrollbar width changed: ${scrollbarWidth}px`);

      onWidthChange(scrollbarWidth);
    }
  }, [rerender, scrollDivRef.current]);


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    setRerender({});
  };

  return (
    <ScrollTester ref={scrollDivRef}/>
  )
};
