import useProducts from "./use-products";
import { PricePlansTypes } from "app/arch/app/products/states/price-plans";


const usePriceGet = () => {
  const products = useProducts();
  const pricePlans = products.pricePlans;
  const prices = products.prices;

  const hook = (
    planType: PricePlansTypes.PlanType,
    billingPeriod: PricePlansTypes.BillingPeriod
  ): number => {
    const priceLookup = pricePlans.getBillingPriceLookup(planType, billingPeriod);
    const priceAmount = prices.getPriceAmount(priceLookup);
    return priceAmount;
  }

  return hook;
}

export default usePriceGet;