import React from 'react';

import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';

import PricePlanContextComponent  from './components/price-plan-context';
import PricePlanPanelComponent    from './components/price-plan-panel';
import PricePlanHeaderComponent   from './components/price-plan-header';
import PricePlanDescriptionComponent from './components/price-plan-description';
import PricePlanFeaturesComponent from './components/price-plan-features';
import PricePlanPriceComponent    from './components/price-plan-price';
import PricePlanSelectComponent   from './components/price-plan-select';
import PricePlanContentComponent  from './components/price-plan-content';


interface Props {
  planType: PricePlansTypes.PlanType
}


export const PricePlanComponent: React.FC<Props> = (props: Props) => {
  const {
    planType
  } = props;

  return (
    <PricePlanContextComponent planType={planType}>
      <PricePlanPanelComponent>
        <PricePlanHeaderComponent />
        <PricePlanContentComponent>
          <PricePlanDescriptionComponent />
          <PricePlanPriceComponent />
          <PricePlanSelectComponent />
          <PricePlanFeaturesComponent />
        </PricePlanContentComponent>
      </PricePlanPanelComponent>
    </PricePlanContextComponent>
  );
}

