import * as Types from './types';


/**
 * Invoice
 */

export const getInvoiceKey = (
  invoiceAddr: Types.InvoiceAddr
): string => (
  `${invoiceAddr.subscriptionId}`
  + `::${invoiceAddr.invoiceId}`
);

export const createInvoiceAddr = (
  subscriptionStripeId: string,
  invoiceStripeId: string,
): Types.InvoiceAddr => {
  const invoiceAddr = { 
    subscriptionId: subscriptionStripeId,
    invoiceId: invoiceStripeId 
  };

  return invoiceAddr;
}   

export const compareInvoiceAddr = (
  srcInvoiceAddr: Types.InvoiceAddr,
  dstInvoiceAddr: Types.InvoiceAddr,
): boolean => (
  srcInvoiceAddr.subscriptionId === dstInvoiceAddr.subscriptionId
  && srcInvoiceAddr.invoiceId === dstInvoiceAddr.invoiceId
);

