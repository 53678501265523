import { useDocState } from 'app/ui/contexts/document';
import useMarkerEdit   from 'app/ui-v2/editor-markers/hooks/use-marker-edit';
import useEditorStatesSetters from '../../../hooks/use-editor-states-setters';


export const useDocHeaderMarkerCreate = () => {
  const document = useDocState();
  
  const {
    setRepoMarkers,
    setHeaderMarkers
  } = useEditorStatesSetters();

  const editMarker = useMarkerEdit();

  const hook = () => {
    const markerAddr = document.repoMarkers.addMarker();
    document.headerMarkers.addMarker(markerAddr);
    document.saveUndo();
    
    setRepoMarkers();
    setHeaderMarkers();

    editMarker(markerAddr);
  }

  return hook;
}


export default useDocHeaderMarkerCreate;