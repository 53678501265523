import React from 'react';

import { ViewTypes } from 'app/arch/home-page-guest/states/view';
import { NavbarTypes as NavbarStateTypes } from "app/arch/home-page-guest/states/navbar";
import { NavbarTypes } from 'app/ui-v2/home-page/hls/navbar2';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import useStates from './use-states';
import useFunctions from './use-functions';


const useConfigMenu = () => {
  const t = useTranslations();
  const states = useStates();
  const fns = useFunctions();

  const getConfig = () => {

    const config: NavbarTypes.Config = {
      items: [
        
        /**
         * Workspace
         */

        {
          text: t("homepage guest, topmenu, demo"),
          dataTest: 'homepage-guest__topmenu__demo',
          selected: states.viewSelected === ViewTypes.ViewItem.DEMO,

          onClick: (event: React.MouseEvent | React.TouchEvent) => {
            fns.handleSelect({
              view: ViewTypes.ViewItem.DEMO,
              navbarItem: NavbarStateTypes.NavbarItem.DEMO,
            }); 
          }
        },
  

        /**
         * Pricing
         */

        {
          text: t("homepage guest, topmenu, pricing"),
          dataTest: 'homepage-guest__topmenu__pricing',
          selected: states.viewSelected === ViewTypes.ViewItem.PRICING,
          onClick: (event: React.MouseEvent | React.TouchEvent) => {
            fns.handleSelect({
              view: ViewTypes.ViewItem.PRICING,
              navbarItem: NavbarStateTypes.NavbarItem.PRICING,
            }); 
          }
        },
      ]
    }

    return config;
  }
  
  const hook = () => {
    return getConfig();
  }

  return hook;
};


export default useConfigMenu;