import React from 'react';

import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import useContextMenu         from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import useDocHeaderMarkerMenu from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-doc-header-marker-menu';

import { MainWrapper } from './styles';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;

  const getMenuConfig = useDocHeaderMarkerMenu();
  const { showMenu }  = useContextMenu();

  const handleContextMenu = (event: React.MouseEvent) => {
    const config = getMenuConfig(markerAddr);
    showMenu({config, event});
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu} >
      { children }
    </MainWrapper>
  );
}
