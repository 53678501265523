import { useContext } from "react";
import Context from "../components/document-states/document-context-scale-set/context";


const useDocumentScaleSet = () => {
  const setScale = useContext(Context);
  return setScale;
}


export default useDocumentScaleSet;