import { MenuItemType } from 'lego-v2/menu';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import Icon from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useFunctions from './__use-functions';


const useItems = (widgetAddr: ContentTypes.WidgetAddr) => {
  const t = useTranslations();
  const fns = useFunctions();

  const getWidgetStyleCopy = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('widget style copy'),
      iconComponent: Icon.Copy,
      onClick: () => {
        fns.copyWidgetStyle(widgetAddr);
        fns.hideMenu();
      }
    }
  });

  const getWidgetStylePaste = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('widget style paste'),
      iconComponent: Icon.Paste,
      onClick: () => {
        fns.pasteWidgetStyle(widgetAddr);
        fns.hideMenu();
      }
    }
  });

  const getWidgetStyleSetDefault = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('widget style set as default'),
      iconComponent: Icon.Default,
      onClick: () => {
        fns.setWidgetStyleDefault(widgetAddr);
        fns.hideMenu();
      }
    }
  });

  const getWidgetEdit = () => ({
    type: MenuItemType.BUTTON,
    skip: ! fns.isWidgetEditable(widgetAddr),
    data: {
      title: t('edit'),
      iconComponent: Icon.Edit.Plain,
      onClick: () => {
        fns.editWidget(widgetAddr);
        fns.hideMenu();
      }
    }
  });

  const getWidgetDuplicate = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('duplicate'),
      iconComponent: Icon.Duplicate,
      onClick: () => {
        fns.duplicateWidget(widgetAddr);
        fns.hideMenu();
      }
    }
  });

  const getWidgetMoveUp = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('widget move up'),
      iconComponent: Icon.Move.Up,
      onClick: () => {
        fns.moveUpWidget(widgetAddr);
      }
    }
  });

  const getWidgetMoveDown = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('widget move down'),
      iconComponent: Icon.Move.Down,
      onClick: () => {
        fns.moveDownWidget(widgetAddr);
      }
    }
  });    
       

  const getWidgetDelete = () => ({
    type: MenuItemType.BUTTON,
    data: {
      title: t('widget delete'),
      variant: 'delete',
      iconComponent: Icon.Delete,
      onClick: () => {
        fns.deleteWidget(widgetAddr);
        fns.hideMenu();
      }
    }
  });    
        
  
  return {
    getWidgetStyleCopy,
    getWidgetStylePaste,
    getWidgetStyleSetDefault,

    getWidgetDuplicate,
    getWidgetEdit,
    getWidgetMoveUp,
    getWidgetMoveDown,
    getWidgetDelete,
  }
  
}

export default useItems;