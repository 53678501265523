import styled from "styled-components";
import Panel from "./panel";


export const MenuBarItemPanel = styled(Panel)`
  display: flex;
  flex-direction: column;

  padding:    ${props => props.theme.menuBarItemPanel.padding};
  background: ${props => props.theme.menuBarItemPanel.background};
`;


export default MenuBarItemPanel;