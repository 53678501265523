import React from 'react';
import { ViewTypes } from 'app/arch/home-page-guest/states/view';
import useViewSelected from '../../hooks/use-view-selected';

import ViewLegalTermsOfServiceComponent from 'app/ui-v2/home-page/hls/views/view-legal-terms-of-service';
import ViewLegalPrivacyPolicyComponent  from 'app/ui-v2/home-page/hls/views/view-legal-privacy-policy';

import ViewRunDemoComponent   from './views/view-run-demo';
import ViewPricingComponent   from './views/view-pricing';

import ViewUserLoginComponent        from './views/view-user-login';
import ViewUserSignupComponent       from './views/view-user-signup';
import ViewUserSignupDoneComponent   from './views/view-user-signup-done';
import ViewUserSignupFailedComponent from './views/view-user-signup-failed';

import ViewPasswordResetRequestFormComponent from './views/view-password-reset-request';
import ViewPasswordResetFormComponent        from './views/view-password-reset-form';
import ViewPasswordResetLinkSentComponent    from './views/view-password-reset-link-sent';


interface Props {
}


export const HomePageGuestViewSelectedComponent: React.FC<Props> = (props: Props) => {
  const viewSelected = useViewSelected();

  const Views: {[key in ViewTypes.ViewItem]: React.ElementType} = {
    [ViewTypes.ViewItem.WELCOME]: ViewRunDemoComponent,
    [ViewTypes.ViewItem.DEMO   ]: ViewRunDemoComponent,
    [ViewTypes.ViewItem.PRICING]: ViewPricingComponent,

    [ViewTypes.ViewItem.USER_LOGIN        ]: ViewUserLoginComponent,
    [ViewTypes.ViewItem.USER_SIGNUP       ]: ViewUserSignupComponent,
    [ViewTypes.ViewItem.USER_SIGNUP_FAILED]: ViewUserSignupFailedComponent,
    [ViewTypes.ViewItem.USER_SIGNUP_DONE  ]: ViewUserSignupDoneComponent,

    [ViewTypes.ViewItem.PASSWORD_RESET_REQUEST_FORM]: ViewPasswordResetRequestFormComponent,
    [ViewTypes.ViewItem.PASSWORD_RESET_LINK_SENT   ]: ViewPasswordResetLinkSentComponent,
    [ViewTypes.ViewItem.PASSWORD_RESET_FORM        ]: ViewPasswordResetFormComponent,

    [ViewTypes.ViewItem.LEGAL_TERMS_OF_SERVICE ]: ViewLegalTermsOfServiceComponent,
    [ViewTypes.ViewItem.LEGAL_PRIVACY_POLICY   ]: ViewLegalPrivacyPolicyComponent,
  }

  const View = Views[viewSelected];
 
  return (
    <View />
  );
}
  
