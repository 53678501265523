import styled from "styled-components";


const GridRow1 = styled.div<{column: number, row: number}>`
  grid-column: ${props => props.column + 1};
  grid-row: ${props => props.row + 1};
  
  height: 100%;
  overflow: hidden;
  
  /* background: red; */
`;


export default GridRow1;
