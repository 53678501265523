import { createContext } from 'react';


export interface ContextProps {
  openItem: (() => void);
  closeItem: (() => void);
  toggleOpenItem: (() => void);
};


const Context = createContext<ContextProps>({
  openItem: () => {},
  closeItem: () => {},
  toggleOpenItem: () => {},
});


export default Context;