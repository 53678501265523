import React from 'react';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState }        from 'app/ui/contexts/document';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';

import Tools           from '../tools';
import { DropWrapper } from './styles';


interface Props {
  disabled?: boolean;
  columnAddr: ReleaselogsTypes.ColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellDropComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    disabled,
    children
  } = props;


  if (disabled) {
    return children;
  }

  return (
    <EnabledComponent 
      columnAddr={columnAddr} 
      disabled={disabled} 
    >
      { children }
    </EnabledComponent>
  );
}



export const EnabledComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children
  } = props;

  const document  = useDocState();
  const getDNDData = useDNDDataGet();
  
  const {
    setReleaselogs
  } = useEditorStatesSetters();
  
  const handleDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const droppedColumnAddr = data.columnAddr;

    document.releaselogs.moveColumn(droppedColumnAddr, columnAddr);
    document.saveUndo();
    setReleaselogs();
  }

  const dndContext = Tools.getDNDContext(columnAddr.releaselogId);

  const contextsDefs: ContextDef[] = [{
    dndContext: dndContext,
    onDrop: handleDrop
  }];

  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs} 
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
