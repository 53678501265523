import styled from "styled-components";
import DialogBase            from '@mui/material/Dialog';
import DialogTitleBase       from '@mui/material/DialogTitle';
import DialogContentBase     from '@mui/material/DialogContent';
import DialogContentTextBase from '@mui/material/DialogContentText';
import DialogActionsBase     from '@mui/material/DialogActions';


export const Dialog = styled(DialogBase)`
  & .MuiDialog-paper {
    background: ${props => props.theme.dialog.background};
  }

  & .MuiDialogContentText-root {
    color: ${props => props.theme.dialog.color.message};
  }
`;


export const DialogTitle = styled(DialogTitleBase)`
  color: ${props => props.theme.dialog.color.title};
`;

export const DialogContent = styled(DialogContentBase)`
  display: flex;
  flex-direction: row;
  gap: ${props => props.theme.defs.gap.large};
`;

export const MsgWrapper = styled.div`
  flex: 1 1 0;
  min-width: 10px;
  align-self: center;

`;

export const IconWrapper = styled.div`
  flex: 0 0 auto;
  align-self: center;
`;

export const DialogContentText = styled(DialogContentTextBase)`
`;

export const DialogActions = styled(DialogActionsBase)`
`;
