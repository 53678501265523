import styled from "styled-components";
import TitleBase     from "../../../styles/title";
import SeparatorBase from "../../../styles/separator";


export const MainWrapper = styled.div<{$disabled?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};

  opacity: ${props => props.$disabled ? props.theme.defs.opacity.disabled : 1};
`;


export const Title = styled(TitleBase)`
`;


const SEPARATOR_MARGIN = 1;

export const Separator = styled(SeparatorBase)`
  margin-top: ${SEPARATOR_MARGIN}px;
  margin-bottom: ${SEPARATOR_MARGIN}px;
`;

