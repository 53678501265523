import { ApolloError } from '@apollo/client';
import { useProgressModal } from 'lego-v2/progress-modal';

import { useAuth } from "services/auth";
import { useMsgBox }   from 'app/ui-v2/app/__modules/info/components/toasts/hooks/use-msg-box';
import { MsgLevel }    from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { MsgDuration } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import useAppStateSetters from 'app/ui-v2/app/hooks/use-app-state-setters';

import { useApp } from './use-app';
import { useTranslations } from './use-translation';


type OnDoneFn = () => void;
type OnErrorFn = (error: string) => void;


const useAppLogin = () => {
  const t = useTranslations();
  const app = useApp();
  const auth = useAuth();

  const { 
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();

  const {
    showMsgBox
  } = useMsgBox();

  const {
    setApp
  } = useAppStateSetters();


  const login = (
    email: string, 
    password: string,
    callbacks?: {
    onDone?: OnDoneFn,
    onError?: OnErrorFn
    }
  ) => {
    showProgressModal();

    auth.login(email, password).subscribe({
      next(result: any) {
        showMsgBox(t("login successful"));
    
        app.appState.updateState({isLogged: true});
        setApp();
        // no need hideProgress 
        // as logout will start app loaders to refresh
        // which will hide progress when finished

        callbacks?.onDone?.();
      },
      error(error: ApolloError) {
        hideProgressModal();
        showMsgBox(
          t("login failed"),
          MsgLevel.ERROR,
          MsgDuration.MIDDLE
        );
        callbacks?.onError?.(error.message);
      }
    });   
  }


  return login;
}

export default useAppLogin;