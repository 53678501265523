import React from 'react';
import { Page } from 'app/arch/print/page';
import { PageFormatType } from 'app/arch/types/types';
import { PageOrientationType } from 'app/arch/types/types';

import DeskScalerComponent from './desk-scaler';
import DeskScaleInitComponent from './desk-scale-init';


interface Props {
  pageFormat: PageFormatType;
  pageOrientation: PageOrientationType;
  children: React.ReactNode;
}


export const DeskComponent: React.FC<Props> = (props: Props) => {
  const {
    pageFormat,
    pageOrientation,
    children,
  } = props;

  const pageSize = Page.getSizePx(pageFormat, pageOrientation);

  
  return (
    <DeskScaleInitComponent pageSize={pageSize}>
      <DeskScalerComponent pageSize={pageSize}>
        { children }
      </DeskScalerComponent>
    </DeskScaleInitComponent>
  );
}
