import { InfoActionsTypes } from "app/arch/app/info";

import useInfoStatesSetters from "../../../hooks/use-info-state-setters";
import useActionsState from "./use-actions-state";


const useActionAdd = () => {
  const {
    setActions
  } = useInfoStatesSetters();

  const actions = useActionsState();

  const addAction = (props: InfoActionsTypes.ActionCreateProps) => {
    const addr = actions.addAction(props);
    setActions();
    return addr;
  }

  return addAction;
}


export default useActionAdd;