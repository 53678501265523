import React from 'react';

import { ReposTypes } from 'app/arch/app/states/repos';
import useRepos from 'app/ui-v2/app/hooks/use-repos';

import RepoItemComponent from './repo-item';
import { MainWrapper } from './styles';


interface Props {
  selectedRepoId: number;
  onRepoSelected: (repoId: number) => void;
}


export const ReposListComponent: React.FC<Props> = (props: Props) => {
  const { 
    selectedRepoId,
    onRepoSelected
  } = props;

  const repos = useRepos();



  const renderRepo = (
    repoPack: ReposTypes.RepoPack, 
    idx: number
  ) => {
    const isSelected = (repoPack.repo.id === selectedRepoId);

    return (
      <RepoItemComponent 
        key={idx}
        repoPack={repoPack}
        selected={isSelected}
        onRepoSelect={onRepoSelected}
      />
    );
  }

  const renderRepos = () => {
    const reposComponents = repos.getReposPacks().map((repo: ReposTypes.RepoPack, idx: number) => {
      return renderRepo(repo, idx);
    });

    return reposComponents;
  }


  return (
    <MainWrapper>
      { renderRepos() }
    </MainWrapper>
  );
}
