import React, { useEffect, useState } from 'react';
import VirtualProbeComponent from '../virtual-probe';
import { AnimPanelBase } from './styles';


interface Props {
  onClose?: () => void;
  setEnabledRef?: any;
  toggleVisibleRef?: any;

  AnimPanel?: React.ElementType;
  children: React.ReactElement;
}


export const AnimatedPanelComponent: React.FC<Props> = (props: Props) => {
  const { 
    setEnabledRef, 
    toggleVisibleRef,
    onClose,

    children, 
  } = props;

  const AnimPanel = props.AnimPanel || AnimPanelBase;

  const [enabled, setEnabled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [panelHeight, setPanelHeight] = useState(0);

  useEffect( () => {
    if ( setEnabledRef !== undefined ) {
      setEnabledRef.current = setEnabled;
    }

    if ( toggleVisibleRef !== undefined ) {
      toggleVisibleRef.current = () => {
        const newValue = ! visible;
        setVisible(newValue);
        
        if (newValue === false) {
          onClose && onClose();
        }
      };
    }
    
    return () => {
      if (setEnabledRef) {
        setEnabledRef.current = null;
      } 

      if (toggleVisibleRef) {
        toggleVisibleRef.current = null;
      } 
    }
  });


  useEffect(() => {
    if ( ! visible ) return;

    const onClick = (e: any) => {
      if (visible) {
        setVisible(false);
        onClose && onClose();
      }
    };

    document.addEventListener('click', onClick);

    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [visible]);

  const handleBBoxChange = (bbox: DOMRect) => {
    setPanelHeight(bbox.height);
  }

  return (
    <>
      {
        panelHeight !== 0 &&
        <AnimPanel
          $enabled={enabled}
          $visible={visible}
          $panelHeight={panelHeight}
        >
          { children }
        </AnimPanel>
      }

      <VirtualProbeComponent
        onBBoxChange={handleBBoxChange}
      >
        { children }
      </VirtualProbeComponent>
    </>
  );
}
