import React from 'react';
import { useRecoilValue } from 'recoil';
import jtl from 'tools/jtl';

import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState } from 'app/ui/contexts/document';
import { UIState_Content } from 'app/ui/states/editor-instruction';

import { ImageComponent } from './image/Image';


interface Props  {
  cellAddr: Types.CellAddr;
}


export const ImagesComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
  } = props;
  
  const docState = useDocState();
  const imagesAddrs = useRecoilValue(UIState_Content.cellImages_imagesAddrs(cellAddr));

  const renderImages = () => {
    if (imagesAddrs.length === 0) {
      return null;
    }

    const imagesComponents = imagesAddrs.map((imageAddr) => {
      // It can happen that someone will have an image in gallery and he uses it in multiple cells.
      // Then he save document and delete this image from gallery (and effectively from remote server),
      // and also from document state. However he does not save it, when he gets content from the server,
      // he will also get in data cell images, image which was already removed from gallery/server.
      // In this case just silently ignore this image.

      // This has been taken care of while instruction
      // gets loaded. If happens here - then not sure why :)
      const imageProps = docState.content.cellImages_getImageProps(imageAddr);
      const instructionImage = docState.repoImages.getImage(imageProps.bid);
      if (instructionImage === undefined) {
        const imageHash = jtl.object.hash(imageAddr);
        console.warn(
          `Ghost cell image, id: ${imageHash}, `
          + `bid: ${imageProps.bid}`
        );
        return null;
      }

      const key = ContentTools.getImageKey(imageAddr);
      return (
        <ImageComponent 
          key={key}
          imageAddr={imageAddr} 
        />
      );
    });
    
    return imagesComponents;
  }

  return renderImages();
}
