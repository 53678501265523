import { useRef } from "react";

import useSelectUserImages          from "lego/components/user-files-selector/use-select-user-images";
import useSelectUserImageCameraBack from "lego/components/user-files-selector/use-select-user-image-camera-back";

import { UploadSource }    from "app/arch/types/types-v2";
import { RepoImagesTypes } from "app/arch/editor-instruction/document/states/loadable/repo-images";
import { ContentTypes }    from "app/arch/editor-instruction/document/states/persistent/content";

import { useDocState }    from "app/ui/contexts/document";
import { UploadFileType } from "app/ui/hooks/editor-instruction/use-upload-files";
import { useUploadFiles } from "app/ui/hooks/editor-instruction/use-upload-files";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";



const useCellImageUpload = () => {
  const document = useDocState();
  const {
    setContent,
    setContentSession,
    setRepoImages,
  } = useEditorStatesSetters();

  const cellAddrRef = useRef<ContentTypes.CellAddr | null>(null);
  const uploadFiles = useUploadFiles(UploadFileType.IMAGES);
 

  const resetLoadingProgress = (files: File[]) => {
    document.contentSession.cellImages_removeImagesUploading(
      cellAddrRef.current !,
      files.length
    );
    setContentSession();
  }

  const handleFilesSelected = (files: File[]) => {
    document.contentSession.cellImages_addImagesUploading(
      cellAddrRef.current !,
      files.length
    );
    setContentSession();
    
    uploadFiles({
      files, 
      onDone: (ok: boolean, data: any) => {
        if ( ! ok ) {
          console.warn(`Images could not be uploaded`);
          resetLoadingProgress(files);
          return;
        }
  
        resetLoadingProgress(files);
        handleFilesUploaded(data.images);
      },
      onError: (error: string) => {
        resetLoadingProgress(files);
      }
    });
  }

  const handleFilesUploaded = (imagesRaw: RepoImagesTypes.ImagesRaw) => {
    const cellAddr = cellAddrRef.current !;

    document.repoImages.loadImages(imagesRaw);
    imagesRaw.forEach(imageRaw => {
      const image = document.repoImages.getImage(imageRaw.id);
      document.content.cellImages_addImage(cellAddr, image);
    });

    document.saveUndo();
    setRepoImages();
    setContent();
  }

  
  const selectFromDisk = useSelectUserImages({
    onFilesSelected: handleFilesSelected
  });

  const selectFromCameraBack = useSelectUserImageCameraBack({
    onFilesSelected: handleFilesSelected
  });


  const hook = (props: {
    cellAddr: ContentTypes.CellAddr, 
    source: UploadSource 
  }) => {

    const {
      cellAddr,
      source
    } = props;
    
    cellAddrRef.current = cellAddr;

    if (source === UploadSource.DISK) {
      selectFromDisk();
    }
    else if (source === UploadSource.CAMERA_BACK) {
      selectFromCameraBack();
    }
  }

  return hook;
}


export default useCellImageUpload;