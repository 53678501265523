import produce from 'immer';
import * as State from './state';
import * as Types from './types';


class Point {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }


  /**
   * Setters
   */

  setPoint(point: Types.Point) {
    this._state = produce(this._state, draft => {
      draft.point = point;
    });
  }


  /**
   * Getters
   */

  getPoint(): Types.Point | null { 
    return State.getPoint(this._state);
  }
}

export default Point;