import React from 'react';

import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';
import { SectionItemProps } from '../types';

import SectionNameProbeComponent       from './section-name-probe';
import SectionNameViewComponent        from './section-name-view';
import SecitionNameCSSComponent        from './secition-name-css';
import SectionNameEditComponent        from './section-name-edit';
import SectionNameDropComponent        from './section-name-drop';
import SectionNameDraggerComponent     from './section-name-dragger';
import SectionNameContextMenuComponent from './section-name-context-menu';
import SectionNameSelectComponent      from './section-name-select';

import { GridColumnSpan }    from './styles';
import { SectionNameBorder } from './styles';


interface Props extends SectionItemProps {
}


export const SectionNameComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
    sectionId
  } = props;
  const docState = useDocState();

  const sectionName = item as SlicerTypes.SectionItem_SectionName;
  const sectionAddr = { sectionId };

  const dataTest = ContentTools.getSectionDataTest({
    prefix: 'content-section',
    sectionAddr, 
    docState,
  });

  return (
    <GridColumnSpan data-test={dataTest}>
      <SectionNameProbeComponent sectionAddr={sectionAddr} >
        <SectionNameBorder>
          <SectionNameSelectComponent sectionAddr={sectionAddr} >
            <SectionNameEditComponent sectionAddr={sectionAddr} >
              <SectionNameContextMenuComponent sectionAddr={sectionAddr} >
                <SectionNameDropComponent sectionAddr={sectionAddr} >
                  <SectionNameDraggerComponent sectionAddr={sectionAddr} >
                    <SecitionNameCSSComponent sectionAddr={sectionAddr} >
                      <SectionNameViewComponent sectionAddr={sectionAddr} />
                    </SecitionNameCSSComponent>
                  </SectionNameDraggerComponent>
                </SectionNameDropComponent>
              </SectionNameContextMenuComponent>
            </SectionNameEditComponent>
          </SectionNameSelectComponent>
        </SectionNameBorder>
      </SectionNameProbeComponent>
    </GridColumnSpan>
  );
}
