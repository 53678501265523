
import { useEffect  } from 'react';
import { useReducer } from 'react';
import { useRecoilValue } from 'recoil';

import { FrameTargetUpdate } from 'lego-v2/frame-resize/arch';

import environment from 'app/environment';

import { Position } from 'app/arch/types/types';
import { Size }     from 'app/arch/types/types';
import { FrameHandlerImage } from 'app/arch/editor-image/widgets/frame-resize-handlers';
import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';

import { useDocState }     from 'app/ui/contexts/document';
import { useInstruction  } from 'app/ui/hooks/editor-instruction/use-instruction';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters  from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import WidgetBoxedBaseComponent from '../__widget-base/widget-boxed-base';
import { WidgetPropsBase }      from '../types';

import { Image } from './styles';


interface Props extends WidgetPropsBase {
}


export const WidgetImageComponent: React.FC<Props> = (props: Props) => {
  const {
    widgetAddr,
  } = props;

  const docState    = useDocState();
  const instruction = useInstruction();

  const widgetProps_ = useRecoilValue(UIState_Content.cellImages_image_widgetProps(widgetAddr));
  const widgetProps = widgetProps_ as ContentTypes.WidgetImageProps;

  const {
    setContent
  } = useEditorStatesSetters();

  const [imgCfg, dispatchImgCfg] = useReducer(imgCfgReducer, {
    image: {
      size: widgetProps.imageSize,
      position: widgetProps.imagePosition,
      clipInset: widgetProps.imageClipInset
    },
    widget: {
      size: widgetProps.size,
      position: widgetProps.position,
    }
  });

  useEffect(() => {
    dispatchImgCfg({
      type: 'reload',
      payload: widgetProps
    });
  }, [widgetProps]);

  const createFrameHandler = (
    scale: number,
    startPosition: Position,
    startSize: Size,
    minSize: Size,
  ) => {
    const customData = {
      imageSize: widgetProps.imageSize,
      imagePosition: widgetProps.imagePosition,
      imageClipInset: widgetProps.imageClipInset,
    };

    return new FrameHandlerImage(
      scale,
      startPosition, 
      startSize, 
      minSize, 
      customData);
  }

  const handleFrameUpdate = (update: FrameTargetUpdate) => {
    dispatchImgCfg({
      type: 'update',
      payload: update
    });
  }

  const handleFrameUpdateDone = (update: FrameTargetUpdate, updated: boolean) => {
    // If updated is true, that means
    // that child component already udpated
    // state, and it needs to be populated
    // and saved to undo.
    updated = updateDocumentContent(docState, widgetAddr, update) || updated;

    if (updated) {
      setContent();
      docState.saveUndo();
    }
  }

  const getImageUrl = () => {
    const image = docState.repoImages.getImage(widgetProps.bid);

    const imageUrl = environment.backend.getInstructionImageUrl(
      instruction.repoId !,
      instruction.id !,
      image.filename
    );

    return imageUrl;
  }

  //----------------------------------
  // Render
  //


  const width  = imgCfg.widget.size[0];
  const height = imgCfg.widget.size[1];

  return (
    <WidgetBoxedBaseComponent
      {...props}
      onFrameUpdate={handleFrameUpdate}
      onFrameUpdateDone={handleFrameUpdateDone}
      frameHandlerFactory={createFrameHandler}
    >
      <Image
        data-imgs={'widget-image'}
        $url={getImageUrl()}
        $backgroundSize={imgCfg.image.size}
        $backgroundPosition={imgCfg.image.position}
        style={{
          width:  `${width}px`,
          height: `${height}px`,
        }}    
      />

    </WidgetBoxedBaseComponent>
  );
}



//----------------------------------
// Image Config Reducer
//

interface ImageCfg {
  image: {
    size: Size;
    position: Position;
    clipInset: ContentTypes.ImageClipInset;
  },
  widget: {
    size: Size;
    position: Position;
  }
};

const imgCfgReducer = (state: ImageCfg, action: any) => {
  switch (action.type) {
    case 'update': {
      let newState = {...state};
      if ( action.payload.custom !== undefined ) {
        newState.image.position  = action.payload.custom.imagePosition  || newState.image.position;
        newState.image.size      = action.payload.custom.imageSize      || newState.image.size;
        newState.image.clipInset = action.payload.custom.imageClipInset || newState.image.clipInset;
      }

      newState.widget.position = action.payload.position || newState.widget.position;
      newState.widget.size     = action.payload.size || newState.widget.size;
      return newState;
    }

    case 'reload': {
      let newState = {...state};
      newState.image.position  = action.payload.imagePosition;
      newState.image.size      = action.payload.imageSize;
      newState.image.clipInset = action.payload.imageClipInset;
      newState.widget.position = action.payload.position;
      newState.widget.size     = action.payload.size;
      return newState;
    }

    default : {
      const msg = `imgCfgReducer: unknown action type: ${action.type}`;
      throw new Error(msg);
    }
  }
}


//----------------------------------
// Document content update
//

const updateDocumentContent = (
  document: DocState,
  widgetAddr: ContentTypes.WidgetAddr,
  update: any
) => {
  if ( ! ('custom' in update)) return false;

  let updated = false;

  if ('imageClipInset' in update.custom) {
    const imageClipInset = update.custom.imageClipInset;
    const widgetUpdate = { imageClipInset };

    document.content.cellImages_image_widgetImage_update(
      widgetAddr,
      widgetUpdate
    );

    updated = true;
  }

  if ('imageSize' in update.custom) {
    const imageSize = update.custom.imageSize;
    const widgetUpdate = { imageSize };

    document.content.cellImages_image_widgetImage_update(
      widgetAddr,
      widgetUpdate
    );

    updated = true;
  }

  if ('imagePosition' in update.custom) {
    const imagePosition = update.custom.imagePosition;
    const widgetUpdate = { imagePosition };

    document.content.cellImages_image_widgetImage_update(
      widgetAddr,
      widgetUpdate
    );

    updated = true;
  }

  return updated;
}
