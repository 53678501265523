import React from 'react';
import { useRecoilValue } from 'recoil';

import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/releaselogs/types';
import { useDocState } from 'app/ui/contexts/document';
import { UIState_Releaselogs }  from 'app/ui/states/editor-instruction';
import useReleaselogColumnsWidths from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/columns/use-releaselog-columns-widths';

import RowCellComponent       from './row-cell';
import RowHeaderCellComponent from './row-header-cell';

import { Grid } from './styles';


interface Props {
  changelog: SlicerTypes.PageItem_ReleaseChangelog;
}


export const ChangelogGridComponent: React.FC<Props> = (props: Props) => {
  const {
    changelog
  } = props;
  
  const document = useDocState();

  const releaselogId = changelog.releaselogId;
  const releaselogAddr = { releaselogId };

  // Track ColumnsWidth
  useReleaselogColumnsWidths(releaselogAddr);
  
  
  // Track ColumnsAddrs (create column, delete column, reorder)
  const columnsAddrs = useRecoilValue(UIState_Releaselogs.columnsAddrs(releaselogAddr));

  const colsWidths = columnsAddrs.map((columnAddr) => {
    const columnProps = document.releaselogs.getColumnProps(columnAddr);
    return `${columnProps.width}px`;
  });

  const columnsTemplate = colsWidths.join(' ');
  const pageRowsCount  = changelog.items.length;

  const RowComps = {
    [SlicerTypes.ChangelogItemType.ROW]       : RowCellComponent,
    [SlicerTypes.ChangelogItemType.HEADER_ROW]: RowHeaderCellComponent,
  };

  const renderItem = (
    item: SlicerTypes.ChangelogItem, 
    rowIdx: number
  ) => {
    const RowComp = RowComps[item.type];
    const lastPageRow = (rowIdx === (changelog.items.length - 1));
    
    return (
      <RowComp
        key={rowIdx}
        item={item}
        releaselogId={releaselogId}
        rowIdx={rowIdx}
        lastPageRow={lastPageRow}
      />
    );
  }

  const renderRows = () => {
    const itemsComps = changelog.items.map((item, rowIdx) => {
      const ItemComp = renderItem(item, rowIdx);
      return ItemComp;
    });

    return itemsComps;
  }

  return (
    <Grid
      columnsTemplate={columnsTemplate}
      rows={pageRowsCount}
    >
      { renderRows() }
    </Grid>
  );
}
  
