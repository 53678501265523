import { Language } from "app/arch/types";
import { Size } from "app/arch/types";
import { ThemeAccentColor } from 'app/arch/app/themes/types';


export const settings = {
  touch: {
    visualFeedbackDelay: 250
  },
  resizerLine: {
    width: {
      mobile: 35,
      desktop: 19,
    }
  },
  
  window: {
    size: {
      min: [310, 310] as Size
    }
  },

  email: {
    contact: 'contact@flexedit.app'
  },
  
  document: {
    scale: {
      min: 0.15,
      max: 2.0,
    },
    header: {
      // width: 140,
      // height: 160,
      // heightInside: 160 - 2 * 1, // document.header.border.width

      border: {
        width: 1
      },


      logo: {
        width: 140,
        height: 160 - 2 * 1, // document.header.border.width
      },

      revisionInfo: {
        width: 140,
      }
    },
  },

  tooltip: {
    entryDelay: 750
  },

  demo: {
    user: {
      email: 'demo@flexedit.app',
      password: '12345'
    },
    pdf: {
      watermark: false
    }
  },

  accentColors: [
      ThemeAccentColor.BLUE,
      ThemeAccentColor.VIOLET,
      ThemeAccentColor.PINK,
      ThemeAccentColor.RED,
      ThemeAccentColor.ORANGE,
      ThemeAccentColor.YELLOW,
      ThemeAccentColor.GREEN,
      ThemeAccentColor.GREY,
  ],

  printView: {
    table: {
      header: {
        padding: {
          min: 1,
          max: 24,
        },
        font: {
          size: {
            min: 8,
            max: 100,
          },
          weight: {
            min: 1,
            max: 9,
          }
        }
      }
    },
    column: {
      size: {
        min: {
          width: 20
        },
        default: {
          width: 160
        }
      }
    },
    cell: {
      padding: {
        default: 6,
        min: 0,
        max: 40
      },
    },

    cell_index : {
      font: {
        size: {
          min: 8,
          max: 100,
          default: 14,
        },
        weight: {
          min: 1,
          max: 9,
          default: 4,
        }
      }
    },

    column_images : {
      image: {
        border: {
          default: {
            visible: true,
            color:   '#666',
            radius:  3,
            width:   1,
          }
        },
        // idx: {
        //   default: {
        //     visible: true,
        //     placement: CornerTypeDupa.TOP_LEFT,
        //     border: {
        //       color: '#666',
        //       radius:  3,
        //       width:   1,
        //     }
        //   }
        // }
      }
    },

    images: {
      gap: {
        default: 2,
        min: 0,
        max: 40
      }
    },

    imageScaleTo: {
      default: {
        width: 200,
        height: 100,
      },

      min: {
        width: 40,
        height: 40,
      },

      max: {
        width: 400,
        height: 400,
      }
    }
  },

  editorImage: {
    newImage: {
      width: 300,
      height: 300,
    },

    desk: {
      width: 1000,
      height: 1000,
    }
  },

  editorText: {
    updateDebounceDelay: 500
  },

  editorLogo: {
    window: {
      widthMin: 380,
      heightMin: 280,

      default: {
        width: 400,
        height: 400,
        x: 150,
        y: 150,
      }
    }
  },



  marker: {
    default: {
      iconSize: 32,
      text: "marker",
    }
  },

  smartLines: {
    align: {
      distance: 4
    }
  },
  
  scaler: {
    scale: {
      min: 0.2,
      max: 8,
      delta: 0.005,
      deltaTouch: 0.003,
    }
  },

  icons: {
    menu: {
      size: 20
    },
    window: {
      controls: {
        size: 20
      }
    }
  },

  context: {
    autohide: {
      delay: 4000
    }
  },

  rateControl: {
    resizing: {
      interval: 50,
    },
    slider: {
      interval: 50
    }
  },

  defaultLanguage: Language.ENGLISH,
}