import { useSetRecoilState } from "recoil";
import useScreenSize from "./use-screen-size";
import { UIScreenSize } from "../states";


const useScreenStateSetters = () => {
  const screenSize = useScreenSize();

  const __setScreenSize = useSetRecoilState(UIScreenSize.state);
  const setScreenSize = () => __setScreenSize(screenSize.state);

  const setAll = () => {
    setScreenSize();
  }

  return {
    setAll,
    setScreenSize,
  }
}


export default useScreenStateSetters;