import React from 'react';
import { DraggerUpdate } from 'lego-v2/dragger/arch';

import { Position }     from 'app/arch/types';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';
import DraggerSmartLinesComponent from '../../../../smart-lines/dragger-smart-lines';

import * as Tools from '../tools';

import { Dragger } from './styles';


interface Props {
  scale: number;
  disabled: boolean;
  widgetAddr: ContentTypes.WidgetAddr;

  onDraggingStart: () => void;
  onDraggingStop: () => void;

  position: Position;
  onPositionUpdate: (position: Position) => void;
  onPositionUpdateDone: (position: Position) => void;

  children: React.ReactNode;
}


export const WidgetBoxedDraggerComponent: React.FC<Props> = (props: Props) => {
  const {
    scale,
    disabled,
    widgetAddr,

    onDraggingStart,
    onDraggingStop,

    position,
    onPositionUpdate,
    onPositionUpdateDone,

    children,
  } = props;

  const docState = useDocState();


  /**
   * Dragger handlers
   */
  const handleDraggerUpdateStart = (event: any) => {
    onDraggingStart();
  }

  const handleDraggerUpdate = (update: DraggerUpdate) => {
    const position = Tools.round(update.position !);
    onPositionUpdate(position); 
  }

  const handleDraggerUpdateDone = (update: DraggerUpdate) => {
    handleDraggerUpdate(update);

    const position = Tools.round(update.position !);
    onPositionUpdateDone(position);
    docState.saveUndo();

    onDraggingStop();
  }


  return (
    <DraggerSmartLinesComponent
      scale={scale}
      disabled={disabled}
      widgetAddr={widgetAddr}

      position={position}

      onUpdateStart={handleDraggerUpdateStart}
      onUpdate={handleDraggerUpdate}
      onUpdateDone={handleDraggerUpdateDone}

      component={Dragger}
    >
      { children }
    </DraggerSmartLinesComponent>
  );
}
