import React from 'react';

import PanelItem       from 'lego/styles/panel_item';
import PanelItemHeader from 'lego/styles/panel_item_header';
import ButtonComponent from 'lego/components/buttons/button';

import { ThemeVariantType } from 'app/arch/app/themes/types';
import Icon from 'app/ui/icons/icons';
import { useTranslations  } from 'app/ui/hooks/app/use-translation';
import useUserSettings     from 'app/ui-v2/app/__modules/user/hooks/use-user-settings';
import useUserStateSetters from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';
import useUserSettingsSave from 'app/ui-v2/app/__modules/user/hooks/use-user-settings-save';

import { ButtonGroup       } from './styles';
import { ButtonGroupLeft   } from './styles';
import { ButtonGroupRight  } from './styles';


interface Props {
  theme: any;
}


export const ThemeVariationSetterComponent: React.FC<Props> = (props: Props) => {
  const { theme: themeDescriptor } = props;
  const t   = useTranslations();
  const userSettings = useUserSettings();
  const saveUserSettings = useUserSettingsSave();

  const DarkModeIcon = Icon.ViewMode.Dark;
  const LightModeIcon = Icon.ViewMode.Light;
  
  const {
    setUserSettings
  } = useUserStateSetters();

  const handleThemeLight = (e: any) => {
    e.stopPropagation();
    updateUserSettings(ThemeVariantType.LIGHT);
  }

  const handleThemeDark = (e: any) => {
    e.stopPropagation();
    updateUserSettings(ThemeVariantType.DARK);
  }

  const updateUserSettings = (variant: ThemeVariantType) => {
    userSettings.updateTheme({ variant });
    setUserSettings();
    saveUserSettings();
  }


  return (
    <PanelItem>
      <PanelItemHeader>
        { t('theme mode') }
      </PanelItemHeader>

      <ButtonGroup>
        <ButtonComponent
          Button={
            <ButtonGroupLeft 
              $selected={themeDescriptor.variant === ThemeVariantType.LIGHT}
              onClick={handleThemeLight}
            >
              <LightModeIcon fontSize='small'/>
            </ButtonGroupLeft>
          }
          tooltip={t('light mode')}
        />

        <ButtonComponent
          Button={
            <ButtonGroupRight 
              $selected={themeDescriptor.variant === ThemeVariantType.DARK}
              onClick={handleThemeDark}
            >
              <DarkModeIcon fontSize='small'/>
            </ButtonGroupRight>
          }
          tooltip={t('dark mode')}
        />

      </ButtonGroup>

    </PanelItem>
  );
}
  
