import styled from "styled-components";


export const EditorRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  width: 100%;

  height: 100%;
  overflow: hidden;
`;


export const WidgetsMenuWrapper = styled.div`
  flex: 0 0 auto;
`;


export const ContentWrapper = styled.div`
  flex: 1 1 0;
  min-width: 0;
`;


export const MenuPropertiesWrapper = styled.div`
  flex: 0 0 auto;
`;
