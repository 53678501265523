import styled from 'styled-components';
import { EditorEditableBase } from 'app/ui/components/editor-txt/styles';


export const StyledWrapper = styled(EditorEditableBase)`
  .editor-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
