import styled from "styled-components";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  gap: ${props => props.theme.defs.gap.large};
`;


export const SliderRawWrapper = styled.div`
  width: 100%;
`;

export const SliderValueWrapper = styled.div`
`;
