import styled from "styled-components";
import ButtonText from "./button_text";


const ButtonTextDelete = styled(ButtonText)`
  &:active:not(:disabled) {
    border-color: ${props => props.theme.defs.colors.special.red};
    background:   ${props => props.theme.defs.colors.special.red};
  }
`;

export default ButtonTextDelete;