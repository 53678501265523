import { useSetRecoilState } from "recoil";
import useDebugPoint from "./use-debug-point";
import { UIDebugPoint } from "../states";


const useDebugStateSetters = () => {
  const debugPoint = useDebugPoint();

  const __setDebugPoint = useSetRecoilState(UIDebugPoint.state);
  const setDebugPoint = () => __setDebugPoint(debugPoint.state);

  const setAll = () => {
    setDebugPoint();
  }

  return {
    setAll,
    setDebugPoint,
  }
}


export default useDebugStateSetters;