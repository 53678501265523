import styled from "styled-components";


interface Props  {
  $color: string;
  $background: string;
  $selected?: boolean;
  $isDragOver?: boolean
}

 const SelectableCell = styled.div<Props>`
  cursor: pointer;

  color: ${props => {
    if (props.$selected) {
      return props.theme.defs.accentColor.contrast;
    }

    if (props.$isDragOver) {
      return props.theme.defs.accentColor.contrast;
    }

    return props.$color;
  }};

  background: ${props => {
    if (props.$selected) {
      return props.theme.defs.accentColor.dark;
    }

    if (props.$isDragOver) {
      return props.theme.defs.accentColor.opacity07.dark;
    }

    return props.$background;
  }};
`;

export default SelectableCell;