import React from 'react';
import { useRecoilValue } from 'recoil';
import { IconContext } from 'react-icons';

import { ActionsTypes } from 'app/arch/app/info/actions';
import IconLib from 'app/ui/icons/icons';

import useActionsState   from '../../hooks/use-actions-state';
import { UIInfoActions } from '../../states';

import { ActionWrapper } from './styles';
import { IconAnchor }    from './styles';
import { IconAnimPulse } from './styles';
import { IconAnimSmily } from './styles';
import { IconAnimTimeout } from './styles';
import { IconWrapper }   from './styles';
import { MainWrapper }   from './styles';
import { Text }          from './styles';


interface Props {
  actionAddr: ActionsTypes.ActionAddr;
}


interface RenderStatesDef {
  Anim: React.ElementType;
  Icon: React.ElementType;
  iconContext: IconContext;
}



export const ActionComponent: React.FC<Props> = (props: Props) => {
  const {
    actionAddr
  } = props;

  const actions = useActionsState();
  const actionProps = actions.getActionProps(actionAddr);
  const actionState = useRecoilValue(UIInfoActions.actionState(actionAddr));


  const runingStateDef: RenderStatesDef =  {
    Anim: IconAnimPulse,
    Icon: IconLib.Heart,
    iconContext: { size: '25px', color: "red" }
  };

  const finishedStateDef: RenderStatesDef =  {
    Anim: IconAnimSmily,
    Icon: IconLib.Face.Happy,
    iconContext: { size: '25px', color: "rgb(180, 160, 0)" }
  };

  const timeoutStateDef: RenderStatesDef =  {
    Anim: IconAnimTimeout,
    Icon: IconLib.Clock.Alarm,
    iconContext: { size: '25px', color: "red" }
  };

  const RenderStatesMap: {[key in ActionsTypes.ActionState]: RenderStatesDef} = {
    [ActionsTypes.ActionState.STARTED]: runingStateDef,
    [ActionsTypes.ActionState.STOPPING_FINISHED]: finishedStateDef,
    [ActionsTypes.ActionState.STOPPING_TIMEOUT]: timeoutStateDef,
  };

  const actionText = (
    ActionsTypes.ActionState.STOPPING_FINISHED !== actionState ?
    actionProps.textStart :
    actionProps.textStop
  )

  const renderStateIcon = (actionState: ActionsTypes.ActionState) => {
    const renderDef = RenderStatesMap[actionState];

    const IconAnim = renderDef.Anim;
    const Icon = renderDef.Icon;
    const iconContext = renderDef.iconContext;

    return (
      <IconAnim>
        <IconContext.Provider value={iconContext} >
          <Icon/>
        </IconContext.Provider>
      </IconAnim>
    );
  }


  return (
    <MainWrapper>
      <ActionWrapper>

        <IconAnchor>
          <IconWrapper>
            { renderStateIcon(actionState) }
          </IconWrapper>
        </IconAnchor>
      
        <Text>
          { actionText }
        </Text>
      </ActionWrapper>
    </MainWrapper>
  );
}
