import styled from "styled-components";
import TextBase from "lego/styles/text";


export const MainWrapper = styled.div`
`;

export const Text = styled(TextBase)`
  font-size:   ${props => props.theme.defs.font.size.normal};
  font-weight: ${props => props.theme.defs.font.weight.normal};
  opacity:     ${props => props.theme.defs.opacity.disabled};
`;
