import React from 'react';

import { UUID } from 'app/arch/types';
import { useDocState }     from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useInstruction }  from 'app/ui/hooks/editor-instruction/use-instruction';


import { RevisionLabel }    from './styles';


interface Props {
  releaselogId: UUID;
}


export const ContentHeaderComponent: React.FC<Props> = (props: Props) => {
  const {
    releaselogId,
  } = props;
  
  const t = useTranslations();
  const instruction = useInstruction();

  const document = useDocState();

  const releaseAddr = {
    releaselogId
  };

  const releaselogProps = document.releaselogs.getReleaselogProps(releaseAddr);

  const revisionLabel = t("document release info, revision");
  const revisionValue = releaselogProps.info.revision || instruction.revision;


  return (
    <RevisionLabel>
    { revisionLabel } { revisionValue }
    </RevisionLabel>
  );
}
