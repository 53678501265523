import React from 'react';
import { useRecoilValue    } from 'recoil';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { WidgetStylesLibraryType } from 'app/arch/types/types-v2';
import { useDocState } from 'app/ui/contexts/document';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import StylesUserComponent   from './styles-user';
import StylesSystemComponent from './styles-system';

import { MainWrapper }      from './styles';
import { ButtonGroup }      from './styles';
import { ButtonGroupRight } from './styles';
import { ButtonGroupLeft  } from './styles';


interface Props {
  widgetAddr: ContentTypes.WidgetAddr
}


export const ControlWidgetsStylesLibComponent: React.FC<Props> = (props: Props) => {
  const { 
    widgetAddr 
  } = props;
  
  const t = useTranslations();
  const document = useDocState();

  const libType = useRecoilValue(UIState_EditorImageSession.sideToolbarPanel_stylesLibraryType);
  
  const {
    setEditorImageSession
  } = useEditorStatesSetters();

  const handleSelectUserStyles = () => {
    document.editorImageSession.setSideToolbarPanel_stylesLibraryType(WidgetStylesLibraryType.USER);
    setEditorImageSession();
  }

  const handleSelectSystemStyles = () => {
    document.editorImageSession.setSideToolbarPanel_stylesLibraryType(WidgetStylesLibraryType.SYSTEM);
    setEditorImageSession();
  }

  const Styles = (
    libType === WidgetStylesLibraryType.SYSTEM ? 
    StylesSystemComponent : 
    StylesUserComponent
  );

  return (
    <MainWrapper>
      <ButtonGroup>
        <ButtonGroupLeft 
          onClick={handleSelectUserStyles}
          $selected={libType === WidgetStylesLibraryType.USER}
        >
          { t('user') }
        </ButtonGroupLeft>

        <ButtonGroupRight 
          onClick={handleSelectSystemStyles}
          $selected={libType === WidgetStylesLibraryType.SYSTEM}
        >
          { t('system') }
        </ButtonGroupRight>
      </ButtonGroup>

      <Styles widgetAddr={widgetAddr} />

    </MainWrapper>
  );
}
  
