import React from 'react';
import { ViewTypes } from 'app/arch/home-page-user/states/view';
import useViewSelected from '../../hooks/use-view-selected';

import ViewLegalTermsOfServiceComponent from 'app/ui-v2/home-page/hls/views/view-legal-terms-of-service';
import ViewLegalPrivacyPolicyComponent  from 'app/ui-v2/home-page/hls/views/view-legal-privacy-policy';

import ViewDocumentsComponent from './views/view-documents';
import ViewReposComponent     from './views/view-repos';
import ViewPricingComponent   from './views/view-pricing';
import ViewDocumentComponent  from './views/view-document';
import ViewPaymentFinalizeComponent from './views/view-payment-finalize';


interface Props {
}


export const HomePageUserViewSelectedComponent: React.FC<Props> = (props: Props) => {
  const viewSelected = useViewSelected();

  const Views: {[key in ViewTypes.ViewItem]: React.ElementType} = {
    [ViewTypes.ViewItem.WELCOME      ] : ViewDocumentsComponent,
    [ViewTypes.ViewItem.DOCUMENTS    ] : ViewDocumentsComponent,
    [ViewTypes.ViewItem.DOCUMENT     ] : ViewDocumentComponent,
    [ViewTypes.ViewItem.REPOSITORIES ] : ViewReposComponent,
    [ViewTypes.ViewItem.PRICING      ] : ViewPricingComponent,
    [ViewTypes.ViewItem.PAYMENT_DONE ] : ViewPaymentFinalizeComponent,

    [ViewTypes.ViewItem.LEGAL_TERMS_OF_SERVICE ] : ViewLegalTermsOfServiceComponent,
    [ViewTypes.ViewItem.LEGAL_PRIVACY_POLICY   ] : ViewLegalPrivacyPolicyComponent,
  }

  const View = Views[viewSelected];
 
  return <View />;
}
  
