import React, { useRef } from 'react';
import ProbeVirtualComponent from '../probe-virtual';

interface Props {
  enabled: boolean;
  onHeightUpdate: (height: number) => void;
  children: React.ReactNode;
}


export const ProbeHeightComponent: React.FC<Props> = (props: Props) => {
  const { 
    enabled,
    onHeightUpdate,
    children 
  } = props;

  const heightRef = useRef<number | null>(null);
  
  const handleBBoxUpdate = (bbox: DOMRectReadOnly) => {
    const height = bbox.height;
    if (heightRef.current === height) {
      // TODO 
      // If you uncommet it
      // It will constantly rerended changelog
      // not sure why, it should stablise
      return;
    }

    heightRef.current = height;
    onHeightUpdate(height);
  }

  return (
    <ProbeVirtualComponent
      enabled={enabled}
      onBBoxUpdate={handleBBoxUpdate}
    >
      { children }
    </ProbeVirtualComponent>
  );
}
  
