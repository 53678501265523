import styled from "styled-components";


export const MainWrapper = styled.div`
  height: 100%;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  z-index: -1;
  visibility: none;
`;