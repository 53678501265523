import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";


const useCellSelectedSet = () => {
  const document = useDocState();

  const {
    setReleaselogsSession
  } = useEditorStatesSetters();


  const selectCell = ({cellAddr, skipStateUpdate}: {
    cellAddr: ReleaselogsTypes.CellAddr | null,
    skipStateUpdate?: boolean,
  }) => {
    document.releaselogsSession.setCellSelected(cellAddr);
    
    if (skipStateUpdate) {
      return;
    }
    
    setReleaselogsSession();
  }

  return selectCell;
}

export default useCellSelectedSet;