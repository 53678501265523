import React, { useEffect, useRef, useState } from 'react';
import { PanelControlsItemSectionControl_InputConfig as Config} from '../../config';
import { Input } from './styles';


interface Props {
  config: Config;
}

export const ControlInputComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;
  const [value, setValue] = useState(config.value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value !== config.value) {
      setValue(config.value);
    }
  }, [config.value]);

  const handleValueChange = (event: any) => {
    setValue(event.target.value);
    config.onChange?.(event.target.value);
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const key = event.key;
    event.stopPropagation();

    if (key === 'Enter') {
      commitEdit();
      inputRef.current!.blur();
    }
    else if (key === 'Escape') {
      inputRef.current!.blur();
    }
  }

  const handleBlur = () => {
    commitEdit();
  }

  const commitEdit = () => {
    config.onChangeDone?.(value);
  }

  return (
    <Input
      ref={inputRef}
      value={value}
      onChange={handleValueChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
    />
  );
}
  
