import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useDocState } from "app/ui/contexts/document";


const useWidgetStyleSetDefault = () => {
  const document = useDocState();

  const setStyleDefault = (widgetAddr: ContentTypes.WidgetAddr) => {
    const widgetProps = document.content.cellImages_image_getWidgetProps(widgetAddr);
    const style = widgetProps.style;
    document.editorImageSettings.setWidgetStyle(style);
  }

  return setStyleDefault;
}


export default useWidgetStyleSetDefault;