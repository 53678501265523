import Point from "./point";


class Debug {
  private _point: Point;

  constructor() {
    this._point = new Point();
  }

  get point() { return this._point; }
}


export default Debug;