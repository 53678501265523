import React, { useEffect, useRef, useState } from 'react';
import Confetti from "react-confetti";
import { MainWrapper } from './styles';


const CONFETTI_COUNT = 150;


interface Props {
}


export const ConfettiComponent: React.FC<Props> = (props: Props) => {
  const confetiRef = useRef<any | null>(null);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);


  useEffect(() => {
    const updateSize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    updateSize();

    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  return (
    <MainWrapper ref={confetiRef}>
      <Confetti 
        numberOfPieces={CONFETTI_COUNT} 
        width={width} 
        height={height} 
      />
    </MainWrapper>
  );
};