import * as Types from './types';


export const getClicks = (): Types.Clicks => ({
  addrs: [],
  props: {}
});


export const getClickProps = (
  props: Types.ClickPartialProps
): Types.ClickProps => ({
  position: (
    props.position !== undefined ?
    props.position :
    [100, 100]
  ),
  onDone: props.onDone
});