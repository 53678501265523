import styled from "styled-components";
import { Box as BoxBase } from 'app/ui-v2/hls/progress-box/styles';
import SeparatorBase from "lego/styles/separator";
import Text from "lego/styles/text";


const WIDTH = 80;

interface Props {
  $rowsCount: number;
}

export const Grid = styled.div<Props>`
  position:relative;

  display: grid;
  row-gap:    ${props => props.theme.defs.gap.normal};
  column-gap: ${props => props.theme.defs.gap.large};

  grid-template-columns: ${WIDTH}px 1fr;
  grid-template-rows: repeat(${props => props.$rowsCount} auto); 
`;

/**
 * Grid Cells
 */
export interface CellProps {
  $columnIdx: number;
  $rowIdx: number;
}

export const Cell = styled.div<CellProps>`
  grid-column: ${props => props.$columnIdx};
  grid-row: ${props => props.$rowIdx};
`;

export const CellFullRow = styled.div`
  grid-column: 1 / -1;
  /* background-color: red; */

  display: flex;
  flex-direction: column;
`;

export const Separator = styled(SeparatorBase)`
  margin-top: 0;
  margin-bottom: 0;
`;


export const CellTitle = styled(Cell)<{$disabled: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  
  color:     ${props => props.theme.defs.colors.color.primary};
  font-size: ${props => props.theme.defs.font.size.large};

  user-select: none;

  opacity: ${props =>
    props.$disabled ?
    props.theme.defs.opacity.disabled :
    1  
  };
`;

export const CellValue = styled(Cell)`
  min-width: 0px;
`;

export const Title = styled(Text)`
  font-size: ${props => props.theme.defs.font.size.small};
`;

export const ProgressWrapper = styled.div`
  /* background-color: rgba(255,0,0,0.5); */
  position: absolute;
  z-index: 1;
  
  top: 0%;
  left: calc(${WIDTH}px + ${props => props.theme.defs.gap.large});
  
  /* translate: calc(50% + ${props => props.theme.defs.gap.large} / 2) 0%; */
  width: calc(100% - ${WIDTH}px - ${props => props.theme.defs.gap.large});
  height: 100%;
`;

export const ProgressBox = styled(BoxBase)`
  width: 100%;
`;

