import { MenuTypes } from "lego-v2/menu"
import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import Icon from "app/ui/icons/icons";
import useStates from "./__use-states";
import useFunctions from "./__use-functions";


const useItems = (widgetAddr: ContentTypes.WidgetAddr | null) => {
  const t = useTranslations();
  const fns = useFunctions();
  const states = useStates(widgetAddr);
  
  /*
    Widget not related
  */
  const getUndo = (): MenuTypes.MenuItem  => ({
    type: MenuTypes.MenuItemType.BUTTON,
    data: {
      title: t('undo'),
      iconComponent: Icon.Undo,
      onClick: (event: React.MouseEvent) => {
        fns.undo();
      },
    }
  });

  const getRedo = (): MenuTypes.MenuItem  => ({
    type: MenuTypes.MenuItemType.BUTTON,
    data: {
      title: t('redo'),
      iconComponent: Icon.Redo,
      onClick: (event: React.MouseEvent) => {
        fns.redo();
      },
    }
  });


  /*
    Widget releated
  */
  const getWidgetDuplicate = (): MenuTypes.MenuItem  => ({
    type: MenuTypes.MenuItemType.BUTTON,
    disabled: ! states.isWidgetSelected,
    data: {
      title: t('widget duplicate'),
      iconComponent: Icon.Widget.Action.Duplicate,
      onClick: (event: React.MouseEvent) => {
        if ( ! widgetAddr ) {
          console.error(`WidgetAddr is null, while duplicating widget`);
          return;
        }
        fns.duplicateWidget(widgetAddr);
      },
    }
  });

  const getWidgetCopy = (): MenuTypes.MenuItem  => ({
    type: MenuTypes.MenuItemType.BUTTON,
    disabled: ! states.isWidgetSelected,
    data: {
      title: t('widget copy'),
      iconComponent: Icon.Widget.Action.Copy,
      onClick: (event: React.MouseEvent) => {
        if ( ! widgetAddr ) {
          console.error(`WidgetAddr is null, while copying widget`);
          return;
        }
        fns.copyWidget(widgetAddr);
      },
    }
  });

  const getWidgetPaste = (): MenuTypes.MenuItem => ({
    type: MenuTypes.MenuItemType.BUTTON,
    disabled: states.isClipboardWidgetsPropsEmpty,
    data: {
      title: t('widget paste'),
      iconComponent: Icon.Widget.Action.Paste,
      onClick: (event: React.MouseEvent) => {
        const imageEdited = states.imageEdited;
        if ( ! imageEdited ) {
          console.error(`ImageEdited is null, while pasting widget`);
          return;
        }
        fns.pasteWidget(imageEdited);
      },
    }
  });


  return {
    getUndo,
    getRedo,

    getWidgetDuplicate,
    getWidgetCopy,
    getWidgetPaste,
  }
}


export default useItems;