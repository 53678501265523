import * as Defaults from './defaults';
import * as Types from './types';


/**
 * State
 */

export type State = {
  point: Types.Point | null,
}

export const getPoint = (
  state: State
): Types.Point | null => { 
  const point = state.point
  return point;
}


/**
 * Initial state
 */

export const createInitialState = (): State => {
  const initState: State = {
    point: null,
  }
  
  return initState;
}
