import React from 'react';
import useDocumentScaleRef from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-ref';
import useSliceRequestSend from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-slicer/use-slice-request-send';
import HLS_ProbeHeightComponent from 'app/ui-v2/editor-instruction/hls/probes/probe-height';


interface Props {
  enabled: boolean;
  onHeightUpdate: (height: number) => void;
  children: React.ReactNode;
}

export const ProbeHeightComponent: React.FC<Props> = (props: Props) => {
  const {
    enabled,
    onHeightUpdate,
    children,
  } = props;

  const scaleRef = useDocumentScaleRef();
  const sendSliceRequest = useSliceRequestSend();

  const handleHeightUpdate = (height: number) => {
    const scale = scaleRef.current !;
    const heightScaled = height / scale;
    onHeightUpdate(heightScaled);
    sendSliceRequest();
  }


  return (
    <HLS_ProbeHeightComponent
      enabled={enabled}
      onHeightUpdate={handleHeightUpdate}
    >
      { children }
    </HLS_ProbeHeightComponent>
  );
}
