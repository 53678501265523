import React from 'react';

import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useDocState } from 'app/ui/contexts/document';
import useDocumentScaleRef from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-ref';

import HeaderCellCSSComponent  from '../../header-cell-css';
import HeaderCellViewComponent from '../../header-cell-view';

import { MainWrapper } from './styles';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
}


export const HeaderCellDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const {
    pageColumnAddr
  } = props;

  const document = useDocState();
  const width = document.headerFields.getColumnWidth(pageColumnAddr);
  const scaleRef = useDocumentScaleRef();
  const scale = scaleRef.current || 1;
  
  return (
    <MainWrapper 
      scale={scale}
      width={width} 
    >
      <HeaderCellCSSComponent pageColumnAddr={pageColumnAddr} >
        <HeaderCellViewComponent pageColumnAddr={pageColumnAddr} />
      </HeaderCellCSSComponent>
    </MainWrapper>
  );
}
  
