import React from 'react';

import HeaderRowComponent from './header-row';
import CellsRowComponent  from './cells-row';
import GridComponent from './grid';


interface Props {
}


export const FieldsGridComponent: React.FC<Props> = (props: Props) => {

  return (
    <GridComponent>
      <HeaderRowComponent />
      <CellsRowComponent />
    </GridComponent>
  );
}
