import { useEffect, useState } from 'react';

import { useMenuKeyBinder } from 'lego-v2/menubar/hooks/use-menu-key-binder';
import * as Types from '../../types';
import MenubarContextComponent from '../menubar-context';
import { Menubar as MenuBarDefalut } from './styles';


interface Props extends Types.MenubarProps {
  MenubarItem: React.ElementType;
}


export const MenubarComponent: React.FC<Props> = (props: Props) => {
  const { 
    config,
    MenubarItem,
  } = props;

  const [
    openItemIdx, 
    setOpenItemIdx
  ] = useState<number | null>(null);
  
  useMenuKeyBinder(config);
  
  const MenuBar = props.MenuBar || MenuBarDefalut;
  
  useEffect(() => {
    props.closeMenu.current = () => {
      setOpenItemIdx(null);
    }
  }, [props.closeMenu.current]);

  const handleItemOpen = (idx: number) => {
    if (openItemIdx !== idx) {
      setOpenItemIdx(idx);
    }
  }

  const handleItemClose = (idx: number) => {
    if (openItemIdx === idx) {
      setOpenItemIdx(null);
    }
  }

  const handleItemOpenToggle = (idx: number) => {
    if (openItemIdx === idx) {
      setOpenItemIdx(null);
      return;
    }

    setOpenItemIdx(idx);
  }

  const handleItemHoverOn = (idx: number) => {
    if (openItemIdx !== null) {
      setOpenItemIdx(idx);
    }
  }

  const handleItemHoverOff = (idx: number) => {
  }

  const renderItems = () => {
    const itemsComponents = config.items.map((item: Types.Item, idx: number) => {
      return (
        <MenubarContextComponent
          key={idx}
          openItem={ () => handleItemOpen(idx) }
          closeItem={ () => handleItemClose(idx) }
          toggleOpenItem={ () => handleItemOpenToggle(idx) }
          hoverItemOn={ () => handleItemHoverOn(idx) }
          hoverItemOff={ () => handleItemHoverOff(idx) }
        >
          <MenubarItem
            config={item}
            idx={idx}
            open={openItemIdx === idx}
          />
        </MenubarContextComponent>
      );
    });

    return itemsComponents;
  }

  return (
    <MenuBar>
      { renderItems() }
    </MenuBar>
  );
}
