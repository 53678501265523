import React from 'react';

import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { useDocState }        from 'app/ui/contexts/document';
import { DNDContext }         from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent       from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';


interface Props {
  markerAddr: HeaderMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerDropComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr,
    children,
  } = props;

  const document = useDocState();
  const getDNDData = useDNDDataGet();

  const { 
    setHeaderMarkers, 
  } = useEditorStatesSetters();


  const handleDrop_fromDocHeader = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;

    document.headerMarkers.moveMarker(
      markerAddrDropped, 
      markerAddr
    );

    document.saveUndo();
    setHeaderMarkers();
  }

  const handleDrop_fromGallery = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;
    
    document.headerMarkers.addMarkerRight(markerAddrDropped, markerAddr);
    document.saveUndo();
    setHeaderMarkers();
  }

  const handleDrop_fromCell = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const markerAddrDropped = data.markerAddr;

    const cellMarkerProps = document.content.cellMarkers_getMarkerProps(markerAddrDropped);

    document.headerMarkers.addMarkerRight(cellMarkerProps.repoMarkerAddr, markerAddr);
    document.saveUndo();
    setHeaderMarkers();
  }

  const contextsDefs: ContextDef[] = [
    {
      dndContext: DNDContext.marker.cell,
      onDrop: handleDrop_fromCell,
    },    
    {
      dndContext: DNDContext.marker.gallery,
      onDrop: handleDrop_fromGallery,
    },
    {
      dndContext: DNDContext.marker.docHeader,
      onDrop: handleDrop_fromDocHeader,
    },
    {
      dndContext: DNDContext.marker.editor,
      onDrop: handleDrop_fromGallery,
    },    
  ];

  return (
    <DNDDropComponent
      contextsDefs={contextsDefs}
    >
      { children }
    </DNDDropComponent>
  );
}
