import { ThemeDefProps } from "../types";


export const themeDefs_margins = (props: ThemeDefProps) => {
  const { colors, darkMode, border, padding, boxShadow } = props;

  const margins = {
    background: darkMode ? "#888" : "#aaa",
  };
    
  return margins;
}

