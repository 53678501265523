import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ViewPanelComponent from 'app/ui-v2/home-page/hls/view-panel';


interface Props {
}


export const ViewUserSignupDoneComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  
  return (
    <ViewPanelComponent title={t("account created")} >
      { t("account created info") }
    </ViewPanelComponent>
  );
}
