const useBrowserSafariVersion = () => {
  const userAgent = navigator.userAgent;

  // Check if the browser is Safari
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  if (isSafari) {
    const versionMatch = userAgent.match(/Version\/([0-9._]+)/);
    if (versionMatch && versionMatch[1]) {

      const releaseText = versionMatch[1];
      const releaseTextSplit: string[] = releaseText.split(".");
      const release: number[] = releaseTextSplit.map(part => parseInt(part));

      return release;
    }
  }

  return null;
}


export default useBrowserSafariVersion;