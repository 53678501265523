import { MenuItemType } from 'lego-v2/menu';
import { MenuConfig   } from 'lego-v2/menu/config';

import { HeaderMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/header-markers';
import { Icon }             from "app/ui/icons/icons";
import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import { useDocState }      from 'app/ui/contexts/document';
import useContextMenu       from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useMarkerEdit        from 'app/ui-v2/editor-markers/hooks/use-marker-edit';

import useDocHeaderMarkerCreate from './use-doc-header-marker-create';
import useEditorStatesSetters   from '../../../hooks/use-editor-states-setters';


const useDocHeaderMarkerMenu = () => {
  const t = useTranslations();
  const document = useDocState();

  const {
    hideMenu
  } = useContextMenu();

  const headerMarkerCreate = useDocHeaderMarkerCreate();

  const {
    setHeaderMarkers
  } = useEditorStatesSetters();

  
  const editMarker__ = useMarkerEdit();

  const createMarker = () => {
    headerMarkerCreate();
    hideMenu();
  }

  const editMarker = (markerAddr: HeaderMarkersTypes.MarkerAddr) => {
    const markerProps = document.headerMarkers.getMarkerProps(markerAddr);
    editMarker__(markerProps.repoMarkerAddr);
    hideMenu();
  }

  const removeMarker = (markerAddr: HeaderMarkersTypes.MarkerAddr) => {
    document.headerMarkers.removeMarker(markerAddr);
    document.saveUndo();
    setHeaderMarkers();
    hideMenu();
  }


  const getConfig = (markerAddr: HeaderMarkersTypes.MarkerAddr) => {
    const config: MenuConfig = {
      sections: [
        {
          items: [
            // {
            //   type: MenuItemType.BUTTON,
            //   data: {
            //     title: t('marker, new'),
            //     iconComponent: Icon.Create,
            //     onClick: (event: React.MouseEvent) => createMarker()
            //   }
            // },   
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, edit'),
                iconComponent: Icon.Edit.Plain,
                onClick: (event: React.MouseEvent) => editMarker(markerAddr)
              }
            },       
          ]
        },      
        {
          items: [
            {
              type: MenuItemType.BUTTON,
              data: {
                title: t('marker, remove'),
                variant: 'delete',
                iconComponent: Icon.Delete,
                onClick: (event: React.MouseEvent) => removeMarker(markerAddr)
              }
            },   
          ],
        },
      ]
    }
  
    return config;
  }
  

  const hook = (markerAddr: HeaderMarkersTypes.MarkerAddr) => {
    const config = getConfig(markerAddr);
    return config;
  }

  return hook;
}


export default useDocHeaderMarkerMenu;