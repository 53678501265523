import React from 'react';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';


interface Props {
  children: React.ReactNode;
}


export const ThemeStyledComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  // 
  // https://styled-components.com/docs/faqs#what-do-i-need-to-do-to-migrate-to-v6
  // 

  // This implements the default behavior from styled-components v5
  const shouldForwardProp = (propName: string, target: any) => {
    // console.log(`Prop to be analyzed ${propName}`);

    if (typeof target === "string") {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
  }

  // For other elements, forward all props
  return true;
}


  return (
    <StyleSheetManager
      shouldForwardProp={shouldForwardProp}
      // enableVendorPrefixes   // that is how it used to be in v5
      // disableVendorPrefixes  // default in v6
    >
      { children }
    </StyleSheetManager>
  );
}

