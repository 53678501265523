import React from 'react';
import LogoComponent from './logo';


interface Props {
}


export const HeaderInfoComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;


  return (
    <LogoComponent />
  );
}

