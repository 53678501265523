import styled from "styled-components";
import PanelThird from "lego/styles/panel_third";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
 

interface VisibleProps {
  $visible: boolean;
}

export const PanelAnchor = styled.div<VisibleProps>`
  position: relative;
  visibility: ${props => props.$visible ? 'visible' : 'hidden' };
  max-height: 0px;
  top: ${props => props.theme.defs.gap.xsmall};
  display: flex;
`;

interface SelectPanelProps {
  $visible?: boolean;
  $height?: number;
}

export const SelectPanel = styled(PanelThird)<SelectPanelProps>`
  position: absolute;
  z-index: 1;

  overflow: clip;
  padding: ${props => props.theme.defs.padding.normal};
  height:  ${props => props.$visible ? `calc(${props.$height}px - ${props.theme.defs.padding.normal} - ${props.theme.defs.padding.normal})` : '0'};
  width:   calc(100% - ${props => props.theme.defs.padding.normal} - ${props => props.theme.defs.padding.normal} - ${props => props.theme.defs.border.width.normal}  - ${props => props.theme.defs.border.width.normal});

  transition: 
    all ${props => props.theme.defs.transition.time.normal} ease-in-out;
`;

export const SelectPanelTester = styled(SelectPanel)`
  height:  auto;
  position: static;
  transition: none;
`;