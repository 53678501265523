import styled from "styled-components";


export const FRAME_CONTROL_BOX_SIZE = 20;
export const FRAME_CONTROL_BOX_OFFSET = 8;

export const FrameControlBox = styled.div`
  position: relative;

  top:  ${-1 * FRAME_CONTROL_BOX_SIZE / 2}px;
  left: ${-1 * FRAME_CONTROL_BOX_SIZE / 2}px;
  
  width:  ${FRAME_CONTROL_BOX_SIZE}px;
  height: ${FRAME_CONTROL_BOX_SIZE}px;

  cursor: pointer;
  box-sizing: border-box;

  outline-style: none;
  
  border-style: solid;
  border-width:  ${props => props.theme.imageEditor.widget.ctrl.border.width};
  border-color:  ${props => props.theme.imageEditor.widget.ctrl.border.color.primary};
  border-radius: ${props => props.theme.imageEditor.widget.ctrl.border.radius};
  background:    ${props => props.theme.imageEditor.widget.ctrl.background.primary};

  transition: all ${props => props.theme.defs.transition.time.normal} ease-in-out;

  &:disabled {
    cursor: not-allowed;
    opacity: ${props => props.theme.defs.opacity.disabled};
  }

  &:active:not(:disabled) {
    background:    ${props => props.theme.imageEditor.widget.ctrl.background.active};
    /* outline-color: ${props => props.theme.defs.colors.border.primary}; */
  }

  &:hover {
    background:    ${props => props.theme.imageEditor.widget.ctrl.background.hover};
    border-color:  ${props => props.theme.imageEditor.widget.ctrl.border.color.hover};
  }
`;