import React from 'react';

import ViewPanelComponent from 'app/ui-v2/home-page/hls/view-panel';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import TextError from 'app-views/styles/text-error';


interface Props {
}


export const TokenExpiredComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  
  return (
    <ViewPanelComponent title={t("password token expired")} >
      <TextError>
      { t("password token expired info") }
      </TextError>
    </ViewPanelComponent>
  );
}

