import React from 'react';
import { useState } from 'react';

import jtl from 'tools/jtl';
import Logger from 'libs/debug';
import environment from 'app/environment';

import PanelTitledComponent from 'app-views/components/panel-titled';

import { ReposTypes }       from 'app/arch/app/states/repos';
import { MsgLevel }         from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useMsgBox }        from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import useRepoMemberAdd     from 'app/ui-v2/app/hooks/use-repo-member-add';
import useRepoFetchReload   from 'app/ui-v2/app/hooks/use-repo-fetch-reload';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';
import RepoMemberAccessSelectorComponent from 'app/ui-v2/home-page/hls/repo-member-access-selector';

import RepoInviteEmailComponent from './repo-invite-email';

import { 
  ProgressBox,
  ButtonSendInvite,
  ButtonShowPanel,
  ButtonShowWrapper,
  CellAccessTitle, 
  CellAccessValue, 
  CellEmailTitle, 
  CellEmailValue, 
  CellSendBtn,
  Grid, 
  Panel,
  ProgressWrapper,
  TextWrap,
} from './styles';


interface Props {
  repoId: number;
}


export const RepoSendInviteComponent: React.FC<Props> = (props: Props) => {
  const {
    repoId
  } = props;

  const t = useTranslations();
  const emailInit__ = (
    environment.dev ?
    "test@flexedit.app" :
    ""
  );

  const [email, setEmail] = useState<string>(emailInit__);
  const [memberAccess, setMemberAccess] = useState(ReposTypes.MemberAccess.VIEWER);
  const addRepoMember = useRepoMemberAdd();
  const [visible, setVisible] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);

  const {
    showMsgBox
  } = useMsgBox();

  const reloadRepo = useRepoFetchReload();
  
  const handleSendInvite = () => {
    setSendingInvite(true);

    addRepoMember({
      repoId,
      email,
      access: memberAccess,
      onDone: () => {

        reloadRepo({
          repoId,
          onDone: () => {
            // setMemberAccess(ReposTypes.MemberAccess.VIEWER);
            setEmail("");
            setSendingInvite(false);
          },
          onError: (error: string) => {
            setSendingInvite(false);
          }
        });
      },
      onError: (error: string) => {
        setSendingInvite(false);
      },
      onLimit: (limitType: string) => {
        const logger = Logger.getAppLimits();
        logger.log(`Can't send repo invite, feature limit reached: ${limitType}`);
        setSendingInvite(false);
      }
    });
  }

  const checkAndSendInvite = () => {
    if ( ! jtl.email.isValid(email)) {
      showMsgBox(t("invalid email address"), MsgLevel.WARN);
      return;
    }

    handleSendInvite();
  }

  const sendEmailDisabled = (
    ! jtl.email.isValid(email) ||
    sendingInvite
  );

  const renderProgress = () => (
    <ProgressWrapper>
      <ProgressBoxComponent 
        title={t("repository invitation sending")} 
        Box={ProgressBox}
      />
    </ProgressWrapper>
  );

  const renderShowButton = () => (
    <ButtonShowWrapper>
      <ButtonShowPanel
        onClick={() => { setVisible(true); }}
      >
        <TextWrap>
        { t("repository button, invite new member") }
        </TextWrap>
      </ButtonShowPanel>
    </ButtonShowWrapper>
  );

  const renderContent = () => (
    <Grid>
      
      <CellEmailTitle $disabled={sendingInvite}>
        <TextWrap>
        { t("repository invite, email") }
        </TextWrap>
      </CellEmailTitle>

      <CellEmailValue>
        <RepoInviteEmailComponent 
          disabled={sendingInvite}
          email={email}
          onEmailChange={setEmail}
          onSendInvitation={checkAndSendInvite}
        />
      </CellEmailValue>

      <CellAccessTitle $disabled={sendingInvite}>
        <TextWrap>
        { t("repository invite, member access") }
        </TextWrap>
      </CellAccessTitle>

      <CellAccessValue>
        <RepoMemberAccessSelectorComponent 
          disabled={sendingInvite}
          memberAccess={memberAccess}
          onSetMemberAccess={setMemberAccess}
        />
      </CellAccessValue> 

      <CellSendBtn>
        <ButtonSendInvite
          disabled={sendEmailDisabled}
          onClick={handleSendInvite}
        >
          <TextWrap>
          { t("repository invite, send") }
          </TextWrap>
        </ButtonSendInvite>
      </CellSendBtn>

    </Grid>
  );

  return (
    <PanelTitledComponent
      Panel={Panel}
      title={t('repository panel, invite new member')}
    >
      { sendingInvite && renderProgress() }
      { ! visible && renderShowButton() }
      { visible && renderContent() }
    </PanelTitledComponent>
  );
}
  
