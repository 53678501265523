import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { useApp } from 'app/ui/hooks/app/use-app';


interface Props {
  children: React.ReactNode;
}


/**
 * We need to check here if it is printout view - and we
 * can't use editor states - as they are not existing yet.
 * And even before editor has been loaded we need to know
 * if it print view - therefore check needs to be done
 * at the very begining of application loading.
 */
export const LoaderIsPrintoutComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const [ready, setIsReady] = useState(false);
  const app = useApp();

  useEffect(() => {
    const url = window.location.href;
    const isPrintout = url.endsWith("view-printout");
    app.appState.updateState({isPrintout});
    
    setIsReady(true);
  }, []);

  if ( ! ready ) {
    return null;
  }

  return children;
}
  
