import React from 'react';
import { useRecoilValue } from 'recoil';

import { SideControlsTypes } from 'lego/components/side-controls';
import SideToolbarBaseComponent from 'lego/components/side-controls/side-toolbar';
import { getConfig } from './config';

import { UserPanelTypes } from 'app/arch/home-page-user/states/user-panel';
import { useHomePageUserState } from 'app/ui-v2/home-page/home-page-user/hooks';
import { useHomePageUserStateSetters } from 'app/ui-v2/home-page/home-page-user/hooks';
import { UIHomePageUser_UserPanel } from 'app/ui-v2/home-page/home-page-user/states';


type Props = SideControlsTypes.PropLeftOrRight;


export const SideToolbarComponent: React.FC<Props> = (props: Props) => {
    const {
    left,
    right,
  } = props;

  const homePageUser = useHomePageUserState();
  const panel = useRecoilValue(UIHomePageUser_UserPanel.panel);

  const {
    setUserPanel
  } = useHomePageUserStateSetters();


  const switchPanel = (newPanelType: UserPanelTypes.PanelItem) => {
    if (panel.visible) {
      if (panel.selected !== newPanelType) {
        homePageUser.userPanel.update({selected: newPanelType});
      }
      else {
        homePageUser.userPanel.update({visible: false});
      }
    }
    else {
      homePageUser.userPanel.update({visible: true});
      homePageUser.userPanel.update({selected: newPanelType});
    }

    setUserPanel();
  }

  const handleUserProfile = () => {
    switchPanel(UserPanelTypes.PanelItem.USER_PROFILE);
  }

  const handleTheme = () => {
    switchPanel(UserPanelTypes.PanelItem.THEME);
  }

  const handleBilling = () => {
    switchPanel(UserPanelTypes.PanelItem.BILLING);
  }

  const cmds = {
    handleUserProfile,
    handleBilling,
    handleTheme,
  };

  const states = {
    panelVisible: panel.visible,
    panelType: panel.selected,
  };

  const config = getConfig(cmds, states);
  
  return (
    <SideToolbarBaseComponent
      config={config}
      
      left={left}
      right={right}      
    />
  );
}

