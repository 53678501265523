import React from 'react';

import SectionsRowsWatcherComponent from './components/sections-rows-watcher';

import usePageFormatWatcher      from './hooks/use-page-format-watcher';
import useDocHeaderItemsWatcher  from './hooks/use-doc-header-items-watcher';
import useSectionsEnabledWatcher from './hooks/use-sections-enabled-watcher';
import useSectionsAddrsdWatcher  from './hooks/use-sections-addrs-watcher';


interface Props {
}


export const WatchersComponent: React.FC<Props> = (props: Props) => {
  usePageFormatWatcher();
  useDocHeaderItemsWatcher();
  useSectionsEnabledWatcher();
  useSectionsAddrsdWatcher();
  
  return (
    <>
      <SectionsRowsWatcherComponent />
    </>
  );
}
