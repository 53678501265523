import { useRecoilValue } from 'recoil';
import { UIState_ReleaselogsSession } from 'app/ui/states/editor-instruction';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';


const useColumnSelected = () => {
  
  const selectedCellAddr__   = useRecoilValue(UIState_ReleaselogsSession.cellSelected);
  const selectedColumnAddr__ = useRecoilValue(UIState_ReleaselogsSession.columnSelected);

  const selectedColumnAddr: Types.ColumnAddr | null  = (
    selectedColumnAddr__ !== null ?
    selectedColumnAddr__ :
    (
      selectedCellAddr__ !== null ?
      selectedCellAddr__ :
      null
    )
  );
 
  return selectedColumnAddr;
}


export default useColumnSelected;