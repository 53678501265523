import styled from "styled-components";


export const MainWrapper = styled.div`

`;

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;


export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;


export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;