import useModals from '../../hooks/use-modals';


const useRelogin = () => {
  const modals = useModals();
  const relogin = modals.relogin;

  return relogin;
}


export default useRelogin;