import styled from "styled-components";
import DivButton from "lego/styles/div_button";
import InputBase from "lego/styles/input";


export const MainWrapperButton = styled(DivButton)`
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  flex-grow: 1;
  height: 100%;

  border-width:  0;
  border-style:  none;
  border-radius: 0px;

  padding-left: ${props => props.theme.defs.padding.small};
  /* background:    ${props => props.selected ? props.theme.defs.accentColor.primary : props.theme.button.background.primary}; */
  /* background-color: red; */
`;

export const Text = styled.div`
  min-width: 30px;
  /* max-width: 136px; */

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Input = styled(InputBase)`
  position: relative;
  z-index: 1;

  width: calc(100% - 5px);
  height: 24px;
  
  border: none;
  background: none;

  padding: 0 0 0 4px;

  color:     ${props => props.theme.button.color.primary};
  font-size: ${props => props.theme.defs.font.size.normal};
`;