import styled from "styled-components";
import ButtonGroupLeftBase   from "lego/styles/button_group_left";
import ButtonGroupMiddleBase from "lego/styles/button_group_middle";
import ButtonGroupRightBase  from "lego/styles/button_group_right";


export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const ButtonGroupLeft = styled(ButtonGroupLeftBase)`
`;

export const ButtonGroupRight = styled(ButtonGroupRightBase)`
`;
