import styled from 'styled-components';
import TextBase        from 'lego/styles/text';
import RevisionBase    from '../styles/revision';
import ReleaseDateBase from '../styles/release-date';
import TextWrapBase from 'lego/styles/text-wrap';
import PanelHeaderBase from 'lego/styles/panel-header';


export const PanelHeader = styled(PanelHeaderBase)` 
`;


export const RevisionHeader = styled(RevisionBase)`
  display: flex;
  align-items: center;

  height: 100%;
  user-select: none;
  /* background: red; */
`;


export const ReleaseDateHeader = styled(ReleaseDateBase)`
  display: flex;
  align-items: center;

  height: 100%;
  user-select: none;
  /* background: red; */
`;

export const ProgressWrapper = styled.div`
  height: 100px;
  /* background: rgba(255, 0,255, 0.2); */
`;


export const TextWrap = styled(TextWrapBase)`
`;


export const Text = styled(TextBase)`
  opacity: ${props => props.theme.defs.opacity.disabled};
  
  text-align: center;
  white-space: wrap;
  /* background: ${props => props.theme.defs.colors.background.secondary}; */
`;

export const ItemPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.normal};

  box-sizing: border-box;
  border-style: none;
  
  padding-top: ${props => props.theme.defs.padding.small};
`;