import React from 'react';
import { HeaderFieldsTools, HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';

import { useDocState } from 'app/ui/contexts/document';
import CellRenderComponent from './cell-render';
import { GridCell } from './styles';


interface Props {
  pageCellAddr: Types.PageCellAddr;
}


export const CellComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr
  } = props;
  
  const document = useDocState();

  const columnAddr = {
    columnId: pageCellAddr.columnId
  }

  const cellIdx = document.headerFields.getColumnIdx(columnAddr);
  const borderLeft = (cellIdx === 0);

  const pageCellDataTest = HeaderFieldsTools.getPageCellDataTest({
    pageCellAddr,
    docState: document,
    prefix: 'header-fields-cell'
  });

  return (
    <GridCell 
      data-test={pageCellDataTest}
      borderLeft={borderLeft} 
    >
      <CellRenderComponent pageCellAddr={pageCellAddr} />
    </GridCell>
  );
}
  
