import React from 'react';
import { HeaderFieldsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-fields';

import { useDocState }  from 'app/ui/contexts/document';
import { DNDContext }   from 'app/ui-v2/editor-instruction/dnd-context';
import DNDDropComponent from 'app/ui-v2/editor-instruction/hls/dnd-drop';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useDNDDataGet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-get';
import { ContextDef } from 'app/ui-v2/editor-instruction/hls/dnd-drop/types';

import { DropWrapper } from './styles';


interface Props {
  pageColumnAddr: HeaderFieldsTypes.PageColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellDropComponent: React.FC<Props> = (props: Props) => {
  const {
    pageColumnAddr,
    children
  } = props;

  const docState  = useDocState();
  const getDNDData = useDNDDataGet();
  
  const {
    setHeaderFields,
    setCustomFieldsColumnsSelected,
  } = useEditorStatesSetters();
  

  const handleDrop = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const data = JSON.parse(dataSerial);
    const droppedColumnAddr = data.columnAddr;

    const moved = docState.headerFields.moveColumn(droppedColumnAddr, pageColumnAddr);
    if ( ! moved ) {
      return;
    }

    docState.saveUndo();
    docState.customFieldsColumnsSelected.reset();

    setHeaderFields();
    setCustomFieldsColumnsSelected();
  }

  const handleDragOver = () => {
    const dataSerial = getDNDData();
    if ( ! dataSerial ) {
      return;
    }

    const isSelected = docState.customFieldsColumnsSelected.isSelected(pageColumnAddr);
    if (isSelected) {
      return;
    }
    
    docState.customFieldsColumnsSelected.setDragOver(pageColumnAddr);
    setCustomFieldsColumnsSelected();
  }

  const handleDragLeave = () => {
    docState.customFieldsColumnsSelected.setDragOver(null);
    setCustomFieldsColumnsSelected();
  }

  const contextsDefs: ContextDef[] = [{
    dndContext: DNDContext.viewContent.customFields.column,
    onDrop: handleDrop,
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
  }];

  return (
    <DNDDropComponent 
      contextsDefs={contextsDefs} 
      Wrapper={DropWrapper}
    >
      { children }
    </DNDDropComponent>
  );
}
  
