import { atom     } from 'recoil';
import { selector } from 'recoil';
import { InstructionState as State } from 'app/arch/editor-instruction/document/states/loadable/instruction';


export const state = atom<State.State>({
  key: "ei_instruction",
  default: State.createInitialState()
});


//--------------------
// Selectors
//
export const docRevision = selector({
  key: "ei_instruction_revision",
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.revision;
  }
});

