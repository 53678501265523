import { atom }           from 'recoil';
import { selector }       from 'recoil';
import { selectorFamily } from 'recoil';

import { HeaderRowsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { HeaderRowsSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/header-rows-session';


/**
 * State
 */
export const state = atom<State.State>({
  key: "ei_docContent_customRowsSession",
  default: State.createInitialState()
});

/**
 * Page Cell
 */
export const pageCellSelected = selector({
  key: "ei_docContent_customRowsSession_pageCellSelected",
  get: ({ get }) => {
    const stateObj = get(state);
    const selectedCellAddr = State.getPageCellSelected(stateObj);
    return selectedCellAddr;
  }
});

export const isPageCellSelected = selectorFamily({
  key: "ei_docContent_customRowsSession_isPageCellSelected",
  get: (pageCellAddr: Types.PageCellAddr) => ({ get }) => {
    const stateObj = get(state);
    const selected = State.isPageCellSelected(stateObj, pageCellAddr);
    return selected;
  }
});
