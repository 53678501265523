import ExcelJS from 'exceljs';
import jtl from 'tools/jtl';


class TextConverter {

  convert(lexicalState: any): ExcelJS.RichText[] {
    const output: ExcelJS.RichText[] = [];

    // console.log(lexicalState)
    let currentListIndex = 1; // Track the current index for numbered lists
  
    // Recursive function to process each node
    const processNode = (node: any) => {
      if (node.children) {
          node.children.forEach((child: any) => processNode(child));
      }
  
      // Handle text nodes
      if (node.type === 'text' && node.text) {
        const font: Partial<ExcelJS.Font> = {
          size: 12,
          color: {},
          name: 'Calibri',
          scheme: 'minor',
        };
  
        // 
        // Extract font color based on style
        // Background can't be set on excel spreadsheet
        // (it looks like background color is a property
        //  for activated excels only). 
        // For that reason if background-color is present
        // we use it to set font color. If color is also set
        // then it take precedense before background-color
        if (node.style) {
          const bgcolorMatch = node.style.match(/background-color:\s*([^;]+)/);
          if (bgcolorMatch && font.color ) {
            const color = jtl.color.hex2argb(bgcolorMatch[1]);
            font.color.argb = color; 
          }

          const colorMatch = node.style.match(/[^-]*color:\s*([^;]+)/);
          if (colorMatch && font.color ) {
            const color = jtl.color.hex2argb(colorMatch[1]);
            if (color !== 'FFFFFFFF') {
              // If font is white
              // than it won't be visible on white
              // cell background
              font.color.argb = color; 
            }
          }

          const fontSizeMatch = node.style.match(/font-size:\s*([^;]+)/);
          if (fontSizeMatch ) {
            font.size = jtl.css.valueToNumber(fontSizeMatch[1]);
          }

        } else {
          if ( font.color ) {
            font.color.theme = 1;
          }
        }
  
        if (node.format) {
          const BOLD = 1;
          const ITALIC = 2;

          font.bold = (node.format & BOLD) === BOLD;
          font.italic = (node.format & ITALIC) === ITALIC;
        } 
        
        // // Check for bold or italic styles
        // if (node.style && node.style.includes('font-weight: bold')) {
        //   font.bold = true;
        // }
        // if (node.mode === 'italic') {
        //   font.italic = true;
        // }
  
        // // Insert a space before text if there's a previous text item
        // if (output.length > 0) {
        //     const lastItem = output[output.length - 1];
        //     if (lastItem.text.trim() !== '') {
        //         output.push({ font: { ...font, size: 12, color: { theme: 1 }, name: 'Calibri', scheme: 'minor' }, text: ' ' });
        //     }
        // }
  
        // Push the formatted text item to the output array
        output.push({ font, text: node.text });
      }
  
      //   // Handle lists
      //   if (node.type === 'list') {
      //     if (node.listType === 'number') {
      //         // Reset the index for numbered lists
      //         currentListIndex = node.start || 1;
      //     } else {
      //         // Handle unnumbered lists (bulleted)
      //         output.push({ font: { ...output[0].font }, text: '• ' }); // Bullet point for unnumbered list
      //     }
      // }
  
      // // Handle list items
      // if (node.type === 'listitem') {
      //     if (node.parent && node.parent.type === 'list' && node.parent.listType === 'number') {
      //         // Format numbered list item
      //         const listItemText = `${currentListIndex}. ${node.children.map((child: any) => child.text).join('')}`;
      //         output.push({ font: { ...output[0].font }, text: listItemText });
      //         currentListIndex++; // Increment for the next numbered item
      //     } else {
      //         // Format unnumbered list item
      //         const listItemText = node.children.map((child: any) => child.text).join('');
      //         output.push({ font: { ...output[0].font }, text: `• ${listItemText}` });
      //     }
      // }
  
      // Handle new lines
      if (node.type === 'paragraph' || node.type === 'listitem') {
        if (output.length > 0) {
          // Insert a new line before the next text
          output.push({ font: { ...output[0].font }, text: '\n' });
        }
      }
    };
  
    // Start processing from the root node
    processNode(lexicalState.root);
    
    // console.log(output);
    return output;
  }
}


export default TextConverter;
