import styled from "styled-components";


const MarkerText = styled.div`
  user-select: none;
  box-sizing: border-box;
  border-radius: ${props => props.theme.defs.border.radius.small};

  text-align: center;
  text-transform: uppercase;
  /* overflow-wrap: anywhere; */
  word-break: break-word;

  padding: 
    ${props => props.theme.defs.padding.medium} 
    ${props => props.theme.defs.padding.large}
  ;
`;


export default MarkerText;