import React from 'react';
import SelectedWidgetInfoComponent from './selected-widget-info';

import { WidgetInfoAnchor }   from './styles';
import { WidgetInfoDetacher } from './styles';


interface Props {
  children: React.ReactNode;
}


export const EditorImageWidgetInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;


  return (
    <>
      <WidgetInfoAnchor>
        <WidgetInfoDetacher>
          <SelectedWidgetInfoComponent />
        </WidgetInfoDetacher>
      </WidgetInfoAnchor> 

      { children }
    </>
  );
}
