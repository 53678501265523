import * as docx from "docx";

import DocState from 'app/arch/editor-instruction/document/states/doc-state';
import { HeaderRowsTypes } from "app/arch/editor-instruction/document/states/persistent/header-rows";

import AssetsRepo from "../../../parts/assets-repo";
import ExporterBase from "../../parts/exporter-base";
import * as Defaults from '../../defaults';
import TextConverter from "../../parts/text-converter";


class CustomRowsExporter extends ExporterBase {
  private _columnsWidthTotal: number;

  constructor(docState: DocState, assetsRepo: AssetsRepo) {
    super(docState, assetsRepo);
    this._columnsWidthTotal = 0;
  }

  get content() { return this._docState.content; }

  createSections() {
    this._calculateColumnsWidth();
    const table = this._createTable();
    const sections = [ table ];

    return sections;
  }

  private _createTable() {
    const rows = this._createRows();
    
    const table = new docx.Table({
      layout: docx.TableLayoutType.FIXED,
      borders: this.getBordersNone(),
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE,
      },
      rows,
    });
    
    return table;
  }

  private _createRows() {
    const headerRows = this._docState.headerRows;
    const rowsAddrs = headerRows.getRowsAddrs();

    const rows = rowsAddrs.map(rowAddr => {
      const cells = this._createCells(rowAddr);
      const row = new docx.TableRow({
        children: cells,
      });
      return row;
    });

    return rows;
  }

  private _createCells(rowAddr: HeaderRowsTypes.CellAddr) {
    const cellHeader = this._getCellHeader(rowAddr);
    const cellValue = this._getCellValue(rowAddr);
    const cells = [cellHeader, cellValue];

    return cells;
  }

  private _getCellHeader(rowAddr: HeaderRowsTypes.CellAddr) {
    const headerRows = this._docState.headerRows;
    const sizePerc = this._getColumnSize(0);
    const rowProps = headerRows.getRowProps(rowAddr);
    
    const textOpt = Defaults.getTextHeaderOptions({
      text: rowProps.name,
    });

    const text = new docx.TextRun(textOpt);

    const descriptionPara = new docx.Paragraph({
      alignment: docx.AlignmentType.LEFT,
      children: [text]
    });

    const docxCell = new docx.TableCell({
      borders: this.getBordersFull(),
      shading: this.getHeaderShading(),
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: [descriptionPara]
    });

    return docxCell;
  }
  
  private _getCellValue(rowAddr: HeaderRowsTypes.CellAddr) {
    const sizePerc = this._getColumnSize(1);
    const headerRows = this._docState.headerRows;
    const cell = headerRows.getCell(rowAddr);
    const textConverter = new TextConverter();
    const paragraphs = textConverter.convert(cell.text);
    
    const docxCell = new docx.TableCell({
      borders: this.getBordersFull(),
      width: {
        size: sizePerc,
        type: docx.WidthType.PERCENTAGE,
      },
      children: paragraphs
    });

    return docxCell;
  }

  private _getColumnSize(idx: number) {
    const headerRows = this._docState.headerRows;
    const columnsProps = headerRows.getColumnsProps();
    const columnProps = columnsProps[idx];
    const sizePerc = (columnProps.width / this._columnsWidthTotal) * 100;
    // console.log(`${idx} ${sizePerc.toFixed(2)} ${columnProps.width.toFixed(2)}`);
    return sizePerc;
  }

  private _calculateColumnsWidth() {
    const headerRows = this._docState.headerRows;
    const columnsProps = headerRows.getColumnsProps();
    
    this._columnsWidthTotal = columnsProps.reduce((acc, columnProps) => {
      return acc + columnProps.width;
    }, 0);
  }
}


export default CustomRowsExporter;