import Demo from './demo';

import Clicks from './clicks';
import { ClicksState } from './clicks';
import { ClicksTools } from './clicks';
import { ClicksTypes } from './clicks';

import Mouse from './mouse'
import { MouseState } from './mouse';
import { MouseTools } from './mouse';
import { MouseTypes } from './mouse';

import Infos from './infos'
import { InfosState } from './infos';
import { InfosTools } from './infos';
import { InfosTypes } from './infos';



export { Clicks as DemoClicks };
export { ClicksState as DemoClicksState };
export { ClicksTools as DemoClicksTools };
export { ClicksTypes as DemoClicksTypes };

export { Mouse as DemoMouse };
export { MouseState as DemoMouseState };
export { MouseTools as DemoMouseTools };
export { MouseTypes as DemoMouseTypes };

export { Infos as DemoInfos };
export { InfosState as DemoInfosState };
export { InfosTools as DemoInfosTools };
export { InfosTypes as DemoInfosTypes };

export default Demo;
