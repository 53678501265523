import React from 'react';
import TooltipComponent from 'lego/components/tooltip';
import { Button } from './styles';


interface Props {
  Icon: React.ElementType;
  tooltip?: string;
  onClick: (event: React.MouseEvent) => void;
}


export const ButtonUploadComponent: React.FC<Props> = (props: Props) => {
  const {
    Icon,
    tooltip,
    onClick
  } = props;

  const dataTest = (
    `doc-editor`
    + `__images-gallery`
    + `__btn__upload-imgs`
  );

  return (
    <TooltipComponent
      title={tooltip}
      placement='right'
    >
      <Button 
        data-test={dataTest}
        onClick={onClick}
      >
        <Icon />
      </Button>
    </TooltipComponent>
  );
}
  
