import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useSelectedReset = () => {
  const document = useDocState();

  const {
    setHeaderMarkersSession
  } = useEditorStatesSetters();

  const hook = (props: {
    skipStateUpdate?: boolean
  } = {
    skipStateUpdate: false
  }) => {
    const skipStateUpdate = props.skipStateUpdate;
    document.headerMarkersSession.setMarkerSelected(null);

    if (skipStateUpdate) {
      return;
    }

    setHeaderMarkersSession();
  }

  return hook;
}


export default useSelectedReset;
