import React from 'react';
import * as Types from '../types';

import IconContextComponent  from './icon-context';
import IconQuestionComponent from './icons/icon-question';
import IconInfoComponent     from './icons/icon-info';
import IconWarningComponent  from './icons/icon-warning';
import IconErrorComponent    from './icons/icon-error';


interface Props {
  icon: Types.IconType;
}


export const IconRenderComponent: React.FC<Props> = (props: Props) => {
  const {
    icon,
  } = props;


  const IconsMap: {[iconType in Types.IconType] : React.FC<{}> } = {
    [Types.IconType.QUESTION]: IconQuestionComponent,
    [Types.IconType.INFO    ]: IconInfoComponent,
    [Types.IconType.WARNING ]: IconWarningComponent,
    [Types.IconType.ERROR   ]: IconErrorComponent,
  }

  const Icon = IconsMap[icon];
  
  return (
    <IconContextComponent>
      <Icon />
    </IconContextComponent>
  );
}

