import { SubscriptionsTypes } from 'app/arch/app/user/states/subscriptions';
import useUserSubscriptionFetch from './use-user-subscription-fetch';
import useUserSubscriptions from './use-user-subscriptions';
import useUserStateSetters from './use-user-state-setters';


type OnDoneFn = () => void;
type OnErrorFn = (error: string) => void;


export interface HookProps {
  subscriptionId: string;
  onDone?: OnDoneFn;
  onError?: OnErrorFn;
}

const useUserSubscriptionReload = () => {
  const fetch = useUserSubscriptionFetch();
  const userSubscriptions = useUserSubscriptions();
  const {
    setUserSubscriptions 
  } = useUserStateSetters();
  
  const hook = ({ 
    subscriptionId,
    onDone,
    onError,
  }: HookProps) => {

    fetch({
      subscriptionId,
      onDone: (subscriptionRaw: SubscriptionsTypes.SubscriptionRaw) => {
        userSubscriptions.updateSubscription(subscriptionRaw);
        setUserSubscriptions();
        onDone?.();
      },
      onError: onError,
    });
  }

  return hook;
}
  

export default useUserSubscriptionReload;