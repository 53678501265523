import { useRef } from "react";

import useSelectUserImage from "lego/components/user-files-selector/use-select-user-image";

import { LogoImageTool }   from "app/arch/editor-logo/logo-image";
import { RepoImagesTypes } from "app/arch/editor-instruction/document/states/loadable/repo-images";
import { useDocState }    from "app/ui/contexts/document";
import { UploadFileType } from "app/ui/hooks/editor-instruction/use-upload-files";
import { useUploadFiles } from "app/ui/hooks/editor-instruction/use-upload-files";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { LogoImage } from "../types";


type OnDoneType = () => void;


const useLogoUpload = () => {
  const document = useDocState();
  const uploadLogo = useUploadFiles(UploadFileType.LOGO);
  const onDoneRef = useRef<OnDoneType | null>(null);

  const {
    setHeaderMeta,
    setHeaderMetaSession
  } = useEditorStatesSetters();

  const resetUploadProgress = () => {
    document.headerMetaSession.updateLogo({uploading: false});
    setHeaderMetaSession();
  }

  //---------------
  // Select files
  //
  const handleFilesSelected = (files: File[]) => {
    document.headerMetaSession.updateLogo({uploading: true});
    setHeaderMetaSession();

    uploadLogo({
      files,
      onDone: (ok: boolean, data: any) => {
        const imageRaw: RepoImagesTypes.ImageRaw = data;
        onLogoUploadDone(ok, imageRaw);
      },
      onError: (error: string) => {
        resetUploadProgress();
      }
    });
  }

  const handleFilesSelectionCancel = () => {
    onDoneRef.current = null;
  }

  const selectFiles =  useSelectUserImage({
    onFilesSelected: handleFilesSelected,
    onFilesSelectionCancel: handleFilesSelectionCancel
  });


  //--------------
  // Upload logo
  //
  const onLogoUploadDone = (ok: boolean, imageRaw: RepoImagesTypes.ImageRaw) => {
    resetUploadProgress();

    if ( ! ok ) {
      console.error(`Could not upload logo image`);
      return;
    }

    const logoImageData = {
      filename: imageRaw.filename,
      width: imageRaw.width,
      height: imageRaw.height,
      bid: imageRaw.id
    } as LogoImage;

    const logoImageTool = new LogoImageTool(logoImageData)
    const { scale, position } = logoImageTool.fitToView();

    document.headerMeta.updateLogoImage(logoImageData);
    document.headerMeta.updateLogoView({scale});
    document.headerMeta.updateLogoView({position});
    document.saveUndo();
    setHeaderMeta();

    onDoneRef.current?.();
    onDoneRef.current = null;
  }


  //--------------------
  // Returned function
  //
  interface Props {
    onDone?: OnDoneType;
  }

  const doUploadLogo = (props?: Props) => {
    if (props) {
      onDoneRef.current = props.onDone || null;
    }

    selectFiles();
  }

  return doUploadLogo;
}

export default useLogoUpload;