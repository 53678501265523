import React         from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { useIsMobile } from 'lego-hooks/use-is-mobile';
import { settings }    from 'app/configs';
import { Size }        from 'app/arch/types';
import { UUID }        from 'app/arch/types';
import { DeltaMove }   from 'app/ui/hooks';
import { useDraggingDelta } from 'app/ui/hooks';
import useDocumentScaleRef from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-ref';

import { Column } from '../types';

import { Mark }        from './styles';
import { MainWrapper } from './styles';
import { ActiveArea }  from './styles';
import { MarkerLine }  from './styles';


const SET_COLUMN_WIDTH_DELAY = settings.rateControl.resizing.interval;


interface Props {
  dataTest: string | null;

  columnId: UUID;
  columns: Column[];

  contentSize: Size;
  postitionLeft: number;

  onResizeStart?: () => void;
  onSetColumnsWidth: (columns: Column[]) => void;
  onResizeEnd?: () => void;

  debug?: boolean;
}


export const ResizerComponent: React.FC<Props> = (props: Props) => {
  const {
    dataTest,

    columnId,
    columns,

    contentSize,
    
    onResizeStart,
    onSetColumnsWidth,
    onResizeEnd,

    debug
  } = props;

  const [
    postionLeft, 
    setPositionLeft
  ] = useState(props.postitionLeft);
  
  const [
    resizing, 
    setResizing
  ] = useState(false);
  
  const scaleRef = useDocumentScaleRef();

  const initPostionLeftRef       = useRef<number>(0);
  const initColumnWidthRef       = useRef<number>(0);

  const columnsAfterSelectedRef  = useRef<Column[]>([]);
  const columnsAfterViewRatioRef = useRef<number[]>([]);

  const columnsUpdatedRef        = useRef<Column[]>([]);

  const isMobile = useIsMobile();

  useEffect(()=> {
    if (resizing) return;
    setPositionLeft(props.postitionLeft);
  }, [props.postitionLeft]);


  const updateColumns_debounced = useDebouncedCallback((delta: DeltaMove) => {
    updateColumns(delta);
  }, SET_COLUMN_WIDTH_DELAY);


  const handleDraggerStart = (event: React.PointerEvent) => {
    const started = startDragging(event);
    if ( ! started ) {
      return;
    }
    
    const column = columns.find((column: Column) => column.id === columnId);
    if (column === undefined) {
      const msg = `Column not found while resizing. Column id ${columnId}`;
      throw new Error(msg);
    }
    
    const columnIdx = columns.indexOf(column);

    //------------------
    //    
    initPostionLeftRef.current = postionLeft;
    initColumnWidthRef.current = column.width;

    columnsUpdatedRef.current = columns.map((column: Column) => ({...column}));

    columnsAfterSelectedRef.current = columns
      .slice(columnIdx + 1)
      .map((column) => ({...column}));

    //------------------
    //    
    const columnsAfterTotalWidth = columnsAfterSelectedRef.current.reduce(
      (accumulator, column) => accumulator + column.width, 0);

    columnsAfterViewRatioRef.current = columnsAfterSelectedRef.current.map((column) => column.width / columnsAfterTotalWidth);

    setResizing(true);

    onResizeStart?.();
  }

  const handleDragMove = (delta: DeltaMove) => {
    updatePostitionLocal(delta);
    updateColumns_debounced(delta);
  }

  const handleDragEnd = (delta: DeltaMove) => {
    updateColumns_debounced.cancel();
    updateColumns(delta);

    setResizing(false);

    onResizeEnd?.();
  }

  const { 
    startDragging
  } = useDraggingDelta({
    onDragMove: handleDragMove,
    onDragEnd: handleDragEnd,
  });

  const updatePostitionLocal = (delta: DeltaMove) => {
    const scale = scaleRef.current !;
    const deltaWidthScaled   = delta.x / scale;
    const minColumnWidth     = settings.printView.column.size.min.width;
    const newColumnWidthTmp  = initColumnWidthRef.current + deltaWidthScaled;
    const newColumnWidth     = Math.max(minColumnWidth, newColumnWidthTmp);
    const deltaWidthAdjusted = newColumnWidth - initColumnWidthRef.current;
    const newLeftPosition    = initPostionLeftRef.current + deltaWidthAdjusted;
    
    setPositionLeft(newLeftPosition);
  }

  const updateColumn =(columnId: UUID, width: number)=> {
    const columns = columnsUpdatedRef.current;
    const column = columns.find((column) => column.id === columnId);
    if (column === undefined) {
      const msg = 'Column not found';
      throw new Error(msg);
    }

    column.width = width;
  }

  const updateColumns = (delta: DeltaMove) => {
    const scale = scaleRef.current !;
    const deltaWidthScaled   = delta.x / scale;
    const minColumnWidth     = settings.printView.column.size.min.width;
    const newColumnWidthTmp  = initColumnWidthRef.current + deltaWidthScaled;
    const newColumnWidth     = Math.max(minColumnWidth, newColumnWidthTmp);
    const deltaWidthAdjusted = newColumnWidth - initColumnWidthRef.current;
    
    // Set column width
    //--------------------
    updateColumn(columnId, newColumnWidth);

    // Adjust width of columns after selected column
    //-----------------------------------------------
    {
      const columns = columnsAfterSelectedRef.current;
      columns.forEach((column, idx) => {
        const ratios = columnsAfterViewRatioRef.current;
        const ratio = ratios[idx];

        const deltaRatioed = -1 * deltaWidthAdjusted * ratio; 
        const newWidth = Math.max(column.width + deltaRatioed, minColumnWidth);
        updateColumn(column.id, newWidth);
      });
    }

    // Correct overflow
    //--------------------
    {
      const columns = columnsUpdatedRef.current;
      const totalWidthNew = columns.reduce((accumulator, column) => (accumulator + column.width), 0);
      const overflow = totalWidthNew - contentSize[0];

      if (overflow > 0) {
        const widthAdjust = newColumnWidth - overflow;
        updateColumn(columnId, widthAdjust);
      }
    }

    onSetColumnsWidth(columnsUpdatedRef.current);
  }

  return (
    <MainWrapper
      style={{left: `${postionLeft}px`}}     
      onPointerDown={handleDraggerStart}
    >
      <ActiveArea 
        data-test={dataTest}
        $mobile={isMobile}
        $debug={debug}
      >
        <MarkerLine $visible={resizing}/>
      </ActiveArea>

      {/* <LineMark 
        style={{
          top: `${30}px`
        }}     
      /> */}

    </MainWrapper>
  );
}
