import React from 'react';
import { useRecoilValue } from 'recoil';
import { ReleaselogsSessionTypes as SessionTypes } from 'app/arch/editor-instruction/document/states/sessions/releaselogs-session';

import { SideControlsTypes }    from 'lego/components/side-controls';
import SideToolbarBaseComponent from 'lego/components/side-controls/side-toolbar';
import { SideToolbarConfig }    from 'lego/components/side-controls/side-toolbar/config';

import { Icon }            from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDocState }     from 'app/ui/contexts/document';
import { UIState_ReleaselogsSession } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters    from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


type Props = SideControlsTypes.PropLeftOrRight;


export const ToolbarComponent: React.FC<Props> = (props: Props) => {
  const {
    left,
    right,
  } = props;

  const t = useTranslations();
  const document = useDocState();
  const panel  = useRecoilValue(UIState_ReleaselogsSession.panelDocument);

  const {
    setReleaselogsSession,
  } = useEditorStatesSetters();

  const switchPanel = (panelSelectedUpdate: SessionTypes.PanelDocumentItem) => {
    if (panel.visible) {
      if (panel.selected !== panelSelectedUpdate) {
        document.releaselogsSession.setPanelDocument({selected: panelSelectedUpdate});
      }
      else {
        document.releaselogsSession.setPanelDocument({visible: false});
      }
    }
    else {
      document.releaselogsSession.setPanelDocument({
        visible: true,
        selected: panelSelectedUpdate
      });
    }

    setReleaselogsSession();
  }

  const showPage      = () => switchPanel(SessionTypes.PanelDocumentItem.PAGE);
  const showTable     = () => switchPanel(SessionTypes.PanelDocumentItem.TABLE);
  const showColumn    = () => switchPanel(SessionTypes.PanelDocumentItem.COLUMN);
  const showDocHeader = () => switchPanel(SessionTypes.PanelDocumentItem.DOC_HEADER);
  // const showColumns = () => switchPanel(Types.ToolbarPropertiesType.COLUMNS);


  const toolbarConfig: SideToolbarConfig = {
    items: [
      {
        tooltip: t('page properties'),
        selected: panel.visible && panel.selected === SessionTypes.PanelDocumentItem.PAGE,
        onClick: showPage,
        Icon: Icon.Page
      },
      {
        tooltip: t('header properties'),
        selected: panel.visible && panel.selected === SessionTypes.PanelDocumentItem.DOC_HEADER,
        onClick: showDocHeader,
        Icon: Icon.DocHeader
      },    
      {
        tooltip: t('table properties'),
        selected: panel.visible && panel.selected === SessionTypes.PanelDocumentItem.TABLE,
        onClick: showTable,
        Icon: Icon.Table
      },
      {
        tooltip: t('column'),
        selected: panel.visible && panel.selected === SessionTypes.PanelDocumentItem.COLUMN,
        onClick: showColumn,
        Icon: Icon.Column
      },     
      // {
      //   tooltip: t('columns'),
      //   selected: visible && panelSelected === ToolbarPropertiesType.COLUMNS,
      //   onClick: showColumns,
      //   Icon: Icon.Columns
      // },
    ]
  };

  return (
    <SideToolbarBaseComponent
      config={toolbarConfig} 
      
      left={left}
      right={right}      
    />
  );  
}
  
