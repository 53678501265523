import { Arrow    } from "app/arch/editor-image/types/arrows";
import DraggerHeadBaseComponent from "../dragger-head-base";
import { DraggerHeadProps } from "../dragger-head-base/DraggerHeadBase";


interface Props extends DraggerHeadProps {
}


export const DraggerHeadArrowComponent: React.FC<Props> = (props: Props) => {
  return (
    <DraggerHeadBaseComponent
      {...props}
      pointer={Arrow.PointerType.ARROW}
    />
  )
}