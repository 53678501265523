import styled from "styled-components";
import SignDiamond from "../styles/sign-diamond";
import { Config } from "../config";


export const Sign = styled(SignDiamond)`
  color: #000;
  background-color: yellow;
  outline-color: #8B0000;

  border-style: solid;
  border-color: red;
  border-width: ${Config.style.border.width}px;
`;
