/**
 * Panel Settings
 */



export type Panel = {
  visible: boolean,
  selected: PanelItem,
}


export enum PanelItem {
  USER_PROFILE = 'user-profile',
  BILLING      = 'billing',
  THEME        = 'theme',
};

export type PanelUpdate = Partial<Panel>;

