import { useRecoilValue } from 'recoil';

import { PanelDraggableConfig } from 'lego/components/panel-draggable/config';

import { Icon }               from "app/ui/icons/icons";
import { useDocState }        from "app/ui/contexts/document";
import { UIState_Content } from 'app/ui/states/editor-instruction';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { ContentTools, ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useColumnVisibleSet from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-column-visible-set';
import useToastAdd from 'app/ui-v2/app/__modules/info/components/toasts/hooks/use-toast-add';
import { ToastsTypes } from 'app/arch/app/info/toasts';
import { useTranslations } from 'app/ui/hooks/app/use-translation';


// This has been started after states migration but not finished
// this is used by draggable panel to move columns up and down in 
// columns stack
const usePanelConfig = () => {
  const document = useDocState();
  const columnsAddrs = useRecoilValue(UIState_Content.columnsAddrs);
  const columnsProps = useRecoilValue(UIState_Content.columnsProps);

  const secColumnVisible = useColumnVisibleSet();
  
  const addToast = useToastAdd();
  const t = useTranslations();

  const columnsVisibleAddrs = columnsAddrs.filter(columnAddr => {
    const columnKey = ContentTools.getColumnKey(columnAddr);
    const columnProp = columnsProps[columnKey];

    const visible = columnProp.visible;
    return visible;
  });

  const {
    setContent,
  } = useEditorStatesSetters();


  const handleShowColumn = (
    columnAddr: ContentTypes.ColumnAddr, 
  ) => {
    secColumnVisible(columnAddr, true);
  }

  const handleHideColumn = (
    columnAddr: ContentTypes.ColumnAddr, 
  ) => {
    const columnKey = ContentTools.getColumnKey(columnAddr);
    const columnProps = columnsProps[columnKey];
    if (columnProps.type === ContentTypes.ColumnType.INDEX) {
      showWarningIndexColumnNotHidden();
      return;
    }

    secColumnVisible(columnAddr, false);
  }
  
  const handleShowColumnTitle = (
    columnAddr: ContentTypes.ColumnAddr, 
  ) => {
    // document.docOutlook.showColumnName(columnId);
    // document.saveUndo();
    // setDocOutlook();
  }

  const handleHideColumnTitle = (
    columnAddr: ContentTypes.ColumnAddr, 
  ) => {
    // document.docOutlook.hideColumnName(columnAddr);
    // document.saveUndo();
    // setDocOutlook();
  }

  const handleItemMove = (srcIdx: number, dstIdx: number) => {
    const srcAddr = columnsAddrs[srcIdx];
    const dstAddr = columnsAddrs[dstIdx];
    
    const columnSrcKey = ContentTools.getColumnKey(srcAddr);
    const columnSrcProps = columnsProps[columnSrcKey];
    if (columnSrcProps.type === ContentTypes.ColumnType.INDEX) {
      showWarningIndexColumnNotMove();
      return;
    }

    const columnDstKey = ContentTools.getColumnKey(dstAddr);
    const columnDstProps = columnsProps[columnDstKey];
    if (columnDstProps.type === ContentTypes.ColumnType.INDEX) {
      showWarningIndexColumnNotMove();
      return;
    }


    document.content.moveColumn(srcAddr, dstAddr);
    document.saveUndo();
    setContent();
  }

  const showWarningIndexColumnNotMove = () => {
    addToast({
      text: t('column index, do not move warning'),
      level: ToastsTypes.ToastLevel.WARN,
    });
  }

  const showWarningIndexColumnNotHidden = () => {
    addToast({
      text: t('column index, do not hide warning'),
      level: ToastsTypes.ToastLevel.WARN,
    });
  }

  const handleSetColumnName = (
    columnAddr: ContentTypes.ColumnAddr, 
    columnName: string
  ) => {
    document.content.updateColumn(columnAddr, {name: columnName});
    document.saveUndo();
    setContent();
  }


  const getConfig = () => {
    const items = columnsAddrs.map((columnAddr) => {
      const key = ContentTools.getColumnKey(columnAddr);

      const titleTmp = document.content.getColumnName(columnAddr);
      const editable = true;

      const title = (
        titleTmp.length > 0 ?
        titleTmp :
        " "
      );

      const columnVisible = columnsVisibleAddrs.includes(columnAddr);
      // const titleVisible  = columnsNameVisibleIds.includes(columnId);
  
      const titleVisible = true;
      
      const IconColumnVisible      = columnVisible ? Icon.Show : Icon.Hide;
      const IconColumnTitleVisible = titleVisible  ? Icon.Title.Visible : Icon.Title.Hidden;
      
      const onToggleColumnVisible = () => { 
        columnVisible ?
          handleHideColumn(columnAddr) :
          handleShowColumn(columnAddr);
      }
  
      const onToggleColumnTitleVisible = () => { 
        titleVisible ?
          handleHideColumnTitle(columnAddr) :
          handleShowColumnTitle(columnAddr);
      }
  

      return {
        key,
        value: title,
        editable,
        onValueChangeDone: (value: string) => handleSetColumnName(columnAddr, value),
        buttons: [
          // {
          //   Icon: IconColumnTitleVisible,
          //   onClick: onToggleColumnTitleVisible
          // },
          {
            Icon: IconColumnVisible,
            onClick: onToggleColumnVisible
          },
        ]
      }
    })
  
    const config: PanelDraggableConfig = {
      items,
      onItemMove: (srcIdx: number, dstIdx: number) => handleItemMove(srcIdx, dstIdx),
    }
  
    return config;
  }
  
  const hook = () => {
    const config = getConfig();
    return config;
  }

  return hook;
}


export default usePanelConfig;