import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { useDocState } from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';


const useFunctions = (markerAddr: Types.MarkerAddr) => {
  const docState = useDocState();

  const {
    setContent,
    setContentSession,
  } = useEditorStatesSetters();

  const removeMarker = (event: KeyboardEvent) => {
    docState.contentSession.cellMarkers_setMarkerSelected(null);    
    setContentSession();

    docState.content.cellMarkers_removeMarker(markerAddr);
    docState.saveUndo();
    setContent();
  }


  return {
    removeMarker,
  }
}

export default useFunctions;