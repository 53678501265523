import styled from "styled-components";
import InputBase from "lego/styles/input";


export const Input = styled(InputBase)`
  width: 100%;
  height: 100%;
  
  border-radius: 0;
  border-style: none;

  padding: ${props => props.theme.defs.padding.normal};
`;