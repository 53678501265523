import styled from 'styled-components';

import ContentColumnBase     from 'app/ui-v2/home-page/styles/content-column';
import ContentLayoutBase     from 'app/ui-v2/home-page/styles/content-layout';
import ContactBarWrapperBase from 'app/ui-v2/home-page/styles/contact-bar-wrapper';



export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  /* background-color: red; */
`;

export const ContentColumn = styled(ContentColumnBase)`
  width: 100%;
  min-width: 0px;
  /* background: rgba(255, 255, 255, 0.2); */
`;

export const ContentLayout = styled(ContentLayoutBase)`
  /* background: rgba(0,255,0, 0.2); */
`;

export const ContactBarWrapper = styled(ContactBarWrapperBase)`
`;
