import PanelItemGenerator  from 'app/ui-v2/editor-instruction/panel-items/generator';

import useFunctions from './__use-functions';
import useStates from './__use-states';


const useItems = () => {
  const fn = useFunctions();
  
  const {
    css,
    visible,
  } = useStates();


  const getVisible = () => 
    PanelItemGenerator.getPanelVisibility({
      visible: visible,
      onClick: fn.setVisible,
    })

  const getFontSize = () => 
    PanelItemGenerator.getFontSize({
      css,
      onChange: (cssUpdate: React.CSSProperties) => { 
        fn.updateCSS({
          cssUpdate, 
          skipUndoSave: true
        });
      },
      onChangeDone: (cssUpdate: React.CSSProperties) => { 
        fn.updateCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      },
    });

  const getFontWeight = () =>
    PanelItemGenerator.getFontWeight({
      css,
      onChange: (cssUpdate: React.CSSProperties) => { 
        fn.updateCSS({
          cssUpdate, 
          skipUndoSave: true
        });
      },
      onChangeDone: (cssUpdate: React.CSSProperties) => { 
        fn.updateCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      },
    });

  const getFontVariant = () => 
    PanelItemGenerator.getFontCapslock({
      css,
      onClick: (cssUpdate: React.CSSProperties) => {
        fn.updateCSS({
          cssUpdate, 
          skipUndoSave: false
        });
      }
    });

    const getPadding = () => 
      PanelItemGenerator.getPadding({
        css,
        onChange: (cssUpdate: React.CSSProperties) => {
          fn.updateCSS({
            cssUpdate, 
            skipUndoSave: true
          });
        },
        onChangeDone: (cssUpdate: React.CSSProperties) => {
          fn.updateCSS({
            cssUpdate, 
            skipUndoSave: false
          });
        },
      });

    const getAlignHorizontal = () => 
      PanelItemGenerator.getAlignHorizontal({
        css,
        onClick: (cssUpdate: React.CSSProperties) => { 
          fn.updateCSS({ 
            cssUpdate,
            skipUndoSave: false 
          });
        },
        singleItem: true,
      });

  return {
    getVisible,
    getFontSize,
    getFontWeight,
    getFontVariant,
    getPadding,
    getAlignHorizontal,
  }
}


export default useItems;