import { ThemeDefProps } from "../types";


export const themeDefs_tooltip = (props: ThemeDefProps) => {
  const { colors, accentColor, border } = props;

  const tooltip = {
    color: colors.color.secondary,
    background: colors.background.sixth,
  };
    
  return tooltip;
}

