import styled from "styled-components";

interface WrapperProps {
  $visible: boolean;
}

export const MainWrapper = styled.div<WrapperProps>`
  /* position: absolute; */
  /* top: ${props => props.$visible ? '-27px' : '-8px'}; */
  /* left: ${props => props.$visible ? '0px' : '-30px'}; */

  user-select: none;
  font-variant-caps: small-caps;

  font-weight: ${props => props.theme.defs.font.weight.normal};
  /* font-size:   ${props => props.theme.defs.font.size.small}; */
  font-size:   10px;
  color:       ${props => props.theme.defs.colors.color.primary};
  background:  ${props => props.theme.defs.colors.background.seventh};
  border-radius: ${props => props.theme.defs.border.radius.small};
  
  padding: 
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.normal}
    ${props => props.theme.defs.padding.normal}
  ;

  opacity: 0;
  &:hover {
    opacity: 1;
  }

  transition: 
    all 
    ${props => props.theme.defs.transition.time.normal} 
    ease-in-out
  ;
`;


export const BuildText = styled.div`
`;

export const BuildNo = styled.div`
`;