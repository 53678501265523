import React from 'react';

import { useStripeSubscription } from 'lego-v2/stripe/stripe-subscription-modal';

import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useBillingPeriod from 'app/ui-v2/home-page/hls/view-pricing-v2/hooks/use-billing-period';

import usePricePlan from '../../../hooks/use-price-plan';
import BuyButtonComponent from '../parts/buy-button';


interface Props {
}


export const PricePlanBuyComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const pay = useStripeSubscription();

  const pricePlan = usePricePlan();
  const billingPeriod = useBillingPeriod();

  const pricePlanType = pricePlan.planType;
  const btnDisabled = (pricePlan.planType === PricePlansTypes.PlanType.FREE);
  
  const handleClick = () => {
    pay({
      pricePlanType,
      billingPeriod
    });
  }

  return (
    <BuyButtonComponent 
      label={t("buy")}
      onClick={handleClick}
      disabled={btnDisabled}
    />
  );
}
