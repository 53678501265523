import Document from "./document";
import CrashWatcher from "./crash-watcher";
import { LexicalRegistryChangelog } from "./lexical-registry";
import { LexicalRegistryContent }  from "./lexical-registry";
import { LexicalRegistryWidgets } from "./lexical-registry";
import { LexicalRegistryCustomFields } from "./lexical-registry/lexical-registry-content2-custom-fields";
import { LexicalRegistryCustomRows } from "./lexical-registry/lexical-registry-custom-rows";


export class EditorInstruction {
  private _recoveryMode: boolean;
  private _document: Document;
  private _crashWatcher: CrashWatcher;

  private _lexicalRegistryContent: LexicalRegistryContent;
  private _lexicalRegistryChangelog: LexicalRegistryChangelog;
  private _lexicalRegistryWidgets: LexicalRegistryWidgets;
  private _lexicalRegistryCustomFields: LexicalRegistryCustomFields
  private _lexicalRegistryCustomRows: LexicalRegistryCustomRows;

  constructor(docId: number) {
    this._recoveryMode = false;

    this._document = new Document(docId);
    this._crashWatcher = new CrashWatcher();

    this._lexicalRegistryChangelog = new LexicalRegistryChangelog();
    this._lexicalRegistryContent = new LexicalRegistryContent();
    this._lexicalRegistryWidgets = new LexicalRegistryWidgets();
    this._lexicalRegistryCustomFields = new LexicalRegistryCustomFields();
    this._lexicalRegistryCustomRows = new LexicalRegistryCustomRows();
  }

  get document()     { return this._document;  }
  get crashWatcher() { return this._crashWatcher; }
  
  get lexicalRegistrChangelog() { return this._lexicalRegistryChangelog; }
  get lexicalRegistrContent() { return this._lexicalRegistryContent; }
  get lexicalRegistrWidgets() { return this._lexicalRegistryWidgets; }
  get lexicalRegistryCustomFields() { return this._lexicalRegistryCustomFields; }
  get lexicalRegistryCustomRows() { return this._lexicalRegistryCustomRows; }

  set recoveryMode(recoveryMode: boolean) { this._recoveryMode = recoveryMode };
  get recoveryMode() { return this._recoveryMode; }
};
