import React from 'react';

import LoadersSimpleComponent  from './loaders-simple';
import LoadersOrderedComponent from './loaders-ordered';
import useAppLoading from 'app/ui-v2/app/hooks/use-app-loading';


interface Props {
  children: React.ReactNode;
}


export const LoaderComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  useAppLoading({loading: true});

  return (
    <>
      <LoadersSimpleComponent />

      <LoadersOrderedComponent>
      { children }
      </LoadersOrderedComponent>
    </>
  );
}
  
