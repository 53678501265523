import * as Types from './types';


export const getSize = (): Types.Size => ({
  width: 0,
  height: 0,
});

export const getSizeVariant = (): Types.SizeVariant => Types.SizeVariant.WIDTH_390;

