import { memo } from 'react';

import { ShadowTop }    from './styles';
import { ShadowRight }  from './styles';
import { ShadowBottom } from './styles';
import { ShadowLeft }   from './styles';


interface Props {
  x1: number;
  y1: number;

  x2: number;
  y2: number;

  shadowStyle?: any;
}


export const MaskedAreaComponent: React.FC<Props> = memo((props: Props) => {
  const { 
    x1,
    y1,
    x2,
    y2,
    shadowStyle,
  } = props;

  return (
    <>
      <ShadowTop
        style={{
          ...shadowStyle,
          height: `${y1}px`
        }}
      />

      <ShadowBottom
        style={{
          ...shadowStyle,
          top: `${y2}px`
        }}
      />

      <ShadowLeft
        style={{
          ...shadowStyle,
          top: `${y1}px`,
          height: `${y2 - y1}px`,
          width: `${x1}px`,
          left: `0px`
        }}
      /> 

      <ShadowRight
        style={{
          ...shadowStyle,
          top: `${y1}px`,
          height: `${y2 - y1}px`,
          right: `0`,
          width: `calc(100% - ${x2}px)`
        }}
      />
    </>
  );
});
