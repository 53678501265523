import { useTranslations }   from "app/ui/hooks/app/use-translation";
import { useDialogCustom }   from "../dialog-custom";
import { DialogCustomTypes } from '../dialog-custom';


interface Props {
  title: string;
  message: string;
  onContinueHandler?: () => void;
  onCancelHandler?: () => void;
  continueAutoFocus?: boolean;
  cancelAutoFocus?: boolean;
  icon?: DialogCustomTypes.IconType;
  dataTest?: string;
}

export const useDialogContinueCancel = () => {
  const {
    show: showCustom,
    hide: hideCustom
  } = useDialogCustom();

  const t = useTranslations();

  const show = (props: Props) => {

    const {
      title,
      message,
      icon,
      continueAutoFocus,
      onContinueHandler,
      cancelAutoFocus,
      onCancelHandler,
      dataTest: dataTest_
    } = props;

    const dataTest = dataTest_ || 'dialog-continue-cancel';

    showCustom({
      title,
      message,
      icon,
      buttons: [{
        autoFocus: continueAutoFocus === true,
        text: t("continue"),
        dataTest: `${dataTest}__btn-continue`,
        onClick: () => {
          onContinueHandler?.();
        }
      }, {
        autoFocus: cancelAutoFocus === true,
        text: t("cancel"),
        dataTest: `${dataTest}__btn-cancel`,
        onClick: () => {
          onCancelHandler?.();
        }
      }]        
    });
  }

  return {
    show
  }
}
