import React from 'react';
import { HeaderFieldsTools } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useDocState } from 'app/ui/contexts/document';

import HeaderCellDraggerComponent     from './header-cell-dragger';
import HeaderCellDropComponent        from './header-cell-drop';
import HeaderCellViewComponent        from './header-cell-view';
import HeaderCellContextMenuComponent from './header-cell-context-menu';
import HeaderCellNameEditComponent    from './header-cell-name-edit';
import HeaderCellCSSComponent         from './header-cell-css';
import HeaderCellSelectComponent      from './header-cell-select';

import { HeaderCell } from './styles';


interface Props {
  pageColumnAddr: Types.PageColumnAddr;
}


export const HeaderCellComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageColumnAddr,
  } = props;

  const document = useDocState();
  const columnIdx = document.headerFields.getColumnIdx(pageColumnAddr);
  const borderLeft = (columnIdx === 0);

  const pageCellDataTest = HeaderFieldsTools.getPageColumnDataTest({
    pageColumnAddr,
    docState: document,
    prefix: 'header-fields-header-cell'
  });

  return (
    <HeaderCell 
      data-test={pageCellDataTest}
      borderLeft={borderLeft} 
    >
      <HeaderCellNameEditComponent pageColumnAddr={pageColumnAddr} >
        <HeaderCellSelectComponent pageColumnAddr={pageColumnAddr} >
          <HeaderCellContextMenuComponent pageColumnAddr={pageColumnAddr} >
            <HeaderCellDraggerComponent pageColumnAddr={pageColumnAddr} >
              <HeaderCellDropComponent pageColumnAddr={pageColumnAddr} >
                <HeaderCellCSSComponent pageColumnAddr={pageColumnAddr} >
                  <HeaderCellViewComponent pageColumnAddr={pageColumnAddr} />
                </HeaderCellCSSComponent>
              </HeaderCellDropComponent>
            </HeaderCellDraggerComponent>
          </HeaderCellContextMenuComponent>
        </HeaderCellSelectComponent>
      </HeaderCellNameEditComponent>
    </HeaderCell>
  );
}
  
