import i18next from 'i18next';

import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { Language } from 'app/arch/types';
import { LanguageCodes } from 'app/arch/types';

import useRepos from 'app/ui-v2/app/hooks/use-repos';
import useUserSettings from 'app/ui-v2/app/__modules/user/hooks/use-user-settings';
import useUserStateSetters from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';
import useUserSettingsFetch from 'app/ui-v2/app/__modules/user/hooks/use-user-settings-fetch';

import { LoadersTools }        from '..';
import LoaderAuthBaseComponent from '../base/loader-auth-base';
import { LoaderCoreProps }     from '../base/loader-auth-base/types';


interface Props {
  children: React.ReactNode;
}


export const LoaderUserSettingsComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  return (
    <LoaderAuthBaseComponent
      debugTitle={"user settings"}
      LoaderCoreAuthComponent={LoaderCoreAuth}
      LoaderCoreUnauthComponent={LoaderCoreUnauth}
    >
      { children }
    </LoaderAuthBaseComponent>
  );
}


/**
 * UserSettings Auth
 */
const LoaderCoreAuth: React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const {
    children 
  } = props;

  const logger = LoadersTools.getLogger();

  const [ready, setReady] = useState(false);
  const repos = useRepos();
  const userSettings = useUserSettings();

  logger.debug(`Loader user settings - ready: ${ready}`);
  
  const {
    setUserSettings,
  } = useUserStateSetters();

  const onDone = (props: {
    user: any,
    userSettings: any
  }) => {
    logger.debug(`Loader user settings, settings loaded`)

    const {
      user,
      userSettings: userSettings_
    } = props;


    const reposPack = repos.getReposPacks();
    if (reposPack.length === 0) {
      const msg = `No repo available`;
      throw new Error(msg);
    }

    // Repo
    const repoPack = reposPack[0];
    userSettings.updateRepo({id: repoPack.repo.id});

    // User data
    userSettings.updateUser({email: user.email});

    // User settings
    userSettings.merge(userSettings_.settings);
    const language = userSettings.getUser().language;
    const langCode = LanguageCodes[language as Language];
    i18next.changeLanguage(langCode);

    setUserSettings();
    setReady(true);
  }

  const onError = (error: string) => {
    const msg = `Can't download user settings, error: ${error}`;
    console.error(msg);
  }

  useUserSettingsFetch({
    onDone,
    onError,
  });

  if ( ! ready ) {
    return null;
  }

  return children;
}
  

/**
 * UserSettings Unauth
 */
const LoaderCoreUnauth:  React.FC<LoaderCoreProps> = (props: LoaderCoreProps) => {
  const {
    children 
  } = props;

  const logger = LoadersTools.getLogger();
  logger.debug(`Loader user settings - render, unauth`);

  const userSettings = useUserSettings();

  const {
    setUserSettings,
  } = useUserStateSetters();

  useEffect(() => {
    logger.debug('Reset user settings');
    userSettings.reset();
    setUserSettings();
  }, []);

  return children;
}
