import { useRecoilValue } from "recoil";

import { Page } from "app/arch/print/page";
import { Size } from "app/arch/types";
import usePageLayout  from "./use-page-layout";
import usePageMargins from "./use-page-margins";


const usePageContentSize = () => {
  const pageLayout  = usePageLayout();
  const pageMargins = usePageMargins();
  
  const pageBodySize = Page.getBodySizePx(
    pageLayout.format, 
    pageLayout.orientation, 
    pageMargins
  ) as Size;
  
  const contentSize = [
    pageBodySize[0],
    pageBodySize[1],
  ] as Size;

  return contentSize;
}


export default usePageContentSize;