import React from 'react';
import { SelectOptionType } from '../config';
import { Item }        from './styles';
import { TextWrapper } from './styles';


interface Props {
  option: SelectOptionType;
  onSelected: (option: SelectOptionType) => void;
}


export const SelectOptionComponent: React.FC<Props> = (props: Props) => {
  const { option, onSelected } = props;

  const handleOptionSelect = (e: React.MouseEvent) => {
    e.stopPropagation();
    onSelected(option);
  }

  return (
    <Item
      onClick={handleOptionSelect}
      selected={option.selected}
    >
      <TextWrapper>
        { option.valueText }
      </TextWrapper>
    </Item>
  );
}
  
