import React from 'react';
import DocHeaderInfoComponent  from './doc-header-info';
import DocHeaderProbeComponent from './doc-header-probe';


interface Props {
}


export const DocHeaderComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <DocHeaderProbeComponent>
      <DocHeaderInfoComponent />
    </DocHeaderProbeComponent>
  );
}
  
