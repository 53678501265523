import React from 'react';
import useMarkerEdit from 'app/ui-v2/editor-markers/hooks/use-marker-edit';
import { MainWrapper } from './styles';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerEditComponent: React.FC<Props> = (props: Props) => {
  const { 
    markerAddr, 
    children, 
  } = props;

  const editMarker = useMarkerEdit();

  const handleEditMarker = (event: React.MouseEvent) => {
    event.stopPropagation();
    editMarker(markerAddr);
  }

  return (
    <MainWrapper onDoubleClick={handleEditMarker} >
      { children }
    </MainWrapper>
  );
}
  
