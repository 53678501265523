// const dev = true;
const dev = false;


const demoActive = false;
// const demoActive = true;


const {
  BACKEND_BASE_URL,
  FRONTEND_URL,
  GOOGLE_ANALYTICS_TAG,
  GOOGLE_ADS_TAG,
  TEST_EMAIL1,
  STRIPE_ENABLED,
  STRIPE_PUBLIC_KEY,
} = (
  dev ? {
    /**
     * Development
     */
    BACKEND_BASE_URL: 'http://192.168.8.210:7000',
    FRONTEND_URL:     'http://192.168.8.210:3000',
    // FRONTEND_URL:     'http://192.168.8.210:5500',

    // BACKEND_BASE_URL: 'http://localhost:7000',
    // FRONTEND_URL:     'http://localhost:3000',

    GOOGLE_ANALYTICS_TAG: 'G-YJY3KHYSEE',
    GOOGLE_ADS_TAG: '',
    TEST_EMAIL1: 'przemek@flexedit.app',
    STRIPE_ENABLED: true,
    STRIPE_PUBLIC_KEY: 'pk_test_51Na4iFAsXApX71h9KdJu8y8tYys7Szixr0QBOPDeeCO6K3dqAdNLeb6CoooS5cyJgVuaSPiOVJKeeWF8O8KnK8vS00xsMq2NNV',
  } 
  : 
  {
    /**
     * Production
     */
    BACKEND_BASE_URL: 'https://back.flexedit.app',
    FRONTEND_URL:     'https://flexedit.app',
    GOOGLE_ANALYTICS_TAG: 'G-SYEK66G30L',
    GOOGLE_ADS_TAG: 'AW-16699197000',
    TEST_EMAIL1: '',
    STRIPE_ENABLED: true,
    STRIPE_PUBLIC_KEY: 'pk_live_51Na4iFAsXApX71h9AOj484hPueMt70YOPY8HwH5jxuErLiVpm1LLdRfcBjPyBVz4PTRSsAh7zPGwowQkR9NPYzxK00CXv7pcrJ',
  }
);


const GOOGLE_OAUTH2_URL    = `${BACKEND_BASE_URL}/auth/login/google-oauth2/`
  
export const environment = {
  debug: dev,
  dev,

  demo: {
    active: demoActive
  },

  stripe: {
    enabled: STRIPE_ENABLED,
    publicKey: STRIPE_PUBLIC_KEY,
  },

  google: {
    analytics: {
      tag : GOOGLE_ANALYTICS_TAG,
      ads : GOOGLE_ADS_TAG,
    },
    oauth2: {
      url: {
        login: GOOGLE_OAUTH2_URL
      }
    }
  },

  frontend: {
    url: `${FRONTEND_URL}`
  },
  
  backend: {
    urlGraphql: `${BACKEND_BASE_URL}/api`,
    urlUpload:  `${BACKEND_BASE_URL}/api`,

    getInstructionImageUrl: (
      repoId: number, 
      instructionId: number, 
      filename: string
    ) => `${__getRepoAssestUrl(repoId, instructionId)}/workspace/images/${filename}`,


    getInstructionLogoUrl: (
      repoId: number, 
      instructionId: number, 
      filename: string
    ) => `${__getRepoAssestUrl(repoId, instructionId)}/workspace/logos/${filename}`,


    getInstructionPreviewUrl: (
      instructionId: number,
    ) => `${BACKEND_BASE_URL}/download/instruction/${instructionId}/preview`,


    getInstructionReleaseUrl: (
      instructionId: number,
      revision: number,
    ) => `${BACKEND_BASE_URL}/download/instruction/${instructionId}/revision/${revision}`,
  },


  test: {
    email1: TEST_EMAIL1,
  }
};


const __getRepoAssestUrl = (repoId: number, instructionId: number) => {
  return (
    `${BACKEND_BASE_URL}`
    + `/repos_static`
    + `/repo_${repoId}`
    + `/instruction_${instructionId}`
  );
}
