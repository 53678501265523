import React from 'react';
import { useState }  from 'react';

import { validate }         from 'app/arch/editor-instruction/validators/validate';
import FormPanelComponent   from 'app-views/components/form-panel';
import InputComponent       from 'app-views/components/input';
import PanelTitledComponent from 'app-views/components/panel-titled';
import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import useDocumentCreate    from 'app/ui-v2/editor-instruction/hooks/use-document-create';

import { Button }        from "./styles";
import { ButtonWrapper } from "./styles";


interface Props {
}

export const CreateDocumentFormComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  const [documentTitle, setDocumentTitle] = useState('');
  const [isTitleValid, setIsTitleValid] = useState(false);
  const [visible, setVisible] = useState(false);
  const createDocument = useDocumentCreate();
  
  const handleDocumentTitleChange = (docTitle: string) => {
    setDocumentTitle(docTitle);
    checkTitle(docTitle);
  };

  const handleSubmit = () => {
    createDocument({title: documentTitle});
  };

  const checkTitle = (title: string) => {
    const titleValid = validate.document.title(title);

    if (titleValid !== isTitleValid) {
      setIsTitleValid(titleValid);
    }
  }

  const handleShow = () => {
    setVisible(true);
  }

  return (
    <PanelTitledComponent title={t('create document')} >
      {
        ! visible &&
        <ButtonWrapper>
          <Button
            data-test={'btn-show-create-doc-panel'}
            onClick={handleShow}
          >
          { t("create") }
          </Button>
        </ButtonWrapper>
      }

      {
        visible &&
        <FormPanelComponent
          buttonText={t('create')}
          btnDataTest={'btn-create-doc'}
          onSubmit={handleSubmit}
          submitDisabled={! isTitleValid}
        >
          <InputComponent
            focusOnLoad={true}
            onChange={handleDocumentTitleChange}
            dataTest={"form-doc-title"}
          />
        </FormPanelComponent>
      }
    </PanelTitledComponent>
  );
}
