import React from 'react';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import { useDocState }        from 'app/ui/contexts/document';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset       from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';


import { MainWrapper } from './styles';


interface Props {
  imageAddr: Types.ImageAddr;
  children: React.ReactNode;
}


export const ImageSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children,
  } = props;

  const docState = useDocState();
  const resetAllSelected = useSelectedReset();

  const {
    setContentSession,
  } = useEditorStatesSetters();

  const handleSelectImage = (event: React.MouseEvent) => {
    event.stopPropagation();

    resetAllSelected();
    
    docState.contentSession.cellImages_setImageSelected(imageAddr);
    docState.contentSession.setCellSelected(imageAddr);
    setContentSession();
  }

  return (
    <MainWrapper onPointerDown={handleSelectImage}>
      { children }
    </MainWrapper>
  );
}
  
