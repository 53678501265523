import { ThemeDefProps } from "../types";


export const themeDefs_menuBar = (props: ThemeDefProps) => {
  const { colors, accentColor, border, padding, boxShadow } = props;

  const menuBar = {
    background: colors.background.primary,

    border: {
      style: 'solid',
      width:  border.width.normal,
      color:  colors.border.primary,
    },

  };
    
  return menuBar;
}

