import React from 'react';

import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/releaselogs/types';
import useReleaselogIsEditable from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-releaselog-is-editable';

import { PageItemsProps } from '../types';
import ChangelogGridComponent   from './changelog-grid';
import ColumnsResizersComponent from './columns-resizers';

import { Anchor } from './styles';
import { ColumnsResizersDetacher } from './styles';


interface Props extends PageItemsProps {
}


export const PageItemChangelogComponent: React.FC<Props> = (props: Props) => {
  const { 
    item
  } = props;

  const changelog = item as SlicerTypes.PageItem_ReleaseChangelog;
  const releaselogId = changelog.releaselogId;
  const releaselogAddr = { releaselogId };

  const isEditable = useReleaselogIsEditable(releaselogAddr);


  return (
    <>
      <Anchor>
        {
          isEditable &&
          <ColumnsResizersDetacher>
            <ColumnsResizersComponent 
              releaselogId={releaselogId} 
              debug={false}
            />
          </ColumnsResizersDetacher>
        }

        <ChangelogGridComponent 
          changelog={changelog}
        />
      </Anchor>
    </>
  );
}
