import React from 'react';
import { useDocState }      from 'app/ui/contexts/document';

import ProbeHeightComponent from 'app/ui-v2/editor-instruction/views/view-content/components/content/page/probe-height';
import { RefWrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const DocMarkersProbeComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const document = useDocState();

  const handleHeightChange = (height: number) => {
    const elemSizes = document.contentElementsSizes;
    elemSizes.pageHeader.setDocMarkersHeight(height);
  }

  // RefWrapper
  // is just here to attach ref to div. 
  // As otherwise it would not be possible
  // to attach ref to functional component.
  return (
    <ProbeHeightComponent
      enabled={true}
      onHeightUpdate={handleHeightChange}
    >
      <RefWrapper>
      { children }
      </RefWrapper>
    </ProbeHeightComponent>
  );
}
  
