import { uuid } from "tools/uuid";
import * as Types from './types';


/**
 * Click
 */

export const getClickKey = (
  clickAddr: Types.ClickAddr
): string => (
  `${clickAddr.clickId}`
);

export const createClickAddr = (
): Types.ClickAddr => {
  const clickId = uuid();
  const clickAddr = { clickId };
  return clickAddr;
}   

export const compareClickAddr = (
  srcClickAddr: Types.ClickAddr,
  dstClickAddr: Types.ClickAddr,
): boolean => (
  srcClickAddr.clickId === dstClickAddr.clickId
);

