import * as Types from "./types"

export const getInvoices = (): Types.Invoices => ({
  addrs: [],
  props: {},
});


export const getSubscriptionsInvoices = (): Types.SubscriptionInvoices => ({
});
