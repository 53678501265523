import { RepoImagesTypes } from 'app/arch/editor-instruction/document/states/loadable/repo-images';
import { DNDContext }   from 'app/ui-v2/editor-instruction/dnd-context';
import DraggerComponent from 'app/ui-v2/editor-instruction/hls/dragger';
import ImageDraggedViewComponent from './image-dragged-view';
import useDNDDataSet from 'app/ui-v2/editor-instruction/hooks/use-dnd-data-set';



interface Props {
  image: RepoImagesTypes.Image;
  children: React.ReactNode;
}


export const ImageDraggerComponent: React.FC<Props> = (props: Props) => {
  const { 
    image, 
    children, 
  } = props;
  
  const setDNDData = useDNDDataSet();

  const handleDragStart = () => {
    const data = {imageGid: image.bid}
    const dataSerial = JSON.stringify(data);
    setDNDData(dataSerial);
  }

  return (
    <DraggerComponent
      dndContext={DNDContext.image.gallery}
      onDragStart={handleDragStart}
      draggedView={
        <ImageDraggedViewComponent image={image} />
      }
    >
      { children }
    </DraggerComponent>
  );
}
