import * as Defaults from './defaults';
import * as Tools from './tools';
import * as Types from './types';


/**
 * State
 */
export type State = Types.State;


/**
 * Clicks
 */

export const getAnimDrag = (
  state: State
): Types.AnimDragNullable => { 
  return state.animDrag;
}


/**
 * Initial state
 */
export const createInitialState = (): State => {
  const initState: State = Defaults.getState();
  return initState;
}
