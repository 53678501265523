import styled from "styled-components";
import PageBase from "../styles/page";
import PageBodyBase from "../styles/page-body";


export const PageShadow = styled.div`
  box-shadow: ${props => props.theme.defs.boxShadow.secondary};

  outline-width: 1px;
  outline-style: solid;
  outline-color: #555;
  border-radius: 2px;
`;


export const Page = styled(PageBase)`
  background: rgba(255, 255, 255, 0.65);
`;


export const PageBody = styled(PageBodyBase)`
`;
