import React from 'react';
import { MutableRefObject } from 'react';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import CellTextEditorComponent from './cell-text-editor';


interface Props  {
  editorTextRef: MutableRefObject<HTMLDivElement | null>;
  pageCellAddr: HeaderRowsTypes.PageCellAddr;
}


export const CellTextComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr,
    editorTextRef,
  } = props;


  return (
    <CellTextEditorComponent
      editorTextRef={editorTextRef}
      pageCellAddr={pageCellAddr}
    /> 
  );
}
