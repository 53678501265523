import PanelItemGenerator  from 'app/ui-v2/editor-instruction/panel-items/generator';

import useFunctions from './__use-functions';
import useStates from './__use-states';


const useItems = () => {
  const fn = useFunctions();
  
  const {
    visible,
  } = useStates();


  const getVisible = () => 
    PanelItemGenerator.getPanelVisibility({
      visible: visible,
      onClick: fn.setVisible,
    })

  return {
    getVisible,
  }
}


export default useItems;