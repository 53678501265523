import styled from "styled-components";
import TextWrap from "lego/styles/text-wrap";


interface Props {
  $selectable:boolean;
}

export const Text = styled(TextWrap)<Props>`
  user-select: ${props => props.$selectable ? "all" : "none"};
`;
