import React from 'react';

import HomePageGuestLayoutComponent from './components/home-page-guest-layout';
import HomePageGuestViewSelectedComponent from './components/home-page-guest-view-selected';


interface Props {
}


export const HomePageGuestComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <HomePageGuestLayoutComponent>
      <HomePageGuestViewSelectedComponent />
    </HomePageGuestLayoutComponent>
  );
}
