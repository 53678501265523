import { ThemeConstsProps } from "../types";


export const themeConsts_scale = (props: ThemeConstsProps) => {
  const scale = {
    // xsmall: "1px", 
    small: 0.7, 
    // normal: "8px",
    // large:  "14px",
    // xlarge:  "20px",
  };

  return scale;
}
