import styled from "styled-components";
import PanelSecondary from "lego/styles/panel_secondary";



export const MarkersPanel = styled(PanelSecondary)`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.defs.colors.background.forth};
  padding: 0px;
  flex-grow: 1;
`;
