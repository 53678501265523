import React from 'react';
import { useRecoilValue } from 'recoil';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';

import { UIState_ReleaselogsSession } from 'app/ui/states/editor-instruction';
import { UIState_ViewsCommon } from 'app/ui/states/editor-instruction';
import { UIState_Releaselogs } from 'app/ui/states/editor-instruction';
import useReleaselogIsEditable from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-releaselog-is-editable';
import { HeaderCell } from './styles';


interface Props {
  columnAddr: ReleaselogsTypes.ColumnAddr;
  children: React.ReactNode
}


export const HeaderCellCssComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;

  const selected        = useRecoilValue(UIState_ReleaselogsSession.isColumnSelected(columnAddr));
  const tableHeaderCSS  = useRecoilValue(UIState_ViewsCommon.tableHeaderCSS);
  const columnHeaderCSS = useRecoilValue(UIState_Releaselogs.columnHeaderCSS(columnAddr));
  const isEditable      = useReleaselogIsEditable(columnAddr);
  
  const {
    color,
    background,
    ...tableHeaderCSS_stripped
  } = tableHeaderCSS;

  const style = {
    ...tableHeaderCSS_stripped,
    ...columnHeaderCSS,
  }

  return (
    <HeaderCell
      $selected={selected}
      $color={color as string}
      $background={background as string}
      $editDisabled={ ! isEditable}
      style={style}
    >
      { children }
    </HeaderCell>
  );
}
  
