import React from 'react';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/releaselogs/types';

import PageItemDocHeaderInfoComponent    from './page-items/page-item-doc-header-info';
import PageItemReleaseChangelogComponent from './page-items/page-item-changelog';
import PageItemReleaseInfoComponent      from './page-items/page-item-releaselog-info';
import PageItemViewTitleComponent        from './page-items/page-item-view-title';
import PageItemSpacerComponent           from './page-items/page-item-spacer';


interface Props {
  page: SlicerTypes.Page;
}


export const PageComponent: React.FC<Props> = (props: Props) => {
  const {
    page
  } = props;

  const itemMap = {
    [SlicerTypes.PageItemType.DOC_HEADER_INFO]:   PageItemDocHeaderInfoComponent,
    [SlicerTypes.PageItemType.VIEW_TITLE]:        PageItemViewTitleComponent,
    [SlicerTypes.PageItemType.RELEASE_INFO]:      PageItemReleaseInfoComponent,
    [SlicerTypes.PageItemType.RELEASE_CHANGELOG]: PageItemReleaseChangelogComponent,
    [SlicerTypes.PageItemType.SPACER]:            PageItemSpacerComponent,
  }

  const renderPageItems = page.items.map((item, idx) => {
    const ItemComp = itemMap[item.type];

    return (
      <ItemComp 
        key={idx}
        item={item}
      />
    );
  })
  
  return (
    <>
      { renderPageItems }
    </>
  );
}
  
