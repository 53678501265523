import React from 'react';
import useDPIWatch from 'libs/dpi/comps/use-dpi-watch';


interface Props {
}


export const LoaderDPIProbeComponent: React.FC<Props> = (props: Props) => {
  useDPIWatch();
  return null;
}
  
