import { createContext } from 'react';
import { PrintoutViewTypes } from 'app/arch/editor-instruction/document/states/printout/printout-view';



export interface ContextProps {
  printoutView: PrintoutViewTypes.ViewType;
}


const Context = createContext<ContextProps>({
  printoutView: PrintoutViewTypes.ViewType.CONTENT,
});


export default Context;