import { ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";


const useWidgetDuplicate = () => {
  const docState = useDocState();

  const {
    setContent,
    setEditorImageSession
  } = useEditorStatesSetters();

  
  const duplicateWidget = (widgetAddr: ContentTypes.WidgetAddr) => {
    const newWidgetAddr = docState.content.cellImages_image_duplicateWidget(widgetAddr);
    docState.saveUndo();
    setContent();

    docState.editorImageSession.setWidgetSelected(newWidgetAddr);
    docState.editorImageSession.setWidgetEdited(null);
    setEditorImageSession();
  }

  return duplicateWidget;
}


export default useWidgetDuplicate;