import styled from 'styled-components';
import { Size }     from 'app/arch/types';
import { Position } from 'app/arch/types';


interface ImageProps {
  $url: string;
  $backgroundSize: Size;
  $backgroundPosition: Position;
}


export const Image =  styled.div<ImageProps>`
  user-select: none;
  overflow: clip;
  
  background-repeat: no-repeat;
  background-image: url(${props => `'${props.$url}'`});

  background-size: 
    ${props => props.$backgroundSize[0]}px 
    ${props => props.$backgroundSize[1]}px; 

  background-position: 
    ${props => props.$backgroundPosition[0]}px
    ${props => props.$backgroundPosition[1]}px; 
`;
