import React from 'react';

import { useDocState } from 'app/ui/contexts/document';
import { Input } from './styles';


export const InputStyledComponent = React.forwardRef((
  props: React.InputHTMLAttributes<HTMLInputElement>, 
  ref: React.Ref<HTMLInputElement>
) => {
  const document = useDocState();
  const tableHeaderCSS = document.headerFields.getTable().header.css;
  
  return (
    <Input
      {...props} 
      ref={ref} 

      $fontSize={tableHeaderCSS.fontSize!.toString()}
      $fontWeight={tableHeaderCSS.fontWeight!.toString()}
      $fontVariant={tableHeaderCSS.fontVariant!.toString()}
    />
  );
});
