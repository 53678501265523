import styled from 'styled-components';
import { StyledFrameBase } from "lego-v2/frame-resize/ui/style-frame-ctrls-rectangular";
import { zIndex } from 'app/ui-v2/editor-image/zIndex';


interface FrameWrapperProps {
  $left: number;
  $top: number;
  $width: number;
  $height: number;
}

export const FrameWrapper =styled.div.attrs<FrameWrapperProps>(props => ({
  style: {
    left:   `${props.$left}px`,
    top:    `${props.$top}px`,
    width:  `${props.$width}px`,
    height: `${props.$height}px`,
  }
}))<FrameWrapperProps>`
  position: absolute;
  pointer-events: none;
  /* z-index: ${zIndex.widgetControls}; */
  /* background: rgba(255, 0, 0, 0.2); */
`;

export const Frame = styled(StyledFrameBase)`
`;
