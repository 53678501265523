import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_ContentColumnsSelected } from 'app/ui/states/editor-instruction';
import { UIState_ContentRowsSelected } from 'app/ui/states/editor-instruction';
import useCellsRowContext from '../../../../../hooks/use-cells-row-context';

import { MainWrapper } from './styles';
import { Background }  from './styles';


interface Props {
  cellAddr: ContentTypes.CellAddr;
  children: React.ReactNode;
}


export const CellsLineDragOverComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props;

  const cellsRowContext = useCellsRowContext();
  const rowAdder = cellsRowContext.rowAdder;

  const rowDragOver = useRecoilValue(UIState_ContentRowsSelected.isDragOver(cellAddr));
  const columnDragOver = useRecoilValue(UIState_ContentColumnsSelected.isDragOver(cellAddr));
  
  const selected = (
    ! rowAdder && 
    (  rowDragOver || columnDragOver )
  );
 
  return (
    <MainWrapper>
      <Background selected={selected} />
      { children }
    </MainWrapper>
  );
}
  
