import { useIsMobile } from 'lego-hooks/use-is-mobile';
import * as Types from '../types';

import NavbarClickComponent from '../navbar-click/navbar';
import NavbarTouchComponent from '../navbar-touch/navbar';


export interface Props {
  config: Types.Config;
}


export const NavbarComponent: React.FC<Props> = (props: Props) => {
  const isMobile = useIsMobile();

  const NavbarComp = (
    isMobile ?
    NavbarTouchComponent :
    NavbarClickComponent
  );

  return <NavbarComp {...props}/>;
}
