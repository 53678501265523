import { ThemeDefProps } from "../types";


export const themeDefs_scrollbar = (props: ThemeDefProps) => {
  const { colors, accentColor, border, padding, font } = props;

  const scrollbar = {
   width: {
    small:  '4px',
    normal: '6px'
   }
  };
    
  return scrollbar;
}
