import React from 'react';
import { useState } from 'react';
import TooltipComponent from 'lego/components/tooltip';

import { ReposTypes } from 'app/arch/app/states/repos';
import Icon from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useRepoMemberRemove } from 'app/ui-v2/app/hooks/use-repo-member-remove';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';

import PanelItemComponent from './panel-item';
import ElementTextComponent from './element-text';
import RepoMemberItemAccessComponent from './repo-member-item-access';

import { MainWrapper } from './styles';
import { Button } from './styles';
import { ProgressBox } from './styles';
import { ProgressDetacher } from './styles';
import { ProgressPositioner } from './styles';


interface Props {
  repoId: number;
  member: ReposTypes.Member;
}


export const MemberPanelComponent: React.FC<Props> = (props: Props) => {
  const {
    repoId,
    member
  } = props;

  const t = useTranslations();
  const removeRepoMember = useRepoMemberRemove();
  const [memberRemoving, setMemberRemoving] = useState(false);

  const handleRemoveMember = () => {
    removeRepoMember({
      repoId,
      memberUsername: member.username,
      onStart: () => {
        setMemberRemoving(true);
      },
      onDone: () => {
        setMemberRemoving(false);
      },
      onError: (error: string) => {
        setMemberRemoving(false);
      }
    });
  }

  
  const removeMemberDef = {
    title: t("repository remove member, item"),
    Element: (
      <TooltipComponent title={t("repository remove member, tooltip")}>
        <Button 
          disabled={memberRemoving}
          onClick={handleRemoveMember}
        >
          <Icon.Delete />
        </Button>
      </TooltipComponent>
    )
  }
  
  const updateMemberAccessDef = {
    title: t("repository member, access"),
    Element: (
      <RepoMemberItemAccessComponent 
        disabled={memberRemoving}
        repoId={repoId}
        member={member}
      />
    )
  }

  const memberStatusDef = {
    title: t("repository member, status"),
    Element: (
      <ElementTextComponent 
        text={t(`repository member, status ${member.status}`)}
      />
    )
  }

  const itemsDefs = [
    memberStatusDef,
    updateMemberAccessDef,
    removeMemberDef,
  ];
  
  const renderItems = () => {
    const Items = itemsDefs.map((itemDef, idx) => (
      <PanelItemComponent
        key={idx}
        title={itemDef.title}
        Element={itemDef.Element}
      />
    ));

    return Items;
  }


  const renderProgress = () => (
    <ProgressPositioner>
      <ProgressDetacher>
        <ProgressBoxComponent 
          Box={ProgressBox}
          hideTitle={true}
        />
      </ProgressDetacher>
    </ProgressPositioner>
  );

  return (
    <MainWrapper>
      {
        memberRemoving &&
        renderProgress()
      }
      { renderItems() }
    </MainWrapper>
  );
}
  
