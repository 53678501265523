import { useRecoilValue } from "recoil";
import { UIState_ViewsCommon } from "app/ui/states/editor-instruction";


const usePageLayout = () => {
  const pageLayout = useRecoilValue(UIState_ViewsCommon.pageLayout);
  return pageLayout;
}


export default usePageLayout;