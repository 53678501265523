
export const Config = {
  style: {
    border: {
      width: 5,
    },
    outline:  {
      width: 2
    },
  },

  shape: {
    circle: {
      size: 40
    },

    rectangle: {
      size: 40
    },

    diamond: {
      size: 36,
    }
  },

  icon: {
    size: 24,
  }
}
