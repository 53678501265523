import * as Types from './types';
import * as Defaults from './defaults';


/**
 * State
 */
export type State = {
  header: Types.Header,
};



/**
 * Getters
 */
export const getHeader = (state: State) => state.header;


/**
 * Initial state
 */
export const createInitialState = () => {
  const state: State = {
    header: Defaults.getHeader(),
  }

  return state;
}

