import React from 'react';

import { useDocState } from 'app/ui/contexts/document';
import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';

import HeaderCellCSSComponent  from '../../header-cell-css';
import HeaderCellViewComponent from '../../header-cell-view';

import { MainWrapper } from './styles';


interface Props {
  pageRowAddr: HeaderRowsTypes.PageRowAddr;
}


export const HeaderCellDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const {
    pageRowAddr
  } = props;

  const document = useDocState();
  const columnsProps = document.headerRows.getColumnsProps();
  const width = columnsProps[0].width;

  
  return (
    <MainWrapper 
      scale={1}
      width={width} 
    >
      <HeaderCellCSSComponent pageRowAddr={pageRowAddr}>
        <HeaderCellViewComponent pageRowAddr={pageRowAddr} />
      </HeaderCellCSSComponent>
    </MainWrapper>
  );
}
  
