import React from 'react';

import environment from 'app/environment';

import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useBillingPeriod    from '../../../../hooks/use-billing-period';
import useBillingPeriodSet from '../../../../hooks/use-billing-period-set';

import { MainWrapper }    from './styles';
import { Content }        from './styles';
import { ButtonsWrapper } from './styles';
import { ButtonLeft }     from './styles';
import { ButtonMiddle }   from './styles';
import { ButtonRight }    from './styles';
import { Title }          from './styles';
import { BtnText }          from './styles';


interface Props {
}


interface BtnDef {
  label: string,
  billingPeriod: PricePlansTypes.BillingPeriod,
  Component: React.ElementType
}


export const PricePlansBillingPeriodToggleComponent: React.FC<Props> = (props: Props) => {
  const {
  } = props;

  const t = useTranslations();
  const setBillingPeriod = useBillingPeriodSet();
  const billingPeriod = useBillingPeriod();

  const btnDay: BtnDef = {
    label: 'Daily',
    billingPeriod: PricePlansTypes.BillingPeriod.DAY,
    Component: ButtonMiddle
  }
  
  const btnMonth: BtnDef = {
    label: t('price monthly'),
    billingPeriod: PricePlansTypes.BillingPeriod.MONTH,
    Component: ButtonLeft
  }

  const btnYear: BtnDef = {
    label: t('price yearly'),
    billingPeriod: PricePlansTypes.BillingPeriod.YEAR,
    Component: ButtonRight
  }

  const renderBtn = (btnDef: BtnDef, idx: number) => {
    const Button = btnDef.Component;

    return (
      <Button
        key={`btn-${idx}`}
        onClick={() => setBillingPeriod(btnDef.billingPeriod) }
        $selected={billingPeriod === btnDef.billingPeriod}
      >
        <BtnText>
        { btnDef.label }
        </BtnText>
      </Button>
    );
  }

  const renderBtns = () => {
    const btnsDefs: BtnDef[] = (
      environment.dev ?
      [btnMonth, btnDay, btnYear] :
      [btnMonth, btnYear]
    )

    const Btns = btnsDefs.map((btnDef, idx) => renderBtn(btnDef, idx));
    return Btns;
  }


  return (
    <MainWrapper>
      <Content>
        <Title>
          { t('payment') }
        </Title>

        <ButtonsWrapper>
          { renderBtns() }
        </ButtonsWrapper>
      </Content>
    </MainWrapper>
  );
}

