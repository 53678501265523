import React, { useEffect } from 'react';

import { ViewTypes } from 'app/arch/home-page-guest/states/view';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import ViewPanelComponent  from 'app/ui-v2/home-page/hls/view-panel';
import useGuestGoHome       from 'app/ui-v2/home-page/home-page-guest/hooks/use-guest-go-home';
import useViewParamsRead   from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-params-read';

import { TextError } from "./styles";


interface Props {
}


export const ViewUserSignupFailedComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  const guestGoHome = useGuestGoHome();
  const viewParams = useViewParamsRead()  as ViewTypes.ViewParamsUserSignupFailed;
  
  useEffect(() => {
    if (viewParams === null) {
      guestGoHome();
    }
  }, [viewParams]);

  if ( viewParams === null) {
    return null;
  }

  const {
    error 
  } = viewParams;

  return (
    <ViewPanelComponent title={t('account creation failed')} >
      <TextError>
      { t('error') }:  { error }.
      </TextError>
    </ViewPanelComponent>     
  );
}
