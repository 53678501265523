import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { useDocState } from 'app/ui/contexts/document';

import { WidgetFC } from './widgets/types';

import WidgetDummyComponent      from './widgets/widget-dummy';
import WidgetEllipseComponent    from './widgets/widget-ellipse';
import WidgetRectangelComponent  from './widgets/widget-rectangel';
import WidgetImageComponent      from './widgets/widget-image';
import WidgetTextComponentSimple from './widgets/widget-text';
import WidgetArrowPlainComponent from './widgets/widget-arrow-plain';
import WidgetArrowTextComponentSimple from './widgets/widget-arrow-text';


export interface WidgetsProps {
  dataTest?: string;
  imageAddr: ContentTypes.ImageAddr;
  scale?: number;
  editDisabled?: boolean;

  widgetTextComponent?: WidgetFC;
  widgetArrowTextComponent?: WidgetFC;
}


export const WidgetsComponent: React.FC<WidgetsProps> = (props: WidgetsProps) => {
  const {
    dataTest,
    imageAddr, 
    editDisabled,
  } = props;

  const document = useDocState();
  const scale = (props.scale !== undefined ? props.scale : 1);
  const WidgetTextComponent      = props.widgetTextComponent || WidgetTextComponentSimple;
  const WidgetArrowTextComponent = props.widgetArrowTextComponent || WidgetArrowTextComponentSimple;

  const widgetsAddrs = useRecoilValue(UIState_Content.cellImages_image_widgetsAddrs(imageAddr)) || [];
  const WidgetMap: {[widgetType in ContentTypes.WidgetType]: WidgetFC} = {
    [ContentTypes.WidgetType.ARROW_PLAIN] : WidgetArrowPlainComponent,

    [ContentTypes.WidgetType.ARROW_TEXT ] : WidgetArrowTextComponent,
    [ContentTypes.WidgetType.TEXT       ] : WidgetTextComponent,
    
    [ContentTypes.WidgetType.RECTANGLE  ] : WidgetRectangelComponent,
    [ContentTypes.WidgetType.ELLIPSE    ] : WidgetEllipseComponent,
    [ContentTypes.WidgetType.IMAGE      ] : WidgetImageComponent,
  };

  const widgetsComponents = widgetsAddrs.map((widgetAddr) => {
    const widgetKey = ContentTools.getWidgetKey(widgetAddr);
    const widgetProps = document.content.cellImages_image_getWidgetProps(widgetAddr);
    const WidgetComponent = WidgetMap[widgetProps.type];
    const widgetIdx = document.content.cellImages_image_getWidgetIdx(widgetAddr);
    
    const widgetDataTest = (
      dataTest !== undefined ? (
        `${dataTest}`
        + `__widget`
        + `-${widgetIdx}`
      ) :
      null
    );

    return (
      <WidgetComponent
        key={widgetKey}
        dataTest={widgetDataTest}
        scale={scale}
        widgetAddr={widgetAddr}
        editDisabled={editDisabled}
      />
    );
  });

  return widgetsComponents;
}
