import { ThemeDefProps } from "../types";


export const themeDefs_itemSelected = (props: ThemeDefProps) => {
  const { 
    accentColor, 
    border 
  } = props;

  const itemSelected = {
    border: {
      style: 'solid',
      width:  border.width.small,
      radius: border.radius.normal,
      color:  accentColor.primary,
    },
  };
    
  return itemSelected;
}

