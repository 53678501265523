import * as Types from './types';
import * as State from './state';


export class SliceRequest {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  requestSlicing(request: Types.SliceRequest) {
    const nextState = State.requestSlicing(this._state, request);
    this.processNewState(nextState);
  }

  getSliceRequest() {
    return State.getSliceRequest(this._state);
  }

  private processNewState(newState: State.State) {
    return this._state = newState;
  }
}
