import React, { useEffect, useRef, useState } from 'react';
import jtl from 'tools/jtl';

import PasswordUpdatedComponent      from '../password-updated';
import PasswordUpdateFailedComponent from '../password-update-failed';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { MsgDuration     } from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import { useMutation } from 'app/arch/backend/use-mutation';
import { mutation    } from 'app/arch/backend';

import TokenExpiredComponent from '../token-expired';
import InputComponent    from 'app-views/components/input';
import ViewFormComponent from 'app/ui-v2/home-page/hls/view-form';


interface Props {
  email: string;
  token: string;
}


export const RequestNewPasswordComponent: React.FC<Props> = (props: Props) => {
  const { 
    email, 
    token 
  } = props;

  const t = useTranslations();
  const newPassword = useRef('');
  
  const [
    passwordUpdatedStatus, 
    setPasswordUpdateStatus
  ] = useState('INIT');
  
  const [
    isPasswordValid, 
    setIsPasswordValid
  ] = useState(false);

  const { 
    mutation: resetUserPassword,
    loading: loadingResetUserPassword,
    data: dataResetUserPassword
  } = useMutation(
    mutation.resetUserPassword,
    {
      onStart: {
        msg:  t("updating password"),
      },
      onEnd: 
      {
        msg: t("password updated"),
        duration: MsgDuration.MIDDLE
      }
    }
  );

  useEffect(() => {
    if (dataResetUserPassword === undefined) {
      return;
    }
    if ('userPasswordReset' in dataResetUserPassword === false) {
      return;
    }

    const data = dataResetUserPassword.userPasswordReset;
    if ( ! data.ok ) {
      console.warn("Problem while reseting password");
      setPasswordUpdateStatus(data.status);
      return;
    }

    setPasswordUpdateStatus("FINISHED");
  }, [dataResetUserPassword]);

  const handlePasswordChange = (password: string) => {
    newPassword.current = password;
    checkPassword(password);
  }

  const checkPassword = (password: string) => {
    setIsPasswordValid(jtl.password.isValid(password));
  }

  const handleSubmit = () => {
    resetUserPassword({ 
      variables: { 
        email,
        token,
        password: newPassword.current
      }
    });
  };

  const renderNewPassword = () => {
    return (
      <ViewFormComponent
        title={t("reset password")}
        onSubmit={handleSubmit}
        formButtonText={t('ok')}
      >
        <InputComponent
          title={t('enter new password')}
          type={'password'}
          onChange={handlePasswordChange}
        />
      </ViewFormComponent>
    );
  }

  const renderPasswordUpdated = () => {
    return (
      <PasswordUpdatedComponent 
        email={email}
        password={newPassword.current}
      />
    );
  }

  return (
    <>
      { passwordUpdatedStatus === "INIT" && renderNewPassword() }
      { passwordUpdatedStatus === "TOKEN_EXPIRED" && <TokenExpiredComponent /> }
      { passwordUpdatedStatus === "FINISHED" && renderPasswordUpdated() }
      { passwordUpdatedStatus === "FAILED" && <PasswordUpdateFailedComponent /> }
    </>
  );
}

