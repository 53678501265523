export interface HookConfigProps {
  showDialog:      (dialogProps?: any) => void;
  setOnOKHandler: (handler: any) => void;
  setOnCancelHandler:  (handler: any) => void;
}


//------------------
// Config hook
// This call is coming from DialogYesNoComponent

let _hookConfig: HookConfigProps | null = null;

export const configureHook = (props: HookConfigProps) => {
  _hookConfig = {
    ...props
  };
}


//-------------------
// Hook itself
//
export interface Cfg  {

}
export const useDialogEnterSingleLine = () => {
  const show = ({
    title,
    msg,
    inputLabel, 

    onCancelHandler, 
    onOKHandler
    }: any) => {
    _hookConfig!.setOnCancelHandler(onCancelHandler);
    _hookConfig!.setOnOKHandler(onOKHandler);
    _hookConfig!.showDialog({
      title, msg, inputLabel
    });
  }

  return {
    show
  }
}
