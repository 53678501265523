import { useEffect } from "react";
import { useProgressModal } from "lego-v2/progress-modal";


const useAppLoading = (props: {loading: boolean}) => {
  const {
    loading
  } = props;

  const {
    showProgressModal,
    hideProgressModal,
  } = useProgressModal();


  useEffect(() => {
    if ( loading ) {
      showProgressModal();
    }
    else {
      hideProgressModal();
    }
  }, [loading]);
}


export default useAppLoading;