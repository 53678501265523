import styled from "styled-components";
import { MdDelete } from 'react-icons/md';
import { MdEdit }   from "react-icons/md";
import { IoMdSettings } from "react-icons/io";


export const IconDelete = styled(MdDelete)`
`;


export const IconEdit = styled(MdEdit)`
`;


export const IconSettings = styled(IoMdSettings)`
`;
