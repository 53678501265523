import React from 'react';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';
import useDocumentScaleRef from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-ref';

import HeaderCellCssComponent  from '../../header-cell-css';
import HeaderCellViewComponent from '../../header-cell-view';
import { MainWrapper } from './styles';


interface Props {
  columnAddr: ReleaselogsTypes.ColumnAddr;
}


export const HeaderCellDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
  } = props;

  const document = useDocState();
  const columnProps = document.releaselogs.getColumnProps(columnAddr);
  const width = columnProps.width;
  const scaleRef = useDocumentScaleRef();
  const scale = scaleRef.current || 1;

  return (
    <MainWrapper 
      scale={scale}
      width={width} 
    >
      <HeaderCellCssComponent columnAddr={columnAddr} >
        <HeaderCellViewComponent columnAddr={columnAddr} />
      </HeaderCellCssComponent>
    </MainWrapper>
  );
}

