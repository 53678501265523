import React from 'react';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';

import PageContextComponent             from './page-context';
import PageItemDocInfoComponent         from './page-items/page-item-doc-info';
import PageItemDocCustomRowsComponent     from './page-items/page-item-doc-custom-rows';
import PageItemDocCustomFieldsComponent from './page-items/page-item-doc-custom-fields';
import PageItemDocMarkersComponent      from './page-items/page-item-doc-markers';
import PageItemContentComponent         from './page-items/page-item-content';
import PageItemDummyComponent           from './page-items/page-item-dummy';


interface Props {
  page: SlicerTypes.Page;
  pageIdx: number;
}


export const PageComponent: React.FC<Props> = (props: Props) => {
  const {
    page,
    pageIdx,
  } = props;

  const itemMap = {
    [SlicerTypes.PageItemType.DOC_INFO         ]: PageItemDocInfoComponent,
    [SlicerTypes.PageItemType.DOC_CUSTOM_ROWS  ]: PageItemDocCustomRowsComponent,
    [SlicerTypes.PageItemType.DOC_CUSTOM_FIELDS]: PageItemDocCustomFieldsComponent,
    [SlicerTypes.PageItemType.DOC_MARKERS      ]: PageItemDocMarkersComponent,
    [SlicerTypes.PageItemType.CONTENT          ]: PageItemContentComponent,
  }

  const renderPageItems = page.items.map((item, idx) => {
    const ItemComp = itemMap[item.type];

    return (
      <ItemComp 
        key={idx}
        item={item}
      />
    );
  })
  
  return (
    <PageContextComponent pageIdx={pageIdx}>
    { renderPageItems }
    </PageContextComponent>
  );
}
  
