import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { WidgetsStylesTypes } from 'app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles';
import { UIState_EditorImageWidgetsStyles } from 'app/ui/states/editor-instruction';

import StylesListComponent from '../styles-list';


interface Props {
  stylesLib: WidgetsStylesTypes.StyleLib;
  widgetAddr: ContentTypes.WidgetAddr | null;
}

export const StylesBaseComponent: React.FC<Props> = (props: Props) => {
  const {
    stylesLib,
    widgetAddr
  } = props;

  const stylesAddrs = useRecoilValue(UIState_EditorImageWidgetsStyles.libStylesAddrs(stylesLib));

  return (
    <StylesListComponent
      stylesLib={stylesLib}
      stylesAddrs={stylesAddrs}
      widgetAddr={widgetAddr}
    />
  );
}
  
