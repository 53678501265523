import React from 'react';
import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import useDocMarkerMenu     from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-doc-marker-menu';
import useContextMenu       from 'app/ui-v2/editor-instruction/hooks/use-context-menu';
import { MainWrapper }      from './styles';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr;
  children: React.ReactNode;
}


export const MarkerContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr,
    children,
  } = props;
  
  const getMenuConfig = useDocMarkerMenu();
  const { showMenu }  = useContextMenu();

  const handlerContextMenu = (event: React.MouseEvent) => {
    const config = getMenuConfig(markerAddr);
    showMenu({config, event});
  }

  return (
    <MainWrapper onContextMenu={handlerContextMenu}>
      { children }
    </MainWrapper>
  );
}
