import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import BtnColorComponent   from 'lego-v2/btn-color';
import { BtnColorProps   } from "lego-v2/btn-color";

import ColorPickerComponent from 'lego/components/color-picker';
import { ColorPickerProps } from 'lego/components/color-picker';
import { ColorPickerView } from 'lego/components/color-picker/types';

import { MainWrapper  } from './styles';
import { PanelWrapper } from './styles';
import { StyledPanel  } from './styles';


interface Props {
  id?: string,
  btnProps: BtnColorProps,
  colorPickerProps: ColorPickerProps,
  isPanelVisible: boolean,
  togglePanelVisible: () => void,
  onPanelEnter?: () => void,
  onPanelLeave?: () => void,
}


export const DropdownColorPickerComponent = (props: Props) => {
  const {
    id,
    btnProps,
    colorPickerProps,
    isPanelVisible,
    togglePanelVisible,
    onPanelEnter,
    onPanelLeave,
  } = props;
  
  const mainWrapperRef = useRef<HTMLDivElement>(null);

  const idProps = ( 
    id !== undefined ?
    {id} :
    {}
  );

  useEffect(() => {
    if ( ! isPanelVisible) return;
    window.addEventListener('keydown', handleKeydown, true);

    return () => {
      window.removeEventListener('keydown', handleKeydown, true);
    };
  }, [isPanelVisible]);

  const handleKeydown = (event: KeyboardEvent) => {
    if ( ! isPanelVisible ) {
      return;
    }
    if (event.key === 'Escape') {
      hidePanel();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  const hidePanel = () => {
    if (isPanelVisible) {
      togglePanelVisible();
    }
  }

  // const handleLostFocus = (e: any) => {
  //   const lostFocusToChild = mainWrapperRef.current!.contains(e.relatedTarget);
  //   if ( ! lostFocusToChild ) {
  //     hidePanel();
  //   }
  // }

  const stopPropagation = (event: any) => { 
    event.stopPropagation(); 
  }

  return (
    <MainWrapper
      {...idProps}
      ref={mainWrapperRef}
      // onBlur={handleLostFocus}
      onClick={stopPropagation}
      onPointerDown={stopPropagation}
      onPointerUp={stopPropagation}
    >
      <BtnColorComponent {...props.btnProps}/>
    {
      props.isPanelVisible &&
      <PanelWrapper>
        <StyledPanel
          onClick={stopPropagation}
          onPointerDown={stopPropagation}
          onPointerUp={stopPropagation}
          onPointerEnter={onPanelEnter}
          onPointerLeave={onPanelLeave}
        >
          <ColorPickerComponent 
            viewMode={ColorPickerView.PALETTE}
            color={colorPickerProps.color}
            colorPalette={colorPickerProps.colorPalette}
            onColorSelected={colorPickerProps.onColorSelected}
          />
        </StyledPanel>
      </PanelWrapper>
    }
    </MainWrapper>
  );
};
