import { uuid } from "tools/uuid";
import * as Types from './types';


/**
 * Info
 */

export const getInfoKey = (
  infoAddr: Types.InfoAddr
): string => (
  `${infoAddr.infoId}`
);

export const createInfoAddr = (
): Types.InfoAddr => {
  const infoId = uuid();
  const infoAddr = { infoId };
  return infoAddr;
}   

export const compareInfoAddr = (
  srcInfoAddr: Types.InfoAddr,
  dstInfoAddr: Types.InfoAddr,
): boolean => (
  srcInfoAddr.infoId === dstInfoAddr.infoId
);

