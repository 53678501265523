import styled from "styled-components";
import TextWrap from "lego/styles/text-wrap";


const Text = styled(TextWrap)`
  min-width: 30px;
`;


export default Text;
