import styled from 'styled-components';
import MenuItem from 'lego/styles/menu_item';
import PanelSecondary from 'lego/styles/panel_secondary';
import PanelWidgetStylesBase from '../../../styles/panel-widget-styles';


export const PanelWidgetStyle = styled(PanelWidgetStylesBase)`
`;


export const StylesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyleItemWrapper = styled.div`
  /* position: relative; */
`;

export const StyleItem = styled(MenuItem)`
  height: 24px;
`;

export const StyleHoveredPreviewDetacher = styled.div`
  position: absolute;
`;

export const StyleHoveredPreviewPanel = styled(PanelSecondary)`
  position: relative;
  background: ${props => props.theme.defs.colors.background.forth};
`;

export const StyleContextMenuStyleRel = styled.div`
  position: absolute;
`;

export const StyleContextMenuStyle = styled.div`
  position: absolute;
  top: -2px;
  left: -165px;
  // TODO autoprobe
  width: 0px;
`;
