import styled from "styled-components";


export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;


const TOP_MENU_HEIGHT = 36;

export const TopMenuWrapper = styled.div`
  height: ${TOP_MENU_HEIGHT}px;
`;

export const ContentWrapper = styled.div`
  height: calc(100% - ${TOP_MENU_HEIGHT}px);
  overflow: clip;
`;
