import styled from 'styled-components';
import Scrollbar from 'lego/styles/scrollbar';


interface ContentCliperProps {
  $isZooming: boolean;
}

export const Scroller = styled(Scrollbar).attrs<ContentCliperProps>(props =>({
  style: { 
    overflow: `${props.$isZooming ? 'hidden' : 'auto'}` 
  }
}))<ContentCliperProps>`
  width: 100%;
  height: 100%;
`;
