import { useRef } from 'react';

import { settings }            from 'app/configs';
import { UUID }                from 'app/arch/types';
import { useDocState }         from 'app/ui/contexts/document';
import useEditorStatesSetters from '../../../hooks/use-editor-states-setters';



export const useColumnImagesAutoScale = (props: {fitWidth?: boolean, fitHeight?: boolean}) => {
  const {
    fitWidth,
    fitHeight
  } = props;
  
  const document = useDocState();

  if ((! fitWidth && ! fitHeight) || (fitWidth && fitHeight)) {
    const msg = `Invalid implementation. FitWidth: ${fitWidth}; FitHeight: ${fitHeight}`;
    throw new Error(msg);
  }

  const fitWidth__ = fitWidth || ! fitHeight;
  // const imagesAddrRef = useRef<CellMedia_ImageAddr[]>([]);
  const imagesAddrRef = useRef<any[]>([]);

  const {
    setContent
  } = useEditorStatesSetters();

  // const fitterFunction = ( 
    // fitWidth__ ?
    // (imageAddr: CellMedia_ImageAddr, width: number)  => document.docContentDupong.cellMedia_image_viewFitToWidth(imageAddr, width) :
    // (imageAddr: CellMedia_ImageAddr, height: number) => document.docContentDupong.cellMedia_image_viewFitToHeight(imageAddr, height)
  // );

  const onChangeStart = (columnId: UUID) => {
    // imagesAddrRef.current = document.docContentDupong.columnMedia_getImages(columnId);
  }

  const onChange = (newSize: number) => {
    if ( imagesAddrRef.current.length === 0 ) { return;
    }

    // imagesAddrRef.current.forEach((imageAddr) => fitterFunction(imageAddr, newSize));
    // setDocContent2();
  }

  
  const onChangeDone = (value: number) => {
    if ( imagesAddrRef.current.length === 0 ) {
      return;
    }

    onChange(value);
    document.saveUndo();

    imagesAddrRef.current = [];
  }

  const getImagesViewScale = (columnId: UUID) => {
    // const imagesAddr = document.docContentDupong.columnMedia_getImages(columnId);
    // if ( imagesAddr.length === 0 ) {
    //   const min = settings.printView.imageScaleTo.min;

    //   // TODO - is this correct
    //   if (fitWidth__) {
    //     return min.width;
    //   }
    //   else {
    //     return min.height;
    //   }
    // }

    // const imageAddr = imagesAddr[0];
    // const image = document.docContentDupong.cellMedia_getImage(imageAddr);

    // ImageViewArea moved to conent types
    // const imageViewArea = image.viewArea as ImageViewAreaDef;

    // if (fitWidth__) {
    //   const width = imageViewArea.x2 - imageViewArea.x1;
    //   return image.viewScale * width ;
    // }
    // else {
    //   const height = imageViewArea.y2 - imageViewArea.y1;
    //   return image.viewScale * height ;
    // }
  }

  return {
    onChangeStart,
    onChange,
    onChangeDone,
    getImagesViewScale
  };
}


export default useColumnImagesAutoScale;
