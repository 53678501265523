import produce from 'immer';

import * as Tools from './tools';
import * as Types from './types';
import * as State from './state';


export class PricePlans {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get raw() { return this._state; }


  /**
   * Getters
   */

  getPlans(): Types.PricePlans {
    return State.getPlans(this._state);
  }
 
  getPlan(planType: Types.PlanType): Types.PricePlan {
    return State.getPlan(this._state, planType);
  }

  getBillingPriceLookup(
    planType: Types.PlanType,
    billingPeriod: Types.BillingPeriod
  ): string {
    const priceLookupKey = State.getBillingPriceLookup(this._state, planType, billingPeriod);
    return priceLookupKey;
  }

  getPlanAndBilling(priceLookup: string): { 
    planType: Types.PlanType,
    billingType: Types.BillingPeriod, 
  } | null {
    const plans = this._state.plans;
    
    for (const planType of Object.values(Types.PlanType)) {
      const plan = plans[planType];

      const pricesLookups = plan.price.lookup;
      const billingPeriods = Object.keys(pricesLookups);
      for (const period_ of billingPeriods) {
        const period = period_ as Types.BillingPeriod;
        if (pricesLookups[period] === priceLookup) {
          return {
            planType: planType,
            billingType: period
          };
        }
      }
    }

    const msg = `Can't find price plan for ${priceLookup}`;
    console.error(msg);

    return null;
  };
  
}
