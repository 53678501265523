import produce from 'immer';
import * as Types from './types';


/**
 * State
 */
export type State = {
  sliceRequest: Types.SliceRequest;
};

/**
 * Producers
 */
export const requestSlicing = produce((
  draft: State,
  request: Types.SliceRequest
) => {
  draft.sliceRequest = request;
});


/**
 * Getters
 */
export const getSliceRequest = (draft: State) => draft.sliceRequest;


/**
 * Initial state
 */
export const createInitialState = () => {
  const state: State = {
    sliceRequest: {},
  }

  return state;
}
