import styled from "styled-components";


export const Info = styled.div`
  user-select: none;
  pointer-events: none;

  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  color:      ${props => props.theme.defs.colors.color.secondary};
  background: ${props => props.theme.defs.accentColor.primary};

  font-size:   ${props => props.theme.defs.font.size.large};
  font-weight: ${props => props.theme.defs.font.weight.large};
  font-variant: small-caps;

  border-style:  solid;
  border-width:  ${props => props.theme.defs.border.width.normal};
  border-color:  ${props => props.theme.defs.accentColor.darker};
  border-radius: ${props => props.theme.defs.border.radius.normal};
`;


export const InfoItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${props => props.theme.defs.padding.medium};
`;


export const InfoItemSeparator = styled.div`
  width: 1px;
  background: ${props => props.theme.defs.accentColor.dark};
`;