import React from 'react';
import { Config } from '../../config';
    

interface Props {
  iconRotation?: number;
  Icon: React.ElementType;
  Sign: React.ElementType;
}


export const MarkerViewSignBaseComponent: React.FC<Props> = (props: Props) => {
  const { 
    Icon,
    Sign,
  } = props;

  const iconRotation = (props.iconRotation || 0);
  const iconSize = Config.icon.size;

  return (
    <Sign>
      <Icon 
        style={{
          maxWidth:  `${iconSize}px`,
          maxHeight: `${iconSize}px`,
          minWidth:  `${iconSize}px`,
          minHeight: `${iconSize}px`,
          transform: `rotate(${iconRotation}deg)`,
        }}
      />
    </Sign>
  );
}
