import { SideToolbar } from 'app/arch/editor-image/types';
import { useTranslations } from "app/ui/hooks/app/use-translation";
import Icon from "app/ui/icons/icons";

import useState from "./__use-state";
import useFunctions from "./__use-functions";


const useItems = () => {
  const t = useTranslations();
  const fns = useFunctions();
  const states = useState();


  const __getDataTest = (itemName: string) => {
    return (
      `editor-image`
      + `__menu-properties`
      + `__${itemName}`
    );
  }

  const getColors = () => ({
    tooltip: t('colors'),
    selected: states.panelVisible && states.panelType === SideToolbar.PanelType.COLORS,
    onClick: fns.showColors,
    Icon: Icon.Brush,
    dataTest: __getDataTest('colors'),
  });


  const getArrow = () => ({
    tooltip: t('arrow'),
    selected: states.panelVisible && states.panelType === SideToolbar.PanelType.ARROW,
    onClick: fns.showWidgetArrow,
    Icon: Icon.Arrow.Bold,
    dataTest: __getDataTest('arrow'),
  });


  const getTextBox = () => ({
    tooltip: t('text box'),
    selected: states.panelVisible && states.panelType === SideToolbar.PanelType.TEXT_BOX,
    onClick: fns.showWidgetTextBox,
    Icon: Icon.TextBox,
    dataTest: __getDataTest('text-box'),
  });


  const getStyles = () => ({
    tooltip: t('styles'),
    selected: states.panelVisible && states.panelType === SideToolbar.PanelType.STYLES,
    onClick: fns.showWidgetStyles,
    Icon: Icon.Styles,
    dataTest: __getDataTest('styles'),
  });

  const getView = () => ({
    tooltip: t('image, view'),
    selected: states.panelVisible && states.panelType === SideToolbar.PanelType.VIEW,
    onClick: fns.showView,
    Icon: Icon.ViewSize,
    dataTest: __getDataTest('image-view'),
  });

  const getLayers = () => ({
    tooltip: t('layers'),
    selected: states.panelVisible && states.panelType === SideToolbar.PanelType.LAYERS,
    onClick: fns.showLayers,
    Icon: Icon.Layers,
    dataTest: __getDataTest('layers'),
  });

  const getSmartLines = () => ({
    tooltip: 'Smartlines',
    selected: states.panelVisible && states.panelType === SideToolbar.PanelType.SMART_LINES,
    onClick: fns.showSmartLines,
    Icon: Icon.Grid,
  });

  return {
    getColors,
    getArrow,
    getTextBox,
    getStyles,
    getView,
    getLayers,
    getSmartLines,
  }
}


export default useItems;