import styled from 'styled-components';


export const MainWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100%;
  height: 100%;

  z-index: 500002; 
  
  pointer-events: none;
`;