import styled from "styled-components";

interface InputProps {
  focused?: boolean;
}

export const Input = styled.input<InputProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: ${props => props.theme.defs.colors.color.primary};
    opacity: ${props => props.theme.defs.opacity.fadeMore};
    font-size: ${props => props.theme.defs.font.size.normal};
    font-weight: ${props => props.theme.defs.font.weight.small};
    font-style: italic;
  }

  ${props => props.focused ? `
      &::placeholder {
        visibility: hidden;
      }
    `: 
    ''
  };

  box-sizing: border-box;

  color:         ${props => props.theme.input.color};
  background:    ${props => props.theme.input.background};
  border-color:  ${props => props.theme.input.border.color};
  border-radius: ${props => props.theme.input.border.radius};
  border-style:  ${props => props.theme.input.border.style};
  border-width:  ${props => props.theme.input.border.width};
  font-size:     ${props => props.theme.input.font.size};
  padding:       ${props => props.theme.input.padding};

  &:focus-visible {
    outline-style: ${props => props.theme.attrs.focusVisible.outline.style};
    outline-width: ${props => props.theme.attrs.focusVisible.outline.width};
    outline-color: ${props => props.theme.attrs.focusVisible.outline.color};
  }

  opacity: ${props =>
    props.disabled ?
    props.theme.defs.opacity.disabled :
    1
  };

  cursor: ${props =>
    props.disabled ?
    "not-allowed" :
    "auto"
  };
`;


export default Input;