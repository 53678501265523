import Fuse from 'fuse.js';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { ViewTypes }   from 'app/arch/home-page-user/states/view';
import { Instruction } from 'app/arch/backend/types';
import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import useDocumentEdit      from 'app/ui-v2/editor-instruction/hooks/use-document-edit';
import useViewSelect        from 'app/ui-v2/home-page/home-page-user/hooks/use-view-select';
import useViewParamsSet     from 'app/ui-v2/home-page/home-page-user/hooks/use-view-params-set';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';

import PanelTitledComponent from 'app-views/components/panel-titled';
import InstructionItemComponent from '../instruction-item';

import InstructionListHeaderComponent from './instruction-list-header';
import SearchInputComponent from './search-input';
import * as Tools from './tools';
import * as Types from './types';

import { Panel } from './styles';
import { PanelContent } from './styles';
import { DocsWrapper } from './styles';
import { ProgressWrapper } from './styles';


interface Props {
  docsLoaded: boolean;
  documents: Instruction[];
}


export const InstructionsListComponent: React.FC<Props> = (props: Props) => {
  const { 
    docsLoaded,
    documents: documentsInit,
  } = props;

  const t = useTranslations();
  const editDocument = useDocumentEdit();
  const selectView = useViewSelect();
  const setViewParams = useViewParamsSet();
  const [documents, setDocuments] = useState(documentsInit);
  const [documentsAll, setDocumentsAll] = useState(documentsInit);
  const [sortBy, setSortBy] = useState(Types.SortBy.NONE);
  const [sortOrder, setSortOrder] = useState(Types.SortOrder.ASC);
  const [selectedDoc, setSelectedDoc] = useState<number | null>(null);


  useEffect(() => {
    if ( ! docsLoaded ) {
      return;
    }

    if (documentsInit === documents) {
      return;
    }

    setDocuments(documentsInit);
    setDocumentsAll(documentsInit);
  }, [documentsInit, docsLoaded]);


  const handleInstructionSelect = (documentId: number) => {
    setSelectedDoc(documentId);
  }

  const handleInstructionEdit = (documentId: number) => {
    editDocument(documentId);
  }
  
  const handleInstructionSettings = (documentId: number) => {
    selectView(ViewTypes.ViewItem.DOCUMENT);
    setViewParams({documentId});
  }

  const handleInstructionsSort = (sortByUpdate: Types.SortBy) => {
    const isSameSortBy = (sortBy === sortByUpdate);

    let sortOrderUpdate = sortOrder;
    if (isSameSortBy) {
      if (sortOrder === Types.SortOrder.ASC) {
        sortOrderUpdate = Types.SortOrder.DESC;
      }
      else {
        sortOrderUpdate = Types.SortOrder.ASC;
      }
    }

    setSortBy(sortByUpdate);
    setSortOrder(sortOrderUpdate);

    const docSorted = Tools.sortInstructions(
      documents, 
      sortByUpdate, 
      sortOrderUpdate
    );
    setDocuments(docSorted)
  }

  const handleDocsSearch = (search: string) => {
    if (search.length <= 0) {
      setDocuments(documentsAll);
      return;
    }
    
    const titles = documentsAll.map(doc => doc.title);
    const fuse = new Fuse(titles, { threshold: 0.3 });
    const results = fuse.search(search);

    const docs = results.map((result, idx) => {
      const refIdx = result.refIndex;
      const doc = documentsAll[refIdx];
      return doc;
    });

    setDocuments(docs);
  }

  const handleDocsSearchCancel = () => {
    setDocuments(documentsAll);
  }

  const renderSearchDoc = () => {
    return (
      <SearchInputComponent
        onSearch={handleDocsSearch}
        onCancel={handleDocsSearchCancel}
      />
    );
  }

  const renderDoc = (instruction: Instruction, idx: number) => {
    const selected = selectedDoc === +instruction.id;

    return (
      <InstructionItemComponent 
        key={idx}
        selected={selected}
        instruction={instruction}
        onInstructionSelect={handleInstructionSelect}
        onInstructionEdit={handleInstructionEdit}
        onInstructionSettings={handleInstructionSettings}
      />
    );
  }

  const renderDocs = () => {
    const SearchComp = renderSearchDoc();
    const DocsComps = documents.map((doc: any, idx: number) => renderDoc(doc, idx));

    return (
      <PanelTitledComponent
        title={t('your documents')}
        Panel={Panel}
        Header={
          <InstructionListHeaderComponent 
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={handleInstructionsSort}
          />
        }
      >
        <PanelContent>
          { SearchComp }
          <DocsWrapper>
            { DocsComps }
          </DocsWrapper>
        </PanelContent>
      </PanelTitledComponent>
    );
  }

  return (
    <>
      { docsLoaded && renderDocs() }

      { 
        ! docsLoaded &&
        <ProgressWrapper>
          <ProgressBoxComponent 
            title={t("documents loading")}
          />
        </ProgressWrapper>
      }
    </>
  );
}

