import React from 'react';
import { useRecoilValue } from 'recoil';

import { ReposTools } from 'app/arch/app/states/repos';
import { ReposTypes } from 'app/arch/app/states/repos';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { UIState_Repos }   from 'app/ui/states/app/repos-state';
import useRepo      from 'app/ui-v2/editor-instruction/hooks/use-repo';
import useRepos     from 'app/ui-v2/app/hooks/use-repos';
import useUsername  from 'app/ui-v2/app/hooks/use-user-name';
import useDocsFetch from 'app/ui-v2/home-page/home-page-user/hooks/use-docs-fetch';
import ViewTitledComponent from 'app/ui-v2/home-page/hls/view-titled';

import RepoSetterComponent          from 'app-views/app-components/repo-setter';
import RepoInvitationPanelComponent from 'app-views/app-components/repo-invitation-panel';

import InstructionsListComponent   from './instructions-list/instructions-list';
import CreateDocumentFormComponent from './create-document-form';

import { DocumentsWrapper }  from './styles';
import { AutoHeightWrapper } from './styles';


interface Props {
}


export const ViewDocumentsComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const username = useUsername();
  const repos = useRepos();
  const reposPacks = useRecoilValue(UIState_Repos.reposPacks);
  const { id: repoId } = useRepo();

  const memberAccess = repos.getRepoMemberAccess(repoId, username);
  const memberStatus = repos.getRepoMemberStatus(repoId, username);
  
  const memberStatusAccepted = (memberStatus === ReposTypes.MemberStatus.INVITE_ACCEPTED);
  const memberWriteAccess    = ReposTools.hasAccessWrite(memberAccess);

  const {
    documents,
    loaded: docsLoaded
  } = useDocsFetch({
    repoId, 
    skip: ! memberStatusAccepted,
    skipMsgs: true,
  });

  const displayReposSetter   = (reposPacks.length > 1);
  const displayDocInvitation = (memberStatus === ReposTypes.MemberStatus.INVITE_SENT);
  const displayCreateDoc     = (memberStatusAccepted && memberWriteAccess);
  
  const docsAvailable  = (docsLoaded && documents.length > 0);
  const displayDocList = (memberStatusAccepted && (! docsLoaded || docsAvailable));


  return (
    <ViewTitledComponent title={t('documents')}>
      {
        displayReposSetter &&
        <AutoHeightWrapper>
          <RepoSetterComponent selectedRepoId={repoId} />
        </AutoHeightWrapper>
      }

      {
        displayDocInvitation &&
        <AutoHeightWrapper>
          <RepoInvitationPanelComponent repoId={repoId} />
        </AutoHeightWrapper>
      }

      {
        displayCreateDoc &&
        <AutoHeightWrapper>
          <CreateDocumentFormComponent />
        </AutoHeightWrapper>
      }

      {
        displayDocList &&
        <DocumentsWrapper>
          <InstructionsListComponent 
            docsLoaded={docsLoaded}
            documents={documents}
          />
        </DocumentsWrapper>
      }
        
    </ViewTitledComponent>
  );
}

