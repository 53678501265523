import React from 'react';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { useEditor } from 'app/ui-v2/editor-instruction/hooks/use-editor';
import LexicalToolbarComponent from 'app/ui-v2/editor-instruction/hls/lexical/lexical-toolbar';

import { TopToolbar } from './styles';


interface Props {
  pageCellAddr: HeaderRowsTypes.PageCellAddr;
}


export const CellTextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr
  } = props;

  const editor = useEditor();
  const lexicalRegistry = editor.lexicalRegistryCustomRows;
  const lexical = lexicalRegistry.getLexical(pageCellAddr);

  return (
    <TopToolbar>
      <LexicalToolbarComponent lexical={lexical} />
    </TopToolbar>  
  );
}
  
