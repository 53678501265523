import { PanelControlsConfig } from "lego/components/panel-controls/config";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useItems from "./__use-items";
import useState from "./__use-state";


const useConfigView = () => {
  const t = useTranslations();
  const items = useItems();
  const states = useState();


  const getConfig = (removeMe: any) => {
    const config: PanelControlsConfig = {
      items: [
        {
          title: t('view image, view size'),
          sections: [
            {
              subsections: [
                {
                  title: t('view image, auto fit'),
                  items: [
                    items.getAutoFitMode(),
                    items.getAutoFitType(),
                  ]
                },                
  
                {
                  skip: states.isAutoFitAutomatic,
                  title: t('view image, fit'),
                  items: [
                    items.getOverlayerSizeAutoFit(),
                    items.getOverlayerLocked(),
                  ]
                },
                
                {
                  skip: states.isAutoFitAutomatic,
                  title: t('view image, overlayer'),
                  items: [
                    items.getOverlayerViewType(),
                  ]
                },
              ]
            },
          ]
        },
      ]
    } // config

    return config;
  }

  return getConfig;

}


export default useConfigView;