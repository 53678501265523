import { useEffect } from 'react';
import jtl from 'tools/jtl';

import { mutation }     from "app/arch/backend";
import { useMutation }  from "app/arch/backend/use-mutation";
import { HistoryTypes } from 'app/arch/editor-instruction/document/states/history-state';
import { MsgDuration }     from "app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box";
import { useDocState }     from "app/ui/contexts/document";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useDocTemplates     from 'app/ui-v2/doc-templates/hooks/use-doc-templates';
import { useDialogYesNo }  from 'app/ui/components/editor-instruction/dialogs/dialog-yes-no/use-dialog-yes-no';
import { useInstruction }  from 'app/ui/hooks/editor-instruction/use-instruction';

import useRepo from './use-repo';


const useDocTemplateSave = () => {
  const t = useTranslations();
  
  const repo = useRepo();
  const instruction = useInstruction();
  const document = useDocState();
  const docTemplates = useDocTemplates();

  const { 
    show: showDialog 
  } = useDialogYesNo();

  const { 
    mutation: mutate,
    data: mutData,
    variables: mutVariables
  } = useMutation(
    mutation.saveDocTemplate, {
      onStart: { msg:  t("saving"), },
      onEnd:   { msg:  t("saved"), duration: MsgDuration.MIDDLE }
    }
  );

  useEffect(() => {
    if (mutData === undefined) {
      return;
    }

    const data = mutData.docTemplateSave;
    if ( ! data.ok) {
      console.warn("Can't save document template");
      return;
    }

    templateSaved();
  }, [mutData]);


  const templateSaved = () => {
    if (repo.id === null) {
      console.error("Repo id is null. Can't export template");
      return;
    }

    const contentSerial = mutVariables.content;
    const content =  jtl.serialize.deserialize(contentSerial);
    docTemplates.template.setTemplate(repo.id, content);
  }

  const saveTemplate = () => {
    if (repo.id === null) {
      const msg = `RepoId is null`;
      throw new Error(msg);
    }

    const contentStates: HistoryTypes.Record = jtl.object.copy(document.getContentStates());
    const headerMeta = contentStates.headerMeta;
    
    if ( headerMeta.logo.image !== null ) {
      headerMeta.logo.image.bid = -1;
    }
    const contentSerial = jtl.serialize.serialize(contentStates);

    mutate({ 
      variables: { 
        content: contentSerial, 
        instructionId: instruction.id
      }
    });
  }

  //
  // Hook
  //
  const hook = () => {
    const title   = t("repository template export, dialog title");
    const message = t("repository template export, dialog msg");
    const dataTest = 'editor__dialog__export-template';

    showDialog({
      title,
      message,
      dataTest,
      onNoHandler: () => {
      },
      onYesHandler: () => {
        saveTemplate();
      },
      noAutoFocus: true,
    });
  }

  return hook;
}


export default useDocTemplateSave;
