import { useSetRecoilState } from "recoil";

import { useDocState } from "app/ui/contexts/document";

import { UIState_CustomFieldsColumnsSelected } from "app/ui/states/editor-instruction";
import { UIState_CustomRowsRowsSelected } from "app/ui/states/editor-instruction";
import { UIState_ContentRowsSelected } from "app/ui/states/editor-instruction";
import { UIState_ContentColumnsSelected } from "app/ui/states/editor-instruction";

import { UIState_Content } from "app/ui/states/editor-instruction";
import { UIState_ContentSession } from "app/ui/states/editor-instruction";
import { UIState_HeaderFields } from "app/ui/states/editor-instruction";
import { UIState_HeaderFieldsSession } from "app/ui/states/editor-instruction";
import { UIState_ContentView } from "app/ui/states/editor-instruction";
import { UIState_ContentSliceRequest } from "app/ui/states/editor-instruction";
import { UIState_Releaselogs } from "app/ui/states/editor-instruction";
import { UIState_ReleaselogsSession } from "app/ui/states/editor-instruction";
import { UIState_ReleaselogsView } from "app/ui/states/editor-instruction";
import { UIState_ReleaselogsSliceRequest } from "app/ui/states/editor-instruction";
import { UIState_HeaderRows } from "app/ui/states/editor-instruction";
import { UIState_HeaderRowsSession } from "app/ui/states/editor-instruction";
import { UIState_HeaderMeta } from "app/ui/states/editor-instruction";
import { UIState_HeaderMetaSession } from "app/ui/states/editor-instruction";
import { UIState_HeaderMarkers } from "app/ui/states/editor-instruction";
import { UIState_HeaderMarkersSession } from "app/ui/states/editor-instruction";
import { UIState_RepoImages } from "app/ui/states/editor-instruction";
import { UIState_RepoImagesSession } from "app/ui/states/editor-instruction";
import { UIState_MetaDataState } from "app/ui/states/editor-instruction";
import { UIState_RepoMarkers } from "app/ui/states/editor-instruction";
import { UIState_ViewsCommon } from "app/ui/states/editor-instruction";
import { UIState_EditorSession } from "app/ui/states/editor-instruction";
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';
import { UIState_EditorLogoSession } from "app/ui-v2/editor-logo/states";
import { UIState_EditorMarkerSession } from "app/ui-v2/editor-markers/states";
import { UIState_Instruction } from "app/ui/states/editor-instruction";
import { UIState_EditorImageSettings } from "app/ui/states/editor-instruction";
import { UIState_EditorImageWidgetsStyles } from "app/ui/states/editor-instruction";
import { UIPrintoutView } from "../views/view-printout/states";


const useEditorStatesSetters = () => {
  const document = useDocState();
  const docState = document;


  /**
   *  Loadable */
  const __setInstruction   = useSetRecoilState(UIState_Instruction.state);
  const __setRepoImages    = useSetRecoilState(UIState_RepoImages.state);
  const __setEditorImageWidgetsStyles = useSetRecoilState(UIState_EditorImageWidgetsStyles.state);

  const setInstruction   = () => __setInstruction(docState.instruction.state);
  const setRepoImages    = () => __setRepoImages(docState.repoImages.state);
  const setEditorImageWidgetsStyles = () => __setEditorImageWidgetsStyles(docState.widgetsStyles.state);


  /** 
   * Persistent */
  const __setContent             = useSetRecoilState(UIState_Content.state);
  const __setContentView         = useSetRecoilState(UIState_ContentView.state);
  const __setEditorImageSettings = useSetRecoilState(UIState_EditorImageSettings.state);
  const __setHeaderFields        = useSetRecoilState(UIState_HeaderFields.state);
  const __setHeaderRows          = useSetRecoilState(UIState_HeaderRows.state);
  const __setHeaderMarkers       = useSetRecoilState(UIState_HeaderMarkers.state);
  const __setHeaderMeta          = useSetRecoilState(UIState_HeaderMeta.state);
  const __setMetaData            = useSetRecoilState(UIState_MetaDataState.state);
  const __setReleaselogs         = useSetRecoilState(UIState_Releaselogs.state);
  const __setReleaselogsView     = useSetRecoilState(UIState_ReleaselogsView.state);
  const __setRepoMarkers         = useSetRecoilState(UIState_RepoMarkers.state);
  const __setViewsCommon         = useSetRecoilState(UIState_ViewsCommon.state);

  const setContent             = () => __setContent(docState.content.state);
  const setContentView         = () => __setContentView(docState.contentView.state);
  const setEditorImageSettings = () => __setEditorImageSettings(docState.editorImageSettings.state);
  const setHeaderFields        = () => __setHeaderFields(docState.headerFields.state);
  const setHeaderRows          = () => __setHeaderRows(docState.headerRows.state);
  const setHeaderMarkers       = () => __setHeaderMarkers(docState.headerMarkers.state);
  const setHeaderMeta          = () => __setHeaderMeta(docState.headerMeta.state);
  const setMetaData            = () => __setMetaData(docState.metaData.state);
  const setReleaselogs         = () => __setReleaselogs(docState.releaselogs.state);
  const setReleaselogsView     = () => __setReleaselogsView(docState.releaselogsView.state);
  const setRepoMarkers         = () => __setRepoMarkers(docState.repoMarkers.state);
  const setViewsCommon         = () => __setViewsCommon(docState.viewsCommon.state);


  /** 
   * Selected */
  const __setContentRowsSelected  = useSetRecoilState(UIState_ContentRowsSelected.state);
  const __setContentColumnsSelected = useSetRecoilState(UIState_ContentColumnsSelected.state);
  const __setCustomRowsRowsSelected = useSetRecoilState(UIState_CustomRowsRowsSelected.state);
  const __setCustomFieldsColumnsSelected = useSetRecoilState(UIState_CustomFieldsColumnsSelected.state);

  const setContentRowsSelected  = () => __setContentRowsSelected(docState.contentRowsSelected.state);
  const setContentColumnsSelected = () => __setContentColumnsSelected(docState.contentColumnsSelected.state);
  const setCustomRowsRowsSelected = () => __setCustomRowsRowsSelected(docState.customRowsRowsSelected.state);
  const setCustomFieldsColumnsSelected = () => __setCustomFieldsColumnsSelected(docState.customFieldsColumnsSelected.state);


  /** 
   * Sessions */
  const __setContentSession       = useSetRecoilState(UIState_ContentSession.state);
  const __setEditorImageSession   = useSetRecoilState(UIState_EditorImageSession.state);
  const __setEditorLogoSession    = useSetRecoilState(UIState_EditorLogoSession.state);
  const __setEditorMarkerSession  = useSetRecoilState(UIState_EditorMarkerSession.state);
  const __setEditorSession        = useSetRecoilState(UIState_EditorSession.state);
  const __setHeaderFieldsSession  = useSetRecoilState(UIState_HeaderFieldsSession.state);
  const __setHeaderRowsSession    = useSetRecoilState(UIState_HeaderRowsSession.state);
  const __setHeaderMarkersSession = useSetRecoilState(UIState_HeaderMarkersSession.state);
  const __setHeaderMetaSession    = useSetRecoilState(UIState_HeaderMetaSession.state);
  const __setReleaselogsSession   = useSetRecoilState(UIState_ReleaselogsSession.state);
  const __setRepoImagesSession    = useSetRecoilState(UIState_RepoImagesSession.state);

  const setContentSession       = () => __setContentSession(docState.contentSession.state);
  const setEditorImageSession   = () => __setEditorImageSession(docState.editorImageSession.state);
  const setEditorLogoSession    = () => __setEditorLogoSession(docState.editorLogoSession.state);
  const setEditorMarkerSession  = () => __setEditorMarkerSession(docState.editorMarkerSession.state);
  const setEditorSession        = () => __setEditorSession(docState.editorSession.state);
  const setHeaderFieldsSession  = () => __setHeaderFieldsSession(docState.headerFieldsSession.state);
  const setHeaderRowsSession    = () => __setHeaderRowsSession(docState.headerRowsSession.state);
  const setHeaderMarkersSession = () => __setHeaderMarkersSession(docState.headerMarkersSession.state);
  const setHeaderMetaSession    = () => __setHeaderMetaSession(docState.headerMetaSession.state);
  const setReleaselogsSession   = () => __setReleaselogsSession(docState.releaselogsSession.state);
  const setRepoImagesSession    = () => __setRepoImagesSession(docState.repoImagesSession.state);


  /** 
   * Slicing */
  const __setContentSliceRequest     = useSetRecoilState(UIState_ContentSliceRequest.state);
  const __setReleaselogsSliceRequest = useSetRecoilState(UIState_ReleaselogsSliceRequest.state);

  const setContentSliceRequest     = () => __setContentSliceRequest(docState.contentSliceRequest.state);
  const setReleaselogsSliceRequest = () => __setReleaselogsSliceRequest(docState.releaselogsSliceRequest.state);


  /**
   *  Printout */
  const __setPrintoutView = useSetRecoilState(UIPrintoutView.state);
  const setPrintoutView   = () => __setPrintoutView(docState.printoutView.state);



  return {
    /** Loadable */
    setInstruction,
    setRepoImages,
    setEditorImageWidgetsStyles,

    /** Persistent */
    setContent,
    setContentView,
    setEditorImageSettings,
    setHeaderFields,
    setHeaderRows,
    setHeaderMarkers,
    setHeaderMeta,
    setMetaData,
    setReleaselogs,
    setReleaselogsView,
    setRepoMarkers,
    setViewsCommon,


    /** Selected */
    setContentRowsSelected,
    setContentColumnsSelected,
    setCustomRowsRowsSelected,
    setCustomFieldsColumnsSelected,

    
    /** Sessions */
    setContentSession,
    setEditorImageSession,
    setEditorLogoSession,
    setEditorMarkerSession,
    setEditorSession,
    setHeaderFieldsSession,
    setHeaderRowsSession,
    setHeaderMarkersSession,
    setHeaderMetaSession,
    setReleaselogsSession,
    setRepoImagesSession,

    /** Slicing */
    setContentSliceRequest,
    setReleaselogsSliceRequest,

    /** Printout */
    setPrintoutView,
  }
}


export default useEditorStatesSetters;