import styled from "styled-components";

interface ButtonProps {
  $selected?: boolean;
}

const Button = styled.button<ButtonProps>`
  cursor: pointer;
  user-select: none;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  box-sizing: border-box;
  height: ${props => props.theme.button.size.primary.height}px;
  max-width: ${props => props.theme.button.size.max.width}px;

  border-style: ${props => props.theme.button.border.style};
  border-width: ${props => props.theme.button.border.width};
  border-radius: ${props => props.theme.button.border.radius};

  ${props => props.$selected ? 
  `
    color:        ${props.theme.button.color.selected};
    background:   ${props.theme.button.background.selected};
    border-color: ${props.theme.button.border.color.selected};
  `
  : 
  `
    color:        ${props.theme.button.color.primary};
    background:   ${props.theme.button.background.primary};
    border-color: ${props.theme.button.border.color.primary};

    &:focus-visible:not(:active) {
      outline-style: ${props.theme.attrs.focusVisible.outline.style};
      outline-width: ${props.theme.attrs.focusVisible.outline.width};
      outline-color: ${props.theme.attrs.focusVisible.outline.color};
    }
  `
  }

  &:disabled {
    cursor: not-allowed;
    opacity: ${props => props.theme.defs.opacity.disabled};
  }

  &:active:not(:disabled) {
    color:        ${props => props.theme.button.color.active};
    background:   ${props => props.theme.button.background.active};
    border-color: ${props => props.theme.button.border.color.active};
  }

  &:hover:not(:active) {
    /* color: ${props =>
      props.$selected ?
      props.theme.button.color.selected :
      props.theme.button.color.hover
    };
      
    border-color: ${props => 
      props.$selected ?
      props.theme.button.border.color.selected :
      props.theme.button.border.color.hover
    };

    background: ${props => 
      props.$selected ?
      props.theme.button.background.selected :
      props.theme.button.background.hover
    }; */
  }
`;


// &:hover:not(:active) {
//   ${props => props.selected ? 
//     `
//       background: ${props.theme.background.hover.selected};
//     `
//   : 
//     `
//       background: ${props.theme.background.hover.inactive};
//     `
//   }
// }
export default Button;