import { ThemeConstsProps } from "../types";


export const themeConsts_transition = (props: ThemeConstsProps) => {
  const transition = {
    time: {
      quick:  '0.1s',
      normal: '0.2s',
      middle: '0.4s',
      long:   '0.6s', // Image editor side panel slide in
    },
  };

  return transition;
}
