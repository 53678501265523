import React, { useRef }  from 'react';
import jtl from 'tools/jtl';

import { Position } from 'app/arch/types';
import { DeltaMove        } from 'app/ui/hooks';
import { useDraggingDelta } from 'app/ui/hooks';

import { FrameControlBox } from '../../styles/frame-control-box';
import { Anchor, Detacher } from './styles';


const DEBOUNCE_DELAY = 0;


interface Props {
  position: Position;
  scale: number;
  
  onDragStart?: () => void;
  onDrag?:      (deltaMove: Position) => void;
  onDragStop?:  () => void;
}


export const ControlBoxComponent: React.FC<Props> = (props: Props) => {
  const {
    scale,
    position,
    onDragStart,
    onDrag,
    onDragStop
  } = props;

  const dragging = useRef(false);

  const handlePointerDown = (event: React.PointerEvent) => {
    const started = startDragging(event);
    if ( ! started ) {
      return;
    }

    dragging.current = true;
    onDragStart?.();
  };
  
  const updateDebounced = jtl.core.debounce((delta: DeltaMove) => {
    const updatePosition = [
      delta.x / scale,
      delta.y / scale
    ] as Position;

    if ( ! dragging.current ) return;
    
    onDrag?.(updatePosition);
  }, DEBOUNCE_DELAY);

  const handleDragMove = (delta: DeltaMove) => {
    updateDebounced(delta);
  }

  const handleDragEnd = (delta: DeltaMove) => {
    dragging.current = false;
    onDragStop?.();
  }

  const {
    startDragging
  } = useDraggingDelta({
    onDragMove: handleDragMove,
    onDragEnd: handleDragEnd
  });

  const left = position[0];
  const top  = position[1];

  return (
    <Anchor>
      <Detacher
        style={{
          left: `${left}px`,
          top:  `${top}px`,

          transform: `scale(${1 / scale})`,
          transformOrigin: '0 0',
        }}
      >
        <FrameControlBox
          onPointerDown={handlePointerDown}
        />
      </Detacher>
    </Anchor>
  );
}
