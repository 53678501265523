import { atom } from 'recoil';
import { selector } from 'recoil';
import { ScreenSizeState as State } from 'app/arch/app/screen';


export const state = atom<State.State>({
  key: "screen_size",
  default: State.createInitialState()
});


export const sizeVariant = selector({
  key: `screen_size_variant`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getSizeVariant(stateObj);
  }
});
