import React from 'react';

import { settings } from 'app/configs';
import environment from 'app/environment';

import { Page as PageTool } from 'app/arch/print/page';
import { UnitsSymbol } from 'app/arch/types/types';
import usePrintoutViewPageCurrent from 'app/ui-v2/editor-instruction/views/view-printout/hooks/use-printout-view-page-current';
import useFeatureFlagState from 'app/ui-v2/app/__modules/feature-flag/hooks/use-feature-flag-state';
import WatermarkComponent from 'app/ui-v2/editor-instruction/components/watermark-layer/WatermarkLayer';
import useViewPagesContext from '../../../../hooks/use-view-pages-context';
import PageIdxControlComponent from './page-idx-control';
import DemoOverviewComponent from './demo-overview';

import { useIsGuestUser } from 'services/auth';

import { PageProps } from '../types';
import { Page } from './styles';
import { PageBody } from './styles';


interface Props extends PageProps {
}


export const PagePrintoutComponent: React.FC<Props> = (props: Props) => {
  const { 
    pageIdx,
    margins,
    format, 
    orientation,

    children,
  } = props;
  
  const featureFlag = useFeatureFlagState();
  const currentPage = usePrintoutViewPageCurrent();
  const viewPagesContext = useViewPagesContext();
  const printoutView = viewPagesContext.printoutView;

  const isGuestUser = useIsGuestUser();

  const pageSize = PageTool.getSizeWithUnits(format, orientation);
  const marginUnit = UnitsSymbol[margins.units];
  const watermarkEnabled = settings.demo.pdf.watermark;

  const Colors = [
    'rgba(255, 0, 0, 0.3)',
    'rgba(0, 255, 0, 0.3)',
    'rgba(255, 0, 255, 0.3)',
    'rgba(255, 255, 0, 0.3)',
  ];

  const backgroundColor = (
    environment.debug && false ?
    Colors[pageIdx % Colors.length] :
    undefined
  );
  
  const enabled = (
       currentPage.pageIdx === pageIdx
    && currentPage.view === printoutView
  );

  if ( ! enabled ) {
    return null;
  }

  return (
    <>
      <PageIdxControlComponent />
      <Page
        $background={backgroundColor}

        $width={pageSize[0]}
        $height={pageSize[1]}

        $paddingLeft={`${margins.left}${marginUnit}`}
        $paddingRight={`${margins.right}${marginUnit}`}
        $paddingTop={`${margins.top}${marginUnit}`}
        $paddingBottom={`${margins.bottom}${marginUnit}`}
      >

        <PageBody>
          { children }
        </PageBody>
      </Page>

      {
        isGuestUser() &&
        featureFlag.demoOverviewVisible &&
        <DemoOverviewComponent 
          width={pageSize[0]}
          height={pageSize[1]}
        />
      }

    </>
  );
}
