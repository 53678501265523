import styled from "styled-components";
import Button from "./button";


const ButtonText = styled(Button)`
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;  
  padding: ${props => props.theme.buttonText.padding};

  overflow: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default ButtonText;