import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { MainWrapper } from './styles';
import { Text } from './styles';


interface Props {
}


export const OwnerPanelComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();

  return (
    <MainWrapper>
      <Text>
        { t('repository owner') }
      </Text>
    </MainWrapper>
  );
}

