import { useEffect }  from 'react';
import { query }      from 'app/arch/backend';
import { useQueryV2 } from 'app/arch/backend/use-query-v2';


type OnDoneFn = (userData: {
  featureFlag: any,
}) => void;


export interface Props {
  onDone: OnDoneFn;
  onError: (error: string) => void;
}


const useFeatureFlagFetch = (props: Props) => {
  const {
    onDone,
    onError,
  } = props;

  const { 
    data,
    refetch,
    error,
  } = useQueryV2({
    query: query.getFeatureFlag(),
  });

  useEffect(() => {
    if ( ! data ) {
      return;
    }

    const featureFlag = data.featureFlag;

    onDone({
      featureFlag
    });

  }, [data]);

  useEffect(() => {
    if ( ! error) {
      return;
    }

    onError(error.message)
  }, [error]);

  return refetch;
}
  

export default useFeatureFlagFetch;