import React from 'react';
import { useRecoilValue } from 'recoil';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import useBaseWatcher from '../../hooks/use-base-watcher';


/**
 * Sections Rows
 */

interface Props {
}


export const SectionsRowsWatcherComponent: React.FC<Props> = (props: Props) => {
  const sectionsAddrs = useRecoilValue(UIState_Content.sectionsAddrs);
  
  const SectionRowsWatchers = sectionsAddrs.map((sectionAddr) =>(
    <SectionRowsWatcherComponent 
      key={sectionAddr.sectionId}
      sectionAddr={sectionAddr}
    />
  ));

  return SectionRowsWatchers;
}
  

/**
 * Section Rows
 */

interface SectionRowsWatcherProps {
  sectionAddr: ContentTypes.SectionAddr
}

const SectionRowsWatcherComponent: React.FC<SectionRowsWatcherProps> = (props: SectionRowsWatcherProps) => {
  const {
    sectionAddr
  } = props;

  useSectionRowsAddrsdWatcher(sectionAddr);

  return null;
}
  

/**
 * Section Rows Hook
 */

const useSectionRowsAddrsdWatcher = (
  sectionAddr: ContentTypes.SectionAddr
) => {
  const rowsAddrs = useRecoilValue(UIState_Content.rowsAddrs(sectionAddr));
  useBaseWatcher([rowsAddrs]);
}
  