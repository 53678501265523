import styled from "styled-components";
import Button from "lego/styles/button";


export const MainWrapper = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  min-width: 24px;
  height: 100%;

  border-width:  0;
  border-style:  none;
  border-radius: 0px;

  border-left-width: ${props => props.theme.button.border.width};
  border-left-color: ${props => props.theme.button.border.color.primary};
  border-left-style: solid;

  &:active:not(:disabled) {
    border-color: ${props => props.theme.button.border.color.primary};
  }
`;
