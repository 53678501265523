import { useEffect } from 'react';

import { query } from 'app/arch/backend';
import { useQueryV2 } from 'app/arch/backend/use-query-v2';


const useUserLoggedFetch = (props: {
  onDone: (isUserLogged: boolean) => void,
  onError: (error: string) => void,
}) => {
  const {
    onDone,
    onError
  } = props;

  const { 
    error: userLoginError, 
    data: userLoginData
  } = useQueryV2({
    query: query.getUserLogin(), 
  });
  
  useEffect(() => {
    if ( ! userLoginData ) {
      return;
    }

    const isUserLoggedIn = userLoginData.userLogin;
    onDone(isUserLoggedIn);
  }, [userLoginData]);

  useEffect(() => {
    if ( ! userLoginError ) {
      return;
    }

    console.error(userLoginError);
    onError(userLoginError.message);
  }, [userLoginError]);
}

export default useUserLoggedFetch;
