import OAuth from "./oauth";


class Google {
  private _oauth: OAuth;

  constructor() {
    this._oauth = new OAuth();
  }

  get oauth() { return this._oauth; }
}

export default Google;