export enum ViewType {
  CHANGELOG = "view-changelog",
  CONTENT   = "view-content",
  PRINTOUT  = 'view-printout',
  SANDBOX   = 'view-sandbox',
};


export enum ToolbarItems {
  IMAGE   = 'image',
  MARKER  = 'marker',
};


export enum ToolbarProperties {
  PAGE     = 'page',
  HEADER   = 'header',
  TABLE    = 'table',
  COLUMN   = 'column',
  COLUMNS  = 'columns',
};

