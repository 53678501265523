import { atom } from 'recoil';
import { selector } from 'recoil';
import { ControlsDraggersState as State } from 'app/arch/app/controls';


export const state = atom<State.State>({
  key: "controls_draggers",
  default: State.createInitialState()
});


export const enabled = selector({
  key: `controls_draggers_enabled`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getEnabled(stateObj);
  }
});
