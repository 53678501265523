import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import * as google from 'app/configs/google';
import useGoogleOAuth from '../../hooks/use-google-oauth';
import useGoogleStateSetters from '../../hooks/use-google-state-setters';


interface Props {
  children: React.ReactNode;
}


export const GoogleOAuthProviderComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
  } = props;

  const googleOAuth = useGoogleOAuth();

  const {
    setGoogleOAuth
  } = useGoogleStateSetters();

  
  const handleSuccess = () => {
    googleOAuth.updateOAuth({ready: true});
    setGoogleOAuth();
  }

  const handleError = () => {
    googleOAuth.updateOAuth({error: true});
    setGoogleOAuth();
  }
  
  return (
    <GoogleOAuthProvider 
      clientId={google.oauth.clientId}
      onScriptLoadSuccess={handleSuccess}
      onScriptLoadError={handleError}
    >
      { children }
    </GoogleOAuthProvider>
  );
}

