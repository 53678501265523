import React  from 'react';

import { useApp } from 'app/ui/hooks/app/use-app';
import useAppStateSetters from 'app/ui-v2/app/hooks/use-app-state-setters';
import useUserLoggedFetch from 'app/ui-v2/app/hooks/use-user-logged-fetch';
import { useAuth } from 'services/auth';

import LoaderBaseComponent from '../base/loader-base';
import { LoaderCore }      from '../base/loader-base/types';
import { LoaderCoreProps } from '../base/loader-base/types';

import * as Tools from '../tools';


interface Props {
  children: React.ReactNode;
}


export const LoaderUserLoggedComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  return (
    <LoaderBaseComponent
      LoaderCoreComponent={LoaderCoreComponent}
      debugTitle={'user logged'}
    >
      { children }
    </LoaderBaseComponent>
  )
}


const LoaderCoreComponent: LoaderCore = (props: LoaderCoreProps) => {
  const {
    onDone
  } = props;

  const auth = useAuth();
  const app  = useApp();

  const {
    setApp
  } = useAppStateSetters();

  const updateAuth = (logged: boolean) => {
    const logger = Tools.getLogger();
    logger.debug(`Auth updating, auth: ${logged}`);

    if ( ! logged ) {
      auth.reset();
    }
    
    app.appState.updateState({isLogged: logged});
    setApp();

    onDone();
  }

  const handleDone = (isUserLogged: boolean) => {
    updateAuth(isUserLogged);
  }

  const handleError = (error: string) => {
    console.error(`Fetch user logged error: ${error}`);
    updateAuth(false);
  }

  useUserLoggedFetch({
    onDone: handleDone,
    onError: handleError
  });

  return null;
}
