import React from 'react';
import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useEditor } from 'app/ui-v2/editor-instruction/hooks/use-editor';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
  disabled?: boolean;
}


export const RegistrationPluginComponent: React.FC<Props> = (props: Props) => {
  const { 
    cellAddr,
    disabled,
  } = props;

  const editor = useEditor();
  const [lexical] = useLexicalComposerContext();

  useEffect(() => {
    if (disabled) {
      return;
    }

    const registry = editor.lexicalRegistrChangelog;
    registry.addLexical(cellAddr, lexical);

    return () => {
      registry.removeLexical(cellAddr);
    };
    
  }, [lexical]);

  return null;
}
  
