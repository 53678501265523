import React from 'react';
import * as Types from '../../types';
import HLS_NavbarPanelSectionComponent from '../../hls/navbar-panel-section';
import NavbarPanelSectionItemComponent from '../navbar-panel-section-item';


interface Props {
  config: Types.Section;
  last: boolean;
}


export const NavbarPanelSectionComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
    last,
  } = props;


  return (
    <HLS_NavbarPanelSectionComponent
      config={config}
      last={last}
      NavbarPanelSectionItem={NavbarPanelSectionItemComponent}
    />
  );
}

