import { useRecoilValue } from "recoil";
import { UIScreenSize } from "../states";


const useScreenSizeVariantWatch = () => {
  const sizeVariant = useRecoilValue(UIScreenSize.sizeVariant);
  return sizeVariant;
}


export default useScreenSizeVariantWatch;