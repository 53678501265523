import React from 'react';

import { ViewTypes } from 'app/arch/home-page-guest/states/view';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useViewParamsRead   from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-params-read';
import ViewTitledComponent from 'app/ui-v2/home-page/hls/view-titled';

import AccountCreateComponent from './account-create';
import GoogleSignupComponent  from './google-signup';
import { Content } from './styles';


interface Props {
}


export const ViewUserSignupComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const viewParams = useViewParamsRead() as ViewTypes.ViewParamsUserSignup;
  
  const email = (
    viewParams !== null ?
    viewParams.email :
    null
  );


  return (
    <ViewTitledComponent 
      title={t('create account')} 
      Content={Content}
    >
      <AccountCreateComponent email={email} />
      <GoogleSignupComponent />
    </ViewTitledComponent>
  );
}
