import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { UIState_HeaderRowsSession } from 'app/ui/states/editor-instruction';

import { MainWrapper } from './styles';
import { Border }      from './styles';


interface Props {
  pageCellAddr: HeaderRowsTypes.PageCellAddr;
  children: React.ReactNode;
}


export const CellSelectedBorderComponent: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr,
    children,
  } = props;

  const selected = useRecoilValue(UIState_HeaderRowsSession.isPageCellSelected(pageCellAddr));


  return (
    <MainWrapper>
      <Border selected={selected} />
      { children }
    </MainWrapper>
  );
}
  
