import produce from 'immer';

import * as State from './state';
import * as Types from './types';


class FeatureFlag {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */

  setDemoOverviewVisilbe(showDemoOverview: boolean) {
    this._state = produce(this._state, draft => {
      draft.demoOverviewVisible = showDemoOverview;
    });
  }

  loadFlags(flagsSerial: string) {
    // console.log("Load feature flags");
    this._state = produce(this._state, draft => {
      const flags = JSON.parse(flagsSerial);
      if ( flags.demoOverviewVisible !== undefined) {
        draft.demoOverviewVisible = flags.demoOverviewVisible;
      }
    });
  }
 

  /**
   * Getters
   */

  getDemoOverviewVisilbe(): boolean { 
    return State.getDemoOverviewVisilbe(this._state);
  }
}

export default FeatureFlag;