import styled from "styled-components";

const Clip = styled.div`
  overflow: clip;
  border-style:  none;
  outline-style: none;
  /* background: rgba(255,0,0,0.3); */

`;


export default Clip;
