import React from 'react';
import { Icon } from "./styles";


interface Props {
}


export const IconInfoComponent: React.FC<Props> = (props: Props) => {
  return <Icon />;
}

