export enum IconType {
  QUESTION = 'question',
  INFO     = 'info',
  ERROR    = 'error',
  WARNING  = 'warning',
}

export interface Button {
  text: string;
  onClick?: () => void;
  autoFocus?: boolean;
  dataTest?: string,
}

export interface ShowDialogProps {
  title: string;
  message?: string;
  messageComponent?: any;
  icon?: IconType;
  buttons: Button[];
}

export interface HookConfigProps {
  setTitle:   (title: string) => void;
  setMessage: (msg: string) => void;
  setMessageComponent: (msgComponent: any) => void;
  setIcon: (icon: IconType | null) => void;
  setButtons: (buttons: Button[]) => void;

  showDialog: () => void;
  hideDialog: () => void;
}

