import { useEffect } from "react";

import { useMutation } from "app/arch/backend/use-mutation";
import { mutation }    from "app/arch/backend";
import { useDocState }        from "app/ui/contexts/document";
import { MsgDuration }        from "app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box";
import { useDialogYesNo }     from "app/ui/components/editor-instruction/dialogs/dialog-yes-no/use-dialog-yes-no";
import { useTranslations }    from "app/ui/hooks/app/use-translation";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { LogoImage } from "../types";


const useLogoDelete = () => {
  const document = useDocState();
  const t = useTranslations();

  const { show: showDialogYesNo } = useDialogYesNo();

  const {
    setHeaderMeta
  } = useEditorStatesSetters();

  //-------------------------
  // Delete logo image
  //
  const { 
    mutation: deleteInstructionLogoMutation,
    data: deleteInstructionLogoData,
  } = useMutation(
    mutation.deleteInstructionLogo,
    {
      onStart: { msg:  t("logo deleting") },
      onEnd: 
      {
        msg:  t("logo deleted"),
        duration: MsgDuration.MIDDLE
      }
    }
  );

  useEffect(() => {
    if ( ! deleteInstructionLogoData ) {
      return;
    }

    const data = deleteInstructionLogoData.instructionLogoDelete;
    if (! data.ok) {
      console.warn(`Logo image has not been delete`);
      return;
    }

    //-------------
    // State update
    document.headerMeta.updateLogoImage(null);
    setHeaderMeta();
    document.saveUndo();
  
  }, [deleteInstructionLogoData]);
  
  const handleDelete = (logoImage: LogoImage) => {
    const title   = t("logo delete title");
    const message = t("logo delete message");
    const noAutoFocus = true;
    
    const onNoHandler  = () => {};
    const onYesHandler = () => {
      deleteInstructionLogoMutation({ 
        variables: { logoId: logoImage!.bid } 
      });
    }

    showDialogYesNo({title, message, noAutoFocus, onYesHandler, onNoHandler });
  }


  return handleDelete;
}


export default useLogoDelete;