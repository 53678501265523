import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';

import { EditorImageSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/editor-image-session';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';


//----------------
//
// State
//

export const state = atom<State.State>({
  key: "editorImageSession",
  default: State.createInitialState()
});


//--------------------
//
// Selectors
//

/**
 * 
 * Window
 * 
 */

export const windowVisible = selector({
  key: `editorImageSession_windowVisible`,
  get: ({ get }) => {
    const stateObj = get(state);
    const window = State.getWindow(stateObj);
    return window.visible;
  }
});





/**
 * 
 * Widgets
 * 
 */

export const isWidgetSelected = selectorFamily({
  key: `editorImageSession_isWidgetSelected`,
  get: (widgetAddr: ContentTypes.WidgetAddr) => ({ get }) => {
    const stateObj = get(state);
    const isSelected = State.isWidgetSelected(stateObj, widgetAddr);
    return isSelected;
  }
});

export const widgetSelected = selector({
  key: `editorImageSession_widgetSelected`,
  get: ({ get }) => {
    const stateObj = get(state);
    const widgetSelected = State.getWidgetSelected(stateObj);
    return widgetSelected;
  }
});

export const widgetPartSelected = selectorFamily({
  key: `editorImageSession_widgetPartSelected`,
  get: (widgetAddr: ContentTypes.WidgetAddr) => ({ get }) => {
    const stateObj = get(state);
    const widgetPartSelected = State.getWidgetPartSelected(stateObj, widgetAddr);
    
    return widgetPartSelected;
  }
});

export const widgetEdited = selector({
  key: `editorImageSession_widgetEdited`,
  get: ({ get }) => {
    const stateObj = get(state);
    const widgetEdited = State.getWidgetEdited(stateObj);
    return widgetEdited;
  }
});

export const isWidgetEdited = selectorFamily({
  key: `editorImageSession_isWidgetEdited`,
  get: (widgetAddr: ContentTypes.WidgetAddr) => ({ get }) => {
    const stateObj = get(state);
    const isEdited = State.isWidgetEdited(stateObj, widgetAddr);
    return isEdited;
  }
});

export const widgetResizingSize = selector({
  key: `editorImageSession_widgetResizingSize`,
  get: ({ get }) => {
    const stateObj = get(state);
    const size = State.getWidgetResizingSize(stateObj);
    return size;
  }
});


/**
 * 
 * Smart lines
 * 
 */

export const smartLinesWidgetsLines = selector({
  key: `editorImageSession_smartLinesWidgetsLines`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getSmartLinesWidgetsLines(stateObj);
  }
});

export const smartLinesWidgetsStickyLines = selector({
  key: `editorImageSession_smartLinesWidgetsStickyLines`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getSmartLinesWidgetsStickyLines(stateObj);
  }
});

export const smartLinesWidgetsSrcLines = selector({
  key: `editorImageSession_smartLinesWidgetsSrcLines`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getSmartLinesWidgetsSrcLines(stateObj);
  }
});









export const scale = selector({
  key: 'editorImageSession_scale',
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.scale;
  },
});


export const imageEdited = selector({
  key: 'eeditorImageSession_imageEdited',
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getImageEdited(stateObj);
  },
});

export const viewDefinerAutoFit = selector({
  key: 'editorImageSession_viewDefinerAutoFit',
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.viewDefiner.autoFit;
  },
});

export const viewDefinerOverlayerShowType = selector({
  key: 'editorImageSession_viewDefinerOverlayerShowType',
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.viewDefiner.overlayer.showType;
  },
});

export const viewDefinerOverlayerResizerLocked = selector({
  key: 'editorImageSession_viewDefinerOverlayerResizerLocked',
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.viewDefiner.overlayer.resizerLocked;
  },
});

export const clipboardWidgetsProps = selector({
  key: `editorImageSession_clipboardWidgets`,
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.clipboard.widgetsProps;
  }
});

export const clipboardWidgetStyle = selector({
  key: `editorImageSession_clipboardWidgetStyle`,
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.clipboard.widgetStyle;
  }
});

export const sideToolbarPanelType = selector({
  key: `editorImageSession_sideToolbar_panelType`,
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.sideToolbar.panel.type;;
  }
});


export const sideToolbarPanelVisible = selector({
  key: `editorImageSession_sideToolbar_panelVisible`,
  get: ({ get }) => {
    const stateObj = get(state);
    return stateObj.sideToolbar.panel.visible;
  }
});

export const sideToolbarPanel_stylesLibraryType = selector({
  key: `editorImageSession_sideToolbarPanel_stylesLibraryType`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getSideToolbarPanel_stylesLibraryType(stateObj);
  }
});

export const multiSelectionSelectedWidgets = selector({
  key: `editorImageSession_multiSelectionSelectedWidgets`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getMultiSelectionSelectedWidgets(stateObj);
  }
});

