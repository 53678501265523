import { ComponentType } from 'react';
import { 
  ColorBar, 
  StyledBtnColorBase, 
} from './styles';


export interface Props {
  id?: string;
  iconMuiComponent: any;
  selectedColor: string;
  onClick: (event?: any) => void;
  isActive?: boolean;
  component?: ComponentType<any>;
}


export const BtnColorComponent = (props: Props) => {
  const {
    id,
    iconMuiComponent: IconComponent, 
    onClick, 
    selectedColor,
    isActive: isAcitve_,
    component: component_,

  } = props;


  const idProps = (
    id !== undefined ?
    {id: id} :
    {}
  );

  const isActive = isAcitve_ !== undefined ? isAcitve_ : false;;
  const iconSize = 'medium';
  const StyledBtnColor = props.component !== undefined ? props.component : StyledBtnColorBase;

  return (
    <StyledBtnColor 
      className={`item ` + (isActive ? 'active' : '')}
      onClick={onClick}
    >
      <ColorBar
        {...idProps}
        style={{
          backgroundColor: selectedColor
        }}
      />

      <IconComponent 
        fontSize={iconSize}
      /> 

    </StyledBtnColor>    
  )
};
