import React from 'react';
import Context from './context';


interface Props {
  pageIdx: number;
  children: React.ReactNode;
}


export const PageContextComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
    pageIdx,
  } = props;

  return (
    <Context.Provider value={{pageIdx}} >
      { children }
    </Context.Provider>
  );
}

