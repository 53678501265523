import React from 'react';

import { PanelControlsItemSectionConfig as Config} from '../../../config';
import PanelSectionComponentBase       from '../../base/panel-section';
import PanelSectionItemComponent       from '../panel-section-item';
import PanelSectionSubsectionComponent from '../panel-section-subsection';


interface Props {
  config: Config;
}


export const PanelSectionComponent: React.FC<Props> = (props: Props) => {
  const { 
    config 
  } = props;

  return (
    <PanelSectionComponentBase
      config={config}
      SectionItem={PanelSectionItemComponent}
      SectionSubsection={PanelSectionSubsectionComponent}
    />
  );
}
  
