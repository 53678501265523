import React from 'react';
import usei18nInit from 'app/ui-v2/app/hooks/use-i18n-init';
import LoaderBaseComponent from '../base/loader-base';
import { LoaderCore }      from '../base/loader-base/types';
import { LoaderCoreProps } from '../base/loader-base/types';


interface Props {
  children: React.ReactNode;
}


export const LoaderTranslationComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;
  
  return (
    <LoaderBaseComponent
      debugTitle={'translation'}
      LoaderCoreComponent={LoaderCoreComponent}
    >
      { children }
    </LoaderBaseComponent>
  )
}


/**
 * Translation Core Loader
 */
const LoaderCoreComponent: LoaderCore = (props: LoaderCoreProps) => {
  const {
    onDone
  } = props;

  usei18nInit({onDone});
  return null;
}
