import React, { useEffect } from 'react';

import { ViewTypes } from 'app/arch/home-page-guest/states/view';
import useGuestGoHome from 'app/ui-v2/home-page/home-page-guest/hooks/use-guest-go-home';
import useViewParamsRead from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-params-read';

import RequestNewPasswordComponent from './request-new-password';


interface Props {
}


export const ViewPasswordResetFormComponent: React.FC<Props> = (props: Props) => {
  const goHome = useGuestGoHome();
  const viewParams = useViewParamsRead()  as ViewTypes.ViewParamsPasswordReset;
  
  if ( viewParams === null) {
    return null;
  }

  useEffect(() => {
    if (viewParams === null) {
      goHome();
    }
  }, [viewParams]);

  const {
    email,
    token
  } = viewParams;

  if ( ! email || ! token ) {
    const msg = `Invalid email: ${email} or token: ${token}`;
    throw new Error(msg);
  }

  return (
    <RequestNewPasswordComponent 
      email={email}
      token={token}
    />
  );
}
