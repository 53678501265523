import React from 'react';
import HLS_MarkerViewIconComponent from 'app/ui-v2/editor-markers/hls/marker-views/marker-view-icon';
import { MarkerViewProps } from '../types';


interface Props extends MarkerViewProps {
}


export const MarkerViewIconComponent: React.FC<Props> = (props: Props) => {
  const {
    markerProps
  } = props;

  return (
    <HLS_MarkerViewIconComponent markerProps={markerProps} />
  );
}

