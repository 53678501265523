import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";


const useColumnSelectedSet = () => {
  const document = useDocState();
  const {
    setReleaselogsSession
  } = useEditorStatesSetters();


  const selectColumn = (columnAddr: Types.ColumnAddr | null) => {
    document.releaselogsSession.setColumnSelected(columnAddr);
    setReleaselogsSession();
  }

  return selectColumn;
}

export default useColumnSelectedSet;