import React from 'react';
import * as Types from '../../types';
import HLS_MenubarComponent from '../../hls/menubar';
import MenubarItemComponent from '../menubar-item';


export interface Props extends Types.MenubarProps {
}


export const MenubarComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;


  return (
    <HLS_MenubarComponent 
      {...props} 
      MenubarItem={MenubarItemComponent}
    />
  );
}

