import { ViewTypes } from "app/arch/home-page-user/states/view"
import useState from "./use-state"


const useViewParamsSet = () => {
  const homePageUser = useState();
  
  const setViewParams = (params: ViewTypes.ViewParams) => {
    homePageUser.view.update({params});
  }

  return setViewParams;
}


export default useViewParamsSet;