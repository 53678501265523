import styled from "styled-components";
import Button from "./button";


export const ButtonIcon = styled(Button)`
  width: 30px;
  height: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items:     center;
  background-color: transparent;
`;

export default ButtonIcon;