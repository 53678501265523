import React from 'react';
import ImagesPanelComponent from './images-panel';
import { MainWrapper } from './styles';


interface Props {
}


export const ControlImagesComponent: React.FC<Props> = (props: Props) => {
  return (
    <MainWrapper>
      <ImagesPanelComponent />
    </MainWrapper>
  );
}
  
