import React from 'react';

import HLS_FlexEditLogoComponent from 'app/ui-v2/home-page/hls/flex-edit-logo';

import TopMenuComponent    from './top-menu';
import SignOutBtnComponent from './sign-out-btn';

import { TopBar, TopMenuWrapperTest }           from './styles';
import { FlexLogoWrapper }  from './styles';
import { TopMenuWrapper }   from './styles';
import { LogoutBtnWrapper } from './styles';
import TopMenuVerticalComponent from './top-menu-vertical';


interface Props {
}


export const TopbarComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <TopBar>

      <FlexLogoWrapper>
        <HLS_FlexEditLogoComponent/>
      </FlexLogoWrapper>

      <TopMenuWrapper>
        <TopMenuComponent />
      </TopMenuWrapper>

    {/* <TopMenuWrapperTest>
      <TopMenuVerticalComponent />
    </TopMenuWrapperTest> */}
      <LogoutBtnWrapper>
        <SignOutBtnComponent />
      </LogoutBtnWrapper>
    </TopBar>
  );
}
  
