import styled from "styled-components";
import MenuItemBase from 'lego/styles/menu_item';


export const MainWrapper = styled.div`

`;

export const MenuItem = styled(MenuItemBase)`
  padding:        ${props => props.theme.defs.padding.small};
  padding-top:    ${props => props.theme.defs.padding.normal};
  padding-bottom: ${props => props.theme.defs.padding.normal};

  display: flex;
  justify-content: space-evenly;
`;