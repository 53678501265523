import React from 'react';

import { PanelControlsItemSectionControlConfig as Config } from '../../../../config';

import { MainWrapper }  from './styles';
import { Title }        from  './styles';
import { TitleWrapper } from  './styles';
import { ItemWrapper }  from './styles';


interface Props {
  config: Config;
  sectionDisabled: boolean;
  itemDisabled: boolean;
  ItemComponent: React.ElementType;
  itemProps: any;
}


export const ItemComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
    sectionDisabled,
    itemDisabled,
    ItemComponent,
    itemProps
  } = props;

  const title  = (
    config.title !== undefined && config.title.length !== 0 ?
    config.title :
    <>&nbsp;</>
  );

  return (
    <MainWrapper 
      $disabled={itemDisabled && ! sectionDisabled }
    >
      <TitleWrapper>
        <Title>
          { title }
        </Title>
      </TitleWrapper>

      <ItemWrapper>
        <ItemComponent 
          config={config as any} 
          {...itemProps}
        />
      </ItemWrapper>
    </MainWrapper>
  )
}
