import { ThemeConstsProps } from "../types";


export const themeConsts_padding = (props: ThemeConstsProps) => {
  const padding = {
    none:  "0px", 
    xsmall:  "2px", 
    small:  "3px", 
    medium: "4px",
    normal: "6px",
    large:  "10px",
    xlarge:  "15px",
    xxlarge:  "22px",
  };

  return padding;
}
