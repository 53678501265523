import React from 'react';

import { ReleaselogsTools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';

import { useDocState } from 'app/ui/contexts/document';
import useSelectedReset   from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/use-selected-reset';
import useRowSelectedSet  from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/row/use-row-selected-set';
import useCellSelectedSet from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/cell/use-cell-selected-set';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { MainWrapper } from './styles';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
  children: React.ReactNode;
}


export const CellIndexSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr,
    children,
  } = props
  
  const document = useDocState();
  const resetSelected   = useSelectedReset();
  const setRowSelected  = useRowSelectedSet();
  const setCellSelected = useCellSelectedSet();

  const {
    setReleaselogsSession
  } = useEditorStatesSetters();

  const handleSelectRow = (event: React.MouseEvent) => {
    event.stopPropagation();

    const selectedRow = document.releaselogsSession.getRowSelected();
    const isSelected = (
      selectedRow !== null &&
      ReleaselogsTools.compareRowAddr(selectedRow, cellAddr)
    );

    if ( isSelected ) {
      return;
    }

    // Select cell as well, in order
    // get get panel column menu active for
    // idx row.
    const skipStateUpdate = true;

    resetSelected({ skipStateUpdate });
    setCellSelected({ cellAddr, skipStateUpdate });
    setRowSelected({
      rowAddr: cellAddr,
      skipStateUpdate,
    });

    setReleaselogsSession();
  }


  return (
    <MainWrapper onPointerDown={handleSelectRow}>
      { children }
    </MainWrapper>
  );
}
