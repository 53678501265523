import produce from 'immer';

import * as Defaults from './defaults';
import * as State from './state';
import * as Tools from './tools';
import * as Types from './types';


class Toasts {
  private _state: State.State;
  private _listener: Types.Listener;

  constructor() {
    this._state = State.createInitialState();
    this._listener = null;
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }


  /**
   * Setters
   */
  addToast(props: Types.ToastPartialProps) {
    const toastAddr = Tools.createToastAddr();
    const toastProps = Defaults.getToastProps(props);

    this._state = produce(this._state, draft => {
      const toastsAddrs = State.getToastsAddrs(draft);
      const toastsProps = State.getToastsProps(draft);

      const toastKey = Tools.getToastKey(toastAddr);

      toastsAddrs.push(toastAddr);
      toastsProps[toastKey] = toastProps;
    });

    this.runListener();
    this.scheduleDelete(toastAddr, toastProps.duration);
    return toastAddr;
  }

  removeToast(toastAddr: Types.ToastAddr) {
    this._state = produce(this._state, draft => {
      const toastsAddrs = State.getToastsAddrs(draft);
      const toastsProps = State.getToastsProps(draft);

      const toastIdx = State.getToastIdx(draft, toastAddr);
      const toastKey = Tools.getToastKey(toastAddr);

      toastsAddrs.splice(toastIdx, 1);
      delete toastsProps[toastKey];
    });

    this.runListener();
  }

  addListener(listener: Types.Listener) {
    this._listener = listener;
  }

  removeListener() {
    this._listener = null;
  }

  private runListener() {
    if (this._listener === null) {
      return;
    }

    this._listener(this._state);
  }

  private scheduleDelete(
    toastAddr: Types.ToastAddr,
    duration: number,
  ) {
    setTimeout(() => {
      this.removeToast(toastAddr);
    }, duration);
  }

  /**
   * 
   * Getters
   * 
   */

  /**
   * Toasts
   */
  getToastsAddrs(): Types.ToastsAddrs { 
    return State.getToastsAddrs(this._state);
  }
  
  getToastsProps(): Types.ToastsProps { 
    return State.getToastsProps(this._state);
  }


  /**
   * Toast
   */
  getToastProps(
    toastAddr: Types.ToastAddr,
  ): Types.ToastProps { 
    return State.getToastProps(this._state, toastAddr);
  }

  getToastIdx(
    toastAddr: Types.ToastAddr,
  ): number { 
    return State.getToastIdx(this._state, toastAddr);
  }
}

export default Toasts;