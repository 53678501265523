import { useState } from "react";
import { useEffect } from "react";


const usePanelScreenOverflowCheck = (panelRef: React.RefObject<HTMLDivElement> ) => {
  const [widthOverflow, setWidthOverflow] = useState(0);
  const [done, setDone] = useState(false);

  const checkOverflow = () => {
    if (panelRef.current) {
      const rect = panelRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      const overflow = windowWidth - rect.right;
      if (overflow < 0) {
        setWidthOverflow(overflow);
      }

      setDone(true);
    }
  };

  useEffect(() => {
    checkOverflow();
  }, []);

  return {
    widthOverflow,
    done,
  };
}


export default usePanelScreenOverflowCheck;