import styled from 'styled-components';


export const Canceler = styled.div`
`;

export const FrameArrowHeadWrapper = styled.div`
  position: absolute;
`;

export const FrameArrowBodyWrapper = styled.div`
  position: absolute;
`;

