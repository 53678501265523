import * as Defaults from './defaults';
import * as Types from './types';


/**
 * State
 */

export type State = {
  enabled: boolean,
}

export const getEnabled = (
  state: State
): boolean => { 
  const enabled = state.enabled
  return enabled;
}


/**
 * Initial state
 */

export const createInitialState = (): State => {
  const initState: State = {
    enabled: true,
  }
  
  return initState;
}
