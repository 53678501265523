import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useAssetsRepo from '../../../../hook/use-assets-repo';
import ImageViewComponent from '../../../parts/image-view';

import ImageIdxComponent from './image-idx';
import ImageBorderComponent from './image-border';
import WidgetsComponent from './widgets';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
}


export const ImageComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
  } = props;

  const assetsRepo = useAssetsRepo();
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ( ! wrapperRef.current ) {
      return;
    }

    if ( ! assetsRepo ) {
      console.warn(`Assets repo not ready`);
      return;
    }

    const element = wrapperRef.current;
    assetsRepo.cellImages.addElement(imageAddr, element);

    return () => {
      assetsRepo.cellImages.removeElement(imageAddr);
    }
  }, []);


  return (
    <ImageViewComponent ref={wrapperRef}>
      <ImageIdxComponent imageAddr={imageAddr} >
        <ImageBorderComponent imageAddr={imageAddr}>
          <WidgetsComponent imageAddr={imageAddr} />
        </ImageBorderComponent>
      </ImageIdxComponent>
    </ImageViewComponent>
  );
}

