import React from 'react';
import { ContentTypes as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';

import { useDocState }        from 'app/ui/contexts/document';
import useSelectedReset       from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-selected-reset';
import useSectionSelectedSet  from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/section/use-section-selected-set';

import { MainWrapper } from './styles';


interface Props {
  sectionAddr: TypesContent.SectionAddr;
  children: React.ReactNode;
}


export const SectionNameSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    sectionAddr,
    children
  } = props;

  const document = useDocState();
  const resetAllSelected = useSelectedReset();
  const setSectionSelected = useSectionSelectedSet();

  const handleSelectSection = (event: React.MouseEvent) => {
    event.stopPropagation();

    const isSelected = document.contentSession.isSectionSelected(sectionAddr);
    if ( isSelected ) {
      return;
    }

    resetAllSelected();
    setSectionSelected({ sectionAddr });
  }


  return (
    <MainWrapper onPointerDown={handleSelectSection}>
      { children }
    </MainWrapper>
  );
}
  
