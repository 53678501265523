import React from 'react';
import MarkersCreateNewComponent from './markers-create-new';


interface Props {
}


export const ControlMarkersCreateComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <MarkersCreateNewComponent />
  );
}
  
