export enum PanelDocumentItem {
  PAGE       = 'page',
  DOC_HEADER = 'doc-header',
  TABLE      = 'table',
  COLUMN     = 'column,'
};

export type PanelDocumentState = {
  visible: boolean;
  selected: PanelDocumentItem;
}
