import React from 'react';
import * as Types from '../../types';
import HLS_MenubarItemPanelComponent from 'lego-v2/menubar/hls/menubar-item-panel';
import MenubarItemPanelItemComponent from '../menubar-item-panel-item';
import MenubarItemPanelItemSubsectionComponent from '../menubar-item-panel-item-subsection';


interface Props extends Types.MenubarItemPanelProps {
}


export const MenubarItemPanelComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;


  return (
    <HLS_MenubarItemPanelComponent 
      {...props}
      MenubarItemPanelItem={MenubarItemPanelItemComponent}
      MenubarItemPanelItemSubsection={MenubarItemPanelItemSubsectionComponent}
    />
  );
}

