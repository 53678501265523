import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { COMMAND_PRIORITY_EDITOR } from 'lexical';
import { KEY_TAB_COMMAND } from 'lexical';

import * as Types from "app/arch/editor-instruction/document/states/persistent/content/types";
import useCellTextSelect from "./use-cell-text-select-next";


interface Props {
  cellAddr: Types.CellAddr;
}


export const TabPlugin: React.FC<Props> = (props: Props) => {
  const { 
    cellAddr,
  } = props;

  const [editor] = useLexicalComposerContext();
  const selectNextCellText = useCellTextSelect();

  useEffect(() => {
    return editor.registerCommand<KeyboardEvent>(
      KEY_TAB_COMMAND,
      (event) => {
        event.preventDefault();
        selectNextCellText(cellAddr);

        const handled = true;
        return handled;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  });

  return null;
}
