import { settings } from "app/configs";
import { SmartLine } from "./smart-line";

import { SmartLineRaw, StickyLineRaw   } from "./types";
import { SmartLinesRaw  } from "./types";
import { StickyLinesRaw } from "./types";
import { OrientedSmartLinesRaw } from "./types";



export class SmartLinesAnalyzer {
  protected _srcLines: OrientedSmartLinesRaw;
  protected _dstLines: OrientedSmartLinesRaw;

  constructor(
    srcLines: OrientedSmartLinesRaw,
    dstLines: OrientedSmartLinesRaw | null
  ) {

    this._srcLines = srcLines;
    this._dstLines = dstLines || {
      vertical:   [],
      horizontal: []
    };
  }

  getStickyLines(stickyDistance: number): StickyLinesRaw {
    const stickyLinesHorizontal = this.calculateStickyLines(
      this._srcLines.horizontal,
      this._dstLines.horizontal,
      stickyDistance
    );

    const stickyLinesVertical = this.calculateStickyLines(
      this._srcLines.vertical,
      this._dstLines.vertical,
      stickyDistance
    );

    const stickyLines: StickyLinesRaw = {
      horizontal: stickyLinesHorizontal,
      vertical: stickyLinesVertical,
    };

    return stickyLines;
  }

  private calculateStickyLines(srcLines: SmartLinesRaw, dstLines: SmartLinesRaw, stickyDistance: number) {
    let hits : StickyLineRaw[] =[];

    for (let srcLine of srcLines) {
      for (let dstLine of dstLines) {
        if (this.checkIfLineAlign(srcLine, dstLine, stickyDistance) ) {
          hits.push({
            srcLine,
            dstLine,
          });
        }
      }
    }

    // Found sticky lines
    if ( hits.length ) {
      const hit0 = hits[0];
      const srcLine = hit0.srcLine;

      const srcHorizontal = SmartLine.IsHorizontal(srcLine);

      if ( srcHorizontal ) {
        const distances = hits.map(hit =>  Math.abs(hit.srcLine.y0 - hit.dstLine.y0));
        let minDistance = (Math.min(...distances));

        const minimalHits = hits.filter((hit, idx) => distances[idx] === minDistance);
        const tmp = minimalHits.map((hit, idx) => hit.dstLine.y0);

        const mySet = new Set(tmp);
        if (mySet.size > 1) {
          // console.warn('multiple align lines currently unsupported');
        }

        const targetY0 = hits[0].dstLine.y0;
        const toProcess = hits.filter((hit, idx) => hit.dstLine.y0 === targetY0 );

        const x0s = toProcess.map(hit => hit.dstLine.x0);
        const x1s = toProcess.map(hit => hit.dstLine.x1);
        const x0min = Math.min(...x0s);
        const x1max = Math.max(...x1s);


        const ret: StickyLineRaw =  {
          srcLine: hits[0].srcLine,
          dstLine: {
            x0: x0min,
            y0: hits[0].dstLine.y0,
            x1: x1max,
            y1: hits[0].dstLine.y0,
          }
        };

        return ret;
      }
      else {
        const distances = hits.map(hit =>  Math.abs(hit.srcLine.x0 - hit.dstLine.x0));
        let minDistance = (Math.min(...distances));

        const minimalHits = hits.filter((hit, idx) => distances[idx] === minDistance);
        const tmp = minimalHits.map((hit, idx) => hit.dstLine.x0);

        const mySet = new Set(tmp);
        if (mySet.size > 1) {
          // console.warn('multiple align lines currently unsupported');
        }

        const targetX0  = hit0.dstLine.x0;
        const toProcess = hits.filter((hit, idx) => hit.dstLine.x0 === targetX0 );

        const y0s = toProcess.map(hit => hit.dstLine.y0);
        const y1s = toProcess.map(hit => hit.dstLine.y1);
        const y0min = Math.min(...y0s);
        const y1max = Math.max(...y1s);


        const ret: {srcLine: SmartLineRaw, dstLine: SmartLineRaw} =  {
          srcLine: hits[0].srcLine,
          dstLine: {
            x0: hits[0].dstLine.x0,
            y0: y0min,
            x1: hits[0].dstLine.x0,
            y1: y1max,
          }
        };

        return ret;
      }
    }

    return null;
  }

  private checkIfLineAlign(srcLine: SmartLineRaw, dstLine: SmartLineRaw, stickyDistance: number) {
    const srcHorizontal = SmartLine.IsHorizontal(srcLine);
    const dstHorizontal = SmartLine.IsHorizontal(dstLine);

    // Line type do not match
    if (srcHorizontal !== dstHorizontal) {
      return false;
    }

    // Line type match
    if (srcHorizontal) {
      if ( Math.abs(srcLine.y0 - dstLine.y0) <= stickyDistance ) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      if ( Math.abs(srcLine.x0 - dstLine.x0) <= stickyDistance ) {
        return true;
      }
      else {
        return false;
      }
    }
  }
}
