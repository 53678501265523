import React from 'react';

import { TextWrapper } from './styles';
import { Icon } from './styles';
import { Text } from './styles';


interface Props {
  msg: string;
}


export const MessageComponent: React.FC<Props> = (props: Props) => {
  const {
    msg
  } = props;


  return (
    <TextWrapper>
      <Icon />
      <Text>
        { msg }
      </Text>
    </TextWrapper>
  );
}

