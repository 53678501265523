import { useRecoilValue } from 'recoil';
import { UIState_ContentView } from 'app/ui/states/editor-instruction';
import useBaseWatcher from './use-base-watcher';


const useDocHeaderItemsWatcher = () => {
  const docHeaderItems = [
    useRecoilValue(UIState_ContentView.headerMetaVisible),
    useRecoilValue(UIState_ContentView.headerInternalVisible),
    useRecoilValue(UIState_ContentView.headerFieldsVisible),
    useRecoilValue(UIState_ContentView.headerMarkersVisible),
  ];

  useBaseWatcher(docHeaderItems);
}
  
export default useDocHeaderItemsWatcher;