import React from 'react';
import HLS_NameEditComponent from '../../name-edit';
import InputStyledComponent from './input-styled';


interface Props {
  columnName: string;
  onColumnSelected: () => void;
  onColumnNameEditDone: (newName: string) => void;
  
  children: React.ReactNode;
}


export const HeaderCellNameEditComponent: React.FC<Props> = (props: Props) => {
  const {
    columnName,
    onColumnSelected,
    onColumnNameEditDone,
    children,
  } = props;

  return (
    <HLS_NameEditComponent
      name={columnName}
      onEditStart={onColumnSelected}
      onEditDone={onColumnNameEditDone}
      Input={InputStyledComponent}
    >
      { children }
    </HLS_NameEditComponent>
  );
}
