import React from 'react';
import PriceBaseComponent from '../price-base';

import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import usePriceGet from 'app/ui-v2/app/__modules/products/hooks/use-price-get';
import usePricePlan from '../../../hooks/use-price-plan';


interface Props {
}


export const PriceMonthlyComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const pricePlan = usePricePlan();
  const getPrice = usePriceGet();

  const { plan, planType } = pricePlan;

  const priceMonthly = getPrice(planType, PricePlansTypes.BillingPeriod.MONTH);
  const priceYearly = priceMonthly * 12;

  return (
    <PriceBaseComponent 
      free={planType === PricePlansTypes.PlanType.FREE}
      priceMonthly={priceMonthly}
      priceYearly={priceYearly}
      billingPeriod={ t('payment monthly') }
    />
  );
}

