type SetVisibleFn = (visible: boolean) => void;
type SetMsgFn = (msg?: string) => void;


let __setVisible: SetVisibleFn | null = null;
let __setMsg: SetMsgFn | null = null;


export const setup = (
  setVisible: SetVisibleFn | null,
  setMsg: SetMsgFn | null,
) => {
  __setVisible = setVisible;
  __setMsg = setMsg;
}


export const useProgressModal = () => {
  const showProgressModal = (text?: string) => {
    __setMsg?.(text);
    __setVisible?.(true);
  }

  const hideProgressModal = () => {
    __setVisible?.(false);
    __setMsg?.(undefined);
  }

  return { 
    showProgressModal, 
    hideProgressModal
  };
}
