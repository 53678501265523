import React from 'react';

import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';

import HeaderCellProbeComponent       from './header-cell-probe';
import HeaderCellDraggerComponent     from './header-cell-dragger';
import HeaderCellDropComponent        from './header-cell-drop';
import HeaderCellViewComponent        from './header-cell-view';
import HeaderCellContextMenuComponent from './header-cell-context-menu';
import HeaderCellSelectComponent      from './header-cell-select';
import HeaderCellNameEditComponent    from './header-cell-name-edit';
import HeaderCellCssComponent         from './header-cell-css';

import { HeaderCell } from './styles';


interface Props {
  columnAddr: ContentTypes.ColumnAddr;
}


export const HeaderCellComponent: React.FC<Props> = (props: Props) => {
  const { 
    columnAddr,
  } = props;

  const document = useDocState();
  const columnType = document.content.getColumnType(columnAddr);
  const isIdxColumn = (columnType === ContentTypes.ColumnType.INDEX);
  
  const dataTest = ContentTools.getColumnDataTest({
    columnAddr,
    docState: document,
    prefix: 'content-header-cell'
  });

  return (
    <HeaderCellProbeComponent enabled={isIdxColumn} >
      <HeaderCell 
        data-test={dataTest}
        borderLeft={isIdxColumn} 
      >
        <HeaderCellNameEditComponent columnAddr={columnAddr} >
          <HeaderCellSelectComponent columnAddr={columnAddr} >
            <HeaderCellContextMenuComponent columnAddr={columnAddr} >
              <HeaderCellDraggerComponent 
                columnAddr={columnAddr} 
                disabled={isIdxColumn}
              >
                <HeaderCellDropComponent 
                  columnAddr={columnAddr} 
                  disabled={isIdxColumn}
                >
                  <HeaderCellCssComponent columnAddr={columnAddr} >
                    <HeaderCellViewComponent columnAddr={columnAddr} />
                  </HeaderCellCssComponent>
                </HeaderCellDropComponent>
              </HeaderCellDraggerComponent>
            </HeaderCellContextMenuComponent>
          </HeaderCellSelectComponent>
        </HeaderCellNameEditComponent>
      </HeaderCell>
    </HeaderCellProbeComponent>
  );
}
