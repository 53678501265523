import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { useMutation }              from 'app/arch/backend/use-mutation';
import { mutation as mutationDefs } from 'app/arch/backend';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useApp } from 'app/ui/hooks/app/use-app';
import { UIState_Repos } from 'app/ui/states/app/repos-state';
import { useDialogYesNo } from 'app/ui/components/editor-instruction/dialogs/dialog-yes-no/use-dialog-yes-no';
import { useRepoFallbackSet } from './use-repo-fallback-set';


export interface HookProps {
  repoId: number;
  onStart?: () => void;
  onDone?: () => void;
}


export const useRepoMemberRejectInvite = () => {
  const t = useTranslations();

  const app = useApp();
  const hookPropsRef = useRef<HookProps | null>(null);

  const {show: showDialog} = useDialogYesNo();

  const setRepoState = useSetRecoilState(UIState_Repos.state);
  const setFallbackRepo  = useRepoFallbackSet();

  // Mutation
  //
  const { 
    mutation,
    data: rejectRepoMemberInviteData,
    error,
  } = useMutation(mutationDefs.rejectRepoMemberInvite);

  useEffect(() => {
    if (! rejectRepoMemberInviteData) {
      return;
    }

    const data = rejectRepoMemberInviteData.repoMemberRejectInvite;
    if (! data.ok) {
      console.warn("Can't reject repo member invitation");
      return;
    }

    const { repoId, onDone } = hookPropsRef.current !;
    app.repos.removeRepoPack(repoId);
    setRepoState(app.repos.raw);
    setFallbackRepo();
    onDone?.();

    hookPropsRef.current = null;
  }, [rejectRepoMemberInviteData]);

  const hook = (props: HookProps) => {
    hookPropsRef.current = props;

    const title    = t("repository invitation, reject msg title");
    const message_ = t("repository invitation, reject msg txt");
    const repoPack = app.repos.getRepoPack(props.repoId);
    const repoName = repoPack ? repoPack.repo.name : t("repository noname");
    const message  = message_.replace("<<REPO>>", repoName);

    const onNoHandler = () => {
    };

    const onYesHandler = () => {
      props.onStart?.();
      mutation({
        variables: { repoId: props.repoId }
      });
    };

    const noAutoFocus = true;

    showDialog({
      title,
      message,
      onNoHandler,
      onYesHandler,
      noAutoFocus,
    })

  }

  return hook;
}
