import styled from "styled-components";
import DraggedBase from "lego/styles/dragged";


export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  width: 140px;
  height: 24px;
  background: ${props => props.theme.defs.colors.background.secondary};
`;

export const Dragged = styled(DraggedBase)`
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: ${props => props.theme.defs.padding.small};

`;

export const Text = styled.div`
  font-size: ${props => props.theme.defs.font.size.normal};
  
  min-width: 30px;
  max-width: 136px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${props => props.theme.button.color.primary};
`;