import React from 'react';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useContextElement      from 'app/ui-v2/editor-instruction/hooks/use-context-element';
import { useDocState }        from 'app/ui/contexts/document';

import { MainWrapper } from './styles';


interface Props {
  children: React.ReactNode;
}


export const SectionAdderSelectComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const document = useDocState();

  const {
    showElement,
  }  = useContextElement();

  const {
    setContent
  } = useEditorStatesSetters();

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    document.content.addSection();
    document.saveUndo();
    setContent();

    // const onDone = (newSectionAddr: Types.SectionAddr) => {
    //   // setSectionSelected({sectionAddr: newSectionAddr});
    // }

    // showElement({
    //   Element: SectionCreatePanelComponent, 
    //   ElementProps: { onDone },
    //   event
    // });
  }

  return (
    <MainWrapper onClick={handleClick}>
      { children }
    </MainWrapper>
  );
}
