import { useDocState } from "app/ui/contexts/document";


const useReleaselogsState = () => {
  const docState = useDocState();
  const releaselogs = docState.releaselogs;
  return releaselogs;
}


export default useReleaselogsState;