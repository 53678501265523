import styled from "styled-components";
import ContentItemBorder from "app/ui-v2/editor-instruction/views/shared/styles/content-item-border";


interface Props {
  $width: number;
}

export const MainWrapper = styled(ContentItemBorder).attrs<Props>(props => ({
  style: {
    width: `${props.$width}px`
  }
}))<Props>`
  border-bottom-style: solid;
`;
