import React from 'react';
import * as Types from '../../types';
import Icon from 'app/ui/icons/icons';


interface Props {
  sortOrder: Types.SortOrder;
}


export const SortMarkComponent: React.FC<Props> = (props: Props) => {
  const {
    sortOrder,
  } = props;

  const SortMark = (
    sortOrder === Types.SortOrder.ASC ?
    Icon.Sort.Asc :
    Icon.Sort.Desc
  );
  
  return <SortMark />;
}

