import styled from "styled-components";
import ControlTitleBase from "../../styles/control-title";


export const ControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xsmall};
`;

export const ControlTitle = styled(ControlTitleBase)`
`;