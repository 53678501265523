import React        from 'react';
import { useState } from 'react';

import InputComponent from 'lego/components/input';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { useDocState }     from 'app/ui/contexts/document';
import useContextElement      from 'app/ui-v2/editor-instruction/hooks/use-context-element';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import TitledElementComponent from 'app/ui-v2/editor-instruction/components/titled-element';
import ElementCreatePanelComponent from 'app/ui-v2/editor-instruction/views/shared/components/element-create-panel';

import { SectionAddPlacement } from './types';


export interface Props {
  srcSectionAddr?: Types.SectionAddr,
  sectionAddPlacement?: SectionAddPlacement,
  onDone: (newSectionAddr: Types.SectionAddr) => void,
}


export const SectionCreatePanelComponent: React.FC<Props> = (props: Props) => {
  const {
    srcSectionAddr,
    onDone
  } = props;

  const t = useTranslations();
  const document = useDocState();

  const sectionAddPlacement = props.sectionAddPlacement || SectionAddPlacement.AT_CONTENT_END;
  const [sectionName, setSectionName] = useState("");

  const {
    setContent,
  } = useEditorStatesSetters();

  const {
    hideElement
  } = useContextElement();

  const createSection = () => {
    let newSectionAddr_: Types.SectionAddr | null = null;
    const sectionProps = { name: sectionName };

    const srcSectionNeeded = [
      SectionAddPlacement.ABOVE_SRC_SECTION,
      SectionAddPlacement.BELOW_SRC_SECTION,
    ];

    if (
      srcSectionNeeded.includes(sectionAddPlacement) && 
      srcSectionAddr === undefined
    ) {
      const msg = (
        `Src section addr not specified ` +
        `while adding at: ${sectionAddPlacement}. ` +
        `This is implementation error.`
      );
      throw new Error(msg);
    }

    switch (sectionAddPlacement) {
      case SectionAddPlacement.ABOVE_SRC_SECTION : {
        newSectionAddr_ = document.content.addSectionAbove(srcSectionAddr !, sectionProps);
        break;
      }
      case SectionAddPlacement.BELOW_SRC_SECTION : {
        newSectionAddr_ = document.content.addSectionBelow(srcSectionAddr !, sectionProps);
        break;
      }
      case SectionAddPlacement.AT_CONTENT_END : {
        newSectionAddr_ = document.content.addSection(sectionProps);
        break;
      }
      default:  {
        const msg = `Invalid section add placement: ${sectionAddPlacement}`;
        throw new Error(msg);
      }            
    }
   
    const newSectionAddr = newSectionAddr_ !;

    document.content.addRow(newSectionAddr);
    document.content.addRow(newSectionAddr);

    setContent();
    document.saveUndo();

    onDone?.(newSectionAddr);
    hideElement();
  }

  const handleInput = (value: string) => {
    setSectionName(value);
  }
  
  const handleCreate = () => {
    createSection();
  }
  const handleCancel = () => {
    hideElement();
  }

  return (
    <ElementCreatePanelComponent
      onCreate={handleCreate}
      onCancel={handleCancel}
    >
      <TitledElementComponent
        title={t("section name")}
      >
        <InputComponent 
          value={sectionName}
          focusOnLoad={true}
          onValueChange={handleInput}
        />
      </TitledElementComponent>
    </ElementCreatePanelComponent>
    
  );
}
  
