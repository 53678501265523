import { keyframes } from "styled-components";
import Panel from 'lego/styles/panel';
import TextBase from 'lego/styles/text';
import styled from 'styled-components';


const __PULSE1 = [
  {progress: 0, scale: 1, opacity: 1},
  {progress: 10, scale: 1, opacity: 1},
  {progress: 15, scale: 1, opacity: 1},
  {progress: 20, scale: 0.6, opacity: 1},
  {progress: 25, scale: 1.6, opacity: 1},
  {progress: 30, scale: 0.8, opacity: 1},
  {progress: 40, scale: 1.0, opacity: 1},
  {progress: 100, scale: 1, opacity: 1},
]

const __PULSE2 = [
  {progress: 0, scale: 1, opacity: 1},
  {progress: 10, scale: 1, opacity: 1},
  {progress: 15, scale: 1, opacity: 1},
  {progress: 20, scale: 0.6, opacity: 1},
  {progress: 25, scale: 1.6, opacity: 1},
  {progress: 30, scale: 0.8, opacity: 1},
  {progress: 40, scale: 1.2, opacity: 1},
  {progress: 50, scale: 0.9, opacity: 1},
  {progress: 55, scale: 1.1, opacity: 1},
  {progress: 60, scale: 1, opacity: 1},
  {progress: 100, scale: 1, opacity: 1},
]


const __genPulseFrames = (): string => {
  const frames = __PULSE1.map((record) => {
    const frame =`${record.progress}% { transform: scale(${record.scale / 1.5}); opacity: ${record.opacity}; }`
    return frame;
  });
  const keyframes = frames.join('\n');
  return keyframes;
};


const __animPulse = () => keyframes`
  ${__genPulseFrames()}
`;


const __SMILY = [
  {progress: 0, rotate: -20},
  {progress: 10, rotate: 20},
  {progress: 20, rotate: -15},
  {progress: 30, rotate: 15},
  {progress: 40, rotate: -10},
  {progress: 50, rotate: 5},
  {progress: 60, rotate: -5},
  {progress: 70, rotate: 10},
  {progress: 80, rotate: -10},
  {progress: 90, rotate: 20},
  {progress: 100, rotate: -10},
];

const __genSmilyFrames = (): string => {
  const frames = __SMILY.map((record) => {
    const frame =`${record.progress}% { transform: rotate(${record.rotate}deg); }`
    return frame;
  });

  const keyframes = frames.join('\n');
  return keyframes;
};

const __animSmily = () => keyframes`
  ${__genSmilyFrames()}
`;


const __TIMOEUT = [
  {progress: 0,  x: 2, y: 2},
  {progress: 10, x: -2, y: -2},
  {progress: 20, x: 2, y: -2},
  {progress: 30, x: 2, y: 2},
  {progress: 40, x: -2, y: 2},
  {progress: 50, x: 2, y: -2},
  {progress: 60, x: -2, y: -2},
  {progress: 70, x: 2, y: 2},
  {progress: 80, x: -2, y: 2},
  {progress: 90, x: 2, y: -2},
  {progress: 100, x: -2, y: 2},
];

const __genTimeoutFrames = (): string => {
  const frames = __TIMOEUT.map((record) => {
    const frame =`${record.progress}% { transform: translate(${record.x}px, ${record.y}px); }`
    return frame;
  });

  const keyframes = frames.join('\n');
  return keyframes;
};

const __animTimeout = () => keyframes`
  ${__genTimeoutFrames()}
`;


export const MainWrapper = styled.div`
  /* background: red; */
  display: flex;
  flex-direction: row;
  max-width: 100%;
  justify-content: flex-end;
`;


export const ActionWrapper = styled(Panel)`
  flex-shrink: 1;

  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-width: 50px;
  gap: 0px;

  box-sizing: border-box;
`;


export const IconAnchor  = styled.div`
  justify-self: flex-start;
  flex: 0 0 34px;
  position: relative;
  height: 30px;
  /* background: #333; */
`;


export const IconWrapper  = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  border-radius: 50%;
  translate: -50% -50%;
`;

export const IconAnimPulse  = styled.div`
  animation: 
    ${__animPulse()} 
    2s
    infinite
  ;
`;

export const IconAnimSmily  = styled.div`
  animation: 
    ${__animSmily()} 
    2s
    infinite
  ;
`;

export const IconAnimTimeout  = styled.div`
  animation: 
    ${__animTimeout()} 
    0.5s
    infinite
  ;
`;

export const Text = styled(TextBase)`
  width: unset;
  white-space: wrap;
  overflow: visible;
`;
