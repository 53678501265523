import React from 'react';
import { useRecoilValue } from 'recoil';

import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState }        from 'app/ui/contexts/document';
import ContentSaveBasePlugin  from 'app/ui/components/editor-txt/plugins/content-save-plugin-base';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_Releaselogs } from 'app/ui/states/editor-instruction';


interface Props {
  cellAddr: ReleaselogsTypes.CellAddr;
}


export const ContentSavePluginComponent: React.FC<Props> = (props: Props) => {
  const {
    cellAddr, 
  } = props;

  const document = useDocState();
  const { setReleaselogs } = useEditorStatesSetters();

  const editorState = useRecoilValue(
    UIState_Releaselogs.cellText_editorState(cellAddr)
  );

  const handleEditorStateInit = (editorStateUpdate: string) => {
    // Initial editor state should be set during cell
    // construction. If so, this method should not be
    // called.
    console.error("[ViewChangelog] Editor text state init called");

    // document.docReleaselog.cellText_writeEditorState(cellAddr, editorStateUpdate);
    // document.saveUndo();
    // setDocReleaselog();
  }

  const handleEditorStateSave = (editorStateUpdate: string) => {
    document.releaselogs.cellText_writeEditorState(cellAddr, editorStateUpdate);
    document.saveUndo();
    setReleaselogs();
  }

  return (
    <ContentSaveBasePlugin
      editorTextState={editorState}
      onInitEditorTextState={handleEditorStateInit}
      onSaveEditorTextState={handleEditorStateSave}
    />
  );
}
