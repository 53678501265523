import React from 'react';
import { useState } from 'react';
import log from 'libs/log';

import { PrintoutViewTypes } from 'app/arch/editor-instruction/document/states/printout/printout-view';

import ViewPagesComponent from '../../../shared/components/view-pages';
import useSelectedReset   from '../../hooks/use-selected-reset';

import TopMenuComponent       from './top-menu';
import PanelDocumentComponent from './side-panels/panel-properties';
import SliceSystemComponent   from './slice-system';


interface Props {
}


export const ContentComponent: React.FC<Props> = (props: Props) => {
  const resetSelected   = useSelectedReset();
  const [pagesContent, setPagesContent] = useState<any[]>([]);

  log.info("Render ViewChangelog")

  const handlePagesRedefined = (pages: React.ReactElement[]) => {
    setPagesContent(pages);
  }

  // TODO take me out
  // so for example side menus will not be re-render
  // when we need to rerender release logs sliced
  const renderPagesContent = () => {
    return pagesContent;
  }


  return (
    <>
      <SliceSystemComponent 
        onPagesDefined={handlePagesRedefined}
      />

      <ViewPagesComponent
        printoutView={PrintoutViewTypes.ViewType.CHANGELOG}

        renderPagesContent={renderPagesContent}
        resetSelectedItems={resetSelected}
        
        TopMenu={TopMenuComponent}
        // SideLeftPanel={MenuItemsComponent}
        SideRightPanel={PanelDocumentComponent}
      />
    </>
  );
}
