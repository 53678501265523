import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from "app/ui/contexts/document";


const useReleaselogIsEditable = (releaselogAddr: Types.ReleaselogAddr) => {
  const document = useDocState();
  const isEditable = document.releaselogs.isReleaselogEditable(releaselogAddr);
  return isEditable;
}


export default useReleaselogIsEditable;