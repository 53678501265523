import React from 'react';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import IconLib from 'app/ui/icons/icons';
import InfoPanelComponent from '../parts/info-panel';

import { MainWrapper } from './styles';
import { PromoText } from './styles';
import { PromoTitle } from './styles';


interface Props {
}


export const PromoTextComponent: React.FC<Props> = (props: Props) => {
  const {

  } = props;

  const t = useTranslations();
  const Icon = IconLib.Callout;

  return (
    <InfoPanelComponent
      Icon={Icon}
      title={ t("demo view, panel promo, header")}
    >
      <MainWrapper>
        <PromoTitle>
          { t("demo view, panel promo, title") }
        </PromoTitle>
        <PromoText>
          { t("demo view, panel promo, text") }
        </PromoText>
      </MainWrapper>
    </InfoPanelComponent>
  );
}
