import React from 'react';

import { ReposTypes } from 'app/arch/app/states/repos';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useUsername from 'app/ui-v2/app/hooks/use-user-name';

import { InvitationMarker }  from './styles';
import { RepoName }   from './styles';
import { ListItem }   from './styles';



interface Props {
  repoPack: ReposTypes.RepoPack;
  selected: boolean;
  onRepoSelect: (repoId: number) => void;
}



export const RepoItemComponent: React.FC<Props> = (props: Props) => {
  const {
    repoPack,
    selected,
    onRepoSelect,
  } = props;
  
  const t = useTranslations();
  const username = useUsername();

  const handleRepoSelect = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRepoSelect(repoPack.repo.id);
  }

  const member     = repoPack.members.find((member) => member.username === username) !;
  const invitation = member.status === ReposTypes.MemberStatus.INVITE_SENT;
  
  const markerTxt = t("repository invited marker").toUpperCase();

  return (
    <ListItem
      onClick={handleRepoSelect}
      selected={selected}
    >
      <RepoName>
      { repoPack.repo.name }
      </RepoName>

      {
        invitation && 
        <InvitationMarker>
        { markerTxt }
        </InvitationMarker>
      }
    </ListItem>
  );
}
