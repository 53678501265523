import React from 'react';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import useDocumentScaleRef from 'app/ui-v2/editor-instruction/__document/hooks/use-document-scale-ref';
import { useDocState } from 'app/ui/contexts/document';
import HeaderCellCssComponent  from '../../header-cell-css';
import HeaderCellViewComponent from '../../header-cell-view';

import { MainWrapper } from './styles';


interface Props {
  columnAddr: Types.ColumnAddr;
}


export const HeaderCellDraggedViewComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr
  } = props;
  
  const document = useDocState();
  const width = document.content.getColumnWidth(columnAddr);
  const scaleRef = useDocumentScaleRef();
  const scale = scaleRef.current || 1;
  
  return (
    <MainWrapper 
      width={width} 
      scale={scale}
    >
      <HeaderCellCssComponent columnAddr={columnAddr} >
        <HeaderCellViewComponent columnAddr={columnAddr} />
      </HeaderCellCssComponent>
    </MainWrapper>
  );
}

