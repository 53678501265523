import styled from "styled-components";


export const Border = styled.div`
  width: 80px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
