import React from 'react';
import { useRecoilValue } from 'recoil';

import { HeaderFieldsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/header-fields';
import { useDocState }        from 'app/ui/contexts/document';
import ContentSaveBasePlugin  from 'app/ui/components/editor-txt/plugins/content-save-plugin-base';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_HeaderFields } from 'app/ui/states/editor-instruction';


interface Props {
  pageCellAddr: Types.PageCellAddr;
}


export const ContentSavePlugin: React.FC<Props> = (props: Props) => {
  const {
    pageCellAddr, 
  } = props;

  const document = useDocState();

  const { 
    setHeaderFields 
  } = useEditorStatesSetters();

  const editorState = useRecoilValue(
    UIState_HeaderFields.cellText_editorState(pageCellAddr)
  );

  const handleEditorStateInit = (editorStateUpdate: string) => {
    // Initial editor state should be set during cell
    // construction. If so, this method should not be
    // called.
    console.error("[ViewContent, CustomFields] Editor text state init called");
  }

  const handleEditorStateSave = (editorStateUpdate: string) => {
    document.headerFields.cellText_writeContent(pageCellAddr, editorStateUpdate);
    document.saveUndo();
    setHeaderFields();
  }

  return (
    <ContentSaveBasePlugin
      editorTextState={editorState}
      onInitEditorTextState={handleEditorStateInit}
      onSaveEditorTextState={handleEditorStateSave}
    />
  );
}
