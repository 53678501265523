import styled from 'styled-components';


export const DraggerBase = styled.div`
  cursor: move;
  pointer-events: all;
  touch-action: none;
`;


export default DraggerBase;