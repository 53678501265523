import React from 'react';

import { LayoutScroller }    from './styles';
import { Layout }            from './styles';
import { TopBarWrapper }     from './styles';
import { ContentWrapper }    from './styles';


interface Props {
  Topbar: React.FC;
  children: React.ReactNode;
}


export const HomePageLayoutComponent: React.FC<Props> = (props: Props) => {
  const {
    Topbar,
    children
  } = props;


  return (
    <LayoutScroller>
      <Layout>
        
        <TopBarWrapper>
          <Topbar />
        </TopBarWrapper>
        
        <ContentWrapper>
          { children }
        </ContentWrapper>

      </Layout>
    </LayoutScroller>    
  );
}

