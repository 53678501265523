import React from 'react';
import { useRecoilValue } from 'recoil';

import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import * as Tools from 'app/arch/editor-instruction/document/states/persistent/content/tools';
import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/content/types';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import useColumnsVisible from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-columns-visible';

import { SectionItemProps } from '../types';
import CellComponent from './cell';
import CellsRowContextComponent from './cells-row-context';


interface Props extends SectionItemProps {
}


export const CellsRowComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
    sectionId,
  } = props;

  const cellsRow = item as SlicerTypes.SectionItem_CellsRow;
  const rowId    = cellsRow.rowId;
  const rowAddr = {
    sectionId,
    rowId
  };

  // If row has been deleted, but content has not been 
  // resliced yet - leftover row will be still in DOM.
  // Therefore check if it does exists and skip displaying
  // it, if it does not.
  const rowPresent = useRecoilValue(UIState_Content.rowPresent(rowAddr));

  if ( ! rowPresent ) {
    return null;
  }

  return (
    <CellsRowPresentComponent {...props} />
  );
}


/**
 * 
 * CellsRowPresent
 * 
 */

const CellsRowPresentComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
    sectionId,
  } = props;

  // Track ColumnsVisible
  const columnsVisible = useColumnsVisible();

  const cellsRow = item as SlicerTypes.SectionItem_CellsRow;
  const rowId    = cellsRow.rowId;

  const renderCell = (columnAddr: Types.ColumnAddr) => {
    const cellAddr: Types.CellAddr = {
      ...columnAddr,
      sectionId,
      rowId,
    }

    const key = Tools.getCellKey(cellAddr);

    return (
      <CellComponent
        key={key}
        cellAddr={cellAddr}
      />
    );
  }

  const renderCells = () => {
    const Cells = columnsVisible.map((columnAddr) => renderCell(columnAddr));
    return Cells;
  }

  return (
    <CellsRowContextComponent 
      cellsRow={cellsRow}
      sectionId={sectionId}
    >
    { renderCells() }
    </CellsRowContextComponent>
  );
}
