import useViewSelected from "app/ui-v2/home-page/home-page-guest/hooks/use-view-selected";


const useStates = () => {
  const viewSelected = useViewSelected();

  return {
    viewSelected,
  }
}

export default useStates;
