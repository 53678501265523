import React from 'react';
import { PanelControlsItemSectionControl_SpacerConfig as Config} from '../../config';

import { MainWrapper } from './styles';


interface Props {
  config: Config;
}


export const ControlSpacerComponent: React.FC<Props> = (props: Props) => {
  const {
    config
  } = props;

  const height = (
    config.height !== undefined ?
    config.height :
    0
  );

  return (
    <MainWrapper $height={height} />
  );
}

