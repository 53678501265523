import { useState } from "react";
import { SubscriptionsTypes } from "app/arch/app/user/states/subscriptions";
import { useTranslations } from "app/ui/hooks/app/use-translation";
import useProducts from "app/ui-v2/app/__modules/products/hooks/use-products";
import useUserSubscriptionReload from "app/ui-v2/app/__modules/user/hooks/use-user-subscription-reload";
import useUserSubscriptionMutateCancelAtEnd from "app/ui-v2/app/__modules/user/hooks/use-user-subscription-mutate-cancel-at-end";


const useFunctions = () => {
  const t = useTranslations();
  const products = useProducts();

  const mutateSubscriptionCancelAtEnd = useUserSubscriptionMutateCancelAtEnd();
  const reloadSubscription = useUserSubscriptionReload();
  const [updatingAutoPayment, setUpdatingAutoPayment] = useState<SubscriptionsTypes.SubscriptionAddr | null>(null);


  const cancelSubscriptionAtEnd = (
    subAddr: SubscriptionsTypes.SubscriptionAddr,
    cancel: boolean
  ) => {
    const subscriptionId = subAddr.subscriptionId;
    setUpdatingAutoPayment(subAddr);

    mutateSubscriptionCancelAtEnd({
      variables: { subscriptionId, cancel },
      onDone: (subscriptionId: string) => {
        reloadSubscription({
          subscriptionId,
          onDone: () => {
            setUpdatingAutoPayment(null);
          }
        });
      }
    });
  }

  const isUpdating = (subAddr: SubscriptionsTypes.SubscriptionAddr) => {
    return subAddr === updatingAutoPayment;
  }

  const donwloadInvoice = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = `invoice_add-date.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const openInvoice = (url: string) => {
    window.open(url, '_blank');
  }

  const resolvePriceLookup = (priceLookupKey: string) => {
    const planAndBilling = products.pricePlans.getPlanAndBilling(priceLookupKey);

    if (planAndBilling === null) {
      return null;
    }

    const {
      planType,
      billingType,
    } = planAndBilling !;

    const plan = products.pricePlans.getPlan(planType);
    const planName = t(plan.title);
    return planName;
  }

  return {
    cancelSubscriptionAtEnd,
    isUpdating,
    donwloadInvoice,
    openInvoice,
    resolvePriceLookup,
  }
}

export default useFunctions;
