import React from 'react';
import InputComponent from 'lego/components/input';


interface Props {
  disabled: boolean;
  email: string;
  onEmailChange: (email: string) => void;
  onSendInvitation: () => void;
}


export const RepoInviteEmailComponent: React.FC<Props> = (props: Props) => {
  const {
    email,
    disabled,
    onEmailChange,
    onSendInvitation
  } = props;

  const handleEmailChange = (email_: string) => {
    onEmailChange(email_);
  }

  return (
    <InputComponent
      value={email}
      disabled={disabled}
      onValueChange={handleEmailChange}
      onCommit={onSendInvitation}
      // blurOnEnterKey={true}
      focusOnLoad={true}
    />
  );
}
  
