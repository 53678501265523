import styled from "styled-components";


export const ToolbarBase = styled.div`
  display: flex;
  flex-direction: row;

  box-sizing: border-box;

  gap:     ${props => props.theme.toolbar.gap};
  padding: ${props => props.theme.toolbar.padding};

  background:    ${props => props.theme.toolbar.background};

  border-style:  ${props => props.theme.toolbar.border.style};
  border-color:  ${props => props.theme.toolbar.border.color};
  border-width:  ${props => props.theme.toolbar.border.width};
  border-radius: ${props => props.theme.toolbar.border.radius};
  /* border-color: red; */
  /* border-width: 3px; */
`;


export default ToolbarBase;