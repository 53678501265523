import React from 'react';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import usePricePlan from '../../hooks/use-price-plan';
import { Description } from './styles';


interface Props {
}


export const PricePlanDescriptionComponent: React.FC<Props> = (props: Props) => {
  const t = useTranslations();
  const pricePlan = usePricePlan();
  const plan = pricePlan.plan;

  return (
    <Description>
      { t(plan.description) }
    </Description>
  );
}

