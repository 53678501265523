import React from 'react';
import { useRef } from 'react';

import MenubarComponent from 'lego-v2/menubar/menubar';
import useConfig from './config/use-config';

import { MenuWrapper } from './styles';
import { MenubarWrapper } from './styles';


interface Props {
}


export const EditorMarkerTopMenuComponent: React.FC<Props> = (props: Props) => {
  const closeMenuRef = useRef<(() => void) | null>(null);
  const getConfig = useConfig({ closeMenuRef });
  const config = getConfig();


  return (
    <MenuWrapper>
      <MenubarComponent
        config={config}
        closeMenu={closeMenuRef}
        MenuBar={MenubarWrapper}
      />
    </MenuWrapper>
  );
}
  
