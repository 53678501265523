import i18next from 'i18next';
import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { GetConfigProps } from "./types";


const MARGIN_MIN = 0;
const MARGIN_MAX = 80;

export const getConfigTextBox = (props: GetConfigProps) => {
  const { cmds, states } = props;
  const t = i18next.t;

  
  const disabled = (
    states.widgetProps === null ||
    ! ContentTools.isWidgetEditorText(states.widgetProps?.type)
  );


  const __margin_top = () => (
    {
      title: t('top'),
      type: PanelControlsItemSectionItemType.SLIDER,
      onChange: cmds.onTextMarginTopChanged,
      onChangeDone: cmds.onTextMarginTopChangedDone,
      min: MARGIN_MIN,
      max: MARGIN_MAX,
      value: states.textMarginTop,
    }
  );

  const __margin_bottom = () => (
    {
      title: t('bottom'),
      type: PanelControlsItemSectionItemType.SLIDER,
      onChange: cmds.onTextMarginBottomChanged,
      onChangeDone: cmds.onTextMarginBottomChangedDone,
      min: MARGIN_MIN,
      max: MARGIN_MAX,
      value: states.textMarginBottom,
    }
  );

  const __margin_left = () => (
    {
      title: t('left'),
      type: PanelControlsItemSectionItemType.SLIDER,
      onChange: cmds.onTextMarginLeftChanged,
      onChangeDone: cmds.onTextMarginLeftChangedDone,
      min: MARGIN_MIN,
      max: MARGIN_MAX,
      value: states.textMarginLeft,
    }
  );

  const __margin_right = () => (
    {
      title: t('right'),
      type: PanelControlsItemSectionItemType.SLIDER,
      onChange: cmds.onTextMarginRightChanged,
      onChangeDone: cmds.onTextMarginRightChangedDone,
      min: MARGIN_MIN,
      max: MARGIN_MAX,
      value: states.textMarginRight,
    }
  );
  
  const config: PanelControlsConfig = {
    disabled: disabled,
    items: [
      {
        title: t('text box'),
        sections: [
          {
            subsections: [
              {
                title: t('margins'),
                items: [
                  __margin_left(),
                  __margin_right(),
                  __margin_top(),
                  __margin_bottom(),
                ]
              }
            ]
          }
        ]
      },
    ]
  } // config

  return config;
}

export default getConfigTextBox;