import styled from 'styled-components';

import LayoutScrollerBase from 'app/ui-v2/home-page/styles/layout-scroller';
import LayoutBase         from 'app/ui-v2/home-page/styles/layout';
import TopBarWrapperBase  from 'app/ui-v2/home-page/styles/top-bar-wrapper';
import ContentWrapperBase from 'app/ui-v2/home-page/styles/content-wrapper';


export const LayoutScroller = styled(LayoutScrollerBase)`
`;

export const Layout = styled(LayoutBase)`
`;

export const TopBarWrapper = styled(TopBarWrapperBase)`
`;

export const ContentWrapper = styled(ContentWrapperBase)`
  /* background: rgba(255, 0, 0, 0.2); */
`;