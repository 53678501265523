import { atom } from 'recoil';
import { selectorFamily } from 'recoil';
import { UUID } from 'app/arch/types';
import { HeaderMarkersSessionState as State } from 'app/arch/editor-instruction/document/states/sessions/header-markers-session';


//----------------
//
// State
//

export const state = atom<State.State>({
  key: "ei_docHeaderMarkersSession",
  default: State.createInitialState()
});


//--------------------
//
// Selectors
//
  
export const isMarkerSelected = selectorFamily({
  key: `ei_docHeaderMarkersSession_isMarkerSelected`,
  get: (markerId: UUID) => ({ get }) => {
    const stateObj = get(state);
    const selectedMarker = State.getMarkerSelected(stateObj);

    return markerId === selectedMarker;
  }
});
