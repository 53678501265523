import React from 'react';
import TooltipComponent from 'lego/components/tooltip';
import { PanelControlsItemSectionControl_ButtonConfig as Config} from '../../config';
import { Button } from './styles';
import { Text }   from './styles';


interface Props {
  config: Config;
}


export const ControlButtonComponent: React.FC<Props> = (props: Props) => {
  const { config } = props;
  const Icon = config.Icon || null;
  const text = config.text;

  return (
    <TooltipComponent title={config.tooltip}>
      <Button
        onClick={config.onClick}
        data-test={config.dataTest}
        $width={config.width}
      >
        { text && <Text>{text}</Text> }
        { Icon && <Icon /> }
      </Button>
    </TooltipComponent>
  );
}
  
