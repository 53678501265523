import React from 'react';
import Context from './context';


interface Props {
  isRowLast: boolean;
  children: React.ReactNode;

}


export const CellContextComponent: React.FC<Props> = (props: Props) => {
  const {
    isRowLast,
    children,
  } = props;

  return (
    <Context.Provider value={{isRowLast}}>
      { children }
    </Context.Provider>
  );
}

