import styled from "styled-components";
import TitleBase     from "../../../styles/title";
import SeparatorBase from "../../../styles/separator";


export const MainWrapper = styled.div<{$disabled?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: inherit;

  opacity: ${props => props.$disabled ? props.theme.defs.opacity.disabled : 1};
`;


export const Title = styled(TitleBase)`
  font-size:   ${props => props.theme.defs.font.size.xlarge};
  font-weight: ${props => props.theme.defs.font.weight.normal};
`;


export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: inherit;
`;


export const Separator = styled(SeparatorBase)`
`;
