import { useApp }  from "./use-app";

import useAppStateSetters  from "app/ui-v2/app/hooks/use-app-state-setters";
import useUserStateSetters from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';
import useProductsStateSetters from "app/ui-v2/app/__modules/products/hooks/use-products-state-setter";
import { useHomePageUserStateSetters } from 'app/ui-v2/home-page/home-page-user/hooks';
import { useHomePageGuestStateSetters } from 'app/ui-v2/home-page/home-page-guest/hooks';


const useAppReset = () => {
  const app  = useApp();

  const {
    setAll: setAppAll, 
  } = useAppStateSetters();

  const {
    setAll: setUserAll,
  } = useUserStateSetters();

  const {
    setAll: setProductsAll,
  } = useProductsStateSetters();

  const {
    setAll: setHomePageUserAll,
  } = useHomePageUserStateSetters();

  const {
    setAll: setHomePageGuestAll,
  } = useHomePageGuestStateSetters();

  /**
   * Hook
   */
  const resetApp = () => {
    app.reset();

    setAppAll();
    setUserAll();
    setProductsAll();
    setHomePageUserAll();
    setHomePageGuestAll();
  }


  return resetApp;
}

export default useAppReset;