import { PricePlansTypes } from "app/arch/app/products/states/price-plans";
import useProducts from "app/ui-v2/app/__modules/products/hooks/use-products";
import useProductsStateSetters from "app/ui-v2/app/__modules/products/hooks/use-products-state-setter";


const useBillingPeriodSet = () => {
  const products = useProducts();
  const {
    setPricePlansSession
  } = useProductsStateSetters();

  
  const setBillingPeriod = (
    billingPeriod: PricePlansTypes.BillingPeriod
  ) => {
    products.pricePlansSession.setBillingPeriod(billingPeriod);
    setPricePlansSession();
  }

  return setBillingPeriod;
}

export default useBillingPeriodSet;