import axios from "axios";

import environment from "app/environment";

import app from "app/arch/app";
import { ToastsTypes } from "app/arch/app/info/toasts";

import { useTranslations } from "app/ui/hooks/app/use-translation";
import { useInstruction }  from "app/ui/hooks/editor-instruction/use-instruction";
import useToastAdd         from "app/ui-v2/app/__modules/info/components/toasts/hooks/use-toast-add";


type OnDone = (ok: boolean, data: any) => void;
type OnError = (error: string) => void;


export interface HookProps {
  files: File[],
  onDone?: OnDone,
  onError?: OnError,
}


export enum UploadFileType {
  LOGO   = "logo",
  IMAGES = "images"
}


//---------------------------
// 
// Hook Upload Files
// 

export const useUploadFiles = (uploadType: UploadFileType) => {
  const t = useTranslations();
  const instruction = useInstruction();
  const addToast = useToastAdd();

  //---------------------------
  // 
  // Upload files
  // 

  const uploadFiles = ({
    files, 
    onDone, 
    onError 
  }: HookProps) => {

    if (files.length === 0) {
      console.warn("nothing to upload");
      return;
    }

    // Works also without encoding.
    // So remove it is a problem.
    const filesEncoded = files.map((file) => {
      const encodedFileName = encodeURI(file.name);
      return new File([file], encodedFileName, {
        type: file.type
      });
    });

    const promise = __executeUploadFilesMutation(
      uploadType,
      instruction.id !,
      filesEncoded
    );

    promise.then((response) => {

      if ('errors' in response.data ) {
        const error = response.data.errors[0].message;
        addToast({
          text: error,
          level: ToastsTypes.ToastLevel.ERROR,
        });
        onError?.(error);
      }
      else {
        const mutationData = response.data.data.instructionFilesUpload;
        const ok = mutationData.ok;
        const data = JSON.parse(mutationData.data);
        onDone?.(ok, data);
        
        addToast({
          text: t("file(s) uploaded"),
          level: ToastsTypes.ToastLevel.INFO,
        });
      }
    })
    .catch((error) => {
      addToast({
        text: error,
        level: ToastsTypes.ToastLevel.ERROR,
      });

      onError?.(error);
    });
  }

  return uploadFiles;
}




//---------------------------
// 
// GraphQL upload mutatation
// 

const __executeUploadFilesMutation = (
  uploadType: UploadFileType,
  instructionId: number, 
  files: File[]
) => {
  const operations = {
    query: `
      mutation InstructionFilesUpload($uploadType: String!, $instructionId: Int!, $files: [Upload!]!) {
        instructionFilesUpload(uploadType: $uploadType, instructionId: $instructionId, files: $files) {
          ok
          data
        }
      }
    `,
    variables: {
      uploadType: uploadType,
      files: new Array(files.length).fill(null),
      instructionId: instructionId
    },
  };

  let formData = new FormData(); 

  //---------------------
  // Mutation
  //
  formData.append("operations", JSON.stringify(operations));

  
  //---------------------
  // Variable map
  //
  const map: any = {};
  for (let i=0; i < files.length; i++) {
      map[`${i}`] = [`variables.files.${i}`];
  }
  formData.append("map", JSON.stringify(map));
  
  //---------------------
  // Vars/files
  //
  for (let i=0; i < files.length; i++) {
    formData.append(`${i}`, files[i])
  }

  const url = environment.backend.urlUpload;
  const config = {
    headers: { 
      'content-type': 'multipart/form-data',
      ...app.auth.getAuthHeader()
    }
  }

  const promise = axios.post(url, formData, config)
  return promise;
}
