import { UUID } from "app/arch/types";


/**
 * Toast addr
 */
export type ToastAddr = {
  toastId: UUID
};


/**
 * Toasts addrs
 */
export type ToastsAddrs = ToastAddr[];


/**
 * Toast Props
 */
export type ToastProps = {
  level: ToastLevel,
  duration: ToastDuration,
  text: string,
}

export type ToastPartialProps = Partial<
  Omit< ToastProps, "text">
> & {
  text: string;
} ;

export enum ToastLevel {
  INFO    = 'info',
  WARN    = 'warn',
  ERROR   = 'error',
  SUCCESS = 'success',
};

export enum ToastDuration {
  XSHORT = 1000,
  SHORT  = 2000,
  MIDDLE = 3000,
  NORMAL = 4000,
  LONG   = 6000,
  XLONG  = 8000,
  ERROR  = 6000,
};


/**
 * Toasts Props
 */
export type ToastsProps = {
  [toastKey: string]: ToastProps
};


/**
 * Toasts State
 */
export type Toasts = {
  addrs: ToastsAddrs,
  props: ToastsProps,
};



export type Listener = ((toasts: Toasts) => void) | null;