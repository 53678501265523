import { useNavigate } from 'react-router-dom';
import { useProgressModal } from 'lego-v2/progress-modal';
import urls from 'app/configs/urls';


export const useDocumentEdit = () => {
  const navigate = useNavigate();
  const { 
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();

  const editDocument = (documentId: number) => {
    showProgressModal();
    const url = urls.editor.document.edit(documentId);
    navigate(url);
  }

  return editDocument;
}


export default useDocumentEdit;
