import React from 'react';
import TopToolbarCommonComponent from 'app/ui-v2/editor-instruction/hls/top-toolbar-common';
import useCellSelected from '../../../../hooks/cell/use-cell-selected';


interface Props {
}


export const MenuDefaultComponent: React.FC<Props> = (props: Props) => {
  const cellSelected = useCellSelected();

  if (cellSelected ) {
    return null;
  }

  return (
    <TopToolbarCommonComponent />
  );
}
