import styled from "styled-components";
import { Input as InputBase } from "app/ui-v2/editor-instruction/views/shared/components/name-edit/name-input/styles";


export const Input = styled(InputBase)<{
  $fontSize: string,
  $fontWeight: string,
  $fontVariant: string,
}>`
  font-size: ${props => props.$fontSize};
  font-weight: ${props => props.$fontWeight};
  font-variant: ${props => props.$fontVariant};
`;
