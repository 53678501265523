import React from 'react';
import { ThemeProvider } from 'styled-components';

import { themes           } from 'app/arch/app/themes/themes';
import { ThemeAccentColor } from 'app/arch/app/themes/types';
import { ThemeVariantType } from 'app/arch/app/themes/types';
import { useThemeDescriptor } from './use-theme-descriptor';

import ThemeMuiComponent        from './theme-mui';
import ThemeStyledComponent     from './theme-styled';
import ThemeReactIconsComponent from './theme-react-icons';

import { GlobalStyles } from './styles';


interface Props {
  children: React.ReactNode;
}


export const ThemeComponent: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const themeDescriptor = useThemeDescriptor();

  const darkMode      = themeDescriptor.variant === ThemeVariantType.DARK;
  const generateTheme = themes.getThemeGenerator(themeDescriptor.type);
  const accentColor   = themeDescriptor.accentColor as ThemeAccentColor;

  const themeData = generateTheme({
    accentColor,
    darkMode
  });


  return (
    <ThemeProvider theme={themeData}>
      <ThemeReactIconsComponent>
        <ThemeMuiComponent themeData={themeData}>
          <ThemeStyledComponent>
            <GlobalStyles themeData={themeData}/>
            { children }
          </ThemeStyledComponent>
        </ThemeMuiComponent>
      </ThemeReactIconsComponent>
    </ThemeProvider>
  );
}
