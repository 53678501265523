import React from 'react';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import useContextMenu  from "app/ui-v2/editor-instruction/hooks/use-context-menu";
import useColumnMenu        from "app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-column-menu";

import { MainWrapper } from './styles';


interface Props {
  columnAddr: ContentTypes.ColumnAddr;
  children: React.ReactNode;
}


export const HeaderCellContextMenuComponent: React.FC<Props> = (props: Props) => {
  const {
    columnAddr,
    children,
  } = props;

  const getMenuConfig = useColumnMenu();
  const { showMenu  } = useContextMenu();

  const handleContextMenu = (event: React.MouseEvent) => {
    const config = getMenuConfig(columnAddr);

    showMenu({
      event,
      config
    });
  }

  return (
    <MainWrapper onContextMenu={handleContextMenu}>
      { children }
    </MainWrapper>
  );
}
  
