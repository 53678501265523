import React from 'react';

import { RateControl } from 'lego/types';

import SliderRawComponent   from '../slider-raw';
import SliderValueComponent from '../controls/slider-value';

import { MainWrapper }        from './styles';
import { SliderRawWrapper }   from './styles';
import { SliderValueWrapper } from './styles';


interface Props {
  value: number | null;
  step?: number;
  min?:  number;
  max?:  number;

  onChangeStart?: () => void;
  onChange?: (value: number) => void;
  onChangeDone?: (value: number) => void;

  rateControl?: RateControl,
  disabled?: boolean;
}


export const SliderComponent: React.FC<Props> = (props: Props) => {
  const { 
    value, 
    step,
    min, 
    max, 
    onChangeStart,
    onChange,
    onChangeDone,

    rateControl,
    disabled,
  } = props;

  const handleChangeStart = () => {
    onChangeStart?.();
  }

  const handleChange = (value: number) => {
    onChange?.(value);
  }

  const handleChangeDone = (value: number) => {
    onChangeDone?.(value);
  }

  return (
    <MainWrapper>
      <SliderRawWrapper>
        <SliderRawComponent 
          min={min}
          max={max}
          value={value}
          step={step}
          onChangeStart={handleChangeStart}
          onChange={handleChange}
          onChangeDone={handleChangeDone}
          rateControl={rateControl}
          disabled={disabled}
        />
      </SliderRawWrapper>

      <SliderValueWrapper>
        <SliderValueComponent 
          min={min}
          max={max}
          value={value}
          step={step}
          disabled={disabled}
          onChangeDone={handleChangeDone}
        />
      </SliderValueWrapper>
    </MainWrapper>
  );
}
  
