import { ContentTools, ContentTypes } from "app/arch/editor-instruction/document/states/persistent/content";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { useDocState } from "app/ui/contexts/document";


const useImageViewFit = () => {
  const document = useDocState();

  const {
    setContent
  } = useEditorStatesSetters();


  const __fitView = (
    imageAddr: ContentTypes.ImageAddr,
    widgetsAddrs: ContentTypes.WidgetAddr[]
  ) => {
    const viewArea = ContentTools.fitImageView(document, widgetsAddrs);

    document.content.cellImages_updateImage(imageAddr, {viewArea});
    setContent();
    document.saveUndo();
  }

  const fitViewToImage = (imageAddr: ContentTypes.ImageAddr) => {
    const widgetsAddrs = document.content.cellImages_image_getWidgetsAddrs(imageAddr);
  
    const widgetImgAddr = widgetsAddrs.find((widgetAddr) => {
      const widgetProps = document.content.cellImages_image_getWidgetProps(widgetAddr);
      return (widgetProps.type === ContentTypes.WidgetType.IMAGE);
    })

    if (widgetImgAddr === undefined) {
      const msg = 'No image widget found';
      console.error(msg);
      return;
    }

    __fitView(imageAddr, [widgetImgAddr]);
  }

  const fitViewToAll = (imageAddr: ContentTypes.ImageAddr) => {
    const widgetsAddrs = document.content.cellImages_image_getWidgetsAddrs(imageAddr);
    __fitView(imageAddr, widgetsAddrs);
  }

  
  return {
    fitViewToImage,
    fitViewToAll,
  };
}


export default useImageViewFit;