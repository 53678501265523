import { useRecoilValue } from 'recoil';
import { UIState_Releaselogs } from 'app/ui/states/editor-instruction';
import useBaseWatcher from './use-base-watcher';


const useReleaselogsAddrsdWatcher = () => {
  const releaselogsAddrs = useRecoilValue(UIState_Releaselogs.releaselogsAddrs);
  useBaseWatcher([releaselogsAddrs]);
}
  
export default useReleaselogsAddrsdWatcher;