import { Size } from 'app/arch/types';
import { createContext } from 'react';


export interface ContextProps {
  size: Size;
};

const Context = createContext<ContextProps>({
  size: [0, 0],
});


export default Context;