import useSelectUserFiles from "./use-select-user-files";
import { HookBaseProps } from "./types";


export interface HookProps extends HookBaseProps {
}


const useSelectUserImages = (props: HookProps) => {
  return useSelectUserFiles({
    ...props,
    multipleFiles: true,
    acceptFiles: "image/png, image/jpeg",
  });

};

export default useSelectUserImages;