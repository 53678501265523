import React from 'react';
import jtl from 'tools/jtl';

import { StripeTypes } from 'lego-v2/stripe';

import { PricePlansTypes } from 'app/arch/app/products/states/price-plans';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useProducts from 'app/ui-v2/app/__modules/products/hooks/use-products';
import usePriceGet from 'app/ui-v2/app/__modules/products/hooks/use-price-get';

import { MainWrapper }     from './styles';
import { ItemsWrapper }    from './styles';
import { ItemWrapper }     from './styles';
import { ItemTitle }       from './styles';
import { LicenseNameItem } from './styles';
import { PriceItem }       from './styles';
import { LicenseValidityItem } from './styles';


interface Props {
  product: StripeTypes.Product;
}


export const ProductInfoComponent: React.FC<Props> = (props: Props) => {
  const { product } = props;
  const t = useTranslations();

  const products = useProducts();
  const getPrice = usePriceGet();

  const { 
    pricePlanType,
    billingPeriod
  } = product;

  const pricePlans = products.pricePlans;
  const pricePlan = pricePlans.getPlan(product.pricePlanType);
  const price = getPrice(pricePlanType, billingPeriod);
  const priceFormatted = jtl.number.moneyFormat(price);


  const validFor = (
    billingPeriod === PricePlansTypes.BillingPeriod.MONTH ? 
    t('license, validity, 1 month') :
    t('license, validity, 12 months')
  );


  return (
    <MainWrapper>

      <ItemsWrapper $color={pricePlan.color}>
        <ItemWrapper>
          <ItemTitle>
            { t('license') }
          </ItemTitle>

          <LicenseNameItem $color={pricePlan.color}>
          { t(pricePlan.title) }
          </LicenseNameItem>
        </ItemWrapper>


        <ItemWrapper>
          <ItemTitle>
            { t('license, price') }
          </ItemTitle>

          <PriceItem>
            { priceFormatted }
          </PriceItem>  
        </ItemWrapper>


        <ItemWrapper>
          <ItemTitle>
            { t('license, validity') }
          </ItemTitle>

          <LicenseValidityItem>
          { validFor }
          </LicenseValidityItem>  
        </ItemWrapper>
      </ItemsWrapper>

  
    </MainWrapper>
  );
}