import { useState } from 'react';
import { useEffect } from 'react';

import ProgressComponent from 'app/ui-v2/hls/progress';
import { setup as setupProgressModal } from './use-progress-modal';

import { MainWrapper } from './styles';
import { TextWrapper } from './styles';
import { Text } from './styles';


interface Props {
}


export const ProgressModalComponent: React.FC<Props> = (props: Props) => {
  const [visible, setVisible] = useState(false);
  const [msg, setMsg] = useState<string | undefined>(undefined);

  useEffect(() => {
    setupProgressModal(setVisible, setMsg);
    
    return () => {
      setupProgressModal(null, null);
    }
  }, [setVisible]);


  return (
    <MainWrapper $visible={visible}>
      {
        msg !== undefined &&
        <TextWrapper>
          <Text>{ msg }</Text>
          <ProgressComponent />
        </TextWrapper>
      }
      {
        msg === undefined &&
        <ProgressComponent />
      }
    </MainWrapper>
  );
};
