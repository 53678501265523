import { useState }  from 'react';
import { useEffect } from 'react';

import { query }       from 'app/arch/backend';
import { useQueryV2 }  from 'app/arch/backend/use-query-v2';
import { Instruction } from 'app/arch/backend/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';


const useDocFetch = (props: {
  documentId: number,
  skipMsgs?: boolean,
}) => {

  const {
    documentId,
    skipMsgs
  } = props;

  const t = useTranslations();
  const [loaded, setLoaded] = useState(false);
  const [document, setDocument] = useState<Instruction | null>(null);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    setLoaded(false);
    setDocument(null);
    setError(null);
  }, [documentId]);


  const msgs = (
    skipMsgs ? 
    {} : 
    {
      onStart: { msg: t("document loading") },
      onEnd: { msg: t("document loaded") }
    }
  );

  const { 
    error: loadDocError, 
    data: loadDocData
  } = useQueryV2({
    query: query.getInstruction(),
    variables: {instructionId: documentId},
    msgs,
  });


  /**
   * Query data
   */
  useEffect(() => {
    if ( ! loadDocData ) {
      return;
    }

    const doc = loadDocData.instruction;
    setDocument(doc);
    setLoaded(true);
    setError(null);
  }, [loadDocData]);


  /**
   * Query error
   */
  useEffect(() => {
    if ( ! loadDocError) {
      return;
    }

    setError(loadDocError.message);
    setDocument(null);
    setLoaded(true);
  }, [loadDocError]);


  return {
    document,
    loaded,
    error
  }
}

export default useDocFetch;