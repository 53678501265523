import { useSetRecoilState } from "recoil";
import { UIModalsRelogin } from "../states";
import useRelogin from "./use-relogin";


const useReloginStateSetters = () => {
  const relogin = useRelogin();

  const __setRelogin = useSetRecoilState(UIModalsRelogin.state);
  const setRelogin = () => __setRelogin(relogin.state);

  const setAll = () => {
    setRelogin();
  }

  return {
    setAll,
    setRelogin,
  }
}


export default useReloginStateSetters;