import i18next from 'i18next';

import { PanelControlsConfig              } from "lego/components/panel-controls/config"

import { GetConfigProps } from "./types";


const getConfigStyles = (props: GetConfigProps) => {
  const { cmds, states } = props;
  const t = i18next.t;
  const disabled = states.widgetAddr === null;

  // Remove me

  


  const config: PanelControlsConfig = {
    disabled: disabled,
    items: [
      {
        title: t('styles'),
        sections: [
          {
            items: [
            ]
          },
        ]
      }
    ]
  } // config

  return config;
}

export default getConfigStyles;