import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';
import { WidgetsComponent } from '../widgets/Widgets';

import { Clipper }    from './styles';
import { Scaler }     from './styles';
import { Detacher }   from './styles';
import { Positioner } from './styles';


export interface Props {
  imageAddr: Types.ImageAddr;
  viewArea: Types.ImageViewArea;
  viewScale: number;

  selected?: boolean;
}


export const WidgetsViewScaledComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr, 
    viewArea,
    viewScale,
  } = props;
  
  const width  = viewArea.x2 - viewArea.x1;
  const height = viewArea.y2 - viewArea.y1;

  const widthScaled  = width  * viewScale;
  const heightScaled = height * viewScale;

  const left = -1 * viewArea.x1;
  const top  = -1 * viewArea.y1;

  return (
    <Clipper
      $width={widthScaled}
      $height={heightScaled}
    >
      <Scaler $scale={viewScale}>
        <Positioner>
          <Detacher
            $left={left}
            $top={top}
          >
            <WidgetsComponent
              scale={1}
              imageAddr={imageAddr}
              editDisabled={true}
            />
          </Detacher>
        </Positioner>
      </Scaler>
    </Clipper>
  );
}
