import styled from "styled-components";
import ItemPanel from "lego/styles/item-panel";
import ListItemBase from 'lego/styles/list-item';
import TextWrap from "lego/styles/text-wrap";



export const MainWrapper = styled(ItemPanel)`
`;


export const ListItem = styled(ListItemBase)`
  ${props => props.selected ? `
    background: transparent;
    color: ${props.theme.menuItem.color.primary};
  ` : ''
  }
`;


export const MemberUsername = styled(TextWrap)`
  width: 100%;
  font-size: ${props => props.theme.defs.font.size.large};
`;
