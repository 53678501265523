import React, { useState } from 'react';

import ItemPanelGridComponent from 'lego-v2/item-panel-grid';
import { ItemPanelGridTypes } from 'lego-v2/item-panel-grid';

import { DocumentRelease } from 'app/arch/backend/types';
import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useDocumentReleaseDownload from 'app/ui-v2/app/hooks/use-document-release-download';

import { ButtonSendPDF } from './styles';
import { TextWrap } from './styles';


interface Props {
  documentId: number;
  release: DocumentRelease;
}


export const ItemPdfDownloadComponent: React.FC<Props> = (props: Props) => {
  const {
    documentId,
    release,
  } = props;
  
  const t = useTranslations();

  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const downloadDocumentRelease = useDocumentReleaseDownload();

  const handleDownloadPDF = () => {
    setDownloadingPDF(true);
    const revision = release.revision;
    const showProgress = false;
    
    downloadDocumentRelease({
      documentId, 
      revision,
      showProgress,
      onDone: () => {
        setDownloadingPDF(false);
      },
      onError: (error: string) => {
        setDownloadingPDF(false);
      }
    });
  }

  const cellsAddrs: ItemPanelGridTypes.Cells = [
    {
      rowIdx: 0,
      columnIdx: 0,
      disabled: downloadingPDF,
      Component: (
        <>
        </>
      )
    },
    {
      rowIdx: 0,
      columnIdx: 1,
      disabled: downloadingPDF,
      Component: (
        <ButtonSendPDF
          disabled={downloadingPDF}
          onClick={handleDownloadPDF}
        >
          <TextWrap>
          { t("doc release download, btn") }
          </TextWrap>
        </ButtonSendPDF>
      )
    }
  ];

  return (
    <ItemPanelGridComponent
      title={t("doc release download, title")}
      rowsCount={1}
      cells={cellsAddrs}
      showProgres={downloadingPDF}
      // progressTitle={t("doc release send, msgbox, sending")}
    />
  );
}

