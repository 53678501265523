import styled from "styled-components";
import { Box } from "app/ui-v2/hls/progress-box/styles";
import ButtonPanel from "lego/styles/button_panel";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.small};
`;


export const Button = styled(ButtonPanel)`
  width: 100%;
  max-width: unset;
`;

export const ProgressPositioner = styled.div`
  position: relative;
`;

export const ProgressDetacher = styled.div`
  position: absolute;
  width: 100%;
`;

export const ProgressBox = styled(Box)`
  width: 100%;
`;