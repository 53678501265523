import produce from 'immer';
import * as State from './state';
import * as Types from './types';
import * as Tools from './tools';


export class RepoImages {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  get state() { return this._state; }
  set state(state: State.State) { this._state = state; }

  
  /**
   * Setters
   */
  
  addImage(image: Types.Image) {
    this._state = produce(this._state, draft => {
      draft.images.push(image);
    });
  }

  removeImage(bid: number) {
    this._state = produce(this._state, draft => {
      const idx = State.getImageIdx(draft, bid);
      draft.images.splice(idx, 1);
    });
  }

  loadImages(images: Types.ImagesRaw) {
    this._state = produce(this._state, draft => {
      
      images.forEach((imageRaw) => {
        const image = Tools.convertImage(imageRaw); 
        draft.images.push(image);
      });

    });
  }

  //-----------------
  //
  // Getters
  
  hasImage(bid: number) {
    return State.hasImage(this._state, bid);
  }
  
  getImage(bid: number) {
    return State.getImage(this._state, bid);
  }

  getImages() {
    return State.getImages(this._state);
  }
}
