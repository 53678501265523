import styled from "styled-components";


export const MainWrapper = styled.div`
  display: flex;
  height: 100%;
  background-color: ${props => props.theme.defs.colors.background.primary};
`;

export const Padding = styled.div`
  padding: ${props => props.theme.defs.padding.xxlarge};
  flex-grow: 1;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xlarge};
  /* background-color: red; */
`;
