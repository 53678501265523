import React from 'react';

import { settings } from 'app/configs';

import { ThemeAccentColor } from 'app/arch/app/themes/types';
import { ThemeVariantType } from 'app/arch/app/themes/types';
import { themes           } from 'app/arch/app/themes/themes';
import { useThemeDescriptor } from 'app/ui/components/app/theme/use-theme-descriptor';
import useUserSettings        from 'app/ui-v2/app/__modules/user/hooks/use-user-settings';
import useUserSettingsSave    from 'app/ui-v2/app/__modules/user/hooks/use-user-settings-save';
import useUserStateSetters    from 'app/ui-v2/app/__modules/user/hooks/use-user-state-setters';

import { ButtonGroup } from './styles';
import { ButtonColor } from './styles';


interface Props {
}


export const ControlThemeAccentColorComponent: React.FC<Props> = (props: Props) => {
  const userSettings = useUserSettings();
  const theme = useThemeDescriptor();

  const saveUserSettings = useUserSettingsSave();
  const {
    setUserSettings
  } = useUserStateSetters();

  const darkMode  = theme.variant === ThemeVariantType.DARK;
  const generateThemeAccentColors = themes.getThemeAccentColors(theme.type);
  const accentColors = generateThemeAccentColors(darkMode);

  const setAccentColor = (accentColor: ThemeAccentColor) => {
    userSettings.updateTheme({ accentColor });
    setUserSettings();
    saveUserSettings();
  }

  const renderButtons = () => {
    const buttonColors = settings.accentColors.map((color) => {
      const accent = accentColors[color];
      const selected = theme.accentColor === color;

      return (
        <ButtonColor
          color={accent.primary}
          borderColor={accent.dark}
          selected={selected}
          key={`btn-${color}`}
          onClick={(e: any) => {
            e.stopPropagation();
            setAccentColor(color);
          }}
        />
      )
    });

    return buttonColors;
  }

  return (
      <ButtonGroup>
      { renderButtons() }
      </ButtonGroup>
  );
}
  