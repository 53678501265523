import { useSetRecoilState } from "recoil";

import { useApp } from "app/ui/hooks/app/use-app";
import { UIState_Repos } from "app/ui/states/app/repos-state";
import { UIAppStates } from "../__modules/app-states/states";


const useAppStateSetters = () => {
  const app = useApp();

  const __setApp = useSetRecoilState(UIAppStates.state);
  const __setRepos = useSetRecoilState(UIState_Repos.state);
 
  const setApp = () => __setApp(app.appState.raw);
  const setRepos = () => __setRepos(app.repos.raw);

  const setAll = () => {
    setApp();
    setRepos();
  }

  return {
    setAll,
    
    setApp,
    setRepos,
  }
}

export default useAppStateSetters;