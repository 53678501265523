import styled from "styled-components";
import { Item as ItemBase } from "lego-v2/menubar/hls/menubar-item-panel-item/styles";
import { IconWrapper as IconWrapperBase } from "lego-v2/menubar/hls/menubar-item-panel-item/styles";
import { IconScaler as IconScalerBase } from "lego-v2/menubar/hls/menubar-item-panel-item/styles";
import { TextWrapper as TextWrapperBase } from "lego-v2/menubar/hls/menubar-item-panel-item/styles";
import { Text as TextBase } from "lego-v2/menubar/hls/menubar-item-panel-item/styles";
import { KeyBindingWrapper as KeyBindingWrapperBase } from "lego-v2/menubar/hls/menubar-item-panel-item/styles";


export const Item = styled(ItemBase)`
`;

export const IconWrapper = styled(IconWrapperBase)`
`;

export const IconScaler = styled(IconScalerBase)`
`;


export const TextWrapper = styled(TextWrapperBase)`
`;

export const Text = styled(TextBase)`
`;

export const KeyBindingWrapper = styled(KeyBindingWrapperBase)`
`;
