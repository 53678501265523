import * as Defaults from './defaults';
import * as Types from "./types"


/**
 * State
 */
export type State = {
  panel: Types.Panel;
}


/**
 * Getters
 */
export const getPanel = (state: State) => state.panel;


/**
 * Create Init State
 */
export const createInitialState = (): State => {
  const initState: State = {
    panel: Defaults.getPanel()
  }

  return initState;
}
