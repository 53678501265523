import { PanelControlsConfig } from "lego/components/panel-controls/config"

import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { useDocState } from 'app/ui/contexts/document';
import useColumnSelected from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/column/use-column-selected';

import useColumnIndexConfig from './__use-column-index-config';
import useColumnTextConfig  from './__use-column-text-config';
import useColumnUnselectedConfig from './__use-column-unselected-config';


const usePanelConfigColumn = () => {
  const document = useDocState();
  const selectedColumnAddr = useColumnSelected();

  const getColumnIndexConfig = useColumnIndexConfig();
  const getColumnTextConfig  = useColumnTextConfig();
  const getColumnUnselectedConfig = useColumnUnselectedConfig();

  
  const getConfig = () => {
    if (selectedColumnAddr === null) {
      return getColumnUnselectedConfig();
    }

    const columnProps = document.releaselogs.getColumnProps(selectedColumnAddr);
  
    const Configs: {[key in Types.ColumnType]: () => PanelControlsConfig } = {
      [Types.ColumnType.INDEX]: getColumnIndexConfig,
      [Types.ColumnType.TEXT ]: getColumnTextConfig,
    }

    const getConfigFn = Configs[columnProps.type];
    const config = getConfigFn();
    
    return config;
  }

  
  return getConfig;
}
  
export default usePanelConfigColumn;
