import React from 'react';
import { useRecoilValue } from 'recoil';

import { UIState_ReleaselogsView } from 'app/ui/states/editor-instruction';

import { Title } from './styles';


interface Props {
}


export const ViewTitleContentComponent: React.FC<Props> = (props: Props) => {
  const title = useRecoilValue(UIState_ReleaselogsView.headerTitleTitle);

  const titleDisplayed  = (
    title.length !== 0 ?
    title :
    <>&nbsp;</>
  );

  return (
    <Title>
      { titleDisplayed }
    </Title>
  );
}
  
