import React from 'react';

import { ControlTitle     } from './styles';
import { ControlWrapper   } from './styles';


interface Props {
  title: string;
  component: any;
}


export const ControlSubItemComponent: React.FC<Props> = (props: Props) => {
  const { title, component } = props;

  return (
    <ControlWrapper>
      <ControlTitle>
        { title }
      </ControlTitle>
      { component }
    </ControlWrapper>
  );
}
  
