import React from "react";
import jtl from "tools/jtl";
import * as docx from "docx";
import * as Types from './types';


export const getTextHeaderShading = (
  update: Types.ShadingOptionsUpdate
): Types.ShadingOptions => {
  const opt: Types.ShadingOptions = {
    color: "auto",
    type: docx.ShadingType.CLEAR,
    ...update,
  }

  return opt;
}


export const getFont = () => ({
  size: 16,
  color: "#000000",
  font: 'Calibri',
});


export const getTextHeaderOptions = (
  update: Types.TextRunOptionsUpdate
): Types.TextRunOptions => {
  const font = getFont();

  const opt: Types.TextRunOptions = {
    ...font,
    bold: true,
    size: 18,
    ...update,
  }

  return opt;
}


export const getTableHeaderOptions = (
  css: React.CSSProperties,
  update_: Types.TextRunOptionsUpdate
): Types.TextRunOptions => {
  const smallCaps = (css.fontVariant === "small-caps");
  const allCaps = (css.fontVariant === "all-small-caps");
  const size = jtl.css.valueToNumber(css.fontSize, 18);
  const color = jtl.color.expandHex(css.color);

  const update = {
    ...update_,
    smallCaps,
    allCaps,
    size,
    color,
  }

  return getTextHeaderOptions(update);
}


export const getCustomFieldsHeaderOptions = (
  css: React.CSSProperties,
  update_: Types.TextRunOptionsUpdate
): Types.TextRunOptions => {
  const smallCaps = (css.fontVariant === "small-caps");
  const allCaps = (css.fontVariant === "all-small-caps");
  const size = jtl.css.valueToNumber(css.fontSize, 18);

  const update = {
    ...update_,
    smallCaps,
    allCaps,
    size,
  }

  return getTextHeaderOptions(update);
}


export const getTextOptions = (
  update: Types.TextRunOptionsUpdate
): Types.TextRunOptions => {
  const font = getFont();

  const opt: Types.TextRunOptions = {
    ...font,
    ...update,
  }

  return opt;
}


export const getTitleTextOptions = (
  update: Types.TextRunOptionsUpdate
): Types.TextRunOptions => {
  const textOpt = getTextOptions({});

  const opt: Types.TextRunOptions = {
    ...textOpt,
    bold: true,
    size: 26,
    ...update,
  }

  return opt;
}


export const getDescriptionTextOptions = (
  update: Types.TextRunOptionsUpdate
): Types.TextRunOptions => {
  const textOpt = getTextOptions({});

  const opt: Types.TextRunOptions = {
    ...textOpt,
    size: 20,
    ...update,
  }

  return opt;
}


export const getBorderOff = () => ({ 
  style: docx.BorderStyle.NIL, 
  size: 0,
});


export const getBorderOn = () => ({ 
  style: docx.BorderStyle.SINGLE, 
  size: 1,
  color: "000000",
});
