import React from 'react';

import { PanelControlsItemSectionControlConfig as Config } from '../../../../config';

import DynamicWidth        from '../../../../styles/dynamic-width';
import DynamicWidthWrapper from '../../../../styles/dynamic-width-wrapper';

import { MainWrapper } from './styles';
import { Title }       from './styles';


interface Props {
  config: Config;
  sectionDisabled: boolean;
  itemDisabled: boolean;
  ItemComponent: React.ElementType;
  itemProps: any;
}

export const ItemComponent: React.FC<Props> = (props: Props) => {
  const {
    config,
    sectionDisabled,
    itemDisabled,
    ItemComponent,
    itemProps
  } = props;

  const disabled = (itemDisabled && ! sectionDisabled);


  return (
    <MainWrapper $disabled={disabled} >
      {
        config.title &&
        <DynamicWidthWrapper>
          <DynamicWidth>
            <Title>
              { config.title }
            </Title>
          </DynamicWidth>
        </DynamicWidthWrapper>
      }
      <ItemComponent 
        config={config as any} 
        {...itemProps}
      />
    </MainWrapper>
  );
}
