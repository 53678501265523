import { atom } from 'recoil';
import { selector } from 'recoil';
import { selectorFamily } from 'recoil';
import { ReleaselogsState as State } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';


type CellAddr   = Types.CellAddr;
type ColumnAddr = Types.ColumnAddr;


//----------------
// State
//

export const state = atom<State.State>({
  key: "ei_docReleaselog",
  default: State.createInitialState()
});



/**
 * 
 * Releaselogs
 * 
 */

export const releaselogActive = selector({
  key: "ei_docReleaselog_releaselogActive",
  get: ({ get }) => {
    const stateObj = get(state);
    const releaselogAddr = State.getReleaselogActive(stateObj);
    return releaselogAddr;
  }
});


/**
 * 
 * Releaselog
 * 
 */

export const releaselogDescriptionVisible = selectorFamily({
  key: "ei_docReleaselog_releaselogDescriptionVisible",
  get: (releaseAddr: Types.ReleaselogAddr) => ({ get }) => {
    const stateObj = get(state);
    const releaselogInfo = State.getReleaselogInfo(stateObj, releaseAddr);
    return releaselogInfo.description.visible;
  }
});

export const releaselogDescriptionText = selectorFamily({
  key: "ei_docReleaselog_releaselogDescriptionText",
  get: (releaseAddr: Types.ReleaselogAddr) => ({ get }) => {
    const stateObj = get(state);
    const releaselogInfo = State.getReleaselogInfo(stateObj, releaseAddr);
    return releaselogInfo.description.text;
  }
});

export const releaselogsAddrs = selector({
  key: `ei_docReleaselog_releaselogsAddrs`,
  get: ({ get }) => {
    const stateObj = get(state);
    const releaselogsAddrs = State.getReleaselogsAddrs(stateObj);
    return releaselogsAddrs;
  }
});


/**
 * 
 * Column(s)
 */

export const columnsAddrs = selectorFamily({
  key: "ei_docReleaselog_columnsAddrs",
  get: (releaselogAddr: Types.ReleaselogAddr) => ({ get }) => {
    const stateObj = get(state);
    const columnsAddrs = State.getColumnsAddrs(stateObj, releaselogAddr);
    return columnsAddrs;
  }
});

export const columnWidth = selectorFamily({
  key: "ei_docReleaselog_columnWidth",
  get: (columnAddr: ColumnAddr) => ({ get }) => {
    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr);
    return columnProps.width;
  }
});

export const columnCSS = selectorFamily({
  key: "ei_docReleaselog_columnCSS",
  get: (columnAddr: ColumnAddr | null) => ({ get }) => {
    if (columnAddr === null) {
      return null;
    }
    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr);
    return columnProps.css;
  }
});

export const columnHeaderCSS = selectorFamily({
  key: "ei_docReleaselog_columnHeaderCSS",
  get: (columnAddr: ColumnAddr | null) => ({ get }) => {
    if (columnAddr === null) {
      return null;
    }
    
    const stateObj = get(state);
    const columnProps = State.getColumnProps(stateObj, columnAddr);
    return columnProps.header.css;
  }
});


/**
 * 
 * Rows
 */

export const rowsAddrs = selectorFamily({
  key: "ei_docReleaselog_rowsAddrs",
  get: (releaseAddr: Types.ReleaselogAddr) => ({ get }) => {
    const stateObj = get(state);
    const rowsAddrs = State.getRowsAddrs(stateObj, releaseAddr);
    return rowsAddrs;
  }
});


/**
 * 
 * Rows
 */

export const rowPresent = selectorFamily({
  key: "ei_docReleaselog_rowPresent",
  get: (rowAddr: Types.RowAddr) => ({ get }) => {
    const stateObj = get(state);
    const present = State.isRowPresent(stateObj, rowAddr);
    return present;
  }
});


/**
 * 
 * Cells
 * 
 */

export const cellText_editorState = selectorFamily({
  key: "ei_docReleaselog_cellText_editorState",
  get: (cellAddr: CellAddr) => ({ get }) => {
    const stateObj = get(state);
    const editorState = State.cellText_getEditorState(stateObj, cellAddr);
    return editorState;
  }
});
