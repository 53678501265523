import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from "app/ui-v2/editor-instruction/hooks/use-editor-states-setters";
import { RepoMarkersTypes } from "app/arch/editor-instruction/document/states/persistent/repo-markers";


const useMarkerEdit = () => {
  const document = useDocState();

  const {
    setEditorMarkerSession,
  } = useEditorStatesSetters();

  const hook = (markerAddr: RepoMarkersTypes.MarkerAddr) => {
    document.editorMarkerSession.updateWindow({visible: true});
    document.editorMarkerSession.updateEditor({
      iconSearchName: '',
      selectedMarkerAddr: markerAddr
    });
    setEditorMarkerSession();
  }

  return hook;
}

export default useMarkerEdit;