import React from 'react';
import { useRef }    from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';

import MenuComponent          from 'lego-v2/menu';
import { MenuConfig }         from 'lego-v2/menu/config';
import useKeyBindings         from 'lego/components/key-bindings/hooks/use-key-bindings';
import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';

import { Position } from 'app/arch/types';

import useGClick                   from '../../hooks/use-gclick';
import { useSetupContextMenuHook } from '../../hooks/use-context-menu';
import ScreenFitterComponent from '../screen-fitter';

import { MainWrapper } from './styles';


interface Props {
}


export const ContextMenuComponent: React.FC<Props> = (props: Props) => {
  const [position, setPosition]     = useState<Position>([0, 0]);
  const [menuConfig, setMenuConfig] = useState<MenuConfig | null>(null);

  const escBindId = useRef<string | null>(null);
  const setupContextMenuHook = useSetupContextMenuHook()

  const { 
    addKeyDownBinding, 
    removeKeyDownBinding,
  } = useKeyBindings();

  useEffect(() => {
    setupContextMenuHook({
      setConfig: (config: MenuConfig | null) => setMenuConfig(config),
      setPosition: (position_: Position) => setPosition(position_)
    });
  });

  useEffect(() => {
    if (menuConfig !== null) { 
      addEscBinding(); 
    }
    else { 
      removeEscBinding(); 
    }
  }, [menuConfig]);


  useGClick({
    enabled: menuConfig !== null,
    onClick: (event: any) => {
      handleMenuClose();
    }
  });


  const addEscBinding = () => {
    if (escBindId.current !== null) {
      // Is already added
      return;
    }

    escBindId.current = addKeyDownBinding({
      check: (event: React.KeyboardEvent) => event.key === "Escape",
      callback: escPressed,
      priority: KeyBindingPriority.CONTEXT_LAYER
    });
  }

  const removeEscBinding = () => {
    if ( escBindId.current !== null) {
      removeKeyDownBinding(escBindId.current);
      escBindId.current = null;
    }
  }

  const escPressed = () => {
    handleMenuClose();
  }

  const handleMenuClose = () => {
    setMenuConfig(null);
  }

  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  }

  return (
    <>
    {
      menuConfig && 
      <ScreenFitterComponent
        position={position}
      >
        <MainWrapper 
          onClick={stopPropagation}
        >
          <MenuComponent
            dataTest={'editor-instruction-context-menu'}
            onClose={handleMenuClose}
            config={menuConfig}
          />
        </MainWrapper>
      </ScreenFitterComponent>
    }
    </>
  );
}
  
