import React from 'react';

import { SectionItem } from 'lego/components/panel-controls/config';
import { PanelControlsItemSectionItemType } from 'lego/components/panel-controls/config';

import { ControlItemsMixComponent }  from '../ControlItemsMix';
import ControlButtonComponent        from '../../control-button';
import ControlButtonGroupComponent   from '../../control-button-group';
import ControlColorPickerComponent   from '../../control-color-picker';
import ControlDropdownListComponent  from '../../control-dropdown-list';
import ControlInputComponent         from '../../control-input';
import ControlListComponent          from '../../control-list';
import ControlProgressComponent      from '../../control-progress';
import ControlSliderComponent        from '../../control-slider';
import ControlSpacerComponent        from '../../control-spacer';
import ControlTextComponent          from '../../control-text';
import ControlTextMultilineComponent from '../../control-text-multiline';
import ControlToolbarComponent       from '../../control-toolbar';


interface Props {
  config: SectionItem
}


export const ItemRenderComponent: React.FC<Props> = (props: Props) => {
  const {
    config
  } = props;

  const Items = {
    [PanelControlsItemSectionItemType.BUTTON        ]: ControlButtonComponent,
    [PanelControlsItemSectionItemType.BUTTON_GROUP  ]: ControlButtonGroupComponent,
    [PanelControlsItemSectionItemType.COLOR_PICKER  ]: ControlColorPickerComponent,
    [PanelControlsItemSectionItemType.DROPDOWN_LIST ]: ControlDropdownListComponent,
    [PanelControlsItemSectionItemType.INPUT         ]: ControlInputComponent,
    [PanelControlsItemSectionItemType.LIST          ]: ControlListComponent,
    [PanelControlsItemSectionItemType.PROGRESS      ]: ControlProgressComponent,
    [PanelControlsItemSectionItemType.SLIDER        ]: ControlSliderComponent,
    [PanelControlsItemSectionItemType.SPACER        ]: ControlSpacerComponent,
    [PanelControlsItemSectionItemType.TEXT          ]: ControlTextComponent,
    [PanelControlsItemSectionItemType.TEXT_MULTILINE]: ControlTextMultilineComponent,
    [PanelControlsItemSectionItemType.TOOLBAR       ]: ControlToolbarComponent,
    [PanelControlsItemSectionItemType.ITEMS_MIX     ]: ControlItemsMixComponent,
    
    // TODO
    [PanelControlsItemSectionItemType.USER_COMPONENT]: ControlButtonComponent,
  }

  const ItemComponent = Items[config.type];

  return (
    <ItemComponent  config={config as any} />
  );
}

