import produce from 'immer';
import { settings }     from 'app/configs';
import { WidgetsStylesDefaults, WidgetsStylesTypes } from "app/arch/editor-instruction/document/states/persistent/editor-image-widgets-styles";
import * as Types from './types';


type Widget = {
  style: WidgetsStylesTypes.StyleAttrs
};

//----------------------------
//
// State's
//

export type State = {
  widget: Widget,

  grid: {
    size: number,
    enabled: boolean,
    showType: Types.GridType,
  }

  smartLines: {
    stickyLines: {
      visible: boolean,
      sticky: boolean,
      stickyness: number,
    },
    aligmentLines: {
      visible: boolean,
    },
    srcLines: {
      visible: boolean,
    }
  },
};


//----------------------------
//
// Producers
//

export const setWidgetStyle  = produce((draft: State, widgetStyle: WidgetsStylesTypes.StyleAttrs) => {
  draft.widget.style = widgetStyle;
}); 

export const setGridSize  = produce((draft: State, size: number) => {
  draft.grid.size = size;
}); 

export const setGridEnabled  = produce((draft: State, enabled: boolean) => {
  draft.grid.enabled = enabled;
}); 

export const setGridShowType  = produce((draft: State, showType: Types.GridType) => {
  draft.grid.showType = showType;
}); 

export const setSmartLinesAligmentLinesVisible  = produce((draft: State, visible: boolean) => {
  draft.smartLines.aligmentLines.visible = visible;
}); 

export const setSmartLinesSticky  = produce((draft: State, sticky: boolean) => {
  draft.smartLines.stickyLines.sticky = sticky;
}); 

export const setSmartLinesStickyness  = produce((draft: State, stickyness: number) => {
  draft.smartLines.stickyLines.stickyness = stickyness;
});

export const setSmartLinesSrcLinesVisible  = produce((draft: State, visible: boolean) => {
  draft.smartLines.srcLines.visible = visible;
});

//----------------------------
//
// Getters
//

export const getGrid = (state: State) => state.grid;
export const getSmartLines = (state: State) => state.smartLines;


//----------------------------
//
// Create initial state
//

export const createInitialState = () => {
  return {
    grid: {
      size: 10,
      enabled: false,
      showType: Types.GridType.HIDE
    },

    smartLines: {
      stickyLines: {
        visible: true,
        sticky: true,
        stickyness: settings.smartLines.align.distance
      },
      aligmentLines: {
        visible: false
      },
      srcLines: {
        visible: false
      },
    },
    
    widget: {
      style: WidgetsStylesDefaults.getStyleAttrs(),
    }
  }
}

