import styled from "styled-components";
import TitleBase from 'app-views/styles/title';


export const Background = styled.div`
  pointer-events: all;
  overflow-y: scroll;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.9);
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.defs.padding.xlarge};
`;

export const DummyFiller = styled.div`
  /* background: rgba(2, 255, 0, 0.9); */
  flex: 1 1 0;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Content = styled.div`
  width: 280px;

  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.large};
`;

export const Title = styled(TitleBase)`
  display: flex;
  align-items: center;
  justify-content: center;
`;