import styled from "styled-components";


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.large};

  box-sizing: border-box;
  padding: 
    0
    ${props => props.theme.defs.padding.xlarge} 
    ${props => props.theme.defs.padding.xxlarge} 
    ${props => props.theme.defs.padding.xlarge};

`;
