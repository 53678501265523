import React from 'react';
import { useRecoilValue } from 'recoil';
import { HeaderRowsTypes } from 'app/arch/editor-instruction/document/states/persistent/header-rows';
import { UIState_HeaderRows } from 'app/ui/states/editor-instruction';
import { Text } from './styles';


interface Props {
  pageRowAddr: HeaderRowsTypes.PageRowAddr;
}


export const HeaderCellViewComponent: React.FC<Props> = (props: Props) => {
  const {
    pageRowAddr,
  } = props;

  const rowNameTmp = useRecoilValue(UIState_HeaderRows.rowName(pageRowAddr));

  const rowName = (
    rowNameTmp.length ?
    rowNameTmp :
    <>&nbsp;</>
  );

  return (
    <Text>
    { rowName }
    </Text>
  );
}

