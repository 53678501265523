import { ContentTypes as Types } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState } from "app/ui/contexts/document";
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import useSelectedReset from '../use-selected-reset';


const useSectionDelete = () => {
  const document = useDocState();
  const {
    setContent,
  } = useEditorStatesSetters();

  const resetSelected    = useSelectedReset();

  const deleteSection = (sectionAddr: Types.SectionAddr) => {
    document.content.deleteSection(sectionAddr);
    document.saveUndo();
    setContent();
    resetSelected();
  }

  return deleteSection;
}


export default useSectionDelete;