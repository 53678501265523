import DPI from 'libs/dpi';
import { Size, UnitsSymbol, UnitsType } from "app/arch/types/types";
import { PageFormatType } from "app/arch/types/types";
import { PageMarginType } from "app/arch/types/types";
import { PageOrientationType } from "app/arch/types/types";

export namespace Page {

const PageSizes = {
  [PageFormatType.A3]:     [297, 420],
  [PageFormatType.A4]:     [210, 297],
  [PageFormatType.A5]:     [148, 210],
  [PageFormatType.A6]:     [105, 148],
  [PageFormatType.LEGAL]:  [8.5, 11],
  [PageFormatType.LETTER]: [8.5, 14],
}

const PageSizesMetric = [
  PageFormatType.A3, 
  PageFormatType.A4, 
  PageFormatType.A5,
  PageFormatType.A6,
]

const PageSizesImperial = [
  PageFormatType.LEGAL, 
  PageFormatType.LETTER, 
]

export const getSizeWithUnits = (format: PageFormatType, orientation: PageOrientationType) => {
  const metricStr = getUnitsByPageFormat(format);
  const sizeTmp = PageSizes[format];

  if (sizeTmp === undefined) {
    const msg = `Can't find dimensions for page size: ${format}`
    throw new Error(msg);
  }

  const size = (
    orientation === PageOrientationType.PORTRAIT ?
    [sizeTmp[0], sizeTmp[1]] :
    [sizeTmp[1], sizeTmp[0]]
  );

  const ret = [
    `${size[0]}${metricStr}`,
    `${size[1]}${metricStr}`,
  ];
  return ret;
}

export const getSizePx = (
  format: PageFormatType, 
  orientation: PageOrientationType
): Size => {
  const sizeTmp = PageSizes[format];
  
  if (sizeTmp === undefined) {
    const msg = `Can't find dimensions for page size: ${format}`
    throw new Error(msg);
  }

  const sizeOriented = (
    orientation === PageOrientationType.PORTRAIT ?
    [sizeTmp[0], sizeTmp[1]] :
    [sizeTmp[1], sizeTmp[0]]
  );

  let size = [0, 0] as Size;
  if (PageSizesMetric.includes(format)) {
    size[0] = DPI.mm2px(sizeOriented[0]);
    size[1] = DPI.mm2px(sizeOriented[1]);
  }

  if (PageSizesImperial.includes(format)) {
    size[0] = DPI.in2px(sizeOriented[0]);
    size[1] = DPI.in2px(sizeOriented[1]);
  }

  return size;
}

export const getBodySizePx = (format: PageFormatType, orientation: PageOrientationType, margins: PageMarginType) => {
  const pageSize = getSizePx(format, orientation);

  const marginTopPx    = DPI.any2px(margins.top, margins.units);
  const marginBottomPx = DPI.any2px(margins.bottom, margins.units);
  const marginLeftPx   = DPI.any2px(margins.left, margins.units);
  const marginRightPx  = DPI.any2px(margins.right, margins.units);
  
  const bodySize = [
    pageSize[0] - (marginLeftPx + marginRightPx),
    pageSize[1] - (marginTopPx + marginBottomPx),
  ];

  return bodySize;
}

export const getUnitsByPageFormat = (format: PageFormatType) => {
  if (PageSizesMetric.includes(format)) return 'mm';
  if (PageSizesImperial.includes(format)) return 'in';

  const msg = `Can't get metric string for size: ${format}`;
  throw new Error(msg);
}

export const getUnitsString = (unit: UnitsType) => {
  return UnitsSymbol[unit];
}

}
