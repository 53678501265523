import jtl from "tools/jtl";

import ShapeBaseComponent from "../shape-base";
import { ShapeProps } from "../../types";
import { ShapeCalcArrow } from "../../shapes-calculators/shape-calc-arrow";


interface Props extends ShapeProps {
  points: string[][];
}


export const ShapeArrowBaseComponent: React.FC<Props> = (props: Props) => {
  const {
    startPoint, 
    endPoint,
    style,
  } = props;

  const shapeCalc = new ShapeCalcArrow({
    startPoint,
    endPoint,
    style,
  });
  const c = shapeCalc;

  return (
    <ShapeBaseComponent
      width={c.widthTotal}
      height={c.heightTotal}
      startPoint={props.startPoint}
      endPoint={props.endPoint}
      layer={props.layer}
      style={props.style}
      points={props.points}
    />
  );
}
