import { useRecoilValue } from 'recoil';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import useBaseWatcher from './use-base-watcher';


const useSectionsEnabledWatcher = () => {
  const sectionsEnabled = useRecoilValue(UIState_Content.sectionsEnabled);
  useBaseWatcher([sectionsEnabled]);
}
  
export default useSectionsEnabledWatcher;
