import React from 'react';
import useAppLoading from 'app/ui-v2/app/hooks/use-app-loading';
import { LoadersTools } from '..';


interface Props {
  children: React.ReactNode;
}


export const LoaderLoadingFinishedComponent: React.FC<Props> = (props: Props) => {
  const {
    children
  } = props;

  const logger = LoadersTools.getLogger();
  logger.debug("Loader loading finished");

  useAppLoading({loading: false});

  return children;
}
