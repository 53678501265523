import styled from "styled-components";


const GridRow2 = styled.div<{column: number, row: number}>`
  grid-column: ${props => props.column + 1};
  grid-row: ${props => props.row + 1};

  height: 0px;
  position: relative;

  /* background: green; */
`;


export default GridRow2;
