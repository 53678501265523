import { atom}      from 'recoil';
import { selector } from 'recoil';
import { UserSettingsState as State } from 'app/arch/app/user';


/**
 * State
 */

export const state = atom<State.State>({
  key: "userSettings",
  default: State.createInitialState()
});



/**
 * Selectors
 */

export const theme = selector({
  key: `userSettings_theme`,
  get: ({ get }) => {
    const stateObj = get(state) ;
    return State.getTheme(stateObj);
  }
});

export const userLanguage = selector({
  key: `userSettings_userLanguage`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getUser(stateObj).language;
  }
});

export const user = selector({
  key: `userSettings_user`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getUser(stateObj);
  }
});

export const company = selector({
  key: `userSettings_company`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getCompany(stateObj);
  }
});

export const repoId = selector({
  key: `userSettings_repoId`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getRepo(stateObj).id;
  }
});

