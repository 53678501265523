import { atom }           from 'recoil';


/**
 * Signal columns widths change
 */

export const columnsWidths = atom({
  key: "ei_docContent_docCustomFieldsSignals_columnsWidths",
  default: {}
});

