import React from 'react';
import { IconContext } from 'react-icons';


interface Props {
  children: React.ReactNode;
}


export const IconStyleContextComponent: React.FC<Props> = (props: Props) => {
  const { 
    children
  } = props;


  return (
    <IconContext.Provider 
      value={{ 
        size: `50px`, 
      }}
    >
      { children }
    </IconContext.Provider>  
  );
}
  
