import styled from 'styled-components';
import { StyledBtnText } from 'app-views/styles';


export const BtnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${props => props.theme.defs.gap.small};
`;


export const BtnText = styled(StyledBtnText)`
  flex-grow: 1;
  height: 40px;
  border-radius: ${props => props.theme.defs.border.radius.normal};
  color: white;
  font-size: ${props => props.theme.defs.font.size.xlarge};

  &:not(:disabled) {
    font-weight: ${props => props.theme.defs.font.weight.medium};
    background-color: ${props => props.theme.defs.accentColor.primary};
  }

  &:not(:disabled):hover {
    background-color: ${props => props.theme.defs.accentColor.darker};
  }
`;


export const BtnCancel = styled(BtnText)`
  color: #888;
  &:hover {
    color: #fff;
  }

  &:not(:disabled) {
    background-color: #ddd;
  }
`;
