import React from 'react';
import HLS_MarkerViewSignComponent from 'app/ui-v2/editor-markers/hls/marker-views/marker-view-sign';
import { MarkerViewProps } from '../types';


interface Props extends MarkerViewProps {
}


export const MarkerViewSignComponent: React.FC<Props> = (props: Props) => {
  const {
    markerProps
  } = props;


  return (
    <HLS_MarkerViewSignComponent markerProps={markerProps} />
  );
}

