import { useRecoilValue } from 'recoil';
import { InfoActionsTools } from 'app/arch/app/info';
import { ActionComponent } from './components/action/Action';
import useActionsListenerRegistration from './hooks/use-actions-listener-registration';

import { UIInfoActions } from './states';

import { ActionsWrapper } from './styles';


interface Props {
}


export const ActionsComponent: React.FC<Props> = (props: Props) => {
  useActionsListenerRegistration();
  const actionsAddrs = useRecoilValue(UIInfoActions.addrs);
  
  if ( ! actionsAddrs.length ) {
    return null;
  }

  return (
    <ActionsComponentCore {...props} />
  );
}


export const ActionsComponentCore: React.FC<Props> = (props: Props) => {
  useActionsListenerRegistration();
  const actionsAddrs = useRecoilValue(UIInfoActions.addrs);
  
  const Actions = actionsAddrs.map(actionAddr => {
    const key = InfoActionsTools.getActionKey(actionAddr);

    return (
      <ActionComponent
        key={key}
        actionAddr={actionAddr} 
      />
    );
  });

  return (
    <ActionsWrapper>
      { Actions }
      {/* <ToastsTestComponent /> */}
    </ActionsWrapper>
  );
}
