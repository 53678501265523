import { useApp } from 'app/ui/hooks/app/use-app';
import { useSetRecoilState } from 'recoil';
import { UIUser_Settings }   from 'app/ui-v2/app/__modules/user/states';
import useUserSettings       from '../__modules/user/hooks/use-user-settings';


export interface HookProps {
}


export const useRepoFallbackSet = () => {
  const app = useApp();
  const userSettings = useUserSettings();
  const setUserSettings = useSetRecoilState(UIUser_Settings.state);

  const hook = () => {
    const reposPacks = app.repos.getReposPacks();
    
    if (reposPacks.length === 0) {
      userSettings.updateRepo({id: null});
      setUserSettings(userSettings.raw);
      return;
    }

    const repoPack = reposPacks[0];
    userSettings.updateRepo({id: repoPack.repo.id});
    setUserSettings(userSettings.raw);
  }

  return hook;
}
