import { ThemeDefProps } from "../types";


export const themeDefs_toolbar = (props: ThemeDefProps) => {
  const { colors, gap, border, padding, boxShadow } = props;

  const panel = {
    gap: gap.none,
    padding: padding.none,

    background: colors.background.primary,

    border: {
      style: 'none',
      color:  colors.border.primary,
      width:  border.width.normal,
      radius: border.radius.none,
    },
  };
    
  return panel;
}

