import { Position, Size } from "app/arch/types"

export enum FrameCtrlType {
  TOP_LEFT   = 'top_left',
  TOP_MIDDLE = 'top_middle',
  TOP_RIGHT  = 'top_right',

  MIDDLE_LEFT  = 'middle_left',
  MIDDLE_RIGHT = 'middle_right',

  BOTTOM_LEFT   = 'bottom_left',
  BOTTOM_MIDDLE = 'bottom_middle',
  BOTTOM_RIGHT  = 'bottom_right',

  DUMMY         = 'dummy'
};


export type FrameTargetUpdate = {
  control: FrameCtrlType,
  size?: Size,
  position?: Position,
  custom?: any,
};