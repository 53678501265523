import { atom } from 'recoil';
import { selector } from 'recoil';
import { PricesState as State } from 'app/arch/app/products/states/prices';


export const state = atom<State.State>({
  key: "products_prices",
  default: State.createInitialState()
});


/**
 * Selectors
 */

export const loaded = selector({
  key: `products_prices_loaded`,
  get: ({ get }) => {
    const stateObj = get(state) ;
    return State.getLoaded(stateObj);
  }
});
