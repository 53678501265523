import React from 'react';
import { Size } from 'app/arch/types';
import Context from './context';


interface Props {
  size: Size;
  children: React.ReactNode;
}


export const WindowContextComponent: React.FC<Props> = (props: Props) => {
  const {
    size,
    children,
  } = props;

  const context = { size };


  return (
    <Context.Provider value={context}>
      { children }
    </Context.Provider>
  );
}
