import styled from 'styled-components';

export const LINE_GRADIENT_COLOR = '#888';

export const LINE_GRADIENT = `
  transparent, 
  transparent 5px, 
  ${LINE_GRADIENT_COLOR} 5px, 
  ${LINE_GRADIENT_COLOR} 10px
`;

export const DRAGGER_SIZE = 10;

export const LineWrapperBase = styled.div`
  position: absolute;
  width: ${DRAGGER_SIZE}px;
  height: ${DRAGGER_SIZE}px;

  /* background-color: rgba(255, 0, 0, 0.05); */
`;


export interface StyledLineProps {
  $deltaX: number;
}

export const StyledLineBase = styled.div<StyledLineProps>`
  position: absolute;
  height: 100%;
  width: 100%;
`;
