import { UUID } from "app/arch/types";


//-----------
// Cell base
//
export interface Cell {
}

//------------
// Index Cell
//
export interface IndexCell extends Cell {
}

//------------
// Text Cell
//
export interface TextCell extends Cell {
  editorState: string | null;
}

export type CellTypes = (
    IndexCell
  | TextCell 
)

/**
 * Column
 */
export enum ColumnType {
  INDEX = "index",
  TEXT  = "text",
}

export type ColumnAddr = {
  releaselogId: UUID;
  columnId: UUID;
}

export interface ColumnProps {
  type: ColumnType;
  name: string;
  width: number;
  css: React.CSSProperties;
  header: {
    css: React.CSSProperties;
  }
}

export interface ColumnIndexProps extends ColumnProps {
}
  
export interface ColumnTextProps extends ColumnProps {
}

export type ColumnPropsTypes = (
  ColumnIndexProps   |
  ColumnTextProps
);

export type ColumnPropsUpdate = (
  Partial< 
    Omit< 
      ColumnProps, "type" | "css" | "header"
    > 
  >);


/**
 * Columns
 */
export type ColumnsAddrs = ColumnAddr[];

export type ColumnsProps = {
  [columnKey: string]: ColumnProps
}

export type Columns = {
  addrs: ColumnsAddrs,
  props: ColumnsProps,
}


/**
 * Row
 */
export type RowAddr = {
  releaselogId: UUID;
  rowId: UUID;
}

export type RowProps = {
}

export type RowPropsUpdate = (
  Partial<RowProps>
);


/**
 * Rows
 */
export type RowsAddrs = RowAddr[];

export type RowsProps = {
  [rowKey: string]: RowProps
}

export type Rows = {
  addrs: RowsAddrs,
  props: RowsProps,
}


/**
 * Cell
 */
export type CellAddr = {
  releaselogId: UUID;
  columnId: UUID;
  rowId: UUID;
}


/**
 * Cells
 */
export type Cells = {
  [cellKey: string]: CellTypes
}


/**
 * Releaselog
 */
export type ReleaselogAddr = {
  releaselogId: UUID
};

export type ReleaselogInfoDescription = {
  text: string | null;
  visible: boolean;
}

export type ReleaseInfoDescriptionUpdate = Partial<ReleaselogInfoDescription>;

export type ReleaselogInfo = {
  date: string | null;
  revision: number | null;
  description: ReleaselogInfoDescription;
}

export type ReleaseInfoUpdate = Partial<ReleaselogInfo>;

export type ReleaselogProps = {
  editable: boolean;
  info: ReleaselogInfo;
}

export type ReleaselogChangelog = {
  columns: Columns;
  rows:    Rows;
  cells:   Cells;
}


/**
 * Releaselogs
 */
export type ReleaselogsAddrs = ReleaselogAddr[];

export type ReleaselogsProps = {
  [releaselogKey: string]: ReleaselogProps
};

export type ReleaselogsChangelogs = {
  [releaselogKey: string]: ReleaselogChangelog
};

export type Releaselogs = {
  addrs: ReleaselogsAddrs,
  props: ReleaselogsProps,
  changelogs: ReleaselogsChangelogs;
};
