import { useDocState } from 'app/ui/contexts/document';


const useDNDDataGet = () => {
  const document = useDocState();

  const getData = () => {
    return document.editorSession.getDNDData();
  }


  return getData;
}


export default useDNDDataGet;