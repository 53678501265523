import React from "react";
import { useEffect } from "react";
import { DependencyList } from "react";
import { useDebouncedCallback } from "use-debounce";


const DEBOUNCE_DELAY = 1;


const useResizeObserver = (props: {
  elementRef: React.RefObject<HTMLElement>;
  onResize: (entries: ResizeObserverEntry[]) => void;
}, deps?: DependencyList) => {
  const { 
    elementRef,
    onResize,
  } = props;


  const observeHandler = useDebouncedCallback((entries: ResizeObserverEntry[]) => {
    onResize(entries);
  }, DEBOUNCE_DELAY);

  useEffect(() => {
    const element = elementRef.current;
    if ( ! element) {
      return;
    }

    const resizeObserver = new ResizeObserver(observeHandler);
    resizeObserver.observe(element);

    return () => {
      observeHandler.cancel();
      resizeObserver.unobserve(element);
      // resizeObserver.disconnect();
    };

  }, deps);
}


export default useResizeObserver;
