import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  gap: ${props => props.theme.defs.gap.large};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export const TimerWrapper = styled.div`
  display: inline-flex;
  min-width: 25px;
  max-width: 25px;
  justify-content: flex-end;
  padding-right: 3px;
  /* background-color: red; */
`;

