import styled from "styled-components";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Separator = styled.div`
  height: 8px;
  display: flex;
  
  border-style:  ${props => props.theme.button.border.style};
  border-width:  ${props => props.theme.button.border.width};
  border-color:  ${props =>props.theme.button.border.color.primary};
  border-radius: 0;
  
  background:   ${props => props.theme.defs.accentColor.primary};
`;

export const DummySeparator = styled.div`
  display: none;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
