import { useRecoilValue } from "recoil";
import { UIControlsDraggers } from "../states";


const useControlsDraggersEnabledWatch = () => {
  const enabled = useRecoilValue(UIControlsDraggers.enabled);
  return enabled;
}


export default useControlsDraggersEnabledWatch;