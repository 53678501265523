import styled from 'styled-components';

import TopToolbarSection from 'app/ui-v2/editor-instruction/styles/top-toolbar-section';
import TopToolbarButton from 'app/ui-v2/editor-instruction/styles/top-toolbar-button';


export const Button = styled(TopToolbarButton)`
  border-style: none;
`;

export const Section = styled(TopToolbarSection)<{$first?: boolean}>`
  border-left-style: ${props => props.$first ? 'solid': 'none'};
  border-right-style: solid;
`;
