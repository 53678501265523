import React from 'react';
import { Item, Text } from './styles';

interface Props {
  text: string;
  Icon: React.ElementType;
  onClick: () => void;
  selected: boolean;
}


export const ColumnItemTypeComponent: React.FC<Props> = (props: Props) => {
    const {
    text,
    Icon,
    selected,
    onClick
  } = props;

  return (
    <Item 
      onClick={onClick}
      selected={selected}
    >
      <Icon />
      
      <Text>
        { text }
      </Text>

    </Item>
  );
}
  
