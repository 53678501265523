import { useRecoilValue } from "recoil";
import { UIAppStates } from "../__modules/app-states/states";


const useUserIsLogged = () => {
  const isLogged = useRecoilValue(UIAppStates.isLogged);
  return isLogged;
}


export default useUserIsLogged;