import styled from 'styled-components';
import { TOP_BAR_HEIGHT } from './top-bar-wrapper';


const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - ${TOP_BAR_HEIGHT}px);

  overflow: clip;
`;

export default ContentWrapper;