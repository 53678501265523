import jtl from "tools/jtl";
import { PanelControlsConfig } from "lego/components/panel-controls/config"
import { PanelControlsItemSectionConfig } from "lego/components/panel-controls/config"
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"

import { InvoicesTypes } from "app/arch/app/user/states/invoices";
import { SubscriptionsTypes } from 'app/arch/app/user/states/subscriptions';

import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import PanelItemGenerator   from 'app/ui-v2/editor-instruction/panel-items/generator';
import useUserSubscriptions from 'app/ui-v2/app/__modules/user/hooks/use-user-subscriptions';
import useUserInvoices      from "app/ui-v2/app/__modules/user/hooks/use-user-invoices";

import useStates    from './use-states';
import useFunctions from './use-functions';


const useConfig = () => {
  const t = useTranslations();
  const states = useStates();
  const fns = useFunctions();

  const subscriptions = useUserSubscriptions();
  const invoices = useUserInvoices();


  const getInvoiceItems = (invoiceAddr: InvoicesTypes.InvoiceAddr, lastItem: boolean) => {
    const invoiceProps = invoices.getInvoiceProps(invoiceAddr);

    const item =  [
      {
        title: t('invoice, date'),
        type: PanelControlsItemSectionItemType.TEXT,
        value: jtl.epoch.toUTCDate(invoiceProps.created),
      },
      {
        title: t('invoice, status'),
        type: PanelControlsItemSectionItemType.TEXT,
        value: invoiceProps.paid ? t('invoice, paid') : t('invoice, unpaid'),
      },
      {
        type: PanelControlsItemSectionItemType.BUTTON,
        text: t('invoice, download'),
        onClick: () => { 
          fns.openInvoice(invoiceProps.urlHosted);
        },
      },
      {
        skip: lastItem,
        type: PanelControlsItemSectionItemType.SPACER,
      },     
    ];

    return item;
  }

  const getInvoicesConfig = (subAddr: SubscriptionsTypes.SubscriptionAddr) => {
    if ( ! states.invoicesLoaded ) {
      const subsection = {
        title: t('invoices'),
        items: [{
          type: PanelControlsItemSectionItemType.PROGRESS,
        }]
      }

      return subsection;
    }

    const invoicesAddrs = invoices.getInvoicesAddrs(subAddr);
    const itemsTmp = invoicesAddrs.map((invoiceAddr, idx)=> {
      const lastItem = (idx === (invoicesAddrs.length - 1));
      const items = getInvoiceItems(invoiceAddr, lastItem);
      return items;
    });

    const items = itemsTmp.flat();;

    const subsection = {
      title: t('invoices'),
      items,
    }

    return subsection
  }

  const getSubscriptionConfig = (subAddr: SubscriptionsTypes.SubscriptionAddr) => {
    const subProps = subscriptions.getSubscriptionProps(subAddr);
    // const Detail = subProps.cancelAtPeriodEnd ? DetailInactive : DetailActive;

    const invoicesSubsection = getInvoicesConfig(subAddr);
    const subscriptionType = fns.resolvePriceLookup(subProps.priceLookupKey);

    const config: PanelControlsItemSectionConfig = {
      subsections: [
      {
        title: t('subscription'),
        items: [
          {
            title: t("subscription start date"),
            type: PanelControlsItemSectionItemType.TEXT,
            value: jtl.epoch.toUTCDate(subProps.currentPeriodStart)
          },
          {
            skip: subscriptionType === null,
            title: t("subscription type"),
            type: PanelControlsItemSectionItemType.TEXT,
            value: subscriptionType || '',
          },
          {
            title: t("subscription payment period"),
            type: PanelControlsItemSectionItemType.TEXT,
            value: subProps.payPeriod,
          },
          {
            title: t("subscription price"),
            type: PanelControlsItemSectionItemType.TEXT,
            value: `${subProps.price} ${subProps.currency.toUpperCase()}`,
          },
          {
            disabled: fns.isUpdating(subAddr),
            skip: ! subProps.cancelAtPeriodEnd,
            title: t("subscription expiry date"),
            type: PanelControlsItemSectionItemType.TEXT,
            value: jtl.epoch.toUTCDate(subProps.cancelAt ?? 0),
          },
          {
            disabled: fns.isUpdating(subAddr),
            skip: subProps.cancelAtPeriodEnd,
            title: t("subscription next payment"),
            type: PanelControlsItemSectionItemType.TEXT,
            value: jtl.epoch.toUTCDate(subProps.currentPeriodEnd),
          },
          PanelItemGenerator.getToggleOnOff({
            disabled: fns.isUpdating(subAddr),
            title: t('subscription auto payment'),
            selected: ! subProps.cancelAtPeriodEnd,
            onClick: () => {
              fns.cancelSubscriptionAtEnd(subAddr, ! subProps.cancelAtPeriodEnd);
            },
          }),
        ],
      },
      invoicesSubsection,
    ]}
  
    return config;
  }

  const getConfig = (): PanelControlsConfig => {
    const sections = states.subscriptionsAddrs.map(subscriptionAddr => getSubscriptionConfig(subscriptionAddr));
    const config: PanelControlsConfig = {
      items: [{
        title: t('payments'),
        sections
      }]
    } 
  
    return config;
  }

  return getConfig;
};


export default useConfig;