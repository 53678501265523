import styled from "styled-components";
import DivButton from "lego/styles/div_button";


const Item = styled(DivButton)<{open: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;  
  gap: ${props => props.theme.defs.gap.small};
  
  box-sizing: border-box;
  padding: ${props => props.theme.buttonText.padding};

  ${(props) => {
    const { selected, open, theme } = props;
    
    let color;
    let background;
    let borderColor;

    if (! selected && ! open) {
      color = theme.button.color.primary;
      background = 'none';
      borderColor = 'transparent';
    } 
    else if (selected) {
      color = theme.button.color.selected;
      background = theme.button.background.selected;
      borderColor = theme.button.border.color.selected;
    } 
    else if (open) {
      color = theme.button.color.selected;
      background = theme.button.background.hover;
      borderColor = theme.button.border.color.hover;
    }

    return `
      color: ${color};
      background: ${background};
      border-color: ${borderColor};
    `;
  }};
`;


export default Item;