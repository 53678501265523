import React from 'react';
import { useRecoilValue } from 'recoil';

import * as SlicerTypes from 'app/arch/editor-instruction/document/slicers/releaselogs/types';
import { ReleaselogsTypes } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';
import { ReleaselogsTools } from 'app/arch/editor-instruction/document/states/persistent/releaselogs';

import { useDocState } from 'app/ui/contexts/document';
import { UIState_Releaselogs } from 'app/ui/states/editor-instruction';

import { RowProps } from '../types';

import GridCellComponent from './grid-cell';
import { CellIdx } from './types';


interface Props extends RowProps {
}


export const RowCellComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
    releaselogId,
  } = props;

  const rowConfig = item as SlicerTypes.ChangelogItem_Row;
  const rowAddr: ReleaselogsTypes.RowAddr = {
    releaselogId,
    rowId: rowConfig.rowId
  }

  const rowPresent = useRecoilValue(UIState_Releaselogs.rowPresent(rowAddr));

  if ( ! rowPresent ) {
    return null;
  }

  return (
    <RowPresentCellComponent {...props} />
  );
}
  

/**
 * 
 * RowPresentCellComponent
 * 
 */

export const RowPresentCellComponent: React.FC<Props> = (props: Props) => {
  const {
    item,
    releaselogId,
    rowIdx,
    lastPageRow,
  } = props;

  const document = useDocState();
  const releaselogAddr = { releaselogId };

  const columnsAddrs = document.releaselogs.getColumnsAddrs(releaselogAddr);
  
  const rowConfig = item as SlicerTypes.ChangelogItem_Row;

  //-------------
  // Cells 
  const renderCell = (
    cellAddr: ReleaselogsTypes.CellAddr, 
    cellIdx: CellIdx,
    lastRow: boolean,
  ) => {
    const key = ReleaselogsTools.getCellKey(cellAddr);
    const firstCellInRow = (cellIdx.columnIdx === 0);

    return (
      <GridCellComponent
        key={key}
        cellAddr={cellAddr}

        firstCellInRow={firstCellInRow}
        lastRow={lastRow}
        lastPageRow={lastPageRow}
      />
    );
  }

  const renderRow = () => {
    const rowId = rowConfig.rowId;
    const rowAddr = { 
      releaselogId,
      rowId
    }

    const lastRow = document.releaselogs.isRowLast(rowAddr);

    const CellsComps = columnsAddrs.map((columnAddr, columnIdx) => {
      const columnId = columnAddr.columnId;
      const cellAddr = { 
        releaselogId, 
        columnId, 
        rowId 
      };
      const cellIdx  = { columnIdx, rowIdx };

      const CellComp = renderCell(cellAddr, cellIdx, lastRow);
      return CellComp;
    });

    return CellsComps;
  }

  return renderRow();
}
  
