import styled from "styled-components";
import ButtonToolbar from "lego/styles/button_toolbar";


export const StyledBtnColorBase = styled(ButtonToolbar)`
  position: relative;
`;

export const ColorBar = styled.div`
  position: absolute;
  top: calc(100% - 10px);
  left: 5px;
  height: 5px;
  width: 20px;
  
  pointer-events: none;
`;