import { MenubarTypes } from 'lego-v2/menubar';
import useSystemKeys from 'lego/components/key-bindings/hooks/use-system-keys';
import { KeyBindingPriority } from 'lego/components/key-bindings/arch/types';

import { Icon } from 'app/ui/icons/icons';
import { useTranslations } from 'app/ui/hooks/app/use-translation';

import * as Types from './types';
import useFunctions from './use-functions';


export interface Props {
  closeMenuRef: Types.CloseMenuRef
}


const useConfig = ({ closeMenuRef }: Props) => {
  const t = useTranslations();
  const fns = useFunctions({closeMenuRef});
  const systemKeys  = useSystemKeys();


  const getConfig = () => {
    const config: MenubarTypes.Config = {
      items: [
        {
          title: t("marker"),
          dataTest: 'menu-marker',
          panel: {
            sections: [
              {
                items: [
                  {
                    type: "item",
                    title: t("marker, new"),
                    dataTest: 'menu-marker-item-marker-new',
                    icon: Icon.Add,
                    onClick: (event: React.MouseEvent) => { 
                      fns.handleMarkerCreate();
                    },
                    // keysLabel: `${systemKeys.action}M` ,
                    // keysBinding: (event: any) => (event.ctrlKey || event.metaKey) && ! event.shiftKey && event.key === 'm',
                  },
                  {
                    type: "item",
                    title: t("marker, duplicate"),
                    dataTest: 'menu-marker-item-marker-duplicate',
                    icon: Icon.Duplicate,
                    onClick: (event: React.MouseEvent) => { 
                      fns.handleMarkerDuplicate();
                    },
                  },                  
                ]
              },
              {
                items: [
                  {
                    type: "item",
                    title: t("exit"),
                    icon: Icon.Close,
                    onClick: (event: React.MouseEvent) => { 
                      fns.handleExit();
                    },
                    keysLabel: `${systemKeys.shiftSymbol}${systemKeys.actionSymbol}X` ,
                    keysBindingCheck: (event: any) => (event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'x',
                    keysBidningPriority: KeyBindingPriority.EDITOR_MARKER
                  },
                ]
              },                    
            ]
          }
        },

        {
          title: t("edit"),
          panel: {
            sections: [
              {
                items: [
                  {
                    type: "item",
                    title: t("undo"),
                    icon: Icon.Undo,
                    onClick: (event: React.MouseEvent) => { 
                      fns.handleUndo();
                    },
                    keysLabel: `${systemKeys.actionSymbol}Z` ,
                    keysBindingCheck: (event: React.KeyboardEvent) => (event.ctrlKey || event.metaKey) && ! event.shiftKey && event.key === 'z',
                    keysBidningPriority: KeyBindingPriority.EDITOR_INSTRUCTION
                  },
                  {
                    type: "item",
                    title: t("redo"),
                    icon: Icon.Redo,
                    onClick: (event: React.MouseEvent) => { 
                      fns.handleRedo();
                    },
                    keysLabel: `${systemKeys.shiftSymbol}${systemKeys.actionSymbol}Z` ,
                    keysBindingCheck: (event: React.KeyboardEvent) => (event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'z',
                    keysBidningPriority: KeyBindingPriority.EDITOR_INSTRUCTION
                  },
                ]
              }
            ]
          }
        },
      ]
    }

    return config;
  }
  
  return getConfig;
};


export default useConfig;