import { createContext } from 'react';

import EditorInstruction from 'app/arch/editor-instruction';

export interface EditorInstructionContextProps {
  editorInstruction: EditorInstruction,
}


export const EditorInstructionContext = createContext<EditorInstructionContextProps | null>(null);
