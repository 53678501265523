import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import jtl from 'tools/jtl';

import { settings }             from 'app/configs';
import { useDocState }          from 'app/ui/contexts/document';
import useColumnImagesAutoScale from 'app/ui-v2/editor-instruction/views/view-content/hooks/use-column-images-auto-scale';
import useEditorStatesSetters   from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';

import useColumnSelected from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/column/use-column-selected';
import { UIState_Content } from 'app/ui/states/editor-instruction';
import { ContentTypes  as TypesContent } from 'app/arch/editor-instruction/document/states/persistent/content';



const useFunctions = () => {
  const document = useDocState();

  const selectedColumnAddr = useColumnSelected();
  const columnCSS = useRecoilValue(UIState_Content.columnCSS(selectedColumnAddr)) || {};
  const columnHeaderCSS = useRecoilValue(UIState_Content.columnHeaderCSS(selectedColumnAddr)) || {};

  const columnImagesAutoScaleHeight = useColumnImagesAutoScale({fitHeight: true});
  const columnImagesAutoScaleWidth  = useColumnImagesAutoScale({fitWidth: true});
    
  const {
    setContent
  } = useEditorStatesSetters();
  

  /**
   * 
   * Column Header
   * 
   */

  const columnHeader__updateCSS = (cssUpdate: React.CSSProperties) => {
    document.content.updateColumnHeaderCSS(selectedColumnAddr !, cssUpdate);
    document.saveUndo();
    setContent();
  }

  const columnHeader__checkCSS = (cssProps: React.CSSProperties) => {
    return jtl.css.hasProps(columnHeaderCSS, cssProps);
  }


  /**
   * 
   * Column
   * 
   */

  const column__handleCSSUpdate = (updateCSS: React.CSSProperties) => {
    document.content.updateColumnCSS(selectedColumnAddr !, updateCSS);
    document.saveUndo();
    setContent();
  }

  const column__handleCSSSliderUpdate = (updateCSS: React.CSSProperties) => {
    document.content.updateColumnCSS(selectedColumnAddr !, updateCSS);
    setContent();
  }

  const column__handleCSSSliderUpdateDone = (updateCSS: React.CSSProperties) => {
    column__handleCSSSliderUpdate(updateCSS);
    document.saveUndo();
  }

  const column__checkCSS = (cssProps: React.CSSProperties) => {
    return jtl.css.hasProps(columnCSS, cssProps);
  }

  const column__isDirectionRow = () => {
    return column__checkCSS({
      flexDirection: 'row'
    });
  } 

  const column__isDirectionColumn = () => {
    return column__checkCSS({
      flexDirection: 'column'
    });
  } 

  const column__getCSSFontSize = () => {
    return __getColumnCSS('fontSize', settings.printView.cell_index.font.size.default);
  }

  const column__getCSSFontWeight = () => {
    return __getColumnCSS('fontWeight', settings.printView.cell_index.font.weight.default * 100) / 100;
  }

  const column__getCSSGap = () => {
    return __getColumnCSS('gap', settings.printView.images.gap.default);
  }

  const column__getCSSPadding = () => {
    return __getColumnCSS('padding', settings.printView.cell.padding.default);
  }

  const __getColumnCSS = (cssAttr: string, fallback: number) => {
    const value = jtl.css.getValue(columnCSS, cssAttr);
    const valueInPx = jtl.css.valueToNumber(value, fallback);
    return valueInPx;
  }



  /**
   * 
   * Column Images
   * 
   */

  const columnImages_updateProps = (
    update: TypesContent.ColumnImagesPropsUpdate,
    skipUndoSave?: boolean,
  ) => {
    document.content.columnImages_updateColumn(
      selectedColumnAddr !,
      update
    );

    if ( ! skipUndoSave)  {
      document.saveUndo();
    }
    setContent();
  }




// TODO

  const columnImages__handleImagesSetHeightStart = () => {
    if (selectedColumnAddr === null) { 
      console.warn(`Selected column id is null`);
      return; 
    }

    // columnImagesAutoScaleHeight.onChangeStart(selectedColumnAddr);
  }

  const columnImages__handleImagesSetHeightChange = (value: number) => {
    columnImagesAutoScaleHeight.onChange(value);
  }

  const columnImages__handleImagesSetHeightDone = (value: number) => {
    // columnImagesAutoScaleHeight.onChangeDone(value);
  }

  const columnImages__handleImagesSetWidthStart = () => {
    if (selectedColumnAddr === null) { 
      console.warn(`Selected column id is null`);
      return; 
    }

    // columnImagesAutoScaleWidth.onChangeStart(selectedColumnAddr);
  }

  const columnImages__handleImagesSetWidthChange = (value: number) => {
    columnImagesAutoScaleWidth.onChange(value);
  }

  const columnImages__handleImagesSetWidthDone = (value: number) => {
    columnImagesAutoScaleWidth.onChangeDone(value);
  }


  const getColumnImagesViewScaleWidth = () => {
    if (selectedColumnAddr === null) { 
      console.warn(`Selected column addr is null`);
      return; 
    }

    // const viewScale = columnImagesAutoScaleWidth.getImagesViewScale(selectedColumnAddr);
    // return viewScale;
    return 2;
  }

  const getColumnImagesViewScaleHeight = () => {
    if (selectedColumnAddr === null) { 
      console.warn(`Selected column id is null`);
      return; 
    }

    return 2;
    // const viewScale = columnImagesAutoScaleHeight.getImagesViewScale(selectedColumnAddr);
    // return viewScale;
  }


  return {
    /**
     * Column Header
     */

    columnHeader__updateCSS,
    columnHeader__checkCSS,


    /**
     * Column
     */

    column__handleCSSUpdate,
    column__handleCSSSliderUpdate,
    column__handleCSSSliderUpdateDone,
    
    column__checkCSS,
    column__isDirectionRow,
    column__isDirectionColumn,

    column__getCSSFontSize,
    column__getCSSFontWeight,

    column__getCSSGap,
    column__getCSSPadding,
    
    
    /**
     * Column Images
     */

    columnImages_updateProps,

    

    // 
    // TODO
    //
    columnImages__handleImagesSetHeightStart,
    columnImages__handleImagesSetHeightChange,
    columnImages__handleImagesSetHeightDone,

    columnImages__handleImagesSetWidthStart,
    columnImages__handleImagesSetWidthChange,
    columnImages__handleImagesSetWidthDone,

    getColumnImagesViewScaleWidth,
    getColumnImagesViewScaleHeight,
    // 
    // TODO END
    // 
  }
}


export default useFunctions;