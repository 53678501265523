import styled from 'styled-components';


export const AutoHeightWrapper = styled.div`
  flex: 0 0 auto;
  /* background: rgba(0, 255, 0, 0.2); */
`;

export const MembersWrapper = styled.div`
  flex: 0 1 auto;
  min-height: 100px;
  /* background: rgba(0, 255, 0, 0.2); */
`;
