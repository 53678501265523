import produce from 'immer';

import * as Tools from './tools';
import * as Types from './types';
import * as State from './state';
import { PricePlansTypes } from '../price-plans';


export class PricePlansSession {
  private _state: State.State;

  constructor() {
    this._state = State.createInitialState();
  }

  reset() {
    const logger = Tools.getLogger();
    logger.debug("Reseting price plans session");
    this._state = State.createInitialState();
  }

  get raw() { return this._state; }


  /**
   * Setters
   */

  setBillingPeriod(period: PricePlansTypes.BillingPeriod) {
    this._state = produce(this._state, draft => {
      draft.billingPeriod = period;
    });
    return this._state;
  }


  /**
   * Getters
   */
  getBillingPeriod() {
    return State.getBillingPeriod(this._state);
  }
}
