import styled from "styled-components";


export const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 24px;
  
  border-style:  ${props => props.theme.button.border.style};
  border-width:  ${props => props.theme.button.border.width};
  border-color:  ${props =>props.theme.button.border.color.primary};
  border-radius: 0;
  
  background:   ${props => props.theme.button.background.primary};
  font-size:    ${props => props.theme.defs.font.size.normal};
  color: ${props => props.theme.button.color.primary};

  &:not(:last-child) {
    border-bottom-width: 0;
    border-bottom-style: none;
  }
`;

export const ColumnItem = styled.div`
  flex: 1 1 auto;
  min-width: 0px; 
  width: 0px;
`;

export const ColumnButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
`;

