import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { UIProducts_Prices } from 'app/ui-v2/app/__modules/products/states';

import ViewPricingLayoutComponent  from './components/view-pricing-layout';
import PricePlansComponent         from './components/price-plans';
import PricesLoadProgressComponent from './components/prices-load-progress';
import usePricesLoad               from 'app/ui-v2/app/__modules/products/hooks/use-prices-load';


interface Props {
}


export const ViewPricingV2Component: React.FC<Props> = (props: Props) => {
  const pricesLoaded = useRecoilValue(UIProducts_Prices.loaded);
  const loadPrices = usePricesLoad();

  useEffect(() => {
    if (pricesLoaded) {
      return;
    }

    loadPrices();
  }, []);

  if ( ! pricesLoaded ) {
    return (
      <PricesLoadProgressComponent />
    );
  }

  return (
    <ViewPricingLayoutComponent>
      <PricePlansComponent />
    </ViewPricingLayoutComponent>
  );
}

