import React from 'react';

import { PanelType } from '../side-controls/types';

import { PanelControlsConfig }    from './config';
import PanelItemComponent__narrow from './skins/narrow/panel-item';
import PanelItemComponent__wide   from './skins/wide/panel-item';
import { unpackBoolean }          from './tools';

import { MainWrapper }    from './styles';
import { PaddingWrapper } from './styles';


interface Props {
  config: PanelControlsConfig;
  panelType: PanelType;
}


export const PanelControlsComponent: React.FC<Props> = (props: Props) => {
  const { 
    config, 
    panelType,
  } = props;

  const panelDisabled = unpackBoolean(config.disabled);

  const PanelTypeToItemMapper: {[key in PanelType]: any} = {
    [PanelType.NARROW_190]: PanelItemComponent__narrow,
    [PanelType.NARROW_230]: PanelItemComponent__narrow,

    [PanelType.WIDE_320]: PanelItemComponent__wide,
    [PanelType.WIDE_360]: PanelItemComponent__wide,
    [PanelType.WIDE_390]: PanelItemComponent__wide,
  }

  const PanelItem = PanelTypeToItemMapper[panelType];

  const renderItems = () => {
    return config.items.map((item, idx) => (
      <PanelItem
        key={`${idx}`}
        config={item}
      />
    ));
  }

  return (
    <PaddingWrapper $disabled={panelDisabled}>
      <MainWrapper>
        { renderItems() }
      </MainWrapper>
    </PaddingWrapper>
  );
}
