import { Position } from 'app/arch/types';
import { Size } from 'app/arch/types';
import { settings } from 'app/configs';
import * as Types from './types';


export const getWindow = (): Types.Window => ({
  visible: false,
  maximized: true,

  size: [300, 300] as Size,
  position: [100, 100] as Position,
});


export const getWindowSizeMin = (): Size => settings.window.size.min;


export const getViewDefiner = (): Types.ViewDefiner => ({
  autoFit: {
    autoMode:  Types.ViewDefinerAutomationMode.AUTOMATIC,
    fitType: Types.ViewDefinerFitType.FIT_TO_ALL,
  },
  overlayer: {
    showType: Types.ViewDefinerShowType.LINES_AND_OVERLAYERS,
    resizerLocked: false,
  }
});
