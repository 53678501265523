import * as Defaults from './defaults';
import * as Types from './types';


/**
 * State
 */

export type State = {
  size: Types.Size,
  sizeVariant: Types.SizeVariant,
}

export const getSize = (
  state: State
): Types.Size => { 
  const size = state.size
  return size;
}

export const getSizeVariant = (
  state: State
): Types.SizeVariant => { 
  const sizeVariant = state.sizeVariant
  return sizeVariant;
}


/**
 * Initial state
 */

export const createInitialState = (): State => {
  const initState: State = {
    size: Defaults.getSize(),
    sizeVariant: Defaults.getSizeVariant(),
  }
  
  return initState;
}
