import React from 'react';
import { PageItemsProps } from '../types';
import DocInfoComponent from './doc-info';


interface Props extends PageItemsProps {
}


export const PageItemDocInfoComponent: React.FC<Props> = (props: Props) => {
  
  return (
    <DocInfoComponent />
  );
}
  
