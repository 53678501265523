
export const getDataTest = (
  panelName: string,
  itemName: string
) => {
  return (
    `editor-image`
    + `__menu-properties`
    + `__${panelName}`
    + `__${itemName}`
  );
}