import styled from "styled-components";

export const PanelItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.defs.gap.xsmall};

  border-radius: ${props => props.theme.input.border.radius};
  border-color:  ${props => props.theme.input.border.color};
  border-width:  ${props => props.theme.input.border.width};
  
  &:focus-visible {
    outline-style: ${props => props.theme.attrs.focusVisible.outline.style};
    outline-width: ${props => props.theme.attrs.focusVisible.outline.width};
    outline-color: ${props => props.theme.attrs.focusVisible.outline.color};
  }

`;

export default PanelItem;