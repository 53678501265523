import i18next from 'i18next';
import { PanelControlsConfig, PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import { GetConfigProps } from "./types";
import { settings } from 'app/configs';
import environment from 'app/environment';



export const getConfigSmartLines = (props: GetConfigProps) => {
  const { cmds, states } = props;
  const t = i18next.t;
  const disabled = false;


  const __stickyLines_enabled = () => (
    {
      // disabled: true,
      title: t('sticky'),
      type: PanelControlsItemSectionItemType.BUTTON_GROUP,
      buttons: [
        {
          text: t('off'),
          value: false,
          selected: ! states.smartLines.stickyLines.sticky,
        },
        {
          text: t('on'),
          value: true,
          selected: states.smartLines.stickyLines.sticky,
        },
      ],
      onClick: cmds.onSmartLinesSticky
    }
  );

  const __stickyLines_debug = () => (
    {
      skip: ! environment.debug,
      title: 'src sticky debug',
      type: PanelControlsItemSectionItemType.BUTTON_GROUP,
      buttons: [
        {
          text: t('off'),
          value: false,
          selected: ! states.smartLines.srcLines.visible,
        },
        {
          text: t('on'),
          value: true,
          selected: states.smartLines.srcLines.visible,
        },
      ],
      onClick: cmds.onSmartLinesSrcLinesVisible
    }
  );

  const __stickyLines_stickyDistance = () => (
    {
      title: t('sticky distance'),
      type: PanelControlsItemSectionItemType.SLIDER,
      onChange: cmds.onSmartLinesStickynessChange,
      onChangeDone: cmds.onSmartLinesStickynessChangeDone,
      step: 1,
      min: 1,
      max: 20,
      value: states.smartLines.stickyLines.stickyness,
    }
  );

  const config: PanelControlsConfig = {
    disabled: disabled,
    items: [
      {
        title: t('smart lines'),
        sections: [
          {
            title: t('sticky lines'),
            items: [
              __stickyLines_enabled(),
              __stickyLines_debug(),
              __stickyLines_stickyDistance(),
            ]
          },
          {
            title: t('aligment lines'),
            items: [
              {
                title: t('visible'),
                type: PanelControlsItemSectionItemType.BUTTON_GROUP,
                buttons: [
                  {
                    text: t('off'),
                    value: false,
                    selected: ! states.smartLines.aligmentLines.visible,
                  },
                  {
                    text: t('on'),
                    value: true,
                    selected: states.smartLines.aligmentLines.visible,
                  },
                ],
                onClick: cmds.onSmartLinesAligmentLinesVisible
              },
            ]
          }          
        ]
      },
      
    ]
  } // config

  return config;
}

export default getConfigSmartLines;