/**
 * Panel Settings
 */

export type View = {
  selected: ViewItem,
  params: ViewParams,
}


export enum ViewItem {
  WELCOME = 'welcome',
  DEMO    = 'demo',
  PRICING = 'pricing',

  USER_LOGIN  = 'user-login',
  USER_SIGNUP = 'user-sign-up',
  USER_SIGNUP_DONE   = 'user-sign-up-done',
  USER_SIGNUP_FAILED = 'user-sign-up-failed',

  PASSWORD_RESET_REQUEST_FORM = 'password-reset-request-form',
  PASSWORD_RESET_LINK_SENT    = 'password-request-link-sent',
  PASSWORD_RESET_FORM         = 'password-reset-form',

  LEGAL_TERMS_OF_SERVICE = 'legal-terms-of-service',
  LEGAL_PRIVACY_POLICY   = 'legal-privacy-policy'
};


export type ViewUpdate = Partial<View>;


/**
 * ViewParams
 */
export type ViewParams = (
    ViewParamsUserSignup
  | ViewParamsPasswordReset
  | ViewParamsPasswordResetRequest
  | ViewParamsUserSignupFailed
  | ViewParamsBlank
);

export type ViewParamsBlank = null;

export type ViewParamsUserSignup = {
  email: string,
}

export type ViewParamsPasswordResetRequest = {
  email: string,
}

export type ViewParamsPasswordReset = {
  email: string,
  token: string
}

export type ViewParamsUserSignupFailed = {
  error: string,
}
