import React from 'react';
import { useRecoilValue } from 'recoil';

import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { UIState_Content } from 'app/ui/states/editor-instruction';

import { Border } from './styles';


interface Props {
  imageAddr: ContentTypes.ImageAddr;
  children: React.ReactNode;
}


export const ImageBorderComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children,
  } = props;

  const imageBorderCSS = useRecoilValue(UIState_Content.columnImages_imageBorderCSS(imageAddr));
  const css = imageBorderCSS || {};

  return (
    <Border style={css}>
      { children }
    </Border>
  );
}
