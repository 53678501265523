import { RefObject } from "react";


export interface Props {
  editorTextRef: RefObject<HTMLDivElement | null>;
}


const useCaretOnPoint = (props: Props) => {
  const {
    editorTextRef,
  } = props;


  const __getRange = (x: number, y: number) => {
    const documentTypeless = document as any;

    // For WebKit browsers (Chrome, Safari, older versions)
    if (document.caretRangeFromPoint) {
      console.debug('WebKit - caretRangeFromPoint')
      const range = document.caretRangeFromPoint(x, y);

      return range;
    } 

    // For Firefox
    if (documentTypeless.caretPositionFromPoint) {
      console.debug('FireFox - caretPositionFromPoint')
      const position = documentTypeless.caretPositionFromPoint(x, y);
      const range = documentTypeless.createRange();
      range.setStart(position.offsetNode, position.offset);
      range.collapse(true);

      return range;
    } 

    // For Internet Explorer
    if (documentTypeless.body.createTextRange) {
      console.debug('IE - createTextRange')
      const range = documentTypeless.body.createTextRange();
      range.moveToPoint(x, y);

      return range;
    }

    return null;
  }


  const __setCaretOnPoint = (x: number, y: number): boolean => {
    const range = __getRange(x, y);
    if ( ! range ) {
      return false;
    }

    const selection = window.getSelection();
    if (! selection) {
      return false;
    }

    selection.removeAllRanges();
    selection.addRange(range);

    return true;
  }


  const __setCaretOnEnd = () => {
    const editor = editorTextRef.current;
    if (! editor ) {
      const msg = `EditorText is not ready`;
      console.error(msg);
      return;
    }

    const range = document.createRange();
    range.selectNodeContents(editor);

    // Beginning of text
    // range.collapse(true);

    // End of text
    range.collapse(false);
  
    // Clear existing selection and set the new range
    const selection = window.getSelection();
    if (! selection ) {
      const msg = `Failed to create selection`;
      console.error(msg);
      return;
    }

    selection.removeAllRanges();
    selection.addRange(range);
  }


  const setCaretOnPoint = (x: number, y: number) => {
    const editorText = editorTextRef.current;
    if (! editorText) {
      console.error('Editor text not ready');
      return; 
    }

    let counter = 0;
    const INTERVAL_DELAY = 10;
    const INTERVAL_TIMEOUT = 8; // seconds
    const INTERVAL_COUNT_MAX = INTERVAL_TIMEOUT * 1000 / INTERVAL_DELAY;

    const intervalId = setInterval(() => {
      if (counter++ >= INTERVAL_COUNT_MAX) {
        clearInterval(intervalId);
        return;
      }

      const editable = editorText.contentEditable === 'true';
      if (editable) {
        clearInterval(intervalId);

        const ok = __setCaretOnPoint(x, y);
        if (ok) {
          console.debug("Caret text placed OK");
        }
        else {
          console.debug("Caret text placed failed, try fallback");
          __setCaretOnEnd();
        }
      }
    }, INTERVAL_DELAY);
  }

  return setCaretOnPoint;
}


export default useCaretOnPoint;





// const placeCaretAtMousePosition = (event: React.MouseEvent) => {
  // const { clientX, clientY } = event;
  // const range = document.createRange();
  // const selection = window.getSelection();
  // if (selection === null) {
  //   return;
  // }


  // selection.removeAllRanges();

  // const element = editorTextRef?.current;
  // if (! element ){
  //   return;
  // }

  // const { left, top } = element.getBoundingClientRect();
  // const x = clientX - left;
  // const y = clientY - top;

  // let node = document.elementFromPoint(clientX, clientY);
  // if (!node) return;

  // if (node.nodeType === Node.TEXT_NODE) {
  //   range.setStart(node, 0);
  //   range.setEnd(node, node.length);
  // } else {
  //   const childNodes = Array.from(node.childNodes);
  //   let closestNode = null;
  //   let minDistance = Infinity;

  //   childNodes.forEach((child) => {
  //     const { left, top, right, bottom } = child. .getBoundingClientRect();
  //     const dx = x - (left + (right - left) / 2);
  //     const dy = y - (top + (bottom - top) / 2);
  //     const distance = dx * dx + dy * dy;

  //     if (distance < minDistance) {
  //       minDistance = distance;
  //       closestNode = child;
  //     }
  //   });

  //   if (closestNode) {
  //     node = closestNode;
  //     if (node.nodeType === Node.TEXT_NODE) {
  //       range.setStart(node, 0);
  //       range.setEnd(node, node.length);
  //     } else {
  //       range.setStart(node, 0);
  //       range.setEnd(node, node.childNodes.length);
  //     }
  //   }
  // }

  // range.collapse(true);
  // selection.addRange(range);
// };