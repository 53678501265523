import { useTranslations } from 'app/ui/hooks/app/use-translation';
import useItems from './__use-items';


const useConfigSection = () => {
  const t = useTranslations();
  const items = useItems();

  const section = {
    subsections: [
      {
        title: t('custom rows'),
        items: [
          items.getVisible(),
        ]
      },
    ],
  }
  
  return section
}


export default useConfigSection;