import React      from 'react';
import { useRef } from 'react';

import { ContentTools } from 'app/arch/editor-instruction/document/states/persistent/content';
import { ContentTypes } from 'app/arch/editor-instruction/document/states/persistent/content';
import { useDocState }  from 'app/ui/contexts/document';

import ImageIdComponent          from './image-id';
import ImageDropComponent        from './image-drop';
import ImagePreviewComponent     from './image-preview';
import ImageKeyBindingsComponent from './image-key-bindings';
import ImageContextMenuComponent from './image-context-menu';
import ImageSelectComponent      from './image-select';
import ImageEditComponent        from './image-edit';
import ImageDraggerComponent     from './image-dragger';
import ImageSelectedComponent    from './image-selected';
import ImageBorderComponent      from './image-border';
import ImageIdxComponent         from './image-idx';

import { ContainerTop } from './styles';


interface Props  {
  imageAddr: ContentTypes.ImageAddr;
}


export const ImageComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
  } = props;

  const docState = useDocState();
  const containerTopRef = useRef<HTMLDivElement>(null);

  const prefix = (
    `doc-editor`
    + `__view-content`
    + `__cell-images`
    + `__image`
  );

  const dataTest = ContentTools.getImageDataTest({
    prefix,
    docState,
    imageAddr
  });

  return (
    <ContainerTop 
      ref={containerTopRef} 
      data-test={dataTest}
    >
      <ImageIdComponent imageAddr={imageAddr} >
        <ImageSelectComponent imageAddr={imageAddr} >
          <ImageKeyBindingsComponent imageAddr={imageAddr} >
            <ImageContextMenuComponent imageAddr={imageAddr} >
              <ImageDropComponent  imageAddr={imageAddr} >
                <ImageEditComponent imageAddr={imageAddr} >
                  <ImageSelectedComponent imageAddr={imageAddr} >
                    <ImageIdxComponent imageAddr={imageAddr} >
                      <ImageBorderComponent imageAddr={imageAddr} >
                        <ImageDraggerComponent imageAddr={imageAddr} >
                          <ImagePreviewComponent 
                            imageAddr={imageAddr} 
                            resizerContainerRef={containerTopRef}
                          />
                        </ImageDraggerComponent>
                      </ImageBorderComponent>
                    </ImageIdxComponent>
                  </ImageSelectedComponent>
                </ImageEditComponent>
              </ImageDropComponent>
            </ImageContextMenuComponent>
          </ImageKeyBindingsComponent>
        </ImageSelectComponent>
      </ImageIdComponent>

    </ContainerTop>
  );
}
