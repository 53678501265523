import { useAuth } from "services/auth";
import useGuestGoHome from "../home-page-guest/hooks/use-guest-go-home";
import useUserGoHome  from "../home-page-user/hooks/user-user-go-home";


const useGoHome = () => {
  const userGoHome = useUserGoHome();
  const guestGoHome = useGuestGoHome();
  const auth = useAuth();


  const goHome = () => {
    const isLogged = auth.isLogged();
    if (isLogged) {
      userGoHome();
    }
    else {
      guestGoHome();
    }
  }

  return goHome;
}


export default useGoHome;