import i18next from 'i18next';
import jtl from 'tools/jtl';
import { PanelControlsItemSectionItemType } from "lego/components/panel-controls/config"
import { settings } from 'app/configs';
import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import Icon from "app/ui/icons/icons";


namespace PanelItemGenerator {

  /**
   * 
   * Font Variant
   * 
   */

  export const getFontCapslock = (props: {
    css: React.CSSProperties;
    onClick: (event: any) => void;
    skip?: boolean,
    disabled?: boolean,
    dataTest?: string,
  }) => {
    const t = i18next.t;
    
    const {
      css,
      onClick,
      skip,
      disabled,
      dataTest,
    } = props;

    const fontVariant = css['fontVariant'];

    const item = {
      title: t('font caps lock'),
      skip,
      disabled,
      type: PanelControlsItemSectionItemType.BUTTON_GROUP,
      dataTest,
      buttons: [
        {
          tooltip: t('font variant none'),
          value: 'none',
          Icon: Icon.Unshift,
          selected: fontVariant === "none",
        },
        {
          tooltip: t('font variant small caps'),
          value: 'small-caps',
          Icon: Icon.Shift,
          selected: fontVariant === "small-caps",
        },   
        {
          tooltip: t('font variant all caps'),
          value: 'all-small-caps',
          Icon: Icon.Capslock,
          selected: fontVariant === "all-small-caps",
        },                 
      ],
      onClick: (fontVariant: string) => {
        const cssUpdate = {fontVariant};
        onClick(cssUpdate);
      }
    }

    return item;
  }

  export const getFontWeight = (props: {
    css: React.CSSProperties,
    onChange: (cssUpdate: React.CSSProperties) => void;
    onChangeDone: (cssUpdate: React.CSSProperties) => void;
    skip?: boolean,
    disabled?: boolean,
    dataTest?: string,
  }) => {
    const {
      css,
      onChange,
      onChangeDone,
      skip,
      disabled,
      dataTest,
    } = props;

    const t = i18next.t;
    
    const valueTxt = jtl.css.getValue(css, 'fontWeight');
    const value = jtl.css.valueToNumber(valueTxt) / 100;

    const item = {
      title: t('font weight'),
      skip,
      disabled,
      type: PanelControlsItemSectionItemType.SLIDER,
      min: 1,
      max: 9,
      step: 1,
      value,
      dataTest,
      onChange: (value: number) => { 
        const cssUpdate = { fontWeight: `${value * 100}` };
        onChange(cssUpdate);
      },
      onChangeDone: (value: number) => {
        const cssUpdate = { fontWeight: `${value * 100}` };
        onChangeDone(cssUpdate);
      }
    }

    return item;
  }

  export const getFontSize = (props: {
    css: React.CSSProperties,
    onChange: (cssUpdate: React.CSSProperties) => void;
    onChangeDone: (cssUpdate: React.CSSProperties) => void;
    minValue?: number,
    maxValue?: number,
    skip?: boolean,
    disabled?: boolean,
    dataTest?: string,
  }) => {
    const t = i18next.t;
    const minValue = (
      props.minValue !== undefined ?
      props.minValue :
      settings.printView.cell_index.font.size.min
    );

    const maxValue = (
      props.maxValue !== undefined ?
      props.maxValue :
      settings.printView.cell_index.font.size.max
    );

    const item = __getSimpleCSS({
      ...props,
      title: t('size'),
      attrName: 'fontSize',
      minValue,
      maxValue,
    });

    return item;
  }

  export const getPadding = (props: {
    css: React.CSSProperties,
    minValue?: number,
    maxValue?: number,
    skip?: boolean,
    disabled?: boolean,
    onChange: (cssUpdate: React.CSSProperties) => void,
    onChangeDone: (cssUpdate: React.CSSProperties) => void,
  }) => {
    const t = i18next.t;

    const minValue = (
      props.minValue !== undefined ?
      props.minValue :
      settings.printView.cell.padding.min
    );

    const maxValue = (
      props.maxValue !== undefined ?
      props.maxValue :
      settings.printView.cell.padding.max
    );

    const item = __getSimpleCSS({
      ...props,
      title: t('padding'),
      attrName: 'padding',
      minValue,
      maxValue
    });

    return item;
  }

   export const getBorderRadius = (props: {
    css: React.CSSProperties,
    onChange: (cssUpdate: React.CSSProperties) => void,
    onChangeDone: (cssUpdate: React.CSSProperties) => void,
    minValue?: number,
    maxValue?: number,
    skip?: boolean,
    disabled?: boolean,
  }) => {
    const t = i18next.t;

    const item = __getSimpleCSS({
      ...props,
      title: t('rounded corners'),
      attrName: 'borderRadius',
    });

    return item;
  }

  export const getBorderWidth = (props: {
    css: React.CSSProperties,
    onChange: (cssUpdate: React.CSSProperties) => void,
    onChangeDone: (cssUpdate: React.CSSProperties) => void,
    minValue?: number,
    maxValue?: number,
    skip?: boolean,
    disabled?: boolean,
  }) => {
    const t = i18next.t;

    const item = __getSimpleCSS({
      ...props,
      title: t('width'),
      attrName: 'borderWidth',
    });

    return item;
  }

  const __getSimpleCSS = (props: {
    title: string,
    attrName: string,
    minValue?: number,
    maxValue?: number,
    css: React.CSSProperties,
    onChange: (cssUpdate: React.CSSProperties) => void,
    onChangeDone: (cssUpdate: React.CSSProperties) => void,
    skip?: boolean,
    disabled?: boolean,
    dataTest?: string,
  }) => {
    const {
      title,
      css,
      attrName, 
      minValue,
      maxValue,
      onChange,
      onChangeDone,
      skip,
      disabled,
      dataTest
    } = props;

    const valueTxt = jtl.css.getValue(css, attrName);
    const value = jtl.css.valueToNumber(valueTxt);
    
    const item = ({
      title,
      skip,
      disabled,
      type: PanelControlsItemSectionItemType.SLIDER,
      min:  minValue,
      max:  maxValue,
      step: 1,
      value,
      dataTest,
      onChange: (value: number) => { 
        const cssUpdate = { [attrName]: `${value}px` };
        onChange(cssUpdate);
      },
      onChangeDone: (value: number) => {
        const cssUpdate = { [attrName]: `${value}px` };
        onChangeDone(cssUpdate);
      }
    });

    return item;
  }


  /**
   * 
   * Panel Visibility
   *
   */

  export const getPanelVisibility = (props: {
    visible: TypesShared.PageItemVisibility,
    onClick: (event: any) => void;
    skip?: boolean,
    disabled?: boolean,
  }) => {
    const t = i18next.t;
    
    const {
      visible,
      onClick,
      skip,
      disabled,
    } = props;

    const item = {
      title: t('page header, on pages'),
      skip,
      disabled,
      type: PanelControlsItemSectionItemType.BUTTON_GROUP,
      onClick,
      buttons: [
        {
          tooltip: t('page visiblity, none'),
          Icon: Icon.OnPage.Visibility.None,
          value: TypesShared.PageItemVisibility.NONE,
          selected: visible === TypesShared.PageItemVisibility.NONE,
        },
        {
          tooltip: t('page visiblity, first page'),
          Icon: Icon.OnPage.Visibility.FirstPage,
          value: TypesShared.PageItemVisibility.FIRST_PAGE,
          selected: visible === TypesShared.PageItemVisibility.FIRST_PAGE,
        },
        {
          tooltip: t('page visiblity, all pages'),
          Icon: Icon.OnPage.Visibility.All,
          value: TypesShared.PageItemVisibility.ALL,
          selected: visible === TypesShared.PageItemVisibility.ALL,
        },
      ]
    }

    return item;
  }


  /**
   * 
   * On / Off buttons
   * 
   */
  export const getToggleOnOff = (props: {
    title?: string,
    selected: boolean,
    onClick: (event: any) => void,
    skip?: boolean,
    disabled?: boolean,
  }) => {
    const t = i18next.t;

    const {
      title,
      selected,
      onClick,
      skip,
      disabled,
    } = props;

    const item = {
      title,
      skip,
      disabled,
      onClick,
      type: PanelControlsItemSectionItemType.BUTTON_GROUP,
      buttons: [
        {
          text: t('off'),
          value: false,
          selected: ! selected
        },
        {
          text: t('on'),
          value: true,
          selected: selected
        },
      ]
    }

    return item;
  }
  

  /**
   * 
   * Align horizontal
   */
  
  export const getAlignHorizontal = (props: {
    title?: string,
    css: React.CSSProperties,
    onClick: (event: any) => void,
    singleItem?: boolean,
    skip?: boolean,
    disabled?: boolean,
  }) => {
    const t = i18next.t;

    const {
      css,
      onClick,
      singleItem,
      skip,
      disabled,
    } =  props;
    
    const title = props.title || t('view print, images align horizontal');
    const item = ({
      skip,
      disabled,
      title,
      type: PanelControlsItemSectionItemType.BUTTON_GROUP,
      buttons: [
        {
          Icon: Icon.Align.To.Left.Horizontal,
          tooltip: t("left"),
          value: { justifyContent: 'flex-start' },
          selected: () => jtl.css.hasProps(css, {
            justifyContent: 'flex-start'
          }),
        },
        {
          Icon: Icon.Align.To.Center.Horizontal,
          tooltip: t("center"),
          value: { justifyContent: 'center' },
          selected: () => jtl.css.hasProps(css, {
            justifyContent: 'center'
          }),
        },
        {
          Icon: Icon.Align.To.Right.Horizontal,
          tooltip: t("right"),
          value: { justifyContent: 'flex-end' },
          selected: () => jtl.css.hasProps(css, {
            justifyContent: 'flex-end'
          })
        },
        {
          skip: singleItem,
          Icon: Icon.Align.SpaceBetween.Horizontal,
          tooltip: t("space evenly"),
          value: { justifyContent: 'space-evenly' },
          selected: () => jtl.css.hasProps(css, {
            justifyContent: 'space-evenly'
          })
        },
      ],
      onClick
    });

    return item;
  }

} // ns


export default PanelItemGenerator;