import React from 'react';
import { useRecoilValue } from 'recoil';

import jtl from 'tools/jtl';

import { settings } from 'app/configs';
import { useDocState } from 'app/ui/contexts/document';
import useColumnSelected from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/column/use-column-selected';
import useEditorStatesSetters from 'app/ui-v2/editor-instruction/hooks/use-editor-states-setters';
import { UIState_Releaselogs } from 'app/ui/states/editor-instruction';


const useFunctions = () => {
  const document = useDocState();

  const {
    setReleaselogs,
  } = useEditorStatesSetters();

  const selectedColumnAddr = useColumnSelected();
  const columnCSS          = useRecoilValue(UIState_Releaselogs.columnCSS(selectedColumnAddr)) || {};
  const columnHeaderCSS    = useRecoilValue(UIState_Releaselogs.columnHeaderCSS(selectedColumnAddr)) || {};


  //----------------
  // Column header

  const header_updateCSS = (value: React.CSSProperties) => {
    document.releaselogs.updateColumnHeaderCSS(selectedColumnAddr!, value);
    document.saveUndo();
    setReleaselogs();
  }

  const header_checkCSS = (props: React.CSSProperties) => {
    return jtl.css.hasProps(columnHeaderCSS, props)
  }


  //---------------
  // Column

  const column_updateCSS = (cssProps: React.CSSProperties) => {
    document.releaselogs.updateColumnCSS(selectedColumnAddr !, cssProps);
    document.saveUndo();
    setReleaselogs();
  }
  
  const column_sliderUpdateCSS = (cssProps: React.CSSProperties) => {
    document.releaselogs.updateColumnCSS(selectedColumnAddr !, cssProps);
    setReleaselogs();
  }

  const column_sliderUpdateCSSDone = (cssProps: React.CSSProperties)  => {
    column_sliderUpdateCSS(cssProps);
    document.saveUndo();
  }

  const column_checkCSS = (props: React.CSSProperties) => {
    return jtl.css.hasProps(columnCSS, props)
  }

  const column_getCSSValue = (cssAttr: string, fallback: number) => {
    const value = jtl.css.getValue(columnCSS, cssAttr);
    const valueInPx = jtl.css.valueToNumber(value, fallback);
    return valueInPx;
  }
 
  const column_isDirectionColumn = () => {
    return column_checkCSS({flexDirection: 'column'});
  }

  const column_isDirectionRow = () => {
    return column_checkCSS({flexDirection: 'row'});
  }

  const column_getPadding = () => {
    return column_getCSSValue('padding', settings.printView.cell.padding.default);
  }

  const column_getFontSize = () => {
    return column_getCSSValue('fontSize', settings.printView.cell_index.font.size.default);
  }

  const column_getFontWeight = () => {
    return column_getCSSValue('fontWeight', settings.printView.cell_index.font.weight.default * 100) / 100;
  }


  return {
    header_updateCSS,
    header_checkCSS,

    column_updateCSS,

    column_sliderUpdateCSS,
    column_sliderUpdateCSSDone,

    column_checkCSS,
    column_getCSSValue,
    column_isDirectionColumn,
    column_isDirectionRow,
    column_getPadding,
    column_getFontSize,
    column_getFontWeight,
  }
}


export default useFunctions;