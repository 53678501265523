import { atom }     from 'recoil';
import { selector } from 'recoil';
import { HeaderMarkersState as State } from 'app/arch/editor-instruction/document/states/persistent/header-markers';


//----------------
//
// State
//

export const state = atom<State.State>({
  key: "edi_doc_headerMarkers",
  default: State.createInitialState()
});


//--------------------
//
// Selectors
//
export const markersAddrs = selector({
  key: `edi_doc_headerMarkers_markersAddrs`,
  get: ({ get }) => {
    const stateObj = get(state);
    return State.getMarkersAddrs(stateObj);
  }
});

