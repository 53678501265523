import styled from "styled-components";

import GridRow1Base from "../../styles/navbar-item/grid-row1";
import GridRow2Base from "../../styles/navbar-item/grid-row2";
import ItemBase from "../../styles/navbar-item/item";
import PanelDetacherBase from "../../styles/navbar-item/panel-detacher";
import TextBase from "../../styles/navbar-item/text";
import IconWrapperBase from "../../styles/navbar-item/icon-wrapper";


export const GridRow1 = styled(GridRow1Base)`
  /* width: 150px; */
  /* height: 30px; */
  /* background-color: red; */
`;

export const GridRow2 = styled(GridRow2Base)`
  /* width: 50px; */
  /* height: 30px; */
  /* background-color: blue; */
`;

export const Item = styled(ItemBase)`
`;

export const PanelDetacher = styled(PanelDetacherBase)`
`;

export const Text = styled(TextBase)`
`;

export const IconWrapper = styled(IconWrapperBase)`
`;
