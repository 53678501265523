import { useRecoilValue } from "recoil"
import { UIProducts_PricePlansSession } from "app/ui-v2/app/__modules/products/states";


const useBillingPeriod = () => {
  const billingPeriod = useRecoilValue(UIProducts_PricePlansSession.billingPeriod);
  return billingPeriod;
}


export default useBillingPeriod;