import { useRef } from 'react';

import { RateControlType } from 'lego/types';
import { PanelControlsConfig }              from 'lego/components/panel-controls/config';
import { PanelControlsItemSectionItemType } from 'lego/components/panel-controls/config';

import { Page }                from 'app/arch/print/page';
import { PageFormatType }      from 'app/arch/types/types';
import { PageOrientationType } from 'app/arch/types/types';

import { useDocState }        from 'app/ui/contexts/document';
import { useTranslations }    from 'app/ui/hooks/app/use-translation';
import useCustomFieldsColumnsAutoAdjust from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-custom-fields/columns/use-columns-auto-adjust';
import useContentColumnsAutoAdjust      from 'app/ui-v2/editor-instruction/views/view-content/hooks/doc-content/columns/use-columns-auto-adjust';
import useReleaselogsColumnsAutoAdjust  from 'app/ui-v2/editor-instruction/views/view-changelog/hooks/columns/use-releaselogs-columns-auto-adjust';

import usePageLayout  from './use-page-layout';
import usePageMargins from './use-page-margins';
import useEditorStatesSetters from '../../../../hooks/use-editor-states-setters';




const usePagePanelConfig = () => {
  const document = useDocState();
  const t = useTranslations();

  const pageLayout = usePageLayout();
  const pageMargins = usePageMargins();
  
  const docReleaselogsColumnsAutoAdjust = useReleaselogsColumnsAutoAdjust()
  const docContent2ColumnsAutoAdjust    = useContentColumnsAutoAdjust();
  const contentCustomFieldsAutoAdjust   = useCustomFieldsColumnsAutoAdjust();

  //----------
  // Setters 
  const { 
    setViewsCommon
  } = useEditorStatesSetters();

  const prevPageMarginsLockedRef = useRef<boolean | null>(null);

  const autoAdjustColumns = () => {
    docReleaselogsColumnsAutoAdjust();
    docContent2ColumnsAutoAdjust();
    contentCustomFieldsAutoAdjust();
  }

  const margin_changeStart = () => {
    prevPageMarginsLockedRef.current = document.viewsCommon.getPageMargins().locked;
    document.viewsCommon.updatePageMargins({locked: false});
    setViewsCommon();
  }

  const margin_change = (marginName: string, marginValue: number) => {
    const update = {[marginName]: marginValue};
    document.viewsCommon.updatePageMargins(update);
    setViewsCommon();
    autoAdjustColumns();
  }

  const margin_changeDone = (marginName: string, marginValue: number) => {
    margin_change(marginName, marginValue);

    if (prevPageMarginsLockedRef.current !== null) {
      if (prevPageMarginsLockedRef.current !== document.viewsCommon.getPageMargins().locked) {
        const locked = prevPageMarginsLockedRef.current;
        document.viewsCommon.updatePageMargins({locked});
        setViewsCommon();
      }
    }

    prevPageMarginsLockedRef.current = null;
    document.saveUndo();
  }

  
  const handlePageOrientation = (orientation: PageOrientationType) => {
    document.viewsCommon.updatePageLayout({orientation});
    setViewsCommon();
    autoAdjustColumns();

    document.saveUndo();
  }

  const handlePageFormat = (format: PageFormatType) => {
    document.viewsCommon.updatePageLayout({format});
    setViewsCommon();
    autoAdjustColumns();

    document.saveUndo();
  }

  const handlePageMarginsLocked = (locked: boolean) => {
    document.viewsCommon.updatePageMargins({locked});
    document.saveUndo();
    setViewsCommon();
  }
  
  const unit = Page.getUnitsString(pageMargins.units);
  
  const getMarginTitle = (title: string) => {
    return `${title} (${unit})`;
  }

  //--------------------
  //
  // Config definition
  //
  const getConfig = () => {

    const __page_orientation = () => (
      {
        title: t('page orientation'),
        type: PanelControlsItemSectionItemType.BUTTON_GROUP,
        buttons: [
          {
            text: t('page portrait'),
            value: PageOrientationType.PORTRAIT,
            selected: pageLayout.orientation === PageOrientationType.PORTRAIT
          },
          {
            text: t('page landscape'),
            value: PageOrientationType.LANDSCAPE,
            selected: pageLayout.orientation === PageOrientationType.LANDSCAPE
          },                  
        ],
        onClick: handlePageOrientation
      }
    );

    const __page_size = () => (
      {
        title: t('page size'),
        type: PanelControlsItemSectionItemType.BUTTON_GROUP,
        buttons: [
          // {
          //   text: 'A6',
          //   value: PageFormatType.A6,
          //   selected: pageLayout.format === PageFormatType.A6
          // },          
          {
            text: 'A5',
            value: PageFormatType.A5,
            selected: pageLayout.format === PageFormatType.A5
          },
          {
            text: 'A4',
            value: PageFormatType.A4,
            selected: pageLayout.format === PageFormatType.A4
          },
          {
            text: 'A3',
            value: PageFormatType.A3,
            selected: pageLayout.format === PageFormatType.A3
          },
        ],
        onClick: handlePageFormat
      }
    );

    const __margins_locked = () => (
      {
        title: t('locked'),
        type: PanelControlsItemSectionItemType.BUTTON_GROUP,
        buttons: [
          {
            text: t('unlocked'),
            value: false,
            selected: ! pageMargins.locked
          },
          {
            text: t('locked'),
            value: true,
            selected: pageMargins.locked
          },                  
        ],
        onClick: handlePageMarginsLocked
      }
    );

    const __margin_size_base = (marginName: 'top' | 'bottom' | 'left' | 'right') => ({
      title: getMarginTitle(t(marginName)),
      type: PanelControlsItemSectionItemType.SLIDER,
      onChangeStart: margin_changeStart,
      onChange: (value: number) => margin_change(marginName, value),
      onChangeDone: (value: number) => margin_changeDone(marginName, value),
      rateControl: {
        controlType: RateControlType.DEBOUNCED
      },
      min: 0,
      max: 100,
      value: pageMargins[marginName],
    })

    const __margin_top    = () => __margin_size_base('top');
    const __margin_bottom = () => __margin_size_base('bottom');
    const __margin_left   = () => __margin_size_base('left');
    const __margin_right  = () => __margin_size_base('right');


    const config: PanelControlsConfig = {
      items: [
        {
          title: t('page'),
          sections: [
            {
              subsections: [
                {
                  title: t('layout'),
                  items: [
                    __page_orientation(),
                    __page_size(),
                  ]
                }
              ],
            },
            {
              subsections: [
                {
                  title: t('page margins'),
                  items: [
                    __margins_locked(),
                    __margin_left(),
                    __margin_right(),
                    __margin_top(),
                    __margin_bottom(),
                  ]
                }
              ],
            }
          ]
        },
      ]
    } // config

    return config;
  }

  return getConfig;
}
  
export default usePagePanelConfig;
