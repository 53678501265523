export enum ThemeType {
  MACOSX  = 'macosx',
  WINDOWS = 'window',
  GNOME   = 'gnome',
}


export enum ThemeVariantType {
  LIGHT = 'light',
  DARK  = 'dark'
}

export enum ThemeAccentColor {
  BLUE   = 'blue',
  VIOLET = 'violet',
  PINK   = 'pink',
  RED    = 'red',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  GREEN  = 'green',
  GREY   = 'grey',
}

export type ThemeProps = {
  accentColor: ThemeAccentColor; // remove me
  darkMode: boolean;
}

export type ThemeConstsProps = {
  darkMode: boolean;
}

export type ThemeDefProps = {
  darkMode: boolean;
  accentColor: any;
  border: any;
  boxShadow: any;
  colors: any;
  font: any;
  gap: any;
  opacity: any;
  outline: any;
  padding: any;
  scale: any;
}

