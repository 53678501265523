import React from 'react';
import { useEffect } from 'react';
import { useState }  from 'react';
import jtl from 'tools/jtl';

import { useProgressModal } from 'lego-v2/progress-modal';
import environment from 'app/environment';

import FormPanelComponent from 'app-views/components/form-panel';
import InputComponent     from 'app-views/components/input';

import { useMutation } from 'app/arch/backend/use-mutation';
import { mutation }    from 'app/arch/backend';
import { ViewTypes }   from 'app/arch/home-page-guest/states/view';

import { useTranslations } from 'app/ui/hooks/app/use-translation';
import { MsgDuration }     from 'app/ui-v2/app/__modules/info/components/toasts/__remove-me/msg-box';
import useViewSelect       from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-select';
import useViewParamsSet    from 'app/ui-v2/home-page/home-page-guest/hooks/use-view-params-set';

import { Panel } from "./styles";


interface Props {
  email: string | null;
}


export const AccountCreateComponent: React.FC<Props> = (props: Props) => {
  const selectView = useViewSelect();
  const setViewParams = useViewParamsSet();

  const t = useTranslations();

  const emilInit = (
    props.email !== null ?
    props.email :
    (
      environment.dev ?
      environment.test.email1 :
      ""
    )
  );

  const [email, setEmail] = useState(emilInit);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const { 
    showProgressModal, 
    hideProgressModal
  } = useProgressModal();

  useEffect(() => {
    const emailValid = jtl.email.isValid(email);
    if (emailValid !== isEmailValid) {
      setIsEmailValid(emailValid);
    }
  }, []);

  const { 
    mutation: createUser,
    loading: loadingCreateUser,
    data: createUserData
  } = useMutation(
    mutation.createUser,
    {
      onStart: {
        msg:  t("creating user"),
      },
      onEnd: 
      {
        msg: t("user created"),
        duration: MsgDuration.MIDDLE
      }
    }
  );


  useEffect(() => {
    if ( ! createUserData ) {
      return;
    }

    if ('userCreate' in createUserData === false) {
      return;
    }

    hideProgressModal();

    const data = createUserData.userCreate;
    if ( ! data.ok ) {
      const error = data.error;
      selectView(ViewTypes.ViewItem.USER_SIGNUP_FAILED);
      setViewParams({error})
      return;
    }

    selectView(ViewTypes.ViewItem.USER_SIGNUP_DONE);
  }, [createUserData]);

  const handleEmailChange = (email: string) => {
    setEmail(email);
    setIsEmailValid(jtl.email.isValid(email));
  }

  const handleSubmit = () => {
    showProgressModal();
    createUser({ 
      variables: { email }
    });
  }

  return (
    <Panel>
      <FormPanelComponent
        buttonText={t('create')}
        onSubmit={handleSubmit}
        submitDisabled={! isEmailValid}
      >
        <InputComponent
          title={t('enter your email')}
          value={email}
          onChange={handleEmailChange}
        />
      </FormPanelComponent>
    </Panel>
  );
}
  
