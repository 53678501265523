import useDivsImgsLoaded from './__use-divs-imgs-loaded';
import useImgsTagsLoaded from './__use-imgs-tags-loaded';


const useImagesLoaded = (startCheck: boolean) => {
  const imgsTagsLoaded = useImgsTagsLoaded(startCheck);
  const divsImgsLoaded = useDivsImgsLoaded(startCheck);

  const loaded = (imgsTagsLoaded && divsImgsLoaded);
  return loaded;
};

export default useImagesLoaded;
