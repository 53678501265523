import { useRecoilValue } from 'recoil';

import * as TypesShared from 'app/arch/editor-instruction/document/states/types';
import { UIState_HeaderMeta } from 'app/ui/states/editor-instruction';
import { UIState_ReleaselogsView } from 'app/ui/states/editor-instruction';


const useStates = () => {
  const visible     = useRecoilValue(UIState_ReleaselogsView.headerMetaVisible);
  const visibleNone = (visible === TypesShared.PageItemVisibility.NONE);
  const logoVisible     = useRecoilValue(UIState_HeaderMeta.logoVisible);
  const revisionVisible = useRecoilValue(UIState_HeaderMeta.releaseInfoVisible);


  return {
    visible,
    visibleNone,

    logoVisible,
    revisionVisible,
  }
}


export default useStates;