import styled from 'styled-components';
import Scrollbar from 'lego/styles/scrollbar';
import { CONTACT_BAR_HEIGHT } from './contact-bar-wrapper';


const ContentLayout = styled(Scrollbar)`
  width: 100%;
  height: calc(100% - ${CONTACT_BAR_HEIGHT}px);
  
  overflow: auto;
  box-sizing: border-box;
  padding: ${props => props.theme.defs.padding.xlarge};
`;


export default ContentLayout;