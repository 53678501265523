import React from 'react';
import { useRecoilValue } from 'recoil';
import * as Types from 'app/arch/editor-instruction/document/states/persistent/content/types';

import { UIState_ContentSession } from 'app/ui/states/editor-instruction';
import { MainWrapper } from './styles';


interface Props {
  imageAddr: Types.ImageAddr;
  children: React.ReactNode;
}


export const ImageSelectedComponent: React.FC<Props> = (props: Props) => {
  const {
    imageAddr,
    children 
  } = props;

  const isSelected = useRecoilValue(UIState_ContentSession.cellImages_isImageSelected(imageAddr));
  
  return (
    <MainWrapper selected={isSelected}>
      { children }
    </MainWrapper>
  );
}
  
