import { useRecoilValue } from 'recoil';
import { UIState_EditorImageSession } from 'app/ui/states/editor-instruction';


const useState = () => {
  const panelType    = useRecoilValue(UIState_EditorImageSession.sideToolbarPanelType);
  const panelVisible = useRecoilValue(UIState_EditorImageSession.sideToolbarPanelVisible);


  return {
    panelType,
    panelVisible,
  }
}


export default useState;