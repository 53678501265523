import produce from 'immer';
import Logger from 'libs/debug';
import * as Types from './types';
import * as State from './state';


export class HistoryState {
  private _state: State.StateRaw;

  constructor(record: Types.Record) {
    this._state = State.createInitialState(record);
  }

  get state() { return this._state; }

  saveUndo(record: Types.Record) {
    this._state = produce(this._state, draft => {
      // TODO 
      // Add some kind of undo size limit

      draft.past.push(draft.current);
      draft.current = record;
      draft.future = [];
    });
  }

  undo(): Types.Record {
    const log = Logger.getDocUndo();

    this._state = produce(this._state, draft => {
      if (draft.past.length > 0) {
        draft.future.push(draft.current);
        draft.current = draft.past.pop() !;
      }
      else {
        log.info("Can't undo - top of undo stack");
      }
    });

    return this._state.current;
  }

  redo(): Types.Record {
    const log = Logger.getDocUndo();

    this._state = produce(this._state, draft => {
      if (draft.future.length > 0) {
        draft.past.push(draft.current);
        draft.current = draft.future.pop() !;
      }
      else {
        log.info("Can't redo - top of redo stack");
      }
    });

    return this._state.current;
  }
}
