import React from 'react';

import { DocumentRelease } from 'app/arch/backend/types';
import ItemPdfSendComponent from './item-pdf-send';
import ItemPdfDownloadComponent from './item-pdf-download';
import { Separator } from './styles';


interface Props {
  documentId: number;
  release: DocumentRelease;
}


export const ReleaseItemExpandedComponent: React.FC<Props> = (props: Props) => {
  const {
    documentId,
    release,
  } = props;

  return (
    <>
      <ItemPdfDownloadComponent
        documentId={documentId}
        release={release}
      />
      <ItemPdfSendComponent 
        documentId={documentId}
        release={release}
      />
      <Separator />    
    </>
  );
}

