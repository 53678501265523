import React from 'react';

import { RepoMarkersTypes } from 'app/arch/editor-instruction/document/states/persistent/repo-markers';
import SideMenuComponent from './side-menu';
import MarkerEditPanelComponent from './marker-edit-panel';

import { MainRow }            from './styles';
import { MarkerEditWrapper }  from './styles';
import { SideToolbarWrapper } from './styles';


interface Props {
  markerAddr: RepoMarkersTypes.MarkerAddr,
}


export const EditorMarkerContentComponent: React.FC<Props> = (props: Props) => {
  const {
    markerAddr
  } = props;

  return (
    <MainRow>

      <MarkerEditWrapper>
        <MarkerEditPanelComponent markerAddr={markerAddr} />
      </MarkerEditWrapper>

      <SideToolbarWrapper>
        <SideMenuComponent markerAddr={markerAddr} />
      </SideToolbarWrapper>

    </MainRow>
  );
}
