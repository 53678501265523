import React, { useState } from 'react';

import PanelTitledComponent from 'app-views/components/panel-titled';
import { ReposTypes } from 'app/arch/app/states/repos';

import { useTranslations }  from 'app/ui/hooks/app/use-translation';
import { useRepoMemberAcceptInvite } from 'app/ui-v2/app/hooks/use-repo-member-accept-invite';
import { useRepoMemberRejectInvite } from 'app/ui-v2/app/hooks/use-repo-member-reject-invite';
import useRepos from 'app/ui-v2/app/hooks/use-repos';
import ProgressBoxComponent from 'app/ui-v2/hls/progress-box';
import useUsername from 'app/ui-v2/app/hooks/use-user-name';

import { Button } from './styles';
import { ButtonDelete } from './styles';
import { ButtonsWrapper } from './styles';
import { Content } from './styles';
import { IconAccept } from './styles';
import { IconReject } from './styles';
import { ProgressBox } from './styles';
import { Text } from './styles';


interface Props {
  repoId: number;
}


export const RepoInvitationPanelComponent: React.FC<Props> = (props: Props) => {
  const {
    repoId,
  } = props;

  const t = useTranslations();
  const username = useUsername();
  const acceptRepoInvite = useRepoMemberAcceptInvite();
  const rejectRepoInvite = useRepoMemberRejectInvite();

  const [inviteProcessing, setInviteProcessing] = useState(false);

  const repos = useRepos();
  const repoPack = repos.getRepoPack(repoId);
  const invitationText = t("repository invitation, info");


  const checkMemberStatus = () => {
    const userMemberStatus = repos.getRepoMemberStatus(repoId, username);
    const validStatus = ( userMemberStatus === ReposTypes.MemberStatus.INVITE_SENT);
    if ( ! validStatus ) {
      console.error(`Invalid member status`);
    }
    return validStatus;
  }
  
  const handleAccept = () => {
    if ( ! checkMemberStatus() ) {
      return;
    }

    setInviteProcessing(true);
    acceptRepoInvite({
      repoId: repoPack.repo.id,
    });
  }
  
  const handleReject = () => {
    if ( ! checkMemberStatus() ) {
      return;
    }

    rejectRepoInvite({
      repoId: repoPack.repo.id,
      onStart: () => setInviteProcessing(true)
    });
  }

  const renderProgress = () => (
    <ProgressBoxComponent
      Box={ProgressBox} 
      hideTitle={true}
    />
  );

  const renderContent = () => (
    <Content>
      <Text>
        { invitationText }
      </Text>

      <ButtonsWrapper>
        <Button onClick={handleAccept} >
          <IconAccept />
          { t("accept") }
        </Button>
        <ButtonDelete onClick={handleReject} >
          <IconReject />
          { t("reject") }
        </ButtonDelete>
      </ButtonsWrapper>

    </Content>
  );

  return (
    <PanelTitledComponent title={t('repository invitation')} >
      { inviteProcessing && renderProgress() }
      { ! inviteProcessing && renderContent() }
    </PanelTitledComponent>
  );
}
