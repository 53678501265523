import React from 'react';

import * as Types from '../types';

import { LastUpdate } from './styles';
import { Content } from './styles';
import { ContentTitle } from './styles';
import { Sections } from './styles';
import { Section } from './styles';
import { SectionTitle } from './styles';
import { Paragraphs } from './styles';
import { Paragraph } from './styles';
import { Endlog } from './styles';


interface Props {
  content: Types.Content
}


export const LegalTextContentComponent: React.FC<Props> = (props: Props) => {
  const {
    content
  } = props;

  
  const renderParagraphs = (
    paragraphs: Types.Paragraphs
  ) => {
    const ParagrapshComps = paragraphs.map((paragraph, idx) => (
      <Paragraph key={`paragraph-${idx}`}>
      { paragraph }
      </Paragraph>
    ));

    return (
      <Paragraphs>
        { ParagrapshComps }
      </Paragraphs>
    );
  }

  const renderSection = (
    section: Types.Section, idx: number
  ) => (
    <Section key={`section-${idx}`}>
      {
        section.title &&
        <SectionTitle>
          { idx }. { section.title}
        </SectionTitle>
      }
      { renderParagraphs(section.paragraphs) }
    </Section>
  );

  const renderSections = () => {
    const SectionsComps = content.sections.map(
      (section, idx) => renderSection(section, idx)
    );

    return (
      <Sections>
        { SectionsComps }
      </Sections>
    );
  }


  return (
    <Content>
      <ContentTitle>
        { content.title }
      </ContentTitle>

      <LastUpdate>
        Last updated: { content.lastUpdate }
      </LastUpdate>

      { renderSections() }

      <Endlog>
        { content.endlog }
      </Endlog>
    </Content>
  );
}
  
